import moment from 'moment';
import {
  createColumnsV2Common,
  createV2CommonFilters,
} from '../../../../aspects/V2CommonFields';
import {
  createColumnsV2Recadvs,
  createV2RecadvFilters,
} from '../../../../aspects/V2RecadvFields';
import { CommonList } from '../../../../shared';

/**
 * Declare the additional columns to display (aspects)
 */
const columns: any[] = [
  ...createColumnsV2Recadvs(),
  ...createColumnsV2Common(),
];

/**
 * Specific filters for orders
 */
const additionalFilters: any[] = [
  ...createV2CommonFilters(),
  ...createV2RecadvFilters(),
];

/**
 * Order list component
 */
const DxV2RecadvList = (props) => {
  return (
    <CommonList
      title='dxMessages.invoices.title'
      {...props}
      columns={columns}
      additionalFilters={additionalFilters}
      filterDefaultValues={{
        ingestDateAfter: moment()
          .subtract(4, 'hours')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      }}
    />
  );
};

export default DxV2RecadvList;
