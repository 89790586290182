/**
 * Helper methods for handling the local storage.
 */
export class LocalStorageHelpers {
  /**
   * Store item
   *  @throws Error if local storage is full or inaccessible.
   */
  public static storeItem<TValue>(key: string, value: TValue) {
    if (!LocalStorageHelpers.validateLocalStorage()) {
      throw new Error('Local storage seems to be full or inaccessible!');
    }

    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Retrieve Item
   * @throws Error if local storage is full or inaccessible.
   */
  public static retrieveItem<TValue>(key: string): TValue | undefined {
    if (!LocalStorageHelpers.validateLocalStorage()) {
      throw new Error('Local storage seems to be full or inaccessible!');
    }

    const result = localStorage.getItem(key);

    if (result) {
      return JSON.parse(result) as TValue;
    }

    return undefined;
  }

  /**
   * Remove item from local storage
   *  @throws Error if local storage is full or inaccessible.
   */
  public static removeItem(key: string) {
    if (!LocalStorageHelpers.validateLocalStorage()) {
      throw new Error('Local storage seems to be full or inaccessible!');
    }

    localStorage.removeItem(key);
  }

  /**
   * Checks if the local storage is available.
   */
  public static validateLocalStorage() {
    return localStorage !== undefined;
  }
}
