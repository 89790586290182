import {
  cellStyle,
  DateInput,
  GenericDateField,
  GenericSimpleField,
  Metadata,
  MultiSelectInput,
  OrderSubType,
  SubTypeLabels,
  widthPresets,
} from '@dx-ui/dx-common';
import { TextInput } from 'react-admin';
import { DocumentSubTypeField } from './CommonFields';

export const OrderIdField = (props) => <GenericSimpleField {...props} />;

export const OrderDateField = (props) => <GenericDateField {...props} />;

export const createColumnsOrder = () => [
  {
    id: Metadata.orderId,
    label: 'dxMessages.headers.orderId',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <OrderIdField {...props} />,
  },
  {
    id: Metadata.orderDate,
    label: 'dxMessages.headers.orderDate',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <OrderDateField {...props} />,
  },
];

export const createSpecificColumnsOrder = () => [
  {
    id: Metadata.documentSubTypeCode,
    label: 'dxMessages.headers.documentSubTypeCode',
    sortable: false,
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <DocumentSubTypeField {...props} />,
  },
];

export const createOrderFilters = () => [
  {
    id: Metadata.orderId,
    filter: (props) => (
      <TextInput
        source={Metadata.orderId}
        label='dxMessages.headers.orderId'
        resettable
        {...props}
      />
    ),
  },
  {
    id: Metadata.orderDate,
    filter: (props) => (
      <DateInput
        source={Metadata.orderDate}
        label='dxMessages.headers.orderDate'
        {...props}
      />
    ),
  },
];

export const createSpecificOrderFilters = () => [
  {
    id: Metadata.documentSubTypeCode,
    filter: (props) => (
      <MultiSelectInput
        label='dxMessages.headers.documentSubTypeCode'
        source={Metadata.documentSubTypeCode}
        width={widthPresets.large}
        sortLabels={true}
        {...props}
        options={[
          {
            id: OrderSubType.InitialOrder,
            label: SubTypeLabels[OrderSubType.InitialOrder],
          },
          {
            id: OrderSubType.UpdatedOrder,
            label: SubTypeLabels[OrderSubType.UpdatedOrder],
          },
          {
            id: OrderSubType.NotModifiedUpdatedOrder,
            label: SubTypeLabels[OrderSubType.NotModifiedUpdatedOrder],
          },
          {
            id: OrderSubType.FinalOrder,
            label: SubTypeLabels[OrderSubType.FinalOrder],
          },
          {
            id: OrderSubType.CrossDockingOrder,
            label: SubTypeLabels[OrderSubType.CrossDockingOrder],
          },
          {
            id: OrderSubType.GoodsReturnOrder,
            label: SubTypeLabels[OrderSubType.GoodsReturnOrder],
          },
        ]}
      />
    ),
  },
];
