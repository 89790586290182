import { colors, DxTheme } from '@dx-ui/dx-common';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/styles';
import AutoCompleteSingleSelectInput from './AutoCompleteSingleSelectInput';

const useStyles = makeStyles<DxTheme>(
  (theme) => ({
    root: {
      position: 'relative',
      width: '100%',
    },
    select: {
      '-moz-appearance': 'none', // Remove Firefox custom style
      '-webkit-appearance': 'none', // Fix SSR issue
      appearance: 'none', // Reset
      // When interacting quickly, the text can end up selected.
      // Native select can"t be selected either.
      userSelect: 'none',
      paddingRight: theme.spacing(1),
      minWidth: theme.spacing(2), // So it doesn"t collapse.
      cursor: 'pointer',
      '&:focus': {
        // Show that it's not a text input
        background:
          theme.palette.type === 'light'
            ? 'rgba(0, 0, 0, 0.05)'
            : 'rgba(255, 255, 255, 0.05)',
        borderRadius: 0, // Reset Chrome style
      },
      // Remove Firefox focus border
      '&:-moz-focusring': {
        color: 'transparent',
        textShadow: '0 0 0 #000',
      },
      // Remove IE11 arrow
      '&::-ms-expand': {
        display: 'none',
      },
      width: 'auto', // Fix Safari textOverflow
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    disabled: {
      cursor: 'default',
    },
    icon: {
      position: 'absolute',
      right: -12,
      top: 4,
      color: theme.palette.text.secondary,
      pointerEvents: 'none', // Don"t block pointer events on the select under the icon.
    },
    items: {
      flex: '0 1 auto',
      overflowY: 'auto',
    },
    paperWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      maxHeight: 'calc(50vh - 32px)',
    },
    itemSeparator: {
      height: 0,
      borderBottomStyle: 'solid',
      borderBottomColor: colors.black30,
      borderBottomWidth: 1,
      margin: '8px 16px 8px 48px',
    },
    children: {
      flex: '0 1 auto',
      marginTop: 8,
      borderTopStyle: 'solid',
      borderTopColor: colors.black30,
      borderTopWidth: 1,
      padding: '4px 8px',
    },
    filter: {
      margin: '8px 16px',
      flex: '0 0 auto',
    },
    loader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '8px 16px',
    },
    errorText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '8px 16px',
      color: colors.functionalRed,
    },
  }),
  { name: 'AutoCompleteSingleSelect' }
);

const Select = (props) => {
  const {
    options,
    sortLabels = false,
    getOptionId,
    renderOptionLabel,
    renderOptionTooltip,
    filterOption,
    icon,
    placeholder,
    filterPlaceholder,
    children,
    onOpen,
    onClose,
    loading,
    errorText,
    renderValue,
    numberToDisplaySearch,
    ...other
  } = props;

  const classes = useStyles();
  return (
    <Input
      inputComponent={AutoCompleteSingleSelectInput}
      {...other}
      inputProps={{
        classes,
        options,
        sortLabels,
        getOptionId,
        renderOptionLabel,
        renderOptionTooltip,
        filterOption,
        icon,
        placeholder,
        filterPlaceholder,
        children,
        onOpen,
        onClose,
        loading,
        errorText,
        renderValue,
        numberToDisplaySearch,
      }}
    />
  );
};

export default Select;
