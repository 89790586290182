import { UserRoles } from '../security/UserRoles';

/**
 * Checks whether the permissions contains the PSP administrator role.
 *
 * @param permissions the <ReactAdmin/> permissions (as returned by usePermissions())
 * @returns {boolean}
 */
export const isPspAdministrator = (permissions: object) =>
  !!permissions?.['ADMINISTRATION']?.find(
    (role: string) => role === 'PSP administrator'
  );

/**
 * Checks whether the permissions contains the company administrator role.
 *
 * @param permissions the <ReactAdmin/> permissions (as returned by usePermissions())
 * @returns {boolean}
 */
export const isCompanyAdministrator = (permissions: any = []) =>
  !!permissions?.['ADMINISTRATION']?.find(
    (role: string) => role === 'company administrator'
  );

export const isCompanyAdministratorV2 = (permissions: any = []) => {
  const appPermissions = permissions?.AUTH_ROLES
    ? permissions?.AUTH_ROLES[UserRoles.DXPURCHASE_PRODUCT]
    : permissions[UserRoles.DXPURCHASE_PRODUCT];
  if (appPermissions?.find((p) => p === UserRoles.CUSTOMER_ADMINISTRATOR)) {
    return true;
  }
  return false;
};

export const isBetaTester = (
  permissions: any = [],
  product: string = UserRoles.DXPURCHASE_PRODUCT
) => {
  const appPermissions = permissions?.AUTH_ROLES
    ? permissions?.AUTH_ROLES[product]
    : permissions[product];
  if (appPermissions?.find((p) => p === UserRoles.BETA_TESTER)) {
    return true;
  }
  return false;
};

export const isPeppolInvoiceWriter = (
  permissions: any = [],
  product: string = UserRoles.PEPPOL_PRODUCT
) => {
  const appPermissions = permissions?.AUTH_ROLES
    ? permissions?.AUTH_ROLES[product]
    : permissions[product];
  if (appPermissions?.find((p) => p === UserRoles.PEPPOL_INVOICE_WRITER)) {
    return true;
  }
  return false;
};
