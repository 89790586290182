import React from 'react';
import { TextField } from '@material-ui/core';

const Input = (props) => {
  const {
    meta: { touched, error }, // eslint-disable-line react/prop-types
    input: inputProps, // eslint-disable-line react/prop-types
    ...rest
  } = props;
  return (
    <TextField
      error={!!(touched && error)}
      helperText={(touched && error) || ' '}
      {...inputProps}
      {...rest}
      fullWidth
    />
  );
};

export default Input;
