import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useRef } from 'react';
import { ExpandRowButton, useTranslate } from 'react-admin';
import { Constants } from '../../constants';
import { DxTheme } from '../../types';
import { getDxApplication } from '../../utils';
import { AttachmentRows } from './AttachmentRows';
import { useChangeAttachmentsAllowed } from './AttachmentUtils';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    header: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    paper: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    expandIcon: {
      padding: theme.spacing(1),
      transform: 'rotate(-90deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expanded: {
      transform: 'rotate(0deg)',
    },
  }),
  { name: 'AttachmentsSection' }
);

/**
 * Describes component props
 */
export interface AttachmentsSectionProps {
  standardColumns: any;
  basePath: any;
  resource: any;
  specificResource: any;
  showMetadataButton: any;
  downloadButton: any;
  previewButton: any;
  currentSortAttach: any;
  setSort: any;
  id: any;
  attachments: any;
  dxuid: any;
  recipientId: any;
  processStatus: any;
  invoiceType: any;
  expanded?: boolean;
  toggleExpanded?: () => void;
  readOnly?: boolean;
}

/**
 * Attachments ection for related documents
 */
export const AttachmentsSection = (props: AttachmentsSectionProps) => {
  const {
    specificResource,
    attachments,
    toggleExpanded,
    expanded = false,
  } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const ref = useRef();
  const dxApplication = getDxApplication();

  let isAttachmentSectionAllowed =
    useChangeAttachmentsAllowed(specificResource) || !!attachments?.length;
  isAttachmentSectionAllowed =
    dxApplication === Constants.DXPURCHASE_APP
      ? isAttachmentSectionAllowed
      : true;

  return (
    <>
      {isAttachmentSectionAllowed && (
        <Grid container direction='column'>
          <Grid item>
            <Typography className={classes.header} variant='h6'>
              {translate('dxMessages.attachments.title', {
                _: 'Attachments',
              })}
              <ExpandRowButton
                className={classNames(
                  classes.expandIcon,
                  {
                    [classes.expanded]: expanded,
                  },
                  'attachments'
                )}
                classes={{
                  expandIcon: classes.expandIcon,
                  expanded: classes.expanded,
                }}
                expanded={expanded}
                onClick={toggleExpanded}
                title={translate(
                  expanded ? 'ra.action.close' : 'ra.action.expand'
                )}
                innerRef={ref}
              />
            </Typography>
            {expanded && (
              <Paper
                square
                className={classNames(classes.paper, 'attachments')}
              >
                <AttachmentRows
                  {...props}
                  redirectOnDelete={true}
                  refreshOnDelete={true}
                />
              </Paper>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
