import { Chip } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup, { FormGroupProps } from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FieldTitle, InputProps } from 'ra-core';
import { FunctionComponent, useCallback } from 'react';
import { InputHelperText, sanitizeInputRestProps } from 'react-admin';
import { DxTheme } from '../../types';
import useInput from './useInput';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: (props: { readOnly: boolean }) => {
      return {
        pointerEvents: props.readOnly ? 'none' : 'inherit',
        cursor: props.readOnly ? 'default' : 'inherit',
      };
    },
  }),
  { name: 'BooleanInput' }
);

const BooleanInput: FunctionComponent<
  InputProps<CheckboxProps> &
    Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'>
> = ({
  format,
  label,
  fullWidth,
  helperText,
  titleSuffix,
  onBlur,
  onChange,
  onFocus,
  options,
  disabled,
  parse,
  resource,
  source,
  validate,
  inputProps,
  defaultValue,
  ...rest
}) => {
  const {
    id,
    input: { onChange: finalFormOnChange, type, value, ...inputProperties },
    isRequired,
    meta: { error },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'checkbox',
    validate,
    defaultValue,
    ...rest,
  });

  const handleChange = useCallback(
    (event, value) => {
      finalFormOnChange(value);
    },
    [finalFormOnChange]
  );

  const classes = useStyles({ readOnly: rest.readOnly });

  return (
    <FormGroup {...sanitizeInputRestProps(rest)}>
      <FormControlLabel
        classes={{ root: classes.root }}
        control={
          <Checkbox
            id={id}
            color='primary'
            onChange={handleChange}
            {...inputProperties}
            {...options}
            disabled={disabled}
          />
        }
        label={
          <>
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
            {titleSuffix &&
              titleSuffix.map((s) => {
                return (
                  <Chip
                    label={s}
                    size='small'
                    style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                    key={s}
                  />
                );
              })}
          </>
        }
      />
      <FormHelperText error={!!error}>
        <InputHelperText touched={true} error={error} helperText={helperText} />
      </FormHelperText>
    </FormGroup>
  );
};

BooleanInput.propTypes = {
  // @ts-ignore
  options: PropTypes.shape(Checkbox.propTypes),
  disabled: PropTypes.bool,
};

BooleanInput.defaultProps = {
  options: {},
};

export default BooleanInput;
