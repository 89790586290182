import { formatNumericValue, Metadata } from '@dx-ui/dx-common';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import React from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { DataHelpers } from '../../../services/DataHelpers';
import { AlfrescoProperties } from '../../types';

const useStyles = makeStyles(
  (theme) => ({
    taxTotalTable: {
      borderCollapse: 'collapse',
    },
    value: {
      paddingLeft: 3,
    },
  }),
  { name: 'TaxTotals' }
);

/**
 * Adds a new row to the table dedicated to totals (edm:totalVat, edm:totalWithVat, ...).
 * record are then aligned, formatted and justified.
 */
const TotalTableRow = ({ label, record, source }) => {
  const value = get(record.properties, source);
  const currencyID = DataHelpers.getCurrencyID(record);
  const locale = useLocale();
  const classes = useStyles();

  return (
    <tr>
      <td>
        <Typography variant='caption'>{label}</Typography>
      </td>
      <td>
        <Typography>:</Typography>
      </td>
      <td className={classes.value}>
        <Typography align='right' className={source}>
          {value ? formatNumericValue(value, locale) : '--'}
        </Typography>
      </td>
      <td>
        <Typography>{currencyID}</Typography>
      </td>
    </tr>
  );
};

/**
 * Computes Tax summary Panel
 * @param {*} data formData
 */
const TaxTotals = ({ data }: { data: AlfrescoProperties }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <table className={classes.taxTotalTable}>
      <tbody>
        <TotalTableRow
          label={translate('dxMessages.headers.totalVat')}
          record={data}
          source={Metadata.totalVat}
        />
        <TotalTableRow
          label={translate('dxMessages.headers.totalWithoutVat')}
          record={data}
          source={Metadata.totalWithoutVat}
        />
        <TotalTableRow
          label={translate('dxMessages.headers.totalWithVat')}
          record={data}
          source={Metadata.totalWithVat}
        />
      </tbody>
    </table>
  );
};

export default TaxTotals;
