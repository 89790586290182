/**
 * Display the count of documents received by DocProcess per document
 * type and per day.
 */
import { gql, useQuery } from '@apollo/client';
import {
  DxTheme,
  MultiSelectInput,
  NestingMenuItem,
  sendGAEvent,
  useSetupUsage,
  widthPresets,
} from '@dx-ui/dx-common/src';
import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import { SetupUsage } from '@dx-ui/dx-common/src/utils/useSetupUsage';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { usePreferences } from '@react-admin/ra-preferences';
import { debounce, groupBy } from 'lodash';
import MaterialTable from 'material-table';
import moment, { Moment } from 'moment';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Identifier,
  SimpleForm,
  useGetIdentity,
  useLocale,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import ReactCardFlip from 'react-card-flip';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Tooltip as RcTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { GA_EVENTS } from '../../../../../utils';
import DocumentCountPerDayAndStatusDemo from '../demo/DocumentCountPerDayAndStatusDemo';

// The supported document types.
export enum DocumentType {
  INVOICE = 'INVOICE',
  ORDER = 'ORDER',
  RECADV = 'RECADV',
  DESADV = 'DESADV',
}

export enum RelationRole {
  ALL = 'ALL',
  SUPPLIER = 'SUPPLIER',
  BUYER = 'BUYER',
}

// All the document status, whatever document type.
export enum Status {
  Accepted_by_Customer = 'Accepted_by_Customer',
  Content_valid = 'Content_valid',
  Dispute = 'Dispute',
  Format_valid = 'Format_valid',
  No_credit = 'No_credit',
  No_status = 'No_status',
  Outage = 'Outage',
  Pending_Enrichments = 'Pending_Enrichments',
  Paid_by_recipient = 'Paid_by_recipient',
  Pending_to_Customer = 'Pending_to_Customer',
  Received_by_Customer = 'Received_by_Customer',
  Received_by_DX = 'Received_by_DX',
  Rejected_by_Customer = 'Rejected_by_Customer',
  Received_by_Recipient = 'Received_by_Recipient',
  Rejected_by_DX = 'Rejected_by_DX',
  Sent = 'Sent',
  Transmitted_by_DX = 'Transmitted_by_DX',
  Matching_in_Progress = 'Matching_in_Progress',
}

// The supported status per document type.
export const STATUS_PER_DOC_TYPE: Record<DocumentType, Status[]> = {
  INVOICE: [
    Status.No_status,
    Status.Sent,
    Status.Accepted_by_Customer,
    Status.Rejected_by_Customer,
    Status.Received_by_Customer,
    Status.Received_by_DX,
    Status.Rejected_by_DX,
    Status.Received_by_Recipient,
    Status.Paid_by_recipient,
    Status.Pending_to_Customer,
    Status.Matching_in_Progress,
  ],
  ORDER: [
    Status.No_status,
    Status.Sent,
    Status.Rejected_by_DX,
    Status.Received_by_DX,
    Status.Received_by_Recipient,
  ],
  RECADV: [
    Status.No_status,
    Status.Sent,
    Status.Received_by_DX,
    Status.Rejected_by_DX,
    Status.Received_by_Recipient,
  ],
  DESADV: [
    Status.No_status,
    Status.Sent,
    Status.Rejected_by_DX,
    Status.Received_by_DX,
    Status.Received_by_Recipient,
  ],
};

export interface Choices {
  id: string;
  label: string;
}

export interface Metrics {
  date: string;
  month: string;
  year: string;
  Accepted_by_Customer?: number;
  Content_valid?: number;
  Dispute?: number;
  Format_valid?: number;
  No_credit?: number;
  No_status?: number;
  Outage?: number;
  Pending_Enrichments?: number;
  Paid_by_recipient?: number;
  Pending_to_Customer?: number;
  Received_by_Customer?: number;
  Received_by_DX?: number;
  Rejected_by_Customer?: number;
  Received_by_Recipient?: number;
  Rejected_by_DX?: number;
  Sent?: number;
  Transmitted_by_DX?: number;
  Matching_in_Progress?: number;
}

export interface Table {
  count: number;
  date: string;
  percentage: string;
  relationName: string;
  status: string;
}

const I18N_KEY = 'dxMessages.dashboard.widgets.DocumentCountPerDayAndStatus';
const I18N_COMPANY = 'dxMessages.dashboard.widgets.DocumentCompany';
const I18N_WIDGET = 'dxMessages.dashboard.widgets.WidgetTitle.name';

const NON_DISPLAYABLE_STATUSES = [Status.Received_by_Customer];

/**
 * Replaces a status if there is an existing mapping.
 * @param status Status to be eventually replaced
 * @returns the Status which replaces the input one
 * if any or the input one.
 */
const exposedStatus = (status: Status): Status => {
  switch (status) {
    case Status.Received_by_Customer:
      return Status.Received_by_Recipient;
    default:
      return status;
  }
};

const DocumentCountPerDayAndStatus: FC<
  IWidgetContent & { documentType: DocumentType } & {
    relationRole: RelationRole;
  }
> = ({
  account,
  onTheShelves,
  userPreferencesRootKey,
  documentType,
  relationRole,
  openConfiguration,
}) => {
  const classes = useStyles({ onTheShelves: onTheShelves });
  const theme = useTheme();
  const translate = useTranslate();
  const [help, setHelp] = useState(false);
  const [openChild, setOpenChild] = useState(false);
  const [openAllDetails, setOpenAllDetails] = useState(false);
  const [childContext, setChildContext] = useState<Table[]>([]);
  const [selectDate, setSelectDate] = useState<string>();
  const [month, setMonth] = usePreferences(
    `${userPreferencesRootKey}.${documentType}.month`,
    moment().format('YYYY-MM')
  );
  // when we choose a way to display the dates, we want to see the text
  // in date format MMMM YYYY
  const [displayOptionDays, setDisplayOptionDays] = usePreferences(
    `${userPreferencesRootKey}.${documentType}.displayOptionDays`,
    moment(month).format('MMMM YYYY')
  );
  const [status, setStatus] = useState({});
  const onMonthChange = (e) => {
    setMonth(e.target.value);
    if (moment.months().includes(moment(e.target.value).format('MMMM'))) {
      setMonthNumber(
        moment.months().indexOf(moment(e.target.value).format('MMMM'))
      );
    }
    setSaveYear(moment(e.target.value).format('YYYY'));
    setChartData([]);
    setResultRange([]);
    setRangeDates([]);
    setDisplayOptionDays(moment(e.target.value).format('MMMM YYYY'));
    setRangeValue('');
    setCombinedDateAndTypes({});
  };
  const setupUsage = useSetupUsage();
  // date and types for the days in the current month
  const [companyDateAndTypes, setCompanyDateAndTypes] = useState({});
  // date and types for the months
  const [monthsDateAndTypes, setMonthsDateAndTypes] = useState({});
  // date and types for years
  const [yearsDateAndTypes, setYearsDateAndTypes] = useState({});
  // list of companies used in monthly view
  const [monthlyCompanies, setMonthlyCompanies] = useState<string[]>([]);
  // list of companies used in yearly view
  const [yearlyCompanies, setYearlyCompanies] = useState<string[]>([]);
  // the table data for months details per company
  const [tableDataMonth, setTabelDataMonth] = useState<Table[]>([]);
  // the table data for years details per company
  const [tableDataYears, setTabelDataYears] = useState<Table[]>([]);
  // the table data for an entire year
  const [tableMonths, setTableMonths] = useState<Table[]>([]);
  // the table data for all years in chart
  const [tableYears, setTableYears] = useState<Table[]>([]);
  // the monthly metrics for a filtered company
  const [monthlyCompanyMetrics, setMonthlyCompanyMetrics] = useState<Metrics[]>(
    []
  );
  // the yearly metrics for a filtered company
  const [yearlyCompanyMetrics, setYearlyCompanyMetrics] = useState<Metrics[]>(
    []
  );
  // list of choices used in monthly filtering by company
  const [monthlyChoices, setMonthlyChoices] = useState<Choices[]>([]);
  // list of choices used in yearly filtering by company
  const [yearlyChoices, setYearlyChoices] = useState<Choices[]>([]);
  // date and types for the days in the previous month
  const [pastCompanyDateAndTypes, setPastCompanyDateAndTypes] = useState({});
  // original metrics with relationName parameter
  const [chartMetrics, setChartMetrics] = useState<Metrics[]>([]);
  // list with all the partners of the company user
  const [companies, setCompanies] = useState<string[]>([]);
  // company choices for company filter
  const [choices, setChoices] = useState<Choices[]>([]);
  const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
  // details per company for an entire month
  const [detailsPerMonth, setDetailsPerMonth] = useState<any>();
  // array with selected companies from filter
  const [companyPreferences, setCompaniesPreferences] = usePreferences<any>(
    `${userPreferencesRootKey}.company`,
    null
  );
  // differentiate the browsers we use
  const userAgent = navigator.userAgent;
  // save the number of a month
  const [monthNumber, setMonthNumber] = usePreferences(
    `${userPreferencesRootKey}.monthNumber`
  );
  // save the name of the month
  const [selectedMonth, setSelectedMonth] = useState('');
  // year picker for mozilla and safari
  const [saveYear, setSaveYear] = usePreferences(
    `${userPreferencesRootKey}.saveYear`,
    moment().format('YYYY')
  );
  // object based on company relation merged on current and previous month
  const [combinedDateAndTypes, setCombinedDateAndTypes] = useState({});
  // year value in years view
  const [selectedYear, setSelectedYear] = usePreferences(
    `${userPreferencesRootKey}.selectedYear`,
    moment().format('YYYY')
  );
  // for safari and mozilla firefox type month is not working
  // so we will use a button that will open a popover
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const openPopover = Boolean(anchor);
  const id = openPopover ? 'simple-popover' : undefined;
  const [monthSelected, setMonthSelected] = useState();
  const [yearNumber, setYearNumber] = useState();

  const listButton = translate('dxMessages.dashboard.widgets.ListButton.title');
  const titleMonthTable = `${translate(
    'dxMessages.dashboard.widgets.SummaryTitle.title'
  )}`;
  const displayLabel = translate('dxMessages.dashboard.widgets.Display.title');
  const dateRangeLabel = translate(
    'dxMessages.dashboard.widgets.DateRange.title'
  );
  const daysLabel = translate('dxMessages.dashboard.widgets.Display.days');
  const monthsLabel = translate('dxMessages.dashboard.widgets.Display.months');
  const yearsLabel = translate('dxMessages.dashboard.widgets.Display.years');
  const last7Label = translate(
    'dxMessages.dashboard.widgets.DateRange.last7Days'
  );
  const last14Label = translate(
    'dxMessages.dashboard.widgets.DateRange.last14Days'
  );
  const thisMonthLabel = translate(
    'dxMessages.dashboard.widgets.DateRange.thisMonth'
  );
  const lastMonthLabel = translate(
    'dxMessages.dashboard.widgets.DateRange.lastMonth'
  );
  const selectMonthLabel = translate(
    'dxMessages.dashboard.widgets.DateRange.selectMonth'
  );
  const thisQuarterLabel = translate(
    'dxMessages.dashboard.widgets.DateRange.thisQuarter'
  );
  const lastQuarterLabel = translate(
    'dxMessages.dashboard.widgets.DateRange.lastQuarter'
  );
  const thisYearLabel = translate(
    'dxMessages.dashboard.widgets.DateRange.thisYear'
  );
  const lastYearLabel = translate(
    'dxMessages.dashboard.widgets.DateRange.lastYear'
  );
  const last3YearsLabel = translate(
    'dxMessages.dashboard.widgets.DateRange.last3Years'
  );
  const selectYearLabel = translate(
    'dxMessages.dashboard.widgets.DateRange.selectYear'
  );
  const title = `${translate(`${I18N_COMPANY}.${documentType}`)} ${selectDate}`;
  const widgetTitle = translate(I18N_WIDGET);
  const tableLabel = translate(
    'dxMessages.dashboard.widgets.TableButton.title'
  );
  // data for days as we have in chart
  const [chartData, setChartData] = useState<Metrics[]>([]);
  // value of the selection display for months
  const [rangeValue, setRangeValue] = usePreferences(
    `${userPreferencesRootKey}.${documentType}.rangeValue`,
    ''
  );
  // range view: days, months or years
  const [option, setOption] = usePreferences(
    `${userPreferencesRootKey}.${documentType}.option`,
    'days'
  );

  // process data for showing documents by company
  const setupMaterialTable = (value) => {
    const dateFormat = new Date(Object.keys(value).toString());
    const date = moment(dateFormat).format('MMMM Do, YYYY');
    const dateProduction = moment(Object.keys(value).toString()).format(
      'MMMM Do, YYYY'
    );
    setupUsage === SetupUsage.DEMO
      ? setSelectDate(date)
      : setSelectDate(dateProduction);
    const relationNames: string[] = [];
    const countByRelations = {};
    const statuses = {};
    let sum = 0;
    const selection: Table[] = [];
    value[`${Object.keys(value)}`].forEach((data) => {
      filterStatuses.forEach((status) => {
        if (companyPreferences === null) {
          if (data.status === status) {
            selection.push(data);
          }
        } else {
          if (companyPreferences.length === 0) {
            if (data.status === status) {
              selection.push(data);
            }
          } else {
            companyPreferences.forEach((partener) => {
              if (data.status === status && data.relationName === partener) {
                selection.push(data);
              }
            });
          }
        }
      });
    });
    let sortedData = selection.sort((a, b) =>
      b.relationName > a.relationName
        ? 1
        : a.relationName > b.relationName
        ? -1
        : 0
    );
    value[`${Object.keys(value)}`].forEach((relation) => {
      if (!relationNames.includes(relation.relationName)) {
        relationNames.push(relation.relationName);
      }
    });
    // eslint-disable-next-line array-callback-return
    relationNames.some((name) => {
      value[`${Object.keys(value)}`].forEach((company) => {
        if (name === company.relationName) {
          sum += company.count;
        }
      });
      countByRelations[name] = sum;
      sum = 0;
    });
    sortedData.forEach((arr) => {
      arr['percentage'] =
        Math.round((arr.count / countByRelations[arr.relationName]) * 100) +
        '%';
    });
    sortedData.forEach((data) => {
      statuses[data.status] = translate(`${I18N_KEY}.status.${data.status}`);
    });
    setStatus(statuses);
    setChildContext(sortedData);
    setOpenChild(true);
  };

  const onClickOpenChild = (event) => {
    setupMaterialTable(event);
  };

  // process data for monthly document details per company
  const monthlyTable = (month) => {
    setMonthSelected(month);
    const arrayMonths: Table[] = [];
    const sumObject: any = {};
    const statuses = {};
    monthsDateAndTypes[month].forEach((row) => {
      row.status = exposedStatus(row.status);
      arrayMonths.push(row);
    });
    const combinedArray: Table[] = Object.values(
      arrayMonths.reduce((accumulator, currentObject) => {
        const key = currentObject.status + currentObject.relationName;
        if (!accumulator[key]) {
          accumulator[key] = { ...currentObject };
        } else {
          accumulator[key].count = accumulator[key].count + currentObject.count;
        }
        return accumulator;
      }, {})
    );
    monthlyCompanies.forEach((company) => {
      let sum = 0;
      combinedArray.forEach((row: any) => {
        if (company === row.relationName) {
          sum += row.count;
        }
      });
      sumObject[company] = sum;
    });
    combinedArray.forEach((array: any) => {
      array.percentage =
        Math.round((array.count / sumObject[array.relationName]) * 100) + '%';
    });
    combinedArray.forEach((data: any) => {
      statuses[data.status] = translate(`${I18N_KEY}.status.${data.status}`);
    });
    const result = combinedArray
      .filter((array: any) => {
        if (companyPreferences !== null) {
          return companyPreferences.includes(array.relationName);
        } else {
          return array;
        }
      })
      .filter((data: any) => {
        if (filterStatuses !== null) {
          return filterStatuses.includes(data.status);
        } else {
          return data.status;
        }
      });
    setStatus(statuses);
    setTabelDataMonth(result);
    setOpenChild(true);
  };

  // table with the details per company for a specific month
  const materialTableMonth = (
    <Grid>
      <MaterialTable
        title={titleMonthTable + ' ' + moment(monthSelected).format('MMMM')}
        columns={[
          {
            title: translate('dxMessages.dashboard.widgets.Columns.company'),
            field: 'relationName',
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.status'),
            field: 'status',
            lookup: status,
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.count'),
            field: 'count',
            type: 'numeric',
            filtering: false,
          },
          {
            title: '%',
            field: 'percentage',
            filtering: false,
            sorting: false,
          },
        ]}
        data={tableDataMonth}
        options={{
          exportButton: true,
          paging: false,
          maxBodyHeight: 400,
          search: false,
          filtering: true,
        }}
      />
    </Grid>
  );

  // process data for the details per company for the entire year
  const showTableMonth = () => {
    const arrayMonths: Table[] = [];
    const sumObject: any = {};
    const statuses = {};
    Object.keys(monthsDateAndTypes).forEach((month) => {
      monthsDateAndTypes[month].forEach((row) => {
        row.status = exposedStatus(row.status);
        arrayMonths.push(row);
      });
    });
    const combinedArray: Table[] = Object.values(
      arrayMonths.reduce((accumulator, currentObject) => {
        const key = currentObject.status + currentObject.relationName;
        if (!accumulator[key]) {
          accumulator[key] = { ...currentObject };
        } else {
          accumulator[key].count = accumulator[key].count + currentObject.count;
        }
        return accumulator;
      }, {})
    );
    monthlyCompanies.forEach((company) => {
      let sum = 0;
      combinedArray.forEach((row: any) => {
        if (company === row.relationName) {
          sum += row.count;
        }
      });
      sumObject[company] = sum;
    });
    combinedArray.forEach((array: any) => {
      array.percentage =
        Math.round((array.count / sumObject[array.relationName]) * 100) + '%';
    });
    combinedArray.forEach((data: any) => {
      statuses[data.status] = translate(`${I18N_KEY}.status.${data.status}`);
    });
    const result = combinedArray
      .filter((array: any) => {
        if (companyPreferences !== null) {
          return companyPreferences.includes(array.relationName);
        } else {
          return array;
        }
      })
      .filter((data: any) => {
        if (filterStatuses !== null) {
          return filterStatuses.includes(data.status);
        } else {
          return data.status;
        }
      });
    setTableMonths(result);
    setStatus(statuses);
    setOpenAllDetails(true);
  };

  // table with the details per company for an entire year
  const materialTableMonthsSummary = (
    <Grid>
      <MaterialTable
        title={
          rangeValue === ''
            ? titleMonthTable + ' ' + selectedYear
            : rangeValue === 'thisQuarter'
            ? titleMonthTable + ' ' + thisQuarterLabel.toLowerCase()
            : rangeValue === 'lastQuarter'
            ? titleMonthTable + ' ' + lastQuarterLabel.toLowerCase()
            : rangeValue === 'thisYear'
            ? titleMonthTable + ' ' + thisYearLabel.toLowerCase()
            : titleMonthTable + ' ' + lastYearLabel.toLowerCase()
        }
        columns={[
          {
            title: translate('dxMessages.dashboard.widgets.Columns.company'),
            field: 'relationName',
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.status'),
            field: 'status',
            lookup: status,
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.count'),
            field: 'count',
            type: 'numeric',
            filtering: false,
          },
          {
            title: '%',
            field: 'percentage',
            filtering: false,
            sorting: false,
          },
        ]}
        data={tableMonths}
        options={{
          exportButton: true,
          paging: false,
          maxBodyHeight: 400,
          search: false,
          filtering: true,
        }}
      />
    </Grid>
  );

  // process data for the details per company for an year
  const yearlyTable = (year) => {
    setYearNumber(year);
    const arrayYears: Table[] = [];
    const sumObject: any = {};
    const statuses = {};
    yearsDateAndTypes[year].forEach((row) => {
      row.status = exposedStatus(row.status);
      arrayYears.push(row);
    });
    const combinedArray: Table[] = Object.values(
      arrayYears.reduce((accumulator, currentObject) => {
        const key = currentObject.status + currentObject.relationName;
        if (!accumulator[key]) {
          accumulator[key] = { ...currentObject };
        } else {
          accumulator[key].count = accumulator[key].count + currentObject.count;
        }
        return accumulator;
      }, {})
    );
    yearlyCompanies.forEach((company) => {
      let sum = 0;
      combinedArray.forEach((row: any) => {
        if (company === row.relationName) {
          sum += row.count;
        }
      });
      sumObject[company] = sum;
    });
    combinedArray.forEach((array: any) => {
      array.percentage =
        Math.round((array.count / sumObject[array.relationName]) * 100) + '%';
    });
    combinedArray.forEach((data: any) => {
      statuses[data.status] = translate(`${I18N_KEY}.status.${data.status}`);
    });
    const result = combinedArray
      .filter((array: any) => {
        if (companyPreferences !== null) {
          return companyPreferences.includes(array.relationName);
        } else {
          return array;
        }
      })
      .filter((data: any) => {
        if (filterStatuses !== null) {
          return filterStatuses.includes(data.status);
        } else {
          return data.status;
        }
      });
    setStatus(statuses);
    setTabelDataYears(result);
    setOpenChild(true);
  };

  // table with the details per company for a specific year
  const materialTableYears = (
    <Grid>
      <MaterialTable
        title={titleMonthTable + ' ' + yearNumber}
        columns={[
          {
            title: translate('dxMessages.dashboard.widgets.Columns.company'),
            field: 'relationName',
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.status'),
            field: 'status',
            lookup: status,
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.count'),
            field: 'count',
            type: 'numeric',
            filtering: false,
          },
          {
            title: '%',
            field: 'percentage',
            filtering: false,
            sorting: false,
          },
        ]}
        data={tableDataYears}
        options={{
          exportButton: true,
          paging: false,
          maxBodyHeight: 400,
          search: false,
          filtering: true,
        }}
      />
    </Grid>
  );

  // process data for the details per company for the last 3 years
  const showTableYear = () => {
    const arrayYears: Table[] = [];
    const sumObject: any = {};
    const statuses = {};
    Object.keys(yearsDateAndTypes).forEach((year) => {
      yearsDateAndTypes[year].forEach((row) => {
        row.status = exposedStatus(row.status);
        arrayYears.push(row);
      });
    });
    const combinedArray: Table[] = Object.values(
      arrayYears.reduce((accumulator, currentObject) => {
        const key = currentObject.status + currentObject.relationName;
        if (!accumulator[key]) {
          accumulator[key] = { ...currentObject };
        } else {
          accumulator[key].count = accumulator[key].count + currentObject.count;
        }
        return accumulator;
      }, {})
    );
    yearlyCompanies.forEach((company) => {
      let sum = 0;
      combinedArray.forEach((row: any) => {
        if (company === row.relationName) {
          sum += row.count;
        }
      });
      sumObject[company] = sum;
    });
    combinedArray.forEach((array: any) => {
      array.percentage =
        Math.round((array.count / sumObject[array.relationName]) * 100) + '%';
    });
    combinedArray.forEach((data: any) => {
      statuses[data.status] = translate(`${I18N_KEY}.status.${data.status}`);
    });
    const result = combinedArray
      .filter((array: any) => {
        if (companyPreferences !== null) {
          return companyPreferences.includes(array.relationName);
        } else {
          return array;
        }
      })
      .filter((data: any) => {
        if (filterStatuses !== null) {
          return filterStatuses.includes(data.status);
        } else {
          return data.status;
        }
      });
    setTableYears(result);
    setStatus(statuses);
    setOpenAllDetails(true);
  };

  // table with the details per company for an entire year
  const materialTableYearsSummary = (
    <Grid>
      <MaterialTable
        title={titleMonthTable + ' "' + last3YearsLabel.toLowerCase() + '"'}
        columns={[
          {
            title: translate('dxMessages.dashboard.widgets.Columns.company'),
            field: 'relationName',
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.status'),
            field: 'status',
            lookup: status,
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.count'),
            field: 'count',
            type: 'numeric',
            filtering: false,
          },
          {
            title: '%',
            field: 'percentage',
            filtering: false,
            sorting: false,
          },
        ]}
        data={tableYears}
        options={{
          exportButton: true,
          paging: false,
          maxBodyHeight: 400,
          search: false,
          filtering: true,
        }}
      />
    </Grid>
  );

  // get the choices for the daily view filter by company
  useEffect(() => {
    if (option === 'days') {
      const companiesArray: string[] = [];
      const choicesArray: Choices[] = [];
      Object.keys(companyDateAndTypes).forEach((day) => {
        companyDateAndTypes[day].forEach((row) => {
          if (!companiesArray.includes(row.relationName)) {
            companiesArray.push(row.relationName);
            choicesArray.push({
              id: row.relationName,
              label: row.relationName,
            });
          }
        });
      });
      setCompanies(companiesArray);
      setChoices(choicesArray);
    }
  }, [companyDateAndTypes, option]);

  // process data from filtering daily chart by company
  const setUpChart = useCallback(
    (value) => {
      if (value !== null) {
        if (value.length !== 0) {
          const result =
            rangeValue === '7days' || rangeValue === '14days'
              ? Object.keys(combinedDateAndTypes).map((date) =>
                  combinedDateAndTypes[date].reduce((acc, t) => {
                    value.forEach((value) => {
                      if (t.relationName === value) {
                        acc.date = date;
                        acc[exposedStatus(t.status)] =
                          t.count + (acc[exposedStatus(t.status)] || 0);
                      }
                    });
                    return acc;
                  }, {})
                )
              : Object.keys(companyDateAndTypes).map((date) =>
                  companyDateAndTypes[date].reduce((acc, t) => {
                    value.forEach((value) => {
                      if (t.relationName === value) {
                        acc.date = date;
                        acc[exposedStatus(t.status)] =
                          t.count + (acc[exposedStatus(t.status)] || 0);
                      }
                    });
                    return acc;
                  }, {})
                );
          const metricsPerCompany = result.filter((element) => {
            if (Object.keys(element).length !== 0) {
              return true;
            }
            return false;
          });
          setChartMetrics(metricsPerCompany);
        }
      } else {
        setChartMetrics([]);
      }
    },
    [combinedDateAndTypes, companyDateAndTypes, rangeValue]
  );

  // get the choices for the monthly view filter by company
  useEffect(() => {
    if (option === 'months') {
      const companiesArray: string[] = [];
      const choicesArray: Choices[] = [];
      Object.keys(monthsDateAndTypes).forEach((month) => {
        monthsDateAndTypes[month].forEach((row) => {
          if (!companiesArray.includes(row.relationName)) {
            companiesArray.push(row.relationName);
            choicesArray.push({
              id: row.relationName,
              label: row.relationName,
            });
          }
        });
      });
      setMonthlyCompanies(companiesArray);
      setMonthlyChoices(choicesArray);
    }
  }, [monthsDateAndTypes, option]);

  // process data from filtering monthly chart by company
  const filterCompaniesMonthly = useCallback(
    (values) => {
      const arrayFilteredRows: Metrics[] = [];
      let result: Metrics[] = [];
      if (values !== null) {
        Object.keys(monthsDateAndTypes).forEach((month) => {
          arrayFilteredRows.push(
            monthsDateAndTypes[month].reduce((accumulator, currentValue) => {
              values.forEach((value) => {
                if (currentValue.relationName === value) {
                  accumulator.date = currentValue.month;
                  accumulator[exposedStatus(currentValue.status)] =
                    currentValue.count +
                    (accumulator[exposedStatus(currentValue.status)] || 0);
                }
              });
              return accumulator;
            }, {})
          );
        });
        result = arrayFilteredRows.filter((obj) => Object.keys(obj).length > 0);
      }
      setMonthlyCompanyMetrics(result);
    },
    [monthsDateAndTypes]
  );

  // get the choices for the yearly view filter by company
  useEffect(() => {
    if (option === 'years') {
      const companiesArray: string[] = [];
      const choicesArray: Choices[] = [];
      Object.keys(yearsDateAndTypes).forEach((year) => {
        yearsDateAndTypes[year].forEach((row) => {
          if (!companiesArray.includes(row.relationName)) {
            companiesArray.push(row.relationName);
            choicesArray.push({
              id: row.relationName,
              label: row.relationName,
            });
          }
        });
      });
      setYearlyCompanies(companiesArray);
      setYearlyChoices(choicesArray);
    }
  }, [option, yearsDateAndTypes]);

  // process data from filtering yearly chart by company
  const filterCompaniesYearly = useCallback(
    (values) => {
      const arrayFilteredRows: Metrics[] = [];
      let result: Metrics[] = [];
      if (values !== null) {
        Object.keys(yearsDateAndTypes).forEach((year) => {
          arrayFilteredRows.push(
            yearsDateAndTypes[year].reduce((accumulator, currentValue) => {
              values.forEach((value) => {
                if (currentValue.relationName === value) {
                  accumulator.date = currentValue.year;
                  accumulator[exposedStatus(currentValue.status)] =
                    currentValue.count +
                    (accumulator[exposedStatus(currentValue.status)] || 0);
                }
              });
              return accumulator;
            }, {})
          );
        });
        result = arrayFilteredRows.filter((obj) => Object.keys(obj).length > 0);
      }
      setYearlyCompanyMetrics(result);
    },
    [yearsDateAndTypes]
  );

  // event click for company drop-down filter
  const handleClick = (event) => {
    if (option === 'years') {
      setCompaniesPreferences(event);
      filterCompaniesYearly(event);
    } else if (option === 'days') {
      setCompaniesPreferences(event);
      setUpChart(event);
    } else if (option === 'months') {
      setCompaniesPreferences(event);
      filterCompaniesMonthly(event);
    }
  };

  // the customized new title of a widget
  const [customizedTitle, setCustomizedTitle] = usePreferences(
    `${userPreferencesRootKey}.${documentType}.titleName`,
    translate(`${I18N_KEY}.${documentType}.title`)
  );

  // the change event for the title input filed
  const handleChange = (event) => {
    setCustomizedTitle(event.target.value);
  };

  // table with the details per company for a specific day
  const table = (
    <Grid>
      <MaterialTable
        title={title}
        columns={[
          {
            title: translate('dxMessages.dashboard.widgets.Columns.company'),
            field: 'relationName',
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.status'),
            field: 'status',
            lookup: status,
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.count'),
            field: 'count',
            type: 'numeric',
            filtering: false,
          },
          {
            title: '%',
            field: 'percentage',
            filtering: false,
            sorting: false,
          },
        ]}
        data={childContext}
        options={{
          exportButton: true,
          paging: false,
          maxBodyHeight: 400,
          search: false,
          filtering: true,
        }}
      />
    </Grid>
  );

  // get a list with all DocProcess years
  const currentYear = new Date().getFullYear();
  const [firstYear, setFirstYear] = useState<number>(2005);
  const [arrayYears, setArrayYears] = useState<number[]>([]);
  // data-range result
  const [resultRange, setResultRange] = useState<Metrics[]>([]);
  // array with all the dates we choose to filter
  const [rangeDates, setRangeDates] = useState<string[]>([]);

  // we will need at some point to find some values in both
  // the current month and previous month
  const [lastMonthMetrics, setLastMonthMetrics] = useState<Metrics[]>([]);

  // effect hook for defining the array with years of DocProcess
  useEffect(() => {
    if (currentYear >= firstYear) {
      setArrayYears((arrayYears) => [...arrayYears, firstYear]);
      setFirstYear(firstYear + 1);
    } else {
      setArrayYears(arrayYears.reverse());
    }
  }, [arrayYears, currentYear, firstYear]);

  // filter the data for the specific dates corresponding to last 7 days array
  const handle7Days = () => {
    setRangeValue('7days');
    setDisplayOptionDays(selectMonthLabel);
    setCombinedDateAndTypes({});
    if (resultRange.length !== 0) {
      setResultRange([]);
    }
    if (month !== moment().format('YYYY-MM')) {
      setChartData([]);
      setMonth(moment().format('YYYY-MM'));
    }
    const array7Days: string[] = [];
    for (let i = 0; i < 7; i++) {
      setupUsage === SetupUsage.PRODUCTION
        ? array7Days.push(moment().subtract(i, 'days').format('YYYYMMDD'))
        : array7Days.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    }
    if (array7Days.length !== 0) setRangeDates(array7Days);
    setMonthNumber(moment.months().indexOf(moment().format('MMMM')));
    setSaveYear(moment().format('YYYY'));
    setSelectedMonth(moment().format('MMMM'));
  };

  // filter the data for the specific dates corresponding to last 14 days array
  const handle14Days = () => {
    setRangeValue('14days');
    setDisplayOptionDays(selectMonthLabel);
    setCombinedDateAndTypes({});
    if (resultRange.length !== 0) {
      setResultRange([]);
    }
    if (month !== moment().format('YYYY-MM')) {
      setChartData([]);
      setMonth(moment().format('YYYY-MM'));
    }
    const array14Days: string[] = [];
    for (let i = 0; i < 14; i++) {
      setupUsage === SetupUsage.PRODUCTION
        ? array14Days.push(moment().subtract(i, 'days').format('YYYYMMDD'))
        : array14Days.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    }
    setRangeDates(array14Days);
    setMonthNumber(moment.months().indexOf(moment().format('MMMM')));
    setSaveYear(moment().format('YYYY'));
    setSelectedMonth(moment().format('MMMM'));
  };

  // set the month to current month
  const handleThisMonth = () => {
    setRangeDates([]);
    if (month !== moment().format('YYYY-MM')) {
      setChartData([]);
      setMonth(moment().format('YYYY-MM'));
    }
    setDisplayOptionDays(selectMonthLabel);
    setMonth(moment().format('YYYY-MM'));
    setRangeValue('thisMonth');
    setCombinedDateAndTypes({});
    setMonthNumber(moment.months().indexOf(moment().format('MMMM')));
    setSaveYear(moment().format('YYYY'));
    setSelectedMonth(moment().format('MMMM'));
  };

  // set the month to previous one
  const handleLastMonth = () => {
    setRangeValue('lastMonth');
    setDisplayOptionDays(selectMonthLabel);
    setRangeDates([]);
    if (month !== moment().subtract(1, 'months').format('YYYY-MM')) {
      setChartData([]);
      setMonth(moment().subtract(1, 'months').format('YYYY-MM'));
    }
    setCombinedDateAndTypes({});
    setMonthNumber(
      moment.months().indexOf(moment().subtract(1, 'months').format('MMMM'))
    );
    setSaveYear(moment().subtract(1, 'months').format('YYYY'));
    setSelectedMonth(moment().subtract(1, 'months').format('MMMM'));
  };

  // event click in safari/mozilla changing month
  const handleMonth = (event: any) => {
    setSelectedMonth(event.target.value);
    setMonthNumber(moment.months().indexOf(event.target.value));
    setChartData([]);
    setResultRange([]);
    setRangeDates([]);
    setDisplayOptionDays(
      moment(moment.months().indexOf(event.target.value) + 1, 'M').format(
        'MMMM'
      ) +
        ' ' +
        saveYear
    );
    setRangeValue('');
    setCombinedDateAndTypes({});
  };

  // event click in safari/mozilla changing year
  const handleYear = (event: any) => {
    setSaveYear(event.target.value);
    setChartData([]);
    setResultRange([]);
    setRangeDates([]);
    setDisplayOptionDays(
      moment(monthNumber + 1, 'M').format('MMMM') + ' ' + event.target.value
    );
    setRangeValue('');
    setCombinedDateAndTypes({});
  };

  // saving options into user preference account is not enough
  // we need also to execute the instructions related to the choice
  useEffect(() => {
    if (rangeValue === '7days') {
      if (chartData.length !== 0) return handle7Days();
    } else if (rangeValue === '14days') {
      if (chartData.length !== 0) return handle14Days();
    } else if (rangeValue === 'thisMonth') {
      return handleThisMonth();
    } else if (rangeValue === 'lastMonth') {
      return handleLastMonth();
    }
    if (option === 'months') {
      if (rangeValue === 'thisQuarter') {
        return handleThisQuarter();
      } else if (rangeValue === 'lastQuarter') {
        return handleLastQuarter();
      } else if (rangeValue === 'lastYear') {
        return handleLastYear();
      } else if (rangeValue === 'thisYear') {
        return handleThisYear();
      } else {
        return handleYearChange(selectedYear);
      }
    }
    if (option === 'years') {
      if (rangeValue === 'last3Years') {
        return handleYearsOption();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeValue, chartData]);

  // event when clicking to date button for safari/mozilla date picker
  const changeMonth = (event: any) => {
    setAnchor(event.currentTarget);
  };

  // props for the drop down list selection
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 165,
        width: 150,
      },
    },
  };
  const handleClose = () => {
    setAnchor(null);
  };

  // if we have the case where last 7/14 days are dates containing two different months
  // we will save in effect hook the data for current month
  useEffect(() => {
    if (option === 'days') {
      rangeDates.forEach((range) => {
        if (chartData.length !== 0) {
          chartData.forEach((days) => {
            if (days.date === range) {
              if (!resultRange.includes(days))
                setResultRange([...resultRange, days]);
            }
          });
        }
        if (setupUsage === SetupUsage.PRODUCTION)
          if (lastMonthMetrics.length !== 0)
            lastMonthMetrics.forEach((days) => {
              if (days.date === range) {
                if (!resultRange.includes(days))
                  setResultRange([...resultRange, days]);
              }
            });
      });
      if (rangeDates.length === 0 && resultRange.length !== 0) {
        setResultRange([]);
      }
    }
  }, [
    chartData,
    lastMonthMetrics,
    option,
    rangeDates,
    resultRange,
    setupUsage,
  ]);

  // effect hook for date picker in case of mozilla and safari
  useEffect(() => {
    if (!userAgent.match(/chrome/i)) {
      if (displayOptionDays !== selectMonthLabel) {
        const monthName = moment.months()[monthNumber];
        monthName !== undefined
          ? setMonth(saveYear + '-' + moment(monthName, 'MMMM').format('MM'))
          : setMonth(saveYear + '-' + moment().format('MM'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    displayOptionDays,
    monthNumber,
    saveYear,
    selectedMonth,
    setMonth,
    setMonthNumber,
    userAgent,
  ]);
  const currentDate = new Date();
  const currentYearProps = currentDate.getFullYear();
  const currentMonthProps = currentDate.getMonth() + 1;

  // the choices for the display of day selection
  const arrayDays = [
    <MenuItem key='7days' value='7days' onClick={handle7Days}>
      {last7Label}
    </MenuItem>,
    <MenuItem key='14days' value='14days' onClick={handle14Days}>
      {last14Label}
    </MenuItem>,
    <MenuItem key='thisMonth' value='thisMonth' onClick={handleThisMonth}>
      {thisMonthLabel}
    </MenuItem>,
    <MenuItem key='lastMonth' value='lastMonth' onClick={handleLastMonth}>
      {lastMonthLabel}
    </MenuItem>,
    <NestingMenuItem
      value={displayOptionDays}
      key='displayOptionDays'
      menuItems={
        userAgent.match(/chrome/i) ? (
          [
            <TextField
              id='to'
              type='month'
              size='small'
              defaultValue={month}
              inputProps={{
                max: `${currentYearProps}-${currentMonthProps
                  .toString()
                  .padStart(2, '0')}`, // Set the max attribute to disable future months
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onMonthChange}
              // When it's open the configuration panel, diasble the month selection.
              disabled={onTheShelves}
              required
              key='to_month'
            />,
          ]
        ) : (
          <>
            <TextField
              id='to'
              label={translate(`${I18N_KEY}.during`)}
              type='button'
              size='small'
              value={moment(month, 'YYYY-MM').format('MMMM YYYY')}
              InputLabelProps={{
                shrink: true,
              }}
              onClick={changeMonth}
              // When it's open the configuration panel, diasble the month selection.
              disabled={onTheShelves}
              required
              key='to_month'
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchor}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <FormControl variant='standard' className={classes.form}>
                <InputLabel id='month-picker' className={classes.date}>
                  {translate('dxMessages.dashboard.widgets.Date.month')}
                </InputLabel>
                <Select
                  labelId='month-picker'
                  id='month-picker'
                  value={
                    selectedMonth.length !== 0
                      ? selectedMonth
                      : monthNumber !== undefined
                      ? moment.months()[monthNumber]
                      : moment().format('MMMM')
                  }
                  onChange={handleMonth}
                  label='Month Picker'
                  MenuProps={MenuProps}
                >
                  {moment.months().map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant='standard' className={classes.form}>
                <InputLabel id='year-picker' className={classes.date}>
                  {translate('dxMessages.dashboard.widgets.Date.year')}
                </InputLabel>
                <Select
                  labelId='year-picker'
                  id='year-picker'
                  value={saveYear}
                  onChange={handleYear}
                  label='Year Picker'
                  MenuProps={MenuProps}
                >
                  {arrayYears.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Popover>
          </>
        )
      }
    >
      {displayOptionDays}
    </NestingMenuItem>,
  ];
  // in monthly graphql we need to pass the start and
  // the end of the month we want to display and also the start and the
  // end of the year all of them of type integer
  const [startMonth, setStartMonth] = useState<number>(
    parseInt(moment().startOf('year').format('M'))
  );
  const [endMonth, setEndMonth] = useState<number>(
    parseInt(moment().endOf('year').format('M'))
  );
  const [startYear, setStartYear] = useState<number>(
    parseInt(moment().startOf('year').format('YYYY'))
  );
  const [endYear, setEndYear] = useState<number>(
    parseInt(moment().endOf('year').format('YYYY'))
  );
  const [inputStartYear, setInputStartYear] = useState(
    parseInt(moment().subtract(2, 'years').startOf('year').format('YYYY'))
  );
  const [inputEndYear, setInputEndYear] = useState(
    parseInt(moment().endOf('year').format('YYYY'))
  );

  // set the parameters of time to the current quarter
  const handleThisQuarter = () => {
    setSelectedYear(selectYearLabel);
    setRangeDates([]);
    setRangeValue('thisQuarter');
    setStartMonth(
      moment().quarter(moment().quarter()).startOf('quarter').month() + 1
    );
    setEndMonth(
      moment().quarter(moment().quarter()).endOf('quarter').month() + 1
    );
    setStartYear(parseInt(moment().startOf('year').format('YYYY')));
    setEndYear(parseInt(moment().endOf('year').format('YYYY')));
  };

  // set the parameters of time to the previous quarter, if we are in the first quarter
  // the previous one is the last quarter of last year
  const handleLastQuarter = () => {
    setSelectedYear(selectYearLabel);
    setRangeDates([]);
    setRangeValue('lastQuarter');
    if (moment().quarter() > 1) {
      setStartMonth(
        moment()
          .quarter(moment().quarter() - 1)
          .startOf('quarter')
          .month() + 1
      );
      setEndMonth(
        moment()
          .quarter(moment().quarter() - 1)
          .endOf('quarter')
          .month() + 1
      );
      setStartYear(parseInt(moment().startOf('year').format('YYYY')));
      setEndYear(parseInt(moment().endOf('year').format('YYYY')));
    } else {
      setStartMonth(moment().quarter(4).startOf('quarter').month() + 1);
      setEndMonth(moment().quarter(4).endOf('quarter').month() + 1);
      setStartYear(
        parseInt(moment().subtract(1, 'year').startOf('year').format('YYYY'))
      );
      setEndYear(
        parseInt(moment().subtract(1, 'year').endOf('year').format('YYYY'))
      );
    }
  };

  // set the parameters of time to the current year
  const handleThisYear = () => {
    setSelectedYear(selectYearLabel);
    setRangeDates([]);
    setRangeValue('thisYear');
    setStartMonth(parseInt(moment().startOf('year').format('M')));
    setEndMonth(parseInt(moment().endOf('year').format('M')));
    setStartYear(parseInt(moment().startOf('year').format('YYYY')));
    setEndYear(parseInt(moment().endOf('year').format('YYYY')));
  };

  // set the parameters of time to the previous year
  const handleLastYear = () => {
    setSelectedYear(selectYearLabel);
    setRangeDates([]);
    setRangeValue('lastYear');
    setStartMonth(parseInt(moment().startOf('year').format('M')));
    setEndMonth(parseInt(moment().endOf('year').format('M')));
    setStartYear(
      parseInt(moment().subtract(1, 'year').startOf('year').format('YYYY'))
    );
    setEndYear(
      parseInt(moment().subtract(1, 'year').endOf('year').format('YYYY'))
    );
  };

  // event for selecting a year in the months view
  const handleYearChange = (event) => {
    if (event !== selectYearLabel) {
      setSelectedYear(event);
      setRangeDates([]);
      setRangeValue('');
      setStartMonth(parseInt(moment(event).startOf('year').format('M')));
      setEndMonth(parseInt(moment(event).endOf('year').format('M')));
      setStartYear(
        parseInt(moment(event, 'YYYY').startOf('year').format('YYYY'))
      );
      setEndYear(parseInt(moment(event, 'YYYY').endOf('year').format('YYYY')));
    }
  };

  // the choices for the display of months selection
  const arrayMonths = [
    <MenuItem key='thisQuarter' value='thisQuarter' onClick={handleThisQuarter}>
      {thisQuarterLabel}
    </MenuItem>,
    <MenuItem key='lastQuarter' value='lastQuarter' onClick={handleLastQuarter}>
      {lastQuarterLabel}
    </MenuItem>,
    <MenuItem key='thisYear' value='thisYear' onClick={handleThisYear}>
      {thisYearLabel}
    </MenuItem>,
    <MenuItem key='lastYear' value='lastYear' onClick={handleLastYear}>
      {lastYearLabel}
    </MenuItem>,
    <MenuItem
      key='selectedYear'
      style={{ display: 'none' }}
      value={selectedYear}
    >
      {selectedYear}
    </MenuItem>,
    <div key='year-picker' style={{ height: '37px', width: '150px' }}>
      <InputLabel id='year-picker' />
      <Select
        labelId='year-picker'
        id='year-picker'
        onChange={(ev) => handleYearChange(ev.target.value)}
        value={selectedYear}
        style={{ height: '37px', width: '160px', textIndent: '16px' }}
        // options will be displayed under the select component
        MenuProps={{
          anchorOrigin: { vertical: 'center', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          getContentAnchorEl: null,
          style: {
            maxHeight: 250,
            width: 100,
          },
        }}
      >
        {arrayYears.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
        <MenuItem
          style={{ display: 'none' }}
          key={selectYearLabel}
          value={selectYearLabel}
        >
          {selectYearLabel}
        </MenuItem>
      </Select>
    </div>,
  ];

  // the choice for the display of years selection
  const arrayYear = [
    <MenuItem key='last3Years' value='last3Years'>
      {last3YearsLabel}
    </MenuItem>,
  ];

  // event to save the value of display selection
  const handleDisplay = (ev) => {
    setOption(ev.target.value);
  };

  // event to save the value of the day selection
  const handleSelectRange = (ev) => {
    if (selectedYear !== selectYearLabel) setRangeValue(ev.target.value);
  };

  // create the data for material table entire month
  const handleShowList = () => {
    const metricsArray: any = [];
    const statuses: any = {};
    Object.keys(combinedDateAndTypes).length !== 0
      ? Object.keys(combinedDateAndTypes).forEach((date) => {
          combinedDateAndTypes[date].forEach((object) => {
            metricsArray.push(object);
          });
        })
      : Object.keys(companyDateAndTypes).forEach((date) => {
          companyDateAndTypes[date].forEach((object) => {
            metricsArray.push(object);
          });
        });
    const metricsCompany: any = [];
    companies.forEach((company) => {
      metricsCompany.push(
        metricsArray.reduce((acc, t) => {
          if (t.relationName === company) {
            acc.relationName = company;
            acc[exposedStatus(t.status)] =
              t.count + (acc[exposedStatus(t.status)] || 0);
          }
          return acc;
        }, {})
      );
    });
    const companyDetails: any = [];
    metricsCompany.forEach((metricCompany) => {
      const arrayMetrics: any = [];
      Object.keys(metricCompany).forEach((el) => {
        const helperObject: any = {};
        if (el !== 'relationName') {
          helperObject['status'] = el;
          helperObject['count'] = metricCompany[el];
        }
        arrayMetrics.push(helperObject);
      });
      const results = arrayMetrics.filter((element) => {
        if (Object.keys(element).length !== 0) {
          return true;
        }
        return false;
      });
      results.forEach((arr) => {
        arr['relationName'] = metricCompany.relationName;
      });
      results.forEach((arr) => {
        companyDetails.push(arr);
      });
    });
    const countByRelations: any = {};
    companies.forEach((company) => {
      companyDetails.forEach((el) => {
        if (company === el.relationName) {
          countByRelations[company] =
            el.count + (countByRelations[company] || 0);
        }
      });
    });
    companyDetails.forEach((arr) => {
      arr['percentage'] =
        Math.round((arr.count / countByRelations[arr.relationName]) * 100) +
        '%';
    });
    companyDetails.forEach((data) => {
      statuses[data.status] = translate(`${I18N_KEY}.status.${data.status}`);
    });
    const companyFilteredDetails: any = [];
    if (companyPreferences !== null) {
      if (companyPreferences.length !== 0) {
        companyDetails.forEach((metrics) => {
          if (
            companyPreferences.includes(metrics.relationName) &&
            filterStatuses.includes(metrics.status)
          )
            companyFilteredDetails.push(metrics);
        });
      } else {
        companyDetails.forEach((metrics) => {
          if (filterStatuses.includes(metrics.status)) {
            companyFilteredDetails.push(metrics);
          }
        });
      }
    } else {
      companyDetails.forEach((metrics) => {
        if (filterStatuses.includes(metrics.status)) {
          companyFilteredDetails.push(metrics);
        }
      });
    }
    setStatus(statuses);
    companyFilteredDetails.length === 0
      ? setDetailsPerMonth(companyDetails)
      : setDetailsPerMonth(companyFilteredDetails);
    setOpenAllDetails(true);
  };

  // the table for monthly details by company
  const monthDetailsTable = (
    <Grid>
      <MaterialTable
        title={
          rangeValue === '7days'
            ? titleMonthTable + '"' + last7Label.toLowerCase() + '"'
            : rangeValue === '14days'
            ? titleMonthTable + '"' + last14Label.toLowerCase() + '"'
            : titleMonthTable + moment(month, 'YYYY-MM').format('MMMM, YYYY')
        }
        columns={[
          {
            title: translate('dxMessages.dashboard.widgets.Columns.company'),
            field: 'relationName',
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.status'),
            field: 'status',
            lookup: status,
          },
          {
            title: translate('dxMessages.dashboard.widgets.Columns.count'),
            field: 'count',
            type: 'numeric',
            filtering: false,
          },
          {
            title: '%',
            field: 'percentage',
            filtering: false,
            sorting: false,
          },
        ]}
        data={detailsPerMonth}
        options={{
          exportButton: true,
          paging: false,
          maxBodyHeight: 400,
          search: false,
          filtering: true,
        }}
      />
    </Grid>
  );

  // we need to process data for the case when a range
  // is containing two different months
  useEffect(() => {
    if (option === 'days') {
      rangeDates.forEach((date) => {
        Object.keys(companyDateAndTypes).forEach((current: any) => {
          if (current === date) {
            if (!Object.keys(combinedDateAndTypes).includes(current)) {
              setCombinedDateAndTypes({
                ...combinedDateAndTypes,
                ...{ [current]: companyDateAndTypes[current] },
              });
            }
          }
        });
        Object.keys(pastCompanyDateAndTypes).forEach((past: any) => {
          if (past === date) {
            if (!Object.keys(combinedDateAndTypes).includes(past)) {
              setCombinedDateAndTypes({
                ...combinedDateAndTypes,
                ...{ [past]: pastCompanyDateAndTypes[past] },
              });
            }
          }
        });
      });
    }
  }, [
    combinedDateAndTypes,
    companyDateAndTypes,
    option,
    pastCompanyDateAndTypes,
    rangeDates,
  ]);

  // settings for selection of days display
  const handleDaysOption = () => {
    setRangeValue('');
    setDisplayOptionDays(moment(month).format('MMMM YYYY'));
  };

  // settings for selection of years display
  const handleYearsOption = () => {
    setChartMetrics([]);
    setRangeValue('last3Years');
    setRangeDates([]);
    setInputStartYear(
      parseInt(moment().subtract(2, 'years').startOf('year').format('YYYY'))
    );
    setInputEndYear(parseInt(moment().endOf('year').format('YYYY')));
  };

  // settings for selection of months display
  const handleMonthsOption = () => {
    setChartMetrics([]);
    setSelectedYear(moment().format('YYYY'));
    setRangeDates([]);
    setRangeValue('');
    setStartMonth(parseInt(moment().startOf('year').format('M')));
    setEndMonth(parseInt(moment().endOf('year').format('M')));
    setStartYear(parseInt(moment().startOf('year').format('YYYY')));
    setEndYear(parseInt(moment().endOf('year').format('YYYY')));
  };

  // if there are companies saved in the filter then update the chart
  // when refreshing the page or switch browsers
  useEffect(() => {
    if (option === 'months') {
      if (companyPreferences !== null) {
        if (companyPreferences.length !== 0 && monthlyCompanies.length !== 0)
          filterCompaniesMonthly(companyPreferences);
      } else {
        setMonthlyCompanyMetrics([]);
      }
    } else if (option === 'years') {
      if (companyPreferences !== null) {
        if (companyPreferences.length !== 0 && yearlyCompanies.length !== 0)
          filterCompaniesYearly(companyPreferences);
      } else {
        setYearlyCompanyMetrics([]);
      }
    } else {
      if (companyPreferences !== null) {
        if (companyPreferences.length !== 0 && companies.length !== 0)
          setUpChart(companyPreferences);
      } else {
        setChartMetrics([]);
      }
    }
  }, [
    companies.length,
    companyPreferences,
    filterCompaniesMonthly,
    filterCompaniesYearly,
    monthlyCompanies.length,
    option,
    setUpChart,
    yearlyCompanies.length,
  ]);

  return (
    // Flip the card to display the help.
    <ReactCardFlip
      isFlipped={help || openChild || openAllDetails}
      flipDirection='vertical'
    >
      <Card
        classes={{
          root: classes.card,
        }}
      >
        {!onTheShelves && (
          <div className={classes.help}>
            <IconButton onClick={() => setHelp(!help)} size='small'>
              <HelpIcon />
            </IconButton>
          </div>
        )}
        <CardHeader
          title={
            openConfiguration === false ? (
              customizedTitle
            ) : onTheShelves ? (
              translate(`${I18N_KEY}.${documentType}.title`)
            ) : (
              <TextField
                id='standard-basic'
                label={widgetTitle}
                variant='standard'
                defaultValue={customizedTitle}
                style={{ width: '500px' }}
                onChange={handleChange}
                onDoubleClick={(ev: any) => ev.target.select()}
                autoComplete='off'
                inputProps={{ maxLength: 120 }}
              />
            )
          }
          subheader={
            // Select the month we want the count per day for.
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <FormControl
                  variant='standard'
                  style={{
                    width: 1,
                    minWidth: 120,
                    marginRight: 15,
                  }}
                >
                  <InputLabel id='display'>{displayLabel}</InputLabel>
                  <Select
                    labelId='display'
                    id='display'
                    onChange={handleDisplay}
                    label='Display'
                    value={option}
                    disabled={onTheShelves}
                    // options will be displayed under the select component
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value='days' onClick={handleDaysOption}>
                      {daysLabel}
                    </MenuItem>
                    <MenuItem value='months' onClick={handleMonthsOption}>
                      {monthsLabel}
                    </MenuItem>
                    <MenuItem value='years' onClick={handleYearsOption}>
                      {yearsLabel}
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant='standard'
                  style={{
                    width: 1,
                    minWidth: 120,
                    marginRight: 35,
                  }}
                >
                  <InputLabel id='date-range'>{dateRangeLabel}</InputLabel>
                  <Select
                    labelId='date-range'
                    id='date-range'
                    label='Date range'
                    onChange={handleSelectRange}
                    style={{
                      width: '160px',
                    }}
                    disabled={option === 'years' || onTheShelves}
                    value={
                      option === 'months'
                        ? rangeValue === ''
                          ? selectedYear
                          : rangeValue
                        : rangeValue !== ''
                        ? rangeValue
                        : displayOptionDays
                    }
                    // options will be displayed under the select component
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {option === 'years'
                      ? arrayYear.map((year) => year)
                      : option === 'months'
                      ? arrayMonths.map((months) => months)
                      : arrayDays.map((days) => days)}
                  </Select>
                </FormControl>

                <SimpleForm
                  toolbar={<></>}
                  style={{
                    marginTop: '-12px',
                    marginBottom: '-20px',
                  }}
                >
                  <MultiSelectInput
                    label={translate(
                      'dxMessages.dashboard.widgets.Columns.company'
                    )}
                    source='Companies'
                    sortLabels={true}
                    width={widthPresets.large}
                    onChange={(event) => handleClick(event)}
                    options={
                      option === 'days'
                        ? choices
                        : option === 'months'
                        ? monthlyChoices
                        : yearlyChoices
                    }
                    defaultValue={companyPreferences}
                    disabled={openConfiguration}
                  />
                </SimpleForm>
              </div>
              <Tooltip title={tableLabel}>
                <Button
                  variant='outlined'
                  style={{ marginTop: '15px' }}
                  onClick={() => {
                    sendGAEvent(
                      GA_EVENTS.categories.DASHBOARD.name,
                      GA_EVENTS.categories.DASHBOARD.actions.STATUS_BY_COMPANY,
                      account?.company?.cmsRootDir
                    );
                    if (openConfiguration === false) {
                      if (option === 'days') return handleShowList();
                      if (option === 'months') return showTableMonth();
                      if (option === 'years') return showTableYear();
                    }
                  }}
                  disabled={openConfiguration}
                >
                  &#9776; {listButton}
                </Button>
              </Tooltip>
            </div>
          }
        />
        <CardContent style={{ marginBottom: '1em' }}>
          {/* MUST set a height for the diagram. */}
          <div style={{ width: '100%', height: '35vh' }}>
            {onTheShelves || setupUsage !== SetupUsage.PRODUCTION ? (
              <DocumentCountPerDayAndStatusDemo
                documentType={documentType}
                startDate={moment(month, 'YYYY-MM').startOf('month')}
                endDate={moment(month, 'YYYY-MM').endOf('month')}
                startYear={startYear}
                endYear={endYear}
                startMonth={startMonth}
                endMonth={endMonth}
                userPreferencesRootKey={userPreferencesRootKey}
                companyPreferences={companyPreferences}
                onTheShelves={onTheShelves}
                onClickOpenChild={onClickOpenChild}
                openConfiguration={openConfiguration}
                chartMetrics={chartMetrics}
                rangeDates={rangeDates}
                option={option}
                resultRange={resultRange}
                rangeValue={rangeValue}
                chartData={chartData}
                monthlyCompanyMetrics={monthlyCompanyMetrics}
                monthlyTable={monthlyTable}
                yearlyTable={yearlyTable}
                yearlyCompanyMetrics={yearlyCompanyMetrics}
                inputStartYear={inputStartYear}
                inputEndYear={inputEndYear}
                setFilterStatuses={setFilterStatuses}
                setCompanyDateAndTypes={setCompanyDateAndTypes}
                setChartData={setChartData}
                setLastMonthMetrics={setLastMonthMetrics}
                setPastCompanyDateAndTypes={setPastCompanyDateAndTypes}
                setMonthsDateAndTypes={setMonthsDateAndTypes}
                setYearsDateAndTypes={setYearsDateAndTypes}
              />
            ) : (
              // 20210118, 20210120
              <DocumentCountPerDayAndStatusLive
                companyId={account.company.id}
                documentType={documentType}
                startDate={moment(month, 'YYYY-MM').startOf('month')}
                endDate={moment(month, 'YYYY-MM').endOf('month')}
                startYear={startYear}
                endYear={endYear}
                startMonth={startMonth}
                endMonth={endMonth}
                userPreferencesRootKey={userPreferencesRootKey}
                companyPreferences={companyPreferences}
                onClickOpenChild={onClickOpenChild}
                relationRole={relationRole}
                openConfiguration={openConfiguration}
                chartMetrics={chartMetrics}
                rangeDates={rangeDates}
                option={option}
                resultRange={resultRange}
                rangeValue={rangeValue}
                chartData={chartData}
                monthlyCompanyMetrics={monthlyCompanyMetrics}
                monthlyTable={monthlyTable}
                yearlyTable={yearlyTable}
                yearlyCompanyMetrics={yearlyCompanyMetrics}
                inputStartYear={inputStartYear}
                inputEndYear={inputEndYear}
                setFilterStatuses={setFilterStatuses}
                setCompanyDateAndTypes={setCompanyDateAndTypes}
                setChartData={setChartData}
                setLastMonthMetrics={setLastMonthMetrics}
                setPastCompanyDateAndTypes={setPastCompanyDateAndTypes}
                setMonthsDateAndTypes={setMonthsDateAndTypes}
                setYearsDateAndTypes={setYearsDateAndTypes}
              />
            )}
          </div>
        </CardContent>
      </Card>
      {/* Display the guide for this widget.  */}
      <Card style={{ backgroundColor: theme.palette.grey[50] }}>
        <div className={classes.help}>
          <IconButton
            onClick={() =>
              help
                ? setHelp(!help)
                : openAllDetails
                ? setOpenAllDetails(!openAllDetails)
                : setOpenChild(!openChild)
            }
            size='small'
          >
            <KeyboardReturnIcon />
          </IconButton>
        </div>
        <Box m={1} mt={2} p={1}>
          {help && <Help documentType={documentType} />}
          {openChild && option === 'days' && <Typography>{table}</Typography>}
          {openChild && option === 'months' && (
            <Typography>{materialTableMonth}</Typography>
          )}
          {openChild && option === 'years' && (
            <Typography>{materialTableYears}</Typography>
          )}
          {openAllDetails && option === 'days' && (
            <Typography>{monthDetailsTable}</Typography>
          )}
          {openAllDetails && option === 'months' && (
            <Typography>{materialTableMonthsSummary}</Typography>
          )}
          {openAllDetails && option === 'years' && (
            <Typography>{materialTableYearsSummary}</Typography>
          )}
        </Box>
      </Card>
    </ReactCardFlip>
  );
};

const DocumentCountPerDayAndStatusLive: FC<{
  companyId: Identifier;
  documentType: DocumentType;
  startDate: Moment;
  endDate: Moment;
  startMonth: number;
  endMonth: number;
  startYear: number;
  endYear: number;
  userPreferencesRootKey: string;
  companyPreferences: string[];
  onClickOpenChild: any;
  relationRole: RelationRole;
  openConfiguration: boolean;
  chartMetrics: Metrics[];
  rangeDates: string[];
  option: string;
  resultRange: Metrics[];
  rangeValue: string;
  chartData: Metrics[];
  monthlyCompanyMetrics: Metrics[];
  monthlyTable: any;
  yearlyTable: any;
  yearlyCompanyMetrics: Metrics[];
  inputStartYear: number;
  inputEndYear: number;
  setPastCompanyDateAndTypes: any;
  setMonthsDateAndTypes: any;
  setYearsDateAndTypes: any;
  setCompanyDateAndTypes: any;
  setFilterStatuses: Dispatch<SetStateAction<string[]>>;
  setChartData: Dispatch<SetStateAction<Metrics[]>>;
  setLastMonthMetrics: Dispatch<SetStateAction<Metrics[]>>;
}> = ({
  companyId,
  documentType,
  startDate,
  endDate,
  startMonth,
  endMonth,
  startYear,
  endYear,
  userPreferencesRootKey,
  companyPreferences,
  onClickOpenChild,
  relationRole,
  openConfiguration,
  chartMetrics,
  rangeDates,
  option,
  resultRange,
  rangeValue,
  chartData,
  monthlyCompanyMetrics,
  monthlyTable,
  yearlyTable,
  yearlyCompanyMetrics,
  inputStartYear,
  inputEndYear,
  setPastCompanyDateAndTypes,
  setMonthsDateAndTypes,
  setYearsDateAndTypes,
  setCompanyDateAndTypes,
  setFilterStatuses,
  setChartData,
  setLastMonthMetrics,
}) => {
  const translate = useTranslate();
  // Keep in the user preferences the invoice statuses he's interested in.
  const [statuses, setStatuses] = usePreferences(
    `${userPreferencesRootKey}.${documentType}.statuses`,
    STATUS_PER_DOC_TYPE[documentType]
  );

  // Analytics for daily document metrics per realtion metrics
  // Ask the analytic endpoint for the document count for the user company.
  const {
    loading: queryLoadingDays,
    error: queryErrorDays,
    data: queryDataDays,
  } = useQuery(DAILY_RELATIONS_GRAPHQL_REQUEST, {
    variables: {
      client:
        process.env.NODE_ENV === 'production'
          ? companyId
          : '001G_xGUkNWTwXqRdHxm15oh9OegSKvYtpuLBgd6oFZgXRdNOasspAh',
      docType: documentType,
      // The date dimension in the anayltic dataset is typed as a 'YYYYMMDD'
      // strings.
      startDate: startDate.format('YYYYMMDD'),
      endDate: endDate.format('YYYYMMDD'),
      role: relationRole,
    },
  });

  // Analytics for the previous day, used in merging last 7/14 days
  const { error: queryErrorPreviousMonth, data: queryDataPreviousMonth } =
    useQuery(DAILY_RELATIONS_GRAPHQL_REQUEST, {
      variables: {
        client:
          process.env.NODE_ENV === 'production'
            ? companyId
            : '001G_xGUkNWTwXqRdHxm15oh9OegSKvYtpuLBgd6oFZgXRdNOasspAh',
        docType: documentType,
        // The date dimension in the anayltic dataset is typed as a 'YYYYMMDD'
        // strings.
        startDate: moment(
          moment().subtract(1, 'months').format('YYYY-MM-DD'),
          'YYYY-MM'
        )
          .startOf('month')
          .format('YYYYMMDD'),
        endDate: moment(
          moment().subtract(1, 'months').format('YYYY-MM-DD'),
          'YYYY-MM'
        )
          .endOf('month')
          .format('YYYYMMDD'),
        role: relationRole,
      },
    });

  // Analytics for monthly documents metrics
  // Ask the analytic endpoint for the document count for the user company.
  const {
    loading: queryLoadingMonthsRelations,
    error: queryErrorMonthsRelations,
    data: queryDataMonthsRelations,
  } = useQuery(MONTHLY_RELATION_GRAPHQL_REQUEST, {
    variables: {
      client:
        process.env.NODE_ENV === 'production'
          ? companyId
          : '001G_xGUkNWTwXqRdHxm15oh9OegSKvYtpuLBgd6oFZgXRdNOasspAh',
      docType: documentType,
      // The date dimension in the anayltic dataset is typed as a 'YYYYMMDD'
      // strings.
      startMonth: startMonth,
      endMonth: endMonth,
      startYear: startYear,
      endYear: endYear,
      role: relationRole,
    },
    skip: option !== 'months',
  });

  const {
    loading: queryLoadingYearsRelations,
    error: queryErrorYearsRelations,
    data: queryDataYearsRelations,
  } = useQuery(YEARS_RELATIONS_GRAPHQL_REQUEST, {
    variables: {
      client:
        process.env.NODE_ENV === 'production'
          ? companyId
          : '001G_xGUkNWTwXqRdHxm15oh9OegSKvYtpuLBgd6oFZgXRdNOasspAh',
      docType: documentType,
      // The date dimension in the anayltic dataset is typed as a 'YYYYMMDD'
      // strings.
      startYear: inputStartYear,
      endYear: inputEndYear,
      role: relationRole,
    },
    skip: option !== 'years',
  });

  if (queryErrorDays)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          queryErrorDays,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );

  if (queryErrorMonthsRelations)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          queryErrorDays,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );

  if (queryErrorPreviousMonth)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          queryErrorDays,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );

  if (queryErrorYearsRelations)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          queryErrorDays,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );

  // Record the invoice statuses to show in the bar chart.
  const onToggleInvoiceStatus = (status: Status, show: boolean) => {
    if (show && !statuses.includes(status)) setStatuses([...statuses, status]);
    else if (!show && statuses.includes(status))
      setStatuses(statuses.filter((_) => _ !== status));
  };

  return (
    <CountPerDayAndStatusBarChart
      queryLoadingDays={queryLoadingDays}
      queryLoadingMonthsRelations={queryLoadingMonthsRelations}
      queryLoadingYearsRelations={queryLoadingYearsRelations}
      queryDataMonthsRelations={queryDataMonthsRelations}
      queryDataDays={queryDataDays}
      queryDataPreviousMonth={queryDataPreviousMonth}
      queryDataYearsRelations={queryDataYearsRelations}
      statuses={statuses}
      userPreferencesRootKey={userPreferencesRootKey}
      companyPreferences={companyPreferences}
      onToggleInvoiceStatus={onToggleInvoiceStatus}
      documentType={documentType}
      onClickOpenChild={onClickOpenChild}
      openConfiguration={openConfiguration}
      chartMetrics={chartMetrics}
      rangeDates={rangeDates}
      option={option}
      resultRange={resultRange}
      rangeValue={rangeValue}
      chartData={chartData}
      monthlyCompanyMetrics={monthlyCompanyMetrics}
      monthlyTable={monthlyTable}
      yearlyTable={yearlyTable}
      yearlyCompanyMetrics={yearlyCompanyMetrics}
      setCompanyDateAndTypes={setCompanyDateAndTypes}
      setPastCompanyDateAndTypes={setPastCompanyDateAndTypes}
      setMonthsDateAndTypes={setMonthsDateAndTypes}
      setYearsDateAndTypes={setYearsDateAndTypes}
      setFilterStatuses={setFilterStatuses}
      setChartData={setChartData}
      setLastMonthMetrics={setLastMonthMetrics}
    />
  );
};

export const CountPerDayAndStatusBarChart: FC<{
  queryLoadingDays: boolean;
  queryLoadingMonthsRelations: boolean;
  queryLoadingYearsRelations: boolean;
  queryDataPreviousMonth: any;
  queryDataMonthsRelations: any;
  queryDataDays: any;
  queryDataYearsRelations: any;
  statuses: Status[];
  userPreferencesRootKey: string;
  companyPreferences: string[];
  onToggleInvoiceStatus: (status: Status, show: boolean) => void;
  documentType: DocumentType;
  onTheShelves?: boolean;
  onClickOpenChild: any;
  openConfiguration: boolean;
  chartMetrics: Metrics[];
  rangeDates: string[];
  option: string;
  resultRange: Metrics[];
  rangeValue: string;
  chartData: Metrics[];
  monthlyCompanyMetrics: Metrics[];
  monthlyTable: any;
  yearlyTable: any;
  yearlyCompanyMetrics: Metrics[];
  setCompanyDateAndTypes: any;
  setPastCompanyDateAndTypes: any;
  setMonthsDateAndTypes: any;
  setYearsDateAndTypes: any;
  setChartData: Dispatch<SetStateAction<Metrics[]>>;
  setLastMonthMetrics: Dispatch<SetStateAction<Metrics[]>>;
  setFilterStatuses: Dispatch<SetStateAction<string[]>>;
}> = ({
  queryLoadingDays,
  queryLoadingYearsRelations,
  queryLoadingMonthsRelations,
  queryDataPreviousMonth,
  queryDataMonthsRelations,
  queryDataDays,
  queryDataYearsRelations,
  statuses,
  userPreferencesRootKey,
  companyPreferences,
  onToggleInvoiceStatus,
  documentType,
  onTheShelves = false,
  onClickOpenChild,
  openConfiguration,
  chartMetrics,
  rangeDates,
  option,
  resultRange,
  rangeValue,
  chartData,
  monthlyCompanyMetrics,
  monthlyTable,
  yearlyTable,
  yearlyCompanyMetrics,
  setCompanyDateAndTypes,
  setPastCompanyDateAndTypes,
  setMonthsDateAndTypes,
  setYearsDateAndTypes,
  setFilterStatuses,
  setChartData,
  setLastMonthMetrics,
}) => {
  const translate = useTranslate();
  const theme = useTheme();
  const [checkedStatuses, setCheckedStatuses] = useState<string[]>(statuses);
  const [yearlyMetrics, setYearlyMetrics] = useState<Metrics[]>([]);
  const [monthlyMetrics, setMonthlyMetrics] = useState<Metrics[]>([]);
  const [lastMonthCompanyMetrics, setLastMonthCompanyMetrics] = useState<any>();
  const setupUsage = useSetupUsage();

  //render the data for the company filter
  useEffect(() => {
    setFilterStatuses(checkedStatuses);
    if (option === 'days') {
      if (
        queryDataDays !== undefined &&
        Object.keys(queryDataDays).length !== 0
      ) {
        const company: any = groupBy(
          queryDataDays.dailyDocumentMetricsPerRelationMetrics.metrics.map(
            (_) => ({
              date: _.dimensions.dateDimension.date,
              status: _.docStatus,
              count: parseInt(_.value),
              relationName: _.relationName,
            })
          ),
          ({ date }) => date
        );
        setCompanyDateAndTypes(company);
      }
    }
  }, [
    checkedStatuses,
    queryDataDays,
    setFilterStatuses,
    setCompanyDateAndTypes,
    option,
  ]);

  // metrics for monthly selection with relations
  useEffect(() => {
    if (option === 'months') {
      if (monthlyMetrics.length !== 0) {
        setMonthlyMetrics([]);
      }
      const monthlyDateAndTypes: any = groupBy(
        queryDataMonthsRelations?.monthlyDocumentMetricsPerRelationMetrics?.metrics.map(
          (_) => ({
            month: _.dimensions.dateDimension.month,
            year: _.dimensions.dateDimension.year,
            status: _.docStatus,
            count: parseInt(_.value),
            relationName: _.relationName,
          })
        ),
        ({ month }) => month
      );
      setMonthsDateAndTypes(monthlyDateAndTypes);
      setMonthlyMetrics((monthlyMetrics) => [
        ...monthlyMetrics,
        ...Object.keys(monthlyDateAndTypes).map((number) => {
          const help = monthlyDateAndTypes[number].reduce(
            (accumulator, currentValue) => {
              accumulator.date = number;
              accumulator[exposedStatus(currentValue.status)] =
                currentValue.count +
                (accumulator[exposedStatus(currentValue.status)] || 0);
              return accumulator;
            },
            {}
          );
          return help;
        }),
      ]);
    }
  }, [
    monthlyMetrics.length,
    option,
    queryDataMonthsRelations,
    setMonthsDateAndTypes,
  ]);

  // metrics for the yearly selection
  useEffect(() => {
    if (option === 'years') {
      if (queryDataYearsRelations !== undefined) {
        if (yearlyMetrics.length !== 0) {
          setMonthlyMetrics([]);
        }
        const yearlyDateAndTypes: any = groupBy(
          queryDataYearsRelations.yearlyDocumentMetricsPerRelationMetrics.metrics.map(
            (_) => ({
              year: _.dimensions.dateDimension.year,
              status: _.docStatus,
              count: parseInt(_.value),
              relationName: _.relationName,
            })
          ),
          ({ year }) => year
        );
        setYearsDateAndTypes(yearlyDateAndTypes);
        let yearsArray: any = [];
        Object.keys(yearlyDateAndTypes).forEach((year) => {
          yearsArray.push(
            yearlyDateAndTypes[year].reduce((accumulator, currentValue) => {
              accumulator.date = year;
              accumulator[exposedStatus(currentValue.status)] =
                currentValue.count +
                (accumulator[exposedStatus(currentValue.status)] || 0);
              return accumulator;
            }, {})
          );
        });
        setYearlyMetrics(yearsArray);
      }
    }
  }, [
    option,
    queryDataYearsRelations,
    setYearsDateAndTypes,
    yearlyMetrics.length,
  ]);

  // we will have here the original metrics processed from query helpful for details per company
  useEffect(() => {
    if (option === 'days') {
      if (setupUsage !== SetupUsage.DEMO) {
        if (queryDataPreviousMonth !== undefined) {
          const lastMonthDateAndTypes = groupBy(
            queryDataPreviousMonth.dailyDocumentMetricsPerRelationMetrics.metrics.map(
              (_) => ({
                date: _.dimensions.dateDimension.date,
                status: _.docStatus,
                count: parseInt(_.value),
                relationName: _.relationName,
              })
            ),
            ({ date }) => date
          );
          if (Object.keys(lastMonthDateAndTypes) !== undefined) {
            const lastMonthMetrics = Object.keys(lastMonthDateAndTypes).map(
              (date) =>
                lastMonthDateAndTypes[date].reduce((acc, t) => {
                  acc.date = date;
                  acc[exposedStatus(t.status)] =
                    t.count + (acc[exposedStatus(t.status)] || 0);
                  return acc;
                }, {})
            );
            setPastCompanyDateAndTypes(lastMonthDateAndTypes);
            setLastMonthCompanyMetrics(lastMonthDateAndTypes);
            setLastMonthMetrics(lastMonthMetrics);
          }
        }
      }
    }
  }, [
    option,
    queryDataPreviousMonth,
    setLastMonthMetrics,
    setPastCompanyDateAndTypes,
    setupUsage,
  ]);

  // Knead the anayltic metrics to fit the data format expected by the bar chart.
  if (queryLoadingDays) return <CircularProgress size='1em' />;
  // No data, no bar chart.
  if (queryDataDays.dailyDocumentMetricsPerRelationMetrics.metrics.length === 0)
    return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;

  if (option === 'months') {
    if (queryLoadingMonthsRelations) return <CircularProgress size='1em' />;
    // No data, no bar chart.
    if (
      queryDataMonthsRelations.monthlyDocumentMetricsPerRelationMetrics !==
      undefined
    )
      if (
        queryDataMonthsRelations.monthlyDocumentMetricsPerRelationMetrics
          .metrics.length === 0
      )
        return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;
  }

  if (option === 'years') {
    if (queryLoadingYearsRelations) return <CircularProgress size='1em' />;
    if (
      queryDataYearsRelations?.yearlyDocumentMetricsPerRelationMetrics?.metrics
        .length === 0
    )
      return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;
  }

  // Knead the anayltic metrics to fit the data format expected by the bar chart.
  const dateAndTypes = groupBy(
    queryDataDays.dailyDocumentMetricsPerRelationMetrics.metrics.map((_) => ({
      date: _.dimensions.dateDimension.date,
      status: _.docStatus,
      count: parseInt(_.value),
      relationName: _.relationName,
    })),
    ({ date }) => date
  );

  //the data we need for filtering a chart
  const metrics = Object.keys(dateAndTypes).map((date) =>
    dateAndTypes[date].reduce((acc, t) => {
      acc.date = date;
      acc[exposedStatus(t.status)] =
        t.count + (acc[exposedStatus(t.status)] || 0);
      return acc;
    }, {})
  );

  const dataForChart = () => {
    if (option === 'months') {
      // First verify if we have filter by company.
      if (monthlyCompanyMetrics.length !== 0) {
        return [...monthlyCompanyMetrics];
      }
      // If we don't have the filter on, then we check if
      // we have data in months.
      else if (monthlyMetrics.length !== 0) {
        return [...monthlyMetrics];
      } else {
        return [...[]];
      }
    } else if (option === 'years') {
      // Check if we have filter by company on.
      if (yearlyCompanyMetrics.length !== 0) {
        return [...yearlyCompanyMetrics];
      }
      // If not, display all the data we have in years.
      else if (yearlyMetrics.length !== 0) {
        return [...yearlyMetrics];
      } else {
        return [...[]];
      }
    } else {
      // Check in days view if the filter by company is on.
      if (chartMetrics.length === 0) {
        // Check if we have a result after choosing a range option.
        if (resultRange.length !== 0) {
          // Check if we can sort the bars by date
          if (resultRange.length > 1) {
            return [...resultRange].sort((s1, s2) =>
              s1.date.localeCompare(s2.date)
            );
          } else {
            return [...resultRange];
          }
        }
        // If we don't have neither the filter on or one of
        // the range option checked, we display initial data
        else {
          return [...metrics].sort((s1, s2) => s1.date.localeCompare(s2.date));
        }
      }
      // If we have filter on, we check if we can sort the bars by date
      else if (chartMetrics.length > 1) {
        return [...chartMetrics].sort((s1, s2) =>
          s1.date.localeCompare(s2.date)
        );
      } else {
        return [...chartMetrics];
      }
    }
  };

  if (dataForChart().length === 0) {
    return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;
  }

  if (companyPreferences !== null) {
    if (option === 'days') {
      if (companyPreferences.length !== 0 && chartMetrics.length === 0) {
        return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;
      }
      if (rangeDates.length !== 0 && resultRange.length === 0)
        return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;
    } else if (option === 'months') {
      if (
        companyPreferences.length !== 0 &&
        monthlyCompanyMetrics.length === 0
      ) {
        return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;
      }
    } else {
      if (
        companyPreferences.length !== 0 &&
        yearlyCompanyMetrics.length === 0
      ) {
        return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;
      }
    }
  }

  return (
    <>
      <DocumentCountBarChart
        data={metrics}
        statuses={statuses}
        sessionStorageRootKey={userPreferencesRootKey}
        dataType={documentType}
        onTheShelves={onTheShelves}
        onClickOpenChild={onClickOpenChild}
        objectData={dateAndTypes}
        openConfiguration={openConfiguration}
        chartMetrics={chartMetrics}
        option={option}
        lastMonthCompanyMetrics={lastMonthCompanyMetrics}
        rangeValue={rangeValue}
        chartData={chartData}
        monthlyTable={monthlyTable}
        yearlyTable={yearlyTable}
        setChartData={setChartData}
        dataForChart={dataForChart}
      />
      {/* The checkbox list to toggle the display of each invoice status.  */}
      <Box display='flex' justifyContent='center' flexWrap='wrap'>
        {STATUS_PER_DOC_TYPE[documentType]
          .filter((status) =>
            NON_DISPLAYABLE_STATUSES.find((s) => s !== status)
          )
          .map((status) => (
            <Box key={status} display='flex' alignItems='center'>
              <Checkbox
                checked={statuses.includes(status)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onToggleInvoiceStatus(status, event.target.checked);
                  event.target.checked === false
                    ? setCheckedStatuses(
                        checkedStatuses.filter((item) => item !== status)
                      )
                    : !checkedStatuses.includes(status)
                    ? setCheckedStatuses(checkedStatuses.concat(status))
                    : setCheckedStatuses(checkedStatuses);
                }}
                size='small'
                style={{ color: documentTypeColor(status, theme) }}
              />
              <Typography variant='caption'>
                {translate(`${I18N_KEY}.status.${status}`)}
              </Typography>
            </Box>
          ))}
      </Box>
    </>
  );
};

const DocumentCountBarChart: FC<{
  data: any[];
  statuses: Status[];
  sessionStorageRootKey: string;
  dataType: DocumentType;
  onTheShelves?: boolean;
  onClickOpenChild: any;
  objectData: any;
  openConfiguration: boolean;
  chartMetrics: Metrics[];
  option: string;
  lastMonthCompanyMetrics: any;
  rangeValue: string;
  chartData: Metrics[];
  monthlyTable: any;
  yearlyTable: any;
  setChartData: any;
  dataForChart: any;
}> = ({
  data,
  statuses,
  onTheShelves = false,
  sessionStorageRootKey,
  dataType,
  onClickOpenChild,
  objectData,
  openConfiguration,
  chartMetrics,
  option,
  lastMonthCompanyMetrics,
  rangeValue,
  chartData,
  monthlyTable,
  yearlyTable,
  setChartData,
  dataForChart,
}) => {
  const translate = useTranslate();
  const theme = useTheme();
  // save selection of brush into account
  const [startIndex, setStartIndex]: any = usePreferences(
    `${sessionStorageRootKey}.${dataType}.startIndex`,
    0
  );
  const [endIndex, setEndIndex]: any = usePreferences(
    `${sessionStorageRootKey}.${dataType}.endIndex`,
    undefined
  );
  const redirect = useRedirect();
  const classes = useStyles({ onTheShelves: onTheShelves });
  const notify: any = useNotify();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  useEffect(() => {
    if (data.length - endIndex < 2) {
      setEndIndex(undefined);
    }
  }, [data.length, endIndex, setEndIndex]);

  // effect hook to set the metrics corresponding to the chart to the parent component
  useEffect(() => {
    if (data.length !== 0) {
      if (rangeValue === '7days' || rangeValue === '14days') {
        const arr: any = [];
        data.forEach((row) => {
          if (moment(row.date).format('MM') === moment().format('MM')) {
            if (!arr.includes(moment(row.date).format('MM')))
              arr.push(moment(row.date).format('MM'));
          }
        });
        if (arr.length === 1)
          if (chartData.length === 0) {
            setChartData(data);
          }
      } else {
        if (chartData.length === 0) {
          setChartData(data);
        }
      }
    }
  }, [chartData, data, rangeValue, setChartData]);

  const handleClick = (type: DocumentType, day: string /* ex: 20220209*/) => {
    if (!onTheShelves && option !== 'months' && option !== 'years') {
      const from = moment(day, 'YYYYMMDD').startOf('day').toISOString();
      const to = moment(day, 'YYYYMMDD').endOf('day').toISOString();
      const filter = encodeURIComponent(
        `{"created":{"from":"${from}","to":"${to}"}}`
      );
      switch (type) {
        case DocumentType.INVOICE:
          redirect(`/invoice?filter=${filter}`);
          break;
        case DocumentType.ORDER:
          redirect(`/order?filter=${filter}`);
          break;
        case DocumentType.RECADV:
          redirect(`/recadv?filter=${filter}`);
          break;
        case DocumentType.DESADV:
          redirect(`/despatchAdvice?filter=${filter}`);
          break;
      }
      notify('dxMessages.dashboard.widgets.InformBanner.title', 'info', {
        autoHideDuration: 10000,
      });
      sendGAEvent(
        GA_EVENTS.categories.DASHBOARD.name,
        GA_EVENTS.categories.DASHBOARD.actions.LIST_DOCUMENTS,
        account?.company?.cmsRootDir
      );
    }
  };

  // X axis: put the day num and bold Saturday and Sunday.
  const tick = ({ x, y, payload: { value } }) => {
    const day = moment(value, 'YYYYMMDD');
    return (
      <Tooltip
        title={
          option === 'years'
            ? ''
            : !onTheShelves
            ? openConfiguration === false
              ? translate('dxMessages.dashboard.widgets.Tooltip.message')
              : ''
            : ''
        }
        placement='right-end'
      >
        <text
          x={x}
          y={y + 5}
          fontSize='12'
          textAnchor='middle'
          cursor={openConfiguration === false ? 'pointer' : 'inherit'}
          onClick={() => {
            if (option === 'days')
              sendGAEvent(
                GA_EVENTS.categories.DASHBOARD.name,
                GA_EVENTS.categories.DASHBOARD.actions.STATUS_BY_COMPANY,
                account?.company?.cmsRootDir
              );
          }}
        >
          {option === 'days'
            ? day.format('D') + '\u2630'
            : option === 'months'
            ? moment(value, 'M').format('MMMM') + '\u2630'
            : value + '\u2630'}
        </text>
      </Tooltip>
    );
  };
  // prevent a possible issue when we have month with 30 days and we
  // change to another month with 31 days
  useEffect(() => {
    if (data.length - endIndex < 2) {
      setEndIndex(undefined);
    }
  }, [chartMetrics.length, data.length, endIndex, setEndIndex]);

  // The tooltip per dated bars: the date and the list of counts per document type.
  const BarTooltip = ({ active, payload, label }) =>
    active && payload && payload.length ? (
      <div
        style={{
          // Use a semi-transparent background so the adjacent bars are not hidden
          // (and use can still compare bare values).
          background: 'rgba(210, 210, 210, 0.9)',
          border: '1px solid black',
          padding: '5px',
        }}
      >
        <Typography>
          {option === 'months'
            ? moment(label, 'M').format('MMMM')
            : option === 'years'
            ? moment(label, 'YYYY').format('YYYY')
            : moment(label, 'YYYYMMDD').format('MMM Do YY')}
        </Typography>
        <ul>
          {payload.map((_) => (
            <li key={_.name}>
              <Typography>
                {_.name}: {_.value}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    ) : null;

  return (
    /* Set a 99% width to fix the window down size pb. */
    <ResponsiveContainer width='99%' height='100%'>
      <BarChart
        data={dataForChart()}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey={'date'}
          tickLine={false}
          interval={0}
          tick={tick}
          onClick={(event: any) => {
            if (option === 'days') {
              if (openConfiguration === false) {
                if (!onTheShelves) {
                  const objectSelectedMonth = Object.fromEntries(
                    Object.entries(objectData).filter(
                      ([key, val]) => key.toString() === event.value.toString()
                    )
                  );
                  const objectLastMonth =
                    lastMonthCompanyMetrics !== undefined
                      ? Object.fromEntries(
                          Object.entries(lastMonthCompanyMetrics).filter(
                            ([key, val]) =>
                              key.toString() === event.value.toString()
                          )
                        )
                      : {};
                  Object.keys(objectSelectedMonth).length !== 0
                    ? onClickOpenChild(objectSelectedMonth)
                    : onClickOpenChild(objectLastMonth);
                }
              }
            }
            if (option === 'months') {
              if (openConfiguration === false) {
                monthlyTable(event.value);
              }
            }
            if (option === 'years') {
              if (openConfiguration === false) {
                yearlyTable(event.value);
              }
            }
          }}
        />
        <YAxis allowDecimals={false} />
        {/* @ts-ignore */}
        <RcTooltip content={<BarTooltip />} />
        {statuses
          .filter((status) =>
            NON_DISPLAYABLE_STATUSES.find((s) => s !== status)
          )
          .map((status) => (
            <Bar
              key={status}
              name={translate(`${I18N_KEY}.status.${status}`)}
              dataKey={status}
              stackId='a'
              fill={documentTypeColor(status, theme)}
              onClick={(ev) => {
                if (openConfiguration === false)
                  return handleClick(dataType, ev.date);
              }}
              className={
                openConfiguration === false
                  ? option !== 'months' && option !== 'years'
                    ? classes.bar
                    : ''
                  : ''
              }
            />
          ))}
        <Brush
          dataKey='name'
          height={20}
          startIndex={startIndex}
          endIndex={endIndex}
          onChange={debounce((e) => {
            setStartIndex(e.startIndex);
            setEndIndex(e.endIndex);
          }, 500)}
          // No label in the brush.
          tickFormatter={() => ''}
          // Prevent the widget from moving when dragging the brush.
          className='outsideDashboardGrip'
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const documentTypeColor = (status: Status, theme: Theme) => {
  // https://docprocess.atlassian.net/wiki/spaces/PMKT/pages/3745939469/Analytics+dashboard
  switch (status) {
    case Status.Accepted_by_Customer:
      return '#8A85C6';
    case Status.Sent:
      return '#5F8BB5';
    case Status.Received_by_Recipient:
      return '#69985F';
    case Status.Received_by_DX:
      return '#A8D4A7';
    case Status.Rejected_by_Customer:
      return '#D10A31';
    case Status.Rejected_by_DX:
      return '#FFBAB3';
    case Status.Paid_by_recipient:
      return '#99CFCD';
    case Status.Pending_to_Customer:
      return '#E0BD8D';
    case Status.Matching_in_Progress:
      return '#1C96ED';
    default:
      return '#10CFC9';
  }
};

const Help: FC<{ documentType: DocumentType }> = ({ documentType }) => {
  const locale = useLocale();
  const theme = useTheme();
  const translate = useTranslate();

  switch (locale) {
    case 'fr':
      return (
        <>
          <Typography paragraph>
            Ce widget affiche le nombre de documents échangées via la plateforme
            DocProcess, par jour et par état.
          </Typography>
          <Typography>
            Sélectionnez le mois qui vous intéresse puis affinez votre recherche
            en:
          </Typography>
          <ul>
            <li>
              Filtrant par état. Cochez les cases correspondantes en bas du
              diagramme:
              <Box display='flex' flexDirection='row'>
                {STATUS_PER_DOC_TYPE[documentType]
                  .filter((status) =>
                    NON_DISPLAYABLE_STATUSES.find((s) => s !== status)
                  )
                  .map((status) => (
                    <Box
                      key={status}
                      display='flex'
                      flexDirection='row'
                      alignItems='center'
                    >
                      <Checkbox
                        size='small'
                        style={{ color: documentTypeColor(status, theme) }}
                        checked
                      />
                      <Typography variant='caption' noWrap>
                        {translate(`${I18N_KEY}.status.${status}`)}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </li>
            <li>
              Zoomant sur une période spécifique. Utilisez la barre en bas du
              diagramme pour ajuster la période affichée en faisant glisser les
              extrémités:
              <div
                style={{
                  width: '80%',
                  marginTop: '1em',
                  background: theme.palette.grey[400],
                  border: '1px solid black',
                  borderLeft: `7px solid ${theme.palette.grey[700]}`,
                  borderRight: `7px solid ${theme.palette.grey[700]}`,
                }}
              >
                <span>← raccourcir ici</span>
                <span style={{ float: 'right' }}>ou ici →</span>
              </div>
            </li>
          </ul>
          <Typography>
            <li>
              En cliquant sur l’une des barres, vous serez redirigé vers la
              liste de documents correspondante. (La liste des documents en
              cours de traitement peut être différente de celle affichée dans le
              graphique)
            </li>
            <li>
              8&#9776; Cliquez sous une barre pour afficher le détail par
              entreprise.
            </li>
            <li>
              <span style={{ alignItems: 'center' }}>
                <Button variant='outlined' style={{ pointerEvents: 'none' }}>
                  &#9776;{' '}
                  {translate('dxMessages.dashboard.widgets.ListButton.title')}
                </Button>{' '}
                Afficher sous forme de tableau les détails par entreprise, pour
                l’intégralité du graphique.
              </span>
            </li>
            <li>
              Afficher (Jours / Mois / Ans): sélectionnez l'échelle de temps du
              diagramme.
            </li>
            <li>Plage de dates: sélectionnez la période désirée.</li>
          </Typography>
        </>
      );
    case 'ro':
      return (
        <>
          <Typography paragraph>
            Acest widget afișează numărul de documente schimbate prin
            intermediul platformei DocProcess, pe zi și pe status.
          </Typography>
          <Typography>
            Selectează luna care te interesează, apoi rafinează-ți căutarea
            prin:
          </Typography>
          <ul>
            <li>
              Filtrarea după status. Bifează casetele corespunzătoare din partea
              de jos a diagramei:
              <Box display='flex' flexDirection='row'>
                {STATUS_PER_DOC_TYPE[documentType]
                  .filter((status) =>
                    NON_DISPLAYABLE_STATUSES.find((s) => s !== status)
                  )
                  .map((status) => (
                    <Box
                      key={status}
                      display='flex'
                      flexDirection='row'
                      alignItems='center'
                    >
                      <Checkbox
                        size='small'
                        style={{ color: documentTypeColor(status, theme) }}
                        checked
                      />
                      <Typography variant='caption' noWrap>
                        {translate(`${I18N_KEY}.status.${status}`)}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </li>
            <li>
              Concentrarea pe o anumită perioadă. Utilizează bara din partea de
              jos a diagramei pentru a ajusta perioada afișată trăgând fiecare
              capăt al barei:
              <div
                style={{
                  width: '80%',
                  marginTop: '1em',
                  background: theme.palette.grey[400],
                  border: '1px solid black',
                  borderLeft: `7px solid ${theme.palette.grey[700]}`,
                  borderRight: `7px solid ${theme.palette.grey[700]}`,
                }}
              >
                <span>← micșorează aici</span>
                <span style={{ float: 'right' }}>sau aici →</span>
              </div>
            </li>
          </ul>
          <Typography>
            <li>
              Apăsând pe una dintre barele colorate, vei fi redirecționat către
              lista corespunzătoare de documente. (Lista documentelor aflate în
              procesare poate fi diferită de cea afișată în grafice.)
            </li>
            <li>
              8&#9776; Apăsând click sub bara graficului puteți vedea detalii în
              funcție de companie.
            </li>
            <li>
              <span style={{ alignItems: 'center' }}>
                <Button variant='outlined' style={{ pointerEvents: 'none' }}>
                  &#9776;{' '}
                  {translate('dxMessages.dashboard.widgets.ListButton.title')}
                </Button>{' '}
                Afișează detalii pe companie pentru întreg graficul.
              </span>
            </li>
            <li>
              Afișare (Zile / Luni / Ani): selectați intervalul de timp al
              graficului.
            </li>
            <li>Interval de date: selectați intervalul dorit.</li>
          </Typography>
        </>
      );
    default:
      return (
        <>
          <Typography paragraph>
            This widget displays the number of documents exchanged via the
            DocProcess platform, per day and per status.
          </Typography>
          <Typography>
            Select the month you are interested in, then refine your search by:
          </Typography>
          <ul>
            <li>
              Filtering per status. Click on the corresponding checkbox(es) at
              the bottom of the diagram:
              <Box display='flex' flexDirection='row'>
                {STATUS_PER_DOC_TYPE[documentType]
                  .filter((status) =>
                    NON_DISPLAYABLE_STATUSES.find((s) => s !== status)
                  )
                  .map((status) => (
                    <Box
                      key={status}
                      display='flex'
                      flexDirection='row'
                      alignItems='center'
                    >
                      <Checkbox
                        size='small'
                        style={{ color: documentTypeColor(status, theme) }}
                        checked
                      />
                      <Typography variant='caption' noWrap>
                        {translate(`${I18N_KEY}.status.${status}`)}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </li>
            <li>
              Zooming in on specific days. Use the brush bar at the bottom of
              the diagram and adjust the time period by sliding each end of the
              bar:
              <div
                style={{
                  width: '80%',
                  marginTop: '1em',
                  background: theme.palette.grey[400],
                  border: '1px solid black',
                  borderLeft: `7px solid ${theme.palette.grey[700]}`,
                  borderRight: `7px solid ${theme.palette.grey[700]}`,
                }}
              >
                <span>← squeeze here</span>
                <span style={{ float: 'right' }}>or here →</span>
              </div>
            </li>
          </ul>
          <Typography>
            <li>
              Clicking on one of the bars redirects to the corresponding list of
              documents. (List of documents in processing may be different than
              that shown in chart.)
            </li>
            <li>
              8&#9776; Clicking below a bar allows seeing details by company.
            </li>
            <li>
              <span style={{ alignItems: 'center' }}>
                <Button variant='outlined' style={{ pointerEvents: 'none' }}>
                  &#9776;{' '}
                  {translate('dxMessages.dashboard.widgets.ListButton.title')}
                </Button>{' '}
                Show details by company, for the entire chart.
              </span>
            </li>
            <li>
              Display (Days / Month / Years): select the level of detail the
              chart will display.
            </li>
            <li>Date range: select the period the chart will cover.</li>
          </Typography>
        </>
      );
  }
};

const useStyles = makeStyles((theme: DxTheme) => ({
  // Fixed into the top right corner.
  help: {
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(0),
  },
  bar: (props: { onTheShelves: boolean }) => {
    return {
      cursor: props.onTheShelves ? 'inherit' : 'pointer',
    };
  },
  date: {
    padding: '10px 10px 10px 10px',
  },
  form: { width: 120, padding: '10px 10px 10px 10px' },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
    overflowY: 'auto',
    overflowX: 'auto',
  },
}));

export const DAILY_RELATIONS_GRAPHQL_REQUEST = gql`
  query DailyDocumentMetricsPerRelationMetrics(
    $client: String!
    $startDate: String!
    $endDate: String!
    $docType: DocType!
    $role: RelationRole!
  ) {
    dailyDocumentMetricsPerRelationMetrics(
      client: $client
      startDate: $startDate
      endDate: $endDate
      docType: $docType
      role: $role
    ) {
      metrics {
        name
        value
        docType
        docStatus
        relationName
        dimensions {
          dateDimension {
            date
          }
        }
      }
    }
  }
`;

export const MONTHLY_RELATION_GRAPHQL_REQUEST = gql`
  query MonthlyDocumentMetricsPerRelationMetrics(
    $client: String!
    $startMonth: Int!
    $endMonth: Int!
    $startYear: Int!
    $endYear: Int!
    $docType: DocType!
    $role: RelationRole!
  ) {
    monthlyDocumentMetricsPerRelationMetrics(
      client: $client
      startMonth: $startMonth
      endMonth: $endMonth
      startYear: $startYear
      endYear: $endYear
      docType: $docType
      role: $role
    ) {
      metrics {
        name
        value
        docType
        docStatus
        relationName
        dimensions {
          dateDimension {
            month
            year
          }
        }
      }
    }
  }
`;

export const YEARS_RELATIONS_GRAPHQL_REQUEST = gql`
  query YearlyDocumentMetricsPerRelationMetrics(
    $client: String!
    $startYear: Int!
    $endYear: Int!
    $docType: DocType!
    $role: RelationRole!
  ) {
    yearlyDocumentMetricsPerRelationMetrics(
      client: $client
      startYear: $startYear
      endYear: $endYear
      docType: $docType
      role: $role
    ) {
      metrics {
        name
        value
        docType
        docStatus
        relationName
        dimensions {
          dateDimension {
            year
          }
        }
      }
    }
  }
`;

export default DocumentCountPerDayAndStatus;
