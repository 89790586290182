import {
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Language from '@material-ui/icons/Language';
import { useSetLocaleAndPreference } from '@react-admin/ra-preferences';
import { useFeature } from 'flagged';
import React, { useEffect, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { getAvailableTranslatedLocales } from '../intl/LocaleInfo';
import { LocalStorageHelpers } from '../utils/LocalStorageHelpers';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 'auto',
      height: 'auto',
      borderRadius: 24,
    },
    icon: {
      marginRight: '0.2em',
    },
    dxMenuIconButton: {
      width: 'auto',
      height: 'auto',
      padding: 12,
      borderRadius: 24,
      marginRight: '0.05em',
      color: 'inherit',
    },
  }),
  { name: 'LocaleSwitcher' }
);

// index 0 of 'options' is a disabled item used as Menu title
let options = [
  'dxMessages.user_menu.select_language',
  ...getAvailableTranslatedLocales(),
];

/**
 * Menu Item which interfaces with the redux CHANGE_LOCALE action
 */
const LocaleSwitcher = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  const arabicLanguage = useFeature('arabicLanguage');
  const hebrewLanguage = useFeature('hebrewLanguage');
  if (!arabicLanguage) {
    options = options.filter((l) => l !== 'ar');
  }
  if (!hebrewLanguage) {
    options = options.filter((l) => l !== 'he');
  }
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(options.indexOf(locale));
  const setLocale = useSetLocaleAndPreference();

  useEffect(() => {
    const updateLocaleDisplayed = () => {
      if (selectedIndex !== options.indexOf(locale)) {
        setSelectedIndex(options.indexOf(locale));
      }
    };
    updateLocaleDisplayed();
  }, [locale, selectedIndex]);

  useEffect(() => {
    const migratesOldLanguage = () => {
      let oldSavedLanguage;
      try {
        const user = localStorage.getItem('user');
        oldSavedLanguage = localStorage.getItem(user + '.language');
        if (oldSavedLanguage && oldSavedLanguage.length > 0) {
          setLocale(oldSavedLanguage);
          LocalStorageHelpers.removeItem(user + '.language');
        }
      } catch (e) {
        // don't care
      }
    };

    migratesOldLanguage();
  });

  const handleClickListItem = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleChangeLanguage = (event, index) => {
    setLocale(options[index]);
    setSelectedIndex(index);
    setAnchorElLanguage(null);
  };

  const handleClose = () => {
    setAnchorElLanguage(null);
  };

  return (
    <div className={classes.root}>
      <Tooltip
        title={translate('dxMessages.user_menu.language', {
          _: 'Help',
        })}
      >
        <IconButton
          className={classes.dxMenuIconButton}
          onClick={handleClickListItem}
          aria-label='Languages'
        >
          <Language className={classes.icon} />
          <Typography>{options[selectedIndex]}</Typography>
          <ArrowDropDownIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElLanguage}
        open={Boolean(anchorElLanguage)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        getContentAnchorEl={null}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            disabled={index === 0} // Menu title
            selected={index === selectedIndex}
            onClick={(event) => handleChangeLanguage(event, index)}
          >
            {index === 0 ? translate(option) : option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
});

export default LocaleSwitcher;
