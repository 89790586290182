import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccountDetailsEdit from './AccountDetailsEdit';
import AccountDetailsList from './AccountDetailsList';

const v2Company = {
  icon: LocationOnIcon,
  list: AccountDetailsList,
  edit: AccountDetailsEdit,
};

export default v2Company;
