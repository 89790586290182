/**
 * Displays the count of despatch advices since a date.
 */
import { CircularProgress, Typography } from '@material-ui/core';
import { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';

const DemoCount: FC<{ day: Moment }> = ({ day }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(undefined);
  const epoc = day.format('x');

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      setData(Math.floor(Math.random() * 20));
      setLoading(false);
    };
    getData();
  }, [epoc]);

  if (loading) return <CircularProgress size='1em' />;

  return (
    <Typography variant='h5' component='h2'>
      {data}
    </Typography>
  );
};

export default DemoCount;
