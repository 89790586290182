import { Constants } from '@dx-ui/dx-common';
import { withStyles } from '@material-ui/core/styles';
import { Query } from 'react-admin';

const styles = {
  message: {
    fontFamily: 'inherit',
    margin: 0,
  },
};

const PrettyDisplay = (props) => {
  const { className, message } = props;
  const anafDictionary = 'xmlns="mfp:anaf:dgti:efactura:mesajEroriFactuta:';

  if (message.lastIndexOf(anafDictionary) !== -1) {
    // This Aperak comes from E-factura
    // Extracts xml part and parse it
    const complexMessage: string[] = message.split(
      '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>'
    );

    const xmlMessage = complexMessage[1];
    var xmlMessagePart = new DOMParser().parseFromString(
      xmlMessage,
      'text/xml'
    );
    const errorNode = xmlMessagePart.querySelector('parsererror');
    if (errorNode) {
      // parsing failed
      return <pre className={className}>{complexMessage[0]}</pre>;
    } else {
      // parsing succeeded
      let msg: string[] = [];
      for (
        let i = 0;
        i < xmlMessagePart.documentElement.childElementCount;
        i++
      ) {
        const currentChild: any =
          xmlMessagePart.documentElement.children.item(i);
        msg.push(`${currentChild?.attributes?.errorMessage?.nodeValue}\n`);
      }
      return (
        <pre className={className}>
          {complexMessage[0]}
          {msg}
        </pre>
      );
    }
  } else {
    return <pre className={className}>{message}</pre>;
  }
};

export const AperakContentField = withStyles(styles)(
  ({ classes, record = {}, resource, translate, basePath }: any) => (
    <Query
      type={Constants.API_APERAK_DETAILS}
      resource={resource}
      payload={{
        record: record,
        basePath: basePath,
      }}
    >
      {({ data, loading, error }) => {
        if (error) {
          return (
            <span>
              {translate('dxMessages.showMetadata.aperakDetailsError')}
            </span>
          );
        }
        return loading ? (
          <span>{translate('dxMessages.download.inProgress')}</span>
        ) : (
          <PrettyDisplay className={classes.message} message={data.message} />
        );
      }}
    </Query>
  )
);

export default AperakContentField;
