import { DocumentTypeCode } from '@dx-ui/dx-common';
import { DataHelpers } from '../../../services/DataHelpers';
import DespatchAdviceLinesTable from './DespatchAdviceLinesTable';
import { FieldComponentProps } from './FieldComponentProps';
import { FormField } from './FormField';
import InvoiceLinesTable from './InvoiceLinesTable';
import OrderDocLinesTable from './OrderDocLinesTable';
import ReceiptAdviceLinesTable from './ReceiptAdviceLinesTable';

const renderItems = (items, otherProps) =>
  items?.map((item, idx) => {
    return (
      <FormField
        {...otherProps}
        key={`${item.name}_${idx}`}
        source={item.source} // need to set it here
        field={item}
      />
    );
  });

/**
 * Renders a lines table according to the type of document.
 */
const OrderLinesTable = (props: FieldComponentProps) => {
  const { record } = props;
  const documentTypeCode = DataHelpers.getDocumentTypeCode(record);
  if (documentTypeCode === DocumentTypeCode.INVOIC) {
    return <InvoiceLinesTable {...props} renderItems={renderItems} />;
  } else if (documentTypeCode === DocumentTypeCode.DESADV) {
    return <DespatchAdviceLinesTable {...props} renderItems={renderItems} />;
  } else if (documentTypeCode === DocumentTypeCode.RECADV) {
    return <ReceiptAdviceLinesTable {...props} renderItems={renderItems} />;
  } else if (documentTypeCode === DocumentTypeCode.ORDERS) {
    return <OrderDocLinesTable {...props} renderItems={renderItems} />;
  } else {
    return <span>Lines cannot be displayed. Document type not supported.</span>;
  }
};

export default OrderLinesTable;
