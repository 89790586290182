import {
  Checkbox,
  Chip,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { get, set } from 'lodash';
import PropTypes from 'prop-types';
import { ChoicesProps, FieldTitle, InputProps, useChoices } from 'ra-core';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  InputHelperText,
  Labeled,
  LinearProgress,
  SupportCreateSuggestionOptions,
} from 'react-admin';
import useInput from './useInput';
import { useSupportCreateSuggestion } from './useSupportCreateSuggestion';

/**
 * An Input component for a select box allowing multiple selections, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property as the option text
 * @example
 * const choices = [
 *    { id: 'programming', name: 'Programming' },
 *    { id: 'lifestyle', name: 'Lifestyle' },
 *    { id: 'photography', name: 'Photography' },
 * ];
 * <SelectArrayInput source="tags" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <SelectArrayInput source="authors" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <SelectArrayInput source="authors" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <SelectArrayInput source="authors" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'programming', name: 'myroot.tags.programming' },
 *    { id: 'lifestyle', name: 'myroot.tags.lifestyle' },
 *    { id: 'photography', name: 'myroot.tags.photography' },
 * ];
 */
const SelectArrayInput = (props: SelectArrayInputProps) => {
  const {
    choices = [],
    classes: classesOverride,
    className,
    create,
    createLabel,
    createValue,
    disableValue,
    format,
    helperText,
    titleSuffix,
    label,
    loaded,
    loading,
    margin = 'dense',
    onBlur,
    onChange,
    onCreate,
    onFocus,
    options,
    optionText,
    optionValue,
    itemOptionValue = '_', // "_" from Oasis Ubl 2.1 leaf values
    parse,
    resource,
    source,
    translateChoice,
    validate,
    variant = 'filled',
    ...rest
  } = props;

  if (create || onCreate) {
    throw new Error(
      'This is a custom version of SelectArrayInput. Creating new items not supported. This would need further adaptation of the original component.'
    );
  }

  const classes = useStyles(props);
  const inputLabel = useRef<any>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  /**
   * Complete list of values used by the select input control
   */
  const [choiceValues, setChoiceValues] = React.useState<any[]>([]);

  /**
   * Selected values
   */
  const [selectedValues, setSelectedValues] = React.useState<any[]>([]);

  /**
   * Choices in the select input control. When creating, the first choice is a special value choice.
   */
  const [finalChoices, setFinalChoices] = React.useState<any[]>([]);

  useEffect(() => {
    // Will be null while loading and we don't need this fix in that case
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  const { getChoiceText, getChoiceValue, getDisableValue } = useChoices({
    optionText,
    optionValue,
    disableValue,
    translateChoice,
  });
  const {
    input,
    isRequired,
    meta: { error, submitError, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const handleChange = useCallback(
    (event, newItem) => {
      if (newItem) {
        input.onChange([
          ...input.value,
          get(newItem.props.value, itemOptionValue),
        ]);
        return;
      }

      input.onChange(event);
    },
    [input, itemOptionValue]
  );

  const {
    getCreateItem,
    handleChange: handleChangeWithCreateSupport,
    createElement,
  } = useSupportCreateSuggestion({
    create,
    createLabel,
    createValue,
    handleChange,
    onCreate,
    optionText,
  });

  const createItem = create || onCreate ? getCreateItem() : null;

  const getItemValue = useCallback(
    (item) => {
      return get(item, itemOptionValue);
    },
    [itemOptionValue]
  );

  const setItemValue = useCallback(
    (item, value) => {
      return set(item, itemOptionValue, value);
    },
    [itemOptionValue]
  );

  useEffect(() => {
    const tempFinalChoices =
      create || onCreate ? [...choices, createItem] : choices;

    const tempChoiceValues = tempFinalChoices?.map((choice: any) => {
      const item = {};
      setItemValue(item, getChoiceValue(choice));
      return item;
    });

    setFinalChoices(tempFinalChoices);
    setChoiceValues(tempChoiceValues);
  }, [choices, create, createItem, getChoiceValue, onCreate, setItemValue]);

  const inputToDataValues = useCallback(
    (inputValues: any[]) => {
      if (!choiceValues?.length) {
        return [];
      }

      return inputValues.map((inputValue) =>
        choiceValues.find(
          (choiceValue) =>
            getItemValue(inputValue) === getItemValue(choiceValue)
        )
      );
    },
    [choiceValues, getItemValue]
  );

  useEffect(() => {
    const values = inputToDataValues(input.value || []);
    setSelectedValues(values);
  }, [input.value, inputToDataValues]);

  const renderMenuItemOption = useCallback(
    (choice) => getChoiceText(choice),
    [getChoiceText]
  );

  /**
   * Get the object corresponding to the choice. Select input does reference equality.
   * We need the same objects as in the values collection
   */
  const choiceToDataValue = useCallback(
    (choice) =>
      choiceValues.find((cv) => getItemValue(cv) === getChoiceValue(choice)),
    [choiceValues, getChoiceValue, getItemValue]
  );

  const renderMenuItem = useCallback(
    (choice, idx) => {
      return choice ? (
        <MenuItem
          key={idx} // changed for complex id types
          value={choiceToDataValue(choice)} // reference comparison - used to make selection work
          disabled={getDisableValue(choice)}
        >
          <Checkbox
            checked={selectedValues.indexOf(choiceToDataValue(choice)) > -1}
            size='small'
            color='default'
          />
          {!!createItem && choice?.id === createItem.id
            ? createItem.name
            : renderMenuItemOption(choice)}
        </MenuItem>
      ) : null;
    },
    [
      choiceToDataValue,
      createItem,
      getDisableValue,
      renderMenuItemOption,
      selectedValues,
    ]
  );

  if (loading) {
    return (
      <Labeled
        label={label}
        source={source}
        resource={resource}
        className={className}
        isRequired={isRequired}
        margin={margin}
      >
        <LinearProgress />
      </Labeled>
    );
  }

  return (
    <>
      <FormControl
        margin={margin}
        className={classnames(classes.root, className)}
        error={!!(error || submitError)}
        variant={variant}
        {...sanitizeRestProps(rest)}
      >
        <InputLabel
          ref={inputLabel}
          id={`${label}-outlined-label`}
          error={!!(error || submitError)}
        >
          <>
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
            {titleSuffix &&
              titleSuffix.map((s) => {
                return (
                  <Chip
                    label={s}
                    size='small'
                    style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                    key={s}
                  />
                );
              })}
          </>
        </InputLabel>
        <Select
          autoWidth
          labelId={`${label}-outlined-label`}
          multiple
          error={!!(error || submitError)}
          renderValue={(selected: any[]) => (
            <div className={classes.selected}>
              {selected?.length > 1 && (
                <Chip
                  label={selected?.length}
                  className={classes.chip}
                  size='small'
                />
              )}
              {selected
                .map((item) =>
                  choices.find((choice) => {
                    return getChoiceValue(choice) === getItemValue(item);
                  })
                )
                .filter((item) => !!item)
                .map((item) => renderMenuItemOption(item))
                .join(', ')}
            </div>
          )}
          data-testid='selectArray'
          {...input}
          onChange={handleChangeWithCreateSupport}
          value={selectedValues}
          labelWidth={labelWidth}
          {...options}
        >
          {choiceValues && finalChoices.map(renderMenuItem)}
        </Select>
        <FormHelperText error={!!(error || submitError)}>
          <InputHelperText
            touched={touched ?? false}
            error={error || submitError}
            helperText={helperText}
          />
        </FormHelperText>
      </FormControl>
      {createElement}
    </>
  );
};

export interface SelectArrayInputProps
  extends Omit<ChoicesProps, 'choices' | 'optionText'>,
    Omit<SupportCreateSuggestionOptions, 'handleChange'>,
    Omit<InputProps<SelectProps>, 'source'>,
    Omit<FormControlProps, 'defaultValue' | 'onBlur' | 'onChange' | 'onFocus'> {
  choices?: object[];
  source: string;
  itemOptionValue: string;
  labelSuffix?: any;
}

SelectArrayInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  disableValue: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
  translateChoice: PropTypes.bool,
};

SelectArrayInput.defaultProps = {
  options: {},
  optionText: 'name',
  optionValue: 'id',
  disableValue: 'disabled',
  translateChoice: true,
};

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  alwaysOn,
  basePath,
  choices,
  classNamInputWithOptionsPropse,
  componenInputWithOptionsPropst,
  crudGetMInputWithOptionsPropsatching,
  crudGetOInputWithOptionsPropsne,
  defaultValue,
  disableValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  loaded,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}: any) => rest;

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    chip: {
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[500],
      margin: theme.spacing(1 / 3),
    },
    selected: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'RaSelectArrayInput' }
);

export default SelectArrayInput;
