import { usePreferences } from '@react-admin/ra-preferences';
import { useEffect } from 'react';
import { getLastUsedApplication, getUser } from '../../security';
import { LocalStorageHelpers } from '../../utils/LocalStorageHelpers';
import useLocalStorageValueGeneric from '../../utils/useLocalStorageValueGeneric';

export interface SavedQuery {
  label: string;
  resource: string;
  displayedFilters: any;
  filter: any;
  perPage: number;
  sort: any;
  pathName?: any;
}

export const useSavedQueries = (): [
  SavedQuery[],
  (value: SavedQuery[]) => void
] => {
  const application = getLastUsedApplication();
  const user = getUser();
  const oldKey = `${application}.${user}.savedQueries`;
  const newKey = `${application}.savedQueries`;

  const [oldSavedQueries, setOldSavedQueries] = useLocalStorageValueGeneric<
    SavedQuery[]
  >('savedQueries', []);

  const [savedQueries, setSavedQueries] = usePreferences<SavedQuery[]>(
    newKey,
    []
  );

  useEffect(() => {
    const migratesOldQueries = () => {
      if (oldSavedQueries && oldSavedQueries.length > 0) {
        setSavedQueries(oldSavedQueries);
        setOldSavedQueries([]);
        LocalStorageHelpers.removeItem(oldKey);
      }
    };

    migratesOldQueries();
  }, [oldSavedQueries, setSavedQueries, setOldSavedQueries, oldKey]);

  return [savedQueries, setSavedQueries];
};
