// nested menu item for usage in a multi-level menu
import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import React, { useState } from 'react';
import { colors } from '../../constants/Colors';

const useStyles = makeStyles(
  (theme) => ({
    arrowDropDown: {
      transform: 'rotate(-90deg)',
      marginLeft: 'auto',
      paddingTop: 8,
      color: colors.black65,
    },
    menu: (props: { noAccessToMenu: boolean }) => {
      return {
        visibility: props.noAccessToMenu === true ? 'hidden' : 'visible',
      };
    },
  }),
  { name: 'NestingMenuItems' }
);

const NestingMenuItem = React.forwardRef<any, any>((props, ref) => {
  const {
    children,
    menuItems,
    noAccessToMenu = false,
    onClose,
    ...other
  } = props;
  const classes = useStyles({ noAccessToMenu });

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const preventDefault = (evt) => {
    evt.stopPropagation();
  };

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);

    const { onClose } = props;
    if (onClose) {
      onClose();
    }
  };

  return (
    <MenuItem {...other} onClick={handleClick}>
      {children}
      <ArrowDropDown className={classes.arrowDropDown} />
      <div
        onClick={preventDefault}
        onMouseDown={preventDefault}
        onMouseUp={preventDefault}
        onTouchStart={preventDefault}
        onTouchEnd={preventDefault}
      >
        <Menu
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          onClose={handleClose}
          classes={{ paper: classes.menu }}
        >
          {menuItems}
        </Menu>
      </div>
    </MenuItem>
  );
});

export default NestingMenuItem;
