/**
 * Display the count of documents received by DocProcess per document
 * type and per day.
 */
import { usePreferences } from '@react-admin/ra-preferences';
import moment, { Moment } from 'moment';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  CountPerDayAndStatusBarChart,
  DocumentType,
  Metrics,
  STATUS_PER_DOC_TYPE,
  Status,
} from '../live/DocumentCountPerDayAndStatus';

const DocumentCountPerDayAndStatusDemo: FC<{
  documentType: DocumentType;
  startDate: Moment;
  endDate: Moment;
  startMonth: number;
  endMonth: number;
  startYear: number;
  endYear: number;
  userPreferencesRootKey: string;
  companyPreferences: string[];
  onTheShelves: boolean;
  onClickOpenChild: any;
  openConfiguration: boolean;
  chartMetrics: Metrics[];
  rangeDates: string[];
  option: string;
  resultRange: Metrics[];
  rangeValue: string;
  chartData: Metrics[];
  monthlyCompanyMetrics: Metrics[];
  monthlyTable: any;
  yearlyTable: any;
  yearlyCompanyMetrics: Metrics[];
  inputStartYear: number;
  inputEndYear: number;
  setCompanyDateAndTypes: any;
  setPastCompanyDateAndTypes: any;
  setMonthsDateAndTypes: any;
  setYearsDateAndTypes: any;
  setFilterStatuses: Dispatch<SetStateAction<string[]>>;
  setChartData: Dispatch<SetStateAction<Metrics[]>>;
  setLastMonthMetrics: Dispatch<SetStateAction<Metrics[]>>;
}> = ({
  documentType,
  startDate,
  endDate,
  startMonth,
  endMonth,
  startYear,
  endYear,
  userPreferencesRootKey,
  companyPreferences,
  onTheShelves,
  onClickOpenChild,
  openConfiguration,
  chartMetrics,
  rangeDates,
  option,
  resultRange,
  rangeValue,
  chartData,
  monthlyCompanyMetrics,
  monthlyTable,
  yearlyTable,
  yearlyCompanyMetrics,
  inputStartYear,
  inputEndYear,
  setCompanyDateAndTypes,
  setPastCompanyDateAndTypes,
  setMonthsDateAndTypes,
  setYearsDateAndTypes,
  setFilterStatuses,
  setChartData,
  setLastMonthMetrics,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMonths, setLoadingMonths] = useState<boolean>(true);
  const [loadingYears, setLoadingYears] = useState<boolean>(true);
  const [data, setData] = useState<any>(undefined);
  const [dataMonths, setDataMonths] = useState<any>(undefined);
  const [dataYears, setDataYears] = useState<any>(undefined);
  const [statuses, setStatuses] = usePreferences(
    `${userPreferencesRootKey}.${documentType}.statuses`,
    STATUS_PER_DOC_TYPE[documentType]
  );

  const epocStart = startDate.format('x');
  const epocEnd = endDate.format('x');

  function getRandomItem(arr) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const item = arr[randomIndex];
    return item;
  }

  useEffect(() => {
    const array = ['Company1', 'Company2', 'Company3', 'Company4'];
    const getData = () => {
      setLoading(true);
      let data = {
        dailyDocumentMetricsPerRelationMetrics: { metrics: [] as any },
      };

      // based on sample entries, creates random data per current month
      [1, 2, 3, 4, 5].forEach(() => {
        const date = moment(
          parseInt(epocStart) +
            Math.floor(
              Math.random() * (parseInt(epocEnd) - parseInt(epocStart))
            )
        );

        STATUS_PER_DOC_TYPE[documentType].forEach((docStatus) => {
          data.dailyDocumentMetricsPerRelationMetrics.metrics.push({
            dimensions: {
              dateDimension: { date: date.format('YYYY-MM-DD') },
            },
            docStatus: docStatus,
            value: parseInt(Math.floor(Math.random() * 20).toString()),
            relationName: getRandomItem(array),
          });
        });
      });
      setData(data);
      setLoading(false);
    };
    getData();
  }, [epocStart, epocEnd, documentType]);

  useEffect(() => {
    if (option === 'months') {
      const companiesDemo = ['Company1', 'Company2', 'Company3', 'Company4'];
      const getMonthData = () => {
        setLoadingMonths(true);
        let dataMonths: any = {
          monthlyDocumentMetricsPerRelationMetrics: { metrics: [] },
        };
        let array: any = [];
        const currentQuarter = Math.floor(new Date().getMonth() / 3) + 1;
        if (rangeValue === 'thisQuarter') {
          for (let i = (currentQuarter - 1) * 3; i < currentQuarter * 3; i++) {
            array.push(i + 1);
          }
        } else if (rangeValue === 'lastQuarter') {
          var prevQuarter = currentQuarter - 1;
          if (prevQuarter < 1) {
            prevQuarter = 4;
          }
          for (let i = (prevQuarter - 1) * 3; i < prevQuarter * 3; i++) {
            array.push(i + 1);
          }
        } else {
          array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        }

        array.forEach(() => {
          const month: any =
            Math.floor(Math.random() * (endMonth - startMonth)) + 1;
          STATUS_PER_DOC_TYPE[documentType].forEach((docStatus) => {
            dataMonths?.monthlyDocumentMetricsPerRelationMetrics?.metrics.push({
              dimensions: {
                dateDimension: {
                  month: month,
                  year: startYear,
                },
              },
              docStatus: docStatus,
              value: Number(Math.floor(Math.random() * 300)),
              relationName: getRandomItem(companiesDemo),
            });
          });
        });
        setDataMonths(dataMonths);
        setLoadingMonths(false);
      };
      getMonthData();
    }
  }, [documentType, endMonth, option, rangeValue, startMonth, startYear]);

  useEffect(() => {
    if (option === 'years') {
      const companiesDemo = ['Company1', 'Company2', 'Company3', 'Company4'];
      const getYearlyData = () => {
        setLoadingYears(true);
        let data: any = {
          yearlyDocumentMetricsPerRelationMetrics: { metrics: [] },
        };

        // based on sample entries, creates random data per current month
        [1, 2, 3].forEach(() => {
          const years = [inputStartYear, inputStartYear + 1, inputEndYear];

          STATUS_PER_DOC_TYPE[documentType].forEach((docStatus) => {
            data?.yearlyDocumentMetricsPerRelationMetrics?.metrics.push({
              dimensions: {
                dateDimension: { year: getRandomItem(years) },
              },
              docStatus: docStatus,
              value: Number(Math.floor(Math.random() * 600)),
              relationName: getRandomItem(companiesDemo),
            });
          });
        });
        setDataYears(data);
        setLoadingYears(false);
      };
      getYearlyData();
    }
  }, [documentType, inputEndYear, inputStartYear, option]);

  // Record the invoice statuses to show in the bar chart.
  const onToggleInvoiceStatus = (status: Status, show: boolean) => {
    if (show && !statuses.includes(status)) setStatuses([...statuses, status]);
    else if (!show && statuses.includes(status))
      setStatuses(statuses.filter((_) => _ !== status));
  };

  return (
    <CountPerDayAndStatusBarChart
      queryLoadingDays={loading}
      queryDataDays={data}
      queryDataMonthsRelations={dataMonths}
      queryLoadingMonthsRelations={loadingMonths}
      queryLoadingYearsRelations={loadingYears}
      queryDataYearsRelations={dataYears}
      statuses={statuses}
      userPreferencesRootKey={userPreferencesRootKey}
      companyPreferences={companyPreferences}
      onToggleInvoiceStatus={onToggleInvoiceStatus}
      documentType={documentType}
      onTheShelves={onTheShelves}
      onClickOpenChild={onClickOpenChild}
      openConfiguration={openConfiguration}
      chartMetrics={chartMetrics}
      setFilterStatuses={setFilterStatuses}
      setCompanyDateAndTypes={setCompanyDateAndTypes}
      rangeDates={rangeDates}
      option={option}
      setChartData={setChartData}
      resultRange={resultRange}
      setLastMonthMetrics={setLastMonthMetrics}
      setPastCompanyDateAndTypes={setPastCompanyDateAndTypes}
      queryDataPreviousMonth={data}
      rangeValue={rangeValue}
      chartData={chartData}
      setMonthsDateAndTypes={setMonthsDateAndTypes}
      monthlyCompanyMetrics={monthlyCompanyMetrics}
      monthlyTable={monthlyTable}
      yearlyTable={yearlyTable}
      setYearsDateAndTypes={setYearsDateAndTypes}
      yearlyCompanyMetrics={yearlyCompanyMetrics}
    />
  );
};

export default DocumentCountPerDayAndStatusDemo;
