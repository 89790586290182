import React from 'react';
import { connect } from 'react-redux';
import { updateFormTemplate } from '../../actions/UpdateFormTemplate';
import DynamicFormView from './DynamicFormView';
import useDynamicFormController, {
  DynamicFormProps,
} from './useDynamicFormController';

const DynamicForm = (props: DynamicFormProps) => {
  const formProps = useDynamicFormController(props);
  return <DynamicFormView {...formProps} />;
};

const mapStateToProps = (state, ownProps) => {
  return {
    template: state.newFormTemplate,
  };
};

const enhance = connect(mapStateToProps, { updateFormTemplate });

export default enhance(DynamicForm);
