import { Constants, Metadata } from '@dx-ui/dx-common';
import {
  createCarrefourInvoiceTypeDetailsFilters,
  createColumnsCarrefourInvoiceTypeDetails,
  createColumnsContract,
  createColumnsDeliveryLocation,
  createColumnsDespatchAdvice,
  createColumnsOrder,
  createColumnsProcessDetails,
  createColumnsReceiptAdvice,
  createColumnsRegulatorDetails,
  createColumnsTotals,
  createCommonColumns,
  createCommonFilters,
  createContractFilters,
  createDeliveryLocationFilters,
  createDespatchAdviceFilters,
  createOrderFilters,
  createProcessDetailsFilters,
  createReceiptAdviceFilters,
  createRegulatorFilters,
  createSpecificColumnsInvoice,
  createSpecificInvoiceFilters,
  createTotalsFilters,
} from '../../../aspects';
import { InvoiceService } from '../../../services/InvoiceService';
import { CommonList } from '../../../shared';
import BulkDeleteActionButtons from '../../../shared/BulkDeleteActionButtons';
import ContextualToolbar from '../../../shared/ContextualToolbar';

/**
 * Declare the additional columns to display vs commons
 */
const columns: any[] = [
  ...createCommonColumns(),
  ...createColumnsContract(),
  ...createColumnsDeliveryLocation(),
  ...createColumnsTotals(),
  ...createColumnsOrder(),
  ...createColumnsProcessDetails(),
  ...createColumnsDespatchAdvice(),
  ...createColumnsReceiptAdvice(),
  ...createColumnsCarrefourInvoiceTypeDetails(),
  ...createSpecificColumnsInvoice(),
  ...createColumnsRegulatorDetails(),
];

// Change edm:documentTypeCode column configuration
// in order to always have this col for all documents.
columns
  .filter((col) => col.id === Metadata.orderId || col.id === Metadata.orderDate)
  .forEach((col) => {
    col.alwaysOn = true;
  });

/**
 * Specific filters
 */
const additionalFilters = [
  ...createCommonFilters(),
  ...createContractFilters(),
  ...createDeliveryLocationFilters(),
  ...createTotalsFilters(),
  ...createOrderFilters(),
  ...createProcessDetailsFilters(),
  ...createDespatchAdviceFilters(),
  ...createReceiptAdviceFilters(),
  ...createCarrefourInvoiceTypeDetailsFilters(),
  ...createSpecificInvoiceFilters(),
  ...createRegulatorFilters(),
];

const WebInvoiceList = (props) => {
  const { permissions } = props;
  return (
    <CommonList
      title='dxMessages.invoices.title'
      {...props}
      columns={columns}
      additionalFilters={additionalFilters}
      contextualToolbar={
        <ContextualToolbar
          resource={Constants.RESOURCE_INVOICE}
          allowEdit={InvoiceService.canCreate(permissions)}
        />
      }
      bulkActions={<BulkDeleteActionButtons />}
    />
  );
};

export default WebInvoiceList;
