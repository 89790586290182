import {
  cellStyle,
  DateInput,
  GenericDateField,
  GenericSimpleField,
  Metadata,
  MultiSelectInput,
  ReceiptAdviceSubType,
  SubTypeLabels,
  widthPresets,
} from '@dx-ui/dx-common';
import { TextInput } from 'react-admin';
import { DocumentSubTypeField } from './CommonFields';

export const ReceiptAdviceIdField = (props) => (
  <GenericSimpleField {...props} />
);

export const ReceiptAdviceDateField = (props) => (
  <GenericDateField {...props} />
);

export const createColumnsReceiptAdvice = () => [
  {
    id: Metadata.receiptAdviceId,
    label: 'dxMessages.headers.receiptAdviceId',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <ReceiptAdviceIdField {...props} />,
  },
  {
    id: Metadata.receiptAdviceDate,
    label: 'dxMessages.headers.receiptAdviceDate',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <ReceiptAdviceDateField {...props} />,
  },
];

export const createSpecificColumnsReceiptAdvice = () => [
  {
    id: Metadata.documentSubTypeCode,
    label: 'dxMessages.headers.documentSubTypeCode',
    sortable: false,
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <DocumentSubTypeField {...props} />,
  },
];

export const createReceiptAdviceFilters = () => [
  {
    id: Metadata.receiptAdviceId,
    filter: (props) => (
      <TextInput
        source={Metadata.receiptAdviceId}
        label='dxMessages.headers.receiptAdviceId'
        resettable
        {...props}
      />
    ),
  },
  {
    id: Metadata.receiptAdviceDate,
    filter: (props) => (
      <DateInput
        source={Metadata.receiptAdviceDate}
        label='dxMessages.headers.receiptAdviceDate'
        {...props}
      />
    ),
  },
];

export const createSpecificReceiptAdviceFilters = () => [
  {
    id: Metadata.documentSubTypeCode,
    filter: (props) => (
      <MultiSelectInput
        label='dxMessages.headers.documentSubTypeCode'
        source={Metadata.documentSubTypeCode}
        width={widthPresets.large}
        sortLabels={true}
        {...props}
        options={[
          {
            id: ReceiptAdviceSubType.GoodsReceiptAdvice,
            label: SubTypeLabels[ReceiptAdviceSubType.GoodsReceiptAdvice],
          },
          {
            id: ReceiptAdviceSubType.GoodsReturnAdvice,
            label: SubTypeLabels[ReceiptAdviceSubType.GoodsReturnAdvice],
          },
          {
            id: ReceiptAdviceSubType.AcknowledgementOfReceipt,
            label: 'dxMessages.receiptAdvices.palletReceiptNotice',
          },
        ]}
      />
    ),
  },
];
