import { DxTheme, sendGAEvent } from '@dx-ui/dx-common';
import { Chip, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ActionDelete from '@material-ui/icons/Delete';
import classNames from 'classnames';
import { cloneDeep, get } from 'lodash';
import PropTypes from 'prop-types';
import { Record, ValidationError, useTranslate } from 'ra-core';
import {
  Children,
  FC,
  ReactElement,
  cloneElement,
  isValidElement,
  useRef,
} from 'react';
import { ClassesOverride, FormInput, useGetIdentity } from 'react-admin';
import { useForm } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { GA_EVENTS } from '../../../GAUtils';
import { FormDataHelpers } from '../../../services/FormDataHelpers';
/*
Based on the react-admin source code for SimpleFormIterator component

TODO: find a way to use the default iterator and delete this one

*/

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      '& > li:last-child': {
        borderBottom: 'none',
      },
    },
    line: {
      display: 'flex',
      listStyleType: 'none',
      [theme.breakpoints.down('xs')]: { display: 'block' },
      '&.fade-enter': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
      },
      '&.fade-enter-active': {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'all 500ms ease-in',
      },
      '&.fade-exit': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '&.fade-exit-active': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
        transition: 'all 500ms ease-in',
      },
      marginRight: 20,
      '& section > div': {
        marginRight: 20,
      },
    },
    index: {
      position: 'relative',
      top: '1.5em',
      backgroundColor: 'transparent',
      border: `1px solid transparent`,
      marginRight: '1em',
      fontSize: '1rem',
    },
    stickyIndex: {
      background: theme.app.tableBkgColor,
      display: 'flex',
      position: 'sticky',
      left: 0,
      top: 0,
      zIndex: 20,
    },
    form: {
      flex: 2,
      display: 'flex',
    },
    action: {
      paddingTop: '0.5em',
      display: 'flex',
    },
    actionRemove: {
      paddingTop: '1.5em',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    deleteButton: {
      color: theme.palette.primary.dark,
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.dark, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    lineErrorIndicator: {
      paddingTop: '1.5em',
      paddingRight: '0.8em',
    },
    error: {
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
    },
    hidden: {
      visibility: 'hidden',
    },
  }),
  { name: 'OrderSimpleFormIterator' }
);

const DefaultAddButton = (props) => {
  const classes = useStyles(props);
  const translate = useTranslate();
  return (
    <Button size='small' {...props}>
      <AddIcon className={classes.leftIcon} />
      {translate('ra.action.add')}
    </Button>
  );
};

const DefaultRemoveButton = (props) => {
  const classes = useStyles(props);
  const translate = useTranslate();
  return (
    <Tooltip title={translate('ra.action.remove')}>
      <Button size='small' {...props} className={classes.deleteButton}>
        <ActionDelete />
      </Button>
    </Tooltip>
  );
};

const SimpleFormIterator: FC<SimpleFormIteratorProps> = (props) => {
  const {
    addButton = <DefaultAddButton />,
    removeButton = <DefaultRemoveButton />,
    basePath,
    children,
    className,
    fields,
    meta,
    record,
    resource,
    source,
    disabled,
    disableAdd,
    disableRemove,
    variant,
    margin,
    TransitionProps,
    defaultValue,
    createNewLine,
    noComputation = false,
  } = props;

  const { error, submitFailed } = { ...meta };
  const classes = useStyles(props);
  const nodeRef = useRef(null);
  const form = useForm();
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  // We need a unique id for each field for a proper enter/exit animation
  // so we keep an internal map between the field position and an auto-increment id
  const nextId = useRef(
    fields && fields.length
      ? fields.length
      : defaultValue
      ? defaultValue.length
      : 0
  );

  // We check whether we have a defaultValue (which must be an array) before checking
  // the fields prop which will always be empty for a new record.
  // Without it, our ids wouldn't match the default value and we would get key warnings
  // on the CssTransition element inside our render method
  const ids = useRef(
    nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
  );

  const removeField = (index) => () => {
    ids.current.splice(index, 1);
    fields?.remove(index);
  };

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determining if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  const disableRemoveField = (record, disableRemove) => {
    if (typeof disableRemove === 'boolean') {
      return disableRemove;
    }
    return disableRemove && disableRemove(record);
  };

  const addField = () => {
    ids.current.push(nextId.current++);

    let newLine: any = undefined;
    if (typeof createNewLine === 'function') {
      newLine = createNewLine(record);
    }
    fields?.push(newLine);
  };

  // add field and call the onClick event of the button passed as addButton prop
  const handleAddButtonClick = (originalOnClickHandler) => (event) => {
    sendGAEvent(
      GA_EVENTS.categories.FORM.name,
      GA_EVENTS.categories.FORM.actions.ADD_LINE,
      account?.company?.cmsRootDir
    );

    addField();
    if (originalOnClickHandler) {
      originalOnClickHandler(event);
    }
  };

  const updateLinesData = () => {
    const originalData = form.getState().values;
    const newData: any = cloneDeep(originalData);

    !noComputation && FormDataHelpers.recalculateTaxesAndPrices(newData);
    !noComputation && FormDataHelpers.manageMonoVat(newData);
    !noComputation && FormDataHelpers.manageMonoTaxCategory(newData);

    form.batch(() => {
      const properties = Object.keys(newData).filter((t) => t !== 'id');
      properties.forEach((propName) =>
        form.change(propName, newData[propName])
      );
    });
  };

  // remove field and call the onClick event of the button passed as removeButton prop
  const handleRemoveButtonClick =
    (originalOnClickHandler, index) => (event) => {
      sendGAEvent(
        GA_EVENTS.categories.FORM.name,
        GA_EVENTS.categories.FORM.actions.REMOVE_LINE,
        account?.company?.cmsRootDir
      );

      removeField(index)();

      // once the field is removed => data reprocessing
      updateLinesData();

      if (originalOnClickHandler) {
        originalOnClickHandler(event);
      }
    };

  const records = source ? get(record, source) : undefined;

  const errorAtLine = (index) => error && error[index];

  return fields ? (
    <ul className={classNames(classes.root, className)}>
      {submitFailed && typeof error !== 'object' && error && (
        <FormHelperText error>
          <ValidationError error={error as string} />
        </FormHelperText>
      )}
      <TransitionGroup component={null}>
        {fields.map((member, index) => (
          <CSSTransition
            nodeRef={nodeRef}
            key={ids.current[index]}
            timeout={500}
            classNames='fade'
            {...TransitionProps}
          >
            <li className={classes.line}>
              <section className={classes.form}>
                <div className={classes.stickyIndex}>
                  <Tooltip
                    title={
                      (errorAtLine(index) &&
                        translate('dxMessages.error_messages.line_error')) ||
                      ''
                    }
                  >
                    <Chip
                      label={index + 1}
                      className={classNames(
                        classes.index,
                        errorAtLine(index) && classes.error
                      )}
                      size='medium'
                    />
                  </Tooltip>
                  {!disabled &&
                    !disableRemoveField(
                      (records && records[index]) || {},
                      disableRemove
                    ) && (
                      <div className={classes.actionRemove}>
                        {cloneElement(removeButton, {
                          onClick: handleRemoveButtonClick(
                            removeButton.props.onClick,
                            index
                          ),
                          className: classNames(
                            'button-remove',
                            `button-remove-${source}-${index}`
                          ),
                        })}
                      </div>
                    )}
                </div>
                {Children.map<any, any>(
                  children,
                  (input: ReactElement, index2) => {
                    if (!isValidElement<any>(input)) {
                      return null;
                    }
                    const { source, ...inputProps } = input.props;
                    return (
                      <FormInput
                        basePath={input.props.basePath || basePath}
                        input={cloneElement(input, {
                          source: source ? `${member}.${source}` : member,
                          index: source ? undefined : index2,
                          label:
                            typeof input.props.label === 'undefined'
                              ? source
                                ? `resources.${resource}.fields.${source}`
                                : undefined
                              : input.props.label,
                          disabled,
                          ...inputProps,
                        })}
                        record={(records && records[index]) || {}}
                        resource={resource}
                        variant={variant}
                        margin={margin}
                      />
                    );
                  }
                )}
              </section>
            </li>
          </CSSTransition>
        ))}
      </TransitionGroup>
      {!disabled && !disableAdd && (
        <li className={classes.line}>
          <span className={classes.action}>
            {cloneElement(addButton, {
              onClick: handleAddButtonClick(addButton.props.onClick),
              className: classNames('button-add', `button-add-${source}`),
            })}
          </span>
        </li>
      )}
    </ul>
  ) : null;
};

SimpleFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
};

SimpleFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  addButton: PropTypes.element,
  removeButton: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  // @ts-ignore
  fields: PropTypes.object,
  meta: PropTypes.object,
  // @ts-ignore
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  TransitionProps: PropTypes.shape({}),
  createNewLine: PropTypes.func,
  noComputation: PropTypes.bool,
};

type DisableRemoveFunction = (record: Record) => boolean;

export interface SimpleFormIteratorProps
  extends Partial<Omit<FieldArrayRenderProps<any, HTMLElement>, 'meta'>> {
  addButton?: ReactElement;
  basePath?: string;
  classes?: ClassesOverride<typeof useStyles>;
  className?: string;
  defaultValue?: any;
  disabled?: boolean;
  disableAdd?: boolean;
  disableRemove?: boolean | DisableRemoveFunction;
  margin?: 'none' | 'normal' | 'dense';
  meta?: {
    // the type defined in FieldArrayRenderProps says error is boolean, which is wrong.
    error?: any;
    submitFailed?: boolean;
  };
  record?: Record;
  removeButton?: ReactElement;
  resource?: string;
  source?: string;
  TransitionProps?: any;
  variant?: 'standard' | 'outlined' | 'filled';
  createNewLine?: (data?: any) => any;
  noComputation?: boolean;
}

export default SimpleFormIterator;
