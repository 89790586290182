import { useGAPageViews } from '@dx-ui/dx-common';
import { ShowContext, useShowController } from 'react-admin';
import ReceiptAdviceShowView from './ReceiptAdviceShowView';

const ReceiptAdviceShow = (props) => {
  useGAPageViews();
  const controllerProps = useShowController(props);
  return (
    <ShowContext.Provider value={controllerProps}>
      <ReceiptAdviceShowView controllerProps={controllerProps} {...props} />
    </ShowContext.Provider>
  );
};

export default ReceiptAdviceShow;
