import { fetchUtils, useGetIdentity, useNotify } from 'react-admin';

const useSwitchTo = () => {
  const { identity } = useGetIdentity();
  const notify = useNotify();

  const switchTo = (url: string, appName?: string) => {
    fetchUtils
      .fetchJson(`/api/auth/switchAppToken`, {
        credentials: 'include',
      })
      .then((resp: any) => {
        if (appName) {
          // Small hack here to set the local storage to help with switching
          // app smoother.
          localStorage.setItem(
            identity?.version === 3
              ? identity?.person.email + '.dxApplication'
              : identity?.id + '.dxApplication',
            appName
          );
        }
        window.location.assign(`${url}?token=${resp.body}`);
      })
      .catch((error) =>
        notify(
          'dxMessages.error_messages.OnError_contact_the_support',
          'error',
          {
            error: error.message,
          }
        )
      );
  };

  return switchTo;
};

export default useSwitchTo;
