import {
  Constants,
  DocumentTypeCode,
  DxTheme,
  GA_EVENTS,
  Metadata,
  readStatusChange,
  sendGAEvent,
} from '@dx-ui/dx-common';
import { Drawer, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconInfo from '@material-ui/icons/Info';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Fragment, useState } from 'react';
import { useGetIdentity, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import ShowMetadata from './ShowMetadata';

const useStyles: any = makeStyles(
  (theme: DxTheme) => ({
    header: {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      minHeight: 60,
    },
    field: {
      width: 400,
      [theme.breakpoints.down('sm')]: {
        width: '90vw',
      },
      '& pre': {
        whiteSpace: 'pre-wrap',
      },
    },
    button: {
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgb(255,255,255,0.2)',
      },
      margin: '0 5px',
    },
    title: {
      color: 'white',
    },
  }),
  { name: 'ShowMetadataButton' }
);

const ShowMetadataButton = (props) => {
  const {
    resource,
    title,
    record,
    className,
    basePath,
    groupingMode = true,
  } = props;

  const classes = useStyles(props);
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const [showPanel, setShowPanel] = useState(false);

  const handleClick = () => {
    const action: any = (name) => {
      switch (name) {
        case DocumentTypeCode.APERAK:
          return 'Message';
        case DocumentTypeCode.CATLOG:
          return 'Catalog';
        case DocumentTypeCode.CNTCND:
          return 'Contract';
        case DocumentTypeCode.DESADV:
          return 'Despatch Advice';
        case DocumentTypeCode.DELFOR:
          return 'Forecast';
        case DocumentTypeCode.INVOIC:
          return 'Invoice';
        case DocumentTypeCode.ATTACH:
          return 'Attachment';
        case DocumentTypeCode.ORDERS:
          return 'Order';
        case DocumentTypeCode.RECADV:
          return 'Receipt Advice';
        case DocumentTypeCode.WAYBIL:
          return 'CMR';
      }
    };
    sendGAEvent(
      GA_EVENTS.categories.SHOW_METADATA.name,
      record ? action(record.properties[Metadata.documentTypeCode]) : undefined,
      account?.company?.cmsRootDir,
      record ? record.properties[Metadata.dxuid] : undefined
    );

    setShowPanel(true);
  };

  const handleCloseClick = () => {
    setShowPanel(false);
    if (
      record &&
      record.properties &&
      record.properties[Metadata.readStatus] === Constants.READ_STATUS_NEW
    ) {
      record.properties[Metadata.readStatus] = Constants.READ_STATUS_READ;
    }
    dispatch(readStatusChange(Constants.READ_STATUS_READ));
  };

  return (
    <Fragment>
      <Tooltip title={translate(title, { _: title })}>
        <IconButton color='primary' onClick={handleClick} className={className}>
          <IconInfo />
        </IconButton>
      </Tooltip>
      <Drawer anchor='right' open={showPanel} onClose={handleCloseClick}>
        <div className={classes.header}>
          <IconButton className={classes.button} onClick={handleCloseClick}>
            <IconKeyboardArrowRight />
          </IconButton>
          <Typography variant='h6' className={classes.title}>
            {translate(title, { _: title })}
          </Typography>
        </div>
        <ShowMetadata
          className={classes.field}
          resource={resource}
          record={record}
          basePath={basePath}
          groupingMode={groupingMode}
        />
      </Drawer>
    </Fragment>
  );
};

export default ShowMetadataButton;
