import {
  cellStyle,
  GenericSimpleField,
  Metadata,
  widthPresets,
} from '@dx-ui/dx-common';
import React from 'react';
import { TextInput } from 'react-admin';

export const CarrierPartyNameField = (props) => (
  <GenericSimpleField {...props} />
);

export const ConsignorPartyNameField = (props) => (
  <GenericSimpleField {...props} />
);

export const LoadingLocationAddressField = (props) => (
  <GenericSimpleField {...props} />
);

export const LoadingLocationGlnField = (props) => (
  <GenericSimpleField {...props} />
);

export const LoadingLocationNameField = (props) => (
  <GenericSimpleField {...props} />
);

export const UnloadingLocationAddressField = (props) => (
  <GenericSimpleField {...props} />
);

export const UnloadingLocationGlnField = (props) => (
  <GenericSimpleField {...props} />
);

export const UnloadingLocationNameField = (props) => (
  <GenericSimpleField {...props} />
);

export const createColumnsTransportDetails = () => [
  {
    id: Metadata.carrierPartyName,
    label: 'dxMessages.headers.carrierPartyName',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <CarrierPartyNameField {...props} />,
  },
  {
    id: Metadata.consignorPartyName,
    label: 'dxMessages.headers.consignorPartyName',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <ConsignorPartyNameField {...props} />,
  },
  {
    id: Metadata.loadingLocationAddress,
    label: 'dxMessages.headers.loadingLocationAddress',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <LoadingLocationAddressField {...props} />,
  },
  /*  {
    id: Metadata.loadingLocationGln,
    label: 'dxMessages.headers.loadingLocationGln',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <LoadingLocationGlnField {...props} />
  }, */
  {
    id: Metadata.loadingLocationName,
    label: 'dxMessages.headers.loadingLocationName',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <LoadingLocationNameField {...props} />,
  },
  {
    id: Metadata.unloadingLocationAddress,
    label: 'dxMessages.headers.unloadingLocationAddress',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <UnloadingLocationAddressField {...props} />,
  },
  /* {
    id: Metadata.unloadingLocationGln,
    label: 'dxMessages.headers.unloadingLocationGln',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <UnloadingLocationGlnField {...props} />
  }, */
  {
    id: Metadata.unloadingLocationName,
    label: 'dxMessages.headers.unloadingLocationName',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <UnloadingLocationNameField {...props} />,
  },
];

export const createTransportDetailsFilters = () => [
  {
    id: Metadata.carrierPartyName,
    filter: (props) => (
      <TextInput
        source={Metadata.carrierPartyName}
        label='dxMessages.headers.carrierPartyName'
        resettable
        {...props}
      />
    ),
  },
  {
    id: Metadata.consignorPartyName,
    filter: (props) => (
      <TextInput
        source={Metadata.consignorPartyName}
        label='dxMessages.headers.consignorPartyName'
        resettable
        {...props}
      />
    ),
  },
  {
    id: Metadata.loadingLocationAddress,
    filter: (props) => (
      <TextInput
        source={Metadata.loadingLocationAddress}
        label='dxMessages.headers.loadingLocationAddress'
        resettable
        {...props}
      />
    ),
  },
  /* {
    id: Metadata.loadingLocationGln,
    filter: (props) => (
      <GLNInput
        source={Metadata.loadingLocationGln}
        label='dxMessages.headers.loadingLocationGln'
        resettable
        {...props}
      />
    )
  }, */
  {
    id: Metadata.loadingLocationName,
    filter: (props) => (
      <TextInput
        source={Metadata.loadingLocationName}
        label='dxMessages.headers.loadingLocationName'
        resettable
        {...props}
      />
    ),
  },
  {
    id: Metadata.unloadingLocationAddress,
    filter: (props) => (
      <TextInput
        source={Metadata.unloadingLocationAddress}
        label='dxMessages.headers.unloadingLocationAddress'
        resettable
        {...props}
      />
    ),
  },
  /* {
    id: Metadata.unloadingLocationGln,
    filter: (props) => (
      <GLNInput
        source={Metadata.unloadingLocationGln}
        label='dxMessages.headers.unloadingLocationGln'
        resettable
        {...props}
      />
    )
  }, */
  {
    id: Metadata.unloadingLocationName,
    filter: (props) => (
      <TextInput
        source={Metadata.unloadingLocationName}
        label='dxMessages.headers.unloadingLocationName'
        resettable
        {...props}
      />
    ),
  },
];
