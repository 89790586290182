import { colors } from '@dx-ui/dx-common';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const i18nKey =
  'dxMessages.task.invoice_matching.cancellationDecisionTask.cockpit.';

// The toolbar of the n-way matching cockpit for the cancel invoice.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const NwayMatchingCockpitToolbar = (props) => {
  const { record } = props;
  if (!record) return null;

  return (
    <Toolbar {...props}>
      <SaveButton
        label={i18nKey + 'button.cancelInvoice'}
        icon={<ThumbUpIcon />}
        transform={buildRequest('approve')}
        disabled={props.pristine || props.saving}
      />
      <SaveButton
        label={i18nKey + 'button.rejectCancellation'}
        icon={<ThumbDownIcon />}
        transform={buildRequest('reject')}
        style={{ margin: '2px', backgroundColor: colors.brightOrange }}
        disabled={props.pristine || props.saving}
      />
    </Toolbar>
  );
};

const buildRequest = (action: string) => (props: any) => {
  return {
    input: {
      data: props.input.data,
      action: action,
    },
  };
};

export default NwayMatchingCockpitToolbar;
