import {
  cellStyle,
  GenericSimpleField,
  Metadata,
  NumberRangeInput,
  widthPresets,
} from '@dx-ui/dx-common';
import React from 'react';

/**
 * Fields which correspond to the edm:totals aspect
 */
export const CurrencyField = (props) => <GenericSimpleField {...props} />;

export const TotalWithVatField = (props) => <GenericSimpleField {...props} />;

export const TotalWithoutVatField = (props) => (
  <GenericSimpleField {...props} />
);

export const TotalVatField = (props) => <GenericSimpleField {...props} />;

export const createColumnsTotals = () => [
  {
    id: Metadata.currency,
    label: 'dxMessages.headers.currency',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    alignRight: true,
    renderCell: (props) => <CurrencyField {...props} />,
  },
  {
    id: Metadata.totalWithVat,
    label: 'dxMessages.headers.totalWithVat',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    alignRight: true,
    renderCell: (props) => <TotalWithVatField {...props} />,
  },
  {
    id: Metadata.totalWithoutVat,
    label: 'dxMessages.headers.totalWithoutVat',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    alignRight: true,
    renderCell: (props) => <TotalWithoutVatField {...props} />,
  },
  {
    id: Metadata.totalVat,
    label: 'dxMessages.headers.totalVat',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    alignRight: true,
    renderCell: (props) => <TotalVatField {...props} />,
  },
];

export const createTotalsFilters = () => [
  {
    id: Metadata.totalVat,
    filter: (props) => (
      <NumberRangeInput
        source={Metadata.totalVat}
        label='dxMessages.headers.totalVat'
        {...props}
      />
    ),
  },
  {
    id: Metadata.totalWithVat,
    filter: (props) => (
      <NumberRangeInput
        source={Metadata.totalWithVat}
        label='dxMessages.headers.totalWithVat'
        {...props}
      />
    ),
  },
  {
    id: Metadata.totalWithoutVat,
    filter: (props) => (
      <NumberRangeInput
        source={Metadata.totalWithoutVat}
        label='dxMessages.headers.totalWithoutVat'
        {...props}
      />
    ),
  },
];
