import {
  AutocompleteInput,
  DateTimeInput,
  Edit,
  maxLength,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';

export const DxProductEdit = (props: any) => {
  const notify = useNotify();

  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.updated');
    redirect('list', props.basePath);
  };

  return (
    <Edit {...props} onSuccess={onSuccess} undoable={false}>
      <SimpleForm>
        <TextInput
          label={'dxMessages.companyManagement.dxProduct.description'}
          source='description'
          disabled
        />
        <TextInput
          label={'dxMessages.companyManagement.dxProduct.comments'}
          source='comments'
          validate={[maxLength(1000)]}
        />
        <SelectInput
          label={'dxMessages.companyManagement.dxProduct.flgActive'}
          source='flgActive'
          choices={[
            { id: 1, name: 'Active' },
            { id: 0, name: 'Inactive' },
          ]}
          validate={[required(), maxLength(50)]}
        />
        <ReferenceInput
          label='dxMessages.companyManagement.dxProduct.idProductGroup'
          source='idProductGroup'
          reference='management/dropdowndefinition'
          validate={required()}
          filterToQuery={(searchText) => ({
            description: [searchText],
            idType: '10',
          })}
          sort={{ field: 'description', order: 'ASC' }}
        >
          <AutocompleteInput optionText='description' fullWidth />
        </ReferenceInput>
        <DateTimeInput
          label={'dxMessages.companyManagement.audit.tsChange'}
          source='tsChange'
          disabled
        />
        <TextInput
          label={'dxMessages.companyManagement.audit.userIdChange'}
          source='userIdChange'
          disabled
        />
      </SimpleForm>
    </Edit>
  );
};

export default DxProductEdit;
