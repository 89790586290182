import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { muiTheme } from '../themes/CreateTheme';
import { DxTheme } from '../types';
import DocProcessStackedLogo from './DocProcessStackedLogo';
import Login from './Login';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';
import LostPasswordForm from './SendLostPasswordEmailForm';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    home: {
      backgroundImage: "url('/ripple-zigzag-10.png')",
      backgroundSize: '100%',
    },
    centerMe: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '86vh',
    },
    logo: {
      height: '14vh',
    },
    main: {
      // Login react-admin main
      height: 'auto',
      minHeight: 'auto',
    },
    card: {
      // Login react-admin card
      padding: '2rem',
      marginTop: 'unset',
      '& button': {
        width: 'unset',
      },
      '& form > div:last-child': {
        justifyContent: 'flex-end',
      },
    },
    hideAvatar: {
      display: 'none',
    },
    content: {
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'LoginPage' }
);

const DxLoginForm = (props) => {
  const translate = useTranslate();
  return (
    <div>
      <LoginForm {...props} />
      <Typography variant='body1'>
        <Link to='/lost-password'>
          {translate('dxMessages.auth.lost_password_title')}
        </Link>
      </Typography>
    </div>
  );
};

const LoginPage = (props) => {
  const { resetPassword, lostPassword } = props;
  const classes = useStyles();

  return (
    <div className={classes.home}>
      <div className={classes.logo}>
        <DocProcessStackedLogo />
      </div>
      <div className={classes.centerMe}>
        <div className={classes.container}>
          <Login
            classes={{
              main: classes.main,
              card: classes.card,
              avatar: (lostPassword || resetPassword) && classes.hideAvatar,
            }}
            backgroundImage={undefined}
            theme={muiTheme}
          >
            {(resetPassword && <ResetPasswordForm />) ||
              (lostPassword && <LostPasswordForm />) || (
                <DxLoginForm {...props} />
              )}
          </Login>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
