import { Constants, isPspAdministrator } from '@dx-ui/dx-common/src';
import { Button, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  FieldProps,
  FormDataConsumer,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  maxLength,
  minLength,
  number,
  required,
  useLocale,
  useNotify,
  usePermissions,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { CountryService } from '../../../../services/CountryService';
import { Address } from '../../../../shared/types';
import UploadFilesField from '../UploadFilesField';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

/**
 * The edition of a person.
 *
 * Only visible for PSP administrators.
 */
const AddressDetailsCreate: any = ({ setCreate, ...props }) => {
  const notify = useNotify();

  const redirect = useRedirect();

  const translate = useTranslate();

  const { permissions } = usePermissions();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.created');
    if (setCreate !== undefined) {
      setCreate(false);
    } else {
      redirect('list', props.basePath);
    }
  };

  const onFailure = (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error?.status === 409) {
      //address already exist
      notify('dxMessages.error_messages.address_already_exist', 'error');
    } else {
      //unknown error
      notify('dxMessages.error_messages.OnError_contact_the_support', 'error', {
        error: error.message,
      });
    }
  };

  const validateCreate = (values: any, translate: any) => {
    const errors: any = {};

    const flgBilling: boolean = values.flgBilling;
    const flgShipping: boolean = values.flgShipping;
    const flgLogistic: boolean = values.flgLogistic;

    if (!flgBilling && !flgLogistic && !flgShipping) {
      errors.flgShipping = translate(
        'resources.address.edit.notifications.flgError'
      );
    }
    return errors;
  };
  const CustomCreateToolbar = (props) => (
    <Toolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton />
      <CancelButton />
    </Toolbar>
  );

  const CancelButton = () => {
    const translate = useTranslate();
    return (
      <Button
        onClick={() => {
          if (setCreate !== undefined) {
            setCreate(false);
          } else {
            redirect('list', props.basePath);
          }
        }}
        variant='contained'
      >
        {translate('ra.action.cancel')}
      </Button>
    );
  };

  return (
    <div>
      {isPspAdministrator(permissions) && (
        <div>
          <a
            href='/management/bulkaddress'
            style={{ marginTop: '20px', display: 'flex' }}
          >
            {translate(
              'dxMessages.companyManagement.account.downloadCsvTemplate'
            )}
          </a>
          <UploadFilesField
            {...props}
            postUrl={`/management/bulkaddress/`}
            revertUrl={`dxrevert/`}
          />
        </div>
      )}
      <Create
        title={<AddressTitle />}
        onSuccess={onSuccess}
        onFailure={onFailure}
        component='div'
        {...props}
        resource={Constants.RESOURCE_ADDRESSES}
      >
        <SimpleForm
          redirect='list'
          validate={(values) => validateCreate(values, translate)}
          toolbar={<CustomCreateToolbar />}
        >
          <FormLayout {...props} />
        </SimpleForm>
      </Create>
    </div>
  );
};

const AddressTitle = ({ record }: any) => <span>{record?.email}</span>;

const FormLayout: FC<FieldProps<Address>> = (props) => {
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <GeneralInfo {...props} />
      </Grid>
    </Grid>
  );
};

const GeneralInfo: FC<FieldProps<Address>> = (props: any) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const locale = useLocale();
  const countryService = new CountryService(locale);
  const countriesList = countryService.getCountries();
  return (
    <Paper className={classes.paper}>
      <Grid container direction='column' spacing={0}>
        <Grid item>
          <Grid container spacing={2}>
            {isPspAdministrator(permissions) && (
              <Grid item xs={4}>
                <ReferenceInput
                  label='resources.accounts.create.wizard.steps.companySelection.company'
                  source='companyId'
                  reference='management/company'
                  validate={required()}
                  filterToQuery={(searchText) => ({
                    accountname: [searchText],
                  })}
                  sort={{ field: 'accountname', order: 'ASC' }}
                  defaultValue={parseInt(props.id)}
                  disabled={props.id}
                >
                  <AutocompleteInput optionText='accountname' fullWidth />
                </ReferenceInput>
              </Grid>
            )}
            <Grid item xs={4}>
              <TextInput
                label='resources.address.edit.sections.street'
                source='street'
                fullWidth
                validate={[required(), maxLength(255)]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label='resources.address.edit.sections.additionalStreet'
                source='additionalStreet'
                fullWidth
                validate={maxLength(255)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label='resources.address.edit.sections.buildingNumber'
                source='buildingNumber'
                fullWidth
                validate={[required(), maxLength(10)]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label='resources.address.edit.sections.postBox'
                source='postBox'
                fullWidth
                validate={maxLength(50)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label='resources.address.edit.sections.city'
                source='city'
                fullWidth
                validate={[required(), maxLength(255)]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label='resources.address.edit.sections.postalCode'
                source='postalCode'
                fullWidth
                validate={maxLength(50)}
              />
            </Grid>
            {isPspAdministrator(permissions) && (
              <Grid item xs={4}>
                <TextInput
                  label='resources.address.edit.sections.financialAccount'
                  source='financialAccount'
                  fullWidth
                  validate={maxLength(255)}
                />
              </Grid>
            )}
            {isPspAdministrator(permissions) && (
              <Grid item xs={4}>
                <TextInput
                  label='resources.address.edit.sections.bank'
                  source='bank'
                  fullWidth
                  validate={maxLength(255)}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.flgLogistic && (
                    <TextInput
                      label='resources.address.edit.sections.gln'
                      source='gln'
                      fullWidth
                      validate={[number()]}
                    />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  !formData.flgLogistic && (
                    <TextInput
                      label='resources.address.edit.sections.gln'
                      source='gln'
                      fullWidth
                      validate={[minLength(13), maxLength(13), number()]}
                    />
                  )
                }
              </FormDataConsumer>
            </Grid>
            <Grid item xs={4}>
              <AutocompleteInput
                label='resources.address.edit.sections.country'
                optionText='name'
                optionValue='code'
                source='country'
                validate={[required()]}
                choices={countriesList}
              />
            </Grid>
            {isPspAdministrator(permissions) && (
              <Grid item xs={2}>
                <BooleanInput
                  label='resources.address.edit.sections.flgBilling'
                  source='flgBilling'
                  defaultValue={false}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <BooleanInput
                label='resources.address.edit.sections.flgShipping'
                source='flgShipping'
                defaultValue={false}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <BooleanInput
                label='resources.address.edit.sections.flgLogistic'
                source='flgLogistic'
                defaultValue={false}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddressDetailsCreate;
