import { DataProvider } from 'ra-core';
import {
  AlfrescoDocumentService,
  CatalogService,
  CompanyService,
  DespatchAdviceService,
  InvoiceService,
  OrderService,
  ReceiptAdviceService,
  TemplatesService,
  WaybillService,
} from '.';
import { IDocumentService } from './IDocumentService';

/**
 * Provides instances of Service.
 *
 * Usage example:
 * DocumentServiceFactory.create(
 *       InvoiceServiceCreator,
 *       dataProvider
 *     );
 */
export class DocumentServiceFactory {
  public static create(
    creator: new () => IServiceCreator,
    dataProvider: DataProvider
  ): IDocumentService {
    const t = new creator();
    return t.create(dataProvider);
  }
}

export interface IServiceCreator {
  create(dataProvider: DataProvider): IDocumentService;
}

export class InvoiceServiceCreator implements IServiceCreator {
  public create(dataProvider: DataProvider): InvoiceService {
    const documentService = new AlfrescoDocumentService(dataProvider);
    const companyService = new CompanyService(dataProvider);
    const templatesService = new TemplatesService(dataProvider);
    const catalogService = new CatalogService(dataProvider);

    return new InvoiceService(
      companyService,
      catalogService,
      templatesService,
      documentService
    );
  }
}

export class ReceiptAdviceServiceCreator implements IServiceCreator {
  public create(dataProvider: DataProvider): ReceiptAdviceService {
    const documentService = new AlfrescoDocumentService(dataProvider);
    const companyService = new CompanyService(dataProvider);
    const templatesService = new TemplatesService(dataProvider);

    return new ReceiptAdviceService(
      companyService,
      templatesService,
      documentService
    );
  }
}

export class OrderServiceCreator implements IServiceCreator {
  public create(dataProvider: DataProvider): OrderService {
    const documentService = new AlfrescoDocumentService(dataProvider);
    const companyService = new CompanyService(dataProvider);
    const templatesService = new TemplatesService(dataProvider);
    const catalogService = new CatalogService(dataProvider);

    return new OrderService(
      companyService,
      templatesService,
      catalogService,
      documentService
    );
  }
}

export class DespatchAdviceServiceCreator implements IServiceCreator {
  public create(dataProvider: DataProvider): DespatchAdviceService {
    const documentService = new AlfrescoDocumentService(dataProvider);
    const companyService = new CompanyService(dataProvider);
    const templatesService = new TemplatesService(dataProvider);
    const catalogService = new CatalogService(dataProvider);

    return new DespatchAdviceService(
      companyService,
      templatesService,
      catalogService,
      documentService
    );
  }
}

export class WaybillServiceCreator implements IServiceCreator {
  public create(dataProvider: DataProvider): WaybillService {
    const documentService = new AlfrescoDocumentService(dataProvider);
    const companyService = new CompanyService(dataProvider);
    const catalogService = new CatalogService(dataProvider);

    const templatesService = new TemplatesService(dataProvider);

    const invoiceServiceWaybill = new InvoiceService(
      companyService,
      catalogService,
      templatesService,
      documentService
    );

    return new WaybillService(
      invoiceServiceWaybill,
      companyService,
      templatesService,
      documentService
    );
  }
}
