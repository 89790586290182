import { DateInput } from '@dx-ui/dx-common';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a DateInput
 * The rendering takes into account that the field can be a member of an array.
 */
const DateInputField = (props: FieldComponentProps) => {
  const sanitizeProps: any = ({
    loading,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;
  return (
    <FieldWrapper {...props}>
      {(fieldProps) => {
        return <DateInput {...sanitizeProps(fieldProps)} />;
      }}
    </FieldWrapper>
  );
};

export default DateInputField;
