// An action input is a complex form inputs to gather the data to complete
// a user task.
// Always displayed inside the react-admin "edit" mode of the task resource.
//
// Note: if an action input is not supposed to be used by some users
// (depending on their rights), wrap it with the HOC: SuspendedInput.
import React, { FC } from 'react';
import { FieldProps, Task } from '../../../shared/types';
import InvoiceValidationCockpit from './input/invoicevalidation/InvoiceValidationCockpit';
import CancellationCockpit from './input/nwaymatching/cancellation/CancellationCockpit';
import I2oNwayMatchingCockpit from './input/nwaymatching/i2o/I2oNwayMatchingCockpit';
import I2prNwayMatchingCockpit from './input/nwaymatching/i2pr/I2prNwayMatchingCockpit';
import OrderNegotiationClientCockpit from './input/ordernegotiation/client/OrderNegotiationClientCockpit';
import OrderNegotiationSupplierCockpit from './input/ordernegotiation/supplier/OrderNegotiationSupplierCockpit';
import SaintGobainCockpit from './input/saintgobain/SaintGobainCockpit';

const ActionInput: FC<FieldProps<Task<any>>> = (props) => {
  const { record, resource, basePath, dataProvider } = props;
  if (!record) return null;
  switch (record.uiSpecification.type) {
    case 'i2prHoldCockpit':
      return (
        <I2prNwayMatchingCockpit
          dataProvider={dataProvider}
          resource={resource}
          record={record}
          basePath={basePath}
          id={record.id}
        />
      );
    case 'i2oHoldCockpit':
      return (
        <I2oNwayMatchingCockpit
          dataProvider={dataProvider}
          resource={resource}
          record={record}
          basePath={basePath}
          id={record.id}
        />
      );
    case 'invoiceCancellationCockpit':
      return (
        <CancellationCockpit
          dataProvider={dataProvider}
          resource={resource}
          record={record}
          basePath={basePath}
          id={record.id}
        />
      );
    case 'sg_validation':
    case 'sg_ap_validation':
      return (
        <SaintGobainCockpit
          dataProvider={dataProvider}
          resource={resource}
          record={record}
          basePath={basePath}
          id={record.id}
          type={record.uiSpecification.type}
        />
      );
    case 'supplier_order_negotiation':
      return (
        <OrderNegotiationSupplierCockpit
          dataProvider={dataProvider}
          resource={resource}
          record={record}
          basePath={basePath}
          id={record.id}
        />
      );
    case 'client_order_negotiation':
      return (
        <OrderNegotiationClientCockpit
          dataProvider={dataProvider}
          resource={resource}
          record={record}
          basePath={basePath}
          id={record.id}
        />
      );
    case 'invoiceValidationCockpit':
      return (
        <InvoiceValidationCockpit
          dataProvider={dataProvider}
          resource={resource}
          record={record}
          basePath={basePath}
          id={record.id}
        />
      );
    default:
      throw new Error(`No form for ${record.uiSpecification.type}`);
  }
};

export default ActionInput;
