import { Drawer, IconButton, Tooltip, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import TimelineIcon from '@material-ui/icons/Timeline';
import React, { useMemo, useState } from 'react';
import { useGetIdentity, useTranslate } from 'react-admin';
import { GA_EVENTS, sendGAEvent } from '../../utils';
import AuditTrailTimeline from './AuditTrailTimeline';

const useStyles: any = makeStyles(
  (theme) => ({
    header: {
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      minHeight: 60,
    },
    field: {
      width: 400,
      '& pre': {
        whiteSpace: 'pre-wrap',
      },
    },
    button: {
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgb(255,255,255,0.2)',
      },
      margin: '0 5px',
    },
    link: {
      color: theme.palette.text.secondary,
    },
    title: {
      color: 'white',
    },
  }),
  { name: 'AuditTrailButton' }
);

/**
 * Defines AuditTrailButton props
 */
export interface AuditTrailButtonProps {
  /**
   * Document id
   */
  documentId: string;

  /**
   * Renders the control as a button or as a menu item
   */
  isMenuItem?: boolean;

  /**
   * Show the sidebar by default
   */
  open?: boolean;

  /**
   * Button label
   */
  label?: string;
}

/**
 * Renders a button that can show the trail side bar when clicked.
 */
const AuditTrailButton = React.forwardRef<any, any>(
  (props: AuditTrailButtonProps, ref) => {
    const {
      isMenuItem = false,
      //resource,
      label = 'dxMessages.audit_trail.show',
      documentId,
      ...rest
    } = props;

    const { identity } = useGetIdentity();
    // @ts-ignore
    const account: Account = identity;
    const translate = useTranslate();
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClick = () => {
      sendGAEvent(
        GA_EVENTS.categories.AUDIT_TRAIL.name,
        GA_EVENTS.categories.AUDIT_TRAIL.actions.SHOW,
        account?.company?.cmsRootDir,
        documentId
      );

      setOpen(true);
    };

    const handleCloseClick = () => {
      setOpen(false);
    };

    const translatedLabel = useMemo(
      () => translate(label, { _: label }),
      [label, translate]
    );

    return (
      <>
        {isMenuItem ? (
          <MenuItem
            ref={ref}
            onClick={handleClick}
            classes={{ root: classes.link }}
          >
            {translatedLabel}
          </MenuItem>
        ) : (
          <Tooltip title={translatedLabel}>
            <IconButton
              color='primary'
              onClick={handleClick}
              {...rest}
              ref={ref}
            >
              <TimelineIcon />
            </IconButton>
          </Tooltip>
        )}
        <Drawer anchor='right' open={open} onClose={handleCloseClick}>
          <div className={classes.header}>
            <IconButton className={classes.button} onClick={handleCloseClick}>
              <IconKeyboardArrowRight />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              {translate('dxMessages.audit_trail.show')}
            </Typography>
          </div>
          <AuditTrailTimeline documentId={documentId} />
        </Drawer>
      </>
    );
  }
);

export default AuditTrailButton;
