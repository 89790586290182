import _ from 'lodash';
import { AlfrescoDocumentService } from '../../services/AlfrescoDocumentService';
import { P2pPreviewData } from '../../shared/types';
import DespatchAdvicePreviewView from './DespatchAdvicePreviewView';
import DespatchAdviceSources, {
  IDespatchAdviceSources,
} from './DespatchAdviceSources';

interface PropsModel {
  record: P2pPreviewData;
  resource?: string;
  toolbar?: any;
  basePath: any;
}
const DespatchAdvicePreviewController = (props: PropsModel) => {
  const { record } = props;

  let despatchAdviceSources: DespatchAdviceSources;
  if (AlfrescoDocumentService.isWebDocument(record)) {
    despatchAdviceSources = new DespatchAdviceSources(true);
  } else {
    despatchAdviceSources = new DespatchAdviceSources(false);
  }

  const previewData: P2pPreviewData = {
    id: record.id,
    properties: record.properties,
    ublProperties: _.get(
      record,
      despatchAdviceSources.DespatchAdviceSources().root.source
    ),
    linesView: record.linesView,
  };

  const dataSources: IDespatchAdviceSources = {
    AmountSources: despatchAdviceSources.AmountSources(),
    AddressSources: despatchAdviceSources.AddressSources(),
    ContactSources: despatchAdviceSources.ContactSources(),
    DocumentReferenceSources: despatchAdviceSources.DocumentReferenceSources(),
    LocationSources: despatchAdviceSources.LocationSources(),
    PartySources: despatchAdviceSources.PartySources(),
    DespatchAdvicePartySources:
      despatchAdviceSources.DespatchAdvicePartySources(),
    DespatchAdviceRootSources:
      despatchAdviceSources.DespatchAdviceRootSources(),
    ShipmentInformationSources:
      despatchAdviceSources.ShipmentInformationSources(),
    DeliverySources: despatchAdviceSources.DeliverySources(),
    DespatchAdviceLineSources:
      despatchAdviceSources.DespatchAdviceLineSources(),
    OrderReferenceSources: despatchAdviceSources.OrderReferenceSources(),
  };

  return (
    <DespatchAdvicePreviewView
      {...{
        record: previewData,
        dataSources,
      }}
    />
  );
};

export default DespatchAdvicePreviewController;
