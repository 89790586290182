import {
  account,
  authProvider,
  company,
  Constants,
  DxLayout,
  i18nProvider,
  identityprovider,
  isCompanyAdministrator,
  isPspAdministrator,
  LoginPage,
  MessageCreate,
  MessageEdit,
  MessageList,
  MessageShow,
  muiTheme,
  NotFound,
  persons,
  readStatusReducer,
  regulator,
  SITE_SPEED_SAMPLE_RATE,
  UserRoles,
} from '@dx-ui/dx-common';
import { Admin, Resource } from 'react-admin';
import ReactGA from 'react-ga4';
import { Dashboard } from '../../components';
import updateFormTemplateReducer from '../../reducers/updateFormTemplateReducer';
import v2Company from '../company/management/account';
import AccountDetailsCreate from '../company/management/account/AccountDetailsCreate';
import AccountDetailsShow from '../company/management/account/AccountDetailsShow';
import AccountRelationCreate from '../company/management/accountrelation/AccountRelationCreate';
import AccountRelationEdit from '../company/management/accountrelation/AccountRelationEdit';
import AccountRelationList from '../company/management/accountrelation/AccountRelationList';
import AddressBufferList from '../company/management/address_buffer/AddressBufferList';
import address from '../company/management/addresses';
import ContactCreate from '../company/management/contact/ContactCreate';
import ContactEdit from '../company/management/contact/ContactEdit';
import ContactList from '../company/management/contact/ContactList';
import DxV2DesadvList from '../company/management/desadv/DxV2DesadvList';
import DropdownDefCreate from '../company/management/dropdowndef/DropdownDefCreate';
import DropdownDefEdit from '../company/management/dropdowndef/DropdownDefEdit';
import DropdownDefList from '../company/management/dropdowndef/DropdownDefList';
import DxProductCreate from '../company/management/dxproduct/DxProductCreate';
import DxProductEdit from '../company/management/dxproduct/DxProductEdit';
import DxProductList from '../company/management/dxproduct/DxProductList';
import DxRoleCreate from '../company/management/dxrole/DxRoleCreate';
import DxRoleEdit from '../company/management/dxrole/DxRoleEdit';
import DxRoleList from '../company/management/dxrole/DxRoleList';
import FormatTypeCreate from '../company/management/formattype/FormatTypeCreate';
import FormatTypeEdit from '../company/management/formattype/FormatTypeEdit';
import FormatTypeList from '../company/management/formattype/FormatTypeList';
import DxV2InvoiceList from '../company/management/invoice/DxV2InvoiceList';
import DxV2OrderList from '../company/management/order/DxV2OrderList';
import PeppolCreate from '../company/management/peppol/PepolCreate';
import PeppolEdit from '../company/management/peppol/PeppolEdit';
import PeppolList from '../company/management/peppol/PeppolList';
import ProductUsageCreate from '../company/management/products/ProductUsageCreate';
import ProductUsageEdit from '../company/management/products/ProductUsageEdit';
import ProductUsageList from '../company/management/products/ProductUsageList';
import DxV2RecadvList from '../company/management/recadv/DxV2RecadvList';
import SuppliersList from '../company/management/suppliers/SuppliersList';
import UsersCreate from '../company/management/users/UsersCreate';
import { UserShow } from '../company/management/users/UserShow';
import UsersList from '../company/management/users/UsersList';
import SupportedProductsCreate from '../company/supportedproducts/SupportedProductsCreate';
import SupportedProductsList from '../company/supportedproducts/SupportedProductsList';
import { DespatchAdviceList, DespatchAdviceShow } from '../despatchAdvice';
import WebDespatchAdviceEdit from '../despatchAdvice/draft/WebDespatchAdviceEdit';
import WebDespatchAdviceList from '../despatchAdvice/draft/WebDespatchAdviceList';
import { DxDocumentList, DxDocumentShow } from '../dxDocument';
import { ForecastList, ForecastShow } from '../forecast';
import { InvoiceList, InvoiceShow } from '../invoice';
import WebInvoiceEdit from '../invoice/draft/WebInvoiceEdit';
import WebInvoiceList from '../invoice/draft/WebInvoiceList';
import EfacturaMonitoringList from '../monitoring/efactura/EfacturaMonitoringList';
import { OrderList, OrderShow } from '../order';
import WebOrderEdit from '../order/draft/WebOrderEdit';
import WebOrderList from '../order/draft/WebOrderList';
import { ReceiptAdviceList, ReceiptAdviceShow } from '../receiptAdvice';
import WebReceiptAdviceEdit from '../receiptAdvice/draft/WebReceiptAdviceEdit';
import WebReceiptAdviceList from '../receiptAdvice/draft/WebReceiptAdviceList';
import TaskEdit from '../task/TaskEdit';
import TaskList from '../task/TaskList';
import { WayBillList, WayBillShow } from '../waybill';
import WebWayBillEdit from '../waybill/draft/WebWayBillEdit';
import WebWayBillList from '../waybill/draft/WebWayBillList';
import { customRoutes } from './';
import dataProvider from './dataProvider';

const getResources = (permissions) => {
  const appPermissions = permissions.AUTH_ROLES
    ? permissions.AUTH_ROLES[UserRoles.DXPURCHASE_PRODUCT]
    : permissions[UserRoles.DXPURCHASE_PRODUCT];
  const adminPermissions = permissions.AUTH_ROLES
    ? permissions.AUTH_ROLES
    : permissions;
  const efacturaPermissions = permissions.AUTH_ROLES
    ? permissions.AUTH_ROLES[UserRoles.EFACTURA_PRODUCT]
    : permissions[UserRoles.EFACTURA_PRODUCT];
  let DxResources: any[] = [];
  if (efacturaPermissions) {
    if (efacturaPermissions.find((p) => p === UserRoles.EFACTURA_ACCESS)) {
      // V2 customer admin user Access for regulatorAccess
      DxResources.push(
        <Resource
          name='regulatorAccess/efactura'
          options={{
            label: 'resources.regulatorAccess.submenu',
          }}
          {...regulator}
        />
      );
    }
  }

  if (appPermissions) {
    if (
      appPermissions.filter(
        (p: string) =>
          p !== UserRoles.DXPURCHASE_ACCESS && p !== UserRoles.BETA_TESTER
      ).length !== 0
    ) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_DOCUMENTS}
          list={DxDocumentList}
          show={DxDocumentShow}
        />
      );
    }

    if (appPermissions.find((p) => p === UserRoles.VIEW_ORDER)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_ORDER}
          list={OrderList}
          show={OrderShow}
        />
      );
    }
    if (appPermissions.find((p) => p === UserRoles.CREATE_ORDER)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_WEBORDER}
          list={WebOrderList}
          show={OrderShow}
          edit={WebOrderEdit}
        />
      );
    }
    if (appPermissions.find((p) => p === UserRoles.VIEW_DESPATCH_ADVICE)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_DESPATCH_ADVICE}
          list={DespatchAdviceList}
          show={DespatchAdviceShow}
        />
      );
    }
    if (appPermissions.find((p) => p === UserRoles.CREATE_DESPATCH_ADVICE)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_WEBDESPATCH_ADVICE}
          list={WebDespatchAdviceList}
          show={DespatchAdviceShow}
          edit={WebDespatchAdviceEdit}
        />
      );
    }
    if (appPermissions.find((p) => p === UserRoles.VIEW_RECEIPT_ADVICE)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_RECEIPT_ADVICE}
          list={ReceiptAdviceList}
          show={ReceiptAdviceShow}
        />
      );
    }

    if (appPermissions.find((p) => p === UserRoles.CREATE_RECEIPT_ADVICE)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_WEBRECEIPT_ADVICE}
          list={WebReceiptAdviceList}
          show={ReceiptAdviceShow}
          edit={WebReceiptAdviceEdit}
        />
      );
    }
    if (appPermissions.find((p) => p === UserRoles.VIEW_INVOICE)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_INVOICE}
          list={InvoiceList}
          show={InvoiceShow}
        />
      );
    }
    if (appPermissions.find((p) => p === UserRoles.CREATE_INVOICE)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_WEBINVOICE}
          list={WebInvoiceList}
          show={InvoiceShow}
          edit={WebInvoiceEdit}
        />
      );
    }
    if (appPermissions.find((p) => p === UserRoles.VIEW_FORECAST)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_FORECAST}
          list={ForecastList}
          show={ForecastShow}
        />
      );
    }
    if (appPermissions.find((p) => p === UserRoles.VIEW_WAYBILL)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_WAYBILL}
          list={WayBillList}
          show={WayBillShow}
        />
      );
    }
    if (appPermissions.find((p) => p === UserRoles.CREATE_WAYBILL)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_WEBWAYBILL}
          list={WebWayBillList}
          show={WayBillShow}
          edit={WebWayBillEdit}
        />
      );
    }
    if (
      appPermissions.find((p) => p === UserRoles.EFACTURA_ACCESS) ||
      appPermissions.find((p) => p === UserRoles.DXADMIN)
    ) {
      // V2 customer admin user Access for regulatorAccess
      DxResources.push(
        <Resource
          name='regulatorAccess/efactura'
          options={{
            label: 'resources.regulatorAccess.submenu',
          }}
          {...regulator}
        />
      );
    }

    if (appPermissions.find((p) => p === UserRoles.BETA_TESTER)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_EFACTURA_MONITORING_CONTENT_ERROR}
          list={EfacturaMonitoringList}
        />
      );

      DxResources.push(
        <Resource
          name={Constants.RESOURCE_EFACTURA_MONITORING_REJECTED_DOCUMENTS}
          list={EfacturaMonitoringList}
        />
      );
    }
  }

  if (
    (appPermissions &&
      appPermissions.find((p) => p === UserRoles.CUSTOMER_ADMINISTRATOR)) ||
    (adminPermissions && isPspAdministrator(adminPermissions))
  ) {
    DxResources.push(
      <Resource
        name={Constants.RESOURCE_ACCOUNT}
        {...v2Company}
        create={
          isPspAdministrator(adminPermissions)
            ? AccountDetailsCreate
            : undefined
        }
        show={
          isPspAdministrator(adminPermissions) ? AccountDetailsShow : undefined
        }
      />
    );

    DxResources.push(
      <Resource
        name={Constants.RESOURCE_CONTACT}
        list={ContactList}
        edit={ContactEdit}
        create={
          isPspAdministrator(adminPermissions) ? ContactCreate : undefined
        }
      />
    );

    DxResources.push(
      <Resource
        name={Constants.RESOURCE_SUPPORTED_PRODUCTS}
        list={SupportedProductsList}
        create={
          isPspAdministrator(adminPermissions)
            ? SupportedProductsCreate
            : undefined
        }
      />
    );

    DxResources.push(<Resource name={Constants.RESOURCE_CREDIT} />);
    DxResources.push(
      <Resource name={Constants.RESOURCE_PRODUCT_FOR_RELATION} />
    );
    DxResources.push(
      <Resource name={Constants.RESOURCE_PRODUCT_FOR_RELATION_PRODUCTS} />
    );
    DxResources.push(
      <Resource
        name={Constants.RESOURCE_DROPDOWNDEF}
        list={DropdownDefList}
        edit={DropdownDefEdit}
        create={
          isPspAdministrator(adminPermissions) ? DropdownDefCreate : undefined
        }
      />
    );

    DxResources.push(
      <Resource
        name={Constants.RESOURCE_FORMAT_TYPE}
        list={FormatTypeList}
        edit={FormatTypeEdit}
        create={
          isPspAdministrator(adminPermissions) ? FormatTypeCreate : undefined
        }
      />
    );

    DxResources.push(
      <Resource
        name={Constants.RESOURCE_DX_ROLE}
        list={DxRoleList}
        edit={DxRoleEdit}
        create={isPspAdministrator(adminPermissions) ? DxRoleCreate : undefined}
      />
    );

    DxResources.push(
      <Resource
        name={Constants.RESOURCE_DXPRODUCT}
        list={DxProductList}
        edit={DxProductEdit}
        create={
          isPspAdministrator(adminPermissions) ? DxProductCreate : undefined
        }
      />
    );

    DxResources.push(
      <Resource
        name={Constants.RESOURCE_SUPPLIERS}
        list={AccountRelationList}
        edit={AccountRelationEdit}
        create={
          isPspAdministrator(adminPermissions)
            ? AccountRelationCreate
            : undefined
        }
      />
    );

    DxResources.push(
      <Resource name={Constants.RESOURCE_CUSTOMER} list={SuppliersList} />
    );
    DxResources.push(
      <Resource name={Constants.RESOURCE_ADDRESSES} {...address} />
    );
    DxResources.push(
      <Resource
        name={Constants.RESOURCE_ADDRESS_BUFFER}
        list={AddressBufferList}
      />
    );

    DxResources.push(
      <Resource
        name={Constants.RESOURCE_USERS}
        list={UsersList}
        show={UserShow}
        create={UsersCreate}
      />
    );

    DxResources.push(
      <Resource
        name={Constants.RESOURCE_PRODUCTUSAGE}
        list={ProductUsageList}
        edit={ProductUsageEdit}
        create={
          isPspAdministrator(adminPermissions) ? ProductUsageCreate : undefined
        }
      />
    );
  }

  if (adminPermissions) {
    if (isPspAdministrator(adminPermissions)) {
      DxResources.push(
        <Resource name={Constants.RESOURCE_V2INVOICE} list={DxV2InvoiceList} />
      );
      DxResources.push(
        <Resource name={Constants.RESOURCE_V2ORDER} list={DxV2OrderList} />
      );
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_V2DESPATCHADVICE}
          list={DxV2DesadvList}
        />
      );
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_V2RECEIPTADVICE}
          list={DxV2RecadvList}
        />
      );
    }
  }

  if (adminPermissions) {
    if (isPspAdministrator(adminPermissions)) {
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_PEPPOL}
          list={PeppolList}
          edit={PeppolEdit}
          create={PeppolCreate}
        />
      );
    }
  }

  if (adminPermissions) {
    if (
      isPspAdministrator(adminPermissions) ||
      isCompanyAdministrator(adminPermissions)
    ) {
      DxResources.push(
        <Resource
          name='configuration-accounts'
          options={{
            label: 'resources.accounts.menu',
          }}
          {...account}
        />,
        <Resource
          name='configuration-companies'
          options={{
            label: isPspAdministrator(adminPermissions)
              ? 'resources.companies.menu'
              : 'resources.company.menu',
          }}
          {...company}
        />,
        <Resource
          name='configuration-people'
          options={{
            label: 'resources.people.menu',
          }}
          {...persons}
        />
      );
    }
    if (isPspAdministrator(adminPermissions)) {
      // V3 customer admin user Access for regulatorAccess
      DxResources.push(
        <Resource
          name='regulatorAccess/efactura'
          options={{
            label: 'resources.regulatorAccess.submenu',
          }}
          {...regulator}
        />
      );
      DxResources.push(
        <Resource
          name='configuration-identityProviders'
          options={{
            label: 'resources.identityProviders.menu',
          }}
          {...identityprovider}
        />
      );
    }
    DxResources.push(
      <Resource
        name={Constants.RESOURCE_MESSAGES}
        create={
          isPspAdministrator(adminPermissions) ? MessageCreate : undefined
        }
        list={isPspAdministrator(adminPermissions) ? MessageList : undefined}
        edit={isPspAdministrator(adminPermissions) ? MessageEdit : undefined}
        show={MessageShow}
      />
    );
    if (isCompanyAdministrator(adminPermissions)) {
      DxResources.push(
        <Resource name={Constants.RESOURCE_ADDRESSES} {...address} />
      );
      DxResources.push(
        <Resource
          name={Constants.RESOURCE_CONTACT}
          list={ContactList}
          edit={ContactEdit}
        />
      );

      DxResources.push(
        <Resource name={Constants.RESOURCE_ACCOUNT} {...v2Company} />
      );

      DxResources.push(
        <Resource name={Constants.RESOURCE_SUPPLIERS} list={SuppliersList} />
      );

      DxResources.push(
        <Resource name={Constants.RESOURCE_CUSTOMER} list={SuppliersList} />
      );
      DxResources.push(<Resource name='configuration-companyadminpeople' />);
    }
  }

  DxResources.push(
    <Resource name='configuration-dxfeatures' />,
    <Resource name='loginevents' />,
    <Resource name='accountlifecycleevents' />,
    /* feedbackMessage Resource declaration for ReferenceField usage */
    <Resource name={Constants.RESOURCE_FEEDBACK_MESSAGE} />,
    /* attachment Resource declaration for ReferenceField usage */
    <Resource name={Constants.RESOURCE_ATTACHMENT} />,
    /* ubl Resource declaration for ReferenceField usage */
    <Resource name={Constants.RESOURCE_UBL} />,

    // For user profile edit page
    <Resource name={Constants.RESOURCE_USERPROFILES} />,
    <Resource name={Constants.RESOURCE_USERPROFILES_V2} />,

    // web form template management
    <Resource name={Constants.RESOURCE_WEBFORMTEMPLATE} />,

    <Resource name={Constants.RESOURCE_CATALOG} />,
    <Resource
      name={Constants.RESOURCE_TASKS}
      options={{
        label: 'resources.tasks.name',
      }}
      list={TaskList}
      edit={TaskEdit}
    />
  );
  return DxResources;
};

const App = () => {
  ReactGA.initialize('G-BN23Y6KJQ2', {
    gaOptions: {
      siteSpeedSampleRate: SITE_SPEED_SAMPLE_RATE,
    },
  });

  return (
    <Admin
      title={Constants.DXPURCHASE_APP_DISPLAY}
      dashboard={Dashboard}
      catchAll={NotFound}
      authProvider={authProvider}
      layout={DxLayout}
      dataProvider={dataProvider}
      theme={muiTheme}
      i18nProvider={i18nProvider}
      loginPage={(props: any) =>
        LoginPage({ appName: Constants.DXPURCHASE_APP, ...props })
      }
      customRoutes={customRoutes}
      customReducers={{
        readStatus: readStatusReducer,
        newFormTemplate: updateFormTemplateReducer,
      }}
    >
      {getResources}
    </Admin>
  );
};

export default App;
