import { SimpleLabeledField } from '@dx-ui/dx-common';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { useTranslate } from 'react-admin';
import { IWayBillSources } from '../WayBillSources';
import WayBillContactFragment from './WayBillContactFragment';
import WayBillLocationFragment from './WayBillLocationFragment';

const useStyles = makeStyles(
  (theme) => ({
    partyTile: {
      height: '100%',
      backgroundColor: theme.palette.grey[100],
      padding: '2em 1em 1em 1em',
    },
  }),
  { name: 'WayBillPartyTile' }
);
interface PropsModel {
  title: any;
  record: any;
  dataSources: IWayBillSources;
  source?: any;
}

const WayBillPartyTile = (props: PropsModel): JSX.Element => {
  const { record, title, dataSources } = props;
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Grid container direction='column' className={classes.partyTile}>
      <Grid item>
        <Typography classes={{ root: "font-weight: 'bold'" }}>
          {translate(title)}
        </Typography>
      </Grid>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.vatIdentification')}
          data={get(record, dataSources.PartySources.vatId)}
          oneLine
          className={`${title}_vatId`}
        />
      </Grid>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.name')}
          data={get(record, dataSources.PartySources.name)}
          oneLine
          className={`${title}_name`}
        />
      </Grid>

      <WayBillLocationFragment
        record={get(record, dataSources.PartySources.postalAddress.source)}
        dataSources={dataSources}
        title={title}
      />

      <WayBillContactFragment
        record={get(record, dataSources.PartySources.contact.source)}
        dataSources={dataSources}
        title={title}
      />
    </Grid>
  );
};

export default WayBillPartyTile;
