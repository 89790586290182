import { DxTheme, ProcessStatus, ToolbarBackButton } from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';
import {
  ShowView,
  SimpleShowLayout,
  Toolbar,
  usePermissionsOptimized,
} from 'react-admin';
import { DataHelpers } from '../../services/DataHelpers';
import { WaybillService } from '../../services/WaybillService';
import DocumentPreviewShowActions from '../../shared/DocumentPreviewShowActions';
import EditWebDocumentButton from '../../shared/EditWebDocumentButton';
import WayBillActions from './WayBillActions';
import WayBillPreview from './WayBillPreview';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    toolbar: {
      backgroundColor: theme.palette.background.default,
      paddingLeft: 0,
    },
  }),
  { name: 'WayBillShowView' }
);

const WayBillToolbar = (props) => {
  const { record } = props;

  const { permissions } = usePermissionsOptimized();
  const classes = useStyles();

  const allowEdit =
    WaybillService.canCreate(permissions) &&
    DataHelpers.getProcessStatus(record) === ProcessStatus.DRAFT;

  return (
    <Toolbar className={classes.toolbar} {...props}>
      <ToolbarBackButton />
      {allowEdit && (
        <EditWebDocumentButton
          label={'dxMessages.buttons.editWayBill'}
          {...props}
        />
      )}
      <WayBillActions isMenuItem={false} permissions={permissions} />
    </Toolbar>
  );
};

const WayBillShowView = (props: any) => {
  const { controllerProps } = props;

  return (
    <ShowView
      actions={<DocumentPreviewShowActions />}
      {...controllerProps}
      title='dxMessages.waybill.title'
    >
      {controllerProps.record && (
        <SimpleShowLayout>
          <WayBillPreview
            record={controllerProps.record}
            toolbar={WayBillToolbar}
          />
        </SimpleShowLayout>
      )}
    </ShowView>
  );
};

export default WayBillShowView;
