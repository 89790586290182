import { makeStyles } from '@material-ui/core/styles';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { SaveButton, Toolbar, useNotify } from 'react-admin';
import { computeFinalOrder, LineStatusCode } from '../common/util/utils';

const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    columnGap: '1em',
  },
});

const useButtonStyles = makeStyles({
  reject: {
    backgroundColor: 'red',
  },
});

type action = 'accept' | 'reject';

// A toolbar to customise the Save & Cancel buttons.
const OrderNegotiationClientCockpitToolbar = (props) => {
  const toolbarClasses = useToolbarStyles();
  const buttonClasses = useButtonStyles();
  const notify = useNotify();
  const { record } = props;
  if (!record) return null;

  const buildRequest = (action: action) => (props: any) => {
    return new Promise<void>((resolve, reject) => {
      let order = props.flow.variables.order;
      if (action === 'accept') {
        // Now we replace the old order lines with the new before sending it to the flow.
        // Also remove any null values from the partial acceptance.
        order.OrderLine =
          record?.flow?.variables?.updatedOrder.OrderLine.filter((e: any) => e);

        // Lastly, we want to set status codes in the order
        const orderUpdated = order.OrderLine.some(function (item) {
          return (
            item.LineItem.LineStatusCode === LineStatusCode.UPDATED ||
            item.LineItem.LineStatusCode.value === LineStatusCode.UPDATED
          );
        });
        if (orderUpdated) {
          if (!order.OrderDocumentReference) {
            order.OrderDocumentReference = {};
          }
          order.OrderDocumentReference.DocumentStatusCode = 'CA';
          order.OrderDocumentReference.ID = { value: order.ID.value };
        }
      }
      if (action === 'reject') {
        if (!props.input?.data?.comment) {
          notify(
            'dxMessages.task.reginamaria.errorMsg.missing_rej_reason',
            'error'
          );
          reject();
        }
        Object.values(order.OrderLine).forEach((item: any) => {
          item.LineItem.LineStatusCode = LineStatusCode.NOT_ACCEPTED;
        });
        order.OrderTypeCode.value = '320';
        if (!order.OrderDocumentReference) {
          order.OrderDocumentReference = {};
        }
        order.OrderDocumentReference.DocumentStatusCode = '64';
        order.OrderDocumentReference.ID = { value: order.ID.value };
      }
      resolve({
        ...props,
        input: {
          data: {
            updatedOrder: computeFinalOrder(order),
            comment: props?.input?.data?.comment,
          },
          action: action,
        },
      });
    });
  };
  return (
    // @ts-ignore
    <Toolbar {...props} classes={toolbarClasses}>
      <SaveButton
        label='dxMessages.task.reginamaria.toolbar.accept'
        icon={<ThumbUpIcon />}
        transform={buildRequest('accept')}
      />
      <SaveButton
        className={buttonClasses.reject}
        label='dxMessages.task.reginamaria.toolbar.reject'
        icon={<ThumbDownIcon />}
        transform={buildRequest('reject')}
      />
    </Toolbar>
  );
};

export default OrderNegotiationClientCockpitToolbar;
