import {
  AuditTrailButton,
  DocumentTypeCode,
  DxTheme,
  MoreButton,
  PreviewButton,
  RelatedDocsButton,
} from '@dx-ui/dx-common';
import { useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';
import { useFeature } from 'flagged';
import React from 'react';
import InvoiceActions from '../modules/invoice/InvoiceActions';
import OrderActions from '../modules/order/OrderActions';
import ReceiptAdviceActions from '../modules/receiptAdvice/ReceiptAdviceActions';
import { AlfrescoDocumentService, DataHelpers } from '../services';
import { DownloadButton, ShowMetadataButton } from './';

//TODO: merge this class with ContextualToolbar

/**
 * Manages the display of the row contextual buttons.
 */
const DisplayContextualButtons = React.forwardRef<any, any>((props, ref) => {
  const { record, basePath, resource, className } = props;
  const isSmall = useMediaQuery((theme: DxTheme) =>
    theme.breakpoints.down('sm')
  );

  const auditTrailEnabled = useFeature('auditTrail');
  let isWebDocument = AlfrescoDocumentService.isWebDocument(record);

  const documentTypeCode: DocumentTypeCode | undefined =
    DataHelpers.getDocumentTypeCode(record);

  return (
    <div className={classNames('showOnHover', className)}>
      {auditTrailEnabled && !isWebDocument && (
        <AuditTrailButton documentId={record.id} />
      )}
      <ShowMetadataButton
        title='dxMessages.headers.show_metadata'
        record={record}
        resource={resource}
        className='showDocumentMetadata'
        basePath={basePath}
        groupingMode={true}
      />

      {!isSmall && (
        <DownloadButton
          record={record}
          className='downloadDocument'
          tooltip='dxMessages.buttons.download'
          basePath={basePath}
          resource={resource}
        />
      )}
      {record.content.mimeType === 'application/pdf' && (
        <PreviewButton
          title={record.name}
          tooltip='dxMessages.buttons.preview'
          className='previewDocument'
          href={`${record.id}/preview`}
        />
      )}
      <MoreButton
        record={record}
        basePath={basePath}
        label=''
        title={'dxMessages.buttons.more'}
        className='moreActions'
        resource={resource}
        menuItems={true}
      >
        <RelatedDocsButton
          record={record}
          basePath={basePath}
          label=''
          className='relatedDocument'
          resource={resource}
        />
        {documentTypeCode === DocumentTypeCode.INVOIC && (
          <InvoiceActions record={record} />
        )}
        {documentTypeCode === DocumentTypeCode.RECADV && (
          <ReceiptAdviceActions record={record} />
        )}
        {documentTypeCode === DocumentTypeCode.ORDERS && (
          <OrderActions record={record} />
        )}
      </MoreButton>
    </div>
  );
});

export default DisplayContextualButtons;
