import {
  Create,
  maxLength,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';

export const DropdownDefCreate = (props: any) => {
  const notify = useNotify();

  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.created');
    redirect('list', props.basePath);
  };

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput
          label={'dxMessages.companyManagement.dropdowndef.description'}
          source='description'
        />
        <SelectInput
          label={'dxMessages.companyManagement.dropdowndef.idtype'}
          source='idType'
          choices={[
            { id: 1, name: 'Invoice type' },
            { id: 2, name: 'Payment means code' },
            { id: 3, name: 'Payment start event' },
            { id: 4, name: 'Payment reference event' },
            { id: 5, name: 'Payment type period' },
            { id: 6, name: 'Invoice type code' },
            { id: 7, name: 'Package type' },
            { id: 8, name: 'Tax' },
            { id: 9, name: 'Payment terms qualifier' },
            { id: 10, name: 'Product group' },
            { id: 11, name: 'Country' },
          ]}
          validate={[required(), maxLength(50)]}
        />
        <TextInput
          label={'dxMessages.companyManagement.dropdowndef.value'}
          source='value'
        />
      </SimpleForm>
    </Create>
  );
};

export default DropdownDefCreate;
