import {
  colors,
  Constants,
  DocumentTypeCode,
  GA_EVENTS,
  Metadata,
  readStatusChange,
  sendGAEvent,
} from '@dx-ui/dx-common';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { useState } from 'react';
import {
  showNotification,
  useDataProvider,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(
  (theme) => ({
    menuItemText: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    menuItemLink: {
      textDecoration: 'none', // removes underline of anchor tag
      color: colors.black80,
      minWidth: '100%',
    },
  }),
  { name: 'DownloadButton' }
);
/**
 * Defines a direct download button or a menu to download specific doc representation
 */
const DownloadButton = (props) => {
  const { record = {}, className, tooltip, renderAsMenu = true } = props;
  const initialAvailableDownloads: any[] = [];
  const [anchorEl, setAnchorEl] = useState(null);
  const [availableDownloads, setAvailableDownloads] = useState(
    initialAvailableDownloads
  );
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const translate = useTranslate();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();

  /**
   * Opens the Download menu and gets the list of doc presentation.
   * Limited to DxPurchase.
   */
  const handleClick = (event) => {
    sendGAEvent(
      GA_EVENTS.categories.DOWNLOAD_DATA.name,
      GA_EVENTS.categories.DOWNLOAD_DATA.actions.DOWNLOAD,
      account?.company?.cmsRootDir
    );

    const { record, resource, renderAsMenu = true } = props;
    const currentTarget = event.currentTarget;
    if (renderAsMenu) {
      // Gets the list of representations of the current doc
      // same edm:dxuid and same edm:documentId
      // http://localhost:8080/dxpurchase/invoice?filter={"edm:dxuid":record.properties["edm:dxuid"],"edm:documentId":record.properties["edm:documentId"]}&range=[0,99]&sort=["id","DESC"]
      // Save them in state in order to define the menu.
      const payload = {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          [Metadata.dxuid]: record.properties[Metadata.dxuid],
          [Metadata.documentId]: record.properties[Metadata.documentId],
        },
        allFormatTypes: true, // returns all DOC representations
      };
      let availableDownloads: any[] = [];
      /**
       * Implementation of :
       * https://docprocess.atlassian.net/wiki/spaces/DXPOR/pages/1646526471/Document+Identification+with+regard+to+Client+Supplier
       * */
      dataProvider
        .getList(resource, payload)
        .then(({ data, total }) => {
          data.forEach((doc) => {
            let docInfo: any = {};
            docInfo.data = doc;
            const formatType =
              doc.properties[Metadata.processDocumentFormatType];
            const typeCode = doc.properties[Metadata.documentTypeCode];
            const withDX = doc.name.indexOf('_DX.') !== -1;
            const filename = doc.name;
            const filenameExtension = filename
              .substring(filename.lastIndexOf('.') + 1, filename.length)
              .toUpperCase();

            if (
              typeCode === DocumentTypeCode.ORDERS ||
              typeCode === DocumentTypeCode.RECADV ||
              typeCode === DocumentTypeCode.DELFOR
            ) {
              if (
                formatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_ORIGINAL
              ) {
                // ORDERS/RECADV/DELFOR CLIENT ORIGINAL
                docInfo.itemText = `${filenameExtension} ${translate(
                  'dxMessages.headers.customer'
                )} (${translate(
                  'dxMessages.processDocumentFormatTypes.ORIGINAL'
                )})`;
              } else if (
                formatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_IMAGE
              ) {
                if (withDX) {
                  // ORDERS/RECADV/DELFOR SUPPLIER IMAGE
                  docInfo.itemText = `${filenameExtension} ${translate(
                    'dxMessages.headers.supplier'
                  )}`;
                } else {
                  // ORDERS/RECADV/DELFOR CLIENT IMAGE
                  docInfo.itemText = `${filenameExtension} ${translate(
                    'dxMessages.headers.customer'
                  )}`;
                }
              } else if (
                formatType ===
                Constants.PROCESS_DOCUMENT_FORMAT_TYPE_ISSUER_IMAGE
              ) {
                // ORDERS/RECADV/DELFOR CLIENT ISSUER_IMAGE
                docInfo.itemText = `${filenameExtension} ${translate(
                  'dxMessages.headers.customer'
                )}`;
              } else if (
                formatType ===
                Constants.PROCESS_DOCUMENT_FORMAT_TYPE_RECIPIENT_IMAGE
              ) {
                // ORDERS/RECADV/DELFOR SUPPLIER RECIPIENT_IMAGE
                docInfo.itemText = `${filenameExtension} ${translate(
                  'dxMessages.headers.supplier'
                )}`;
              } else if (
                formatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_TARGET
              ) {
                // ORDERS/RECADV/DELFOR SUPPLIER TARGET
                docInfo.itemText = `${filenameExtension} ${translate(
                  'dxMessages.headers.supplier'
                )} (${translate(
                  'dxMessages.processDocumentFormatTypes.TARGET'
                )})`;
              } else if (
                formatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DX ||
                formatType === null
              ) {
                // ORDERS/RECADV/DELFOR UBL
                docInfo.itemText = 'UBL';
              } else {
                // Unknown doc
                // eslint-disable-next-line no-console
                console.log(
                  `Unknown document ${Metadata.processDocumentFormatType} value : %o`,
                  docInfo.data
                );
                docInfo = null;
              }
            } else if (
              typeCode === DocumentTypeCode.INVOIC ||
              typeCode === DocumentTypeCode.DESADV ||
              typeCode === DocumentTypeCode.WAYBIL
            ) {
              if (
                formatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_ORIGINAL
              ) {
                // INVOIC/DESADV/WAYBIL SUPLIER ORIGINAL
                docInfo.itemText = `${filenameExtension} ${translate(
                  'dxMessages.headers.supplier'
                )} (${translate(
                  'dxMessages.processDocumentFormatTypes.ORIGINAL'
                )})`;
              } else if (
                formatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_IMAGE
              ) {
                if (withDX) {
                  //  INVOIC/DESADV/WAYBIL CLIENT IMAGE
                  docInfo.itemText = `${filenameExtension} ${translate(
                    'dxMessages.headers.customer'
                  )}`;
                } else {
                  //  INVOIC/DESADV/WAYBIL SUPPLIER IMAGE
                  docInfo.itemText = `${filenameExtension} ${translate(
                    'dxMessages.headers.supplier'
                  )}`;
                }
              } else if (
                formatType ===
                Constants.PROCESS_DOCUMENT_FORMAT_TYPE_ISSUER_IMAGE
              ) {
                //  INVOIC/DESADV/WAYBIL SUPPLIER ISSUER_IMAGE
                docInfo.itemText = `${filenameExtension} ${translate(
                  'dxMessages.headers.supplier'
                )}`;
              } else if (
                formatType ===
                Constants.PROCESS_DOCUMENT_FORMAT_TYPE_RECIPIENT_IMAGE
              ) {
                //  INVOIC/DESADV/WAYBIL CLIENT RECIPIENT_IMAGE
                docInfo.itemText = `${filenameExtension} ${translate(
                  'dxMessages.headers.customer'
                )}`;
              } else if (
                formatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_TARGET
              ) {
                //  INVOIC/DESADV/WAYBIL CLIENT TARGET
                docInfo.itemText = `${filenameExtension} ${translate(
                  'dxMessages.headers.customer'
                )} (${translate(
                  'dxMessages.processDocumentFormatTypes.TARGET'
                )})`;
              } else if (
                formatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DX ||
                formatType === null
              ) {
                //  INVOIC/DESADV/WAYBIL UBL
                docInfo.itemText = 'UBL';
              } else if (
                formatType ===
                Constants.PROCESS_DOCUMENT_FORMAT_TYPE_ISSUER_ORIGINAL
              ) {
                //  INVOIC/DESADV/WAYBIL SUPLIER ISSUER_ORIGINAL
                docInfo.itemText = `${filenameExtension} ${translate(
                  'dxMessages.headers.supplier'
                )} (${translate(
                  'dxMessages.processDocumentFormatTypes.ORIGINAL'
                )})`;
              } else {
                // Unknown doc
                // eslint-disable-next-line no-console
                console.log(
                  `Unknown document ${Metadata.processDocumentFormatType} value : %o`,
                  docInfo.data
                );
                docInfo = null;
              }
            } else {
              // eslint-disable-next-line no-console
              console.log(
                `Unknown document ${Metadata.documentTypeCode} value : %o`,
                docInfo.data
              );
              docInfo = null;
            }

            if (docInfo) {
              availableDownloads.push(docInfo);
            }
          });
          // Store the available doc representation which helps to define the menu items.
          setAvailableDownloads(availableDownloads);
          setAnchorEl(currentTarget);
        })
        .catch((e) => {
          showNotification('dxMessages.download.error', 'warning');
        });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadClick = () => {
    const { record } = props;
    if (
      record &&
      record.properties &&
      record.properties[Metadata.readStatus] === Constants.READ_STATUS_NEW
    ) {
      record.properties[Metadata.readStatus] = Constants.READ_STATUS_DOWNLOADED;
    }
    dispatch(readStatusChange(Constants.READ_STATUS_DOWNLOADED));
  };

  const open = Boolean(anchorEl);

  // Renders the menu according to the doc representation fetched when click to open
  const toRender = renderAsMenu ? (
    <span>
      <Tooltip title={translate(tooltip)}>
        <IconButton
          aria-label='download'
          aria-owns={open ? 'download-menu' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
          color='primary'
          style={{ visibility: open ? 'visible' : undefined }}
          className={className}
        >
          <DownloadIcon />
          <ArrowDropDownIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id='download-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        getContentAnchorEl={null}
      >
        {availableDownloads &&
          availableDownloads?.map((doc) => {
            const id = doc.data.id;
            return (
              <MenuItem
                onClick={handleDownloadClick}
                key={id}
                component='a'
                href={`/${Constants.DXPURCHASE_APP}/${id}/download`}
                className={classes.menuItemLink}
              >
                <div className={classes.menuItemText}>
                  <ListItemIcon>
                    <DownloadIcon />
                  </ListItemIcon>
                  <ListItemText disableTypography>{doc.itemText}</ListItemText>
                </div>
              </MenuItem>
            );
          })}
      </Menu>
    </span>
  ) : (
    <a
      href={`/${Constants.DXPURCHASE_APP}/${record.id}/download`}
      onClick={handleDownloadClick}
    >
      <Tooltip title={translate(tooltip)}>
        <IconButton color='primary' className={className}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    </a>
  );
  return toRender;
};

export default DownloadButton;
