import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
export const DxRoleCreate = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify('resources.address.edit.notifications.created');
    redirect('list', props.basePath);
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput
          label={'dxMessages.companyManagement.dxrole.name'}
          source='rolle'
          required={true}
        />
        <SelectInput
          label={'dxMessages.companyManagement.dxrole.adminOnly'}
          source='adminOnly'
          choices={[
            { id: true, name: 'TRUE' },
            { id: false, name: 'FALSE' },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};
export default DxRoleCreate;
