import ReactAdminButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { FC } from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      position: 'relative',
      color: 'white',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }),
  { name: 'Button' }
);

const sanitizeRestProps = ({
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  submitOnEnter,
  onSave,
  invalid,
  saving,
  mutationMode,
  validating,
  ...rest
}) => rest;

const Button: FC<any> = ({
  onClick,
  label = 'button',
  variant = 'contained',
  className,
  loading,
  icon,
  color,
  ...rest
}: any) => {
  const translate = useTranslate();
  const classes = useStyles();

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <ReactAdminButton
      className={classnames(classes.button, className)}
      onClick={handleClick}
      variant={variant}
      color={color || (loading ? 'default' : 'primary')}
      {...sanitizeRestProps(rest)}
    >
      {loading ? (
        <CircularProgress
          size={18}
          thickness={2}
          className={classes.leftIcon}
        />
      ) : (
        icon &&
        React.cloneElement(icon, {
          className: classnames(classes.leftIcon, classes.icon),
        })
      )}
      {label && translate(label, { _: label })}
    </ReactAdminButton>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  icon: PropTypes.element,
  loading: PropTypes.bool,
};

export default Button;
