import { colors } from '@dx-ui/dx-common';
import { Box, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { FC } from 'react';
import {
  BooleanInput,
  FieldProps,
  linkToRecord,
  SelectInput,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { Link } from 'react-router-dom';
import { isPspAdministrator } from '../../configuration';
import Job from '../../job/Job';
import { Account } from '../../types';
import Identities from '../Identities';
import LoginEventAside from './LoginEventAside';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
  // The two entries below: make a Switch red when unchecked.
  switchBase: {
    // Unchecked switch color for the thumb.
    color: colors.functionalRed,
  },
  track: {
    // Unchecked switch color for the track.
    backgroundColor: colors.functionalRed,
  },
}));

/**
 * All the user account info, excepted the usage rights.
 *
 * Only visible by administrators.
 * The info of the company the account belongs to are visible only by PSP administrators
 * (company administrators only see the accounts of their own company, so no need to
 * display it as it's the same for all user accounts).
 */
const UserTab: FC<FieldProps<Account>> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { loaded, permissions } = usePermissions();
  const { values } = useFormState({ subscription: { values: true } });
  // const { identity, loading } = useGetIdentity();

  const { record } = props;

  if (!loaded || !permissions || !record) return null;

  const companyId = record.company.id;

  return (
    <div style={{ width: '100%' }}>
      <Box display='flex' flexDirection='row-reverse' mb='-1em'>
        <LoginEventAside {...props} />
      </Box>
      <Grid container direction='column' spacing={2}>
        {/* <Grid item>
          <Typography variant='caption'>
            {translate('resources.accounts.edit.tabs.user.created', {
              date: new Date(record.created).toLocaleDateString(
                // @ts-ignore
                identity.language,
                {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                }
              ),
            })}
          </Typography>
        </Grid> */}
        <Grid item style={{ marginBottom: '1em' }}>
          <Grid container spacing={2} alignItems='flex-start'>
            <Grid item>
              {values.enabled && (
                <Typography>
                  {translate(
                    'resources.accounts.edit.tabs.user.accountEnabled'
                  )}
                </Typography>
              )}
              {!values.enabled && (
                <Typography>
                  {translate(
                    'resources.accounts.edit.tabs.user.accountDisabled'
                  )}
                </Typography>
              )}
              <BooleanInput
                label=''
                source='enabled'
                helperText='resources.accounts.edit.tabs.user.accountEnabledHelper'
                options={{
                  classes: {
                    switchBase: classes.switchBase,
                    track: classes.track,
                  },
                }}
              />
            </Grid>
            <Grid item>
              {values.accountNonLocked && (
                <Typography>
                  {translate('resources.accounts.edit.tabs.user.nonLocked')}
                </Typography>
              )}
              {!values.accountNonLocked && (
                <Typography>
                  {translate('resources.accounts.edit.tabs.user.locked')}
                </Typography>
              )}
              <BooleanInput
                label=''
                source='accountNonLocked'
                disabled={record.accountNonLocked}
                helperText={
                  record.accountNonLocked
                    ? 'resources.accounts.edit.tabs.user.nonLockedHelper'
                    : 'resources.accounts.edit.tabs.user.lockedHelper'
                }
                options={{
                  classes: {
                    switchBase: classes.switchBase,
                    track: classes.track,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* PSP administrators can see accounts of any company so display the account company. */}
        {isPspAdministrator(permissions) && (
          <Grid item>
            <Company {...props} />
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Person {...props} />
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <Typography variant='h5'>
                  {translate(
                    'resources.accounts.edit.tabs.user.sections.job.title'
                  )}
                </Typography>
                <Job
                  // @ts-ignore
                  companyId={companyId}
                  hasEdit={true}
                  {...props}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Identities {...props} companyId={companyId} disabled={false} />
        </Grid>
      </Grid>
    </div>
  );
};

const Company: FC<FieldProps<Account>> = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5'>
        {translate('resources.accounts.edit.tabs.user.sections.company.title')}
      </Typography>
      <Typography variant='caption'>
        {translate(
          'resources.accounts.edit.tabs.user.sections.company.subtitle'
        )}
        <IconButton
          color='primary'
          component={Link}
          to={linkToRecord('/configuration-companies', record?.company.id)}
          size='small'
        >
          <EditIcon style={{ width: '16px', height: '16px' }} />
        </IconButton>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container direction='column'>
            <Grid item>
              <TextInput
                label='resources.accounts.edit.tabs.user.sections.company.name'
                source='company.name'
                fullWidth
                variant='standard'
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextInput
                label='resources.accounts.edit.tabs.user.sections.company.fiscalCode'
                source='company.fiscalCode'
                fullWidth
                variant='standard'
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <TextInput
            label='resources.accounts.edit.tabs.user.sections.company.address'
            source='company.address'
            multiline
            fullWidth
            variant='standard'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const Person: FC<FieldProps<Account>> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { loaded, permissions } = usePermissions();
  const { values } = useFormState({ subscription: { values: true } });

  if (!loaded || !permissions) return null;

  const { record } = props;

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5'>
        {translate('resources.accounts.edit.tabs.user.sections.person.title')}
      </Typography>
      {isPspAdministrator(permissions) && (
        <Typography variant='caption'>
          {translate(
            'resources.accounts.edit.tabs.user.sections.person.subtitleForPspAdmin'
          )}
          <IconButton
            color='primary'
            component={Link}
            to={linkToRecord('/configuration-people', record?.person?.id)}
            size='small'
          >
            <EditIcon style={{ width: '16px', height: '16px' }} />
          </IconButton>
        </Typography>
      )}
      {!isPspAdministrator(permissions) && (
        <Typography variant='caption'>
          {translate(
            'resources.accounts.edit.tabs.user.sections.person.subtitleForCompanyAdmin'
          )}{' '}
          <AccountCircleIcon fontSize='small' />
          ).
        </Typography>
      )}
      <Grid container direction='column' spacing={0}>
        <Grid item>
          <TextInput
            label='resources.accounts.edit.tabs.user.sections.person.email'
            source='person.email'
            fullWidth
            variant='standard'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInput
                label='resources.accounts.edit.tabs.user.sections.person.firstname'
                source='person.firstname'
                fullWidth
                variant='standard'
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextInput
                label='resources.accounts.edit.tabs.user.sections.person.lastname'
                source='person.lastname'
                fullWidth
                variant='standard'
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <SelectInput
            label='resources.accounts.edit.tabs.user.sections.person.language'
            source='person.language'
            choices={[
              {
                id: 'en',
                name: 'resources.accounts.edit.tabs.user.sections.person.english',
              },
              {
                id: 'ro',
                name: 'resources.accounts.edit.tabs.user.sections.person.romanian',
              },
              {
                id: 'fr',
                name: 'resources.accounts.edit.tabs.user.sections.person.french',
              },
            ]}
            variant='standard'
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item>
          {values?.person?.phone && (
            <TextInput
              label='resources.accounts.edit.tabs.user.sections.person.phone'
              source='person.phone'
              fullWidth
              variant='standard'
              InputProps={{
                readOnly: true,
              }}
            />
          )}
          {!values?.person?.phone && (
            <Typography variant='caption'>
              {translate(
                'resources.accounts.edit.tabs.user.sections.person.noPhone'
              )}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserTab;
