import { Constants } from '@dx-ui/dx-common/src/constants';
import React from 'react';
import {
  createColumnsDespatchAdvice,
  createColumnsExtendedInvoiceDetails,
  createColumnsOrder,
  createColumnsProcessDetails,
  createColumnsTransportDetails,
  createCommonColumns,
  createCommonFilters,
  createDespatchAdviceFilters,
  createExtendedInvoiceDetailsFilters,
  createOrderFilters,
  createProcessDetailsFilters,
  createTransportDetailsFilters,
} from '../../../aspects';
import { WaybillService } from '../../../services';
import { CommonList } from '../../../shared';
import BulkDeleteActionButtons from '../../../shared/BulkDeleteActionButtons';
import ContextualToolbar from '../../../shared/ContextualToolbar';

/**
 * Declare the additional columns to display vs commons
 */
const columns: any[] = [
  ...createCommonColumns(),
  ...createColumnsOrder(),
  ...createColumnsProcessDetails(),
  ...createColumnsDespatchAdvice(),
  ...createColumnsTransportDetails(),
  ...createColumnsExtendedInvoiceDetails(),
];

/**
 * Specific filters
 */
const additionalFilters: any[] = [
  ...createCommonFilters(),
  ...createOrderFilters(),
  ...createProcessDetailsFilters(),
  ...createDespatchAdviceFilters(),
  ...createTransportDetailsFilters(),
  ...createExtendedInvoiceDetailsFilters(),
];
const WebWayBillList = (props) => {
  const { permissions } = props;
  return (
    <CommonList
      title='dxMessages.waybill.title'
      {...props}
      columns={columns}
      additionalFilters={additionalFilters}
      contextualToolbar={
        <ContextualToolbar
          resource={Constants.RESOURCE_WAYBILL}
          allowEdit={WaybillService.canCreate(permissions)}
        />
      }
      bulkActions={<BulkDeleteActionButtons />}
    />
  );
};

export default WebWayBillList;
