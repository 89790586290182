import { merge } from 'lodash';
import { TranslationMessages } from 'ra-core';
import hebrewMessages from 'ra-language-hebrew';

const fixedRaMessages: TranslationMessages = merge(hebrewMessages, {
  ra: {
    action: {
      export: 'יצוא',
      unselect: 'בטל את הבחירה',
      close: 'סגירה',
    },
    page: {
      empty: 'לא נמצא %{name}.',
      dashboard: 'דשבורד',
    },
    navigation: {
      skip_nav: 'דלג לתוכן',
    },
  },
});

export { fixedRaMessages as hebrewMessages };
