import { Button, FormControl, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import PropTypes from 'prop-types';
import { refreshView as refreshViewAction } from 'ra-core';
import React from 'react';
import { useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import { colors } from '../constants/Colors';
import { AutoCompleteMultiSelect } from './inputs';
import { withoutPlaceholderMultiple, withPlaceholderMultiple } from './menus';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: '3px 0px',
      borderRadius: 4,
      '& div:before': {
        borderBottom: 'unset !important', // remove MuiInput underline to mimic a button
      },
      '& div:after': {
        borderBottom: 'unset !important', // remove MuiInput underline to mimic a button
      },
      // Behaves such as react-admin buttons on hover and focus
      '&:hover': {
        boxSizing: 'border-box',
        borderRadius: 4,
        backgroundColor: theme.palette.primary.light,
      },
      '& div[role=button]:focus': {
        backgroundColor: 'transparent',
      },
    },
    viewColumnIcon: {
      marginRight: 2,
    },
  }),
  { name: 'ListColumnSelector' }
);

const ListColumnSelector = (props) => {
  const { columns, columnsVisibility, refreshView } = props;

  const translate = useTranslate();
  const classes = useStyles();

  const getOptionId = (x) => x.id;
  const renderOptionLabel = (x) => x.label;

  const renderIcon = () => (
    <Tooltip title={translate('dxMessages.list.columns', { _: 'Columns' })}>
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          color: colors.mainColor5,
          fontSize: 'small',
        }}
      >
        <ViewColumnIcon className={classes.viewColumnIcon} />
        <div style={{ display: 'block', textTransform: 'uppercase' }}>
          {translate('dxMessages.list.columns', { _: 'Columns' })}
        </div>
        <ArrowDropDown />
      </div>
    </Tooltip>
  );

  const handleSetDefault = () => {
    const newColumnsVisibility = new Map(
      columns
        .filter((column) => !column.alwaysOn && !column.hiddenByDefault)
        .map((column) => [column.id, true])
    );
    refreshView();
    if (props.onChange) {
      props.onChange(newColumnsVisibility);
    }
  };

  /**
   * Updates the list of columns to display
   */
  const handleShownChange = (evt) => {
    const selectedColumnIds = columns
      .filter((column) => !column.alwaysOn && columnsVisibility.get(column.id))
      .map((column) => column.id);

    // Initialized the new list from the event.
    // The value is an array containing the new state of the multi selection
    const newSelectedColumnIds = withoutPlaceholderMultiple(evt.target.value);
    let currentColumnId;

    // The existing integration done with our List component and based on the columnsVisibility Map
    // implies to retrieve the only one element which have been added or removed.
    if (newSelectedColumnIds.length > selectedColumnIds.length) {
      // Addition case. Use array destructring here.
      [currentColumnId] = newSelectedColumnIds.filter(
        (col) => !selectedColumnIds.includes(col)
      );
    } else {
      // Subtraction case. Use array destructring here.
      [currentColumnId] = selectedColumnIds.filter(
        (col) => !newSelectedColumnIds.includes(col)
      );
    }

    // Retrieve the related column info. Use array destructuring
    const [column] = columns.filter((col) => col.id === currentColumnId);
    // Update the columnsVisibility to be passed back to the List component

    const nextState = !columnsVisibility.get(currentColumnId);
    if (nextState === !column.hiddenByDefault) {
      columnsVisibility.delete(currentColumnId);
    } else {
      columnsVisibility.set(currentColumnId, nextState);
    }
    refreshView();

    if (props.onChange) {
      props.onChange(columnsVisibility);
    }
  };

  const filterOption = (option, query, lowerCaseQuery) => {
    if (!query) {
      return true;
    }
    let { label } = option;
    label = translate(label);
    return label.toLowerCase().indexOf(lowerCaseQuery) !== -1;
  };

  const selectableColumns = columns
    .filter((column) => !column.alwaysOn)
    .filter(
      (column) =>
        column.displayInGroupingMode || // explicitly targeted for being displayed in grouping mode
        column.displayInGroupingMode === undefined // implicitly targeted for being displayed in grouping mode
    );
  const selectedColumnIds = columns
    .filter((column) => !column.alwaysOn && columnsVisibility.get(column.id))
    .map((column) => column.id);

  return (
    <div>
      <FormControl className={classes.root}>
        <AutoCompleteMultiSelect
          value={withPlaceholderMultiple(Array.from(selectedColumnIds))}
          onChange={handleShownChange}
          placeholder={undefined}
          filterPlaceholder={undefined}
          icon={undefined}
          ref={undefined}
          sortLabels={true}
          options={selectableColumns}
          getOptionId={getOptionId}
          renderOptionLabel={renderOptionLabel}
          filterOption={filterOption}
          onOpen={undefined}
          loading={undefined}
          errorText={undefined}
          renderValue={renderIcon()}
        >
          {!!Array.from(selectedColumnIds).length && (
            <Button
              style={{ color: colors.black65 }}
              onClick={handleSetDefault}
            >
              {translate('dxMessages.list.default', {
                _: 'DEFAULT',
              })}
            </Button>
          )}
        </AutoCompleteMultiSelect>
      </FormControl>
    </div>
  );
};

ListColumnSelector.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  columnsVisibility: PropTypes.object,
};

const enhance = connect(null, { refreshView: refreshViewAction });

export default enhance(ListColumnSelector);
