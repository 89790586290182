import { colors } from '@dx-ui/dx-common/src';
import { Box, Tooltip, Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { useLocale } from 'react-admin';

const RestoreDefault = ({ text, tooltip, resetDashboardToDefault }) => {
  return (
    <Tooltip title={tooltip}>
      <button
        style={{
          color: colors.mainColor5,
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          textDecoration: 'underline',
          display: 'inline',
          margin: 0,
          paddingRight: '0.5em',
          paddingLeft: 0,
          paddingBottom: 0,
          paddingTop: 0,
          fontSize: 'inherit',
        }}
        onClick={() => resetDashboardToDefault()}
      >
        {text}
      </button>
    </Tooltip>
  );
};

const WelcomeMessage = ({ resetDashboardToDefault }) => {
  const locale = useLocale();
  switch (locale) {
    case 'fr':
      return (
        <Box>
          <Typography>Bienvenue sur la plateforme DocProcess BEA.</Typography>
          <Typography>
            Vous pouvez personnaliser votre tableau de bord en cliquant sur
            l’icône <SettingsIcon /> en haut à droite de la page.
          </Typography>
          <Typography>
            <RestoreDefault
              text='Cliquer ici'
              tooltip='Tableau de bord par défaut'
              resetDashboardToDefault={resetDashboardToDefault}
            />
            pour restaurer le tableau de bord par défaut
          </Typography>
        </Box>
      );
    case 'ro':
      return (
        <Box>
          <Typography>Bun venit pe platforma DocProcess BEA.</Typography>
          <Typography>
            Îți poți personaliza tabloul de bord apăsând pe pictogramă{' '}
            <SettingsIcon /> în partea dreaptă sus a paginii.
          </Typography>
          <Typography>
            <RestoreDefault
              text='Click aici'
              tooltip='Restabili implicit'
              resetDashboardToDefault={resetDashboardToDefault}
            />
            pentru a restabili tabloul de bord la setările implicite
          </Typography>
        </Box>
      );
    default:
      return (
        <Box>
          <Typography>Welcome to the DocProcess BEA platform.</Typography>
          <Typography>
            Create your dashboard by clicking the <SettingsIcon /> icon at the
            top right-corner of the screen.
          </Typography>
          <Typography>
            <RestoreDefault
              text='Click here'
              tooltip='Restore default'
              resetDashboardToDefault={resetDashboardToDefault}
            />
            to restore dashboard to default settings
          </Typography>
        </Box>
      );
  }
};

export default WelcomeMessage;
