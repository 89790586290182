import { cellStyle, widthPresets } from '@dx-ui/dx-common';
import { GenericV2InvoiceField } from '@dx-ui/dx-common/src/components/fields/GenericFields';
import { TextInput } from 'react-admin';

export const V2TextField = (props) => <GenericV2InvoiceField {...props} />;

export const createColumnsV2Common = () => [
  {
    id: 'currentState',
    label: 'dxMessages.companyManagement.commonDocumentColumns.currentState',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    alwaysOn: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <V2TextField {...props} />,
  },
  {
    id: 'dxuid',
    label: 'dxMessages.companyManagement.commonDocumentColumns.dxuid',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <V2TextField {...props} />,
  },
  {
    id: 'sourceCif',
    label: 'dxMessages.companyManagement.commonDocumentColumns.sourceCif',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    alwaysOn: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <V2TextField {...props} />,
  },
  {
    id: 'destinationCif',
    label: 'dxMessages.companyManagement.commonDocumentColumns.destinationCif',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <V2TextField {...props} />,
  },
  {
    id: 'originalFilename',
    label:
      'dxMessages.companyManagement.commonDocumentColumns.originalFileName',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <V2TextField {...props} />,
  },
];

export const createV2CommonFilters = () => [
  {
    id: 'currentState',
    filter: (props) => (
      <TextInput
        source='currentState'
        label='dxMessages.companyManagement.commonDocumentColumns.currentState'
        resettable
        {...props}
      />
    ),
  },
  {
    id: 'dxuid',
    filter: (props) => (
      <TextInput
        source='dxuid'
        label='dxMessages.companyManagement.commonDocumentColumns.dxuid'
        resettable
        {...props}
      />
    ),
  },
  {
    id: 'sourceCif',
    filter: (props) => (
      <TextInput
        source='sourceCif'
        label='dxMessages.companyManagement.commonDocumentColumns.sourceCif'
        resettable
        {...props}
      />
    ),
    alwaysOn: true,
  },
  {
    id: 'destinationCif',
    filter: (props) => (
      <TextInput
        source='destinationCif'
        label='dxMessages.companyManagement.commonDocumentColumns.destinationCif'
        resettable
        {...props}
      />
    ),
  },
  {
    id: 'originalFilename',
    filter: (props) => (
      <TextInput
        source='originalFilename'
        label='dxMessages.companyManagement.commonDocumentColumns.originalFileName'
        resettable
        {...props}
      />
    ),
  },
];
