// An action form is a form, inline in the task list - one per task,
// enabling users to complete a task.
// If the task UI is simple enough (approve/reject, ...), the whole UI
// is embedded in the row.
// If not, the action form only displays a single button which, when clicked,
// opens the complex UI in the react-admin "edit" mode of the "task" resource.
// The opening of the right UI is handled by TaskEdit.tsx and ActionInput.tsx
//
// Note: we use the suffix "Form" (ActionForm) for the components which takes
// care by themselves on their data submission (useMutation, ...) and the suffix
// "Input" (ActionInput) for the components which are included into a react-admin
// edition form (react-admin takes care of submitting their values).
import LaunchIcon from '@material-ui/icons/Launch';
import { FC } from 'react';
import { EditButton, useTranslate } from 'react-admin';
import { FieldProps, Task } from '../../../shared/types';
import ApprovalForm from './form/ApprovalForm';

const ActionForm: FC<FieldProps<Task<any>>> = (props) => {
  const { record, basePath } = props;
  const translate = useTranslate();

  if (!record) return null;
  switch (record.uiSpecification.type) {
    case 'approval':
      // Row-embedded mode.
      return <ApprovalForm record={record} />;
    // No edit button for cost centre allocation & multi-user validation tasks
    // (seperate UI application)
    case 'allocate_cost_centres':
    case 'multiUserSerialInvoiceValidationCockpit':
      return null;
    default:
      // Edit mode.
      return (
        <EditButton
          record={record}
          basePath={basePath}
          label={translate(`dxMessages.task.button.open`, { _: 'Open Task' })}
          icon={<LaunchIcon style={{ width: '32px', height: '32px' }} />}
        />
      );
  }
};

export default ActionForm;
