import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { useState } from 'react';
import {
  useGetIdentity,
  useListContext,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import { DxTheme, GA_EVENTS, sendGAEvent } from '../..';
import { SavedQueriesSaveDialog } from './SavedQueriesSaveDialog';
import { SavedQuery, useSavedQueries } from './useSavedQueries';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    saveFilterBtn: {
      height: '32px',
      fontWeight: 'unset',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  { name: 'SavedQueriesSaveBtn' }
);

const SavedQueriesSaveBtn = ({ disabled }) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const resource = useResourceContext();
  const listContext = useListContext();
  const [savedQueries, setSavedQueries] = useSavedQueries();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const handleClick = () => {
    setOpen(true);
  };

  const addSavedQuery = (queryLabel: string) => {
    const query: SavedQuery = {
      label: queryLabel,
      resource,
      displayedFilters: listContext.displayedFilters,
      filter: listContext.filterValues,
      perPage: listContext.perPage,
      sort: listContext.currentSort,
      pathName: window?.location?.pathname,
    };

    setSavedQueries([query, ...savedQueries]);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = (queryLabel: string) => {
    sendGAEvent(
      GA_EVENTS.categories.SAVED_QUERIES.name,
      GA_EVENTS.categories.SAVED_QUERIES.actions.SAVE,
      account?.company?.cmsRootDir
    );

    addSavedQuery(queryLabel);
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        color='primary'
        startIcon={<BookmarkIcon />}
        className={classes.saveFilterBtn}
        type='submit'
        disabled={disabled}
      >
        {translate('dxMessages.filter.savedQueriesSaveBtn')}
      </Button>
      <SavedQueriesSaveDialog
        open={open}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default SavedQueriesSaveBtn;
