import {
  Grid,
  IconButton,
  Paper,
  TextField as MUTextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get } from 'lodash';
import { FC, useMemo } from 'react';
import {
  ArrayInput,
  Error,
  InputProps,
  Loading,
  required,
  SelectArrayInputProps,
  SimpleFormIterator,
  useGetList,
  useInput,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { Link } from 'react-router-dom';
import { Feature } from '../../types';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The tab with company subscriptions.
 *
 * Only visible for administrators and in read-only mode.
 */
const SubscriptionsTab = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const { loaded, permissions } = usePermissions();

  // Fetch all the features exposed by the Doc Process platform.
  const { data, loading, error } = useGetList<Feature>(
    'configuration-dxfeatures',
    {
      page: 1,
      perPage: 200, // Should not be more than 200 before a long time.
    }
  );

  // Get the current form values to compute addedFeatureIds below.
  const { values } = useFormState({ subscription: { values: true } });

  if (error) return <Error error={error} />;
  if (!loaded || loading) return <Loading />;

  // 'data' is a JSON object whose properties are the feature ID.
  // Convert it into an array of features and add a title to each
  // taking the i18n translation of their ID if any.
  const features = Object.values(data)?.map((f) => ({
    ...f,
    title: translate(`resources.dxfeatures.${f.id}.description`, {
      _: f.id,
    }),
  }));

  // Get the IDs of the features already added into the form.
  const addedFeatureIds =
    values?.subscriptions
      // When just added in the react-admin ArrayInput (not selected yet
      // in the dropdown list), values.subscriptions contains an undefined
      // element, so skip it.
      ?.filter((s) => !!s?.feature)
      .map((s) => s.feature.id) || [];

  if (!loaded || !permissions) return null;

  const subtitle = () => {
    return (
      <Typography variant='caption'>
        <Alert severity='warning' style={{ margin: '1em' }}>
          {translate(
            'resources.companies.edit.tabs.subscriptions.psp_message',
            {
              _:
                'For each feature, a set of excluded roles can be selected in order ' +
                'to limit the list of available roles allowed for any subscribed ' +
                'feature when assigning the user account usages',
            }
          )}
        </Alert>
        {translate('resources.companies.edit.tabs.subscriptions.subtitle_4', {
          _: 'Once subscribed, each feature usage can be assigned to a user account through the Accounts menu',
        })}
        <IconButton
          color='primary'
          component={Link}
          to={'/configuration-accounts'}
          size='small'
        >
          <EditIcon style={{ width: '16px', height: '16px' }} />
        </IconButton>
        .
      </Typography>
    );
  };

  return (
    <Paper className={classes.paper} style={{ marginTop: '1em' }}>
      {subtitle()}
      <ArrayInput label='' source='subscriptions'>
        <SimpleFormIterator
          // Disable the Add button when all the platform features
          // are already selected.
          disableAdd={addedFeatureIds.length >= features.length}
        >
          <SubscriptionInput
            features={features}
            addedFeatureIds={addedFeatureIds}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Paper>
  );
};

const SubscriptionInput: FC<
  Omit<InputProps, 'source'> & {
    source?: string;
  }
> = ({ source, features, addedFeatureIds }) => {
  const { values } = useFormState({ subscription: { values: true } });
  const translate = useTranslate();
  const featureId = get(values, `${source}.feature.id`);
  const roleIds =
    features.find((f) => f.id === featureId)?.availableRoles.map((r) => r.id) ||
    [];
  const addedExcludedRoleIds =
    values?.subscriptions
      ?.filter((s) => !!s?.feature) // skip potential new added subscription not yet assigned
      ?.filter((s) => s.feature.id === featureId)
      ?.filter((s) => !!s?.excludedRoleIds) // skip potential new added subscription not yet assigned
      .map((s) =>
        s.excludedRoleIds.map((roleId) => ({
          id: roleId.id ? roleId.id : roleId,
          // Look for the role description into the i18n domain bundles (i18n/xx-domain.tsx),
          // key: resources.dxfeatures.<featureId>.<roleId>, and default to the roleId itself.
          name: translate(
            `resources.dxfeatures.${featureId}.${
              roleId.id ? roleId.id : roleId
            }`,
            {
              _: roleId.id ? roleId.id : roleId,
            }
          ),
        }))
      )?.[0] || [];

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={2}>
        <FeatureInput
          features={features}
          addedFeatureIds={addedFeatureIds}
          source={`${source}.feature`}
          fullWidth
          validate={required()}
        />
      </Grid>
      <Grid item xs={8}>
        <ExcludedRoleInput
          source={`${source}.excludedRoleIds`}
          addedExcludedRoleIds={addedExcludedRoleIds}
          featureId={featureId}
          roleIds={roleIds}
        />
      </Grid>
    </Grid>
  );
};

const FeatureInput: FC<InputProps> = (props) => {
  const translate = useTranslate();

  // Link the material-ui field below (Autocomplete )with the react-admin input framework.
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const { features, addedFeatureIds } = props;

  return (
    <Autocomplete
      options={features
        // Remove the already added features.
        .filter((f) => !addedFeatureIds.includes(f.id))
        // Sort them to get a nicer selection dropdown list.
        .sort((f1, f2) => f1.title.localeCompare(f2.title))}
      onChange={(event, val) => onChange(val)}
      // feature may be undefined when just added into the ArrayInput.
      getOptionLabel={(feature) => feature?.title || ''}
      renderInput={(params) => (
        <MUTextField
          {...params}
          name={name}
          label={translate(
            'resources.companies.edit.tabs.subscriptions.feature'
          )}
          variant='standard'
          error={!!(touched && error)}
          helperText={touched && error && translate(error)}
          InputProps={{
            ...params.InputProps,
            // Remove the input box underline, not nice when in an react-admin
            // ArrayInput.
            disableUnderline: true,
          }}
          required={isRequired}
        />
      )}
      {...rest}
    />
  );
};

const ExcludedRoleInput: FC<
  SelectArrayInputProps & { featureId: string; roleIds: Array<string> }
> = (props: any) => {
  const translate = useTranslate();
  const {
    input: { name, onChange, value, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const { featureId, roleIds, addedExcludedRoleIds } = props;

  const options = roleIds.map((roleId) => ({
    id: roleId,
    // Look for the role description into the i18n domain bundles (i18n/xx-domain.tsx),
    // key: resources.dxfeatures.<featureId>.<roleId>, and default to the roleId itself.
    name: translate(`resources.dxfeatures.${featureId}.${roleId}`, {
      _: roleId,
    }),
  }));

  const firstLetter = (option) => {
    const firstLetter = (option.name[0] || '').toUpperCase();
    return /[0-9]/.test(firstLetter) ? '0-9' : firstLetter;
  };

  const renderInput = (params) => (
    <MUTextField
      {...params}
      name={name}
      variant='standard'
      label={translate(
        'resources.companies.edit.tabs.subscriptions.excludedRoleIds'
      )}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      InputProps={{ ...params.InputProps, disableUnderline: true }}
    />
  );

  const formattedValue = useMemo(() => {
    if (!value) return [];
    return value.map((v) =>
      v.id
        ? { id: v.id, name: v.name }
        : {
            id: v,
            name: translate(`resources.dxfeatures.${featureId}.${v}`, {
              _: v,
            }),
          }
    );
  }, [featureId, translate, value]);

  const handleOnChange = (val) => {
    onChange(val);
  };

  // When less than 5 roles, display a simple dropdown list, otherwise a list with grouped
  // entry per first letter.
  if (options.length < 6) {
    return (
      <Autocomplete
        {...rest}
        value={formattedValue}
        multiple
        size='small'
        options={options
          .filter((r) => !addedExcludedRoleIds.map((x) => x.id).includes(r.id))
          .sort((a, b) => {
            const order = -firstLetter(b).localeCompare(firstLetter(a));
            return order !== 0 ? order : -b.name.localeCompare(a.name);
          })}
        getOptionSelected={(option, value) => {
          return option.id === value.id || option.id === value;
        }}
        onChange={(event, val) => handleOnChange(val)}
        getOptionLabel={(role) => {
          return role.name;
        }}
        renderInput={renderInput}
      />
    );
  } else {
    return (
      <Autocomplete
        {...rest}
        value={formattedValue}
        multiple
        size='small'
        options={options
          .filter((r) => !addedExcludedRoleIds.map((x) => x.id).includes(r.id))
          .sort((a, b) => {
            const order = -firstLetter(b).localeCompare(firstLetter(a));
            return order !== 0 ? order : -b.name.localeCompare(a.name);
          })}
        groupBy={(option) => firstLetter(option)}
        getOptionSelected={(option, value) => {
          return option.id === value.id || option.id === value;
        }}
        onChange={(event, val) => handleOnChange(val)}
        getOptionLabel={(role) => {
          return role.name;
        }}
        renderInput={renderInput}
      />
    );
  }
};
export default SubscriptionsTab;
