import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  useLocale,
  useNotify,
  useRedirect,
} from 'react-admin';
import { CountryService } from '../../../../services';

export const PeppolEdit = (props: any) => {
  const notify = useNotify();
  const locale = useLocale();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.companies.edit.notificationsPeppol.updated');
    redirect('list', props.basePath);
  };

  const countryService = new CountryService(locale);
  const countries = countryService.getCountries();
  const countryItems = countries.map((country) => {
    return {
      id: country.code,
      name: country.name,
    };
  });

  return (
    <Edit {...props} onSuccess={onSuccess} undoable={false}>
      <SimpleForm>
        <TextInput
          label={'dxMessages.peppol.issuer_identification.title'}
          source='issuerIdentification'
        />
        <TextInput
          label={'dxMessages.peppol.company_name.title'}
          source='companyName'
        />
        <SelectInput
          label={'dxMessages.peppol.country.title'}
          source='country'
          choices={countryItems}
        />
        <TextInput
          label={'dxMessages.peppol.company_identification.title'}
          source='companyIdentification'
        />
        <TextInput
          label={'dxMessages.peppol.schemaId.title'}
          source='schemaId'
          type='number'
          format={(value) => (value ? value.toString().padStart(4, '0') : '')}
        />
        <TextInput label={'dxMessages.peppol.value.title'} source='valueId' />
      </SimpleForm>
    </Edit>
  );
};

export default PeppolEdit;
