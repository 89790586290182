// in src/customRoutes.js
import { RouteWithoutLayout } from 'react-admin';
import { Route } from 'react-router-dom';
import { ChangeUserEmail } from '.';
import ProfileEdit from '../configuration/profile/ProfileEdit';
import { LandingPage } from '../layout';
import {
  ChangePasswordRoutePath,
  LoginPage,
  LostPasswordRoutePath,
  MyAccount,
  ResetPasswordRoutePath,
  UserProfileRoutePath,
} from '../login';

const ChangeEmailRoutePath = '/change-email/:token';

const routes = [
  <Route
    key='resetPassword'
    exact
    path={ResetPasswordRoutePath}
    render={(props) => <LoginPage {...props} resetPassword />}
    //@ts-ignore
    noLayout
  />,

  <Route
    key='lostPassword'
    exact
    path={LostPasswordRoutePath}
    render={(props) => <LoginPage {...props} lostPassword />}
    //@ts-ignore
    noLayout={true}
  />,

  <Route
    key='changeEmail'
    exact
    path={ChangeEmailRoutePath}
    render={(props) => <ChangeUserEmail {...props} />}
    //@ts-ignore
    noLayout
  />,

  <Route
    key='changePassword'
    path={ChangePasswordRoutePath}
    render={(props) => (
      <MyAccount {...props} basePath={ChangePasswordRoutePath} feature={1} />
    )}
  />,

  // There are two different profile pages depending on the user owns a V2 or a V3 account.

  // V2 profile.
  <Route
    key='userProfile'
    path={UserProfileRoutePath}
    render={(props) => (
      <MyAccount {...props} basePath={UserProfileRoutePath} feature={0} />
    )}
  />,

  // V3 profile.
  <Route key='profile' path='/profile' render={() => <ProfileEdit />} />,

  // landing page
  <RouteWithoutLayout path='/home' noLayout render={() => <LandingPage />} />,
];

export default routes;
