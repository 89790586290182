import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  DateTimeInput,
  FieldProps,
  ReferenceInput,
  required,
  SimpleFormIterator,
  Translate,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { Company, CompanyUpdateValues } from '../../types';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The tab with the employee delegations.
 *
 * Only visible for administrators.
 */
const DelegationsTab: FC<FieldProps<Company>> = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const { values } = useFormState<CompanyUpdateValues>({
    subscription: onValuesChangeSubscription,
  });

  return (
    <Paper className={classes.paper} style={{ marginTop: '1em' }}>
      <Typography variant='caption'>
        {translate('resources.companies.edit.tabs.delegations.subtitle')}
      </Typography>
      <ArrayInput
        label=''
        source='delegations'
        validate={validateDelegations(translate)}
      >
        <SimpleFormIterator>
          <Delegation companyId={values.id} />
        </SimpleFormIterator>
      </ArrayInput>
    </Paper>
  );
};

const Delegation = ({ source, companyId }: any) => {
  const translate = useTranslate();

  return (
    <Grid container direction='column' spacing={0}>
      <Grid item>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={6}>
            <ReferenceInput
              label='resources.companies.edit.tabs.delegations.representee'
              source={`${source}.representee.id`}
              reference='configuration-people'
              filter={{ companyId: companyId }}
              filterToQuery={(searchText) => ({
                email: [searchText],
              })}
              sort={{ field: 'email', order: 'ASC' }}
              validate={required()}
            >
              <AutocompleteInput
                optionText='email'
                fullWidth
                helperText='resources.companies.edit.tabs.delegations.representeeHelper'
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <Box display='flex' alignItems='center'>
              <Typography>&rArr;&nbsp;&nbsp;</Typography>
              <ReferenceInput
                label='resources.companies.edit.tabs.delegations.delegee'
                source={`${source}.delegee.id`}
                reference='configuration-people'
                filter={{ companyId: companyId }}
                filterToQuery={(searchText) => ({
                  email: [searchText],
                })}
                sort={{ field: 'email', order: 'ASC' }}
                validate={required()}
              >
                <AutocompleteInput
                  optionText='email'
                  fullWidth
                  helperText='resources.companies.edit.tabs.delegations.delegeeHelper'
                />
              </ReferenceInput>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='caption'>
          {translate('resources.companies.edit.tabs.delegations.periodTitle')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2} alignItems='center'>
          <Grid item>
            <DateTimeInput
              label='resources.companies.edit.tabs.delegations.from'
              source={`${source}.from`}
              validate={required()}
            />
          </Grid>
          <Grid item>
            <DateTimeInput
              label='resources.companies.edit.tabs.delegations.to'
              source={`${source}.to`}
              validate={required()}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const validateDelegations =
  (translate: Translate) =>
  (
    values: Array<{
      representee?: { id: string };
      delegee?: { id: string };
      from?: string;
      to?: string;
    }>
  ) => {
    const errors: Array<string> = [];

    values.forEach((delegation, i) => {
      if (
        delegation?.representee?.id &&
        delegation?.delegee?.id === delegation?.representee?.id
      ) {
        errors.push(
          translate(
            'resources.companies.edit.tabs.delegations.errors.samePerson',
            { line: i + 1 }
          )
        );
      }
      if (
        delegation?.from &&
        delegation?.to &&
        new Date(delegation.from) >= new Date(delegation.to)
      ) {
        errors.push(
          translate(
            'resources.companies.edit.tabs.delegations.errors.fromAfterTo',
            { line: i + 1 }
          )
        );
      }
    });

    return errors.join(', ');
  };

// Only trigger a render when the form values change
const onValuesChangeSubscription = { values: true };

export default DelegationsTab;
