import React from 'react';
import { Confirm, useTranslate } from 'react-admin';

export const SavedQueriesDeleteDialog = ({
  open,
  onCancel,
  onConfirm,
  savedQueryName,
}: {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  savedQueryName: string;
}) => {
  const translate = useTranslate();

  return (
    <Confirm
      isOpen={open}
      title='dxMessages.filter.savedQueriesDeleteTitle'
      content={translate('dxMessages.filter.removeQueryRemoveText')}
      translateOptions={{
        savedQuery: savedQueryName,
      }}
      onConfirm={onConfirm}
      onClose={onCancel}
    />
  );
};
