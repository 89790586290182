import {
  Constants,
  DocumentTypeCode,
  Metadata,
  ProcessStatus,
  RegulatorExtraDetailsType,
  Ubl,
} from '@dx-ui/dx-common';
import { OrderLineDetails } from '@dx-ui/lib-oasis-ubl-2.1/src/OrderModel';
import { first, get, set } from 'lodash';
import DespatchAdviceSources from '../modules/despatchAdvice/DespatchAdviceSources';
import WaybillSources from '../modules/waybill/WayBillSources';
import { FieldTypes } from '../shared';
import {
  AlfrescoProperties,
  AllowanceChargeItem,
  InvoiceLine,
  P2pData,
} from '../shared/types';

// TODO: this file contains mixed code for different file formats.
// This should be split in different files

export class DataHelpers {
  public static getDocumentTypeCode(
    data: AlfrescoProperties
  ): DocumentTypeCode | undefined {
    return get(data.properties, Metadata.documentTypeCode);
  }

  public static getDocumentSubtypeCode(
    data: AlfrescoProperties
  ): string | undefined {
    return get(data.properties, Metadata.documentSubTypeCode);
  }

  public static getRecipientId(data: any): string {
    return get(data.properties, Metadata.recipientId);
  }

  public static setRecipientId(document: any, data: any) {
    set(document.properties, Metadata.recipientId, data);
  }

  public static getRecipientName(data: any): string {
    return get(data.properties, Metadata.recipientName);
  }

  public static setRecipientName(document: any, data: any) {
    set(document.properties, Metadata.recipientName, data);
  }

  public static getDeliveryGLN(data: P2pData): string | undefined {
    return get(data, Ubl.deliveryLocationGLN);
  }

  public static getInvoiceType(data: AlfrescoProperties): string | undefined {
    return get(data.properties, Metadata.invoiceType);
  }

  public static setInvoiceType(document: any, data: any) {
    set(document.properties, Metadata.invoiceType, data);
  }

  public static getInvoiceTypeCode(data: P2pData): string | undefined {
    return get(data, Ubl.invoiceTypeCode);
  }

  public static getProcessStatus = (
    record: AlfrescoProperties
  ): ProcessStatus => {
    return get(record?.properties, Metadata.processStatus);
  };

  public static getProcessDocumentFormatType(
    record: AlfrescoProperties
  ): string | undefined {
    return get(record?.properties, Metadata.processDocumentFormatType);
  }

  /**
   * Gets the currency id from the alfresco metadata
   */
  public static getCurrencyID = (record: AlfrescoProperties): string => {
    return get(record.properties, Metadata.currency);
  };

  public static regulatorExtraDetails = (
    record: AlfrescoProperties
  ): RegulatorExtraDetailsType | undefined => {
    return get(record.properties, Metadata.regulatorExtraDetails);
  };

  /**
   * Sets the currency id to the alfresco metadata
   */
  public static setCurrencyID = (
    record: AlfrescoProperties,
    currency: string
  ) => {
    return set(record.properties, Metadata.currency, currency);
  };

  public static getDiscountNode(
    line: InvoiceLine | OrderLineDetails
  ): AllowanceChargeItem | undefined {
    if ((line as OrderLineDetails)?.LineItem?.length > 0) {
      return;
    }
    let discountNode = first(
      (line as InvoiceLine)?.allowanceCharge?.filter(
        (ac: AllowanceChargeItem) =>
          (ac.chargeIndicator?.value === false &&
            ac.allowanceChargeReasonCode?.value ===
              Constants.DISCOUNT_REASON) || // non-draft
          (ac.chargeIndicator?.value === false &&
            ac.allowanceChargeReasonCode?.value === undefined) // non-draft before introduction of SGR
      )
    );
    if (!discountNode) {
      // draft
      discountNode = (line as InvoiceLine)?.allowanceCharge?.[0];
    }
    return discountNode;
  }

  public static getDiscountValue(
    allowanceChargeItem: AllowanceChargeItem | undefined
  ): number | undefined {
    return allowanceChargeItem?.multiplierFactorNumeric?.value;
  }

  public static getGreenTaxNode(
    line: InvoiceLine | OrderLineDetails,
    isGreenTaxOnlyTemplate: boolean = false
  ): AllowanceChargeItem | undefined {
    if ((line as OrderLineDetails)?.LineItem?.length > 0) {
      return;
    }

    let greenTaxNode = first(
      (line as InvoiceLine)?.allowanceCharge?.filter(
        (ac: AllowanceChargeItem) =>
          (ac.chargeIndicator?.value === true &&
            ac.allowanceChargeReasonCode?.value ===
              Constants.GREEN_TAX_REASON) || // non-draft
          (ac.chargeIndicator?.value === true &&
            ac.allowanceChargeReasonCode?.value === undefined) // non-draft before introduction of SGR
      )
    );

    const isDraft: boolean =
      Object.keys((line as any)?.[FieldTypes.Taxes] || {}).length > 0;
    const greenTaxData = (line as any)?.[FieldTypes.Taxes]?.find(
      (t: any) => t._ === FieldTypes.GT_Amount
    );
    if (isDraft && !isGreenTaxOnlyTemplate && greenTaxNode !== undefined) {
      // draft multi-taxes
      greenTaxNode.perUnitAmount!.value = greenTaxData?.value;
    }

    return greenTaxNode;
  }

  public static getGreenTaxValue(
    allowanceChargeItem: AllowanceChargeItem | undefined
  ): number | undefined {
    return allowanceChargeItem?.perUnitAmount?.value;
  }

  public static getSGRNode(
    line: InvoiceLine | OrderLineDetails
  ): AllowanceChargeItem | undefined {
    if ((line as OrderLineDetails)?.LineItem?.length > 0) {
      return;
    }
    let SGRNode = first(
      (line as InvoiceLine)?.allowanceCharge?.filter(
        (ac: AllowanceChargeItem) =>
          ac.allowanceChargeReasonCode?.value === Constants.SGR_REASON // non-draft
      )
    );
    if (!SGRNode) {
      // draft
      SGRNode = (line as InvoiceLine)?.allowanceCharge?.[2];
    }
    return SGRNode;
  }

  public static getSGRTaxValue(
    allowanceChargeItem: AllowanceChargeItem | undefined
  ): number | undefined {
    return allowanceChargeItem?.perUnitAmount?.value;
  }

  public static getSGRTaxAmountValue(
    allowanceChargeItem: AllowanceChargeItem | undefined
  ): number | undefined {
    return allowanceChargeItem?.amount?.value;
  }

  public static getSugarTaxNode(
    line: InvoiceLine | OrderLineDetails
  ): AllowanceChargeItem | undefined {
    if ((line as OrderLineDetails)?.LineItem?.length > 0) {
      return;
    }

    let sugarTaxNode = first(
      (line as InvoiceLine)?.allowanceCharge?.filter(
        (ac: AllowanceChargeItem) =>
          ac.chargeIndicator?.value === true &&
          ac.allowanceChargeReasonCode?.value === Constants.SUGAR_TAX_REASON // non-draft
      )
    );

    const isDraft: boolean =
      Object.keys((line as any)?.[FieldTypes.Taxes] || {}).length > 0;
    const sugarTaxData = (line as any)?.[FieldTypes.Taxes]?.find(
      (t: any) => t._ === FieldTypes.SGT_Amount
    );
    if (isDraft && sugarTaxNode !== undefined) {
      // draft multi-taxes
      sugarTaxNode.perUnitAmount!.value = sugarTaxData?.value;
    }

    return sugarTaxNode;
  }

  public static getSugarTaxValue(
    allowanceChargeItem: AllowanceChargeItem | undefined
  ): number | undefined {
    return allowanceChargeItem?.perUnitAmount?.value;
  }

  public static getServiceDescriptionTypeCode(data: any): string | undefined {
    return get(
      data,
      WaybillSources.WebWaybillUbl()
        .shipmentInformationConsignmentCarrierAssignedID
    );
  }

  /**
   * Gets the Quantity Unit Code from the UBL
   */
  public static getQuantityUnitCode = (data: any): string => {
    const documentTypeCode = DataHelpers.getDocumentTypeCode(data);
    if (documentTypeCode === DocumentTypeCode.INVOIC) {
      return get(data, Ubl.unitCodeInvoice);
    } else if (documentTypeCode === DocumentTypeCode.DESADV) {
      const despatchAdviceUbl = new DespatchAdviceSources(true);
      return get(
        data,
        despatchAdviceUbl.DespatchAdviceLineSources().deliveryQuantityUnitCode
      );
    } else {
      return get(data, Ubl.unitCodeInvoice);
    }
  };

  /**
   * Gets the payment Exchange Rate node from the UBL
   */
  public static getPaymentExchangeRate = (data: any): string => {
    return get(data, Ubl.paymentExchangeRate);
  };
  public static getPaymentExchangeRateSourceCurrencyCode = (
    data: any
  ): string => {
    return get(data, Ubl.paymentExchangeRateSourceCurrencyCode);
  };
  public static getPaymentExchangeRateTargetCurrencyCode = (
    data: any
  ): string => {
    return get(data, Ubl.paymentExchangeRateTargetCurrencyCode);
  };
  public static getPaymentExchangeRateCalculationRate = (data: any): string => {
    return get(data, Ubl.paymentExchangeRateCalculationRate);
  };

  // PEPPOL specifics
  public static setCustomizationID = (document: any, data: any) => {
    set(document, Ubl.customizationID, data);
  };

  public static getCustomizationID = (data: P2pData): string | undefined => {
    return get(data, Ubl.customizationID);
  };
  public static setProfileID = (document: any, data: any) => {
    set(document, Ubl.profileID, data);
  };

  public static getProfileID = (data: P2pData): string | undefined => {
    return get(data, Ubl.profileID);
  };
}
