import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { ThemeProvider } from '@material-ui/styles';
import {
  PreferencesContextProvider,
  usePreferences,
} from '@react-admin/ra-preferences';
import { clone } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Logout,
  Title,
  useGetIdentity,
  useLocale,
  useQuery,
  useTranslate,
} from 'react-admin';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import { DxMenu } from '..';
import { MessageType } from '../../components/notifications/types';
import { Account } from '../../configuration/types';
import { getDirection } from '../../intl';
import { muiTheme } from '../../themes/CreateTheme';
import { DxTheme } from '../../types';
import { GA_EVENTS, sendGAEvent, useGAPageViews } from '../../utils';
import AppBar from '../AppBar';
import DashboardConfigurationDrawer from '../Dashboard/DashboardConfigurationDrawer';
import Widget, { WidgetConfiguration, WidgetKey } from '../Dashboard/Widget';
import AppsSelector from './AppsSelector';
import MessageWidget from './widgets/MessageWidget';
import Widgets from './widgets/Widgets';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      minHeight: '100vh',
      position: 'relative',
      minWidth: 'fit-content',
      width: '100%',
      color: theme.palette.getContrastText(theme.palette.background.default),
      backgroundImage: "url('home-background.png')",
      backgroundSize: 'cover',
    },
    appFrame: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      [theme.breakpoints.up('xs')]: {
        marginTop: theme.spacing(6),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(7),
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexBasis: 0,
      padding: theme.spacing(3),
      paddingLeft: 0,
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 5,
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4),
      },
    },
    contentWithSide: {
      display: 'flex',
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    // Fix the configuration drawer button into the top right corner.
    configure: {
      position: 'fixed',
      // Below the toolbar.
      ...theme.mixins.toolbar,
      right: theme.spacing(0.5),
      marginTop: '1em',
    },
    // When the configuration drawer is open, shift the dashboard to
    // the left (same size as the drawer) so the drawer does not overlap
    // the dashboard.
    shiftLeft: {
      marginRight: `${LANDINGPAGE_CONF_DRAWER_WIDTH_IN_WV}vw`,
      // Diagonal grey stripes.
      background: `repeating-linear-gradient(-55deg,${theme.palette.grey[100]},${theme.palette.grey[100]} 10px,${theme.palette.grey[50]} 10px,${theme.palette.grey[50]} 20px)`,
    },
    shiftRight: {
      marginLeft: '0px',
    },
  }),
  { name: 'LandingPage' }
);

export interface LandingPageProps {}

export const ROW_HEIGHT = 100;
export const MARGIN = 10;
// The width of the configuration drawer in viewport units.
export const LANDINGPAGE_CONF_DRAWER_WIDTH_IN_WV = 31 as const;

// Use a responsive dashboard layout to redraw all the widgets when the
// viewport changes.
const ResponsiveGridLayout = WidthProvider(Responsive);

// The GraphQL analytics endpoint.
const graphqlHttpLink = createHttpLink({
  uri: '/graphql',
});

// The graphql client, passed by context to all the widgets.
const client = new ApolloClient({
  // The `from` function combines an array of individual links
  // into a link chain.
  link: from([graphqlHttpLink]),
  cache: new InMemoryCache(),
  // Enable sending cookies over cross-origin requests. Especially the SSO
  // token.
  credentials: 'include',
  defaultOptions: {
    query: { errorPolicy: 'none' },
  },
});

const isCorruptedConfiguration = (lg: any): boolean => {
  if (typeof lg.x !== 'number') {
    return true;
  }
  if (typeof lg.y !== 'number') {
    return true;
  }
  if (typeof lg.w !== 'number') {
    return true;
  }
  if (typeof lg.h !== 'number') {
    return true;
  }
  if (typeof lg.i !== 'string') {
    return true;
  }
  return false;
};

const LandingPage = (props: LandingPageProps) => {
  const classes = useStyles();
  const locale = useLocale();
  const direction = getDirection(locale);

  useGAPageViews();

  return (
    <>
      <PreferencesContextProvider synchronize>
        <div className={classes.root} {...props} dir={direction}>
          <div className={classes.appFrame}>
            <ThemeProvider theme={createMuiTheme(muiTheme)}>
              <AppBar
                noLayout={true}
                logout={<Logout button={true} />}
                userMenu={<DxMenu logout={<Logout button={true} />} />}
                style={{
                  backgroundImage: "url('home-background.png')",
                }}
              />
              <main className={classes.contentWithSide}>
                <HomePage />
              </main>
            </ThemeProvider>
          </div>
        </div>
      </PreferencesContextProvider>
    </>
  );
};

type WidgetInstance = string;

/**
 * Extracts the widget key out from its instance.
 */
const toWidgetKey = (instance: WidgetInstance): WidgetKey =>
  instance.substring(instance.lastIndexOf('@') + 1);

const createWidgetInstance = (key: WidgetKey): WidgetInstance =>
  `${new Date().getTime()}@${key}`;

// Restricts to the widgets the user can access to.
const restrict = (
  account: Account,
  widgets: Record<WidgetKey, WidgetConfiguration>
) => {
  return Object.keys(widgets).reduce((acc, key) => {
    if (widgets[key].isAllowedFor(account)) acc[key] = widgets[key];
    return acc;
  }, {} as Record<WidgetKey, WidgetConfiguration>);
};

const LastLogin = ({ accountId }) => {
  const translate = useTranslate();
  // Be careful: useGetOne() breaks the browser on logout.
  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'configuration-accounts',
    payload: { id: `${accountId}/lastLoginTime` },
  });

  if (error) return null;
  if (loading) return null;
  if (!data.lastLoginTime) return null;

  return (
    <Typography
      variant='caption'
      style={{ display: 'flex', justifyContent: 'flex-end' }}
    >
      {translate('dxMessages.dashboard.widgets.WelcomeBanner.LastLogin')}:{' '}
      {moment(data.lastLoginTime).format('lll')}
    </Typography>
  );
};

interface HomePageProps {}
const HomePage = (props: HomePageProps) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { identity, loading } = useGetIdentity();
  // 'viewVersion' gets incremented on each refresh (the react-admin one, with the button in the App bar).
  const viewVersion = useSelector((state: any) => state.admin.ui.viewVersion);
  // @ts-ignore
  const account: Account = identity;

  const DEFAULT_LAYOUT: any = {
    lg: [],
  };

  // The widgets to put on screen and their layouting, both retrieved
  // from the user preferences (if any) otherwise the default ones.
  let [layouts, setLayouts] = usePreferences(
    'dxportal.landing-page.grid-layout',
    DEFAULT_LAYOUT
  );

  const [widgetsConfig, setWidgetsConfig] = usePreferences(
    'dxportal.landing-page.cfg',
    {}
  );

  // Removes corrupted widget layout configurations
  const _setLayouts = (layouts) => {
    setLayouts({
      lg: [...layouts.lg.filter((_) => !isCorruptedConfiguration(_))],
    });
  };

  // Toggle the dashboard configuration drawer on/off.
  const [openConfiguration, setOpenConfiguration] = useState(false);

  // When user hits the soft refresh button, clear the Apollo client cache
  // so analytic queries gets re-run.
  useEffect(
    () => {
      client.resetStore();
    }, // The effect depends on the view version so get called on each refresh.
    [viewVersion]
  );

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
  }, []);

  const toggleConfiguration =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      )
        return;
      setOpenConfiguration(open);
      if (open) {
        sendGAEvent(
          GA_EVENTS.categories.DASHBOARD.name,
          GA_EVENTS.categories.DASHBOARD.actions.CONFIGURE_HOMEPAGE,
          account?.company?.cmsRootDir
        );
      }

      // Toggling the configuration drawer changes the dashboard size so
      // send a browser resize event (react-grid-layout listens to it)
      // to trigger the re-computing of the layout.
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1);
    };

  // Remove from the layout the widgets the user is not allowed to access to
  // (may happen when the user rights changed: the layout in the user
  // preferences doesn't reflect this change, so may contain a widget
  // the user has no more access to).
  layouts = {
    lg:
      layouts.lg.length !== 0
        ? layouts.lg
            .filter(
              (_) =>
                _.i === '__dropping-elem__' ||
                Widgets[toWidgetKey(_.i)]?.isAllowedFor(identity as Account)
            )
            .filter((_) => !isCorruptedConfiguration(_))
        : [],
  };

  // Redraw the dashboard when a widget gets closed by the user.
  const onClose = (instance: string) => {
    _setLayouts({
      lg: layouts.lg.filter((_) => _.i !== instance),
    });

    const newConfig = clone(widgetsConfig);
    delete newConfig[instance];
    setWidgetsConfig(newConfig);
  };

  // When moving a widget to add over the dashboard, display the drop zone.
  const onLayoutChange = (layout, layouts) => {
    // See react-grid-layout for a description of '__dropping-elem__'.
    if (!layout.some((li) => li.i === '__dropping-elem__')) {
      if (layouts.lg !== undefined) {
        if (layouts.lg.length !== 0) {
          layouts.lg.forEach((l: any) => {
            if (l.minH === undefined) {
              l.minH = l.h;
            }
            if (l.maxH === undefined) {
              l.maxH = l.h;
            }
            if (l.i.toLowerCase().includes('savedqueries')) {
              l.minW = 2;
            }
          });
        }
      }
      _setLayouts(layouts);
    }
  };

  // Redraw the dashboard when a widget gets added on screen by the user.
  // The key of the widget is in the data transfer info.
  const onDrop = (layout, layoutItem, ev) => {
    const widgetKey = ev.dataTransfer.getData('text/plain');
    _setLayouts({
      lg: [
        ...layout.filter((li) => li.i !== '__dropping-elem__'),
        {
          ...layoutItem,
          i: createWidgetInstance(widgetKey),
        },
      ],
    });
  };

  // Passed to the widgets so they can resize themselves.
  const updateSize = (instance: WidgetInstance) => (w: number, h: number) =>
    _setLayouts({
      lg: layouts.lg.map((widget) => {
        if (widget.i === instance) return { ...widget, w, h };
        return widget;
      }),
    });

  // Passed to the widgets (at least usefull for the WelcomeBanner one)
  // in order to let them able to set the dashboard to the default layout
  const resetDashboardToDefault = () => {
    setLayouts(DEFAULT_LAYOUT);
    setWidgetsConfig({});
  };

  // The widgets to list in the configuartion drawer, that is:
  // (1) the ones which are not singleton.
  // (2) the ones which are singleton but not on screen yet.
  // For both, the ones the user has the permissions on.
  const droppableWidgets = Object.keys(restrict(identity as Account, Widgets))
    .filter(
      (key) =>
        !Widgets[key].singleton ||
        !layouts.lg.some((_) => key === toWidgetKey(_.i))
    )
    .reduce((acc, key) => {
      acc[key] = Widgets[key];
      return acc;
    }, {} as Record<WidgetKey, WidgetConfiguration>);

  // Compute the height of the view in pixels.
  // cf https://github.com/react-grid-layout/react-grid-layout#grid-layout-props
  // Grid Item Heights and Widths
  // Grid item widths are based on container and number of columns. The size of a grid unit's height is based on rowHeight.
  // Note that an item that has h=2 is not exactly twice as tall as one with h=1 unless you have no margin!
  // In order for the grid to not be ragged, when an item spans grid units, it must also span margins.
  // So you must add the height or width or the margin you are spanning for each unit.
  // So actual pixel height is (rowHeight * h) + (margin * 2 * (h - 1).
  // For example, with rowHeight=30, margin=[10,10] and a unit with height 4, the calculation is (30 * 4) + (10 * 3)
  const height: number =
    layouts.lg.length !== 0
      ? layouts.lg
          .map((l) => l.h)
          .reduce((acc, h) => {
            const value = ROW_HEIGHT * h + MARGIN * 2 * (h - 1);
            return acc + value;
          }, 0)
      : 100;

  if (loading) return null;

  return (
    <>
      <AppsSelector accountId={identity?.id} />
      <div id='main-content' className={classes.content}>
        <LastLogin accountId={identity?.id} />
        <div>
          {/* The button to toggle the configuration. */}
          <div className={classes.configure}>
            <DashboardConfigurationDrawer
              open={openConfiguration}
              toggle={toggleConfiguration}
              droppableWidgets={droppableWidgets}
              drawerWidth={LANDINGPAGE_CONF_DRAWER_WIDTH_IN_WV}
              Widgets={Widgets}
            />
          </div>
          <div
            className={
              openConfiguration ? classes.shiftLeft : classes.shiftRight
            }
          >
            {/* Make teh graphql client accessible to any widget. */}
            <ApolloProvider client={client}>
              <Box m={1}>
                <Title
                  title={translate('dxMessages.dashboard.Welcome', {
                    firstname: '',
                  })}
                />
                <MessageWidget
                  messageIssuer='@DocProcessOperations'
                  messageIssuerTitle='DocProcess Operations'
                  messageType={MessageType.GENERAL}
                  onTheShelves={false}
                  openConfiguration={false}
                  widgetName='PspMessageWidget'
                />
                <MessageWidget
                  messageIssuer='@DocProcessNews'
                  messageIssuerTitle='DocProcess News'
                  messageType={MessageType.MARKETING}
                  onTheShelves={false}
                  openConfiguration={false}
                  widgetName='MktMessageWidget'
                />
                <ResponsiveGridLayout
                  // To keep the dropzone covering at least the browser viewport,
                  // put the min Height.
                  style={{
                    minWidth: '100%',
                    minHeight: height,
                    maxHeight: height,
                    height: height,
                  }}
                  className='layout'
                  containerPadding={[MARGIN, MARGIN]}
                  layouts={layouts}
                  breakpoints={{ lg: 1200 }}
                  cols={{ lg: 12 }}
                  rowHeight={ROW_HEIGHT}
                  isResizable={true}
                  // When the drawer is open, there is a 'close' icon in the top-right corner
                  // so put the resize handle on the bottom-right corner.
                  resizeHandles={openConfiguration ? ['se'] : ['ne']}
                  isDroppable={true}
                  onLayoutChange={onLayoutChange}
                  onDrop={onDrop}
                  // Returns the size of the widget to add when moving it over the dashboard.
                  onDropDragOver={(e: any) => {
                    const size = e.dataTransfer.types.find(
                      (_) => _ !== 'text/plain'
                    );
                    return JSON.parse(size);
                  }}
                  draggableCancel='.outsideDashboardGrip'
                >
                  {layouts.lg
                    .filter(
                      (_) =>
                        _.i !== '__dropping-elem__' &&
                        !!Widgets[toWidgetKey(_.i)]
                    )
                    .map((_) => {
                      const cfg = Widgets[toWidgetKey(_.i)];
                      return (
                        <div key={_.i}>
                          <Widget
                            onTheShelves={false}
                            close={
                              openConfiguration && cfg.closeable
                                ? () => onClose(_.i)
                                : undefined
                            }
                            singleton={cfg.singleton}
                          >
                            {React.createElement(cfg.content, {
                              onTheShelves: false,
                              account: identity as Account,
                              userPreferencesRootKey: `dxportal.landing-page.cfg.${_.i}`,
                              updateSize: updateSize(_.i),
                              resetDashboardToDefault: resetDashboardToDefault,
                              openConfiguration: openConfiguration,
                            })}
                          </Widget>
                        </div>
                      );
                    })}
                </ResponsiveGridLayout>
                {layouts.lg.length === 0 && (
                  // Empty widget zone message
                  <Box
                    style={{
                      minHeight: '50vh',
                      maxHeight: '50vh',
                      height: '50vh',
                      margin: MARGIN,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {openConfiguration === false ? (
                      <Typography variant='h3' style={{ opacity: 0.2 }}>
                        {translate('dxMessages.landingPage.empty.click', {
                          _: 'Click',
                        })}{' '}
                        <SettingsIcon />{' '}
                        {translate('dxMessages.landingPage.empty.drop', {
                          _: 'and drop your widgets',
                        })}
                      </Typography>
                    ) : (
                      <Typography variant='h3' style={{ opacity: 0.2 }}>
                        {translate(
                          'dxMessages.landingPage.empty.openedConfigurationDrop',
                          {
                            _: 'Drop your widgets',
                          }
                        )}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </ApolloProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
