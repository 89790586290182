import * as React from 'react';
import {
  Edit,
  maxLength,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';

export const DxRoleEdit = (props: any) => {
  const nameValidate = [required(), maxLength(50)];

  const notify = useNotify();

  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.updated');
    redirect('list', props.basePath);
  };

  return (
    <Edit {...props} onSuccess={onSuccess} undoable={false}>
      <SimpleForm>
        <TextInput
          label={'dxMessages.companyManagement.dxrole.name'}
          source='rolle'
          validate={nameValidate}
        />
        <SelectInput
          label={'dxMessages.companyManagement.dxrole.adminOnly'}
          source='adminOnly'
          choices={[
            { id: true, name: 'TRUE' },
            { id: false, name: 'FALSE' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default DxRoleEdit;
