import React, { useEffect, useState } from 'react';
import makeComponentTrashable from 'trashable-react';
import { AnnouncementsProvider } from './AnnouncementsContext';

const typePriority = {
  marketing: 0,
  downtime: 1,
  error: 2,
};

const AnnouncementsFetcher = (props) => {
  const { children } = props;

  const [announcements, setAnnouncements] = useState<any[]>([]);
  const [suppress, setSuppress] = useState(false);

  useEffect(() => {
    const request = new Request(`/announcements`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      }),
      credentials: 'include',
    });

    fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.error('Could not fetch announcements:', response);
        }
      } else {
        response
          .json()
          .then(handleReceiveAnnouncements)
          .catch((ex) => {
            if (process.env.NODE_ENV !== 'production') {
              // eslint-disable-next-line no-console
              console.error('Invalid announcements content retrieved : %o', ex);
            }
          });
      }
    });

    return () => {
      clearTimeout();
    };
  }, []);

  function getMostImportantAnnouncement() {
    return suppress
      ? null
      : announcements.reduce((acc, announce) => {
          if (!acc) {
            return announce;
          }
          const accTypePrio = typePriority[acc.type] || 0;
          const currentTypePrio = typePriority[announce.type] || 0;
          if (accTypePrio === currentTypePrio) {
            return (acc.priority || 0) > (announce.priority || 0)
              ? acc
              : announce;
          }
          return accTypePrio > currentTypePrio ? acc : announce;
        }, null);
  }

  const handleReceiveAnnouncements = (announcements) => {
    setAnnouncements(
      announcements.filter((announce) => {
        try {
          return !localStorage.getItem(`announcementDismissed-${announce.id}`);
        } catch (ex) {
          /* ignore */
        }
        return true;
      })
    );
  };

  const handleDismissAnnouncement = (announcementId) => {
    if (!announcementId) {
      return;
    }

    try {
      localStorage.setItem(`announcementDismissed-${announcementId}`, 'true');
    } catch (ex) {
      /* ignore */
    }

    setAnnouncements(
      announcements.filter((announce) => announce.id !== announcementId)
    );
    setSuppress(true);

    clearTimeout();

    setTimeout(() => {
      setSuppress(false);
    }, 500);
  };

  const announcement = getMostImportantAnnouncement();

  return (
    <AnnouncementsProvider
      value={{
        announcement,
        onDismissAnnouncement: () => handleDismissAnnouncement(announcement.id),
      }}
    >
      {children}
    </AnnouncementsProvider>
  );
};

export default makeComponentTrashable(AnnouncementsFetcher);
