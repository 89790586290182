import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HelpIcon from '@material-ui/icons/HelpOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import SettingsIcon from '@material-ui/icons/Settings';
import classNames from 'classnames';
import { useState } from 'react';
import {
  MenuItemLink,
  ReduxState,
  UserMenu,
  useDataProvider,
  useGetIdentity,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { DxTheme, GA_EVENTS, LocaleSwitcher, sendGAEvent } from '..';
import { isPspAdministrator } from '../configuration';
import { Constants } from '../constants';
import { colors } from '../constants/Colors';
import { UserProfileRoutePath } from '../login';
import { AccountSwitcher } from '../security';
import PersonCompanySwitcher from '../security/PersonCompanySwitcher';

const customIconButton = {
  width: 'auto',
  height: 'auto',
  padding: 12,
  borderRadius: 24,
};

const useUserIconStyles = makeStyles(
  (theme: Theme) => ({
    icon: {
      display: 'flex',
    },
  }),
  { name: 'UserIcon' }
);

const UserIcon = (props) => {
  const classes = useUserIconStyles();
  let username: string = 'anonymous';

  if (typeof localStorage !== 'undefined') {
    username = localStorage.getItem('user') ?? username;
  }

  return (
    <div className={classes.icon}>
      <Typography>{username}</Typography>
      <ArrowDropDownIcon />
    </div>
  );
};

const MyUserMenu = (props) => {
  const classes = useDxMenuStyles();
  return (
    <UserMenu
      {...props}
      icon={<UserIcon />}
      classes={{ avatar: classes.avatar }}
    />
  );
};

const useDxMenuStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& .myUserMenuWrapper button': customIconButton,
    },
    email: {
      fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
      fontSize: 12, // 0.75em
      fontWeight: 400,
      letterSpacing: '0.05em',
      color: colors.black50,
    },
    helpDropdown: {
      marginRight: '0.05em',
      color: 'inherit',
    },
    menuItemLink: {
      textDecoration: 'none',
      color: colors.black80,
      minWidth: '100%',
    },
    menuItemText: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    dxMenuIconButton: customIconButton,
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  }),
  { name: 'DxMenu' }
);

const DxMenu = (props) => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState<any | undefined>(undefined);
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const multiAccounts = identity?.person.multiAccounts || false;
  const getZendeskJWT = async () => {
    const loadData = async () => {
      try {
        const response = await dataProvider[Constants.RESOURCE_ZENDESK](
          Constants.RESOURCE_ZENDESK,
          {}
        );

        if (response && response.data) {
          setData(response?.data);
        }
      } catch {
        // TODO: handle it as an error, not a 'no trail'.
        setData(undefined);
      }
    };

    loadData();
  };

  const { logout, ...rest } = props;
  const translate = useTranslate();
  const classes = useDxMenuStyles();
  // @ts-ignore
  const account: Account = identity;

  const [openHelpMenu, setOpenHelpMenu] = useState(false);
  const [anchorElHelpMenu, setAnchorElHelpMenu] = useState(null);

  const handleHelpMenuClick = (evt) => {
    evt.preventDefault();
    getZendeskJWT();
    setOpenHelpMenu(!openHelpMenu);
    setAnchorElHelpMenu(evt.currentTarget);
  };

  const handleCloseHelpMenu = () => {
    setOpenHelpMenu(false);
  };

  const handleClickUserGuideMenu = () => {
    sendGAEvent(
      GA_EVENTS.categories.HELP_MENU.name,
      GA_EVENTS.categories.HELP_MENU.actions.OPEN_USERGUIDE,
      account?.company?.cmsRootDir
    );
    setOpenHelpMenu(false);
  };

  const open = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );

  // Get the version (2 or 3) of the user to know which user profile view to display.
  let userVersion = '2';
  if (typeof localStorage !== 'undefined') {
    try {
      const username = localStorage.getItem('user');
      if (!!username)
        userVersion =
          localStorage.getItem(`${username}.${Constants.USER_VERSION}`) || '2';
    } catch {}
  }

  return (
    <div className={classes.root}>
      <div>
        <Tooltip
          title={translate('dxMessages.help_menu.title', {
            _: 'Help',
          })}
        >
          <IconButton
            className={classNames(
              classes.helpDropdown,
              classes.dxMenuIconButton
            )}
            onClick={handleHelpMenuClick}
          >
            <HelpIcon />
            <ArrowDropDownIcon />
          </IconButton>
        </Tooltip>
        <Menu
          open={openHelpMenu}
          anchorEl={anchorElHelpMenu}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          getContentAnchorEl={null}
          onClose={handleCloseHelpMenu}
        >
          <MenuItem
            component='a'
            href={`https://docprocess.zendesk.com/access/jwt?jwt=${data}`}
            target='_blank'
            rel='noopener noreferrer'
            className={classes.menuItemLink}
            onClick={handleClickUserGuideMenu}
          >
            <div className={classes.menuItemText}>
              <ListItemIcon>
                <LaunchIcon />
              </ListItemIcon>
              <ListItemText>
                {translate('dxMessages.help_menu.userGuide', {
                  _: 'User Guide',
                })}
              </ListItemText>
            </div>
          </MenuItem>
        </Menu>
      </div>
      <LocaleSwitcher />
      <div className='myUserMenuWrapper'>
        <MyUserMenu {...rest}>
          <MenuItemLink
            to={userVersion === '3' ? '/profile' : UserProfileRoutePath}
            primaryText={translate('dxMessages.user_menu.account_preferences')}
            leftIcon={<SettingsIcon />}
            sidebarIsOpen={open}
          />
          {isPspAdministrator(permissions) && (
            <AccountSwitcher identity={identity} />
          )}
          {multiAccounts && <PersonCompanySwitcher identity={identity} />}
          <Divider />
          <div>{logout}</div>
        </MyUserMenu>
      </div>
    </div>
  );
};

export default DxMenu;
