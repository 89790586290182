import { GA_EVENTS as COMMON_GA_EVENTS } from '@dx-ui/dx-common';
import { merge } from 'lodash';

// https://www.optimizesmart.com/event-tracking-guide-google-analytics-simplified-version/#a3a

export const GA_EVENTS = merge(COMMON_GA_EVENTS, {
  categories: {
    DOCUMENT_CONVERT: {
      name: 'convert',
      actions: {
        ORDER_TO_INVOICE: 'order to invoice',
        ORDER_TO_DESADV: 'order to desadv',
        DESADV_TO_RECADV: 'despatch advice to receipt advice',
      },
    },
    FORM: {
      name: 'edit form',
      actions: {
        EDIT: 'edit',
        CLONE: 'clone',
        CANCEL: 'cancel',
        CORRECT: 'corrective invoice',
        ADD_LINE: 'add invoice line',
        REMOVE_LINE: 'remove invoice line',
        SAVE: 'save form',
        PREVIEW: 'preview form',
        SEND: 'send form',
        TOTAL_LINES: 'total lines',
      },
    },
  },
});
