import { colors, PriceTextField } from '@dx-ui/dx-common';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { get } from 'lodash';
import React from 'react';
import { FunctionField, TextField } from 'react-admin';

const generalTextField = (record, source) => (
  <TextField
    style={{
      minWidth: '8em',
      color: record.extension.matched ? colors.black : colors.brightOrange,
    }}
    source={source}
    record={record}
  />
);

const generalPriceTextField = (record, source, locale) => (
  <PriceTextField
    style={{
      textAlign: 'right',
      color: record.extension.matched ? colors.black : colors.brightOrange,
    }}
    source={source}
    record={record}
    sortable={false}
  />
);

export const renderColumns = (classes, locale) => {
  return [
    <FunctionField
      label='dxMessages.headers.number'
      sortable={false}
      key='number'
      render={(record) => {
        return (
          <TextField
            style={{
              color: record.extension.matched
                ? colors.black
                : colors.brightOrange,
            }}
            source='ID.value'
            record={record}
          />
        );
      }}
    />,
    <FunctionField
      label='dxMessages.headers.codeClient'
      sortable={false}
      key='codeCLient'
      render={(record) => {
        return generalTextField(
          record,
          'Item.BuyersItemIdentification.ID.value'
        );
      }}
    />,
    <FunctionField
      label='dxMessages.headers.codeSupplier'
      sortable={false}
      key='codeSupplier'
      render={(record) => {
        return generalTextField(
          record,
          'Item.SellersItemIdentification.ID.value'
        );
      }}
    />,
    <FunctionField
      label='dxMessages.headers.codeStandard'
      sortable={false}
      key='codeStandard'
      render={(record) => {
        return generalTextField(
          record,
          'Item.StandardItemIdentification.ID.value'
        );
      }}
    />,
    <FunctionField
      label='dxMessages.headers.description'
      sortable={false}
      key='product'
      render={(record) => {
        return (
          <TextField
            style={{
              minWidth: '8em',
              color: record.extension.matched
                ? colors.black
                : colors.brightOrange,
            }}
            source='Item.Description[0].value'
            record={record}
          />
        );
      }}
    />,
    <FunctionField
      source='InvoicedQuantity.value'
      label='dxMessages.headers.quantity'
      sortable={false}
      key='quantity'
      render={(record) => {
        return generalTextField(record, 'InvoicedQuantity.value');
      }}
    />,
    <FunctionField
      source='InvoicedQuantity.unitCode'
      label='dxMessages.headers.um'
      sortable={false}
      key='um'
      render={(record) => {
        return generalTextField(record, 'InvoicedQuantity.unitCode');
      }}
    />,
    <FunctionField
      source='Price.PriceAmount.value'
      label='dxMessages.headers.price'
      locale={locale}
      sortable={false}
      key='price'
      render={(record) => {
        return generalPriceTextField(record, 'Price.PriceAmount.value', locale);
      }}
    />,
    <FunctionField
      source='AllowanceCharge[0].MultiplierFactorNumeric.value'
      label='dxMessages.headers.discount'
      key='discount'
      sortable={false}
      render={(record) => {
        const discount = (record?.AllowanceCharge || []).find(
          (ac) =>
            (ac.AllowanceChargeReasonCode &&
              ac.AllowanceChargeReasonCode?.value === 'false') ||
            (ac.AllowanceChargeReasonCode &&
              ac.AllowanceChargeReasonCode?.value === false) ||
            ac.ChargeIndicator?.value === false ||
            !!ac.multiplierFactorNumeric?.value
        )?.MultiplierFactorNumeric?.value;

        return (
          <Typography
            style={{
              color: record.extension.matched
                ? colors.black
                : colors.brightOrange,
              textAlign: 'right',
            }}
          >
            {discount || '0'} %
          </Typography>
        );
      }}
    />,
    <FunctionField
      source='AllowanceCharge[1].PerUnitAmount.value'
      label='dxMessages.headers.greenTax'
      locale={locale}
      sortable={false}
      key='greenTax'
      render={(record) => {
        const greenTax = (record.AllowanceCharge || []).find(
          (ac) =>
            !(
              (ac?.AllowanceChargeReasonCode &&
                ac.AllowanceChargeReasonCode?.value === 'false') ||
              (ac?.AllowanceChargeReasonCode &&
                ac.AllowanceChargeReasonCode?.value === false) ||
              ac.ChargeIndicator?.value === false ||
              !!ac.multiplierFactorNumeric?.value
            )
        )?.PerUnitAmount?.value;

        return (
          <Typography
            style={{
              color: record.extension.matched
                ? colors.black
                : colors.brightOrange,
              textAlign: 'right',
            }}
          >
            {greenTax
              ? Intl.NumberFormat(locale, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(greenTax)
              : '--'}
          </Typography>
        );
      }}
    />,
    <FunctionField
      source='TaxTotal[0].TaxSubtotal[0].Percent.value'
      label='dxMessages.headers.vat'
      key='vat'
      sortable={false}
      render={(record) => {
        const vatPercentage = get(
          record,
          'TaxTotal[0].TaxSubtotal[0].Percent.value'
        );
        return isNaN(vatPercentage) ? (
          <Typography
            style={{
              color: record.extension.matched
                ? colors.black
                : colors.brightOrange,
            }}
          >
            {'--'}
          </Typography>
        ) : (
          <Typography
            style={{
              color: record.extension.matched
                ? colors.black
                : colors.brightOrange,
            }}
          >
            {vatPercentage} {'%'}
          </Typography>
        );
      }}
      style={{ minWidth: '3em' }}
    />,
    <FunctionField
      source='LineExtensionAmount.value'
      locale={locale}
      label='dxMessages.headers.totalWithoutVat'
      sortable={false}
      key='totalWithoutVat'
      render={(record) => {
        return generalPriceTextField(
          record,
          'LineExtensionAmount.value',
          locale
        );
      }}
    />,
    <FunctionField
      source='TaxTotal[0].TaxSubtotal[0].TaxAmount.value'
      label='dxMessages.headers.totalVat'
      locale={locale}
      sortable={false}
      key='totalVat'
      render={(record) => {
        return generalPriceTextField(
          record,
          'TaxTotal[0].TaxSubtotal[0].TaxAmount.value',
          locale
        );
      }}
    />,
    <FunctionField
      label='Match'
      locale={locale}
      sortable={false}
      key='matchedIcon'
      render={(record) => {
        if (record.extension.matched) {
          return (
            <CheckIcon
              style={{
                color: colors.functionalGreen,
                verticalAlign: 'bottom',
              }}
            />
          );
        } else {
          return (
            <ClearIcon
              style={{
                color: colors.brightOrange,
                verticalAlign: 'bottom',
              }}
            />
          );
        }
      }}
    />,
  ];
};
