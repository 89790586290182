import { DxTheme, useGAPageViews } from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';
import { Edit } from 'react-admin';
import { DynamicForm } from '../../shared';

const useStyles: any = makeStyles(
  (theme: DxTheme) => ({
    root: {
      '& > div': {
        boxShadow: 'unset',
      },
      height: '100%',
    },
    main: {
      height: '100%',
    },
  }),
  { name: 'DocumentEdit' }
);

const DocumentEdit = ({ previewComponent, mode, ...props }) => {
  const classes = useStyles();
  const { location } = props;

  useGAPageViews();
  const redirectMode = location?.state?.redirectMode;

  return (
    <Edit
      classes={{ root: classes.root, main: classes.main }}
      actions={false}
      {...props}
      undoable={false}
    >
      <DynamicForm
        previewComponent={previewComponent}
        mode={redirectMode ? redirectMode : mode}
      />
    </Edit>
  );
};

export default DocumentEdit;
