import { Record as RaRecord } from 'react-admin';

export interface Identity {
  id: string;
  provider: {
    id: string;
  };
}

export type IdentityProviderType = 'BUILT_IN' | 'OPEN_ID_CONNECT';
export interface IdentityProvider {
  type: IdentityProviderType;
  id: string;
}

export interface Role {
  id: string;
}
export interface Feature {
  id: string;
  availableRoles: Array<Role>;
}
export interface Subscription {
  id: string;
  feature: Feature;
  excludedRoleIds: Array<string>;
  active: boolean;
}

export interface Delegation {}
export interface Person extends RaRecord {
  email: string;
  firstname: string;
  lastname: string;
  language: 'en' | 'ro' | 'fr';
  phone?: string;
  identities: Array<Identity>;
}
export interface Company extends RaRecord {
  name: string;
  fiscalCode: string;
  cmsRootDir: string;
  allowedIdentityProviders: Array<IdentityProvider>;
  subscriptions: Array<Subscription>;
  delegations: Array<Delegation>;
}
export interface JobAttribute {
  key: string;
  value?: string;
}

export interface Job {
  attributes: Array<JobAttribute>;
}
export interface UsageRight {
  feature: { id: string };
  roles: Array<{ id: string }>;
}
export interface Account extends RaRecord {
  created: string; // 2021-05-24T14:48:02.732358Z
  enabled: boolean;
  locked: boolean;
  expired: boolean;
  person: Person;
  company: Company;
  job?: Job;
  configuredUsageRights: UsageRight[];
  delegatedUsageRights: UsageRight[];
  onTheFlyUsageRights: UsageRight[];
  hasDocProcessIdentity?: boolean;
}

export enum RegulatorStatus {
  pending_validation = 'PENDING_VALIDATION',
  under_validation = 'UNDER_VALIDATION',
  validated = 'VALIDATED',
  rejected = 'REJECTED',
}
export interface Regulator extends RaRecord {
  clientId: string;
  clientSecret: string;
  created: number;
  email: string;
  status: RegulatorStatus;
  id: string;
}

export interface AccountCreationFormValues {
  company?: {
    id: string; // The ID of the company the account belongs to.
  };
  personId?: string; // The ID of the person the account belongs to.
  person?: {
    email: string;
    firstname: string;
    lastname: string;
    language: string;
    identities: Array<{ provider: { id: string }; id: string }>;
  };
  usageRights?: Array<{
    feature: { id: string; roles?: Array<{ id: string }> };
  }>;
  job?: Job;
  termsAcceptance?: boolean;
}

export interface CompanyUpdateValues {
  id: string;
  delegations?: Array<{
    representee?: { id: string };
    delegee?: { id: string };
    from?: string;
    to?: string;
  }>;
}

export interface ResetPassword {
  email: string;
  application: string;
}

export interface AccountLifeCycleEvent extends RaRecord {
  type: 'UPDATED' | 'CREATION' | 'DELETATION';
  when: string; // 2021-06-03T09:27:30.439632Z
  account: Account;
  by: Account;
}
export interface UpdatedAccountLifeCycleEvent extends AccountLifeCycleEvent {
  type: 'UPDATED';
  updated: Account;
}
