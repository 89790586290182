import { DxTheme, EmptyValue, GenericSimpleField } from '@dx-ui/dx-common';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { useTranslate } from 'react-admin';
import { WaybillService } from '../../../services/WaybillService';
import { IWayBillSources } from '../WayBillSources';
import WayBillTransportCostsFragment from './WayBillTransportCostsFragment';
import WayBillTransportShipmentStageFragment from './WayBillTransportShipmentStageFragment';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    noBorder: {
      borderBottom: 'none',
    },
    portLocation: {
      backgroundColor: theme.palette.grey[100],
      padding: '1em',
    },
    section: {
      padding: '0 0 1em 0',
    },
    shipmentNumber: {
      height: '55px',
      textAlign: 'center' as 'center',
    },
    shipmentDataContainer: {
      paddingLeft: '1em',
    },
    remarks: {
      marginRight: '1em',
      marginLeft: 2,
    },
  }),
  { name: 'WayBillTransport' }
);
interface PropsModel {
  record: any;
  dataSources: IWayBillSources;
}
const WayBillTransport = (props: PropsModel): JSX.Element => {
  const { record, dataSources } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const shipmentStage = get(
    record,
    dataSources.ShipmentInformationSources.shipmentStage.source
  );
  const key = get(
    shipmentStage,
    dataSources.ShipmentStageSources.loadingNumber
  );

  const consignment = get(
    record,
    dataSources.ShipmentInformationSources.consignment.source
  );
  const remarks = get(consignment, dataSources.ConsignmentSources.remarks);

  const serviceDescriptionCode = get(
    consignment,
    dataSources.ConsignmentSources.carrierAssignedID
  );
  const serviceDescriptionText =
    WaybillService.getWaybillServiceDescriptionText(serviceDescriptionCode);

  return (
    <Grid container direction='column'>
      <Grid item>
        <Grid container direction='row' key={key}>
          <Grid item xs={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate('dxMessages.waybill.loadingTable.loadingNumber')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.noBorder} align='center'>
                    <GenericSimpleField
                      record={{ properties: { value: key } }}
                      id='value'
                      className='loadingNumber'
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={10} className={classes.shipmentDataContainer}>
            <Grid container>
              <Grid item xs={12} className={classes.section}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding='none'>
                        {translate(
                          'dxMessages.waybill.loadingTable.licensePlate'
                        )}
                      </TableCell>
                      <TableCell padding='none'>
                        {translate(
                          'dxMessages.waybill.loadingTable.packageNumber'
                        )}
                      </TableCell>
                      <TableCell padding='none'>
                        {translate(
                          'dxMessages.waybill.loadingTable.serviceDescription'
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell padding='none'>
                        <GenericSimpleField
                          record={{
                            properties: {
                              value: get(
                                record,
                                dataSources.ShipmentInformationSources
                                  .licensePlate
                              ),
                            },
                          }}
                          id='value'
                          className='licensePlate'
                        />
                      </TableCell>
                      <TableCell padding='none'>
                        <GenericSimpleField
                          record={{
                            properties: {
                              value: get(
                                consignment,
                                dataSources.ConsignmentSources.packagesNumber
                              ),
                            },
                          }}
                          id='value'
                          className='packageNumber'
                        />
                      </TableCell>
                      <TableCell padding='none'>
                        <GenericSimpleField
                          record={{
                            properties: {
                              value: translate(serviceDescriptionText),
                            },
                          }}
                          id='value'
                          className='transportType'
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

              <Grid item xs={12} className={classes.section}>
                <div style={{ display: 'inline-flex', alignItems: 'baseline' }}>
                  <Typography variant='caption'>
                    {translate('dxMessages.waybill.loadingTable.remarks')}
                  </Typography>
                  <Typography variant='caption' className={classes.remarks}>
                    :
                  </Typography>
                  <div className='remarks'>
                    {remarks ? <Typography>{remarks}</Typography> : EmptyValue}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <WayBillTransportShipmentStageFragment
                  record={shipmentStage}
                  dataSources={dataSources}
                />
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <WayBillTransportCostsFragment
                  record={consignment}
                  dataSources={dataSources}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WayBillTransport;
