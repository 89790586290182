import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import IconCancel from '@material-ui/icons/Cancel';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { linkToRecord, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { DxTheme } from '../../types';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    button: {
      position: 'relative',
      backgroundColor: theme.palette.secondary.light,
      color: theme.colors.mainColor4,
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    icon: {
      fontSize: 18,
    },
  }),
  { name: 'CancelButton' }
);

const sanitizeRestProps = ({
  basePath,
  className,
  classes,
  saving,
  label = 'ra.action.cancel',
  invalid,
  variant,
  translate,
  handleSubmit,
  handleSubmitWithRedirect,
  submitOnEnter,
  redirect,
  locale,
  showNotification,
  undoable,
  onSave,
  mutationMode,
  ...rest
}: any) => rest;

const CancelButton = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  const {
    className,
    invalid,
    label = 'ra.action.cancel',
    pristine,
    saving,
    submitOnEnter,
    variant = 'contained',
    basePath,
    record,
    resource,
    target = 'show',
    to,
    icon = <IconCancel />,
    ...rest
  } = props;

  return (
    <Button
      className={classnames(classes.button, className)}
      variant={variant}
      component={Link}
      to={
        to || {
          pathname:
            record && record.id
              ? `${linkToRecord(basePath, record.id)}/${target}`
              : `/${target}`,
          basePath,
          record,
          className,
          resource,
        }
      }
      color='secondary'
      {...sanitizeRestProps(rest)}
    >
      {React.cloneElement(icon, {
        className: classnames(classes.leftIcon, classes.icon),
      })}
      {label && translate(label, { _: label })}
    </Button>
  );
};

CancelButton.propTypes = {
  className: PropTypes.string,
  handleSubmitWithRedirect: PropTypes.func,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  pristine: PropTypes.bool,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  showNotification: PropTypes.func,
  submitOnEnter: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained', undefined]),
};

export default CancelButton;
