import {
  DxTheme,
  PreviewButton as previewButton,
  RelatedAttachments,
} from '@dx-ui/dx-common';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import React from 'react';
import { useTranslate } from 'react-admin';
import {
  createColumnsProcessDetails,
  createCommonColumns,
} from '../../aspects';
import {
  DownloadButton as downloadButton,
  ShowMetadataButton as showMetadataButton,
} from '../../shared';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    paper: {
      maxWidth: '99%',
    },
  }),
  { name: 'AttachmentsDialog' }
);

const AttachmentsDialog = ({
  resource,
  id,
}: {
  resource: string;
  id: string;
}) => {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant='outlined' color='primary' onClick={handleClickOpen}>
        <AttachFileIcon />
        {translate('dxMessages.attachments.title', {
          _: 'Attachments',
        })}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='attachments-dialog-title'
        aria-describedby='attachments-dialog-description'
        maxWidth='xl'
        fullWidth={true}
        classes={{ paper: classes.paper }}
      >
        <DialogContent>
          <RelatedAttachments
            columnsProcessDetails={createColumnsProcessDetails()}
            commonColumns={createCommonColumns()}
            showMetadataButton={showMetadataButton}
            downloadButton={downloadButton}
            previewButton={previewButton}
            resource={resource}
            id={id}
            redirectOnDelete={false}
            refreshOnDelete={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            {translate('ra.action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AttachmentsDialog;
