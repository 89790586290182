import * as React from 'react';
import { FC } from 'react';
import { FieldProps } from 'react-admin';
import { Account } from '../types';
import KeyValueJob from './KeyValueJob';

/**
 * The <ReactAdmin /> input for the account job.
 *
 * Depending on the company the account belongs to, the UI may differ.
 * That's why it's a selector. But whatever UI gets displayed, the form value
 * it fills in MUST always be a Job.
 *
 * The props always contain an Account record EXCEPT on creation mode (hasCreate is true).
 * In that case, the contextual form always contains the value company.id.
 *
 * @typedef { import("../types").Job } Job
 * @typedef { import("../types").Account } Account
 */
const Job: FC<
  FieldProps<Account> & {
    companyId: string;
    hasCreate?: boolean;
    hasEdit?: boolean;
    hasShow?: boolean;
    disabled?: boolean;
  }
> = (props) => {
  const { companyId } = props;

  // Depending on the company, select how to input the job of the person
  // the account belongs to.
  switch (companyId) {
    default:
      // Here, always the default (key, value)* input:
      return <KeyValueJob {...props} />;
  }
};

export default Job;
