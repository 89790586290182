import { useTheme } from '@material-ui/core';
import { cloneDeep, merge } from 'lodash';
import MaterialTable from 'material-table';
import React, { useEffect, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { ColumnConstants } from '../common/Constants';
import ColumnRenderer from '../common/util/ColumnRenderer';
import { excludeColumn } from '../common/util/utils';

const InnerClientTable = (props) => {
  const { rowData, comparedLines, excludedColumns, updatedOrderLine } = props;
  const tableRef = useRef();
  const translate = useTranslate();
  const theme = useTheme();
  const [innerRows, setInnerRows] = React.useState<any>();
  const [originalLines, setOriginalLines] = React.useState<any>();
  const [comparedOrderLines, setComparedOrderLines] = React.useState<any>();

  useEffect(() => {
    setOriginalLines(cloneDeep(rowData.LineItem.Delivery));
    setInnerRows(
      merge(cloneDeep(rowData.LineItem.Delivery), cloneDeep(comparedLines))
    );
    setComparedOrderLines(comparedLines);
  }, [comparedLines, rowData]);

  return (
    <MaterialTable
      tableRef={tableRef}
      title={translate('dxMessages.task.reginamaria.table.deliveryTitle')}
      components={{
        Container: (props) => <>{props.children}</>,
      }}
      columns={[
        {
          title: translate('dxMessages.task.reginamaria.table.deliveryId'),
          field: 'ID.value',
          editable: 'never',
          hidden: excludeColumn(excludedColumns, ColumnConstants.DELIVERY_ID),
        },
        {
          title: translate('dxMessages.task.reginamaria.table.startDate'),
          field: 'RequestedDeliveryPeriod.StartDate.value',
          type: 'date',
          headerStyle: {
            textAlign: 'right',
          },
          cellStyle: {
            textAlign: 'right',
          },
          dateSetting: { locale: 'en-GB' },
          editable: excludeColumn(
            excludedColumns,
            ColumnConstants.DELIVERY_START_DATE
          )
            ? 'never'
            : 'always',
          render: (rowData) => {
            // @ts-ignore
            const rowId = rowData.tableData.id;
            return (
              <ColumnRenderer
                rowId={rowId}
                comparedLines={comparedOrderLines}
                originalLines={originalLines}
                tableData={rowData}
                jsonPath='RequestedDeliveryPeriod.StartDate.value'
              />
            );
          },
        },
        {
          title: translate('dxMessages.task.reginamaria.table.endDate'),
          field: 'RequestedDeliveryPeriod.EndDate.value',
          type: 'date',
          headerStyle: {
            textAlign: 'right',
          },
          cellStyle: {
            textAlign: 'right',
          },
          dateSetting: { locale: 'en-GB' },
          editable: excludeColumn(
            excludedColumns,
            ColumnConstants.DELIVERY_END_DATE
          )
            ? 'never'
            : 'always',
          render: (rowData) => {
            // @ts-ignore
            const rowId = rowData.tableData.id;
            return (
              <ColumnRenderer
                rowId={rowId}
                comparedLines={comparedOrderLines}
                originalLines={originalLines}
                tableData={rowData}
                jsonPath='RequestedDeliveryPeriod.EndDate.value'
              />
            );
          },
        },
        {
          title: translate(
            'dxMessages.task.reginamaria.table.actualDeliveryDate'
          ),
          field: 'ActualDeliveryDate.value',
          type: 'date',
          hidden: excludeColumn(
            excludedColumns,
            ColumnConstants.DELIVERY_ACTUAL_DATE
          ),
          dateSetting: { locale: 'en-GB' },
          headerStyle: {
            textAlign: 'right',
          },
          cellStyle: {
            textAlign: 'right',
          },
          render: (rowData) => {
            // @ts-ignore
            const rowId = rowData.tableData.id;
            return (
              <ColumnRenderer
                rowId={rowId}
                comparedLines={comparedOrderLines}
                originalLines={originalLines}
                tableData={rowData}
                jsonPath='ActualDeliveryDate.value'
              />
            );
          },
        },
        {
          title: translate('dxMessages.task.reginamaria.table.quantity'),
          field: 'Quantity.value',
          type: 'numeric',
          hidden: excludeColumn(
            excludedColumns,
            ColumnConstants.DELIVERY_QUANTITY
          ),
          render: (rowData) => {
            // @ts-ignore
            const rowId = rowData.tableData.id;
            return (
              <ColumnRenderer
                rowId={rowId}
                comparedLines={comparedOrderLines}
                originalLines={originalLines}
                tableData={rowData}
                jsonPath='Quantity.value'
              />
            );
          },
        },
      ]}
      data={innerRows}
      options={{
        showTitle: false,
        search: false,
        filtering: false,
        paging: false,
        padding: 'dense',
        actionsColumnIndex: -1,
        headerStyle: {
          fontWeight: 'bolder',
          borderBottom: '1px solid lightgrey',
          color:
            updatedOrderLine === null
              ? theme.palette.action.disabled
              : undefined,
          pointerEvents: updatedOrderLine === null ? 'none' : 'inherit',
        },
        rowStyle: (rowData: any): any => ({
          color:
            updatedOrderLine === null
              ? theme.palette.action.disabled
              : undefined,
          pointerEvents: updatedOrderLine === null ? 'none' : 'inherit',
        }),
      }}
      localization={{
        header: { actions: '' },
        body: {
          emptyDataSourceMessage: translate(
            'dxMessages.task.reginamaria.table.emptyDeliveryTable'
          ),
        },
      }}
    />
  );
};

export default InnerClientTable;
