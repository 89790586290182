import { SingleDateInput } from '@dx-ui/dx-common';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a DateInput
 * The rendering takes into account that the field can be a member of an array.
 */
const SingleDateInputField = (props: FieldComponentProps) => {
  const { field } = props;
  const sanitizeProps: any = ({
    inputProps,
    loading,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;
  return (
    <FieldWrapper {...props}>
      {(fieldProps) => {
        return (
          <SingleDateInput
            {...sanitizeProps(fieldProps)}
            titleSuffix={field?.businessTerms}
          />
        );
      }}
    </FieldWrapper>
  );
};

export default SingleDateInputField;
