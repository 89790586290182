import { Constants, isPspAdministrator } from '@dx-ui/dx-common/src';
import { Button } from '@material-ui/core';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  email,
  maxLength,
  regex,
  required,
  useNotify,
  usePermissions,
  useRedirect,
  useTranslate,
} from 'react-admin';

export const ContactCreate: any = ({ setCreate, ...props }) => {
  const translate = useTranslate();
  const emailValidate = [required(), email(), maxLength(50)];
  const nameValidate = [required(), maxLength(50)];
  const phoneValidate = [
    maxLength(30),
    regex(
      /^[+]?[-0-9\s]*[(]?[0-9]{1,4}[)]?[-\s/0-9]*/,
      translate('dxMessages.error_messages.phone_number_invalid')
    ),
  ];
  const { permissions } = usePermissions();

  const notify = useNotify();

  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.created');
    if (setCreate !== undefined) {
      setCreate(false);
    } else {
      redirect('list', props.basePath);
    }
  };

  const CustomCreateToolbar = (props) => (
    <Toolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton />
      <CancelButton />
    </Toolbar>
  );

  const CancelButton = () => {
    const translate = useTranslate();
    return (
      <Button
        onClick={() => {
          if (setCreate !== undefined) {
            setCreate(false);
          } else {
            redirect('list', props.basePath);
          }
        }}
        variant='contained'
      >
        {translate('ra.action.cancel')}
      </Button>
    );
  };

  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      resource={Constants.RESOURCE_CONTACT}
    >
      <SimpleForm toolbar={<CustomCreateToolbar />}>
        {isPspAdministrator(permissions) && (
          <ReferenceInput
            label='resources.accounts.create.wizard.steps.companySelection.company'
            source='companyId'
            reference='management/company'
            validate={required()}
            filterToQuery={(searchText) => ({
              accountname: [searchText],
            })}
            sort={{ field: 'accountname', order: 'ASC' }}
            defaultValue={parseInt(props?.id)}
            disabled={props.id}
          >
            <AutocompleteInput optionText='accountname' fullWidth />
          </ReferenceInput>
        )}
        <TextInput
          label={'dxMessages.companyManagement.contact.lastName'}
          source='lastName'
          validate={nameValidate}
        />
        <TextInput
          label={'dxMessages.companyManagement.contact.firstName'}
          source='firstName'
          validate={nameValidate}
        />
        <TextInput
          label={'dxMessages.companyManagement.contact.phone'}
          source='phone'
          validate={phoneValidate}
        />
        <TextInput
          label={'dxMessages.companyManagement.contact.mail'}
          source='mail'
          validate={emailValidate}
        />
      </SimpleForm>
    </Create>
  );
};

export default ContactCreate;
