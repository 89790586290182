import { isPspAdministrator } from '@dx-ui/dx-common';
import { useGAPageViews } from '@dx-ui/dx-common/src';
import { withStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  CreateButton,
  Datagrid,
  ExportButton,
  Filter,
  FilterProps,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import CustomDeleteButton from './CustomDeleteButton';
import CustomEditButton from './CustomEditButton';

// interface LastLoginTimeResponse extends RaRecord {
//   // ISO-8601 representation.
//   lastLoginTime: string;
// }

const AddressFilters = (props: Omit<FilterProps, 'children'>) => {
  const { permissions, loaded } = usePermissions();
  if (!loaded || !permissions) return null;

  return (
    <Filter {...props}>
      {isPspAdministrator(permissions) && (
        <ReferenceInput
          label='resources.accounts.list.headers.company'
          source='companyId'
          reference='management/company'
          filterToQuery={(searchText) => ({
            accountname: [searchText],
          })}
          sort={{ field: 'accountname', order: 'ASC' }}
          allowEmpty={true}
          alwaysOn
        >
          <AutocompleteInput
            optionText='accountname'
            optionValue='id'
            resettable={true}
          />
        </ReferenceInput>
      )}
      <TextInput
        label={'dxMessages.companyManagement.addresses.street'}
        source='street'
        alwaysOn
      />
      <TextInput
        label={'dxMessages.companyManagement.addresses.city'}
        source='city'
        alwaysOn
      />
      <TextInput
        label={'dxMessages.companyManagement.addresses.gln'}
        source='gln'
        alwaysOn
      />
      <SelectInput
        source='addressType'
        label='dxMessages.companyManagement.addresses.addressType'
        choices={[
          {
            id: 'billing',
            name: 'dxMessages.companyManagement.addresses.flgBilling',
          },
          {
            id: 'shipping',
            name: 'dxMessages.companyManagement.addresses.flgShipping',
          },
          {
            id: 'logisitic',
            name: 'dxMessages.companyManagement.addresses.flgLogistic',
          },
        ]}
        alwaysOn
      />
    </Filter>
  );
};

const AddressDetailsList = (props: any) => {
  const ErrorIcon = withStyles((theme) => ({
    root: {
      color: theme.palette.error.main,
    },
  }))(ClearIcon);
  const { permissions, loaded } = usePermissions();

  useGAPageViews();

  if (!loaded || !permissions) return null;

  const ListActions = () => {
    return (
      <TopToolbar>
        <CreateButton />
        <ExportButton maxResults={5000} />
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<AddressFilters />}
      actions={<ListActions />}
      empty={false}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        {/* A company administrator only sees his own company accounts
        so don't show the company column */}
        {isPspAdministrator(permissions) && (
          <ReferenceField
            label='resources.accounts.list.headers.company'
            source='companyId'
            reference='management/company'
            link={false}
            sortable={false}
          >
            <TextField source='accountname' />
          </ReferenceField>
        )}
        <TextField
          label={'dxMessages.companyManagement.addresses.street'}
          source='street'
        />
        <TextField
          label={'dxMessages.companyManagement.addresses.additionalStreet'}
          source='additionalStreet'
        />
        <TextField
          label={'dxMessages.companyManagement.addresses.buildingNumber'}
          source='buildingNumber'
        />
        <TextField
          label={'dxMessages.companyManagement.addresses.postBox'}
          source='postBox'
        />
        <TextField
          label={'dxMessages.companyManagement.addresses.city'}
          source='city'
        />
        <TextField
          label={'dxMessages.companyManagement.addresses.postalCode'}
          source='postalCode'
        />
        <TextField
          label={'dxMessages.companyManagement.addresses.financialAccount'}
          source='financialAccount'
        />
        <TextField
          label={'dxMessages.companyManagement.addresses.bank'}
          source='bank'
        />
        <TextField
          label={'dxMessages.companyManagement.addresses.gln'}
          source='gln'
        />
        <TextField
          label={'dxMessages.companyManagement.addresses.country'}
          source='country'
          sortable={false}
        />
        <BooleanField
          label={'dxMessages.companyManagement.addresses.flgBilling'}
          source='flgBilling'
          sortable={false}
          // @ts-ignore
          FalseIcon={ErrorIcon}
        />
        <BooleanField
          label={'dxMessages.companyManagement.addresses.flgShipping'}
          source='flgShipping'
          sortable={false}
          // @ts-ignore
          FalseIcon={ErrorIcon}
        />
        <BooleanField
          label={'dxMessages.companyManagement.addresses.flgLogistic'}
          source='flgLogistic'
          sortable={false}
          // @ts-ignore
          FalseIcon={ErrorIcon}
        />
        <CustomEditButton />
        <CustomDeleteButton />
      </Datagrid>
    </List>
  );
};

export default AddressDetailsList;
