import {
  cellStyle,
  GenericSimpleField,
  Metadata,
  widthPresets,
} from '@dx-ui/dx-common';
import React from 'react';
import { TextInput } from 'react-admin';

export const AttachmentParentDXUIDField = (props) => (
  <GenericSimpleField {...props} />
);

export const createColumnsAttachment = () => [
  {
    id: Metadata.attachmentParentDxuid,
    label: 'dxMessages.headers.attachmentParentDxuid',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <AttachmentParentDXUIDField {...props} />,
  },
];

export const createAttachmentFilters = () => [
  {
    id: Metadata.attachmentParentDxuid,
    filter: (props) => (
      <TextInput
        source={Metadata.attachmentParentDxuid}
        label='dxMessages.headers.attachmentParentDxuid'
        resettable
        {...props}
      />
    ),
  },
];
