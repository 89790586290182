// A pre-defined form to approve or reject.
//
// When rejecting, the user must comment its decision.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

import { Button } from '@dx-ui/dx-common';
import { Grid, TextField } from '@material-ui/core';
import ApproveIcon from '@material-ui/icons/Check';
import RejectIcon from '@material-ui/icons/Close';
import React, { FC, useState } from 'react';
import { Mutation } from 'react-admin';

const TaskAction: FC<any> = (props) => {
  const { record } = props;

  const [comment, setComment] = useState<string>();

  const options = {
    undoable: true,
    onSuccess: {
      refresh: true,
      notification: {
        body: 'Done! Please wait for the list to refresh',
        level: 'info',
      },
    },
    onError: {
      notification: { body: 'Error: comment not approved', level: 'warning' },
    },
  };

  const ApproveButton = ({ record }) => {
    const payload = {
      id: record.id,
      data: { comment: comment, action: 'approved' },
    };
    return (
      <Mutation
        type='UPDATE'
        resource='bpm/tasks'
        payload={payload}
        options={options}
      >
        {(approve) => (
          <Button label='Approve' icon={<ApproveIcon />} onClick={approve} />
        )}
      </Mutation>
    );
  };

  const RejectButton = ({ record }) => {
    const payload = {
      id: record.id,
      data: { comment: 'Invalid document!', action: 'rejected' },
    };
    return (
      <Mutation
        type='UPDATE'
        resource='bpm/tasks'
        payload={payload}
        options={options}
      >
        {(reject) => (
          <Button label='Reject' icon={<RejectIcon />} onClick={reject} />
        )}
      </Mutation>
    );
  };

  if (!record) return null;

  // When a user clicks on one button, be careful to disable both buttons till the action completes
  // so user cannot quickly cliks both buttons.
  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <TextField
          multiline
          fullWidth
          placeholder='Your comment here'
          rows='2'
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <ApproveButton record={record} />
        </Grid>
        <Grid item>
          <RejectButton record={record} />
        </Grid>
      </Grid>
      {/* {error && (
        <Grid item>
          <Typography variant='caption' color='error'>
            {translate(error)}
          </Typography>
        </Grid>
      )} */}
    </Grid>
  );
};

export default TaskAction;

// TODO: Replace code above with code below after react-admin v3 has been implemented.
// Currently there are two warnings in the console because of the <Mutation>

// A pre-defined form to approve or reject.
//
// When rejecting, the user must comment its decision.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// import { Fab, Grid, TextField, Typography } from "@material-ui/core";
// import { withStyles } from "@material-ui/core/styles";
// import ApproveIcon from "@material-ui/icons/Check";
// import RejectIcon from "@material-ui/icons/Close";
// import React, { FC, useState } from "react";
// import { useMutation, useNotify, useRefresh, useTranslate } from "react-admin";
// import { colors } from "../../../theme/Colors";
// import { FieldProps, Task } from "../../../types";

// // Use a red button for the rejection.
// const RejectFab = withStyles((theme: any) => ({
//   root: {
//     backgroundColor: colors.red[100],
//     "&:hover": {
//       backgroundColor: colors.red[900],
//     },
//   },
// }))(Fab);

// interface TaskDataProps {
//   action: "approved" | "rejected";
//   comment?: string;
// }

// const TaskAction: FC<FieldProps<Task>> = ({ record }) => {
//   const translate = useTranslate();
//   const notify = useNotify();
//   const refresh = useRefresh();
//   const [comment, setComment] = useState<string>();
//   const [error, setError] = useState<string>();

//   const [mutate, { loading }] = useMutation(
//     {
//       type: "update",
//       resource: "bpm/tasks",
//       payload: {
//         id: record?.id,
//         data: {
//           comment: comment,
//         },
//       },
//     },
//     {
//       onSuccess: () => {
//         notify("Done", "info");
//         refresh();
//       },
//       onFailure: (error: any) => {
//         if (error.status === 410)
//           notify(
//             "This task is gone. Done by someone else? Please hit the refresh button."
//           );
//         else
//           notify(
//             "Oups. Retry later or contact the support!",
//             { error: error.message },
//             "warning"
//           );
//       },
//     }
//   );
//   const approve = () =>
//     mutate({
//       payload: {
//         data: {
//           action: "approved",
//         },
//       },
//     });
//   const reject = () =>
//     mutate({
//       payload: {
//         data: {
//           action: "rejected",
//         },
//       },
//     });

//   if (!record) return null;

//   // When a user clicks on one button, be careful to disable both buttons till the action completes
//   // so user cannot quickly cliks both buttons.
//   return (
//     <Grid container direction="column" spacing={2}>
//       <Grid item>
//         <TextField
//           multiline
//           fullWidth
//           placeholder="Your comment here"
//           variant="outlined"
//           rows="2"
//           value={comment}
//           onChange={(event) => setComment(event.target.value)}
//         />
//       </Grid>
//       <Grid item container spacing={2}>
//         <Grid item>
//           <Fab
//             size="small"
//             color="primary"
//             variant="extended"
//             aria-label="Approve"
//             onClick={approve}
//             disabled={loading}
//           >
//             <ApproveIcon />
//             Approve
//           </Fab>
//         </Grid>
//         <Grid item>
//           <RejectFab
//             size="small"
//             variant="extended"
//             aria-label="Reject"
//             disabled={loading}
//             onClick={() => {
//               if (!comment) {
//                 setError("common.provideComment");
//                 return;
//               }
//               reject();
//             }}
//           >
//             <RejectIcon />
//             Reject
//           </RejectFab>
//         </Grid>
//       </Grid>
//       {error && (
//         <Grid item>
//           <Typography variant="caption" color="error">
//             {translate(error)}
//           </Typography>
//         </Grid>
//       )}
//     </Grid>
//   );
// };

// export default TaskAction;
