import { Constants } from '@dx-ui/dx-common';
import { Button } from '@material-ui/core';
import {
  DateTimeInput,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  email,
  maxLength,
  regex,
  required,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';

export const ContactEdit: any = ({ setEdit, ...props }) => {
  const translate = useTranslate();
  const emailValidate = [required(), email(), maxLength(50)];
  const nameValidate = [required(), maxLength(50)];
  const phoneValidate = [
    maxLength(30),
    regex(
      /^[+]?[-0-9\s]*[(]?[0-9]{1,4}[)]?[-\s/0-9]*/,
      translate('dxMessages.error_messages.phone_number_invalid')
    ),
  ];
  const { id } = props;

  const notify = useNotify();

  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.updated');
    if (setEdit !== undefined) {
      setEdit(false);
    } else {
      redirect('list', props.basePath);
    }
  };

  const CustomEditToolbar = (props) => (
    <Toolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SaveButton
          {...props}
          style={{ display: 'flex', alignItems: 'center' }}
        />
        <CancelButton />
      </div>
    </Toolbar>
  );

  const CancelButton = () => {
    const translate = useTranslate();
    return (
      <Button
        onClick={() => {
          if (setEdit !== undefined) {
            setEdit(false);
          } else {
            redirect('list', props.basePath);
          }
        }}
        variant='contained'
      >
        {translate('ra.action.cancel')}
      </Button>
    );
  };

  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      id={props.props ? props.props.record.id : id}
      resource={Constants.RESOURCE_CONTACT}
    >
      <SimpleForm toolbar={<CustomEditToolbar />}>
        <TextInput
          label={'dxMessages.companyManagement.contact.lastName'}
          source='lastName'
          validate={nameValidate}
        />
        <TextInput
          label={'dxMessages.companyManagement.contact.firstName'}
          source='firstName'
          validate={nameValidate}
        />
        <TextInput
          label={'dxMessages.companyManagement.contact.phone'}
          source='phone'
          validate={phoneValidate}
        />
        <TextInput
          label={'dxMessages.companyManagement.contact.mail'}
          source='mail'
          validate={emailValidate}
        />
        <DateTimeInput
          label={'dxMessages.companyManagement.audit.tsChange'}
          source='tsChange'
          disabled
        />

        <TextInput
          label={'dxMessages.companyManagement.audit.userIdChange'}
          source='userIdChange'
          disabled
        />
      </SimpleForm>
    </Edit>
  );
};

export default ContactEdit;
