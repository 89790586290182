import {
  BaseUrlFormatter,
  buildDataProvider as buildCommonDataProvider,
  commonBaseUrlFormatter,
  CommonDataProvider,
  Constants,
  GenericResult,
  getDeleteResource,
  getTotalFromHeaders,
  httpClient,
  Metadata,
  resourceWebTypeToWebDocumentResource,
} from '@dx-ui/dx-common';
import { stringify } from 'query-string';
import { DataProvider } from 'react-admin';
import { RESOURCE_ANALYTICS } from '../../components/dashboard';

const AppName = Constants.DXPURCHASE_APP;

const rootUrl = `${window.location.origin}`;

const getAperakContent = (aperakXml: string): string => {
  let parser = new DOMParser();
  const xmlDoc: Document = parser.parseFromString(aperakXml, 'text/xml');
  const aperakFirstNode = xmlDoc?.firstChild?.nodeName;
  let content: string = '';
  if (aperakFirstNode === 'ApplicationResponse') {
    content =
      xmlDoc.getElementsByTagName('cbc:StatusReason')[0]?.textContent || '';
  } else if (aperakFirstNode === 'DXMessage') {
    content =
      xmlDoc.getElementsByTagName('DocumentDetail')[0]?.textContent || '';
  } else {
    throw new Error('Unrecognized aperak content format');
  }
  return content;
};

const apiGetTaskDocuments = async (resource, params) => {
  const options: RequestInit = {};
  const url = `${urlFormatter(resource, params)}/${resource}`;
  options.method = 'POST';
  options.body = JSON.stringify(params);
  const response = await httpClient(url, options);
  const { json } = response;
  return { data: json };
};

const apiGetCatalog = async (resource, params) => {
  const options: RequestInit = {};
  const url = `${urlFormatter(resource, params)}/${resource}/${
    params.buyerId
  }/${params.supplierId}`;
  options.method = 'GET';
  const response = await httpClient(url, options);
  const { json } = response;
  return { data: json };
};

const syncCompany = async (resource, params) => {
  const options: RequestInit = {};
  const url = `${urlFormatter(resource, params)}/${resource}/sync/${
    params.identification
  }`;
  options.method = 'POST';
  const response = await httpClient(url, options);
  return { data: response };
};

const apiCloneDocument = async (resource, params) => {
  const options: RequestInit = {};
  let url = `${urlFormatter(resource, params)}/${
    Constants.RESOURCE_WEBDOCUMENT
  }/clone/${params.cloneType}`;
  if (params.nodeId) {
    url += `/${params.nodeId}`;
  }
  options.method = 'POST';
  const response = await httpClient(url, options);
  const { json } = response;
  return { data: json };
};

export type DxPortalDataProvider = {
  apiGetTaskDocuments: (
    resource: string,
    params: any
  ) => Promise<GenericResult>;
  apiGetCatalog: (resource: string, params: any) => Promise<GenericResult>;
  apiCloneDocument: (resource: string, params: any) => Promise<GenericResult>;
  syncCompany: (resource: string, params: any) => Promise<GenericResult>;
};

export type CustomDataProvider = DataProvider &
  CommonDataProvider &
  DxPortalDataProvider;

const urlFormatter: BaseUrlFormatter = (resource: string, params: any) => {
  if (resource.startsWith(RESOURCE_ANALYTICS)) {
    // do not include app name in url for analytics calls
    return `${rootUrl}`;
  }
  if (
    resource.startsWith('configuration-') ||
    [
      'loginevents',
      'accountlifecycleevents',
      'nwaymatching-statistics',
      'regulatorAccess/efactura',
      Constants.RESOURCE_TASKS,
    ].includes(resource)
  ) {
    // do not include app name but '/api' in url for configuration calls
    return `${rootUrl}/api`;
  }

  return commonBaseUrlFormatter(AppName, resource, rootUrl);
};

const dataProvider: CustomDataProvider = {
  // add common data provider operations
  ...buildCommonDataProvider(urlFormatter),

  getList: async (resource, params) => {
    const options: RequestInit = {};
    let query: any = {};
    let filter = params.filter;
    if (
      [
        Constants.RESOURCE_TASKS,
        'configuration-accounts',
        'configuration-companies',
        'configuration-people',
        'configuration-companyadminpeople',
        'configuration-identityProviders',
        'configuration-dxfeatures',
        'loginevents',
        'usermgmt/roles',
        Constants.RESOURCE_ADDRESSES,
        Constants.RESOURCE_ACCOUNT,
        Constants.RESOURCE_CONTACT,
        Constants.RESOURCE_SUPPORTED_PRODUCTS,
        Constants.RESOURCE_CREDIT,
        Constants.RESOURCE_DROPDOWNDEF,
        Constants.RESOURCE_FORMAT_TYPE,
        Constants.RESOURCE_DX_ROLE,
        Constants.RESOURCE_DXPRODUCT,
        Constants.RESOURCE_PRODUCT_FOR_RELATION_PRODUCTS,
        Constants.RESOURCE_SUPPLIERS,
        Constants.RESOURCE_CUSTOMER,
        Constants.RESOURCE_USERS,
        Constants.RESOURCE_PRODUCTUSAGE,
        Constants.RESOURCE_V2INVOICE,
        Constants.RESOURCE_V2ORDER,
        Constants.RESOURCE_V2DESPATCHADVICE,
        Constants.RESOURCE_V2RECEIPTADVICE,
        Constants.RESOURCE_MESSAGES,
        Constants.RESOURCE_PEPPOL,
        Constants.RESOURCE_EFACTURA_MONITORING_CONTENT_ERROR,
        Constants.RESOURCE_EFACTURA_MONITORING_REJECTED_DOCUMENTS,
        'accountlifecycleevents',
        'regulatorAccess/efactura',
      ].includes(resource)
    ) {
      // For tasks, we hit the task REST API, which doesn't expect the pagination and the filter
      // the same way as docxchange, so prepare the request accordingly:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      query = {
        _start: JSON.stringify((page - 1) * perPage),
        _end: JSON.stringify(page * perPage),
        _sort: field,
        _order: order,
        ...filter,
      };
    } else {
      if (params && params.pagination && params.sort) {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        };
      }

      // Saves last get list query for docs
      if (typeof localStorage !== 'undefined') {
        const username = localStorage.getItem('user');
        localStorage.setItem(
          username + '.' + Constants.DX_LAST_GET_LIST,
          JSON.stringify({
            perPage: params.pagination?.perPage,
            sort: params.sort,
            filter: params.filter,
          })
        );
      }

      // We need to filter the GET_LIST based on edm:processDocumentFormatType
      if (params['allFormatTypes'] !== true) {
        // Add edm:processDocumentFormatType filter
        if (
          params.filter !== undefined &&
          params.filter[Metadata.processDocumentFormatType] === undefined
        ) {
          if (
            resource === Constants.RESOURCE_WEBINVOICE ||
            resource === Constants.RESOURCE_WEBDESPATCH_ADVICE ||
            resource === Constants.RESOURCE_WEBRECEIPT_ADVICE ||
            resource === Constants.RESOURCE_WEBORDER ||
            resource === Constants.RESOURCE_WEBWAYBILL
          ) {
            // Specific case for web Invoices list : Only DRAFT
            filter[Metadata.processDocumentFormatType] =
              Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT;
          } else {
            if (
              resource !== 'regulatorAccess/efactura' &&
              !resource.startsWith('company') &&
              !resource.startsWith('usermgmt') &&
              !resource.startsWith('address')
            ) {
              filter[Metadata.processDocumentFormatType] = [
                Constants.PROCESS_DOCUMENT_FORMAT_TYPE_ORIGINAL,
                Constants.PROCESS_DOCUMENT_FORMAT_TYPE_TARGET,
              ];
            }
          }
        }
      }
      query.filter = JSON.stringify(filter);
    }

    resource = resourceWebTypeToWebDocumentResource(resource);

    const url = `${urlFormatter(resource, params)}/${resource}?${stringify(
      query
    )}`;

    const response = await httpClient(url, options);

    const { headers, json } = response;

    if (!headers.has('content-range')) {
      throw new Error(
        'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
      );
    }

    return {
      data: json,
      total: getTotalFromHeaders(headers),
    };
  },

  getOne: async (resource, params) => {
    const options: RequestInit = {};
    resource = resourceWebTypeToWebDocumentResource(resource);

    const url = `${urlFormatter(resource, params)}/${resource}/${params.id}`;

    const response = await httpClient(url, options); //TODO: handle error responses
    const { json } = response;
    return { data: json };
  },

  getMany: async (resource, params) => {
    let query: any = {};
    if (
      [
        'configuration-accounts',
        'configuration-companies',
        'configuration-people',
        'configuration-companyadminpeople',
        Constants.RESOURCE_ADDRESSES,
        Constants.RESOURCE_CONTACT,
        Constants.RESOURCE_SUPPORTED_PRODUCTS,
        Constants.RESOURCE_CREDIT,
        Constants.RESOURCE_PRODUCT_FOR_RELATION,
        Constants.RESOURCE_PRODUCT_FOR_RELATION_PRODUCTS,
        Constants.RESOURCE_DROPDOWNDEF,
        Constants.RESOURCE_FORMAT_TYPE,
        Constants.RESOURCE_DX_ROLE,
        Constants.RESOURCE_DXPRODUCT,
        Constants.RESOURCE_ACCOUNT,
        Constants.RESOURCE_USERS,
        Constants.RESOURCE_SUPPLIERS,
        Constants.RESOURCE_CUSTOMER,
        Constants.RESOURCE_PRODUCTUSAGE,
        Constants.RESOURCE_PEPPOL,
        Constants.RESOURCE_V2INVOICE,
        Constants.RESOURCE_V2ORDER,
        Constants.RESOURCE_V2DESPATCHADVICE,
        Constants.RESOURCE_V2RECEIPTADVICE,
        Constants.RESOURCE_EFACTURA_MONITORING_CONTENT_ERROR,
        Constants.RESOURCE_EFACTURA_MONITORING_REJECTED_DOCUMENTS,
        'configuration-dxfeatures',
        'regulatorAccess/efactura',
        Constants.RESOURCE_MESSAGES,
      ].includes(resource)
    ) {
      query = {
        id: params.ids,
      };
    } else {
      query = {
        filter: JSON.stringify({ id: params.ids }),
      };
    }
    const url = `${urlFormatter(resource, params)}/${resource}?${stringify(
      query
    )}`;

    const response = await httpClient(url, {}); //TODO: handle error responses
    const { json } = response;
    return { data: json };
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    let query: any = {};
    let filter = params.filter;
    if (
      [
        Constants.RESOURCE_TASKS,
        'configuration-accounts',
        'configuration-companies',
        'configuration-people',
        'configuration-companyadminpeople',
        'configuration-identityProviders',
        'configuration-dxfeatures',
        'loginevents',
        Constants.RESOURCE_ADDRESSES,
        Constants.RESOURCE_ACCOUNT,
        Constants.RESOURCE_CONTACT,
        Constants.RESOURCE_SUPPORTED_PRODUCTS,
        Constants.RESOURCE_CREDIT,
        Constants.RESOURCE_PRODUCT_FOR_RELATION,
        Constants.RESOURCE_PRODUCT_FOR_RELATION_PRODUCTS,
        Constants.RESOURCE_DROPDOWNDEF,
        Constants.RESOURCE_FORMAT_TYPE,
        Constants.RESOURCE_DXPRODUCT,
        Constants.RESOURCE_SUPPLIERS,
        Constants.RESOURCE_CUSTOMER,
        Constants.RESOURCE_USERS,
        Constants.RESOURCE_PRODUCTUSAGE,
        Constants.RESOURCE_PEPPOL,
        Constants.RESOURCE_V2INVOICE,
        Constants.RESOURCE_V2ORDER,
        Constants.RESOURCE_V2DESPATCHADVICE,
        Constants.RESOURCE_V2RECEIPTADVICE,
        Constants.RESOURCE_EFACTURA_MONITORING_CONTENT_ERROR,
        Constants.RESOURCE_EFACTURA_MONITORING_REJECTED_DOCUMENTS,
        'accountlifecycleevents',
        'regulatorAccess/efactura',
      ].includes(resource)
    ) {
      // For tasks, we hit the task REST API, which doesn't expect the pagination and the filter
      // the same way as docxchange, so prepare the request accordingly:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      query = {
        _start: JSON.stringify((page - 1) * perPage),
        _end: JSON.stringify(page * perPage),
        _sort: field,
        _order: order,
        ...filter,
      };
    } else {
      query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
    }
    const url = `${urlFormatter(resource, params)}/${resource}?${stringify(
      query
    )}`;
    const response = await httpClient(url, {});
    const { headers, json } = response;

    if (!headers.has('content-range')) {
      throw new Error(
        'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
      );
    }

    return {
      data: json,
      total: parseInt(
        headers?.get('content-range')?.split('/')?.pop() ?? '0',
        10
      ),
    };
  },

  update: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${resource}/${params.id}`;
    options.method = 'PUT';
    options.body = JSON.stringify(params.data);
    const response = await httpClient(url, options);

    const { json } = response;
    return { data: json };
  },

  updateMany: (resource, params) => {
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${urlFormatter(resource, params)}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        })
      )
    ).then((responses) => ({
      data: responses.map((response: any) => response.json),
    }));
  },

  create: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${resource}`;
    options.method = 'POST';
    options.body = JSON.stringify(params.data);
    const response = await httpClient(url, options);

    const { json } = response;
    return { data: { ...params.data, id: json?.id } };
  },

  delete: async (resource, params) => {
    const options: RequestInit = {};
    resource = getDeleteResource(resource);
    const url = `${urlFormatter(resource, params)}/${resource}/${params.id}`;
    options.method = 'DELETE';
    const response = await httpClient(url, options);

    const { json } = response;
    return { data: json ? json : params.previousData };
  },

  deleteMany: async (resource, params) => {
    resource = getDeleteResource(resource);

    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${urlFormatter(resource, params)}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then((responses) => ({
      data: responses.map((response: any) => response.json),
    }));
  },

  [Constants.API_APERAK_DETAILS]: async (resource, params) => {
    const options: RequestInit = {};

    const { record } = params;

    const url = `${urlFormatter(resource, params)}/${record.id}/download`;

    const response = await httpClient(url, options);

    let message: string = '';
    try {
      message = getAperakContent(response.body);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.error('Error while parsing APERAK details text', ex);
    }
    return {
      data: { message: message },
    };
  },

  [Constants.API_CREATE_BULK_DOWNLOAD]: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${resource}`;
    options.method = 'POST';
    options.body = JSON.stringify(params.data);
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_DELETE_BULK_DOWNLOAD]: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${resource}`;
    options.method = 'POST';
    options.body = JSON.stringify(params.data);
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },
  [Constants.API_GET_BULK_DOWNLOAD]: async (resource, params) => {
    const request = new Request(
      `${urlFormatter(resource, params)}/${resource}/${params.id}`,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/zip',
          'X-Requested-With': 'XMLHttpRequest',
        }),
        credentials: 'include',
      }
    );

    let response: Response = await fetch(request);
    return { data: response };
  },

  [Constants.API_GET_UBL_JSON]: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${Constants.RESOURCE_UBL}/${
      params.id
    }`;
    options.method = 'GET';
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_GET_JSON_CONTENT]: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${
      Constants.RESOURCE_WEBDOCUMENT
    }/content/${params.id}`;
    options.method = 'GET';
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_POST_CREATE_DOCUMENT]: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${
      Constants.RESOURCE_WEBDOCUMENT
    }/${resource}`;
    options.method = 'POST';
    options.body = JSON.stringify(params.data);
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.RESOURCE_ZENDESK]: async (resource, params) => {
    const options: RequestInit = {};
    const url = resource;
    options.method = 'POST';
    const response = await httpClient(url, options);
    if (response.status === 200) {
      return { data: response.body };
    } else {
      return undefined;
    }
  },

  [Constants.API_PUT_SAVE_DOCUMENT]: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${
      Constants.RESOURCE_WEBDOCUMENT
    }/${params.id}`;
    options.method = 'PUT';
    options.body = JSON.stringify(params.data);
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_SEND_DOCUMENT]: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${resource}/send/${
      params.id
    }`;
    options.method = 'POST';
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_GET_TEMPLATE]: async (resource, params) => {
    const options: RequestInit = {};
    let url = `${urlFormatter(resource, params)}/${resource}/${params.bspId}/${
      params.recipientId
    }/${params.documentType}`;
    if (params.documentSubType) {
      url += `/${params.documentSubType}`;
    }
    if (params.documentCategoryCode) {
      url += `/${params.documentCategoryCode}`;
    }
    options.method = 'GET';
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_GET_TEMPLATES_METADATA]: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${resource}/metadata`;
    options.method = 'GET';
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_GET_TEMPLATES_SUB_TYPES]: async (resource, params) => {
    const options: RequestInit = {};
    const url = `${urlFormatter(resource, params)}/${resource}/docsubtypes/${
      params.bspId
    }/${params.recipientId}/${params.documentType}`;
    options.method = 'GET';
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_GET_COMPANY_DETAILS]: async (resource, params) => {
    const options: RequestInit = {};
    const url = params.identification
      ? `${urlFormatter(resource, params)}/${resource}/details/${
          params.identification
        }`
      : `${urlFormatter(resource, params)}/${resource}/details`;
    options.method = 'GET';
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_FETCH_ENDPOINTS_BY_ISSUER_IDENTIFICATION_OR_COMPANY_NAME]:
    async (resource, params) => {
      const options: RequestInit = {};
      const url = params.identification
        ? `${urlFormatter(resource, params)}/${resource}/peppol/endpoints/${
            params.identification
          }`
        : `${urlFormatter(resource, params)}/${resource}/peppol/endpoints`;
      options.method = 'GET';
      const response = await httpClient(url, options);
      const { json } = response;
      return { data: json };
    },

  [Constants.API_GET_COMPANY_RELATIONS]: async (resource, params) => {
    const options: RequestInit = {};
    const url = params.identification
      ? `${urlFormatter(resource, params)}/${resource}/relations/${
          params.docType
        }/${params.identification}/`
      : `${urlFormatter(resource, params)}/${resource}/relations/${
          params.docType
        }`;
    options.method = 'GET';
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_GET_RECIPIENTS_FOR_TEMPLATE]: async (resource, params) => {
    const options: RequestInit = {};
    const url = params.identification
      ? `${urlFormatter(
          resource,
          params
        )}/${resource}/templates?identification=${
          params.identification
        }&bspId=${params.bspId}&documentType=${params.documentType}`
      : `${urlFormatter(resource, params)}/${resource}/templates?bspId=${
          params.bspId
        }&documentType=${params.documentType}`;
    options.method = 'GET';
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  apiGetAllDocs: async (resource, params) => {
    const options: RequestInit = {};
    const { sortMessage, sortAttach, sortLinked, sortRelated } = params;
    const query = {};

    if (sortMessage) {
      query['sortMessage'] = JSON.stringify([
        sortMessage.field,
        sortMessage.order,
      ]);
    }
    if (sortRelated) {
      query['sortRelated'] = JSON.stringify([
        sortRelated.field,
        sortRelated.order,
      ]);
    }

    if (sortLinked) {
      query['sortLinked'] = JSON.stringify([
        sortLinked.field,
        sortLinked.order,
      ]);
    }

    if (sortAttach) {
      query['sortAttach'] = JSON.stringify([
        sortAttach.field,
        sortAttach.order,
      ]);
    }
    const url = `${urlFormatter(resource, params)}/${resource}/${
      params.documentId
    }?${stringify(query)}`;
    options.method = 'GET';
    const response = await httpClient(url, options);
    const { json } = response;
    return { data: json };
  },

  [Constants.API_GET_TASK_DOCUMENTS]: apiGetTaskDocuments,
  apiGetTaskDocuments,

  [Constants.API_GET_CATALOG]: apiGetCatalog,
  apiGetCatalog,

  [Constants.API_CLONE_DOCUMENT]: apiCloneDocument,
  apiCloneDocument,

  [Constants.SYNC_COMPANY]: syncCompany,
  syncCompany,
};

export default dataProvider;
