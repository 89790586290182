import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import React, { useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Constants } from '../../constants';
import { DxTheme } from '../../types';
import { useInterval, useSetupUsage, useSwitchTo } from '../../utils';
import { SetupUsage } from '../../utils/useSetupUsage';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    showIcon: {
      color: 'inherit',
    },
  }),
  { name: 'HomeButton' }
);

const HomeButton = React.forwardRef<any>((props, ref) => {
  const { className }: any = props;

  const classes = useStyles(props);
  const translate = useTranslate();
  const switchTo = useSwitchTo();
  const setupUsage = useSetupUsage();

  const [count, setCount] = useState(0);
  const dataProvider = useDataProvider();
  const viewVersion = useSelector((state: any) => state.admin.ui.viewVersion);

  const getTasksCount = async () => {
    dataProvider
      .getOne(Constants.RESOURCE_TASKS, {
        id: 'taskCount',
      })
      .then(({ data }: any) => {
        setCount(data.taskCount);
      })
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.debug('error %o', error);
      });
  };

  useEffect(() => {
    getTasksCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewVersion]);

  // Get the last published messages from the message center.
  useInterval(() => {
    getTasksCount();
  }, 1000 * 60 * 5);

  const handleClick = (event) => {
    if (setupUsage === SetupUsage.DEV) {
      switchTo(`/#/home`, Constants.DXPURCHASE_APP);
    } else {
      switchTo(`/${Constants.DXPURCHASE_APP}/#/home`, Constants.DXPURCHASE_APP);
    }
  };

  return (
    <Tooltip
      title={
        count > 0
          ? `${translate('dxMessages.menu.home')} : ${translate(
              'dxMessages.dashboard.widgets.PendingTaskCount.Count'
            )}`
          : translate('dxMessages.menu.home')
      }
    >
      <IconButton
        aria-label='Home'
        onClick={handleClick}
        color='inherit'
        className={className}
      >
        <Badge
          badgeContent={<></>}
          invisible={count === 0 || undefined}
          color='error'
          overlap='rectangle'
          variant='dot'
        >
          <HomeIcon className={classes.showIcon} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
});

export default HomeButton;
