import {
  Constants,
  documentTypeCodeToResource,
  DxTheme,
  Metadata,
  OrderSubType,
  ProcessStatus,
  sendGAEvent,
  ToolbarBackButton,
} from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import {
  linkToRecord,
  useDataProvider,
  useNotify,
  usePermissionsOptimized,
  useRedirect,
} from 'ra-core';
import { useEffect, useMemo, useState } from 'react';
import {
  Loading,
  Responsive,
  ShowView,
  SimpleShowLayout,
  Toolbar,
  useGetIdentity,
} from 'react-admin';
import { GA_EVENTS } from '../../GAUtils';
import {
  DataHelpers,
  DespatchAdviceService,
  DespatchAdviceServiceCreator,
  DocumentServiceFactory,
  InvoiceService,
  InvoiceServiceCreator,
  OrderService,
} from '../../services';
import { EditWebDocumentButton } from '../../shared';
import DocumentPreviewShowActions from '../../shared/DocumentPreviewShowActions';
import ToolbarButton from '../../shared/ToolbarButton';
import { P2pData } from '../../shared/types';
import { OrderPreview } from './';
import OrderActions from './OrderActions';

const useStyles = makeStyles((theme: DxTheme) => ({
  toolbar: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: 0,
  },
}));

const OrderShowView = (props) => {
  const { controllerProps } = props;

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { permissions } = usePermissionsOptimized();
  const notify = useNotify();

  const properties = controllerProps?.record?.properties;

  const [loading, setLoading] = useState<boolean>(false);
  const [converting, setConverting] = useState<boolean>(false);
  const [canConvertToDesadv, setCanConvertToDesadv] = useState(false);
  const [canConvertToInvoice, setCanConvertToInvoice] = useState(false);
  const [resource, setResource] = useState<any>(undefined);
  const [called, setCalled] = useState(false);
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const invoiceService = useMemo(
    () =>
      DocumentServiceFactory.create(
        InvoiceServiceCreator,
        dataProvider
      ) as InvoiceService,
    [dataProvider]
  );

  const desadvService = useMemo(
    () =>
      DocumentServiceFactory.create(
        DespatchAdviceServiceCreator,
        dataProvider
      ) as DespatchAdviceService,
    [dataProvider]
  );

  const allowEdit =
    OrderService.canCreate(permissions) &&
    DataHelpers.getProcessStatus(controllerProps.record) ===
      ProcessStatus.DRAFT;

  useEffect(() => {
    const getConversionsAvailability = async () => {
      const documentRecipientId = OrderService.getRecipientId(properties);

      if (!documentRecipientId) {
        throw new Error('Document recipientId should not be empty!');
      }

      setLoading(true);

      try {
        const canCreateInvoice = InvoiceService.canCreate(permissions);
        if (canCreateInvoice) {
          const templateRights = await invoiceService.getInvoiceTemplateRights(
            documentRecipientId,
            OrderService.getRegulatorExtraDetails(properties)
          );
          const invoiceCreationPolicy =
            await invoiceService.fetchCreationPolicy(
              Constants.RESOURCE_INVOICE,
              documentRecipientId
            );

          // OrderTypeCode must not be an initial or update order to convert.
          const canCreateFromOrder =
            get(properties, Metadata.documentSubTypeCode) !==
              OrderSubType.InitialOrder &&
            get(properties, Metadata.documentSubTypeCode) !==
              OrderSubType.NotModifiedUpdatedOrder &&
            get(properties, Metadata.documentSubTypeCode) !==
              OrderSubType.UpdatedOrder;

          setCanConvertToInvoice(
            templateRights.cloneOrCreate &&
              invoiceCreationPolicy.conversion &&
              canCreateFromOrder
          );
        }

        const canCreateDesadv = DespatchAdviceService.canCreate(permissions);
        if (canCreateDesadv) {
          const hasTemplate = await desadvService.hasDesadvTemplate(
            documentRecipientId
          );
          const desadvCreationPolicy = await desadvService.fetchCreationPolicy(
            Constants.RESOURCE_DESPATCH_ADVICE,
            documentRecipientId
          );
          setCanConvertToDesadv(hasTemplate && desadvCreationPolicy.conversion);
        }

        setResource(
          documentTypeCodeToResource(get(properties, Metadata.documentTypeCode))
        );
        setLoading(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setLoading(false);
      }
    };

    if (properties && dataProvider && permissions) {
      if (!called) {
        setCalled(true);
        getConversionsAvailability();
      }
    }
  }, [
    properties,
    dataProvider,
    permissions,
    called,
    invoiceService,
    desadvService,
  ]);

  enum ActionType {
    CONVERT_INVOICE,
    CONVERT_DESADV,
  }

  const onClickDoAction = (record: P2pData, actionType: ActionType) => {
    return async (e) => {
      e.preventDefault();

      if (!record.id) {
        throw new Error('record id cannot be null');
      }

      setConverting(true);
      notify('ra.page.loading');

      let editFormResource = '';

      try {
        let result: P2pData;
        switch (actionType) {
          case ActionType.CONVERT_INVOICE:
            sendGAEvent(
              GA_EVENTS.categories.DOCUMENT_CONVERT.name,
              GA_EVENTS.categories.DOCUMENT_CONVERT.actions.ORDER_TO_INVOICE,
              account?.company?.cmsRootDir,
              record
                ? `Order DXUID: ${record.properties[Metadata.dxuid]}`
                : undefined
            );

            result = await invoiceService.createFromOrder(record.id);
            editFormResource = Constants.RESOURCE_WEBINVOICE;
            break;
          case ActionType.CONVERT_DESADV:
            sendGAEvent(
              GA_EVENTS.categories.DOCUMENT_CONVERT.name,
              GA_EVENTS.categories.DOCUMENT_CONVERT.actions.ORDER_TO_DESADV,
              account?.company?.cmsRootDir,
              record
                ? `Order DXUID: ${record.properties[Metadata.dxuid]}`
                : undefined
            );

            result = await desadvService.createFromOrder(record.id);
            editFormResource = Constants.RESOURCE_WEBDESPATCH_ADVICE;
            break;
          default:
            throw new Error(`Invalid value for actionType: ${actionType}`);
        }
        setConverting(false);
        redirect(`/${linkToRecord(editFormResource, result.id)}`);
      } catch (e) {
        setConverting(false);
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };
  };

  return (
    <ShowView
      {...controllerProps}
      title='dxMessages.orders.title'
      actions={<DocumentPreviewShowActions />}
    >
      <Responsive
        medium={
          <SimpleShowLayout>
            {loading ? (
              <Loading />
            ) : (
              <OrderPreview
                record={controllerProps.record}
                resource={resource}
                basePath={controllerProps.basePath}
              />
            )}
            <Toolbar classes={{ toolbar: classes.toolbar }}>
              <ToolbarBackButton />
              {canConvertToInvoice && (
                <ToolbarButton
                  disabled={loading || converting}
                  loading={loading || converting}
                  label={'dxMessages.buttons.convertToInvoice'}
                  onClick={onClickDoAction(
                    controllerProps.record,
                    ActionType.CONVERT_INVOICE
                  )}
                />
              )}
              {canConvertToDesadv && (
                <ToolbarButton
                  disabled={loading || converting}
                  loading={loading || converting}
                  label={'dxMessages.buttons.convertToDespatchAdvice'}
                  onClick={onClickDoAction(
                    controllerProps.record,
                    ActionType.CONVERT_DESADV
                  )}
                />
              )}
              {allowEdit && (
                <EditWebDocumentButton
                  label={'dxMessages.buttons.editOrder'}
                  {...props}
                />
              )}
              <OrderActions
                record={controllerProps.record}
                isMenuItem={false}
              />
            </Toolbar>
          </SimpleShowLayout>
        }
      />
    </ShowView>
  );
};

export default OrderShowView;
