import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DescriptionOutlined, HighlightOffOutlined } from '@material-ui/icons';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { usePreferences } from '@react-admin/ra-preferences';
import MaterialTable, { MTablePagination } from 'material-table';
import moment from 'moment';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  SimpleForm,
  fetchUtils,
  useGetIdentity,
  usePermissions,
  useRedirect,
  useTranslate,
} from 'react-admin';
import ReactCardFlip from 'react-card-flip';
import { MultiSelectInput } from '../../../../../components';
import { ProcessStatus } from '../../../../../constants';
import { UserRoles } from '../../../../../security';
import { DxTheme } from '../../../../../types';
import {
  GA_EVENTS,
  sendGAEvent,
  useSetupUsage,
  widthPresets,
} from '../../../../../utils';
import { SetupUsage } from '../../../../../utils/useSetupUsage';
import { IWidgetContent } from '../../../Widget';
import cartouche from '../../cartouche.png';
import cartoucheDark from '../../cartoucheDark.png';
import { displayDataDemo } from '../demo/DisplayDocumentDemo';
import { DocumentType } from './DocumentCountPerDayAndStatus';

const useStyles = makeStyles((theme: DxTheme) => ({
  main: {
    paddingLeft: 15,
    paddingTop: 8,
    background: `url(${
      theme.palette.type === 'dark' ? cartoucheDark : cartouche
    }) no-repeat`,
    '& .icon': {
      color: '#dc2440',
    },
    backgroundSize: '500px 80px',
  },
  configure: {
    position: 'fixed',
    left: theme.spacing(0),
    bottom: theme.spacing(0),
  },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
    overflowY: 'auto',
    overflowX: 'auto',
  },
  help: {
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(0),
  },
  content: {
    maxHeight: '36vh',
    backgroundColor: 'transparent',
  },
  menuPaper: {
    maxHeight: 350,
  },
}));

const statusesChoices = [
  ProcessStatus.ACCEPTED_BY_RECIPIENT,
  ProcessStatus.CONTENT_ERROR,
  ProcessStatus.FORMAT_ERROR,
  ProcessStatus.NO_CREDIT,
  ProcessStatus.PENDING_ENRICHMENTS,
  ProcessStatus.PENDING_CORRECTION,
  ProcessStatus.RECEIVED_BY_DX,
  ProcessStatus.PAID_BY_RECIPIENT,
  ProcessStatus.PARTIALLY_PAID_BY_RECIPIENT,
  ProcessStatus.RECEIVED_BY_RECIPIENT,
  ProcessStatus.REJECTED_BY_RECIPIENT,
  ProcessStatus.RECEIVED_BY_BSP,
  ProcessStatus.CONDITIONALLY_ACCEPTED,
  ProcessStatus.RECEIVED_AND_VALIDATED_BY_BSP,
  ProcessStatus.REJECTED_BY_BSP,
  ProcessStatus.SENT,
  ProcessStatus.UNDER_QUERY,
  ProcessStatus.IN_PROCESS,
  ProcessStatus.WAITING_FOR_RECIPIENT,
  ProcessStatus.MATCHING_IN_PROGRESS,
  ProcessStatus.MATCHING_ERROR,
  ProcessStatus.ALLOCATION_IN_PROGRESS,
  ProcessStatus.REJECTED_BY_SUPPLIER,
];

export const convertV2ToV3Status = (st) => {
  switch (st) {
    case ProcessStatus.ACCEPTED_BY_CUSTOMER:
      return ProcessStatus.ACCEPTED_BY_RECIPIENT;
    case ProcessStatus.ACCOUNT_CHECKED:
    case ProcessStatus.ENRICHED:
    case ProcessStatus.LOCALLY_DELIVERED:
    case ProcessStatus.NEW:
    case ProcessStatus.NORMALIZED:
    case ProcessStatus.VALIDATED:
      return ProcessStatus.RECEIVED_BY_DX;
    case ProcessStatus.DELIVERED:
    case ProcessStatus.PENDING_SCHEDULED_DELIVERY:
      return ProcessStatus.SENT;
    case ProcessStatus.DELIVERED_FROM_CONTENT_ERROR:
      return ProcessStatus.CONTENT_ERROR;
    case ProcessStatus.DELIVERED_FROM_FORMAT_ERROR:
      return ProcessStatus.FORMAT_ERROR;
    case ProcessStatus.PENDING_RECADV:
      return ProcessStatus.PENDING_ENRICHMENTS;
    case ProcessStatus.PENDING_TO_CUSTOMER:
      return ProcessStatus.WAITING_FOR_RECIPIENT;
    case ProcessStatus.RECEIVED_BY_CUSTOMER:
      return ProcessStatus.RECEIVED_BY_RECIPIENT;
    case ProcessStatus.REJECTED_BY_CUSTOMER:
      return ProcessStatus.REJECTED_BY_RECIPIENT;
    case ProcessStatus.SUSPENDED:
      return ProcessStatus.NO_CREDIT;
    default:
      return st;
  }
};

const DisplayDocuments: FC<IWidgetContent> = ({
  onTheShelves,
  openConfiguration,
  userPreferencesRootKey,
}) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const [help, setHelp] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const setupUsage = useSetupUsage();
  const redirect = useRedirect();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const [recipient, setRecipient]: any = usePreferences<any>(
    `${userPreferencesRootKey}.recipient`,
    ''
  );
  const [issuer, setIssuer]: any = usePreferences<any>(
    `${userPreferencesRootKey}.issuer`,
    ''
  );
  const [status, setStatus]: any = usePreferences<any>(
    `${userPreferencesRootKey}.status`,
    []
  );
  const [documentType, setDocumentType]: any = usePreferences<any>(
    `${userPreferencesRootKey}.documentType`,
    [DocumentType.INVOICE]
  );
  const [loading, setLoading]: any = useState(true);
  const [data, setData]: any = useState([]);

  // display document type choices depending on permissions
  const filterPermissions = () => {
    if (permissions !== undefined) {
      let permissionList: string[] = [];
      let documentTypes: any = [];
      if (permissions[UserRoles.DXPURCHASE_PRODUCT]) {
        permissionList = permissions[UserRoles.DXPURCHASE_PRODUCT];
      }

      if (permissionList.includes(UserRoles.VIEW_INVOICE)) {
        documentTypes.push({
          id: DocumentType.INVOICE,
          label: DocumentType.INVOICE,
        });
      }
      if (permissionList.includes(UserRoles.VIEW_ORDER)) {
        documentTypes.push({
          id: DocumentType.ORDER,
          label: DocumentType.ORDER,
        });
      }
      if (permissionList.includes(UserRoles.VIEW_RECEIPT_ADVICE)) {
        documentTypes.push({
          id: DocumentType.DESADV,
          label: DocumentType.DESADV,
        });
      }
      if (permissionList.includes(UserRoles.VIEW_DESPATCH_ADVICE)) {
        documentTypes.push({
          id: DocumentType.RECADV,
          label: DocumentType.RECADV,
        });
      }
      return documentTypes;
    }
  };

  const customizeForRequest: any = (docType) => {
    switch (docType) {
      case DocumentType.DESADV:
        return 'despatchAdvice';
      default:
        return docType.toLowerCase();
    }
  };

  // we need exactly the names from document list filter
  const documentTypeFilters = (document) => {
    switch (document) {
      case 'INVOICE':
        return 'INVOIC';
      case 'ORDER':
        return 'ORDERS';
      default:
        return document;
    }
  };
  const [recipientChoices, setRecipientChoices]: any = useState([]);
  const [issuerChoices, setIssuerChoices]: any = useState([]);
  const [isHovered, setIsHovered] = useState(null);

  const range = encodeURIComponent('[0,99]');
  const sort = encodeURIComponent('["edm:issueDate","DESC"]');
  const I18N_KEY = 'dxMessages.dashboard.widgets.DisplayDocuments';
  const widgetTitle = translate(`${I18N_KEY}.title`);
  const [title, setTitle]: [string, Dispatch<SetStateAction<string>>] =
    usePreferences<any>(`${userPreferencesRootKey}.widgetTitle`, widgetTitle);

  useEffect(() => {
    const newRecipientChoices = new Set(recipientChoices);
    const newIssuerChoices = new Set(issuerChoices);

    data.forEach((d) => {
      if (d.properties['edm:recipientName'] !== undefined) {
        newRecipientChoices.add(d.properties['edm:recipientName']);
      }
      if (d.properties['edm:issuerName'] !== undefined) {
        newIssuerChoices.add(d.properties['edm:issuerName']);
      }
    });

    if (
      newRecipientChoices.size !== recipientChoices.length ||
      newIssuerChoices.size !== issuerChoices.length
    ) {
      setRecipientChoices(Array.from(newRecipientChoices));
      setIssuerChoices(Array.from(newIssuerChoices));
    }
  }, [data, issuerChoices, recipientChoices]);

  const handleDisplayRecipient = (ev) => {
    setLoading(true);
    setRecipient(ev.target.value);
  };
  const handleDisplayIssuer = (ev) => {
    setLoading(true);
    setIssuer(ev.target.value);
  };
  const handleDisplayStatus = (ev) => {
    setLoading(true);
    setStatus(ev);
  };
  const handleDisplayDocumentType = (ev) => {
    if (ev !== null && ev?.length !== 0) {
      setLoading(true);
      setDocumentType(ev);
    }
  };

  // fetch the first 100 rows per document type
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const fetchData = async () => {
      const testFilters: any = [];
      testFilters.push(`"edm:processDocumentFormatType":["ORIGINAL","TARGET"]`);
      if (issuer && issuer.length !== 0) {
        testFilters.push(`"edm:issuerName":"${issuer}"`);
      }
      if (recipient && recipient.length !== 0) {
        testFilters.push(`"edm:recipientName":"${recipient}"`);
      }
      if (status && status.length !== 0) {
        testFilters.push(
          `"edm:processStatus":[${status.map(
            (st) => `"${convertV2ToV3Status(st)}"`
          )}]`
        );
      }
      if (documentType && documentType.length !== 0) {
        const customFilterNames = documentType.map((dt) =>
          documentTypeFilters(dt)
        );
        testFilters.push(
          `"edm:documentTypeCode":[${customFilterNames.map(
            (cfn) => `"${cfn}"`
          )}]`
        );
      }
      const testFilter = encodeURIComponent(`{${testFilters.join(',')}}`);

      const promises = documentType.map(async (type) => {
        const response = await fetchUtils.fetchJson(
          `/dxportal/${customizeForRequest(
            type
          )}?filter=${testFilter}&range=${range}&sort=${sort}`,
          {
            method: 'GET',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          }
        );
        return response.json;
      });

      const results = await Promise.all(promises);
      const combinedData: any = results.reduce((acc: any, result: any) => {
        return [...acc, ...result];
      }, []);
      combinedData.sort((a: any, b: any) => {
        const dateA: any = new Date(b.properties['edm:issueDate']);
        const dateB: any = new Date(a.properties['edm:issueDate']);
        return dateA - dateB;
      });

      setData(combinedData);
      setLoading(false);
    };
    const throttledFetchData = async () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        fetchData();
      }, 1500);
    };

    if (documentType && documentType.length !== 0 && !onTheShelves) {
      throttledFetchData();
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType?.length, issuer, onTheShelves, recipient, status?.length]);

  // based on the current selection, it will redirect us to the list of documents
  const redirectList = () => {
    const origin = window.location.origin;
    const testFilters: any = [];

    if (issuer && issuer.length !== 0) {
      testFilters.push(`"edm:issuerName":"${issuer}"`);
    }
    if (recipient && recipient.length !== 0) {
      testFilters.push(`"edm:recipientName":"${recipient}"`);
    }
    if (status && status.length !== 0) {
      testFilters.push(
        `"edm:processStatus":[${status.map(
          (st) => `"${convertV2ToV3Status(st)}"`
        )}]`
      );
    }
    if (documentType && documentType.length !== 0) {
      const customFilterNames = documentType.map((dt) =>
        documentTypeFilters(dt)
      );
      testFilters.push(
        `"edm:documentTypeCode":[${customFilterNames.map((cfn) => `"${cfn}"`)}]`
      );
    }

    const testFilter = encodeURIComponent(`{${testFilters.join(',')}}`);
    const sort = 'edm:issueDate';
    if (setupUsage === SetupUsage.PRODUCTION || setupUsage === SetupUsage.UAT) {
      if (status !== '') {
        window.location.href = `${origin}/dxportal/#/documents?displayedFilters={"edm:processStatus":true}&filter=${testFilter}&sort=${sort}&order=DESC`;
      } else {
        window.location.href = `${origin}/dxportal/#/documents?filter=${testFilter}&sort=${sort}&order=DESC`;
      }
    } else {
      if (status !== '') {
        redirect(
          `documents?displayedFilters={"edm:processStatus":true}&filter=${testFilter}&sort=${sort}&order=DESC`
        );
      } else {
        redirect(`documents?filter=${testFilter}&sort=${sort}&order=DESC`);
      }
    }
  };

  const labeledStatuses: any = statusesChoices
    .map((data) => {
      return {
        id: data,
        label: translate(`dxMessages.processStatus.${data}`),
      };
    })
    .sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();

      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });

  const previewRedirect = (rowData) => {
    switch (rowData.nodeType) {
      case 'edm:invoice':
        redirect(
          `${customizeForRequest(DocumentType.INVOICE)}/${rowData.id}/show`
        );
        break;
      case 'edm:order':
        redirect(
          `${customizeForRequest(DocumentType.ORDER)}/${rowData.id}/show`
        );
        break;
      case 'edm:recadv':
        redirect(
          `${customizeForRequest(DocumentType.RECADV)}/${rowData.id}/show`
        );
        break;
      case 'edm:despatchAdvice':
        redirect(
          `${customizeForRequest(DocumentType.DESADV)}/${rowData.id}/show`
        );
        break;
    }
  };

  return (
    <ReactCardFlip isFlipped={help} flipDirection='vertical'>
      <Card
        classes={{
          root: classes.card,
        }}
      >
        <CardHeader
          title={
            <div className={classes.main}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '50px' }}>
                  <Grid item xs={3}>
                    <Box className='icon'>
                      {
                        <DescriptionOutlined
                          style={{ width: '47px', height: '47px' }}
                        />
                      }
                    </Box>
                  </Grid>
                </div>
                <div style={{ display: 'block' }}>
                  {openConfiguration === false ? (
                    <Typography
                      noWrap
                      align='right'
                      style={{ fontSize: '26px', marginBottom: '10px' }}
                    >
                      {title}
                    </Typography>
                  ) : onTheShelves ? (
                    <Typography
                      noWrap
                      align='right'
                      style={{ fontSize: '26px', marginBottom: '10px' }}
                    >
                      {widgetTitle}
                    </Typography>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                      }}
                    >
                      <TextField
                        id='newName'
                        variant='standard'
                        label={translate(
                          'dxMessages.dashboard.widgets.WidgetTitle.name'
                        )}
                        value={title}
                        onChange={(ev) => setTitle(ev.target.value)}
                        onDoubleClick={(ev: any) => ev.target.select()}
                        style={{ width: '400px' }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      flexWrap: 'wrap',
                      gap: '30px',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <FormControl
                      style={{ marginRight: '15px' }}
                      variant='outlined'
                    >
                      <InputLabel htmlFor='recipient'>
                        {translate(
                          'dxMessages.dashboard.widgets.DisplayDocuments.recipient'
                        )}
                      </InputLabel>
                      <Input
                        id='recipient'
                        type='text'
                        value={recipient}
                        disabled={openConfiguration}
                        onChange={handleDisplayRecipient}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={() => {
                                setLoading(true);
                                setRecipient('');
                              }}
                              edge='end'
                            >
                              <HighlightOffOutlined />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl
                      style={{ marginRight: '15px' }}
                      variant='outlined'
                    >
                      <InputLabel htmlFor='issuer'>
                        {translate(
                          'dxMessages.dashboard.widgets.DisplayDocuments.issuer'
                        )}
                      </InputLabel>
                      <Input
                        id='issuer'
                        type='text'
                        value={issuer}
                        disabled={openConfiguration}
                        onChange={handleDisplayIssuer}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={() => {
                                setLoading(true);
                                setIssuer('');
                              }}
                              edge='end'
                            >
                              <HighlightOffOutlined />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <SimpleForm
                      toolbar={<></>}
                      style={{
                        marginTop: '-12px',
                        marginBottom: '-20px',
                        marginRight: onTheShelves ? '-8px' : '15px',
                      }}
                    >
                      <MultiSelectInput
                        label={translate(
                          'dxMessages.dashboard.widgets.DisplayDocuments.status'
                        )}
                        source='status'
                        sortLabels={true}
                        width={widthPresets.medium}
                        onChange={handleDisplayStatus}
                        options={labeledStatuses}
                        defaultValue={convertV2ToV3Status(status)}
                        disabled={openConfiguration}
                      />
                    </SimpleForm>
                    <SimpleForm
                      toolbar={<></>}
                      style={{
                        marginTop: '-12px',
                        marginBottom: '-20px',
                        marginRight: onTheShelves ? '-15px' : '0px',
                      }}
                    >
                      <MultiSelectInput
                        label={translate(
                          'dxMessages.dashboard.widgets.DisplayDocuments.document'
                        )}
                        source='document'
                        sortLabels={true}
                        width={widthPresets.medium}
                        onChange={handleDisplayDocumentType}
                        options={
                          filterPermissions() !== undefined
                            ? filterPermissions()
                            : [{ id: '', label: '' }]
                        }
                        defaultValue={documentType}
                        disabled={openConfiguration}
                        hideButton={true}
                      />
                    </SimpleForm>
                  </div>
                </div>
              </div>
            </div>
          }
        />
        <CardContent className={classes.content}>
          {/* MUST set a height for the diagram. */}
          {loading === true && !onTheShelves ? (
            <div style={{ height: '300px' }}>
              <LinearProgress />
            </div>
          ) : (
            <div
              style={{
                display: 'block',
                overflowX: 'auto',
                overflowY: 'auto',
                backgroundColor: 'transparent',
              }}
            >
              <div>
                <MaterialTable
                  title=''
                  columns={[
                    {
                      title: 'ID',
                      field: 'properties.edm:documentId',
                      headerStyle: {
                        backgroundColor: '#f6f5f8',
                      },
                      cellStyle: {
                        color: '#757ce8',
                      },
                      render: (rowData) => (
                        <div
                          onClick={() => {
                            sendGAEvent(
                              GA_EVENTS.categories.DASHBOARD.name,
                              GA_EVENTS.categories.DASHBOARD.actions
                                .SHOW_DOCUMENT_PREVIEW,
                              account?.company?.cmsRootDir
                            );
                            previewRedirect(rowData);
                          }}
                          onMouseEnter={() => setIsHovered(rowData.id)}
                          onMouseLeave={() => setIsHovered(null)}
                          style={{
                            cursor: onTheShelves ? 'inherit' : 'pointer',
                            backgroundColor: !onTheShelves
                              ? isHovered === rowData.id
                                ? '#e0e0e0'
                                : 'transparent'
                              : 'inherit',
                          }}
                        >
                          {rowData.properties['edm:documentId']}
                        </div>
                      ),
                    },
                    {
                      title: translate(
                        'dxMessages.dashboard.widgets.DisplayDocuments.document'
                      ),
                      field: 'properties.edm:documentTypeCode',
                      render: (rowData: any) => {
                        return translate(
                          `dxMessages.dashboard.widgets.DisplayDocuments.documentType.${rowData.properties['edm:documentTypeCode']}`
                        );
                      },
                      headerStyle: {
                        backgroundColor: '#f6f5f8',
                      },
                    },
                    {
                      title: translate(
                        'dxMessages.dashboard.widgets.DisplayDocuments.issuer'
                      ),
                      field: 'properties.edm:issuerName',
                      headerStyle: {
                        backgroundColor: '#f6f5f8',
                      },
                    },
                    {
                      title: translate(
                        'dxMessages.dashboard.widgets.DisplayDocuments.recipient'
                      ),
                      field: 'properties.edm:recipientName',
                      headerStyle: {
                        backgroundColor: '#f6f5f8',
                      },
                    },
                    {
                      title: translate(
                        'dxMessages.dashboard.widgets.DisplayDocuments.issueDate'
                      ),
                      field: 'properties.edm:issueDate',
                      render: (rowData: any) => {
                        return moment(
                          rowData.properties['edm:issueDate']
                        ).format('L');
                      },
                      headerStyle: {
                        backgroundColor: '#f6f5f8',
                      },
                    },
                    {
                      title: translate(
                        'dxMessages.dashboard.widgets.DisplayDocuments.status'
                      ),
                      field: 'properties.edm:processStatus',
                      render: (rowData: any) => {
                        return translate(
                          `dxMessages.processStatus.${rowData.properties['edm:processStatus']}`
                        );
                      },
                      headerStyle: {
                        backgroundColor: '#f6f5f8',
                      },
                    },
                  ]}
                  data={onTheShelves ? displayDataDemo : data}
                  options={{
                    toolbar: false,
                    rowStyle: {
                      backgroundColor: 'transparent',
                    },
                    pageSize: 5,
                    pageSizeOptions: [5],
                    sorting: !openConfiguration,
                    padding: 'dense',
                  }}
                  components={{
                    Pagination: (props) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          variant='text'
                          onClick={redirectList}
                          disabled={openConfiguration}
                        >
                          {translate(
                            'dxMessages.dashboard.widgets.DisplayDocuments.button'
                          )}
                        </Button>
                        <Typography align='center'>
                          {translate(`${I18N_KEY}.limitMessage`)}
                        </Typography>
                        <div>
                          <MTablePagination {...props} />
                        </div>
                      </div>
                    ),
                  }}
                  style={{ backgroundColor: 'transparent' }}
                />
              </div>
            </div>
          )}
        </CardContent>
      </Card>
      <Card style={{ backgroundColor: theme.palette.grey[50] }}>
        <div className={classes.help}>
          <IconButton onClick={() => setHelp(!help)} size='small'>
            <KeyboardReturnIcon />
          </IconButton>
        </div>
        <Box m={1} mt={2} p={1}></Box>
      </Card>
    </ReactCardFlip>
  );
};

export default DisplayDocuments;
