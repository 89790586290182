import { Constants } from '@dx-ui/dx-common/src';
import { Button, Grid } from '@material-ui/core';
import {
  EditableDatagrid,
  RowForm,
  RowFormProps,
} from '@react-admin/ra-editable-datagrid';
import {
  AutocompleteInput,
  DateField,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  NumberInput,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';

const CreditForm = (props: Omit<RowFormProps, 'children'>) => {
  const refresh = useRefresh();
  const handleRefresh = () => {
    refresh();
  };

  return (
    <RowForm {...props} onSuccess={handleRefresh}>
      <NumberInput source='creditvalue' validate={required()} />
    </RowForm>
  );
};

export const ProductUsageEdit: any = ({ setEdit, ...props }) => {
  const notify = useNotify();

  const redirect = useRedirect();
  const onSuccess = () => {
    notify('resources.address.edit.notifications.updated');
    if (setEdit !== undefined) {
      setEdit(false);
    } else {
      redirect('list', props.basePath);
    }
  };
  const { id } = props;
  // const SanitizeGridPropsComponent = (props) => {
  //   const { basePath, record, resource, children, ...rest } = props;
  //   return <Grid {...rest}>{children}</Grid>;
  // };

  const CustomEditToolbar = (props) => (
    <Toolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SaveButton
          {...props}
          style={{ display: 'flex', alignItems: 'center' }}
        />
        <CancelButton {...props} />
      </div>
      <DeleteWithConfirmButton onSuccess={() => setEdit(false)} />
    </Toolbar>
  );

  const CancelButton = (props: any) => {
    const translate = useTranslate();
    return (
      <Button
        onClick={() => {
          if (setEdit !== undefined) {
            setEdit(false);
          }
          redirect(
            '/' +
              Constants.RESOURCE_ACCOUNT +
              '/' +
              props.record.idaccounts +
              '/show/4'
          );
        }}
        variant='contained'
      >
        {translate('ra.action.cancel')}
      </Button>
    );
  };

  return (
    <Edit
      {...props}
      id={props.props ? props.props.record.id : id}
      onSuccess={onSuccess}
      undoable={false}
      resource={Constants.RESOURCE_PRODUCTUSAGE}
    >
      <SimpleForm toolbar={<CustomEditToolbar />}>
        <Grid container direction='row' style={{ width: '100%' }}>
          <Grid item container direction='column' xs={8}>
            <Grid item>
              <ReferenceInput
                label='resources.accounts.create.wizard.steps.companySelection.company'
                source='idaccounts'
                reference='management/company'
                validate={required()}
                filterToQuery={(searchText) => ({
                  accountname: [searchText],
                })}
                sort={{ field: 'accountname', order: 'ASC' }}
              >
                <AutocompleteInput optionText='accountname' disabled />
              </ReferenceInput>
            </Grid>
            <Grid item>
              <ReferenceInput
                label={'dxMessages.companyManagement.productUsage.product'}
                source='iddxproduct'
                reference='management/dxproduct'
                validate={required()}
                filterToQuery={(searchText) => ({
                  description: [searchText],
                })}
                sort={{ field: 'description', order: 'ASC' }}
              >
                <AutocompleteInput optionText='description' disabled />
              </ReferenceInput>
            </Grid>
            <Grid item>
              <TextInput
                label={'dxMessages.companyManagement.productUsage.totalusage'}
                source='totalcount'
                disabled
              />
            </Grid>
            <Grid item>
              <TextInput
                label={'dxMessages.companyManagement.productUsage.totalcredit'}
                source='totalcredit'
                disabled
              />
            </Grid>
            <Grid item>
              <TextInput
                label={'dxMessages.companyManagement.productUsage.thresold'}
                source='thresold'
              />
            </Grid>
            <Grid item>
              <DateTimeInput
                label={'dxMessages.companyManagement.audit.tsChange'}
                source='tsChange'
                disabled
              />
            </Grid>
            <Grid item>
              <TextInput
                label={'dxMessages.companyManagement.audit.userIdChange'}
                source='userIdChange'
                disabled
              />
            </Grid>
          </Grid>
          <Grid item container direction='column' xs={4}>
            <Grid item>
              <ReferenceManyField
                filter={{
                  idproductusage: props.props ? props.props.record.id : id,
                }}
                reference={Constants.RESOURCE_CREDIT}
                target='idproductusage'
              >
                <EditableDatagrid
                  mutationMode='undoable'
                  createForm={
                    <CreditForm
                      initialValues={{
                        idproductusage: props.props
                          ? props.props.record.id
                          : id,
                      }}
                    />
                  }
                  editForm={<CreditForm />}
                >
                  <TextField source='creditvalue' />
                  <DateField source='tsChange' showTime />
                  <TextField source='userIdChange' />
                </EditableDatagrid>
              </ReferenceManyField>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default ProductUsageEdit;
