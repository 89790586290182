import { TimeInput } from '@dx-ui/dx-common';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a Time input
 */
const TimeInputField = (props: FieldComponentProps) => {
  const sanitizeProps: any = ({
    loading,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;
  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <TimeInput {...sanitizeProps(fieldProps)} resettable ampm={false} />
      )}
    </FieldWrapper>
  );
};

export default TimeInputField;
