import { Chip, Grid, Typography, useTheme } from '@material-ui/core';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import Remove from '@material-ui/icons/RemoveCircle';
import Report from '@material-ui/icons/Report';
import { cloneDeep } from 'lodash';
import MaterialTable from 'material-table';
import React, { useEffect } from 'react';
import { TextInput, Title, useTranslate } from 'react-admin';
import { ColumnConstants } from '../common/Constants';
import OrderSummaryField from '../common/OrderSummaryField';
import ColumnRenderer from '../common/util/ColumnRenderer';
import { diff } from '../common/util/JsonComparator';
import {
  LineStatusCode,
  MT_LOCALIZATION,
  excludeColumn,
  formatUblClient,
} from '../common/util/utils';
import InnerClientTable from './InnerClientTable';

const OrderNegotiationClientCockpit = (props) => {
  const { record } = props;
  const excludedColumns =
    record?.flow?.variables?.negotiationDefinition?.excludedColumns || [];
  const translate = useTranslate();
  const theme = useTheme();
  const [rows, setRows] = React.useState<any>();
  const [updatedOrder, setUpdatedOrder] = React.useState<any>();
  const [comparedOrderLines, setComparedOrderLines] = React.useState<any>();

  useEffect(() => {
    // Format dates for both, the serialization adds the time
    formatUblClient(record?.flow?.variables?.order);
    formatUblClient(record?.flow?.variables?.updatedOrder);
    const orderLines = record?.flow?.variables?.order?.OrderLine || [];
    const updatedLines = record?.flow?.variables?.updatedOrder?.OrderLine;
    setRows(orderLines);
    // Sort the updateOrder lines in order to have the same order as the original order ones
    // and mark as null the deleted ones
    const updatedOrderToCheck: any[] = [];
    orderLines.forEach((l) => {
      const uLine = updatedLines.find(
        (u) => u.LineItem?.ID?.value === l.LineItem?.ID?.value
      );

      if (
        uLine.LineItem?.LineStatusCode === LineStatusCode.NOT_ACCEPTED ||
        uLine.LineItem?.LineStatusCode?.value === LineStatusCode.NOT_ACCEPTED
      ) {
        updatedOrderToCheck.push(null);
      } else {
        updatedOrderToCheck.push(uLine);
      }
    });
    setUpdatedOrder(updatedOrderToCheck);
    setComparedOrderLines(
      diff(cloneDeep(orderLines), cloneDeep(updatedOrderToCheck))
    );
  }, [record]);

  if (!record) return null;

  const cellStyle = (rowData: any) => {
    return {
      pointerEvents:
        comparedOrderLines[rowData.tableData.id] === null ? 'none' : 'inherit',
    };
  };
  return (
    <>
      <Title
        title={`Accept Order #${record?.flow?.variables?.order?.ID?.value}`}
      />
      <Grid container direction='column'>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant='caption'>
              {translate('dxMessages.task.reginamaria.key.infoClient')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                float: 'left',
                display: 'flex',
                paddingBottom: '2em',
              }}
            >
              <Chip label='Key' variant='outlined' size='small' />
              <Chip
                label={translate(
                  'dxMessages.task.reginamaria.key.originalValue'
                )}
                variant='outlined'
                size='small'
                style={{ color: 'red' }}
              />
              <Chip
                label={translate(
                  'dxMessages.task.reginamaria.key.proposedValue'
                )}
                variant='outlined'
                size='small'
                style={{ color: 'green' }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <OrderSummaryField
              order={record?.flow?.variables?.order}
              icon={<AddShoppingCart />}
            />
          </Grid>
          <Grid item xs={10}>
            <MaterialTable
              title={translate('dxMessages.task.reginamaria.table.orderTitle')}
              columns={[
                {
                  title: translate('dxMessages.task.reginamaria.table.itemId'),
                  field: 'LineItem.ID.value',
                  editable: 'never',
                  filtering: false,
                  hidden: excludeColumn(
                    excludedColumns,
                    ColumnConstants.ORDER_ITEM_ID
                  ),
                  cellStyle(data: any[], rowData: any): any {
                    return cellStyle(rowData);
                  },
                },
                {
                  title: translate(
                    'dxMessages.task.reginamaria.table.itemDesc'
                  ),
                  field: 'LineItem.Item.Description[0].value',
                  editable: 'never',
                  filtering: false,
                  hidden: excludeColumn(
                    excludedColumns,
                    ColumnConstants.ORDER_ITEM_DESC
                  ),
                },
                {
                  title: translate(
                    'dxMessages.task.reginamaria.table.buyerIdentification'
                  ),
                  field: 'LineItem.Item.BuyersItemIdentification.ID.value',
                  editable: 'never',
                  filtering: false,
                  hidden: excludeColumn(
                    excludedColumns,
                    ColumnConstants.ORDER_BUYER_ID
                  ),
                  cellStyle(data: any[], rowData: any): any {
                    return cellStyle(rowData);
                  },
                },
                {
                  title: translate(
                    'dxMessages.task.reginamaria.table.quantity'
                  ),
                  field: 'LineItem.Quantity.value',
                  type: 'numeric',
                  filtering: false,
                  hidden: excludeColumn(
                    excludedColumns,
                    ColumnConstants.ORDER_QUANTITY
                  ),
                  cellStyle(data: any[], rowData: any): any {
                    return cellStyle(rowData);
                  },
                  render: (rowData: any) => {
                    // @ts-ignore
                    const rowId = rowData.tableData.id;
                    return (
                      <ColumnRenderer
                        rowId={rowId}
                        comparedLines={comparedOrderLines}
                        originalLines={rows}
                        tableData={rowData}
                        jsonPath='LineItem.Quantity.value'
                      />
                    );
                  },
                },
                {
                  title: translate(
                    'dxMessages.task.reginamaria.table.unitCode'
                  ),
                  field: 'LineItem.Quantity.unitCode',
                  editable: 'never',
                  filtering: false,
                  hidden: excludeColumn(
                    excludedColumns,
                    ColumnConstants.ORDER_UNIT_CODE
                  ),
                  cellStyle(data: any[], rowData: any): any {
                    return cellStyle(rowData);
                  },
                },
                {
                  title: translate('dxMessages.task.reginamaria.table.price'),
                  field: 'LineItem.Price.PriceAmount.value',
                  type: 'numeric',
                  filtering: false,
                  hidden: excludeColumn(
                    excludedColumns,
                    ColumnConstants.ORDER_PRICE
                  ),
                  cellStyle(data: any[], rowData: any): any {
                    return cellStyle(rowData);
                  },
                  render: (rowData: any) => {
                    // @ts-ignore
                    const rowId = rowData.tableData.id;
                    return (
                      <ColumnRenderer
                        rowId={rowId}
                        comparedLines={comparedOrderLines}
                        originalLines={rows}
                        tableData={rowData}
                        jsonPath='LineItem.Price.PriceAmount.value'
                      />
                    );
                  },
                },
                {
                  title: translate(
                    'dxMessages.task.reginamaria.table.expirationDate'
                  ),
                  field: 'LineItem.Item.ItemInstance[0].BestBeforeDate.value',
                  type: 'date',
                  filtering: false,
                  hidden: excludeColumn(
                    excludedColumns,
                    ColumnConstants.ORDER_BEST_BEFORE_DATE
                  ),
                  cellStyle(data: any[], rowData: any): any {
                    return cellStyle(rowData);
                  },
                  render: (rowData: any) => {
                    // @ts-ignore
                    const rowId = rowData.tableData.id;
                    return (
                      <ColumnRenderer
                        rowId={rowId}
                        comparedLines={comparedOrderLines}
                        originalLines={rows}
                        tableData={rowData}
                        jsonPath='LineItem.Item.ItemInstance[0].BestBeforeDate.value'
                      />
                    );
                  },
                },
                {
                  title: translate(
                    'dxMessages.task.reginamaria.table.numberOfDeliveries'
                  ),
                  field: '',
                  filtering: false,
                  hidden: excludeColumn(
                    excludedColumns,
                    ColumnConstants.DELIVERY_TABLE
                  ),
                  cellStyle(data: any[], rowData: any): any {
                    return cellStyle(rowData);
                  },
                  render: (rowData: any) => {
                    // @ts-ignore
                    const rowId = rowData.tableData.id;
                    return (
                      <div>
                        {updatedOrder[rowId]?.LineItem?.Delivery?.length ||
                          rowData.LineItem?.Delivery?.length ||
                          0}
                      </div>
                    );
                  },
                },
                {
                  title: translate(
                    'dxMessages.task.reginamaria.table.lineStatusCode'
                  ),
                  field: '',
                  lookup: {
                    [LineStatusCode.UPDATED]: translate(
                      'dxMessages.task.reginamaria.lineStatusCode.UPDATED'
                    ),
                    [LineStatusCode.NO_CHANGE]: translate(
                      'dxMessages.task.reginamaria.lineStatusCode.NO_CHANGE'
                    ),
                    [LineStatusCode.NOT_ACCEPTED]: translate(
                      'dxMessages.task.reginamaria.lineStatusCode.NOT_ACCEPTED'
                    ),
                  },
                  customFilterAndSearch: (term, rowData) => {
                    if (!term || term.length === 0) {
                      return true;
                    }
                    const line = comparedOrderLines[rowData.tableData.id];
                    const lineStatusCode = line?.LineItem?.LineStatusCode?.value
                      ? line?.LineItem?.LineStatusCode.value
                      : line?.LineItem?.LineStatusCode;
                    if (
                      term.find((t) => t === LineStatusCode.NOT_ACCEPTED) &&
                      line === null
                    ) {
                      return true;
                    } else if (
                      term.find((t) => t === LineStatusCode.UPDATED) &&
                      lineStatusCode === LineStatusCode.UPDATED
                    ) {
                      return true;
                    } else if (
                      term.find((t) => t === LineStatusCode.NO_CHANGE) &&
                      lineStatusCode === LineStatusCode.NO_CHANGE
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  },
                  cellStyle(data: any[], rowData: any): any {
                    return {
                      color:
                        comparedOrderLines[rowData.tableData.id] === null
                          ? 'red'
                          : 'inherit',
                      textAlign: 'center',
                    };
                  },
                  headerStyle: { textAlign: 'center' },
                  render: (rowData: any) => {
                    if (comparedOrderLines[rowData.tableData.id] === null) {
                      return <Remove />;
                    } else if (
                      comparedOrderLines[rowData.tableData.id]?.LineItem
                        ?.LineStatusCode === LineStatusCode.UPDATED ||
                      comparedOrderLines[rowData.tableData.id]?.LineItem
                        ?.LineStatusCode?.value === LineStatusCode.UPDATED
                    ) {
                      return <Report style={{ color: 'orange' }} />;
                    } else {
                      return <></>;
                    }
                  },
                },
              ]}
              detailPanel={(rowData: any) => {
                if (
                  !excludeColumn(
                    excludedColumns,
                    ColumnConstants.DELIVERY_TABLE
                  )
                ) {
                  // @ts-ignore
                  const rowId = rowData.tableData.id;
                  // @ts-ignore
                  const deliveryRow = rowData.LineItem.Delivery;
                  return (
                    <div
                      id={rowId}
                      style={{ paddingBottom: '2em', paddingLeft: '6em' }}
                    >
                      <InnerClientTable
                        updatedOrderLine={updatedOrder[rowId]}
                        rowData={rowData}
                        comparedLines={diff(
                          deliveryRow,
                          updatedOrder[rowId]?.LineItem?.Delivery || []
                        )}
                        excludedColumns={excludedColumns}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              }}
              data={rows}
              options={{
                showTitle: true,
                search: false,
                filtering: true,
                paging: true,
                padding: 'dense',
                actionsColumnIndex: -1,
                pageSize: 10,
                headerStyle: {
                  fontWeight: 'bolder',
                  borderBottom: '1px solid lightgrey',
                },
                rowStyle: (rowData: any): any => ({
                  whiteSpace: 'pre-line',
                  color:
                    comparedOrderLines[rowData.tableData.id] === null
                      ? theme.palette.action.disabled
                      : null,
                }),
              }}
              localization={MT_LOCALIZATION(translate)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              source='input.data.comment'
              label='dxMessages.task.reginamaria.table.comment'
              fullWidth
              multiline
              rows='2'
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderNegotiationClientCockpit;
