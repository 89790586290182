import { useCallback, useEffect, useState } from 'react';
import { getLastUsedApplication, getUser } from '../security';
import { LocalStorageHelpers } from '../utils/LocalStorageHelpers';

const ValueChangedEvent = 'onValueChanged';
const LocalStorageChangedEvent = 'storage';

export interface TValue {
  [key: string]: boolean;
}

const useLocalStorageValueGeneric = <TValue>(
  localStorageKey: string,
  defaultValue: TValue
): [TValue, (value: TValue) => void] => {
  const application = getLastUsedApplication();
  const user = getUser();
  const key = `${application}.${user}.${localStorageKey}`;

  const localStorageValue =
    LocalStorageHelpers.retrieveItem<TValue | undefined>(key) ?? defaultValue;

  const [value, setValue] = useState(localStorageValue);

  const changeValue = useCallback(
    (newValue: TValue) => {
      LocalStorageHelpers.storeItem(key, newValue);

      window.dispatchEvent(
        new CustomEvent<any>(ValueChangedEvent, {
          detail: { newKey: key, newValue: newValue },
        })
      );
    },
    [key]
  );

  const handleValueChanged = useCallback(
    (event: CustomEvent<any>) => {
      const { newKey, newValue } = event.detail;
      if (newKey !== key) {
        return;
      }
      setValue(newValue ?? defaultValue);
    },
    [defaultValue, key]
  );

  const handleStorageChanged = useCallback(
    (event: StorageEvent) => {
      if (event.key !== key) {
        return;
      }

      let newValue: TValue = defaultValue;
      if (event.newValue) {
        try {
          newValue = JSON.parse(event.newValue);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }

      setValue(newValue ?? defaultValue);
    },
    [defaultValue, key]
  );

  useEffect(() => {
    // fired when value change
    //@ts-ignore
    window.addEventListener(ValueChangedEvent, handleValueChanged);

    // fired when local storage changes
    window.addEventListener(LocalStorageChangedEvent, handleStorageChanged);

    return (): void => {
      // remove listeners

      //@ts-ignore
      window.removeEventListener(ValueChangedEvent, handleValueChanged);
      window.removeEventListener(
        LocalStorageChangedEvent,
        handleStorageChanged
      );
    };
  }, [handleValueChanged, handleStorageChanged]);

  return [value, changeValue];
};

export default useLocalStorageValueGeneric;
