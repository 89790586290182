import { DocumentTypeCode, formatNumericValue } from '@dx-ui/dx-common';
import {
  TaxSubtotalDetails,
  TaxTotalDetails,
} from '@dx-ui/lib-oasis-ubl-2.1/src/OrderModel';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { groupBy } from 'lodash';
import compose from 'lodash/flowRight';
import { translate } from 'react-admin';
import { TaxAndPriceUtils } from '../../../services';
import { DataHelpers } from '../../../services/DataHelpers';
import {
  AlfrescoProperties,
  TaxItem,
  TaxTotalItem,
  UblProperties,
} from '../../types';
import { round } from '../utils';

const styles = (theme): any => ({
  borderedTable: {
    marginTop: '1em',
    borderCollapse: 'collapse',
    '& th': {
      paddingLeft: '1em',
      paddingRight: '1em',
    },
    '& td': {
      borderBottom: '1px solid',
      borderBottomColor: theme.palette.divider,
      paddingLeft: '1em',
      paddingRight: '1em',
    },
  },
});

/**
 * Computes Tax summary Panel
 * @param {*} data formData
 */
const TaxSummaryPanel = ({
  data,
  ...rest
}: {
  data: AlfrescoProperties & UblProperties;
  props: any;
}) => {
  const { classes, locale, translate }: any = rest;
  const isInvoice =
    DataHelpers.getDocumentTypeCode(data) === DocumentTypeCode.INVOIC;
  const documentTaxTotal: TaxTotalItem[] | TaxTotalDetails[] = isInvoice
    ? data?.ublProperties?.taxTotal
    : data.ublProperties.Order[0].TaxTotal;
  const showTable = isInvoice
    ? !!(documentTaxTotal as TaxTotalItem[])?.length &&
      !!(documentTaxTotal as TaxTotalItem[])[0].taxSubtotal?.length
    : !!(documentTaxTotal as TaxTotalDetails[])?.length &&
      !!(documentTaxTotal as TaxTotalDetails[])[0].TaxSubtotal?.length;

  if (!showTable) {
    return null;
  } else {
    let tableLines = isInvoice
      ? (documentTaxTotal as TaxTotalItem[])[0].taxSubtotal
      : (documentTaxTotal as TaxTotalDetails[])[0].TaxSubtotal;
    const currencyID = DataHelpers.getCurrencyID(data) || '--';

    const lineTaxSubtotals: any = [];

    // group by percentage
    let groupedValues = isInvoice
      ? groupBy(tableLines, (values) => (values as TaxItem).percent?.value)
      : groupBy(
          tableLines,
          (values) => (values as TaxSubtotalDetails).Percent?.[0]._
        );
    const sortedObjectKeys = Object.keys(groupedValues)
      .map((key) => +key)
      .filter((key) => !isNaN(key))
      .sort((a, b) => a - b);

    sortedObjectKeys.forEach((percent) => {
      if (!groupedValues[percent]) {
        return;
      }
      const { totalVat, totalWithoutVat, taxCategory } = groupedValues[
        percent
      ].reduce(
        (acc: any, values) => ({
          totalVat: isInvoice
            ? TaxAndPriceUtils.limitDecimals(
                acc.totalVat + (values as TaxItem).taxAmount.value
              )
            : TaxAndPriceUtils.limitDecimals(
                acc.totalVat + (values as TaxSubtotalDetails).TaxAmount[0]._
              ),
          totalWithoutVat: isInvoice
            ? TaxAndPriceUtils.limitDecimals(
                acc.totalWithoutVat +
                  round((values as TaxItem).taxableAmount?.value)
              )
            : TaxAndPriceUtils.limitDecimals(
                acc.totalWithoutVat +
                  round((values as TaxSubtotalDetails).TaxableAmount?.[0]._)
              ),
          taxCategory: isInvoice
            ? (values as TaxItem).taxCategory
            : (values as TaxSubtotalDetails).TaxCategory,
        }),
        {
          totalVat: 0,
          totalWithoutVat: 0,
          taxCategory: undefined,
        }
      );

      const taxSubtotal = TaxAndPriceUtils.buildTaxSubtotal(
        percent,
        totalVat,
        totalWithoutVat,
        currencyID,
        taxCategory // Not relevant here
      );

      lineTaxSubtotals.push(taxSubtotal);
    });

    tableLines = lineTaxSubtotals;

    return (
      <table className={classes.borderedTable}>
        <tbody>
          <tr>
            <th>
              <Typography variant='caption'>
                {translate('dxMessages.invoices.tax_summary_vat_percent')}
              </Typography>
            </th>
            <th>
              <Typography variant='caption'>
                {translate('dxMessages.invoices.tax_summary_taxable_amount', {
                  currencyID,
                })}
              </Typography>
            </th>
            <th>
              <Typography variant='caption'>
                {translate('dxMessages.invoices.tax_summary_tax_amount')}
              </Typography>
            </th>
          </tr>
          {tableLines?.map((line, index) => {
            return (
              <tr key={index}>
                <td>
                  <Typography align='right' className={classes.readOnly}>
                    {isInvoice ? line.percent?.value : line.Percent?.[0]._}
                  </Typography>
                </td>
                <td>
                  <Typography align='right'>
                    {isInvoice
                      ? line?.taxableAmount?.value !== undefined
                        ? formatNumericValue(
                            line.taxableAmount.value,
                            locale,
                            2,
                            2
                          )
                        : '--'
                      : line?.TaxableAmount?.[0]._ !== undefined
                      ? formatNumericValue(
                          line.TaxableAmount?.[0]._,
                          locale,
                          2,
                          2
                        )
                      : '--'}
                  </Typography>
                </td>
                <td>
                  <Typography align='right' className={classes.readOnly}>
                    {isInvoice
                      ? line?.taxAmount?.value !== undefined
                        ? formatNumericValue(line.taxAmount.value, locale, 2, 2)
                        : '--'
                      : line?.TaxAmount?.[0]._ !== undefined
                      ? formatNumericValue(line.TaxAmount?.[0]._, locale, 2, 2)
                      : '--'}
                  </Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
};

const enhance = compose(translate, withStyles(styles));
export default enhance(TaxSummaryPanel);
