import BookmarksIcon from '@material-ui/icons/Bookmarks';
import React from 'react';
import { useTranslate } from 'react-admin';
import { MenuItemsGroup } from '../..';
import SavedQueriesMenuItem from './SavedQueriesMenuItem';
import { SavedQuery, useSavedQueries } from './useSavedQueries';

const SavedQueriesMenuItems = ({
  classes: classesOverride,
}: {
  classes: any;
}) => {
  const [savedQueries, setSavedQueries] = useSavedQueries();
  const translate = useTranslate();

  if (!savedQueries?.length) {
    return null;
  }

  const handleRemove = (savedQuery: SavedQuery) => {
    const newSavedQueries = savedQueries.filter((item) => item !== savedQuery);
    setSavedQueries(newSavedQueries);
  };

  return (
    <MenuItemsGroup
      label={translate('dxMessages.filter.savedQueriesMenu')}
      icon={<BookmarksIcon />}
    >
      {savedQueries.map((savedQuery, idx) => (
        <SavedQueriesMenuItem
          key={`savedQuery-${idx}`}
          savedQuery={savedQuery}
          onRemove={() => handleRemove(savedQuery)}
          classes={{ menuItem: classesOverride.menuItem }}
        />
      ))}
    </MenuItemsGroup>
  );
};

export default SavedQueriesMenuItems;
