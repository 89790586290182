import { useEffect, useState } from 'react';
import { Constants } from '../../constants';
import { getPermissions, UserRoles } from '../../security';
import { getDxApplication } from '../../utils';

/**
 * Checks whether changing attachments is allowed
 */
export const useChangeAttachmentsAllowed = (resource: string | undefined) => {
  const permissions = getPermissions();
  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    let allowAttachmentsChange = false;
    const dxApplication = getDxApplication();

    if (resource && dxApplication === Constants.DXPURCHASE_APP) {
      const attachRole = getAttachmentsRole(permissions, resource);
      allowAttachmentsChange = !!permissions && !!attachRole;
    }

    setAllowed(allowAttachmentsChange);
  }, [permissions, resource]);

  return allowed;
};

/**
 * Retrieves attachment related role from permissions array
 */
export const getAttachmentsRole = (permissions: any, resource: string) => {
  let permissionList: string[] = [];
  if (permissions[UserRoles.DXPURCHASE_PRODUCT]) {
    permissionList = permissions[UserRoles.DXPURCHASE_PRODUCT];
  }
  const findPermission = (item) => {
    return (
      (item === UserRoles.ATTACH_INVOICE &&
        resource === Constants.RESOURCE_WEBINVOICE) ||
      (item === UserRoles.ATTACH_INVOICE &&
        resource === Constants.RESOURCE_INVOICE) ||
      (item === UserRoles.ATTACH_DESPATCH_ADVICE &&
        resource === Constants.RESOURCE_WEBDESPATCH_ADVICE) ||
      (item === UserRoles.ATTACH_WAYBILL &&
        resource === Constants.RESOURCE_WEBWAYBILL) ||
      (item === UserRoles.ATTACH_RECEIPT_ADVICE &&
        resource === Constants.RESOURCE_WEBRECEIPT_ADVICE) ||
      (item === UserRoles.ATTACH_ORDER &&
        resource === Constants.RESOURCE_WEBORDER)
    );
  };
  // Extract the one that is specific for this page (only web document creation)
  return permissionList.find(findPermission);
};
