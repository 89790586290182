import { isPspAdministrator, useGAPageViews } from '@dx-ui/dx-common/src';
import { withStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { set, split } from 'lodash';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  ExportButton,
  Filter,
  FilterProps,
  FunctionField,
  List,
  SelectField,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  useNotify,
  usePermissions,
} from 'react-admin';
import { Empty } from '../../../common/Empty';
import PspAdminDeleteButton from './PspAdminDeleteButton';
import PspAdminSyncButton from './PspAdminSyncButton';

// interface LastLoginTimeResponse extends RaRecord {
//   // ISO-8601 representation.
//   lastLoginTime: string;
// }

const AccountFilters = (props: Omit<FilterProps, 'children'>) => {
  return (
    <Filter {...props}>
      <TextInput
        label={'dxMessages.companyManagement.account.name'}
        source='accountname'
        alwaysOn
      />
      <TextInput
        label={'dxMessages.companyManagement.account.vatCode'}
        source='identification'
        alwaysOn
      />
      <SelectInput
        label={'dxMessages.companyManagement.account.idstatus'}
        source='idstatus'
        choices={[
          { id: 1, name: 'Active' },
          { id: 2, name: 'Not Active' },
          { id: 3, name: 'Suspended' },
          { id: 4, name: 'Terminat' },
        ]}
        defaultValue={1}
      />
    </Filter>
  );
};

const AccountDetailsList = (props: any) => {
  const ErrorIcon = withStyles((theme) => ({
    root: {
      color: theme.palette.error.main,
    },
  }))(ClearIcon);
  useGAPageViews();
  const { permissions } = usePermissions();
  const notify = useNotify();

  const onFailure = (error: any) => {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error?.status === 500) {
      //address already exist
      notify('dxMessages.error_messages.impossible_delete_company', 'error');
    } else {
      //unknown error
      notify('dxMessages.error_messages.OnError_contact_the_support', 'error', {
        error: error.message,
      });
    }
  };

  const ListActions = () => {
    return (
      <TopToolbar>
        {isPspAdministrator(permissions) && <CreateButton />}
        <ExportButton maxResults={5000} />
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={false}
      empty={isPspAdministrator(permissions) ? <Empty /> : false}
      filters={isPspAdministrator(permissions) ? <AccountFilters /> : undefined}
      actions={<ListActions />}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        <TextField
          label={'dxMessages.companyManagement.account.name'}
          source='accountname'
        />
        <TextField
          label={'dxMessages.companyManagement.account.vatCode'}
          source='identification'
        />
        <FunctionField
          label={'dxMessages.companyManagement.account.registryNumber'}
          render={(record) => {
            if (record?.traderegnr?.indexOf('*') !== -1) {
              set(
                record as any,
                'tradeRegisterNumber',
                split(record?.traderegnr, '*')?.[0]
              );
            } else {
              set(record as any, 'tradeRegisterNumber', record?.traderegnr);
            }
            return <TextField source='tradeRegisterNumber' sortable={false} />;
          }}
        />

        <FunctionField
          label={'dxMessages.companyManagement.account.corporateStock'}
          render={(record) => {
            //
            if (record?.traderegnr?.indexOf('*') !== -1) {
              set(
                record as any,
                'corporateStockAmount',
                split(record?.traderegnr, '*')?.[1]
              );
            }
            return <TextField source='corporateStockAmount' record={record} />;
          }}
        />
        {isPspAdministrator(permissions) && (
          <TextField
            label={'dxMessages.companyManagement.account.uidIdentification'}
            source='uidIdentification'
            sortable={false}
          />
        )}
        <SelectField
          label={'dxMessages.companyManagement.account.idstatus'}
          source='idstatus'
          fullWidth
          choices={[
            { id: 1, name: 'Active' },
            { id: 2, name: 'Not Active' },
            { id: 3, name: 'Suspended' },
            { id: 4, name: 'Terminat' },
          ]}
          defaultValue={1}
        />
        {isPspAdministrator(permissions) && (
          <BooleanField
            label='dxMessages.companyManagement.account.v3Synchronize'
            source='v3Synchronize'
            sortable={false}
            // @ts-ignore
            FalseIcon={ErrorIcon}
          />
        )}
        <PspAdminDeleteButton onFailure={onFailure} />
        {isPspAdministrator(permissions) && <ShowButton />}
        <PspAdminSyncButton />
      </Datagrid>
    </List>
  );
};

export default AccountDetailsList;
