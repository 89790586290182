import { useTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { ColumnConstants } from '../common/Constants';
import {
  LineStatusCode,
  compareDates,
  datesOverlap,
  excludeColumn,
  formatUblLineSupplier,
  isDateInFuture,
  readOnlyColumn,
} from '../common/util/utils';

const DisplayErrors = (props) => {
  const { innerTableErrors } = props;
  return (
    <Alert severity='error'>
      <ul>
        {innerTableErrors.map((e, index) => (
          <li key={index}>
            <strong>{e}</strong>
          </li>
        ))}
      </ul>
    </Alert>
  );
};

const InnerSupplierTable = (props) => {
  const { excludedColumns, extraPeriodInDay, readOnlyColumns } = props;
  const [rows, setRows] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [innerTableErrors, setInnerTableErrors] = useState<string[]>([]);
  const theme = useTheme();
  const form = useForm();
  const translate = useTranslate();
  const onRowAdd: any =
    props.addDelivery === true &&
    props.rowData.LineItem.LineStatusCode !== LineStatusCode.NOT_ACCEPTED
      ? (row) => {
          return new Promise<void>((resolve, reject) => {
            setTimeout(async () => {
              if (await validation(row, 'add')) {
                return reject();
              }
              setRows([...rows, row]);
              select(row);
              props.onChange([...rows, row], props.rowData.tableData.id);
              resolve();
            }, 1000);
          });
        }
      : null;

  const select = (row) => {
    setSelectedRow(row);
  };

  const validation = async (row, action) => {
    setInnerTableErrors([] as string[]);
    if (action === 'add' && Object.keys(row).length === 0) {
      return true;
    }
    let errorFields: string[] = [];

    // Quantity check
    if (!excludeColumn(excludedColumns, ColumnConstants.DELIVERY_QUANTITY)) {
      const quantity: Number = props.rowData.LineItem.Quantity.value;
      if (isNaN(row.Quantity?.value) || row.Quantity?.value <= 0) {
        errorFields.push(
          translate(
            'dxMessages.task.reginamaria.errorMsg.invalid_quantity_value'
          )
        );
      } else {
        let delQuantity: Number =
          rows
            .filter((item) => {
              if (action === 'edit') {
                return item?.ID.value !== row?.ID.value;
              } else {
                return true;
              }
            })
            .reduce((a, b) => a + b.Quantity.value, 0) + row.Quantity.value ||
          0;

        if (delQuantity > quantity) {
          errorFields.push(
            translate(
              'dxMessages.task.reginamaria.errorMsg.delivered_quantity_too_big'
            )
          );
        }
      }

      // Quantity check when adding a delivery row is forbidden (ex: Altex use case)
      // Thus, the quantity check on exceeding values can be done immediately
      if (
        !excludeColumn(excludedColumns, ColumnConstants.DELIVERY_QUANTITY) &&
        props.addDelivery === false
      ) {
        const quantity: Number = props.rowData.LineItem.Quantity.value;
        if (!isNaN(row.Quantity?.value) && row.Quantity?.value > 0) {
          if (row.Quantity.value < quantity) {
            errorFields.push(
              translate(
                'dxMessages.task.reginamaria.errorMsg.delivered_quantity_too_low'
              )
            );
          }
        }
      }
    }

    //Delivery date check
    if (rows.length > 0) {
      if (
        !excludeColumn(excludedColumns, ColumnConstants.DELIVERY_ACTUAL_DATE) &&
        !row?.ActualDeliveryDate?.value
      ) {
        errorFields.push(
          translate(
            'dxMessages.task.reginamaria.errorMsg.actual_date_mandatory'
          )
        );
      }
      if (
        !excludeColumn(excludedColumns, ColumnConstants.DELIVERY_ACTUAL_DATE) &&
        row?.RequestedDeliveryPeriod?.StartDate?.value &&
        row?.RequestedDeliveryPeriod?.EndDate?.value &&
        !compareDates(
          row?.ActualDeliveryDate?.value,
          row?.RequestedDeliveryPeriod?.StartDate?.value,
          row?.RequestedDeliveryPeriod?.EndDate?.value,
          extraPeriodInDay
        )
      ) {
        errorFields.push(
          translate(
            extraPeriodInDay === 0
              ? 'dxMessages.task.reginamaria.errorMsg.dates_not_in_range'
              : 'dxMessages.task.reginamaria.errorMsg.dates_not_in_range_with_extra',
            { extra: extraPeriodInDay }
          )
        );
      }
      if (
        !excludeColumn(excludedColumns, ColumnConstants.DELIVERY_START_DATE) &&
        excludeColumn(excludedColumns, ColumnConstants.DELIVERY_ACTUAL_DATE) &&
        !row?.RequestedDeliveryPeriod?.StartDate?.value
      ) {
        errorFields.push(
          translate('dxMessages.task.reginamaria.errorMsg.incorrect_start_date')
        );
      }
      if (
        !excludeColumn(excludedColumns, ColumnConstants.DELIVERY_END_DATE) &&
        excludeColumn(excludedColumns, ColumnConstants.DELIVERY_ACTUAL_DATE) &&
        !row?.RequestedDeliveryPeriod?.EndDate?.value
      ) {
        errorFields.push(
          translate('dxMessages.task.reginamaria.errorMsg.incorrect_end_date')
        );
      }
      if (
        !excludeColumn(excludedColumns, ColumnConstants.DELIVERY_END_DATE) &&
        excludeColumn(excludedColumns, ColumnConstants.DELIVERY_ACTUAL_DATE) &&
        row?.RequestedDeliveryPeriod?.EndDate?.value &&
        !isDateInFuture(row?.RequestedDeliveryPeriod?.EndDate?.value)
      ) {
        errorFields.push(
          translate(
            'dxMessages.task.reginamaria.errorMsg.end_date_must_be_in_future'
          )
        );
      }
      if (
        !excludeColumn(excludedColumns, ColumnConstants.DELIVERY_START_DATE) &&
        excludeColumn(excludedColumns, ColumnConstants.DELIVERY_ACTUAL_DATE) &&
        row?.RequestedDeliveryPeriod?.StartDate?.value &&
        !isDateInFuture(row?.RequestedDeliveryPeriod?.StartDate?.value)
      ) {
        errorFields.push(
          translate(
            'dxMessages.task.reginamaria.errorMsg.start_date_must_be_in_future'
          )
        );
      }
      if (
        !excludeColumn(excludedColumns, ColumnConstants.DELIVERY_START_DATE) &&
        !excludeColumn(excludedColumns, ColumnConstants.DELIVERY_END_DATE) &&
        row?.RequestedDeliveryPeriod?.StartDate?.value &&
        row?.RequestedDeliveryPeriod?.EndDate?.value &&
        datesOverlap(
          row?.RequestedDeliveryPeriod?.StartDate?.value,
          row?.RequestedDeliveryPeriod?.EndDate?.value
        )
      ) {
        errorFields.push(
          translate('dxMessages.task.reginamaria.errorMsg.dates_overlap')
        );
      }
    }
    setInnerTableErrors(errorFields);

    if (errorFields.length > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    formatUblLineSupplier(props.rowData, form);
    setRows(props.rowData.LineItem.Delivery);
  }, [form, props.rowData, props.rowData.LineItem.Delivery]);

  const cellStyle: any = () => {
    return {
      color:
        props.rowData.LineItem?.LineStatusCode === LineStatusCode.NOT_ACCEPTED
          ? theme.palette.action.disabled
          : null,
    };
  };

  return (
    <div>
      <MaterialTable
        title={translate('dxMessages.task.reginamaria.table.deliveryTitle')}
        components={{
          Container: (props) => <>{props.children}</>,
        }}
        columns={[
          {
            title: translate('dxMessages.task.reginamaria.table.deliveryId'),
            field: 'ID.value',
            editable: 'never',
            hidden: excludeColumn(excludedColumns, ColumnConstants.DELIVERY_ID),
            cellStyle: (data: any[], rowData: any) => cellStyle(),
            headerStyle: cellStyle(),
          },
          {
            title: translate('dxMessages.task.reginamaria.table.startDate'),
            field: 'RequestedDeliveryPeriod.StartDate.value',
            type: 'date',
            dateSetting: { locale: 'en-GB' },
            editable:
              excludeColumn(
                excludedColumns,
                ColumnConstants.DELIVERY_START_DATE
              ) ||
              !excludeColumn(
                excludedColumns,
                ColumnConstants.DELIVERY_ACTUAL_DATE
              )
                ? 'never'
                : 'always',
            cellStyle: (data: any[], rowData: any) => cellStyle(),
            headerStyle: cellStyle(),
          },
          {
            title: translate('dxMessages.task.reginamaria.table.endDate'),
            field: 'RequestedDeliveryPeriod.EndDate.value',
            type: 'date',
            dateSetting: { locale: 'en-GB' },
            editable:
              excludeColumn(
                excludedColumns,
                ColumnConstants.DELIVERY_END_DATE
              ) ||
              !excludeColumn(
                excludedColumns,
                ColumnConstants.DELIVERY_ACTUAL_DATE
              )
                ? 'never'
                : 'always',
            cellStyle: (data: any[], rowData: any) => cellStyle(),
            headerStyle: cellStyle(),
          },
          {
            title: translate(
              'dxMessages.task.reginamaria.table.actualDeliveryDate'
            ),
            field: 'ActualDeliveryDate.value',
            type: 'date',
            dateSetting: { locale: 'en-GB' },
            hidden: excludeColumn(
              excludedColumns,
              ColumnConstants.DELIVERY_ACTUAL_DATE
            ),
            cellStyle: (data: any[], rowData: any) => cellStyle(),
            headerStyle: cellStyle(),
          },
          {
            title: translate('dxMessages.task.reginamaria.table.quantity'),
            field: 'Quantity.value',
            type: 'numeric',
            hidden: excludeColumn(
              excludedColumns,
              ColumnConstants.DELIVERY_QUANTITY
            ),
            editable: (columnDef, rowData) =>
              !readOnlyColumn(
                readOnlyColumns,
                ColumnConstants.DELIVERY_QUANTITY
              ),
            cellStyle: (data: any[], rowData: any) => cellStyle(),
            headerStyle: cellStyle(),
          },
        ]}
        data={rows}
        onRowClick={(evt, row) => select(row)}
        editable={{
          isEditHidden: (rowData) =>
            props.rowData.LineItem?.LineStatusCode ===
            LineStatusCode.NOT_ACCEPTED,
          isDeleteHidden: (rowData) =>
            props.rowData.LineItem?.LineStatusCode ===
              LineStatusCode.NOT_ACCEPTED ||
            props.rowData.LineItem?.Delivery?.length === 1,
          onRowAdd: onRowAdd,
          onRowUpdate: (newRow: any, oldRow: any) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(async () => {
                if (await validation(newRow, 'edit')) {
                  return reject();
                }
                const dataUpdate = [...rows];
                const index = rows.indexOf(oldRow);
                dataUpdate[index] = newRow;
                setRows([...dataUpdate]);
                select(newRow);
                props.onChange([...dataUpdate], props.rowData.tableData.id);
                resolve();
              }, 1000);
            }),
          onRowDelete: (row) =>
            new Promise<void>((resolve, reject) => {
              setTimeout(() => {
                const newRows = rows.filter((r) => r.ID !== row.ID);
                setRows(newRows);
                props.onChange(newRows, props.rowData.tableData.id);
                if (selectedRow && selectedRow.ID === row.ID) {
                  setSelectedRow(null);
                }
                resolve();
              }, 1000);
            }),
        }}
        options={{
          showTitle: false,
          search: false,
          filtering: false,
          paging: false,
          padding: 'dense',
          actionsColumnIndex: -1,
          headerStyle: {
            fontWeight: 'bolder',
            borderBottom: '1px solid lightgrey',
            pointerEvents:
              props.rowData.LineItem?.LineStatusCode !==
              LineStatusCode.NOT_ACCEPTED
                ? 'inherit'
                : 'none',
          },
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow && selectedRow.tableData.id === rowData.tableData.id
                ? theme.palette.action.selected
                : 'white',
            pointerEvents:
              props.rowData.LineItem?.LineStatusCode !==
              LineStatusCode.NOT_ACCEPTED
                ? 'inherit'
                : 'none',
          }),
        }}
        localization={{
          header: { actions: '' },
          body: {
            emptyDataSourceMessage: translate(
              'dxMessages.task.reginamaria.table.emptyDeliveryTable'
            ),
            editRow: {
              deleteText: translate(
                'dxMessages.task.reginamaria.table.deleteVerification'
              ),
            },
          },
        }}
      />
      {innerTableErrors.length > 0 && (
        <DisplayErrors innerTableErrors={innerTableErrors} />
      )}
    </div>
  );
};

export default InnerSupplierTable;
