import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GridOn from '@material-ui/icons/GridOn';
import { useState } from 'react';
import { crudGetAll, useGetIdentity, useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Metadata } from '../../constants/Constants';
import { DxTheme } from '../../types';
import {
  ExportFileTypes,
  GA_EVENTS,
  manageExportComplexFields,
  sendGAEvent,
} from '../../utils';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    button: {
      borderRadius: 4,
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
      padding: 7,
      marginRight: 2,
      width: 'auto',
      height: 'auto',
    },
    iconButton: {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: 'small',
    },
  }),
  { name: 'ExportButton' }
);

const options = {
  VISIBLE_COLUMNS: 'exportVisibleColumns',
  ALL_COLUMNS: 'exportAllColumns',
};

const ExportButton = (props) => {
  const {
    exporter,
    filter,
    maxResults,
    sort,
    resource,
    columns,
    visibleColumns,
    label,
    icon,
    className,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles(props);
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Exports all columns or only the visible ones according to mode
   * @param {string} mode options.VISIBLE_COLUMNS or options.All_COLUMNS
   */
  const handleClickChoice = (mode) => {
    // If the mode is set to visible columns, we need to add the second part of
    // the complex displays.
    // Example : IssuerName + issuerId
    let columnsToExport: any[] = [];
    if (mode === options.VISIBLE_COLUMNS) {
      sendGAEvent(
        GA_EVENTS.categories.LIST.name,
        GA_EVENTS.categories.LIST.actions.EXPORT_VISIBLE_COLUMNS,
        account?.company?.cmsRootDir
      );

      columnsToExport = manageExportComplexFields(visibleColumns, columns);
    } else {
      sendGAEvent(
        GA_EVENTS.categories.LIST.name,
        GA_EVENTS.categories.LIST.actions.EXPORT_ALL_COLUMNS,
        account?.company?.cmsRootDir
      );

      columnsToExport = [...columns].filter(
        (col) => col.id !== Metadata.document
      );
    }

    const getAllCallback = ({ payload: { data } }) =>
      exporter(data, columnsToExport, ExportFileTypes.XLS, translate);

    dispatch(crudGetAll(resource, sort, filter, maxResults, getAllCallback));

    setAnchorEl(null);
  };

  return (
    <span>
      <Tooltip title={translate(label)}>
        <IconButton
          aria-label='Export'
          aria-owns={open ? 'export-menu' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
          color='primary'
          style={{ visibility: open ? 'visible' : undefined }}
          disableRipple={true}
          className={className}
          classes={{ root: classes.button }}
        >
          <div className={classes.iconButton}>
            {icon}
            {translate(label).toUpperCase()}
            <ArrowDropDownIcon />
          </div>
        </IconButton>
      </Tooltip>
      <Menu
        id='export-menu'
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          key={options.VISIBLE_COLUMNS}
          className={options.VISIBLE_COLUMNS}
          onClick={(event) => handleClickChoice(options.VISIBLE_COLUMNS)}
        >
          {translate('dxMessages.export.visibleColumns', {
            _: 'Export Visible Columns',
          })}
        </MenuItem>
        <MenuItem
          key={options.ALL_COLUMNS}
          className={options.ALL_COLUMNS}
          onClick={(event) => handleClickChoice(options.ALL_COLUMNS)}
        >
          {translate('dxMessages.export.allColumns', {
            _: 'Export all Columns',
          })}
        </MenuItem>
      </Menu>
    </span>
  );
};

ExportButton.defaultProps = {
  label: 'ra.action.export',
  maxResults: 1000,
  icon: <GridOn style={{ marginRight: 2, transform: 'scale(0.8)' }} />,
};

export default ExportButton;
