import { Constants, isPspAdministrator } from '@dx-ui/dx-common';
import SyncIcon from '@material-ui/icons/Sync';
import PropTypes from 'prop-types';
import { Record, RedirectionSideEffect, useDataProvider } from 'ra-core';
import { FC, ReactElement, SyntheticEvent, useState } from 'react';
import {
  Button,
  ButtonProps,
  Confirm,
  useNotify,
  usePermissions,
} from 'react-admin';

const PspAdminSyncButton: FC<SyncButtonProps> = ({
  basePath = '',
  label = 'dxMessages.companyManagement.account.sync',
  record,
  icon = defaultIcon,
  ...rest
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const handleDialogClose = () => setOpen(false);
  const handleClick = () => setOpen(true);

  const submit = async () => {
    setOpen(false);
    const data = await dataProvider.syncCompany(Constants.RESOURCE_ACCOUNT, {
      identification: record?.identification,
    });

    if (data.data.status === 200) {
      notify('dxMessages.companyManagement.account.success');
    } else {
      notify('dxMessages.companyManagement.account.error', 'error');
    }
  };

  const { permissions } = usePermissions();
  const showSyncButton = isPspAdministrator(permissions);
  if (!showSyncButton) {
    return null;
  }

  return (
    <>
      <Button label={label} {...(rest as any)} onClick={handleClick}>
        {icon}
      </Button>
      <Confirm
        isOpen={open}
        title='Sync'
        content='dxMessages.pspMessage'
        onConfirm={submit}
        onClose={handleDialogClose}
      />
    </>
  );
};

const defaultIcon = <SyncIcon />;

interface Props {
  basePath?: string;
  classes?: object;
  className?: string;
  icon?: ReactElement;
  label?: string;
  onClick?: (e: MouseEvent) => void;
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;

  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  undoable?: boolean;
}

export type SyncButtonProps = Props & ButtonProps;

PspAdminSyncButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  // @ts-ignore
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  undoable: PropTypes.bool,
  icon: PropTypes.element,
};

PspAdminSyncButton.defaultProps = {
  undoable: true,
};

export default PspAdminSyncButton;
