import { Chip, Grid, Paper, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslate } from 'react-admin';
import { checkFieldValidity, getSourceField } from '../utils';
import { useCommonFieldStyles } from './CommonFieldStyles';
import { FieldComponentProps } from './FieldComponentProps';
import { FormField } from './FormField';

/**
 * Renders a container (a Paper containing inputs)
 */
const ContainerField = (props: FieldComponentProps) => {
  const { field } = props;

  const translate = useTranslate();
  const classes = useCommonFieldStyles(props);

  const error = checkFieldValidity(field);
  if (error) {
    return error;
  }

  return (
    <Grid container item direction='column' xs={field?.options?.xs ?? 12}>
      <Grid item>
        <Typography className={classes.header} variant='h6'>
          {field?.options?.title &&
            field.title &&
            translate(field.title, { _: field.title })}
          {field.businessTerms?.map((s) => {
            return (
              <Chip
                label={s}
                size='small'
                style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                key={s}
              />
            );
          })}
        </Typography>
      </Grid>

      {field.items && (
        <Grid item>
          <Paper square className={classNames(classes.paper)}>
            <Grid container spacing={2}>
              {field.items.map((item, idx) => {
                var itemProps = { ...props };
                itemProps.field = item;
                itemProps.source = getSourceField(item.source); // need to set it here
                return <FormField {...itemProps} key={`${item.name}_${idx}`} />;
              })}
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default ContainerField;
