import {
  cellStyle,
  DateInput,
  GenericDateField,
  GenericSimpleField,
  Metadata,
  widthPresets,
} from '@dx-ui/dx-common';
import React from 'react';
import { TextInput } from 'react-admin';

export const ContractIdField = (props) => <GenericSimpleField {...props} />;

export const ContractDateField = (props) => <GenericDateField {...props} />;

export const createColumnsContract = () => [
  {
    id: Metadata.contractId,
    label: 'dxMessages.headers.contractId',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <ContractIdField {...props} />,
  },
  {
    id: Metadata.contractDate,
    label: 'dxMessages.headers.contractDate',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <ContractDateField {...props} />,
  },
];

export const createContractFilters = () => [
  {
    id: Metadata.contractId,
    filter: (props) => (
      <TextInput
        source={Metadata.contractId}
        label='dxMessages.headers.contractId'
        resettable
        {...props}
      />
    ),
  },
  {
    id: Metadata.contractDate,
    filter: (props) => (
      <DateInput
        source={Metadata.contractDate}
        label='dxMessages.headers.contractDate'
        {...props}
      />
    ),
  },
];
