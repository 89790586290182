import { Constants } from '@dx-ui/dx-common/src';
import { Button } from '@material-ui/core';
import {
  EditableDatagrid,
  RowForm,
  RowFormProps,
} from '@react-admin/ra-editable-datagrid';
import {
  AutocompleteInput,
  DateField,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';

const ProductForm = (props: Omit<RowFormProps, 'children'>) => {
  const refresh = useRefresh();
  const handleRefresh = () => {
    refresh();
  };
  return (
    <RowForm {...props} onSuccess={handleRefresh}>
      <ReferenceInput
        label={'dxMessages.companyManagement.productUsage.product'}
        source='iddxproduct'
        filter={{ idaccountrelation: props.initialValues.idaccountrelation }}
        reference={Constants.RESOURCE_PRODUCT_FOR_RELATION_PRODUCTS}
        validate={required()}
        filterToQuery={(searchText) => ({
          description: [searchText],
        })}
        sort={{ field: 'description', order: 'ASC' }}
      >
        <AutocompleteInput optionText='description' />
      </ReferenceInput>
    </RowForm>
  );
};

const ProductEditForm = (props: Omit<RowFormProps, 'children'>) => {
  const refresh = useRefresh();
  const handleRefresh = () => {
    refresh();
  };

  return (
    <RowForm {...props} onSuccess={handleRefresh}>
      <ReferenceInput
        label={'dxMessages.companyManagement.productUsage.product'}
        source='iddxproduct'
        reference={Constants.RESOURCE_PRODUCT_FOR_RELATION_PRODUCTS}
        validate={required()}
        filterToQuery={(searchText) => ({
          description: [searchText],
        })}
        filter={{ idaccountrelation: props.initialValues.idaccountrelation }}
        sort={{ field: 'description', order: 'ASC' }}
      >
        <AutocompleteInput optionText='description' disabled />
      </ReferenceInput>
    </RowForm>
  );
};

export const AccountRelationEdit: any = ({ setEdit, ...props }) => {
  const notify = useNotify();
  const { id } = props;
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.updated');
    if (setEdit !== undefined) {
      setEdit(false);
    } else {
      redirect('list', props.basePath);
    }
  };

  const CustomEditToolbar = (props) => (
    <Toolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SaveButton
          {...props}
          style={{ display: 'flex', alignItems: 'center' }}
        />
        <CancelButton />
      </div>
      <DeleteWithConfirmButton onSuccess={() => setEdit(false)} />
    </Toolbar>
  );

  const CancelButton = () => {
    const translate = useTranslate();
    return (
      <Button
        onClick={() => {
          if (setEdit !== undefined) {
            setEdit(false);
          } else {
            redirect('list', props.basePath);
          }
        }}
        variant='contained'
      >
        {translate('ra.action.cancel')}
      </Button>
    );
  };

  return (
    <Edit
      {...props}
      onSuccess={onSuccess}
      undoable={false}
      id={props.props ? props.props.record.id : id}
      resource={Constants.RESOURCE_SUPPLIERS}
    >
      <SimpleForm toolbar={<CustomEditToolbar />}>
        <ReferenceInput
          label='dxMessages.companyManagement.accountRelation.customer'
          source='idcustomer'
          reference='management/company'
          link={false}
          sortable={false}
        >
          <AutocompleteInput
            source='accountname'
            optionText='accountname'
            fullwidth
            disabled
          />
        </ReferenceInput>

        <ReferenceInput
          label='dxMessages.companyManagement.accountRelation.supplier'
          source='idsupplier'
          reference='management/company'
          link={false}
        >
          <AutocompleteInput
            source='accountname'
            optionText='accountname'
            fullwidth
            disabled
          />
        </ReferenceInput>

        <TextInput
          label={
            'dxMessages.companyManagement.accountRelation.suppliercode4customer'
          }
          source='supplierCode4Customer'
        />
        <DateTimeInput
          label={'dxMessages.companyManagement.audit.tsChange'}
          source='tsChange'
          fullWidth
          disabled
        />
        <TextInput
          label={'dxMessages.companyManagement.audit.userIdChange'}
          source='userIdChange'
          fullWidth
          disabled
        />
        <ReferenceManyField
          filter={{
            idaccountrelation: props.props ? props.props.record.id : id,
          }}
          reference={Constants.RESOURCE_PRODUCT_FOR_RELATION}
          target='idproductusage'
        >
          <EditableDatagrid
            mutationMode='undoable'
            createForm={
              <ProductForm
                initialValues={{
                  idaccountrelation: props.props ? props.props.record.id : id,
                }}
              />
            }
            editForm={
              <ProductEditForm
                initialValues={{
                  idaccountrelation: props.props ? props.props.record.id : id,
                }}
              />
            }
          >
            <ReferenceField
              label={'dxMessages.companyManagement.productUsage.product'}
              source='iddxproduct'
              reference='management/dxproduct'
              link={false}
              sortable={false}
            >
              <TextField source='description' />
            </ReferenceField>
            <DateField source='tsChange' showTime />
            <TextField source='userIdChange' />
          </EditableDatagrid>
        </ReferenceManyField>
      </SimpleForm>
    </Edit>
  );
};

export default AccountRelationEdit;
