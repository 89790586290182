/**
 * The docXchange dashboard (set as the landing page of the App).
 *
 * The dashboard is a collection of widgets. The first time the user logins,
 * the dashboard displays a default collection of widgets.
 *
 * Then the user can change the widget locations, their size, remove them, or add new
 * ones.
 *
 * There is an exception: the <WelcomeBanner/> widget is always on screen,
 * at the top of the dashboard. It's a static widget (not moveable, not removeable,
 * not resizable).
 *
 * When the user owns no right to access docXchange, the dashboard is replaced by
 * a 'Please subscribe...' warning message.
 */
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { GA_EVENTS, sendGAEvent, useGAPageViews } from '@dx-ui/dx-common/src';
import { Account } from '@dx-ui/dx-common/src/configuration/types';
import DashboardConfigurationDrawer from '@dx-ui/dx-common/src/layout/Dashboard/DashboardConfigurationDrawer';
import Widget from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { usePreferences } from '@react-admin/ra-preferences';
import { clone } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Loading, Title, useGetIdentity, useTranslate } from 'react-admin';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useSelector } from 'react-redux';
import 'react-resizable/css/styles.css';
import Widgets, { WidgetConfiguration, WidgetKey } from './Widgets';

// The default layout of the dashboard when there is no user preferences
// specifying the user choice. Just contain the welcome widgets.
// cf https://docprocess.atlassian.net/browse/DXPOR-5185
const DEFAULT_LAYOUT = {
  lg: [
    {
      i: 'WelcomeBanner',
      x: 0,
      y: 0,
      ...Widgets['WelcomeBanner'].size,
      static: true,
    },
    {
      w: 6,
      h: 1,
      minH: 1,
      maxH: 1,
      x: 0,
      y: 2,
      i: '1664282514810@InvoiceCount',
      moved: false,
      static: false,
      isDraggable: true,
    },
    {
      w: 6,
      h: 1,
      minH: 1,
      maxH: 1,
      x: 6,
      y: 3,
      i: '1664282520715@OrderCount',
      moved: false,
      static: false,
      isDraggable: true,
    },
    {
      w: 6,
      h: 1,
      minH: 1,
      maxH: 1,
      x: 0,
      y: 3,
      i: '1664282579883@RecadvCount',
      moved: false,
      static: false,
      isDraggable: true,
    },
    {
      w: 6,
      h: 1,
      minH: 1,
      maxH: 1,
      x: 6,
      y: 2,
      i: '1664282592866@DesadvCount',
      moved: false,
      static: false,
      isDraggable: true,
    },
    {
      w: 12,
      h: 5,
      minH: 5,
      maxH: 5,
      x: 0,
      y: 4,
      i: '1664282601132@InvoiceCountPerDayAndStatus',
      moved: false,
      static: false,
      isDraggable: true,
    },
    {
      w: 12,
      h: 5,
      minH: 5,
      maxH: 5,
      x: 0,
      y: 9,
      i: '1664282604157@DocumentReceptions',
      moved: false,
      static: false,
      isDraggable: true,
    },
  ],
};

// The width of the configuration drawer in viewport units.
export const DASHBOARD_CONF_DRAWER_WIDTH_IN_WV = 31 as const;

// Use a responsive dashboard layout to redraw all the widgets when the
// viewport changes.
const ResponsiveGridLayout = WidthProvider(Responsive);

// The GraphQL analytics endpoint.
const graphqlHttpLink = createHttpLink({
  uri: '/graphql',
});

// The graphql client, passed by context to all the widgets.
const client = new ApolloClient({
  // The `from` function combines an array of individual links
  // into a link chain.
  link: from([graphqlHttpLink]),
  cache: new InMemoryCache(),
  // Enable sending cookies over cross-origin requests. Especially the SSO
  // token.
  credentials: 'include',
  defaultOptions: {
    query: { errorPolicy: 'none' },
  },
});

const isCorruptedConfiguration = (lg: any): boolean => {
  if (typeof lg.x !== 'number') {
    return true;
  }
  if (typeof lg.y !== 'number') {
    return true;
  }
  if (typeof lg.w !== 'number') {
    return true;
  }
  if (typeof lg.h !== 'number') {
    return true;
  }
  if (typeof lg.i !== 'string') {
    return true;
  }
  return false;
};

/**
 * The dashboard or an information panel when user doesn't own the right
 * to access the App.
 */
const Dashboard = () => {
  const { identity, loading } = useGetIdentity();
  useGAPageViews();

  if (loading) return <Loading />;

  // Depending on user owns the DxPurchase feature or is a PSP administrator,
  // display the dashboard or a 'Please subscribe' warning message.
  const account = identity as Account;
  const allowed = account.configuredUsageRights
    .concat(account.delegatedUsageRights)
    .concat(account.onTheFlyUsageRights)
    .some(
      (_) =>
        _.feature.id === 'DxPurchase' ||
        (_.feature.id === 'administration' &&
          _.roles.find((r) => r.id === 'PSP administrator'))
    );

  return allowed ? (
    <MyDashboard />
  ) : (
    <PleaseSubscribe account={identity as Account} />
  );
};

const PleaseSubscribe: FC<{ account: Account }> = ({ account }) => {
  const translate = useTranslate();

  const firstname = account?.person?.firstname || '';
  return (
    <>
      <Title
        title={translate('dxMessages.dashboard.Welcome', {
          firstname,
        })}
      />
      <Alert severity='error' style={{ margin: '1em' }}>
        <Typography style={{ maxWidth: '1000px' }}>
          {translate('dxMessages.dashboard.noAccessRightForDocXchange')}
        </Typography>
      </Alert>
    </>
  );
};

const MyDashboard = () => {
  const classes = useStyles();
  const translate = useTranslate();
  // 'viewVersion' gets incremented on each refresh (the react-admin one, with the button in the App bar).
  const viewVersion = useSelector((state: any) => state.admin.ui.viewVersion);
  const { identity, loading } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  // The widgets to put on screen and their layouting, both retrieved
  // from the user preferences (if any) otherwise the default ones.
  let [layouts, setLayouts] = usePreferences(
    'dxportal.dashboard.grid-layout',
    DEFAULT_LAYOUT
  );

  const [widgetsConfig, setWidgetsConfig] = usePreferences(
    'dxportal.dashboard.cfg',
    {}
  );

  // Removes corrupted widget layout configurations
  const _setLayouts = (layouts) => {
    setLayouts({
      lg: [...layouts.lg.filter((_) => !isCorruptedConfiguration(_))],
    });
  };

  // Toggle the dashboard configuration drawer on/off.
  const [openConfiguration, setOpenConfiguration] = useState(false);

  // When user hits the soft refresh button, clear the Apollo client cache
  // so analytic queries gets re-run.
  useEffect(
    () => {
      client.resetStore();
    }, // The effect depends on the view version so get called on each refresh.
    [viewVersion]
  );

  const toggleConfiguration =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      )
        return;
      setOpenConfiguration(open);
      if (open) {
        sendGAEvent(
          GA_EVENTS.categories.DASHBOARD.name,
          GA_EVENTS.categories.DASHBOARD.actions.CONFIGURE,
          account?.company?.cmsRootDir
        );
      }

      // Toggling the configuration drawer changes the dashboard size so
      // send a browser resize event (react-grid-layout listens to it)
      // to trigger the re-computing of the layout.
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1);
    };

  if (loading || !identity) return <Loading />;

  // Remove from the layout the widgets the user is not allowed to access to
  // (may happen when the user rights changed: the layout in the user
  // preferences doesn't reflect this change, so may contain a widget
  // the user has no more access to).
  layouts = {
    lg: layouts.lg
      .filter(
        (_) =>
          _.i === '__dropping-elem__' ||
          Widgets[toWidgetKey(_.i)]?.isAllowedFor(identity as Account)
      )
      .filter((_) => !isCorruptedConfiguration(_)),
  };

  const firstname = identity?.person?.firstname || '';

  // Redraw the dashboard when a widget gets closed by the user.
  const onClose = (instance: string) => {
    _setLayouts({
      lg: layouts.lg.filter((_) => _.i !== instance),
    });

    const newConfig = clone(widgetsConfig);
    delete newConfig[instance];
    setWidgetsConfig(newConfig);
  };

  // When moving a widget to add over the dashboard, display the drop zone.
  const onLayoutChange = (layout, layouts) => {
    // See react-grid-layout for a description of '__dropping-elem__'.
    if (!layout.some((li) => li.i === '__dropping-elem__')) {
      if (layouts.lg !== undefined) {
        if (layouts.lg.length !== 0) {
          layouts.lg.forEach((l: any) => {
            if (l.minH === undefined) {
              l.minH = l.h;
            }
            if (l.maxH === undefined) {
              l.maxH = l.h;
            }
          });
        }
      }
      _setLayouts(layouts);
    }
  };

  // Redraw the dashboard when a widget gets added on screen by the user.
  // The key of the widget is in the data transfer info.
  const onDrop = (layout, layoutItem, ev) => {
    const widgetKey = ev.dataTransfer.getData('text/plain');
    _setLayouts({
      lg: [
        ...layout.filter((li) => li.i !== '__dropping-elem__'),
        {
          ...layoutItem,
          i: createWidgetInstance(widgetKey),
        },
      ],
    });
  };

  // Passed to the widgets so they can resize themselves.
  const updateSize = (instance: WidgetInstance) => (w: number, h: number) =>
    _setLayouts({
      lg: layouts.lg.map((widget) => {
        if (widget.i === instance) return { ...widget, w, h };
        return widget;
      }),
    });

  // Passed to the widgets (at least usefull for the WelcomeBanner one)
  // in order to let them able to set the dashboard to the default layout
  const resetDashboardToDefault = () => {
    setLayouts(DEFAULT_LAYOUT);
    setWidgetsConfig({});
  };

  // The widgets to list in the configuartion drawer, that is:
  // (1) the ones which are not singleton.
  // (2) the ones which are singleton but not on screen yet.
  // For both, the ones the user has the permissions on.
  const droppableWidgets = Object.keys(restrict(identity as Account, Widgets))
    .filter(
      (key) =>
        !Widgets[key].singleton ||
        !layouts.lg.some((_) => key === toWidgetKey(_.i))
    )
    .reduce((acc, key) => {
      acc[key] = Widgets[key];
      return acc;
    }, {} as Record<WidgetKey, WidgetConfiguration>);

  // Compute the height of the dashboard in pixels.
  // ~ (highest widget row + its height) * (100 + 20)
  // 100 => the row heights, 20 => (10 + 10) the margin beetween widgets.
  const height = Math.max(...layouts.lg.map((l) => l.y + l.h)) * 120;

  return (
    <>
      {/* The button to toggle the configuration. */}
      <div className={classes.configure}>
        <DashboardConfigurationDrawer
          open={openConfiguration}
          toggle={toggleConfiguration}
          droppableWidgets={droppableWidgets}
          drawerWidth={DASHBOARD_CONF_DRAWER_WIDTH_IN_WV}
          Widgets={Widgets}
        />
      </div>
      {/* When the dashboard configuration drawer is open, shift the dashboard
      to the left to make room for the dashboard. */}
      <div
        className={openConfiguration ? classes.shiftLeft : classes.shiftRight}
      >
        {/* Make teh graphql client accessible to any widget. */}
        <ApolloProvider client={client}>
          <Box m={1} mr={2.5}>
            <Title
              title={translate('dxMessages.dashboard.Welcome', {
                firstname,
              })}
            />
            <ResponsiveGridLayout
              // To keep the dropzone covering at least the browser viewport,
              // put the min Height.
              style={{
                minWidth: '100%',
                minHeight: height,
                maxHeight: height,
                height: height,
              }}
              className='layout'
              containerPadding={[10, 10]}
              layouts={layouts}
              breakpoints={{ lg: 1200 }}
              cols={{ lg: 12 }}
              rowHeight={100}
              isResizable={true}
              // When the drawer is open, there is a 'close' icon in the top-right corner
              // so put the resize handle on the bottom-right corner.
              resizeHandles={openConfiguration ? ['se'] : ['ne']}
              isDroppable={true}
              onLayoutChange={onLayoutChange}
              onDrop={onDrop}
              // Returns the size of the widget to add when moving it over the dashboard.
              onDropDragOver={(e) => {
                const size = e.dataTransfer.types.find(
                  (_) => _ !== 'text/plain'
                );
                return JSON.parse(size);
              }}
              draggableCancel='.outsideDashboardGrip'
            >
              {layouts.lg
                .filter(
                  (_) =>
                    _.i !== '__dropping-elem__' && !!Widgets[toWidgetKey(_.i)]
                )
                .map((_) => {
                  const cfg = Widgets[toWidgetKey(_.i)];
                  return (
                    <div key={_.i}>
                      <Widget
                        onTheShelves={false}
                        close={
                          openConfiguration && cfg.closeable
                            ? () => onClose(_.i)
                            : undefined
                        }
                        singleton={cfg.singleton}
                      >
                        {React.createElement(cfg.content, {
                          onTheShelves: false,
                          account: identity as Account,
                          userPreferencesRootKey: `dxportal.dashboard.cfg.${_.i}`,
                          updateSize: updateSize(_.i),
                          resetDashboardToDefault: resetDashboardToDefault,
                          openConfiguration: openConfiguration,
                        })}
                      </Widget>
                    </div>
                  );
                })}
            </ResponsiveGridLayout>
          </Box>
        </ApolloProvider>
      </div>
    </>
  );
};

type WidgetInstance = string;

/**
 * Extracts the widget key out from its instance.
 */
const toWidgetKey = (instance: WidgetInstance): WidgetKey =>
  instance.substring(instance.lastIndexOf('@') + 1);

const createWidgetInstance = (key: WidgetKey): WidgetInstance =>
  `${new Date().getTime()}@${key}`;

// Restricts to the widgets the user can access to.
const restrict = (
  account: Account,
  widgets: Record<WidgetKey, WidgetConfiguration>
) => {
  return Object.keys(widgets).reduce((acc, key) => {
    if (widgets[key].isAllowedFor(account)) acc[key] = widgets[key];
    return acc;
  }, {} as Record<WidgetKey, WidgetConfiguration>);
};

const useStyles = makeStyles((theme) => ({
  // Fix the configuration drawer button into the top right corner.
  configure: {
    position: 'fixed',
    // Below the toolbar.
    ...theme.mixins.toolbar,
    right: 0,
    marginTop: '1em',
    zIndex: 4000,
  },
  // When the configuration drawer is open, shift the dashboard to
  // the left (same size as the drawer) so the drawer does not overlap
  // the dashboard.
  shiftLeft: {
    marginRight: `${DASHBOARD_CONF_DRAWER_WIDTH_IN_WV}vw`,
    // Diagonal grey stripes.
    background: `repeating-linear-gradient(-55deg,${theme.palette.grey[100]},${theme.palette.grey[100]} 10px,${theme.palette.grey[50]} 10px,${theme.palette.grey[50]} 20px)`,
  },
  shiftRight: {
    marginLeft: '0px',
  },
}));

export default Dashboard;
