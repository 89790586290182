import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
import { colors } from '../../constants/Colors';

const isChrome = /Chrome/.test(navigator.userAgent);

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'block',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    chromeWrapper: {
      display: 'inline-block',
    },
    placeholder: {
      color: 'rgba(0, 0, 0, 0.42)',
    },
    iconWrapper: {
      '& > svg': {
        verticalAlign: 'middle',
        color: colors.black65,
        marginLeft: '8px',
        marginRight: '8px',
      },
      '& > :global(.material-icons)': {
        verticalAlign: 'middle',
        color: colors.black65,
        marginLeft: '8px',
        marginRight: '8px',
      },
    },
    countIcon: {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      borderColor: colors.black65,
      borderWidth: '1px',
      borderStyle: 'solid',
      marginRight: 8,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  {
    name: 'SelectRenderer',
  }
);

const emptyPlaceholder = ['_'];

export function withPlaceholder(value) {
  return value || '_';
}

export function withoutPlaceholder(value) {
  return value === '_' ? null : value;
}

export const withPlaceholderMultiple = (value) =>
  value.length ? value : emptyPlaceholder;

// Types as optionally string to make it easier to do withoutPlaceholderMultiple(evt.target.value)
export const withoutPlaceholderMultiple = (value) =>
  value.filter((x) => x !== '_');

export const SelectRenderer = (props) => {
  const { icon, value, children, placeholder, multiple } = props;

  const classes = useStyles();

  const hasPlaceholder =
    placeholder !== undefined &&
    (multiple ? !value.length || value[0] === '_' : value === '_');

  const text = hasPlaceholder ? (
    <span className={classes.placeholder}>{placeholder}</span>
  ) : typeof children === 'function' ? (
    multiple ? (
      !!value.length &&
      value[0] !== '_' &&
      (() => {
        const valueArray = value;
        const { length } = valueArray;
        return (
          <span className={classNames(isChrome && classes.chromeWrapper)}>
            {length > 1 && (
              <div className={classes.countIcon}>
                <div>{length}</div>
              </div>
            )}
            {valueArray.map((item, index) => [
              !!index && <span key='comma'>, </span>,
              <span key='item'>{children(item, index, length)}</span>,
            ])}
          </span>
        );
      })()
    ) : (
      children(value === '_' ? undefined : value, 1, 1)
    )
  ) : (
    children
  );

  return (
    <div className={classNames(classes.root, !!icon && classes.iconWrapper)}>
      {icon}
      {text}
    </div>
  );
};

export default SelectRenderer;
