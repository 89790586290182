/**
 * The drawer holding the widgets not on screen yet so user can drag and drop
 * them on his dashboard (or remove ones already on screen).
 */
import { GA_EVENTS, sendGAEvent } from '@dx-ui/dx-common/src';
import { Account } from '@dx-ui/dx-common/src/configuration/types';
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { FC, Fragment, useEffect, useRef } from 'react';
import { Loading, useGetIdentity, useTranslate } from 'react-admin';
import isEqual from 'react-fast-compare';
import Widget, { WidgetConfiguration, WidgetKey } from './Widget';

/**
 * The configuration drawer.
 *
 * It displays the available widgets on one column.
 *
 * @param droppableWidgets the available widgets, ready to be dropped
 *  on the dashboard
 * @returns
 */
const DashboardConfigurationDrawer: FC<{
  open: boolean;
  toggle: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  droppableWidgets: Record<WidgetKey, WidgetConfiguration>;
  drawerWidth: number;
  Widgets: Record<WidgetKey, WidgetConfiguration>;
}> = ({ open, toggle, droppableWidgets = {}, drawerWidth, Widgets }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { identity, loading } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  // When listing the available widgets, always start first by the ones
  // just removed by the user (if any).
  const prevDroppableWidgets = usePrevious(droppableWidgets) || {};
  const sortedWidgetKeys = Object.keys(droppableWidgets).sort((k1, k2) => {
    if (
      !prevDroppableWidgets.hasOwnProperty(k1) &&
      !prevDroppableWidgets.hasOwnProperty(k2)
    )
      return 0;
    if (!prevDroppableWidgets.hasOwnProperty(k1)) return -1;
    if (!prevDroppableWidgets.hasOwnProperty(k2)) return 1;
    return 0;
  });
  // Compute the list of categories the widgets belongs to.
  const categories = Object.values(droppableWidgets)
    .map((_) => _.category)
    // Remove duplicates.
    .filter((item, pos, self) => self.indexOf(item) === pos)
    .sort();

  if (loading) return <Loading />;

  return (
    <>
      {/* The button to open the drawer */}
      <Tooltip title={translate('dxMessages.dashboard.DesignYourHomePage')}>
        <IconButton onClick={toggle(true)} size='small'>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor='right'
        open={open}
        variant='persistent'
        onClose={toggle(false)}
      >
        <>
          {/* Make the close button sticky so when scrolling the widget list,
              the button stays on screen. */}
          <div
            style={{
              position: 'sticky',
              top: '90px',
              right: `calc(${drawerWidth}vw - 10px)`,
              zIndex: 10000,
            }}
          >
            <IconButton size='small' onClick={toggle(false)}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Grid container direction='column'>
            {/* Empty space to start displaying below the app bar. */}
            <Grid item style={{ height: '85px' }} />
            <Grid item>
              <Box m={1} p={1} width={`calc(${drawerWidth}vw - 22px)`}>
                <Typography paragraph>
                  {translate('dxMessages.dashboard.ConfigurationDescription')}
                </Typography>
                <Typography variant='caption' paragraph>
                  <PlusOneIcon className={classes.plus} />{' '}
                  {translate(
                    'dxMessages.dashboard.ConfigurationSingletonDescription'
                  )}
                </Typography>
                <Grid container direction='column' spacing={1}>
                  {sortedWidgetKeys.length === 0 && (
                    <Typography variant='caption'>
                      {translate('dxMessages.dashboard.NoWidgetToAdd')}
                    </Typography>
                  )}
                  {categories.flatMap((category) =>
                    sortedWidgetKeys
                      .filter((key) => Widgets[key].category === category)
                      .map((key, i) => (
                        <Fragment key={`widget-${key}`}>
                          {i === 0 && (
                            <Grid
                              item
                              xs={12}
                              style={{ marginTop: '2em', marginBottom: '1em' }}
                            >
                              <Typography variant='h5'>
                                {translate(
                                  `dxMessages.dashboard.categories.${category}`
                                )}
                              </Typography>
                              <Divider />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <div
                              draggable={true}
                              unselectable='on'
                              onDragStart={(e) => {
                                // The dropped zone needs two data: the widget key
                                // and its size. The data transfer API provides no way
                                // to cleanly hold this info so passing them that way:
                                e.dataTransfer.setData('text/plain', key);
                                e.dataTransfer.setData(
                                  JSON.stringify(Widgets[key].size),
                                  ''
                                );
                                sendGAEvent(
                                  GA_EVENTS.categories.DASHBOARD.name,
                                  GA_EVENTS.categories.DASHBOARD.actions
                                    .DRAG_WIDGET,
                                  account?.company?.cmsRootDir,
                                  key
                                );
                              }}
                              // Add a pulse effect on the widgets the user
                              // just removed from the dashboard to show where
                              // a widget is going when clicked on its close button.
                              className={
                                prevDroppableWidgets.hasOwnProperty(key)
                                  ? ''
                                  : classes.added
                              }
                            >
                              <Widget
                                onTheShelves
                                singleton={Widgets[key].singleton}
                              >
                                {React.createElement(Widgets[key].content, {
                                  onTheShelves: true,
                                  account: identity! as Account,
                                  userPreferencesRootKey: `dxportal.dashboard.cfg.${key}`,
                                  updateSize: () => {},
                                  openConfiguration: open,
                                })}
                              </Widget>
                            </div>
                          </Grid>
                        </Fragment>
                      ))
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </>
      </Drawer>
    </>
  );
};

// See https://usehooks.com/usePrevious/
const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class.
  const ref = useRef();
  // Store current value in ref.
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes.
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

const useStyles = makeStyles((theme) => ({
  // Pulse effect on widgets just added back to the configuration panel
  // (just removed out of the dashboard by user).
  added: {
    animation: '$pulse 1s ease',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.5, 0.5)',
    },
    '50%': {
      transform: 'scale(1.1, 1.1)',
    },
    '100%': {
      transform: 'scale(1, 1)',
    },
  },
  // The plus icon on the top right side of the widget when in the drawer and
  // meaning you can drag more than one widget of this type on the dashboard.
  plus: {
    color: theme.palette.common.white,
    width: '18px',
    height: '18px',
    backgroundColor: theme.palette.success.main,
    borderRadius: '50%',
    padding: '2px',
  },
}));

export default React.memo(DashboardConfigurationDrawer, (r1, r2) => {
  const equal =
    r1.open === r2.open &&
    isEqual(Object.keys(r1.droppableWidgets), Object.keys(r2.droppableWidgets));

  return equal;
});
