// A task UI for testing purpose.
import { DxTheme } from '@dx-ui/dx-common';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { TextInput, Title, useTranslate } from 'react-admin';
import {
  createColumnsProcessDetails,
  createCommonColumns,
} from '../../../../../aspects';
import ClusterPanel from '../common/ClusterPanel';
import RejectionReasonSelect from './RejectionReasonSelect';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      '& > div': {
        boxShadow: 'unset',
      },
    },
    header: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
  }),
  { name: 'DocumentDisplay' }
);

// The columns to display in the cluster panel.
const columns: any[] = createCommonColumns()
  .concat(createColumnsProcessDetails())
  .filter((column) => column.displayInCluster === true);

// Make the document ID not clickable as we have the preview in the expand panel.
Object.values(columns).forEach((value: any) => {
  if (value.id === 'edm:documentId') {
    value.readOnly = true;
    value.label = 'dxMessages.headers.documentId';
  }
});

const DocumentDisplay = (props) => {
  const { resource, record, id, type } = props;
  const translate = useTranslate();
  const classes = useStyles();

  // Extract dxuids variable given by the flow. Eg. ['dx123', 'dx5432'];
  let clusterDxuids = record?.flow?.variables?.dxuids;
  return (
    <>
      <Title title='Approve or reject the invoice' />
      <Grid container direction='column'>
        <Grid item>
          <Typography className={classes.header} variant='h6'>
            {translate('dxMessages.task.saintgobainui.title', {
              _: 'Invoice and related CMR',
            })}
          </Typography>
          <ClusterPanel
            resource={resource}
            id={id}
            dxuids={clusterDxuids}
            columns={columns}
          />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            {type === 'sg_ap_validation' && (
              <Grid item xs={2}>
                <TextInput
                  source='input.data.sapId'
                  label='SAP ID'
                  variant='standard'
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={2}>
              <RejectionReasonSelect />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                source='input.data.additionalInformation'
                label='dxMessages.task.saintgobainui.additionalInfo'
                variant='standard'
                fullWidth
              />
              <TextInput
                style={{ display: 'none' }}
                source='input.action'
                initialValue='accept'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentDisplay;
