import { Ubl } from '@dx-ui/dx-common';
import _ from 'lodash';

// https://stackoverflow.com/questions/6393943/convert-javascript-string-in-dot-notation-into-an-object-reference/6394168

const formValues = {
  values: [
    {
      //DIFFERENT DEFAULT VALUES:
      templateType: 'P2P',
      [Ubl.paymentMeansCode]: 42,
      //LISTS:
      selectValues: {},
    },
  ],
};

/**
 * Copy values
 * from a bracket notation (ex: prop1.arr[0].field: 10)
 * to a dot notation (ex: prop1.arr:[{field: 10}])
 */
const setObjectProperties = (src, dest, rootFields) => {
  Object.keys(_.omit(src, rootFields) || {}).forEach((f) => {
    const temp = _.get(src, f);
    _.set(dest, f, temp);
  });

  rootFields.forEach((rootField) => {
    Object.keys(src[rootField] || {}).forEach((f) => {
      const temp = _.get(src[rootField], f);
      _.set(dest[rootField], f, temp);
    });
  });
};

const formValuesDotNotation = { values: [] };

formValues.values.forEach((v) => {
  let x = {
    properties: {},
    ublProperties: {},
    selectValues: {
      properties: {},
      ublProperties: {},
    },
  };
  setObjectProperties(_.omit(v, 'selectValues'), x, [
    'properties',
    'ublProperties',
  ]);
  setObjectProperties(v.selectValues, x.selectValues, [
    'properties',
    'ublProperties',
  ]);
  formValuesDotNotation.values.push(x);
});

export default formValuesDotNotation;
