import { DxTheme, EmptyValue } from '@dx-ui/dx-common';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { get } from 'lodash';
import { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { IWayBillSources } from '../WayBillSources';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    transportCostRow: {
      padding: '0 2em 0 0',
    },
    transportCost: {
      textAlign: 'right' as 'right',
    },
  }),
  { name: 'WayBillTransportCostRow' }
);
const replaceUndefinedWithEmpty = (element: any): JSX.Element => {
  return element ? element : EmptyValue;
};
interface WayBillTransportCostRowPropsModel {
  title: any;
  record: any;
  dataSources: IWayBillSources;
}
const WayBillTransportCostRow = (
  props: WayBillTransportCostRowPropsModel
): JSX.Element => {
  const { title, record, dataSources } = props;
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Fragment>
      <Grid container direction='row' className={classes.transportCostRow}>
        <Grid item xs={8}>
          <Typography variant='caption'>
            {translate(title)}
            <span style={{ marginRight: 2, marginLeft: 2 }}>:</span>
          </Typography>
        </Grid>
        <Grid item xs={4} className={classNames(classes.transportCost, title)}>
          {replaceUndefinedWithEmpty(
            get(record, dataSources.AmountSources.value)
          )}{' '}
          {replaceUndefinedWithEmpty(
            get(record, dataSources.AmountSources.currency)
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

interface WayBillTransportCostsFragmentPropsModel {
  record: any;
  dataSources: IWayBillSources;
}
const WayBillTransportCostsFragment = (
  props: WayBillTransportCostsFragmentPropsModel
): JSX.Element => {
  const { record, dataSources } = props;
  return (
    <Grid container direction='row' justify='flex-end'>
      <Grid item xs={4}>
        <Grid container direction='column'>
          {[
            dataSources.ConsignmentSources.transportCostBeforeFees,
            dataSources.ConsignmentSources.transportFees,
            dataSources.ConsignmentSources.transportCostIncludingFees,
          ].map((cost) => {
            return (
              <Grid item key={cost.title}>
                <WayBillTransportCostRow
                  title={cost.title}
                  record={get(record, cost.source)}
                  dataSources={dataSources}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WayBillTransportCostsFragment;
