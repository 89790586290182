// The list of user tasks.
import { makeStyles } from '@material-ui/core/styles';
import {
  Datagrid,
  Filter,
  FunctionField,
  List,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import { DxTheme } from '../../types';
import { GenericSimpleField } from '../fields';
import { MultiSelectInput, SelectInput } from '../inputs';
import { MessageState, MessageType } from './types';

const useStyles = makeStyles<DxTheme>(
  (theme) => ({
    root: {
      '& > div ': {
        boxShadow: 'unset',
      },
    },
  }),
  { name: 'MessageList' }
);

const Filters = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <MultiSelectInput
        label='dxMessages.messagesCenter.language.title'
        source='language'
        options={[
          { id: 'en', label: 'English' },
          { id: 'fr', label: 'Français' },
          { id: 'ro', label: 'Română' },
        ]}
      />
      <MultiSelectInput
        label='dxMessages.messagesCenter.type.title'
        source='types'
        options={[
          {
            id: MessageType.GENERAL,
            label: translate('dxMessages.messagesCenter.type.GENERAL'),
          },
          {
            id: MessageType.MARKETING,
            label: translate('dxMessages.messagesCenter.type.MARKETING'),
          },
          // {
          //   id: MessageType.DOWNTIME,
          //   label: translate('dxMessages.messagesCenter.type.DOWNTIME'),
          // },
          // {
          //   id: MessageType.BANNER,
          //   label: translate('dxMessages.messagesCenter.type.BANNER'),
          // },
        ]}
        alwaysOn
      />
      <SelectInput
        label='dxMessages.messagesCenter.state.title'
        source='state'
        choices={[
          {
            id: MessageState.DRAFT,
            name: translate('dxMessages.messagesCenter.state.DRAFT'),
          },
          {
            id: MessageState.PUBLISHED,
            name: translate('dxMessages.messagesCenter.state.PUBLISHED'),
          },
        ]}
      />
      <TextInput
        source='topic'
        label={'dxMessages.messagesCenter.topic.title'}
      />
    </Filter>
  );
};

const MessageList: any = ({ permissions, ...props }) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      filters={<Filters />}
      exporter={false}
      bulkActionButtons={false}
      classes={{ root: classes.root }}
    >
      <Datagrid rowClick='show'>
        <FunctionField
          source='type'
          label='dxMessages.messagesCenter.type.title'
          render={(record) => {
            const type = record?.type.toUpperCase();
            const label = `dxMessages.messagesCenter.type.${type}`;
            return <GenericSimpleField label={label} />;
          }}
        />
        <TextField
          source='topic'
          label={'dxMessages.messagesCenter.topic.title'}
        />
        <TextField
          source='language'
          label='dxMessages.messagesCenter.language.title'
        />
        {/* <DateField source='fromInstant' />
        <DateField source='toInstant' /> */}
        <FunctionField
          source='state'
          label='dxMessages.messagesCenter.state.title'
          render={(record) => {
            const label = `dxMessages.messagesCenter.state.${record?.state}`;
            return <GenericSimpleField label={label} />;
          }}
        />
      </Datagrid>
    </List>
  );
};

export default MessageList;
