import { Box, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import get from 'lodash/get';
import * as React from 'react';
import { FC } from 'react';
import {
  ArrayInput,
  email,
  FieldProps,
  Identifier,
  linkToRecord,
  Loading,
  RecordMap,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  useGetList,
  useGetOne,
  useTranslate,
} from 'react-admin';
import { Field, useFormState } from 'react-final-form';
import { Link } from 'react-router-dom';
import { LOGO_AND_LABELS } from '../identityprovider';
import { Account, Company, IdentityProvider } from '../types';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The edition of the identities a person may use to login into his account.
 */
const Identities: FC<
  FieldProps<Account> & { companyId: Identifier; disabled: boolean }
> = ({ companyId, disabled }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { values } = useFormState({ subscription: onValuesChangeSubscription });

  // Fetch the company owning the account to get the identity providers
  // it supports (Doc Process built-in login, Google social login, ...).
  const {
    data: company,
    loading: companyLoading,
    error: companyError,
  } = useGetOne<Company>('configuration-companies', companyId);

  // Fetch all the identity providers supported by the Doc Process platform.
  // We need both the company supported providers and the platform ones when
  // displaying the identities of a person: he may own an identity whose
  // provider has been removed from the company list.
  const {
    data: allIdentityProviders,
    loading: providersLoading,
    error: providersErrors,
  } = useGetList<IdentityProvider>('configuration-identityProviders', {
    page: 1,
    perPage: 10,
  });

  if (companyLoading || providersLoading) return <Loading />;
  if (companyError || providersErrors || !company || !allIdentityProviders)
    return null;

  const companyIdentityProviders = company.allowedIdentityProviders;

  // If there is only the Doc Process provider, disable the Add button after once
  // configured login.
  const disableAdd =
    companyIdentityProviders.length === 1 &&
    companyIdentityProviders[0].type === 'BUILT_IN' &&
    get(values, 'person.identities', []).length > 0;

  return (
    <Paper className={classes.paper}>
      <Typography
        variant='h5'
        style={{ color: !disabled ? 'black' : 'lightgray' }}
      >
        {translate('resources.identityProviders.common.authentication')}
      </Typography>
      <Typography
        variant='caption'
        style={{ color: !disabled ? 'black' : 'lightgray' }}
      >
        {translate('resources.identityProviders.common.authenticationHelper')}
        <IconButton
          color='primary'
          component={Link}
          to={linkToRecord('/configuration-companies', companyId)}
          size='small'
        >
          <EditIcon style={{ width: '16px', height: '16px' }} />
        </IconButton>
        .
      </Typography>
      <ArrayInput
        label=''
        source='person.identities'
        disabled={disabled}
        validate={validateOnlyOneDxIdentity}
      >
        <SimpleFormIterator disableAdd={disableAdd}>
          <Identity
            allIdentityProviders={allIdentityProviders}
            allowedIdentityProviders={companyIdentityProviders}
            disabled={disabled}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Paper>
  );
};

const validateOnlyOneDxIdentity = (values) => {
  return (values || []).filter((id) => id?.provider?.id === 'Doc Process')
    .length > 1
    ? 'resources.identityProviders.common.errors.onlyOneDxIdentity'
    : undefined;
};

const Identity: FC<
  FieldProps<Account> & {
    allIdentityProviders: RecordMap<IdentityProvider>;
    allowedIdentityProviders: Array<IdentityProvider>;
    disabled: boolean;
  }
> = ({
  record,
  source,
  allIdentityProviders,
  allowedIdentityProviders,
  disabled,
}) => {
  const translate = useTranslate();
  const { values } = useFormState({ subscription: onValuesChangeSubscription });

  // The already configured identities only contains the ID of their identity provider
  // (Google, ...). As we want also the type of the providers (OpenId Connect, ...),
  // get it from allIdentityProviders.
  const providerId = get(values, `${source}.provider.id`);
  // TBD: Temporary fix for NEXT button in Create Account
  let provider = (providerId && allIdentityProviders[providerId]) || {
    type: 'BUILT_IN',
    id: 'Doc Process',
  };

  const validateDxIdentityId = [required(), email()];

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        {/* Put the provider type, hidden, in the form. */}
        <Field
          name={`${source}.provider.type`}
          component='input'
          type='hidden'
          initialValue={provider?.type}
        />
        <SelectInput
          label='resources.identityProviders.common.loginType'
          source={`${source}.provider.id`}
          choices={allowedIdentityProviders}
          validate={required()}
          optionText={<IdentityProviderOptionText />}
          disabled={disabled}
          fullWidth
        />
      </Grid>
      {provider?.type === 'OPEN_ID_CONNECT' && (
        <Grid item xs={9}>
          <TextInput
            label='resources.identityProviders.common.email'
            source={`${source}.id`}
            validate={required()}
            disabled={disabled}
            fullWidth
            helperText={translate(
              'resources.identityProviders.common.emailOpenIdConnectHelper',
              { providerId: provider.id.toUpperCase() }
            )}
          />
        </Grid>
      )}
      {provider?.type === 'BUILT_IN' && (
        <Grid item xs={9}>
          <TextInput
            label='resources.identityProviders.common.email'
            source={`${source}.id`}
            defaultValue={values?.person?.email || record?.person?.email || ''}
            validate={validateDxIdentityId}
            disabled={disabled}
            fullWidth
            helperText='resources.identityProviders.common.emailBuiltInHelper'
          />
        </Grid>
      )}
    </Grid>
  );
};

const IdentityProviderOptionText = ({ record }: any) => {
  if (!LOGO_AND_LABELS[record.id]) return <span>{record.id}</span>;

  return (
    <Box display='flex' alignItems='center'>
      <img
        src={LOGO_AND_LABELS[record.id].logo}
        alt={LOGO_AND_LABELS[record.id].label}
        style={{ width: '1em', height: '1em' }}
      />
      <Typography>&nbsp;{LOGO_AND_LABELS[record.id].label}</Typography>
    </Box>
  );
};

// Only trigger a render when the form values change.
const onValuesChangeSubscription = { values: true };

export default Identities;
