import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import get from 'lodash/get';
import { Constants, Metadata, NodeType } from '../../constants';
import { DxTheme } from '../../types';
import { documentTypeCodeToResource } from '../../utils';
import { DeleteIconButton } from '../buttons';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    container: {
      display: 'flex',
    },
    contextualButtons: {
      display: 'flex',
      width: 'fit-content',
      position: 'relative',
      left: 'calc(100vw - 580px)',
      bottom: '1em',
      borderRadius: 30,
      background: theme.app.tableBkgColor,
      boxShadow:
        '0px 1px 2px rgba(0, 0, 0, 0.24), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    },
  }),
  { name: 'RowsContextualButtons' }
);

export interface RowsContextualButtonsProps {
  basePath: any;
  resource: string;
  record: any;
  showMetadataButton: any;
  downloadButton: any;
  previewButton: any;
  specificResource: string;
  renderAsMenu?: boolean;
  redirectOnDelete?: boolean;
  refreshOnDelete?: boolean;
  onDelete?: (item?: any) => void;
  isDeletionAllowed?: boolean;
}

/**
 * Displays Contextual buttons
 */
const RowsContextualButtons = ({
  basePath,
  resource,
  record,
  showMetadataButton: ShowMetadataButton,
  downloadButton: DownloadButton,
  previewButton: PreviewButton,
  specificResource,
  renderAsMenu = false,
  redirectOnDelete = true,
  refreshOnDelete = true,
  isDeletionAllowed = true,
  onDelete,
}: RowsContextualButtonsProps) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        'showOnHover',
        classes.contextualButtons,
        classes.container
      )}
    >
      <ShowMetadataButton
        title='dxMessages.headers.show_metadata'
        record={record}
        resource={resource}
        basePath={basePath}
        groupingMode={false}
        className='showDocumentMetadata'
      />
      <DownloadButton
        record={record}
        tooltip='dxMessages.buttons.download'
        basePath={basePath}
        resource={documentTypeCodeToResource(
          get(record?.properties, Metadata.documentTypeCode),
          get(record?.properties, Metadata.processDocumentFormatType)
        )}
        renderAsMenu={renderAsMenu}
        className='downloadDocument'
      />
      {record.content.mimeType === 'application/pdf' && (
        <PreviewButton
          record={record}
          basePath={basePath}
          resource={resource}
          title={record.name}
          tooltip='dxMessages.buttons.preview'
          className='previewDocument'
        />
      )}
      {record.nodeType === NodeType.ATTACH && isDeletionAllowed && (
        <DeleteIconButton
          record={record}
          basePath={basePath}
          label='ra.action.delete'
          className='deleteDocument'
          redirect={null}
          onSuccess={(item) => {
            if (typeof onDelete === 'function') {
              onDelete(item);
            }
          }}
          refreshOnDelete={refreshOnDelete}
          redirectOnDelete={redirectOnDelete}
          resource={Constants.RESOURCE_WEBDOCUMENT}
        />
      )}
    </div>
  );
};

export default RowsContextualButtons;
