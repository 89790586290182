import { DxTheme, Metadata, sendGAEvent } from '@dx-ui/dx-common';
import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { linkToRecord } from 'ra-core';
import React, { useCallback } from 'react';
import { useGetIdentity, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { GA_EVENTS } from '../GAUtils';
import { DataHelpers } from '../services';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    button: {
      marginLeft: 4,
    },
    icon: {
      marginRight: 4,
    },
  }),
  { name: 'EditWebDocumentButton' }
);

const EditWebDocumentButton = (props) => {
  const {
    basePath = '',
    record,
    label = 'dxMessages.buttons.editInvoice',
    icon = <EditIcon />,
    disabled = false,
    to = {
      pathname: `${linkToRecord(basePath, record.id)}`,
      state: {
        documentSubTypeCode: DataHelpers.getDocumentSubtypeCode(record),
      },
    },
  } = props;
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const sanitizeButtonProps = ({
    basePath,
    handleSubmit,
    handleSubmitWithRedirect,
    submitOnEnter,
    classes,
    onSave,
    ...rest
  }) => rest;

  const translate = useTranslate();
  const classes = useStyles();

  const handleOnClick = useCallback(() => {
    sendGAEvent(
      GA_EVENTS.categories.FORM.name,
      GA_EVENTS.categories.FORM.actions.EDIT,
      account?.company?.cmsRootDir,
      record ? record.properties[Metadata.dxuid] : undefined
    );
  }, [account?.company?.cmsRootDir, record]);

  return (
    <Tooltip
      title={
        disabled ? translate('dxMessages.error_messages.not_enough_rights') : ''
      }
    >
      {/* Define a span in order to still have a tooltip even if the item is disabled */}
      <span>
        <Button
          onClick={handleOnClick}
          color='primary'
          component={Link}
          variant='contained'
          type='button'
          classes={{ root: classes.button }}
          {...sanitizeButtonProps(props)}
          {...{ to }}
          disabled={disabled}
        >
          {React.cloneElement(icon, { className: classes.icon })}
          {translate(label, { _: label })}
        </Button>
      </span>
    </Tooltip>
  );
};

export default EditWebDocumentButton;
