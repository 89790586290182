import useLocalStorageValueGeneric from '../utils/useLocalStorageValueGeneric';
import { FeatureFlagsState } from './FeatureFlagsContext';

const useLocalStorageFeatureFlags = (
  flags: FeatureFlagsState
): [FeatureFlagsState, (value: FeatureFlagsState) => void] => {
  const [featureFlags, setFeatureFlags] = useLocalStorageValueGeneric(
    'experimental',
    flags || {}
  );

  return [featureFlags, setFeatureFlags];
};

export default useLocalStorageFeatureFlags;
