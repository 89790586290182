import { isPspAdministrator } from '@dx-ui/dx-common';
import PropTypes from 'prop-types';
import { OnFailure, Record, RedirectionSideEffect } from 'ra-core';
import { FC, ReactElement, SyntheticEvent } from 'react';
import {
  ButtonProps,
  DeleteWithConfirmButton,
  usePermissions,
} from 'react-admin';

const PspAdminDeleteButton: FC<DeleteButtonProps> = ({ ...props }) => {
  const { permissions } = usePermissions();
  const showDeleteButton = isPspAdministrator(permissions);
  if (!showDeleteButton) {
    return null;
  }
  return <DeleteWithConfirmButton id={props.id} {...props} />;
};

interface Props {
  basePath?: string;
  classes?: object;
  className?: string;
  icon?: ReactElement;
  label?: string;
  onClick?: (e: MouseEvent) => void;
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;
  // May be injected by Toolbar - sanitized in DeleteWithUndoButtonutton
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  undoable?: boolean;
  onFailure?: OnFailure;
}

export type DeleteButtonProps = Props & ButtonProps;

PspAdminDeleteButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  // @ts-ignore
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  undoable: PropTypes.bool,
  icon: PropTypes.element,
};

PspAdminDeleteButton.defaultProps = {
  undoable: true,
};

export default PspAdminDeleteButton;
