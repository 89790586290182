import {
  DxTheme,
  ProcessStatus,
  RegulatorExtraDetailsType,
  ToolbarBackButton,
} from '@dx-ui/dx-common';
import { isPeppolInvoiceWriter } from '@dx-ui/dx-common/src/configuration/configuration';
import { makeStyles } from '@material-ui/core/styles';
import {
  ShowView,
  SimpleShowLayout,
  Toolbar,
  usePermissionsOptimized,
} from 'react-admin';
import { DataHelpers } from '../../services/DataHelpers';
import { InvoiceService } from '../../services/InvoiceService';
import DocumentPreviewShowActions from '../../shared/DocumentPreviewShowActions';
import EditWebDocumentButton from '../../shared/EditWebDocumentButton';
import { InvoicePreview } from './';
import InvoiceActions from './InvoiceActions';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    toolbar: {
      backgroundColor: theme.palette.background.default,
      paddingLeft: 0,
    },
  }),
  { name: 'InvoiceToolbar' }
);

const InvoiceToolbar = (props: any) => {
  const { record } = props;

  const { permissions } = usePermissionsOptimized();
  const classes = useStyles();
  let enoughEditRights: boolean = true;

  // General rules for allowing the edition
  const allowEdit =
    InvoiceService.canCreate(permissions) &&
    DataHelpers.getProcessStatus(record) === ProcessStatus.DRAFT;

  // Specific PEPPOL rights checks
  const peppolInvoiceWriter: boolean = isPeppolInvoiceWriter(permissions);
  const peppolInvoice: boolean =
    DataHelpers.regulatorExtraDetails(record) ===
    RegulatorExtraDetailsType.PEPPOL;

  if (peppolInvoice && !peppolInvoiceWriter) {
    enoughEditRights = false;
  }

  return (
    <Toolbar className={classes.toolbar}>
      <ToolbarBackButton />
      {allowEdit && (
        <EditWebDocumentButton
          label={'dxMessages.buttons.editInvoice'}
          disabled={!enoughEditRights}
          {...props}
        />
      )}
      <InvoiceActions record={record} isMenuItem={false} />
    </Toolbar>
  );
};

const InvoiceShowView = (props) => {
  const { controllerProps } = props;

  return (
    <ShowView
      actions={<DocumentPreviewShowActions />}
      {...controllerProps}
      title='dxMessages.invoices.title'
    >
      {controllerProps.record && (
        <SimpleShowLayout>
          <InvoicePreview
            record={controllerProps.record}
            toolbar={InvoiceToolbar}
          />
        </SimpleShowLayout>
      )}
    </ShowView>
  );
};

export default InvoiceShowView;
