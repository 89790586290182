import {
  GA_EVENTS,
  isPspAdministrator,
  sendGAEvent,
  useGAPageViews,
} from '@dx-ui/dx-common/src';
import { unparse as convertToCSV } from 'papaparse';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  Filter,
  FilterProps,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  TopToolbar,
  downloadCSV,
  fetchUtils,
  useGetIdentity,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { Empty } from '../../../common/Empty';

const AccountRelationFilters = (props: Omit<FilterProps, 'children'>) => {
  const { permissions, loaded } = usePermissions();
  if (!loaded || !permissions) return null;

  return (
    <Filter {...props}>
      {isPspAdministrator(permissions) && (
        <ReferenceInput
          label='dxMessages.companyManagement.accountRelation.customer'
          source='idcustomer'
          reference='management/company'
          filterToQuery={(searchText) => {
            return {
              accountname: [searchText],
            };
          }}
          sort={{ field: 'accountname', order: 'ASC' }}
          allowEmpty={true}
          alwaysOn
        >
          <AutocompleteInput
            optionText='accountname'
            optionValue='id'
            resettable={true}
          />
        </ReferenceInput>
      )}
      {isPspAdministrator(permissions) && (
        <ReferenceInput
          label='dxMessages.companyManagement.accountRelation.supplier'
          source='idsupplier'
          reference='management/company'
          filterToQuery={(searchText) => {
            return {
              accountname: [searchText],
            };
          }}
          sort={{ field: 'accountname', order: 'ASC' }}
          allowEmpty={true}
          alwaysOn
        >
          <AutocompleteInput
            optionText='accountname'
            optionValue='id'
            options={{ type: 'search' }}
          />
        </ReferenceInput>
      )}

      <TextInput
        label={
          'dxMessages.companyManagement.accountRelation.suppliercode4customer'
        }
        source='suppliercode4Customer'
        alwaysOn
      />
    </Filter>
  );
};

const AccountRelationList = (props: any) => {
  const notify = useNotify();
  const translate = useTranslate();
  useGAPageViews();
  const { permissions, loaded } = usePermissions();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const MyPagination = (props) => (
    <Pagination rowsPerPageOptions={[5, 10]} {...props} />
  );

  if (!loaded || !permissions) return null;

  const exporter: any = (notify) => async (data) => {
    const idsCustomer: any = [];
    const idsSupplier: any = [];
    data.forEach((d: any) => {
      idsCustomer.push(d.idcustomer);
      idsSupplier.push(d.idsupplier);
    });

    const resultCustomer: any = await fetchUtils
      .fetchJson(`/management/company/list`, {
        method: 'POST',
        headers: new Headers({ Accept: 'application/json' }),
        body: JSON.stringify({
          ids: idsCustomer,
          pagination: { page: 1, perPage: 5000 },
          sort: { field: 'id', order: 'ASC' },
          filter: { _start: 0, _end: 5000 },
        }),
      })
      .then((response) => response.json)
      .then((result) => result);

    const resultSupplier: any = await fetchUtils
      .fetchJson(`/management/company/list`, {
        method: 'POST',
        headers: new Headers({ Accept: 'application/json' }),
        body: JSON.stringify({
          ids: idsSupplier,
          pagination: { page: 1, perPage: 5000 },
          sort: { field: 'id', order: 'ASC' },
          filter: { _start: 0, _end: 5000 },
        }),
      })
      .then((response) => response.json)
      .then((result) => result);

    const summariesForExport = data.map((summary, index) => {
      const customerSummary =
        resultCustomer.find((item) => item.id === summary.idcustomer) || {};
      const supplierSummary =
        resultSupplier.find((item) => item.id === summary.idsupplier) || {};
      return {
        id: index + 1,
        customer: customerSummary?.accountname,
        customerID: customerSummary?.identification,
        supplier: supplierSummary?.accountname,
        supplierID: supplierSummary?.identification,
        supplierCode4Customer: summary?.supplierCode4Customer,
      };
    });
    const csv = convertToCSV(summariesForExport);
    const BOM = '\uFEFF';
    downloadCSV(`${BOM} ${csv}`, 'management_relations');
  };

  const ListActions = () => {
    return (
      <TopToolbar>
        {isPspAdministrator(permissions) && <CreateButton />}
        <ExportButton exporter={exporter(notify)} maxResults={5000} />
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      filters={<AccountRelationFilters />}
      empty={isPspAdministrator(permissions) ? <Empty /> : false}
      bulkActionButtons={false}
      pagination={<MyPagination />}
      actions={<ListActions />}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        <ReferenceField
          label='dxMessages.companyManagement.accountRelation.customer'
          source='idcustomer'
          reference='management/company'
          link={false}
          sortable={false}
        >
          <TextField source='accountname' />
        </ReferenceField>
        <ReferenceField
          label='dxMessages.companyManagement.accountRelation.customerId'
          source='idcustomer'
          reference='management/company'
          link={false}
          sortable={false}
        >
          <TextField source='identification' />
        </ReferenceField>
        <ReferenceField
          label='dxMessages.companyManagement.accountRelation.supplier'
          source='idsupplier'
          reference='management/company'
          link={false}
          sortable={false}
        >
          <TextField source='accountname' />
        </ReferenceField>
        <ReferenceField
          label='dxMessages.companyManagement.accountRelation.supplierId'
          source='idsupplier'
          reference='management/company'
          link={false}
          sortable={false}
        >
          <TextField source='identification' />
        </ReferenceField>
        <TextField
          label='dxMessages.companyManagement.accountRelation.suppliercode4customer'
          source='supplierCode4Customer'
        />
        {isPspAdministrator(permissions) && <EditButton />}
        {isPspAdministrator(permissions) && (
          <DeleteWithConfirmButton
            onClick={() =>
              sendGAEvent(
                GA_EVENTS.categories.ACCOUNT_RELATION_MGMT.name,
                GA_EVENTS.categories.ACCOUNT_RELATION_MGMT.actions
                  .DELETE_ACCOUNTRELATION,
                account?.company?.cmsRootDir
              )
            }
            onFailure={(error) => {
              if (error.status === 400) {
                notify(
                  'dxMessages.companyManagement.accountRelation.delete_account_error',
                  'error',
                  {
                    _: 'Unable to delete this account',
                    error: translate(
                      'dxMessages.companyManagement.accountRelation.delete_account_error'
                    ),
                  }
                );
              } else {
                notify(
                  'dxMessages.companyManagement.accountRelation.delete_account_error',
                  'error',
                  {
                    _: 'Unable to delete this account',
                    error: `${error}`,
                  }
                );
              }
            }}
          />
        )}
      </Datagrid>
    </List>
  );
};

export default AccountRelationList;
