import { isPspAdministrator, useGAPageViews } from '@dx-ui/dx-common/src';
import { Box } from '@material-ui/core';
import moment from 'moment';
import {
  Datagrid,
  DateField,
  DateTimeInput,
  Filter,
  FilterProps,
  List,
  SelectInput,
  TextField,
  usePermissions,
} from 'react-admin';
import { Empty } from '../../common/Empty';
import CreateTicketButton from './CreateTicketButton';
import IgnoreButton from './IgnoreButton';
import SupplierNotifiedButton from './SupplierNotifiedButton';

// interface LastLoginTimeResponse extends RaRecord {
//   // ISO-8601 representation.
//   lastLoginTime: string;
// }

const EfacturaMonitoringFilters = (props: Omit<FilterProps, 'children'>) => {
  const { permissions, loaded } = usePermissions();
  if (!loaded || !permissions) return null;

  return (
    <Filter {...props}>
      <DateTimeInput
        source='timestampMin'
        label='dxMessages.monitoring.efactura.eventDateStart'
        showTime={true}
        // on useRefresh() the format will be converted to the local one breaking the app functionality.
        // we need to check always that the format is the standard one specific for DateTimeInput.
        parse={(date: Date) => (date ? moment(date).toISOString() : null)}
      />
      <DateTimeInput
        source='timestampMax'
        label='dxMessages.monitoring.efactura.eventDateEnd'
        showTime={true}
        // on useRefresh() the format will be converted to the local one breaking the app functionality.
        // we need to check always that the format is the standard one specific for DateTimeInput.
        parse={(date: Date) => (date ? moment(date).toISOString() : null)}
      />
      <SelectInput
        source='status'
        label='dxMessages.monitoring.efactura.status'
        allowEmpty
        alwaysOn
        choices={[
          { id: 'NEW', name: 'NEW' },
          { id: 'IGNORE', name: 'IGNORE' },
          { id: 'SUPPLIER_NOTIFIED', name: 'SUPPLIER_NOTIFIED' },
          { id: 'TICKET_CREATED', name: 'TICKET_CREATED' },
        ]}
      />
    </Filter>
  );
};

const EfacturaMonitoringList = (props: any) => {
  useGAPageViews();
  const { permissions, loaded } = usePermissions();

  if (!loaded || !permissions) return null;

  return (
    <List
      bulkActionButtons={false}
      filters={<EfacturaMonitoringFilters />}
      filterDefaultValues={{
        timestampMin: moment()
          .subtract(4, 'hours')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        timestampMax: moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      }}
      empty={isPspAdministrator(permissions) ? <Empty /> : false}
      {...props}
    >
      <Datagrid
        style={{
          marginTop: '1em',
        }}
      >
        <TextField
          label={'dxMessages.monitoring.efactura.filename'}
          source='filename'
        />
        <TextField
          label={'dxMessages.monitoring.efactura.reason'}
          source='reason'
          sortable={false}
        />
        <TextField
          label={'dxMessages.monitoring.efactura.status'}
          source='status'
          sortable={false}
        />
        <TextField
          label={'dxMessages.monitoring.efactura.userId'}
          source='userId'
          sortable={false}
        />
        <DateField
          label={'dxMessages.monitoring.efactura.date'}
          source='actionDate'
          sortable={false}
        />
        <Box
          // The field get displayed into a Datagrid row, so prevent from
          // triggering the Datagrid click behavior.
          onClick={(e) => e.stopPropagation()}
          // Shrink the container to the button sizes.
          display='inline-flex'
          boxShadow={4}
          borderRadius={30}
          bgcolor='background.paper'
          alignItems='center'
          justifyContent='flex-end'
          p={0.2}
          style={{
            cursor: 'pointer',
            border: '1px solid grey',
          }}
          {...props}
        >
          <Box>
            <Box>
              <IgnoreButton {...props} />
            </Box>
          </Box>
          <Box>
            <Box pl={1}>
              <SupplierNotifiedButton {...props} />
            </Box>
          </Box>
          <Box>
            <Box pl={1}>
              <CreateTicketButton {...props} />
            </Box>
          </Box>
        </Box>
      </Datagrid>
    </List>
  );
};

export default EfacturaMonitoringList;
