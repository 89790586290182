import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { cloneDeep } from 'lodash';
import { useContext } from 'react';
import { usePermissionsOptimized } from 'react-admin';
import { UserRoles } from '../security';
import { useGAPageViews } from '../utils';
import { FeatureFlagsContext } from './FeatureFlagsContext';
import useLocalStorageFeatureFlags from './useLocalStorageFeatureFlags';

const useStyles = makeStyles(
  (theme) => ({
    featureList: {
      listStyle: 'none',
    },
    paper: {
      padding: theme.spacing(2),
    },
    warning: {
      color: 'red',
    },
  }),
  { name: 'FeatureFlagsToggler' }
);

export interface FeatureFlagsTogglerProps {
  product: string;
}

const FeatureFlagsToggler = (props: FeatureFlagsTogglerProps) => {
  const { product } = props;
  const classes = useStyles();

  const contextFlags = useContext(FeatureFlagsContext);
  const { permissions } = usePermissionsOptimized();

  const hasPermissions =
    !!permissions && permissions[product]?.indexOf(UserRoles.BETA_TESTER) >= 0;

  const [featureFlags, setFeatureFlags] =
    useLocalStorageFeatureFlags(contextFlags);

  const toggleFeatureFlag = (e: any, featureFlagName: string) => {
    const featureFlagsCopy = cloneDeep(featureFlags);
    const enabled = !!featureFlags[featureFlagName];
    featureFlagsCopy[featureFlagName] = !enabled;
    setFeatureFlags(featureFlagsCopy);
  };

  useGAPageViews();

  if (!hasPermissions) {
    return (
      <Alert severity='warning'>
        You don't seem to have the necessary rights to use the experimental
        features page.
      </Alert>
    );
  }

  return (
    <Paper className={classes.paper}>
      <FormControl component='fieldset'>
        <FormLabel component='legend'>Toggle feature flags ON/OFF:</FormLabel>
        <FormGroup aria-label='position'>
          {Object.keys(featureFlags)?.map((name) => (
            <FormControlLabel
              key={name}
              control={
                <Switch
                  checked={!!featureFlags[name]}
                  onChange={(e) => toggleFeatureFlag(e, name)}
                />
              }
              label={name}
              labelPlacement='start'
            />
          ))}
        </FormGroup>
        <FormHelperText error={true}>Be careful.</FormHelperText>
      </FormControl>
    </Paper>
  );
};

export default FeatureFlagsToggler;
