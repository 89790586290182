import {
  colors,
  columnWidthStyles,
  Constants,
  DxTheme,
  merge,
  Metadata,
  useTz,
} from '@dx-ui/dx-common';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { get } from 'lodash';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Responsive,
  ShowView,
  useDataProvider,
  useLocale,
  useTranslate,
} from 'react-admin';
import { DisplayContextualButtons } from '../../../../../shared';
import { DespatchAdvicePreview } from '../../../../despatchAdvice';
import { InvoicePreview } from '../../../../invoice';
import { OrderPreview } from '../../../../order';
import { ReceiptAdvicePreview } from '../../../../receiptAdvice';
import { WayBillPreview } from '../../../../waybill';
import ClusterShowController from './ClusterShowController';

const useStyles: any = makeStyles(
  (theme: DxTheme) => ({
    root: {
      '& > div': {
        boxShadow: 'unset',
      },
    },
    headerCell: {
      padding: '5px 10px',
      verticalAlign: 'middle',
      '&.alignRight': {
        textAlign: 'right',
      },
      '&.alignRight span[role=button]': {
        // sortable columns
        justifyContent: 'flex-end',
      },
      '&:last-child': {
        padding: '5px 10px',
      },
      '& span[role=button]': {
        // https://docprocess.atlassian.net/browse/DXPOR-349
        verticalAlign: 'unset',
        display: 'flex',
      },
    },
    row: {
      '& .showOnHover': {
        visibility: 'hidden',
      },
      '&:hover .showOnHover': {
        visibility: 'visible',
      },
      '&:hover .stickyCol': {
        backgroundColor: theme.app.tableOnHoverBkgColor, // color like row hover one.
      },
      '&:hover td:first-child': {
        backgroundColor: theme.app.tableOnHoverBkgColor, // set color like row hover one.
      },
      verticalAlign: 'text-bottom',
    },
    headerRow: {
      '&:hover .stickyCol': {
        backgroundColor: theme.app.tableBkgColor,
      },
    },
    view: {
      padding: theme.spacing(2),
    },
    header: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    paper: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    tableScroll: {
      // wrapper div css class
      position: 'relative',
      width: 'calc(100vw - 370px)',
      zIndex: 1,
      overflow: 'auto',
      '& table': {
        borderCollapse: 'separate',
      },
    },
    headerCell_withStickyCols: {
      '&.stickyCol': {
        background: theme.app.tableBkgColor,
        position: 'sticky',
        left: 0,
        top: 0,
        zIndex: 20,
      },
    },
    row_withStickyCols: {
      '&:hover td:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 20,
        backgroundColor: theme.app.tableOnHoverBkgColor,
      },
    },
    headerRow_withStickyCols: {
      '& th:first-child': {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 30,
        backgroundColor: theme.app.tableBkgColor,
      },
    },
    webform: {
      borderColor: colors.black65,
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    contextualButtons: {
      display: 'flex',
      width: 'fit-content',
      position: 'relative',
      left: 'calc(100vw - 700px)',
      bottom: '1em',
      borderRadius: 30,
      background: theme.app.tableBkgColor,
      boxShadow:
        '0px 1px 2px rgba(0, 0, 0, 0.24), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    },
  }),
  { name: 'ClusterPanel' }
);

const ClusterPanel = (props) => {
  const { resource, basePath, record, id, type, columns, dxuids, ...rest } =
    props;

  const classes = useStyles(props);
  const dataProvider = useDataProvider();

  const SanitizePropsComponent = (props) => {
    const { basePath, children, ...rest } = props;
    return <div {...rest}>{children}</div>;
  };

  return (
    <ClusterShowController
      resource={resource}
      id={id}
      dataProvider={dataProvider}
      dxuids={dxuids}
      {...rest}
    >
      {({ isLoading, ...controllerProps }: any) => {
        if (isLoading) {
          return null;
        } else {
          return (
            <ShowView
              actions={false}
              title={''}
              {...controllerProps}
              classes={{ root: classes.root }}
            >
              <Responsive
                medium={
                  <SanitizePropsComponent>
                    <Paper
                      square
                      className={classNames(classes.paper, 'related-document')}
                    >
                      <CreateRows
                        columns={columns}
                        basePath={basePath}
                        data={get(controllerProps, 'record')}
                        resource={resource}
                      />
                    </Paper>
                  </SanitizePropsComponent>
                }
              />
            </ShowView>
          );
        }
      }}
    </ClusterShowController>
  );
};

const DisplayTablePanel = ({ record, resource }) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (
    get(record, `properties.${Metadata.documentTypeCode}`) ===
    Constants.DOCUMENT_TYPE_CODE_INVOIC
  ) {
    return (
      <div className={classes.webform}>
        <InvoicePreview record={record} />
      </div>
    );
  } else if (
    get(record, `properties.${Metadata.documentTypeCode}`) ===
    Constants.DOCUMENT_TYPE_CODE_RECADV
  ) {
    return (
      <div className={classes.webform}>
        <ReceiptAdvicePreview record={record} />
      </div>
    );
  } else if (
    get(record, `properties.${Metadata.documentTypeCode}`) ===
    Constants.DOCUMENT_TYPE_CODE_WAYBIL
  ) {
    return (
      <div className={classes.webform}>
        <WayBillPreview record={record} />
      </div>
    );
  } else if (
    get(record, `properties.${Metadata.documentTypeCode}`) ===
    Constants.DOCUMENT_TYPE_CODE_ORDERS
  ) {
    return (
      <div className={classes.webform}>
        <OrderPreview record={record} />
      </div>
    );
  } else if (
    get(record, `properties.${Metadata.documentTypeCode}`) ===
    Constants.DOCUMENT_TYPE_CODE_DESADV
  ) {
    return (
      <div className={classes.webform}>
        <DespatchAdvicePreview record={record} />
      </div>
    );
  } else {
    return (
      <Typography variant='caption' color='error'>
        {translate('dxMessages.notInvoice', {
          _: 'Unable to show preview of this document',
        })}
      </Typography>
    );
  }
};

const CreateRows = (props) => {
  const { columns, basePath, resource, data, ...rest } = props;

  const classes = useStyles(props);
  const locale = useLocale();
  const translate = useTranslate();
  const tz = useTz();

  return (
    <div className={classes.tableScroll}>
      <ListContextProvider
        value={{
          ids: Object.keys(data),
          data,
          currentSort: {},
          resource,
          selectedIds: [],
        }}
      >
        <Datagrid
          classes={{
            headerRow: classNames(
              classes.headerRow,
              classes.headerRow_withStickyCols
            ),
            headerCell: classNames(
              classes.headerCell,
              classes.headerCell_withStickyCols
            ),
            row: classNames(classes.row, classes.row_withStickyCols),
          }}
          expand={<DisplayTablePanel record={data} resource={resource} />}
          {...rest}
        >
          {columns.map((column, index) => {
            let headerClassName = '';
            if (column.id === 'documentId') {
              headerClassName = 'stickyCol';
            }
            if (column.alignRight) {
              headerClassName = headerClassName + ' alignRight';
            }
            return (
              <FunctionField
                label={
                  typeof column.label === 'function'
                    ? column.label({ resource })
                    : column.label
                }
                cellClassName={
                  column.id === 'documentId' ? 'stickyCol' : undefined
                }
                headerClassName={headerClassName.trim()}
                source={column.id}
                key={`${column.id}_${index}`}
                sortable={column.sortable}
                render={(record, index) => {
                  return (
                    <div
                      style={merge(
                        columnWidthStyles(column.width),
                        column.cellStyle
                      )}
                      key={`${column.id}_${index}`}
                    >
                      {column.renderCell({
                        record,
                        translate,
                        locale,
                        tz,
                        basePath,
                        readOnly: column.readOnly,
                        id: column.id,
                        alignRight: column.alignRight,
                      })}
                      {column.id === Metadata.documentId && (
                        <>
                          <DisplayContextualButtons
                            className={classes.contextualButtons}
                            basePath={`/${Constants.RESOURCE_DOCUMENTS}`}
                            resource={Constants.RESOURCE_DOCUMENTS}
                            record={record}
                          />
                        </>
                      )}
                    </div>
                  );
                }}
              />
            );
          })}
        </Datagrid>
      </ListContextProvider>
    </div>
  );
};

export default ClusterPanel;
