import {
  Constants,
  DxTheme,
  Metadata,
  ProcessStatus,
  ReceiptAdviceSubType,
  sendGAEvent,
  ToolbarBackButton,
  WebFormMode,
} from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useMemo, useState } from 'react';
import {
  linkToRecord,
  Loading,
  Responsive,
  ShowView,
  SimpleShowLayout,
  Toolbar,
  useDataProvider,
  useGetIdentity,
  useNotify,
  usePermissionsOptimized,
  useRedirect,
} from 'react-admin';
import { GA_EVENTS } from '../../GAUtils';
import {
  DocumentServiceFactory,
  ReceiptAdviceService,
  ReceiptAdviceServiceCreator,
} from '../../services';
import { DataHelpers } from '../../services/DataHelpers';
import { DespatchAdviceService } from '../../services/DespatchAdviceService';
import DocumentPreviewShowActions from '../../shared/DocumentPreviewShowActions';
import EditWebDocumentButton from '../../shared/EditWebDocumentButton';
import ToolbarButton from '../../shared/ToolbarButton';
import { P2pData } from '../../shared/types';
import { DespatchAdvicePreview } from './';
import DespatchAdviceActions from './DespatchAdviceActions';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    toolbar: {
      backgroundColor: theme.palette.background.default,
      paddingLeft: 0,
    },
  }),
  { name: 'DespatchAdviceShowView' }
);

enum ActionType {
  CONVERT_TO_RECADV,
}

const DespatchAdviceToolbar = (props) => {
  const { record, onClickDoAction, canConvertToRecadv, loading, converting } =
    props;

  const { permissions } = usePermissionsOptimized();
  const classes = useStyles();

  const allowEdit =
    DespatchAdviceService.canCreate(permissions) &&
    DataHelpers.getProcessStatus(record) === ProcessStatus.DRAFT;

  return (
    <Toolbar className={classes.toolbar} {...props}>
      <ToolbarBackButton />
      {allowEdit && (
        <EditWebDocumentButton
          label={'dxMessages.buttons.editDespatchAdvice'}
          {...props}
        />
      )}
      {canConvertToRecadv && (
        <ToolbarButton
          disabled={loading || converting}
          loading={loading || converting}
          label={'dxMessages.buttons.convertToReceiptAdvice'}
          onClick={onClickDoAction(record, ActionType.CONVERT_TO_RECADV)}
        />
      )}
      <DespatchAdviceActions isMenuItem={false} permissions={permissions} />
    </Toolbar>
  );
};

const DespatchAdviceShowView = (props) => {
  const { controllerProps } = props;
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { permissions } = usePermissionsOptimized();
  const notify = useNotify();

  const properties = controllerProps?.record?.properties;

  const [loading, setLoading] = useState<boolean>(false);
  const [converting, setConverting] = useState<boolean>(false);
  const [canConvertToRecadv, setCanConvertToRecadv] = useState(false);
  const [called, setCalled] = useState(false);
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const receiptAdviceService = useMemo(
    () =>
      DocumentServiceFactory.create(
        ReceiptAdviceServiceCreator,
        dataProvider
      ) as ReceiptAdviceService,
    [dataProvider]
  );

  useEffect(() => {
    const getConversionsAvailability = async () => {
      const documentRecipientId =
        DespatchAdviceService.getRecipientId(properties);

      if (!documentRecipientId) {
        throw new Error('Document recipientId should not be empty!');
      }

      setLoading(true);

      try {
        const canCreateReceiptAdvice =
          ReceiptAdviceService.canCreate(permissions);
        if (canCreateReceiptAdvice) {
          const templateRights =
            await receiptAdviceService.getReceiptAdviceTemplateRights(
              documentRecipientId
            );
          const receiptAdviceCreationPolicy =
            await receiptAdviceService.fetchCreationPolicy(
              Constants.RESOURCE_RECEIPT_ADVICE,
              documentRecipientId
            );

          setCanConvertToRecadv(
            templateRights.cloneOrCreate &&
              receiptAdviceCreationPolicy.conversion
          );
        }
        setLoading(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setLoading(false);
      }
    };

    if (properties && dataProvider && permissions) {
      if (!called) {
        setCalled(true);
        getConversionsAvailability();
      }
    }
  }, [properties, dataProvider, permissions, receiptAdviceService, called]);

  const onClickDoAction = (record: P2pData, actionType: ActionType) => {
    return async (e) => {
      e.preventDefault();

      if (!record.id) {
        throw new Error('record id cannot be null');
      }

      setConverting(true);
      notify('ra.page.loading');

      let editFormResource = '';

      try {
        let result: P2pData;
        switch (actionType) {
          case ActionType.CONVERT_TO_RECADV:
            sendGAEvent(
              GA_EVENTS.categories.DOCUMENT_CONVERT.name,
              GA_EVENTS.categories.DOCUMENT_CONVERT.actions.DESADV_TO_RECADV,
              account?.company?.cmsRootDir,
              record
                ? `DESADV DXUID: ${record.properties[Metadata.dxuid]}`
                : undefined
            );

            result = await receiptAdviceService.createFromDespatchAdvice(
              record.id
            );
            editFormResource = Constants.RESOURCE_WEBRECEIPT_ADVICE;
            break;
          default:
            throw new Error(`Invalid value for actionType: ${actionType}`);
        }
        setConverting(false);
        redirect(
          `/${linkToRecord(editFormResource, result.id)}`,
          undefined, // basePath
          undefined, // id
          undefined, // data
          {
            documentSubTypeCode: ReceiptAdviceSubType.GoodsReceiptAdvice,
            redirectMode: WebFormMode.SCRATCH_RECEIPT_ADVICE,
          } // state
        );
      } catch (e) {
        setConverting(false);
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };
  };

  return (
    <ShowView
      actions={<DocumentPreviewShowActions />}
      {...controllerProps}
      title='dxMessages.despatchAdvices.title'
    >
      <Responsive
        medium={
          <SimpleShowLayout>
            {loading && !controllerProps.record ? (
              <Loading />
            ) : (
              <DespatchAdvicePreview record={controllerProps.record} />
            )}
            <DespatchAdviceToolbar
              record={controllerProps.record}
              onClickDoAction={onClickDoAction}
              canConvertToRecadv={canConvertToRecadv}
              converting={converting}
              loading={loading}
            />
          </SimpleShowLayout>
        }
      />
    </ShowView>
  );
};

export default DespatchAdviceShowView;
