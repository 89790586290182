import { isPspAdministrator } from '@dx-ui/dx-common';
import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  FunctionField,
  List,
  SelectInput,
  TextField,
  TextInput,
  useLocale,
  useNotify,
  usePermissions,
} from 'react-admin';
import { CountryService } from '../../../../services';
import { Empty } from '../../../common/Empty';

const ListFilters = (props: any) => {
  const locale = useLocale();
  const countryService = new CountryService(locale);
  const countries = countryService.getCountries();
  const countryItems = countries.map((country) => {
    return {
      id: country.code,
      name: country.name,
    };
  });

  return (
    <Filter {...props}>
      <TextInput
        label='dxMessages.peppol.issuer_identification.title'
        source='issuerIdentification'
        variant='standard'
        alwaysOn
      />
      <TextInput
        label='dxMessages.peppol.company_name.title'
        source='companyName'
        variant='standard'
        alwaysOn
      />
      <SelectInput
        label={'dxMessages.peppol.country.title'}
        source='country'
        choices={countryItems}
        alwaysOn
      />
      <TextInput
        label='dxMessages.peppol.company_identification.title'
        source='companyIdentification'
        variant='standard'
        alwaysOn
      />
      <TextInput
        label='dxMessages.peppol.schemaId.title'
        source='schemaId'
        variant='standard'
        alwaysOn
      />
      <TextInput
        label='dxMessages.peppol.value.title'
        source='valueId'
        variant='standard'
        alwaysOn
      />
    </Filter>
  );
};

const PeppolList = (props) => {
  const notify = useNotify();
  const { permissions } = usePermissions();

  return (
    <List
      {...props}
      filters={<ListFilters {...props} />}
      empty={isPspAdministrator(permissions) ? <Empty /> : false}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        <TextField
          label={'dxMessages.peppol.issuer_identification.title'}
          source='issuerIdentification'
        />
        <TextField
          label={'dxMessages.peppol.company_name.title'}
          source='companyName'
        />
        <TextField label='dxMessages.peppol.country.title' source='country' />
        <TextField
          label={'dxMessages.peppol.company_identification.title'}
          source='companyIdentification'
        />
        <FunctionField
          label='dxMessages.peppol.schemaId.title'
          render={(record) => {
            const schemaId =
              '0'.repeat(4 - record?.schemaId.toString().length) +
              record?.schemaId;
            return schemaId;
          }}
        />
        <TextField label={'dxMessages.peppol.value.title'} source='valueId' />
        <EditButton />
        <DeleteWithConfirmButton
          onFailure={() => {
            notify('dxMessages.peppol.error', 'error');
          }}
        />
      </Datagrid>
    </List>
  );
};

export default PeppolList;
