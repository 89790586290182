// The UI displaying an invoice line by line.
import { clone, colors, DxTheme } from '@dx-ui/dx-common';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import React, { FC } from 'react';
import { Datagrid, ListContextProvider } from 'react-admin';
import InvoiceSummaryField from '../nwaymatching/InvoiceSummaryField';
import {
  FieldProps,
  INVOICE_ICONS,
  Task,
  UblInvoice,
} from '../nwaymatching/types';
import { renderColumns } from './ValidationColumns';

const useStyles: any = makeStyles(
  (theme: DxTheme) => ({
    root: {
      '& > div': {
        boxShadow: 'unset',
      },
    },
    headerCell: {
      padding: '5px 10px',
      verticalAlign: 'middle',
      '&.alignRight': {
        textAlign: 'right',
      },
      '&.alignRight span[role=button]': {
        justifyContent: 'flex-end', // sortable columns
      },
      '&:last-child': {
        padding: '5px 10px',
      },
      '& span[role=button]': {
        verticalAlign: 'unset', // https://docprocess.atlassian.net/browse/DXPOR-349
        display: 'flex',
      },
    },
    row: {
      verticalAlign: 'text-bottom',
    },
    headerRow: {
      '&:hover .stickyCol': {
        backgroundColor: theme.app.tableBkgColor,
      },
    },
    header: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    warningIcon: {
      marginRight: 3,
    },
  }),
  { name: 'InvoiceValidationField' }
);

// The invoice, line by line.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const InvoiceValidationField: FC<FieldProps<Task<InvoiceFlowVariables>>> = (
  props
) => {
  const { record, locale, resource } = props;
  const classes = useStyles();

  if (record == null) return null;

  const { invoice } = record.flow.variables;

  const invoiceLines = clone(invoice?.InvoiceLine) || [];

  const rowByIds: { [key: string]: LineInfo } = invoiceLines.reduce(
    (acc, line) => {
      const row: LineInfo = clone(line); // Do not modify the orginal UBL unvoice.
      const lineId = get(row, 'ID.value');
      acc[lineId] = row;
      return acc;
    },
    {}
  );

  return (
    <div>
      <Grid container direction='column' spacing={8}>
        <Grid item>
          <InvoiceSummaryField
            invoice={invoice}
            correctionInvoiceChain={[]}
            icon={INVOICE_ICONS(invoice?.InvoiceTypeCode.value, {
              fontSize: 30,
              color: colors.lightPurple,
            })}
          />
        </Grid>
        <Grid item>
          <ListContextProvider
            value={{
              ids: Object.keys(rowByIds),
              data: rowByIds,
              currentSort: { field: 'id', order: 'DESC' },
              resource,
              selectedIds: [],
            }}
          >
            <Datagrid
              classes={{
                row: classes.row,
                rowCell: classes.linesCell,
                headerCell: classes.headerCell,
                tbody: classes.tbody,
              }}
            >
              {renderColumns(classes, locale)}
            </Datagrid>
          </ListContextProvider>
        </Grid>
      </Grid>
    </div>
  );
};

interface InvoiceFlowVariables {
  // The invoice under matching. Always present.
  invoice: UblInvoice;
}

interface LineInfo {
  id: string;
  [key: string]: any; // The UBL invoice.
}

export default InvoiceValidationField;
