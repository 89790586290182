import { FC } from 'react';
import {
  Create,
  CreateProps,
  FormTab,
  TabbedForm,
  useNotify,
  useRedirect,
} from 'react-admin';
import CompanyTab from './creation/CompanyTab';
import SubscriptionsTab from './creation/SubscriptionsTab';

/**
 * The creation of a company.
 *
 * Only visible for PSP administrators.
 * Three tabs: (1) general information & supported authentication types,
 * (2) the features subscribed by the company, (3) the delegation of employees usage rights,
 */
const CompanyCreate: FC<CreateProps> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.companies.edit.notifications.created');
    redirect('list', props.basePath);
  };

  /**
   * Formats excludedRoleIds content before sending to dataProvider
   * @param formData form data
   * @returns form data containing good excludedRoleIds format
   */
  const transformBeforeCreating = (formData) => {
    const newSubscriptionsFormat = formData.subscriptions?.map((s) => {
      if (s.excludedRoleIds) {
        const newExcludedRoleIds = s.excludedRoleIds.map((e) =>
          e.id ? e.id : e
        );
        s.excludedRoleIds = newExcludedRoleIds;
      }
      return s;
    });
    formData.subscriptions = newSubscriptionsFormat
      ? newSubscriptionsFormat
      : formData.subscriptions;
    return formData;
  };

  return (
    <Create
      component='div'
      onSuccess={onSuccess}
      {...props}
      transform={transformBeforeCreating}
    >
      <TabbedForm>
        <FormTab label='resources.companies.edit.tabs.company.title'>
          <CompanyTab />
        </FormTab>
        <FormTab label='resources.companies.edit.tabs.subscriptions.title'>
          <SubscriptionsTab />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CompanyCreate;
