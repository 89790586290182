import { isPspAdministrator } from '@dx-ui/dx-common';
import { IconButton, Tooltip } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import { Record, linkToRecord } from 'ra-core';
import { FC, ReactElement } from 'react';
import {
  ButtonProps,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '18px',
  },
}));

const IgnoreButton: FC<EditButtonProps> = ({
  basePath = '',
  label = 'dxMessages.monitoring.efactura.ignore',
  record,
  icon = defaultIcon,
  ...rest
}) => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();
  const recordJson = useRecordContext();
  const showButton = isPspAdministrator(permissions);
  const classes = useStyles();
  if (!showButton) {
    return null;
  }

  const ignore = (id) => {
    const request = new Request(`/efactura/monitoring/ignore/` + id, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      }),
      credentials: 'include',
    });
    fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        // eslint-disable-next-line no-console
        notify('Could not update.', 'error');
      } else {
        notify('Updated.');
        refresh();
      }
    });
  };

  return (
    <IconButton
      component={Link}
      to={linkToRecord(basePath, recordJson && recordJson.id)}
      //label={label}
      onClick={(e) => {
        e.stopPropagation();
        if (recordJson !== undefined) {
          ignore(recordJson.id);
        }
      }}
      {...(rest as any)}
    >
      <Tooltip title={translate(label)} arrow className={classes.tooltip}>
        {icon}
      </Tooltip>
    </IconButton>
  );
};

const defaultIcon = (
  <CancelIcon
    color='primary'
    style={{
      width: '20px',
      height: '20px',
    }}
  />
);

interface Props {
  basePath?: string;
  record?: Record;
  icon?: ReactElement;
}

export type EditButtonProps = Props & ButtonProps & MuiButtonProps;

IgnoreButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
};

export default IgnoreButton;
