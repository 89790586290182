import {
  DateTimeInput,
  Edit,
  maxLength,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';

export const FormatTypeEdit = (props: any) => {
  const nameValidate = [required(), maxLength(50)];
  const commentValidate = [maxLength(500)];

  const notify = useNotify();

  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.updated');
    redirect('list', props.basePath);
  };

  return (
    <Edit {...props} onSuccess={onSuccess} undoable={false}>
      <SimpleForm>
        <TextInput
          label={'dxMessages.companyManagement.formattype.description'}
          source='description'
          validate={nameValidate}
        />
        <TextInput
          label={'dxMessages.companyManagement.formattype.comment'}
          source='comment'
          validate={commentValidate}
        />
        <SelectInput
          label={'dxMessages.companyManagement.formattype.idDocumentType'}
          source='idDocumentType'
          choices={[
            { id: 1, name: 'INVOICE' },
            { id: 2, name: 'DESADV' },
          ]}
        />
        <DateTimeInput
          label={'dxMessages.companyManagement.audit.tsChange'}
          source='tsChange'
          disabled
        />
        <TextInput
          label={'dxMessages.companyManagement.audit.userIdChange'}
          source='userIdChange'
          disabled
        />
      </SimpleForm>
    </Edit>
  );
};

export default FormatTypeEdit;
