import { Grid, Typography } from '@material-ui/core';
import { cloneDeep, isUndefined, merge, set } from 'lodash';
import * as math from 'mathjs';
import {
  email,
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
  Translate,
} from 'react-admin';
import { Template, TemplateModelElement, ValidationEmitter } from '../types';
import { FieldTypes } from './fields/FieldTypes';
import {
  availableValidations,
  compareDates,
  fixedLength,
  isSet,
  lineQuantitiesWithSameSign,
  maxDecimals,
  mustBeSet,
  negative,
  nonZero,
  numbersOnly,
  regexMatch,
} from './validation/availableValidations';

export const FORM_NAME = 'dynamic-form';

/**
 * Rounds the value with the specified number of decimals
 */
export const round = (value: number | undefined, decimals = 2) => {
  if (value === undefined) {
    return value;
  }

  return math.round(value, decimals);
};

/**
 * Retrieves in template the onChange function identified by name.
 * @param {*} onChangeFunctions template onChangeFunctions section
 * @param {*} name unique id of an onChange function in template
 */
export const getOnChangeFunctionFromTemplate = (
  onChangeFunctions,
  name
): any => {
  let onChangeFuncInfo = {};
  onChangeFunctions.forEach((onChangeFunc) => {
    if (onChangeFunc.name === name) {
      onChangeFuncInfo = onChangeFunc;
    }
  });
  return onChangeFuncInfo;
};

/**
 * Retrieves in the template the field identified by name.
 * @param {*} template template information
 * @param {*} name unique id of an input in template
 */
export const getFieldFromTemplate = (
  template: Template,
  name: string
): TemplateModelElement | undefined => {
  for (let i = 0; i < template.model.length; i++) {
    let element = template.model[i];
    // First search at root level
    if (element.name === name) {
      return element;
    }
    if (
      element.type === FieldTypes.Container ||
      element.type === FieldTypes.OrderLinesTable ||
      element.type === FieldTypes.ArrayLinesTable
    ) {
      // Search within the items
      if (element.items) {
        for (let j = 0; j < element.items.length; j++) {
          let item = element.items[j];
          if (item.name === name) {
            return item;
          }
        }
      }
      if (element.topItems) {
        for (let k = 0; k < element.topItems.length; k++) {
          let item = element.topItems[k];
          if (item.name === name) {
            return item;
          }
        }
      }
    }
  }
  return undefined;
};

/**
 * Retrieves in the template model the field identified by source.
 * @param {*} template template information
 * @param {*} source source of an input in template model
 */
export const getFieldFromSourceInTemplate = (
  template: Template,
  source: string
): TemplateModelElement | undefined => {
  for (let i = 0; i < template.model.length; i++) {
    let element = template.model[i];
    // First search at root level
    if (element.source === source) {
      return element;
    }
    if (
      element.type === FieldTypes.Container ||
      element.type === FieldTypes.OrderLinesTable ||
      element.type === FieldTypes.ArrayLinesTable
    ) {
      // Search within the items
      if (element.items) {
        for (let j = 0; j < element.items.length; j++) {
          let item = element.items[j];
          if (item.source === source) {
            return item;
          }
        }
      }
      if (element.topItems) {
        for (let k = 0; k < element.topItems.length; k++) {
          let item = element.topItems[k];
          if (item.source === source) {
            return item;
          }
        }
      }
    }
  }
  return undefined;
};

/**
 * Retrieves in template the label of the input identified by name.
 * The input can be an item of an OrderLinesTable or a Container
 * @param {*} template template information
 * @param {*} name unique id of an input in template
 */
export const getInputLabelFromTemplate = (template, name) => {
  let label = 'Unknown input label';
  template.model.forEach((element) => {
    // First search at root level
    if (element.name === name) {
      label = element.label;
    }
    if (
      element.type === FieldTypes.Container ||
      element.type === FieldTypes.OrderLinesTable ||
      element.type === FieldTypes.ArrayLinesTable
    ) {
      // Search within the items
      element.items.forEach((item) => {
        if (item.name === name) {
          label = item.label;
        }
        return label !== 'Unknown input label';
      });
    }
    return label !== 'Unknown input label';
  });
  return label;
};

/**
 * returns an updated template where field identified by name is set with a new value property
 * @param {Template} template JSON model of the form
 * @param {string} name field name
 * @param {any} property property name
 * @param {any} value property value
 */
export const setFieldProperty: any = (
  template: Template,
  name: string,
  property: any,
  value: any
) => {
  let newTemplate = cloneDeep(template);
  newTemplate = setFieldPropertyBy(template, 'name', name, property, value);
  return newTemplate;
};

/**
 * returns an updated template where field identified by one of its property is set with a new value property
 * @param {Template} template JSON model of the form
 * @param {string} by field property name used to search
 * @param {string} byValue: value to search
 * @param {any} property the property name of the field property to set
 * @param {any} value value to set to targeted property
 */
export const setFieldPropertyBy: any = (
  template: Template,
  by: string,
  byValue: string,
  property: any,
  value: any
) => {
  let field: TemplateModelElement = {
    name: 'Unknown field',
    type: 'Unknown field',
  };

  const newTemplate = cloneDeep(template);
  newTemplate.model.forEach((element) => {
    // First search at root level
    if (element[by] === byValue) {
      field = element;
      if (
        element[property] instanceof Object === true &&
        element[property] instanceof Array === false
      ) {
        merge(element[property], value);
      } else {
        set(element, property, value);
      }
    }
    if (
      element.type === FieldTypes.Container ||
      element.type === FieldTypes.OrderLinesTable ||
      element.type === FieldTypes.ArrayLinesTable
    ) {
      // Search within the items
      element?.items?.forEach((item) => {
        if (item[by] === byValue) {
          field = item;
          if (
            item[property] instanceof Object === true &&
            item[property] instanceof Array === false
          ) {
            merge(item[property], value);
          } else {
            set(item, property, value);
          }
        }
        return field.name !== 'Unknown field';
      });

      if (element.topItems) {
        element.topItems.forEach((item) => {
          if (item[by] === byValue) {
            field = item;
            if (
              item[property] instanceof Object === true &&
              item[property] instanceof Array === false
            ) {
              merge(item[property], value);
            } else {
              set(item, property, value);
            }
          }
          return field.name !== 'Unknown field';
        });
      }
    }
    return field.name !== 'Unknown field';
  });
  return newTemplate;
};

/**
 * Retrieves in the template, the first field source of a field having 'property'='value'
 * @param {object} template JSON model of the form
 * @param {string} property field property
 * @param {*} value expected value for property
 */
export const getFieldSourceOnPropertyValue = (template, property, value) => {
  let sourceName = 'Unknown field';
  template.model.forEach((element) => {
    // First search at root level
    if (element[property] === value) {
      sourceName = element.source;
    }
    if (
      element.type === FieldTypes.Container ||
      element.type === FieldTypes.OrderLinesTable ||
      element.type === FieldTypes.ArrayLinesTable
    ) {
      // Search within the items
      element.items.forEach((item) => {
        if (item[property] === value) {
          sourceName = item.source;
        }
        return sourceName !== 'Unknown field';
      });

      if (element.topItems) {
        element.topItems.forEach((item) => {
          if (item[property] === value) {
            sourceName = item.source;
          }
          return sourceName !== 'Unknown field';
        });
      }
    }
    return sourceName !== 'Unknown field';
  });
  return sourceName;
};

/**
 * Retrieves in template the source of the field identified by name.
 * The field can be an item of an OrderLinesTable or a Container
 * @param {*} template template information
 * @param {*} name unique id of a field in template
 * @param {*} getSource if defined, FormDataConsumer utility for getting sources in an ArrayInput
 */
export const getFieldSourceFromTemplate = (template, name, getSource?) => {
  return getSource
    ? getSource(getFieldSourceOnPropertyValue(template, 'name', name))
    : getSourceField(getFieldSourceOnPropertyValue(template, 'name', name));
};

/**
 * Check template consistency
 * @param {*} template layout
 * @param {*} field template field definition
 */
export const checkFieldValidity = (field) => {
  if (
    !field.source &&
    !field.sourceValue &&
    field.type !== FieldTypes.Container &&
    field.type !== FieldTypes.LayoutSpacer
  ) {
    return (
      <div style={{ display: 'block' }}>
        <Typography variant='h5' color='error'>
          {`Undefined source property for ${field.type} : ${field.name} in template`}
        </Typography>
      </div>
    );
  }
};

/**
 * Checks if 'edm:' is present and use 'properties.' if the case
 * @param source name of field
 */
export const getSourceField = (
  source: string | undefined
): string | undefined => {
  if (!source) {
    return source;
  }
  return source?.startsWith('edm:') ? `properties.${source}` : source;
};

/**
 * Sets valid source field
 * @param source name of field
 */
export const setSourceField = (
  record: any,
  source: string,
  value: any,
  setIfUndefined: any = true
) => {
  if (value === undefined && setIfUndefined === false) {
    return;
  }

  const path = getSourceField(source);

  if (path) {
    set(record, path, value);
  }
};

/**
 * Builds a field validation object from the field and the record
 */
export const buildFieldValidation = (
  template: Template,
  field: TemplateModelElement,
  emitter: ValidationEmitter,
  translate: Translate
): any[] => {
  const validate: any[] = [];
  if (field.required) {
    validate.push(required());
  }

  if (!isUndefined(field.minLength)) {
    validate.push(minLength(field.minLength));
  }

  if (!isUndefined(field.maxLength)) {
    validate.push(maxLength(field.maxLength));
  }

  if (!isUndefined(field.minValue)) {
    validate.push(minValue(field.minValue));
  }

  if (!isUndefined(field.maxValue)) {
    validate.push(maxValue(field.maxValue));
  }

  if (!isUndefined(field.maxDecimals)) {
    validate.push(maxDecimals(field.maxDecimals));
  }

  if (!isUndefined(field.numbersOnly)) {
    validate.push(numbersOnly(field.numbersOnly));
  }

  if (!isUndefined(field.lineQuantitiesWithSameSign)) {
    validate.push(lineQuantitiesWithSameSign(field.lineQuantitiesWithSameSign));
  }

  if (!isUndefined(field.nonZero)) {
    validate.push(nonZero(field.nonZero));
  }

  if (!isUndefined(field.negative)) {
    validate.push(negative(field.negative));
  }

  if (!isUndefined(field.compareDates)) {
    validate.push(compareDates(template, field.compareDates));
  }

  if (!isUndefined(field.fixedLength)) {
    validate.push(fixedLength(field.fixedLength));
  }

  if (!isUndefined(field.email)) {
    validate.push(email());
  }

  if (!isUndefined(field.isSet)) {
    validate.push(isSet(template, emitter, field.isSet));
  }
  if (!isUndefined(field.mustBeSet)) {
    validate.push(mustBeSet(template, emitter, field.mustBeSet, translate));
  }

  if (!isUndefined(field.regexMatch)) {
    validate.push(regexMatch(template, field.regexMatch));
  }

  if (field.customValidate && availableValidations[field.customValidate.name]) {
    validate.push(
      availableValidations[field.customValidate.name](
        template,
        field.customValidate.params,
        field.customValidate.message
      )
    );
  }

  return validate;
};

/**
 * Used by grids
 */
export const CommaSeparator = () => (
  <Grid item>
    <Typography variant='caption' style={{ marginRight: 2, marginLeft: 2 }}>
      ,
    </Typography>
  </Grid>
);

/**
 * Returns the index of the line of the source of a field within a lines table.
 * Returns -1 if the field is not defined in a lines table.
 * */
export const getLineIndexFromSource = (source: string): number => {
  let lineIndex = -1;
  const sourceSplited = source?.split('.');
  if (sourceSplited && sourceSplited[0].startsWith('lines[')) {
    // Retrieves the emitter
    const reg = 'lines\\[(.*)\\]$';
    var regexp = new RegExp(reg);
    const result = regexp.exec(sourceSplited[0] || '');
    if (result) {
      lineIndex = parseInt(result[1]);
    }
  }
  return lineIndex;
};
