import { formatNumericValue, TextInput } from '@dx-ui/dx-common';
import { useCallback } from 'react';
import { useLocale } from 'react-admin';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

const backToNumber = (v) => {
  return parseFloat(v);
};

/**
 * Renders a NumberDisplayField according to the form data template
 * The rendering takes into account that the field can be a member of an array.
 */
const NumberDisplayField = (props: FieldComponentProps) => {
  const { field } = props;
  const options = field.options;
  const locale = useLocale();

  const format = useCallback(
    (v) => formatNumericValue(v, locale, options?.price ? 2 : 0),
    [locale, options]
  );

  const parse = useCallback((v) => {
    return backToNumber(v);
  }, []);

  const sanitizeProps: any = ({
    loading,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;

  // We are using a TextInput here with format and parse in order to
  // have a formatted display of the number (especially for price format (2 digits))
  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <TextInput
          {...sanitizeProps(fieldProps)}
          format={format}
          parse={parse}
          titleSuffix={field?.businessTerms}
        />
      )}
    </FieldWrapper>
  );
};

export default NumberDisplayField;
