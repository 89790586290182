import { colors, withPlaceholderMultiple } from '@dx-ui/dx-common';
import { Button, FormControl } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { InputProps, useInput, useTranslate } from 'react-admin';
import AutocompleteMultiSelect from './AutoCompleteMultiSelect';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      marginBottom: 8,
      minWidth: 100,
      maxWidth: 150,
    },
  }),
  { name: 'MultiSelectInput' }
);
export interface MultiSelectInputProps extends InputProps {
  width?: any;
  placeholder?: any;
  filterPlaceholder?: any;
  icon?: any;
  options: any;
  sortLabels?: boolean;
  label?: string;
  className?: string;
  disabled?: boolean;
  clear?: boolean;
  hideButton?: boolean;
}

const MultiSelectInput = (props: MultiSelectInputProps) => {
  const {
    width,
    placeholder,
    filterPlaceholder,
    icon,
    options,
    sortLabels = false,
    label,
    className,
    disabled,
    clear,
    hideButton,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  const getOptionId = (x) => x.id;
  const renderOptionLabel = (x) => x.label;

  const { input } = useInput(props);

  const handleClearAll = () => {
    const onChange = input.onChange;
    if (onChange) {
      onChange(null);
    }
  };

  const handleChange = (evt) => {
    const onChange = input.onChange;
    if (!onChange) {
      return;
    }
    const value = evt.target.value;
    onChange(value.length ? value : null);
  };

  const filterOption = (option, query, lowerCaseQuery, translate) => {
    if (!query) {
      return true;
    }
    let { label } = option;
    label = translate(label, { _: label });
    return label.toLowerCase().indexOf(lowerCaseQuery) !== -1;
  };

  //const valueArray = input?.value || [];
  const valueArray =
    (input?.value.length !== 0 && props.defaultValue === null
      ? props.defaultValue
      : input?.value) || [];
  const hasPlaceholder = !!placeholder;

  return (
    <FormControl
      className={classNames(classes.container, className)}
      style={width}
    >
      {label && (
        <InputLabel
          shrink={hasPlaceholder ? true : undefined}
          variant='filled'
          margin='dense'
        >
          {translate(label, { _: label })}
        </InputLabel>
      )}

      <AutocompleteMultiSelect
        {...input}
        value={placeholder ? withPlaceholderMultiple(valueArray) : valueArray}
        placeholder={placeholder}
        filterPlaceholder={filterPlaceholder}
        icon={icon}
        options={options}
        sortLabels={sortLabels}
        getOptionId={getOptionId}
        onChange={handleChange}
        renderOptionLabel={renderOptionLabel}
        filterOption={filterOption}
        onOpen={undefined}
        loading={undefined}
        errorText={undefined}
        renderValue={undefined}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        disabled={disabled}
        clear={clear ? handleClearAll() : undefined}
      >
        {!!Array.from(valueArray).length && hideButton !== true && (
          <Button
            style={{
              color: colors.black65,
            }}
            onClick={handleClearAll}
          >
            {translate('dxMessages.list.clearAll', {
              _: 'Clear all',
            })}
          </Button>
        )}
      </AutocompleteMultiSelect>
    </FormControl>
  );
};

MultiSelectInput.propTypes = {
  classes: PropTypes.object,
  translate: PropTypes.func,
};

export default MultiSelectInput;
