import { Constants } from '@dx-ui/dx-common';
import { DataProvider } from 'react-admin';

export class TaskService {
  /**
   * Builds the service instance
   * @param {*} dataProvider React Admin data provider
   */
  constructor(public dataProvider: DataProvider) {}

  /**
   * gets company details
   * @param dxuids list of dxuids
   */
  async getDocuments(dxuids?: string[]): Promise<any> {
    // load company Details
    const response = await this.dataProvider[Constants.API_GET_TASK_DOCUMENTS](
      Constants.RESOURCE_TASK_DOCUMENTS,
      dxuids
    );

    return response.data;
  }
}
