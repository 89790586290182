import {
  GA_EVENTS,
  isPspAdministrator,
  sendGAEvent,
  useGAPageViews,
} from '@dx-ui/dx-common/src';
import React from 'react';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  ExportButton,
  Filter,
  FilterProps,
  FunctionField,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  TopToolbar,
  useGetIdentity,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';

const UsersFilter = (props: Omit<FilterProps, 'children'>) => {
  const { permissions, loaded } = usePermissions();
  if (!loaded || !permissions) return null;
  return (
    <Filter {...props}>
      {isPspAdministrator(permissions) && (
        <ReferenceInput
          label='resources.accounts.list.headers.company'
          source='companyId'
          reference='management/company'
          filterToQuery={(searchText) => ({
            accountname: [searchText],
          })}
          sort={{ field: 'accountname', order: 'ASC' }}
          allowEmpty={true}
          alwaysOn
        >
          <AutocompleteInput
            optionText='accountname'
            optionValue='id'
            resettable={true}
          />
        </ReferenceInput>
      )}
      <TextInput
        label={'dxMessages.companyManagement.users.userId'}
        source='id'
        alwaysOn
      />
      {isPspAdministrator(permissions) && (
        <ReferenceInput
          label='dxMessages.companyManagement.users.role'
          source='roleId'
          reference='management/dxrole'
          filterToQuery={(searchText) => ({
            rolle: [searchText],
          })}
          sort={{ field: 'rolle', order: 'ASC' }}
          allowEmpty={true}
          alwaysOn
        >
          <AutocompleteInput
            optionText='rolle'
            optionValue='id'
            resettable={true}
          />
        </ReferenceInput>
      )}
    </Filter>
  );
};

const UsersList = (props: any) => {
  const notify = useNotify();
  const translate = useTranslate();

  const { permissions, loaded } = usePermissions();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  useGAPageViews();
  const UserPagination = (props) => (
    <Pagination rowsPerPageOptions={[10]} {...props} />
  );
  if (!loaded || !permissions) return null;

  const ListActions = () => {
    return (
      <TopToolbar>
        <CreateButton />
        <ExportButton maxResults={5000} />
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      filters={isPspAdministrator(permissions) ? <UsersFilter /> : undefined}
      empty={false}
      pagination={<UserPagination />}
      actions={<ListActions />}
      bulkActionButtons={false}
      hasCreate={true}
    >
      <Datagrid style={{ marginTop: '1em' }} rowClick='show'>
        {isPspAdministrator(permissions) && (
          <ReferenceField
            label='resources.accounts.list.headers.company'
            source='companyId'
            reference='management/company'
            link={false}
            sortable={false}
          >
            <TextField source='accountname' />
          </ReferenceField>
        )}
        <TextField
          label={'dxMessages.companyManagement.users.userId'}
          source='id'
        />
        <TextField label={'dxMessages.myAccount.lastName'} source='lastName' />
        <FunctionField
          label={'dxMessages.companyManagement.users.isCompanyAdmin'}
          render={(record: any) => {
            if (record.roles)
              return !record.roles.includes('Customer administrator')
                ? '\u274C'
                : '\u2705';
          }}
          sortable={false}
        />
        <DeleteWithConfirmButton
          id={props?.id}
          onClick={() =>
            sendGAEvent(
              GA_EVENTS.categories.USER_MGMT.name,
              GA_EVENTS.categories.USER_MGMT.actions.DELETE_USER,
              account?.company?.cmsRootDir
            )
          }
          onFailure={(error) => {
            if (error.status === 400) {
              notify('dxMessages.myAccount.delete_user_error', 'error', {
                _: 'Unable to delete this user',
                error: translate(
                  'dxMessages.myAccount.delete_customer_admin_error'
                ),
              });
            } else {
              notify('dxMessages.myAccount.delete_user_error', 'error', {
                _: 'Unable to delete this user',
                error: `${error}`,
              });
            }
          }}
        />
      </Datagrid>
    </List>
  );
};

export default UsersList;
