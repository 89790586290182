import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import { SaveButton } from 'react-admin';
import { toolbarStyles } from './styles';

const useStyles: any = makeStyles(toolbarStyles, { name: 'SendButton' });

const SendButton = (props) => {
  const { documentSending, onSend } = props;
  const sanitizeProps = ({ saving, documentSending, onSend, ...rest }) => rest; // replace saving prop for the save button, do not use the one provided by the toolbar
  const classes = useStyles(props);
  return (
    <SaveButton
      {...sanitizeProps(props)}
      label={'dxMessages.buttons.send'}
      icon={<SendIcon />}
      submitOnEnter={false}
      redirect={false}
      className={classes.toolbarButton}
      saving={documentSending}
      onSave={onSend}
    />
  );
};

export default SendButton;
