import { DataProvider, GetOneResult, Identifier, Record } from 'ra-core';
import { Constants } from '../constants/Constants';
import { DomFileDownloadService } from './DomFileDownloadService';

const DEFAULT_INTERVAL = 3000;
const DEFAULT_TIMEOUT = 300000;

/**
 * Status of the bulk file creation
 */
export const BULK_CREATE_STATUS = {
  PENDING: 'PENDING',
  READY: 'DONE',
  ERROR: 'ERROR',
};

/**
 * Provides operations for bulk downloading documents and related documents
 */
export class AlfrescoDownloadService {
  _interval: number;
  _timeout: number;

  /**
   * Builds the service instance
   * @param {*} dataProvider React Admin data provider
   * @param {*} domFileDownloadService File downloader service instance
   * @param {*} alfrescoDeleteService Delete from Alfresco service instance
   */
  constructor(
    public dataProvider: DataProvider,
    public domFileDownloadService: DomFileDownloadService
  ) {
    this._interval = DEFAULT_INTERVAL;
    this._timeout = DEFAULT_TIMEOUT;
  }

  get timeout() {
    return this._timeout;
  }

  set timeout(timeout) {
    this._timeout = timeout;
  }

  get interval() {
    return this._interval;
  }

  set interval(interval) {
    this._interval = interval;
  }

  /**
   * Starts the download for the zip with the selected documents its related items
   * @param {*} selectedIds Selected documents ids to download
   */
  async download(selectedIds: Identifier[], representation?: any) {
    const resultCreate = await this._createZipFile(selectedIds, representation);
    const downloadReady = await this._pollUntilReadyStatusOrTimeout(
      resultCreate.id
    );

    if (downloadReady) {
      // wait for node download
      await this._downloadZipFile(resultCreate.id, resultCreate.name);

      // delete node
      await this._delete(resultCreate.id);
    } else {
      throw new Error(
        'Timeout while creating the zip file with all related documents.'
      );
    }
  }

  private async _delete(nodeId: Identifier) {
    const payload = {
      data: [{ id: nodeId }],
    };

    let result: boolean = false;

    const response: Response = await this.dataProvider[
      Constants.API_DELETE_BULK_DOWNLOAD
    ](Constants.RESOURCE_BULK_DOWNLOAD_DELETE, payload);

    if (response.status < 200 || response.status >= 300) {
      throw new Error('Could not delete document');
    } else {
      result = true;
    }

    return result;
  }

  private async _checkDownloadStatus(nodeId: Identifier) {
    const payload = {
      id: nodeId,
    };

    const result: GetOneResult<Record> = await this.dataProvider.getOne(
      Constants.RESOURCE_BULK_DOWNLOAD_STATUS,
      payload
    );
    return result.data;
  }

  private async _downloadZipFile(nodeId: Identifier, fileName: string) {
    const payload = {
      id: nodeId,
    };
    const result = await this.dataProvider[Constants.API_GET_BULK_DOWNLOAD](
      Constants.RESOURCE_BULK_DOWNLOAD_CONTENT,
      payload
    );
    const response: Response = result.data;
    if (response.status < 200 || response.status >= 300) {
      // eslint-disable-next-line no-console
      throw new Error(
        `Could not download file. Http status: ${response.status}`
      );
    } else {
      const content = await response.blob();
      await this.domFileDownloadService.downloadFile(content, fileName);
    }
  }

  private async _pollUntilReadyStatusOrTimeout(
    nodeId: string
  ): Promise<string | boolean> {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const startTime = new Date().getTime();
    let ready = false;
    let isTimeout = false;
    let error = '';

    while (!ready && !isTimeout) {
      try {
        let checkReadyResult = await this._checkDownloadStatus(nodeId);
        if (checkReadyResult) {
          ready = checkReadyResult.status === BULK_CREATE_STATUS.READY;

          if (checkReadyResult.status === BULK_CREATE_STATUS.ERROR) {
            error = JSON.stringify(checkReadyResult);
            break;
          }
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(
          `Error checking download status for nodeId=${nodeId}`,
          err
        );
        error = err;
        break;
      }
      if (!ready) {
        await sleep(this._interval);
        const elapsed = new Date().getTime() - startTime;
        isTimeout = elapsed > this._timeout;
      }
    }

    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        resolve(ready);
      }
    });
  }

  private async _createZipFile(nodeIds: Identifier[], representation?: any) {
    if (!nodeIds || !nodeIds.length) {
      throw new Error(
        'You have to provide at least a nodeId to create a zip file'
      );
    }

    let payload;
    if (representation !== undefined) {
      payload = {
        data: {
          nodeIds: nodeIds,
          representation: representation,
        },
      };
    } else {
      payload = {
        data: {
          nodeIds: nodeIds,
        },
      };
    }

    const result = await this.dataProvider[Constants.API_CREATE_BULK_DOWNLOAD](
      Constants.RESOURCE_BULK_DOWNLOAD_CREATE,
      payload
    );

    return result.data;
  }
}
