import { useDataProvider } from 'react-admin';
import { AlfrescoDownloadService, DomFileDownloadService } from '.';

/**
 * Gets the file download service
 */
const useDownloadService = (): AlfrescoDownloadService => {
  const dataProvider = useDataProvider();
  const domFileDownloadService: DomFileDownloadService =
    new DomFileDownloadService();
  const bulkDownloadService = new AlfrescoDownloadService(
    dataProvider,
    domFileDownloadService
  );
  return bulkDownloadService;
};

export default useDownloadService;
