// The cockpit UI to approve or reject the cancellation of an invoice.
import { colors } from '@dx-ui/dx-common';
import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import classNames from 'classnames';
import { get } from 'lodash';
import compose from 'lodash/flowRight';
import moment from 'moment';
import { Translate } from 'ra-core';
import React, { FC } from 'react';
import { required, TextInput, Title, translate } from 'react-admin';
import {
  createColumnsProcessDetails,
  createCommonColumns,
} from '../../../../../../aspects';
import {
  EnhancedProps,
  FieldProps,
  Task,
} from '../../../../../../shared/types';
import ClusterPanel from '../../common/ClusterPanel';
import InvoiceSummaryField from '../InvoiceSummaryField';

const styles: any = (theme) => ({
  paper: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  comment: {
    background: '#f4f4f4',
    border: '1px solid #ddd',
    borderLeft: '3px solid black',
    color: '#666',
    pageBreakInside: 'avoid',
    fontFamily: 'monospace',
    lineHeight: '1.6',
    marginBottom: '1.6em',
    maxWidth: '100%',
    overflow: 'auto',
    padding: '1em 1.5em',
    display: 'block',
    wordWrap: 'break-word',
  },
});

// The columns to display in the cluster panel.

const columns: any[] = createCommonColumns()
  .concat(createColumnsProcessDetails())
  .filter((column) => column.displayInCluster === true);

// Make the document ID not clickable as we have the preview in the expand panel.
Object.values(columns).forEach((value: any) => {
  if (value.id === 'edm:documentId') {
    value.readOnly = true;
    value.label = 'dxMessages.headers.documentId';
  }
});

const i18nKey =
  'dxMessages.task.invoice_matching.cancellationDecisionTask.cockpit.';

const CancellationCockpit: FC<
  FieldProps<Task<CancellationCockpitVariables>> &
    EnhancedProps & { classes: any }
> = (props) => {
  const { resource, id, record, translate } = props;

  if (!record) return null;

  const {
    // The cancel invoice.
    invoice: cancelInvoice,
    error,
    // The invoice to cancel.
    referencedInvoice,
    // The n-way matching that occurred (if any) on the invoice to cancel.
    referencedInvoiceNwayMatchingRegistration: nwayMatchingRegistration,
    // The documents involved into the n-way matching.
    cluster = [],
  } = record.flow.variables;

  // The invoice to cancel.
  const referencedInvoiceId = get(
    cancelInvoice,
    'BillingReference[0].InvoiceDocumentReference.ID.value'
  );
  const clusterDxuids = cluster.map((doc) => doc.dxuid);

  return (
    <>
      <Title title={translate(i18nKey + 'title')} />
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Typography className={props.classes.header} variant='h6'>
            {translate(i18nKey + 'theInvoiceToCancel')}:
          </Typography>
        </Grid>
        <Grid item>
          <Paper
            square
            className={classNames(props.classes.paper, 'related-document')}
          >
            <div className={props.classes.tableScroll}>
              {error && (
                <Typography
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1.5em',
                  }}
                >
                  <WarningIcon style={{ color: colors.brightOrange }} />
                  <ErrorField error={error} translate={translate} />
                </Typography>
              )}
              {!error && !referencedInvoice && (
                <Typography
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1.5em',
                  }}
                >
                  <WarningIcon style={{ color: colors.brightOrange }} />
                  {translate(i18nKey + 'invoiceNotFound', {
                    ref: referencedInvoiceId,
                  })}
                </Typography>
              )}
            </div>
            <InvoiceSummaryField invoice={referencedInvoice} />
            {nwayMatchingRegistration && (
              <MatchingStatus status={nwayMatchingRegistration} />
            )}
          </Paper>
        </Grid>
        <Grid item>
          <Typography className={props.classes.header} variant='h6'>
            {translate(i18nKey + 'theCancelInvoiceDetails')}:
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: '1em' }}>
          <Paper
            square
            className={classNames(props.classes.paper, 'related-document')}
          >
            <ClusterPanel
              resource={resource}
              id={id}
              dxuids={clusterDxuids}
              columns={columns}
            />
          </Paper>
        </Grid>
        <Grid item>
          <TextInput
            source='input.data.comment'
            label={i18nKey + 'comment'}
            fullWidth
            multiline
            rows='3'
            validate={required()}
          />
        </Grid>
      </Grid>
    </>
  );
};

// Display the error message.
// error is a stringified NwayMatchingError.
const ErrorField: FC<{ error: string; translate: Translate }> = ({
  error,
  translate,
}) => {
  try {
    const json = JSON.parse(error);
    const params = json.params || {};
    return (
      <Grid container direction='column'>
        <Grid item>
          <Typography variant='caption'>
            {translate(`${i18nKey}errors.${json.key}`, {
              _: json.message,
              ...params,
            })}
          </Typography>
        </Grid>
        {(json.details || []).map((detail, i) => {
          const params = detail.params || {};
          return (
            <Grid item key={i}>
              <Typography variant='caption' noWrap>
                &bull;&nbsp;
                {translate(`${i18nKey}errors.${detail.key}`, {
                  _: detail.message,
                  ...params,
                })}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  } catch (e) {
    // Should never happen.
    return <Typography variant='caption'>{error}</Typography>;
  }
};

const enhance = compose(translate, withStyles(styles, { withTheme: true }));

const MatchingStatus: FC<{ status: NwayMatchingRegistration }> = enhance(
  ({ status, classes, translate }) => {
    const { accepted, created, invoiceHoldManager, invoiceHoldComment } =
      status;
    const date = moment(new Date(created)).format('MMM Do YYYY');

    if (invoiceHoldManager) {
      return (
        <div>
          {translate(i18nKey + 'invoiceAcceptance', {
            acceptance: accepted
              ? translate(i18nKey + 'accepted')
              : translate(i18nKey + 'rejected'),
            date: date,
            user: invoiceHoldManager,
          })}
          {invoiceHoldComment && (
            <>
              <span> {translate(i18nKey + 'withTheComment')}</span>
              <div style={{ marginTop: '1em' }} className={classes.comment}>
                {invoiceHoldComment}
              </div>
            </>
          )}
        </div>
      );
    } else {
      return (
        <div>
          {translate(i18nKey + 'automaticInvoiceAcceptance', {
            acceptance: accepted
              ? translate(i18nKey + 'accepted')
              : translate(i18nKey + 'rejected'),
            date: date,
          })}
        </div>
      );
    }
  }
);

interface CancellationCockpitVariables {
  // The documents involved in the cancellation, that is: the cancel invoice and the one to cancel.
  // The one to cancel may be missing.
  cluster: Array<ClusterDocument>;

  // The cancel invoice.
  invoice: UblInvoice;

  // The invoice to cancel. May be missing.
  referencedInvoice?: UblInvoice;

  // The n-way matching done (if any) on the invoice to cancel.
  referencedInvoiceNwayMatchingRegistration?: NwayMatchingRegistration;

  // The stringified JSON error ({key: "<error_id>", message: "<msg in English>", params: ...}).
  error?: string;
}

type UblInvoice = any;

interface ClusterDocument {
  type: 'invoice';
  dxuid: string;
  reason: 'cancel' | 'target';
}

interface NwayMatchingRegistration {
  accepted: boolean;
  created: string; // 2020-10-27T08:55:54.35702Z
  invoiceHoldManager?: string;
  invoiceHoldComment?: string;
}

export default enhance(CancellationCockpit);
