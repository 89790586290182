import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import React, { useCallback, useState } from 'react';
import { DxTheme } from '../..';
import { SavedQueriesDeleteDialog } from './SavedQueriesDeleteDialog';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      borderRadius: 'unset',
      padding: theme.spacing(1),
    },
    deleteButton: {
      borderRadius: 'unset',
      padding: theme.spacing(1),
    },
  }),
  { name: 'SavedQueriesDeleteBtn' }
);

const SavedQueriesDeleteBtn = ({
  onRemove,
  savedQueryName,
}: {
  onRemove: () => void;
  savedQueryName: string;
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = useCallback(() => {
    onRemove && onRemove();
    setOpen(false);
  }, [onRemove]);

  return (
    <>
      <IconButton
        color='primary'
        aria-label='Delete Saved Query'
        classes={{ root: classes.deleteButton }}
        size='small'
        onClick={handleClick}
      >
        <DeleteOutlineIcon />
      </IconButton>
      <SavedQueriesDeleteDialog
        open={open}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        savedQueryName={savedQueryName}
      />
    </>
  );
};

export default SavedQueriesDeleteBtn;
