import { Constants, Metadata } from '@dx-ui/dx-common';
import {
  createColumnsContract,
  createColumnsDeliveryLocation,
  createColumnsOrder,
  createColumnsProcessDetails,
  createColumnsReceiptAdvice,
  createColumnsTotals,
  createCommonColumns,
  createCommonFilters,
  createContractFilters,
  createDeliveryLocationFilters,
  createOrderFilters,
  createProcessDetailsFilters,
  createReceiptAdviceFilters,
  createSpecificColumnsReceiptAdvice,
  createSpecificReceiptAdviceFilters,
  createTotalsFilters,
} from '../../../aspects';
import { ReceiptAdviceService } from '../../../services/ReceiptAdviceService';
import { CommonList } from '../../../shared';
import BulkDeleteActionButtons from '../../../shared/BulkDeleteActionButtons';
import ContextualToolbar from '../../../shared/ContextualToolbar';

/**
 * Declare the additional columns to display vs commons
 */
const columns: any[] = [
  ...createCommonColumns(),
  ...createColumnsContract(),
  ...createColumnsDeliveryLocation(),
  ...createColumnsTotals(),
  ...createColumnsOrder(),
  ...createColumnsProcessDetails(),
  ...createColumnsReceiptAdvice(),
  ...createSpecificColumnsReceiptAdvice(),
];

// Change edm:orderId and edm:orderDate columns configuration
// in order to always have this cols for Web receiptAdvices.
columns
  .filter((col) => col.id === Metadata.orderId || col.id === Metadata.orderDate)
  .forEach((col) => {
    col.alwaysOn = true;
  });

/**
 * Specific filters
 */
const additionalFilters: any[] = [
  ...createCommonFilters(),
  ...createContractFilters(),
  ...createDeliveryLocationFilters(),
  ...createTotalsFilters(),
  ...createOrderFilters(),
  ...createProcessDetailsFilters(),
  ...createReceiptAdviceFilters(),
  ...createSpecificReceiptAdviceFilters(),
];

const WebReceiptAdviceList = (props) => {
  const { permissions } = props;
  return (
    <CommonList
      title='dxMessages.receiptAdvices.title'
      {...props}
      columns={columns}
      additionalFilters={additionalFilters}
      contextualToolbar={
        <ContextualToolbar
          resource={Constants.RESOURCE_RECEIPT_ADVICE}
          allowEdit={ReceiptAdviceService.canCreate(permissions)}
        />
      }
      bulkActions={<BulkDeleteActionButtons />}
    />
  );
};

export default WebReceiptAdviceList;
