import { SelectInput } from '@dx-ui/dx-common';
import { get } from 'lodash';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a SelectInput
 */
const SelectInputField = (props: FieldComponentProps) => {
  const { field, selectValues } = props;

  const options = field.options;

  let valueField: string = options?.optionValue || 'id';
  let nameField: string = options?.optionText ?? valueField;

  // select items
  const choices = field.source ? get(selectValues, field.source) || [] : [];

  // allow empty value
  const allowEmpty = field.allowEmpty ? field.allowEmpty : false;
  const sanitizeProps: any = ({ withConfirm, enableIfValid, ...rest }): any =>
    rest;

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <SelectInput
          {...sanitizeProps(fieldProps)}
          optionText={nameField}
          optionValue={valueField}
          choices={choices}
          allowEmpty={allowEmpty}
          titleSuffix={field?.businessTerms}
        />
      )}
    </FieldWrapper>
  );
};

export default SelectInputField;
