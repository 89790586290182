import {
  AuditTrailButton,
  DeleteIconButton,
  DocumentTypeCode,
  MoreButton,
  ProcessStatus,
  RegulatorExtraDetailsType,
  RelatedDocsButton,
} from '@dx-ui/dx-common';
import { isPeppolInvoiceWriter } from '@dx-ui/dx-common/src/configuration/configuration';
import { IconButton, Tooltip } from '@material-ui/core';
import ContentCreate from '@material-ui/icons/Create';
import classNames from 'classnames';
import { useFeature } from 'flagged';
import { linkToRecord, usePermissionsOptimized, useTranslate } from 'ra-core';
import { Link } from 'react-router-dom';
import { ShowMetadataButton } from '.';
import InvoiceActions from '../modules/invoice/InvoiceActions';
import OrderActions from '../modules/order/OrderActions';
import ReceiptAdviceActions from '../modules/receiptAdvice/ReceiptAdviceActions';
import { AlfrescoDocumentService } from '../services';
import { DataHelpers } from '../services/DataHelpers';

//TODO: merge this class with DisplayContextualButton

/**
 * Contextual buttons display management
 */
const ContextualToolbar = (props) => {
  const { record, basePath, className, resource, redirect } = props;
  const translate = useTranslate();
  const { permissions } = usePermissionsOptimized();
  let enoughEditRights: boolean = true;

  // General rules for allowing the edition
  let allowEdit = props.allowEdit ?? true;
  allowEdit =
    allowEdit && DataHelpers.getProcessStatus(record) === ProcessStatus.DRAFT;

  // Specific PEPPOL rights checks
  const peppolInvoiceWriter: boolean = isPeppolInvoiceWriter(permissions);
  const peppolInvoice: boolean =
    DataHelpers.regulatorExtraDetails(record) ===
    RegulatorExtraDetailsType.PEPPOL;

  if (peppolInvoice && !peppolInvoiceWriter) {
    enoughEditRights = false;
  }

  const documentTypeCode: DocumentTypeCode | undefined =
    DataHelpers.getDocumentTypeCode(record);

  const auditTrailEnabled = useFeature('auditTrail');
  let isWebDocument = AlfrescoDocumentService.isWebDocument(record);

  return (
    <div className={classNames('showOnHover', className)}>
      {auditTrailEnabled && !isWebDocument && (
        <AuditTrailButton documentId={record.id} />
      )}
      <ShowMetadataButton
        title='dxMessages.headers.show_metadata'
        record={record}
        resource={resource}
        className='showDocumentMetadata'
        basePath={basePath}
        groupingMode={true}
      />

      {allowEdit && (
        <Tooltip
          title={
            enoughEditRights
              ? translate('ra.action.edit')
              : translate('dxMessages.error_messages.not_enough_rights')
          }
        >
          {/* Define a span in order to still have a tooltip even if the item is disabled */}
          <span>
            <IconButton
              color='primary'
              component={Link}
              className='editDocument'
              resource={resource}
              disabled={!enoughEditRights}
              {...{
                record,
                to: {
                  pathname: linkToRecord(basePath, record.id),
                  state: {
                    documentSubTypeCode:
                      DataHelpers.getDocumentSubtypeCode(record),
                  },
                },
              }}
            >
              <ContentCreate />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <DeleteIconButton
        record={record}
        basePath={basePath}
        label='ra.action.delete'
        className='deleteDocument'
        resource={resource}
        redirect={redirect}
      />
      <MoreButton
        record={record}
        basePath={basePath}
        label=''
        title={'dxMessages.buttons.more'}
        className='moreActions'
        resource={resource}
        menuItems={true}
      >
        <RelatedDocsButton
          record={record}
          basePath={basePath}
          label=''
          className='relatedDocument'
          resource={resource}
        />
        {documentTypeCode === DocumentTypeCode.INVOIC && (
          <InvoiceActions record={record} />
        )}
        {documentTypeCode === DocumentTypeCode.RECADV && (
          <ReceiptAdviceActions record={record} />
        )}
        {documentTypeCode === DocumentTypeCode.ORDERS && (
          <OrderActions record={record} />
        )}
      </MoreButton>
    </div>
  );
};

export default ContextualToolbar;
