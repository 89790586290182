import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { default as classNames, default as classnames } from 'classnames';
import arrayMutators from 'final-form-arrays';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import PropTypes from 'prop-types';
import { HtmlHTMLAttributes, ReactNode, useCallback, useEffect } from 'react';
import {
  Button,
  ClassesOverride,
  FilterFormInput,
  useGetIdentity,
} from 'react-admin';
import { Form, FormRenderProps, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { DxTheme } from '../../types';
import { GA_EVENTS, sendGAEvent } from '../../utils';
import SavedQueriesSaveBtn from '../saved-queries/SavedQueriesSaveBtn';
import FilterButton from './FilterButton';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      marginTop: -theme.spacing(2),
      paddingTop: 0,
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      minHeight: theme.spacing(10),
      pointerEvents: 'none',
    },
    clearFix: { clear: 'right' },
    applyButtonContainer: {
      width: '100%',
      justifyContent: 'flex-end',
      display: 'flex',
      position: 'relative',
    },
    container: {
      padding: theme.spacing(1),
    },
    // Pulse effect on Search button on tranistion from disabled to available.
    availableSearch: {
      animation: '$pulse 1s ease',
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1, 1)',
      },
      '50%': {
        transform: 'scale(1.1, 1.1)',
      },
      '100%': {
        transform: 'scale(1, 1)',
      },
    },
  }),
  { name: 'DxFilterForm' }
);

const sanitizeRestProps = ({
  active,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  error,
  errors,
  filterValues,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  invalid,
  modified,
  modifiedSinceLastSubmit,
  pristine,
  setFilters,
  showFilter,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touched,
  valid,
  validating,
  values,
  visited,
  ...props
}: Partial<FilterFormProps>) => props;

export interface FilterFormProps
  extends Omit<FormRenderProps, 'initialValues'>,
    Omit<HtmlHTMLAttributes<HTMLFormElement>, 'children'> {
  classes?: ClassesOverride<typeof useStyles>;
  className?: string;
  resource?: string;
  filterValues: any;
  hideFilter: (filterName: string) => void;
  setFilters: (filters: any, displayedFilters: any) => void;
  showFilter: (filterName: string, defaultValue: any) => void;
  displayedFilters: any;
  filters: ReactNode[];
  initialValues?: any;
  margin?: 'none' | 'normal' | 'dense';
  variant?: 'standard' | 'outlined' | 'filled';
}

const FilterForm = ({
  classes = {},
  className,
  resource,
  margin,
  variant,
  filters,
  displayedFilters = {},
  hideFilter,
  initialValues,
  ...rest
}: FilterFormProps) => {
  useEffect(() => {
    filters.forEach((filter: any) => {
      if (filter.props.alwaysOn && filter.props.defaultValue) {
        throw new Error(
          'Cannot use alwaysOn and defaultValue on a filter input. Please set the filterDefaultValues props on the <List> element instead.'
        );
      }
    });
  }, [filters]);

  const getShownFilters = () =>
    filters.filter(
      (filterElement: any) =>
        filterElement.props.alwaysOn ||
        displayedFilters[filterElement.props.source] ||
        typeof lodashGet(initialValues, filterElement.props.source) !==
          'undefined'
    );

  const handleHide = useCallback(
    (event) => hideFilter(event.currentTarget.dataset.key),
    [hideFilter]
  );

  return (
    <form
      className={classnames(className, classes.form)}
      {...sanitizeRestProps(rest)}
      onSubmit={handleSubmit}
    >
      {getShownFilters().map((filterElement: any) => (
        <FilterFormInput
          key={filterElement.props.source}
          filterElement={filterElement}
          handleHide={handleHide}
          resource={resource}
          variant={filterElement.props.variant || variant}
          margin={filterElement.props.margin || margin}
        />
      ))}
      <div className={classes.clearFix} />
    </form>
  );
};

const handleSubmit = (event) => {
  event.preventDefault();
  return false;
};

FilterForm.propTypes = {
  resource: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.node).isRequired,
  displayedFilters: PropTypes.object,
  hideFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export const mergeInitialValuesWithDefaultValues = ({
  initialValues,
  filters,
}) => ({
  ...filters
    .filter(
      (filterElement: JSX.Element) =>
        filterElement.props.alwaysOn && filterElement.props.defaultValue
    )
    .reduce(
      (acc, filterElement: JSX.Element) =>
        lodashSet(
          { ...acc },
          filterElement.props.source,
          filterElement.props.defaultValue
        ),
      {} as any
    ),
  ...initialValues,
});

const ApplyButton = (props) => {
  const { setFilters } = props;
  const formState = useFormState();
  const classes = useStyles();
  const loading = useSelector((state: any) => {
    return state.admin.loading > 0;
  });
  const isXSmall = useMediaQuery((theme: DxTheme) =>
    theme.breakpoints.down('xs')
  );
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  return (
    <Button
      label='ra.action.search'
      variant='outlined'
      onClick={() => {
        setFilters(formState.values);
        sendGAEvent(
          GA_EVENTS.categories.SEARCH.name,
          GA_EVENTS.categories.SEARCH.actions.SEARCH,
          account?.company?.cmsRootDir
        );
      }}
      disabled={formState.pristine || formState.invalid || loading}
      className={classNames(
        !formState.pristine && formState.valid && !loading
          ? classes.availableSearch
          : undefined,
        'trigger-search'
      )}
    >
      {isXSmall ? <SearchIcon /> : undefined}
    </Button>
  );
};

const EnhancedFilterForm = (props) => {
  const { classes: classesOverride, ...rest } = props;
  const classes = useStyles(props);

  const mergedInitialValuesWithDefaultValues =
    mergeInitialValuesWithDefaultValues(props);

  const { initialValues, ...rest2 } = rest;

  return (
    <Form
      onSubmit={handleFinalFormSubmit}
      initialValues={mergedInitialValuesWithDefaultValues}
      mutators={{ ...arrayMutators }}
      render={(formProps) => (
        <>
          <Grid container className={classes.container}>
            <Grid item>
              <FilterButton {...formProps} {...rest2} />
            </Grid>
            <Grid item>
              <SavedQueriesSaveBtn disabled={!formProps.pristine} />
            </Grid>
            <Grid item>
              <FilterForm classes={classes} {...formProps} {...rest2} />
            </Grid>
            <Grid item className={classes.applyButtonContainer}>
              <ApplyButton setFilters={rest.setFilters} />
            </Grid>
          </Grid>
        </>
      )}
    />
  );
};

const handleFinalFormSubmit = () => {};

export default EnhancedFilterForm;
