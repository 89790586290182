import { FC } from 'react';
import {
  Edit,
  EditProps,
  FieldProps,
  FormTab,
  SaveButton,
  TabbedForm,
  Toolbar,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { Company } from '../types';
import AccountsChangeHistoryTab from './edition/AccountsChangeHistoryTab';
import CompanyTab from './edition/CompanyTab';
import DelegationsTab from './edition/DelegationsTab';
import SubscriptionsTab from './edition/SubscriptionsTab';

/**
 * The edition of a company.
 *
 * Only visible for administrators.
 * Three tabs: (1) general information & supported authentication types,
 * (2) the features subscribed by the company, (3) the delegation of employees usage rights,
 */
const CompanyEdit: FC<EditProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('resources.companies.edit.notifications.updated');
    refresh();
  };

  return (
    <Edit
      title={<CompanyTitle />}
      component='div'
      undoable={false}
      onSuccess={onSuccess}
      {...props}
    >
      <TabbedForm toolbar={<CompanyEditToolbar />}>
        <FormTab label='resources.companies.edit.tabs.company.title'>
          <CompanyTab />
        </FormTab>
        <FormTab label='resources.companies.edit.tabs.subscriptions.title'>
          <SubscriptionsTab />
        </FormTab>
        <FormTab label='resources.companies.edit.tabs.delegations.title'>
          <DelegationsTab />
        </FormTab>
        <FormTab label='resources.companies.edit.tabs.history.title'>
          <AccountsChangeHistoryTab />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const CompanyTitle: FC<FieldProps<Company>> = ({ record }) => {
  const translate = useTranslate();

  if (!record) return null;
  return (
    <span>
      {translate('resources.companies.edit.title', {
        companyName: record.name,
      })}
    </span>
  );
};

/**
 * Formats excludedRoleIds content before sending to dataProvider
 * @param formData form data
 * @returns form data containing good excludedRoleIds format
 */
const transformBeforeSaving = (formData) => {
  const newSubscriptionsFormat = formData.subscriptions?.map((s) => {
    if (s.excludedRoleIds) {
      const newExcludedRoleIds = s.excludedRoleIds.map((e) =>
        e.id ? e.id : e
      );
      s.excludedRoleIds = newExcludedRoleIds;
    }
    return s;
  });
  formData.subscriptions = newSubscriptionsFormat
    ? newSubscriptionsFormat
    : formData.subscriptions;
  return formData;
};

/**
 * Remove the delete action.
 */
const CompanyEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} transform={transformBeforeSaving} />
  </Toolbar>
);

export default CompanyEdit;
