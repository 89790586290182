import get from 'lodash/get';
import { useCallback, useState } from 'react';
import {
  Loading,
  ShowContext,
  ShowView,
  SimpleShowLayout,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { Metadata } from '../../constants';
import { documentTypeCodeToResource, getMessagePrefix } from '../../utils';
import { AttachmentRows } from './AttachmentRows';
import { RelatedDocumentsPropsModel } from './RelatedDocuments';
import RelatedDocumentsShowController from './RelatedDocumentsShowController';

/**
 * Removes unexpected prop(s) from the DOM in order to not inject it(them) to the children
 */
const SanitizePropsComponent = (props) => {
  const { basePath, children, allDocs, ...rest } = props;
  return <div {...rest}>{children}</div>;
};

export interface RelatedAttachmentsPropsModel
  extends RelatedDocumentsPropsModel {
  id: string;
  redirectOnDelete?: boolean;
  refreshOnDelete?: boolean;
  version?: number;
}

const RelatedAttachments = ({
  columnsProcessDetails,
  commonColumns,
  showMetadataButton,
  downloadButton,
  previewButton,
  resource,
  id,
  redirectOnDelete = true,
  refreshOnDelete = true,
  version,
}: RelatedAttachmentsPropsModel) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const [localVersion, setLocalVersion] = useState(0);

  const basePath = `/${resource}`;

  // Defining the columns to be displayed
  const linkedColumns = [...commonColumns, ...columnsProcessDetails].filter(
    (column) => column.displayInLinkedDocuments === true
  );

  Object.values(linkedColumns).forEach((value) => {
    if (value.id === 'edm:documentId') {
      value.label = 'dxMessages.headers.documentId';
    }
  });

  const standardColumns = commonColumns.filter(
    (column) => column.displayInRelatedDocuments === true
  );

  const refreshLocal = useCallback(() => {
    // hack to reload the attached documents after a delete
    setLocalVersion(localVersion + 1);
  }, [localVersion]);

  const handleDeleteAttachment = useCallback(() => {
    refreshLocal();
  }, [refreshLocal]);

  const handleReloadAttachments = useCallback(() => {
    if (version === undefined) {
      refreshLocal();
    } else {
      refresh();
    }
  }, [refresh, refreshLocal, version]);

  if (!resource) {
    return null;
  }

  return (
    <RelatedDocumentsShowController
      resource={resource}
      id={id}
      version={version !== undefined ? version : localVersion}
    >
      {(controllerProps) => {
        const { setSort, currentSortAttach, isLoading, record } =
          controllerProps;

        if (isLoading) {
          return <Loading />;
        } else {
          const documentTypeCode = get(
            record.parentDocument.properties,
            Metadata.documentTypeCode
          );
          const specificResource = documentTypeCodeToResource(
            documentTypeCode,
            get(
              record.parentDocument?.properties,
              Metadata.processDocumentFormatType
            )
          );
          const documentId = get(
            record.parentDocument.properties,
            Metadata.documentId
          );
          const attachments = record.attachments;
          const processStatus = get(
            record.parentDocument.properties,
            Metadata.processStatus
          );
          const invoiceType = get(
            record.parentDocument.properties,
            Metadata.invoiceType
          );
          const recipientId = get(
            record.parentDocument.properties,
            Metadata.recipientId
          );
          const prefix = translate(
            `dxMessages.${getMessagePrefix(specificResource)}.show_title`
          );
          const title = documentId ? prefix + documentId : <></>;
          const sanitizeControllerProps = ({
            setSort,
            currentSortMessage,
            currentSortRelated,
            currentSortAttach,
            currentSortLinked,
            isLoading,
            ...rest
          }: any) => rest;

          return (
            <ShowContext.Provider
              value={sanitizeControllerProps(controllerProps)}
            >
              <ShowView title={title} actions={false}>
                <SimpleShowLayout>
                  <SanitizePropsComponent>
                    <AttachmentRows
                      standardColumns={standardColumns}
                      basePath={basePath}
                      resource={resource}
                      specificResource={specificResource}
                      showMetadataButton={showMetadataButton}
                      downloadButton={downloadButton}
                      previewButton={previewButton}
                      currentSortAttach={currentSortAttach}
                      setSort={setSort}
                      id={id}
                      attachments={attachments}
                      recipientId={recipientId}
                      processStatus={processStatus}
                      invoiceType={invoiceType}
                      redirectOnDelete={redirectOnDelete}
                      refreshOnDelete={refreshOnDelete}
                      onDelete={handleDeleteAttachment}
                      onReload={handleReloadAttachments}
                      parentMetadata={record.parentDocument.properties}
                    />
                  </SanitizePropsComponent>
                </SimpleShowLayout>
              </ShowView>
            </ShowContext.Provider>
          );
        }
      }}
    </RelatedDocumentsShowController>
  );
};

export default RelatedAttachments;
