import {
  AutoCompleteSingleSelect,
  colors,
  Constants,
  DxTheme,
} from '@dx-ui/dx-common';
import { FormControl, Tooltip, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import {
  Button,
  useDataProvider,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { CompanyModel, CompanyService } from '../services/CompanyService';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: (props: { isSmall }) => {
      return {
        marginLeft: '4px',
        height: '40px',
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        marginTop: props.isSmall ? '24px' : 'inherit',
        marginRight: props.isSmall ? '4px' : 'inherit',
      };
    },
    renderAsbutton: {
      borderRadius: 4,
      '& div:before': {
        borderBottom: 'unset !important', // remove MuiInput underline to mimic a button
      },
      '& div:after': {
        borderBottom: 'unset !important', // remove MuiInput underline to mimic a button
      },
      '& div': {
        top: 2,
      },
      // Behaves such as react-admin buttons on hover and focus
      '&:hover': {
        'box-sizing': 'border-box', // avoid weird incompatible type error with CSSProperties
        borderRadius: 4,
        backgroundColor: theme.palette.primary.light,
      },
      '& div[role=button]:focus': {
        backgroundColor: 'transparent',
      },
    },
  }),
  { name: 'CreateButton' }
);

const CreateButton = (props) => {
  const {
    basePath = '',
    label = 'ra.action.create',
    className,
    resource,
    variant = 'text',
  } = props;
  const [listOfRecipients, setListOfRecipients] = useState<CompanyModel[]>([]);
  const isSmall = useMediaQuery((theme: DxTheme) =>
    theme.breakpoints.down('sm')
  );
  const classes = useStyles({ isSmall, ...props });
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  const getPathname = (resource, basePath): string => {
    let pathname = '';
    if (resource === Constants.RESOURCE_WEBINVOICE) {
      pathname = `${basePath}/create-invoice`;
    } else if (resource === Constants.RESOURCE_WEBDESPATCH_ADVICE) {
      pathname = `${basePath}/create-despatchAdvice`;
    } else if (resource === Constants.RESOURCE_WEBWAYBILL) {
      pathname = `${basePath}/create-waybill`;
    } else if (resource === Constants.RESOURCE_WEBRECEIPT_ADVICE) {
      pathname = `${basePath}/create-receiptAdvice`;
    } else if (resource === Constants.RESOURCE_WEBORDER) {
      pathname = `${basePath}/create-order`;
    } else {
      throw new Error(
        `Error unknown resource : ${resource}. Unable to create document.`
      );
    }
    return pathname;
  };

  const renderIcon = (translate, icon, label) => (
    <Tooltip title={translate(label, { _: label })}>
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          color: colors.mainColor5,
          fontSize: 'small',
        }}
      >
        {icon}
        <div style={{ display: 'block', textTransform: 'uppercase' }}>
          {translate(label, { _: label })}
        </div>
        <ArrowDropDownIcon />
      </div>
    </Tooltip>
  );

  const handleChange = (evt) => {
    const newValue = evt.target.value;
    const pathname = getPathname(resource, basePath);

    redirect(`${pathname}/${newValue}`);
  };

  const getOptionId = (x) => x.id;
  const renderOptionLabel = (x) => x.label;

  const filterOption = (option, query, lowerCaseQuery, translate) => {
    if (!query) {
      return true;
    }
    let { label } = option;
    return label.toLowerCase().indexOf(lowerCaseQuery) !== -1;
  };

  useEffect(() => {
    const fetchRecipients = async () => {
      if (
        resource === Constants.RESOURCE_WEBINVOICE ||
        resource === Constants.RESOURCE_WEBWAYBILL ||
        resource === Constants.RESOURCE_WEBDESPATCH_ADVICE ||
        resource === Constants.RESOURCE_WEBRECEIPT_ADVICE ||
        resource === Constants.RESOURCE_WEBORDER
      ) {
        const companyService = new CompanyService(dataProvider);
        const allowedRecipients: CompanyModel[] =
          await companyService.getRecipientsForCreationFromScratch(resource);
        setListOfRecipients(allowedRecipients);
      }
    };
    fetchRecipients();
  }, [dataProvider, resource]);

  const pathname = getPathname(resource, basePath);

  if (listOfRecipients.length === 1) {
    return (
      <Button
        className={classnames(classes.root, className)}
        label={label}
        color='primary'
        component={Link}
        variant={variant}
        type='button'
        to={`${basePath}${pathname}/${listOfRecipients[0].identification}`}
      >
        <AddIcon />
      </Button>
    );
  } else if (listOfRecipients.length > 1) {
    let options: Array<{ id: string; label: string }> = [];
    listOfRecipients.forEach((recipient) => {
      options.push({ id: recipient.identification, label: recipient.name });
    });
    return (
      <FormControl
        className={classnames(classes.root, classes.renderAsbutton, className)}
      >
        <AutoCompleteSingleSelect
          value=''
          onChange={handleChange}
          sortLabels={true}
          options={options}
          getOptionId={getOptionId}
          renderOptionLabel={renderOptionLabel}
          filterOption={filterOption}
          renderValue={renderIcon(translate, <AddIcon />, label)}
          numberToDisplaySearch={5}
        />
      </FormControl>
    );
  } else {
    return null;
  }
};

export default CreateButton;
