import { Grid } from '@material-ui/core';
import React from 'react';
import { checkFieldValidity } from '../utils';
import { FieldComponentProps } from './FieldComponentProps';

/**
 * Renders an invisible dummy component
 */
const LayoutSpacer = (props: FieldComponentProps) => {
  const { field } = props;
  const error = checkFieldValidity(field);
  if (error) return error;

  return <Grid item xs={field?.options?.xs}></Grid>;
};

export default LayoutSpacer;
