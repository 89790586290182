import { NumberInput } from '@dx-ui/dx-common';
//import { NumberInput } from 'react-admin';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a NumberInput according to data template
 * The rendering takes into account that the field can be a member of an array.
 */
const NumberInputField = (props: FieldComponentProps) => {
  const { field } = props;
  const sanitizeProps: any = ({
    loading,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <NumberInput
          {...sanitizeProps(fieldProps)}
          titleSuffix={field?.businessTerms}
        />
      )}
    </FieldWrapper>
  );
};

export default NumberInputField;
