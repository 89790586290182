import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Translate } from 'react-admin';

export enum LineStatusCode {
  NO_CHANGE = '5',
  NOT_ACCEPTED = '7',
  UPDATED = '3',
}

export const MT_LOCALIZATION = (translate: Translate) => ({
  header: { actions: '' },
  body: {
    emptyDataSourceMessage: translate(
      'dxMessages.task.reginamaria.table.emptyOrderTable'
    ),
    editTooltip: translate('dxMessages.task.reginamaria.table.editTooltip'),
    deleteTooltip: translate('dxMessages.task.reginamaria.table.deleteTooltip'),
    addTooltip: translate('dxMessages.task.reginamaria.table.addTooltip'),
    editRow: {
      cancelTooltip: translate(
        'dxMessages.task.reginamaria.table.cancelTooltip'
      ),
      saveTooltip: translate('dxMessages.task.reginamaria.table.saveTooltip'),
      deleteText: translate('dxMessages.task.reginamaria.table.deleteText'),
    },
  },
  toolbar: {
    addRemoveColumns: translate(
      'dxMessages.task.reginamaria.table.addRemoveColumns'
    ),
    nRowsSelected: translate('dxMessages.task.reginamaria.table.nRowsSelected'),
    searchTooltip: translate('dxMessages.task.reginamaria.table.searchTooltip'),
    searchPlaceholder: translate(
      'dxMessages.task.reginamaria.table.searchPlaceholder'
    ),
    showColumnsTitle: translate(
      'dxMessages.task.reginamaria.table.showColumnsTitle'
    ),
  },
  pagination: {
    labelRowsSelect: translate(
      'dxMessages.task.reginamaria.table.labelRowsSelect'
    ),
    labelDisplayedRows: translate(
      'dxMessages.task.reginamaria.table.labelDisplayedRows'
    ),
    firstTooltip: translate('dxMessages.task.reginamaria.table.firstTooltip'),
    previousTooltip: translate(
      'dxMessages.task.reginamaria.table.previousTooltip'
    ),
    nextTooltip: translate('dxMessages.task.reginamaria.table.nextTooltip'),
    lastTooltip: translate('dxMessages.task.reginamaria.table.lastTooltip'),
  },
});

export const formatDate = (value) => {
  return moment(value).format('YYYY-MM-DD');
};

export const excludeColumn = (columnNames: string[], value: string) => {
  return columnNames.includes(value);
};

export const readOnlyColumn = (columnNames: string[], value: string) => {
  return columnNames.includes(value);
};

/**
 * Ensures that the definition of the delivery nodes at line level will exist and is consistent
 * @param orderLine one OrderLine
 * @param form
 * @returns an order line compliant UBL
 */
export const formatUblLineSupplier = (orderLine, form) => {
  let id = 0;
  // Retrieves the header delivery information for default values usage
  const order: any = form.getState().values.flow?.variables?.order;

  // initializes the Delivery array if not exists.
  if (!orderLine.LineItem.Delivery) {
    orderLine.LineItem.Delivery = [{}];
  }

  orderLine.LineItem.Delivery.forEach((item) => {
    if (item.ActualDeliveryDate?.value) {
      item.ActualDeliveryDate.value = formatDate(item.ActualDeliveryDate.value);
    }
    if (item.RequestedDeliveryPeriod) {
      if (item.RequestedDeliveryPeriod.StartDate?.value) {
        item.RequestedDeliveryPeriod.StartDate.value = formatDate(
          item.RequestedDeliveryPeriod.StartDate.value
        );
      }
      if (item.RequestedDeliveryPeriod.EndDate?.value) {
        item.RequestedDeliveryPeriod.EndDate.value = formatDate(
          item.RequestedDeliveryPeriod.EndDate.value
        );
      }
      if (!item.Quantity?.value) {
        item.Quantity = {
          value: orderLine.LineItem.Quantity.value,
        };
        orderLine.LineItem.LineStatusCode = LineStatusCode.UPDATED;
      }
    } else {
      // At least creates the requestedDeliveryPeriod from the header Delivery
      // and let the validations detect any missing value
      if (order?.Delivery[0].RequestedDeliveryPeriod) {
        item.RequestedDeliveryPeriod = {
          StartDate: {
            value: order?.Delivery[0].RequestedDeliveryPeriod?.StartDate?.value
              ? formatDate(
                  order?.Delivery[0].RequestedDeliveryPeriod?.StartDate?.value
                )
              : null,
          },
          EndDate: {
            value: order?.Delivery[0].RequestedDeliveryPeriod?.EndDate?.value
              ? formatDate(
                  order?.Delivery[0].RequestedDeliveryPeriod?.EndDate?.value
                )
              : null,
          },
        };
      }
      // and reports the line header Delivery Quantity
      if (!item.Quantity?.value) {
        item.Quantity = {
          value: orderLine.LineItem.Quantity.value,
        };
      }
      orderLine.LineItem.LineStatusCode = LineStatusCode.UPDATED;
    }
    id = id + 1;
    item.ID = { value: id.toString() };
  });
  return orderLine;
};

export const formatUblClient = (ubl) => {
  // Format IssueDate
  if (ubl?.IssueDate) {
    ubl.IssueDate.value = formatDate(ubl.IssueDate.value);
  }
  // Set the order type code
  if (!ubl.OrderTypeCode) {
    ubl.OrderTypeCode = {};
  }
  ubl.OrderTypeCode.value = '220';

  if (ubl?.Delivery) {
    ubl?.Delivery.forEach((del) => {
      // Format delivery dates
      if (del.RequestedDeliveryPeriod) {
        if (del.RequestedDeliveryPeriod.StartDate?.value) {
          del.RequestedDeliveryPeriod.StartDate.value = formatDate(
            del.RequestedDeliveryPeriod.StartDate.value
          );
        }
        if (del.RequestedDeliveryPeriod.EndDate?.value) {
          del.RequestedDeliveryPeriod.EndDate.value = formatDate(
            del.RequestedDeliveryPeriod.EndDate.value
          );
        }
      }
      if (del.ActualDeliveryDate) {
        del.ActualDeliveryDate.value = formatDate(del.ActualDeliveryDate.value);
      }
      if (del.PromisedDeliveryPeriod) {
        del.PromisedDeliveryPeriod.StartDate.value = formatDate(
          del.PromisedDeliveryPeriod.StartDate.value
        );
        del.PromisedDeliveryPeriod.EndDate.value = formatDate(
          del.PromisedDeliveryPeriod.EndDate.value
        );
      }
    });
  }
  if (ubl?.OrderLine) {
    ubl?.OrderLine?.forEach((item) => {
      // Update the new LineItem.Quantity total
      // item.LineItem.Quantity.value = item.LineItem.Delivery.map(
      //   (item) => item.Quantity.value
      // ).reduce((prev, curr) => prev + curr, 0);

      // Format dates
      if (item) {
        if (item.LineItem?.Item?.ItemInstance) {
          item.LineItem.Item.ItemInstance[0].BestBeforeDate.value = formatDate(
            item.LineItem.Item.ItemInstance[0].BestBeforeDate.value
          );
        }
      }
      if (!item?.LineItem?.Delivery) {
        item.LineItem.Delivery = [{}];
      }
      item?.LineItem?.Delivery?.forEach((del) => {
        if (del.RequestedDeliveryPeriod) {
          if (del.RequestedDeliveryPeriod.StartDate?.value) {
            del.RequestedDeliveryPeriod.StartDate.value = formatDate(
              del.RequestedDeliveryPeriod.StartDate.value
            );
          }
          if (del.RequestedDeliveryPeriod.EndDate?.value) {
            del.RequestedDeliveryPeriod.EndDate.value = formatDate(
              del.RequestedDeliveryPeriod.EndDate.value
            );
          }
        }
        if (del.ActualDeliveryDate?.value) {
          del.ActualDeliveryDate.value = formatDate(
            del.ActualDeliveryDate.value
          );
        }
        if (del.PromisedDeliveryPeriod) {
          if (del.PromisedDeliveryPeriod.StartDate?.value) {
            del.PromisedDeliveryPeriod.StartDate.value = formatDate(
              del.PromisedDeliveryPeriod.StartDate.value
            );
          }
          if (del.PromisedDeliveryPeriod.EndDate?.value) {
            del.PromisedDeliveryPeriod.EndDate.value = formatDate(
              del.PromisedDeliveryPeriod.EndDate.value
            );
          }
        }
      });
    });
  }
  return ubl;
};

export const removeNullOrUndefinedFields = (obj) => {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] &&
        typeof obj[k] === 'object' &&
        removeNullOrUndefinedFields(obj[k])) ||
      (!obj[k] && obj[k] !== undefined && obj[k] !== 0 && delete obj[k])
  );
  return obj;
};

const removeExpectedEmptyNodes = (obj) => {
  obj.OrderLine.forEach((item) => {
    if (!item) {
      // line has been removed
      return;
    }
    if (item.LineItem?.Item?.BuyersItemIdentification?.ID?.value === '') {
      delete item.LineItem.Item.BuyersItemIdentification;
    }
    if (item.LineItem?.Item?.SellersItemIdentification?.ID?.value === '') {
      delete item.LineItem.Item.SellersItemIdentification;
    }
    if (item.LineItem?.Item?.StandardItemIdentification?.ID?.value === '') {
      delete item.LineItem.Item.StandardItemIdentification;
    }
  });

  return obj;
};

const removeRejectedLines = (obj) => {
  obj.OrderLine = obj.OrderLine.filter((e: any) => e);
  return obj;
};

const removeUnexpectedNodes = (obj) => {
  obj.OrderLine.forEach((item) => {
    if (!item) {
      // line has been removed
      return;
    }
    if (item.tableData) {
      delete item.tableData;
    }
    if (item.LineItem?.Delivery) {
      item.LineItem?.Delivery?.forEach((del) => {
        if (del.tableData) {
          delete del.tableData;
        }
      });
    }
  });

  return obj;
};

const calculateTotals = (obj) => {
  // Calculate line item totals
  obj.OrderLine.forEach((item) => {
    if (!item) {
      // line has been removed
      return;
    }
    //LineExtensionAmount
    if (!item.LineItem.LineExtensionAmount) {
      item.LineItem.LineExtensionAmount = {};
      item.LineItem.LineExtensionAmount.value = 0;
      item.LineItem.LineExtensionAmount.currencyID = 'RON';
    }
    item.LineItem.LineExtensionAmount.value = toFixedDecimals(
      item.LineItem.Quantity.value * item.LineItem.Price.PriceAmount.value,
      2
    );
    // TaxTotalAmount
    if (!item.LineItem.TotalTaxAmount) {
      item.LineItem.TotalTaxAmount = {};
      item.LineItem.TotalTaxAmount.value = 0;
      item.LineItem.TotalTaxAmount.currencyID = 'RON';
    }
    const percent =
      item.LineItem.Item.ClassifiedTaxCategory?.[0]?.Percent?.value ||
      item.LineItem.TaxTotal?.[0]?.TaxSubtotal?.[0]?.percent?.value ||
      0;

    if (percent > 0) {
      item.LineItem.TotalTaxAmount.value = toFixedDecimals(
        (percent / 100) * item.LineItem.LineExtensionAmount.value,
        2
      );
    }
    if (item.LineItem.TaxTotal) {
      if (item.LineItem.TaxTotal.length === 0) {
        delete item.LineItem.TaxTotal;
      }
    }
  });

  //TaxTotal/cbc:TaxAmount
  if (obj.TaxTotal && obj.TaxTotal.length > 0) {
    obj.TaxTotal[0].TaxAmount.value = toFixedDecimals(
      obj.OrderLine.reduce(
        (total, currentValue) =>
          (total = total + currentValue.LineItem.TotalTaxAmount.value),
        0
      ),
      2
    );
  } else {
    if (obj.TaxTotal && obj.TaxTotal.length === 0) {
      delete obj.TaxTotal;
    }
  }

  //AnticipatedMonetaryTotal/cbc:TaxExclusiveAmount
  if (!obj.AnticipatedMonetaryTotal.TaxExclusiveAmount) {
    obj.AnticipatedMonetaryTotal.TaxExclusiveAmount = {};
    obj.AnticipatedMonetaryTotal.TaxExclusiveAmount.value = 0;
    obj.AnticipatedMonetaryTotal.TaxExclusiveAmount.currencyID = 'RON';
  }
  obj.AnticipatedMonetaryTotal.TaxExclusiveAmount.value = toFixedDecimals(
    obj.OrderLine.reduce(
      (total, currentValue) =>
        (total = total + currentValue.LineItem.LineExtensionAmount.value),
      0
    ),
    2
  );

  //AnticipatedMonetaryTotal/cbc:TaxInclusiveAmount
  if (!obj.AnticipatedMonetaryTotal.TaxInclusiveAmount) {
    obj.AnticipatedMonetaryTotal.TaxInclusiveAmount = {};
    obj.AnticipatedMonetaryTotal.TaxInclusiveAmount.value = 0;
    obj.AnticipatedMonetaryTotal.TaxInclusiveAmount.currencyID = 'RON';
  }
  obj.AnticipatedMonetaryTotal.TaxInclusiveAmount.value = toFixedDecimals(
    (obj.TaxTotal?.[0]?.TaxAmount?.value || 0) +
      (obj.AnticipatedMonetaryTotal?.TaxExclusiveAmount?.value || 0),
    2
  );

  //AnticipatedMonetaryTotal/cbc:PayableAmount
  if (!obj.AnticipatedMonetaryTotal.PayableAmount) {
    obj.AnticipatedMonetaryTotal.PayableAmount = {};
    obj.AnticipatedMonetaryTotal.PayableAmount.value = 0;
    obj.AnticipatedMonetaryTotal.PayableAmount.currencyID = 'RON';
  }
  obj.AnticipatedMonetaryTotal.PayableAmount.value =
    obj.AnticipatedMonetaryTotal?.TaxInclusiveAmount?.value || 0;

  // New line count in case we have deleted lines
  if (!obj.LineCountNumeric) {
    obj.LineCountNumeric = {};
    obj.LineCountNumeric.value = 0;
  }
  obj.LineCountNumeric.value = obj.OrderLine.length;
  return obj;
};

// Takes a Number as input, sets the decimal places and returns a Number
function toFixedDecimals(num, dp) {
  //toFixed returns a String
  var numToFixedDp = Number(num).toFixed(dp);
  return Number(numToFixedDp);
}

// This will remove the double array created by converting to the mapping format
const removeDoubleArray = (array) => {
  let result = [];
  array.forEach((element: any) => {
    if (Array.isArray(element)) {
      const elem: any = element[0];
      if (elem.LineItem[0].Delivery && elem.LineItem[0].Delivery.length > 0) {
        elem.LineItem[0].Delivery = elem.LineItem[0].Delivery.map(
          (item) => (item = item[0])
        );
      }
      if (
        elem.LineItem[0].Item[0].Description &&
        elem.LineItem[0].Item[0].Description.length > 0
      ) {
        elem.LineItem[0].Item[0].Description[0] =
          elem.LineItem[0].Item[0].Description[0][0];
      }
      if (
        elem.LineItem[0].Item[0].ClassifiedTaxCategory &&
        elem.LineItem[0].Item[0].ClassifiedTaxCategory.length > 0
      ) {
        elem.LineItem[0].Item[0].ClassifiedTaxCategory[0] =
          elem.LineItem[0].Item[0].ClassifiedTaxCategory[0][0];
      }
      if (
        elem.LineItem[0].Item[0].ItemInstance &&
        elem.LineItem[0].Item[0].ItemInstance.length > 0
      ) {
        elem.LineItem[0].Item[0].ItemInstance[0] =
          elem.LineItem[0].Item[0].ItemInstance[0][0];
      }
      if (elem.LineItem[0].TaxTotal && elem.LineItem[0].TaxTotal.length > 0) {
        elem.LineItem[0].TaxTotal[0] = elem.LineItem[0].TaxTotal[0][0];
        if (
          elem.LineItem[0].TaxTotal[0].TaxSubtotal &&
          elem.LineItem[0].TaxTotal[0].TaxSubtotal.length > 0
        ) {
          elem.LineItem[0].TaxTotal[0].TaxSubtotal[0] =
            elem.LineItem[0].TaxTotal[0].TaxSubtotal[0][0];
        }
      }

      // @ts-ignore
      result.push(elem);
    }
  });
  return result;
};

const createObjectsForFields = (obj) => {
  if (
    typeof obj.OrderDocumentReference?.DocumentStatusCode !== 'object' &&
    obj.OrderDocumentReference?.DocumentStatusCode
  ) {
    obj.OrderDocumentReference.DocumentStatusCode = {
      value: obj.OrderDocumentReference?.DocumentStatusCode,
    };
  }
  Object.values(obj.OrderLine).forEach((item: any) => {
    if (typeof item.LineItem.LineStatusCode !== 'object') {
      item.LineItem.LineStatusCode = { value: item.LineItem.LineStatusCode };
    }
  });
  return obj;
};

// Converts all 'value' key names with '_'
const convertsValueToUnderscore = (obj) => {
  const data = JSON.stringify(obj).replaceAll('value', '_');
  return JSON.parse(data);
};

// This is for the mapping. Each json object but be an array
export const convertObjectsToArray = (obj) => {
  // Remove the lines are they require a different style of processing
  let lines = JSON.stringify(obj.OrderLine);
  delete obj.OrderLine;

  // Make all objects and array of objects (quick way)
  var stringify = JSON.stringify(obj);
  stringify = stringify.replaceAll('{', '[{');
  stringify = stringify.replaceAll('}', '}]');
  stringify = stringify.replaceAll('[[', '[');
  stringify = stringify.replaceAll(']]', ']');

  lines = lines.replaceAll('{', '[{');
  lines = lines.replaceAll('}', '}]');

  obj = JSON.parse(stringify);
  // Add back the orderLines to the order after removing the double arrays
  lines = JSON.parse(lines);
  obj[0].OrderLine = removeDoubleArray(lines);

  // Add the header needed by the mapping
  obj = { Order: obj };
  return {
    ...orderHeader,
    ...obj,
  };
};

export const computeFinalOrder = (obj) => {
  let data = cloneDeep(obj);
  // Remove unexected nodes
  data = removeUnexpectedNodes(data);
  // Remove webforn node potentially empty
  data = removeExpectedEmptyNodes(data);
  // Remove rejected lines
  data = removeRejectedLines(data);
  // Format dates
  data = formatUblClient(data);
  //Recalculate totals
  data = calculateTotals(data);
  //Make key/value fields objects
  data = createObjectsForFields(data);
  // Replace all 'value' keys with '_' which is needed for the mapping
  data = convertsValueToUnderscore(data);
  // Remove null fields
  data = removeNullOrUndefinedFields(data);
  // Prepare the order to fit the UBL json schema
  data = convertObjectsToArray(data);
  return data;
};

export const compareDates = (dateToCheck, start, end, extraPeriodInDay) => {
  const proposedDate = new Date(dateToCheck);
  proposedDate.setHours(0, 0, 0, 0);

  const startDate = new Date(start);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(end);
  endDate.setHours(0, 0, 0, 0);
  const endDateWithExtraPeriod = moment(endDate).add(extraPeriodInDay, 'd');
  if (
    proposedDate.getTime() >= startDate.getTime() &&
    proposedDate.getTime() <= parseInt(endDateWithExtraPeriod.format('x'))
  ) {
    return true;
  } else {
    return false;
  }
};

export const isDateInFuture = (dateToCheck) => {
  const proposedDate = new Date(dateToCheck);
  proposedDate.setHours(0, 0, 0, 0);

  const today = new Date(Date.now());
  today.setHours(0, 0, 0, 0);
  if (proposedDate.getTime() >= today.getTime()) {
    return true;
  } else {
    return false;
  }
};

export const datesOverlap = (start, end) => {
  const startDate = new Date(start);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(end);
  endDate.setHours(0, 0, 0, 0);

  if (
    startDate.getTime() > endDate.getTime() ||
    endDate.getTime() < startDate.getTime()
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Validates that the Bulk actions on delivery dates can be applied
 * @param start start date
 * @param end end date
 * @param actual actual date
 * @param notify [optional] useNotify results if the method has to handle the notification
 * @returns true if valid or false
 */
export const validateDeliveryDates = (
  start: any,
  end: any,
  actual: any,
  extraPeriodInDay: number,
  notify?: any
): boolean => {
  let isValid: boolean = true;

  if (start && end) {
    isValid = compareDates(start, start, end, 0);
    if (!isValid) {
      notify &&
        notify(
          'dxMessages.task.reginamaria.errorMsg.incorrect_start_date',
          'warning',
          {
            _: 'Incorrect start delivery date',
          }
        );
      return isValid;
    }
  }
  if (start && end) {
    isValid = compareDates(end, start, end, 0);
    if (!isValid) {
      notify &&
        notify(
          'dxMessages.task.reginamaria.errorMsg.incorrect_end_date',
          'warning',
          {
            _: 'Incorrect end delivery date',
          }
        );
      return isValid;
    }
  }
  if (isValid && start && end && actual) {
    isValid = compareDates(actual, start, end, extraPeriodInDay);
    if (!isValid) {
      notify &&
        notify(
          extraPeriodInDay === 0
            ? 'dxMessages.task.reginamaria.errorMsg.dates_not_in_range'
            : 'dxMessages.task.reginamaria.errorMsg.dates_not_in_range_with_extra',
          'warning',
          {
            _: 'Incorrect actual delivery date',
            extra: extraPeriodInDay,
          }
        );
    }
  }
  if (isValid && start && actual) {
    isValid = compareDates(actual, start, actual, extraPeriodInDay);
    if (!isValid) {
      notify &&
        notify(
          extraPeriodInDay === 0
            ? 'dxMessages.task.reginamaria.errorMsg.dates_not_in_range'
            : 'dxMessages.task.reginamaria.errorMsg.dates_not_in_range_with_extra',
          'warning',
          {
            _: 'Incorrect actual delivery date',
            extra: extraPeriodInDay,
          }
        );
      return isValid;
    }
  }
  if (isValid && end && actual) {
    isValid = compareDates(actual, actual, end, extraPeriodInDay);
    if (!isValid) {
      notify &&
        notify(
          extraPeriodInDay === 0
            ? 'dxMessages.task.reginamaria.errorMsg.dates_not_in_range'
            : 'dxMessages.task.reginamaria.errorMsg.dates_not_in_range_with_extra',
          'warning',
          {
            _: 'Incorrect actual delivery date',
            extra: extraPeriodInDay,
          }
        );
      return isValid;
    }
  }
  return isValid;
};

export const addEmptyLineForPartialAcceptance = (order, updatedOrder) => {
  const removedLines = order.OrderLine.filter(function (item) {
    return item.LineItem.LineStatusCode === LineStatusCode.NOT_ACCEPTED;
  });
  // Add a null row for the comparing algorithm to understand what was changed (we have to keep the same order)
  if (removedLines) {
    removedLines.forEach((element) => {
      const lineItem = element.LineItem;
      updatedOrder.OrderLine.splice(parseInt(lineItem.ID.value) - 1, 0, null);
    });
  }
  return updatedOrder;
};

export const orderHeader: any = {
  _D: 'urn:oasis:names:specification:ubl:schema:xsd:Order-2',
  _A: 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2',
  _B: 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2',
};
