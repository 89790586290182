import {
  GA_EVENTS,
  isPspAdministrator,
  sendGAEvent,
  useGAPageViews,
} from '@dx-ui/dx-common/src';
import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  FilterProps,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  TextInput,
  useGetIdentity,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { Empty } from '../../../common/Empty';

const DxProductFilters = (props: Omit<FilterProps, 'children'>) => {
  const { permissions, loaded } = usePermissions();
  if (!loaded || !permissions) return null;

  return (
    <Filter {...props}>
      <TextInput
        label={'dxMessages.companyManagement.dxProduct.description'}
        source='description'
        alwaysOn
      />
    </Filter>
  );
};

const DxProductList = (props: any) => {
  const notify = useNotify();
  const translate = useTranslate();
  useGAPageViews();
  const { permissions, loaded } = usePermissions();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  if (!loaded || !permissions) return null;

  return (
    <List
      {...props}
      filters={<DxProductFilters />}
      empty={isPspAdministrator(permissions) ? <Empty /> : false}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        <TextField
          label={'dxMessages.companyManagement.dxProduct.description'}
          source='description'
        />
        <TextField
          label={'dxMessages.companyManagement.dxProduct.comments'}
          source='comments'
          sortable={false}
        />
        <FunctionField
          label={'dxMessages.companyManagement.dxProduct.flgActive'}
          render={(record: any) => {
            return record.flgActive === 0 ? '\u274C' : '\u2705';
          }}
          sortable={false}
        />
        <ReferenceField
          label='dxMessages.companyManagement.dxProduct.idProductGroup'
          source='idProductGroup'
          reference='management/dropdowndefinition'
          link={false}
          sortable={false}
        >
          <TextField source='description' />
        </ReferenceField>
        <EditButton />
        <DeleteWithConfirmButton
          onClick={() =>
            sendGAEvent(
              GA_EVENTS.categories.DROPDOWN_DEF_MGMT.name,
              GA_EVENTS.categories.DROPDOWN_DEF_MGMT.actions.DELETE_DROPDOWNDEF,
              account?.company?.cmsRootDir
            )
          }
          onFailure={(error) => {
            if (error.status === 400) {
              notify(
                'dxMessages.companyManagement.dxProduct.delete_product_error',
                'error',
                {
                  _: translate(
                    'dxMessages.companyManagement.dxProduct.delete_product_error'
                  ),
                  error: translate(
                    'dxMessages.companyManagement.dxProduct.delete_product_error'
                  ),
                }
              );
            } else {
              notify(
                'dxMessages.companyManagement.dxProduct.delete_product_error',
                'error',
                {
                  _: translate(
                    'dxMessages.companyManagement.dxProduct.delete_product_error'
                  ),
                  error: `${error}`,
                }
              );
            }
          }}
        />
      </Datagrid>
    </List>
  );
};

export default DxProductList;
