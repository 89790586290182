import { Constants, isPspAdministrator } from '@dx-ui/dx-common/src';
import { Button } from '@material-ui/core';
import {
  AutocompleteInput,
  Create,
  NumberInput,
  OnFailure,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  Toolbar,
  required,
  useNotify,
  usePermissions,
  useRedirect,
  useTranslate,
} from 'react-admin';

export const ProductUsageCreate: any = ({
  setCreate,
  setEdit,
  setNewProps,
  ...props
}) => {
  const { permissions } = usePermissions();

  const notify = useNotify();

  const redirect = useRedirect();

  const onFailure: OnFailure = (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error?.status === 409) {
      //contracted product already exist
      notify('dxMessages.error_messages.product_usage_already_exists', 'error');
    } else {
      //unknown error
      notify('dxMessages.myAccount.OnError_contact_the_support', 'error', {
        error: error.message,
      });
    }
  };

  const onSuccess = async (saved) => {
    notify('resources.address.edit.notifications.created');
    if (setCreate !== undefined) {
      props.record = saved.data;
      setNewProps(props);
      setCreate(false);
      setEdit(true);
    } else {
      redirect('edit', '/' + Constants.RESOURCE_PRODUCTUSAGE, saved.data.id);
    }
  };

  const CustomCreateToolbar = (props) => (
    <Toolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton />
      <CancelButton />
    </Toolbar>
  );

  const CancelButton = () => {
    const translate = useTranslate();
    return (
      <Button
        onClick={() => {
          if (setCreate !== undefined) {
            setCreate(false);
          } else {
            redirect('list', props.basePath);
          }
        }}
        variant='contained'
      >
        {translate('ra.action.cancel')}
      </Button>
    );
  };

  return (
    <Create
      {...props}
      onFailure={onFailure}
      onSuccess={onSuccess}
      resource={Constants.RESOURCE_PRODUCTUSAGE}
    >
      <SimpleForm toolbar={<CustomCreateToolbar />}>
        {isPspAdministrator(permissions) && (
          <ReferenceInput
            label='resources.accounts.create.wizard.steps.companySelection.company'
            source='idaccounts'
            reference='management/company'
            validate={required()}
            filterToQuery={(searchText) => ({
              accountname: [searchText],
            })}
            sort={{ field: 'accountname', order: 'ASC' }}
            defaultValue={parseInt(props?.id)}
            disabled={props.id}
          >
            <AutocompleteInput optionText='accountname' fullWidth />
          </ReferenceInput>
        )}
        <ReferenceInput
          label={'dxMessages.companyManagement.productUsage.product'}
          source='iddxproduct'
          reference='management/dxproduct'
          validate={required()}
          filterToQuery={(searchText) => ({
            description: [searchText],
          })}
          sort={{ field: 'description', order: 'ASC' }}
        >
          <AutocompleteInput optionText='description' />
        </ReferenceInput>
        <NumberInput
          label={'dxMessages.companyManagement.productUsage.totalusage'}
          source='totalcount'
          disabled
          defaultValue={0}
        />
        <NumberInput
          label={'dxMessages.companyManagement.productUsage.totalcredit'}
          source='totalcredit'
          disabled
          defaultValue={0}
        />
        <NumberInput
          label={'dxMessages.companyManagement.productUsage.thresold'}
          source='thresold'
        />
      </SimpleForm>
    </Create>
  );
};

export default ProductUsageCreate;
