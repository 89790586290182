import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import * as React from 'react';
import { FC } from 'react';
import {
  ArrayInput,
  FieldProps,
  required,
  SimpleFormIterator,
  TextInput,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { isPspAdministrator } from '../../configuration';
import { LOGO_AND_LABELS } from '../../identityprovider';
import { Company } from '../../types';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The tab with company general information & its supported authentication types.
 *
 * Only visible for administrators.
 * Only editable by a PSP administartor and read-only for a company administrator.
 */
const CompanyTab: FC<FieldProps<Company>> = (props) => {
  return (
    <Grid container direction='column' style={{ marginTop: '1em' }}>
      <Grid item>
        <Description />
      </Grid>
      <Grid item>
        <UserAuthentificationTypes />
      </Grid>
    </Grid>
  );
};

const Description: FC<FieldProps<Company>> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { loaded, permissions } = usePermissions();

  if (!loaded || !permissions) return null;

  return (
    <Paper className={classes.paper}>
      <Grid container direction='column'>
        {!isPspAdministrator(permissions) && (
          <Grid item>
            <Typography variant='caption'>
              {translate(
                'resources.companies.edit.tabs.company.sections.description.subtitle'
              )}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Grid container direction='column'>
                <Grid item>
                  <TextInput
                    label='resources.companies.edit.tabs.company.sections.description.name'
                    source='name'
                    validate={required()}
                    InputProps={{
                      readOnly: !isPspAdministrator(permissions),
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextInput
                    label='resources.companies.edit.tabs.company.sections.description.fiscalCode'
                    source='fiscalCode'
                    validate={required()}
                    InputProps={{
                      readOnly: !isPspAdministrator(permissions),
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <TextInput
                label='resources.companies.edit.tabs.company.sections.description.address'
                source='address'
                multiline
                validate={required()}
                InputProps={{
                  readOnly: !isPspAdministrator(permissions),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        {isPspAdministrator(permissions) && (
          <Grid item>
            <TextInput
              label='resources.companies.edit.tabs.company.sections.description.cmsRootDir'
              source='cmsRootDir'
              validate={required()}
              fullWidth
              helperText='resources.companies.edit.tabs.company.sections.description.cmsRootDirHelper'
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const UserAuthentificationTypes: FC<FieldProps<Company>> = () => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Paper className={classes.paper} style={{ marginTop: '1em' }}>
      <Typography variant='h5'>
        {translate(
          'resources.companies.edit.tabs.company.sections.userAuthenticationTypes.subtitle'
        )}
      </Typography>
      <Typography variant='caption'>
        {translate(
          'resources.companies.edit.tabs.company.sections.userAuthenticationTypes.subtitleHelper'
        )}
      </Typography>
      <ArrayInput label='' source='allowedIdentityProviders' disabled={true}>
        <SimpleFormIterator>
          <AuthenticationType />
        </SimpleFormIterator>
      </ArrayInput>
    </Paper>
  );
};

const AuthenticationType: FC<FieldProps<Company>> = ({ source }) => {
  const { values } = useFormState();
  const providerId = get(values, `${source}.id`);

  if (!LOGO_AND_LABELS[providerId]) return <span>{providerId}</span>;

  return (
    <Box display='flex' alignItems='center' mt='1em'>
      <img
        src={LOGO_AND_LABELS[providerId].logo}
        alt={LOGO_AND_LABELS[providerId].label}
        style={{ width: '1em', height: '1em' }}
      />
      <Typography>&nbsp;{LOGO_AND_LABELS[providerId].label}</Typography>
    </Box>
  );
};

export default CompanyTab;
