import {
  Constants,
  DxTheme,
  ProcessStatus,
  ReceiptAdviceSubType,
  ToolbarBackButton,
} from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';
import {
  linkToRecord,
  useDataProvider,
  useNotify,
  usePermissionsOptimized,
  useRedirect,
} from 'ra-core';
import { useEffect, useMemo, useState } from 'react';
import { ShowView, SimpleShowLayout, Toolbar } from 'react-admin';
import {
  DataHelpers,
  DocumentServiceFactory,
  InvoiceService,
  InvoiceServiceCreator,
  OrderService,
  ReceiptAdviceService,
} from '../../services';
import { EditWebDocumentButton } from '../../shared';
import DocumentPreviewShowActions from '../../shared/DocumentPreviewShowActions';
import ToolbarButton from '../../shared/ToolbarButton';
import { P2pData } from '../../shared/types';
import { ReceiptAdvicePreview } from './';
import ReceiptAdviceActions from './ReceiptAdviceActions';

const useStyles = makeStyles((theme: DxTheme) => ({
  toolbar: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: 0,
  },
}));

const ReceiptAdviceToolbar = (props: any) => {
  const { record } = props;

  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const [converting, setConverting] = useState<boolean>(false);
  const [canConvertToInvoice, setCanConvertToInvoice] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { permissions } = usePermissionsOptimized();

  // const [resource, setResource] = useState<any>(undefined);
  const [called, setCalled] = useState(false);

  enum ActionType {
    CONVERT_INVOICE,
  }

  const invoiceService = useMemo(
    () =>
      DocumentServiceFactory.create(
        InvoiceServiceCreator,
        dataProvider
      ) as InvoiceService,
    [dataProvider]
  );

  const onClickDoAction = (record: P2pData, actionType: ActionType) => {
    return async (e) => {
      e.preventDefault();

      if (!record.id) {
        throw new Error('record id cannot be null');
      }

      setConverting(true);
      notify('ra.page.loading');

      let editFormResource = '';

      try {
        let result: P2pData;
        switch (actionType) {
          case ActionType.CONVERT_INVOICE:
            result = await invoiceService.createFromReceiptAdvice(record.id);
            editFormResource = Constants.RESOURCE_WEBINVOICE;
            break;
          default:
            throw new Error(`Invalid value for actionType: ${actionType}`);
        }

        setConverting(false);
        redirect(`/${linkToRecord(editFormResource, result.id)}`);
      } catch (e) {
        setConverting(false);
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };
  };

  const properties = record?.properties;

  const allowEdit =
    ReceiptAdviceService.canCreate(permissions) &&
    DataHelpers.getProcessStatus(record) === ProcessStatus.DRAFT;

  useEffect(() => {
    const getConversionsAvailability = async () => {
      const documentRecipientId =
        ReceiptAdviceService.getRecipientId(properties);

      if (!documentRecipientId) {
        throw new Error('Document recipientId should not be empty!');
      }

      setLoading(true);

      try {
        const canCreateInvoice = InvoiceService.canCreate(permissions);
        if (canCreateInvoice) {
          const templateRights = await invoiceService.getInvoiceTemplateRights(
            documentRecipientId,
            OrderService.getRegulatorExtraDetails(properties)
          );
          const invoiceCreationPolicy =
            await invoiceService.fetchCreationPolicy(
              Constants.RESOURCE_INVOICE,
              documentRecipientId
            );
          setCanConvertToInvoice(
            templateRights.cloneOrCreate && invoiceCreationPolicy.conversion
          );
        }
        setLoading(false);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setLoading(false);
      }
    };

    if (properties && dataProvider && permissions) {
      if (!called) {
        setCalled(true);
        getConversionsAvailability();
      }
    }
  }, [properties, dataProvider, permissions, called, invoiceService]);

  const getLabel = (documentSubTypeCode) => {
    let label = 'ra.action.edit';
    if (
      documentSubTypeCode &&
      documentSubTypeCode === ReceiptAdviceSubType.GoodsReceiptAdvice
    ) {
      label = 'dxMessages.buttons.editReceiptAdvice';
    } else {
      label = 'dxMessages.buttons.editGoodsReturn';
    }
    return label;
  };

  return (
    <Toolbar classes={{ toolbar: classes.toolbar }} {...props}>
      <ToolbarBackButton />
      {canConvertToInvoice && (
        <ToolbarButton
          disabled={loading || converting}
          loading={loading || converting}
          label={'dxMessages.buttons.convertToInvoice'}
          onClick={onClickDoAction(record, ActionType.CONVERT_INVOICE)}
        />
      )}
      {allowEdit && (
        <EditWebDocumentButton
          label={getLabel(DataHelpers.getDocumentSubtypeCode(record))}
          {...props}
        />
      )}
      <ReceiptAdviceActions record={record} isMenuItem={false} />
    </Toolbar>
  );
};

const ReceiptAdviceShowView = (props) => {
  const { controllerProps } = props;

  return (
    <ShowView
      actions={<DocumentPreviewShowActions />}
      {...controllerProps}
      title='dxMessages.receiptAdvices.title'
    >
      {controllerProps.record && (
        <SimpleShowLayout>
          <ReceiptAdvicePreview
            record={controllerProps.record}
            toolbar={ReceiptAdviceToolbar}
          />
        </SimpleShowLayout>
      )}
    </ShowView>
  );
};

export default ReceiptAdviceShowView;
