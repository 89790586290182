import * as React from 'react';
import {
  Pagination as RaPagination,
  PaginationProps,
  useListContext,
} from 'react-admin';

const Pagination: React.FC<PaginationProps> = (props) => {
  const { total } = useListContext();
  return <RaPagination data-testid='count' data-testcount={total} {...props} />;
};

export default Pagination;
