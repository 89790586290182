import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField as MuTextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FC } from 'react';
import {
  ArrayInput,
  FieldProps,
  SimpleFormIterator,
  TextInput,
  useInput,
  useLocale,
  useTranslate,
} from 'react-admin';
import { Account } from '../types';

const PRE_DEFINED_JOB_KEYS = {
  en: ['title', 'location', 'office number'],
  fr: ['titre', 'emplacement', '№ bureau'],
  ro: ['titlu', 'locație', 'număr birou'],
};

/**
 * The default <ReactAdmin/> input for a job.
 *
 * It's an array of free (key, value), [{key: 'title', value:'director'}].
 * Its layout depends on the display context (edition, creation, show).
 */
const KeyValueJob: FC<
  FieldProps<Account> & {
    hasCreate?: boolean;
    hasEdit?: boolean;
    hasShow?: boolean;
    disabled?: boolean;
  }
> = ({
  record,
  disabled = false,
  hasEdit = false,
  hasCreate = false,
  hasShow = false,
}) => {
  const translate = useTranslate();

  return (
    <>
      {hasCreate && (
        <Typography
          variant='caption'
          style={{ color: !disabled ? 'black' : 'lightgray' }}
        >
          {translate('resources.accounts.job.create.subtitle')}
        </Typography>
      )}
      {hasEdit && (
        <Typography variant='caption'>
          {translate('resources.accounts.job.edit.subtitle')}
        </Typography>
      )}
      {hasShow && record?.job && (
        <Typography variant='caption'>
          {translate('resources.accounts.job.show.subtitle')}
        </Typography>
      )}
      {(hasEdit || hasCreate) && (
        <ArrayInput label='' source='job.attributes' disabled={disabled}>
          <SimpleFormIterator>
            <JobAttribute disabled={disabled} />
          </SimpleFormIterator>
        </ArrayInput>
      )}
      {hasShow && record?.job && (
        <TableContainer component='div' style={{ marginTop: '1em' }}>
          <Table size='small'>
            <TableBody>
              {record.job.attributes.map((att) => (
                <TableRow key={att.key}>
                  <TableCell component='th' scope='row'>
                    <b>{att.key}:</b>
                  </TableCell>
                  <TableCell width='100%'>{att.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {hasShow && !record?.job && (
        <Typography>{translate('resources.accounts.job.noJob')}</Typography>
      )}
    </>
  );
};

const JobAttribute: FC<FieldProps<Account> & { disabled: boolean }> = ({
  source,
  disabled,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <JobKey source={`${source}.key`} disabled={disabled} />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label='resources.accounts.job.value'
          source={`${source}.value`}
          disabled={disabled}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const JobKey = (props: any) => {
  const translate = useTranslate();
  const locale = useLocale();
  const {
    input: { onChange, value },
  } = useInput(props);

  const { disabled } = props;

  return (
    <Autocomplete
      value={value}
      freeSolo
      autoSelect // Always with freeSolo or free value to trigger the onChange.
      disableClearable
      options={PRE_DEFINED_JOB_KEYS[locale || 'en']}
      getOptionSelected={(option, value) => option === value}
      onChange={(event, val) => onChange(val)}
      renderInput={(params) => (
        <MuTextField
          {...params}
          label={translate('resources.accounts.job.key')}
          variant='filled'
          margin='dense'
          helperText={translate('resources.accounts.job.keyHelper')}
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
      disabled={disabled}
    />
  );
};

export default KeyValueJob;
