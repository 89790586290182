// The authentication provider based on the V3 single sign-on mechanism.
// Login here is a two-step process:
// 1. First, a REST calls to get authenticated: you get back the SSO cookie
//    (a JWS token with your identity). It's a secure, http-only, same-site
//    cookie, so your JS code has no access to it: the browser takes care of
//    forwarding it on each REST calls.
// 2. Then, a REST call on '/usage-rights', to get all the information about the user
//    required by your app.
import queryString from 'query-string';
import { AuthProvider, fetchUtils } from 'react-admin';
import { Constants } from '../constants/Constants';
import { isPasswordManagementUrl } from '../login';
import { UserRoles } from '../security/UserRoles';
import { sendLogout } from './sendLogout';

interface LoginRequest {
  login: string;
  password: string;
  appName: string;
  singleCompanyView: boolean;
  companyId?: string;
}
/**
 * Ask for the theme page
 */
const isThemePageUrl = () => {
  return window.location.hash.match('^#/theme$');
};

export const saveUsageRightsData = (
  username: string | null,
  usageRights: any
) => {
  const { AUTH_ROLES, BSP_ID, COMPANY_UUID, USER_VERSION } = usageRights;
  try {
    // Then deals with permissions
    localStorage.setItem(
      username + '.' + Constants.PERMISSIONS,
      JSON.stringify(AUTH_ROLES)
    );
    // deals with user info.
    localStorage.setItem(
      username + '.' + Constants.BSP_ID,
      JSON.stringify(BSP_ID)
    );
    localStorage.setItem(
      username + '.' + Constants.COMPANY_UUID,
      JSON.stringify(COMPANY_UUID)
    );
    localStorage.setItem(
      username + '.' + Constants.USER_VERSION,
      JSON.stringify(USER_VERSION)
    );
  } catch (ex) {
    // Not really an issue for us ( ex: No more space in local storage)
    // but can be reported anyway as a warning.
    // eslint-disable-next-line no-console
    console.warn(
      'unable to store the current user info in local storage : ' + ex
    );
  }
};

const fetchUsageRights = async (username: string | null) => {
  let response = await fetch('/usage-rights', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    // Needed to make it work on Safari:
    credentials: 'include',
  });
  if (response.status < 200 || response.status >= 300) {
    throw new Error(
      `HTTP error! Cant contact /usage-rights. status: ${response.statusText}`
    );
  } else {
    let data = await response.json();
    saveUsageRightsData(username, data);

    return data;
  }
};

const appSwitch = async (token: any) => {
  let response = await fetch(`${window.location.origin}/api/auth/appSwitch`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    },
    // Needed to make it work on Safari:
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error(
      `HTTP error. Cant contact appSwitch! status: ${response.status}`
    );
  } else {
    let data = await response.json();
    const userName = data.version === 3 ? data.person.email : data.id;
    localStorage.setItem('user', userName);
    return userName;
  }
};

const authProvider: AuthProvider = {
  login: (request: LoginRequest) => {
    const { login: username } = request;
    const options = {
      headers: new Headers({ Accept: 'application/json' }),
      method: 'post',
      body: JSON.stringify(request),
      // Mandatory for Safari < 12 (without it, the (SSO) cookie gets not propagated
      // on all XHR)
      withCredentials: true,
      credentials: 'include',
    };
    return (
      fetchUtils
        // @ts-ignore
        .fetchJson('/api/auth/signin', options)
        // 1. Get the SSO cookie in case of successful login.
        .then((response: any) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          // Saves the current username in the browser session storage
          // It will not be used for authorization purpose but for availability anywhere in the the app.
          if (typeof localStorage !== 'undefined') {
            try {
              localStorage.setItem('user', username);
            } catch (ex) {
              // Not really an issue for us ( ex: No more space in local storage)
              // but can be reported anyway as a warning.
              // eslint-disable-next-line no-console
              console.warn('unable to store the username in local storage');
            }
          }
          return response.json;
        })
        // 2. Save the user account into the local storage.
        .then((user) => {
          localStorage.setItem(Constants.DX_USER_ACCOUNT, JSON.stringify(user));
        })
        // 3. Get the logged user extra info (SSO cookie is on now, so we can access protected URL like '/usage-rights').
        .then(() => fetchUsageRights(username))
    );
  },
  logout: () => {
    sendLogout();
    return Promise.resolve();
  },
  checkAuth: () => {
    return Promise.resolve();
  },
  getPermissions: () => {
    // 1. Just switched App?
    const parsed = queryString.parse(window.location.search);
    if (parsed.token) {
      // Yes, the JWS token is in the URL (the one used to download the new App bundles),
      // "?token=...".

      // Remove the token from the browser navigation bar.
      window.history.pushState(null, '', window.location.href.split('?')[0]);

      // We get the new token and then get the permissions for the user
      return appSwitch(parsed.token)
        .then((value) => fetchUsageRights(value))
        .catch((error: Error) => {
          // eslint-disable-next-line no-console
          console.error(
            'There has been a problem with the fetch operation: ' +
              error.message
          );
        });
    }

    let permissions = {};
    if (isThemePageUrl()) {
      permissions = '[]';
    } else if (isPasswordManagementUrl()) {
      permissions = UserRoles.RESET_PASSWORD;
    } else if (typeof localStorage !== 'undefined') {
      try {
        // First gets the current user name
        const user = localStorage.getItem('user');
        if (!user) throw new Error('No user so no permission!');
        // Then deals with permissions
        const stored = localStorage.getItem(user + '.' + Constants.PERMISSIONS);
        if (!stored) throw new Error('No stored permission!');
        permissions = JSON.parse(stored);
      } catch (ex) {
        // Not really an issue for us ( ex: No more space in local storage)
        // but can be reported anyway as a warning.
        // eslint-disable-next-line no-console
        console.warn(
          'Unable to get the user permissions from local storage : ' + ex
        );
        return Promise.reject();
      }
    }
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      sendLogout();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  lostPassword: (params: any) => {
    const request = new Request('/pwdmgmt/sendemail', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      }),
      credentials: 'include',
      body: JSON.stringify(params),
    });
    return fetch(request);
  },
  changePassword: (params: any) => {
    const request = new Request('/pwdmgmt/reset', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'jwt-token': params.token,
      }),
      credentials: 'include',
      body: JSON.stringify({
        password: params.password,
        confirmedPassword: params.confirmPassword,
      }),
    });
    return fetch(request);
  },
  changeEmail: (params: any) => {
    const request = new Request('/userprofiles/changeemail', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'jwt-token': params.token,
      }),
      credentials: 'include',
    });
    return fetch(request);
  },
  getIdentity: () => {
    try {
      const stringified = localStorage.getItem(Constants.DX_USER_ACCOUNT);
      if (stringified) {
        const account: any = JSON.parse(stringified);
        const usageRights = [
          ...account.configuredUsageRights,
          ...account.delegatedUsageRights,
          ...account.onTheFlyUsageRights,
        ];
        const isPspAdministrator = usageRights.find(
          (ur) =>
            ur.feature.id === 'administration' &&
            ur.roles.find((r) => r.id === 'PSP administrator')
        );
        const isCompanyAdministrator = usageRights.find(
          (ur) =>
            ur.feature.id === 'administration' &&
            ur.roles.find((r) => r.id === 'company administrator')
        );
        return {
          // The base <ReactAdmin/> UserIdentity props.
          // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

          // The name displayed in the App bar. Take the name part of the email to keep it short.
          fullName:
            account.version === 3
              ? account.person.email.split('@')[0]
              : account.person.id.toString(),
          avatar: isPspAdministrator
            ? '/profile/psp-administrator-avatar.svg'
            : isCompanyAdministrator
            ? '/profile/company-administrator-avatar.svg'
            : '/profile/regular-person-avatar.svg',

          // The custom Doc Process props.
          // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
          email: account.person.email,
          firstname: account.person.firstname,
          lastname: account.person.lastname,
          language: account.person.language,
          phone: account.person.phone,
          hasDocProcessIdentity: account.hasDocProcessIdentity,

          ...account,
        };
      }
    } catch {}

    return undefined;
  },
};

export default authProvider;
