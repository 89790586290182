import {
  IconButton,
  InputAdornment,
  TextField,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useInput, useTranslate } from 'react-admin';

export interface StyleProps {
  minLengthThreshold: number;
}
const useStyles = makeStyles<Theme, StyleProps>(
  (theme) => ({
    clearButton: {
      width: 24,
      height: 24,
    },
    clearIcon: {
      height: 16,
      width: 0,
    },
    visibleClearIcon: {
      width: 16,
    },
    textFieldInput: {
      width: (props) => `${props.minLengthThreshold / 1.4}em`,
      /* Chrome, Safari, Edge, Opera */
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      /* Firefox */
      '-moz-appearance': 'textfield',
    },
  }),
  { name: 'ThresholdTextInput' }
);

const ThresholdTextInput = (props) => {
  const {
    label,
    resource,
    minLengthThreshold,
    validationRegExp,
    validationErrorMessage = 'dxMessages.error_messages.invalid',
    margin,
    resettable,
  } = props;

  const isValidInput = (value: string, validationRegExp: string): boolean => {
    if (!validationRegExp) {
      return true;
    }
    const reg = new RegExp(validationRegExp);
    return reg.test(value);
  };

  const translate = useTranslate();
  const classes = useStyles(props);
  const {
    input,
    meta: { error },
  } = useInput(props);

  const touched = true;

  const { clearButton, clearIcon, visibleClearIcon, textFieldInput } = classes;
  const { value } = input;
  const [renderedValue, setRenderedValue] = useState<string | undefined>(
    undefined
  );
  const [invalidChar, setInvalidInput] = useState('');

  useEffect(() => {
    setRenderedValue(value);
  }, [value]);

  const handleChange = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const newValue = evt.target.value || undefined;
    if (newValue && !isValidInput(newValue, validationRegExp)) {
      setInvalidInput(translate(validationErrorMessage));
      input.onChange('');
    } else {
      setInvalidInput('');
      if (newValue && newValue.length >= minLengthThreshold) {
        input.onChange(newValue);
      } else {
        input.onChange('');
      }
    }
    setRenderedValue(newValue);
  };

  const handleMouseDownClearButton = (evt) => {
    evt.preventDefault();
  };

  const handleClear = (evt) => {
    evt.preventDefault();
    input.onChange('');
    setInvalidInput('');
    setRenderedValue('');
  };

  const onBlur = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const onFocus = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const getEndAdornment = () => {
    if (renderedValue) {
      return (
        <InputAdornment position='end'>
          <IconButton
            onClick={handleClear}
            onMouseDown={handleMouseDownClearButton}
            className={clearButton}
            aria-label={translate('ra.action.clear_input_value')}
            title={translate('ra.action.clear_input_value')}
            disableRipple
          >
            <ClearIcon
              className={classNames(clearIcon, {
                [visibleClearIcon]: renderedValue,
              })}
            />
          </IconButton>
        </InputAdornment>
      );
    } else {
      // show spacer
      return (
        <InputAdornment position='end'>
          <span className={clearButton}>&nbsp;</span>
        </InputAdornment>
      );
    }
  };

  const showError = error || invalidChar;
  return (
    <TextField
      margin={margin}
      InputProps={{
        endAdornment: resettable && getEndAdornment(),
      }}
      inputProps={{ className: textFieldInput }}
      value={renderedValue ? renderedValue : ''}
      resource={resource}
      error={!!(touched && showError)}
      helperText={touched && showError}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={handleChange}
      label={translate(label)}
      placeholder={translate('dxMessages.inputs.min_input_length', {
        minLength: minLengthThreshold,
      })}
    />
  );
};

ThresholdTextInput.propTypes = {
  resource: PropTypes.string,
  source: PropTypes.string,
  label: PropTypes.string,
};

ThresholdTextInput.defaultProps = {
  minLengthThreshold: 24,
  margin: 'normal',
};

export default ThresholdTextInput;
