// A task card displaying a summary of an invoice.
// It expects a UBL invoice.
//
// Use in the task list for example.
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { get } from 'lodash';
import moment from 'moment';
import React, { FC } from 'react';
import { fetchUtils, useNotify, useTranslate } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    invoiceId: {
      fontWeight: 'bolder',
      fontSize: 'larger',
      wordBreak: 'break-all',
      display: 'flex',
      alignItems: 'center',
    },
    correctionChain: {
      color: 'gray',
      marginLeft: '1em',
    },
  }),
  { name: 'GoToOtherApplicationSummary' }
);

const GoToOtherApplicationSummary: FC<GoToOtherApplicationSummaryFieldProps> =
  ({ invoice, icon, app }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();

    if (!invoice) return null;

    const recipient = get(
      invoice,
      'AccountingCustomerParty.Party.PartyName',
      []
    )
      .map((pn: any) => pn?.Name?.value)
      .find((name: any) => !!name);
    const issuer = get(invoice, 'AccountingSupplierParty.Party.PartyName', [])
      .map((pn: any) => pn?.Name?.value)
      .find((name: any) => !!name);

    const switchTo = (url: string) => {
      fetchUtils
        .fetchJson(`/api/auth/switchAppToken`)
        .then((resp: any) =>
          window.location.assign(`${url}?token=${resp.body}`)
        )
        .catch(() => notify('common.errorNotice', 'warning'));
    };

    return (
      <Grid container direction='column'>
        <Grid item style={{ marginBottom: '5px' }}>
          <Typography className={classes.invoiceId}>
            {icon && icon}&nbsp;<span>№ {invoice.ID.value.trim()}</span>
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={3}>
              <Typography variant='caption'>
                {translate('dxMessages.headers.issueDate')}
              </Typography>
              <Typography>
                {invoice?.IssueDate.value &&
                  moment(invoice.IssueDate.value).format('ll')}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='caption'>
                {translate('dxMessages.headers.recipientName')}
              </Typography>
              <Typography>{recipient ? recipient : '-'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='caption'>
                {translate('dxMessages.headers.issuerName')}
              </Typography>
              <Typography>{issuer ? issuer : '-'}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {app && (
          <Grid item>
            <Button
              onClick={() => switchTo('/' + app)}
              variant='outlined'
              size='small'
              endIcon={<ExitToAppIcon />}
            >
              {translate('dxMessages.apmValidation.open_cockpit')}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

type UblInvoice = any;
interface GoToOtherApplicationSummaryFieldProps {
  invoice: UblInvoice;
  icon?: React.ReactNode;
  app?: string;
}

export default GoToOtherApplicationSummary;
