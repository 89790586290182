/**
 * User roles based on src/modules/backend/docxchange/src/main/java/com/docprocess/dxportal/main/security/model/UserRoles.java
 */
export const UserRoles = {
  DXARCHIVE_ACCESS: 'DXARCHIVE_ACCESS',
  DXPURCHASE_ACCESS: 'DXPURCHASE_ACCESS',
  DXCATALOG_ACCESS: 'DXCATALOG_ACCESS',
  DXUPLOAD_ACCESS: 'DXUPLOAD_ACCESS',
  DXCONTRACT_ACCESS: 'DXCONTRACT_ACCESS',
  ADMINISTRATION: 'ADMINISTRATION',
  CREATE_DESPATCH_ADVICE: 'CREATE_DESPATCH_ADVICE',
  CREATE_INVOICE: 'CREATE_INVOICE',
  CREATE_WAYBILL: 'CREATE_WAYBILL',
  CREATE_RECEIPT_ADVICE: 'CREATE_RECEIPT_ADVICE',
  CREATE_ORDER: 'CREATE_ORDER',
  VIEW_WAYBILL: 'VIEW_WAYBILL',
  VIEW_DESPATCH_ADVICE: 'VIEW_DESPATCH_ADVICE',
  VIEW_INVOICE: 'VIEW_INVOICE',
  VIEW_ORDER: 'VIEW_ORDER',
  VIEW_RECEIPT_ADVICE: 'VIEW_RECEIPT_ADVICE',
  VIEW_CONTRACT: 'VIEW_CONTRACT',
  VIEW_FORECAST: 'VIEW_DELIVERY_FORECAST',
  VIEW_CATALOG: 'VIEW_CATALOG',
  DXARCHIVE_PRODUCT: 'DXARCHIVE',
  DXPURCHASE_PRODUCT: 'DXPURCHASE',
  EFACTURA_PRODUCT: 'E-FACTURA',
  ETRANSPORT_PRODUCT: 'E-TRANSPORT',
  DXCATALOG_PRODUCT: 'DXCATALOG',
  DXUPLOAD_PRODUCT: 'DXUPLOAD',
  DXCONTRACT_PRODUCT: 'DXCONTRACT',
  PEPPOL_PRODUCT: 'PEPPOL',
  RECONCILIATION_COCKPIT_PRODUCT: 'RECONCILIATION-COCKPIT',
  EXPENSE_CLASSIFICATION_COCKPIT_PRODUCT: 'ALLOCATION-COCKPIT',
  RESET_PASSWORD: 'RESET_PASSWORD',
  VIEW_TASKS: 'VIEW_TASKS',
  ATTACH_WAYBILL: 'ATTACH_WAYBILL',
  ATTACH_INVOICE: 'ATTACH_INVOICE',
  ATTACH_RECEIPT_ADVICE: 'ATTACH_RECEIPT_ADVICE',
  ATTACH_DESPATCH_ADVICE: 'ATTACH_DESPATCH_ADVICE',
  ATTACH_ORDER: 'ATTACH_ORDER',
  BETA_TESTER: 'BETA_TESTER',
  PSP_ADMINISTRATOR: 'PSP administrator',
  COMPANY_ADMINISTRATOR: 'company administrator',
  CUSTOMER_ADMINISTRATOR: 'CUSTOMER_ADMINISTRATOR',
  DXADMIN: 'DXADMIN',
  EFACTURA_ACCESS: 'EFACTURA_ACCESS',
  ETRANSPORT_ACCESS: 'ETRANSPORT_ACCESS',
  CATALOG_VIEWER: 'CATALOG_VIEWER',
  CATALOG_WRITER: 'CATALOG_WRITER',
  PEPPOL_INVOICE_WRITER: 'PEPPOL_INVOICE_WRITER',
};
