import { Constants, Metadata, useTz } from '@dx-ui/dx-common';
import React from 'react';
import {
  FunctionField,
  Loading,
  SimpleShowLayout,
  useLocale,
  useQuery,
  useTranslate,
} from 'react-admin';
import {
  createColumnsAttachment,
  createColumnsCarrefourInvoiceTypeDetails,
  createColumnsContract,
  createColumnsDeliveryLocation,
  createColumnsDespatchAdvice,
  createColumnsExtendedInvoiceDetails,
  createColumnsFeedbackMessage,
  createColumnsOrder,
  createColumnsProcessDetails,
  createColumnsReceiptAdvice,
  createColumnsRegulatorDetails,
  createColumnsTotals,
  createColumnsTransportDetails,
  createCommonColumns,
  createSpecificColumnsInvoice,
  createSpecificColumnsOrder,
  createSpecificColumnsReceiptAdvice,
} from '../aspects';
import { createSpecificColumnsDesadv } from '../aspects/DespatchAdviceDetailsFields';
import { AperakContentField } from './AperakContentField';

/**
 * Gets the fields to display according to the types/aspects
 * @param {*} record current metadata to display
 */
const getFields = ({ record, groupingMode, ...props }) => {
  let fields: any[] = [...createCommonColumns()];
  switch (record.nodeType) {
    case Constants.NODE_TYPE_ORDERS:
      fields = [
        ...fields,
        ...createSpecificColumnsOrder(),
        ...createColumnsDeliveryLocation(),
        ...createColumnsTotals(),
        ...createColumnsProcessDetails(),
        ...createColumnsContract(),
      ];
      break;
    case Constants.NODE_TYPE_INVOIC:
      fields = [
        ...fields,
        ...createSpecificColumnsInvoice(),
        ...createColumnsDeliveryLocation(),
        ...createColumnsTotals(),
        ...createColumnsProcessDetails(),
        ...createColumnsOrder(),
        ...createColumnsContract(),
        ...createColumnsDespatchAdvice(),
        ...createColumnsCarrefourInvoiceTypeDetails(),
        ...createColumnsReceiptAdvice(),
        ...createColumnsRegulatorDetails(),
      ];
      break;
    case Constants.NODE_TYPE_DESADV:
      fields = [
        ...fields,
        ...createColumnsDeliveryLocation(),
        ...createColumnsTotals(),
        ...createColumnsProcessDetails(),
        ...createColumnsOrder(),
        ...createColumnsContract(),
        ...createSpecificColumnsDesadv(),
      ];
      break;
    case Constants.NODE_TYPE_RECADV:
      fields = [
        ...fields,
        ...createSpecificColumnsReceiptAdvice(),
        ...createColumnsDeliveryLocation(),
        ...createColumnsTotals(),
        ...createColumnsProcessDetails(),
        ...createColumnsDespatchAdvice(),
        ...createColumnsOrder(),
        ...createColumnsReceiptAdvice(),
        ...createColumnsExtendedInvoiceDetails(),
        ...createColumnsContract(),
      ];
      break;
    case Constants.NODE_TYPE_APERAK:
      fields = [...fields, ...createColumnsFeedbackMessage()];
      break;
    case Constants.NODE_TYPE_DELFOR:
      fields = [
        ...fields,
        ...createColumnsDeliveryLocation(),
        ...createColumnsTotals(),
        ...createColumnsProcessDetails(),
        ...createColumnsContract(),
        ...createColumnsOrder(),
      ];
      break;
    case Constants.NODE_TYPE_CNTCND:
      fields = [...fields, ...createColumnsOrder()];
      break;
    case Constants.NODE_TYPE_CATLOG:
      fields = [...createCommonColumns()]; // For tracability.
      break;
    case Constants.NODE_TYPE_ATTACH:
      fields = [...fields, ...createColumnsAttachment()];
      break;
    case Constants.NODE_TYPE_WAYBIL:
      fields = [
        ...fields,
        ...createColumnsExtendedInvoiceDetails(),
        ...createColumnsTransportDetails(),
        ...createColumnsProcessDetails(),
        ...createColumnsDespatchAdvice(),
        ...createColumnsOrder(),
      ];
      break;
    default:
      fields = [...createCommonColumns()];
  }

  if (
    record.properties &&
    record.properties[Metadata.documentTypeCode] ===
      Constants.DOCUMENT_TYPE_CODE_APERAK
  ) {
    const aperakTextField = {
      id: 'aperakCustomField',
      label: 'dxMessages.headers.messageDetails',
      renderCell: (props) => <AperakContentField {...props} />,
    };
    fields = [...fields, aperakTextField];
  }

  // Remove file name from the property list for DxPurchase
  // Remove unrelevant fields while groupingMode (i.e when all representations are aggregate (ex: documentFormatType))
  fields = fields
    .filter((col) => col.displayedOnlyInLists !== true) // remove columns which do have to be displayed when not in list view
    .filter(
      (col) =>
        col.displayInGroupingMode === undefined ||
        col.displayInGroupingMode === groupingMode
    );
  return fields;
};

interface ShowMetadataProps {
  className: any;
  resource: any;
  record: any;
  basePath: any;
  groupingMode: any;
}

/**
 * Render the fields in a SimpleShowLayout
 * As soon as the metadata of a file is read,
 * The backend needs to send a notification (sync V3 - V2).
 * We adopt the solution to send a GET containing the pertinent info
 * The message is always a /metadata one
 */
const ShowMetadata: React.FC<ShowMetadataProps> = (props) => {
  const {
    className,
    resource,
    record,
    basePath,
    groupingMode = true,
    ...rest
  }: any = props;

  const translate = useTranslate();
  const tz = useTz();
  const locale = useLocale();

  const { data, loading, error } = useQuery({
    type: 'apiShowMetadata',
    resource,
    payload: {
      record,
      basePath,
    },
  });

  if (loading) return <Loading />;

  if (error) {
    return (
      <p>
        {translate('dxMessages.showMetadata.error', {
          _: 'Unable to display metadata',
        })}
      </p>
    );
  }

  if (!data) return null;

  return (
    <SimpleShowLayout record={data} {...rest} className={className}>
      {getFields({ ...rest, record, groupingMode }).map((field, index) => {
        return (
          <FunctionField
            label={
              typeof field.label === 'function'
                ? field.label({ resource })
                : field.label
            }
            key={`${field.id}_${index}`}
            source={field.id}
            render={(record, index) => (
              <>
                {field.renderCell({
                  record,
                  translate,
                  resource,
                  className,
                  locale,
                  tz,
                  readOnly: true,
                  id: field.id,
                })}
              </>
            )}
          />
        );
      })}
    </SimpleShowLayout>
  );
};

export default ShowMetadata;
