export const sanitizeFieldComponentProps = ({
  formTemplate,
  formData,
  scopedFormData,
  readOnly,
  getSource,
  updateFormTemplate,
  selectValues,
  children,
  field,
  template,
  documentForm,
  ...rest
}: any) => rest;
