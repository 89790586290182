import { Constants } from '@dx-ui/dx-common';
import React from 'react';
import {
  createColumnsContract,
  createColumnsDeliveryLocation,
  createColumnsOrder,
  createColumnsProcessDetails,
  createColumnsTotals,
  createCommonColumns,
  createCommonFilters,
  createContractFilters,
  createDeliveryLocationFilters,
  createOrderFilters,
  createProcessDetailsFilters,
  createSpecificColumnsOrder,
  createSpecificOrderFilters,
  createTotalsFilters,
} from '../../../aspects';
import { OrderService } from '../../../services/OrderService';
import { CommonList } from '../../../shared';
import BulkDeleteActionButtons from '../../../shared/BulkDeleteActionButtons';
import ContextualToolbar from '../../../shared/ContextualToolbar';

/**
 * Declare the additional columns to display vs commons
 */
const columns: any[] = [
  ...createCommonColumns(),
  ...createColumnsDeliveryLocation(),
  ...createColumnsContract(),
  ...createColumnsTotals(),
  ...createColumnsProcessDetails(),
  ...createColumnsOrder(),
  ...createSpecificColumnsOrder(),
];

/**
 * Specific filters
 */
const additionalFilters: any[] = [
  ...createCommonFilters(),
  ...createDeliveryLocationFilters(),
  ...createContractFilters(),
  ...createTotalsFilters(),
  ...createProcessDetailsFilters(),
  ...createOrderFilters(),
  ...createSpecificOrderFilters(),
];

const WebOrderList = (props) => {
  const { permissions } = props;
  return (
    <CommonList
      title='dxMessages.orders.title'
      {...props}
      columns={columns}
      additionalFilters={additionalFilters}
      contextualToolbar={
        <ContextualToolbar
          resource={Constants.RESOURCE_ORDER}
          allowEdit={OrderService.canCreate(permissions)}
        />
      }
      bulkActions={<BulkDeleteActionButtons />}
    />
  );
};

export default WebOrderList;
