// The list of user tasks.
import { Card, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import compose from 'lodash/flowRight';
import moment from 'moment';
import { FC } from 'react';
import {
  Datagrid,
  DateInput,
  Filter,
  FunctionField,
  List,
  SelectInput,
  TextInput,
  translate,
} from 'react-admin';
import MomentField from '../../shared/MomentField';
import { FieldProps, Task } from '../../shared/types';
import ActionCard from './action/ActionCard';
import ActionForm from './action/ActionForm';

const styles: any = () => ({
  // No Paper shadow around the List form.
  root: {
    '& > div ': {
      boxShadow: 'unset',
    },
  },
});

const TaskFilters = (props) => {
  return (
    <Filter {...props}>
      <TextInput
        label='dxMessages.task.documentId'
        source='documentId'
        alwaysOn
      />
      <SelectInput
        source='documentType'
        label='dxMessages.task.documentType'
        allowEmpty
        alwaysOn
        choices={[
          { id: 'INVOICE', name: 'dxMessages.task.anyInvoice' },
          {
            id: 'COMMERCIAL_INVOICE',
            name: 'dxMessages.task.commercialInvoice',
          },
          { id: 'CANCEL_INVOICE', name: 'dxMessages.task.cancelInvoice' },
          {
            id: 'CORRECTIVE_INVOICE',
            name: 'dxMessages.task.correctiveInvoice',
          },
          { id: 'SERVICE_INVOICE', name: 'dxMessages.task.serviceInvoice' },
          { id: 'ORDERS', name: 'dxMessages.task.order' },
          {
            id: 'ORDER',
            name: 'dxMessages.task.order_negotiation',
          },
        ]}
      />
      <DateInput
        label='dxMessages.task.start_before'
        source='startBeforeTime'
        allowEmpty
        parse={(v: string) => (!!v ? Date.parse(v) : v)}
        format={(v: number) =>
          !!v ? moment(new Date(v)).format('YYYY-MM-DD') : v
        }
        alwaysOn
      />
      <DateInput
        label='dxMessages.task.start_after'
        source='startAfterTime'
        allowEmpty
        parse={(v: string) => (!!v ? Date.parse(v) : v)}
        format={(v: number) =>
          !!v ? moment(new Date(v)).format('YYYY-MM-DD') : v
        }
        alwaysOn
      />
      <SelectInput
        source='duePeriod'
        label='dxMessages.task.due'
        allowEmpty
        alwaysOn
        choices={[
          { id: 'NOW', name: 'dxMessages.task.now' },
          { id: 'IN_ONE_DAY', name: 'dxMessages.task.in_one_day' },
          { id: 'IN_THREE_DAYS', name: 'dxMessages.task.in_three_days' },
          { id: 'IN_ONE_WEEK', name: 'dxMessages.task.in_one_week' },
          { id: 'IN_TWO_WEEKS', name: 'dxMessages.task.in_two_weeks' },
          { id: 'IN_ONE_MONTH', name: 'dxMessages.task.in_one_month' },
          { id: 'IN_THREE_MONTHS', name: 'dxMessages.task.in_three_months' },
          { id: 'IN_SIX_MONTHS', name: 'dxMessages.task.in_six_months' },
          { id: 'IN_ONE_YEAR', name: 'dxMessages.task.in_one_year' },
        ]}
      />
      <TextInput label='dxMessages.task.orderId' source='orderId' />
    </Filter>
  );
};

const Action: FC<FieldProps<Task<any>>> = (props: any) => {
  return (
    <Card
      elevation={0}
      style={{
        background: 'none',
        minWidth: '25em',
      }}
    >
      <CardContent>
        <ActionForm {...props} />
      </CardContent>
    </Card>
  );
};

const TaskList: any = ({ permissions, translate, ...props }) => {
  const { classes } = props;
  return (
    <List
      filters={<TaskFilters />}
      exporter={false}
      bulkActionButtons={false}
      classes={{ root: classes.root }}
      sort={{ field: 'startTime', order: 'DESC' }}
      {...props}
    >
      <Datagrid>
        <ActionCard
          label='dxMessages.task.description'
          translate={translate}
          dataProvider={props.dataProvider}
        />
        <FunctionField
          label='dxMessages.task.start_since'
          sortBy='startTime'
          render={(record: any) => (
            <Card elevation={0} style={{ background: 'none' }}>
              <CardContent>
                <MomentField
                  record={record}
                  source='startTime'
                  translate={translate}
                  dataProvider={props.dataProvider}
                />
              </CardContent>
            </Card>
          )}
        />
        <FunctionField
          label='dxMessages.task.due_in'
          sortBy='dueTime'
          render={(record: any) => (
            <Card elevation={0} style={{ background: 'none' }}>
              <CardContent>
                <MomentField
                  record={record}
                  source='dueTime'
                  translate={translate}
                  dataProvider={props.dataProvider}
                  errorWhenInPast={true}
                />
              </CardContent>
            </Card>
          )}
        />
        <Action translate={translate} dataProvider={props.dataProvider} />
      </Datagrid>
    </List>
  );
};

const enhance = compose(translate, withStyles(styles, { withTheme: true }));
export default enhance(TaskList);
