import { MenuIcon } from '@dx-ui/dx-common/src/layout/SideBarMenu';
import { Box, Typography } from '@material-ui/core';
import { CreateButton, useListContext, useTranslate } from 'react-admin';

export const Empty = () => {
  const { resource } = useListContext();
  const translate = useTranslate();
  const resourceName = translate(`resources.${resource}.name`);
  const emptyMessage = translate('ra.page.empty', { name: resourceName });
  return (
    <Box
      textAlign='center'
      m={1}
      style={{
        textAlign: 'center',
        margin: '2em 1em',
        opacity: 0.5,
      }}
    >
      <MenuIcon
        style={{ width: '9em', height: '9em' }}
        resourceName={resource}
      />
      <Typography variant='h4' paragraph>
        {emptyMessage}
      </Typography>
      <CreateButton />
    </Box>
  );
};
