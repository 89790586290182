import {
  GA_EVENTS,
  isPspAdministrator,
  sendGAEvent,
  useGAPageViews,
} from '@dx-ui/dx-common/src';
import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  FilterProps,
  List,
  SelectField,
  TextField,
  TextInput,
  useGetIdentity,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { Empty } from '../../../common/Empty';

// interface LastLoginTimeResponse extends RaRecord {
//   // ISO-8601 representation.
//   lastLoginTime: string;
// }

const DropdownDefFilters = (props: Omit<FilterProps, 'children'>) => {
  const { permissions, loaded } = usePermissions();
  if (!loaded || !permissions) return null;

  return (
    <Filter {...props}>
      <TextInput
        label={'dxMessages.companyManagement.dropdowndef.description'}
        source='description'
        alwaysOn
      />
    </Filter>
  );
};

const DropdownDefList = (props: any) => {
  const notify = useNotify();
  const translate = useTranslate();
  useGAPageViews();
  const { permissions, loaded } = usePermissions();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  if (!loaded || !permissions) return null;

  const idType = [
    { id: 1, name: 'Invoice type' },
    { id: 2, name: 'Payment means code' },
    { id: 3, name: 'Payment start event' },
    { id: 4, name: 'Payment reference event' },
    { id: 5, name: 'Payment type period' },
    { id: 6, name: 'Invoice type code' },
    { id: 7, name: 'Package type' },
    { id: 8, name: 'Tax' },
    { id: 9, name: 'Payment terms qualifier' },
    { id: 10, name: 'Product group' },
    { id: 11, name: 'Country' },
  ];

  return (
    <List
      {...props}
      filters={<DropdownDefFilters />}
      empty={isPspAdministrator(permissions) ? <Empty /> : false}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        <TextField
          label={'dxMessages.companyManagement.dropdowndef.description'}
          source='description'
        />
        <SelectField
          label={'dxMessages.companyManagement.dropdowndef.idtype'}
          source='idType'
          choices={idType}
        />
        <TextField
          label={'dxMessages.companyManagement.dropdowndef.value'}
          source='value'
          sortable={false}
        />
        <EditButton />
        <DeleteWithConfirmButton
          onClick={() =>
            sendGAEvent(
              GA_EVENTS.categories.DROPDOWN_DEF_MGMT.name,
              GA_EVENTS.categories.DROPDOWN_DEF_MGMT.actions.DELETE_DROPDOWNDEF,
              account?.company?.cmsRootDir
            )
          }
          onFailure={(error) => {
            if (error.status === 400) {
              notify(
                'dxMessages.companyManagement.dropdowndef.delete_dropdown_error',
                'error',
                {
                  _: 'Unable to delete this dropdown definition element',
                  error: translate(
                    'dxMessages.myAccount.delete_customer_admin_error'
                  ),
                }
              );
            } else {
              notify(
                'dxMessages.companyManagement.dropdowndef.delete_dropdown_error',
                'error',
                {
                  _: 'Unable to delete this dropdown definition element',
                  error: `${error}`,
                }
              );
            }
          }}
        />
      </Datagrid>
    </List>
  );
};

export default DropdownDefList;
