import { AutocompleteTextInput } from '@dx-ui/dx-common';
import { get } from 'lodash';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a SelectInput
 */
const AutocompleteTextInputField = (props: FieldComponentProps) => {
  const { field, selectValues } = props;

  const options = field.options;

  let valueField: string = options?.optionValue || 'id';
  let nameField: string = options?.optionText ?? valueField;

  // select items
  const choices = field.source ? get(selectValues, field.source) || [] : [];
  const sanitizeProps: any = ({
    loading,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <AutocompleteTextInput
          {...sanitizeProps(fieldProps)}
          choices={choices}
          optionText={nameField}
          optionValue={valueField}
          optionTooltip={options?.optionTooltip}
          optionAddRenderText={options?.optionAddRenderText}
          optionType={options?.optionType}
          titleSuffix={field?.businessTerms}
        />
      )}
    </FieldWrapper>
  );
};

export default AutocompleteTextInputField;
