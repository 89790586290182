import { Constants, ProcessStatus, redirect } from '@dx-ui/dx-common';
import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import { linkToRecord, Translate } from 'ra-core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  DataProvider,
  hideNotification,
  MenuItemLink,
  showNotification,
  translate,
  withDataProvider,
} from 'react-admin';
import { connect } from 'react-redux';
import {
  AlfrescoDocumentService,
  CopyOperation,
  DataHelpers,
  DocumentServiceFactory,
  WaybillService,
  WaybillServiceCreator,
} from '../../services';
import ToolbarButton from '../../shared/ToolbarButton';
import {
  AlfrescoContent,
  HideNotification,
  Redirect,
  ShowNotification,
} from '../../shared/types';

interface WaybillActionProps {
  record: AlfrescoContent;
  translate: Translate;
  dataProvider: DataProvider;
  isMenuItem: boolean;
  permissions: any;
  redirect: Redirect;
  showNotification: ShowNotification;
  hideNotification: HideNotification;
}

const WayBillActions = (props: WaybillActionProps) => {
  const {
    record,
    translate,
    dataProvider,
    isMenuItem,
    permissions,
    redirect,
    showNotification,
    hideNotification,
  } = props;

  const processStatus: ProcessStatus = DataHelpers.getProcessStatus(record);
  const isWebWaybill = AlfrescoDocumentService.isWebDocument(record);

  const [copyPermissions, setCopyPermissions] = useState<{
    canClone: boolean;
  }>({
    canClone: false,
  });

  const [loading, setLoading] = useState<boolean>(false);

  const documentService = useMemo(
    () => new AlfrescoDocumentService(dataProvider),
    [dataProvider]
  );

  const waybillService = useMemo(
    () =>
      DocumentServiceFactory.create(
        WaybillServiceCreator,
        dataProvider
      ) as WaybillService,
    [dataProvider]
  );

  useEffect(() => {
    const checkForPermissions = async () => {
      let canClone = false;

      setLoading(true);
      try {
        const canCreate = WaybillService.canCreate(permissions);

        if (canCreate) {
          const recipientId = DataHelpers.getRecipientId(record);
          const hasTemplateRights = await waybillService.hasWaybillTemplate(
            recipientId
          );

          const clonePermissions = await documentService.getClonePermissions();

          const cloneKey = isWebWaybill
            ? CopyOperation.CAN_CLONE_WEB_WAYBILL
            : CopyOperation.CAN_CLONE_CLASSIC_WAYBILL;
          canClone =
            isWebWaybill &&
            hasTemplateRights &&
            !!clonePermissions[cloneKey]?.includes(processStatus);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      setCopyPermissions({
        canClone,
      });

      setLoading(false);
    };

    checkForPermissions();
  }, [
    dataProvider,
    processStatus,
    isWebWaybill,
    record,
    permissions,
    waybillService,
    documentService,
  ]);

  enum WaybillActionType {
    CLONE,
  }

  const onClickDoAction = (actionType: WaybillActionType) => {
    return async (e) => {
      e.preventDefault();

      if (!record.id) {
        throw new Error('record id cannot be null');
      }

      setLoading(true);
      showNotification('ra.page.loading');

      try {
        let result: AlfrescoContent;
        switch (actionType) {
          case WaybillActionType.CLONE:
            result = await waybillService.clone(record.id);
            break;
          default:
            throw new Error(`Invalid value for actionType: ${actionType}`);
        }
        hideNotification();
        setLoading(false);
        redirect(`/${linkToRecord(Constants.RESOURCE_WEBWAYBILL, result.id)}`);
      } catch (e) {
        hideNotification();
        setLoading(false);
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };
  };

  if (isMenuItem) {
    const sanitizeLinkProps = ({
      isMenuItem,
      translate,
      record,
      dataProvider,
      dispatch,
      showNotification,
      hideNotification,
      redirect,
      ...rest
    }: any) => rest;
    return (
      <>
        {copyPermissions.canClone && (
          <MenuItemLink
            disabled={loading}
            to={``}
            primaryText={translate('dxMessages.buttons.cloneWayBill')}
            onClick={onClickDoAction(WaybillActionType.CLONE)}
            {...sanitizeLinkProps(props)}
          />
        )}
      </>
    );
  } else {
    const sanitizeBtnProps = ({
      dispatch,
      dataProvider,
      isMenuItem,
      classes,
      className,
      redirect,
      showNotification,
      hideNotification,
      ...rest
    }: any) => rest;
    return (
      <>
        {copyPermissions.canClone && (
          <ToolbarButton
            disabled={loading}
            label={'dxMessages.buttons.cloneWayBill'}
            {...sanitizeBtnProps(props)}
            onClick={onClickDoAction(WaybillActionType.CLONE)}
          />
        )}
      </>
    );
  }
};

WayBillActions.propTypes = {
  record: PropTypes.any.isRequired,
  basePath: PropTypes.string.isRequired,
  permissions: PropTypes.any,
};

WayBillActions.defaultProps = {
  isMenuItem: true,
};

const enhance = compose(
  withDataProvider,
  translate,
  connect(undefined, {
    showNotification,
    hideNotification,
    redirect,
  })
);

export default enhance(WayBillActions);
