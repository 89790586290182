import {
  Box,
  Divider,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { AppBarProps as MuiAppBarProps } from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Children, cloneElement, memo } from 'react';
import {
  ClassesOverride,
  UserMenu as DefaultUserMenu,
  HideOnScroll,
  LoadingIndicator,
} from 'react-admin';
import AnnouncementsBar from '../announcements/AnnouncementsBar';
import { HomeButton } from '../components';
import NotificationsMenu from '../components/notifications/NotificationsMenu';
import { DxTheme } from '../types';
import DocProcessLogo from './DocProcessLogo';
import DxAppsMenu from './DxAppsMenu';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    toolbar: {
      paddingRight: 24,
    },
    dense: {
      overflowX: 'auto',
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontWeight: 'bolder',
    },
    logo: {
      marginLeft: '1em',
    },
  }),
  { name: 'RaAppBar' }
);

/**
 * The AppBar component renders a custom MuiAppBar.
 *
 * @param {Object} props
 * @param {ReactNode} props.children React node/s to be render as children of the AppBar
 * @param {Object} props.classes CSS class names
 * @param {string} props.className CSS class applied to the MuiAppBar component
 * @param {string} props.color The color of the AppBar
 * @param {Component} props.logout The logout button component that will be pass to the UserMenu component
 * @param {boolean} props.open State of the <Admin/> Sidebar
 * @param {Element | boolean} props.userMenu A custom user menu component for the AppBar. <UserMenu/> component by default. Pass false to disable.
 *
 * @example
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props}>
 *           <Typography
 *               variant="h6"
 *               color="inherit"
 *               className={classes.title}
 *               id="react-admin-title"
 *           />
 *       </AppBar>
 *   );
 *};
 *
 * @example Without a user menu
 *
 * const MyAppBar = props => {
 *   const classes = useStyles();
 *   return (
 *       <AppBar {...props} userMenu={false} />
 *   );
 *};
 */
const AppBar = (props: AppBarProps): JSX.Element => {
  const {
    children,
    classes: classesOverride,
    className,
    color = 'secondary',
    logout,
    open,
    title,
    userMenu,
    noLayout,
    ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <HideOnScroll>
      <MuiAppBar className={className} color={color} {...rest}>
        <AnnouncementsBar />
        <Toolbar
          disableGutters
          variant={'dense'}
          classes={{
            root: classes.toolbar,
            dense: classes.dense,
          }}
        >
          {/* Take all the space to push the menus on the right side. */}
          <Box display='flex' flexGrow={1} style={{ alignItems: 'center' }}>
            <Box mr={2}>
              <DxAppsMenu title={title} />
            </Box>
            <Box>
              <DocProcessLogo />
            </Box>
            {Children.count(children) === 0 ? (
              <Box
                display='flex'
                flexDirection='column'
                ml={1}
                style={{ marginTop: '3px' }}
              >
                <Typography variant='caption'>{title}</Typography>
                <Box display='flex' mt={-1} whiteSpace='nowrap'>
                  <Typography
                    variant='h6'
                    id='react-admin-title'
                    className={classes.title}
                  />
                </Box>
              </Box>
            ) : (
              children
            )}
          </Box>
          <NotificationsMenu />
          <LoadingIndicator {...rest} />
          {typeof userMenu === 'boolean'
            ? userMenu === true
              ? cloneElement(<DefaultUserMenu />, { logout })
              : null
            : userMenu
            ? cloneElement(userMenu, { logout })
            : null}
          <Divider orientation='vertical' light flexItem />
          <HomeButton />
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
};

AppBar.propTypes = {
  children: PropTypes.node,
  // @ts-ignore
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'default',
    'inherit',
    'primary',
    'secondary',
    'transparent',
  ]),
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

AppBar.defaultProps = {
  userMenu: <DefaultUserMenu />,
};

export interface AppBarProps extends Omit<MuiAppBarProps, 'title' | 'classes'> {
  classes?: ClassesOverride<typeof useStyles>;
  logout?: JSX.Element;
  open?: boolean;
  title?: string | JSX.Element;
  userMenu?: JSX.Element | boolean;
  noLayout?: boolean;
}

export default memo(AppBar);
