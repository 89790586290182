import { ListItemIcon, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import { useState } from 'react';
import { fetchUtils, useNotify, useTranslate } from 'react-admin';
import { Constants, REST_ENDPOINTS } from '../constants';
import { saveUsageRightsData } from './SsoAuthProvider';

const AccountSwitcher = ({ identity, ...props }) => {
  const classes = useStyles(props);
  const translate = useTranslate();
  const notify = useNotify();
  const [login, setLogin] = useState('');
  const [error, setError] = useState('');

  const impersonateAccount = (login: string) => {
    // Calling 'impersonateAccount' updates the SSO cookie with the new account
    fetchUtils
      .fetchJson(
        `${window.location.origin}${REST_ENDPOINTS.ROOT}/${REST_ENDPOINTS.IMPERSONATE_ACCOUNT}/${login}`,
        {
          credentials: 'include',
        }
      )
      .then(({ json: account }) => {
        // save the PSP user locale storage value
        const pspUserName = localStorage.getItem('user');
        if (pspUserName) {
          localStorage.setItem('psp_user', pspUserName);
        }

        // re-set the local storage account info.
        localStorage.setItem(
          Constants.DX_USER_ACCOUNT,
          JSON.stringify(account)
        );

        const userName =
          account.version === 3 ? account.person.email : account.id;
        localStorage.setItem('user', userName);
      })
      .then(() => {
        // Calling usage-rigths
        // Then deals with permissions
        return fetchUtils.fetchJson(`${window.location.origin}/usage-rights`, {
          credentials: 'include',
        });
      })
      .then(({ json: usageRigths }) => {
        const userName = localStorage.getItem('user');

        saveUsageRightsData(userName, usageRigths);

        const pspUserName = localStorage.getItem('psp_user');
        if (pspUserName) {
          localStorage.removeItem(`${pspUserName}.permissions`);
        }
        // Reload the app but reset the URL hash first as it may point to a page
        // (like the account management page) not accessible with the new account.
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, '', ' ');
        window.location.reload();
      })
      .catch((error) => {
        if (error.status === 404) {
          setError(translate('dxMessages.user_menu.AccountNotFound'));
        } else {
          setError('');
          notify(
            'dxMessages.error_messages.OnError_contact_the_support',
            'error',
            {
              error: error.message,
            }
          );
        }
      })
      .finally(() => {
        // Remove the local saving of the PSP user name
        const pspUserName = localStorage.getItem('psp_user');
        if (pspUserName) {
          localStorage.removeItem('psp_user');
        }
      });
  };

  return (
    <div>
      <MenuItem>
        <ListItemIcon className={classes.icon}>
          <TransferWithinAStationIcon />
        </ListItemIcon>
        <TextField
          id='login'
          label={translate('dxMessages.user_menu.AccountToImpersonate')}
          value={login}
          onChange={(e) => setLogin(e.target.value.trim())}
          // Stop the propagation here to prevent a key from escaping the
          // text box and triggering a menu shortcut.
          onKeyDown={(e) => e.stopPropagation()}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter' && login.length > 0) {
              impersonateAccount(login);
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          size='small'
          placeholder={translate(
            'dxMessages.user_menu.AccountToImpersonateHelper'
          )}
          style={{ width: '16em' }}
          error={!!error}
          helperText={error}
        />
      </MenuItem>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: { minWidth: theme.spacing(5) },
}));

export default AccountSwitcher;
