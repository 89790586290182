import { isPspAdministrator, useGAPageViews } from '@dx-ui/dx-common/src';
import {
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  FilterProps,
  FunctionField,
  List,
  TextField,
  TextInput,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';

// interface LastLoginTimeResponse extends RaRecord {
//   // ISO-8601 representation.
//   lastLoginTime: string;
// }

const DxRoleFilters = (props: Omit<FilterProps, 'children'>) => {
  const { permissions, loaded } = usePermissions();
  if (!loaded || !permissions) return null;

  return (
    <Filter {...props}>
      <TextInput
        label={'dxMessages.companyManagement.dxrole.name'}
        source='rolle'
        alwaysOn
      />
    </Filter>
  );
};

const DxRoleList = (props: any) => {
  useGAPageViews();
  const { permissions, loaded } = usePermissions();
  const notify = useNotify();
  const translate = useTranslate();

  if (!loaded || !permissions) return null;

  return (
    <List
      {...props}
      filters={<DxRoleFilters {...props} />}
      bulkActionButtons={false}
      empty={isPspAdministrator(permissions) ? <CreateButton /> : null}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        <TextField
          label={'dxMessages.companyManagement.dxrole.name'}
          source='rolle'
        />
        <FunctionField
          label={'dxMessages.companyManagement.dxrole.adminOnly'}
          render={(record: any) => {
            return record.adminOnly === true ? 'TRUE' : 'FALSE';
          }}
        />
        <EditButton />
        <DeleteWithConfirmButton
          onFailure={(error) => {
            if (error.status === 400) {
              notify(
                'dxMessages.companyManagement.dxrole.delete_dxrole_error',
                'error',
                {
                  _: 'Unable to delete this role',
                  error: translate(
                    'dxMessages.companyManagement.dxrole.delete_dxrole_error'
                  ),
                }
              );
            } else {
              notify(
                'dxMessages.companyManagement.dxrole.delete_dxrole_error',
                'error',
                {
                  _: 'Unable to delete this role',
                  error: `${error}`,
                }
              );
            }
          }}
        />
      </Datagrid>
    </List>
  );
};

export default DxRoleList;
