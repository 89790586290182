import { useGAPageViews } from '@dx-ui/dx-common';
import { ShowController } from 'react-admin';
import ForecastShowView from './ForecastShowView';

const ForecastShow = (props) => {
  const sanitizeProps = ({ id, resource, record, ...rest }) => rest;
  useGAPageViews();

  return (
    <ShowController {...props}>
      {(controllerProps) => {
        return (
          <ForecastShowView
            controllerProps={controllerProps}
            title='dxMessages.forecast.title'
            {...sanitizeProps(props)}
          />
        );
      }}
    </ShowController>
  );
};

export default ForecastShow;
