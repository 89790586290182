import {
  colors,
  CommonListFilter,
  Constants,
  DxTheme,
  ExportButton,
  exporter,
  FloatingPagination,
  GA_EVENTS,
  getDxApplication,
  ListColumnSelector,
  Metadata,
  ResponsiveGrid,
  sendGAEvent,
  useGAPageViews,
} from '@dx-ui/dx-common';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Inbox from '@material-ui/icons/Inbox';
import { usePreferences } from '@react-admin/ra-preferences';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  List,
  ReduxState,
  TopToolbar,
  useGetIdentity,
  useListContext,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { DespatchAdviceService } from '../services/DespatchAdviceService';
import { InvoiceService } from '../services/InvoiceService';
import { OrderService } from '../services/OrderService';
import { ReceiptAdviceService } from '../services/ReceiptAdviceService';
import { WaybillService } from '../services/WaybillService';
import CreateButton from '../shared/CreateButton';
import BulkDownloadButton from './BulkDownloadButton';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      '& > div ': {
        boxShadow: 'unset',
        display: 'flex',
        alignItems: 'end', // Export and Columns buttons
      },
      '& div div[data-test=bulk-actions-toolbar]': {
        backgroundColor: theme.palette.primary.main,
        color: colors.white,
        borderRadius: 4,
      },
      '& div div[data-test=bulk-actions-toolbar] > div:first-child h3': {
        // Bulk-actions-toolbar title (i.e Nbr Selected Items) position
        position: 'absolute',
        left: theme.spacing(5),
        top: theme.spacing(2.5),
      },
      '& > div:first-child > div:first-child': {
        alignItems: 'flex-end', // align Columns button at the bottom
      },
    },
    content: {
      boxShadow: 'unset',
    },
    headerCell_withStickyCols: {
      '&.stickyCol': {
        background: theme.app.tableBkgColor,
        position: 'sticky',
        left: 0,
        top: 0,
        zIndex: 20,
      },
      '&.stickyCol:first-child': {
        zIndex: 30,
      },
    },
    row_withStickyCols: {
      '& td:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 20,
        backgroundColor: theme.app.tableBkgColor,
      },
      '& td:first-child.stickyCol': {
        position: 'sticky',
        left: 0,
        zIndex: 20,
        backgroundColor: theme.app.tableBkgColor,
      },
      '& td:nth-child(2)': {
        position: 'sticky',
        left: 52,
        zIndex: 20,
        backgroundColor: theme.app.tableBkgColor,
      },
    },
    headerRow_withStickyCols: {
      '& th:first-child': {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 30,
        backgroundColor: theme.app.tableBkgColor,
      },
      '& th:nth-child(2)': {
        left: 52,
        zIndex: 30,
      },
    },
    tableScroll: (props: { sidebarOpen }) => {
      return {
        // wrapper div css class
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.standard,
        }),
        position: 'relative',
        width: props.sidebarOpen ? 'calc(100vw - 255px)' : 'calc(100vw - 70px)',
        zIndex: 1,
        overflow: 'auto',
        height: '71vh',
        '& table': {
          borderCollapse: 'separate',
        },
        borderBottomStyle: 'ridge',
      };
    },
    emptyMessageStyle: {
      display: 'block !important', //  Preserve this style against Export and Columns buttons one
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color:
        theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
    },
    emptyListIcon: {
      width: '9em',
      height: '9em',
    },
    toolbar: {
      bottom: 11,
    },
  }),
  { name: 'CommonList' }
);

/**
 * Default BulkActionsButtons definition
 * @param {*} props
 */
const BulkActionButtons = (props) => (
  <Fragment>
    <BulkDownloadButton {...props} />
  </Fragment>
);

const Empty = () => {
  const { resource } = useListContext();
  const sidebarOpen = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );
  const { emptyMessageStyle, emptyListIcon } = useStyles({ sidebarOpen });
  const translate = useTranslate();
  const resourceName = translate(`resources.${resource}.name`);
  const emptyMessage = translate('ra.page.empty', { name: resourceName });
  return (
    <Box textAlign='center' m={1} className={emptyMessageStyle}>
      <Inbox className={emptyListIcon} />
      <Typography variant='h4' paragraph>
        {emptyMessage}
      </Typography>
      <CreateButtons />
    </Box>
  );
};

const CreateButtons = () => {
  const { permissions } = usePermissions();
  const { resource, basePath } = useListContext();

  return (
    <>
      {WaybillService.canCreate(permissions) &&
        resource === Constants.RESOURCE_WEBWAYBILL && (
          <CreateButton
            basePath={basePath}
            resource={resource}
            label='dxMessages.buttons.createNewDocument'
          />
        )}
      {InvoiceService.canCreate(permissions) &&
        resource === Constants.RESOURCE_WEBINVOICE && (
          <CreateButton
            basePath={basePath}
            resource={resource}
            label='dxMessages.buttons.createNewDocument'
          />
        )}
      {DespatchAdviceService.canCreate(permissions) &&
        resource === Constants.RESOURCE_WEBDESPATCH_ADVICE && (
          <CreateButton
            basePath={basePath}
            resource={resource}
            label='dxMessages.buttons.createNewDocument'
          />
        )}
      {ReceiptAdviceService.canCreate(permissions) &&
        resource === Constants.RESOURCE_WEBRECEIPT_ADVICE && (
          <CreateButton
            basePath={basePath}
            resource={resource}
            label='dxMessages.buttons.createNewDocument'
          />
        )}
      {OrderService.canCreate(permissions) &&
        resource === Constants.RESOURCE_WEBORDER && (
          <CreateButton
            basePath={basePath}
            resource={resource}
            label='dxMessages.buttons.createNewDocument'
          />
        )}
    </>
  );
};

/**
 * Overrides the default Actions toolbar in order to add the ListColumnSelector component
 */
const CommonListActions: React.FC<any> = ({
  bulkActions,
  basePath,
  currentSort,
  exporter,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  total,
  columns,
  columnsVisibility,
  onChangeVisibility,
  permissions,
}) => {
  const isSmall = useMediaQuery((theme: DxTheme) =>
    theme.breakpoints.down('sm')
  );
  const sidebarOpen = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );
  const classes = useStyles({ sidebarOpen });
  if (isSmall) {
    return <CreateButtons />;
  } else {
    return (
      <TopToolbar className={classes.toolbar}>
        {bulkActions &&
          React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
          })}
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
          visibleColumns={[...columns].filter(
            (column) => column.alwaysOn || columnsVisibility.get(column.id)
          )}
          columns={columns}
        />
        <ListColumnSelector
          columns={columns}
          columnsVisibility={columnsVisibility}
          onChange={onChangeVisibility}
        />
        <CreateButtons />
      </TopToolbar>
    );
  }
};

class PersistColumns {
  /**
   * Builds an instance of ColumnsService
   */
  constructor(
    public resource: string,
    public columns: any[],
    public columnsPersisted: any,
    public setColumnsPersisted: any
  ) {}

  public getUser() {
    return localStorage.getItem('user');
  }

  public columnsStorageKey() {
    return `${this.getUser()}.customColumns`;
  }

  public retrieveCustomColumns = () => {
    const key = this.columnsStorageKey();
    const customColumns = JSON.parse(localStorage.getItem(key) || '{}');
    if (customColumns && Object.keys(customColumns).length > 0) {
      // Migrates existing local storage data to new server-side one.
      this.setColumnsPersisted(customColumns);
      const key = this.columnsStorageKey();
      localStorage.removeItem(key);
      return customColumns;
    } else {
      return this.columnsPersisted || {};
    }
  };

  public persistSelectedColumns(customColumnsMap) {
    const customColumns = this.retrieveCustomColumns();
    const columnsKeys = Array.from(customColumnsMap.keys());
    if (columnsKeys.length) {
      customColumns[this.resource] = columnsKeys;
    } else {
      delete customColumns[this.resource];
    }
    this.setColumnsPersisted(customColumns);
  }

  public buildColumnsMap() {
    //configured columns
    let defaultColumns = [...this.columns]
      .filter((column) => !column.alwaysOn && !column.hiddenByDefault) // initialize the visibility map with the non-alwaysOn columns which are not hidden by default
      .map((column) => [column.id, true]);

    //custom user columns by resource
    const customColumns = this.retrieveCustomColumns()[this.resource] || [];
    const columns = customColumns.reduce((map, key) => {
      defaultColumns.push([key, true]);
      return map;
    }, defaultColumns);

    return new Map(columns);
  }
}

/**
 * Provides a list component used in multiple lists in the application
 */
const CommonList = (props) => {
  const {
    bulkActions,
    basePath,
    resource,
    title,
    columns,
    additionalFilters = [],
    contextualToolbar,
    location,
    ...other
  } = props;

  useGAPageViews();

  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;
  const app = getDxApplication();
  const [columnsPersisted, setColumnsPersisted] = usePreferences<any>(
    `${app}.customColumns`,
    undefined
  );
  const columnsPersist: PersistColumns = useMemo(() => {
    return new PersistColumns(
      resource,
      columns,
      columnsPersisted,
      setColumnsPersisted
    );
  }, [columns, columnsPersisted, resource, setColumnsPersisted]);

  const initialColumnsVisibility = columnsPersist.buildColumnsMap();
  const [columnsVisibility, setColumnsVisibility] = useState(
    initialColumnsVisibility
  );

  const sidebarOpen = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );
  const classes = useStyles({ sidebarOpen, ...props });
  const { permissions } = usePermissions();

  const handleChangeVisibility = (columnsVisibility) => {
    sendGAEvent(
      GA_EVENTS.categories.LIST.name,
      GA_EVENTS.categories.LIST.actions.CHOOSE_COLUMNS,
      account?.company?.cmsRootDir
    );

    setColumnsVisibility(new Map([...columnsVisibility.entries()]));
    columnsPersist.persistSelectedColumns(columnsVisibility);
  };

  useEffect(() => {
    columnsPersist.columnsPersisted = columnsPersisted;
    const newVisibilty = columnsPersist.buildColumnsMap();
    setColumnsVisibility(newVisibilty);
  }, [columnsPersist, columnsPersisted]);

  return (
    <List
      {...other}
      filters={<CommonListFilter filters={[...additionalFilters]} />}
      sort={{ field: Metadata.modified, order: 'DESC' }}
      classes={{ root: classes.root, content: classes.content }}
      bulkActionButtons={bulkActions ? bulkActions : <BulkActionButtons />}
      actions={
        <CommonListActions
          columns={[...columns]}
          columnsVisibility={columnsVisibility}
          onChangeVisibility={handleChangeVisibility}
          permissions={permissions}
        />
      }
      basePath={basePath}
      resource={resource}
      // pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      pagination={<FloatingPagination rowsPerPageOptions={[25, 50, 100]} />}
      perPage={25}
      exporter={exporter}
      empty={<Empty />}
    >
      <ResponsiveGrid
        resource={resource}
        columns={columns}
        columnsVisibility={columnsVisibility}
        classes={{
          root: classes.root,
          headerRow_withStickyCols: classes.headerRow_withStickyCols,
          row_withStickyCols: classes.row_withStickyCols,
          tableScroll: classes.tableScroll,
        }}
        contextualToolbar={contextualToolbar}
        app={app}
      />
    </List>
  );
};

export default CommonList;
