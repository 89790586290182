import {
  Avatar,
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
} from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import CachedIcon from '@material-ui/icons/Cached';
import Description from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { useState } from 'react';
import { Loading, usePermissions, useTranslate } from 'react-admin';
import { isBetaTester, isPspAdministrator } from '../configuration';
import { UsageRight } from '../configuration/types';
import { Constants } from '../constants';
import { checkPermissions } from '../security';
import { UserRoles } from '../security/UserRoles';
import { useTaskCount } from '../tasks';
import { useSwitchTo } from '../utils';

const DxAppsMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { loaded, permissions } = usePermissions<UsageRight[], any>();
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const translate = useTranslate();
  const taskCount = useTaskCount({
    permissions,
    productName: Constants.DXPURCHASE_APP,
  });
  const reconciliationTaskCount = useTaskCount({
    permissions,
    productName: Constants.RECONCILIATION_COCKPIT_APP,
  });

  const open = Boolean(anchorEl);
  const switchTo = useSwitchTo();

  if (!loaded) return <Loading />;

  return (
    <div>
      <Tooltip title={translate('dxMessages.buttons.appsSwitcher')}>
        <IconButton
          aria-label='Applications'
          aria-owns={open ? 'apps-menu' : undefined}
          onClick={handleClickListItem}
          color='inherit'
        >
          <AppsIcon />
        </IconButton>
      </Tooltip>

      <Popover
        id='apps-menu'
        open={open}
        anchorEl={anchorEl}
        onClose={(_) => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List dense>
          <ListItem
            button
            id={Constants.DXPURCHASE_APP}
            onClick={() => {
              if (props.title === Constants.DXPURCHASE_APP_DISPLAY) {
                setAnchorEl(null);
                return;
              }
              switchTo(
                `/${Constants.DXPURCHASE_APP}`,
                Constants.DXPURCHASE_APP
              );
            }}
            disabled={
              !isPspAdministrator(permissions as any) &&
              !checkPermissions(
                permissions,
                UserRoles.DXPURCHASE_PRODUCT,
                UserRoles.DXPURCHASE_ACCESS
              ) &&
              !isBetaTester(permissions)
            }
          >
            <ListItemAvatar>
              <Badge badgeContent={taskCount} color='error' overlap='circle'>
                <Avatar>
                  <CachedIcon color={'error'} />
                </Avatar>
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={Constants.DXPURCHASE_APP_DISPLAY}
              secondary={translate('dxMessages.appSwitcher.DocProcess.label')}
            />
          </ListItem>

          <ListItem
            button
            id={Constants.DXARCHIVE_APP}
            onClick={() => {
              if (props.title === Constants.DXARCHIVE_APP_DISPLAY) {
                setAnchorEl(null);
                return;
              }
              switchTo(`/${Constants.DXARCHIVE_APP}`, Constants.DXARCHIVE_APP);
            }}
            disabled={
              !isPspAdministrator(permissions as any) &&
              !checkPermissions(
                permissions,
                UserRoles.DXARCHIVE_PRODUCT,
                UserRoles.DXARCHIVE_ACCESS
              ) &&
              !isBetaTester(permissions, UserRoles.DXARCHIVE_PRODUCT)
            }
          >
            <ListItemAvatar>
              <Avatar>
                <UnarchiveIcon color={'error'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={Constants.DXARCHIVE_APP_DISPLAY}
              secondary={translate('dxMessages.appSwitcher.DxArchive.label')}
            />
          </ListItem>
          <ListItem
            button
            id={Constants.DXCATALOG_APP}
            onClick={() => {
              if (props.title === Constants.DXCATALOG_APP_DISPLAY) {
                setAnchorEl(null);
                return;
              }
              switchTo(`/${Constants.DXCATALOG_APP}`, Constants.DXCATALOG_APP);
            }}
            disabled={
              !isPspAdministrator(permissions! as any) &&
              !checkPermissions(
                permissions,
                UserRoles.DXCATALOG_PRODUCT,
                UserRoles.DXCATALOG_ACCESS
              ) &&
              !isBetaTester(permissions, UserRoles.DXCATALOG_PRODUCT)
            }
          >
            <ListItemAvatar>
              <Avatar>
                <LibraryBooksIcon color={'error'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={Constants.DXCATALOG_APP_DISPLAY}
              secondary={translate('dxMessages.appSwitcher.DxCatalog.label')}
            />
          </ListItem>
          <ListItem
            button
            id={Constants.RECONCILIATION_COCKPIT_APP}
            onClick={() => {
              if (
                props.title === Constants.RECONCILIATION_COCKPIT_APP_DISPLAY
              ) {
                setAnchorEl(null);
                return;
              }
              switchTo(
                `/${Constants.RECONCILIATION_COCKPIT_APP}`,
                Constants.RECONCILIATION_COCKPIT_APP
              );
            }}
            disabled={
              !isPspAdministrator(permissions! as any) &&
              !checkPermissions(
                permissions,
                'reconciliation-cockpit',
                'reconciliator'
              ) &&
              !checkPermissions(
                permissions,
                'reconciliation-cockpit',
                'viewer'
              ) &&
              !isBetaTester(permissions, 'reconciliation-cockpit')
            }
          >
            <ListItemAvatar>
              <Badge
                badgeContent={reconciliationTaskCount}
                color='error'
                overlap='circle'
              >
                <Avatar>
                  <MergeTypeIcon color={'error'} />
                </Avatar>
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={Constants.RECONCILIATION_COCKPIT_APP_DISPLAY}
              secondary={translate(
                'dxMessages.appSwitcher.ReconciliationCockpit.label'
              )}
            />
          </ListItem>
          <ListItem
            button
            id={Constants.ETRANSPORT_COCKPIT_APP}
            onClick={() => {
              if (props.title === Constants.ETRANSPORT_COCKPIT_APP_DISPLAY) {
                setAnchorEl(null);
                return;
              }
              switchTo(
                `/${Constants.ETRANSPORT_COCKPIT_APP}`,
                Constants.ETRANSPORT_COCKPIT_APP
              );
            }}
            disabled={
              !isPspAdministrator(permissions! as any) &&
              !checkPermissions(
                permissions,
                'E-TRANSPORT',
                'ETRANSPORT_COCKPIT_ACCESS'
              ) &&
              !isBetaTester(permissions, 'E-TRANSPORT')
            }
          >
            <ListItemAvatar>
              <Avatar>
                <LocalShippingIcon color={'error'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={Constants.ETRANSPORT_COCKPIT_APP_DISPLAY}
              secondary={translate(
                'dxMessages.appSwitcher.EtransportCockpit.label'
              )}
            />
          </ListItem>
          <ListItem
            button
            id={Constants.DXCONTRACT_APP}
            onClick={() => {
              if (props.title === Constants.DXCONTRACT_APP_DISPLAY) {
                setAnchorEl(null);
                return;
              }
              switchTo(
                `/${Constants.DXCONTRACT_APP}`,
                Constants.DXCONTRACT_APP
              );
            }}
            disabled={
              !isPspAdministrator(permissions! as any) &&
              !checkPermissions(
                permissions,
                UserRoles.DXCONTRACT_PRODUCT,
                UserRoles.DXCONTRACT_ACCESS
              ) &&
              !isBetaTester(permissions, UserRoles.DXCONTRACT_PRODUCT)
            }
          >
            <ListItemAvatar>
              <Avatar>
                <Description color={'error'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={Constants.DXCONTRACT_APP_DISPLAY}
              secondary={translate('dxMessages.appSwitcher.DxContract.label')}
            />
          </ListItem>
          <ListItem
            button
            id={Constants.C4_COCKPIT_APP}
            onClick={() => {
              if (props.title === Constants.C4_COCKPIT_APP) {
                setAnchorEl(null);
                return;
              }
              switchTo(
                `/${Constants.C4_COCKPIT_APP}`,
                Constants.C4_COCKPIT_APP
              );
            }}
            disabled={
              !isPspAdministrator(permissions! as any) &&
              !checkPermissions(
                permissions,
                'carrefour-inbound-cockpit',
                'C4_EFACTURA_COCKPIT_ACCESS'
              )
            }
          >
            <ListItemAvatar>
              <Avatar>
                <FileCopyIcon color={'error'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={Constants.C4_COCKPIT_APP_DISPLAY}
              secondary={translate('dxMessages.appSwitcher.C4Cockpit.label')}
            />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default DxAppsMenu;
