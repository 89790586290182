const ResetPasswordRoot = '/reset-password';
const LostPasswordRoot = '/lost-password';

/**
 * Checks if the request is made to a password management url
 */
export const isPasswordManagementUrl = (): boolean => {
  return !!window.location.hash.match(
    `^#(?:${LostPasswordRoot}|${ResetPasswordRoot}/.+)`
  );
};

// The route to the view for resetting the account password.
// 'token' is the JSON WEB token holding the account info
// (and the signed right to change the password).
// 'login' is the optional login name of the account and is only set when
// the reset password is for an account activation.

export const ResetPasswordRoutePath = `${ResetPasswordRoot}/:token/:login?`;
export const LostPasswordRoutePath = LostPasswordRoot;

export const isPasswordStrong = (password: string): boolean => {
  if (!password) return false;
  // Regex explanation:
  // (?=.*[a-z]) - one or more lower case letters
  // (?=.*[A-Z]) - one or more upper case letters
  // (?=.*[0-9]) - one or more numbers
  // (?=.*[!&@#:\?\+\*-\/\$\(\)]) - one of the special characters
  // (?=.{8,}) - must have at least 8 characters

  const regex =
    /^(?=.*[a-z_])(?=.*[A-Z_])(?=.*[0-9])(?=.*[!&@#:?+*-/$()])(?=.{8,})/g;
  return !!password.match(regex);
};
