import { every, get, has, size } from 'lodash';
import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  CreateProps,
  email,
  Record as RaRecord,
  useAuthProvider,
  useNotify,
  usePermissions,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { isPspAdministrator } from '../configuration';
import { AccountCreationFormValues } from '../types';
import CheckAndSaveStep from './creation/CheckAndSaveStep';
import CompanySelectionStep from './creation/CompanySelectionStep';
import PersonSelectionStep from './creation/PersonSelectionStep';
import UsageRightsSelectionStep from './creation/UsageRightsSelectionStep';
import WizardForm from './WizardForm';

/**
 * The guided wizard to create a new account.
 *
 * It's a regular <ReactAdmin /> Create FC but with a custom form layout built on
 * a material-ui stepper.
 */
const AccountCreate: FC<CreateProps> = (props) => {
  const { loaded, permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const identity: any = authProvider.getIdentity?.();
  if (!loaded || !permissions || !identity) return null;

  const addReferer = (data: AccountCreationFormValues & RaRecord) => ({
    ...data,
    referer: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
  });

  // Add the referer and if the user is a company administrator, force the account company
  // to be the same as the administrator before sending the creation request to the backend.
  const transform = (data: AccountCreationFormValues & RaRecord) =>
    addReferer(
      isPspAdministrator(permissions)
        ? data
        : {
            ...data,
            company: { id: identity?.company.id },
          }
    );

  // On successful creation, redirect to the account list.
  const onSuccess = () => {
    notify('resources.accounts.create.notifications.created');
    redirect('/configuration-accounts');
    refresh();
  };

  return (
    <Create
      title='resources.accounts.create.title'
      transform={transform}
      onSuccess={onSuccess}
      component='div'
      {...props}
    >
      {/* A PSP administrator can select the company the account will belong to, not a company
      administrator (for this last one, new accounts are always assigned to the administrator company).
      So the wizard contains one step more for the PSP administrator (select the company). */}
      <WizardForm
        steps={
          isPspAdministrator(permissions)
            ? PSP_ADMINITSRATOR_STEPS
            : COMPANY_ADMINITSRATOR_STEPS
        }
      />
    </Create>
  );
};

const PSP_ADMINITSRATOR_STEPS = [
  {
    label: 'resources.accounts.create.wizard.steps.companySelection.title',
    fc: <CompanySelectionStep />,
    validation: (values: AccountCreationFormValues) => {
      // The company is mandatory
      return !!values.company;
    },
  },
  {
    label: 'resources.accounts.create.wizard.steps.personSelection.title',
    fc: <PersonSelectionStep />,
    validation: (values: AccountCreationFormValues) => {
      // New person validation.
      const newPerson =
        // Mandatory.
        every(
          [
            'person.email',
            'person.firstname',
            'person.lastname',
            'person.language',
            'person.identities',
          ],
          (p) => has(values, p)
        ) &&
        // At least one identity.
        size(values.person?.identities) > 0 &&
        // For each identity:
        every(
          values.person?.identities,
          (id) =>
            // both the identity ID and its provider ID are mandatory.
            has(id, 'id') &&
            has(id, 'provider.id') &&
            // Doc Process identity ID must be valid email.
            (get(id, 'provider.type') !== 'BUILT_IN' ||
              !email('')(get(id, 'id'), undefined))
        ) &&
        // Only one Doc Process identity can be configured.
        (values.person?.identities || []).filter(
          (id) => get(id, 'provider.type') === 'BUILT_IN'
        ).length <= 1;

      // Either an existing person is set or a new one created.
      const person = has(values, 'personId') || newPerson;

      // If job is defined, at least one attribute, and attribute key is mandatory.
      const job =
        !has(values, 'job.attributes') ||
        every(values.job?.attributes, (att) => has(att, 'key'));

      return person && job;
    },
  },
  {
    label: 'resources.accounts.create.wizard.steps.usageRightsSelection.title',
    fc: <UsageRightsSelectionStep />,
    validation: (values: AccountCreationFormValues) => {
      // The usage rights selection. Not mandatory:
      if (!values.usageRights) return true;

      // But if defined, at least the feature is mandatory:
      if (!every(values.usageRights, (ur) => has(ur, 'feature.id')))
        return false;

      // And there is no duplicate feature.
      const featureIds = values.usageRights.map((ur) => ur.feature.id);
      return (
        featureIds.filter((f, i) => featureIds.indexOf(f) !== i).length === 0
      );
    },
  },
  {
    label: 'resources.accounts.create.wizard.steps.checkAndSave.title',
    fc: <CheckAndSaveStep />,
    validation: (values: AccountCreationFormValues) =>
      !!values?.termsAcceptance,
  },
];

// The same steps as for a PSP administrator without the company selection
// (the company is the one of the administartor).
const [, ...COMPANY_ADMINITSRATOR_STEPS] = PSP_ADMINITSRATOR_STEPS;

export default AccountCreate;
