import { Box, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { FC } from 'react';
import {
  ArrayField,
  ArrayInput,
  ChipField,
  Datagrid,
  FieldProps,
  Loading,
  ReferenceField,
  SimpleFormIterator,
  SingleFieldList,
  TextField,
  useGetOne,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { isPspAdministrator } from '../../configuration';
import { Account, Company } from '../../types';
import ConfigurableUsageRight from '../ConfigurableUsageRight';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The tab where the user selects the usage rights for the account.
 *
 * Only visible by administrators.
 *
 * There are three kinds of usage rights: (1) the configurable ones,
 * (2) the on-the-fly ones (built programmatically, only visible for PSP
 * administrators), and (3) the ones inherited by delegations.
 */
const UsageRightTab: FC<FieldProps<Account>> = (props) => {
  const translate = useTranslate();
  const { loaded, permissions } = usePermissions();
  if (!loaded || !permissions) return null;

  const { record } = props;
  if (!record) return null;

  return (
    <Grid container direction='column' spacing={2} style={{ marginTop: '1em' }}>
      <Grid item>
        <Typography variant='caption'>
          {translate(
            isPspAdministrator(permissions)
              ? 'resources.accounts.edit.tabs.usageRights.pspAdminSubtitle'
              : 'resources.accounts.edit.tabs.usageRights.companyAdminSubtitle'
          )}
        </Typography>
      </Grid>
      <Grid item>
        <ConfiguredUsageRights {...props} />
      </Grid>
      {isPspAdministrator(permissions) && (
        <Grid item>
          <OnTheFlyUsageRights {...props} />
        </Grid>
      )}
      <Grid item>
        <DelegatedUsageRights {...props} />
      </Grid>
    </Grid>
  );
};

const ConfiguredUsageRights: FC<FieldProps<Account>> = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const {
    data: company,
    loading,
    error,
  } = useGetOne<Company>(
    'configuration-companies',
    record!.company.id // Here for sure as check first by the caller.
  );

  if (loading) return <Loading />;
  if (error || !company) return null;

  // features = { 'administration': ['company administrator', 'PSP administrator'], ... }
  const features = company?.subscriptions.reduce((acc, s) => {
    // The selectable roles for a feature is the subtraction between the feature availabe roles
    // minus the excluded ones in the company subscription.
    acc[s.feature.id] = s.feature.availableRoles
      .filter((r) => !s.excludedRoleIds.includes(r.id))
      .map((r) => r.id);
    return acc;
  }, {});

  const validate = (values) => {
    if (!values) return undefined;

    // No duplicate feature.
    const featureIds = values.filter((ur) => !!ur).map((ur) => ur.feature.id);
    const duplicates = featureIds.filter((f, i) => featureIds.indexOf(f) !== i);

    if (duplicates.length !== 0)
      return 'resources.accounts.create.wizard.steps.usageRightsSelection.errors.duplicateFeatures';
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5'>
        {translate(
          'resources.accounts.edit.tabs.usageRights.sections.configurables.title'
        )}
      </Typography>
      <Typography variant='caption'>
        {translate(
          'resources.accounts.edit.tabs.usageRights.sections.configurables.subtitle'
        )}
      </Typography>
      <ArrayInput label='' source='configuredUsageRights' validate={validate}>
        <SimpleFormIterator>
          <ConfigurableUsageRight features={features} />
        </SimpleFormIterator>
      </ArrayInput>
    </Paper>
  );
};

const OnTheFlyUsageRights: FC<FieldProps<Account>> = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { record } = props;

  if (!record) return null;

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5'>
        {translate(
          'resources.accounts.edit.tabs.usageRights.sections.onthefly.title'
        )}
      </Typography>
      <Typography variant='caption'>
        {translate(
          'resources.accounts.edit.tabs.usageRights.sections.onthefly.subtitle'
        )}
      </Typography>
      <Box mt={2}>
        {!record?.onTheFlyUsageRights?.length && (
          <Typography>
            {translate(
              'resources.accounts.edit.tabs.usageRights.sections.onthefly.none'
            )}
          </Typography>
        )}
        {record?.onTheFlyUsageRights?.length > 0 && (
          <ArrayField label='' source='onTheFlyUsageRights' {...props}>
            <Datagrid style={{ width: '100%' }}>
              <TextField
                label='resources.accounts.create.wizard.steps.usageRightsSelection.feature'
                source='feature.id'
              />
              <ArrayField
                label={translate(
                  'resources.accounts.create.wizard.steps.usageRightsSelection.roles'
                )}
                source='roles'
              >
                <SingleFieldList linkType={false}>
                  <ChipField source='id' size='small' color='primary' />
                </SingleFieldList>
              </ArrayField>
            </Datagrid>
          </ArrayField>
        )}
      </Box>
    </Paper>
  );
};

const DelegatedUsageRights: FC<FieldProps<Account>> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { record } = props;

  if (!record) return null;

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5'>
        {translate(
          'resources.accounts.edit.tabs.usageRights.sections.delegateds.title'
        )}
      </Typography>
      <Typography variant='caption'>
        {translate(
          'resources.accounts.edit.tabs.usageRights.sections.delegateds.subtitle'
        )}
        <IconButton
          color='primary'
          component={Link}
          // to={linkToRecord("/configuration-companies", record?.company.id)}
          to={`/configuration-companies/${record?.company.id}/2`}
          size='small'
        >
          <EditIcon style={{ width: '16px', height: '16px' }} />
        </IconButton>
        )
      </Typography>
      <Box mt={2}>
        {!record?.delegatedUsageRights?.length && (
          <Typography>
            {translate(
              'resources.accounts.edit.tabs.usageRights.sections.delegateds.none'
            )}
          </Typography>
        )}
        {record?.delegatedUsageRights?.length > 0 && (
          <ArrayField label='' source='delegatedUsageRights' {...props}>
            <Datagrid style={{ width: '100%' }}>
              <TextField
                label='resources.accounts.edit.tabs.usageRights.sections.delegateds.feature'
                source='feature.id'
              />
              <ArrayField
                label='resources.accounts.edit.tabs.usageRights.sections.delegateds.roles'
                source='roles'
              >
                <SingleFieldList linkType={false}>
                  <ChipField source='id' size='small' color='primary' />
                </SingleFieldList>
              </ArrayField>
              <ReferenceField
                label='resources.accounts.edit.tabs.usageRights.sections.delegateds.from'
                source='delegation.representee.id'
                reference='configuration-people'
              >
                <TextField source='email' />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        )}
      </Box>
    </Paper>
  );
};

export default UsageRightTab;
