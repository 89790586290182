import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/fr';
import 'moment/locale/he';
import 'moment/locale/ro';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { getLocaleInfo, getMessages } from './LocaleInfo';

const i18Provider = (locale) => {
  moment.locale(locale); //  Date Format
  switch (locale) {
    case 'fr':
      moment.updateLocale('fr', {
        calendar: {
          lastDay: '[hier]',
          sameDay: '[aujourd’hui]',
          nextDay: '[demain]',
          lastWeek: 'dddd',
          nextWeek: 'dddd',
          sameElse: 'L',
        },
      });
      break;
    case 'en':
      moment.updateLocale('en', {
        calendar: {
          lastDay: '[yesterday]',
          sameDay: '[today]',
          nextDay: '[tomorrow]',
          lastWeek: 'dddd',
          nextWeek: 'dddd',
          sameElse: 'L',
        },
      });
      break;
    case 'ro':
      moment.updateLocale('ro', {
        calendar: {
          lastDay: '[ieri]',
          sameDay: '[azi]',
          nextDay: '[mâine]',
          lastWeek: 'dddd',
          nextWeek: 'dddd',
          sameElse: 'L',
        },
      });
      break;
  }
  return getMessages(locale);
};

const locale = getLocaleInfo();

export default polyglotI18nProvider(i18Provider, locale);
