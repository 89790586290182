import { getTz } from '../utils/utils';

/**
 * Gets the browser locale
 */
const useTz = (): string => {
  return getTz();
};

export default useTz;
