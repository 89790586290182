import { DxTheme } from '@dx-ui/dx-common';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    button: {
      minHeight: 35,
      backgroundColor: theme.palette.secondary.light,
      color: theme.colors.mainColor4,
      marginLeft: 4,
    },
  }),
  { name: 'ToolbarBackButton' }
);

const sanitizeBtnProps = ({
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  onSave,
  submitOnEnter,
  ...rest
}: any) => rest;

const ToolbarBackButton = (props: any) => {
  const history = useHistory();
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Button
      variant='contained'
      onClick={() => {
        history.goBack();
      }}
      type='button'
      color='secondary'
      classes={{ root: classes.button }}
      {...sanitizeBtnProps(props)}
    >
      {translate('ra.action.back')}
    </Button>
  );
};

export default ToolbarBackButton;
