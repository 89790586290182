import {
  cellStyle,
  colors,
  DxTheme,
  GenericSimpleField,
  MultiSelectInput,
  RegulatorExtraDetailsType,
  widthPresets,
} from '@dx-ui/dx-common';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { TextInput, useTranslate } from 'react-admin';
import { DataHelpers } from '../services';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    statusIcon: {
      fontSize: 'medium',
      marginRight: 3,
      verticalAlign: 'middle',
      strokeWidth: 2,
    },
  }),
  { name: 'RegulatorStatusIcon' }
);

export const RegulatorStatusCodeIcon = (props) => {
  const { status } = props;
  const classes = useStyles();

  switch (status) {
    case '0':
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.functionalGreen,
            stroke: colors.functionalGreen,
          }}
          className={classes.statusIcon}
        />
      );

    case '10':
    case '20':
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.red[50],
            stroke: colors.red[50],
          }}
          className={classes.statusIcon}
        />
      );
    case '40':
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.functionalOrange,
            stroke: colors.functionalOrange,
          }}
          className={classes.statusIcon}
        />
      );

    default:
      return <></>;
  }
};

/**
 * Renders the Regulator Status Code value as a Field
 * @param {*} translate I18N translate function
 * @param {*} record current row values
 */
export const RegulatorStatusCodeField = ({ record = { properties: [] } }) => {
  const translate = useTranslate();

  const regulatorStatusCode = record.properties['edm:regulatorStatusCode'];
  return (
    <Typography>
      {<RegulatorStatusCodeIcon status={regulatorStatusCode} />}
      <span>
        {translate(`dxMessages.regulatorStatusCode.${regulatorStatusCode}`, {
          _: '--',
        })}
      </span>
    </Typography>
  );
};

/**
 * Renders Regulator Id info as a Field
 * @param {*} className
 * @param {*} record current row values
 */
export const RegulatorIdField = (props) => <GenericSimpleField {...props} />;

/**
 * Renders Regulator Extra Details info as a Field
 * @param {*} className
 * @param {*} record current row values
 */
export const RegulatorExtraDetails = ({ record = { properties: [] } }) => {
  const translate = useTranslate();

  const regulatorExtraDetails = DataHelpers.regulatorExtraDetails(record);
  return (
    <Typography>
      <span>
        {regulatorExtraDetails === RegulatorExtraDetailsType.ANAF_ONLY
          ? translate(`dxMessages.regulatorExtraDetails.efacturaOnly`)
          : '--'}
      </span>
    </Typography>
  );
};

/**
 * common columns
 */
export const createColumnsRegulatorDetails = () => [
  {
    id: 'edm:regulatorId',
    label: 'dxMessages.headers.regulatorId',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.medium,
    renderCell: (props) => <RegulatorIdField {...props} />,
  },
  {
    id: 'edm:regulatorExtraDetails',
    label: 'dxMessages.headers.regulatorExtraDetails',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.medium,
    renderCell: (props) => <RegulatorExtraDetails {...props} />,
  },
  {
    id: 'edm:regulatorStatusCode',
    label: 'dxMessages.headers.regulatorStatusCode',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    readOnly: true,
    renderCell: (props) => <RegulatorStatusCodeField {...props} />,
  },
];

/**
 * Common filters
 */
export const createRegulatorFilters = () => [
  {
    id: 'edm:regulatorId',
    filter: (props) => (
      <TextInput
        source='edm:regulatorId'
        label='dxMessages.headers.regulatorId'
        resettable
        {...props}
      />
    ),
  },
  {
    id: 'edm:regulatorExtraDetails',
    filter: (props) => (
      <MultiSelectInput
        source='edm:regulatorExtraDetails'
        label='dxMessages.headers.regulatorExtraDetails'
        width={widthPresets.large}
        resettable
        {...props}
        options={[
          {
            id: RegulatorExtraDetailsType.ANAF_ONLY,
            label: 'dxMessages.regulatorExtraDetails.efacturaOnly',
          },
        ]}
      />
    ),
  },
  {
    id: 'edm:regulatorStatusCode',
    filter: (props) => (
      <MultiSelectInput
        label='dxMessages.headers.regulatorStatusCode'
        source='edm:regulatorStatusCode'
        width={widthPresets.large}
        sortLabels={true}
        {...props}
        options={[
          {
            id: '0',
            label: 'dxMessages.regulatorStatusCode.0',
          },
          {
            id: '10',
            label: 'dxMessages.regulatorStatusCode.10',
          },
          {
            id: '20',
            label: 'dxMessages.regulatorStatusCode.20',
          },
          {
            id: '40',
            label: 'dxMessages.regulatorStatusCode.40',
          },
        ]}
      />
    ),
  },
];
