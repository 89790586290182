import {
  Constants,
  DocumentTypeCode,
  documentTypeCodeToResource,
  Metadata,
  ProcessStatus,
} from '@dx-ui/dx-common';
import { get } from 'lodash';
import { DataProvider, GetListParams, Record } from 'react-admin';
import {
  AlfrescoContent,
  AlfrescoProperties,
  DocumentProperties,
} from '../shared/types';
import { DataHelpers } from './DataHelpers';

export interface DocumentQueryResult {
  data: any;
  total: number;
}

export enum DuplicationType {
  COPY = 'COPY',
  INVOIC_CANCELLATION = 'INVOIC_CANCELLATION',
  INVOIC_CORRECTIVE = 'INVOIC_CORRECTIVE',
}

export type CopyPermissions = {
  [key in
    | CopyOperation.CAN_CANCEL_CORRECTIVE_WEB_INVOICE
    | CopyOperation.CAN_CANCEL_CORRECTIVE_CLASSIC_INVOICE
    | CopyOperation.CAN_CLONE_WEB_INVOICE
    | CopyOperation.CAN_CLONE_CLASSIC_INVOICE
    | CopyOperation.CAN_CLONE_WEB_WAYBILL
    | CopyOperation.CAN_CLONE_CLASSIC_WAYBILL
    | CopyOperation.CAN_CLONE_WEB_DESADV
    | CopyOperation.CAN_CLONE_CLASSIC_DESADV
    | CopyOperation.CAN_CLONE_WEB_ORDER
    | CopyOperation.CAN_CLONE_CLASSIC_ORDER]: ProcessStatus[] | undefined;
};

export enum CopyOperation {
  CAN_CANCEL_CORRECTIVE_WEB_INVOICE = 'CAN_CANCEL_CORRECTIVE_WEB_INVOICE',
  CAN_CANCEL_CORRECTIVE_CLASSIC_INVOICE = 'CAN_CANCEL_CORRECTIVE_CLASSIC_INVOICE',
  CAN_CLONE_WEB_INVOICE = 'CAN_CLONE_WEB_INVOICE',
  CAN_CLONE_CLASSIC_INVOICE = 'CAN_CLONE_CLASSIC_INVOICE',
  CAN_CLONE_WEB_WAYBILL = 'CAN_CLONE_WEB_WAYBILL',
  CAN_CLONE_CLASSIC_WAYBILL = 'CAN_CLONE_CLASSIC_WAYBILL',
  CAN_CLONE_WEB_DESADV = 'CAN_CLONE_WEB_DESADV',
  CAN_CLONE_CLASSIC_DESADV = 'CAN_CLONE_CLASSIC_DESADV',
  CAN_CLONE_WEB_RECADV = 'CAN_CLONE_WEB_RECADV',
  CAN_CLONE_CLASSIC_RECADV = 'CAN_CLONE_CLASSIC_RECADV',
  CAN_CLONE_WEB_ORDER = 'CAN_CLONE_WEB_ORDER',
  CAN_CLONE_CLASSIC_ORDER = 'CAN_CLONE_CLASSIC_ORDER',
}

export interface IAlfrescoDocumentService {
  dataProvider: DataProvider;
  /**
   * Get the invoice created from a supported document
   */
  convertTo(
    nodeId: string,
    documentType: DocumentTypeCode
  ): Promise<AlfrescoContent>;

  /**
   * Creates a new document based on an existing one
   */
  copy(nodeId: string, cloneType: DuplicationType): Promise<AlfrescoContent>;

  /**
   * Returns if actions are allowed or not, grouped by process status
   */
  getClonePermissions(): Promise<CopyPermissions>;

  /**
   * Gets the DRAFT document content from alfresco
   */
  loadDocumentDraft(
    nodeId: string | undefined,
    documentTypeCode: DocumentTypeCode
  ): Promise<AlfrescoContent>;

  /**
   * Gets the document (not DRAFT) content from alfresco
   */
  loadDocument(
    nodeId: string,
    documentTypeCode: DocumentTypeCode
  ): Promise<any>;

  /**
   * Initialize a new document for the web form
   */
  initNewDocument(
    documentTypeCode: DocumentTypeCode,
    documentSubTypeCode?: string
  ): AlfrescoProperties;

  /**
   * Saves a document to alfresco
   */
  createOrUpdateDocument(
    nodeId: string | undefined,
    data: AlfrescoContent
  ): Promise<DocumentProperties>;

  /**
   * Sends a saved document to the workflow treatment
   */
  sendDocument(nodeId: string): Promise<DocumentQueryResult>;

  /**
   * Gets the document Metadata from alfresco
   */
  listDocuments(resource: string, filter: object): Promise<Record[]>;
}

export class AlfrescoDocumentService implements IAlfrescoDocumentService {
  constructor(public dataProvider: DataProvider) {}

  /**
   * Get the invoice created from a supported document
   */
  public async convertTo(
    nodeId: string,
    DocumentType: DocumentTypeCode
  ): Promise<AlfrescoContent> {
    const result = await this.dataProvider.apiPost(
      `${Constants.RESOURCE_CONVERT}/${DocumentType}/${nodeId}`,
      undefined
    );

    return result.data;
  }

  /**
   * Creates a new document based on an existing one
   */
  public async copy(
    nodeId: string,
    cloneType: DuplicationType
  ): Promise<AlfrescoContent> {
    const payload = {
      cloneType,
      nodeId: nodeId,
    };
    const result = await this.dataProvider[Constants.API_CLONE_DOCUMENT](
      Constants.RESOURCE_CLONE,
      payload
    );

    return result.data;
  }

  /**
   * Returns if actions are allowed or not by process status
   */
  public async getClonePermissions(): Promise<CopyPermissions> {
    const result = await this.dataProvider.apiGet(
      `${Constants.RESOURCE_CLONE}/permissions`,
      undefined
    );
    return result.data;
  }

  /**
   * Gets the document content from alfresco
   */
  public async loadDocumentDraft(
    nodeId: string,
    documentTypeCode: DocumentTypeCode
  ): Promise<AlfrescoContent> {
    const resource = documentTypeCodeToResource(documentTypeCode);
    const payload = { id: nodeId };
    const response = await this.dataProvider[Constants.API_GET_JSON_CONTENT](
      resource,
      payload
    );
    return response.data;
  }

  /**
   * Gets the document (not DRAFT) content from alfresco
   */
  public async loadDocument(
    nodeId: string,
    documentTypeCode: DocumentTypeCode
  ): Promise<any> {
    const resource = documentTypeCodeToResource(documentTypeCode);
    const payload = { id: nodeId };
    const response = await this.dataProvider[Constants.API_GET_UBL_JSON](
      resource,
      payload
    );
    return response.data;
  }

  /**
   * Initialize a new document for the web form
   */
  public initNewDocument(
    documentTypeCode: DocumentTypeCode,
    documentSubTypeCode?: string
  ): AlfrescoProperties {
    const newDocument = {
      properties: {},
    };

    newDocument.properties[Metadata.documentTypeCode] = documentTypeCode;
    newDocument.properties[Metadata.documentSubTypeCode] = documentSubTypeCode;

    newDocument.properties[Metadata.readStatus] = Constants.READ_STATUS_NEW;
    newDocument.properties[Metadata.processStatus] = ProcessStatus.DRAFT;
    newDocument.properties[Metadata.processDocumentFormatType] =
      Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT;

    return newDocument;
  }

  /**
   * Saves a document to alfresco
   */
  public async createOrUpdateDocument(
    nodeId: string | undefined,
    data: AlfrescoContent
  ): Promise<DocumentProperties> {
    const queryType = nodeId
      ? Constants.API_PUT_SAVE_DOCUMENT
      : Constants.API_POST_CREATE_DOCUMENT;

    const documentTypeCode = DataHelpers.getDocumentTypeCode(data);

    const resource = documentTypeCodeToResource(documentTypeCode);
    const payload = {
      data,
      id: nodeId,
    };
    const result = await this.dataProvider[queryType](resource, payload);
    return result.data;
  }

  /**
   * Sends a saved document to the workflow treatment
   */
  public async sendDocument(nodeId: string): Promise<DocumentQueryResult> {
    const resource = Constants.RESOURCE_WEBDOCUMENT;
    const payload = {
      id: nodeId,
    };
    return await this.dataProvider[Constants.API_SEND_DOCUMENT](
      resource,
      payload
    );
  }

  /**
   * Gets the document Metadata from alfresco
   */
  public async listDocuments(resource: string, filter: any): Promise<Record[]> {
    const payload: GetListParams = {
      filter,
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'ASC' },
    };
    const response = await this.dataProvider.getList(resource, payload);
    return response.data;
  }

  /**
   * Checks that provided document is of type "DRAFT"
   */
  public static isWebDocument(document: AlfrescoProperties): boolean {
    const type = get(document.properties, Metadata.processDocumentFormatType);
    return type === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT;
  }
}
