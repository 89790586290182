import { InputAdornment, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import HttpStatus from 'http-status-codes';
import React, { useState } from 'react';
import {
  ReduxState,
  TopToolbar,
  useAuthProvider,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Button } from '../components';
import { DxTheme } from '../types';
import Input from './Input';
import PasswordCriterias from './PasswordCriterias';
import { isPasswordStrong } from './PasswordManagementUtils';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    cancelButton: {
      marginLeft: '0.5em',
      backgroundColor: theme.palette.secondary.light,
      color: theme.colors.mainColor4,
      '&:hover': {
        backgroundColor: theme.colors.blue[100],
      },
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'ResetPasswordForm' }
);

const ResetPasswordForm = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const authProvider = useAuthProvider();
  const match = useRouteMatch<{ token: string; login?: string }>();
  const [showNewPassword, SetShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const loading = useSelector<ReduxState, boolean>(
    (state) => state.admin.loading > 0
  );

  const validate = (values) => {
    const errors: {
      newPassword: string | undefined;
      confirmPassword: string | undefined;
    } = { newPassword: undefined, confirmPassword: undefined };

    if (!values.newPassword) {
      errors.newPassword = translate('dxMessages.auth.new_password_required');
    } else if (!isPasswordStrong(values.newPassword)) {
      errors.newPassword = translate('dxMessages.auth.password_criteria_error');
    } else if (!values.confirmPassword) {
      errors.confirmPassword = translate(
        'dxMessages.auth.confirm_password_required'
      );
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = translate(
        'dxMessages.auth.change_password_match_error'
      );
    }

    return errors;
  };

  const redirectToLogin = () => {
    redirect('/login');
  };

  const onCancel = (values) => {
    redirectToLogin();
  };

  const submit = async (values) => {
    try {
      const response: any = await authProvider.changePassword({
        token: match.params.token,
        password: values.newPassword,
        confirmPassword: values.confirmPassword,
      });

      if (response.status === HttpStatus.UNAUTHORIZED) {
        notify(
          'dxMessages.auth.token_invalid_or_already_used',
          'warning',
          undefined,
          undefined,
          30000
        );
      } else if (response.status === HttpStatus.TOO_MANY_REQUESTS) {
        // request sent too many times
        notify(
          'dxMessages.auth.token_too_many_attempts',
          'warning',
          undefined,
          undefined,
          30000
        );
      } else if (response.status < 200 || response.status >= 300) {
        throw new Error(
          `Error during password change. Http status returned: ${response.status}.`
        );
      } else {
        notify('dxMessages.auth.change_password_confirmation', 'info');
      }

      redirectToLogin();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      notify('dxMessages.auth.change_password_error', 'error');
    }
  };

  // Only set when it's a new account whose password must be configured.
  const login = match.params.login;

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit, pristine, submitting, valid }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <Typography variant='h4'>
              {translate(
                !login
                  ? 'auth.change_password_title'
                  : 'auth.configure_password_title'
              )}
            </Typography>
            {login && (
              <Typography variant='caption'>
                {translate('auth.associatedWithLogin')}:{' '}
                <b style={{ fontSize: 'larger' }}>{login}</b>
              </Typography>
            )}
            <PasswordCriterias />

            <div className={classes.input}>
              <Field
                autoFocus
                id='newPassword'
                name='newPassword'
                component={Input}
                label={translate('dxMessages.auth.new_password')}
                type={showNewPassword ? 'text' : 'password'}
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => SetShowNewPassword(!showNewPassword)}
                        onMouseDown={(event: React.MouseEvent<HTMLElement>) =>
                          event.preventDefault()
                        }
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className={classes.input}>
              <Field
                id='confirmPassword'
                name='confirmPassword'
                component={Input}
                label={translate('dxMessages.auth.confirm_password')}
                type={showConfirmPassword ? 'text' : 'password'}
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={(event: React.MouseEvent<HTMLElement>) =>
                          event.preventDefault()
                        }
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <TopToolbar>
            <Button
              label='dxMessages.buttons.send'
              icon={<SendIcon />}
              type='submit'
              disabled={loading || submitting || pristine || !valid}
              loading={loading}
              color='primary'
            />
            <Button
              className={classes.cancelButton}
              onClick={onCancel}
              label='ra.action.cancel'
              icon={<CancelIcon />}
              disabled={loading || submitting}
              color='secondary'
            />
          </TopToolbar>
        </form>
      )}
    />
  );
};

export default ResetPasswordForm;
