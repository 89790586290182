import { Button } from '@dx-ui/dx-common/src';
import {
  Grid,
  TextField as MuiTextField,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import Delete from '@material-ui/icons/DeleteOutline';
import { Autocomplete } from '@material-ui/lab';
import { cloneDeep, set } from 'lodash';
import MaterialTable, { MTableToolbar } from 'material-table';
import { createRef, useEffect, useState } from 'react';
import { TextInput, Title, useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { ColumnConstants } from '../common/Constants';
import OrderSummaryField from '../common/OrderSummaryField';
import {
  LineStatusCode,
  MT_LOCALIZATION,
  excludeColumn,
  readOnlyColumn,
  validateDeliveryDates,
} from '../common/util/utils';
import InnerTable from './InnerSupplierTable';

const OrderNegotiationSupplierCockpit = (props) => {
  const { record } = props;
  const classes = useStyles();
  const excludedColumns =
    record?.flow?.variables?.negotiationDefinition?.excludedColumns || [];
  const readOnlyColumns =
    record?.flow?.variables?.negotiationDefinition?.readOnlyColumns || [];
  // Checks whether the profile explicitely asks for a button to add delivery info
  const addDelivery: boolean =
    record?.flow?.variables?.negotiationDefinition?.addDelivery || false;
  const extraPeriodInDay: number =
    record?.flow?.variables?.negotiationDefinition?.extraPeriodInDay || 0;
  const [rows, setRows] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [bulkStartDeliveryDate, setBulkStartDeliveryDate] = useState<any>();
  const [bulkEndDeliveryDate, setBulkEndDeliveryDate] = useState<any>();
  const [bulkActualDeliveryDate, setBulkActualDeliveryDate] = useState<any>();

  const theme = useTheme();
  const form = useForm();
  const notify = useNotify();
  const translate = useTranslate();
  const materialTableRef = createRef();

  const select = (row) => {
    setSelectedRow(row);
  };

  useEffect(() => {
    setRows(form.getState().values.flow?.variables?.order?.OrderLine || []);
  }, [form]);

  if (!record) return null;

  // From the InnerTable component, we return the updated delivery information and the line item ID
  const handleChange = (delivery, lineItemId) => {
    const dataUpdate = [...rows];
    dataUpdate[lineItemId].LineItem.Delivery = delivery;
    // Line has been modified
    dataUpdate[lineItemId].LineItem.LineStatusCode = LineStatusCode.UPDATED;
    setRows((oldRows) => (oldRows = [...dataUpdate]));
    form.change(
      'flow.variables.order.OrderLine',
      dataUpdate ? [...dataUpdate] : null
    );
  };

  const validation = async (newRow, oldRow) => {
    let errorFields: string[] = [];

    // Quantity check
    if (!excludeColumn(excludedColumns, ColumnConstants.ORDER_QUANTITY)) {
      if (newRow.LineItem.Quantity.value < 0) {
        errorFields.push(
          translate('dxMessages.task.reginamaria.errorMsg.negative_numbers')
        );
      }

      const initialLine =
        record?.flow?.variables?.order.OrderLine[oldRow.tableData.id];
      const initialQuantityValue = initialLine.LineItem.Quantity.value;
      if (newRow.LineItem.Quantity.value > initialQuantityValue) {
        errorFields.push(
          translate('dxMessages.task.reginamaria.errorMsg.incorrect_quantity')
        );
      }
    }

    // Coming from V3 profile
    const priceTolerance =
      record?.flow?.variables?.negotiationDefinition?.priceTolerancePercent;
    if (
      !excludeColumn(excludedColumns, ColumnConstants.ORDER_PRICE) &&
      priceTolerance
    ) {
      if (priceTolerance?.lessThan >= 0) {
        const lessThanAmount =
          oldRow.LineItem.Price.PriceAmount.value *
          ((100 - priceTolerance.lessThan) / 100);

        if (newRow.LineItem.Price.PriceAmount.value < lessThanAmount) {
          errorFields.push(
            translate(
              'dxMessages.task.reginamaria.errorMsg.price_lower_than_tolerance',
              {
                lessThan: priceTolerance.lessThan,
              }
            )
          );
        }
      }

      if (priceTolerance?.greaterThan >= 0) {
        const greaterThanAmount =
          oldRow.LineItem.Price.PriceAmount.value *
          ((100 + priceTolerance.greaterThan) / 100);

        if (newRow.LineItem.Price.PriceAmount.value > greaterThanAmount) {
          errorFields.push(
            translate(
              'dxMessages.task.reginamaria.errorMsg.price_higher_than_tolerance',
              {
                greaterThan: priceTolerance.greaterThan,
              }
            )
          );
        }
      }
    }

    if (errorFields.length > 0) {
      notify(errorFields.join(', '), 'warning');
      return true;
    }
    return false;
  };

  /**
   * Applies the changes for the dates defined for bulk action
   * @returns
   */
  const bulkChangeDeliveryDates = () => {
    // Check the consistency of the dates defined for bulk operations
    if (
      !validateDeliveryDates(
        bulkStartDeliveryDate,
        bulkEndDeliveryDate,
        bulkActualDeliveryDate,
        extraPeriodInDay,
        notify
      )
    ) {
      return;
    }
    let dataUpdate = [...rows];
    for (let rowIndex = 0; rowIndex < dataUpdate.length; rowIndex++) {
      let lineItem = dataUpdate[rowIndex].LineItem;
      if (!lineItem.Delivery) {
        lineItem.Delivery = [{}];
      }
      if (lineItem.Delivery?.length > 0) {
        for (let index = 0; index < lineItem.Delivery.length; index++) {
          let d = lineItem?.Delivery[index];
          if (
            bulkActualDeliveryDate &&
            lineItem.LineStatusCode !== LineStatusCode.NOT_ACCEPTED
          ) {
            d = {
              ...d,
              ...{
                ActualDeliveryDate: {
                  value: bulkActualDeliveryDate,
                },
              },
            };
            lineItem.LineStatusCode = LineStatusCode.NO_CHANGE;
          }
          if (
            bulkStartDeliveryDate &&
            lineItem.LineStatusCode !== LineStatusCode.NOT_ACCEPTED
          ) {
            d = {
              ...d,
              ...{
                RequestedDeliveryPeriod: {
                  ...d.RequestedDeliveryPeriod,
                  StartDate: { value: bulkStartDeliveryDate },
                },
              },
            };
            lineItem.LineStatusCode = LineStatusCode.UPDATED;
          }
          if (
            bulkEndDeliveryDate &&
            lineItem.LineStatusCode !== LineStatusCode.NOT_ACCEPTED
          ) {
            d = {
              ...d,
              ...{
                RequestedDeliveryPeriod: {
                  ...d.RequestedDeliveryPeriod,
                  EndDate: { value: bulkEndDeliveryDate },
                },
              },
            };
            lineItem.LineStatusCode = LineStatusCode.UPDATED;
          }
          lineItem.Delivery[index] = d;
        }
      }
    }
    setRows((oldRows) => (oldRows = [...dataUpdate]));
    form.change(
      'flow.variables.order.OrderLine',
      dataUpdate ? [...dataUpdate] : null
    );
    notify('dxMessages.task.reginamaria.table.modificationsApplied');
  };

  const cellStyle = (rowData) => {
    return {
      color:
        rowData.LineItem?.LineStatusCode === LineStatusCode.NOT_ACCEPTED
          ? theme.palette.action.disabled
          : null,
      pointerEvents:
        rowData.LineItem?.LineStatusCode !== LineStatusCode.NOT_ACCEPTED
          ? 'inherit'
          : 'none',
    };
  };

  return (
    <>
      <Title
        title={translate('dxMessages.task.reginamaria.table.title', {
          orderId: `${record?.flow?.variables?.order?.ID?.value}`,
        })}
      />
      <Grid container direction='column'>
        <Grid item xs={12} style={{ paddingBottom: '2em' }}>
          <Typography variant='caption'>
            {translate('dxMessages.task.reginamaria.key.infoSupplier')}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <OrderSummaryField
            order={record?.flow?.variables?.order}
            icon={<AddShoppingCart />}
          />
        </Grid>
        <Grid item xs={10}>
          <MaterialTable
            columns={[
              {
                title: translate('dxMessages.task.reginamaria.table.itemId'),
                field: 'LineItem.ID.value',
                editable: 'never',
                hidden: excludeColumn(
                  excludedColumns,
                  ColumnConstants.ORDER_ITEM_ID
                ),
                cellStyle(data: any[], rowData: any): any {
                  return cellStyle(rowData);
                },
              },
              {
                title: translate('dxMessages.task.reginamaria.table.itemDesc'),
                field: 'LineItem.Item.Description[0].value',
                editable: 'never',
                hidden: excludeColumn(
                  excludedColumns,
                  ColumnConstants.ORDER_ITEM_DESC
                ),
                cellStyle(data: any[], rowData: any): any {
                  return cellStyle(rowData);
                },
              },
              {
                title: translate(
                  'dxMessages.task.reginamaria.table.buyerIdentification'
                ),
                field: 'LineItem.Item.BuyersItemIdentification.ID.value',
                editable: 'never',
                hidden: excludeColumn(
                  excludedColumns,
                  ColumnConstants.ORDER_BUYER_ID
                ),
                cellStyle(data: any[], rowData: any): any {
                  return cellStyle(rowData);
                },
              },
              {
                title: translate('dxMessages.task.reginamaria.table.quantity'),
                field: 'LineItem.Quantity.value',
                type: 'numeric',
                hidden: excludeColumn(
                  excludedColumns,
                  ColumnConstants.ORDER_QUANTITY
                ),
                cellStyle(data: any[], rowData: any): any {
                  return cellStyle(rowData);
                },
              },
              {
                title: translate('dxMessages.task.reginamaria.table.unitCode'),
                field: 'LineItem.Quantity.unitCode',
                editable: 'never',
                hidden: excludeColumn(
                  excludedColumns,
                  ColumnConstants.ORDER_UNIT_CODE
                ),
                cellStyle(data: any[], rowData: any): any {
                  return cellStyle(rowData);
                },
              },
              {
                title: translate('dxMessages.task.reginamaria.table.price'),
                field: 'LineItem.Price.PriceAmount.value',
                type: 'numeric',
                hidden: excludeColumn(
                  excludedColumns,
                  ColumnConstants.ORDER_PRICE
                ),
                editable: (columnDef, rowData) =>
                  !readOnlyColumn(readOnlyColumns, ColumnConstants.ORDER_PRICE),
                cellStyle(data: any[], rowData: any): any {
                  return cellStyle(rowData);
                },
              },
              {
                title: translate(
                  'dxMessages.task.reginamaria.table.expirationDate'
                ),
                field: 'LineItem.Item.ItemInstance[0].BestBeforeDate.value',
                type: 'date',
                hidden: excludeColumn(
                  excludedColumns,
                  ColumnConstants.ORDER_BEST_BEFORE_DATE
                ),
                cellStyle(data: any[], rowData: any): any {
                  return cellStyle(rowData);
                },
              },
            ]}
            detailPanel={[
              (rowData) => ({
                ...props,
                render: (rowData: any) => {
                  // rowData is now updated, so changes took place
                  if (
                    !excludeColumn(
                      excludedColumns,
                      ColumnConstants.DELIVERY_TABLE
                    )
                  ) {
                    return (
                      <div style={{ paddingBottom: '2em', paddingLeft: '6em' }}>
                        <InnerTable
                          rowData={rowData}
                          onChange={handleChange}
                          excludedColumns={excludedColumns}
                          readOnlyColumns={readOnlyColumns}
                          addDelivery={addDelivery}
                          extraPeriodInDay={extraPeriodInDay}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                },
              }),
            ]}
            data={rows}
            onRowClick={(evt, row) => select(row)}
            editable={{
              isEditHidden: (rowData) =>
                rowData.LineItem?.LineStatusCode ===
                LineStatusCode.NOT_ACCEPTED,
              onRowUpdate: (newRow, oldRow) =>
                new Promise<void>((resolve, reject) => {
                  setTimeout(async () => {
                    if (await validation(newRow, oldRow)) {
                      return reject();
                    }
                    newRow.LineItem.LineStatusCode = LineStatusCode.UPDATED;
                    const dataUpdate = [...rows];
                    const index = rows.indexOf(oldRow);
                    if (
                      readOnlyColumn(
                        readOnlyColumns,
                        ColumnConstants.DELIVERY_QUANTITY
                      ) &&
                      !excludeColumn(
                        excludedColumns,
                        ColumnConstants.ORDER_QUANTITY
                      )
                    ) {
                      // If the delivered quantity is readOnly and there is only one instance of delivery,
                      // we are in the context where we can update the delivered delivery instance
                      // with the OrderQuantity change
                      if (newRow.LineItem?.Delivery?.length === 1) {
                        set(
                          newRow,
                          'LineItem.Delivery[0].Quantity.value',
                          newRow.LineItem.Quantity.value
                        );
                      }
                    }
                    dataUpdate[index] = newRow;
                    setRows([...dataUpdate]);
                    select(newRow);
                    form.change(
                      'flow.variables.order.OrderLine',
                      dataUpdate ? [...dataUpdate] : null
                    );
                    resolve();
                  }, 1000);
                }),
            }}
            options={{
              showTitle: false,
              search: false,
              filtering: false,
              paging: true,
              padding: 'dense',
              actionsColumnIndex: -1,
              actionsCellStyle: {
                // same actions order as innerTable
                direction: 'rtl',
              },
              pageSize: 10,
              headerStyle: {
                fontWeight: 'bolder',
                borderBottom: '1px solid lightgrey',
              },
              rowStyle: (rowData: any) => ({
                whiteSpace: 'pre-line',
                backgroundColor:
                  selectedRow &&
                  selectedRow.tableData.id === rowData.tableData.id
                    ? theme.palette.action.selected
                    : 'white',
              }),
            }}
            tableRef={materialTableRef}
            actions={[
              (rowData: any) => ({
                icon: 'undo',
                tooltip: translate('dxMessages.task.reginamaria.buttons.undo'),
                hidden:
                  rowData.LineItem.LineStatusCode !==
                  LineStatusCode.NOT_ACCEPTED,
                onClick: (event: any, rowData: any) => {
                  new Promise<void>((resolve, reject) => {
                    setTimeout(async () => {
                      const materialTable: any = materialTableRef.current;
                      let currentRows = cloneDeep(rows);
                      Object.values(currentRows).forEach((item: any) => {
                        // @ts-ignore
                        const rowId = rowData.LineItem.ID.value;
                        if (item.LineItem.ID.value === rowId) {
                          // Remove partial acceptance of line deletion
                          item.LineItem.LineStatusCode =
                            LineStatusCode.NO_CHANGE;
                        }
                      });
                      setRows(currentRows);
                      form.change('flow.variables.order.OrderLine', [
                        ...currentRows,
                      ]);
                      select(rowData);
                      materialTable.setState({
                        ...materialTable.dataManager.getRenderState(),
                      });
                    });
                  });
                },
              }),
              (rowData: any) => ({
                icon: Delete,
                tooltip: translate(
                  'dxMessages.task.reginamaria.table.deleteTooltip'
                ),
                hidden:
                  rowData.LineItem?.LineStatusCode ===
                    LineStatusCode.NOT_ACCEPTED ||
                  rows.filter(
                    (r) =>
                      r.LineItem?.LineStatusCode === LineStatusCode.NOT_ACCEPTED
                  ).length ===
                    rows.length - 1,
                onClick: (event: any, rowData: any) => {
                  new Promise<void>((resolve, reject) => {
                    setTimeout(async () => {
                      const materialTable: any = materialTableRef.current;
                      // Set the line status code as 7 to remove later
                      let currentRows = cloneDeep(rows);
                      Object.values(currentRows).forEach((item: any) => {
                        // @ts-ignore
                        const rowId = rowData.LineItem.ID.value;
                        if (item.LineItem.ID.value === rowId) {
                          // Partial acceptance (this line we be removed if the client accepts)
                          item.LineItem.LineStatusCode =
                            LineStatusCode.NOT_ACCEPTED;
                        }
                      });
                      setRows(currentRows);
                      form.change('flow.variables.order.OrderLine', [
                        ...currentRows,
                      ]);
                      setSelectedRow(rowData);
                      materialTable.setState({
                        ...materialTable.dataManager.getRenderState(),
                      });
                    });
                  });
                },
              }),
            ]}
            components={{
              Toolbar: (props) => {
                if (
                  !excludeColumn(
                    excludedColumns,
                    ColumnConstants.DELIVERY_TABLE
                  )
                ) {
                  return (
                    <div>
                      <Typography variant='h5'>
                        {translate(
                          'dxMessages.task.reginamaria.table.orderTitle'
                        )}
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <Paper
                          variant='outlined'
                          square={false}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            label='dxMessages.task.reginamaria.table.bulkButton'
                            onClick={bulkChangeDeliveryDates}
                            style={{ marginLeft: 4, marginRight: 4 }}
                            disabled={
                              !bulkActualDeliveryDate &&
                              !bulkStartDeliveryDate &&
                              !bulkEndDeliveryDate
                            }
                          />
                          {!excludeColumn(
                            excludedColumns,
                            ColumnConstants.DELIVERY_START_DATE
                          ) &&
                            excludeColumn(
                              excludedColumns,
                              ColumnConstants.DELIVERY_ACTUAL_DATE
                            ) && (
                              <Tooltip
                                title={translate(
                                  'dxMessages.task.reginamaria.table.startDate'
                                )}
                                placement='top'
                              >
                                <Autocomplete
                                  value={bulkStartDeliveryDate}
                                  freeSolo // Always with freeSolo or free value to trigger the onChange.
                                  autoSelect
                                  options={[]}
                                  getOptionSelected={(option, value) =>
                                    option === value
                                  }
                                  onInputChange={(event, val) =>
                                    setBulkStartDeliveryDate(val)
                                  }
                                  className={classes.autoComplete}
                                  renderInput={(params) => {
                                    return (
                                      <MuiTextField
                                        {...params}
                                        label={translate(
                                          'dxMessages.task.reginamaria.table.startDate'
                                        )}
                                        variant='filled'
                                        margin='dense'
                                        type='date'
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </Tooltip>
                            )}
                          {!excludeColumn(
                            excludedColumns,
                            ColumnConstants.DELIVERY_END_DATE
                          ) &&
                            excludeColumn(
                              excludedColumns,
                              ColumnConstants.DELIVERY_ACTUAL_DATE
                            ) && (
                              <Tooltip
                                title={translate(
                                  'dxMessages.task.reginamaria.table.endDate'
                                )}
                                placement='top'
                              >
                                <Autocomplete
                                  value={bulkEndDeliveryDate}
                                  freeSolo // Always with freeSolo or free value to trigger the onChange.
                                  autoSelect
                                  options={[]}
                                  getOptionSelected={(option, value) =>
                                    option === value
                                  }
                                  onInputChange={(event, val) =>
                                    setBulkEndDeliveryDate(val)
                                  }
                                  className={classes.autoComplete}
                                  renderInput={(params) => {
                                    return (
                                      <MuiTextField
                                        {...params}
                                        label={translate(
                                          'dxMessages.task.reginamaria.table.endDate'
                                        )}
                                        variant='filled'
                                        margin='dense'
                                        type='date'
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </Tooltip>
                            )}
                          {!excludeColumn(
                            excludedColumns,
                            ColumnConstants.DELIVERY_ACTUAL_DATE
                          ) && (
                            <Tooltip
                              title={translate(
                                'dxMessages.task.reginamaria.table.actualDeliveryDate'
                              )}
                              placement='top'
                            >
                              <Autocomplete
                                value={bulkActualDeliveryDate}
                                freeSolo // Always with freeSolo or free value to trigger the onChange.
                                autoSelect
                                options={[]}
                                getOptionSelected={(option, value) =>
                                  option === value
                                }
                                onInputChange={(event, val) =>
                                  setBulkActualDeliveryDate(val)
                                }
                                className={classes.autoComplete}
                                renderInput={(params) => {
                                  return (
                                    <MuiTextField
                                      {...params}
                                      label={translate(
                                        'dxMessages.task.reginamaria.table.actualDeliveryDate'
                                      )}
                                      variant='filled'
                                      margin='dense'
                                      type='date'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Tooltip>
                          )}
                        </Paper>
                        <div>
                          <MTableToolbar {...props} />
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <MTableToolbar {...props} />
                    </div>
                  );
                }
              },
            }}
            localization={MT_LOCALIZATION(translate)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            source='input.data.comment'
            label='dxMessages.task.reginamaria.table.comment'
            fullWidth
            multiline
            rows='2'
          />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    paddingBottom: '1.2em',
    width: '13rem',
    marginRight: '1em',
  },
}));
export default OrderNegotiationSupplierCockpit;
