import { makeStyles } from '@material-ui/core/styles';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { colors } from '../../constants/Colors';
import { ProcessStatus } from '../../constants/Constants';
import { DxTheme } from '../../types';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    statusIcon: {
      fontSize: 'medium',
      marginRight: 3,
      verticalAlign: 'middle',
      strokeWidth: 2,
    },
  }),
  { name: 'ProcessStatusIcon' }
);

export const ProcessStatusIcon = (props) => {
  const classes = useStyles();

  switch (props.status) {
    case ProcessStatus.NEW:
    case ProcessStatus.RECEIVED_BY_DX:
    case ProcessStatus.NORMALIZED:
    case ProcessStatus.ACCOUNT_CHECKED:
    case ProcessStatus.ENRICHED:
    case ProcessStatus.VALIDATED:
    case ProcessStatus.LOCALLY_DELIVERED:
    case ProcessStatus.PAID_BY_RECIPIENT:
    case ProcessStatus.PENDING_SCHEDULED_DELIVERY:
    case ProcessStatus.DELIVERED:
    case ProcessStatus.NOTIFIED:
    case ProcessStatus.ACCEPTED_BY_CUSTOMER:
    case ProcessStatus.RECEIVED_BY_CUSTOMER:
    case ProcessStatus.SENT:
    case ProcessStatus.RECEIVED_BY_RECIPIENT:
    case ProcessStatus.ACCEPTED_BY_RECIPIENT:
    case ProcessStatus.RECEIVED_BY_SUPPLIER:
    case ProcessStatus.RECEIVED_BY_BSP:
    case ProcessStatus.RECEIVED_AND_VALIDATED_BY_BSP:
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.functionalGreen,
            stroke: colors.functionalGreen,
          }}
          className={classes.statusIcon}
        />
      );

    case ProcessStatus.DELIVERED_FROM_FORMAT_ERROR:
    case ProcessStatus.FORMAT_ERROR:
    case ProcessStatus.DELIVERED_FROM_CONTENT_ERROR:
    case ProcessStatus.CONTENT_ERROR:
    case ProcessStatus.REJECTED_BY_CUSTOMER:
    case ProcessStatus.REJECTED_BY_RECIPIENT:
    case ProcessStatus.REJECTED_BY_SUPPLIER:
    case ProcessStatus.REJECTED_BY_BSP:
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.red[50],
            stroke: colors.red[50],
          }}
          className={classes.statusIcon}
        />
      );

    case ProcessStatus.DRAFT:
    case ProcessStatus.PENDING_TO_CUSTOMER:
    case ProcessStatus.PENDING_RECADV:
    case ProcessStatus.WAITING_FOR_RECIPIENT:
    case ProcessStatus.PENDING_ENRICHMENTS:
    case ProcessStatus.PENDING_CORRECTION:
    case ProcessStatus.MATCHING_IN_PROGRESS:
    case ProcessStatus.ALLOCATION_IN_PROGRESS:
    case ProcessStatus.PARTIALLY_PAID_BY_RECIPIENT:
    case ProcessStatus.UNDER_QUERY:
    case ProcessStatus.CONDITIONALLY_ACCEPTED:
    case ProcessStatus.IN_PROCESS:
      return (
        <HourglassEmptyIcon
          style={{
            color: colors.functionalOrange,
            stroke: colors.functionalOrange,
          }}
          className={classes.statusIcon}
        />
      );

    case ProcessStatus.SUSPENDED:
    case ProcessStatus.NO_CREDIT:
    case ProcessStatus.MATCHING_ERROR:
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.red[100],
            stroke: colors.red[100],
          }}
          className={classes.statusIcon}
        />
      );

    default:
      return (
        <RadioButtonUncheckedIcon
          style={{ stroke: colors.black05 }}
          className={classes.statusIcon}
        />
      );
  }
};
