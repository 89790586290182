import { Grid, TextField as MUTextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get, has } from 'lodash';
import * as React from 'react';
import { FC } from 'react';
import {
  FieldProps,
  required,
  SelectArrayInputProps,
  SelectInput,
  useInput,
  useTranslate,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { Account } from '../types';

const ConfigurableUsageRight: FC<
  FieldProps<Account> & { features: Record<string, Array<string>> }
> = (props) => {
  const form = useForm();
  // Only trigger a render when the form values change.
  const { values } = useFormState({ subscription: { values: true } });
  const translate = useTranslate();

  const { source, features } = props;

  // features = { 'administration': ['company administrator', 'PSP administrator'], ... }
  // => [{ id: 'administration, name: description }, ...]
  const choices = Object.keys(features).map((featureId) => ({
    id: featureId,
    // Look for the feature description into the i18n domain bundles (i18n/xx-domain.tsx),
    // key: resources.dxfeatures.<featureId>.description, and default to the featureId itself.
    name: translate(`resources.dxfeatures.${featureId}.description`, {
      _: featureId,
    }),
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SelectInput
          source={`${source}.feature.id`}
          label='resources.accounts.create.wizard.steps.usageRightsSelection.feature'
          validate={required()}
          choices={choices}
          optionText='name'
          // Reset the associated roles on feature change.
          onChange={() => form.change(`${source}.roles`, [])}
          fullWidth
          InputProps={{ disableUnderline: true }}
        />
      </Grid>
      <Grid item xs={8}>
        {/* If a feature is selected, display a select box with its roles. */}
        {has(values, `${source}.feature.id`) && (
          <RoleInput
            source={`${source}.roles`}
            featureId={get(values, `${source}.feature.id`)}
            roleIds={features[get(values, `${source}.feature.id`)] || []}
          />
        )}
      </Grid>
    </Grid>
  );
};

const RoleInput: FC<
  SelectArrayInputProps & { featureId: string; roleIds: Array<string> }
> = (props: any) => {
  const translate = useTranslate();
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const { featureId, roleIds } = props;

  const options = roleIds.map((roleId) => ({
    id: roleId,
    // Look for the role description into the i18n domain bundles (i18n/xx-domain.tsx),
    // key: resources.dxfeatures.<featureId>.<roleId>, and default to the roleId itself.
    name: translate(`resources.dxfeatures.${featureId}.${roleId}`, {
      _: roleId,
    }),
  }));

  const firstLetter = (option) => {
    const firstLetter = (option.name[0] || '').toUpperCase();
    return /[0-9]/.test(firstLetter) ? '0-9' : firstLetter;
  };

  const renderInput = (params) => (
    <MUTextField
      {...params}
      name={name}
      variant='standard'
      label={translate(
        'resources.accounts.create.wizard.steps.usageRightsSelection.roles'
      )}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      InputProps={{ ...params.InputProps, disableUnderline: true }}
    />
  );

  // When less than 5 roles, display a simple dropdown list, otherwise a list with grouped
  // entry per first letter.
  if (options.length < 6) {
    return (
      <Autocomplete
        {...rest}
        multiple
        size='small'
        options={options.sort((a, b) => {
          const order = -firstLetter(b).localeCompare(firstLetter(a));
          return order !== 0 ? order : -b.name.localeCompare(a.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(event, val) => onChange(val)}
        getOptionLabel={(role) => options.find((r) => r.id === role.id)?.name}
        renderInput={renderInput}
      />
    );
  } else {
    return (
      <Autocomplete
        {...rest}
        multiple
        size='small'
        options={options.sort((a, b) => {
          const order = -firstLetter(b).localeCompare(firstLetter(a));
          return order !== 0 ? order : -b.name.localeCompare(a.name);
        })}
        groupBy={(option) => firstLetter(option)}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(event, val) => onChange(val)}
        getOptionLabel={(role) => options.find((r) => r.id === role.id)?.name}
        renderInput={renderInput}
      />
    );
  }
};

export default ConfigurableUsageRight;
