import React from 'react';

const DocProcessLogo = (props) => (
  <svg
    id='Monogram'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 263 266'
    width='25'
    height='25'
  >
    <defs />
    <g id='DP'>
      <rect fill='#ff6863' y='114' width='57' height='152' />
      <path
        fill='#ff6863'
        d='M225.26,36.49C201.06,12.27,168.47,0,128.41,0H0V57H128.41c33,0,48.07,11.29,56.56,19.79,8.66,8.66,20.17,23.88,20.17,55.83,0,32.15-11.8,47.32-20.67,55.94-9,8.77-25.13,20.44-60.29,20.44H113v57h11.18c41.94,0,74.64-11.95,100-36.53s38-57,38-96.85C262.12,93.3,249.71,61,225.26,36.49Z'
      />
    </g>
  </svg>
);

export default DocProcessLogo;
