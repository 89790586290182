import { makeStyles } from '@material-ui/core/styles';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { SaveButton, Toolbar, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { ColumnConstants } from '../common/Constants';
import {
  LineStatusCode,
  addEmptyLineForPartialAcceptance,
  computeFinalOrder,
  excludeColumn,
  formatUblLineSupplier,
  validateDeliveryDates,
} from '../common/util/utils';

const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    // justifyContent: "center",
    columnGap: '1em',
  },
});

const useButtonStyles = makeStyles({
  reject: {
    backgroundColor: 'red',
  },
});

type action = 'accept' | 'reject';

// A toolbar to customise the Save & Cancel buttons.
const OrderNegotiationSupplierCockpitToolbar = (props) => {
  const toolbarClasses = useToolbarStyles();
  const buttonClasses = useButtonStyles();
  const form = useForm();
  const translate = useTranslate();
  const { setFormErrors, ...rest } = props;
  const { record } = rest;
  if (!record) return null;

  const buildRequest = (action: action) => (props: any) => {
    return new Promise<void>((resolve, reject) => {
      let orderUpdated: Boolean = false;
      let order = form.getState().values.flow?.variables?.order;
      const excludedColumns =
        record?.flow?.variables?.negotiationDefinition?.excludedColumns || [];
      const extraPeriodInDay =
        record?.flow?.variables?.negotiationDefinition?.extraPeriodInDay || 0;
      const skipBuyerApproval =
        record?.flow?.variables?.negotiationDefinition.skipBuyerApproval ||
        false;
      if (action === 'accept') {
        // Find if the order lines were updated or deleted
        orderUpdated = order.OrderLine.some(function (item) {
          return (
            item.LineItem.LineStatusCode === LineStatusCode.UPDATED ||
            item.LineItem.LineStatusCode === LineStatusCode.NOT_ACCEPTED
          );
        });
        Object.values(order.OrderLine).forEach((item: any) => {
          // Line accepted without modifications
          if (!item.LineItem.LineStatusCode) {
            item.LineItem.LineStatusCode = LineStatusCode.NO_CHANGE;
          }
        });
        // Partial acceptance (lines were deleted)
        const originalOrder = record?.flow?.variables?.order;
        if (
          originalOrder &&
          order.OrderLine.length !== originalOrder.OrderLine.length
        ) {
          orderUpdated = true;
          order = addEmptyLineForPartialAcceptance(originalOrder, order);
        }
        let quantityError = false;
        let missingDeliveryDate = false;
        let incorrectDeliveryDates = false;
        let inconsistentRequestedDeliveryDates = false;
        let errorsToDisplay: string[] = [];
        if (!excludeColumn(excludedColumns, ColumnConstants.DELIVERY_TABLE)) {
          order?.OrderLine?.forEach((item) => {
            if (
              !item.LineItem.Delivery &&
              item.LineItem.LineStatusCode !== LineStatusCode.NOT_ACCEPTED
            ) {
              // auto fill delivery info with header delivery info if exists
              item = formatUblLineSupplier(item, form);
            }
            // Update the new LineItem.Quantity total
            // Exclude all with line status code 7 (deleted line)
            if (
              item &&
              item.LineItem &&
              item.LineItem.LineStatusCode &&
              item.LineItem.LineStatusCode !== LineStatusCode.NOT_ACCEPTED
            ) {
              let value = item.LineItem.Delivery?.map(
                (del) => del?.Quantity?.value
              ).reduce((prev, curr) => prev + curr, 0);

              if (
                !excludeColumn(
                  excludedColumns,
                  ColumnConstants.DELIVERY_QUANTITY
                )
              ) {
                // Check if we are in the case where we can automatically set the Quantity
                if (!value && !item.LineItem.Delivery) {
                  const newValue = item.LineItem.Quantity.value;
                  item.LineItem.Delivery = [
                    {
                      Quantity: { value: newValue },
                    },
                  ];
                  value = newValue;
                  item.LineItem.LineStatusCode = LineStatusCode.UPDATED;
                }
                if (!value && item.LineItem.Delivery?.length === 1) {
                  // A delivery node exists but without Quantity defined.
                  // Enrich it.
                  const newValue = item.LineItem.Quantity.value;
                  item.LineItem.Delivery[0].Quantity = {
                    value: newValue,
                  };
                  value = newValue;
                  item.LineItem.LineStatusCode = LineStatusCode.UPDATED;
                }
                if (value > item.LineItem.Quantity.value) {
                  errorsToDisplay.push(
                    translate(
                      'dxMessages.task.reginamaria.errorMsg.item_quantity',
                      {
                        lineId: item.LineItem.ID.value,
                      }
                    )
                  );
                  quantityError = true;
                }
                if (value < item.LineItem.Quantity.value) {
                  errorsToDisplay.push(
                    translate(
                      'dxMessages.task.reginamaria.errorMsg.item_quantity_lower',
                      {
                        lineId: item.LineItem.ID.value,
                      }
                    )
                  );
                  quantityError = true;
                }
              }
              if (
                !excludeColumn(
                  excludedColumns,
                  ColumnConstants.DELIVERY_ACTUAL_DATE
                )
              ) {
                if (
                  item.LineItem.Delivery.find(
                    (del) =>
                      !del.ActualDeliveryDate || !del.ActualDeliveryDate.value
                  )
                ) {
                  errorsToDisplay.push(
                    translate(
                      'dxMessages.task.reginamaria.errorMsg.actual_date_mandatory_line',
                      {
                        lineId: item.LineItem.ID.value,
                      }
                    )
                  );
                  missingDeliveryDate = true;
                } else {
                  // actual Delivery date has been specified
                  // then validate the dates consistencies
                  incorrectDeliveryDates = item.LineItem.Delivery.some(
                    (del) => {
                      return !validateDeliveryDates(
                        del.RequestedDeliveryPeriod.StartDate.value,
                        del.RequestedDeliveryPeriod.EndDate.value,
                        del.ActualDeliveryDate.value,
                        extraPeriodInDay
                      );
                    }
                  );
                  if (incorrectDeliveryDates) {
                    errorsToDisplay.push(
                      translate(
                        extraPeriodInDay === 0
                          ? 'dxMessages.task.reginamaria.errorMsg.dates_not_in_range_in_line'
                          : 'dxMessages.task.reginamaria.errorMsg.dates_not_in_range_with_extra_in_line',
                        {
                          _: 'Incorrect actual delivery date',
                          extra: extraPeriodInDay,
                          lineId: item.LineItem.ID.value,
                        }
                      )
                    );
                  }
                }
              } else {
                // When no actual dates are required (RM case),
                // the requested delivery Period start and end date are mandatory
                if (
                  item.LineItem.Delivery?.find(
                    (del) =>
                      !del.RequestedDeliveryPeriod ||
                      !del.RequestedDeliveryPeriod?.StartDate?.value ||
                      !del.RequestedDeliveryPeriod?.EndDate?.value
                  )
                ) {
                  errorsToDisplay.push(
                    translate(
                      'dxMessages.task.reginamaria.errorMsg.missing_requested_start_or_end_dates_line',
                      {
                        lineId: item.LineItem.ID.value,
                      }
                    )
                  );
                  inconsistentRequestedDeliveryDates = true;
                }
              }
            }
          });
        }
        if (!orderUpdated) {
          if (!order.OrderDocumentReference) {
            order.OrderDocumentReference = {};
          }
          order.OrderDocumentReference.DocumentStatusCode = 'AP';
          order.OrderDocumentReference.ID = { value: order.ID.value };
        }
        if (
          quantityError ||
          missingDeliveryDate ||
          incorrectDeliveryDates ||
          inconsistentRequestedDeliveryDates
        ) {
          setFormErrors(errorsToDisplay);
          reject();
        }
      }
      if (action === 'reject') {
        if (!props.input?.data?.comment) {
          setFormErrors([
            translate(
              'dxMessages.task.reginamaria.errorMsg.missing_rej_reason'
            ),
          ]);
          reject();
        }
        Object.values(order.OrderLine).forEach((item: any) => {
          item.LineItem.LineStatusCode = LineStatusCode.NOT_ACCEPTED;
        });
        order.OrderTypeCode.value = '320';
        if (!order.OrderDocumentReference) {
          order.OrderDocumentReference = {};
        }
        order.OrderDocumentReference.DocumentStatusCode = 'RE';
        order.OrderDocumentReference.ID = { value: order.ID.value };
      }
      resolve({
        ...rest,
        input: {
          data: {
            updatedOrder:
              !orderUpdated || skipBuyerApproval
                ? computeFinalOrder(order)
                : order,
            orderUpdated: orderUpdated,
            comment: props?.input?.data?.comment,
          },
          action: action,
        },
      });
    });
  };

  return (
    // @ts-ignore
    <Toolbar {...rest} classes={toolbarClasses}>
      <SaveButton
        label='dxMessages.task.reginamaria.toolbar.send'
        icon={<ThumbUpIcon />}
        transform={buildRequest('accept')}
      />
      <SaveButton
        className={buttonClasses.reject}
        label='dxMessages.task.reginamaria.toolbar.reject'
        icon={<ThumbDownIcon />}
        transform={buildRequest('reject')}
      />
    </Toolbar>
  );
};

export default OrderNegotiationSupplierCockpitToolbar;
