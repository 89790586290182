import React from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { Constants } from '../constants';

const useGAPageViews = (locationOverride?: string) => {
  let location = useLocation();
  React.useEffect(() => {
    const sendToGA = () => {
      const path = locationOverride || location.pathname;

      ReactGA.set({ page_title: path }); // Update the user's current page

      let username: string = 'anonymous';
      let company_uuid: string = 'unknown';

      if (typeof localStorage !== 'undefined') {
        username = localStorage.getItem('user') ?? username;

        try {
          const storedValue = localStorage.getItem(
            `${username}.${Constants.COMPANY_UUID}`
          );
          if (storedValue) {
            company_uuid = JSON.parse(storedValue);
          }
        } catch {}
      }

      const measureScriptLoadTime = (callback) => {
        const startTime = performance.now();
        let scriptLoaded = false;
        setTimeout(() => {
          if (!scriptLoaded) {
            scriptLoaded = true;
            const loadTime = performance.now() - startTime;
            callback(loadTime);
          }
        }, 0);
      };

      measureScriptLoadTime((loadTime) => {
        ReactGA.send({
          hitType: 'pageview',
          page: path,
          company_uuid: company_uuid,
          page_load_time: loadTime / 1000,
        });
      });
    };

    sendToGA();
  }, [location, locationOverride]);
};

export default useGAPageViews;
