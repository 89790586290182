import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { stringify } from 'query-string';
import { FC, forwardRef, useCallback } from 'react';
import { useGetIdentity } from 'react-admin';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { Constants, DxTheme, GA_EVENTS, sendGAEvent } from '../..';
import SavedQueriesDeleteBtn from './SavedQueriesDeleteBtn';
import { SavedQuery } from './useSavedQueries';

const useNavLinkRefStyles = makeStyles(
  (theme: DxTheme) => ({
    menuItemLink: { width: 200 },
  }),
  { name: 'NavLinkRef' }
);

const NavLinkRef = forwardRef<
  HTMLAnchorElement,
  NavLinkProps & {
    onRemove?: () => void;
  }
>((props, ref) => {
  const { onRemove, title, ...rest } = props;
  const classes = useNavLinkRefStyles();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const handleRemoveSavedQuery = useCallback(() => {
    sendGAEvent(
      GA_EVENTS.categories.SAVED_QUERIES.name,
      GA_EVENTS.categories.SAVED_QUERIES.actions.DELETE,
      account?.company?.cmsRootDir
    );

    onRemove && onRemove();
  }, [account, onRemove]);

  return (
    <Grid container alignItems='center' wrap='nowrap'>
      <NavLink
        innerRef={ref}
        {...rest}
        className={classnames(rest.className, classes.menuItemLink)}
      />
      <SavedQueriesDeleteBtn
        onRemove={handleRemoveSavedQuery}
        savedQueryName={title || ''}
      />
    </Grid>
  );
});

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      color: theme.palette.text.secondary,
    },
    active: {
      color: theme.colors.mainColor5,
      fontWeight: 700,
      fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
      backgroundColor: theme.colors.lightGrey,
    },
    menuItem: {
      paddingLeft: theme.spacing(4),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
      '&:hover': {
        backgroundColor: theme.colors.lightGrey,
      },
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: 'SavedQueriesMenuItem' }
);

export interface SavedQueriesMenuItemProps {
  classes?: any;
  savedQuery: SavedQuery;
  className?: string;
  onRemove?: () => void;
  onClick?: (event: any) => void;
  tooltipProps?: TooltipProps;
}

const SavedQueriesMenuItem: FC<SavedQueriesMenuItemProps> = forwardRef(
  (props, ref: any) => {
    const {
      classes: classesOverride,
      className,
      savedQuery,
      onClick,
      onRemove,
      tooltipProps,
      ...rest
    } = props;
    const classes = useStyles(props);
    const label = savedQuery.label;
    const location = useLocation();
    const { identity } = useGetIdentity();
    // @ts-ignore
    const account: Account = identity;

    const isSelectedSavedQuery = (resource: string): boolean => {
      if (location.pathname !== `/${resource}`) {
        return false;
      }

      // gets last get list query stored in local storage
      let lastQuery = '';
      if (typeof localStorage !== 'undefined') {
        const username = localStorage.getItem('user');
        lastQuery =
          localStorage.getItem(username + '.' + Constants.DX_LAST_GET_LIST) ||
          '';
      }
      if (lastQuery === '') {
        // unable to discriminate
        return false;
      }

      const query = JSON.parse(lastQuery);

      // Gets the filters info and sanitizes the entry
      const filter = JSON.parse(
        JSON.stringify(query.filter).replace(
          /\+([0-9][0-9]):([0-9][0-9])/g,
          '+$1$2'
        )
      );

      const { field, order } = query.sort;
      const perPage = query.perPage;

      // standard comparison
      if (
        !isEqual(
          filter,
          JSON.parse(
            JSON.stringify(savedQuery.filter).replace(
              /\+([0-9][0-9]):([0-9][0-9])/g,
              '+$1$2'
            )
          )
        )
      ) {
        return false;
      }

      if (field !== savedQuery.sort.field) {
        return false;
      }
      if (order !== savedQuery.sort.order) {
        return false;
      }
      if (perPage !== savedQuery.perPage) {
        return false;
      }

      return true;
    };

    const handleMenuTap = useCallback(
      (e) => {
        sendGAEvent(
          GA_EVENTS.categories.SAVED_QUERIES.name,
          GA_EVENTS.categories.SAVED_QUERIES.actions.SELECT,
          account?.company?.cmsRootDir
        );

        onClick && onClick(e);
      },
      [account, onClick]
    );

    const handleRemoveSavedQuery = useCallback(() => {
      onRemove && onRemove();
    }, [onRemove]);

    const renderMenuItem = () => {
      const search = {
        filter: JSON.stringify(savedQuery.filter),
        sort: savedQuery.sort.field,
        order: savedQuery.sort.order,
        page: 1,
        perPage: savedQuery.perPage,
        displayedFilters: JSON.stringify(savedQuery.displayedFilters),
      };
      return (
        <MenuItem
          className={classnames(classes.root, className, classes.menuItem)}
          component={NavLinkRef}
          ref={ref}
          tabIndex={0}
          to={{
            pathname: `/${savedQuery.resource}`,
            search: stringify(search),
          }}
          {...rest}
          onClick={handleMenuTap}
          onRemove={handleRemoveSavedQuery}
          selected={isSelectedSavedQuery(savedQuery.resource)}
        >
          <ListItemIcon className={classes.icon}>
            <BookmarkIcon titleAccess={label} />
          </ListItemIcon>
          <Typography variant='inherit' noWrap>
            {label}
          </Typography>
        </MenuItem>
      );
    };

    return <Tooltip title={savedQuery.label}>{renderMenuItem()}</Tooltip>;
  }
);

export default SavedQueriesMenuItem;
