import { withStyles } from '@material-ui/core/styles';
import compose from 'lodash/flowRight';
import { DeleteButton, SaveButton, Toolbar } from 'react-admin';
import InvoiceValidationCockpitToolbar from './input/invoicevalidation/InvoiceValidationCockpitToolbar';
import CancellationCockpitToolbar from './input/nwaymatching/cancellation/CancellationCockpitToolbar';
import NwayMatchingCockpitToolbar from './input/nwaymatching/NwayMatchingCockpitToolbar';
import OrderNegotiationClientCockpitToolbar from './input/ordernegotiation/client/OrderNegotiationClientCockpitToolbar';
import OrderNegotiationSupplierCockpitToolbar from './input/ordernegotiation/supplier/OrderNegotiationSupplierCockpitToolbar';
import SaintGobainCockpitToolbar from './input/saintgobain/SaintGobainCockpitToolbar';

const styles = () => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

// A toolbar to customise the Save & Cancel buttons.
const ActionToolbar = (props) => {
  const { setFormErrors, ...rest } = props;
  const { record } = rest;
  if (!record) return null;

  switch (`${record.flow.definitionKey}.${record.definitionKey}`) {
    case 'i2pr_matching.invoiceHoldDecisionTask':
    case 'i2o_matching.invoiceHoldDecisionTask':
      return <NwayMatchingCockpitToolbar {...rest} />;
    case 'simpleMultiLevelInvoiceValidation.invoiceValidationApprovalTask':
      return <InvoiceValidationCockpitToolbar {...rest} />;
    case 'i2pr_matching.cancellationDecisionTask':
    case 'i2o_matching.cancellationDecisionTask':
      return <CancellationCockpitToolbar {...rest} />;
    case 'invoice-waybill-cmr.SG_Validation_User_Task':
    case 'invoice-waybill-cmr.SG_Accounts_Payable_Validation_User_Task':
      return <SaintGobainCockpitToolbar {...rest} />;
    case 'order-negotiation.Supplier_Negotiation_Task':
      // This Component uses setFormErrors to display errors
      return <OrderNegotiationSupplierCockpitToolbar {...props} />;
    case 'order-negotiation.Client_Negotiation_Task':
      return <OrderNegotiationClientCockpitToolbar {...rest} />;
    default:
      // Add a cancel task button if required by the task UI specification.
      return (
        <Toolbar {...rest}>
          <SaveButton />
          {record.uiSpecification.allowCancellation && (
            <DeleteButton undoable={false} />
          )}
        </Toolbar>
      );
  }
};

const enhance = compose(withStyles(styles, { withTheme: true }));
export default enhance(ActionToolbar);
