// The UI displaying an invoice line by line, with a n-way matching status per line.
//   Each line provides an expandable panel with details about its n-way matching.
import { clone, colors, DxTheme } from '@dx-ui/dx-common';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import { get } from 'lodash';
import { FC } from 'react';
import {
  Datagrid,
  ListContextProvider,
  useLocale,
  useTranslate,
} from 'react-admin';
import { renderColumns } from '../CommonColumns';
import InvoiceSummaryField from '../InvoiceSummaryField';
import {
  ExchangeRate,
  FieldProps,
  INVOICE_ICONS,
  Matching,
  MatchingRecadvLine,
  Price,
  RecadvLineSnapshot,
  SuggestedRecadvLine,
  Suggestion,
  Task,
  UblInvoice,
  UblRecadv,
} from '../types';
import { SgrMatchingError } from './I2prNwayMatchingCockpit';

const useStyles: any = makeStyles(
  (theme: DxTheme) => ({
    root: {
      '& > div': {
        boxShadow: 'unset',
      },
    },
    headerCell: {
      padding: '5px 10px',
      verticalAlign: 'middle',
      '&.alignRight': {
        textAlign: 'right',
      },
      '&.alignRight span[role=button]': {
        justifyContent: 'flex-end', // sortable columns
      },
      '&:last-child': {
        padding: '5px 10px',
      },
      '& span[role=button]': {
        verticalAlign: 'unset', // https://docprocess.atlassian.net/browse/DXPOR-349
        display: 'flex',
      },
    },
    row: {
      verticalAlign: 'text-bottom',
    },
    headerRow: {
      '&:hover .stickyCol': {
        backgroundColor: theme.app.tableBkgColor,
      },
    },
    header: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    tableScroll: {
      // // wrapper div css class
      // position: 'relative',
      // width: 'calc(100vw - 370px)',
      // zIndex: 1,
      // overflow: 'auto',
      // '& table': {
      //   borderCollapse: 'separate',
      // },
    },
    warningIcon: {
      marginRight: 3,
    },
  }),
  { name: 'NwayMatchedInvoiceField' }
);

const i18nKey =
  'dxMessages.task.invoice_matching.invoiceHoldDecisionTask.cockpit.';

// The EAN code for SGR taxes.
const SGR_EAN = '9814567890126';

// The invoice, line by line with n-way matching details.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const NwayMatchedInvoiceField: FC<FieldProps<Task<InvoiceFlowVariables>>> = (
  props
) => {
  const { record, locale, resource } = props;
  const translate = useTranslate();
  const classes = useStyles();

  if (record == null) return null;

  const {
    virtualInvoice,
    correctionInvoiceChain,
    rollback,
    completeCluster,
    error,
    matching = {},
    suggestion = {},
    recadvLineSnapshots = [],
    exchangeRates = [],
    sgrTaxesMatched = true,
    sgrTaxesErrors = [],
  } = record.flow.variables;

  // Filter out the line holding the SG taxes.
  const invoiceLines = (virtualInvoice?.InvoiceLine || []).filter(
    (_) => get(_, 'Item.StandardItemIdentification.ID.value') !== SGR_EAN
  );

  const rowByIds: { [key: string]: ExpandPanelInfo } = invoiceLines.reduce(
    (acc, line) => {
      const row: ExpandPanelInfo = clone(line); // Do not modify the orginal UBL unvoice.
      const lineId = get(row, 'ID.value');
      acc[lineId] = row;

      // Set the total value (without VAT)
      // set(column, Ubl.totalWithoutVat, computeTotalWithoutVat(column));

      const lineMatching: Array<MatchingRecadvLine> = get(matching, lineId, []);
      const lineSuggestion: Array<SuggestedRecadvLine> = get(
        suggestion,
        lineId,
        []
      );

      // Compute the quantity of item perfectly matched (right price & line value).
      const matchedQuantity = lineMatching.reduce(
        (acc, recadvLine) => acc + recadvLine.proposal.quantity,
        0
      );

      // Compute the quantity of item imperfectly matched (bad unitary price or line value).
      const suggestedQuantity = lineSuggestion.reduce(
        (acc, recadvLine) => acc + recadvLine.proposal.quantity,
        0
      );

      // Compute the quantity of item imperfectly matched but with the right unitary price.
      const suggestedQuantityWithRightUnitaryPrice = lineSuggestion
        .filter((recadvLine) =>
          recadvLine.proposal.hints.includes('UNITARY_PRICE_OK')
        )
        .reduce((acc, recadvLine) => acc + recadvLine.proposal.quantity, 0);

      // Compute the list of the prices which may be wrong.
      const recadvUnitaryPrices = lineSuggestion.map(
        (line) => line.proposal.unitaryPriceWoGreenTaxAndVat
      );

      // Compute the already invoiced quantity.
      const alreadyInvoicedQuantity = recadvLineSnapshots
        .filter(
          (s) =>
            lineMatching.some(
              (line) =>
                line.proposal.recadvId === s.id &&
                line.proposal.recadvLineId === s.lineId
            ) ||
            lineSuggestion.some(
              (line) =>
                line.proposal.recadvId === s.id &&
                line.proposal.recadvLineId === s.lineId
            )
        )
        .reduce((acc, s) => acc + s.quantity, 0);

      const expectedQuantity = get(row, 'InvoicedQuantity.value');
      row.extension = {
        matched: matchedQuantity === expectedQuantity,
        matchedByQuantity:
          matchedQuantity + suggestedQuantity === expectedQuantity,
        matchedByQuantityAndUnitaryPrice:
          matchedQuantity + suggestedQuantityWithRightUnitaryPrice ===
          expectedQuantity,
        matchedQuantity: matchedQuantity,
        suggestedQuantity: suggestedQuantity,
        suggestedQuantityWithRightUnitaryPrice:
          suggestedQuantityWithRightUnitaryPrice,
        alreadyInvoicedQuantity: alreadyInvoicedQuantity,
        recadvUnitaryPrices: recadvUnitaryPrices,
      };

      return acc;
    },
    {}
  );

  return (
    <div>
      {rollback && (
        <Typography
          variant='caption'
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <WarningIcon style={{ color: colors.brightOrange, fontSize: 20 }} />
          &nbsp;{translate(i18nKey + 'raceConditionOnInvoiceApproval')}
        </Typography>
      )}
      {error && (
        <Typography
          variant='caption'
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <WarningIcon
            style={{
              color: colors.functionalRed,
              fontSize: 20,
            }}
            className={classes.warningIcon}
          />
          <ErrorField error={error} translate={translate} />
        </Typography>
      )}
      {!error && !completeCluster && (
        <Typography
          variant='caption'
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <WarningIcon style={{ color: colors.brightOrange, fontSize: 20 }} />
          &nbsp;{translate(i18nKey + 'incompleteCluster')}
        </Typography>
      )}
      {!sgrTaxesMatched && (
        <Box display='flex' flexDirection='column' mb='2em'>
          <Box>
            <Typography color='error'>
              <ErrorIcon color='error' />
              &nbsp;
              {translate(i18nKey + 'wrongSgrTaxes')}:
            </Typography>
          </Box>
          <Box mt='1em' width='50%'>
            <SgrErrorTable sgrTaxesErrors={sgrTaxesErrors} />
          </Box>
        </Box>
      )}
      {(error || rollback || !completeCluster || !sgrTaxesMatched) && (
        <div style={{ margin: '1.5em' }} />
      )}
      <Grid container direction='column' spacing={8}>
        <Grid item>
          <InvoiceSummaryField
            invoice={virtualInvoice}
            correctionInvoiceChain={correctionInvoiceChain}
            icon={INVOICE_ICONS(virtualInvoice?.InvoiceTypeCode.value, {
              fontSize: 30,
              color: colors.lightPurple,
            })}
          />
        </Grid>
        {exchangeRates && exchangeRates.length > 0 && (
          <div
            style={{ marginTop: '1em', marginRight: '2em', textAlign: 'end' }}
          >
            <Typography variant='caption'>
              {translate(i18nKey + 'usedExchangeRate')}
            </Typography>
            <Grid container direction='column'>
              {exchangeRates.map((rate, i) => (
                <Grid item key={i}>
                  <ExchangeRateField exchangeRate={rate} />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        <Grid item>
          <ListContextProvider
            value={{
              ids: Object.keys(rowByIds),
              data: rowByIds,
              currentSort: { field: 'id', order: 'DESC' },
              resource,
              selectedIds: [],
            }}
          >
            <Datagrid
              classes={{
                row: classes.row,
                rowCell: classes.linesCell,
                headerCell: classes.headerCell,
                tbody: classes.tbody,
              }}
              //@ts-ignore
              expand={<ExpandPanel {...props} />}
            >
              {renderColumns(classes, locale)}
            </Datagrid>
          </ListContextProvider>
        </Grid>
      </Grid>
    </div>
  );
};

// Display the error message.
// error is a stringified NwayMatchingError.
const ErrorField: FC<{ error: string; translate: any }> = ({
  error,
  translate,
}) => {
  try {
    const json = JSON.parse(error);
    const params = json.params || {};
    return (
      <Grid container direction='column'>
        <Grid item>
          <Typography variant='caption'>
            {translate(`${i18nKey}errors.${json.key}`, {
              _: json.message,
              ...params,
            })}
          </Typography>
        </Grid>
        {(json.details || []).map((detail, i) => {
          const params = detail.params || {};
          return (
            <Grid item key={i}>
              <Typography variant='caption' noWrap>
                &bull;&nbsp;
                {translate(`${i18nKey}errors.${detail.key}`, {
                  _: detail.message,
                  ...params,
                })}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  } catch (e) {
    // Should never happen.
    return <Typography variant='caption'>{error}</Typography>;
  }
};

const ExpandPanel: FC<FieldProps<ExpandPanelInfo>> = (props) => {
  const { record } = props;
  const translate = useTranslate();

  if (record == null) return null;

  const info = record.extension;

  const gross = record.Price.PriceAmount.value;
  const greenTax =
    record?.AllowanceCharge?.find((ac) => ac?.ChargeIndicator.value)
      ?.PerUnitAmount.value || 0;
  const discount =
    record?.AllowanceCharge?.find((ac) => !ac?.ChargeIndicator.value)
      ?.MultiplierFactorNumeric.value || 0;

  const unitaryPrice = (
    gross -
    (gross * discount) / 100 +
    greenTax
  ).toLocaleString(undefined, {
    style: 'currency',
    currency: get(record, 'Price.PriceAmount.currencyID'),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const recadvUnitaryPrices = info.matched
    ? unitaryPrice
    : info.recadvUnitaryPrices
        .map((p) =>
          p.value.toLocaleString(undefined, {
            style: 'currency',
            currency: p.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        )
        .join(',') || '--';

  return (
    <Table padding='none'>
      <TableHead>
        <TableRow>
          <TableCell>{translate(i18nKey + 'element')}</TableCell>
          <TableCell>{translate(i18nKey + 'rules')}</TableCell>
          <TableCell>
            {translate('dxMessages.invoices.document_label')}
            <br />
            <span style={{ fontSize: 'xx-small' }}>
              ({translate(i18nKey + 'unitaryNetPriceAlgo')})
            </span>
          </TableCell>
          <TableCell>
            {translate('dxMessages.receiptAdvices.document_label')}
            <br />
            <span style={{ fontSize: 'xx-small' }}>
              ({translate(i18nKey + 'acceptedMinusInvoiced')})
            </span>
          </TableCell>
          <TableCell> {translate(i18nKey + 'match')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key='quantity'>
          <TableCell component='th' scope='row'>
            <span style={{ color: colors.lightPurple, fontWeight: 'bolder' }}>
              {translate('dxMessages.headers.quantity')}
            </span>
          </TableCell>
          <TableCell>{translate(i18nKey + 'quantityRule')}</TableCell>
          <TableCell>{get(record, 'InvoicedQuantity.value')}</TableCell>
          <TableCell>{info.matchedQuantity + info.suggestedQuantity}</TableCell>
          <TableCell>
            {info.matchedByQuantity ? (
              <CheckIcon
                style={{
                  color: colors.functionalGreen,
                  verticalAlign: 'bottom',
                }}
              />
            ) : (
              <ClearIcon
                style={{
                  color: colors.brightOrange,
                  verticalAlign: 'bottom',
                }}
              />
            )}
          </TableCell>
        </TableRow>
        <TableRow key='price'>
          <TableCell component='th' scope='row'>
            <span style={{ color: colors.lightPurple, fontWeight: 'bolder' }}>
              {translate(i18nKey + 'unitaryPrice')}
            </span>
          </TableCell>
          <TableCell>{translate(i18nKey + 'unitaryPriceRule')}</TableCell>
          <TableCell>{unitaryPrice}</TableCell>
          <TableCell>{recadvUnitaryPrices}</TableCell>
          {info.matchedQuantity + info.suggestedQuantity === 0 && (
            <TableCell>N/A</TableCell>
          )}
          {info.matchedQuantity + info.suggestedQuantity !== 0 && (
            <TableCell>
              {info.matched ||
              (info.matchedQuantity + info.suggestedQuantity > 0 &&
                info.suggestedQuantity ===
                  info.suggestedQuantityWithRightUnitaryPrice) ? (
                <CheckIcon
                  style={{
                    color: colors.functionalGreen,
                    verticalAlign: 'bottom',
                  }}
                />
              ) : (
                <ClearIcon
                  style={{
                    color: colors.brightOrange,
                    verticalAlign: 'bottom',
                  }}
                />
              )}
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ExchangeRateField: FC<ExchangeRateFieldProps> = ({ exchangeRate }) => {
  const locale = undefined;

  const toFromRate = Intl.NumberFormat(locale, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  }).format(1 / exchangeRate.value);

  const fromToRate = Intl.NumberFormat(locale, {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  }).format(exchangeRate.value);

  return (
    <div
      style={{
        padding: '5px',
        fontSize: 'smaller',
      }}
    >
      1 {exchangeRate.to} → {toFromRate} {exchangeRate.from}
      <br />
      <span style={{ color: colors.functionalGray }}>
        1 {exchangeRate.from} → {fromToRate} {exchangeRate.to}
      </span>
      <br />
      <span style={{ color: colors.functionalGray }}>
        source: {exchangeRate.source} - {exchangeRate.at}
      </span>
    </div>
  );
};

/**
 * The table holding the SGR error, one row per bad invoice line.
 */
const SgrErrorTable: FC<{ sgrTaxesErrors: SgrMatchingError[] }> = ({
  sgrTaxesErrors,
}) => {
  const translate = useTranslate();
  const locale = useLocale();

  return (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>{translate('dxMessages.headers.number')}</TableCell>
            <TableCell align='right'>SGR</TableCell>
            <TableCell align='right'>
              ∑ {translate('dxMessages.receiptAdvices.title')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...sgrTaxesErrors]
            .sort((e1, e2) => e1.invoiceLineId.localeCompare(e2.invoiceLineId))
            .map((err) => (
              <TableRow
                key={err.invoiceLineId}
                // style={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {err.invoiceLineId}
                </TableCell>
                <TableCell align='right'>
                  {err.invoiceSgrAmount.toLocaleString(locale, {
                    style: 'currency',
                    currency: err.currency,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </TableCell>
                <TableCell align='right'>
                  <Typography color='error' style={{ fontWeight: 'bolder' }}>
                    {err.recadvsSgrAmount.toLocaleString(locale, {
                      style: 'currency',
                      currency: err.currency,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface ExchangeRateFieldProps {
  exchangeRate: ExchangeRate;
}

interface InvoiceFlowVariables {
  companyId: string;
  // The invoice under matching. Always present.
  virtualInvoice: UblInvoice;
  // The correction chain, when virtualInvoice is a corrective invoice.
  correctionInvoiceChain?: Array<UblInvoice>;
  // The recadvs belonging to the cluster.
  recadvs?: Array<UblRecadv>;
  // A flag indicating whether all the recadvs have been retrieved.
  completeCluster?: boolean;
  // A flag indicating whether the invoice was totally matched (all the lines match)
  invoiceMatched?: boolean;
  // The lines that was perfectly matched.
  matching?: Matching;
  // The lines that was imperfectly matched (bad unitary price,... but the item identifications
  // matches)
  suggestion?: Suggestion;
  // The quantities already invoiced on the recadv lines.
  recadvLineSnapshots?: Array<RecadvLineSnapshot>;
  // The currency exchange rates used during the macthing (when recadvs and invoice use different
  // currencies)
  exchangeRates?: Array<ExchangeRate>;
  // A flag indicating that this invoice was accepted previously but another invoice on the same
  // recadv lines was also accepted just before and removed some quantities on the recadv lines
  // that was matched by this invoice: so its acceptation was rollback-ed and its n-way matching
  // re-played.
  rollback: boolean;
  // The stringified JSON error ({key: "<error_id>", message: "<msg in English>", params: ...}).
  error?: string;
  // A flag indicating whether the sum of the SGR taxes on the invoice lines
  // matches the ones on the recadv lines.
  sgrTaxesMatched?: boolean;
  // The details of the errors when sgrTaxesMatched is false.
  sgrTaxesErrors?: SgrMatchingError[];
}

// The data provided to the line of the expandable panel (detail of the line n-way matching).
interface ExpandPanelInfo {
  id: string;
  [key: string]: any; // The UBL invoice.
  extension: {
    matched: boolean; // Perfect match: quantity, unitary price, line value.
    matchedByQuantityAndUnitaryPrice: boolean; // Only matched by quantity and unitary price.
    matchedByQuantity: boolean; // Not by unitary price or line value.
    matchedQuantity: number;
    suggestedQuantity: number;
    suggestedQuantityWithRightUnitaryPrice: number;
    alreadyInvoicedQuantity: number;
    recadvUnitaryPrices: Array<Price>;
  };
}

export default NwayMatchedInvoiceField;
