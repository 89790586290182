/**
 * Displays the count of ongoing tasks for the company
 * the user belongs to.
 */
import { gql, useQuery } from '@apollo/client';
import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import useSetupUsage, {
  SetupUsage,
} from '@dx-ui/dx-common/src/utils/useSetupUsage';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import moment from 'moment';
import { FC } from 'react';
import { useTranslate } from 'react-admin';
import { DxTheme } from '../../../../../types';
import cartouche from '../../cartouche.png';
import cartoucheDark from '../../cartoucheDark.png';
import DemoCount from '../demo/DemoCount';

const I18N_KEY = 'dxMessages.dashboard.widgets.PendingTaskCount';

const PendingTaskCount: FC<IWidgetContent> = ({ onTheShelves, account }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const setupUsage = useSetupUsage();

  return (
    <Card classes={{ root: classes.card }}>
      <div className={classes.main}>
        <Grid container>
          <Grid item xs={3}>
            <Box className='icon'>
              <HourglassEmptyIcon style={{ width: '48px', height: '48px' }} />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-end'
              justifyContent='flex-end'
            >
              <Box
                // Need to put the width to prevent the Box from getting out the Grid.
                style={{ width: '99%' }}
              >
                {/* Double the label in a tooltip in case of truncature. */}
                <Tooltip title={translate(`${I18N_KEY}.Count`)}>
                  <Typography color='textSecondary' noWrap align='right'>
                    {translate(`${I18N_KEY}.Count`)}
                  </Typography>
                </Tooltip>
              </Box>
              <Box display='flex' alignItems='center' justifyContent='flex-end'>
                {/* When on the shelves, do not fetch the real count. */}
                {onTheShelves || setupUsage !== SetupUsage.PRODUCTION ? (
                  <DemoCount day={moment().subtract(1, 'days')} />
                ) : (
                  <Count companyId={account.company.id} />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

const GRAPHQL_REQUEST = gql`
  query TaskMetric($metric: TaskMetricName!, $client: String!) {
    taskMetric(metric: $metric, client: $client) {
      metrics {
        name
        value
      }
    }
  }
`;

const Count = ({ companyId }) => {
  const translate = useTranslate();
  const { loading, error, data } = useQuery(GRAPHQL_REQUEST, {
    variables: {
      metric: 'OpenTasks',
      client:
        process.env.NODE_ENV === 'production'
          ? companyId
          : '001G_eRNgTpdIVOYap0gS5MNfZwiTuYxnmXPblztQgP35G7hlQwR8gv',
    },
  });

  if (error)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          error,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );
  if (loading) return <CircularProgress size='1em' />;

  return (
    <Typography variant='h5' component='h2'>
      {data.taskMetric.metrics.map((_) => _.value).find((_) => true) || '-'}
    </Typography>
  );
};

const useStyles = makeStyles((theme: DxTheme) => ({
  main: {
    padding: 16,
    background: `url(${
      theme.palette.type === 'dark' ? cartoucheDark : cartouche
    }) no-repeat`,
    '& .icon': {
      color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440',
    },
  },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
  },
}));

export default PendingTaskCount;
