import { FlagsProvider as FlaggedFlagsProvider } from 'flagged';
import { FeatureFlagsContext, FeatureFlagsState } from './FeatureFlagsContext';
import useLocalStorageFeatureFlags from './useLocalStorageFeatureFlags';

const InnerFlagsProvider = ({
  features = {},
  children,
}: {
  features?: FeatureFlagsState;
  children: React.ReactNode;
}) => {
  const [flags] = useLocalStorageFeatureFlags(features);

  return (
    <FlaggedFlagsProvider features={flags}>{children}</FlaggedFlagsProvider>
  );
};

export const FlagsProvider = ({
  features = {},
  children,
}: {
  features?: FeatureFlagsState;
  children: React.ReactNode;
}) => {
  return (
    <FeatureFlagsContext.Provider value={features}>
      <InnerFlagsProvider features={features}>{children}</InnerFlagsProvider>
    </FeatureFlagsContext.Provider>
  );
};
