import { isPspAdministrator, useGAPageViews } from '@dx-ui/dx-common/src';
import { unparse as convertToCSV } from 'papaparse';
import {
  CreateButton,
  Datagrid,
  ExportButton,
  List,
  Pagination,
  ReferenceField,
  TextField,
  TopToolbar,
  downloadCSV,
  fetchUtils,
  useNotify,
  usePermissions,
} from 'react-admin';

// interface LastLoginTimeResponse extends RaRecord {
//   // ISO-8601 representation.
//   lastLoginTime: string;
// }

const SuppliersList = (props: any) => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  useGAPageViews();

  const MyPagination = (props) => (
    <Pagination rowsPerPageOptions={[5, 10]} {...props} />
  );

  const exporter: any = (notify) => async (data) => {
    const idsCustomer: any = [];
    const idsSupplier: any = [];
    data.forEach((d: any) => {
      idsCustomer.push(d.idcustomer);
      idsSupplier.push(d.idsupplier);
    });
    const resultCustomer: any = await fetchUtils
      .fetchJson(`/management/company/list`, {
        method: 'POST',
        headers: new Headers({ Accept: 'application/json' }),
        body: JSON.stringify({
          ids: idsCustomer,
          pagination: { page: 1, perPage: 5000 },
          sort: { field: 'id', order: 'ASC' },
          filter: { _start: 0, _end: 5000 },
        }),
      })
      .then((response) => response.json)
      .then((result) => result);

    const resultSupplier: any = await fetchUtils
      .fetchJson(`/management/company/list`, {
        method: 'POST',
        headers: new Headers({ Accept: 'application/json' }),
        body: JSON.stringify({
          ids: idsSupplier,
          pagination: { page: 1, perPage: 5000 },
          sort: { field: 'id', order: 'ASC' },
          filter: { _start: 0, _end: 5000 },
        }),
      })
      .then((response) => response.json)
      .then((result) => result);

    const summariesForExport = data.map((summary, index) => {
      const customerSummary =
        resultCustomer.find((item) => item.id === summary.idcustomer) || {};
      const supplierSummary =
        resultSupplier.find((item) => item.id === summary.idsupplier) || {};
      return {
        id: index + 1,
        customer: customerSummary?.accountname,
        supplier: supplierSummary?.accountname,
        supplierCode4Customer: summary?.supplierCode4Customer,
      };
    });
    const csv = convertToCSV(summariesForExport);
    const BOM = '\uFEFF';
    downloadCSV(`${BOM} ${csv}`, 'management_relations');
  };

  const ListActions = () => {
    return (
      <TopToolbar>
        {isPspAdministrator(permissions) && <CreateButton />}
        <ExportButton exporter={exporter(notify)} maxResults={5000} />
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={false}
      empty={false}
      pagination={<MyPagination />}
      actions={<ListActions />}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        <ReferenceField
          label='dxMessages.companyManagement.accountRelation.customer'
          source='idcustomer'
          reference='management/company'
          link={false}
          sortable={false}
        >
          <TextField source='accountname' />
        </ReferenceField>
        <ReferenceField
          label='dxMessages.companyManagement.accountRelation.supplier'
          source='idsupplier'
          reference='management/company'
          link={false}
          sortable={false}
        >
          <TextField source='accountname' />
        </ReferenceField>
        <TextField
          label='dxMessages.companyManagement.accountRelation.suppliercode4customer'
          source='supplierCode4Customer'
        />
      </Datagrid>
    </List>
  );
};

export default SuppliersList;
