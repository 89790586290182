import {
  CancelButton,
  documentTypeCodeToResource,
  Metadata,
  RegulatorExtraDetailsType,
  useChangeAttachmentsAllowed,
} from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import PreviewIcon from '@material-ui/icons/RemoveRedEye';
import SaveIcon from '@material-ui/icons/Save';
import { get } from 'lodash';
import { useState } from 'react';
import {
  Confirm,
  FormDataConsumer,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { DataHelpers } from '../../../services';
import { P2pData } from '../../types';
import AttachmentsDialog from '../AttachmentsDialog';
import CustomToolbarButton from './CustomToolbarButton';
import SendButton from './SendButton';
import { toolbarStyles } from './styles';

const useStyles: any = makeStyles(toolbarStyles, {
  name: 'DynamicFormToolbar',
});

export interface DynamicFormToolbarProps extends ToolbarProps<any> {
  isReadOnly: any;
  basePath: any;
  onPreview: any;
  onSave: any;
  onSend: any;
  documentSaving: any;
  documentSending: any;
}

const getSpecificResource = (record: P2pData) => {
  const documentTypeCode = get(record.properties, Metadata.documentTypeCode);
  const specificResource = documentTypeCodeToResource(
    documentTypeCode,
    get(record.properties, Metadata.processDocumentFormatType)
  );
  return specificResource;
};

/**
 * Provides the button bar for the form
 */
const DynamicFormToolbar = (props: DynamicFormToolbarProps) => {
  const {
    isReadOnly,
    basePath,
    onPreview,
    onSave,
    onSend,
    documentSaving,
    documentSending,
    record,
    invalid,
  } = props;

  const classes = useStyles();
  const form = useForm();
  const translate = useTranslate();
  const [saveConfirmOpen, setSaveConfirmOpen] = useState(false);
  const [saveData, setSaveData] = useState<any>(undefined);

  const formState = useFormState();
  const onlySubmitErrors: boolean =
    !!formState?.hasSubmitErrors && !formState?.hasValidationErrors;

  const specificResource = getSpecificResource(record);

  const attachmentsAllowed = useChangeAttachmentsAllowed(specificResource);

  const sanitizeProps = ({
    onPreview,
    onSave,
    onSend,
    isReadOnly,
    mutationMode,
    documentSaving,
    documentSending,
    validating,
    ...rest
  }: any) => rest;

  const handleOnSave = async (formData: P2pData) => {
    const formState = form.getState();
    if (formState.invalid) {
      setSaveData(formData);
      setSaveConfirmOpen(true);
    } else {
      if (typeof onSave === 'function') {
        onSave(formData);
      }
    }
  };

  const handleOnSend = async (formData: P2pData) => {
    if (typeof onSend === 'function') {
      return onSend(formData);
    }
  };

  const handleOnPreview = () => {
    if (typeof onPreview === 'function') {
      onPreview();
    }
  };

  const handleSaveConfirmClose = () => {
    setSaveConfirmOpen(false);
  };

  const handleSaveConfirm = () => {
    setSaveConfirmOpen(false);
    if (typeof onSave === 'function') {
      onSave(saveData);
    }
  };

  return (
    <FormDataConsumer>
      {({ formData }) => {
        // TODO : TEMPORARY CODE
        const isWaybill = get(formData.ublProperties, 'Waybill') ? true : false;
        // TEMPORARY CODE
        const isAnafOnly: boolean =
          DataHelpers.regulatorExtraDetails(formData) ===
          RegulatorExtraDetailsType.ANAF_ONLY
            ? true
            : false;
        const recipientId = DataHelpers.getRecipientId(formData);

        return (
          <Toolbar
            {...sanitizeProps(props)}
            classes={{ toolbar: classes.toolbar }}
          >
            {isReadOnly ? (
              <CustomToolbarButton
                label='ra.action.back'
                icon={<EditIcon />}
                {...sanitizeProps(props)}
                onClick={() => handleOnPreview()}
                disabled={documentSaving || documentSending}
              />
            ) : (
              <>
                <CancelButton
                  {...sanitizeProps(props)}
                  label='ra.action.close'
                  to={{
                    pathname: basePath,
                  }}
                  icon={<CancelIcon />}
                  disabled={documentSaving || documentSending}
                  className={classes.toolbarButton}
                />
                <CustomToolbarButton
                  label='ra.action.save'
                  icon={<SaveIcon />}
                  {...sanitizeProps(props)}
                  onClick={() => handleOnSave(formData)}
                  loading={documentSaving}
                  disabled={documentSaving || documentSending}
                />
                <Confirm
                  isOpen={saveConfirmOpen}
                  loading={documentSaving}
                  title={translate('dxMessages.webForm.saveConfirmTitle')}
                  content={translate('dxMessages.webForm.saveConfirmMessage')}
                  onConfirm={handleSaveConfirm}
                  onClose={handleSaveConfirmClose}
                />
                <CustomToolbarButton
                  label='dxMessages.buttons.preview'
                  icon={<PreviewIcon />}
                  {...sanitizeProps(props)}
                  onClick={() => handleOnPreview()}
                  disabled={documentSaving || documentSending}
                />
                {!isWaybill && (
                  <SendButton
                    {...sanitizeProps(props)}
                    documentSending={documentSending}
                    disabled={documentSaving || documentSending}
                    invalid={onlySubmitErrors !== !!invalid}
                    onSend={() => handleOnSend(formData)}
                  />
                )}
                {attachmentsAllowed &&
                  specificResource &&
                  record.id &&
                  recipientId &&
                  !isAnafOnly && (
                    <div className={classes.toolbarRightButtons}>
                      <AttachmentsDialog
                        resource={specificResource}
                        id={record.id}
                      />
                    </div>
                  )}
              </>
            )}
          </Toolbar>
        );
      }}
    </FormDataConsumer>
  );
};

export default DynamicFormToolbar;
