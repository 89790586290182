import FingerprintIcon from '@material-ui/icons/Fingerprint';
import { IdentityProviderType } from '../types';
import docprocessLogo from './docprocess-32x32.png';
import googleLogo from './google-32x32.png';
import { IdentityProviderList } from './IdentityProviderList';

/**
 * The logos and labels of some identity providers.
 */
const LOGO_AND_LABELS: Record<
  string,
  { logo: string; label: string; type: IdentityProviderType }
> = {
  'Doc Process': {
    logo: docprocessLogo,
    label: 'Doc Process',
    type: 'BUILT_IN',
  },
  google: {
    logo: googleLogo,
    label: 'Google',
    type: 'OPEN_ID_CONNECT',
  },
};

const identityProviders = {
  icon: FingerprintIcon,
  list: IdentityProviderList,
};

export { LOGO_AND_LABELS };
export default identityProviders;
