import { DomainMessages } from './DomainMessages';
/**
 * English I18N english messages.
 * In order to not alterate w/ react-admin messages, the application messages must not be defined
 * under ${locale}:ra namespace (where locale = en, ro fr , etc ..).
 * Moreover, ${locale}:resources is the namespace reserved to interact w/ react-admin resources label and title.
 * See : https://marmelab.com/react-admin/Translation.html#translating-resource-and-field-names
 */
export const enDomainMessages: DomainMessages = {
  dxMessages: {
    // English Application messages
    dashboard: {
      categories: {
        general: 'General',
        'nway-matching': 'N-way matching',
        'cash-collection': 'Cash Collection',
      },
      ConfigurationDescription:
        'Customize your dashboard by dragging and dropping widgets from below to the main page, moving them around, and  removing them as needed.',
      ConfigurationSingletonDescription:
        'This icon on the top right corner of some widgets means you can add it ' +
        'more than once on the dashboard, setting different configurations so ' +
        'each one displays different data.',
      DesignYourHomePage: 'Design your home page',
      LearnMore: 'Learn more',
      noAccessRightForDocXchange:
        'You do not own enough access rights to access docXchange. ' +
        'Your administrator may have forgotten to switch it on into your account. ' +
        'Please, contact him if you think you should.',
      NoData: 'No data',
      NoWidgetToAdd: 'No widget to add, all of them are already on screen.',
      OnErrorContactSupport:
        'Oups, an error occured. Please, retry later on or contact the DocProcess ' +
        'support (support@doc-process.com) [%{error}]',
      Welcome: 'Welcome %{firstname}',
      widgets: {
        DocumentReceptions: {
          title: 'Documents, per day and type',
          during: 'Time period',
          NoReception: 'No documents for the selected criteria.',
        },
        InvoiceAcceptanceSummary: {
          AutoApproved: 'Auto. approved',
          ManuallyApproved: 'Manually approved',
          Rejected: 'Rejected',
          Cancelled: 'Cancelled',
          i2pr: 'Invoice/receipt advices matchings',
          i2o: 'Invoice/orders matchings',
          SetEarlierDate: 'Select an earlier date.',
          Since_le: 'Since',
        },
        InvoiceCount: {
          Count: 'Invoice (%{on})',
          On: 'On',
          rejected: '%{count} rej.',
        },
        DocumentCountPerDayAndStatus: {
          INVOICE: {
            title: 'Invoices, per day and status',
          },
          ORDER: {
            title: 'Orders, per day and status',
          },
          RECADV: {
            title: 'Receipt advices, per day and status',
          },
          DESADV: {
            title: 'Despatch advices, per day and status',
          },
          during: 'Time period',
          NoReception: 'No documents for the selected criteria.',
          status: {
            Accepted_by_Customer: 'Accepted',
            Content_valid: 'Content validated',
            Dispute: 'Dispute',
            Format_valid: 'Format validated',
            No_credit: 'No credit',
            No_status: 'Other',
            Outage: 'Outage',
            Pending_Enrichments: 'Pending enrichments',
            Paid_by_recipient: 'Paid',
            Pending_to_Customer: 'Pending on recipient side',
            Received_by_Customer: 'Received',
            Received_by_DX: 'Received by DocProcess',
            Rejected_by_Customer: 'Rejected',
            Received_by_Recipient: 'Received',
            Rejected_by_DX: 'Rejected by DocProcess',
            Sent: 'Sent',
            Transmitted_by_DX: 'Transmitted by DocProcess',
            Matching_in_Progress: 'Matching in progress',
          },
        },
        InvoiceWaitingForDocuments: {
          Count: 'Invoices waiting for documents',
        },
        InvoiceWaitingForApproval: {
          Count: 'Invoices waiting for approval',
        },
        InvoiceWaitingForYourApproval: {
          Count: 'Invoices waiting for your approval',
          overdue: 'overdue',
        },
        OrderCount: {
          Count: 'Orders (%{on})',
          On: 'On',
        },
        PendingTaskCount: {
          Count: 'Pending tasks',
          Since_le: 'Since',
        },
        RecadvCount: {
          Count: 'Receipt advices (%{on})',
          On: 'On',
        },
        DesadvCount: {
          Count: 'Despatch advices (%{on})',
          On: 'On',
        },
        TotalVariationOnAcceptedInvoiceLines: {
          title: 'Total variation on accepted amounts',
          helper:
            'A positive amount means that you paid more than the exact prices.',
          Since: 'Since',
          Since_helper: 'The date when to start computing the differences',
        },
        WelcomeBanner: { LastLogin: 'Your last login' },
        CashCollectionProjectionOverTime: {
          title: 'Projection of cash collected overtime',
          due: 'Due M',
          overdue: 'Overdue',
          amount: 'Amount',
        },
        TotalAmountOwedTopTen: {
          title: 'Total amount owed (top 10 clients)',
          overdue: 'Overdue',
          amount: 'Amount',
          intervals: {
            current: 'due',
            overdue_1_30: 'overdue since 1 - 30 days',
            overdue_31_60: 'overdue since 31 - 60 days',
            overdue_61_90: 'overdue since 61 - 90 days',
            overdue_91_180: 'overdue since 91 - 180 days',
            overdue_beyond_180: 'overdue > 180 days',
          },
        },
        InformBanner: {
          title:
            'List of documents in processing may be different than that shown in chart.',
        },
        DocumentCompany: {
          INVOICE: 'Invoices for',
          ORDER: 'Orders for',
          RECADV: 'Receipt Advices for',
          DESADV: 'Despatch Advices for',
        },
        Tooltip: {
          message: 'click to see details per company',
        },
        Columns: {
          company: 'Company',
          status: 'Status',
          count: 'Count',
        },
        WidgetTitle: {
          name: 'Widget title',
        },
        ListButton: {
          title: 'Table',
        },
        SummaryTitle: {
          title: 'Summary for ',
        },
        Date: {
          month: 'Month',
          year: 'Year',
        },
        AppsWidget: {
          title: 'My applications',
        },
        Display: {
          title: 'Display',
          days: 'Days',
          months: 'Months',
          years: 'Years',
        },
        DateRange: {
          title: 'Date range',
          last7Days: 'Last 7 days',
          last14Days: 'Last 14 days',
          thisMonth: 'This month',
          lastMonth: 'Last month',
          selectMonth: 'Select month',
          thisQuarter: 'This quarter',
          lastQuarter: 'Last quarter',
          thisYear: 'This year',
          lastYear: 'Last year',
          selectYear: 'Select year',
          last3Years: 'Last 3 years',
        },
        TableButton: {
          title: 'Display as table',
        },
        SavedQueries: {
          title: 'My saved queries',
          business: {
            title: 'Business',
          },
        },
        DisplayDocuments: {
          title: 'Recent documents',
          INVOICE: {
            title: 'Latest invoices',
          },
          ORDER: {
            title: 'Latest orders',
          },
          DESADV: {
            title: 'Latest despatch advices',
          },
          RECADV: {
            title: 'Latest receipt advices',
          },
          documentType: {
            INVOIC: 'Invoice',
            ORDERS: 'Order',
            ORDER: 'Order',
            RECADV: 'Receipt Advice',
            DESADV: 'Despatch Advice',
          },
          recipient: 'Recipient',
          issuer: 'Issuer',
          status: 'Status',
          issueDate: 'Issue Date',
          document: 'Document type',
          button: 'open in search',
          clear: 'Clear',
          limitMessage: 'Note: displaying only last 100 documents per type',
        },
      },
    },
    landingPage: {
      empty: {
        click: 'Click',
        drop: 'and drop your widgets',
        openedConfigurationDrop: 'Drop your widgets',
      },
      message: {
        post: 'Posted',
      },
    },
    pspMessage: 'Are you sure you want to sync?',
    catalogList: {
      menu: 'Catalogs',
    },
    catalogEdit: {
      menu: 'Catalogs editing ',
    },
    v2administration: {
      menu: 'V2 Administration',
    },
    peppol: {
      menu: 'PEPPOL',
      accessPoint: {
        title: 'Peppol Participant ID',
      },
      schemaId: {
        title: 'Peppol: Schema ID',
      },
      value: {
        title: 'Peppol: Value',
      },
      company_identification: {
        title: "Recipient's VAT Code",
      },
      company_name: {
        title: "Recipient's Company Name",
      },
      issuer_identification: {
        title: "Issuer's VAT Code",
      },
      country: {
        title: "Recipient's Country",
      },
      error: 'Unable to delete.',
    },
    monitoring: {
      menuMonitoring: 'Monitoring',
      efactura: {
        incidentId: 'Incident Id',
        filename: 'Filename',
        reason: 'Reason',
        status: 'Status',
        userId: 'User Id',
        date: 'Date',
        eventDateStart: 'Event Date Start',
        eventDateEnd: 'Event Date End',
        ignore: 'Ignore',
        supplierNotified: 'Supplier Notify',
        createTask: 'Create Jira Task',
      },
    },
    companyManagement: {
      menu: 'Accounts',
      menuV2: 'User & Company (V2)',
      globalView: 'V2 Global View',
      documentSearch: 'V2 Document Search',
      redirect: 'Go to',
      contractedProducts: 'Contracted products',
      suppliers: 'Suppliers',
      customers: 'customers',
      contacts: 'Contacts',
      account: {
        title: 'Account',
        name: 'Company Name',
        vatCode: 'VAT Code',
        registryNumber: 'Trade Registry number',
        corporateStock: 'Corporate Stock amount',
        uidIdentification: 'UID Identification',
        idstatus: 'State',
        idformattype: 'Invoice input format',
        idformattypedesadv: 'Desadv input format',
        supplierId: 'Supplier Id',
        sync: 'SYNC',
        success: 'Company was synchronized successfully',
        error: 'Error in synchronization',
        isCompanyAdmin: 'Company admin',
        v3Synchronize: 'V3 Synchronize Planned',
        country: 'Country',
        detailsTooltip: 'This option is available only for ANAF companies.',
        detailsTooltipRo: 'This option is supported only in Romania.',
        getDetails: 'get details',
        downloadCsvTemplate: 'Download CSV template',
        processing: 'Processing ...',
      },
      users: {
        userId: 'User Id',
        role: 'User Role',
        title: 'Users',
        help: 'A list with all the roles that can be assigned to the user. A role is available if the company has access to the feature and the user role is assigned.',
        isCompanyAdmin: 'Customer Administrator',
      },
      contact: {
        title: 'Contact',
        lastName: 'Name',
        firstName: 'First Name',
        phone: 'Phone',
        mail: 'Mail',
      },
      dropdowndef: {
        title: 'Dropdown Definition',
        description: 'Description',
        idtype: 'Id Type',
        value: 'Value',
        delete_dropdown_error:
          'Unable to delete, the selected item  is already in use.',
      },
      formattype: {
        comment: 'Comment',
        description: 'Description',
        idDocumentType: 'Id Document Type',
        delete_formattype_error:
          'Unable to delete, the selected format type is already in use.',
      },
      dxrole: {
        name: 'Name',
        adminOnly: 'AdminOnly',
        delete_dxrole_error:
          'Unable to delete, the selected role is already in use.',
      },
      dxProduct: {
        title: 'DX Product',
        description: 'Description',
        comments: 'Comments',
        flgActive: 'flgActive',
        idProductGroup: 'idProductGroup',
        delete_product_error:
          'Unable to delete, the selected item  is already in use.',
      },
      accountRelation: {
        title: 'Account relation',
        customer: 'Customer',
        customerId: 'Customer ID',
        supplier: 'Supplier',
        supplierId: 'Supplier ID',
        suppliercode4customer: 'Supplier code for customer',
        delete_account_error:
          'Unable to delete, the selected account is already in use.',
      },
      addresses: {
        title: 'Addresses',
        addressType: 'Address Type',
        street: 'Street',
        additionalStreet: 'Additional Street',
        buildingNumber: 'Building Number',
        postBox: 'Post Box',
        city: 'City',
        postalCode: 'Postal Code',
        financialAccount: 'IBAN',
        bank: 'Bank',
        gln: 'GLN',
        country: 'Country Code',
        flgBilling: 'Billing Address',
        flgShipping: 'Shipping Address',
        flgLogistic: 'Logistic Address',
      },
      addressBuffer: {
        delete: 'DELETE',
        message: 'Address was synchronized successfully',
      },
      productUsage: {
        product: 'Product',
        title: 'Contracted Products',
        totalusage: 'Total Usage',
        totalcredit: 'Total Credit',
        thresold: 'Threshold',
        advailible: 'Available',
      },
      supportedProducts: {
        product: 'Products',
        title: 'Supported Products',
        delete_supportedproduct_error:
          'Unable to delete, the selected product is already in use.',
      },
      audit: {
        tsChange: 'Last change at',
        userIdChange: 'Last change by',
      },
      commonDocumentColumns: {
        currentState: 'Current State',
        dxuid: 'DXUID',
        sourceCif: 'Source CIF',
        destinationCif: 'Destination CIF',
        originalFileName: 'Original Filename',
        ingestDate: 'Ingest Date',
        ingestDateBefore: 'Ingest Date Before',
        ingestDateAfter: 'Ingest Date After',
        invoiceId: 'Invoice ID',
        orderId: 'Order ID',
        receiptAdviceId: 'Receipt Advice ID',
        despatchAdviceId: 'Despatch Advice ID',
        issuingDate: 'Issuing date',
        issuingDateStart: 'Issuing date start',
        issuingDateEnd: 'Issuing date end',
        customerName: 'Customer',
        supplierName: 'Supplier',
      },
    },
    invoices: {
      title: 'Invoices',
      document_label: 'Invoice',
      edit_title: 'Edit invoice ',
      create_title: 'Create invoice',
      show_title: 'Invoice : ',
      status_history_title: 'Status history',
      payment_method: 'Payment Method',
      payment_means_code: 'Payment means',
      due_date: 'Due Date',
      billing_period: 'Billing Period',
      iban: 'IBAN',
      bank: 'Bank',
      initialInvoice: 'Initial invoice',
      lineId: 'Order line ID',
      lines: 'Lines',
      invoiceNumber: 'Invoice number',
      delivery: 'Delivery',
      note: 'Note',
      accounting_code_code: 'Accounting cost code',
      deliveryDate_IssueDate_error:
        'Delivery date should be inferior or equal to the invoice issue date',
      issueDate_DueDate_error:
        'Due date should be greater or equal to the invoice issue date',
      referenceIssueDate_issueDate_error:
        'Issue date should be greater or equal to the reference invoice issue date',
      issueDate_error: 'Issue date cannot be greater than the current date',
      dateWithoutId_error: "This date can't be set without its related ID",
      fmdlinenote_error:
        'Please add the value for the note as "Invoicing period: yyyyMMdd-yyyyMMdd" \n. Example. Invoicing period: 20200701-20200731',
      sgrWithoutRef_error: 'Please set the "Product ref" field',
      valueWithoutName_error:
        "This value can't be set without its related name",
      orderIdSalesOrderID_error:
        'Please set to "NA" Order ID (BT-13) in that case',
      tax_summary_vat_percent: 'VAT (%)',
      tax_summary_taxable_amount: 'Taxable Amount (%{currencyID})',
      tax_summary_tax_amount: 'Tax amount',
      tax_category_code: 'Tax category code',
      fetchBuyerDetailsButtonLabel: 'Complete the informations',
      fetchBuyerDetailsTooltip:
        'Bring the information about company base on fiscal code',
      taxCategory: {
        standard: '(S) Standard',
        reverse: '(AE) Reverse taxation',
        collection: 'Tax on collection',
        exempt: '(E) Tax exempt',
        freeExport: '(G) Free export item, tax not charged',
        exemptionWithDeductibility: 'Exemption with deductibility',
        exemptWithoutDeduction: 'Exempt without right of deduction',
        zeroVat: '(Z) Zero tax',
        triangleTransaction: 'Triangular transaction',
        proRata: 'Tax normal pro rata',
        specialRegime: 'Special regime (art. 1521, 1522)',
        specialExemption: 'Reg. special exemption art 311 CF',
        shorteningSpec: 'Reg. shortening spec 312 CF',
        purchaseWithInstallation:
          'EU purchases of goods with installation / assembly',
        outsideOfScope: '(O) Services outside scope of tax',
        EEAIntraCommunity:
          '(K) VAT exempt for EEA intra-community supply of goods and services',
        L: '(L) Canary Islands general indirect tax',
        M: '(M) Tax for production, services and importation in Ceuta and Melilla',
        B: '(B) Transferred (VAT), In Italy',
        '00': '(00) 0 %',
        '01': '(01) 6 %',
        '02': '(02) 12 %',
        '03': '(03) 21 %',
        '45': '(45) Reverse charge - Contractor',
        NA: '(NA) Exempt (miscelleanous)',
        FD: '(FD) Financial discount',
        SC: '(SC) Small company',
        '00_44': '(00/44) 0% Clause 44',
        '03_SE': '(03/SE) Standard exchange ',
        MA: '(MA) Margin',
        '46_GO': '(46/GO) ICD Goods',
        '47_TO': '(47/TO) ICD Manufacturing cost',
        '47_AS': '(47/AS) ICD Assembly',
        '47_DI': '(47/DI) ICD Distance',
        '47_SE': '(47/SE) ICD Services',
        '44': '(44) ICD Services B2B',
        '46_TR': '(46/TR) ICD Triangle a-B-c',
        '47_EX': '(47/EX) Export non E.U.',
        '47_EI': '(47/EI) Indirect export',
        '47_EE': '(47/EE) Export via E.U.',
        NS: '(NS) Not subject to VAT invoice/credit note',
        OSS_G: '(OSS-G) OSS Goods',
        OSS_S: '(OSS-S) OSS Services',
        OSS_I: '(OSS-I) OSS Import',
      },
      tax_reason_code: 'Tax reason code',
      taxReasonCode: {
        VATEX_EU_AE:
          '(AE) => VATEX-EU-AE: Reverse charge Supports EN 16931-1 rule BR-AE-10',
        VATEX_EU_D:
          '(E) => VATEX-EU-D: Intra-Community acquisition from second hand means of transport Second-hand means of transport',
        VATEX_EU_F:
          '(E) => VATEX-EU-F: Intra-Community acquisition of second hand goods Second-hand goods',
        VATEX_EU_G:
          '(G) => VATEX-EU-G: Export outside the EU Supports EN 16931-1 rule BR-G-10',
        VATEX_EU_I:
          '(E) =>  VATEX-EU-I: Intra-Community acquisition of works of art Works of art',
        VATEX_EU_IC:
          '(K) => VATEX-EU-IC: Intra-Community supply Supports EN 16931-1 rule BR-IC-10',
        VATEX_EU_O:
          '(O) => VATEX-EU-O: Not subject to VAT Supports EN 16931-1 rule BR-O-10',
        VATEX_EU_J:
          "(E) => VATEX-EU-J: Intra-Community acquisition of collectors items and antiques Collectors' items and antiques",
        VATEX_EU_79_C:
          '(E) => VATEX-EU-79-C: Exempt based on article 79, point c of Council Directive 2006/112/EC',
        VATEX_EU_132:
          '(E) => VATEX-EU-132: Exempt based on article 132 of Council Directive 2006/112/EC',
        VATEX_EU_143:
          '(E) => VATEX-EU-143: Exempt based on article 143 of Council Directive 2006/112/EC',
        VATEX_EU_148:
          '(E) => VATEX-EU-148: Exempt based on article 148 of Council Directive 2006/112/EC',
        VATEX_EU_151:
          '(E) => VATEX-EU-151: Exempt based on article 151 of Council Directive 2006/112/EC',
        VATEX_EU_309:
          '(E) => VATEX-EU-309: Exempt based on article 309 of Council Directive 2006/112/EC',
        VATEX_FR_FRANCHISE:
          '(E) => VATEX-FR-FRANCHISE: France domestic VAT franchise in base',
        VATEX_FR_CNWVAT:
          'VATEX-FR-CNWVAT: France domestic Credit Notes without VAT',
        BETE_45: 'BETE-45: Reverse charge - Contractor',
        BETE_EX: ' BETE-EX: Exempt',
        BETE_FD: 'BETE-FD: Financial discount',
        BETE_SC: 'BETE-SC: Small company',
        BETE_00_44: 'BETE-00/44: 0% Clause 44',
        BETE_03_SE: 'BETE-03/SE: Standard exchange',
        BETE_MA: 'BETE-MA: Margin',
        BETE_46_GO: 'BETE-46/GO: Intra-community supply - Goods',
        BETE_47_TO: 'BETE-47/TO: Intra-community supply - Manufacturing cost',
        BETE_47_AS: 'BETE-47/AS: Intra-community supply - Assembly',
        BETE_47_DI: 'BETE-47/DI: Intra-community supply - Distance',
        BETE_47_SE: 'BETE-47/SE: Intra-community supply - Services',
        BETE_44: 'BETE-44: Intra-community supply - Services B2B',
        BETE_46_TR: 'BETE-46/TR: Intra-community supply - Triangle a-B-c',
        BETE_47_EX: 'BETE-47/EX: Export non E.U.',
        BETE_47_EI: 'BETE-47/EI: Indirect export',
        BETE_47_EE: 'BETE-47/EE: Export via E.U.',
        BETE_NS: 'BETE-NS: Not subject to VAT',
      },
      taxReasonCodeTooltip: {
        VATEX_EU_AE:
          'Reverse charge Supports EN 16931-1 rule BR-AE-10 (VATEX-EU-AE)' +
          ' - Only use with VAT category code AE',
        VATEX_EU_D:
          'Intra-Community acquisition from second hand means of transport Second-hand means of transport (VATEX-EU-D)' +
          ' - Indication that VAT has been paid according to the relevant transitional arrangements - Only use with VAT category code E',
        VATEX_EU_F:
          'Intra-Community acquisition of second hand goods Second-hand goods (VATEX-EU-F)' +
          ' - Indication that the VAT margin scheme for second-hand goods has been applied. - Only use with VAT category code E',
        VATEX_EU_G:
          'Export outside the EU Supports EN 16931-1 rule BR-G-10 (VATEX-EU-G) - Only use with VAT category code G',
        VATEX_EU_I:
          'Intra-Community acquisition of works of art Works of art (VATEX-EU-I)' +
          ' - Indication that the VAT margin scheme for works of art has been applied. - Only use with VAT category code E',
        VATEX_EU_IC:
          'Intra-Community supply Supports EN 16931-1 rule BR-IC-10 (VATEX-EU-IC) - Only use with VAT category code K',
        VATEX_EU_O:
          'Not subject to VAT Supports EN 16931-1 rule BR-O-10 (VATEX-EU-O) - Only use with VAT category code O',
        VATEX_EU_J:
          'Intra-Community acquisition of collectors items and antiques Collector’s items and antiques (VATEX-EU-J)' +
          ' - Indication that the VAT margin scheme for collector’s items and antiques has been applied. - Only use with VAT category code E',
        VATEX_EU_79_C:
          'Exempt based on article 79, point c of Council Directive 2006/112/EC (VATEX-EU-79-C) - Only use with VAT category code E',
        VATEX_EU_132:
          'Exempt based on article 132 of Council Directive 2006/112/EC (VATEX-EU-132) - Only use with VAT category code E',
        VATEX_EU_143:
          'Exempt based on article 143 of Council Directive 2006/112/EC (VATEX-EU-143) - Only use with VAT category code E',
        VATEX_EU_148:
          'Exempt based on article 148 of Council Directive 2006/112/EC (VATEX-EU-148) - Only use with VAT category code E',
        VATEX_EU_151:
          'Exempt based on article 151 of Council Directive 2006/112/EC (VATEX-EU-151) - Only use with VAT category code E',
        VATEX_EU_309:
          'Exempt based on article 309 of Council Directive 2006/112/EC (VATEX-EU-309) - Only use with VAT category code E',
        VATEX_FR_FRANCHISE:
          'France domestic VAT franchise in base (VATEX-FR-FRANCHISE). For domestic invoicing in France',
        VATEX_FR_CNWVAT:
          'France domestic Credit Notes without VAT, due to supplier forfeit of VAT for discount (VATEX-FR-CNWVAT). For domestic Credit Notes only in France',
        BETE_45: 'Reverse charge - Contractor (BETE-45)',
        BETE_EX: 'Exempt (BETE-EX)',
        BETE_FD: 'Financial discount (BETE-FD)',
        BETE_SC: 'Small company (BETE-SC)',
        BETE_00_44: '0% Clause 44 (BETE-00/44)',
        BETE_03_SE: 'Standard exchange (BETE-03/SE)',
        BETE_MA: 'Margin (BETE-MA)',
        BETE_46_GO: 'Intra-community supply - Goods (BETE-46/GO)',
        BETE_47_TO: 'Intra-community supply - Manufacturing cost (BETE-47/TO)',
        BETE_47_AS: 'Intra-community supply - Assembly (BETE-47/AS)',
        BETE_47_DI: 'Intra-community supply - Distance (BETE-47/DI)',
        BETE_47_SE: 'Intra-community supply - Services (BETE-47/SE)',
        BETE_44: 'Intra-community supply - Services B2B (BETE-44)',
        BETE_46_TR: 'Intra-community supply - Triangle a-B-c (BETE-46/TR)',
        BETE_47_EX: 'Export non E.U. (BETE-47/EX)',
        BETE_47_EI: 'Indirect export (BETE-47/EI)',
        BETE_47_EE: 'Export via E.U. (BETE-47/EE)',
        BETE_NS: 'Not subject to VAT (BETE-NS)',
      },
      charge_reason_code: 'Charge reason code',
      chargeReasonCode: {
        AA: 'AA - Advertising',
        AAA: 'AAA - Telecommunication',
        AAC: 'AAC - Technical modification',
        AAD: 'AAD - Job-order production',
        AAE: 'AAE - Outlays',
        AAF: 'AAF - Off-premises',
        AAH: 'AAH - Additional processing',
        AAI: 'AAI - Attesting',
        AAS: 'AAS - Acceptance',
        AAT: 'AAT - Rush delivery',
        AAV: 'AAV - Special construction',
        AAY: 'AAY - Airport facilities',
        AAZ: 'AAZ - Concession',
        ABA: 'ABA - Compulsory storage',
        ABB: 'ABB - Fuel removal',
        ABC: 'ABC - Into plane',
        ABD: 'ABD - Overtime',
        ABF: 'ABF - Tooling',
        ABK: 'ABK - Miscellaneous',
        ABL: 'ABL - Additional packaging',
        ABN: 'ABN - Dunnage',
        ABR: 'ABR - Containerisation',
        ABS: 'ABS - Carton packing',
        ABT: 'ABT - Hessian wrapped',
        ABU: 'ABU - Polyethylene wrap packing',
        ACF: 'ACF - Miscellaneous treatment',
        ACG: 'ACG - Enamelling treatment',
        ACH: 'ACH - Heat treatment',
        ACI: 'ACI - Plating treatment',
        ACJ: 'ACJ - Painting',
        ACK: 'ACK - Polishing',
        ACL: 'ACL - Priming',
        ACM: 'ACM - Preservation treatment',
        ACS: 'ACS - Fitting',
        ADC: 'ADC - Consolidation',
        ADE: 'ADE - Bill of lading',
        ADJ: 'ADJ - Airbag',
        ADK: 'ADK - Transfer',
        ADL: 'ADL - Slipsheet',
        ADM: 'ADM - Binding',
        ADN: 'ADN - Repair or replacement of broken returnable package',
        ADO: 'ADO - Efficient logistics',
        ADP: 'ADP - Merchandising',
        ADQ: 'ADQ - Product mix',
        ADR: 'ADR - Other services',
        ADT: 'ADT - Pick-up',
        ADW: 'ADW - Chronic illness',
        ADY: 'ADY - New product introduction',
        ADZ: 'ADZ - Direct delivery',
        AEA: 'AEA - Diversion',
        AEB: 'AEB - Disconnect',
        AEC: 'AEC - Distribution',
        AED: 'AED - Handling of hazardous cargo',
        AEF: 'AEF - Rents and leases',
        AEH: 'AEH - Location differential',
        AEI: 'AEI - Aircraft refueling',
        AEJ: 'AEJ - Fuel shipped into storage',
        AEK: 'AEK - Cash on delivery',
        AEL: 'AEL - Small order processing service',
        AEM: 'AEM - Clerical or administrative services',
        AEN: 'AEN - Guarantee',
        AEO: 'AEO - Collection and recycling',
        AEP: 'AEP - Copyright fee collection',
        AES: 'AES - Veterinary inspection service',
        AET: 'AET - Pensioner service',
        AEU: 'AEU - Medicine free pass holder',
        AEV: 'AEV - Environmental protection service',
        AEW: 'AEW - Environmental clean-up service',
        AEX: 'AEX - National cheque processing service outside account area',
        AEY: 'AEY - National payment service outside account area',
        AEZ: 'AEZ - National payment service within account area',
        AJ: 'AJ - Adjustments',
        AU: 'AU - Authentication',
        CA: 'CA - Cataloguing',
        CAB: 'CAB - Cartage',
        CAD: 'CAD - Certification',
        CAE: 'CAE - Certificate of conformance',
        CAF: 'CAF - Certificate of origin',
        CAI: 'CAI - Cutting',
        CAJ: 'CAJ - Consular service',
        CAK: 'CAK - Customer collection',
        CAL: 'CAL - Payroll payment service',
        CAM: 'CAM - Cash transportation',
        CAN: 'CAN - Home banking service',
        CAO: 'CAO - Bilateral agreement service',
        CAP: 'CAP - Insurance brokerage service',
        CAQ: 'CAQ - Cheque generation',
        CAR: 'CAR - Preferential merchandising location',
        CAS: 'CAS - Crane',
        CAT: 'CAT - Special colour service',
        CAU: 'CAU - Sorting',
        CAV: 'CAV - Battery collection and recycling',
        CAW: 'CAW - Product take back fee',
        CAX: 'CAX - Quality control released',
        CAY: 'CAY - Quality control held',
        CAZ: 'CAZ - Quality control embargo',
        CD: 'CD - Car loading',
        CG: 'CG - Cleaning',
        CS: 'CS - Cigarette stamping',
        CT: 'CT - Count and recount',
        DAB: 'DAB - Layout/design',
        DAC: 'DAC - Assortment allowance',
        DAD: 'DAD - Driver assigned unloading',
        DAF: 'DAF - Debtor bound',
        DAG: 'DAG - Dealer allowance',
        DAH: 'DAH - Allowance transferable to the consumer',
        DAI: 'DAI - Growth of business',
        DAJ: 'DAJ - Introduction allowance',
        DAK: 'DAK - Multi-buy promotion',
        DAL: 'DAL - Partnership',
        DAM: 'DAM - Return handling',
        DAN: 'DAN - Minimum order not fulfilled charge',
        DAO: 'DAO - Point of sales threshold allowance',
        DAP: 'DAP - Wholesaling discount',
        DAQ: 'DAQ - Documentary credits transfer commission',
        DL: 'DL - Delivery',
        EG: 'EG - Engraving',
        EP: 'EP - Expediting',
        ER: 'ER - Exchange rate guarantee',
        FAA: 'FAA - Fabrication',
        FAB: 'FAB - Freight equalization',
        FAC: 'FAC - Freight extraordinary handling',
        FC: 'FC - Freight service',
        FH: 'FH - Filling/handling',
        FI: 'FI - Financing',
        GAA: 'GAA - Grinding',
        HAA: 'HAA - Hose',
        HD: 'HD - Handling',
        HH: 'HH - Hoisting and hauling',
        IAA: 'IAA - Installation',
        IAB: 'IAB - Installation and warranty',
        ID: 'ID - Inside delivery',
        IF: 'IF - Inspection',
        IR: 'IR - Installation and training',
        IS: 'IS - Invoicing',
        KO: 'KO - Koshering',
        L1: 'L1 - Carrier count',
        LA: 'LA - Labelling',
        LAA: 'LAA - Labour',
        LAB: 'LAB - Repair and return',
        LF: 'LF - Legalisation',
        MAE: 'MAE - Mounting',
        MI: 'MI - Mail invoice',
        ML: 'ML - Mail invoice to each location',
        NAA: 'NAA - Non-returnable containers',
        OA: 'OA - Outside cable connectors',
        PA: 'PA - Invoice with shipment',
        PAA: 'PAA - Phosphatizing (steel treatment)',
        PC: 'PC - Packing',
        PL: 'PL - Palletizing',
        RAB: 'RAB - Repacking',
        RAC: 'RAC - Repair',
        RAD: 'RAD - Returnable container',
        RAF: 'RAF - Restocking',
        RE: 'RE - Re-delivery',
        RF: 'RF - Refurbishing',
        RH: 'RH - Rail wagon hire',
        RV: 'RV - Loading',
        SA: 'SA - Salvaging',
        SAA: 'SAA - Shipping and handling',
        SAD: 'SAD - Special packaging',
        SAE: 'SAE - Stamping',
        SAI: 'SAI - Consignee unload',
        SG: 'SG - Shrink-wrap',
        SH: 'SH - Special handling',
        SM: 'SM - Special finish',
        SU: 'SU - Set-up',
        TAB: 'TAB - Tank renting',
        TAC: 'TAC - Testing',
        TT: 'TT - Transportation - third party billing',
        TV: 'TV - Transportation by vendor',
        V1: 'V1 - Drop yard',
        V2: 'V2 - Drop dock',
        WH: 'WH - Warehousing',
        XAA: 'XAA - Combine all same day shipment',
        YY: 'YY - Split pick-up',
        ZZZ: 'ZZZ - Mutually defined',
      },
      discount_reason_code: 'Discount reason code',
      discountReasonCode: {
        '41': '41 - Bonus for works ahead of schedule',
        '42': '42 - Other bonus',
        '60': "60 - Manufacturer's consumer discount",
        '62': '62 - Due to military status',
        '63': '63 - Due to work accident',
        '64': '64 - Special agreement',
        '65': '65 - Production error discount',
        '66': '66 - New outlet discount',
        '67': '67 - Sample discount',
        '68': '68 - End-of-range discount',
        '70': '70 - Incoterm discount',
        '71': '71 - Point of sales threshold allowance',
        '88': '88 - Material surcharge/deduction',
        '95': '95 - Discount',
        '100': '100 - Special rebate',
        '102': '102 - Fixed long term',
        '103': '103 - Temporary',
        '104': '104 - Standard',
        '105': '105 - Yearly turnover',
      },
      taxes: {
        label: 'Unitary tax',
        SGT_Amount: 'Sugar Tax',
        SGT_Percent: 'Sugar Tax %',
        GT_Amount: 'Green Tax',
        GT_Percent: 'Green Tax %',
      },
      commercialInvoice: '380 - Commercial invoice',
      cancelInvoice: '381 - Cancel invoice',
      correctiveInvoice: '384 - Corrective invoice',
      selfbillingInvoice: '389 - Self-billing invoice',
      accountingInvoice: '751 - Invoice information for accounting purposes',
      missingExchangeRate: 'Please set currency exchange rate as well',
      missingTargetCurrencyCode: 'Please set target currency code as well',
      applyExchangeRateButtonLabel: 'Apply Exchange Rate',
      applyExchangeRateTooltip: 'Apply exchange rate on all invoice lines',
      efactura: {
        efactura_choice_label: 'e-Factura options',
        efactura_send: 'send to e-Factura',
        efactura_no_send: 'No e-Factura',
        efactura_only: 'only e-Factura',
      },
      custom: {
        multimedia: {
          internal_transaction: 'Internal transaction',
          intra_community: 'Intra-community purchase',
          import_services: 'Import services',
          transport_invoice: 'Transport invoice chargeable on DVI',
        },
      },
      businessProcessType: {
        '1': 'Merchandise',
        '2': 'Services or other acquisitions',
        '3': 'Trade and financial discounts',
      },
      taxRepresentative: 'Tax representative',
      documentLevelCharges: 'Document level charges',
      documentLevelDiscounts: 'Document level discounts',
      primaryAccountNumberID: 'Primary account number of card',
      orderReferenceSalesOrderId: 'Sales order reference',
    },
    customers: {
      title: 'Customers',
    },
    issuers: {
      title: 'Issuers',
    },
    role: {
      meniu: 'Roles',
      domain: 'Domain',
      document: 'Documents',
    },
    despatchAdvices: {
      title: 'Despatch Advices',
      document_label: 'Despatch Advice',
      edit_title: 'Edit Despatch Advice ',
      create_title: 'Create Despatch Advice',
      show_title: 'Despatch Advice : ',
      number: 'Nr : ',
      despatchAdviceNumber: 'Despatch Advice number',
      shippingContactName: 'Contact name',
      shippingContactPhone: 'Contact telephone',
      shippingContactEmail: 'Contact electronic mail',
      despatchSupplierPartyID: 'Despatch supplier ID',
      shipmentId: 'Shipment ID',
      shipment: 'Shipment',
      grossVolumeMeasure: 'Gross volume',
      grossVolumeMeasureUnitCode: 'Measure unit',
      netVolumeMeasure: 'Net volume',
      netVolumeMeasureUnitCode: 'Measure unit',
      totalNumberofGoods: 'Total number of goods',
      totalNumberofGoodsUnitCode: 'Unit code',
      customsDeclaredValueAmount: 'Declared customs value amount',
      customsDeclaredValueAmountCurrency: 'Currency',
      specialInstructions: 'Special instructions',
      exportCountryCode: 'Export country',
      shipmentStageId: 'Shipment stage ID',
      transportModeCode: 'Transport mode code',
      transportMeansTypeCode: 'Transport means for shipment stage',
      transportMeansTypeCodeListId: 'Transport means identification list',
      carrierCode: 'Carrier code',
      carrierName: 'Carrier name',
      trackingId: 'Shipment tracking ID',
      actualDeliveryDate: 'Actual delivery date',
      actualDeliveryTime: 'Actual delivery time',
      requestedDeliveryDate: 'Requested delivery date',
      requestedDeliveryTime: 'Requested time',
      estimatedArrivalDate: 'Estimated arrival date',
      estimatedArrivalTime: 'Estimated arrival time',
      handlingUnitTypeCode: 'Handling unit measurement unit',
      handlingUnitShippingMarks: 'Type of marking',
      handlingUnitPackageQuantity: 'Number of packages',
      handlingUnitPackageQuantityUnitCode: 'Measure unit',
      handlingUnitPackageReturnableMaterialIndicator:
        'Package type (returnable or not)',
      handlingUnitPackagePackingLevelCode: 'Packaging level code',
      handlingUnitPackagePackagingTypeCode: 'Type of package identification',
      missingLineOrderIDref: 'Please set Order ID',
      logisticOnly: 'Logistic Only',
      logisticOnlyMessage:
        'This is a logistic despatch advice, so it will not reach the buyer via EDI channel. The only purpose for this is to allow the despatch to be issued and printed',
    },
    receiptAdvices: {
      title: 'Receipt Advices',
      document_label: 'Receipt Advice',
      document_label_return: 'Goods return advice',
      document_label_ack: 'Acknowledgement of receipt',
      receiptAdviceSubType: '632 - Receipt Advice',
      goodsReturnSubType: '35E - Goods return advice',
      ackReceiptSubType: '352 - Acknowledgement of receipt',
      palletReceiptNotice: '352 - Pallet receipt notice',
      create_title: 'Create Receipt Advice',
      edit_title: 'Edit Receipt Advice',
      create_goods_return_title: 'Create Goods Return',
      edit_goods_return_title: 'Edit Goods Return',
      show_title: 'Receipt Advice : ',
      number: 'Nr : ',
      goodsReturnID: 'Goods return ID',
      receiptAdviceID: 'Receipt advice ID',
      goodsReturnIssueDate: 'Issue date',
      receiptAdviceType: 'Receipt advice type',
      goodsReturnReason: 'Goods return reason',
      licensePlateId: 'License plate ID',
      productService: 'Product / Service',
      customerContact: 'Customer contact',
      supplierContact: 'Supplier contact',
      returnReasonAlteredMerchandise: 'Altered Merchandise',
      returnReasonMissingMerchandise: 'Missing Merchandise',
      returnReasonExtraQuantity: 'Extra Quantity',
    },
    feedbackMessages: {
      title: 'Messages',
      document_label: 'Message',
      edit_title: 'Edit Message ',
      show_title: 'Message : ',
      no_docs: 'No messages',
    },
    linkedDocuments: {
      no_docs: 'No documents',
    },
    attachments: {
      title: 'Attachments',
      document_label: 'Attachment',
      edit_title: 'Edit Attachment ',
      show_title: 'Attachment : ',
      no_docs: 'No attachments',
    },
    orders: {
      title: 'Orders',
      document_label: 'Order',
      edit_title: 'Edit Order ',
      create_title: 'Create order',
      show_title: 'Order : ',
      orderNumber: 'Order number ',
      initialOrder: '105 - Initial order',
      updatedOrder: '231 - Updated order',
      updatedOrderNotModified: '320 - Acknowledgement of order',
      finalOrder: '220 - Final order',
      crossDocking: '50E - Cross-docking',
      goodsReturn: '70E - Goods return',
      orderType: 'Order type',
    },
    forecast: {
      title: 'Forecasts',
      document_label: 'Forecast',
      edit_title: 'Edit Forecast ',
      show_title: 'Forecast : ',
    },
    contract: {
      title: 'Contract',
      document_label: 'Contract',
      edit_title: 'Edit Contract ',
      show_title: 'Contract : ',
    },
    catalog: {
      title: 'Catalogs',
      document_label: 'Catalog',
      edit_title: 'Edit Catalog ',
      show_title: 'Catalog : ',
    },
    apm: {
      title: 'Head over to the Expense Classification Cockpit to see this task',
      subheader: 'Please talk to your sales advisor for more information',
    },
    apmValidation: {
      title: 'Head over to the Validation Cockpit to see this task',
      subheader: 'Please, approve or reject the invoice below:',
      open_cockpit: 'Open the cockpit',
    },
    waybill: {
      title: 'CMRs (all)',
      document_label: 'CMR',
      document_sublabel:
        'Convention on the Contract for the International Carriage of Goods by Road',
      edit_title: 'Edit CMR',
      create_title: 'Create CMR',
      show_title: 'CMR : ',
      number: 'Document number',
      carrierParty: 'CARRIER PARTY',
      consigneeParty: 'CONSIGNEE PARTY',
      consignorParty: 'CONSIGNOR PARTY',
      freightForwarderParty: 'FREIGHT FORWARDER PARTY',
      note: 'Note',
      partyTile: {
        vatIdentification: 'VAT identification',
        name: 'Name',
        street: 'Street',
        additionalStreet: 'Additional street',
        number: 'Street/building no',
        city: 'City',
        postalCode: 'ZIP code',
        countrySubEntityCode: 'County code',
        countryCode: 'Country code',
        contactName: 'Contact',
        phone: 'Phone',
        email: 'Email',
      },
      loadingTable: {
        serviceDescription: 'Transport type',
        loadingNumber: 'Loading number',
        licensePlate: 'Vehicle(s) license plate',
        marksAndNumber: 'Marks and numbers',
        packageNumber: 'Number of packages',
        packingMethod: 'Method of packing',
        goodsNature: 'Nature of goods',
        remarks: 'Remarks',
        loadingLocation: 'LOADING LOCATION',
        unloadingLocation: 'UNLOADING LOCATION',
        loadingDate: 'Loading date',
        unloadingDate: 'Unloading date',
        costs: {
          transportCostBeforeFees: 'Transport cost before fees',
          transportFees: 'Transport fees',
          transportCostIncludingFees: 'Total transport cost including fees',
        },
        addressName: 'Address name',
      },
      serviceDescription: {
        glassSale: 'Glass sale',
        glassAcquisition: 'Glass acquisition',
        culletsReturn: 'Cullets return',
        shelvesReturn: 'Shelves return',
        fees: 'Fees',
      },
      issueDate_loadingDate_error:
        'Should be less or equal to invoice issue date',
      unloadingDate_loadingDate_error:
        'Should be greater or equal to loading date',
    },
    dxDocuments: {
      title: 'All Documents',
      document_label: 'Document',
      edit_title: 'Edit Document ',
      show_title: 'Document : ',
    },
    state: {
      completed: 'Completed',
      suspended: 'Suspended',
      active: 'Active',
    },
    appSwitcher: {
      DocProcess: {
        label: 'Manage your documents and business workflows',
      },
      DxArchive: {
        label: 'Archive your documents',
      },
      DxCatalog: {
        label: 'Manage your product catalogs',
      },
      ExpenseClassificationCockpit: {
        label: 'Allocate expenses to your invoices',
      },
      ReconciliationCockpit: {
        label: 'Manage your Accounts Payable',
      },
      DxContract: {
        label: 'Manage your contracts',
      },
      DxUpload: {
        label: 'DocProcess smart connector',
      },
      EtransportCockpit: {
        label: 'Declare your road transport in Romania',
      },
      C4Cockpit: {
        label: 'Manage your efactura documents',
      },
    },
    user_menu: {
      settings: 'Settings',
      language: 'Language',
      select_language: 'Select a language',
      account_preferences: 'My Account',
      AccountNotFound: 'Account not found!',
      AccountToImpersonate: 'Account to impersonate',
      AccountToImpersonateHelper: 'email or login <ENTER>',
      SwitchCompany: 'Switch company',
    },
    help_menu: {
      title: 'Help Menu',
      userGuide: 'User Guide',
      feedbacks: 'Give feedback',
      mailto: 'mailto:feedback@doc-process.com?subject=Feedback on %{DxApp}',
    },
    menu: {
      home: 'Home',
      documents: 'Documents',
      create: 'Document creation',
      administration: 'Administration',
      tasks: 'Tasks',
      tooltip: 'Documents view',
    },
    buttons: {
      unselect: 'Unselect',
      preview: 'Preview',
      more: 'More ...',
      related_documents: 'Related Documents and Messages',
      download: 'Download',
      ediDocument: 'EDI Document',
      createNewDocument: 'Create',
      cloneInvoice: 'Clone the invoice',
      cancelInvoice: 'Cancel the invoice',
      correctiveInvoice: 'Correct the invoice',
      send: 'send',
      convertToInvoice: 'Convert to invoice',
      convertToDespatchAdvice: 'Convert to despatch advice',
      convertToReceiptAdvice: 'Convert to receipt Advice',
      editInvoice: 'Edit invoice',
      editDespatchAdvice: 'Edit despatch advice',
      editWayBill: 'Edit CMR',
      editReceiptAdvice: 'Edit receipt advice',
      editGoodsReturn: 'Edit goods return',
      editOrder: 'Edit order',
      cloneWayBill: 'Clone the CMR',
      cloneDespatchAdvice: 'Clone the Despatch advice',
      cloneDocument: 'Clone document',
      appsSwitcher: 'Applications switcher',
      messagesCenter: 'Messages center',
    },
    inputs: {
      min_input_length: 'Minimum %{minLength} characters',
      add_new_entry: 'Add "%{entry}"',
    },
    error_messages: {
      must_be_greater: 'Must be greater than %{target}',
      phone_number_invalid: 'Phone number is invalid',
      generic_error:
        'An unexpected error has occurred. Please try again later.',
      form_load_error: 'An error occurred while loading form data',
      form_save_error: 'An error occurred while saving form data',
      form_invalid_template:
        'The form could not be displayed. Please contact the support.',
      max_decimals: 'Please use a maximum of %{decimals} decimals',
      type_number: 'Value must be a number',
      unable_to_display: 'Document not yet available. Please retry later.',
      quantities_use_same_sign:
        'Quantities should be all positive or all negative',
      non_zero: 'Value cannot be equal to 0',
      negative: 'Value must be negative.',
      invalid_DXUID: 'invalid DXUID',
      invalid: 'Invalid',
      fixed_length: 'Must contain exactly %{fixedLength} digits',
      line_error: 'This line contains one or more errors.',
      OnError_contact_the_support:
        'Oups, an error occured. Please, retry later on or contact the DocProcess ' +
        'support (support@doc-process.com) [%{error}]',
      address_already_exist: 'Address already exist for this GLN',
      company_already_exists: 'The company name or the VAT code already exist.',
      company_info_not_found: 'Company information not found',
      email_already_exists: 'This email address is already registered.',
      product_usage_already_exists: 'This product is already registered.',
      impossible_delete_company:
        'Impossible to delete company, dependencies need to be removed first',
      vat_not_found: 'Warning, VAT code not found in public directory',
      not_enough_rights: 'Not enough rigths for this operation',
      invalid_character: 'Invalid character: %{unwanted}',
      fieldTobeSet: '%{label} needs to be also set',
    },
    processStatus: {
      ACCEPTED_BY_CUSTOMER: 'Accepted by recipient',
      ACCEPTED_BY_RECIPIENT: 'Accepted by recipient',
      ACCOUNT_CHECKED: 'Received by DocProcess',
      ALLOCATION_IN_PROGRESS: 'Allocation in progress',
      CONTENT_ERROR: 'Content error',
      DELIVERED: 'Sent',
      DELIVERED_FROM_CONTENT_ERROR: 'Content error',
      DELIVERED_FROM_FORMAT_ERROR: 'Format error',
      ENRICHED: 'Received by DocProcess',
      FORMAT_ERROR: 'Format error',
      LOCALLY_DELIVERED: 'Received by DocProcess',
      MATCHING_ERROR: 'Matching failed',
      MATCHING_IN_PROGRESS: 'Matching in progress',
      NEW: 'Received by DocProcess',
      NO_CREDIT: 'No credit',
      NORMALIZED: 'Received by DocProcess',
      NOTIFIED: 'Sent',
      PAID_BY_RECIPIENT: 'Paid by recipient',
      PARTIALLY_PAID_BY_RECIPIENT: 'Partially paid by recipient',
      PENDING_ENRICHMENTS: 'Pending enrichments',
      PENDING_RECADV: 'Pending enrichments',
      PENDING_SCHEDULED_DELIVERY: 'Sent',
      PENDING_TO_CUSTOMER: 'Waiting for recipient',
      PENDING_CORRECTION: 'Pending correction',
      RECEIVED_BY_CUSTOMER: 'Received by recipient',
      RECEIVED_BY_DX: 'Received by DocProcess',
      RECEIVED_BY_RECIPIENT: 'Received by recipient',
      RECEIVED_BY_SUPPLIER: 'Received by supplier',
      REJECTED_BY_CUSTOMER: 'Rejected by recipient',
      REJECTED_BY_RECIPIENT: 'Rejected by recipient',
      REJECTED_BY_SUPPLIER: 'Rejected by supplier',
      RECEIVED_BY_BSP: 'Received by Service Provider',
      RECEIVED_AND_VALIDATED_BY_BSP:
        'Received and validated by Service Provider',
      REJECTED_BY_BSP: 'Rejected by Service Provider',
      UNDER_QUERY: 'Pending info',
      IN_PROCESS: 'In Process',
      CONDITIONALLY_ACCEPTED: 'Conditionally accepted',
      SENT: 'Sent',
      SUSPENDED: 'No Credit',
      VALIDATED: 'Received by DocProcess',
      WAITING_FOR_RECIPIENT: 'Waiting for recipient',
      DRAFT: 'Draft',
      openAuditTrail: {
        tooltip:
          'Click to open the processing details drawer and have more information on the document status',
      },
    },
    readStatus: {
      NEW: 'New',
      DOWNLOADED: 'Downloaded',
      READ: 'Read',
      CONVERTED: 'Converted',
    },
    invoiceTypes: {
      FAM: 'FAM : Merchandise selfinvoice',
      FAS: 'FAS : Services selfinvoice',
      FCG: 'FCG : General expenses invoice',
      FIM: 'FIM : Assets invoice',
      FMD: 'FMD : Discount invoice',
      FME: 'FME : External merchandise invoice',
      FMF: 'FMF : Merchandise invoice',
      FSR: 'FSR : Service invoice',
      FGO: 'FGO : General expenses invoice with order',
    },
    processDocumentFormatTypes: {
      ORIGINAL: 'Original',
      ISSUER_ORIGINAL: 'Original',
      IMAGE: 'Image',
      ISSUER_IMAGE: 'Image',
      RECIPIENT_IMAGE: 'Image',
      TARGET: 'Target',
      DX: 'UBL',
    },
    archivePeriod: {
      1: '1',
      10: '10',
      Permanent: 'Permanent',
    },
    documentTypeCode: {
      APERAK: 'Message',
      CATLOG: 'Catalog',
      CNTCND: 'Contract',
      DESADV: 'Despatch Advice',
      DELFOR: 'Forecast',
      INVOIC: 'Invoice',
      INVOICE: 'Invoice',
      ORDERS: 'Order',
      ORDER: 'Order',
      RECADV: 'Receipt Advice',
      ATTACH: 'Attachment',
      WAYBIL: 'CMR',
    },
    regulatorExtraDetails: {
      efacturaOnly: 'Fiscal authority only',
    },
    regulatorStatusCode: {
      '0': 'Accepted',
      '10': 'Rejected',
      '20': 'Fiscal authority internal error',
      '40': 'Pending',
    },
    taxPointDateCode: {
      '3': 'Invoice issue date',
      '35': 'Actual date of delivery',
      '432': 'Amount paid on the same day',
    },
    headers: {
      accountingCost: 'Accounting cost',
      companyLegalForm: 'Company legal form',
      id: 'ID',
      manufacturersItemIdentification: 'Manufacturers Code',
      transactionType: 'Transaction type',
      packageSequence: 'Package sequence ID',
      numberOfPackages: 'Number of packages',
      packingLevelCode: 'Package sequence',
      packagingTypeCode: 'Type of package',
      certNumberBioProduct: 'Prod cert no for BIO',
      deliveryNoteReferenceNumber: 'Delivery Note number',
      packageID: 'Package ID (SSCC)',
      packageIDC: 'SSCC code',
      packageMarkerID: 'Package marker ID',
      shippingContainer: 'Serial shipping container',
      bestBefore: 'Best Before',
      expiryDate: 'Expiry Date',
      lotNumberID: 'Batch number',
      tradeCode: 'Trade register number',
      supplierBank: 'Supplier Bank',
      clientBank: 'Client Bank',
      packingType: 'Type of packaging',
      UmUnitPriceBase: 'Base measure unit for unit price',
      unitPriceBase: 'Basis for the unit price',
      deliveryQuantityCode: 'Delivery quantity code',
      deliveryQuantity: 'Delivered quantity',
      unitsNumber: 'Number of units per box',
      invoiceTypeCode: 'Invoice Type Code',
      duration: 'Duration',
      periodType: 'Period Type',
      referenceReporting: 'Reference Reporting',
      referenceEvent: 'Reference Event',
      paymentDeadline: 'Payment Deadline',
      paymentMeansCode: 'Payment Type UN/ECE 4461',
      paymentMeansID: 'Payment Term (TP)',
      addition: 'Additional Information',
      customer: 'Customer',
      customerId: 'Customer ID',
      status: 'Status',
      documentName: 'File name',
      documents: 'Related documents',
      documentNumber: 'Document number',
      issueDate: 'Issue Date',
      sender: 'Sender',
      senderId: 'Sender ID',
      recipientName: 'Recipient',
      recipientId: 'Recipient ID',
      receiverId: 'Receiver ID',
      createdBy: 'Created by',
      modifiedBy: 'Modified By',
      modificationDate: 'Modification Date',
      modifiedAt: 'Modification Date',
      creationDate: 'Creation Date',
      createdAt: 'Creation Date',
      documentTypeCode: 'Document Type',
      documentId: 'Document ID',
      document: 'Document',
      excisePayerCode: 'Excise payer code',
      issuerName: 'Issuer',
      archivePeriod: 'Archive Period',
      archiveDate: 'Archive Date',
      supplier: 'Supplier',
      supplierId: 'Supplier ID',
      buyer: 'Buyer',
      buyerId: 'Buyer ID',
      buyerVatIdentifier: 'Customer VAT ID',
      buyerReference: 'Buyer reference',
      startedAt: 'Started at',
      deliveryDate: 'Delivery date',
      actualDeliveryDate: 'Actual delivery date',
      deliveryHour: 'Delivery hour',
      parties: 'Parties',
      orders: 'Orders',
      number: 'Nr.',
      totalWithVat: 'Total with VAT',
      totalWithoutVat: 'Total w/o VAT',
      totalVat: 'Total VAT',
      vat: 'VAT',
      vatPercent: 'VAT (%)',
      quantity: 'Quantity',
      quantityLidl: 'Number of cartons/boxes',
      price: 'Price',
      invoiceNumber: 'Invoice number',
      invoiceId: 'Invoice ID',
      invoiceDate: 'Invoice Date',
      orderId: 'Order ID',
      orderDate: 'Order Date',
      forecastId: 'Forecast ID',
      attachment: 'Attachment',
      invoiceType: 'Invoice Type',
      processDocumentFormatType: 'Document format type',
      originalFileName: 'Original File Name',
      adherentUniqueIdentifier: 'Adherent unique ID',
      issuerId: 'Issuer ID',
      gln: 'GLN Code',
      deliveryLocationName: 'Delivery Location Name',
      locationAddress: 'Delivery Location',
      address: 'Address',
      currency: 'Currency',
      processStatus: 'Process status',
      readStatus: 'Read Status',
      receiptAdviceId: 'Receipt Advice ID',
      receiptAdviceDate: 'Receipt Advice Date',
      despatchAdviceId: 'Despatch Advice ID',
      despatchAdviceDate: 'Despatch Advice Date',
      contractId: 'Contract ID',
      contractDate: 'Contract Date',
      dxuid: 'DXUID',
      documentReferenceDxuid: 'Document reference DXUID',
      attachmentParentDxuid: 'Attachment parent DXUID',
      show_metadata: 'Metadata',
      description: 'Description',
      referenceInvoiceId: 'Reference invoice',
      referenceInvoiceDate: 'Reference invoice date',
      codeClient: 'Client code',
      codeStandard: 'EAN code',
      itemClassificationCode: 'Item classification code',
      codeSupplier: 'Supplier code',
      codeBuyer: 'Buyer code',
      productCodeRef: 'Product ref',
      um: 'U.M',
      messageDetails: 'Message details',
      buyerCustomer: 'Ordered by',
      supplierReceiverCode: 'Supplier code',
      supplierName: 'Name',
      numberOfLines: 'Nr. lines',
      user: 'User',
      streetName: 'Street',
      additionalStreet: 'Additional street',
      buildingNumber: 'Building Nr.',
      cityName: 'City',
      postalZone: 'Postal Zone',
      countryCode: 'Country',
      discount: 'Discount (%)',
      discountAmount: 'Discount amount',
      taxAmount: 'Tax amount',
      taxType: 'Tax type',
      greenTax: 'Green tax',
      sugarTax: 'Sugar tax',
      sgrTax: 'D.R.S.',
      unitaryGreenTax: 'Unitary green tax',
      paymentTermsQualifier: 'Qualifier',
      paymentTermsStartEvent: 'Start Event',
      paymentTermsReferenceEventCode: 'Reference event code',
      paymentTermsSettlementPeriodCode: 'Period Code',
      paymentType: 'Means of payment code UN / ECE 4461',
      paymentTermsSettlementPeriodDurationMeasure: 'Duration measure',
      paymentTerms: 'Payment terms',
      identification: 'Identification',
      linkedDocuments: 'Linked documents',
      carrierPartyName: 'Carrier party name',
      consignorPartyName: 'Consignor party name',
      loadingLocationAddress: 'Loading location address',
      loadingLocationGln: 'Loading location GLN',
      loadingLocationName: 'Loading location name',
      unloadingLocationAddress: 'Unloading location address',
      unloadingLocationGln: 'Unloading location GLN',
      unloadingLocationName: 'Unloading location name',
      extendedInvoiceId: 'Invoice ID',
      extendedInvoiceIssueDate: 'Invoice issue date',
      extendedInvoiceDxuid: 'Invoice DXUID',
      paymentExchangeRateSourceCurrencyCode: 'Source currency code',
      paymentExchangeRateTargetCurrencyCode: 'Target currency code',
      paymentExchangeRateCalculationRate: 'Currency exchange rate',
      shippingLocation: 'Shipping location',
      shipment: 'Shipment',
      shipmentInformation: 'Shipment and contact information',
      shipmentStage: 'Shipment stage',
      shipmentDelivery: 'Shipment tracking',
      shipmentTransportHandlingUnit: 'Shipment - transport handling unit',
      orderReferenceSalesOrderId: 'Supplier Order ID',
      orderLineReferenceLineId: 'Order line item reference',
      orderTypeCode: 'Order type code',
      unitaryPrice: 'Unitary price',
      fiscalCode: 'Fiscal code',
      contactName: 'Name',
      contact: 'Contact',
      contactPhone: 'Phone',
      contactNote: 'Note',
      contactIDCardSeries: 'ID card series',
      contactIDCardNumber: 'ID card number',
      contactIDCardIssuer: 'ID card issuer',
      measureUnit: 'Measure unit',
      documentSubTypeCode: 'Document Subtype',
      name: 'Name',
      regulatorId: 'Fiscal authority download ID',
      regulatorStatusCode: 'Fiscal authority status code',
      regulatorExtraDetails: 'Fiscal authority details',
      grossWeightMeasure: 'Gross Weight Measure',
      grossVolumeMeasure: 'Gross Volume Measure',
      heightMeasure: 'Height Measure',
      grossWeightMeasureUm: 'Weight Measure U.M.',
      grossVolumeMeasureUm: 'Volume Measure U.M.',
      heightMeasureUm: 'Height Measure U.M.',
      heightMeasureAttributeId: 'Height Measure Attribute ID',
      grossWeightMeasureAttributeId: 'Gross Weight Measure Atribute ID',
      grossVolumeMeasureAttributeId: 'Gross Volume Measure Attribute ID',
      quantityPerPallet: 'Maximum number of cartons/boxes per pallet',
      quantityUnitPerPallet: 'U.M. Quantity',
      customizationID: 'Customization ID',
      profileID: 'Profile ID',
      projectReferenceID: 'Project reference',
      contactElectronicMail: 'Email',
      sendPDFToElectronicMail: 'Email for sending PDF',
      taxPointDate: 'VAT validity date',
      taxPointDateCode: 'VAT validity date code',
      businessProcessType: 'Business process type',
      payeeParty: 'Payee party name',
      taxRepresentativeCompanyID: 'Seller tax representative tax number',
      taxRepresentativeName: 'Seller tax representative name',
      additionalDocumentReference: 'Additional documents references',
      addressLine: 'Additional address line',
      invoicePeriodEndDate: 'End date of Invoicing period',
      invoicePeriodStartDate: 'Start date of invoicing period',
      additionalItemPropertyName: 'Item attribute name',
      additionalItemPropertyValue: 'Item attribute value',
      baseQuantity: 'Base quantity',
      tradeRegistryNumber: 'Trade registry number',
    },
    list: {
      columns: 'Columns',
      search: 'Search',
      default: 'Default',
      addFilter: 'More Filters',
      clearAll: 'Clear All',
      goToPage: 'Go to page',
      paginationHelp:
        'Hint : You can use the LEFT and RIGHT keys to navigate between the pages or press the "g" key to go to a specific page',
    },
    filter: {
      date: {
        datePicker: 'Pick dates',
        today: 'Today',
        allTime: 'Reset date',
        yesterday: 'Yesterday',
        thisWeek: 'This week',
        last7days: 'Last 7 days',
        lastWeek: 'Last week',
        last14days: 'Last 14 days',
        thisMonth: 'This month',
        lastMonth: 'Last month',
        last30days: 'Last 30 days',
      },
      numberRange: {
        from: 'from',
        to: 'to',
      },
      issuerLabels: {
        name: 'Issuer Name',
        id: 'Issuer ID',
      },
      recipientLabels: {
        name: 'Recipient Name',
        id: 'Recipient ID',
      },
      receiver: {
        id: 'Receiver ID',
      },
      savedQueriesSaveBtn: 'Save query',
      savedQueriesMenu: 'Saved queries',
      savedQueriesSaveText: 'Save current query as',
      savedQueriesDeleteTitle: 'Delete : %{savedQuery}',
      removeQueryRemoveText: 'Do you want really want to delete this query?',
      dateRange: {
        from: 'From:',
        to: 'To: ',
        invalidRange: 'Invalid date range',
      },
      apply: 'Apply',
    },
    pdf: {
      loadError: 'Unable to preview the document',
      page: 'Page',
      of: 'of',
    },
    showMetadata: {
      error: 'Unable to display metadata',
      aperakDetailsError: 'Unable to display message details',
    },
    download: {
      error: 'Error: Unable to retrieve document representations',
      errorBulk: 'Error: Unable to download selected documents',
      inProgress: 'Download in progress ...',
      completed: 'Download completed.',
      warningLimit: 'Please limit your selection to 200 items',
    },
    delete: {
      error: 'Error: Unable to delete selected document',
      errorBulk: 'Error: Unable to delete selected documents',
      inProgress: 'Delete in progress ...',
      completed: 'Delete completed.',
      warningLimit: 'Please limit your selection to 200 items',
    },
    messages: {
      update_profile_title: 'Update user profile',
      no_apps_purchased: 'Did you purchase any application ?',
    },
    webForm: {
      saved: 'Document ID *** %{documentId} *** saved ...',
      savedWithNoId: 'Document saved ...',
      errorSaving: 'Unable to save the draft ...',
      noDocumentId: 'Please identify the document first',
      sent: 'Document sent ...',
      errorSending: 'Unable to send the document',
      errorLoadTemplate: 'Unable to find a valid form template',
      saveConfirmTitle: 'Save document',
      saveConfirmMessage:
        'The form contains invalid fields. Are you sure you want to save?',
      sendUnknownError: 'Unknown error occurred. Please contact the support.',
      sendInvalidUblError:
        'Invalid document format. Please contact the support.',
      sendErrorTitle: 'Error',
      deleteLine: 'Delete line number : %{lineNumber}',
      deleteLineTooltip: 'Delete',
      deleteLineConfirm: 'Are you sure ?',
      deleteLineNotifySuccess: 'Line number %{lineNumber} deleted',
      popup: {
        title: 'Did you know ?',
        content:
          'Did you know that you can convert an order to an invoice from the "Orders" menu ?',
        moreInfoTextLink: 'Click here to learn how',
        moreInfoTextLinkUrl:
          'https://support.doc-process.com/hc/en-us/articles/8880637306397-Did-you-know-you-can-automatically-convert-an-order-to-an-invoice-',
        checkboxDismissText: "Don't show this again",
      },
      unitCode: {
        EA: 'EA - Unit',
        '27': '27 - Theoretical ton',
        DAY: 'DAY - Day',
        GRM: 'GRM - Gram',
        H87: 'H87 - Piece',
        KGM: 'KGM - Kilogram',
        LM: 'LM - Metre',
        LTR: 'LTR - Litre',
        MLT: 'MLT - Millilitre',
        MTK: 'MTK - Square metre',
        MTQ: 'MTQ - Cubic metre',
        SET: 'SET - Set',
        XBG: 'XBG - Bag',
        XBX: 'XBX - Box',
        XPA: 'XPA - Packet',
        XPP: 'XPP - Unpacked article',
        XPX: 'XPX - Pallet',
      },
    },
    export: {
      visibleColumns: 'Export Visible Columns',
      allColumns: 'Export All Columns',
    },
    auth: {
      companySelectionAtLogin:
        'You belongs to more than one companies. Select the one you want to log in to.',

      crt_password: 'Current password',
      crt_password_required: 'Please enter your current password',
      crt_password_invalid: 'Password is incorrect.',

      new_password: 'New password',
      new_password_required: 'Please enter your new password',

      confirm_password: 'Retype the new password',
      confirm_password_required: 'Please enter your new password again',

      lost_password_title: 'Forgot your password?',
      lost_password_desc:
        'Please enter your username to request a password reset',
      lost_password_email_notif:
        'If an account exists for this username, an e-mail will be sent with further instructions.',
      lost_password_error:
        'An error occurred while sending password recovery instructions',

      change_password_title: 'Change password',
      change_password_confirmation:
        'Your password has been succesfuly changed. Please login using the new password.',
      change_password_expired:
        'Your reset password link has expired. Password has not been changed.',
      change_password_error:
        'Could not change your password due to an internal error.',
      change_password_match_error:
        'The passwords don’t match, please try again.',

      password_criteria_error:
        'New password is not strong enough. See criterias for details.',
      password_criteria_alert_title: 'DocProcess password policy',

      password_criteria_text:
        'In order to have a strong password, your password must comply with the following criterias:',
      password_criteria_length: 'Contain at least 8 characters',
      password_criteria_lowercase: 'Contain a lowercase letter',
      password_criteria_uppercase: 'Contain an uppercase letter',
      password_criteria_digit: 'Contain a digit',
      password_criteria_special:
        'Contain at least one of the following characters: ! & @ # : ? + * - /  $ ( )',
      token_too_many_attempts:
        'Too many attempts. Please try again in 30 minutes.',
      token_invalid_or_already_used: 'Your request is invalid or has expired.',
    },
    myAccount: {
      email: 'Email',
      firstName: 'First name',
      enabled: 'Enabled',
      lastName: 'Last name',
      phoneNumber: 'Phone number',
      roles: 'Roles',
      profile_update_success:
        'Your user data (except the email address) has been successfully updated. If your email address has been changed, a confirmation will be needed. Check your new email inbox for instructions.',
      profile_update_error:
        'Cannot update user profile. An error has occurred.',
      create_user_success:
        'The user has been successfully created. An email has been sent to the address given for more instructions.',
      create_user_success_synchronize:
        'The user has been successfully created. An email will be sent to the address given, once company is synched with V3.',
      create_user_error: 'Unable to create the user',
      delete_user_error: 'Unable to delete this user : %{error}',
      delete_customer_admin_error:
        'It is required to have at least one administrator per company.',
      email_update_success: 'Your email has been successfully updated.',
      email_update_error: 'Cannot update email. An error has occurred.',
      companyName: 'Company',
      generate_password_reset_link_button: 'Generate reset password link',
    },
    task: {
      documentId: 'Document ID',
      documentType: 'Type',
      due: 'Due',
      start_after: 'Start after',
      start_before: 'Start before',
      description: 'Description',
      start_since: 'Start since',
      due_in: 'Due in',
      orderId: 'Order ID',
      now: 'Now',
      anyInvoice: 'Invoice',
      commercialInvoice: 'Commercial invoice',
      cancelInvoice: 'Cancel invoice',
      correctiveInvoice: 'Corrective invoice',
      serviceInvoice: 'FSR invoice',
      order: 'Order',
      order_negotiation: 'Order Negotiation',
      in_one_day: 'In one day',
      in_three_days: 'In three days',
      in_one_week: 'In one week',
      in_two_weeks: 'In two weeks',
      in_one_month: 'In one month',
      in_three_months: 'In three months',
      in_six_months: 'In six months',
      in_one_year: 'In one year',
      button: {
        approve: 'Approve',
        reject: 'Reject',
        open: 'Open Task',
      },
      invoiceToCancel: 'Invoice to cancel',
      invoiceMatched: 'Invoice matched',
      invoiceNotMatched: 'Invoice not matched',
      title: 'Trebuie să aprobați sau să refuzați factura',
      matched: 'N-way Matching Cluster',
      request: {
        success: 'Success!',
      },
      comment: {
        message: 'Please provide a comment',
      },
      taskCountError: 'Task Management is not available',
      saintgobainui: {
        rejectReason: {
          incorrect_order_number: 'Incorrect order number',
          incorrect_deliverynote_number: 'Incorrect delivery note number',
          incorrect_loading_location: 'Incorrect loading location',
          incorrect_unloading_location: 'Incorrect unloading location',
          incorrect_transport_cost: 'Incorrect transport cost',
          incorrect_invoice_currency: 'Incorrect invoice currency',
        },
        label: 'Rejection reason',
        title: 'Invoice and associated CMRs',
        additionalInfo: 'Additional information',
        errorMsg: {
          missing_sap_id:
            'SAP ID is missing. You must add one to approve this task',
          max_sap_id_length: 'SAP ID maximum length is 10',
          missing_rej_reason:
            'Rejection reason missing. Please select one from the drop down list',
          rej_reason_with_accept:
            "You can't have a rejection reason or additional information when accepting an invoice. Please remove it before continuing",
          sapid_with_rej:
            "You can't have a SapId when rejecting an invoice. Please remove it before continuing",
        },
      },
      multimedia: {
        card: {
          title: 'Invoice Validation',
          subheader: 'Please, approve or reject the invoice below:',
        },
      },
      reginamaria: {
        card: {
          supplier: {
            title: 'Supplier Order Negotiation',
            subheader:
              'Please make changes to the order or send it without modifications',
          },
          client: {
            title: 'Client Order Negotiation',
            subheader: 'Please approve or reject the order',
          },
        },
        table: {
          title: 'Amend Order #%{orderId}',
          deliveryTitle: 'Delivery',
          orderTitle: 'Order Items',
          startDate: 'Requested Start Date',
          endDate: 'Requested End Date',
          actualDeliveryDate: 'Actual Delivery Date',
          quantity: 'Quantity',
          unitCode: 'U.M.',
          itemId: 'Item Id',
          itemDesc: 'Item Description',
          buyerIdentification: 'Buyer Identification',
          price: 'Unit price without VAT',
          expirationDate: 'Expiration Date',
          numberOfDeliveries: 'Number of Deliveries',
          deliveryId: 'Delivery Id',
          comment: 'Comment',
          emptyDeliveryTable: 'No delivery data.',
          emptyOrderTable: 'No data found in order.',
          deleteVerification: 'Are you sure you want to delete this row?',
          bulkButton: 'Apply to all',
          modificationsApplied: 'Modifications applied',
          addRemoveColumns: 'Add or remove columns',
          nRowsSelected: '{0} row(s) selected',
          searchTooltip: 'Search',
          searchPlaceholder: 'Search',
          showColumnsTitle: 'Show Columns',
          labelRowsSelect: 'rows',
          firstTooltip: 'First Page',
          previousTooltip: 'Previous Page',
          nextTooltip: 'Next Page',
          lastTooltip: 'Last Page',
          labelDisplayedRows: '{from}-{to} of {count}',
          addTooltip: 'Add',
          deleteTooltip: 'Delete',
          editTooltip: 'Edit',
          cancelTooltip: 'Cancel',
          saveTooltip: 'Save',
          deleteText: 'Are you sure delete this row?',
          lineStatusCode: 'Line status',
        },
        lineStatusCode: {
          NO_CHANGE: 'Unchanged',
          NOT_ACCEPTED: 'Not accepted',
          UPDATED: 'Updated',
        },
        client: {
          tooltip:
            'If the lines are not highlighted green, the process added the missing data per line',
        },
        toolbar: {
          accept: 'Accept',
          send: 'Send',
          reject: 'Reject',
        },
        buttons: {
          undo: 'Undo delete',
        },
        key: {
          infoSupplier:
            'Please add any changes to the order lines in the table below. Once finished, you can send the order or if you are not happy, you can reject it.',
          infoClient:
            'These are the proposed changes by the supplier. You can accept or reject the changes.',
          originalValue: 'Original value',
          proposedValue: 'Proposed value',
        },
        errorMsg: {
          missing_rej_reason: 'Rejection reason missing',
          missing_delivery_information_line:
            'Missing delivery information for line %{lineId}',
          delivered_quantity_too_big:
            'Delivered quantity is bigger than line quantity',
          delivered_quantity_too_low:
            'Delivered quantity is lower than line quantity',
          negative_numbers: 'Negative numbers are not allowed',
          same_delivery_dates:
            'Delivery dates are the same as another entry. Please combine them',
          incorrect_quantity: "Quantity can't be higher than initial quantity",
          actual_date_mandatory: 'The actual delivery date is mandatory',
          dates_not_in_range:
            'The actual delivery must be between the requested start date and end date',
          dates_not_in_range_with_extra:
            'The actual delivery must be between the requested start date and end date + %{extra} days',
          dates_not_in_range_in_line:
            'Line %{lineId} : the actual delivery must be between the requested start date and end date',
          dates_not_in_range_with_extra_in_line:
            'Line %{lineId} : the actual delivery must be between the requested start date and end date + %{extra} days',
          incorrect_start_date: 'Incorrect start delivery date',
          incorrect_end_date: 'Incorrect end delivery date',
          price_higher_than_tolerance:
            'The new price can only be increased by %{greaterThan}% of the current price at most.',
          price_lower_than_tolerance:
            'The new price can only be lowered by %{lessThan}% of the current price at most.',
          item_quantity:
            'Delivered quantity is higher than line quantity for line %{lineId}. Please fix it before submitting',
          item_quantity_lower:
            'Delivered quantity is lower than line quantity for line %{lineId}. Please fix it before submitting',
          invalid_quantity_value:
            'The delivered quantity value entered is invalid',
          actual_date_mandatory_line:
            'The actual delivery date is missing for line %{lineId}',
          delivery_dates_inconsistency_line:
            'The delivery dates are inconsistent for line %{lineId}',
          missing_requested_start_or_end_dates_line:
            'Missing mandatory requested start or end date for line %{lineId}',
          end_date_must_be_in_future:
            "The requested end date must be in the future or equal to today's date.",
          start_date_must_be_in_future:
            "The requested start date must be in the future or equal to today's date.",
          dates_overlap: 'The requested start date and end date are not valid.',
        },
      },
      invoice_matching: {
        invoiceHoldDecisionTask: {
          title: 'Invoice n-way matching',
          subtitle: 'Please, approve or reject the invoice below:',
          cockpit: {
            title: 'Approve or reject the invoice',
            button: {
              approve: 'Approve',
              reject: 'Reject',
            },
            nwayMatchingOfTheinvoice: 'The n-way matching',
            invoiceCluster: 'Documents involved into the n-way matching',
            comment:
              'You must enter a comment to unlock the Approve/Reject buttons:',
            invoiceClusterDetails:
              'The invoice, its receipt advices (linked by order), and may be previous accepted invoices on the same receipt advices.',
            i2oInvoiceClusterDetails:
              'The invoice, its orders, and may be previous accepted invoices on the same orders.',
            raceConditionOnInvoiceApproval:
              'You have already accepted this invoice but someone else matched some of the receipt advice lines in the meantime, causing a conflict. You need to accept/reject it again.',
            i2oRaceConditionOnInvoiceApproval:
              'You have already accepted this invoice but someone else matched some of the order lines in the meantime, causing a conflict. You need to accept/reject it again.',
            incompleteCluster:
              'The matching may be incomplete as all the involved documents have not been retrieved before the time limit (missing receipt advices?).',
            i2oIncompleteCluster:
              'The matching may be incomplete as all the involved documents have not been retrieved before the time limit (missing orders?).',
            usedExchangeRate: 'Exchange rate(s) used in the matching:',
            element: 'Element',
            rules: 'Rules',
            acceptedMinusInvoiced: 'accepted - already invoiced',
            unitaryNetPriceAlgo: 'net price = gross - discount + green tax',
            match: 'Match',
            quantityRule:
              'Invoice quantity <= sum of matched receipt advice quantities',
            i2oQuantityRule:
              'Invoice quantity <= sum of matched order quantities',
            unitaryPrice: 'Unitary price',
            unitaryPriceRule:
              'Invoice unitary net price <= receipt advice unitary net price with green tax',
            i2oUnitaryPriceRule:
              'Invoice unitary net price <= order unitary price',
            correcting: 'Correcting',
            correctionChain:
              'The commercial invoice to correct and all its corrective invoices',
            errors: {
              PartialNwayMatchingException:
                'You only got a partial n-way matching due to errors into the invoice lines:',
              InvoiceLineWithNegativeQuantity:
                'Invoice line with negative quantity: %{invoiceId} at line: %{lineId}',
              CorrectiveInvoiceWithNoReferencedInvoiceId:
                'Corrective invoice with no reference ID on the one to correct: %{id}',
              CorrectiveWithoutSameOrderRefAsCommercial:
                'Corrective without the same order reference as the commercial: %{correctiveId}, ' +
                '%{correctiveOrderId} - %{commercialId}, %{commercialOrderId}',
              RecadvsWithDuplicateID:
                'Receipt advices in the n-way matching cluster with the same ID!',
              RecadvWithDuplicateLineID:
                'Receipt advices with duplicate line ID',
              InvoiceWithDuplicateLineID: 'Invoice with duplicate line ID',
              InvoiceWithNoRequiredTopOrderRef:
                'Invoice without the mandatory (top) order reference!',
              clusteringPeriodElapsed:
                'Stop waiting for receipt advices: period elapsed!',
              i2oClusteringPeriodElapsed:
                'Stop waiting for orders: period elapsed!',
              waitingForCorrectedInvoicePeriodElapsed:
                'Stop waiting for the invoice to correct: period elapsed!',
              waitingForRecadvPeriodElapsed:
                'Stop waiting for receipt advices: period elapsed!',
              i2oWaitingForOrderPeriodElapsed:
                'Stop waiting for orders: period elapsed!',
            },
            wrongSgrTaxes:
              'The items were perfectly matched but some SGR taxes in the invoice lines do not match the ones in the receipt advices',
          },
        },
        cancellationDecisionTask: {
          title: 'Invoice cancellation',
          subtitle: 'Please, approve or reject the cancel invoice below:',
          cockpit: {
            title: 'Approve or reject the cancel invoice',
            button: {
              cancelInvoice: 'Approve cancellation',
              rejectCancellation: 'Reject cancellation',
            },
            invoiceAcceptance:
              'This invoice has been %{acceptance} on %{date} by %{user}',
            automaticInvoiceAcceptance:
              'This invoice has been automatically %{acceptance} on %{date}.',
            accepted: 'accepted',
            rejected: 'rejected',
            withTheComment: 'with the comment:',
            invoiceNotFound:
              'The invoice to cancel was not found (reference: %{ref}).',
            cancelInvoiceWithNoRef:
              'This cancel invoice has no reference to the invoice to cancel.',
            comment: 'Comment',
            theCancelInvoice: 'The cancel invoice',
            theInvoiceToCancel: 'The invoice to cancel',
            theCancelInvoiceDetails:
              'Details of the cancel invoice and its target',
          },
        },
      },
    },
    uploadFiles: {
      uploadBox:
        "Drag'n'Drop the file to attach or <span class='filepond--label-action'> Browse </span>",
      preparing: 'Preparing',
      ready: 'Ready',
      clickToRemove: 'Click to remove',
      clickToCancel: 'Click to cancel',
      fileTooLarge: 'File is too large',
      maxFileSizeIs: 'Maximum file size is {filesize}',
      shortFileTooLarge: 'Too large',
      shortMaxFileSizeIs: 'Max size is {filesize}',
      uploadError: 'Error during upload',
      loadError: 'Error during download',
      loadInitError: 'Failed to get the file description',
      tapToRetry: 'Click the circle to retry',
      uploadRefreshInfo:
        'Uploaded files will soon be available in the attachment list. Please refresh manually.',
      successfullyCreated:
        'File has been uploaded! It should be available in the list of attachments soon',
      removeFile: 'File has been removed',
      uploadInitError: 'Upload fonctionality not currently available.',
      maxFilesReached: 'Maximum number of attached files has been reached.',
    },
    ubl: {
      packageMarkerID: 'EAN-128 or other format',
      packagingTypeCode: {
        pallet: 'pallet',
        box: 'box',
        carton: 'carton',
      },
      payment_means_code: {
        bank_transfer: 'Bank transfer',
        cash: 'Cash',
        collection: 'Collection',
      },
      payment_terms_settlement_period_code: {
        day: 'D - Day',
        weekDay: 'WD - Week Day',
        month: 'M - Month',
        year: 'Y - Year',
      },
    },
    analytics: {
      dashboardMenuGroup: 'Dashboards',
      analytics: 'Analytics',
      activitySummary: 'Activity Summary',
      timePeriod: 'Time period',
      monthly: 'monthly',
      daily: 'daily',
      monthlySummary: 'Summary of Monthly Activity',
      dailySummary: 'Summary of Daily Activity',
      month: 'Month',
      day: 'Select date',
      totalProcessed: 'Documents processed',
      processedByType: 'Documents processed by type',
      tableSummary:
        'This table displays the total number of documents received and sent for the selected period.',
      processedByCompany: 'Documents processed by company',
      documentType: 'Select document type',
      chartSummary: 'This chart only displays the first 10 companies.',
      type: 'Type',
      documentsReceived: 'Documents Received',
      documentsSent: 'Documents Sent',
    },
    audit_trail: {
      show: 'Processing details',
      title: 'Processing details',
      // See AuditTrailTimeline for the allowed categories.
      categories: {
        FLOW_STARTED: 'Processing started',
        DOCUMENT_RECEIVED: 'Document received',
        DOCUMENT_SENT: 'Document sent',
        THIRD_PARTY_ACKNOWLEDGMENT: 'Acknowledged by third party',
        DOCUMENT_REPRESENTATION_GENERATED: 'Document rendered',
        DOCUMENT_ERROR: 'Error into the document',
        DOCUMENT_VALIDATION: 'Document validation',
        WAIT_FOR_USER_ACTION: 'Wait for user action',
        USER_ACTION_DONE: 'Action done',
        USER_ACTION_DELETED: 'Action cancelled',
        FLOW_END: 'End of the processing',
        INCIDENT: 'Incident',
      },
      types: {
        // See audit-trail-1.0.sql for the event types allowed into the trail.
        P2P_INVOICE_FORMAT_ERROR: 'Bad format!',
        P2P_INVOICE_REJECTED_BY_RECIPIENT: 'Rejected by buyer',
        P2P_INVOICE_PAID_BY_RECIPIENT: 'Paid',
        P2P_INVOICE_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by buyer',
        P2P_INVOICE_RECEIVED_BY_BSP:
          'Reception acknowledged by Business Service Provider',
        P2P_INVOICE_REJECTED_BY_BSP: 'Rejected by Business Service Provider',
        P2P_INVOICE_CONTENT_ERROR: 'Content error!',
        P2P_INVOICE_CONTENT_WARNING: 'Content warning!',
        P2P_INVOICE_SENT: 'Invoice sent to buyer',
        P2P_INVOICE_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_INVOICE_END: 'End of processing',
        P2P_INVOICE_WAIT_STATUS: 'Waiting for buyer acknowledgment',
        P2P_INVOICE_ACCEPTED_BY_RECIPIENT: 'Accepted by buyer',
        P2P_INVOICE_WAITING_FOR_RECIPIENT: 'Waiting for buyer action',
        P2P_INVOICE_SENT_TO_REGULATOR: 'Invoice sent to the fiscal authority',
        P2P_INVOICE_WAIT_STATUS_FROM_REGULATOR:
          'Waiting for fiscal authority response',
        P2P_INVOICE_STATUS_FROM_REGULATOR: 'Fiscal authority response',
        P2P_INVOICE_PARTIALLY_PAID_BY_RECIPIENT: 'Partially paid by recipient',
        P2P_INVOICE_RECEIVED_AND_VALIDATED_BY_BSP:
          'Received and validated by Business Service Provider',

        P2P_INVOICE_UNDER_QUERY: 'Pending info',
        P2P_INVOICE_CONDITIONALLY_ACCEPTED: 'Conditionally accepted',
        P2P_INVOICE_IN_PROCESS: 'In process on recipient side',

        P2P_ORDER_CONTENT_ERROR: 'Content error!',
        P2P_ORDER_FORMAT_ERROR: 'Bad format!',
        P2P_ORDER_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_ORDER_SENT: 'Order sent to supplier',
        P2P_ORDER_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by supplier',
        P2P_ORDER_END: 'End of processing',

        P2P_ORDER_NOT_CHANGED: 'The order was not modified by the supplier',
        P2P_ORDER_CHANGED: 'The order was modified by the supplier',
        P2P_ORDER_RECEIVED_BY_SUPPLIER:
          'The order was received by the supplier',
        P2P_ORDER_REJECTED_BY_RECIPIENT:
          'The order was rejected by the recipient',
        P2P_ORDER_ACCEPTED_BY_RECIPIENT:
          'The order was accepted by the recipient',
        P2P_ORDER_ACCEPTED_BY_SUPPLIER:
          'The order was accepted by the supplier',
        P2P_ORDER_REJECTED_BY_SUPPLIER:
          'The order was rejected by the supplier',

        P2P_RECADV_CONTENT_ERROR: 'Content error!',
        P2P_RECADV_FORMAT_ERROR: 'Bad format!',
        P2P_RECADV_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_RECADV_SENT: 'Receipt advice sent to supplier',
        P2P_RECADV_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by supplier',
        P2P_RECADV_END: 'End of processing',

        P2P_DESADV_CONTENT_ERROR: 'Content error!',
        P2P_DESADV_FORMAT_ERROR: 'Bad format!',
        P2P_DESADV_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_DESADV_SENT: 'Despatch advice sent to buyer',
        P2P_DESADV_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by buyer',
        P2P_DESADV_END: 'End of processing',

        USER_TASK_CREATED: {
          invoiceHoldDecisionTask: 'Manual reconciliation requested',
          cancellationDecisionTask: 'Manual cancellation requested',
          costCenterAllocationTask:
            'Failed to fully allocate the invoice lines! Manual allocation requested',
          invoiceValidationTask: 'Manual validation requested',
        },
        USER_TASK_COMPLETED: {
          invoiceHoldDecisionTask: 'Reconciliation task done',
          cancellationDecisionTask: 'Cancellation task done',
          costCenterAllocationTask: 'Manual allocation done',
          invoiceValidationTask: 'Manual validation done',
        },
        USER_TASK_DELETED: {
          invoiceHoldDecisionTask: 'Reconciliation task deleted',
          cancellationDecisionTask: 'Cancellation task deleted',
          invoiceValidationTask: 'Validation task reassigned',
        },

        I2PR_STARTED: 'Matching with receipt advices started',
        I2PR_SUCCESS: 'Matching approved',
        I2PR_ERROR: 'Matching in error',
        I2PR_REJECTED: 'Matching rejected',
        I2PR_SKIPPED: 'Matching skipped',

        I2O_STARTED: 'Matching with orders started',
        I2O_SUCCESS: 'Matching approved',
        I2O_ERROR: 'Matching in error',
        I2O_REJECTED: 'Matching rejected',
        I2O_SKIPPED: 'Matching skipped',

        ALLOCATION_STARTED: 'Try to automatically allocate general expenses',
        ALLOCATION_DONE: 'Allocation registered',
        AUTOMATIC_ALLOCATION_DONE: 'Automatic allocation passed',

        // V2 events:
        // NEW => 'FLOW_STARTED'
        PROCESSED: 'Processed',
        ACCOUNT_CHECKED: 'Account checked',
        DELIVERED_FROM_FORMAT_ERROR: 'Delivered (format error)',
        REJECTED_BY_CUSTOMER: 'Rejected by customer',
        RECEIVED_BY_CUSTOMER: 'Received by customer',
        VALIDATED: 'Validated',
        DELIVERED_FROM_CONTENT_ERROR: 'Content Error!',
        DELIVERED: 'Sent',
        NOTIFIED: 'Notified',
        RECEIVED_BY_DX: 'Received by DocProcess',
        ACCEPTED_BY_CUSTOMER: 'Accepted by customer',
        PENDING_TO_CUSTOMER: 'Pending to customer',
      },
      noTrail: 'No audit trail.',
      descriptions: {},
    },
    messagesCenter: {
      state: {
        DRAFT: 'Draft',
        PUBLISHED: 'Published',
        title: 'State',
        help: "Message visibility. The message can be created as DRAFT and won't be visible until an edition setting it as PUBLISHED. Setting its state to PUBLISHED at creation time will make it visible immediately",
      },
      topic: {
        title: 'Topic',
        help: "Defines a topic to identify your message. This topic is displayed in the user's notifications menu",
      },
      language: {
        title: 'Language',
        help: "The message visibility takes into account the user's language choice. You can then specialize the message according to the language",
      },
      link: {
        text: 'More details',
        href: 'More info link',
        public: 'Public Link',
        knowledgeBase: 'Knowledge base link',
        help: 'Optional "more info" link to an external public link (Knowledge base link = false) or a link to the knowledge base (Knowledge base link = true)',
        show: 'Don’t show again',
      },
      type: {
        BANNER: 'Banner',
        DOWNTIME: 'Downtime',
        MARKETING: 'News',
        GENERAL: 'Operations',
        title: 'Type',
        help: 'Message category. An <<Operations>> or a <<Marketing>> message is visible on the home page for all users.',
      },
      content: {
        title: 'Message content',
        help: 'Message content limited to 300 characters and which could be multilignes. Click on Show button for an idea of the rendering',
      },
    },
  }, // End of English DxMessages
  resources: {
    // Override English react-admin reserved messages
    'management/company': {
      name: 'Companies',
    },
    'management/formattype': {
      name: 'Format type',
    },
    'management/dxrole': {
      name: 'Role',
    },
    'management/supplier': {
      name: 'Suppliers',
    },
    'management/customer': {
      name: 'Customers',
    },
    'management/productusage': {
      name: 'Contracted Products',
    },
    'management/supportedproducts': {
      name: 'Supported Products',
    },
    'management/address': {
      name: 'Addresses',
    },
    'management/bufferaddress': {
      name: 'Address import',
    },
    'management/contact': {
      name: 'Contact',
    },
    'management/dropdowndefinition': {
      name: 'Dropdown Definition',
    },
    'management/dxproduct': {
      name: 'DX Product',
    },
    'management/accountrelation': {
      name: 'Account relation',
    },
    'management/peppol': {
      name: 'Peppol',
    },
    'management/v2invoice': {
      name: 'V2 Invoices',
    },
    'management/v2order': {
      name: 'V2 Orders',
    },
    'management/v2receiptadvice': {
      name: 'V2 Receipt Advices',
    },
    'management/v2despatchadvice': {
      name: 'V2 Despatch Advices',
    },
    'efactura/monitoring/error': {
      name: 'Efactura Content Error',
    },
    'efactura/monitoring/rejected': {
      name: 'Efactura Rejected Documents',
    },
    usermgmt: {
      name: 'Accounts',
    },
    // Override English react-admin reserved messages
    customers: {
      name: 'Customers',
      fields: {
        id: 'Code',
        name: 'Name',
      },
    },
    issuers: {
      name: 'Issuers',
      fields: {
        id: 'Code',
        name: 'Name',
      },
    },
    invoice: {
      name: 'Invoices',
    },
    despatchAdvice: {
      name: 'Despatch Advices',
    },
    recadv: {
      name: 'Receipt Advices',
    },
    feedbackMessage: {
      name: 'Feedback Messages',
    },
    attachment: {
      name: 'Attachments',
    },
    order: {
      name: 'Orders',
    },
    delfor: {
      name: 'Forecasts',
    },
    cntcnd: {
      name: 'Contracts',
    },
    catlog: {
      name: 'Catalogs',
    },
    documents: {
      name: 'All Documents',
    },
    waybill: {
      name: 'CMR',
    },
    webWaybill: {
      name: 'CMR',
    },
    webInvoice: {
      name: 'Invoices',
    },
    webDespatchAdvice: {
      name: 'Despatch Advices',
    },
    webReceiptAdvice: {
      name: 'Receipt Advices',
    },
    webOrder: {
      name: 'Orders',
    },
    userprofiles: {
      name: 'User Profiles',
    },
    tasks: {
      name: 'My Tasks',
      list: {
        title: 'My Tasks',
      },
      edit: {
        title: 'Your Task',
      },
    },
    'configuration-message': {
      name: 'Messages',
    },
  }, // End of Override English react-admin reserved messages
  Forbidden: 'Forbidden',
  Unauthorized: 'Unauthorized',
  NoDocsFound: 'Sorry, no documents found',
};
