import { DxTheme } from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';

export const useCommonFieldStyles = makeStyles(
  (theme: DxTheme) => ({
    header: {
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    invisibleHeader: {
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(2),
    },
    invisiblePaper: {
      padding: theme.spacing(2),
      boxShadow: 'unset',
    },
    readOnly: {
      pointerEvents: 'none',
      '& :before': {
        borderBottom: 'unset', // remove MuiInput underline
      },
      '& :after': {
        borderBottom: 'unset', // remove MuiInput underline
      },
      '&:hover': {
        borderBottom: 'unset !important', // remove MuiInput underline
      },
      '& [role="button"]': {
        cursor: 'initial',
        pointerEvents: 'none',
      },
      '& svg': {
        display: 'none',
      },
    },
    circularProgress: {
      position: 'relative',
      left: '25em',
      top: 0,
    },
    noWrapTitle: {
      '& > label': {
        whiteSpace: 'nowrap',
      },
    },
  }),
  { name: 'CommonFieldStyles' }
);
