import RegulatorIcon from '@material-ui/icons/Policy';
import RegulatorCreate from './RegulatorCreate';
import RegulatorList from './RegulatorList';

const regulator = {
  icon: RegulatorIcon,
  list: RegulatorList,
  create: RegulatorCreate,
};

export default regulator;
