import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { FC } from 'react';
import {
  FilterProps,
  sanitizeListRestProps,
  useListContext,
} from 'react-admin';
import { DxTheme } from '../../types';
import EnhancedFilterForm from './FilterForm';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    form: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    button: {},
    moreFilters: {
      marginTop: '1em',
      marginBottom: '1em',
      // Removes the hide button of the Filters input.
      // Hidding and displaying Filters is FilterButton component responsability.
      '& button.hide-filter': {
        display: 'none',
      },
      '& input::-ms-clear': {
        display: 'none', // IE and Edge automatically add a clear button to TextInput. Need to remove it.
      },
    },
    filterBtnContainer: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }),
  { name: 'Filter' }
);

const Filter: FC<FilterProps> = (props) => {
  const classes = useStyles(props);
  const {
    resource,
    showFilter,
    hideFilter,
    setFilters,
    displayedFilters,
    filterValues,
  } = useListContext(props);

  const {
    classes: classesOverride,
    context,
    children,
    variant,
    ...rest
  } = props;

  return (
    <Paper className={classes.moreFilters} variant='outlined'>
      {hideFilter !== undefined && (
        <EnhancedFilterForm
          className={classes.form}
          filters={React.Children.toArray(children)}
          initialValues={filterValues}
          {...{
            setFilters,
            showFilter,
            displayedFilters,
            hideFilter,
            resource,
          }}
          {...sanitizeListRestProps(rest)}
        />
      )}
    </Paper>
  );
};

Filter.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
};

export default Filter;
