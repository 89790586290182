import { IMessage } from './types';

export const transformBeforeProcessing = (message: IMessage) => {
  // moreInfoLinkToKnowledgeBase is interpreted analysing moreInfoLink content
  if (
    message.moreInfoLink &&
    message.moreInfoLink.startsWith('https://support.doc-process.com/')
  ) {
    message.moreInfoLinkToKnowledgeBase = true;
  } else {
    message.moreInfoLinkToKnowledgeBase = false;
  }
  return message;
};
