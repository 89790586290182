import { isCompanyAdministrator, isPspAdministrator } from '@dx-ui/dx-common';
import { isCompanyAdministratorV2 } from '@dx-ui/dx-common/src/configuration/configuration';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import ContentCreate from '@material-ui/icons/Create';
import PropTypes from 'prop-types';
import { Record, linkToRecord } from 'ra-core';
import { FC, ReactElement } from 'react';
import { Button, ButtonProps, usePermissions } from 'react-admin';
import { Link } from 'react-router-dom';

const CustomEditButton: FC<EditButtonProps> = ({
  basePath = '',
  label = 'ra.action.edit',
  record,
  icon = defaultIcon,
  ...rest
}) => {
  const { permissions } = usePermissions();
  const showEditButton =
    isPspAdministrator(permissions) ||
    (isCompanyAdministrator(permissions) &&
      record !== undefined &&
      record.flgBilling === false) ||
    (isCompanyAdministratorV2(permissions) &&
      record !== undefined &&
      record.flgBilling === false);
  if (!showEditButton) {
    return null;
  }
  return (
    <Button
      component={Link}
      to={linkToRecord(basePath, record && record.id)}
      label={label}
      onClick={stopPropagation}
      {...(rest as any)}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <ContentCreate />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation();

interface Props {
  basePath?: string;
  record?: Record;
  icon?: ReactElement;
}

export type EditButtonProps = Props & ButtonProps & MuiButtonProps;

CustomEditButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
};

export default CustomEditButton;
