import { SimpleLabeledField } from '@dx-ui/dx-common';
import {
  AddressDetails,
  DeliveryDetails,
  OrderDetails,
} from '@dx-ui/lib-oasis-ubl-2.1/src/OrderModel';
import { get } from 'lodash';
import { useTranslate } from 'react-admin';
import {
  Address,
  InvoiceUblProperties,
  OrderUblProperties,
} from '../../shared/types';
import { IAddressSources } from './UblCommon2-0Sources';

export const getBuyerCustomerAddress = (data: {
  ublProperties: InvoiceUblProperties | OrderUblProperties | any;
}): Address | AddressDetails | undefined =>
  (data?.ublProperties as InvoiceUblProperties | OrderUblProperties)
    ?.buyerCustomerParty?.party?.postalAddress ||
  (data.ublProperties.Order[0] as OrderDetails)?.BuyerCustomerParty?.[0]
    ?.Party?.[0]?.PostalAddress?.[0];

export const getAccountingCustomerAddress = (data: {
  ublProperties: InvoiceUblProperties | OrderUblProperties | any;
}): Address | AddressDetails | undefined =>
  (data?.ublProperties as InvoiceUblProperties | OrderUblProperties)
    ?.accountingCustomerParty?.party?.postalAddress ||
  (data?.ublProperties?.Order?.[0] as OrderDetails)
    ?.AccountingCustomerParty?.[0]?.Party?.[0]?.PostalAddress?.[0];

export const getSellerSupplierAddress = (data: {
  ublProperties: InvoiceUblProperties | OrderUblProperties | any;
}): Address | AddressDetails | undefined =>
  (data?.ublProperties as InvoiceUblProperties | OrderUblProperties)
    ?.sellerSupplierParty?.party?.postalAddress ||
  (data.ublProperties.Order[0] as OrderDetails)?.SellerSupplierParty?.[0]
    ?.Party?.[0]?.PostalAddress?.[0];

export const getAccountingSupplierAddress = (data: {
  ublProperties: InvoiceUblProperties;
}): Address | undefined =>
  data?.ublProperties?.accountingSupplierParty?.party?.postalAddress;

export const getDeliveryLocationAddress = (data: {
  ublProperties: InvoiceUblProperties | any;
}): Address | AddressDetails | undefined => {
  const deliverItems =
    (data?.ublProperties as InvoiceUblProperties)?.delivery ||
    (data?.ublProperties?.Order?.[0] as OrderDetails)?.Delivery;
  if (deliverItems?.length) {
    return (
      (deliverItems as any)?.[0]?.deliveryLocation?.address ||
      (deliverItems as DeliveryDetails[])?.[0].DeliveryParty?.[0]
        ?.PostalAddress?.[0]
    );
  }
};

export enum AddressParts {
  AllParts = 0,
  BuildingNumber = 1,
  StreetName = 2,
  AdditionalStreetName = 4,
  PostalZone = 8,
  CityName = 16,
  Country = 32,
}

export const buildAddressAsAString = (
  address: Address | undefined | AddressDetails,
  missingPartIndicator: string | boolean | undefined = undefined, //'--',
  visibleParts = AddressParts.AllParts,
  dataAddressSources?: IAddressSources | undefined
): string | undefined => {
  let completeAddress: string | undefined;

  if (address) {
    const buildingNumberValue: string | undefined = dataAddressSources
      ? get(address, dataAddressSources.number)
      : (address as Address)?.buildingNumber?.value ||
        (address as AddressDetails)?.BuildingNumber?.[0]?._;
    const streetNameValue: string | undefined = dataAddressSources
      ? get(address, dataAddressSources.street)
      : (address as Address)?.streetName?.value ||
        (address as AddressDetails)?.StreetName?.[0]?._;
    const additionalStreetNameValue: string | undefined = dataAddressSources
      ? get(address, dataAddressSources.addStreet)
      : (address as Address)?.additionalStreetName?.value ||
        (address as AddressDetails)?.AdditionalStreetName?.[0]?._;
    const postalZoneValue: string | undefined = dataAddressSources
      ? get(address, dataAddressSources.postalCode)
      : (address as Address)?.postalZone?.value ||
        (address as AddressDetails)?.PostalZone?.[0]?._;
    const cityNameValue: string | undefined = dataAddressSources
      ? get(address, dataAddressSources.city)
      : (address as Address)?.cityName?.value ||
        (address as AddressDetails)?.CityName?.[0]?._;
    const countryCodeValue: string | undefined = dataAddressSources
      ? get(address, dataAddressSources.countryCode)
      : (address as Address)?.country?.identificationCode?.value ||
        (address as AddressDetails)?.Country?.[0]?.IdentificationCode?.[0]._;

    const addressItems: any[] = [];
    const addPart = (part: AddressParts, value: any) => {
      if (visibleParts === AddressParts.AllParts || (visibleParts & part) > 0) {
        addressItems.push(value);
      }
    };
    addPart(AddressParts.StreetName, streetNameValue);
    addPart(AddressParts.AdditionalStreetName, additionalStreetNameValue);
    addPart(AddressParts.BuildingNumber, buildingNumberValue);
    addPart(AddressParts.PostalZone, postalZoneValue);
    addPart(AddressParts.CityName, cityNameValue);
    addPart(AddressParts.Country, countryCodeValue);

    completeAddress = addressItems
      .filter(
        (item) => !!missingPartIndicator || (item !== undefined && item !== '')
      )
      .map((item) => item || missingPartIndicator)
      .join(', ');
  }
  return completeAddress;
};

export const AddressField = ({
  label,
  address,
  className,
  missingPartIndicator = undefined, //'--',
  visibleParts = AddressParts.AllParts,
  dataAddressSources,
}: {
  label?: string;
  address: Address | AddressDetails | undefined;
  missingPartIndicator?: boolean | string | undefined;
  visibleParts?: AddressParts;
  className?: string;
  dataAddressSources?: IAddressSources | undefined;
}) => {
  const translate = useTranslate();
  label = label ?? translate('dxMessages.headers.address');

  return (
    <SimpleLabeledField
      label={label}
      data={buildAddressAsAString(
        address,
        missingPartIndicator,
        visibleParts,
        dataAddressSources
      )}
      oneLine
      className={className}
    />
  );
};
