import { get } from 'lodash';
import React from 'react';
import { useResourceContext } from 'react-admin';
import { AlfrescoDocumentService } from '../../services/AlfrescoDocumentService';
import { P2pPreviewData } from '../../shared/types';
import WayBillPreviewView from './WayBillPreviewView';
import WaybillSources, { IWayBillSources } from './WayBillSources';

interface PropsModel {
  record: any;
  resource?: string;
  toolbar?: any;
  basePath: any;
}

const WayBillPreviewController = (props: PropsModel) => {
  const { basePath, record, toolbar }: PropsModel = props;

  const resource = useResourceContext(props);

  let wayBillSources: WaybillSources;
  if (AlfrescoDocumentService.isWebDocument(record)) {
    wayBillSources = new WaybillSources(true);
  } else {
    wayBillSources = new WaybillSources(false);
  }
  const previewData: P2pPreviewData = {
    id: record.id,
    properties: record.properties,
    ublProperties: get(record, wayBillSources.WayBillSources().root.source),
    linesView: [],
  };
  const dataSources: IWayBillSources = {
    AmountSources: wayBillSources.AmountSources(),
    AddressSources: wayBillSources.AddressSources(),
    ContactSources: wayBillSources.ContactSources(),
    DocumentReferenceSources: wayBillSources.DocumentReferenceSources(),
    LocationSources: wayBillSources.LocationSources(),
    PartySources: wayBillSources.PartySources(),
    WayBillPartySources: wayBillSources.WayBillPartySources(),
    WayBillRootSources: wayBillSources.WayBillRootSources(),
    ShipmentStageSources: wayBillSources.ShipmentStageSources(),
    ConsignmentSources: wayBillSources.ConsignmentSources(),
    ShipmentInformationSources: wayBillSources.ShipmentInformationSources(),
  };

  return (
    <>
      <WayBillPreviewView
        {...{
          record: previewData,
          dataSources,
        }}
      />
      {toolbar &&
        React.createElement(toolbar, {
          record,
          resource,
          basePath,
        })}
    </>
  );
};

export default WayBillPreviewController;
