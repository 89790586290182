import { WebFormMode } from '@dx-ui/dx-common';
import { WayBillPreview } from '..';
import DocumentEdit from '../../common/DocumentEdit';

const WebWayBillEdit = (props) => {
  const { location } = props;
  const redirectMode = location?.state?.redirectMode;
  return (
    <DocumentEdit
      {...props}
      previewComponent={WayBillPreview}
      title={
        redirectMode && redirectMode === WebFormMode.SCRATCH_WAYBILL
          ? 'dxMessages.waybill.create_title'
          : 'dxMessages.waybill.edit_title'
      }
      mode={WebFormMode.DRAFT_WAYBILL}
    />
  );
};

export default WebWayBillEdit;
