import { Box } from '@material-ui/core';
import * as React from 'react';
import { FC } from 'react';
import { Datagrid, FieldProps, List, ListProps } from 'react-admin';
import Pagination from '../Pagination';
import { IdentityProvider } from '../types';
import { LOGO_AND_LABELS } from './';

/**
 * The list of identity providers supported by the Doc Process platform.
 *
 * Only visible for PSP administrators.
 */
export const IdentityProviderList: FC<ListProps> = (props) => (
  <List
    title='resources.identityProviders.list.title'
    pagination={<Pagination />}
    exporter={false}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <LogoField
        label='resources.identityProviders.list.headers.id'
        sortable={false}
      />
    </Datagrid>
  </List>
);

const LogoField: FC<FieldProps<IdentityProvider>> = ({ record }) => {
  if (!record) return null;

  if (!LOGO_AND_LABELS[record.id]) return <span>{record.id}</span>;

  return (
    <Box display='flex' alignItems='center'>
      <img
        src={LOGO_AND_LABELS[record.id].logo}
        alt={LOGO_AND_LABELS[record.id].label}
        style={{ width: '1em', height: '1em' }}
      />
      <span>&nbsp;{LOGO_AND_LABELS[record.id].label}</span>
    </Box>
  );
};
