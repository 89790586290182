import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { DxTheme } from '../types';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      flexWrap: 'nowrap',
      color: theme.palette.primary.main,
    },
    icon: {
      minWidth: theme.spacing(5),
    },
  }),
  { name: 'MenuItemsGroup' }
);

const MenuItemsGroup: FC<{
  label: string;
  icon: React.ReactElement;
}> = (props) => {
  const { label, icon, children } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems='center' className={classes.root}>
        <div className={classes.icon}>{icon}</div>
        {label.toUpperCase()}
      </Grid>
      {children}
    </>
  );
};

export default MenuItemsGroup;
