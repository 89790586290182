import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { DxTheme } from '../..';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    minWidth: {
      minWidth: 250,
    },
    confirmPrimary: {
      color: theme.palette.primary.main,
    },
    iconPaddingStyle: {
      paddingRight: '0.5em',
    },
  }),
  { name: 'SavedQueriesSaveDialog' }
);

export const SavedQueriesSaveDialog = ({
  open,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  onCancel: () => void;
  onConfirm: (queryLabel: string) => void;
}) => {
  const [queryLabel, setQueryLabel] = useState('');
  const translate = useTranslate();
  const classes = useStyles();

  useEffect(() => {
    setQueryLabel('');
  }, [open]);

  const handleQueryLabelChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setQueryLabel(event.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onConfirm(queryLabel);
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.minWidth}>
          <DialogContentText>
            {translate('dxMessages.filter.savedQueriesSaveText')}
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='query-label'
            fullWidth
            inputProps={{ maxLength: 30 }}
            value={queryLabel}
            onChange={handleQueryLabelChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>
            <AlertError className={classes.iconPaddingStyle} />
            {translate('ra.action.cancel')}
          </Button>
          <Button
            type='submit'
            className={classes.confirmPrimary}
            disabled={queryLabel === ''}
          >
            <ActionCheck className={classes.iconPaddingStyle} />
            {translate('ra.action.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
