import { ReceiptAdviceSubType, WebFormMode } from '@dx-ui/dx-common';
import { ReceiptAdvicePreview } from '..';
import DocumentEdit from '../../common/DocumentEdit';

const WebReceiptAdviceEdit = (props) => {
  const { location } = props;
  const redirectMode = location?.state?.redirectMode;
  const documentSubTypeCode = location?.state?.documentSubTypeCode;
  const getTitle = (redirectMode, documentSubTypeCode) => {
    let title = 'ra.action.edit';
    if (!location?.state) {
      return title;
    }
    if (redirectMode && redirectMode === WebFormMode.SCRATCH_RECEIPT_ADVICE) {
      if (
        documentSubTypeCode &&
        documentSubTypeCode === ReceiptAdviceSubType.GoodsReceiptAdvice
      ) {
        title = 'dxMessages.receiptAdvices.create_title';
      } else {
        title = 'dxMessages.receiptAdvices.create_goods_return_title';
      }
    } else {
      if (
        documentSubTypeCode &&
        documentSubTypeCode === ReceiptAdviceSubType.GoodsReceiptAdvice
      ) {
        title = 'dxMessages.receiptAdvices.edit_title';
      } else {
        title = 'dxMessages.receiptAdvices.edit_goods_return_title';
      }
    }
    return title;
  };

  return (
    <DocumentEdit
      {...props}
      previewComponent={ReceiptAdvicePreview}
      title={getTitle(redirectMode, documentSubTypeCode)}
      mode={WebFormMode.DRAFT_RECEIPT_ADVICE}
    />
  );
};

export default WebReceiptAdviceEdit;
