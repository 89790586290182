import inflection from 'inflection';
import { useEffect, useState } from 'react';
import {
  Record,
  ReduxState,
  useDataProvider,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { RelatedDocumentsService } from '../../services';

export interface RelatedDocumentsShowControllerProps {
  children: any;
  id: string;
  resource: string;
  version?: number;
  messages?: boolean;
  relatedDocuments?: boolean;
  attachDocuments?: boolean;
  linkedDocuments?: boolean;
}

const RelatedDocumentsShowController = ({
  children,
  id,
  resource,
  version,
  messages = true,
  relatedDocuments = true,
  attachDocuments = true,
  linkedDocuments = true,
}: RelatedDocumentsShowControllerProps) => {
  // this component will load documents based on currently selected resource
  const record = useSelector<ReduxState, Record | undefined>((state) =>
    state.admin.resources[resource]
      ? state.admin.resources[resource].data[id]
      : undefined
  );
  const translate = useTranslate();
  const redirect = useRedirect();

  const [allDocs, setAllDocs] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [sortMessage, setSortMessage] = useState(
    messages
      ? {
          field: 'id',
          order: 'DESC',
        }
      : null
  );
  const [sortRelated, setSortRelated] = useState(
    relatedDocuments ? { field: 'id', order: 'DESC' } : null
  );
  const [sortAttach, setSortAttach] = useState(
    attachDocuments ? { field: 'id', order: 'DESC' } : null
  );
  const [sortLinked, setSortLinked] = useState(
    linkedDocuments ? { field: 'id', order: 'DESC' } : null
  );

  const dataProvider = useDataProvider();
  const match = useRouteMatch();

  const setSort = (field: string, section: string) => {
    if (section === 'message') {
      const order =
        sortMessage === null ||
        (sortMessage?.field === field && sortMessage?.order === 'ASC')
          ? 'DESC'
          : 'ASC';
      setSortMessage({ field, order });
    }

    if (section === 'related') {
      const order =
        sortRelated === null ||
        (sortRelated?.field === field && sortRelated?.order === 'ASC')
          ? 'DESC'
          : 'ASC';
      setSortRelated({ field, order });
    }

    if (section === 'attach') {
      const order =
        sortAttach === null ||
        (sortAttach?.field === field && sortAttach?.order === 'ASC')
          ? 'DESC'
          : 'ASC';
      setSortAttach({ field, order });
    }

    if (section === 'linked') {
      const order =
        sortLinked === null ||
        (sortLinked?.field === field && sortLinked?.order === 'ASC')
          ? 'DESC'
          : 'ASC';
      setSortLinked({ field, order });
    }
  };

  const resourceName = translate(`resources.${resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(resource)),
  });

  const defaultTitle = translate('ra.page.show', {
    name: `${resourceName}`,
    id,
    record,
  });

  useEffect(() => {
    const updateData = async () => {
      setLoading(true);
      try {
        const service = new RelatedDocumentsService(dataProvider);
        const allDocs = await service.getAllDocs(
          id,
          sortMessage,
          sortRelated,
          sortLinked,
          sortAttach
        );

        if (allDocs) {
          setAllDocs(allDocs);
          setLoading(false);
        }
      } catch {
        redirect('/login');
      }
    };

    updateData();
  }, [
    dataProvider,
    id,
    match.params,
    sortAttach,
    sortLinked,
    sortMessage,
    sortRelated,
    version,
    redirect,
  ]);

  if (!children) {
    return null;
  }

  return children({
    isLoading: loading,
    defaultTitle,
    resource,
    record: allDocs,
    setSort,
    currentSortMessage: sortMessage,
    currentSortRelated: sortRelated,
    currentSortAttach: sortAttach,
    currentSortLinked: sortLinked,
    version,
  });
};

export default RelatedDocumentsShowController;
