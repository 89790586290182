// The latout of the task action.
import { colors } from '@dx-ui/dx-common';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import { FC } from 'react';
import { FieldProps, Task } from '../../../shared/types';
import GoToOtherApplicationSummary from './input/common/GoToOtherApplicationSummary';
import InvoiceSummaryField from './input/nwaymatching/InvoiceSummaryField';
import { INVOICE_ICONS } from './input/nwaymatching/types';
import OrderSummaryField from './input/ordernegotiation/common/OrderSummaryField';

const ActionCardInner: FC<FieldProps<Task<any>>> = (props: any) => {
  const { record, translate } = props;
  if (!record) return null;

  switch (`${record.flow.definitionKey}.${record.definitionKey}`) {
    case 'i2pr_matching.invoiceHoldDecisionTask':
    case 'i2o_matching.invoiceHoldDecisionTask': {
      const { virtualInvoice, correctionInvoiceChain } = record.flow.variables;
      return (
        <>
          <CardHeader
            title={translate(
              'dxMessages.task.invoice_matching.invoiceHoldDecisionTask.title'
            )}
            subheader={translate(
              'dxMessages.task.invoice_matching.invoiceHoldDecisionTask.subtitle'
            )}
          />
          <CardContent>
            <InvoiceSummaryField
              invoice={virtualInvoice}
              correctionInvoiceChain={correctionInvoiceChain}
              icon={INVOICE_ICONS(virtualInvoice?.InvoiceTypeCode.value, {
                fontSize: 30,
                color: colors.lightPurple,
              })}
            />
          </CardContent>
        </>
      );
    }
    case 'i2pr_matching.cancellationDecisionTask':
    case 'i2o_matching.cancellationDecisionTask': {
      const { invoice } = record.flow.variables;
      return (
        <>
          <CardHeader
            title={translate(
              'dxMessages.task.invoice_matching.cancellationDecisionTask.title'
            )}
            subheader={translate(
              'dxMessages.task.invoice_matching.cancellationDecisionTask.subtitle'
            )}
          />
          <CardContent>
            <InvoiceSummaryField
              invoice={record.flow.variables.invoice}
              icon={INVOICE_ICONS(invoice?.InvoiceTypeCode.value, {
                fontSize: 30,
                color: colors.lightPurple,
              })}
            />
          </CardContent>
        </>
      );
    }
    case 'simpleMultiLevelInvoiceValidation.invoiceValidationApprovalTask': {
      const { invoice } = record.flow.variables;
      return (
        <>
          <CardHeader
            title={translate('dxMessages.task.multimedia.card.title')}
            subheader={translate('dxMessages.task.multimedia.card.subheader')}
          />
          <CardContent>
            <InvoiceSummaryField
              invoice={record.flow.variables.invoice}
              icon={INVOICE_ICONS(invoice?.InvoiceTypeCode.value, {
                fontSize: 30,
                color: colors.lightPurple,
              })}
            />
          </CardContent>
        </>
      );
    }
    case 'invoice-waybill-cmr.SG_Validation_User_Task':
    case 'invoice-waybill-cmr.SG_Accounts_Payable_Validation_User_Task': {
      const invoice = record?.flow.variables?.invoice;
      const title =
        record.definitionKey === 'SG_Validation_User_Task'
          ? 'Invoice Validation'
          : 'Invoice Accounts Payable Validation';
      return (
        <>
          <CardHeader
            title={title}
            subheader='Please, approve or reject the invoice below:'
          />
          <CardContent>
            {invoice && (
              <InvoiceSummaryField invoice={record?.flow.variables?.invoice} />
            )}
          </CardContent>
        </>
      );
    }
    case 'order-negotiation.Supplier_Negotiation_Task': {
      return (
        <>
          <CardHeader
            title={translate('dxMessages.task.reginamaria.card.supplier.title')}
            subheader={translate(
              'dxMessages.task.reginamaria.card.supplier.subheader'
            )}
          />
          <CardContent>
            <OrderSummaryField
              order={record?.flow.variables?.order}
              icon={<AddShoppingCart />}
            />
          </CardContent>
        </>
      );
    }
    case 'order-negotiation.Client_Negotiation_Task': {
      return (
        <>
          <CardHeader
            title={translate('dxMessages.task.reginamaria.card.client.title')}
            subheader={translate(
              'dxMessages.task.reginamaria.card.client.subheader'
            )}
          />
          <CardContent>
            <OrderSummaryField
              order={record?.flow.variables?.order}
              icon={<AddShoppingCart />}
            />
          </CardContent>
        </>
      );
    }
    case 'dona-invoice.costCenterAllocationTask': {
      const { invoice } = record.flow.variables;
      return (
        <>
          <CardHeader
            title={translate('dxMessages.apm.title')}
            subheader={translate('dxMessages.apm.subheader')}
          />
          <CardContent>
            <GoToOtherApplicationSummary
              invoice={record.flow.variables.invoice}
              icon={INVOICE_ICONS(invoice?.InvoiceTypeCode.value, {
                fontSize: 30,
                color: colors.lightPurple,
              })}
            />
          </CardContent>
        </>
      );
    }
    case 'multi-user-serial-invoice-approval.invoiceValidationTask': {
      const { invoice } = record.flow.variables;
      return (
        <>
          <CardHeader
            title={translate('dxMessages.apmValidation.title')}
            subheader={translate('dxMessages.apmValidation.subheader')}
          />
          <CardContent>
            <GoToOtherApplicationSummary
              invoice={record.flow.variables.invoice}
              icon={INVOICE_ICONS(invoice?.InvoiceTypeCode.value, {
                fontSize: 30,
                color: colors.lightPurple,
              })}
              app='dxmatching'
            />
          </CardContent>
        </>
      );
    }
    default:
      return (
        <CardHeader
          title={record.flow.definitionKey}
          subheader={record.definitionKey}
        />
      );
  }
};

const ActionCard: FC<FieldProps<Task<any>>> = (props) => {
  return (
    <Card
      elevation={0}
      style={{
        background: 'none',
        maxWidth: '50em',
      }}
    >
      <ActionCardInner {...props} />
    </Card>
  );
};

export default ActionCard;
