import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import {
  AutocompleteInput,
  email,
  FieldProps,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useAuthProvider,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { isPspAdministrator } from '../../configuration';
import Job from '../../job/Job';
import { Account, AccountCreationFormValues } from '../../types';
import Identities from '../Identities';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
  selectOr: {
    position: 'relative',
    color: theme.palette.primary.main,
    marginTop: '10px',
    marginBottom: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  spanOr: {
    display: 'block',
    position: 'absolute',
    left: '10%',
    top: '-2px',
    marginLeft: '-25px',
    backgroundColor: '#fff',
    width: '50px',
    textAlign: 'center',
    fontWeight: 'bolder',
  },
  hrOr: {
    height: '1px',
    backgroundColor: theme.palette.primary.main,
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
}));

/**
 * The step in the account creation wizard where the user selects the person
 * the account is for.
 *
 * A PSP administrator can either choose an already existing person (from another
 * company managed by the platform) or create a new one.
 *
 * A company administrator can either choose an already existing person (from another
 * company he is responsible for) or create a new one on the current company.
 *
 * @see AccountCreate
 */
const PersonSelectionStep: FC<FieldProps<Account>> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { values } = useFormState<AccountCreationFormValues>({
    subscription: onValuesChangeSubscription,
  });
  const authProvider = useAuthProvider();
  const identity: any = authProvider.getIdentity?.();
  const { permissions, loaded } = usePermissions();

  if (!identity || !loaded || !permissions) return null;

  // If PSP administrator selects an existing person (personId set),
  // the 'new person' form must be disabled.
  const { personId } = values;

  // If company is not set into the form, default to the company of the current administrator.
  const companyId = values.company?.id || identity?.company.id;

  return (
    <Grid container direction='column' spacing={2} style={{ marginTop: '1em' }}>
      <>
        <Grid item>
          <Typography variant='caption'>
            {translate(
              'resources.accounts.create.wizard.steps.personSelection.subtitle'
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6'>
            {translate(
              'resources.accounts.create.wizard.steps.personSelection.selectPerson'
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={6}>
              <ReferenceInput
                label='resources.accounts.create.wizard.steps.personSelection.person'
                source='personId'
                filterToQuery={(searchText) => ({
                  email: [searchText],
                })}
                reference={
                  isPspAdministrator(permissions)
                    ? 'configuration-people'
                    : 'configuration-companyadminpeople'
                }
                fullWidth
              >
                <AutocompleteInput optionText='email' resettable={true} />
              </ReferenceInput>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className={classes.selectOr}>
            <hr className={classes.hrOr} />
            <Typography className={classes.spanOr}>
              {translate(
                'resources.accounts.create.wizard.steps.personSelection.or'
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <Typography
            variant='h6'
            style={{ color: !personId ? 'black' : 'lightgray' }}
          >
            {translate(
              'resources.accounts.create.wizard.steps.personSelection.createPerson'
            )}
          </Typography>
        </Grid>
      </>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Person {...props} disabled={!!personId} />
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Typography
                variant='h5'
                style={{ color: !personId ? 'black' : 'lightgray' }}
              >
                {translate(
                  'resources.accounts.create.wizard.steps.personSelection.job'
                )}
              </Typography>
              <Job
                companyId={companyId}
                disabled={!!personId}
                hasCreate={true}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {companyId && (
          <Identities {...props} companyId={companyId} disabled={!!personId} />
        )}
        {!companyId && (
          <Paper className={classes.paper}>
            <Typography
              variant='h5'
              style={{ color: !personId ? 'black' : 'lightgray' }}
            >
              {translate(
                'resources.accounts.create.wizard.steps.personSelection.authentication'
              )}
            </Typography>
            <Typography
              variant='caption'
              style={{ color: !personId ? 'black' : 'lightgray' }}
            >
              You must select a company in the previous step before setting how
              the user will login into the account.
            </Typography>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

const Person: FC<FieldProps<Account> & { disabled: boolean }> = ({
  disabled,
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  // Same i18n as the edit form.

  return (
    <Paper className={classes.paper}>
      <Typography
        variant='h5'
        style={{ color: !disabled ? 'black' : 'lightgray' }}
      >
        {translate('resources.accounts.edit.tabs.user.sections.person.title')}
      </Typography>
      <Typography
        variant='caption'
        style={{ color: !disabled ? 'black' : 'lightgray' }}
      >
        {translate(
          'resources.accounts.create.wizard.steps.personSelection.allFieldsMandatory'
        )}
      </Typography>
      <Grid container direction='column' spacing={0}>
        <Grid item>
          <TextInput
            label='resources.accounts.edit.tabs.user.sections.person.email'
            source='person.email'
            disabled={disabled}
            validate={[required(), email()]}
            helperText='resources.accounts.create.wizard.steps.personSelection.uniqueAmongCompanyEmails'
            fullWidth
          />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInput
                label='resources.accounts.edit.tabs.user.sections.person.firstname'
                source='person.firstname'
                validate={required()}
                disabled={disabled}
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <TextInput
                label='resources.accounts.edit.tabs.user.sections.person.lastname'
                source='person.lastname'
                validate={required()}
                disabled={disabled}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <SelectInput
            label='resources.accounts.edit.tabs.user.sections.person.language'
            source='person.language'
            choices={[
              {
                id: 'en',
                name: 'resources.accounts.edit.tabs.user.sections.person.english',
              },
              {
                id: 'ro',
                name: 'resources.accounts.edit.tabs.user.sections.person.romanian',
              },
              {
                id: 'fr',
                name: 'resources.accounts.edit.tabs.user.sections.person.french',
              },
            ]}
            validate={required()}
            disabled={disabled}
            helperText='resources.accounts.create.wizard.steps.personSelection.selectedLanguageOnLogin'
          />
        </Grid>
        <Grid item>
          <TextInput
            label='resources.accounts.edit.tabs.user.sections.person.phone'
            source='person.phone'
            disabled={disabled}
            helperText='resources.accounts.create.wizard.steps.personSelection.phoneHelper'
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

// Only trigger a render when the form values change.
const onValuesChangeSubscription = { values: true };

export default PersonSelectionStep;
