// A task UI to accept or reject a commercial invoice.
import { Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import compose from 'lodash/flowRight';
import React, { FC } from 'react';
import { required, TextInput, Title } from 'react-admin';
import {
  createColumnsProcessDetails,
  createCommonColumns,
} from '../../../../../aspects';
import { EnhancedProps, FieldProps, Task } from '../../../../../shared/types';
import InvoiceValidationField from './InvoiceValidationField';

const styles: any = (theme) => ({
  header: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
});

// The columns to display in the cluster panel.
const columns: any[] = createCommonColumns()
  .concat(createColumnsProcessDetails())
  .filter((column) => column.displayInCluster === true);

// Make the document ID not clickable as we have the preview in the expand panel.
Object.values(columns).forEach((value: any) => {
  if (value.id === 'edm:documentId') {
    value.readOnly = true;
    value.label = 'dxMessages.headers.documentId';
  }
});

const i18nKey =
  'dxMessages.task.invoice_matching.invoiceHoldDecisionTask.cockpit.';

// The UI cockpit.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const InvoiceValidationCockpit: FC<
  FieldProps<Task<InvoiceValidationCockpitVariables>> &
    EnhancedProps & { classes: any }
> = (props) => {
  const { locale, record } = props;
  if (!record) return null;

  return (
    <>
      <Title title={i18nKey + 'title'} />
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Paper
            square
            className={classNames(props.classes.paper, 'related-document')}
          >
            <InvoiceValidationField record={record} locale={locale} />
          </Paper>
        </Grid>
        <Grid item>
          <TextInput
            source='input.data.comment'
            label={i18nKey + 'comment'}
            fullWidth
            multiline
            rows='3'
            validate={required()}
          />
        </Grid>
      </Grid>
    </>
  );
};

interface InvoiceValidationCockpitVariables {
  // The invoice that needs validating.
  cluster: Array<ClusterDocument>;
  // The invoice under matching.
  invoice: UblInvoice;
}

type UblInvoice = any;

// The cluster is an invoice
interface ClusterDocument {
  type: 'invoice';
  dxuid: string;
  reason: 'target';
}

const enhance = compose(withStyles(styles, { withTheme: true }));

export default enhance(InvoiceValidationCockpit);
