import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import ExposureIcon from '@material-ui/icons/Exposure';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import SettingsIcon from '@material-ui/icons/Settings';
import { usePreferences } from '@react-admin/ra-preferences';
import moment from 'moment';
import { FC, useState } from 'react';
import { useLocale, useQuery, useTranslate } from 'react-admin';
import ReactCardFlip from 'react-card-flip';
import { DxTheme } from '../../../../types';
import cartouche from '../cartouche.png';
import cartoucheDark from '../cartoucheDark.png';

const I18N_KEY =
  'dxMessages.dashboard.widgets.TotalVariationOnAcceptedInvoiceLines';

/**
 * Displays the total count of invoices waiting for approval (all of them,
 * not only the ones assigned to the current user).
 */
const TotalVariationOnAcceptedInvoiceLines: FC<IWidgetContent> = ({
  onTheShelves,
  userPreferencesRootKey,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const [configuring, setConfiguring] = useState(false);
  const [after, setAfter] = usePreferences(
    `${userPreferencesRootKey}.Since`,
    moment().startOf('month').valueOf()
  );

  const onSinceChange = (e) => {
    const next = moment(e.target.value);
    if (next.isValid()) setAfter(next.valueOf());
  };

  return (
    <ReactCardFlip isFlipped={configuring} flipDirection='vertical'>
      <Card classes={{ root: classes.card }}>
        {!onTheShelves && (
          <div className={classes.configure}>
            <IconButton
              onClick={() => setConfiguring(!configuring)}
              size='small'
            >
              <SettingsIcon />
            </IconButton>
          </div>
        )}
        <div className={classes.main}>
          <Grid container>
            <Grid item xs={3}>
              <Box className='icon'>
                <ExposureIcon style={{ width: '48px', height: '48px' }} />
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='flex-end'
                justifyContent='flex-end'
              >
                <Box
                  // Need to put the width to prevent the Box from getting out the Grid.
                  style={{ width: '99%' }}
                >
                  {/* Double the label in a tooltip in case of truncature. */}
                  <Tooltip title={translate(`${I18N_KEY}.title`)}>
                    <Typography color='textSecondary' noWrap align='right'>
                      {translate(`${I18N_KEY}.title`)}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='flex-end'
                >
                  <Box mr={1}>
                    <Typography variant='caption'>
                      {translate(`${I18N_KEY}.Since`)}{' '}
                      {moment(after).format('ll')}
                    </Typography>
                  </Box>
                  {/* When on the shelves, do not fetch the real count. */}
                  {onTheShelves ? (
                    <Typography variant='h5' component='h2'>
                      -123.45 €
                    </Typography>
                  ) : (
                    <VariationsPerCurrency after={after} />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Card>
      <Card style={{ backgroundColor: theme.palette.action.selected }}>
        <div className={classes.configure}>
          <IconButton onClick={() => setConfiguring(!configuring)} size='small'>
            <KeyboardReturnIcon />
          </IconButton>
        </div>
        <Box ml={5} p='10px'>
          <TextField
            id='date'
            label={translate(`${I18N_KEY}.Since`)}
            helperText={translate(`${I18N_KEY}.Since_helper`)}
            type='date'
            defaultValue={moment(after).format('YYYY-MM-DD')}
            size='small'
            // fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onSinceChange}
          />
        </Box>
      </Card>
    </ReactCardFlip>
  );
};

const VariationsPerCurrency = ({ after }) => {
  const locale = useLocale();
  const translate = useTranslate();
  const theme = useTheme();

  // Be careful: useGetOne() breaks the browser on logout.
  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'nwaymatching-statistics',
    payload: { id: `totalVariations?after=${after}` },
  });

  if (error)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          error,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );
  if (loading) return <CircularProgress size='1em' />;

  const count = Object.keys(data.variations).length;

  return (
    <Tooltip title={translate(`${I18N_KEY}.helper`)}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-end'
        justifyContent='flex-end'
      >
        {Object.keys(data.variations).map((currency) => (
          <Box key={currency}>
            <Typography
              style={{
                // fontWeight: 'bolder',
                fontSize: `${1.35 / count}em`,
                color:
                  data.variations[currency] > 0
                    ? theme.palette.error.main
                    : theme.palette.success.main,
              }}
              noWrap
            >
              {data.variations[currency].toLocaleString(locale, {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Box>
        ))}
        {count === 0 && 'N/A'}
      </Box>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: DxTheme) => ({
  main: {
    padding: 16,
    background: `url(${
      theme.palette.type === 'dark' ? cartoucheDark : cartouche
    }) no-repeat`,
    '& .icon': {
      color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440',
    },
  },
  // Fixed into the bottom left corner.
  configure: {
    position: 'fixed',
    left: theme.spacing(0),
    bottom: theme.spacing(0),
  },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
  },
}));

export default TotalVariationOnAcceptedInvoiceLines;
