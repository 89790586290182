import { Chip, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { FieldTitle, InputHelperText, useTranslate } from 'react-admin';
import useInput from './useInput';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 100,
      marginBottom: 2,
      marginTop: 1,
    },
    input: {
      fontSize: 14,
    },
    listbox: {
      fontSize: 14,
      width: '100%',
    },
    endAdornment: {
      top: '50%',
    },
    clearIndicator: {
      '& svg': {
        fontSize: 16,
      },
    },
  }),
  { name: 'AutocompleteTextInput' }
);

const AutocompleteTextInput = (props: any) => {
  const {
    label,
    className,
    choices,
    optionText,
    optionValue,
    optionTooltip,
    optionAddRenderText,
    optionType,
    source,
    resource,
    helperText,
    titleSuffix,
    style,
    qaselector,
    defaultValue,
    ...rest
  } = props;

  const classes = useStyles();
  const translate = useTranslate();

  const convertStringToNumber = (value: any) => {
    if (typeof value === 'string') {
      const float = parseFloat(value);
      return isNaN(float) ? null : float;
    } else {
      return value;
    }
  };

  const {
    input,
    isRequired,
    meta: { error, submitError },
  } = useInput({
    defaultValue,
    parse: optionType === 'number' ? convertStringToNumber : undefined,
    resource,
    source,
    ...rest,
  });
  const filter = createFilterOptions();

  return (
    <Autocomplete
      style={style}
      className={className}
      classes={classes}
      {...input}
      onChange={(event, newValue: any) => {
        if (newValue?.inputValue !== undefined) {
          // Create a new value from the user input
          input.onChange(newValue.inputValue);
        } else if (newValue?.[optionValue] !== undefined) {
          // Standard selection
          input.onChange(newValue[optionValue]);
        } else {
          input.onChange(newValue);
        }
      }}
      onBlur={(event: any) => {
        const newValue = event.target.value;
        if (`${newValue}` !== `${input.value}`) {
          input.onChange(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            [optionText]: translate('dxMessages.inputs.add_new_entry', {
              entry: params.inputValue,
            }),
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={choices}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        let label: any = option;

        if (typeof option === 'string') {
          label = option;
        }

        if (typeof option === 'number') {
          label = `${option}`;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          label = option.inputValue;
        }
        if (option[optionText] !== undefined) {
          // Regular option
          label = option[optionText];
        }

        return label;
      }}
      renderOption={(option) => {
        if (option.inputValue && optionAddRenderText) {
          // Makes new option proposal displayed such as existing options
          // Example of expected syntax : "optionAddRenderText": "valueToRender %",
          return (
            <Typography className={classes.listbox}>
              {optionAddRenderText.replace('valueToRender', option.inputValue)}
            </Typography>
          );
        }
        if (option.inputValue && !optionAddRenderText) {
          return (
            <Typography className={classes.listbox}>
              {option.inputValue}
            </Typography>
          );
        }
        return option[optionTooltip] ? (
          <Tooltip title={option[optionTooltip]} arrow>
            <Typography className={classes.listbox}>
              {option[optionText]}
            </Typography>
          </Tooltip>
        ) : (
          option[optionText]
        );
      }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <>
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
              {titleSuffix &&
                titleSuffix.map((s) => {
                  return (
                    <Chip
                      label={s}
                      size='small'
                      style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                      key={s}
                    />
                  );
                })}
            </>
          }
          error={!!(error || submitError)}
          helperText={
            <InputHelperText
              touched={true}
              error={error || submitError}
              helperText={helperText}
            />
          }
          variant='filled'
          {...{ qaselector }}
          classes={{ root: classes.root }}
        />
      )}
    />
  );
};

export default AutocompleteTextInput;
