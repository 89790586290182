import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import {
  Datagrid,
  DateField,
  EmailField,
  FieldProps,
  Filter,
  FilterProps,
  List,
  ResourceContextProvider,
  SelectField,
  TextInput,
  useTranslate,
} from 'react-admin';
import AccountDiffPanel from '../../account/edition/AccountDiffPanel';
import { Account } from '../../types';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The tab with change history of all the company accounts.
 *
 * Only visible for administrators.
 */
const AccountsChangeHistoryTab: FC<FieldProps<Account>> = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (!record) return null;

  return (
    <div style={{ marginTop: '1em' }}>
      <Typography variant='caption'>
        {translate('resources.companies.edit.tabs.history.subtitle')}
      </Typography>
      <Paper className={classes.paper} style={{ marginTop: '1em' }}>
        <ResourceContextProvider value='accountlifecycleevents'>
          <List
            title=' '
            syncWithLocation
            component='div'
            basePath='/accountlifecycleevents'
            sort={{ field: 'id', order: 'ASC' }}
            filter={{ companyId: record.id }}
            filters={<ChangeFilters />}
            bulkActionButtons={false}
            exporter={false}
            empty={
              <span>
                {translate('resources.accounts.edit.tabs.history.empty')}
              </span>
            }
          >
            <Datagrid
              expand={<AccountDiffPanel />}
              isRowExpandable={(row) => row?.type === 'UPDATE'}
            >
              <EmailField
                label='resources.companies.edit.tabs.history.accountOf'
                source='source.person.email'
              />
              <SelectField
                label='resources.accounts.edit.tabs.history.changeType'
                source='type'
                choices={[
                  {
                    id: 'UPDATE',
                    name: 'resources.accounts.edit.tabs.history.update',
                  },
                  {
                    id: 'CREATION',
                    name: 'resources.accounts.edit.tabs.history.creation',
                  },
                  {
                    id: 'DELETION',
                    name: 'resources.accounts.edit.tabs.history.deletion',
                  },
                ]}
              />
              <DateField
                label='resources.companies.edit.tabs.history.when'
                source='when'
                showTime={true}
              />
              <EmailField
                label='resources.companies.edit.tabs.history.by'
                source='by.person.email'
              />
            </Datagrid>
          </List>
        </ResourceContextProvider>
      </Paper>
    </div>
  );
};

const ChangeFilters = (props: Omit<FilterProps, 'children'>) => {
  return (
    <Filter {...props}>
      <TextInput
        label='resources.accounts.edit.tabs.history.filters.username'
        source='username'
        placeholder='john.doe@inc.com'
        alwaysOn
      />
    </Filter>
  );
};

export default AccountsChangeHistoryTab;
