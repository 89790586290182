import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddressDetailsCreate from './AddressDetailsCreate';
import AddressDetailsEdit from './AddressDetailsEdit';
import AddressDetailsList from './AddressesDetailsList';

const address = {
  icon: LocationOnIcon,
  list: AddressDetailsList,
  edit: AddressDetailsEdit,
  create: AddressDetailsCreate,
};

export default address;
