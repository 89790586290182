import {
  Chip,
  FormControl,
  FormGroup,
  IconButton,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FieldTitle, useInput, useTranslate } from 'react-admin';
import { colors } from '../../constants';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    fixedLabel: {
      color: colors.black50,
      fontSize: 14,
      fontWeight: 700,
    },
    iconButton: {
      color: colors.black65,
      width: 24,
      height: 24,
      bottom: 12,
    },
    clearIcon: {
      height: 16,
      width: 16,
    },
    divider: {
      bottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
    },
    textFieldInput: {
      textAlign: 'right',
      width: 100,

      /* Chrome, Safari, Edge, Opera */
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },

      /* Firefox */
      '-moz-appearance': 'textfield',
    },
    test: {
      root: {
        background: 'red',
      },
    },
  }),
  { name: 'NumberRangeInput' }
);

const NumberRangeInput = (props) => {
  const { label, resource, titleSuffix } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const {
    input,
    meta: { touched, error },
  } = useInput(props);

  const [throttleTimer, setThrottleTimer] = useState<
    NodeJS.Timeout | undefined
  >(undefined);
  const [valueFrom, setValueFrom] = useState(undefined);
  const [valueTo, setValueTo] = useState(undefined);

  useEffect(() => {
    const setValues = () => {
      if (
        input.value &&
        input.value.from !== undefined &&
        input.value.to !== undefined
      ) {
        const valueFrom =
          input.value.from !== 'MIN' ? input.value.from : undefined;
        const valueTo = input.value.to !== 'MAX' ? input.value.to : undefined;
        setValueFrom(valueFrom);
        setValueTo(valueTo);
      } else if (
        input.value &&
        input.value.from === undefined &&
        input.value.to === undefined
      ) {
        setValueFrom(input.value);
        setValueTo(input.value);
      }
    };
    setValues();
  }, [input.value]);

  /*
   * Handles change for both "from" and "to" values
   */
  const handleChange = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    const newValue = evt.target.value || undefined;

    // Check which input has changed
    const isValueFrom = evt.target.id === 'valueFromId';
    let newValueFrom, newValueTo;

    if (throttleTimer) {
      clearTimeout(throttleTimer);
      setThrottleTimer(undefined);
    }
    // The change will be taken into account after throttleTimer
    if (isValueFrom) {
      newValueFrom = newValue;
      setValueFrom(newValueFrom);
      newValueTo = valueTo;
    } else {
      newValueTo = newValue;
      setValueTo(newValueTo);
      newValueFrom = valueFrom;
    }
    const newThrottleTimer = setTimeout(() => {
      // Call the client callback after throttleTimer
      if (!newValueFrom && newValueTo) {
        // If only valueTo is entered,
        // let's query for 0 <= ValueTo
        newValueFrom = 'MIN';
      } else if (!newValueTo && newValueFrom) {
        // If only valueFrom is entered,
        // let's query for valueFrom >= MAX
        newValueTo = 'MAX';
      }
      if (newValueFrom && newValueTo) {
        input.onChange({
          from: newValueFrom,
          to: newValueTo,
        });
      } else {
        // The user has cleared the latest value set.
        // Need to clear the query.
        input.onChange('');
        setValueFrom(undefined);
        setValueTo(undefined);
      }
      setThrottleTimer(undefined);
    }, 700);
    setThrottleTimer(newThrottleTimer);
  };

  const handleClear = (evt) => {
    evt.preventDefault();
    if (throttleTimer) {
      clearTimeout(throttleTimer);
      setThrottleTimer(undefined);
    }
    input.onChange('');
    setValueFrom(undefined);
    setValueTo(undefined);
  };

  const onBlur = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const onFocus = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  return (
    <FormGroup className={classes.container}>
      <FormControl>
        <TextField
          type='number'
          margin='normal'
          id='valueFromId'
          inputProps={{ className: classes.textFieldInput }}
          value={valueFrom ? valueFrom : ''}
          resource={resource}
          error={!!(touched && error)}
          helperText={touched && error}
          label={
            <>
              <FieldTitle label={translate(label, { _: label })} />
              {titleSuffix &&
                titleSuffix.map((s) => {
                  return (
                    <Chip
                      label={s}
                      size='small'
                      style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                      key={s}
                    />
                  );
                })}
            </>
          }
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleChange}
          InputLabelProps={{ variant: 'filled', margin: 'dense' }}
        />
      </FormControl>

      <FormControl className={classes.divider}>
        <span className={classes.fixedLabel}>_</span>
      </FormControl>

      <FormControl>
        <TextField
          type='number'
          margin='normal'
          id='valueToId'
          inputProps={{ className: classes.textFieldInput }}
          value={valueTo ? valueTo : ''}
          resource={resource}
          error={!!(touched && error)}
          helperText={touched && error}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleChange}
        />
      </FormControl>

      {(valueTo || valueFrom) && (
        <FormControl>
          <IconButton
            onClick={handleClear}
            className={classes.iconButton}
            aria-label={translate('ra.action.clear_input_value')}
            title={translate('ra.action.clear_input_value')}
            disableRipple
          >
            <ClearIcon className={classes.clearIcon} />
          </IconButton>
        </FormControl>
      )}
    </FormGroup>
  );
};

NumberRangeInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
};

NumberRangeInput.defaultProps = {
  throttle: true,
};

export default NumberRangeInput;
