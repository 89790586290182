import { gql, useQuery } from '@apollo/client';
import { DxTheme, useSetupUsage } from '@dx-ui/dx-common/src';
import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import { SetupUsage } from '@dx-ui/dx-common/src/utils/useSetupUsage';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import LegendIcon from '@material-ui/icons/Stop';
import { groupBy, round } from 'lodash';
import { FC, useState } from 'react';
import { Identifier, useLocale, useTranslate } from 'react-admin';
import ReactCardFlip from 'react-card-flip';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Tooltip as RcTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import TotalAmountOwedTopTenDemo from '../demo/TotalAmountOwedTopTenDemo';

export const DUE_DATE_INTERVALS = [
  'current',
  'overdue_1_30',
  'overdue_31_60',
  'overdue_61_90',
  'overdue_91_180',
  'overdue_beyond_180',
];
const I18N_KEY = 'dxMessages.dashboard.widgets.TotalAmountOwedTopTen';

const TotalAmountOwedTopTen: FC<IWidgetContent> = ({
  account,
  onTheShelves,
  userPreferencesRootKey,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const [help, setHelp] = useState(false);

  const setupUsage = useSetupUsage();

  return (
    // Flip the card to display the help.
    <ReactCardFlip isFlipped={help} flipDirection='vertical'>
      <Card classes={{ root: classes.card }}>
        {!onTheShelves && (
          <div className={classes.help}>
            <IconButton onClick={() => setHelp(!help)} size='small'>
              <HelpIcon />
            </IconButton>
          </div>
        )}
        <CardHeader title={translate(`${I18N_KEY}.title`)} />
        <CardContent style={{ marginBottom: '1em' }}>
          {/* MUST set a height for the diagram. */}
          <div style={{ width: '100%', height: '35vh' }}>
            {onTheShelves || setupUsage !== SetupUsage.PRODUCTION ? (
              <TotalAmountOwedTopTenDemo
                userPreferencesRootKey={userPreferencesRootKey}
              />
            ) : (
              // Only demo values for the moment.
              // <TotalAmountOwedTopTenLive
              //   companyId={account.company.id}
              //   userPreferencesRootKey={userPreferencesRootKey}
              // />
              <TotalAmountOwedTopTenDemo
                userPreferencesRootKey={userPreferencesRootKey}
              />
            )}
          </div>
        </CardContent>
      </Card>
      {/* Display the guide for this widget.  */}
      <Card style={{ backgroundColor: theme.palette.grey[50] }}>
        <div className={classes.help}>
          <IconButton onClick={() => setHelp(!help)} size='small'>
            <KeyboardReturnIcon />
          </IconButton>
        </div>
        <Box m={1} mt={2} p={1}>
          <Help />
        </Box>
      </Card>
    </ReactCardFlip>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TotalAmountOwedTopTenLive: FC<{
  companyId: Identifier;
  userPreferencesRootKey: string;
}> = ({ companyId, userPreferencesRootKey }) => {
  const translate = useTranslate();
  // Ask the analytic endpoint for the amount due for the user company.
  const { loading, error, data } = useQuery(GRAPHQL_REQUEST, {
    variables: {
      client:
        process.env.NODE_ENV === 'production'
          ? companyId
          : '001G_xGUkNWTwXqRdHxm15oh9OegSKvYtpuLBgd6oFZgXRdNOasspAh',
    },
  });

  if (error)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          error,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );

  return (
    <CashCollectionBarChart
      loading={loading}
      dueDateIntervals={DUE_DATE_INTERVALS}
      data={data}
      userPreferencesRootKey={userPreferencesRootKey}
    />
  );
};

export const CashCollectionBarChart: FC<{
  loading: boolean;
  data: any;
  dueDateIntervals: any;
  userPreferencesRootKey: string;
}> = ({ loading, data, dueDateIntervals, userPreferencesRootKey }) => {
  const translate = useTranslate();

  if (loading) return <CircularProgress size='1em' />;

  // No data, no bar chart.
  if (data.metrics.length === 0)
    return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;
  const clientAndCount = groupBy(
    data.metrics.map((_) => ({
      interval: _.interval,
      amount: round(_.value, 2),
      currencyCode: _.currencyCode,
      client: _.client,
    })),
    ({ client }) => client
  );
  const metrics = Object.keys(clientAndCount).map((client) =>
    clientAndCount[client].reduce((acc, t) => {
      acc.currencyCode = t.currencyCode;
      acc.client = t.client;
      acc[t.interval] = t.amount + (acc[t.interval] || 0);
      return acc;
    }, {})
  );
  return (
    <OverdueBarChart
      data={metrics}
      dueDateIntervals={dueDateIntervals}
      sessionStorageRootKey={userPreferencesRootKey}
    />
  );
};

const OverdueBarChart: FC<{
  data: any[];
  dueDateIntervals: any;
  sessionStorageRootKey: string;
}> = ({ data, dueDateIntervals, sessionStorageRootKey }) => {
  const locale = useLocale();

  return (
    /* Set a 99% width to fix the window down size pb. */
    <ResponsiveContainer width='99%' height='100%'>
      <BarChart
        data={[...data].sort((s1, s2) => {
          let sum_s1 = 0;
          let sum_s2 = 0;
          DUE_DATE_INTERVALS.forEach((interval) => {
            sum_s1 = sum_s1 + s1[interval];
            sum_s2 = sum_s2 + s2[interval];
          });
          return (sum_s1 - sum_s2) * -1;
        })}
        margin={{
          top: 5,
          right: 30,
          left: 40,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='client' tickLine={false} interval={0} />
        <YAxis allowDecimals={false}>
          <Label value={data[0]?.currencyCode} position='left' />
        </YAxis>
        {/* @ts-ignore */}
        <RcTooltip content={<BarTooltip locale={locale} />} />

        {dueDateIntervals.map((interval, index) => (
          <Bar
            key={`${interval}`}
            name={`${interval}`}
            dataKey={interval}
            stackId='a'
            fill={barColor(index)}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

/**
 * Defines the fill for the current due date interval
 * @param index bar index
 * @returns color code as a string
 */
const barColor = (index) => {
  const colors = [
    '#93C47D',
    '#FFE599',
    '#F1C232',
    '#FF9900',
    '#E06666',
    '#660000',
  ];
  return colors[index];
};

// The tooltip per dated bars: the date and the list of amounts.
const BarTooltip = ({ active, payload, label, locale }) => {
  const translate = useTranslate();
  return active && payload && payload.length ? (
    <div
      style={{
        // Use a semi-transparent background so the adjacent bars are not hidden
        // (and use can still compare bare values).
        background: 'rgba(210, 210, 210, 0.9)',
        border: '1px solid black',
        padding: '5px',
      }}
    >
      <Typography>{label}</Typography>
      <ul>
        {payload.map((_) => (
          <li key={_.name}>
            <Typography>
              <LegendIcon
                fontSize='small'
                style={{
                  color: _.color,
                  position: 'relative',
                  bottom: '-0.25em',
                }}
              />
              {translate(`${I18N_KEY}.intervals.${_.name}`)}:{' '}
              {new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: _.payload.currencyCode,
              }).format(_.value)}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

const Help: FC<{}> = () => {
  const locale = useLocale();
  const translate = useTranslate();
  const renderLegend = () => {
    return (
      <ul>
        {DUE_DATE_INTERVALS.map((interval, index) => (
          <li key={index}>
            <LegendIcon
              fontSize='small'
              style={{
                color: barColor(index),
                position: 'relative',
                bottom: '-0.25em',
              }}
            />
            {translate(`${I18N_KEY}.intervals.${interval}`)}
          </li>
        ))}
      </ul>
    );
  };
  switch (locale) {
    case 'fr':
      return (
        <>
          <Typography paragraph>
            Ce graphique affiche, pour les 10 premiers clients, le montant total
            actuellement dû.
          </Typography>
          <Typography paragraph>Légende :</Typography>
          {renderLegend()}
        </>
      );
    case 'ro':
      return (
        <>
          <Typography paragraph>
            Graficul afișează, pentru primii 10 clienți, suma totală datorată în
            prezent.
          </Typography>
          <Typography paragraph>Legendă :</Typography>
          {renderLegend()}
        </>
      );
    default:
      return (
        <>
          <Typography paragraph>
            The chart displays, for the top 10 clients, the total amount
            currently owed.
          </Typography>
          <Typography paragraph>Legend :</Typography>
          {renderLegend()}
        </>
      );
  }
};

const useStyles = makeStyles((theme: DxTheme) => ({
  // Fixed into the top right corner.
  help: {
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(0),
  },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
  },
}));

export const GRAPHQL_REQUEST = gql`
  query DailyDocumentMetrics(
    $client: String!
    $startDate: String!
    $endDate: String!
  ) {
    dailyDocumentMetrics(
      client: $client
      startDate: $startDate
      endDate: $endDate
    ) {
      metrics {
        name
        value
        dimensions {
          dateDimension {
            date
          }
        }
      }
    }
  }
`;

export default TotalAmountOwedTopTen;
