import { Constants, DxTheme, isPspAdministrator } from '@dx-ui/dx-common/src';
import { Company } from '@dx-ui/dx-common/src/configuration/types';
import UserProfile from '@dx-ui/dx-common/src/login/MyAccount/UserProfile';
import { Box, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Edit, Inbox } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import { set, split } from 'lodash';
import { FC, useState } from 'react';
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  EditProps,
  ExportButton,
  FieldProps,
  FunctionField,
  List,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useListContext,
  useLocale,
  usePermissions,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { CountryService } from '../../../../services';
import SupportedProductsCreate from '../../supportedproducts/SupportedProductsCreate';
import AccountRelationCreate from '../accountrelation/AccountRelationCreate';
import AccountRelationEdit from '../accountrelation/AccountRelationEdit';
import AddressDetailsCreate from '../addresses/AddressDetailsCreate';
import AddressDetailsEdit from '../addresses/AddressDetailsEdit';
import ContactCreate from '../contact/ContactCreate';
import ContactEdit from '../contact/ContactEdit';
import ProductUsageCreate from '../products/ProductUsageCreate';
import ProductUsageEdit from '../products/ProductUsageEdit';
import UsersCreate from '../users/UsersCreate';
import AccountDetailsEdit from './AccountDetailsEdit';

const useStyles = makeStyles((theme: DxTheme) => ({
  box: {
    display: 'inline-block',
    marginRight: '200px',
    width: '320px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  emptyMessageStyle: {
    textAlign: 'center',
    opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
    margin: '0 1em',
    color:
      theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
  },
  emptyListIcon: {
    width: '9em',
    height: '9em',
  },
}));

/**
 * The edition of a person.
 *
 * Only visible for PSP administrators.
 */
const AccountDetailsShow: FC<EditProps> = (props) => {
  const { permissions } = usePermissions();
  const refresh = useRefresh();
  let id = props.id;
  const ErrorIcon = withStyles((theme) => ({
    root: {
      color: theme.palette.error.main,
    },
  }))(ClearIcon);
  const endsWithNumber = (text) => {
    return /\d$/.test(text);
  };
  const classes = useStyles();
  const currentURL = window.location.href;
  const record: any = { id: props.id };
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);

  const locale = useLocale();
  const countryService = new CountryService(locale);
  const countries = countryService.getCountries();
  const countryItems = countries.map((country) => {
    return {
      id: country.code,
      name: country.name,
    };
  });

  const labelCreate: any = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Add style={{ marginRight: '5px' }} />
      {'Create'}
    </div>
  );

  const labelEdit: any = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Edit style={{ marginRight: '5px' }} />
      {'Edit'}
    </div>
  );

  const CompanyTitle: FC<FieldProps<Company>> = ({ record }) => {
    const translate = useTranslate();

    if (!record) return null;
    return (
      <span>
        {translate('resources.companies.edit.title', {
          companyName: record.accountname,
        })}
      </span>
    );
  };

  const redirectCreateArea = () => {
    setCreate(true);
  };

  const MyPagination = (props) => (
    <Pagination rowsPerPageOptions={[5, 10]} {...props} />
  );

  const Empty = () => {
    const { resource } = useListContext();
    const { emptyMessageStyle, emptyListIcon } = useStyles();
    const translate = useTranslate();
    const resourceName = translate(`resources.${resource}.name`);
    const emptyMessage = translate('ra.page.empty', { name: resourceName });
    return (
      <Box textAlign='center' m={1} className={emptyMessageStyle}>
        <Inbox className={emptyListIcon} />
        <Typography variant='h4' paragraph>
          {emptyMessage}
        </Typography>
        <Button
          label={labelCreate}
          color='primary'
          onClick={redirectCreateArea}
          style={{ opacity: '1' }}
          translate='no'
        />
      </Box>
    );
  };

  const CustomToolbar = () => {
    return (
      <TopToolbar>
        <Button
          label={labelCreate}
          color='primary'
          onClick={redirectCreateArea}
          style={{ display: !create ? 'flex' : 'none' }}
          translate='no'
        />
        <ExportButton maxResults={5000} />
      </TopToolbar>
    );
  };

  const [newProps, setNewProps]: any = useState({});
  const CustomEdit = (props) => {
    return (
      <Button
        label={labelEdit}
        color='primary'
        onClick={() => {
          setNewProps(props);
          setEdit(true);
        }}
        translate='no'
      />
    );
  };

  return (
    <Show
      {...props}
      title={<CompanyTitle />}
      actions={
        <TopToolbar>
          {!endsWithNumber(currentURL) && (
            <EditButton
              basePath={props.basePath}
              resource={props.resource}
              record={record}
            >
              <AccountDetailsEdit />
            </EditButton>
          )}
        </TopToolbar>
      }
    >
      <TabbedShowLayout>
        <Tab
          label='summary'
          onClick={() => {
            setCreate(false);
            setEdit(false);
          }}
        >
          <TextField
            label={'dxMessages.companyManagement.account.name'}
            source='accountname'
            className={classes.box}
            fullWidth
          />
          <TextField
            label={'dxMessages.companyManagement.account.vatCode'}
            source='identification'
            className={classes.box}
            fullWidth
          />
          <SelectField
            label={'dxMessages.companyManagement.account.country'}
            source='countryCode'
            className={classes.box}
            choices={countryItems}
          />
          <SelectField
            label={'dxMessages.companyManagement.account.idstatus'}
            source='idstatus'
            className={classes.box}
            fullWidth
            choices={[
              { id: 1, name: 'Active' },
              { id: 2, name: 'Not Active' },
              { id: 3, name: 'Suspended' },
              { id: 4, name: 'Terminat' },
            ]}
            defaultValue={1}
          />
          {isPspAdministrator(permissions) && (
            <ReferenceField
              label='dxMessages.companyManagement.account.idformattype'
              source='idformattype'
              reference='management/formattype'
              link={false}
              className={classes.box}
            >
              <TextField source='description' fullWidth />
            </ReferenceField>
          )}
          {isPspAdministrator(permissions) && (
            <ReferenceField
              label='dxMessages.companyManagement.account.idformattypedesadv'
              source='idformattypedesadv'
              reference='management/formattype'
              link={false}
              className={classes.box}
            >
              <TextField source='description' fullWidth />
            </ReferenceField>
          )}
          <FunctionField
            label={'dxMessages.companyManagement.account.registryNumber'}
            className={classes.box}
            render={(record) => {
              if (record?.traderegnr?.indexOf('*') !== -1) {
                set(
                  record as any,
                  'tradeRegisterNumber',
                  split(record?.traderegnr, '*')?.[0]
                );
              } else {
                set(record as any, 'tradeRegisterNumber', record?.traderegnr);
              }
              return (
                <TextField source='tradeRegisterNumber' sortable={false} />
              );
            }}
          />
          <FunctionField
            label={'dxMessages.companyManagement.account.corporateStock'}
            className={classes.box}
            render={(record) => {
              //
              if (record?.traderegnr?.indexOf('*') !== -1) {
                set(
                  record as any,
                  'corporateStockAmount',
                  split(record?.traderegnr, '*')?.[1]
                );
              }
              return (
                <TextField source='corporateStockAmount' record={record} />
              );
            }}
          />
          <DateField
            label={'dxMessages.companyManagement.audit.tsChange'}
            source='tsChange'
            sortable={false}
            className={classes.box}
            fullWidth
          />
          <TextField
            label={'dxMessages.companyManagement.audit.userIdChange'}
            source='userIdChange'
            sortable={false}
            className={classes.box}
            fullWidth
          />
          <BooleanField
            label='dxMessages.companyManagement.account.v3Synchronize'
            source='v3Synchronize'
            sortable={false}
            className={classes.box}
            // @ts-ignore
            FalseIcon={ErrorIcon}
          />
        </Tab>
        <Tab
          label='addresses'
          onClick={() => {
            setCreate(false);
            setEdit(false);
          }}
        >
          {create === false && edit === false ? (
            <ReferenceManyField
              reference={Constants.RESOURCE_ADDRESSES}
              filter={{ companyId: id }}
              target='companyId'
              addLabel={false}
              perPage={10}
            >
              <List
                {...props}
                pagination={<MyPagination />}
                empty={<Empty />}
                actions={<CustomToolbar />}
              >
                <Datagrid>
                  <TextField
                    label={'dxMessages.companyManagement.addresses.street'}
                    source='street'
                  />
                  <TextField
                    label={
                      'dxMessages.companyManagement.addresses.additionalStreet'
                    }
                    source='additionalStreet'
                  />
                  <TextField
                    label={
                      'dxMessages.companyManagement.addresses.buildingNumber'
                    }
                    source='buildingNumber'
                  />
                  <TextField
                    label={'dxMessages.companyManagement.addresses.postBox'}
                    source='postBox'
                  />
                  <TextField
                    label={'dxMessages.companyManagement.addresses.city'}
                    source='city'
                  />
                  <TextField
                    label={'dxMessages.companyManagement.addresses.postalCode'}
                    source='postalCode'
                  />
                  <TextField
                    label={
                      'dxMessages.companyManagement.addresses.financialAccount'
                    }
                    source='financialAccount'
                  />
                  <TextField
                    label={'dxMessages.companyManagement.addresses.bank'}
                    source='bank'
                  />
                  <TextField
                    label={'dxMessages.companyManagement.addresses.gln'}
                    source='gln'
                  />
                  <TextField
                    label={'dxMessages.companyManagement.addresses.country'}
                    source='country'
                    sortable={false}
                  />
                  <BooleanField
                    label={'dxMessages.companyManagement.addresses.flgBilling'}
                    source='flgBilling'
                    sortable={false}
                    // @ts-ignore
                    FalseIcon={ErrorIcon}
                  />
                  <BooleanField
                    label={'dxMessages.companyManagement.addresses.flgShipping'}
                    source='flgShipping'
                    sortable={false}
                    // @ts-ignore
                    FalseIcon={ErrorIcon}
                  />
                  <BooleanField
                    label={'dxMessages.companyManagement.addresses.flgLogistic'}
                    source='flgLogistic'
                    sortable={false}
                    // @ts-ignore
                    FalseIcon={ErrorIcon}
                  />
                  <CustomEdit {...props} />
                  <DeleteWithConfirmButton
                    onSuccess={() => {
                      refresh();
                    }}
                  />
                </Datagrid>
              </List>
            </ReferenceManyField>
          ) : create === true ? (
            <AddressDetailsCreate {...props} setCreate={setCreate} />
          ) : (
            <AddressDetailsEdit props={newProps} setEdit={setEdit} />
          )}
        </Tab>
        <Tab
          label='supported products'
          onClick={() => {
            setCreate(false);
            setEdit(false);
          }}
        >
          {create === false ? (
            <>
              <ReferenceManyField
                reference={Constants.RESOURCE_SUPPORTED_PRODUCTS}
                filter={{ idaccounts: id }}
                target='idaccounts'
                addLabel={false}
                perPage={10}
              >
                <List
                  {...props}
                  pagination={<MyPagination />}
                  actions={<CustomToolbar />}
                  empty={<Empty />}
                >
                  <Datagrid>
                    <ReferenceField
                      label={
                        'dxMessages.companyManagement.productUsage.product'
                      }
                      source='iddxproduct'
                      reference='management/dxproduct'
                      link={false}
                      sortable={false}
                    >
                      <TextField source='description' />
                    </ReferenceField>
                    <DeleteWithConfirmButton
                      onSuccess={() => {
                        refresh();
                      }}
                    />
                  </Datagrid>
                </List>
              </ReferenceManyField>
            </>
          ) : (
            <SupportedProductsCreate {...props} setCreate={setCreate} />
          )}
        </Tab>
        <Tab
          label='contact'
          onClick={() => {
            setCreate(false);
            setEdit(false);
          }}
        >
          {create === false && edit === false ? (
            <ReferenceManyField
              reference={Constants.RESOURCE_CONTACT}
              filter={{ companyId: id }}
              target='companyId'
              addLabel={false}
              perPage={10}
            >
              <List
                {...props}
                pagination={<MyPagination />}
                actions={<CustomToolbar />}
                empty={<Empty />}
              >
                <Datagrid>
                  <TextField
                    label={'dxMessages.companyManagement.contact.lastName'}
                    source='lastName'
                  />
                  <TextField
                    label={'dxMessages.companyManagement.contact.firstName'}
                    source='firstName'
                  />
                  <TextField
                    label={'dxMessages.companyManagement.contact.phone'}
                    source='phone'
                    sortable={false}
                  />
                  <TextField
                    label={'dxMessages.companyManagement.contact.mail'}
                    source='mail'
                    sortable={false}
                  />
                  <CustomEdit {...props} />
                </Datagrid>
              </List>
            </ReferenceManyField>
          ) : create === true ? (
            <ContactCreate {...props} setCreate={setCreate} />
          ) : (
            <ContactEdit props={newProps} setEdit={setEdit} />
          )}
        </Tab>
        <Tab
          label='contracted products'
          onClick={() => {
            setCreate(false);
            setEdit(false);
          }}
        >
          {create === false && edit === false ? (
            <ReferenceManyField
              reference={Constants.RESOURCE_PRODUCTUSAGE}
              filter={{ idaccounts: id }}
              target='idaccounts'
              addLabel={false}
              perPage={10}
            >
              <List
                {...props}
                pagination={<MyPagination />}
                actions={<CustomToolbar />}
                empty={<Empty />}
              >
                <Datagrid>
                  <ReferenceField
                    label={'dxMessages.companyManagement.productUsage.product'}
                    source='iddxproduct'
                    reference='management/dxproduct'
                    link={false}
                    sortable={false}
                  >
                    <TextField source='description' />
                  </ReferenceField>
                  <TextField
                    label={
                      'dxMessages.companyManagement.productUsage.totalusage'
                    }
                    source='totalcount'
                    sortable={false}
                  />
                  <TextField
                    label={
                      'dxMessages.companyManagement.productUsage.totalcredit'
                    }
                    source='totalcredit'
                    sortable={false}
                  />
                  <FunctionField
                    label={
                      'dxMessages.companyManagement.productUsage.advailible'
                    }
                    render={(record) => {
                      return record?.totalcredit - record?.totalcount;
                    }}
                    sortable={false}
                  />
                  <TextField
                    label={'dxMessages.companyManagement.productUsage.thresold'}
                    source='thresold'
                    sortable={false}
                  />
                  <CustomEdit {...props} />
                  <DeleteWithConfirmButton
                    onSuccess={() => {
                      refresh();
                    }}
                  />
                </Datagrid>
              </List>
            </ReferenceManyField>
          ) : create === true ? (
            <ProductUsageCreate
              {...props}
              setEdit={setEdit}
              setCreate={setCreate}
              setNewProps={setNewProps}
            />
          ) : (
            <ProductUsageEdit props={newProps} setEdit={setEdit} />
          )}
        </Tab>
        <Tab
          label='suppliers'
          onClick={() => {
            setCreate(false);
            setEdit(false);
          }}
        >
          {create === false && edit === false ? (
            <ReferenceManyField
              reference={Constants.RESOURCE_SUPPLIERS}
              filter={{ idcustomer: id }}
              target='idcustomer'
              addLabel={false}
              perPage={10}
            >
              <List
                {...props}
                pagination={<MyPagination />}
                actions={<CustomToolbar />}
                empty={<Empty />}
              >
                <Datagrid>
                  <ReferenceField
                    label='dxMessages.companyManagement.accountRelation.customer'
                    source='idcustomer'
                    reference='management/company'
                    link={false}
                    sortable={false}
                  >
                    <TextField source='accountname' />
                  </ReferenceField>
                  <ReferenceField
                    label='dxMessages.companyManagement.accountRelation.supplier'
                    source='idsupplier'
                    reference='management/company'
                    link={false}
                    sortable={false}
                  >
                    <TextField source='accountname' />
                  </ReferenceField>
                  <TextField
                    label='dxMessages.companyManagement.accountRelation.suppliercode4customer'
                    source='supplierCode4Customer'
                  />
                  <CustomEdit {...props} />
                  <DeleteWithConfirmButton
                    onSuccess={() => {
                      refresh();
                    }}
                  />
                </Datagrid>
              </List>
            </ReferenceManyField>
          ) : create === true ? (
            <AccountRelationCreate {...props} setCreate={setCreate} />
          ) : (
            <AccountRelationEdit props={newProps} setEdit={setEdit} />
          )}
        </Tab>
        <Tab
          label='customers'
          onClick={() => {
            setCreate(false);
            setEdit(false);
          }}
        >
          {create === false && edit === false ? (
            <ReferenceManyField
              reference={Constants.RESOURCE_SUPPLIERS}
              filter={{ idsupplier: id }}
              target='idsupplier'
              addLabel={false}
              perPage={10}
            >
              <List
                {...props}
                pagination={<MyPagination />}
                actions={<CustomToolbar />}
                empty={<Empty />}
              >
                <Datagrid>
                  <ReferenceField
                    label='dxMessages.companyManagement.accountRelation.customer'
                    source='idcustomer'
                    reference='management/company'
                    link={false}
                    sortable={false}
                  >
                    <TextField source='accountname' />
                  </ReferenceField>
                  <ReferenceField
                    label='dxMessages.companyManagement.accountRelation.customerId'
                    source='idcustomer'
                    reference='management/company'
                    link={false}
                    sortable={false}
                  >
                    <TextField source='identification' />
                  </ReferenceField>
                  <ReferenceField
                    label='dxMessages.companyManagement.accountRelation.supplier'
                    source='idsupplier'
                    reference='management/company'
                    link={false}
                    sortable={false}
                  >
                    <TextField source='accountname' />
                  </ReferenceField>
                  <ReferenceField
                    label='dxMessages.companyManagement.accountRelation.supplierId'
                    source='idsupplier'
                    reference='management/company'
                    link={false}
                    sortable={false}
                  >
                    <TextField source='identification' />
                  </ReferenceField>
                  <TextField
                    label='dxMessages.companyManagement.accountRelation.suppliercode4customer'
                    source='supplierCode4Customer'
                  />
                  <CustomEdit {...props} />
                  <DeleteWithConfirmButton
                    onSuccess={() => {
                      refresh();
                    }}
                  />
                </Datagrid>
              </List>
            </ReferenceManyField>
          ) : create === true ? (
            <AccountRelationCreate {...props} setCreate={setCreate} />
          ) : (
            <AccountRelationEdit props={newProps} setEdit={setEdit} />
          )}
        </Tab>
        <Tab
          label='users'
          onClick={() => {
            setCreate(false);
            setEdit(false);
          }}
        >
          {create === false && edit === false ? (
            <ReferenceManyField
              reference={Constants.RESOURCE_USERS}
              filter={{ companyId: id }}
              target='companyId'
              addLabel={false}
              perPage={10}
            >
              <List
                {...props}
                pagination={<MyPagination />}
                actions={<CustomToolbar />}
                empty={<Empty />}
              >
                <Datagrid>
                  <ReferenceField
                    label='resources.accounts.list.headers.company'
                    source='companyId'
                    reference='management/company'
                    link={false}
                    sortable={false}
                  >
                    <TextField source='accountname' />
                  </ReferenceField>
                  <TextField
                    label={'dxMessages.companyManagement.users.userId'}
                    source='id'
                  />
                  <TextField
                    label={'dxMessages.myAccount.lastName'}
                    source='lastName'
                  />
                  <DeleteWithConfirmButton
                    onSuccess={() => {
                      refresh();
                    }}
                  />
                  <CustomEdit {...props} />
                </Datagrid>
              </List>
            </ReferenceManyField>
          ) : create === true ? (
            <UsersCreate {...props} setCreate={setCreate} />
          ) : (
            <UserProfile
              props={newProps}
              edit={edit}
              setEdit={setEdit}
              v2Account={true}
            />
          )}
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default AccountDetailsShow;
