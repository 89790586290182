import * as React from 'react';
import { FC } from 'react';
import {
  Datagrid,
  EmailField,
  Filter,
  FilterProps,
  List,
  ListProps,
  TextField,
  TextInput,
} from 'react-admin';
import Pagination from '../Pagination';

/**
 * The list of all the persons owning an account.
 *
 * Only visible for administrators.
 */
const PersonList: FC<ListProps> = (props) => (
  <List
    title='resources.people.list.title'
    pagination={<Pagination />}
    filters={<PersonFilters />}
    exporter={false}
    sort={{ field: 'email', order: 'ASC' }}
    {...props}
  >
    <Datagrid
      rowClick='edit' // Only for test purpose.
      rowStyle={(record, index) => ({
        '--data-item': `'${record.id}'`,
      })}
    >
      <EmailField label='resources.people.list.headers.email' source='email' />
      <TextField
        label='resources.people.list.headers.firstname'
        source='firstname'
        sortable={false}
      />
      <TextField
        label='resources.people.list.headers.lastname'
        source='lastname'
        sortable={false}
      />
    </Datagrid>
  </List>
);

const PersonFilters = (props: Omit<FilterProps, 'children'>) => {
  return (
    <Filter {...props}>
      <TextInput
        label='resources.people.list.filters.email'
        source='email'
        placeholder='john.doe@...'
        alwaysOn
      />
    </Filter>
  );
};

export default PersonList;
