import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { cloneDeep, get, set } from 'lodash';
import moment from 'moment-timezone';
import { DateField, FunctionField, useLocale, useTranslate } from 'react-admin';
import { EmptyValue } from '../../constants/Constants';
import useTz from '../../intl/useTz';
import { DxTheme } from '../../types';
import { formatNumericValue } from '../../utils/utils';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    alignRight: {
      textAlign: 'right',
    },
    simpleLabeledField: {
      display: 'inline-flex',
      alignItems: 'baseline',
    },
    labeledDateField: {
      display: 'inline-flex',
      alignItems: 'baseline',
    },
    labeledHourField: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    newLineValue: {
      display: 'block',
    },
  }),
  { name: 'GenericFields' }
);

export const EmptyValueField = (props) => {
  return (
    <span style={{ display: 'flex', flexDirection: 'column' }}>
      {EmptyValue}
    </span>
  );
};

export const GenericV2InvoiceField = (props) => {
  const {
    id,
    alignRight,
    className,
    record = { properties: [] },
    label,
  } = props;
  const classes = useStyles(props);
  const translate = useTranslate();

  return (
    <div className={classNames(alignRight && classes.alignRight, className)}>
      {label ? (
        <Typography>{translate(label)}</Typography>
      ) : record[id] ? (
        <Typography>{record[id]}</Typography>
      ) : record[id] ? (
        <Typography>{record[id]}</Typography>
      ) : (
        <EmptyValueField />
      )}
    </div>
  );
};

export const GenericSimpleField = (props) => {
  const {
    id,
    alignRight,
    className,
    record = { properties: [] },
    label,
  } = props;
  const classes = useStyles(props);
  const translate = useTranslate();

  return (
    <div className={classNames(alignRight && classes.alignRight, className)}>
      {label ? (
        <Typography>{translate(label)}</Typography>
      ) : record.properties[id] ? (
        <Typography>{record.properties[id]}</Typography>
      ) : record[id] ? (
        <Typography>{record[id]}</Typography>
      ) : (
        <EmptyValueField />
      )}
    </div>
  );
};

export const GenericDateField = (props: any) => {
  const { id, record = {}, className, options = {} } = props;

  const locale = useLocale();
  const tz = useTz();

  let source = id;
  if (record.properties) {
    source = record.properties[id] ? `properties.${id}` : id;
  }
  const date = get(record, source);
  if (date) {
    const value = date.from ? date.from : date;
    const newRecord = cloneDeep(record);

    const newValue = moment.tz(value, tz);
    set(newRecord, source, newValue);

    return (
      <DateField
        source={source}
        record={newRecord}
        options={{
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          ...options,
        }}
        locales={locale}
        className={className}
      />
    );
  }

  return <EmptyValueField />;
};

export const GenericLabeledDateField = ({
  label,
  oneLine,
  className,
  record,
  ...props
}: any) => {
  const classes = useStyles();
  return oneLine ? (
    /* Ex: Dată emitere : 07 octombrie 2018 */
    <div className={classNames(className, classes.labeledDateField)}>
      <Typography variant='caption'>{label}</Typography>
      <Typography variant='caption' style={{ paddingRight: 2, paddingLeft: 2 }}>
        :
      </Typography>
      <GenericDateField record={record} {...props} />
    </div>
  ) : (
    /* Ex:
      Dată emitere
      07 octombrie 2018
    */
    <div className={className}>
      <Typography variant='caption'>{label}</Typography>
      <GenericDateField
        record={record}
        {...props}
        className={classes.newLineValue}
      />
    </div>
  );
};

/**
 * Returns an unlabeled 24h:mm:ss format hour field or <EmptyValueField>
 * @param {*} hour hour numeric timestamp to format or string to display w/o formatting
 */
export const GenericHourField = ({ hour }) => {
  const tz = useTz();

  if (hour) {
    return <Typography>{moment(hour).tz(tz).format('HH:mm')}</Typography>;
  } else {
    return <EmptyValueField />;
  }
};

/**
 * Returns a labeled 24h:mm:ss format hour field on one line or 2
 * @param {string} label translated label
 * @param {boolean} oneLine
 * @param {*} className
 * @param {*} hour hour value to format
 */
export const GenericLabeledHourField = ({
  label,
  oneLine,
  className,
  ...props
}: any) => {
  const classes = useStyles();
  return oneLine ? (
    /* Ex: Delivery hour : 11:48 */
    <div className={classNames(className, classes.labeledDateField)}>
      <Typography variant='caption'>{label}</Typography>
      <Typography variant='caption' style={{ paddingRight: 2, paddingLeft: 2 }}>
        :
      </Typography>
      <GenericHourField {...props} />
    </div>
  ) : (
    /* Ex:
      Delivery hour
      11:48
    */
    <div className={className}>
      <Typography variant='caption'>{label}</Typography>
      <GenericHourField {...props} />
    </div>
  );
};

export const SimpleLabeledField = ({
  label,
  data,
  oneLine = false,
  className,
  ...rest
}: any) => {
  const classes = useStyles();

  return oneLine ? (
    /* Ex: Invoice Number : 12345 */
    <div
      className={classNames(className, classes.simpleLabeledField)}
      {...rest}
    >
      {label && <Typography variant='caption'>{label}</Typography>}
      {label && (
        <Typography variant='caption' style={{ marginRight: 2, marginLeft: 2 }}>
          :
        </Typography>
      )}
      {data ? (
        <Typography variant='body2'>{data}</Typography>
      ) : (
        <EmptyValueField />
      )}
    </div>
  ) : (
    /* Ex:
      Invoice Number
      12345
    */
    <div className={className} {...rest}>
      {label && <Typography variant='body2'>{label}</Typography>}
      {data ? (
        <Typography className={classes.newLineValue}>{data}</Typography>
      ) : (
        <EmptyValueField />
      )}
    </div>
  );
};

/**
 * Displays a localized 2 decimals rigth justified price value
 */
export const PriceTextField = (props) => {
  const {
    source,
    record,
    label,
    key,
    sortable,
    style,
    headerClassName,
    cellClassName,
  } = props;

  const locale = useLocale();
  const value = get(record, source);
  let formattedValue = formatNumericValue(value, locale);

  return (
    <FunctionField
      headerClassName={headerClassName ? headerClassName : undefined}
      cellClassName={cellClassName ? cellClassName : undefined}
      source={source}
      label={label}
      record={record}
      render={() => <Typography style={style}>{formattedValue}</Typography>}
      sortable={sortable}
      key={key}
    />
  );
};
