/**
 * Displays the count of receipt advices since a date.
 */
import { gql, useQuery } from '@apollo/client';
import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import useSetupUsage, {
  SetupUsage,
} from '@dx-ui/dx-common/src/utils/useSetupUsage';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import { usePreferences } from '@react-admin/ra-preferences';
import moment, { Moment } from 'moment';
import { FC, useState } from 'react';
import { Identifier, useTranslate } from 'react-admin';
import ReactCardFlip from 'react-card-flip';
import { DxTheme } from '../../../../../types';
import cartouche from '../../cartouche.png';
import cartoucheDark from '../../cartoucheDark.png';
import DemoCount from '../demo/DemoCount';

const I18N_KEY = 'dxMessages.dashboard.widgets.RecadvCount';

const RecadvCount: FC<IWidgetContent> = ({
  account,
  onTheShelves,
  userPreferencesRootKey,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const [configuring, setConfiguring] = useState(false);
  const [day, setDay] = usePreferences(
    `${userPreferencesRootKey}.day`,
    moment().subtract(1, 'days')
  );
  const setupUsage = useSetupUsage();

  const onDayChange = (e) => {
    const next = moment(e.target.value);
    if (next.isValid()) setDay(next);
  };

  return (
    <ReactCardFlip isFlipped={configuring} flipDirection='vertical'>
      <Card classes={{ root: classes.card }}>
        {!onTheShelves && (
          <div className={classes.configure}>
            <IconButton
              onClick={() => setConfiguring(!configuring)}
              size='small'
            >
              <SettingsIcon />
            </IconButton>
          </div>
        )}
        <div className={classes.main}>
          <Grid container>
            <Grid item xs={3}>
              <Box className='icon'>
                <ReceiptIcon style={{ width: '48px', height: '48px' }} />
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='flex-end'
                justifyContent='flex-end'
              >
                <Box
                  // Need to put the width to prevent the Box from getting out the Grid.
                  style={{ width: '99%' }}
                >
                  {/* Double the label in a tooltip in case of truncature. */}
                  <Tooltip
                    title={translate(`${I18N_KEY}.Count`, {
                      on: moment(day).calendar(),
                    })}
                  >
                    <Typography color='textSecondary' noWrap align='right'>
                      {translate(`${I18N_KEY}.Count`, {
                        on: moment(day).calendar(),
                      })}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='flex-end'
                >
                  {/* When on the shelves, do not fetch the real count. */}
                  {onTheShelves || setupUsage !== SetupUsage.PRODUCTION ? (
                    <DemoCount day={moment(day)} />
                  ) : (
                    <Count companyId={account.company.id} day={moment(day)} />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Card>
      <Card style={{ backgroundColor: theme.palette.action.selected }}>
        <div className={classes.configure}>
          <IconButton onClick={() => setConfiguring(!configuring)} size='small'>
            <KeyboardReturnIcon />
          </IconButton>
        </div>
        <Box ml={5} p='10px'>
          <TextField
            id='date'
            label={translate(`${I18N_KEY}.On`)}
            type='date'
            defaultValue={moment(day).format('YYYY-MM-DD')}
            size='small'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onDayChange}
          />
        </Box>
      </Card>
    </ReactCardFlip>
  );
};

const Count: FC<{ companyId: Identifier; day: Moment }> = ({
  companyId,
  day,
}) => {
  const translate = useTranslate();
  const { loading, error, data } = useQuery(DOC_COUNT_GRAPHQL_REQUEST, {
    variables: {
      client:
        process.env.NODE_ENV === 'production'
          ? companyId
          : '001G_xGUkNWTwXqRdHxm15oh9OegSKvYtpuLBgd6oFZgXRdNOasspAh',
      // The date dimension in the anayltic dataset is typed as a 'YYYYMMDD'
      // strings.
      startDate: day.format('YYYYMMDD'),
      endDate: day.format('YYYYMMDD'),
    },
  });

  if (error)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          error,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );
  if (loading) return <CircularProgress size='1em' />;

  return (
    <Typography variant='h5' component='h2'>
      {data.dailyDocumentMetrics.metrics
        .map((_) => _.value)
        .find((_) => true) || 0}
    </Typography>
  );
};

const DOC_COUNT_GRAPHQL_REQUEST = gql`
  query DailyDocumentMetrics(
    $client: String!
    $startDate: String!
    $endDate: String!
  ) {
    dailyDocumentMetrics(
      client: $client
      startDate: $startDate
      endDate: $endDate
      statusGrouping: false
      docTypes: [RECADV]
    ) {
      metrics {
        name
        value
        docType
        docStatus
        dimensions {
          dateDimension {
            date
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: DxTheme) => ({
  main: {
    padding: 16,
    background: `url(${
      theme.palette.type === 'dark' ? cartoucheDark : cartouche
    }) no-repeat`,
    '& .icon': {
      color: '#dc2440',
    },
  },
  // Fixed into the bottom left corner.
  configure: {
    position: 'fixed',
    left: theme.spacing(0),
    bottom: theme.spacing(0),
  },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
  },
}));

export default RecadvCount;
