import {
  CheckboxMenuItem,
  InputIcon,
  SelectRenderer,
  sortLabeledItemLists,
} from '@dx-ui/dx-common';
import {
  CircularProgress,
  FormControl,
  Input,
  Popover,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import keycode from 'keycode';
import { useState } from 'react';
import { useTranslate } from 'react-admin';

const AutoCompleteMultiSelectInput = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<any>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const translate = useTranslate();
  const {
    onOpen,
    onClose,
    onChange,
    name,
    onBlur,
    inputRef,
    classes,
    className: classNameProp,
    disabled,
    readOnly,
    value: valueRaw,
    sortLabels = false,
    options,
    getOptionId,
    renderOptionLabel,
    filterOption,
    children,
    icon,
    placeholder,
    filterPlaceholder: filterPlaceholderProp,
    onFocus,
    intl,
    loading,
    errorText,
    renderValue,
    ...other
  } = props;

  let ignoreNextBlur = false;

  const handleClick = (event) => {
    ignoreNextBlur = true;
    setOpen(true);
    setQuery('');
    setAnchorEl(event.currentTarget);
    if (onOpen) {
      onOpen(event);
    }
  };

  const handleClose = (evt) => {
    setOpen(false);
    if (onClose) {
      onClose(evt);
    }
  };

  const handleItemClick = (id) => (event) => {
    if (!onChange) {
      return;
    }

    const newValue = value.length === 1 && value[0] === '_' ? [] : [...value];
    const itemIndex = newValue.indexOf(id);
    if (itemIndex === -1) {
      newValue.push(id);
    } else {
      newValue.splice(itemIndex, 1);
    }

    const { target } = event;
    /* eslint-disable no-param-reassign */
    event.target = { ...target, value: newValue, name };
    /* eslint-enable no-param-reassign */
    onChange(event, newValue);
  };

  const handleBlur = (event) => {
    if (ignoreNextBlur === true) {
      event.stopPropagation();
      ignoreNextBlur = false;
      return;
    }

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleKeyDown = (event) => {
    if (readOnly) {
      return;
    }

    if (['space', 'up', 'down'].includes(keycode(event))) {
      event.preventDefault();
      // Opening the menu is going to blur the. It will be focused back when closed.
      ignoreNextBlur = true;
      setOpen(true);
      setQuery('');
      setAnchorEl(event.currentTarget);

      if (onOpen) {
        onOpen(event);
      }
    }
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSelectRef = (node) => {
    if (inputRef) {
      inputRef(node, valueRaw);
    }
  };

  const value = valueRaw.length === 1 && valueRaw[0] === '_' ? [] : valueRaw;

  const sortedOptions = sortLabels
    ? sortLabeledItemLists(translate, options, renderOptionLabel)
    : options;

  const checkedItems = value.map((id) => {
    const option = sortedOptions.find((op) => getOptionId(op) === id);
    return (
      <div key={id}>
        <CheckboxMenuItem selected onClick={handleItemClick(id)}>
          {option
            ? translate(renderOptionLabel(option), {
                _: renderOptionLabel(option),
              })
            : id}
        </CheckboxMenuItem>
      </div>
    );
  });

  const lowerCaseQuery = query.toLowerCase();

  const uncheckedItems: any[] = [];
  sortedOptions.forEach((option) => {
    const id = getOptionId(option);
    if (value.includes(id)) {
      return;
    } // They"ve been iterated above
    if (
      filterOption &&
      !filterOption(option, query, lowerCaseQuery, translate)
    ) {
      return;
    }
    uncheckedItems.push(
      <div key={id}>
        <CheckboxMenuItem onClick={handleItemClick(id)}>
          {translate(renderOptionLabel(option), {
            _: renderOptionLabel(option),
          })}
        </CheckboxMenuItem>
      </div>
    );
  });

  const minimumMenuWidth = !!anchorEl ? anchorEl?.clientWidth : undefined;

  const filterPlaceholder =
    filterPlaceholderProp ||
    translate('dxMessages.list.search', { _: 'Search' });
  /*
    Same component 2 behaviours :
      - if renderValue is undefined, the input displays the selected items separated by
        a coma and the number of selection .
      - if renderValue is a Node object the input displays only this one.
    */
  const displayValue = renderValue ? (
    renderValue
  ) : (
    <>
      <SelectRenderer
        icon={icon}
        placeholder={placeholder}
        value={value}
        multiple
      >
        {(id) => {
          if (!id) {
            return null;
          }
          const option = sortedOptions.find((op) => getOptionId(op) === id);
          return option
            ? translate(renderOptionLabel(option), {
                _: renderOptionLabel(option),
              })
            : id;
        }}
      </SelectRenderer>
      <ArrowDropDownIcon className={classes.icon} />
    </>
  );
  return (
    <div className={classes.root}>
      <div
        className={classNames(
          classes.select,
          classes.selectMenu,
          {
            [classes.disabled]: disabled,
          },
          classNameProp
        )}
        aria-pressed={open ? 'true' : 'false'}
        tabIndex={disabled ? undefined : 0}
        role='button'
        aria-owns={open ? `menu-${name || ''}` : undefined}
        aria-haspopup='true'
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onClick={disabled || readOnly ? undefined : handleClick}
        onFocus={onFocus}
      >
        {displayValue}
      </div>
      <input
        value={value.join(',')}
        name={name}
        readOnly={readOnly}
        ref={handleSelectRef}
        {...other}
        type='hidden'
      />
      <Popover
        id={`menu-${name || ''}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          style: { minWidth: minimumMenuWidth, overflowY: 'initial' },
        }}
      >
        <div className={classes.paperWrapper}>
          {filterOption && sortedOptions.length > 10 && (
            <FormControl className={classes.filter}>
              <Input
                placeholder={filterPlaceholder}
                value={query}
                onChange={handleQueryChange}
                autoFocus
              />
              <InputIcon>
                <SearchIcon className={classes.icon} />
              </InputIcon>
            </FormControl>
          )}
          <div className={classes.items}>
            {checkedItems}
            {!!checkedItems.length &&
              (!!uncheckedItems.length || !!errorText || loading) && (
                <div className={classes.itemSeparator} />
              )}
            {loading && (
              <div className={classes.loader}>
                <CircularProgress size={24} />
              </div>
            )}
            {!!errorText && (
              <div className={classes.errorText}>{errorText}</div>
            )}
            {uncheckedItems}
          </div>
          {children && <div className={classes.children}>{children}</div>}
        </div>
      </Popover>
    </div>
  );
};

export default AutoCompleteMultiSelectInput;
