import moment from 'moment';

//const calendarDateFormat = 'YYYY-MM-DD';

export interface DateRange {
  from: string;
  to: string;
}

/**
 * Converts a predefined date range ('today', 'this-week' ...) into a {from: ..., to: ...} structure.
 * List of predefined dateRanges:
 *           | 'today'
 *           | 'yesterday'
 *           | 'this-week'
 *           | 'last-week'
 *           | 'this-month'
 *           | 'last-month'
 *           | 'last-7-days'
 *           | 'last-14-days'
 *           | 'last-30-days';
 * @param dateOrRange string | DateRange | { predefinedRange: string }
 * @param nowDate optional. If missing Date.now() si used
 */
export function resolveDateRange(
  dateOrRange:
    | string
    | DateRange
    | null
    | {
        predefinedRange:
          | 'today'
          | 'yesterday'
          | 'this-week'
          | 'last-week'
          | 'this-month'
          | 'last-month'
          | 'last-7-days'
          | 'last-14-days'
          | 'last-30-days';
      },
  nowDate = new Date()
): DateRange {
  const dateWithPredefinedRange = dateOrRange as { predefinedRange: string };
  if (dateWithPredefinedRange?.predefinedRange) {
    const now = moment(nowDate);

    switch (dateWithPredefinedRange.predefinedRange) {
      case 'today':
        return {
          from: now.clone().startOf('day').format(),
          to: now.clone().endOf('day').format(),
        };

      case 'yesterday':
        now.subtract(1, 'day');
        return {
          from: now.clone().startOf('day').format(),
          to: now.clone().endOf('day').format(),
        };

      case 'this-week':
        return {
          from: now.clone().startOf('isoWeek').format(),
          to: now.clone().endOf('isoWeek').format(),
        };

      case 'last-week':
        now.subtract(1, 'week');
        return {
          from: now.clone().startOf('isoWeek').format(),
          to: now.clone().endOf('isoWeek').format(),
        };

      case 'this-month':
        return {
          from: now.clone().startOf('month').format(),
          to: now.clone().endOf('month').format(),
        };

      case 'last-month':
        now.subtract(1, 'month');
        return {
          from: now.clone().startOf('month').format(),
          to: now.clone().endOf('month').format(),
        };

      case 'last-7-days':
        return {
          from: now
            .clone()
            .subtract(7 - 1, 'day')
            .startOf('day')
            .format(),
          to: now.clone().endOf('day').format(),
        };

      case 'last-14-days':
        return {
          from: now
            .clone()
            .subtract(14 - 1, 'day')
            .startOf('day')
            .format(),
          to: now.clone().endOf('day').format(),
        };

      case 'last-30-days':
        return {
          from: now
            .clone()
            .subtract(30 - 1, 'day')
            .startOf('day')
            .format(),
          to: now.clone().endOf('day').format(),
        };

      default:
        throw new Error(
          `Unsupported value for predefinedRange field : ${JSON.stringify(
            dateOrRange
          )}`
        );
    }
  }

  let range = dateOrRange as DateRange;
  if (range?.from || range?.to) {
    return range;
  }

  if (typeof dateOrRange === 'string') {
    return { from: dateOrRange, to: dateOrRange };
  }

  throw new Error(
    'Invalid parameter type (dateOrRange):\n' + JSON.stringify(dateOrRange)
  );
}
