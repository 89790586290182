import { Metadata, resolveDateRange } from '@dx-ui/dx-common';
import useSetupUsage, {
  SetupUsage,
} from '@dx-ui/dx-common/src/utils/useSetupUsage';
import {
  createColumnsContract,
  createColumnsDeliveryLocation,
  createColumnsOrder,
  createColumnsProcessDetails,
  createColumnsTotals,
  createCommonColumns,
  createCommonFilters,
  createContractFilters,
  createDeliveryLocationFilters,
  createOrderFilters,
  createProcessDetailsFilters,
  createTotalsFilters,
} from '../../aspects';
import { CommonList, DisplayContextualButtons } from '../../shared';

/**
 * Declare the additional columns to display
 */
const columns: any[] = [
  ...createCommonColumns(),
  ...createColumnsDeliveryLocation(),
  ...createColumnsTotals(),
  ...createColumnsContract(),
  ...createColumnsOrder(),
  ...createColumnsProcessDetails(),
];

/**
 * Specific filters
 */
const additionalFilters: any[] = [
  ...createCommonFilters(),
  ...createDeliveryLocationFilters(),
  ...createTotalsFilters(),
  ...createContractFilters(),
  ...createOrderFilters(),
  ...createProcessDetailsFilters(),
];

/**
 * @param {*} props
 */
const ForecastList = (props) => {
  const setupUsage: SetupUsage | undefined = useSetupUsage();
  const isDemoSetup: boolean = setupUsage === SetupUsage.DEMO;
  return (
    <CommonList
      title='dxMessages.forecast.title'
      {...props}
      columns={columns}
      additionalFilters={additionalFilters}
      contextualToolbar={<DisplayContextualButtons />}
      filterDefaultValues={
        isDemoSetup
          ? {}
          : {
              [Metadata.created]: resolveDateRange({
                predefinedRange: 'last-7-days',
              }),
            }
      }
    />
  );
};

export default ForecastList;
