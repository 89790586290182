import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { useState } from 'react';
import {
  Create,
  RadioButtonGroupInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  maxLength,
  required,
  useTranslate,
} from 'react-admin';
import { DxTheme } from '../../types';
import { ToolbarBackButton } from '../buttons';
import { MessageState, MessageType } from './types';
import { transformBeforeProcessing } from './utils';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    helpIcon: {
      color: theme.colors.black30,
      width: 18,
      height: 18,
    },
    mainContainer: {
      width: '100%',
    },
  }),
  { name: 'MessageCreate' }
);

const MessageCreate = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [complexInput, setComplexInput] = useState<boolean>(false);

  return (
    <Create {...props} transform={transformBeforeProcessing}>
      <SimpleForm redirect='list' toolbar={<CustomToolbar />}>
        <Grid container classes={{ container: classes.mainContainer }}>
          <Grid item container xs={2} direction='row'>
            <Grid item>
              <SelectInput
                source='language'
                choices={[
                  { id: 'en', name: 'English' },
                  { id: 'fr', name: 'Français' },
                  { id: 'ro', name: 'Română' },
                ]}
                label='dxMessages.messagesCenter.language.title'
                validate={[required()]}
              />
            </Grid>
            <Help
              text={translate('dxMessages.messagesCenter.language.help', {
                _: "The message visibility takes into account the user's language choice. You can then specialize the message according to the language",
              })}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <SelectInput
              source='type'
              choices={[
                {
                  id: MessageType.GENERAL,
                  name: translate('dxMessages.messagesCenter.type.GENERAL'),
                },
                {
                  id: MessageType.MARKETING,
                  name: translate('dxMessages.messagesCenter.type.MARKETING'),
                },
                // {
                //   id: MessageType.DOWNTIME,
                //   name: translate('dxMessages.messagesCenter.type.DOWNTIME'),
                // },
                // {
                //   id: MessageType.BANNER,
                //   name: translate('dxMessages.messagesCenter.type.BANNER'),
                // },
              ]}
              label='dxMessages.messagesCenter.type.title'
              validate={[required()]}
              onChange={({ target: { value } }) => {
                if (
                  (value as MessageType) === MessageType.MARKETING ||
                  (value as MessageType) === MessageType.GENERAL
                ) {
                  setComplexInput(false);
                } else {
                  setComplexInput(true);
                }
              }}
            />
            <Help
              text={translate('dxMessages.messagesCenter.type.help', {
                _: 'Message category. A <<General>> or a <<Marketing>> message is visible on the home page for all users.',
              })}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6} style={{ display: 'flex', alignContent: 'center' }}>
            <TextInput
              source='topic'
              validate={[maxLength(30), required()]}
              label={'dxMessages.messagesCenter.topic.title'}
              style={{ width: '100%' }}
            />
            <Help
              text={translate('dxMessages.messagesCenter.topic.help', {
                _: "Defines a topic to identify your message. This topic is displayed in the user's notifications menu",
              })}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid
            item
            xs={12}
            style={{ display: 'flex', alignContent: 'center' }}
          >
            <RadioButtonGroupInput
              source='state'
              choices={[
                {
                  id: MessageState.DRAFT,
                  name: translate('dxMessages.messagesCenter.state.DRAFT'),
                },
                {
                  id: MessageState.PUBLISHED,
                  name: translate('dxMessages.messagesCenter.state.PUBLISHED'),
                },
              ]}
              defaultValue={MessageState.DRAFT}
              label='dxMessages.messagesCenter.state.title'
            />
            <Help
              text={translate('dxMessages.messagesCenter.state.help', {
                _: "Message visibility. The message can be created as DRAFT and won't be visible until an edition setting it as PUBLISHED. Setting its state to PUBLISHED at creation time will make it visible immediately",
              })}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <Grid
              item
              xs={8}
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
              }}
            >
              <TextInput
                source='moreInfoLink'
                validate={[maxLength(200)]}
                label={'dxMessages.messagesCenter.link.href'}
                style={{ width: '100%' }}
              />
              <Help
                text={translate('dxMessages.messagesCenter.link.help', {
                  _: 'Defines a More info Link',
                })}
              />
            </Grid>
          </Grid>
        </Grid>
        {complexInput ? (
          <MarkdownInput source='content' label='' />
        ) : (
          <Grid container classes={{ container: classes.mainContainer }}>
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
              }}
            >
              <TextInput
                source='content'
                label='dxMessages.messagesCenter.content.title'
                multiline
                validate={[maxLength(300)]}
                style={{ width: '100%' }}
              />
              <Help
                text={translate('dxMessages.messagesCenter.content.help', {
                  _: 'Message content limited to 100 characters and which could be multilignes. Click on Show button for an idea of the rendering',
                })}
              />
            </Grid>
          </Grid>
        )}
      </SimpleForm>
    </Create>
  );
};

const Help = (props) => {
  const { text } = props;
  const classes = useStyles();
  return (
    <Grid
      item
      style={{
        display: 'flex',
        alignContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Tooltip title={text}>
        <IconButton color='inherit' size='small'>
          <HelpIcon classes={{ root: classes.helpIcon }} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

const CustomToolbar = (props) => {
  const { record } = props;

  if (!record) return null;

  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
      <ToolbarBackButton />
    </Toolbar>
  );
};

export default MessageCreate;
