import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import {
  FieldProps,
  SelectInput,
  TextInput,
  UserIdentity,
  required,
} from 'react-admin';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The input to change the editable personnal info of the user.
 *
 * All of them are editable BUT his email, which is the ID of his account, so immutable.
 *
 * Look at https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
 * for the meaning of the 'autoComplete' attributes.
 */
const ProfileInfo: FC<FieldProps<UserIdentity>> = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid container direction='column' spacing={0}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextInput
                label='resources.accounts.edit.tabs.user.sections.person.email'
                source='email'
                disabled
                helperText='resources.profile.edit.emailHelper'
                autoComplete='username'
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label='resources.accounts.edit.tabs.user.sections.person.phone'
                source='phone'
                helperText='resources.accounts.edit.tabs.user.sections.person.phoneHelper'
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInput
                label='resources.accounts.edit.tabs.user.sections.person.firstname'
                source='firstname'
                validate={required()}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label='resources.accounts.edit.tabs.user.sections.person.lastname'
                source='lastname'
                validate={required()}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label='dxMessages.myAccount.companyName'
                source='company.name'
                disabled
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <SelectInput
            label='resources.accounts.edit.tabs.user.sections.person.language'
            source='language'
            choices={[
              {
                id: 'en',
                name: 'resources.accounts.edit.tabs.user.sections.person.english',
              },
              {
                id: 'ro',
                name: 'resources.accounts.edit.tabs.user.sections.person.romanian',
              },
              {
                id: 'fr',
                name: 'resources.accounts.edit.tabs.user.sections.person.french',
              },
            ]}
            validate={required()}
            helperText='resources.accounts.edit.tabs.user.sections.person.languageHelper'
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProfileInfo;
