/**
 * Displays the total count of invoices waiting for receipt advices.
 */
import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { FC } from 'react';
import { useQuery, useTranslate } from 'react-admin';
import { DxTheme } from '../../../../types';
import cartouche from '../cartouche.png';
import cartoucheDark from '../cartoucheDark.png';

const InvoiceWaitingForDocuments: FC<IWidgetContent> = ({ onTheShelves }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Card classes={{ root: classes.card }}>
      <div className={classes.main}>
        <Grid container>
          <Grid item xs={3}>
            <Box width='3em' className='icon'>
              <HourglassEmptyIcon style={{ width: '48px', height: '48px' }} />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Typography color='textSecondary' align='right' noWrap>
              {translate(
                'dxMessages.dashboard.widgets.InvoiceWaitingForDocuments.Count'
              )}
            </Typography>
            <Typography variant='h5' component='h2' align='right' noWrap>
              {/* When on the shelves, do not fetch the real count. */}
              {onTheShelves ? '123' : <InvoiceWaitingForDocumentsCount />}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

const InvoiceWaitingForDocumentsCount = () => {
  const translate = useTranslate();
  // Be careful: useGetOne() breaks the browser on logout.
  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'nwaymatching-statistics',
    payload: { id: 'waitingForDocuments' },
  });

  if (error)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          error,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );
  if (loading) return <CircularProgress size='1em' />;

  return <>{data!.count}</>;
};

const useStyles = makeStyles((theme: DxTheme) => ({
  main: {
    padding: 16,
    background: `url(${
      theme.palette.type === 'dark' ? cartoucheDark : cartouche
    }) no-repeat`,
    '& .icon': {
      color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440',
    },
  },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
  },
}));

export default InvoiceWaitingForDocuments;
