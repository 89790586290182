// selectable menu item
import { ListItemIcon, MenuItem } from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import React from 'react';
import { colors } from '../../constants/Colors';

// eslint-disable-next-line react/prefer-stateless-function
const CheckboxMenuItem = (props) => {
  const { children, selected, ...other } = props;
  const Icon = selected ? CheckBox : CheckBoxOutlineBlank;
  return (
    <MenuItem {...other}>
      <ListItemIcon>
        <Icon style={{ color: selected ? colors.mainColor5 : 'inherit' }} />
      </ListItemIcon>
      {children}
    </MenuItem>
  );
};

export default CheckboxMenuItem;
