import compose from 'lodash/flowRight';
import React from 'react';
import { SelectInput, translate } from 'react-admin';
const RejectionInput = (props) => {
  const { translate } = props;
  return (
    <SelectInput
      label={translate(`dxMessages.task.saintgobainui.label`)}
      source='input.data.comment'
      variant='standard'
      choices={[
        {
          id: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_order_number'
          ),
          name: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_order_number'
          ),
        },
        {
          id: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_deliverynote_number'
          ),
          name: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_deliverynote_number'
          ),
        },
        {
          id: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_loading_location'
          ),
          name: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_loading_location'
          ),
        },
        {
          id: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_unloading_location'
          ),
          name: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_unloading_location'
          ),
        },
        {
          id: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_transport_cost'
          ),
          name: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_transport_cost'
          ),
        },
        {
          id: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_invoice_currency'
          ),
          name: translate(
            'dxMessages.task.saintgobainui.rejectReason.incorrect_invoice_currency'
          ),
        },
      ]}
      fullWidth
      allowEmpty
    />
  );
};

const enhance = compose(translate);

export default enhance(RejectionInput);
