import { DataProvider } from 'react-admin';
import { Constants } from '../constants/Constants';

/**
 * Provides operations for interogating the backend for informations on selected documents
 * @param {*} dataProvider DataProvider from ReactAdmin
 */
export class RelatedDocumentsService {
  /**
   * Builds the service instance
   * @param {*} dataProvider React Admin data provider
   */
  constructor(public dataProvider: DataProvider) {}

  async getAllDocs(
    documentId: string,
    sortMessage: any,
    sortRelated: any,
    sortLinked: any,
    sortAttach: any
  ) {
    const payload = {
      documentId: documentId,
      sortMessage: sortMessage,
      sortRelated: sortRelated,
      sortLinked: sortLinked,
      sortAttach: sortAttach,
    };
    const result = await this.dataProvider.apiGetAllDocs(
      Constants.RESOURCE_LINKED,
      payload
    );
    return result.data;
  }
}
