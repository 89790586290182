import { Box, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import keyBy from 'lodash/keyBy';
import * as React from 'react';
import { FC } from 'react';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  Identifier,
  ListContextProvider,
  Loading,
  ReferenceField,
  required,
  ResourceContextProvider,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  useQuery,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { LOGO_AND_LABELS } from '../identityprovider';
import { Person } from '../types';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

/**
 * The edition of a person.
 *
 * Only visible for PSP administrators.
 */
const PersonEdit: FC<EditProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('resources.people.edit.notifications.updated');
    refresh();
  };

  return (
    <Edit
      title={<PersonTitle />}
      undoable={false}
      onSuccess={onSuccess}
      component='div'
      {...props}
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <FormLayout />
      </SimpleForm>
    </Edit>
  );
};

const PersonTitle = ({ record }: any) => <span>{record?.email}</span>;

const FormLayout: FC<FieldProps<Person>> = (props) => {
  const { record } = props;
  if (!record) return null;

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item>
        <GeneralInfo />
      </Grid>
      <Grid item>
        <Identities {...props} />
      </Grid>
      <Grid item>
        <AccountList personId={record.id} />
      </Grid>
    </Grid>
  );
};

const GeneralInfo: FC<FieldProps<Person>> = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container direction='column' spacing={0}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextInput
                label='resources.people.edit.sections.info.email'
                source='email'
                disabled
                helperText='resources.people.edit.sections.info.emailHelper'
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextInput
                label='resources.people.edit.sections.info.phone'
                source='phone'
                helperText='resources.people.edit.sections.info.phoneHelper'
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInput
                label='resources.people.edit.sections.info.firstname'
                source='firstname'
                fullWidth
                validate={required()}
              />
            </Grid>
            <Grid item xs={8}>
              <TextInput
                label='resources.people.edit.sections.info.lastname'
                source='lastname'
                fullWidth
                validate={required()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <SelectInput
            label='resources.people.edit.sections.info.language'
            source='language'
            choices={[
              { id: 'en', name: 'resources.people.edit.sections.info.english' },
              {
                id: 'ro',
                name: 'resources.people.edit.sections.info.romanian',
              },
              { id: 'fr', name: 'resources.people.edit.sections.info.french' },
            ]}
            validate={required()}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const Identities: FC<FieldProps<Person>> = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5'>
        {translate('resources.people.edit.sections.authentication.title')}
      </Typography>
      {record?.identities && record.identities.length > 0 && (
        <>
          <Typography variant='caption'>
            {translate(
              'resources.people.edit.sections.authentication.subtitle'
            )}
          </Typography>
          <ArrayField source='identities'>
            <Datagrid>
              <IdentityProviderField
                label='resources.people.edit.sections.authentication.type'
                source='provider'
              />
              <TextField
                label='resources.people.edit.sections.authentication.login'
                source='id'
              />
            </Datagrid>
          </ArrayField>
        </>
      )}
      {(!record?.identities || record.identities.length === 0) && (
        <Typography variant='caption'>
          {translate('resources.people.edit.sections.authentication.none')}
        </Typography>
      )}
    </Paper>
  );
};

const IdentityProviderField = ({ record, source }: any) => {
  const provider = record[source];
  if (!provider) return null;

  if (!LOGO_AND_LABELS[provider.id]) return <span>&nbsp;{provider.id}</span>;

  return (
    <Box display='flex' alignItems='center'>
      <img
        src={LOGO_AND_LABELS[provider.id].logo}
        alt={LOGO_AND_LABELS[provider.id].label}
        style={{ width: '1em', height: '1em' }}
      />
      <Typography>&nbsp;{LOGO_AND_LABELS[provider.id].label}</Typography>
    </Box>
  );
};

const AccountList = ({ personId }: { personId: Identifier }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const { data, loading, error } = useQuery({
    type: 'getList',
    resource: 'configuration-accounts',
    payload: {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'id', order: 'ASC' },
      filter: { personId },
    },
  });

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error}</p>;

  return (
    <Paper className={classes.paper}>
      <Typography variant='h5'>
        {translate('resources.people.edit.sections.accounts.title')}
      </Typography>
      <Typography variant='caption'>
        {translate('resources.people.edit.sections.accounts.subtitle')}
      </Typography>
      <ResourceContextProvider value='configuration-accounts'>
        <ListContextProvider
          value={{
            basePath: '/configuration-accounts',
            data: keyBy(data, 'id'),
            // @ts-ignore
            ids: data.map(({ id }) => id),
            currentSort: { field: 'id', order: 'ASC' },
            selectedIds: [],
          }}
        >
          <Datagrid rowClick='edit' style={{ marginTop: '1em' }}>
            <ReferenceField
              label='resources.people.edit.sections.accounts.company'
              source='company.id'
              reference='configuration-companies'
              sortable={false}
            >
              <TextField source='name' />
            </ReferenceField>
            <BooleanField
              label='resources.people.edit.sections.accounts.enabled'
              source='enabled'
              sortable={false}
              // @ts-ignore
              FalseIcon={ErrorIcon}
            />
            <BooleanField
              label='resources.people.edit.sections.accounts.nonLocked'
              source='accountNonLocked'
              sortable={false}
              // @ts-ignore
              FalseIcon={ErrorIcon}
            />
          </Datagrid>
        </ListContextProvider>
      </ResourceContextProvider>
    </Paper>
  );
};

const ErrorIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}))(ClearIcon);

// The only way to define our own confirmation labels on delete is to provide
// a custom toolbar.
const CustomToolbar = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { record } = props;

  if (!record) return null;

  return (
    <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
      <SaveButton disabled={props.pristine} />
      <DeleteButton
        undoable={false}
        confirmTitle={translate('resources.companies.edit.deleteConfirmTitle', {
          username: record.email,
        })}
      />
    </Toolbar>
  );
};

export default PersonEdit;
