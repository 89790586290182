import {
  GenericLabeledDateField,
  Metadata,
  SimpleLabeledField,
} from '@dx-ui/dx-common';
import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import compose from 'lodash/flowRight';
import React from 'react';
import { translate } from 'react-admin';

const styles = (theme) => ({
  section: {
    paddingTop: '2em',
    paddingBottom: '2em',
  },
  graySection: {
    backgroundColor: theme.palette.grey[100],
    paddingTop: '2em',
    paddingBottom: '2em',
  },
  paper: {
    padding: theme.spacing(2),
    minWidth: 750, // Ensure that the view won't be altered when window size is small
    boxShadow: 'unset',
  },
});

const ForecastPreview = ({ classes, values, record, translate }) => {
  return (
    <Paper square classes={{ root: classes.paper }}>
      <Grid container justify='center'>
        <Grid item>
          <Typography variant='h6' style={{ fontSize: '2em' }}>
            {translate('dxMessages.forecast.document_label')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container classes={{ container: classes.section }}>
        <Grid item xs={2}>
          <SimpleLabeledField
            label={translate('dxMessages.headers.documentId')}
            data={get(record.properties, Metadata.documentId)}
            className={Metadata.documentId}
          />
        </Grid>
        <Grid item xs={3}>
          <GenericLabeledDateField
            label={translate('dxMessages.headers.issueDate')}
            id={Metadata.issueDate}
            record={{ ...record, ...values }}
            className={Metadata.issueDate}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction='row'
        classes={{ container: classes.graySection }}
      >
        <Grid item xs={6}>
          {/* Supplier */}
          <Grid item>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.supplier')}
                data={get(record.properties, Metadata.issuerName)}
                oneLine
                className={Metadata.issuerName}
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.supplierId')}
                data={get(record.properties, Metadata.issuerId)}
                oneLine
                className={Metadata.issuerId}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item>
            {/* Client */}
            <SimpleLabeledField
              label={translate('dxMessages.headers.buyer')}
              data={get(record.properties, Metadata.recipientName)}
              oneLine
              className={Metadata.recipientName}
            />
          </Grid>
          <Grid item>
            <SimpleLabeledField
              label={translate('dxMessages.headers.buyerId')}
              data={get(record.properties, Metadata.recipientId)}
              oneLine
              className={Metadata.recipientId}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const enhance = compose(translate, withStyles(styles, { withTheme: true }));

export default enhance(ForecastPreview);
