import { SimpleLabeledField } from '@dx-ui/dx-common';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { IWayBillSources } from '../WayBillSources';

interface PropsModel {
  record: any;
  dataSources: IWayBillSources;
  title: string;
}

const WayBillLocationFragment = (props: PropsModel): JSX.Element => {
  const { record, dataSources, title } = props;
  const translate = useTranslate();
  return (
    <Fragment>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.street')}
          data={get(record, dataSources.AddressSources.street)}
          oneLine
          className={`${title}_street`}
        />
      </Grid>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.additionalStreet')}
          data={get(record, dataSources.AddressSources.addStreet)}
          oneLine
          className={`${title}_addStreet`}
        />
      </Grid>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.number')}
          data={get(record, dataSources.AddressSources.number)}
          oneLine
          className={`${title}_buildingNumber`}
        />
      </Grid>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.city')}
          data={get(record, dataSources.AddressSources.city)}
          oneLine
          className={`${title}_city`}
        />
      </Grid>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.postalCode')}
          data={get(record, dataSources.AddressSources.postalCode)}
          oneLine
          className={`${title}_postalCode`}
        />
      </Grid>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.countrySubEntityCode')}
          data={get(record, dataSources.AddressSources.countrySubEntityCode)}
          oneLine
          className={`${title}_countrySubEntityCode`}
        />
      </Grid>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.countryCode')}
          data={get(record, dataSources.AddressSources.countryCode)}
          oneLine
          className={`${title}_countryCode`}
        />
      </Grid>
    </Fragment>
  );
};

export default WayBillLocationFragment;
