import { isPspAdministrator, useGAPageViews } from '@dx-ui/dx-common/src';
import {
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  FilterProps,
  FunctionField,
  List,
  TextField,
  TextInput,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';

// interface LastLoginTimeResponse extends RaRecord {
//   // ISO-8601 representation.
//   lastLoginTime: string;
// }

const FormatTypeFilters = (props: Omit<FilterProps, 'children'>) => {
  const { permissions, loaded } = usePermissions();
  if (!loaded || !permissions) return null;

  return (
    <Filter {...props}>
      <TextInput
        label={'dxMessages.companyManagement.formattype.description'}
        source='description'
        alwaysOn
      />
    </Filter>
  );
};

const FormatTypeList = (props: any) => {
  useGAPageViews();
  const { permissions, loaded } = usePermissions();
  const notify = useNotify();
  const translate = useTranslate();

  if (!loaded || !permissions) return null;

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<FormatTypeFilters {...props} />}
      empty={isPspAdministrator(permissions) ? <CreateButton /> : null}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        <TextField
          label={'dxMessages.companyManagement.formattype.description'}
          source='description'
        />
        <TextField
          label={'dxMessages.companyManagement.formattype.comment'}
          source='comment'
        />
        <FunctionField
          label={'dxMessages.companyManagement.formattype.idDocumentType'}
          render={(record: any) => {
            return record.idDocumentType === 1 ? 'INVOIC' : 'DESADV';
          }}
        />
        <EditButton />
        <DeleteWithConfirmButton
          id={props?.id}
          onFailure={(error) => {
            if (error.status === 400) {
              notify(
                'dxMessages.companyManagement.formattype.delete_formattype_error',
                'error',
                {
                  _: 'Unable to delete this format type',
                  error: translate(
                    'dxMessages.companyManagement.formattype.delete_formattype_error'
                  ),
                }
              );
            } else {
              notify(
                'dxMessages.companyManagement.formattype.delete_formattype_error',
                'error',
                {
                  _: 'Unable to delete this format type',
                  error: `${error}`,
                }
              );
            }
          }}
        />
      </Datagrid>
    </List>
  );
};

export default FormatTypeList;
