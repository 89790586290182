import { Constants, Metadata } from '@dx-ui/dx-common';
import {
  createColumnsContract,
  createColumnsDeliveryLocation,
  createColumnsOrder,
  createColumnsProcessDetails,
  createColumnsTotals,
  createCommonColumns,
  createCommonFilters,
  createContractFilters,
  createDeliveryLocationFilters,
  createOrderFilters,
  createProcessDetailsFilters,
  createTotalsFilters,
} from '../../../aspects';
import {
  createDesadvFilters,
  createSpecificColumnsDesadv,
} from '../../../aspects/DespatchAdviceDetailsFields';
import { DespatchAdviceService } from '../../../services/DespatchAdviceService';
import { CommonList } from '../../../shared';
import BulkDeleteActionButtons from '../../../shared/BulkDeleteActionButtons';
import ContextualToolbar from '../../../shared/ContextualToolbar';

/**
 * Declare the additional columns to display vs commons
 */
const columns: any[] = [
  ...createCommonColumns(),
  ...createColumnsContract(),
  ...createColumnsDeliveryLocation(),
  ...createColumnsTotals(),
  ...createColumnsOrder(),
  ...createColumnsProcessDetails(),
  ...createSpecificColumnsDesadv(),
];

// Change edm:orderId and edm:orderDate columns configuration
// in order to always have this cols for Web despatchAdvices.
columns
  .filter((col) => col.id === Metadata.orderId || col.id === Metadata.orderDate)
  .forEach((col) => {
    col.alwaysOn = true;
  });

/**
 * Specific filters
 */
const additionalFilters: any[] = [
  ...createCommonFilters(),
  ...createContractFilters(),
  ...createDeliveryLocationFilters(),
  ...createTotalsFilters(),
  ...createOrderFilters(),
  ...createProcessDetailsFilters(),
  ...createDesadvFilters(),
];

const WebDespatchAdviceList = (props) => {
  const { permissions } = props;
  return (
    <CommonList
      title='dxMessages.despatchAdvices.title'
      {...props}
      columns={columns}
      additionalFilters={additionalFilters}
      contextualToolbar={
        <ContextualToolbar
          resource={Constants.RESOURCE_DESPATCH_ADVICE}
          allowEdit={DespatchAdviceService.canCreate(permissions)}
        />
      }
      bulkActions={<BulkDeleteActionButtons />}
    />
  );
};

export default WebDespatchAdviceList;
