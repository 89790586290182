import { Grid, Paper, Typography } from '@material-ui/core';
import { MarkdownField } from '@react-admin/ra-markdown';
import {
  BooleanField,
  Labeled,
  Loading,
  Record,
  ReduxState,
  Show,
  SimpleShowLayout,
  Toolbar,
  usePermissions,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { isPspAdministrator } from '../../configuration';
import { UsageRight } from '../../configuration/types';
import MessageWidget from '../../layout/LandingPage/widgets/MessageWidget';
import { ToolbarBackButton } from '../buttons';
import { MessageType } from './types';

const MessageShow = (props) => {
  const { resource, id } = props;
  const { loading, permissions } = usePermissions<UsageRight[], any>();
  const record = useSelector<ReduxState, Record | undefined>((state) =>
    state.admin.resources[resource]
      ? state.admin.resources[resource].data[id]
      : undefined
  );

  if (loading) return <Loading />;

  const SanitizedGridPropsComponent = (props) => {
    const {
      basePath,
      mutationMode,
      validating,
      handleSubmit,
      handleSubmitWithRedirect,
      submitOnEnter,
      onSave,
      invalid,
      pristine,
      saving,
      children,
      ...rest
    } = props;
    return <Grid {...rest}>{children}</Grid>;
  };

  return (
    <>
      <Show
        {...props}
        title={'resources.configuration-message.name'}
        hasEdit={
          props.hasEdit && isPspAdministrator(permissions as any) ? true : false
        }
      >
        <SimpleShowLayout>
          <SanitizedGridPropsComponent container justify='center'>
            {isPspAdministrator(permissions as any) && (
              <Grid item>
                <Typography variant='h6'>{record?.topic}</Typography>
              </Grid>
            )}
          </SanitizedGridPropsComponent>
          <SanitizedGridPropsComponent container>
            {record?.moreInfoLink && isPspAdministrator(permissions as any) && (
              <Grid
                item
                container
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <Grid
                  item
                  xs={8}
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Labeled label='dxMessages.messagesCenter.link.href'>
                    <Typography>{record?.moreInfoLink}</Typography>
                  </Labeled>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Labeled label='dxMessages.messagesCenter.link.knowledgeBase'>
                    <BooleanField
                      source='moreInfoLinkToKnowledgeBase'
                      title='dxMessages.messagesCenter.link.knowledgeBase'
                      valueLabelTrue='dxMessages.messagesCenter.link.knowledgeBase'
                      valueLabelFalse='dxMessages.messagesCenter.link.public'
                      style={{ display: 'flex', justifyContent: 'center' }}
                    />
                  </Labeled>
                </Grid>
              </Grid>
            )}
          </SanitizedGridPropsComponent>
          <SanitizedGridPropsComponent item>
            {record?.type === MessageType.MARKETING && (
              <MessageWidget
                messageIssuer='@DocProcessNews'
                messageIssuerTitle='DocProcess News'
                messageType={MessageType.MARKETING}
                onTheShelves={false}
                openConfiguration={false}
                widgetName='MktMessageWidget'
                id={id}
              />
            )}
            {record?.type === MessageType.GENERAL && (
              <MessageWidget
                messageIssuer='@DocProcessOperations'
                messageIssuerTitle='DocProcess Operations'
                messageType={MessageType.GENERAL}
                onTheShelves={false}
                openConfiguration={false}
                widgetName='PspMessageWidget'
                id={id}
              />
            )}
            {record?.type !== MessageType.GENERAL &&
              record?.type !== MessageType.MARKETING && (
                <Paper
                  variant='outlined'
                  elevation={3}
                  style={{
                    marginTop: 5,
                  }}
                >
                  <MarkdownField source='content' label='' />
                </Paper>
              )}
          </SanitizedGridPropsComponent>
        </SimpleShowLayout>
      </Show>
      <Toolbar>
        <ToolbarBackButton />
      </Toolbar>
    </>
  );
};

export default MessageShow;
