import PropTypes from 'prop-types';
import React from 'react';
import Filter from './Filter';

const CommonListFilter = (props) => {
  const { filters, ...rest } = props;
  const resource = props.resource;

  return (
    <Filter {...rest}>
      {filters.map((commonListFilter) => {
        const alwaysOn = !!commonListFilter.alwaysOn;
        const key = commonListFilter.id;

        return commonListFilter.filter({
          alwaysOn,
          key,
          resource,
        });
      })}
    </Filter>
  );
};

CommonListFilter.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.array,
  resource: PropTypes.string,
};

export default CommonListFilter;
