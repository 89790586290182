import ActionButton from './ActionButton';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a ActionButton
 */
const ActionButtonField = (props: FieldComponentProps) => {
  const sanitizeProps: any = ({ basePath, ...rest }): any => rest;
  return (
    <FieldWrapper {...props}>
      {(fieldProps) => <ActionButton {...sanitizeProps(fieldProps)} />}
    </FieldWrapper>
  );
};

export default ActionButtonField;
