import {
  cellStyle,
  Constants,
  EmptyValueField,
  Metadata,
  MultiSelectInput,
  widthPresets,
} from '@dx-ui/dx-common';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';

/**
 * Renders the Archive Period value as a Field
 * @param {*} className CSS API
 * @param {*} record current row values
 */
export const InvoiceTypeField = ({
  className,
  record = { properties: [] },
}) => {
  const translate = useTranslate();
  if (record.properties[Metadata.invoiceType]) {
    return (
      <Typography className={className}>
        {translate(
          `dxMessages.invoiceTypes.${record.properties[Metadata.invoiceType]}`,
          { _: record.properties[Metadata.invoiceType] }
        )}
      </Typography>
    );
  } else {
    return <EmptyValueField />;
  }
};

export const createColumnsCarrefourInvoiceTypeDetails = () => [
  {
    id: Metadata.invoiceType,
    label: 'dxMessages.headers.invoiceType',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <InvoiceTypeField {...props} />,
  },
];

export const createCarrefourInvoiceTypeDetailsFilters = () => [
  {
    id: Metadata.invoiceType,
    filter: ({ ...props }: any) => (
      <MultiSelectInput
        label='dxMessages.headers.invoiceType'
        source={Metadata.invoiceType}
        width={widthPresets.medium}
        sortLabels={true}
        {...props}
        options={[
          {
            id: Constants.INVOICE_TYPE_FAM,
            label: 'dxMessages.invoiceTypes.FAM',
          },
          {
            id: Constants.INVOICE_TYPE_FAS,
            label: 'dxMessages.invoiceTypes.FAS',
          },
          {
            id: Constants.INVOICE_TYPE_FCG,
            label: 'dxMessages.invoiceTypes.FCG',
          },
          {
            id: Constants.INVOICE_TYPE_FIM,
            label: 'dxMessages.invoiceTypes.FIM',
          },
          {
            id: Constants.INVOICE_TYPE_FMD,
            label: 'dxMessages.invoiceTypes.FMD',
          },
          {
            id: Constants.INVOICE_TYPE_FME,
            label: 'dxMessages.invoiceTypes.FME',
          },
          {
            id: Constants.INVOICE_TYPE_FMF,
            label: 'dxMessages.invoiceTypes.FMF',
          },
          {
            id: Constants.INVOICE_TYPE_FSR,
            label: 'dxMessages.invoiceTypes.FSR',
          },
          {
            id: Constants.INVOICE_TYPE_FGO,
            label: 'dxMessages.invoiceTypes.FGO',
          },
        ]}
      />
    ),
  },
];
