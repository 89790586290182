import { TextInput } from '@dx-ui/dx-common';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a TextInput
 * The rendering takes into account that the field can be a member of an array.
 */
const TextInputField = (props: FieldComponentProps) => {
  const { field } = props;
  const sanitizeProps: any = ({
    loading,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <TextInput
          {...sanitizeProps(fieldProps)}
          type={field?.options?.type || 'text'}
          resettable
          titleSuffix={field?.businessTerms}
        />
      )}
    </FieldWrapper>
  );
};

export default TextInputField;
