import DateFnsUtils from '@date-io/date-fns';
import { Chip, IconButton, InputAdornment, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FieldTitle } from 'ra-core';
import { useCallback } from 'react';
import { InputHelperText, useTranslate } from 'react-admin';
import useInput from './useInput';

const useStyles = makeStyles<Theme>(
  (theme) => ({
    clearButton: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    clearIcon: {
      height: theme.spacing(2),
      width: 0,
    },
    visibleClearIcon: {
      width: theme.spacing(2),
    },
    timeInput: {
      /* Chrome, Safari, Edge, Opera */
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      /* Firefox */
      '-moz-appearance': 'textfield',
      marginTop: theme.spacing(1),
    },
  }),
  { name: 'TimeInput' }
);
const TimeInput = (props) => {
  const {
    helperText,
    titleSuffix,
    options,
    label,
    source,
    resource,
    className,
    providerOptions,
    onBlur,
    onFocus,
    onChange,
    validate,
    resettable = true,
    ampm,
  } = props;

  const {
    input,
    isRequired,
    meta: { error, submitError },
  } = useInput({ resource, source, onBlur, onFocus, onChange, validate });
  const { value } = input;
  const touched = true;
  const classes = useStyles();
  const translate = useTranslate();

  const handleChange = useCallback(
    (value) => {
      Date.parse(value)
        ? input.onChange(value.toISOString())
        : input.onChange(null);
    },
    [input]
  );
  const handleMouseDownClearButton = (evt) => {
    evt.preventDefault();
  };

  const handleClear = (evt) => {
    evt.preventDefault();
    input.onChange(null);
  };

  const { clearButton, clearIcon, visibleClearIcon, timeInput } = classes;
  const getEndAdornment = () => {
    if (value) {
      return (
        <InputAdornment position='end'>
          <IconButton
            onClick={handleClear}
            onMouseDown={handleMouseDownClearButton}
            className={clearButton}
            aria-label={translate('ra.action.clear_input_value')}
            title={translate('ra.action.clear_input_value')}
            disableRipple
          >
            <ClearIcon
              className={classNames(clearIcon, {
                [visibleClearIcon]: value,
              })}
            />
          </IconButton>
        </InputAdornment>
      );
    } else {
      // show spacer
      return (
        <InputAdornment position='end'>
          <span className={clearButton}>&nbsp;</span>
        </InputAdornment>
      );
    }
  };
  return (
    <div className='picker'>
      <MuiPickersUtilsProvider {...providerOptions}>
        <TimePicker
          {...options}
          label={
            <>
              <FieldTitle
                label={label}
                source={source}
                resource={resource}
                isRequired={isRequired}
              />
              {titleSuffix &&
                titleSuffix.map((s) => {
                  return (
                    <Chip
                      label={s}
                      size='small'
                      style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                      key={s}
                    />
                  );
                })}
            </>
          }
          InputProps={{
            endAdornment: resettable && getEndAdornment(),
          }}
          margin='normal'
          error={!!(error || submitError)}
          helperText={
            <InputHelperText
              touched={touched}
              error={error || submitError}
              helperText={helperText}
            />
          }
          className={classNames(className, timeInput)}
          value={value ? new Date(value) : null}
          onChange={(date) => handleChange(date)}
          ampm={ampm}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

TimeInput.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  ampm: PropTypes.bool,
};

TimeInput.defaultProps = {
  input: {},
  meta: { touched: false, error: false },
  options: {},
  resource: '',
  source: '',
  labelTime: '',
  className: '',
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
  ampm: true,
};

export default TimeInput;
