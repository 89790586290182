import { IWidgetContent } from '../../layout/Dashboard/Widget';

enum MessageState {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

enum MessageType {
  GENERAL = 'general',
  MARKETING = 'marketing',
  DOWNTIME = 'downtime',
  BANNER = 'banner',
}

export interface IMessage {
  content: string;
  forAccountIds: string[];
  forCompanyIds: string[];
  fromInstant: number;
  id: string;
  language: string;
  moreInfoLink?: string;
  moreInfoLinkToKnowledgeBase?: boolean;
  publicationInstant: number;
  state: MessageState;
  toInstant: any;
  topic: string;
  type: MessageType;
}

export interface IMessageContent
  extends Omit<
    IWidgetContent,
    'account' | 'userPreferencesRootKey' | 'updateSize'
  > {
  messageIssuer: string;
  messageIssuerTitle: string;
  messageType: MessageType;
  widgetName: string;
  id?: string;
}

export { MessageState, MessageType };
