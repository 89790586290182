import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AdjustIcon from '@material-ui/icons/Adjust';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorIcon from '@material-ui/icons/Error';
import EventIcon from '@material-ui/icons/Event';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import WarningIcon from '@material-ui/icons/Warning';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Constants } from '../../constants';
import { useTz } from '../../intl';
import { AuditTrailItemDto, Category } from './types';

// BE CAREFUL: every time the backend defines a new type of event,
// you MUST update the i18n bundles with:
// 1. dxMessages.audit_trail.types.<event type>
// 2. dxMessages.audit_trail.descriptions.<event type>
//    The description may be parameterized ('bla bla: %{topic}')

// BE CAREFUL 2: every time the backend defines a new user task,
// you MUST update the i18n bundles with:
// 1. dxMessages.audit_trail.types.<each event type in USER_TASK_EVENT_TYPES>.<task definition key>
// 2. dxMessages.audit_trail.descriptions.<task definition key>
//    The description may be parameterized ('bla bla: %{topic}')

const USER_TASK_EVENT_TYPES = [
  'USER_TASK_CREATED',
  'USER_TASK_COMPLETED',
  'USER_TASK_DELETED',
];

const useStyles = makeStyles(
  (theme) => ({
    timelineWidth: {
      width: theme.spacing(100),
      [theme.breakpoints.down('sm')]: {
        width: '90vw',
      },
    },
    spinnerContainer: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    timeline: {},
    timeline_item_opposite: {},
    timeline_separator: {},
    timeline_item: {},
    timeline_item_paper: {
      padding: '16px 16px',
    },
    timeline_item_message: {
      maxHeight: '30em',
      overflowY: 'auto',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-all',
      textAlign: 'left',
    },
    timeline_item_secondaryTail: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  { name: 'AuditTrailTimeline' }
);

const TimeLineItemIcon = ({
  type,
  category,
}: {
  type: string;
  category: Category;
}) => {
  let iconComponent = EventIcon;

  switch (category) {
    case Category.FLOW_STARTED:
      iconComponent = AdjustIcon;
      break;
    case Category.DOCUMENT_RECEIVED:
      iconComponent = CallReceivedIcon;
      break;
    case Category.DOCUMENT_SENT:
      iconComponent = CallMadeIcon;
      break;
    case Category.THIRD_PARTY_ACKNOWLEDGMENT:
      iconComponent = CheckCircleOutlineIcon;
      break;
    case Category.DOCUMENT_REPRESENTATION_GENERATED:
      iconComponent = DescriptionIcon;
      break;
    case Category.DOCUMENT_ERROR:
      iconComponent = () => <ErrorIcon color='error' />;
      break;
    case Category.DOCUMENT_WARNING:
      iconComponent = () => <WarningIcon style={{ color: '#ffea00' }} />;
      break;
    case Category.DOCUMENT_VALIDATION:
      iconComponent = AssignmentTurnedInIcon;
      break;
    case Category.WAIT_FOR_USER_ACTION:
      iconComponent = HourglassEmptyIcon;
      break;
    case Category.USER_ACTION_DONE:
      iconComponent = DoneIcon;
      break;
    case Category.USER_ACTION_DELETED:
      iconComponent = HighlightOffIcon;
      break;
    case Category.FLOW_END:
      iconComponent = DoneAllIcon;
      break;
    case Category.INCIDENT:
      iconComponent = WarningIcon;
      break;
  }

  return React.createElement(iconComponent);
};

const getCategoryColor = (category: Category, theme: Theme) => {
  if (category === Category.DOCUMENT_ERROR) return theme.palette.error.dark;
  if (category === Category.DOCUMENT_WARNING) return theme.palette.error.light;
  return 'primary';
};

const AuditTrailItem = ({ item }: { item: AuditTrailItemDto }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const tz = useTz();
  const theme = useTheme();

  const date = moment(item.timestamp).tz(tz);

  const title = () => {
    // If it's a user task, the event payload contains a 'key' prop
    // with the definition key of the task (ex: invoiceHoldDecisionTask).
    if (USER_TASK_EVENT_TYPES.includes(item.type)) {
      // Try to refine the i18n msg with the task key.
      return translate(
        `dxMessages.audit_trail.types.${item.type}.${item.payload.key}`,
        {
          // If no i18n match, take the item type.
          _: translate(`dxMessages.audit_trail.types.${item.type}`, {
            // If no i18n match, take the item category.
            _: translate(`dxMessages.audit_trail.categories.${item.category}`, {
              // Fallback to the category itself.
              _: item.category,
            }),
          }),
        }
      );
    } else {
      return translate(`dxMessages.audit_trail.types.${item.type}`, {
        // If no i18n match, take the item category.
        _: translate(`dxMessages.audit_trail.categories.${item.category}`, {
          // Fallback to the category itself.
          _: item.category,
        }),
      });
    }
  };

  const description = () => {
    // If it's a user task, the event payload contains a 'key' prop
    // with the definition key of the task (ex: invoiceHoldDecisionTask).
    const key =
      (USER_TASK_EVENT_TYPES.includes(item.type) && item.payload?.key) ||
      item.description.key.replace('key.i18n', '').split('.').pop();

    return translate(`dxMessages.audit_trail.descriptions.${key}`, {
      ...item.description.params,
      // Fallback to the default trail message if no i18n.
      _: item.description.message,
    });
  };

  return (
    <TimelineItem>
      {item.category !== Category.FLOW_STARTED && (
        <TimelineOppositeContent className={classes.timeline_item_opposite}>
          <Paper elevation={3} className={classes.timeline_item_paper}>
            <Typography
              variant='h5'
              component='h1'
              style={{ color: getCategoryColor(item.category, theme) }}
              // color={getCategoryColor(item.category)}
            >
              {title()}
            </Typography>
            <Typography
              variant='body2'
              className={classes.timeline_item_message}
            >
              {description()}
            </Typography>
          </Paper>
        </TimelineOppositeContent>
      )}
      <TimelineSeparator className={classes.timeline_separator}>
        <TimelineDot color='primary' variant='outlined'>
          <TimeLineItemIcon type={item.type} category={item.category} />
        </TimelineDot>
        {/* Add a 'below' line connector if not the last item. */}
        {item.category !== Category.FLOW_END && (
          <TimelineConnector className={classes.timeline_item_secondaryTail} />
        )}
      </TimelineSeparator>
      <TimelineContent className={classes.timeline_item}>
        {date && (
          <Typography variant='body2' color='textSecondary'>
            {date.format('lll')}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

const AuditTrailTimeline = ({ documentId }: { documentId: string }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider(); // TODO: add data provider operation to dx-common

  const [data, setData] = useState<AuditTrailItemDto[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const P2P_INVOICE_STATUS_FROM_REGULATOR = 'P2P_INVOICE_STATUS_FROM_REGULATOR';

  // Fetch the audit trail if any.
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);

      try {
        const response = await dataProvider.apiGet(
          Constants.RESOURCE_AUDIT_TRAIL,
          {
            id: documentId,
          }
        );

        if (response) {
          setData(response?.data?.items);
        }
      } catch {
        // TODO: handle it as an error, not a 'no trail'.
        setData(undefined);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [dataProvider, documentId]);

  if (loading) {
    return (
      <div
        className={classNames(classes.spinnerContainer, classes.timelineWidth)}
      >
        <CircularProgress />
      </div>
    );
  }

  const formatText = (text) => {
    const rejectedText = text.slice(0, text.indexOf('<'));
    const startIndex = text.indexOf('<?xml');
    const xmlWithoutPrefix = text.slice(startIndex);
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlWithoutPrefix, 'text/xml');
    const errorElement = xmlDoc.querySelector('Error');

    if (errorElement) {
      const errorValue = errorElement.getAttribute('errorMessage');
      return rejectedText + '\n\n' + errorValue + '\n';
    } else {
      return text;
    }
  };

  if (!data) {
    return (
      <div className={classNames(classes.timeline, classes.timelineWidth)}>
        <Typography style={{ margin: '1em' }}>
          {translate('dxMessages.audit_trail.noTrail')}
        </Typography>
      </div>
    );
  }

  // Sort by oldest first.
  data.sort((i1, i2) => i1.timestamp - i2.timestamp);

  data.forEach((data) => {
    if (data.type === P2P_INVOICE_STATUS_FROM_REGULATOR) {
      if (data.description.params.status) {
        data.description.params.status = formatText(
          data.description.params.status
        );
      }
    }
  });

  return (
    <Timeline
      align='alternate'
      className={classNames(classes.timeline, classes.timelineWidth)}
    >
      {data.map((item, idx) => (
        <AuditTrailItem key={idx} item={item} />
      ))}
    </Timeline>
  );
};

export default AuditTrailTimeline;
