import {
  GenericLabeledDateField,
  GenericLabeledHourField,
  Metadata,
  SimpleLabeledField,
} from '@dx-ui/dx-common';
import { Grid, Paper, Typography } from '@material-ui/core';
import { get } from 'lodash';
import {
  Datagrid,
  ListContextProvider,
  TextField,
  useTranslate,
} from 'react-admin';
import { AlfrescoDocumentService } from '../../services';
import { P2pPreviewData } from '../../shared/types';
import { AddressField } from '../common/PreviewAddress';
import { useCellStyle, useCommonPreviewStyles } from '../common/PreviewStyles';
import { IDespatchAdviceSources } from './DespatchAdviceSources';

interface PropsModel {
  record: P2pPreviewData;
  dataSources: IDespatchAdviceSources;
  resource?: any;
}
const DespatchAdvicePreviewView = (props: PropsModel): JSX.Element => {
  const { record, dataSources, resource } = props;
  const classes = useCommonPreviewStyles(props);
  const cellStyleClasses = useCellStyle();
  const translate = useTranslate();
  const data = record;
  const isWebDespatchAdvice = AlfrescoDocumentService.isWebDocument(data);

  const lineIds = Object.keys(data?.linesView ?? []);
  const buyerCustomerParty = get(
    data.ublProperties,
    dataSources.DespatchAdvicePartySources.buyerCustomerParty.source
  );
  const buyerCustomerPartyAddress = get(
    buyerCustomerParty,
    dataSources.PartySources.postalAddress.source
  );
  const sellerSupplierParty = get(
    data.ublProperties,
    dataSources.DespatchAdvicePartySources.sellerSupplierParty.source
  );
  const sellerSupplierPartyAddress = get(
    sellerSupplierParty,
    dataSources.PartySources.postalAddress.source
  );
  const deliveryCustomerParty = get(
    data.ublProperties,
    dataSources.DespatchAdvicePartySources.deliveryCustomerParty.source
  );
  const deliveryCustomerPartyAddress = get(
    deliveryCustomerParty,
    dataSources.PartySources.postalAddress.source
  );
  const despatchSupplierParty = get(
    data.ublProperties,
    dataSources.DespatchAdvicePartySources.despatchSupplierParty.source
  );
  const despatchSupplierPartyAddress = get(
    despatchSupplierParty,
    dataSources.PartySources.postalAddress.source
  );

  const despatchSupplierPartyContactInfo = get(
    despatchSupplierParty,
    dataSources.PartySources.contact.source
  );

  const delivery = get(
    get(
      data.ublProperties,
      dataSources.DespatchAdviceRootSources.shipmentInformation.source
    ),
    dataSources.ShipmentInformationSources.delivery.source
  );

  const deliveryAddressFromMetadata = get(
    data.properties,
    Metadata.locationAddress
  );

  const orderReference = get(
    data.ublProperties,
    dataSources.DespatchAdviceRootSources.orderReference.source
  );

  return (
    <>
      <Paper square classes={{ root: classes.paper }}>
        <Grid container justify='center'>
          <Grid item>
            <Typography variant='h6' style={{ fontSize: '2em' }}>
              {translate('dxMessages.despatchAdvices.document_label')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container classes={{ container: classes.section }}>
          <Grid item xs={2}>
            <SimpleLabeledField
              label={translate('dxMessages.despatchAdvices.number')}
              data={
                isWebDespatchAdvice
                  ? get(data.properties, Metadata.documentId)
                  : get(
                      data.ublProperties,
                      dataSources.DespatchAdviceRootSources.number
                    )
              }
              className={Metadata.documentId}
            />
          </Grid>
          <Grid item xs={3}>
            {!isWebDespatchAdvice ? (
              <GenericLabeledDateField
                label={translate('dxMessages.headers.issueDate')}
                id={dataSources.DespatchAdviceRootSources.issueDate}
                record={data.ublProperties}
                className={Metadata.issueDate}
              />
            ) : (
              <GenericLabeledDateField
                label={translate('dxMessages.headers.issueDate')}
                id={Metadata.issueDate}
                record={data.properties}
                className={Metadata.issueDate}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <Grid item>
              <GenericLabeledDateField
                label={translate('dxMessages.headers.deliveryDate')}
                id={dataSources.DeliverySources.actualDeliveryDate}
                record={delivery}
                oneLine
                className={'deliveryDate'}
              />
            </Grid>
            <Grid item>
              <GenericLabeledHourField
                label={translate('dxMessages.headers.deliveryHour')}
                hour={get(
                  delivery,
                  dataSources.DeliverySources.actualDeliveryTime
                )}
                oneLine
                className={'deliveryHour'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction='row'
          classes={{ container: classes.graySection }}
        >
          <Grid item xs={6}>
            <Grid item>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.supplier')}
                  data={get(sellerSupplierParty, dataSources.PartySources.name)}
                  oneLine
                  className={Metadata.issuerName}
                />
              </Grid>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.supplierId')}
                  data={get(
                    sellerSupplierParty,
                    dataSources.PartySources.vatId
                  )}
                  oneLine
                  className={Metadata.issuerId}
                />
              </Grid>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.gln')}
                  data={get(sellerSupplierParty, dataSources.PartySources.gln)}
                  oneLine
                  className={'sellerSupplierGLN'}
                />
              </Grid>
              <Grid item>
                <AddressField
                  address={sellerSupplierPartyAddress}
                  dataAddressSources={dataSources.AddressSources}
                  className={'sellerSupplierAddress'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item>
              <SimpleLabeledField
                label={translate(
                  dataSources.DespatchAdvicePartySources.buyerCustomerParty
                    .title
                )}
                data={get(buyerCustomerParty, dataSources.PartySources.name)}
                oneLine
                className={Metadata.recipientName}
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.buyerId')}
                data={get(buyerCustomerParty, dataSources.PartySources.vatId)}
                oneLine
                className={Metadata.recipientId}
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.gln')}
                data={get(buyerCustomerParty, dataSources.PartySources.gln)}
                oneLine
                className={'buyerCustomerGLN'}
              />
            </Grid>
            <Grid item>
              <AddressField
                address={buyerCustomerPartyAddress}
                dataAddressSources={dataSources.AddressSources}
                className={'buyerCustomerAddress'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction='row'
          classes={{ container: classes.graySection }}
        >
          <Grid item xs={6}>
            <Grid item>
              <SimpleLabeledField
                label={translate(
                  'dxMessages.despatchAdvices.despatchSupplierPartyID'
                )}
                data={get(
                  despatchSupplierParty,
                  dataSources.PartySources.vatId
                )}
                oneLine
                className={'despatchSupplierPartyID'}
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate(
                  'dxMessages.despatchAdvices.shippingContactName'
                )}
                data={get(
                  despatchSupplierPartyContactInfo,
                  dataSources.ContactSources.contactName
                )}
                oneLine
                className={'despatchSupplierPartyContactContactName'}
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate(
                  'dxMessages.despatchAdvices.shippingContactEmail'
                )}
                data={get(
                  despatchSupplierPartyContactInfo,
                  dataSources.ContactSources.email
                )}
                oneLine
                className={'despatchSupplierPartyContactEmail'}
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate(
                  'dxMessages.despatchAdvices.shippingContactPhone'
                )}
                data={get(
                  despatchSupplierPartyContactInfo,
                  dataSources.ContactSources.phone
                )}
                oneLine
                className={'despatchSupplierPartyContactPhone'}
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate(
                  dataSources.DespatchAdvicePartySources.despatchSupplierParty
                    .title
                )}
                data={get(despatchSupplierParty, dataSources.PartySources.name)}
                oneLine
                className={'despatchSupplierPartyName'}
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.gln')}
                data={get(despatchSupplierParty, dataSources.PartySources.gln)}
                oneLine
                className={'despatchSupplierPartyGLN'}
              />
            </Grid>
            <Grid item>
              <AddressField
                address={despatchSupplierPartyAddress}
                dataAddressSources={dataSources.AddressSources}
                className={'despatchSupplierPartyAddress'}
              />
            </Grid>
          </Grid>
          {!isWebDespatchAdvice ? (
            <Grid item xs={6}>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.locationAddress')}
                  data={get(data.properties, Metadata.locationName)}
                  oneLine
                  className={Metadata.locationName}
                />
              </Grid>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.gln')}
                  data={get(data.properties, Metadata.gln)}
                  oneLine
                  className={Metadata.gln}
                />
              </Grid>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.address')}
                  data={deliveryAddressFromMetadata}
                  oneLine
                  className={Metadata.locationAddress}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.locationAddress')}
                  data={get(
                    deliveryCustomerParty,
                    dataSources.PartySources.name
                  )}
                  oneLine
                  className={Metadata.locationName}
                />
              </Grid>
              <Grid item>
                <SimpleLabeledField
                  label={translate('dxMessages.headers.gln')}
                  data={get(
                    deliveryCustomerParty,
                    dataSources.PartySources.gln
                  )}
                  oneLine
                  className={Metadata.gln}
                />
              </Grid>
              <AddressField
                label={translate('dxMessages.headers.address')}
                address={deliveryCustomerPartyAddress}
                dataAddressSources={dataSources.AddressSources}
                className={Metadata.locationAddress}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          spacing={2}
          direction='row'
          classes={{ container: classes.section }}
        >
          <Grid item xs={4}>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.orderId')}
                data={get(orderReference, dataSources.OrderReferenceSources.id)}
                oneLine
                className={Metadata.orderId}
              />
            </Grid>
            <Grid item>
              <GenericLabeledDateField
                label={translate('dxMessages.headers.orderDate')}
                id={dataSources.OrderReferenceSources.issueDate}
                record={orderReference}
                oneLine
                className={Metadata.orderDate}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.supplierReceiverCode')}
                data={get(
                  data.ublProperties,
                  dataSources.DespatchAdvicePartySources
                    .customerAssignedAccountId
                )}
                oneLine
                className={'supplierReceiverCode'}
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.numberOfLines')}
                data={lineIds.length}
                oneLine
                className={'numberOfLines'}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction='column'
            classes={{ container: classes.section }}
          >
            <Grid item>
              <ListContextProvider
                value={{
                  ids: lineIds,
                  data: data.linesView,
                  currentSort: { field: 'nr', order: 'ASC' },
                  resource,
                  selectedIds: [],
                }}
              >
                <Datagrid
                  classes={{
                    row: classes.row,
                    rowCell: classes.linesCell,
                    headerCell: classes.headerCell,
                    tbody: classes.tbody,
                  }}
                >
                  <TextField
                    source='nr'
                    label='dxMessages.headers.number'
                    sortable={false}
                  />
                  <TextField
                    source={`${dataSources.DespatchAdviceLineSources.item.source}.${dataSources.DespatchAdviceLineSources.item.fields.description}`}
                    label='dxMessages.headers.description'
                    sortable={false}
                    classes={{ root: cellStyleClasses.cellWrapped }}
                    style={{ minWidth: '8em' }}
                  />
                  <TextField
                    source={`${dataSources.DespatchAdviceLineSources.item.source}.${dataSources.DespatchAdviceLineSources.item.fields.standardItemIdentification}`}
                    label='dxMessages.headers.codeStandard'
                    sortable={false}
                    style={{ minWidth: '8em' }}
                  />
                  <TextField
                    source={`${dataSources.DespatchAdviceLineSources.item.source}.${dataSources.DespatchAdviceLineSources.item.fields.buyersItemIdentification}`}
                    label='dxMessages.headers.codeClient'
                    sortable={false}
                    style={{ minWidth: '8em' }}
                  />
                  <TextField
                    source={`${dataSources.DespatchAdviceLineSources.item.source}.${dataSources.DespatchAdviceLineSources.item.fields.sellersItemIdentification}`}
                    label='dxMessages.headers.codeSupplier'
                    sortable={false}
                    style={{ minWidth: '8em' }}
                  />
                  <TextField
                    source={
                      dataSources.DespatchAdviceLineSources.deliveredQuantity
                    }
                    label='dxMessages.headers.quantity'
                    sortable={false}
                  />
                  <TextField
                    source={
                      dataSources.DespatchAdviceLineSources
                        .deliveryQuantityUnitCode
                    }
                    label='dxMessages.headers.um'
                    sortable={false}
                  />
                </Datagrid>
              </ListContextProvider>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default DespatchAdvicePreviewView;
