/**
 * Display the count of documents received by DocProcess per document
 * type and per day.
 */
import { gql, useQuery } from '@apollo/client';
import { DxTheme, UserRoles, useSetupUsage } from '@dx-ui/dx-common/src';
import { Account, UsageRight } from '@dx-ui/dx-common/src/configuration/types';
import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import { SetupUsage } from '@dx-ui/dx-common/src/utils/useSetupUsage';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { usePreferences } from '@react-admin/ra-preferences';
import { debounce, groupBy } from 'lodash';
import moment, { Moment } from 'moment';
import React, { FC, useEffect, useState } from 'react';
import {
  Identifier,
  useLocale,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import ReactCardFlip from 'react-card-flip';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Tooltip as RcTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import DocumentReceptionsDemo from '../demo/DocumentReceptionsDemo';

// The supported document types.
export enum DocumentType {
  INVOICE = 'INVOICE',
  ORDER = 'ORDER',
  RECADV = 'RECADV',
  DESADV = 'DESADV',
}

const I18N_KEY = 'dxMessages.dashboard.widgets.DocumentReceptions';
const I18N_WIDGET = 'dxMessages.dashboard.widgets.WidgetTitle.name';

export const DocumentReceptionsCountBarChart: FC<{
  loading: boolean;
  data: any;
  documentTypes: DocumentType[];
  allowedDocumentTypes: DocumentType[];
  userPreferencesRootKey: string;
  onToggleDocumentType: (type: DocumentType, show: boolean) => void;
  onTheShelves?: boolean;
  openConfiguration: boolean;
}> = ({
  loading,
  data,
  documentTypes,
  allowedDocumentTypes,
  userPreferencesRootKey,
  onToggleDocumentType,
  onTheShelves,
  openConfiguration,
}) => {
  const translate = useTranslate();
  const theme = useTheme();

  if (loading) return <CircularProgress size='1em' />;

  // No data, no bar chart.
  if (data.dailyDocumentMetrics.metrics.length === 0)
    return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;

  // Knead the anayltic metrics to fit the data format expected by the bar chart.
  const dateAndTypes = groupBy(
    data.dailyDocumentMetrics.metrics.map((_) => ({
      date: _.dimensions.dateDimension.date,
      type: _.docType,
      count: parseInt(_.value),
    })),
    ({ date }) => date
  );
  const metrics = Object.keys(dateAndTypes).map((date) =>
    dateAndTypes[date].reduce((acc, t) => {
      acc.date = date;
      acc[t.type] = t.count;
      return acc;
    }, {})
  );

  return (
    <>
      <DocumentCountBarChart
        data={metrics}
        documentTypes={documentTypes.filter((_) =>
          allowedDocumentTypes.includes(_)
        )}
        sessionStorageRootKey={userPreferencesRootKey}
        onTheShelves={onTheShelves}
        openConfiguration={openConfiguration}
      />
      {/* The checkbox list to toggle the display of each document type.  */}
      <Box display='flex' justifyContent='center' flexWrap='wrap'>
        {allowedDocumentTypes.map((type) => (
          <Box key={type} display='flex' alignItems='center'>
            <Checkbox
              checked={documentTypes.includes(type)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onToggleDocumentType(type, event.target.checked)
              }
              size='small'
              style={{ color: documentTypeColor(type, theme) }}
            />
            <Typography variant='caption'>
              {translate(`dxMessages.documentTypeCode.${type}`)}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

const DocumentReceptions: FC<IWidgetContent> = ({
  onTheShelves,
  userPreferencesRootKey,
  account,
  openConfiguration,
}) => {
  const classes = useStyles({ onTheShelves: onTheShelves });
  const theme = useTheme();
  const translate = useTranslate();
  const [help, setHelp] = useState(false);
  const [month, setMonth] = usePreferences(
    `${userPreferencesRootKey}.month`,
    moment().format('YYYY-MM')
  );
  const [monthNumber, setMonthNumber] = usePreferences(
    `${userPreferencesRootKey}.documentReceptionMonthNumber`
  );
  const [saveYear, setSaveYear] = usePreferences(
    `${userPreferencesRootKey}.documentReceptionSaveYear`,
    moment().format('YYYY')
  );
  const [selectedMonth, setSelectedMonth] = useState('');

  // differentiate the browsers we use
  const userAgent = navigator.userAgent;
  const setupUsage = useSetupUsage();

  const onMonthChange = (e) => {
    setMonth(e.target.value);
    if (moment.months().includes(moment(e.target.value).format('MMMM'))) {
      setMonthNumber(
        moment.months().indexOf(moment(e.target.value).format('MMMM'))
      );
    }
    setSaveYear(moment(e.target.value).format('YYYY'));
  };

  const isPspAdministrator = allUsageRights(account).some(
    (_) =>
      _.feature.id === 'administration' &&
      _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR)
  );

  const allowedDocumentTypes: DocumentType[] = isPspAdministrator
    ? Object.values(DocumentType)
    : allUsageRights(account)
        .filter((_) => _.feature.id === 'DxPurchase')
        .flatMap((_) => _.roles)
        .map((_) => {
          switch (_.id) {
            case UserRoles.VIEW_INVOICE:
              return DocumentType.INVOICE;
            case UserRoles.VIEW_ORDER:
              return DocumentType.ORDER;
            case UserRoles.VIEW_RECEIPT_ADVICE:
              return DocumentType.RECADV;
            case UserRoles.VIEW_DESPATCH_ADVICE:
              return DocumentType.DESADV;
            default:
              return undefined;
          }
        })
        .filter((type): type is DocumentType => !!type) // Remove the undefined.
        .sort() || [];

  // option to save the title of a chart
  const [customizedTitle, setCustomizedTitle] = usePreferences(
    `${userPreferencesRootKey}.documentTitle`,
    translate(`${I18N_KEY}.title`)
  );
  const handleChange = (event) => {
    setCustomizedTitle(event.target.value);
  };

  const widgetTitle = translate(I18N_WIDGET);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const changeMonth = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMonth = (event: any) => {
    setSelectedMonth(event.target.value);
    setMonthNumber(moment.months().indexOf(event.target.value));
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 165,
        width: 150,
      },
    },
  };
  const handleYear = (event: any) => {
    setSaveYear(event.target.value);
  };
  // get a list with all DocProcess years
  const currentYear = new Date().getFullYear();
  const [startYear, setStartYear] = useState(2005);
  const [arrayYears, setArrayYears]: any = useState([]);
  useEffect(() => {
    if (currentYear >= startYear) {
      setArrayYears((arrayYears) => [...arrayYears, startYear]);
      setStartYear(startYear + 1);
    } else {
      setArrayYears(arrayYears.reverse());
    }
  }, [arrayYears, currentYear, startYear]);

  // effect hook for date picker in case of mozilla and safari
  useEffect(() => {
    if (!userAgent.match(/chrome/i)) {
      const monthName = moment.months()[monthNumber];
      monthName !== undefined
        ? setMonth(saveYear + '-' + moment(monthName, 'MMMM').format('MM'))
        : setMonth(saveYear + '-' + moment().format('MM'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    monthNumber,
    saveYear,
    selectedMonth,
    setMonth,
    setMonthNumber,
    userAgent,
  ]);

  return (
    // Flip the card to display the help.
    <ReactCardFlip isFlipped={help} flipDirection='vertical'>
      <Card
        classes={{
          root: classes.card,
        }}
      >
        {!onTheShelves && (
          <div className={classes.help}>
            <IconButton onClick={() => setHelp(!help)} size='small'>
              <HelpIcon />
            </IconButton>
          </div>
        )}
        <CardHeader
          // when drawer configuration is open, the title of widget can be changed
          title={
            openConfiguration === false ? (
              customizedTitle
            ) : onTheShelves ? (
              translate(`${I18N_KEY}.title`)
            ) : (
              <TextField
                id='standard-basic'
                label={widgetTitle}
                variant='standard'
                style={{ width: '500px' }}
                defaultValue={customizedTitle}
                onChange={handleChange}
                onDoubleClick={(ev: any) => ev.target.select()}
                autoComplete='off'
                inputProps={{ maxLength: 120 }}
              />
            )
          }
          subheader={
            // Select the month we want the count per day for.
            <div>
              {userAgent.match(/chrome/i) ? (
                <TextField
                  id='to'
                  label={translate(`${I18N_KEY}.during`)}
                  type='month'
                  size='small'
                  value={month}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={onMonthChange}
                  // When in the configuration panel, diasble the month selection.
                  disabled={onTheShelves}
                  required
                />
              ) : (
                <>
                  <TextField
                    id='to'
                    label={translate(`${I18N_KEY}.during`)}
                    type='button'
                    size='small'
                    value={moment(month, 'YYYY-MM').format('MMMM YYYY')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onClick={changeMonth}
                    // When in the configuration panel, diasble the month selection.
                    disabled={onTheShelves}
                    required
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <FormControl variant='standard' className={classes.form}>
                      <InputLabel id='month-picker' className={classes.date}>
                        {translate('dxMessages.dashboard.widgets.Date.month')}
                      </InputLabel>
                      <Select
                        labelId='month-picker'
                        id='month-picker'
                        value={
                          selectedMonth.length !== 0
                            ? selectedMonth
                            : monthNumber !== undefined
                            ? moment.months()[monthNumber]
                            : moment().format('MMMM')
                        }
                        onChange={handleMonth}
                        label='Month Picker'
                        MenuProps={MenuProps}
                      >
                        {moment.months().map((month) => (
                          <MenuItem key={month} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl variant='standard' className={classes.form}>
                      <InputLabel id='year-picker' className={classes.date}>
                        {translate('dxMessages.dashboard.widgets.Date.year')}
                      </InputLabel>
                      <Select
                        labelId='year-picker'
                        id='year-picker'
                        value={saveYear}
                        onChange={handleYear}
                        label='Year Picker'
                        MenuProps={MenuProps}
                      >
                        {arrayYears.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Popover>
                </>
              )}
            </div>
          }
        />
        <CardContent style={{ marginBottom: '1em' }}>
          {/* MUST set a height for the diagram. */}
          <div style={{ width: '100%', height: '35vh' }}>
            {onTheShelves || setupUsage !== SetupUsage.PRODUCTION ? (
              <DocumentReceptionsDemo
                allowedDocumentTypes={allowedDocumentTypes}
                startDate={moment(month, 'YYYY-MM').startOf('month')}
                endDate={moment(month, 'YYYY-MM').endOf('month')}
                userPreferencesRootKey={userPreferencesRootKey}
                onTheShelves={onTheShelves}
                openConfiguration={openConfiguration}
              />
            ) : (
              <DocumentReceptionsLive
                companyId={account.company.id}
                allowedDocumentTypes={allowedDocumentTypes}
                startDate={moment(month, 'YYYY-MM').startOf('month')}
                endDate={moment(month, 'YYYY-MM').endOf('month')}
                userPreferencesRootKey={userPreferencesRootKey}
                openConfiguration={openConfiguration}
              />
            )}
          </div>
        </CardContent>
      </Card>
      {/* Display the guide for this widget.  */}
      <Card style={{ backgroundColor: theme.palette.grey[50] }}>
        <div className={classes.help}>
          <IconButton onClick={() => setHelp(!help)} size='small'>
            <KeyboardReturnIcon />
          </IconButton>
        </div>
        <Box m={1} mt={2} p={1}>
          <Help allowedDocumentTypes={allowedDocumentTypes} />
        </Box>
      </Card>
    </ReactCardFlip>
  );
};

const allUsageRights = (account: Account): UsageRight[] =>
  account.configuredUsageRights
    .concat(account.delegatedUsageRights)
    .concat(account.onTheFlyUsageRights);

const DocumentReceptionsLive: FC<{
  companyId: Identifier;
  allowedDocumentTypes: DocumentType[];
  startDate: Moment;
  endDate: Moment;
  userPreferencesRootKey: string;
  openConfiguration: boolean;
}> = ({
  companyId,
  allowedDocumentTypes,
  startDate,
  endDate,
  userPreferencesRootKey,
  openConfiguration,
}) => {
  const translate = useTranslate();

  // Keep in the user preferences the document types he's interested in.
  const [documentTypes, setDocumentTypes] = usePreferences(
    `${userPreferencesRootKey}.documentTypes`,
    allowedDocumentTypes
  );

  // Ask the analytic endpoint for the document count for the user company.
  const { loading, error, data } = useQuery(DOC_COUNT_GRAPHQL_REQUEST, {
    variables: {
      client:
        process.env.NODE_ENV === 'production'
          ? companyId
          : '001G_xGUkNWTwXqRdHxm15oh9OegSKvYtpuLBgd6oFZgXRdNOasspAh',
      // The date dimension in the anayltic dataset is typed as a 'YYYYMMDD'
      // strings.
      startDate: startDate.format('YYYYMMDD'),
      endDate: endDate.format('YYYYMMDD'),
      // Load all document types so there is no full UI refresh when user
      // unselects a type.
      docTypes: allowedDocumentTypes,
    },
  });

  if (error)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          error,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );

  // Record the types of documents to show in the bar chart.
  const onToggleDocumentType = (type: DocumentType, show: boolean) => {
    if (show && !documentTypes.includes(type))
      setDocumentTypes([...documentTypes, type]);
    else if (!show && documentTypes.includes(type))
      setDocumentTypes(documentTypes.filter((_) => _ !== type));
  };

  return (
    <DocumentReceptionsCountBarChart
      loading={loading}
      data={data}
      documentTypes={documentTypes}
      allowedDocumentTypes={allowedDocumentTypes}
      userPreferencesRootKey={userPreferencesRootKey}
      onToggleDocumentType={onToggleDocumentType}
      openConfiguration={openConfiguration}
    />
  );
};

const DocumentCountBarChart: FC<{
  data: any[];
  documentTypes: DocumentType[];
  sessionStorageRootKey: string;
  onTheShelves?: boolean;
  openConfiguration: boolean;
}> = ({
  data,
  documentTypes,
  sessionStorageRootKey,
  onTheShelves = false,
  openConfiguration,
}) => {
  const translate = useTranslate();
  const theme = useTheme();
  const redirect = useRedirect();
  const [startIndex, setStartIndex] = useState(() => {
    // Survive browser reloading.
    const index = sessionStorage.getItem(`${sessionStorageRootKey}.startIndex`);
    return index ? parseInt(index) : 0;
  });
  const [endIndex, setEndIndex] = useState(() => {
    // Survive browser reloading.
    const index = sessionStorage.getItem(`${sessionStorageRootKey}.endIndex`);
    return index ? parseInt(index) : undefined;
  });
  const classes = useStyles({ onTheShelves: onTheShelves });
  const notify: any = useNotify();

  // Redirect to the corresponding list view when clicking on a bar.
  const onBarClick = (type: DocumentType, day: string /* ex: 20220209*/) => {
    const from = moment(day, 'YYYYMMDD').startOf('day').toISOString();
    const to = moment(day, 'YYYYMMDD').endOf('day').toISOString();
    const filter = encodeURIComponent(
      `{"created":{"from":"${from}","to":"${to}"}}`
    );
    if (!onTheShelves) {
      switch (type) {
        case DocumentType.INVOICE:
          redirect(`/invoice?filter=${filter}`);
          break;
        case DocumentType.ORDER:
          redirect(`/order?filter=${filter}`);
          break;
        case DocumentType.RECADV:
          redirect(`/recadv?filter=${filter}`);
          break;
        case DocumentType.DESADV:
          redirect(`/despatchAdvice?filter=${filter}`);
          break;
      }
      notify('dxMessages.dashboard.widgets.InformBanner.title', 'info', {
        autoHideDuration: 10000,
      });
    }
  };

  return (
    /* Set a 99% width to fix the window down size pb. */
    <ResponsiveContainer width='99%' height='100%'>
      <BarChart
        data={[...data].sort((s1, s2) => s1.date.localeCompare(s2.date))}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey='date'
          tickLine={false}
          interval={0}
          tick={tick}
          height={20}
        />
        <YAxis allowDecimals={false} />
        {/* @ts-ignore */}
        <RcTooltip content={<BarChartTooltip />} />
        {documentTypes.map((type) => (
          <Bar
            key={type}
            name={translate(`dxMessages.documentTypeCode.${type}`)}
            dataKey={type}
            fill={documentTypeColor(type, theme)}
            // @ts-ignore
            shape={<TriangleBar />}
            // @ts-ignore
            label={<TopCenterBarLabel />}
            onClick={(ev) => {
              if (openConfiguration === false) return onBarClick(type, ev.date);
            }}
            className={openConfiguration === false ? classes.bar : ''}
          />
        ))}
        <Brush
          dataKey='name'
          height={20}
          startIndex={startIndex}
          endIndex={endIndex}
          onChange={debounce((e) => {
            setStartIndex(() => {
              // Survive browser reloading.
              sessionStorage.setItem(
                `${sessionStorageRootKey}.startIndex`,
                e.startIndex
              );
              return e.startIndex;
            });
            setEndIndex(() => {
              // Survive browser reloading.
              sessionStorage.setItem(
                `${sessionStorageRootKey}.endIndex`,
                e.endIndex
              );
              return e.endIndex;
            });
          }, 500)}
          // No label in the brush.
          tickFormatter={() => ''}
          // Prevent the widget from moving when dragging the brush.
          className='outsideDashboardGrip'
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const DOC_COUNT_GRAPHQL_REQUEST = gql`
  query DailyDocumentMetrics(
    $client: String!
    $startDate: String!
    $endDate: String!
    $docTypes: [DocType]!
  ) {
    dailyDocumentMetrics(
      client: $client
      startDate: $startDate
      endDate: $endDate
      statusGrouping: false
      docTypes: $docTypes
    ) {
      metrics {
        name
        value
        docType
        docStatus
        dimensions {
          dateDimension {
            date
          }
        }
      }
    }
  }
`;

const documentTypeColor = (type: DocumentType, theme: Theme) => {
  // https://material.io/design/color/the-color-system.html#tools-for-picking-colors
  // serie 100
  switch (type) {
    case DocumentType.INVOICE:
      return '#90DB91';
    case DocumentType.ORDER:
      return '#69A4DB';
    case DocumentType.RECADV:
      return '#9379B2';
    case DocumentType.DESADV:
      return '#F5B047';
  }
};

// X axis: put the day num and bold Saturday and Sunday.
const tick = ({ x, y, payload: { value } }) => {
  const day = moment(value, 'YYYYMMDD');
  return (
    <text x={x} y={y + 5} fontSize='12' textAnchor='middle'>
      {day.format('D')}
    </text>
  );
};

// The shape of the bar (trianle pointing to the top).
const TriangleBar = ({ fill, x, y, width, height, value }) =>
  value ? (
    <path
      d={triangleBarPath(x, y, width, height)}
      stroke='none'
      fill={fill}
      cursor='inherit'
    />
  ) : null;

const triangleBarPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${
  y + height
} ${x + width}, ${y + height}
          Z`;

// Put the count at the spike of the bar.
const TopCenterBarLabel = ({ x, y, width, fill, value }) => (
  <text
    x={x}
    y={y}
    dx={width / 2}
    dy={-1}
    fontSize='8'
    fill={fill}
    textAnchor='middle'
  >
    {value}
  </text>
);

// The tooltip per dated bars: the date and the list of counts per document type.
const BarChartTooltip = ({ active, payload, label }) =>
  active && payload && payload.length ? (
    <div
      style={{
        // Use a semi-transparent background so the adjacent bars are not hidden
        // (and use can still compare bare values).
        background: 'rgba(210, 210, 210, 0.9)',
        border: '1px solid black',
        padding: '5px',
      }}
    >
      <Typography>{moment(label, 'YYYYMMDD').format('MMM Do YY')}</Typography>
      <ul>
        {payload.map((_) => (
          <li key={_.name}>
            <Typography>
              {_.name}: {_.value}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  ) : null;

const Help: FC<{ allowedDocumentTypes: DocumentType[] }> = ({
  allowedDocumentTypes,
}) => {
  const locale = useLocale();
  const theme = useTheme();
  const translate = useTranslate();

  switch (locale) {
    case 'fr':
      return (
        <>
          <Typography paragraph>
            Ce widget affiche le nombre de documents échangés via la plateforme
            DocProcess, par jour et par type de document.
          </Typography>
          <Typography>
            Sélectionnez le mois qui vous intéresse puis affinez votre recherche
            en:
          </Typography>
          <ul>
            <li>
              Filtrant par type de document. Cochez les cases correspondantes en
              bas du diagramme:
              <Box display='flex' flexDirection='row'>
                {allowedDocumentTypes.map((type) => (
                  <Box
                    key={type}
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                  >
                    <Checkbox
                      size='small'
                      style={{ color: documentTypeColor(type, theme) }}
                      checked
                    />
                    <Typography variant='caption'>
                      {translate(`dxMessages.documentTypeCode.${type}`)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </li>
            <li>
              Zoomant sur une période spécifique. Utilisez la barre en bas du
              diagramme pour ajuster la période affichée en faisant glisser les
              extrémités:
              <div
                style={{
                  width: '80%',
                  marginTop: '1em',
                  background: theme.palette.grey[400],
                  border: '1px solid black',
                  borderLeft: `7px solid ${theme.palette.grey[700]}`,
                  borderRight: `7px solid ${theme.palette.grey[700]}`,
                }}
              >
                <span>← raccourcir ici</span>
                <span style={{ float: 'right' }}>ou ici →</span>
              </div>
            </li>
          </ul>
          <Typography>
            <span style={{ fontWeight: 'bolder' }}>Astuce</span>: en cliquant
            sur l'un des pics de couleur, vous serez redirigé vers la liste de
            documents correspondante.
          </Typography>
          <Typography>
            La liste des documents en cours de traitement peut être différente
            de celle affichée dans le graphique.
          </Typography>
        </>
      );
    case 'ro':
      return (
        <>
          <Typography paragraph>
            Acest widget afișează numărul de documente schimbate prin
            intermediul platformei DocProcess, pe zi și pe tip de document.{' '}
          </Typography>
          <Typography>
            Selectează luna care te interesează, apoi rafinează-ți căutarea
            prin:
          </Typography>
          <ul>
            <li>
              Filtrarea după tipul de document. Bifează casetele corespunzătoare
              din partea de jos a diagramei:{' '}
              <Box display='flex' flexDirection='row'>
                {allowedDocumentTypes.map((type) => (
                  <Box
                    key={type}
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                  >
                    <Checkbox
                      size='small'
                      style={{ color: documentTypeColor(type, theme) }}
                      checked
                    />
                    <Typography variant='caption'>
                      {translate(`dxMessages.documentTypeCode.${type}`)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </li>
            <li>
              Concentrarea pe o anumită perioadă. Utilizează bara din partea de
              jos a diagramei pentru a ajusta perioada afișată trăgând fiecare
              capăt al barei:
              <div
                style={{
                  width: '80%',
                  marginTop: '1em',
                  background: theme.palette.grey[400],
                  border: '1px solid black',
                  borderLeft: `7px solid ${theme.palette.grey[700]}`,
                  borderRight: `7px solid ${theme.palette.grey[700]}`,
                }}
              >
                <span>← stoarce aici</span>
                <span style={{ float: 'right' }}>ou aici →</span>
              </div>
            </li>
          </ul>
          <Typography>
            <span style={{ fontWeight: 'bolder' }}>Sfat</span>: apăsând pe unul
            dintre vârfurile colorate, vei fi redirecționat către lista
            corespunzătoare de documente.
          </Typography>
          <Typography>
            Lista documentelor aflate în procesare poate fi diferită de cea
            afișată în grafice.
          </Typography>
        </>
      );
    default:
      return (
        <>
          <Typography paragraph>
            This widget displays the number of documents exchanged via the
            DocProcess platform, per day and per document type.{' '}
          </Typography>
          <Typography>
            Select the month you are interested in, then refine your search by:
          </Typography>
          <ul>
            <li>
              Filtering the document type. Click on the corresponding checkboxes
              at the bottom of the diagram:{' '}
              <Box display='flex' flexDirection='row'>
                {allowedDocumentTypes.map((type) => (
                  <Box
                    key={type}
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                  >
                    <Checkbox
                      size='small'
                      style={{ color: documentTypeColor(type, theme) }}
                      checked
                    />
                    <Typography variant='caption'>
                      {translate(`dxMessages.documentTypeCode.${type}`)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </li>
            <li>
              Zooming in on a specific timeframe. Use the brush bar at the
              bottom of the diagram and adjust the time period by sliding each
              end of the bar:
              <div
                style={{
                  width: '80%',
                  marginTop: '1em',
                  background: theme.palette.grey[400],
                  border: '1px solid black',
                  borderLeft: `7px solid ${theme.palette.grey[700]}`,
                  borderRight: `7px solid ${theme.palette.grey[700]}`,
                }}
              >
                <span>← squeeze here</span>
                <span style={{ float: 'right' }}>or here →</span>
              </div>
            </li>
          </ul>
          <Typography>
            <span style={{ fontWeight: 'bolder' }}>Tip</span>: clicking on one
            of the peaks redirects to the corresponding list of documents.
          </Typography>
          <Typography>
            List of documents in processing may be different than that shown in
            chart.
          </Typography>
        </>
      );
  }
};

const useStyles = makeStyles((theme: DxTheme) => ({
  // Fixed into the top right corner.
  help: {
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(0),
  },
  bar: (props: { onTheShelves: boolean }) => {
    return {
      cursor: props.onTheShelves ? 'inherit' : 'pointer',
    };
  },
  date: {
    padding: '10px 10px 10px 10px',
  },
  form: { width: 120, padding: '10px 10px 10px 10px' },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
    opacity: '0.7',
    overflowY: 'auto',
    overflowX: 'auto',
  },
}));

export default DocumentReceptions;
