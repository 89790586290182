import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import {
  FieldProps,
  PasswordInput,
  useAuthProvider,
  UserIdentity,
  useTranslate,
} from 'react-admin';
import {
  DxPasswordPolicyAlert,
  validatePassword,
} from '../identityprovider/dxPasswordPolicy';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The input panel to change the current password.
 *
 * Look at https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
 * for the meaning of the 'autoComplete' attributes.
 */
const ChangePassword: FC<FieldProps<UserIdentity>> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const authProvider = useAuthProvider();
  const identity = authProvider.getIdentity?.();

  if (!identity) return null;

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={0} direction='column'>
            <Typography variant='h5'>
              {translate('resources.profile.edit.changePassword')}
            </Typography>
            <Typography variant='caption'>
              {translate('resources.profile.edit.changePasswordHelper')}
            </Typography>
            <Grid item>
              <PasswordInput
                label='resources.profile.edit.currentPassword'
                source='password'
                autoComplete='new-password' // strangelly the way to disable the autofill
                fullWidth
              />
            </Grid>
            <Grid item>
              <PasswordInput
                label='resources.profile.edit.newPassword'
                source='newPassword'
                fullWidth
                autoComplete='new-password'
                validate={validatePassword}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <DxPasswordPolicyAlert />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ChangePassword;
