import { useGAPageViews } from '@dx-ui/dx-common/src';
import UserProfile from '@dx-ui/dx-common/src/login/MyAccount/UserProfile';
import { Show } from 'react-admin';
export const UserShow = (props: any) => {
  useGAPageViews();
  return (
    <Show {...props}>
      <UserProfile username={props.id} />
    </Show>
  );
};
