import { registerPlugin } from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import React, { useRef, useState } from 'react';
import { useNotify, useRedirect, useTranslate } from 'react-admin';
import { FilePond } from 'react-filepond';
registerPlugin(FilePondPluginFileValidateSize);

export const UploadFilesField = (props: any) => {
  const { postUrl, revertUrl } = props;
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const ref = useRef(null);
  const [files, setFiles] = useState<any[]>([]);

  return (
    <FilePond
      files={files}
      ref={ref}
      allowMultiple={true}
      allowProcess={false}
      // @ts-ignore
      maxFileSize='5MB'
      server={{
        process: {
          url: postUrl,
          timeout: 600000,
          onerror: (error) => {
            // eslint-disable-next-line no-console
            return error;
          },
        },
        revert: {
          url: revertUrl,
          timeout: 10000,
        },
      }}
      onerror={(error) => {
        if (error.code === 403) {
          redirect('/login');
        }
        if (error.code === 400) {
          notify(error.body, 'error', {}, true, 10000);
        }
      }}
      onprocessfiles={() => {
        notify(translate('dxMessages.uploadFiles.ready'), 'info');
        redirect('list', props.basePath);
      }}
      onremovefile={() => {
        setFiles([]);
      }}
    />
  );
};

export default UploadFilesField;
