import countries from 'i18n-iso-countries';

/**
 * Pair of <country_code, country_name>
 */
export interface Country {
  code: string;
  name: string;
}

export class CountryService {
  private static supportedLocales = ['ar', 'en', 'fr', 'he', 'ro'];

  constructor(public locale: string = 'en') {
    if (!this.localeIsSupported(locale)) {
      throw new Error(
        `Locale ${locale} is not supported by CountryService. List of supported locales: ${CountryService.supportedLocales.join(
          ', '
        )}`
      );
    }

    countries.registerLocale(require('i18n-iso-countries/langs/ar.json'));
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
    countries.registerLocale(require('i18n-iso-countries/langs/he.json'));
    countries.registerLocale(require('i18n-iso-countries/langs/ro.json'));
  }

  static getSupportedLocales(): string[] {
    return CountryService.supportedLocales;
  }

  localeIsSupported(locale: string): boolean {
    return CountryService.supportedLocales.includes(locale);
  }

  /**
   * Get the country name by code
   * @param countryCode alpha2 or alpha3 ISO country codes
   */
  getCountryName(countryCode: string): string {
    return countries.getName(countryCode, this.locale);
  }

  /**
   * Get list of (alpha2countrycode, countryname) pairs
   */
  getCountries(): Country[] {
    const codes = countries.getAlpha2Codes();
    return Object.keys(codes).map((code) => {
      const country: Country = {
        code,
        name: countries.getName(code, this.locale),
      };
      return country;
    });
  }
}
