import Cookies from 'js-cookie';
import { fetchUtils } from 'react-admin';
import { Constants } from '../constants';

/**
 * Used for react-admin lists
 */
export const getTotalFromHeaders = (headers) =>
  parseInt(headers?.get('content-range')?.split('/')?.pop() ?? '0', 10);

/**
 * Use this method to process the resource for DELETE, DELETE_MANY
 */
export const getDeleteResource = (resource: string) => {
  let newResource = resource;
  if (
    resource === Constants.RESOURCE_WEBDESPATCH_ADVICE ||
    resource === Constants.RESOURCE_WEBRECEIPT_ADVICE ||
    resource === Constants.RESOURCE_WEBINVOICE ||
    resource === Constants.RESOURCE_WEBWAYBILL ||
    resource === Constants.RESOURCE_WEBORDER ||
    resource === Constants.RESOURCE_ATTACHMENT
  ) {
    newResource = Constants.RESOURCE_WEBDOCUMENT;
  }

  return newResource;
};

/**
 * Http client used in data provider definition
 */
export const httpClient = async (url: string, options: RequestInit = {}) => {
  options.headers = new Headers(options.headers);
  options.headers.append('Accept', 'application/json');
  options.headers.append('X-Requested-With', 'XMLHttpRequest');

  // CSRF protection only on this HTTP methods:
  if (
    options.method &&
    ['DELETE', 'POST', 'PATCH', 'PUT'].includes(options.method)
  ) {
    const csrfCookie = Cookies.get('XSRF-TOKEN');
    if (csrfCookie) {
      options.headers.append('X-XSRF-TOKEN', csrfCookie);
    }
  }

  options.credentials = 'include';
  return await fetchUtils.fetchJson(url, options);
};
