import {
  Button,
  CircularProgress,
  FormControl,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { useState } from 'react';
import { Confirm, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';

const useStyles = makeStyles(
  (theme) => ({
    button: {
      top: '0',
    },
  }),
  { name: 'ActionButton' }
);

const ActionButton = (props) => {
  const {
    label,
    variant = 'standard',
    withConfirm,
    tooltip,
    onChange,
    record,
    inputProps,
    validate,
    enableIfValid,
    loading,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  let disabled: boolean = false;
  const formState = useForm().getState();
  if (enableIfValid) {
    disabled = enableIfValid.some(
      (fieldToCheck) =>
        formState.invalid && get(formState.errors, fieldToCheck.source)
    );
  }

  const handleOnClick = () => {
    if (withConfirm?.content) {
      setConfirmOpen(true);
    } else {
      if (typeof onChange === 'function') {
        onChange();
      }
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirm = () => {
    setConfirmOpen(false);
    if (typeof onChange === 'function') {
      onChange();
    }
  };

  let color: any = 'inherit';
  let variant2Apply = variant;
  // Specfic variant case mixing color scheme and variant
  if (variant === 'outlinedPrimary') {
    variant2Apply = 'outlined';
    color = 'primary';
  }

  const renderButton = () => (
    <div>
      <Button
        variant={variant2Apply}
        color={color}
        onClick={handleOnClick}
        disabled={disabled || loading}
        style={{ minWidth: `${translate(label).length - 2}em` }}
      >
        {loading ? <CircularProgress size='2em' /> : translate(label)}
      </Button>
      {withConfirm?.content && (
        <Confirm
          isOpen={confirmOpen}
          title={translate(label)}
          content={translate(withConfirm?.content)}
          onConfirm={handleConfirm}
          onClose={handleConfirmClose}
        />
      )}
    </div>
  );
  return (
    <FormControl {...rest} className={classes.button}>
      {!!tooltip ? (
        <Tooltip title={translate(tooltip)}>{renderButton()}</Tooltip>
      ) : (
        renderButton()
      )}
    </FormControl>
  );
};

ActionButton.propTypes = {};

ActionButton.defaultProps = {};

export default ActionButton;
