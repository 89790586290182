import { FlagsProvider, isDev } from '@dx-ui/dx-common';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './modules/app';
import reportWebVitals from './reportWebVitals';

if (isDev()) {
  // const mock = require('./mock/mockBackend.js');
  // mock.mockBackend();
}

const auditTrailFeatureEnabled =
  process.env.REACT_APP_AUDIT_TRAIL_ENABLED?.toLowerCase() === 'true';

const arabicLanguageEnabled =
  process.env.REACT_APP_ARABIC_ENABLED?.toLowerCase() === 'true';

const hebrewLanguageEnabled =
  process.env.REACT_APP_HEBREW_ENABLED?.toLowerCase() === 'true';

const delightedSurveyEnabled =
  process.env.REACT_APP_DELIGHTED_ENABLED?.toLowerCase() === 'true';

ReactDOM.render(
  <React.StrictMode>
    <FlagsProvider
      features={{
        auditTrail: auditTrailFeatureEnabled,
        arabicLanguage: arabicLanguageEnabled,
        hebrewLanguage: hebrewLanguageEnabled,
        delightedSurvey: delightedSurveyEnabled,
      }}
    >
      <App />
    </FlagsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
