import { Theme } from '@material-ui/core';
import { Identifier, Record, Translate } from 'ra-core';
import { ReactNode } from 'react';

/**
 * Used by Related Documents
 */
export interface ChildrenFuncParams {
  isLoading: boolean;
  defaultTitle: string;
  resource: string;
  record?: Record;
  translate: Translate;
  setSort: Function;
  currentSortMessage: any;
  currentSortRelated: any;
  currentSortAttach: any;
  currentSortLinked: any;
  version: number;
}

export interface RelatedDocumentProps {
  basePath: string;
  children: (params: ChildrenFuncParams) => ReactNode;
  hasCreate?: boolean;
  hasEdit?: boolean;
  hasShow?: boolean;
  hasList?: boolean;
  isLoading: boolean;
  resource: string;
  dataProvider: any;
  match: any;
}

export interface EnhancedProps {
  id: Identifier;
  record?: Record;
  translate: Translate;
  version: number;
}

export interface DxTheme extends Theme {
  colors: any;
  app: any;
}

export enum CustomAttributeTypes {
  STRING = 'string',
  DATE = 'date',
  NUMERIC = 'numeric',
}
