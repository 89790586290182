import { cloneElement, FC } from 'react';
import {
  CreateButton,
  Datagrid,
  Filter,
  FilterProps,
  List,
  ListProps,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  usePermissions,
} from 'react-admin';
import { isPspAdministrator } from '../configuration';
import Pagination from '../Pagination';

/**
 * The list of on-boarded company.
 *
 * Only visible for administrators.
 * A PSP administartor sees all of them and can export and delete.
 * A company administrator only sees his own company, no export, no delete.
 */
const CompanyList: FC<ListProps> = (props) => {
  const { loaded, permissions } = usePermissions();
  if (!loaded || !permissions) return null;

  return isPspAdministrator(permissions) ? (
    <List
      title='resources.companies.list.title'
      actions={<CompanyActions />}
      pagination={<Pagination />}
      bulkActionButtons={false}
      filters={<CompanyFilters />}
      exporter={false}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <View />
    </List>
  ) : (
    <List
      title='resources.companies.list.title'
      exporter={false}
      bulkActionButtons={false}
      pagination={<></>}
      actions={false}
      {...props}
    >
      <View />
    </List>
  );
};

const CompanyFilters = (props: Omit<FilterProps, 'children'>) => {
  return (
    <Filter {...props}>
      <TextInput
        label='resources.companies.list.filters.name'
        source='name'
        placeholder='INC SA'
        alwaysOn
      />
    </Filter>
  );
};

const View = (props: any) => (
  <Datagrid
    rowClick='edit'
    // Only for test purpose.
    rowStyle={(record, index) => ({
      '--data-item': `'${record.id}'`,
    })}
    {...props}
  >
    <TextField label='resources.companies.list.headers.name' source='name' />
    <TextField
      label='resources.companies.list.headers.fiscalCode'
      source='fiscalCode'
      sortBy='fiscal_code'
    />
    <TextField
      label='resources.companies.list.headers.address'
      source='address'
      sortable={false}
    />
  </Datagrid>
);

const CompanyActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton
        basePath={basePath}
        label='resources.companies.list.toptoolbar.create'
        variant='contained'
        style={{ marginLeft: '1em' }}
      />
    </TopToolbar>
  );
};

export default CompanyList;
