import {
  Constants,
  isPspAdministrator,
  useGAPageViews,
} from '@dx-ui/dx-common/src';
import EditToolbar from '@dx-ui/dx-common/src/login/MyAccount/EditToolbar';
import { makeStyles } from '@material-ui/core/styles';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  Error,
  FormDataConsumer,
  Loading,
  OnFailure,
  OnSuccess,
  ReferenceInput,
  SimpleForm,
  TextInput,
  email,
  maxLength,
  regex,
  required,
  useNotify,
  usePermissions,
  useQuery,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(
  (theme) => ({
    noActions: {
      marginTop: 0,
    },
  }),
  { name: 'UsersCreate' }
);

const UsersCreate: any = ({ setCreate, ...props }) => {
  const { permissions } = usePermissions();
  const classes = useStyles(props);
  const history = useHistory();
  const { basePath } = props;
  const recordCompanies = props?.record;

  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleOnCancel = () => {
    if (setCreate !== undefined) {
      setCreate(false);
    } else {
      history.goBack();
    }
  };

  const { data, loading, error } = useQuery({
    type: 'getList',
    resource: 'usermgmt/roles',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'DESC' },
    },
  });

  useGAPageViews();

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  const emailValidate = [required(), email()];
  const nameValidate = [required(), maxLength(50)];
  const phoneValidate = [
    maxLength(30),
    regex(
      /^[+]?[-0-9\s]*[(]?[0-9]{1,4}[)]?[-\s/0-9]*/,
      translate('dxMessages.error_messages.phone_number_invalid')
    ),
  ];
  const usersData = data.filter(
    (d) => d.id.toLowerCase() !== 'DxAdmin'.toLowerCase()
  );

  const onFailure: OnFailure = (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error?.status === 409) {
      //address already exist
      notify('dxMessages.error_messages.email_already_exists', 'error');
    } else {
      //unknown error
      notify('dxMessages.myAccount.OnError_contact_the_support', 'error', {
        error: error.message,
      });
    }
  };

  const onSuccess: OnSuccess = async (response) => {
    if (setCreate === undefined) {
      await fetch(`/management/company/${response.data.companyId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((data) => data.json())
        .then((resultData) => {
          if (resultData.v3Synchronize === false) {
            notify(
              'dxMessages.myAccount.create_user_success',
              'info',
              undefined,
              undefined,
              50000
            );
          } else {
            notify(
              'dxMessages.myAccount.create_user_success_synchronize',
              'info',
              undefined,
              undefined,
              50000
            );
          }
        });
    }
    if (recordCompanies?.v3Synchronize !== undefined) {
      if (recordCompanies?.v3Synchronize === false) {
        notify(
          'dxMessages.myAccount.create_user_success',
          'info',
          undefined,
          undefined,
          50000
        );
      } else {
        notify(
          'dxMessages.myAccount.create_user_success_synchronize',
          'info',
          undefined,
          undefined,
          50000
        );
      }
    }
    if (setCreate !== undefined) {
      setCreate(false);
    } else {
      redirect('list', basePath);
    }
  };

  return (
    <Create
      {...props}
      onFailure={onFailure}
      onSuccess={onSuccess}
      classes={{ noActions: classes.noActions }}
      title={<></>}
      resource={Constants.RESOURCE_USERS}
    >
      <SimpleForm
        variant='standard'
        toolbar={<EditToolbar onCancel={handleOnCancel} />}
        shouldUnregister
      >
        {isPspAdministrator(permissions) && (
          <BooleanInput
            source='administratorKz'
            label='DxAdmin'
            disabled={setCreate !== undefined}
          />
        )}
        <FormDataConsumer<{ administratorKz: boolean }>>
          {({ formData, ...rest }) =>
            !formData.administratorKz &&
            isPspAdministrator(permissions) && (
              <ReferenceInput
                label='resources.accounts.create.wizard.steps.companySelection.company'
                source='companyId'
                reference='management/company'
                validate={required()}
                filterToQuery={(searchText) => ({
                  accountname: [searchText],
                })}
                sort={{ field: 'accountname', order: 'ASC' }}
                defaultValue={parseInt(props?.id)}
                disabled={props.id}
              >
                <AutocompleteInput optionText='accountname' />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer<{ administratorKz: boolean }>>
          {({ formData, ...rest }) =>
            !props.id &&
            isPspAdministrator(permissions) && (
              <TextInput
                label='dxMessages.companyManagement.users.userId'
                source='id'
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <TextInput
          source='email'
          validate={emailValidate}
          label='dxMessages.myAccount.email'
        />
        <TextInput
          source='firstName'
          validate={nameValidate}
          label='dxMessages.myAccount.firstName'
        />
        <TextInput
          source='lastName'
          validate={nameValidate}
          label='dxMessages.myAccount.lastName'
        />
        <TextInput
          source='phoneNumber'
          validate={phoneValidate}
          label='dxMessages.myAccount.phoneNumber'
        />
        <AutocompleteArrayInput
          style={{ width: '256px' }}
          label='dxMessages.myAccount.roles'
          choices={
            props.resource === Constants.RESOURCE_ACCOUNT ? usersData : data
          }
          source='enabledRoles'
          helperText='dxMessages.companyManagement.users.help'
        />
      </SimpleForm>
    </Create>
  );
};

export default UsersCreate;
