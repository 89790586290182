// red
const red = {
  20: '#FBDDDD',
  50: '#F5ABAB',
  100: '#EB5757',
};

const orange = {
  50: '#ffd7d5',
  100: '#f2b8b5',
  200: '#e69996',
  300: '#d97b77',
  400: '#cd5c58',
  500: '#c13d38',
  A100: '#ff6863',
};

const lightOrange = orange[50];
const darkOrange = orange[500];
const brightOrange = orange.A100;

// gray
const grey = {
  100: '#f5f5f8',
  200: '#eaeaf1',
  300: '#e0e0ea',
  400: '#d5d5e3',
  500: '#cbcbdc',
  600: '#b8b8cd',
  700: '#9797ab',
  900: '#565666',
  1000: '#363643',
  A100: '#151521',
};
const lightGrey = grey[200];
const darkGrey = grey.A100;

// purple
const purple = {
  50: '#440094',
  100: '#3b0082',
  200: '#320070',
  300: '#2a005f',
  400: '#21004D',
  500: '#18003b',
};
const lightPurple = purple[50];
const darkPurple = purple[500];

// blue
const blue = {
  50: '#b7cee6',
  100: '#93b5d4',
  200: '#6f9dc2',
  300: '#4b84af',
  400: '#276c9d',
  500: '#03538b',
};
const lightBlue = blue[50];
const darkBlue = blue[500];

// black
const black = darkGrey;
const black05 = 'rgba(0, 0, 0, 0.05)';
const black30 = 'rgba(0, 0, 0, 0.3)';
const black50 = 'rgba(0, 0, 0, 0.5)';
const black65 = 'rgba(0, 0, 0, 0.65)';
const black80 = 'rgba(0, 0, 0, 0.8)';

const accentColor2 = '#FFF480';
const accentColor3 = '#FFEA00';
const accentColor4 = '#FFC500';

const functionalRed = red[100];
const functionalYellow = '#FFEA00';
const functionalGreen = '#27AE60';
const functionalBlue = '#038ED3';
const functionalGray = '#595959';
const functionalOrange = '#F2994A';

/* eslint-disable no-useless-computed-key */
const primary = {
  ['50']: '#e0eff3',
  ['100']: '#b3d7e0',
  ['200']: '#80bccc',
  ['300']: '#4da1b8',
  ['400']: purple[200],
  ['500']: '#007899',
  ['600']: '#00587a',
  ['700']: '#00385c',
  ['800']: '#00294a',
  ['900']: '#001a37',
  A100: '#ff6863',
  A200: '#67c4ff',
  A400: '#34b1ff',
  A700: '#1aa7ff',
  contrastDefaultColor: 'light',
};

const secondary = {
  ['50']: '#fffae0',
  ['100']: '#fff3b3',
  ['200']: '#ffeb80',
  ['300']: '#ffe34d',
  ['400']: '#ffdd26',
  ['500']: '#ffd700',
  ['600']: '#ffd300',
  ['700']: '#ffcd00',
  ['800']: '#ffc700',
  ['900']: '#ffbe00',
  A100: '#ffffff',
  A200: '#fffbf2',
  A400: '#ffecbf',
  A700: '#ffe5a6',
  contrastDefaultColor: 'dark',
};
/* eslint-enable no-useless-computed-key */

const mainColor4 = primary['400'];
const mainColor5 = purple[100];
const mainColor6 = primary['600'];

const white = '#ffffff';
const white30 = 'rgba(255, 255, 255, 0.3)';

export const colors = {
  accentColor2,
  accentColor3,
  accentColor4,

  functionalRed,
  functionalYellow,
  functionalGreen,
  functionalBlue,
  functionalGray,
  functionalOrange,

  black,
  black05,
  black30,
  black50,
  black65,
  black80,

  primary,
  secondary,

  mainColor4,
  mainColor5,
  mainColor6,

  white,
  white30,
  red,

  purple,
  lightPurple,
  darkPurple,

  blue,
  lightBlue,
  darkBlue,

  grey,
  lightGrey,
  darkGrey,

  orange,
  lightOrange,
  darkOrange,
  brightOrange,
};
