import { Drawer, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import moment from 'moment';
import * as React from 'react';
import { FC } from 'react';
import { FieldProps, Record, useGetList, useTranslate } from 'react-admin';
import { Account } from '../../types';

const useStyles = makeStyles((theme) => ({
  successDot: {
    backgroundColor: theme.palette.success.main,
  },
  errorDot: {
    backgroundColor: theme.palette.error.main,
  },
  warningDot: {
    backgroundColor: theme.palette.warning.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
}));

/**
 * Display a timeline of the last 10 logins (if any) on the account.
 */
const LoginEventAside: FC<FieldProps<Account>> = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [loginEventsOpen, setLoginEventsOpen] = React.useState(false);
  const {
    data = {},
    loading,
    error,
  } = useGetList<LoginEvent>(
    'loginevents',
    { page: 1, perPage: 10 },
    { field: 'created', order: 'DESC' },
    { personId: record?.person?.id, accountId: record?.id }
  );

  if (loading) {
    return null;
  }
  if (error) {
    // console.warn(error);
    return null;
  }

  // @ts-ignore
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setLoginEventsOpen(open);
  };

  const events: Array<LoginEvent> = Object.values(data).sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  return (
    <>
      <div>
        <Typography variant='caption'>
          {translate('resources.accounts.edit.tabs.user.lastLogins')}
        </Typography>
        <IconButton size='small' color='primary' onClick={toggleDrawer(true)}>
          <MenuOpenIcon />
        </IconButton>
      </div>
      <Drawer
        anchor='right'
        open={loginEventsOpen}
        onClose={toggleDrawer(false)}
      >
        <div style={{ margin: '1em', width: '30em' }}>
          <Typography variant='button'>
            {translate(
              'resources.accounts.edit.tabs.user.sections.lastLogins.title'
            )}
          </Typography>
          <br />
          <Typography variant='caption'>
            {translate(
              'resources.accounts.edit.tabs.user.sections.lastLogins.subtitle'
            )}
          </Typography>
          <div style={{ marginTop: '1em' }}>
            {events.length === 0 && (
              <Typography>
                {translate(
                  'resources.accounts.edit.tabs.user.sections.lastLogins.noLogin'
                )}
              </Typography>
            )}
            {events.length > 0 && (
              <Timeline align='alternate'>
                {events.map((event) => (
                  <TimelineItem key={event.id}>
                    <TimelineSeparator>
                      <TimelineDot
                        className={
                          event.successful
                            ? classes.successDot
                            : event?.failureReason !== 'BAD_CREDENTIALS'
                            ? classes.errorDot
                            : classes.warningDot
                        }
                      >
                        {event.type === 'RESET_PWD' && <SettingsIcon />}
                        {event.type === 'UNLOCK' && <LockOpenIcon />}
                        {event.type === 'SIGNOUT' && <ExitToAppIcon />}
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Grid container direction='column'>
                        <Grid item>
                          <Typography variant='caption' noWrap>
                            {moment(event.timestamp).calendar()}
                          </Typography>
                        </Grid>
                        {event.type === 'SIGNOUT' && (
                          <Typography noWrap style={{ fontSize: 'smaller' }}>
                            {translate(
                              'resources.accounts.edit.tabs.user.sections.lastLogins.signout'
                            )}
                          </Typography>
                        )}{' '}
                        {event.type === 'RESET_PWD' && (
                          <Typography noWrap>
                            {translate(
                              'resources.accounts.edit.tabs.user.sections.lastLogins.passwordReset'
                            )}
                          </Typography>
                        )}
                        {event.type === 'UNLOCK' && (
                          <Typography noWrap>
                            {translate(
                              'resources.accounts.edit.tabs.user.sections.lastLogins.unlocked'
                            )}
                          </Typography>
                        )}
                        {event.identityReference.providerId !==
                          'Doc Process' && (
                          <Grid item>
                            <Typography noWrap style={{ fontSize: 'smaller' }}>
                              {translate(
                                'resources.accounts.edit.tabs.user.sections.lastLogins.loggedWith'
                              )}{' '}
                              {event.identityReference.providerId}
                            </Typography>
                          </Grid>
                        )}
                        {event.failureReason && (
                          <Grid item>
                            {event.failureReason === 'BAD_CREDENTIALS' && (
                              <Typography noWrap className={classes.warning}>
                                {translate(
                                  'resources.accounts.edit.tabs.user.sections.lastLogins.badCredentials'
                                )}
                              </Typography>
                            )}
                            {event.failureReason === 'ACCOUNT_LOCKED' && (
                              <Typography
                                noWrap
                                style={{
                                  fontWeight: 'bolder',
                                }}
                                color='error'
                              >
                                {translate(
                                  'resources.accounts.edit.tabs.user.sections.lastLogins.locked'
                                )}
                              </Typography>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

// A wrapper to ensure record contains an account and person ID
// before calling <LoginEventAside/>.
const LoginEventAsideWrapper: FC<FieldProps<Account>> = (props) => {
  const { record } = props;
  if (!record?.id || !record?.person?.id) return null;

  return <LoginEventAside {...props} />;
};
interface LoginEvent extends Record {
  type: 'SIGNIN' | 'SIGNOUT' | 'UNLOCK' | 'RESET_PWD';
  timestamp: string;
  identityReference: {
    providerId: string;
    id: string;
  };
  personId?: string;
  accountId?: string;
  companyId?: string;
  successful: boolean;
  failureReason?:
    | 'BAD_CREDENTIALS'
    | 'IDENTITY_NOT_FOUND'
    | 'ACCOUNT_DISABLED'
    | 'ACCOUNT_LOCKED'
    | 'ACCOUNT_EXPIRED'
    | 'CREDENTIALS_EXPIRED'
    | 'ACCOUNT_NOT_FOUND'
    | 'UNKNOWN';
}

export default LoginEventAsideWrapper;
