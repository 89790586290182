import {
  cellStyle,
  DocumentTypeCode,
  InvoiceTypeCode,
  Metadata,
  MultiSelectInput,
  OrderSubType,
  ReceiptAdviceSubType,
  resolveDateRange,
  SubTypeLabels,
  widthPresets,
} from '@dx-ui/dx-common';
import useSetupUsage, {
  SetupUsage,
} from '@dx-ui/dx-common/src/utils/useSetupUsage';
import {
  createCarrefourInvoiceTypeDetailsFilters,
  createColumnsCarrefourInvoiceTypeDetails,
  createColumnsContract,
  createColumnsDeliveryLocation,
  createColumnsDespatchAdvice,
  createColumnsExtendedInvoiceDetails,
  createColumnsFeedbackMessage,
  createColumnsOrder,
  createColumnsProcessDetails,
  createColumnsReceiptAdvice,
  createColumnsRegulatorDetails,
  createColumnsTotals,
  createCommonColumns,
  createCommonFilters,
  createContractFilters,
  createDeliveryLocationFilters,
  createDespatchAdviceFilters,
  createExtendedInvoiceDetailsFilters,
  createFeedbackMessageFilters,
  createOrderFilters,
  createProcessDetailsFilters,
  createReceiptAdviceFilters,
  createRegulatorFilters,
  createTotalsFilters,
} from '../../aspects';
import { DocumentSubTypeField } from '../../aspects/CommonFields';
import { CommonList, DisplayContextualButtons } from '../../shared';

export const dxDocumentColumns = [
  {
    id: Metadata.documentSubTypeCode,
    label: 'dxMessages.headers.documentSubTypeCode',
    sortable: false,
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <DocumentSubTypeField {...props} />,
  },
];

// specific for DxDocument
const columns: any[] = [
  ...createCommonColumns(),
  ...createColumnsContract(),
  ...dxDocumentColumns,
  ...createColumnsDeliveryLocation(),
  ...createColumnsTotals(),
  ...createColumnsOrder(),
  ...createColumnsProcessDetails(),
  ...createColumnsDespatchAdvice(),
  ...createColumnsReceiptAdvice(),
  ...createColumnsCarrefourInvoiceTypeDetails(),
  ...createColumnsFeedbackMessage(),
  ...createColumnsExtendedInvoiceDetails(),
  ...createColumnsRegulatorDetails(),
];

// Change edm:documentTypeCode column configuration
// in order to always have this col for all documents.
columns
  .filter((col) => col.id === Metadata.documentTypeCode)
  .forEach((col) => {
    col.alwaysOn = true;
  });

/**
 * Declare the additional filters vs commons
 */
const dxDocumentFilters: any[] = [
  {
    id: Metadata.documentTypeCode,
    filter: ({ ...props }: any) => (
      <MultiSelectInput
        label='dxMessages.headers.documentTypeCode'
        source={Metadata.documentTypeCode}
        width={widthPresets.medium}
        sortLabels={true}
        {...props}
        options={[
          {
            id: DocumentTypeCode.ORDERS,
            label: 'dxMessages.documentTypeCode.ORDERS',
          },
          {
            id: DocumentTypeCode.DELFOR,
            label: 'dxMessages.documentTypeCode.DELFOR',
          },
          {
            id: DocumentTypeCode.DESADV,
            label: 'dxMessages.documentTypeCode.DESADV',
          },
          {
            id: DocumentTypeCode.RECADV,
            label: 'dxMessages.documentTypeCode.RECADV',
          },
          {
            id: DocumentTypeCode.INVOIC,
            label: 'dxMessages.documentTypeCode.INVOIC',
          },
          {
            id: DocumentTypeCode.WAYBIL,
            label: 'dxMessages.documentTypeCode.WAYBIL',
          },
        ]}
      />
    ),
    alwaysOn: true,
  },
  {
    id: Metadata.documentSubTypeCode,
    filter: (props) => (
      <MultiSelectInput
        label='dxMessages.headers.documentSubTypeCode'
        source={Metadata.documentSubTypeCode}
        width={widthPresets.large}
        sortLabels={true}
        {...props}
        options={[
          {
            id: OrderSubType.InitialOrder,
            label: SubTypeLabels[OrderSubType.InitialOrder],
          },
          {
            id: OrderSubType.UpdatedOrder,
            label: SubTypeLabels[OrderSubType.UpdatedOrder],
          },
          {
            id: OrderSubType.NotModifiedUpdatedOrder,
            label: SubTypeLabels[OrderSubType.NotModifiedUpdatedOrder],
          },
          {
            id: OrderSubType.FinalOrder,
            label: SubTypeLabels[OrderSubType.FinalOrder],
          },
          {
            id: OrderSubType.CrossDockingOrder,
            label: SubTypeLabels[OrderSubType.CrossDockingOrder],
          },
          {
            id: OrderSubType.GoodsReturnOrder,
            label: SubTypeLabels[OrderSubType.GoodsReturnOrder],
          },
          {
            id: ReceiptAdviceSubType.GoodsReceiptAdvice,
            label: SubTypeLabels[ReceiptAdviceSubType.GoodsReceiptAdvice],
          },
          {
            id: ReceiptAdviceSubType.GoodsReturnAdvice,
            label: SubTypeLabels[ReceiptAdviceSubType.GoodsReturnAdvice],
          },
          {
            id: InvoiceTypeCode.COMMERCIAL,
            label: SubTypeLabels[InvoiceTypeCode.COMMERCIAL],
          },
          {
            id: InvoiceTypeCode.CANCELLATION,
            label: SubTypeLabels[InvoiceTypeCode.CANCELLATION],
          },
          {
            id: InvoiceTypeCode.CORRECTION,
            label: SubTypeLabels[InvoiceTypeCode.CORRECTION],
          },
          {
            id: InvoiceTypeCode.SELF_BILLING,
            label: SubTypeLabels[InvoiceTypeCode.SELF_BILLING],
          },
        ]}
      />
    ),
  },
];

/**
 * Specific filters
 */
const additionalFilters: any[] = [
  ...createCommonFilters(),
  ...dxDocumentFilters,
  ...createContractFilters(),
  ...createDeliveryLocationFilters(),
  ...createTotalsFilters(),
  ...createOrderFilters(),
  ...createProcessDetailsFilters(),
  ...createDespatchAdviceFilters(),
  ...createReceiptAdviceFilters(),
  ...createCarrefourInvoiceTypeDetailsFilters(),
  ...createFeedbackMessageFilters(),
  ...createExtendedInvoiceDetailsFilters(),
  ...createRegulatorFilters(),
];

/**
 * @param {*} props
 */
const DxDocumentList = (props) => {
  const setupUsage: SetupUsage | undefined = useSetupUsage();
  const isDemoSetup: boolean = setupUsage === SetupUsage.DEMO;
  return (
    <CommonList
      title='dxMessages.dxDocuments.title'
      {...props}
      columns={columns}
      additionalFilters={additionalFilters}
      contextualToolbar={<DisplayContextualButtons />}
      filterDefaultValues={
        isDemoSetup
          ? {}
          : {
              [Metadata.created]: resolveDateRange({
                predefinedRange: 'last-7-days',
              }),
            }
      }
    />
  );
};

export default DxDocumentList;
