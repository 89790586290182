import { BooleanInput } from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: 16,
    },
  }),
  { name: 'BooleanInputField' }
);
/**
 * Renders a Boolean input
 */
const BooleanInputField = (props: FieldComponentProps) => {
  const classes = useStyles();
  const sanitizeProps: any = ({
    loading,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;
  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <BooleanInput {...sanitizeProps(fieldProps)} className={classes.root} />
      )}
    </FieldWrapper>
  );
};

export default BooleanInputField;
