import compose from 'lodash/flowRight';
import React, { useEffect } from 'react';
import {
  showNotification,
  useAuthProvider,
  withDataProvider,
} from 'react-admin';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const ChangeUserEmail = (props) => {
  const { match, showNotification } = props;
  const authProvider = useAuthProvider();
  useEffect(() => {
    const callChangeEmailApi = async () => {
      try {
        const response: any = await authProvider.changeEmail({
          token: match.params.token,
        });

        if (response.status === 410) {
          showNotification('dxMessages.auth.token_too_many_attempts', 'error');
        } else if (response.status < 200 || response.status >= 300) {
          throw new Error(
            `Error during password change. Http status returned: ${response.status}.`
          );
        } else {
          showNotification('dxMessages.myAccount.email_update_success', 'info');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        showNotification('dxMessages.myAccount.email_update_error', 'error');
      }
    };
    callChangeEmailApi();
  }, [match, showNotification, authProvider]);

  return <Redirect to='/' />;
};

const enhance = compose(
  withDataProvider,
  connect(undefined, { showNotification })
);

export default enhance(ChangeUserEmail);
