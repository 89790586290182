import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Select, TextField } from 'final-form-material-ui';
import moment from 'moment';
import queryString from 'query-string';
import React, { useState } from 'react';
import {
  Loading,
  LoginComponent,
  useLogin,
  useNotify,
  useQuery,
  useTranslate,
} from 'react-admin';
import { Field, Form } from 'react-final-form';

/**
 * A standalone login page, to serve as authentication gate to the backend.
 *
 * Expects the user to enter an email and a password to login with
 * a Doc Process built-in identity.
 * In case the user owns more than one account (belonging to different companies),
 * he will be asked for choosing the company he wants to login into.
 *
 * The page may also provide social logins through "SIGN UP with ..." buttons.
 *
 * Redirects to the root page (/) upon success, otherwise displays an
 * authentication error notification.
 *
 * @param title the name of the app, sent in the sigin request.
 */
// @ts-ignore
const LoginForm: LoginComponent = ({ appName, theme }) => {
  const translate = useTranslate();
  const login = useLogin();
  const notify = useNotify();
  const classes = useStyles();

  const [password, setPassword] = useState({
    password: '',
    showPassword: false,
  });
  // In case the user owns more than one account, the companies the accounts belong to.
  // (set after submitting the first login).
  const [companies, setCompanies] = useState<Company[]>();

  // Get the identity providers supported by the platform.
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: providers = [],
    loading,
    error,
  } = useQuery({
    type: 'getList',
    resource: 'configuration-identityProviders',
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'ASC' },
    },
  });

  if (error) {
    // Just output the error and don't show any OpenID Connect provider.
    // eslint-disable-next-line no-console
    console.log(error);
  }
  if (loading) {
    return <Loading />;
  }

  // If we come back from a social login, rejected because not registered as a valid identity
  // associated with an account, the URL is: .../index.html?identityProvider=<id>&unregistered=true
  // (a bit hacky but no other way to pass this info up to here)
  const parsed = queryString.parse(window.location.search);
  const unregistered = !!parsed.unregistered;
  if (unregistered) {
    notify('common.unregisteredLogin', 'error', {
      providerId: parsed.identityProvider,
    });

    // Remove the query params from the browser navigation bar.
    window.history.pushState(null, '', window.location.href.split('?')[0]);
  }

  // Keep only the OpenID connect identity provider (Google, ...) and display a "SIGN UP" button
  // for each.
  // Uncomment the day when we start using social logins.
  // const openIdConnectProviders = providers.filter(
  //   (provider: any) => provider.type === 'OPEN_ID_CONNECT'
  // );
  const openIdConnectProviders = [];

  const submit = (values: any) => {
    login(
      {
        login: values.email.trim(),
        password: encodeURIComponent(values.password), // Encode the pwd asap to avoid any console clear display.
        appName,
        companyId: values.company,
      },
      '/home' // redirect to the LandingPage cf userBaseRoutes.tsx
    ).catch((error: any) => {
      switch (error.status) {
        case 300: // MULTIPLE_CHOICES.
          // User belongs to more than one company. Company candidates in error.body (JSON { id: ...,  name: ... })
          setCompanies(error.body);
          notify('resources.login.notifications.selectCompany');
          break;
        case 403:
          const msg =
            error?.body?.key === 'locked'
              ? translate('resources.login.errors.locked', {
                  count: error?.body?.accountLockingStrategy.maxFailureCount,
                  date: moment()
                    .add(
                      moment.duration(
                        error?.body?.accountLockingStrategy.lockDuration,
                        'seconds'
                      )
                    )
                    .calendar(),
                })
              : error.message;
          notify(msg, 'error');
          break;
        case 500:
          notify('resources.login.errors.unexpected');
          break;
        default:
          notify(
            translate(`resources.login.errors.${error?.body?.key}`, {
              _: error.message,
            }),
            'error'
          );
      }
    });
  };

  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container direction='column' spacing={1} alignItems='stretch'>
            <Grid item>
              <Field
                name='email'
                type='text'
                component={TextField}
                label={translate('ra.auth.username')}
                //                label={translate('resources.login.email')}
                margin='normal'
                placeholder='robert.abitbol@thecomp.com'
                fullWidth
                autoComplete='username'
                required
              />
            </Grid>
            <Grid item>
              <Field
                name='password'
                type={password.showPassword ? 'text' : 'password'}
                component={TextField}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword({
                    ...password,
                    password: event.target.value,
                  })
                }
                // label={translate('resources.login.password')}
                label={translate('ra.auth.password')}
                margin='normal'
                fullWidth
                required
                autoComplete='current-password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() =>
                          setPassword({
                            ...password,
                            showPassword: !password.showPassword,
                          })
                        }
                        onMouseDown={(event: React.MouseEvent<HTMLElement>) =>
                          event.preventDefault()
                        }
                      >
                        {password.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {companies && (
              <Grid item>
                <FormControl fullWidth required>
                  <Field
                    name='company'
                    label={translate('resources.login.company') + '*'}
                    component={Select as any}
                    // style={{ width: '14em' }}
                    required
                    autoFocus
                  >
                    {companies.map((company) => (
                      <MenuItem value={company.id} key={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <FormHelperText style={{ width: '20em' }}>
                    {translate('dxMessages.auth.companySelectionAtLogin')}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
            <Grid item container justify='flex-end'>
              <Grid item>
                <Button
                  variant='contained'
                  type='submit'
                  style={{ marginTop: '1em' }}
                  color='primary'
                >
                  {translate('ra.auth.sign_in')}
                </Button>
              </Grid>
            </Grid>
            {openIdConnectProviders.length > 0 && (
              <>
                <Grid item>
                  <div className={classes.selectOr}>
                    <hr className={classes.hrOr} />
                    <Typography className={classes.spanOr} color='primary'>
                      {translate('resources.login.or')}
                    </Typography>
                  </div>
                </Grid>
                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    {openIdConnectProviders.map((provider: any) => (
                      <Grid item key={provider.id}>
                        <Button
                          id={provider.id}
                          variant='outlined'
                          color='primary'
                          href={`/oauth2/authorization/${provider.id}`}
                          fullWidth
                        >
                          {translate('resources.login.loginWith', {
                            providerId: provider.id,
                          })}
                        </Button>
                      </Grid>
                    ))}
                    <Grid item>
                      <Typography variant='caption'>
                        {translate(
                          'resources.login.onlyWithRegisteredAlternateLogins'
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      )}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  home: {
    backgroundImage: "url('ripple-zigzag-10.png')",
    width: '100%',
    height: '100vh',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'none',
  },
  paper: {
    padding: '2em',
    width: '15em',
  },
  logo: {
    height: '14vh',
  },
  selectOr: {
    position: 'relative',
    color: theme.palette.primary.main,
    marginTop: '10px',
    marginBottom: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  spanOr: {
    display: 'block',
    position: 'absolute',
    left: '50%',
    top: '-2px',
    marginLeft: '-25px',
    backgroundColor: '#fff',
    width: '50px',
    textAlign: 'center',
  },
  hrOr: {
    height: '1px',
    backgroundColor: theme.palette.primary.main,
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
}));

interface Company {
  id: string;
  name: string;
}

export default LoginForm;
