import { UserRoles } from '.';
import { Constants } from '../constants';

export const checkLocalStorage = (): boolean => {
  if (typeof localStorage === 'undefined') {
    return false;
  }

  try {
    const key = 'localstorage-test-key-unique-0012212';
    localStorage.setItem(key, 'test-value'); //random array
    localStorage.removeItem(key);
    return true;
  } catch (ex) {
    // Not really an issue for us ( ex: No more space in local storage)
    // but can be reported anyway as a warning.
    // eslint-disable-next-line no-console
    console.warn('Unable to access local storage.');
    return false;
  }
};

/**
 * Gets the user name for the logged user from local storage
 * @returns the user name
 */
export const getUser = () => {
  checkLocalStorage();
  return localStorage.getItem('user') ?? undefined;
};

/**
 * Gets the list of permissions for currently logged user from local storage
 * @returns list of permissions
 */

export const getPermissions = () => {
  // check local storage
  checkLocalStorage();

  // Retrieves the current user
  const user = getUser();

  // Retrieve permissions
  return (
    JSON.parse(
      localStorage.getItem(user + '.' + Constants.PERMISSIONS) || '{}'
    ) || {}
  );
};

/**
 * @param {object} permissions GET /authorization body response
 * @param {string} product product name such as dxarchive, dxpurchase ...
 * @param {string} role see "../shared/UserRoles"
 */
export const checkPermissions = (
  permissions: any,
  product: string,
  role: string
) => {
  if (permissions && permissions[product.toUpperCase()] !== undefined) {
    return (
      permissions[product.toUpperCase()].filter(
        (permission) => permission === role
      ).length === 1
    );
  } else {
    return false;
  }
};

/**
 * Returns the last used application by the current user or null.
 */
export const getLastUsedApplication = () => {
  let dxApplication;
  if (typeof localStorage !== 'undefined') {
    try {
      // Retrieves the current user
      const user = localStorage.getItem('user');
      // Get its current application
      dxApplication = localStorage.getItem(
        user + '.' + Constants.DXAPPLICATION
      );
    } catch (ex) {
      // Not really an issue for us ( ex: No more space in local storage)
      // but can be reported anyway as a warning.
      // eslint-disable-next-line no-console
      console.warn('unable to get the current App from local storage.');
    }
  }
  return dxApplication;
};

/**
 * Gets the application name based on permissions
 */
export const getApplicationFromPermissions = (permissions) => {
  let dxApplication;
  // Takes dxpurchase if allowed and if not, takes the first allowed application
  if (
    permissions[UserRoles.DXPURCHASE_PRODUCT] &&
    permissions[UserRoles.DXPURCHASE_ACCESS]
  ) {
    dxApplication = Constants.DXPURCHASE_APP;
  } else if (
    permissions[UserRoles.DXARCHIVE_PRODUCT] &&
    permissions[UserRoles.DXARCHIVE_ACCESS]
  ) {
    dxApplication = Constants.DXARCHIVE_APP;
  } else if (
    permissions[UserRoles.DXCATALOG_PRODUCT] &&
    permissions[UserRoles.DXCATALOG_ACCESS]
  ) {
    dxApplication = Constants.DXCATALOG_APP;
  } else if (
    permissions[UserRoles.DXUPLOAD_PRODUCT] &&
    permissions[UserRoles.DXUPLOAD_ACCESS]
  ) {
    dxApplication = Constants.DXUPLOAD_APP;
  } else {
    dxApplication = Constants.DXPURCHASE_APP;
  }
  return dxApplication;
};
