import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FC } from 'react';
import {
  FieldProps,
  TextInput,
  email,
  required,
  useTranslate,
} from 'react-admin';
import { Regulator } from '../types';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const RegulatorData: FC<FieldProps<Regulator>> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const requiredEmail = [required(), email()];

  return (
    <Paper className={classes.paper} style={{ marginTop: '1em' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={0} direction='column'>
            <Typography variant='h5'>
              {translate('resources.regulatorAccess.subtitle')}
            </Typography>
            <InfoMessage />
            <Grid item>
              <TextInput
                label='resources.regulatorAccess.approverEmail'
                source='approverEmail'
                fullWidth
                type='email'
                validate={requiredEmail}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const InfoMessage = () => {
  const translate = useTranslate();
  return (
    <Alert severity='warning' style={{ margin: '1em' }}>
      <AlertTitle>
        {translate('resources.regulatorAccess.subtitleHelper', {
          _: 'The email of the approver who can grant the access to the regulator APIs',
        })}
      </AlertTitle>
      {translate('resources.regulatorAccess.infoMessage', {
        _:
          'Starting on 1 July 2022, Romanian legislation mandates that the following types of invoices must be sent to and certified by the government’s fiscal authority system: ' +
          '(1) Invoices that include "high fiscal risk" goods, as defined by the fiscal authority; ' +
          '(2) Invoices for Business-to-Government (B2G) transactions (public procurement). ' +
          'From 2023, this mandate will expand to all Business-to-Business (B2B) invoicing. ' +
          'In order to let the DocProcess platform interact with the fiscal authority on your behalf strictly for this purpose, you need to provide the email of the Approver who will authorize DocProcess to access your company’s Private Virtual Space (SPV).',
      })}
    </Alert>
  );
};
