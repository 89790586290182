import { Constants, WebFormMode } from '@dx-ui/dx-common';
import { useEffect, useMemo, useState } from 'react';
import {
  Loading,
  linkToRecord,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useRouteMatch } from 'react-router-dom';
import {
  DocumentServiceFactory,
  OrderService,
  OrderServiceCreator,
} from '../../services';

const OrderCreate = (props) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();

  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  const match: any = useRouteMatch();
  const { recipientId } = match.params;

  const orderService: OrderService = useMemo(
    () =>
      DocumentServiceFactory.create(
        OrderServiceCreator,
        dataProvider
      ) as OrderService,
    [dataProvider]
  );

  useEffect(() => {
    const createNewOrder = async () => {
      setLoading(true);
      try {
        const nodeId: string | undefined = await orderService.createNew(
          recipientId
        );

        setData(nodeId);
        setLoading(false);
        redirect(
          `/${linkToRecord(Constants.RESOURCE_WEBORDER, nodeId)}`,
          undefined, // basePath
          undefined, // id
          undefined, // data
          { redirectMode: WebFormMode.SCRATCH_ORDER } // state
        );
      } catch (e) {
        notify(
          'dxMessages.dashboard.OnErrorContactSupport',
          'error',
          { error: e },
          true
        );
        setLoading(false);
      }
    };
    if (!data) {
      createNewOrder();
    }
  }, [data, notify, orderService, recipientId, redirect]);
  if (loading) {
    return <Loading />;
  } else return null;
};
export default OrderCreate;
