import { cellStyle, widthPresets } from '@dx-ui/dx-common';
import {
  GenericDateField,
  GenericV2InvoiceField,
} from '@dx-ui/dx-common/src/components/fields/GenericFields';
import {
  AutocompleteInput,
  DateInput,
  DateTimeInput,
  ReferenceInput,
  TextInput,
} from 'react-admin';
import { V2TextField } from './V2CommonFields';

export const ContractIdField = (props) => <GenericV2InvoiceField {...props} />;
export const IssueDateField = (props) => <GenericDateField {...props} />;

export const createColumnsV2Desadvs = () => [
  {
    id: 'despatchAdviceId',
    label:
      'dxMessages.companyManagement.commonDocumentColumns.despatchAdviceId',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    alwaysOn: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    sticky: true,
    renderCell: (props) => <ContractIdField {...props} />,
  },
  {
    id: 'sourceName',
    label: 'dxMessages.companyManagement.commonDocumentColumns.supplierName',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    alwaysOn: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <V2TextField {...props} />,
  },
  {
    id: 'destinationName',
    label: 'dxMessages.companyManagement.commonDocumentColumns.customerName',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    alwaysOn: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <V2TextField {...props} />,
  },
  {
    id: 'issueDate',
    label: 'dxMessages.companyManagement.commonDocumentColumns.issuingDate',
    sortable: true,
    alwaysOn: true,
    displayInRelatedDocuments: true,
    displayInCluster: true,
    displayInLinkedDocuments: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <IssueDateField {...props} />,
  },
  {
    id: 'ingestDate',
    label: 'dxMessages.companyManagement.commonDocumentColumns.ingestDate',
    sortable: true,
    hiddenByDefault: true,
    displayInRelatedDocuments: true,
    displayInCluster: true,
    displayInLinkedDocuments: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <IssueDateField {...props} />,
  },
];

export const createV2DesadvFilters = () => [
  {
    id: 'despatchAdviceId',
    filter: (props) => (
      <TextInput
        source='despatchAdviceId'
        label='dxMessages.companyManagement.commonDocumentColumns.despatchAdviceId'
        resettable
        {...props}
      />
    ),
    alwaysOn: true,
  },
  {
    id: 'sourceName',
    filter: (props) => (
      <ReferenceInput
        label='dxMessages.companyManagement.commonDocumentColumns.supplierName'
        source='sourceName'
        reference='management/company'
        filterToQuery={(searchText) => ({
          accountname: [searchText],
        })}
        sort={{ field: 'accountname', order: 'ASC' }}
        allowEmpty={true}
        alwaysOn
      >
        <AutocompleteInput
          optionText='accountname'
          optionValue='id'
          resettable={true}
        />
      </ReferenceInput>
    ),
    alwaysOn: true,
  },
  {
    id: 'destinationName',
    filter: (props) => (
      <ReferenceInput
        label='dxMessages.companyManagement.commonDocumentColumns.customerName'
        source='destinationName'
        reference='management/company'
        filterToQuery={(searchText) => ({
          accountname: [searchText],
        })}
        sort={{ field: 'accountname', order: 'ASC' }}
        allowEmpty={true}
        alwaysOn
      >
        <AutocompleteInput
          optionText='accountname'
          optionValue='id'
          resettable={true}
        />
      </ReferenceInput>
    ),
    alwaysOn: true,
  },
  {
    id: 'ingestDateAfter',
    filter: (props) => (
      <DateTimeInput
        source='ingestDateAfter'
        label='dxMessages.companyManagement.commonDocumentColumns.ingestDateAfter'
        {...props}
      />
    ),
    alwaysOn: true,
  },
  {
    id: 'ingestDateBefore',
    filter: (props) => (
      <DateTimeInput
        source='ingestDateBefore'
        label='dxMessages.companyManagement.commonDocumentColumns.ingestDateBefore'
        {...props}
      />
    ),
    alwaysOn: true,
  },
  {
    id: 'issueDateStart',
    filter: (props) => (
      <DateInput
        source='issueDateStart'
        label='dxMessages.companyManagement.commonDocumentColumns.issuingDateStart'
        {...props}
      />
    ),
    alwaysOn: true,
  },
  {
    id: 'issueDateEnd',
    filter: (props) => (
      <DateInput
        source='issueDateEnd'
        label='dxMessages.companyManagement.commonDocumentColumns.issuingDateEnd'
        {...props}
      />
    ),
    alwaysOn: true,
  },
];
