import { Typography } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { FieldProps } from 'ra-ui-materialui';
import * as React from 'react';
import { UpdatedAccountLifeCycleEvent } from '../../types';

/**
 * The panel displaying the differences between an updated account and its
 * previous state.
 */
const AccountDiffPanel: React.FC<FieldProps<UpdatedAccountLifeCycleEvent>> = ({
  record,
}) => {
  const translate = useTranslate();
  if (!record) return null;

  const before = record.account;
  const after = record.updated;

  // Compute changes in the identities.
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const addedIdentities = after.person.identities.filter(
    (a) =>
      !before.person.identities.some(
        (b) => b.id === a.id && b.provider.id === a.provider.id
      )
  );
  const removedIdentities = before.person.identities.filter(
    (b) =>
      !after.person.identities.some(
        (a) => a.id === b.id && a.provider.id === b.provider.id
      )
  );

  // Compute changes in the usage rights.
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const beforeFeatureIds = before.configuredUsageRights.map(
    (ur) => ur.feature.id
  );
  const afterFeatureIds = after.configuredUsageRights.map(
    (ur) => ur.feature.id
  );

  const addedUsageRights = after.configuredUsageRights.filter(
    (ur) => !beforeFeatureIds.includes(ur.feature.id)
  );
  const removedUsageRights = before.configuredUsageRights.filter(
    (ur) => !afterFeatureIds.includes(ur.feature.id)
  );

  const sameFeatures = afterFeatureIds
    // Only keep id of features in both after & before.
    .filter((id) => beforeFeatureIds.includes(id))
    .map((id) => {
      const beforeRoleIds = before.configuredUsageRights
        .filter((ur) => ur.feature.id === id)
        .flatMap((ur) => ur.roles)
        .map((r) => r.id);
      const afterRoleIds = after.configuredUsageRights
        .filter((ur) => ur.feature.id === id)
        .flatMap((ur) => ur.roles)
        .map((r) => r.id);

      return {
        featureId: id,
        addedRoles: afterRoleIds.filter((id) => !beforeRoleIds.includes(id)),
        removedRoles: beforeRoleIds.filter((id) => !afterRoleIds.includes(id)),
      };
    });

  const sameFeaturesAddedRoles = sameFeatures.filter(
    (sf) => sf.addedRoles.length > 0
  );
  const sameFeaturesRemovedRoles = sameFeatures.filter(
    (sf) => sf.removedRoles.length > 0
  );

  const featureName = (id: string) =>
    translate(`resources.dxfeatures.${id}.description`, {
      _: id,
    });

  const roleName = (featureId: string, id: string) =>
    translate(`resources.dxfeatures.${featureId}.${id}`, {
      _: id,
    });

  // Compute changes in the job.
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const jobAddedAttributes =
    after?.job?.attributes.filter(
      (aa) => !before?.job?.attributes.some((ba) => aa.key === ba.key)
    ) || [];
  const jobRemovedAttributes =
    before?.job?.attributes.filter(
      (ba) => !after?.job?.attributes.some((aa) => aa.key === ba.key)
    ) || [];

  const jobChangedAttributes =
    after?.job?.attributes
      .filter((aa) =>
        before?.job?.attributes.some(
          (ba) => aa.key === ba.key && aa.value !== ba.value
        )
      )
      .map((aa) => ({
        key: aa.key,
        before: before?.job?.attributes
          .filter((ba) => ba.key === aa.key)
          .map((ba) => ba.value)
          .join(),
        after: aa.value,
      })) || [];

  return (
    <div style={{ marginLeft: '2em' }}>
      <Typography variant='caption'>
        {translate('resources.accounts.edit.tabs.history.changes.what')}
      </Typography>
      <ol>
        {before.enabled !== after.enabled && (
          <li key='enabled'>
            {after.enabled
              ? translate(
                  'resources.accounts.edit.tabs.history.changes.accountEnabled'
                )
              : translate(
                  'resources.accounts.edit.tabs.history.changes.accountDisabled'
                )}
          </li>
        )}
        {addedIdentities.length > 0 && (
          <li key='addedIdentities'>
            <Typography variant='caption'>
              {translate(
                'resources.accounts.edit.tabs.history.changes.newLogins'
              )}
            </Typography>
            <ul>
              {addedIdentities.map((id) => (
                <li key={`addedIdentities.${id.id}`}>
                  {id.id}{' '}
                  <Typography variant='caption'>
                    {translate(
                      'resources.accounts.edit.tabs.history.changes.with'
                    )}
                  </Typography>{' '}
                  {id.provider.id.toUpperCase()}
                </li>
              ))}
            </ul>
          </li>
        )}
        {removedIdentities.length > 0 && (
          <li key='removedIdentities'>
            <Typography variant='caption'>
              {translate(
                'resources.accounts.edit.tabs.history.changes.removedLogins'
              )}
            </Typography>
            <ul>
              {removedIdentities.map((id) => (
                <li key={`removedIdentities.${id.id}`}>
                  {id.id}{' '}
                  <Typography variant='caption'>
                    {translate(
                      'resources.accounts.edit.tabs.history.changes.with'
                    )}
                  </Typography>{' '}
                  {id.provider.id.toUpperCase()}
                </li>
              ))}
            </ul>
          </li>
        )}
        {addedUsageRights.length > 0 && (
          <li key='addedUsageRights'>
            <Typography variant='caption'>
              {translate(
                'resources.accounts.edit.tabs.history.changes.newUsageRights'
              )}
            </Typography>
            <ul>
              {addedUsageRights.map((ur) => (
                <li key={`addedUsageRights.${ur.feature.id}`}>
                  {featureName(ur.feature.id)}
                  <br />
                  <Typography variant='caption'>
                    {translate(
                      'resources.accounts.edit.tabs.history.changes.withRoles'
                    )}
                  </Typography>
                  <ul>
                    {ur.roles.map((r) => (
                      <li key={`addedUsageRights.${ur.feature.id}-${r.id}`}>
                        {roleName(ur.feature.id, r.id)}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        )}
        {removedUsageRights.length > 0 && (
          <li key='removedUsageRights'>
            <Typography variant='caption'>
              {translate(
                'resources.accounts.edit.tabs.history.changes.removedUsageRights'
              )}
            </Typography>
            <ul>
              {removedUsageRights.map((ur) => (
                <li key={`removedUsageRights.${ur.feature.id}`}>
                  {featureName(ur.feature.id)}
                </li>
              ))}
            </ul>
          </li>
        )}
        {sameFeaturesAddedRoles.length > 0 && (
          <li key='sameFeaturesAddedRoles'>
            <Typography variant='caption'>
              {translate(
                'resources.accounts.edit.tabs.history.changes.newUsageRightRoles'
              )}
            </Typography>
            <ul>
              {sameFeaturesAddedRoles.map((ur) => (
                <li key={`sameFeaturesAddedRoles.${ur.featureId}`}>
                  {featureName(ur.featureId)}
                  <br />
                  <Typography variant='caption'>
                    {translate(
                      'resources.accounts.edit.tabs.history.changes.withNewRoles'
                    )}
                  </Typography>
                  <ul>
                    {ur.addedRoles.map((r) => (
                      <li key={`sameFeaturesAddedRoles.${ur.featureId}-${r}`}>
                        {roleName(ur.featureId, r)}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        )}
        {sameFeaturesRemovedRoles.length > 0 && (
          <li key='sameFeaturesRemovedRoles'>
            <Typography variant='caption'>
              {translate(
                'resources.accounts.edit.tabs.history.changes.removedUsageRightRoles'
              )}
            </Typography>
            <ul>
              {sameFeaturesRemovedRoles.map((ur) => (
                <li key={`sameFeaturesRemovedRoles.${ur.featureId}`}>
                  {featureName(ur.featureId)}
                  <br />
                  <Typography variant='caption'>
                    {translate(
                      'resources.accounts.edit.tabs.history.changes.removedRoles'
                    )}
                  </Typography>
                  <ul>
                    {ur.removedRoles.map((r) => (
                      <li key={`sameFeaturesRemovedRoles.${ur.featureId}-${r}`}>
                        {roleName(ur.featureId, r)}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        )}
        {jobAddedAttributes.length > 0 && (
          <li key='jobAddedAttributes'>
            <Typography variant='caption'>
              {translate(
                'resources.accounts.edit.tabs.history.changes.jobAddedAttributes'
              )}
            </Typography>
            <ul>
              {jobAddedAttributes.map((att) => (
                <li key={`jobAddedAttributes.${att.key}`}>
                  {att.key}: {att.value || '-'}
                </li>
              ))}
            </ul>
          </li>
        )}
        {jobRemovedAttributes.length > 0 && (
          <li key='jobRemovedAttributes'>
            <Typography variant='caption'>
              {translate(
                'resources.accounts.edit.tabs.history.changes.jobRemovedAttributes'
              )}
            </Typography>
            <ul>
              {jobRemovedAttributes.map((att) => (
                <li key={`jobRemovedAttributes.${att.key}`}>
                  {att.key}: {att.value || '-'}
                </li>
              ))}
            </ul>
          </li>
        )}
        {jobChangedAttributes.length > 0 && (
          <li key='jobChangedAttributes'>
            <Typography variant='caption'>
              {translate(
                'resources.accounts.edit.tabs.history.changes.jobChangedAttributes'
              )}
            </Typography>
            <ul>
              {jobChangedAttributes.map((att) => (
                <li key={`jobChangedAttributes.${att.key}`}>
                  {att.key}: {att.before || '-'} <span>&rarr;</span>{' '}
                  {att.after || '-'}
                </li>
              ))}
            </ul>
          </li>
        )}
      </ol>
    </div>
  );
};

export default AccountDiffPanel;
