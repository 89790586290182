import compose from 'lodash/flowRight';
import { Component } from 'react';
import { connect } from 'react-redux';
import { TaskService } from '../../../../../services/TaskService';
import { ClusterProps, EnhancedProps } from '../../../../../shared/types';
import { P2pUtils } from '../../../../common/P2pUtils';

function mapStateToProps(state, props) {
  return {
    id: props.id,
    record: state.admin.resources[props.resource]
      ? state.admin.resources[props.resource].data[props.id]
      : null,
    isLoading: state.admin.loading > 0,
  };
}

class UnconnectedShowController extends Component<
  ClusterProps & EnhancedProps
> {
  state = {
    cluster: undefined,
    loading: true,
  };

  async componentDidMount() {
    await this.updateData();
  }

  async updateData() {
    const { dataProvider, dxuids } = this.props;
    const service = new TaskService(dataProvider);
    const documents = await service.getDocuments(dxuids);
    if (documents) {
      this.setState({ cluster: documents, loading: false });
    }
  }

  render() {
    const { children, resource, classes } = this.props;
    let { cluster, loading }: any = this.state;
    if (!children) {
      return null;
    }

    if (cluster) {
      cluster.sort((a, b) => (a.nodeType === 'edm:invoice' ? -1 : 1));
      cluster = P2pUtils.convertLines2ViewObject(cluster);
    }
    return children({
      isLoading: loading,
      defaultTitle: '',
      resource,
      record: cluster,
      classes,
    });
  }
}

const ClusterShowController = compose(connect(mapStateToProps, {}))(
  UnconnectedShowController
);

export default ClusterShowController;
