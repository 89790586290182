import { DxTheme, GenericLabeledDateField } from '@dx-ui/dx-common';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { useTranslate } from 'react-admin';
import { IWayBillSources } from '../WayBillSources';
import WayBillLocationFragment from './WayBillLocationFragment';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    portLocation: {
      backgroundColor: theme.palette.grey[100],
      padding: '1em',
    },
    shipmentDataContainer: {
      paddingLeft: '1em',
    },
  }),
  { name: 'WayBillTransportShipmentStageFragment' }
);

interface PropsModel {
  record: any;
  dataSources: IWayBillSources;
}

const WayBillTransportShipmentStageFragment = (
  props: PropsModel
): JSX.Element => {
  const { record, dataSources } = props;
  const classes = useStyles();
  const translate = useTranslate();
  const loadingLocation = get(
    record,
    dataSources.ShipmentStageSources.loadingLocation.source
  );
  const unloadingLocation = get(
    record,
    dataSources.ShipmentStageSources.unloadingLocation.source
  );
  return (
    <Grid container className={classes.portLocation}>
      <Grid item xs={6}>
        <Grid container direction='column'>
          <Grid item xs={12}>
            <Typography>
              {translate('dxMessages.waybill.loadingTable.loadingLocation')}
            </Typography>

            <WayBillLocationFragment
              record={get(
                loadingLocation,
                dataSources.ShipmentStageSources.loadingLocation.fields.address
                  .source
              )}
              dataSources={dataSources}
              title={'loadingLocation'}
            />

            <GenericLabeledDateField
              label={translate('dxMessages.waybill.loadingTable.loadingDate')}
              oneLine
              id={dataSources.ShipmentStageSources.loadingDate}
              record={record}
              className='loadingDate'
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container direction='column'>
          <Grid item xs={12}>
            <Typography>
              {translate('dxMessages.waybill.loadingTable.unloadingLocation')}
            </Typography>

            <WayBillLocationFragment
              record={get(
                unloadingLocation,
                dataSources.ShipmentStageSources.unloadingLocation.fields
                  .address.source
              )}
              dataSources={dataSources}
              title={'unloadingLocation'}
            />

            <GenericLabeledDateField
              label={translate('dxMessages.waybill.loadingTable.unloadingDate')}
              oneLine
              id={dataSources.ShipmentStageSources.unloadingDate}
              record={record}
              className='unloadingDate'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WayBillTransportShipmentStageFragment;
