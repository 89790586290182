import {
  Constants,
  Metadata,
  ProcessStatus,
  sendGAEvent,
} from '@dx-ui/dx-common';
import { linkToRecord, usePermissionsOptimized } from 'ra-core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  MenuItemLink,
  useDataProvider,
  useGetIdentity,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { GA_EVENTS } from '../../GAUtils';
import {
  AlfrescoDocumentService,
  CopyOperation,
  DataHelpers,
  DocumentServiceFactory,
  ReceiptAdviceService,
  ReceiptAdviceServiceCreator,
} from '../../services';
import ToolbarButton from '../../shared/ToolbarButton';
import { DocumentProperties, P2pData } from '../../shared/types';

interface ActionProps {
  record: P2pData;
  isMenuItem?: boolean;
}

const ReceiptAdviceActions = React.forwardRef<any, ActionProps>(
  (props, ref) => {
    const { record, isMenuItem } = props;

    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const { permissions } = usePermissionsOptimized();

    const processStatus: ProcessStatus = DataHelpers.getProcessStatus(record);
    const isWebDocument = AlfrescoDocumentService.isWebDocument(record);
    const { identity } = useGetIdentity();
    // @ts-ignore
    const account: Account = identity;

    const [copyPermissions, setCopyPermissions] = useState<{
      canClone: boolean;
    }>({
      canClone: false,
    });

    const [loading, setLoading] = useState<boolean>(false);

    const documentService = useMemo(
      () => new AlfrescoDocumentService(dataProvider),
      [dataProvider]
    );

    const receiptAdviceService = useMemo(
      () =>
        DocumentServiceFactory.create(
          ReceiptAdviceServiceCreator,
          dataProvider
        ) as ReceiptAdviceService,
      [dataProvider]
    );

    useEffect(() => {
      const checkForCopyPermissions = async () => {
        let canClone = false;

        setLoading(true);

        try {
          const canCreate = ReceiptAdviceService.canCreate(permissions);

          if (canCreate) {
            const clonePermissions =
              await documentService.getClonePermissions();

            const cloneKey = isWebDocument
              ? CopyOperation.CAN_CLONE_WEB_RECADV
              : CopyOperation.CAN_CLONE_CLASSIC_RECADV;

            canClone =
              isWebDocument &&
              !!clonePermissions[cloneKey]?.includes(processStatus);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }

        setCopyPermissions({
          canClone,
        });

        setLoading(false);
      };

      checkForCopyPermissions();
    }, [
      dataProvider,
      processStatus,
      isWebDocument,
      record,
      permissions,
      receiptAdviceService,
      documentService,
    ]);

    enum ReceiptAdviceActionType {
      CLONE,
    }

    const onClickDoAction = (actionType: ReceiptAdviceActionType) => {
      return async (e) => {
        e.preventDefault();

        if (!record.id) {
          throw new Error('record id cannot be null');
        }

        setLoading(true);

        try {
          let result: DocumentProperties;
          switch (actionType) {
            case ReceiptAdviceActionType.CLONE:
              sendGAEvent(
                GA_EVENTS.categories.FORM.name,
                GA_EVENTS.categories.FORM.actions.CLONE,
                account?.company?.cmsRootDir,
                record
                  ? `Order DXUID: ${record.properties[Metadata.dxuid]}`
                  : undefined
              );

              result = await receiptAdviceService.clone(record.id);
              break;
            default:
              throw new Error(`Invalid value for actionType: ${actionType}`);
          }
          setLoading(false);
          const documentSubTypeCode =
            DataHelpers.getDocumentSubtypeCode(record);
          redirect(
            `/${linkToRecord(Constants.RESOURCE_WEBRECEIPT_ADVICE, result.id)}`,
            undefined, // basePath
            undefined, // id
            undefined, // data
            {
              documentSubTypeCode: documentSubTypeCode,
            } // state
          );
        } catch (e) {
          setLoading(false);
          // eslint-disable-next-line no-console
          console.error(e);
        }
      };
    };

    if (isMenuItem) {
      const sanitizeLinkProps = ({ isMenuItem, record, ...rest }: any) => rest;
      return (
        <>
          {copyPermissions.canClone && (
            <MenuItemLink
              {...sanitizeLinkProps(props)}
              disabled={loading}
              to={``}
              primaryText={translate('dxMessages.buttons.cloneDocument')}
              onClick={onClickDoAction(ReceiptAdviceActionType.CLONE)}
            />
          )}
        </>
      );
    } else {
      const sanitizeBtnProps = ({ isMenuItem, className, ...rest }: any) =>
        rest;

      return (
        <>
          {copyPermissions.canClone && (
            <ToolbarButton
              disabled={loading}
              loading={loading}
              label={'dxMessages.buttons.cloneDocument'}
              {...sanitizeBtnProps(props)}
              onClick={onClickDoAction(ReceiptAdviceActionType.CLONE)}
            />
          )}
        </>
      );
    }
  }
);

ReceiptAdviceActions.defaultProps = {
  isMenuItem: true,
};

export default ReceiptAdviceActions;
