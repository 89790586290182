import {
  AlfrescoDownloadService,
  CheckboxMenuItem,
  DxTheme,
  GA_EVENTS,
  sendGAEvent,
  useDownloadService,
} from '@dx-ui/dx-common';
import { FormControl, Input, Tooltip } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import React from 'react';
import {
  Identifier,
  useGetIdentity,
  useNotify,
  useRecordSelection,
  useTranslate,
} from 'react-admin';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    bulkDownloadBtn: {
      top: theme.spacing(1),
      fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
      width: '150px',
      borderRadius: 24,
      color: theme.colors.white,
      '&:hover': {
        backgroundColor: theme.colors.black30,
      },
      '& .MuiSelect-icon': {
        fill: 'white',
      },
      '& .MuiInputBase-input': {
        height: '50px',
        padding: 0,
        lineHeight: '50px',
      },
      '& .MuiSelect-select': {
        height: '50px',
        lineHeight: '50px',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    iconButtonCustomized: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '5px',
      fontSize: 'medium',
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
    iconButton: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 'medium',
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
    downloadButton: {
      justifyContent: 'center',
      display: 'flex',
      marginTop: '10px',
      marginLeft: '20%',
      marginRight: '20%',
      color: '#3b0082',
      border: '1px solid rgba(59, 0, 130, 0.5)',
      borderRadius: '4px',
    },
  }),
  { name: 'BulkDownloadButton' }
);

const MaxSelectionCount = 200;

/*
 * Creates a Bulk Download button.
 */
const BulkDownloadButton = (props) => {
  const { resource, label } = props;

  const [selectedIds, listActions] = useRecordSelection(resource);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;
  const classes = useStyles(props);
  const translate = useTranslate();
  const notify = useNotify();
  const bulkDownloadService: AlfrescoDownloadService = useDownloadService();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps: any = {
    sx: {
      backgroundColor: 'red',
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  const launchBulkDownload = async (representation?: any) => {
    sendGAEvent(
      GA_EVENTS.categories.DOWNLOAD_DATA.name,
      GA_EVENTS.categories.DOWNLOAD_DATA.actions.BULK_DOWNLOAD,
      account?.company?.cmsRootDir,
      representation
    );

    listActions.select([]);
    notify(
      'dxMessages.download.inProgress',
      'info',
      undefined,
      undefined,
      bulkDownloadService.timeout
    );

    try {
      await bulkDownloadService.download(selectedIds, representation);
      notify('dxMessages.download.completed', 'info');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'Error while downloading selected and related documents',
        error
      );
      notify('dxMessages.download.errorBulk', 'warning');
    }
  };

  const isSelectionBetweenLimits = (selectedIds: Identifier[]) => {
    return (
      selectedIds &&
      selectedIds.length &&
      selectedIds.length <= MaxSelectionCount
    );
  };
  const [representation, setRepresentation] = React.useState([]);

  const handleChange = async (event) => {
    if (event.target.value !== '') {
      if (event.target.value.includes(1)) {
        if (isSelectionBetweenLimits(selectedIds)) {
          setOpen(false);
          await launchBulkDownload(event.target.value.filter((v) => v !== 1));
        } else {
          notify('dxMessages.download.warningLimit', 'warning');
        }
      } else {
        const filtered = event.target.value.filter((element) => element !== '');
        setRepresentation(filtered);
      }
    }
  };

  return (
    <div style={{ marginBottom: '26px' }}>
      <Tooltip
        title={label || translate('dxMessages.buttons.download')}
        placement='left'
      >
        <FormControl className={classes.formControl}>
          <Select
            className={classes.bulkDownloadBtn}
            multiple
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={representation.length !== 0 ? representation : ['']}
            onChange={handleChange}
            input={<Input disableUnderline={true} />}
            renderValue={() => {
              return (
                <div className={classes.iconButtonCustomized}>
                  <DownloadIcon />
                  {label || translate('dxMessages.buttons.download')}
                </div>
              );
            }}
            MenuProps={MenuProps}
          >
            <CheckboxMenuItem key={'PDF'} value={'PDF'}>
              <ListItemText primary={'PDF'} />
            </CheckboxMenuItem>
            <CheckboxMenuItem key={'EDI DOCUMENT'} value={'EDI DOCUMENT'}>
              <ListItemText
                primary={translate('dxMessages.buttons.ediDocument')}
              />
            </CheckboxMenuItem>
            <CheckboxMenuItem key={'ATTACHMENT'} value={'ATTACHMENT'}>
              <ListItemText
                primary={translate('dxMessages.attachments.title')}
              />
            </CheckboxMenuItem>
            <CheckboxMenuItem
              key={'FEEDBACK MESSAGE'}
              value={'FEEDBACK MESSAGE'}
            >
              <ListItemText
                primary={translate('dxMessages.feedbackMessages.title')}
              />
            </CheckboxMenuItem>
            <MenuItem
              key={1}
              value={1}
              className={classes.downloadButton}
              disabled={representation.length === 0}
            >
              <div className={classes.iconButton}>
                <DownloadIcon />
                {label || translate('dxMessages.buttons.download')}
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    </div>
  );
};

export default BulkDownloadButton;
