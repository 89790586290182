import {
  checkPermissions,
  Constants,
  DocumentTypeCode,
  documentTypeCodeToResource,
  EmptyValue,
  formatDateToAlfresco,
  Metadata,
  ReceiptAdviceSubType,
  UserRoles,
} from '@dx-ui/dx-common';
import {
  AddressDetails,
  CustomerPartyDetails,
  DocumentReferenceDetails,
  ItemPropertyDetails,
  PartyDetails,
  ReceiptAdviceDetails,
  ReceiptAdviceModel,
  ReceiptLineDetails,
  SupplierPartyDetails,
} from '@dx-ui/lib-oasis-ubl-2.1/src/ReceiptAdviceModel';
import { cloneDeep, first, get, set, unset } from 'lodash';
import { buildAddressAsAString } from '../modules/common/PreviewAddress';
import {
  Address,
  AlfrescoContent,
  AlfrescoProperties,
  DocumentProperties,
  Line,
  P2pData,
  WebFormData,
} from '../shared/types';
import { setSourceField } from '../shared/webForms/utils';
import {
  DuplicationType,
  IAlfrescoDocumentService,
} from './AlfrescoDocumentService';
import { BaseDocumentService } from './BaseDocumentService';
import {
  CompanyAddress,
  CompanyModel,
  CompanyService,
  CreationPolicy,
} from './CompanyService';
import { DataHelpers } from './DataHelpers';
import { LineProcessor, TaxAndPriceUtils } from './FormDataHelpers';
import { IDocumentService } from './IDocumentService';
import { TemplatesService } from './TemplatesService';

const TEMPLATE_TYPE = 'RECADV';

// DOCUMENT_TYPE_CODE
const DOCUMENT_TYPE_CODE_COL_PATH =
  'ReceiptAdvice[0].AdditionalDocumentReference';
const DOCUMENT_TYPE_CODE_KEY_PATH = 'DocumentTypeCode[0]._';
const DOCUMENT_TYPE_CODE_CHECK_PATH = 'ID[0]._';
const DOCUMENT_TYPE_CODE_VALUES_35E = ['RECADV', 'INVOIC'];
const DOCUMENT_TYPE_CODE_VALUES_632 = ['INVOIC'];

// LINE PRICE VALUES
const PRICE_COL_PATH = 'ReceiptAdvice[0].AdditionalItemProperty';
const PRICE_KEY_PATH = 'ValueQualifier[0]._';
const PRICE_CHECK_PATH = 'ValueQuantity[0]._';
const PRICE_VALUES = ['AAA', '128', '124'];

export interface IReceiptAdviceService extends IDocumentService {}

/**
 *
 */
export class ReceiptAdviceService
  extends BaseDocumentService
  implements IReceiptAdviceService
{
  /**
   * 'ublProperties.' part is important in the constant value because of how data is read from the template
   */
  public static CUSTOM_CATEGORY = 'custom';
  public static MONO_CURRENCY_FIELD = `ublProperties.${ReceiptAdviceService.CUSTOM_CATEGORY}.MONO_CURRENCY`;
  public static MONO_VAT_FIELD = `ublProperties.${ReceiptAdviceService.CUSTOM_CATEGORY}.MONO_VAT`;

  constructor(
    private companyService: CompanyService,
    private templatesService: TemplatesService,
    documentService: IAlfrescoDocumentService
  ) {
    super(documentService);
  }

  public async loadDraft(nodeId: string | undefined): Promise<AlfrescoContent> {
    const resource = documentTypeCodeToResource(DocumentTypeCode.RECADV);
    const payload = { id: nodeId };
    const response = await this.documentService.dataProvider[
      Constants.API_GET_JSON_CONTENT
    ](resource, payload);

    const ublData: ReceiptAdviceModel = response.data.ublProperties
      ? (response.data.ublProperties as ReceiptAdviceModel)
      : (response.data as ReceiptAdviceModel);

    let receiptAdvice: ReceiptAdviceDetails | undefined = undefined;

    if (ublData?.ReceiptAdvice?.length) {
      receiptAdvice = ublData?.ReceiptAdvice[0];
    }

    if (!receiptAdvice) {
      throw new Error(
        `Unexpected error or no receipt advice for nodeId ${nodeId}.`
      );
    }

    this.setupItemOrderConventions(ublData);

    // Sets mandatory lines nodes
    receiptAdvice.ReceiptLine.forEach((line: ReceiptLineDetails) => {
      if (!line.Item?.[0]?.AdditionalItemProperty) {
        const additionalItemProperties: ItemPropertyDetails[] = [];
        PRICE_VALUES.forEach((value) => {
          const item: ItemPropertyDetails = {
            Name: [{ _: 'AMOUNT' }],
            ValueQualifier: [{ _: value }],
          };

          additionalItemProperties.push(item);
        });
        set(line, 'Item[0].AdditionalItemProperty', additionalItemProperties);
      }

      // Add missing Classified nodes
      const classifiedTaxCategory = line.Item?.[0]?.ClassifiedTaxCategory;
      const taxScheme = classifiedTaxCategory?.[0]?.TaxScheme;
      if (!taxScheme) {
        set(line, 'Item[0].ClassifiedTaxCategory[0].TaxScheme', [
          {
            ID: [{ _: '7' }], // Tax scheme (ex: 7)
            Name: [{ _: 'S' }], // Tax Name (ex: S)
            TaxTypeCode: [{ _: 'VAT' }], // Tax type code (ex: VAT)
          },
        ]);
      }
    });

    // EDM Properties are extracted from UBL content
    const metadataProperties = this.buildMetadataProperties(receiptAdvice);

    let customUblProperties = { ublProperties: {} };

    // Mono VAT
    const percentArray = receiptAdvice.ReceiptLine?.map(
      (l) => l.Item?.[0].ClassifiedTaxCategory?.[0].Percent?.[0]._
    );
    const samePercent = new Set(percentArray).size === 1;
    if (!samePercent) {
      set(customUblProperties, ReceiptAdviceService.MONO_VAT_FIELD, undefined);
    } else {
      set(
        customUblProperties,
        ReceiptAdviceService.MONO_VAT_FIELD,
        percentArray?.[0]
      );
    }

    // Mono currency
    set(
      customUblProperties,
      ReceiptAdviceService.MONO_CURRENCY_FIELD,
      metadataProperties[Metadata.currency]
    );

    // store lines outside of the UBL content
    // TODO: this should be changed. Lines should stay together with data.
    let lines: ReceiptLineDetails[] = receiptAdvice.ReceiptLine;
    receiptAdvice.ReceiptLine = [];

    const result: AlfrescoContent = {
      id: nodeId,
      properties: metadataProperties,
      ublContent: {
        ublProperties: { ...customUblProperties.ublProperties, ...ublData },
        lines,
      },
    };

    return result;
  }

  public async load(nodeId: string): Promise<AlfrescoContent> {
    const result = await this.documentService.loadDocument(
      nodeId,
      DocumentTypeCode.RECADV
    );

    const extension = new DOMParser().parseFromString(
      result?.ublextensions?.ublextension?.[0]?.extensionContent?.any,
      'text/xml'
    );

    let lines = result.receiptLine; // old ubl (with "".value")
    if (extension) {
      lines.forEach((line: Line) => {
        /* #region price */
        const price = extension.evaluate(
          `//dx:ReceiptAdviceLineExtension[./dx:ReceiptLineReferenceID[normalize-space()='${line.id?.value}']]/dx:Price/cbc:PriceAmount`,
          extension,
          this.nsResolver,
          XPathResult.NUMBER_TYPE,
          null
        );
        const currency = extension.evaluate(
          `//dx:ReceiptAdviceLineExtension[./dx:ReceiptLineReferenceID[normalize-space()='${line.id?.value}']]/dx:Price/cbc:PriceAmount/@currencyID`,
          extension,
          this.nsResolver,
          XPathResult.STRING_TYPE,
          null
        );
        line.price = {
          priceAmount: {
            value: price.numberValue,
            currencyID: currency.stringValue,
          },
        };
        /* #endregion*/

        /* #region total without vat */
        const totalWithoutVat = extension.evaluate(
          `//dx:ReceiptAdviceLineExtension[./dx:ReceiptLineReferenceID[normalize-space()='${line.id?.value}']]/dx:LineExtensionAmount`,
          extension,
          this.nsResolver,
          XPathResult.NUMBER_TYPE,
          null
        );
        const totalWithoutVatCurrency = extension.evaluate(
          `//dx:ReceiptAdviceLineExtension[./dx:ReceiptLineReferenceID[normalize-space()='${line.id?.value}']]/dx:LineExtensionAmount/@currencyID`,
          extension,
          this.nsResolver,
          XPathResult.STRING_TYPE,
          null
        );

        line.lineExtensionAmount = {
          value: totalWithoutVat.numberValue,
          currencyID: totalWithoutVatCurrency.stringValue,
        };
        /* #endregion */

        /* #region total vat */
        const totalVat = extension.evaluate(
          `//dx:ReceiptAdviceLineExtension[./dx:ReceiptLineReferenceID[normalize-space()='${line.id?.value}']]/dx:TaxtTotal/cbc:TaxAmount`,
          extension,
          this.nsResolver,
          XPathResult.NUMBER_TYPE,
          null
        );
        const totalVatCurrency = extension.evaluate(
          `//dx:ReceiptAdviceLineExtension[./dx:ReceiptLineReferenceID[normalize-space()='${line.id?.value}']]/dx:TaxtTotal/cbc:TaxAmount/@currencyID`,
          extension,
          this.nsResolver,
          XPathResult.STRING_TYPE,
          null
        );
        const taxAmount = {
          value: totalVat.numberValue,
          currencyID: totalVatCurrency.stringValue,
        };
        /* #endregion */

        /* #region vat percent*/
        const vatPercentage = extension.evaluate(
          `//dx:ReceiptAdviceLineExtension[./dx:ReceiptLineReferenceID[normalize-space()='${line.id?.value}']]/dx:TaxtTotal/cac:TaxSubtotal/cbc:Percent`,
          extension,
          this.nsResolver,
          XPathResult.NUMBER_TYPE,
          null
        );

        const percent = {
          value: vatPercentage.numberValue,
        };
        /* #endregion */

        line.taxTotal = [
          {
            taxAmount,
            taxSubtotal: [
              {
                taxAmount,
                percent,
                taxCategory: {
                  taxScheme: TaxAndPriceUtils.getDefaultTaxScheme(),
                },
              },
            ],
          },
        ];

        /* #region green tax */
        // Pre-set allowanceCharge in order to fix which entry contains the green Tax/discount or SGR
        LineProcessor.ensureAllowanceChargeAndDiscount(
          line,
          currency.stringValue
        );

        // First check if there a SGR tax Amount value
        const sgrTaxAmount = extension.evaluate(
          `//dx:ReceiptAdviceLineExtension[./dx:ReceiptLineReferenceID[normalize-space()='${line.id?.value}']]/dx:Price/cac:AllowanceCharge[cbc:AllowanceChargeReasonCode/text() = "SGR"]/cbc:Amount`,
          extension,
          this.nsResolver,
          XPathResult.NUMBER_TYPE,
          null
        );
        // allowanceCharge never empty here
        set(
          line,
          'allowanceCharge[2].amount.value',
          sgrTaxAmount.numberValue || undefined
        );

        // check greenTax PerUnitAmount value if no SGR
        if (!sgrTaxAmount.numberValue) {
          const greenTaxPerUnitAmount = extension.evaluate(
            `//dx:ReceiptAdviceLineExtension[./dx:ReceiptLineReferenceID[normalize-space()='${line.id?.value}']]/dx:Price/cac:AllowanceCharge[cbc:chargeIndicator/text() = "true"]/cbc:PerUnitAmount`,
            extension,
            this.nsResolver,
            XPathResult.NUMBER_TYPE,
            null
          );
          // allowanceCharge never empty here
          set(
            line,
            'allowanceCharge[1].perUnitAmount.value',
            greenTaxPerUnitAmount.numberValue || undefined
          );
        }
        /* #endregion */
      });
    }

    delete result.receiptLine;
    const ublProperties = result;

    const data: AlfrescoContent = {
      id: nodeId,
      properties: {},
      ublContent: {
        ublProperties,
        lines,
      },
    };

    return data;
  }

  public createNewContent(
    documentSubtype: ReceiptAdviceSubType = ReceiptAdviceSubType.GoodsReturnAdvice
  ): AlfrescoContent {
    const content: ReceiptAdviceModel = {
      ...ReceiptAdviceService.receiptAdviceHeader,
      ReceiptAdvice: [
        {
          UBLVersionID: [
            {
              _: '2.1',
            },
          ],
          ReceiptAdviceTypeCode: [{ _: documentSubtype }],
          ID: [],
          IssueDate: [],
          ReceiptLine: [],
          DespatchSupplierParty: [{}],
          DeliveryCustomerParty: [],
          LineCountNumeric: [{ _: 1 }], // 1 line by default (see bellow)
          Shipment: [
            {
              ID: [{ _: '1' }],
            },
          ],
        },
      ],
    };

    this.setupItemOrderConventions(content);

    const data: AlfrescoContent = {
      ...this.documentService.initNewDocument(
        DocumentTypeCode.RECADV,
        documentSubtype
      ),
      ublContent: {
        lines: [ReceiptAdviceService.initNewLine()],
        ublProperties: content,
      },
    };

    return data;
  }

  public async createNew(recipientId: string): Promise<string | undefined> {
    let receiptAdviceContent: AlfrescoContent = await this.createNewContent();
    // Specific to Recadv and Order => for the creation from scratch invocation,
    // the issuerId is map to recipientId. Templates limitation ...
    set(receiptAdviceContent.properties, Metadata.issuerId, recipientId);
    const unflattenedReceiptAdvice = {
      properties: receiptAdviceContent.properties,
      ...receiptAdviceContent.ublContent,
    };
    // Saving w/o nodeId => POST create
    const receiptAdviceProperties = await this.saveData(
      unflattenedReceiptAdvice
    );
    return receiptAdviceProperties.id;
  }

  private setupItemOrderConventions(data: ReceiptAdviceModel) {
    this.setupDocumentTypeCodeConvention(data);
    this.setupLineTotalConvention(data);
  }

  private cleanupItemOrderConventions(data: ReceiptAdviceModel) {
    this.cleanupDocumentTypeCodeConvention(data);
    this.cleanupLineTotalConvention(data);
  }

  /**
   *
   */
  private setupDocumentTypeCodeConvention(data: ReceiptAdviceModel) {
    // ensure certain collections are valid for the template

    // goods return document rules (35E):
    // [0]: Receipt advice document number
    // [1]: Invoice document number

    // Receipt advice document rules (632)
    // [0]: Invoice document number

    if (!data.ReceiptAdvice[0].AdditionalDocumentReference) {
      data.ReceiptAdvice[0].AdditionalDocumentReference = [];
    }

    const emptyItem: DocumentReferenceDetails = {
      ID: [],
    };
    const documentTypeCodeValues =
      data.ReceiptAdvice?.[0]?.ReceiptAdviceTypeCode?.[0]?._ ===
      ReceiptAdviceSubType.GoodsReturnAdvice
        ? DOCUMENT_TYPE_CODE_VALUES_35E
        : DOCUMENT_TYPE_CODE_VALUES_632;

    this.setupCollectionItems(
      data,
      DOCUMENT_TYPE_CODE_COL_PATH,
      DOCUMENT_TYPE_CODE_KEY_PATH,
      documentTypeCodeValues,
      emptyItem
    );
  }

  /**
   *
   */
  private cleanupDocumentTypeCodeConvention(data: ReceiptAdviceModel) {
    this.cleanCollectionItems(
      data,
      DOCUMENT_TYPE_CODE_COL_PATH,
      DOCUMENT_TYPE_CODE_CHECK_PATH
    );
  }

  private cleanupLinesItems(data: ReceiptAdviceModel) {
    const lines = data.ReceiptAdvice[0].ReceiptLine ?? [];

    lines.forEach((line) => {
      if (line.Item?.length) {
        if (!line.Item[0].StandardItemIdentification?.[0].ID?.[0]._) {
          delete line.Item[0].StandardItemIdentification;
        }
        if (!line.Item[0].SellersItemIdentification?.[0].ID?.[0]._) {
          delete line.Item[0].SellersItemIdentification;
        }
        if (!line.Item[0].BuyersItemIdentification?.[0].ID?.[0]._) {
          delete line.Item[0].BuyersItemIdentification;
        }
      }
    });
  }

  /**
   *
   */
  private setupLineTotalConvention(data: ReceiptAdviceModel) {
    const lines = data.ReceiptAdvice[0].ReceiptLine ?? [];

    lines.forEach((line) => {
      if (line.Item?.length) {
        const emptyItem: ItemPropertyDetails = {
          Name: [],
        };
        this.setupCollectionItems(
          line.Item[0],
          PRICE_COL_PATH,
          PRICE_KEY_PATH,
          PRICE_VALUES,
          emptyItem
        );
      }
    });
  }

  /**
   *
   */
  private cleanupLineTotalConvention(data: ReceiptAdviceModel) {
    const lines = data.ReceiptAdvice[0].ReceiptLine ?? [];

    lines.forEach((line) => {
      if (line.Item?.length) {
        this.cleanCollectionItems(
          line.Item[0],
          PRICE_COL_PATH,
          PRICE_CHECK_PATH
        );
      }
    });
  }

  /**
   * Gets or creates a document.
   * @param nodeId Alfresco node Id of the document
   * @param recipientId ??? -> actually this is the issuerId / customerId for receipt advices
   * @param locale
   * @param alfrescoMetadata - alfresco properties (edm:* fields)
   */
  public async getData(
    nodeId: string | undefined,
    recipientId: string,
    locale: string,
    alfrescoMetadata: any = {},
    newTemplate?: any
  ): Promise<WebFormData> {
    let issuerId: string;

    // load document from alfresco or initialize a new one
    let document: AlfrescoContent;
    if (newTemplate) {
      // create a new document
      document = this.createNewContent();
      // 'recipientId' parameter name is not correct -> this should be fixed
      // was true for invoices etc, but not for recadv
      issuerId = recipientId;
    } else {
      //load document from alfresco
      document = await this.loadDraft(nodeId);

      // document already created -> take the issuer directly
      issuerId = get(alfrescoMetadata, Metadata.issuerId);
    }

    const completeData: P2pData = {
      id: nodeId,
      properties: alfrescoMetadata,
      lines: [],
      ublProperties: {},
    };
    const selectValues = [];

    /* #region add hard coded data -> this should be deleted in the future */
    const { data: hardcodedData, lists: hardcodedLists } =
      this.getHardcodedValues(TEMPLATE_TYPE);

    this.mergeFormData(completeData, hardcodedData);
    this.mergeFormData(selectValues, hardcodedLists, true);
    /* #endregion */

    /* #region add data from the loaded document */
    // document content (with lines and data separated)
    const receiptAdvice: ReceiptAdviceDetails =
      document.ublContent.ublProperties;
    const lines: ReceiptLineDetails[] = document.ublContent.lines;

    const flattenedDocument = {
      properties: document.properties,
      ublProperties: receiptAdvice,
      lines,
    };

    this.mergeFormData(completeData, flattenedDocument);
    /* #endregion */

    /* #region call REST APIs and dynamically fill parts of the data */

    // customer
    const companyDetails: CompanyModel = await this.companyService.getDetails();
    const { buyerCustomerParty, buyerCustomerPartyLists } =
      await this.getBuyerCustomerPartyInformation(companyDetails, locale);

    this.mergeFormData(selectValues, buyerCustomerPartyLists);

    // customer delivery location
    const { deliveryCustomerParty, deliveryCustomerPartyLists } =
      await this.getDeliveryInformation(companyDetails, locale);

    this.mergeFormData(selectValues, deliveryCustomerPartyLists);

    // supplier / recipient
    const { sellerSupplierParty, sellerSupplierPartyLists } =
      await this.getSupplierDataAndLists(locale);

    this.mergeFormData(selectValues, sellerSupplierPartyLists);

    // UBL content with loaded data
    const partialReceiptAdvice: ReceiptAdviceModel = {
      ReceiptAdvice: [
        {
          ID: [],
          ReceiptLine: [],
          IssueDate: [],
          SellerSupplierParty: [sellerSupplierParty],
          DeliveryCustomerParty: [deliveryCustomerParty],
          DespatchSupplierParty: [],
          BuyerCustomerParty: [buyerCustomerParty],
        },
      ],
    };

    this.mergeFormData(completeData.ublProperties, partialReceiptAdvice);

    /* #endregion */

    /* #region add data from the template */
    const documentSubTypeCode: ReceiptAdviceSubType =
      this.getRecAdvSubtypeCode(document);

    const template = await this.templatesService.getTemplate(
      DocumentTypeCode.RECADV,
      undefined,
      documentSubTypeCode,
      issuerId
    );

    const { defaultValues, selectValues: templateLists } =
      this.templatesService.getValues(template);

    this.mergeFormData(selectValues, templateLists);

    // default values from template have not the priority
    this.mergeFormData(defaultValues, completeData);
    this.mergeFormData(completeData, defaultValues);

    /* #endregion */

    /* #region add missing data */
    // Lines
    if (completeData.lines.length === 0) {
      const newLine = ReceiptAdviceService.initNewLine();
      completeData.lines = [newLine];
    }

    // completeData.lines.forEach((line) => {
    //   if (!line.custom) {
    //     line.custom = {};
    //   }
    //   set(line.custom, 'RejectReasonCode', [
    //     {
    //       _: 'AlteredMerchandise',
    //     },
    //     {
    //       _: 'AlteredMissingMerchandise',
    //     },
    //   ]);
    // });

    /* #endregion */

    const documentData: P2pData = {
      id: completeData.id,
      lines: completeData.lines,
      properties: completeData.properties,
      ublProperties: completeData.ublProperties,
    };

    const result = {
      documentData,
      selectValues,
      template,
    };

    return result;
  }

  /**
   * Saves the data as an Alfresco document
   * @param formData Formatted document data
   */
  public async saveData(formData: P2pData): Promise<DocumentProperties> {
    const data = cloneDeep(formData);
    // /!\ don't use form formData after this point. Use data instead. It avoids accidentally changing it.
    const nodeId = data.id;

    const queryType = nodeId
      ? Constants.API_PUT_SAVE_DOCUMENT
      : Constants.API_POST_CREATE_DOCUMENT;

    const resource = documentTypeCodeToResource(DocumentTypeCode.RECADV);

    const lines = data.lines as ReceiptLineDetails[];
    const ublData: ReceiptAdviceModel =
      data.ublProperties as ReceiptAdviceModel;
    const receiptAdvice = ublData.ReceiptAdvice[0];

    /* #region lines */
    lines.forEach((line, index) => {
      line.ID = [{ _: `${index + 1}` }];
    });

    receiptAdvice.ReceiptLine = lines;
    receiptAdvice.LineCountNumeric = [{ _: lines.length }];
    /* #endregion */

    /* #region cleanup create empty elements inside collections */

    // cleanup empty collection fields (added during loading)
    this.cleanupItemOrderConventions(ublData);

    // cleanup empty product codes
    this.cleanupLinesItems(ublData);

    // TODO: clean up custom added fields (uses internally and not part of ubl)
    unset(ublData, ReceiptAdviceService.CUSTOM_CATEGORY);
    unset(ublData, 'id');

    /* #endregion */

    // EDM Properties are extracted from UBL content
    const metadataProperties = this.buildMetadataProperties(receiptAdvice);

    const receiptAdviceData = {
      ublContent: {
        ...ReceiptAdviceService.receiptAdviceHeader,
        ...ublData,
      },
      properties: { ...formData.properties, ...metadataProperties },
    };

    const payload = {
      data: receiptAdviceData,
      id: nodeId,
    };

    const result = await this.documentService.dataProvider[queryType](
      resource,
      payload
    );

    return result.data as DocumentProperties;
  }

  /**
   * Create a new document by copying data from source node id
   */
  public async clone(nodeId: string): Promise<DocumentProperties> {
    return await this.documentService.copy(nodeId, DuplicationType.COPY);
  }

  /**
   * Checks if a template exists for user's data
   */
  public async getReceiptAdviceTemplateRights(
    documentRecipientId: string
  ): Promise<{
    cloneOrCreate: boolean;
  }> {
    let cloneOrCreate = false;

    let receiptAdviceSubTypes = await this.templatesService.getSubTypes(
      DocumentTypeCode.RECADV,
      documentRecipientId
    );

    cloneOrCreate =
      receiptAdviceSubTypes?.filter((r) => {
        return Object.keys(r)[0] === ReceiptAdviceSubType.GoodsReceiptAdvice;
      }).length > 0;

    return { cloneOrCreate };
  }

  /**
   * RecipientId from Alfresco metadata based on document type
   */
  public static getRecipientId(alfrescoProperties: any): string | undefined {
    return get(alfrescoProperties, Metadata.issuerId);
  }

  /**
   * Checks if the user has the permissions to create a document
   */
  public static canCreate(permissions: any): boolean {
    return (
      permissions &&
      checkPermissions(
        permissions,
        UserRoles.DXPURCHASE_PRODUCT,
        UserRoles.CREATE_RECEIPT_ADVICE
      )
    );
  }

  public async fetchCreationPolicy(
    resource: string,
    recipientId: string
  ): Promise<CreationPolicy> {
    return await this.companyService.fetchCreationPolicy(resource, recipientId);
  }
  /**
   * Initializes a new line
   */
  public static initNewLine(
    defaultVat?: number | undefined
  ): ReceiptLineDetails {
    const additionalItemProperties: ItemPropertyDetails[] = [];
    PRICE_VALUES.forEach((value) => {
      const item: ItemPropertyDetails = {
        //ValueQuantity: [{_: -1, unitCode: ''}] // value and currency
        Name: [{ _: 'AMOUNT' }],
        ValueQualifier: [{ _: value }],
      };

      additionalItemProperties.push(item);
    });

    let line: ReceiptLineDetails = {
      ID: [{ _: '' }], // Goods return line ID
      //Note: [{ _: '' }], // Line note
      //ReceivedQuantity: [{ _: 0, unitCode: 'KGM' }], // Rejected quantity / Measurement unit for rejected quantity (ex: KGM)
      //RejectReason: [{ _: '' }], // Goods return reason (ex: Marfa deteriorata)
      Item: [
        {
          // Description: [{ _: '' }], // Product/service description (ex: Produs 1)
          // StandardItemIdentification: [{ ID: [{ _: '' }] }], // EAN product code (ex: 5941632000011)
          // SellersItemIdentification: [{ ID: [{ _: '' }] }], // Supplier product code (ex: 150013)
          // BuyersItemIdentification: [{ ID: [{ _: '' }] }], // Customer product code (ex: C11500998)
          AdditionalItemProperty: additionalItemProperties,
          ClassifiedTaxCategory: [
            {
              TaxScheme: [
                {
                  ID: [{ _: '7' }], // Tax scheme (ex: 7)
                  Name: [{ _: 'S' }], // Tax Name (ex: S)
                  TaxTypeCode: [{ _: 'VAT' }], // Tax type code (ex: VAT)
                },
              ],
            },
          ],
        },
      ],
    };

    if (defaultVat !== undefined) {
      line.Item![0].ClassifiedTaxCategory![0].Percent = [{ _: defaultVat }]; // Line VAT amount
    }

    return line;
  }

  /**
   * Build an Address object to use the same method as the one used by OrderPreview
   * */
  public static buildAddressDetails = (
    ublAddress: AddressDetails | undefined
  ): Address | undefined => {
    if (ublAddress === undefined) {
      return undefined;
    }

    const address: Address = { id: '' };

    const buildingNumber = get(ublAddress, 'BuildingNumber[0]._');
    if (buildingNumber !== undefined) {
      address.buildingNumber = { value: buildingNumber };
    }
    const cityName = get(ublAddress, 'CityName[0]._');
    if (cityName !== undefined) {
      address.cityName = { value: cityName };
    }
    const country = get(ublAddress, 'Country[0].IdentificationCode[0]._');
    if (buildingNumber !== undefined) {
      address.country = {
        identificationCode: { value: country },
        name: { value: undefined },
      };
    }
    const postalZone = get(ublAddress, 'PostalZone[0]._');
    if (postalZone !== undefined) {
      address.postalZone = { value: postalZone };
    }
    const streetName = get(ublAddress, 'StreetName[0]._');
    if (buildingNumber !== undefined) {
      address.streetName = { value: streetName };
    }
    const additionalStreetName = get(ublAddress, 'AdditionalStreetName[0]._');
    if (additionalStreetName !== undefined) {
      address.additionalStreetName = { value: additionalStreetName };
    }

    return address;
  };

  /**
   * Takes the ubl content and reads informations used as metadata properties
   */
  private buildMetadataProperties(receiptAdvice: ReceiptAdviceDetails) {
    // EDM Properties are extracted from UBL content
    const metadataProperties = {};
    metadataProperties[Metadata.documentId] = first(receiptAdvice.ID)?._;
    metadataProperties[Metadata.issueDate] = formatDateToAlfresco(
      first(receiptAdvice.IssueDate)?._
    );

    // documentSubTypeCode
    if (receiptAdvice.ReceiptAdviceTypeCode) {
      metadataProperties[Metadata.documentSubTypeCode] =
        receiptAdvice.ReceiptAdviceTypeCode?.[0]?._;
    }

    // recipientId / recipientName
    if (
      !!receiptAdvice.SellerSupplierParty?.length &&
      !!receiptAdvice.SellerSupplierParty[0].Party?.length
    ) {
      const supplierParty = receiptAdvice.SellerSupplierParty[0].Party[0];
      metadataProperties[Metadata.recipientId] =
        supplierParty.PartyIdentification![0].ID[0]._;
      metadataProperties[Metadata.recipientName] =
        supplierParty.PartyName![0].Name[0]._;
    }

    // issuerId / issuerName / issueDate
    if (
      !!receiptAdvice.BuyerCustomerParty?.length &&
      !!receiptAdvice.BuyerCustomerParty[0].Party?.length
    ) {
      const customerParty = receiptAdvice.BuyerCustomerParty![0].Party![0];
      metadataProperties[Metadata.issuerId] =
        customerParty.PartyIdentification![0].ID[0]._;
      metadataProperties[Metadata.issuerName] =
        customerParty.PartyName![0].Name[0]._;
      metadataProperties[Metadata.issueDate] = formatDateToAlfresco(
        first(receiptAdvice.IssueDate)?._
      );
    }

    // documentId
    metadataProperties[Metadata.documentId] = first(receiptAdvice.ID)?._;

    // Metadata : Order details
    const orderReference = first(receiptAdvice.OrderReference);
    if (orderReference) {
      metadataProperties[Metadata.orderId] = first(orderReference.ID)?._;
      metadataProperties[Metadata.orderDate] = formatDateToAlfresco(
        first(orderReference.IssueDate)?._
      );
    }

    // Metadata receipt Advice and invoice references details
    if (
      receiptAdvice.AdditionalDocumentReference?.length &&
      receiptAdvice.ReceiptAdviceTypeCode?.[0]?._ ===
        ReceiptAdviceSubType.GoodsReturnAdvice
    ) {
      if (receiptAdvice.AdditionalDocumentReference[0].ID?.length) {
        metadataProperties[Metadata.receiptAdviceId] =
          receiptAdvice.AdditionalDocumentReference[0].ID[0]._;
      }
      if (receiptAdvice.AdditionalDocumentReference[0].IssueDate?.length) {
        metadataProperties[Metadata.receiptAdviceDate] = formatDateToAlfresco(
          receiptAdvice.AdditionalDocumentReference[0].IssueDate[0]._
        );
      }
      if (receiptAdvice.AdditionalDocumentReference[1].ID?.length) {
        metadataProperties[Metadata.extendedInvoiceId] =
          receiptAdvice.AdditionalDocumentReference[1].ID[0]._;
      }
      if (receiptAdvice.AdditionalDocumentReference[1].IssueDate?.length) {
        metadataProperties[Metadata.extendedInvoiceIssueDate] =
          formatDateToAlfresco(
            receiptAdvice.AdditionalDocumentReference[1].IssueDate[0]._
          );
      }
    }

    if (
      receiptAdvice.AdditionalDocumentReference?.length &&
      receiptAdvice.ReceiptAdviceTypeCode?.[0]?._ ===
        ReceiptAdviceSubType.GoodsReceiptAdvice
    ) {
      if (receiptAdvice.AdditionalDocumentReference[0].ID?.length) {
        metadataProperties[Metadata.extendedInvoiceId] =
          receiptAdvice.AdditionalDocumentReference[0].ID[0]._;
      }
      if (receiptAdvice.AdditionalDocumentReference[0].IssueDate?.length) {
        metadataProperties[Metadata.extendedInvoiceIssueDate] =
          formatDateToAlfresco(
            receiptAdvice.AdditionalDocumentReference[0].IssueDate[0]._
          );
      }
    }

    // Metadata Despatch Advice details
    const desadvReference = first(receiptAdvice.DespatchDocumentReference);
    if (desadvReference) {
      metadataProperties[Metadata.despatchAdviceId] = first(
        desadvReference.ID
      )?._;
      metadataProperties[Metadata.despatchAdviceDate] = formatDateToAlfresco(
        first(desadvReference.IssueDate)?._
      );
    }

    // Delivery information
    if (
      !!receiptAdvice.DeliveryCustomerParty?.length &&
      !!receiptAdvice.DeliveryCustomerParty[0].Party?.length
    ) {
      // Delivery location name
      if (!!receiptAdvice.DeliveryCustomerParty[0].Party[0].PartyName?.length) {
        metadataProperties[Metadata.locationName] =
          receiptAdvice.DeliveryCustomerParty[0].Party[0].PartyName[0].Name[0]._;
      }
      // Delivery location address
      if (
        !!receiptAdvice.DeliveryCustomerParty[0].Party[0].PostalAddress?.length
      ) {
        const deliveryAddress: Address | undefined =
          ReceiptAdviceService.buildAddressDetails(
            receiptAdvice.DeliveryCustomerParty[0].Party[0].PostalAddress[0]
          );
        metadataProperties[Metadata.locationAddress] =
          buildAddressAsAString(deliveryAddress);
      }
      // Delivery location GLN
      if (
        !!receiptAdvice.DeliveryCustomerParty[0].Party[0].EndpointID?.length
      ) {
        metadataProperties[Metadata.gln] =
          receiptAdvice.DeliveryCustomerParty[0].Party[0].EndpointID[0]._;
      }
    }

    // currency : TODO remove this TEMPORARY code : taken from the first currency in first line (same as Preview).
    metadataProperties[Metadata.currency] =
      receiptAdvice.ReceiptLine?.[0]?.Item?.[0]?.AdditionalItemProperty?.[0]?.ValueQuantity?.[0]?.unitCode;

    return metadataProperties;
  }

  private async getSupplierDataAndLists(
    locale: string,
    noDefault: boolean = true
  ) {
    const sellerSupplierPartyLists: any = {};
    let sellerSupplierParty: SupplierPartyDetails = {};

    //get the list with all relations (in case of receipt advices => list if suppliers)
    const suppliers: CompanyModel[] = await this.companyService.getRelations(
      DocumentTypeCode.RECADV
    );

    if (suppliers.length > 0) {
      let supplierId;
      if (suppliers.length === 1) {
        supplierId = suppliers[0].identification;
      }

      // DATA

      if (!noDefault || supplierId) {
        sellerSupplierParty = await this.getSellerSupplierParty(supplierId);
      }

      // LISTS

      //supplier code list
      const selectSuppliersData = this.createSelectData(
        suppliers,
        'identification',
        'name',
        true,
        supplierId
      );

      set(
        sellerSupplierPartyLists,
        'ublProperties.ReceiptAdvice[0].SellerSupplierParty[0].Party[0].PartyIdentification[0].ID[0]._',
        selectSuppliersData.choices
      );

      // country list
      const countryId = get(
        sellerSupplierParty,
        'Party[0].PostalAddress[0].Country[0].IdentificationCode[0]._'
      );
      const countrySelectData = this.getCountriesSelectData(countryId, locale);

      setSourceField(
        // selected country code
        sellerSupplierPartyLists,
        `ublProperties.ReceiptAdvice[0].SellerSupplierParty[0].Party[0].PostalAddress[0].Country[0].IdentificationCode[0]._`,
        countrySelectData.choices
      );
    }

    return { sellerSupplierParty, sellerSupplierPartyLists };
  }

  public async getSellerSupplierParty(identification: string) {
    const sellerSupplierParty: SupplierPartyDetails = {};

    const supplier = await this.companyService.getDetails(identification);

    if (!supplier) {
      throw new Error(`No related suppliers were found!`);
    }

    // PartyDetails
    const partyDetails: PartyDetails = {
      PartyIdentification: [{ ID: [{ _: supplier.identification }] }],
      PartyName: [{ Name: [{ _: supplier.name }] }],
    };

    // AddressDetails
    const supplierCompanyAddress: CompanyAddress = supplier.billingAddress;
    if (supplierCompanyAddress) {
      const postalAddress: AddressDetails = this.companyAddressToUBL(
        supplierCompanyAddress
      );

      partyDetails.PostalAddress = [postalAddress];

      // GLN
      if (supplierCompanyAddress?.gln) {
        partyDetails.EndpointID = [{ _: supplierCompanyAddress?.gln }];
      }
    }

    // Trade Register Number
    if (supplier.registrationNumber) {
      partyDetails.PartyLegalEntity = [
        { CompanyID: [{ _: supplier.registrationNumber }] },
      ];
    }

    // SupplierPartyDetails
    sellerSupplierParty.Party = [partyDetails];

    // Retrieves the supplierCode given by the client to the supplier for the Receipt Advice flow.
    const relations = await this.companyService.getRelations(
      DocumentTypeCode.RECADV
    );
    const currentRelation = relations.filter(
      (r) => r.identification === supplier.identification
    )[0];
    sellerSupplierParty.CustomerAssignedAccountID = [
      { _: currentRelation?.supplierCode },
    ];

    return sellerSupplierParty;
  }

  public async createFromDespatchAdvice(nodeId: string): Promise<P2pData> {
    const result: any = await this.documentService.convertTo(
      nodeId,
      DocumentTypeCode.RECADV
    );
    const data = {
      id: result.id,
      properties: result.properties,
      ublProperties: result.ublProperties,
      lines: [],
    };
    this.setupItemOrderConventions(data.ublProperties);
    return data;
  }

  /**
   * Converts backend addresses to UBL addresses
   */
  private companyAddressToUBL(companyAddress: CompanyAddress) {
    const postalAddress: AddressDetails = {};

    postalAddress.BuildingNumber = [
      { _: companyAddress?.buildingNumber ?? EmptyValue },
    ];

    // HOT FIX : TO BE REVISITED ONCE WE HAVE ALL THE SPECS CLEARED
    // V2 DB Address workaround which is take into account on backend side could not fit issuer info.
    // B/E sets name = tb_address.street, street = tb_address.additionalstreet
    let street = companyAddress?.street;
    if (!street) {
      // Meaning that additionalstreet was empty or not defined
      // => reverts the B/E V2 DB workaround
      street = companyAddress?.name;
    }
    postalAddress.StreetName = [{ _: street ?? EmptyValue }];

    if (companyAddress?.additionalStreet) {
      postalAddress.AdditionalStreetName = [
        { _: companyAddress?.additionalStreet },
      ];
    }

    postalAddress.CityName = [{ _: companyAddress?.city ?? EmptyValue }];
    postalAddress.PostalZone = [
      { _: companyAddress?.postalCode ?? EmptyValue },
    ];

    if (companyAddress?.idCountry) {
      postalAddress.Country = [
        {
          IdentificationCode: [{ _: companyAddress?.idCountry }],
        },
      ];
    }

    return postalAddress;
  }

  private async getBuyerCustomerPartyInformation(
    companyDetails: CompanyModel | undefined,
    locale: string
  ) {
    if (!companyDetails) {
      throw new Error('Customer data cannot be empty.');
    }

    const buyerCustomerPartyLists: any = {};

    // PartyDetails
    const partyDetails: PartyDetails = {
      PartyIdentification: [{ ID: [{ _: companyDetails.identification }] }],
      PartyName: [{ Name: [{ _: companyDetails.name }] }],
    };

    // AddressDetails
    const companyAddress = companyDetails.billingAddress;

    if (companyAddress) {
      const postalAddress: AddressDetails =
        this.companyAddressToUBL(companyAddress);

      partyDetails.PostalAddress = [postalAddress];

      if (companyAddress?.gln) {
        partyDetails.EndpointID = [{ _: companyAddress?.gln }];
      }
    }

    if (companyDetails.registrationNumber) {
      partyDetails.PartyLegalEntity = [
        { CompanyID: [{ _: companyDetails.registrationNumber }] },
      ];
    }

    // CustomerPartyDetails
    const buyerCustomerParty: CustomerPartyDetails = {
      Party: [partyDetails],
    };

    // LISTS
    const countrySelectData = this.getCountriesSelectData(
      companyAddress?.idCountry,
      locale
    );

    setSourceField(
      // selected country code
      buyerCustomerPartyLists,
      `ublProperties.ReceiptAdvice[0].BuyerCustomerParty[0].Party[0].PostalAddress[0].Country[0].IdentificationCode[0]._`,
      countrySelectData.choices
    );

    return {
      buyerCustomerParty,
      buyerCustomerPartyLists,
    };
  }

  private async getDeliveryInformation(
    companyDetails: CompanyModel,
    locale: string,
    noAlternativeDefault: boolean = true
  ) {
    const deliveryCustomerPartyLists: any = {};

    // PartyDetails
    const partyDetails: PartyDetails = {
      PartyIdentification: [{ ID: [{ _: companyDetails.identification }] }],
    };

    // AddressDetails
    const address =
      noAlternativeDefault && companyDetails.shippingAddress.length > 1
        ? undefined
        : first(companyDetails.shippingAddress);

    if (address) {
      const postalAddress: AddressDetails = this.companyAddressToUBL(address);
      if (address?.name) {
        partyDetails.PartyName = [{ Name: [{ _: address?.name }] }];
      }

      if (address?.gln) {
        partyDetails.EndpointID = [{ _: address?.gln }];
      }

      partyDetails.PostalAddress = [postalAddress];
    }

    // CustomerPartyDetails
    const deliveryCustomerParty: CustomerPartyDetails = {
      Party: [partyDetails],
    };

    // country
    const countrySelectData = this.getCountriesSelectData(
      address?.idCountry,
      locale
    );

    setSourceField(
      deliveryCustomerPartyLists,
      `ublProperties.ReceiptAdvice[0].DeliveryCustomerParty[0].Party[0].PostalAddress[0].Country[0].IdentificationCode[0]._`,
      countrySelectData.choices
    );
    //delivery locations
    const deliverySelectData = this.createSelectData(
      companyDetails.shippingAddress ?? [],
      'gln',
      'name',
      true,
      address?.gln
    );

    // LISTS
    setSourceField(
      deliveryCustomerPartyLists,
      `ublProperties.ReceiptAdvice[0].DeliveryCustomerParty[0].Party[0].EndpointID[0]._`,
      deliverySelectData.choices
    );

    return { deliveryCustomerParty, deliveryCustomerPartyLists };
  }

  private getRecAdvSubtypeCode(metadata: AlfrescoProperties) {
    const val = DataHelpers.getDocumentSubtypeCode(metadata);
    const documentSubTypeCode: ReceiptAdviceSubType =
      Object.values(ReceiptAdviceSubType).find((v) => val === v) ??
      ReceiptAdviceSubType.GoodsReturnAdvice;
    return documentSubTypeCode;
  }

  private nsResolver = (prefix: any) => {
    const ns: any = {
      dx: 'urn:com:docprocess:nir:model:specification:schema:xsd:ReceiptAdviceExtension-1',
      cbc: 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2',
      cac: 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2',
    };
    return ns[prefix] || null;
  };

  private static receiptAdviceHeader = {
    _D: 'urn:oasis:names:specification:ubl:schema:xsd:ReceiptAdvice-2',
    _A: 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2',
    _B: 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2',
  };
}
