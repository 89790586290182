import { IconButton, Tooltip } from '@material-ui/core';
import ShowIcon from '@material-ui/icons/Description';
import { linkToRecord, ReduxState } from 'ra-core';
import React from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const RelatedDocsButton = React.forwardRef<any, any>((props, ref) => {
  const {
    basePath = '',
    record = {},
    className,
    isMenuItem,
    resource,
    ...rest
  } = props;

  const translate = useTranslate();
  const open = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );

  return isMenuItem ? (
    <MenuItemLink
      to={{
        pathname: `${linkToRecord(basePath, record.id)}/related-documents`,
      }}
      primaryText={translate('dxMessages.buttons.related_documents')}
      sidebarIsOpen={open}
    />
  ) : (
    <Tooltip title={translate('dxMessages.buttons.related_documents')}>
      <IconButton
        color='primary'
        component={Link}
        to={{
          pathname: `${linkToRecord(basePath, record.id)}/related-documents`,
        }}
        className={className}
        {...rest}
      >
        <ShowIcon />
      </IconButton>
    </Tooltip>
  );
});

export default RelatedDocsButton;
