/**
 * The dashboard widget.
 *
 * It's a decorator around the true content of the widget.
 * The true content is the widget children.
 *
 * KEEP IN MIND that the content size gets computed by the dashboard layouting
 * so the widget children MUST ALWAYS BE RESPONSIVE.
 *
 * The decoration differs depending whether the widget is on the dashboard
 * or into the configuration panel.
 *
 * When on the dashboard, and when the configuration drawer is open, it adds
 * a close button to the content so user can remove it from the dashboard and
 * put it back into the configuration panel by clicking the button.
 *
 * When into the configuration drawer, it adds a grip handle so user can
 * drag'n'drop it on the dashboard.
 */
import { Account } from '@dx-ui/dx-common/src/configuration/types';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import React, { FC } from 'react';

export interface IWidget {
  // A flag indicating whether the widget is 'on-screen' (displayed in the dashboard)
  // or 'on the shelves', that is not on screen but appearing in the configuration
  // drawer, ready to be drop into the dashboard by the user.
  onTheShelves: boolean;
  // Tell whether there can be more than one instance of this widget on the dashboard.
  singleton: boolean;
  // If the widget is closeable (that is: removeable out from the dashboard),
  // callback to call when closing the widget.
  close?: () => void;
  // The true content of the widget. KEEP IN MIND that the content size gets computed by
  // the dashboard layouting.
  children?: React.ReactNode;
}

export interface IWidgetContent {
  // A flag indicating whether the widget is 'on-screen' (displayed in the dashboard)
  // or 'on the shelves', that is not on screen but appearing in the configuration
  // drawer, ready to be drop into the dashboard by the user.
  onTheShelves: boolean;
  // The dashboard owner.
  account: Account;
  // The key in the user preferences where the widget can store its state if any.
  userPreferencesRootKey: string;
  // A callback so the widget can update its size (grid-layout dimensions, see
  // Widgets.tsx)
  updateSize: (w: number, h: number) => void;
  // A callback to reset the layout to the default one
  resetDashboardToDefault?: () => void;
  // The drawer is open or not
  openConfiguration: boolean;
}

const Widget: FC<IWidget> = ({ close, children, onTheShelves, singleton }) => {
  const classes = useStyles();

  // When into the configuration drawer, put a grip handle on the left side
  // to make the widget drag'n'droppable.
  if (onTheShelves)
    return (
      // Use a relative position to set the grip handle and the close icon
      // in the corners.
      <div style={{ position: 'relative', cursor: 'grab' }}>
        {/* The grip to drag the recadv up and down. */}
        <div
          style={{
            position: 'absolute',
            // Full height minus some pixels at the top and bottom.
            top: '4px',
            height: 'calc(100% - 12px)',
            left: '0px',
            padding: '4px',
            // Must be over the z-index of the flipping card.
            zIndex: 10,
          }}
        >
          <div className={classes.grip} />
        </div>
        {!singleton && <PlusOneIcon className={classes.plus} />}
        {children}
      </div>
    );

  // When on the dashboard, add a close button if the widget is closeable
  // so user can put it back into the configuration drawer.
  return (
    // Use a relative position for the close icon in the corners.
    <div style={{ position: 'relative' }}>
      {close && (
        <IconButton
          onClick={close}
          color='primary'
          size='small'
          className={classes.close}
        >
          <CancelIcon />
        </IconButton>
      )}
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  // The grip handle on the left border of the widget.
  grip: {
    background:
      'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjODg4Ij48L3JlY3Q+Cjwvc3ZnPg==")',
    width: '6px',
    height: '100%',
  },
  // The plus icon on the top right side of the widget when in the drawer and
  // meaning you can drag more than one widget of this type on the dashboard.
  plus: {
    color: theme.palette.common.white,
    position: 'absolute',
    top: '3px',
    right: '5px',
    // Must be over the z-index of the flipping card.
    zIndex: 10,
    width: '18px',
    height: '18px',
    backgroundColor: theme.palette.success.main,
    borderRadius: '50%',
    padding: '2px',
    boxShadow: `1px 1px 3px 0px ${theme.palette.grey[500]}`,
  },
  // The close button on the top right side of the widget when  on the dashboard.
  close: {
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    // Must be over the z-index of the flipping card.
    zIndex: 10,
  },
}));
export interface WidgetConfiguration {
  // The category of the widget.
  // The widget expects the i18n key: dxMessages.dashboard.categories.<category>
  category: string;
  // The widget content.
  content: FC<IWidgetContent>;
  // Whether the widget is closeable, that is, when on screen, can be removed
  // by the user out from the dashboard and go back into the configuration drawer.
  closeable: boolean;
  // Only one instance of the widget on the dashboard?
  singleton: boolean;
  // The size of the widget, in column count for the width (12-column grid) and
  // line count for the height.
  size: { w: number; h: number };
  // Checks whether the widget is allowed for the account.
  isAllowedFor: (account: Account) => boolean;
}

export type WidgetKey = string;

export default Widget;
