import {
  DocumentTypeCode,
  DxTheme,
  EmptyValue,
  GenericLabeledDateField,
  SimpleLabeledField,
} from '@dx-ui/dx-common';
import { Grid, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { get } from 'lodash';
import { useTranslate } from 'react-admin';
import { P2pPreviewData } from '../../shared/types';
import { CommaSeparator } from '../../shared/webForms/utils';
import WayBillPartyTile from './component/WayBillPartyTile';
import WayBillTransport from './component/WayBillTransport';
import { IWayBillSources } from './WayBillSources';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    reference: {
      paddingLeft: '1em',
    },
    desAdvRef: {
      paddingLeft: '1.5em',
    },
    note: {
      backgroundColor: theme.palette.grey[100],
      paddingLeft: '1em',
    },
    noteText: {
      paddingLeft: '1em',
    },
    titleSection: {
      padding: '2em 0 4em 0',
      textAlign: 'center',
    },
    section: {
      padding: '0 0 1em 0',
    },
    transportCostRow: {
      padding: '0 2em 0 0',
    },
    transportCost: {
      textAlign: 'right',
    },
    referenceInvoiceDate: {
      paddingLeft: 8,
    },
    despatchAdviceDate: {
      paddingLeft: 8,
    },
  }),
  { name: 'WayBillPreviewView' }
);

interface PropsModel {
  record: P2pPreviewData;
  dataSources: IWayBillSources;
}
const WayBillPreviewView = (props: PropsModel): JSX.Element => {
  const { record, dataSources } = props;
  const classes = useStyles(props);
  const translate = useTranslate();
  const ubl = record.ublProperties;

  // Build all party tiles
  let tiles: { index: number; component: JSX.Element }[] = [];
  Object.values(dataSources.WayBillPartySources).forEach((tile: any, index) => {
    let party = get(ubl, tile.source);
    if (party) {
      tiles.push({
        index: index,
        component: (
          <WayBillPartyTile
            record={party}
            title={tile.title}
            source={tile.source}
            key={index}
            dataSources={dataSources}
          />
        ),
      });
    }
  });

  // Search for document references
  let invoiceReference: any = undefined;
  let despatchAdviceReference: any = undefined;
  const documentReferences = get(
    ubl,
    dataSources.WayBillRootSources.documentReferences
  );
  if (documentReferences) {
    for (const value of documentReferences.values()) {
      switch (
        get(value, dataSources.DocumentReferenceSources.documentTypeCode)
      ) {
        case DocumentTypeCode.INVOIC:
          invoiceReference = value;
          break;
        case DocumentTypeCode.DESADV:
          despatchAdviceReference = value;
          break;
        default:
          break;
      }
    }
  }

  const note = get(ubl, dataSources.WayBillRootSources.note);
  return (
    <Grid container direction='column'>
      <Grid item classes={{ item: classes.section }}>
        <Grid container justify='center'>
          <Grid item className={classes.titleSection}>
            <Typography variant='h6' style={{ fontSize: '2em' }}>
              {translate('dxMessages.waybill.document_label')}
            </Typography>
            <Typography variant='subtitle1'>
              {translate('dxMessages.waybill.document_sublabel')}
            </Typography>
          </Grid>
        </Grid>

        {/** HEADER **/}

        <Grid container classes={{ container: classes.section }}>
          <Grid item xs={3} className={classes.reference}>
            <SimpleLabeledField
              label={translate('dxMessages.waybill.number')}
              data={get(ubl, dataSources.WayBillRootSources.number)}
              className='documentId'
            />
          </Grid>
          <Grid item xs={3}>
            <GenericLabeledDateField
              label={translate('dxMessages.headers.issueDate')}
              id={dataSources.WayBillRootSources.issueDate}
              record={ubl}
              className='issueDate'
            />
          </Grid>
        </Grid>

        {/* Refered invoice */}
        <Grid container direction='row' style={{ display: 'inline-flex' }}>
          <Grid
            item
            xs={6}
            style={{ display: 'inline-flex' }}
            className={classes.reference}
          >
            <SimpleLabeledField
              label={translate('dxMessages.headers.referenceInvoiceId')}
              data={get(
                invoiceReference,
                dataSources.DocumentReferenceSources.number
              )}
              oneLine
              className='referenceInvoiceId'
            />
            <CommaSeparator />
            <span className={classes.referenceInvoiceDate}>
              <GenericLabeledDateField
                label={translate('dxMessages.headers.referenceInvoiceDate')}
                id={dataSources.DocumentReferenceSources.issueDate}
                record={invoiceReference}
                oneLine
                className='referenceInvoiceDate'
              />
            </span>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'inline-flex' }}
            className={classes.desAdvRef}
          >
            <SimpleLabeledField
              label={translate('dxMessages.headers.despatchAdviceId')}
              data={get(
                despatchAdviceReference,
                dataSources.DocumentReferenceSources.number
              )}
              oneLine
              className='despatchAdviceId'
            />
            <CommaSeparator />
            <span className={classes.despatchAdviceDate}>
              <GenericLabeledDateField
                label={translate('dxMessages.headers.despatchAdviceDate')}
                id={dataSources.DocumentReferenceSources.issueDate}
                record={despatchAdviceReference}
                oneLine
                className='despatchAdviceDate'
              />
            </span>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'inline-flex' }}
            className={classes.reference}
          >
            <SimpleLabeledField
              label={translate('dxMessages.headers.orderId')}
              data={get(ubl, dataSources.WayBillRootSources.orderReferenceId)}
              oneLine
              className='orderReferenceId'
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.section}>
        <div className={classes.note}>
          <Typography variant='caption'>
            {translate('dxMessages.waybill.note')}
          </Typography>
          <div className={classNames(classes.noteText, 'note')}>
            {note ? <Typography>{note}</Typography> : EmptyValue}
          </div>
        </div>
      </Grid>

      <Grid item xs={12} className={classes.section}>
        <Grid container spacing={2} direction='row'>
          {tiles.map((tile) => (
            <Grid item xs={6} key={tile.index}>
              {tile.component}
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Horizontal separator */}
      <Grid item className={classes.section}>
        <Divider />
      </Grid>

      <Grid item className={classes.section}>
        <WayBillTransport
          record={get(
            ubl,
            dataSources.WayBillRootSources.shipmentInformation.source
          )}
          dataSources={dataSources}
        />
        <Divider />
      </Grid>
    </Grid>
  );
};

export default WayBillPreviewView;
