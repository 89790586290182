import moment from 'moment';
import xlsx from 'node-xlsx';
import { unparse as convertToCSV } from 'papaparse';
import { downloadCSV } from 'ra-core';
import { CustomAttributeTypes } from '..';
import { Metadata, RegulatorExtraDetailsType } from '../constants/Constants';
import { DomFileDownloadService } from '../services/DomFileDownloadService';

export const ExportFileTypes = { XLS: 'xls', CSV: 'csv' };

const metadataTypeDate = [
  Metadata.issueDate,
  Metadata.despatchAdviceDate,
  Metadata.receiptAdviceDate,
  Metadata.contractDate,
  Metadata.orderDate,
  Metadata.archiveDate,
  Metadata.created,
  Metadata.createdAt,
  Metadata.modified,
  Metadata.modifiedAt,
  Metadata.deliveryDate,
];

const isDate = (colId, value) => {
  if (metadataTypeDate.indexOf(colId) === -1) {
    return false;
  }
  return (
    typeof value === 'string' && moment(value, moment.ISO_8601, true).isValid()
  );
};

const formatDate = (value) => {
  return moment(value).format('DD MMMM YYYY');
};

/**
 * Creates and Downloads the XLSX or CSV file corresponding to the actual list filtered view.
 * @param {object} records
 * @param {object} columns
 * @param {string} fileType "xls" => xlsx file, "csv" => csv file
 * @param {func} translate
 */
export const exporter = (records, columns, fileType, translate) => {
  const columnsToExport = columns.filter((col) => col.nonExportable !== true);

  const fields = columnsToExport.map((col) => {
    if (col.id.indexOf('am:') !== -1) {
      return translate(col.label, { _: col.label });
    } else {
      return typeof col.label === 'string'
        ? translate(col.label, { _: col.id })
        : translate(col.label(), { _: col.id });
    }
  });
  let data: any[] = [];

  switch (fileType) {
    case ExportFileTypes.CSV:
      records.forEach((record) => {
        let values: any = {};
        columnsToExport.forEach((col) => {
          let value;
          if (col.id.indexOf('edm:') !== -1 || col.id.indexOf('am:') !== -1) {
            value = record.properties[col.id];
          } else {
            value = record[col.id];
          }
          if (
            isDate(col.id, value) ||
            col.attributeType === CustomAttributeTypes.DATE
          ) {
            value = formatDate(value);
          }
          // Format Fiscal Authority data with human readable values
          if (col.id.indexOf('edm:regulatorExtraDetails') !== -1) {
            if (value === RegulatorExtraDetailsType.ANAF_ONLY) {
              value = translate(
                'dxMessages.regulatorExtraDetails.efacturaOnly',
                { _: value }
              );
            } else if (value === RegulatorExtraDetailsType.PEPPOL) {
              value = translate('PEPPOL', { _: value });
            }
          }
          if (col.id.indexOf('edm:regulatorStatusCode') !== -1) {
            value = translate(`dxMessages.regulatorStatusCode.${value}`, {
              _: '--',
            });
          }
          values[col.id] = value;
        });
        data.push(values);
      });
      const csv = convertToCSV({
        data,
        fields: fields,
      });
      downloadCSV(csv, 'documents');
      break;
    case ExportFileTypes.XLS:
      records.forEach((record) => {
        let values: any[] = [];
        columnsToExport.forEach((col) => {
          let value: any;
          if (col.id.indexOf('edm:') !== -1 || col.id.indexOf('am:') !== -1) {
            value = record.properties[col.id];
          } else {
            value = record[col.id];
          }
          if (
            isDate(col.id, value) ||
            col.attributeType === CustomAttributeTypes.DATE
          ) {
            value = formatDate(value);
          }
          // Format Fiscal Authority data with human readable values
          if (col.id.indexOf('edm:regulatorExtraDetails') !== -1) {
            if (value === RegulatorExtraDetailsType.ANAF_ONLY) {
              value = translate(
                'dxMessages.regulatorExtraDetails.efacturaOnly',
                { _: value }
              );
            } else if (value === RegulatorExtraDetailsType.PEPPOL) {
              value = translate('PEPPOL', { _: value });
            }
          }
          if (col.id.indexOf('edm:regulatorStatusCode') !== -1) {
            value = translate(`dxMessages.regulatorStatusCode.${value}`, {
              _: '--',
            });
          }
          values.push(value);
        });
        data.push(values);
      });
      const dataToConvert = [[...fields], ...data];
      const xls = xlsx.build([{ name: 'metadata', data: dataToConvert }]);
      downloadXLSX(xls, 'documents.xlsx');
      break;
    default:
  }
};

/**
 * Downloads a xlsx file using a fake link
 * @param {*} xls file content
 * @param {*} filename file name (must contain the .xls suffix)
 */
export const downloadXLSX = (xls, filename) => {
  const domFileDownloadService = new DomFileDownloadService();
  domFileDownloadService.downloadFile(xls, filename);
};

/**
 * Updates the list of visible columns with those which are implicitely displayed.
 * ex: edm:issuerName + edm:issuerId
 * @param {*} visibleColumns columns displayed
 * @param {*} columns available columns
 */
export const manageExportComplexFields = (
  visibleColumns: any,
  columns: any
) => {
  let newVisibleColumns: any[] = [];
  visibleColumns.forEach((col: any) => {
    // Add the level 1 col
    if (col.id !== Metadata.document) {
      newVisibleColumns.push(col);
    }

    // Add the level 2
    if (
      col.id === Metadata.document &&
      visibleColumns.filter((c) => c.id === Metadata.documentTypeCode)
        .length === 0
    ) {
      // add edm:documentTypeCode if not already visible
      newVisibleColumns.push(
        columns.filter((c: any) => c.id === Metadata.documentTypeCode)[0]
      );
    }

    if (
      col.id === Metadata.document &&
      visibleColumns.filter((c) => c.id === Metadata.documentId).length === 0
    ) {
      // add edm:documentId if not already visible
      newVisibleColumns.push(
        columns.filter((c) => c.id === Metadata.documentId)[0]
      );
    }

    if (
      col.id === Metadata.issuerName &&
      visibleColumns.filter((c) => c.id === Metadata.issuerId).length === 0
    ) {
      // add edm:issuerId if not already visible
      newVisibleColumns.push(
        columns.filter((c) => c.id === Metadata.issuerId)[0]
      );
    }
    if (
      col.id === Metadata.recipientName &&
      visibleColumns.filter((c) => c.id === Metadata.recipientId).length === 0
    ) {
      // add edm:recipientId  if not already visible
      newVisibleColumns.push(
        columns.filter((c) => c.id === Metadata.recipientId)[0]
      );
    }
    if (
      col.id === Metadata.processStatus &&
      visibleColumns.filter((c) => c.id === Metadata.modifiedAt).length === 0
    ) {
      // add modifiedAt if not already visible
      newVisibleColumns.push(
        columns.filter((c) => c.id === Metadata.modifiedAt)[0]
      );
    }
    if (
      col.id === Metadata.locationAddress &&
      visibleColumns.filter((c) => c.id === Metadata.locationName).length === 0
    ) {
      // add edm:name if not already visible
      newVisibleColumns.push(
        columns.filter((c) => c.id === Metadata.locationName)[0]
      );
    }
  });
  // removes undefined entries.
  newVisibleColumns = newVisibleColumns.filter((item) => item);
  return [...newVisibleColumns];
};
