import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import JSONPretty from 'react-json-pretty';

export const AppColors = withStyles(
  {},
  { withTheme: true }
)((props: any) => (
  <div>
    <section>
      <h1>Colors</h1>
    </section>
    <div>
      <h2>colors</h2>
      {Object.keys(props.theme.colors).map((c) => (
        <div
          key={c}
          style={{ backgroundColor: props.theme.colors[c], height: 20 }}
        >
          {' '}
          colors.{c}{' '}
        </div>
      ))}
      <h2>primary</h2>
      {[0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        .map<any>((x) => x * 100)
        .concat('A100', 'A200', 'A400', 'A700', 'contrastDefaultColor')
        .map((c) => (
          <div
            key={c}
            style={{
              backgroundColor: props.theme.colors.primary[c],
              height: 20,
            }}
          >
            {' '}
            colors.primary[{c}]{' '}
          </div>
        ))}
      <h2>secondary</h2>
      {[0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        .map<any>((x) => x * 100)
        .concat('A100', 'A200', 'A400', 'A700', 'contrastDefaultColor')
        .map((c) => (
          <div
            key={c}
            style={{
              backgroundColor: props.theme.colors.secondary[c],
              height: 20,
            }}
          >
            {' '}
            colors.secondary[{c}]{' '}
          </div>
        ))}
    </div>
    <section>
      <h1>Theme</h1>
      <JSONPretty id='json-pretty' data={props.theme}></JSONPretty>
    </section>
  </div>
));
