import { IconButton, Menu, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { colors } from '../../constants/Colors';
import { DxTheme } from '../../types';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    showIcon: {
      borderRadius: '50%',
      borderColor: colors.mainColor5,
      background: colors.mainColor5,
      color: 'white',
      '-moz-transform': 'scale(0.8)', // For Firefox
    },
    paper: {
      borderRadius: 30,
      background: theme.app.tableBkgColor,
      boxShadow:
        '0px 1px 2px rgba(0, 0, 0, 0.24), 0px 1px 3px rgba(0, 0, 0, 0.12)',
      // Remove Firefox/safari focus border on menu items (icons or texts)
      '& :focus': {
        outline: 'none',
      },
    },
  }),
  { name: 'MoreButton' }
);

const MoreButton = React.forwardRef<any, any>((props, ref) => {
  const { title, className, children, menuItems = false }: any = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles(props);
  const translate = useTranslate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let childrenArray: any[] = [];
  if (Array.isArray(children)) {
    childrenArray = children;
  } else {
    childrenArray.push(children);
  }

  return (
    <span>
      <Tooltip title={translate(title)}>
        <IconButton
          aria-label='More'
          aria-owns={open ? 'more-menu' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
          color='primary'
          style={{ visibility: open ? 'visible' : undefined }}
          className={className}
        >
          <MoreIcon className={classes.showIcon} />
        </IconButton>
      </Tooltip>
      <Menu
        id='more-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        getContentAnchorEl={null}
        classes={{ paper: menuItems ? undefined : classes.paper }} // Apply specific paper style while items are icons
      >
        {childrenArray.map((child, index) => {
          if ('object' === typeof child) {
            return React.cloneElement(child, {
              key: index,
              onClose: handleClose,
              isMenuItem: menuItems, // force rendering either icons or menu items
            });
          } else {
            return null;
          }
        })}
      </Menu>
    </span>
  );
});

export default MoreButton;
