import {
  GenericDateField,
  GenericLabeledDateField,
  Metadata,
  PriceTextField,
  SimpleLabeledField,
  Ubl,
} from '@dx-ui/dx-common';
import {
  OrderDetails,
  OrderLineDetails,
} from '@dx-ui/lib-oasis-ubl-2.1/src/OrderModel';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cloneDeep, get, set } from 'lodash';
import { tz } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Loading,
  TextField,
  useDataProvider,
  useLocale,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import {
  AlfrescoDocumentService,
  DataHelpers,
  DocumentServiceFactory,
  OrderService,
  OrderServiceCreator,
} from '../../services';
import { AlfrescoContent, P2pData, P2pPreviewData } from '../../shared/types';
import TaxSummaryPanel from '../../shared/webForms/fields/TaxSummaryPanel';
import TaxTotals from '../../shared/webForms/fields/TaxTotals';
import { P2pUtils } from '../common/P2pUtils';
import {
  AddressField,
  AddressParts,
  getAccountingCustomerAddress,
  getBuyerCustomerAddress,
  getDeliveryLocationAddress,
  getSellerSupplierAddress,
} from '../common/PreviewAddress';
import { useCellStyle, useCommonPreviewStyles } from '../common/PreviewStyles';

export const useStyles = makeStyles(
  (theme) => ({
    contactItem: { paddingRight: theme.spacing(2) },
  }),
  { name: 'OrderPreview' }
);

const Lines = ({
  record,
  linesView,
  loading,
  currencyID,
  lineIds,
  resource,
  isUbl21,
}) => {
  const translate = useTranslate();
  const classes = useCommonPreviewStyles();
  const cellStyleClasses = useCellStyle();
  const locale = useLocale();

  // Research in each lines if there is an existing delivery start date
  let withDate: boolean = false;
  withDate = lineIds.some((lineId, index) =>
    isUbl21
      ? (linesView[lineIds[index]] as OrderLineDetails).LineItem[0]
          .Delivery?.[0]?.RequestedDeliveryPeriod?.[0]?.StartDate?.[0]?._
      : get(linesView[lineIds[index]], Ubl.lineDeliveryStartDate) !== undefined
  );
  let hasActualDeliveryDate: boolean = false;
  hasActualDeliveryDate = lineIds.some((lineId, index) =>
    isUbl21
      ? (linesView[lineIds[index]] as OrderLineDetails).LineItem[0]
          .Delivery?.[0]?.ActualDeliveryDate?.[0]?._
      : get(linesView[lineIds[index]], Ubl.lineActualDeliveryDate) !== undefined
  );

  return (
    <Grid container direction='column' classes={{ container: classes.section }}>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.headers.currency')}
          data={currencyID}
          oneLine
          className={Metadata.currency}
        />
      </Grid>
      <Grid item>
        {!loading ? (
          <ListContextProvider
            value={{
              ids: lineIds,
              data: linesView,
              currentSort: {
                field: isUbl21 ? 'LineItem[0].ID[0]._' : 'id.value',
                order: 'ASC',
              },
              resource,
              selectedIds: [],
            }}
          >
            <Datagrid
              classes={{
                row: classes.row,
                rowCell: classes.linesCell,
                headerCell: classes.headerCell,
                tbody: classes.tbody,
              }}
            >
              <TextField
                source={isUbl21 ? 'LineItem[0].ID[0]._' : 'id.value'}
                label='dxMessages.headers.number'
                sortable={false}
                key='number'
              />
              <TextField
                source={
                  isUbl21
                    ? 'LineItem[0].Item[0].StandardItemIdentification[0].ID[0]._'
                    : Ubl.codeStandard
                }
                label='dxMessages.headers.codeStandard'
                sortable={false}
                key='codeStandard'
                style={{ minWidth: '8em' }}
              />
              <TextField
                source={
                  isUbl21
                    ? 'LineItem[0].Item[0].Description[0]._'
                    : Ubl.description
                }
                label='dxMessages.headers.description'
                sortable={false}
                key='product'
                classes={{ root: cellStyleClasses.cellWrapped }}
                style={{ minWidth: '8em' }}
              />
              <TextField
                source={
                  isUbl21
                    ? 'LineItem[0].Item[0].SellersItemIdentification[0].ID[0]._'
                    : Ubl.codeSupplier
                }
                label='dxMessages.headers.codeSupplier'
                sortable={false}
                key='codeSupplier'
                style={{ minWidth: '8em' }}
              />
              <TextField
                source={
                  isUbl21
                    ? 'LineItem[0].Item[0].BuyersItemIdentification[0].ID[0]._'
                    : Ubl.codeClient
                }
                label='dxMessages.headers.codeClient'
                sortable={false}
                key='codeCLient'
                style={{ minWidth: '8em' }}
              />
              {withDate && (
                <DeliveryRequestPeriodColumn
                  label='dxMessages.headers.deliveryDate'
                  record={record}
                  locale={locale}
                  key='deliveryDate'
                  isUbl21={isUbl21}
                />
              )}
              {hasActualDeliveryDate && (
                <DeliveryActualDeliveryDateColumn
                  label='dxMessages.headers.actualDeliveryDate'
                  record={record}
                  locale={locale}
                  key='actualDeliveryDate'
                  isUbl21={isUbl21}
                />
              )}
              <TextField
                source={
                  isUbl21 ? 'LineItem[0].Quantity[0]._' : Ubl.quantityOrder
                }
                label='dxMessages.headers.quantity'
                sortable={false}
                key='quantity'
              />
              <TextField
                source={
                  isUbl21
                    ? 'LineItem[0].Quantity[0].unitCode'
                    : Ubl.unitCodeOrder
                }
                label='dxMessages.headers.um'
                sortable={false}
                key='um'
              />
              <PriceTextField
                headerClassName='alignRight'
                cellClassName='alignRight'
                source={
                  isUbl21 ? 'LineItem[0].Price[0].PriceAmount[0]._' : Ubl.price
                }
                label='dxMessages.headers.price'
                record={record}
                sortable={false}
                key='price'
                style={{ textAlign: 'right' }}
              />
              <FunctionField
                source={
                  isUbl21
                    ? 'LineItem[0].TaxTotal[0].TaxSubtotal[0].Percent[0]._'
                    : Ubl.vatPercentage
                }
                label='dxMessages.headers.vat'
                key='vat'
                sortable={false}
                render={(record) => {
                  const vatPercentage = isUbl21
                    ? (record as OrderLineDetails)?.LineItem?.[0]?.TaxTotal?.[0]
                        ?.TaxSubtotal?.[0]?.Percent?.[0]?._
                    : get(record, Ubl.vatPercentage);
                  return isNaN(vatPercentage) ? (
                    <Typography>{'--'}</Typography>
                  ) : (
                    <Typography>
                      {vatPercentage} {'%'}
                    </Typography>
                  );
                }}
                style={{ minWidth: '3em' }}
              />
              <PriceTextField
                headerClassName='alignRight'
                cellClassName='alignRight'
                source={
                  isUbl21
                    ? 'LineItem[0].TaxTotal[0].TaxSubtotal[0].TaxableAmount[0]._'
                    : Ubl.totalWithoutVat
                }
                label='dxMessages.headers.totalWithoutVat'
                record={record}
                sortable={false}
                key='totalWithoutVat'
                style={{ textAlign: 'right' }}
              />
              <PriceTextField
                headerClassName='alignRight'
                cellClassName='alignRight'
                source={
                  isUbl21
                    ? 'LineItem[0].TaxTotal[0].TaxSubtotal[0].TaxAmount[0]._'
                    : Ubl.totalVat
                }
                label='dxMessages.headers.totalVat'
                record={record}
                sortable={false}
                key='totalVat'
                style={{ textAlign: 'right' }}
              />
            </Datagrid>
          </ListContextProvider>
        ) : (
          <CircularProgress
            size={48}
            classes={{ root: classes.circularProgress }}
          />
        )}
      </Grid>
    </Grid>
  );
};

/**
 * Displays the delivery date with unique date or period
 */
const DeliveryRequestPeriod = ({ startDate, endDate, record, isUbl21 }) => {
  const translate = useTranslate();
  const locale = useLocale();
  if (endDate && startDate === endDate) {
    return (
      <Grid item>
        <GenericLabeledDateField
          label={translate('dxMessages.headers.deliveryDate')}
          tz={tz}
          id={
            isUbl21
              ? 'ublProperties.Order[0].Delivery[0].RequestedDeliveryPeriod[0].StartDate[0]._'
              : Ubl.deliveryRequestStartDate
          }
          record={record}
          className={'deliveryRequestStartDate'}
        />
      </Grid>
    );
  } else {
    return (
      <Grid container direction='row' style={{ alignItems: 'center ' }}>
        <Grid item>
          <GenericLabeledDateField
            label={translate('dxMessages.headers.deliveryDate')}
            tz={tz}
            id={
              isUbl21
                ? 'ublProperties.Order[0].Delivery[0].RequestedDeliveryPeriod[0].StartDate[0]._'
                : Ubl.deliveryRequestStartDate
            }
            record={record}
            className={'deliveryRequestStartDate'}
          />
        </Grid>
        <Grid
          container
          direction='row'
          style={{ marginTop: '1em', width: 'unset' }}
        >
          <Typography
            variant='caption'
            style={{ marginLeft: 2, marginRight: 2, marginTop: 2 }}
          >
            -
          </Typography>
          <GenericDateField
            locale={locale}
            tz={tz}
            id={
              isUbl21
                ? 'ublProperties.Order[0].Delivery[0].RequestedDeliveryPeriod[0].EndDate[0]._'
                : Ubl.deliveryRequestEndDate
            }
            record={record}
            oneLine
            className={'deliveryRequestEndDate'}
          />
        </Grid>
      </Grid>
    );
  }
};

const DeliveryRequestPeriodColumn = ({ label, locale, record, isUbl21 }) => {
  // Builds the Delivey requested period taken into account all the delivery instances
  const deliveryNode = isUbl21
    ? get(record, 'Delivery')
    : get(record, 'delivery');

  const sortedStartDate = deliveryNode
    ?.map((d) =>
      isUbl21
        ? get(d, 'RequestedDeliveryPeriod[0].StartDate[0]._')
        : get(d, 'requestedDeliveryPeriod.startDate.value')
    )
    .sort();

  const sortedEndDate = deliveryNode
    ?.map((d) =>
      isUbl21
        ? get(d, 'RequestedDeliveryPeriod[0].EndDate[0]._')
        : get(d, 'requestedDeliveryPeriod.endDate.value')
    )
    .sort();

  let startDate = sortedStartDate?.[0];
  let endDate = sortedEndDate?.[0];
  const data = {
    startDate: startDate,
    endDate: endDate ? endDate : startDate,
  };
  if (
    (data.startDate === undefined && data.endDate === undefined) ||
    (data.endDate && data.startDate === data.endDate)
  ) {
    return (
      <Grid item>
        <GenericDateField
          locale={locale}
          label={label}
          tz={tz}
          id={'startDate'}
          record={data}
        />
      </Grid>
    );
  } else {
    return (
      <Grid container direction='column' style={{ alignItems: 'center ' }}>
        <Grid item>
          <GenericDateField
            locale={locale}
            label={label}
            tz={tz}
            id={'startDate'}
            record={data}
          />
        </Grid>
        <Grid item>
          <Typography variant='caption'>-</Typography>
        </Grid>
        <Grid item>
          <GenericDateField
            locale={locale}
            tz={tz}
            id={'endDate'}
            record={data}
            oneLine
          />
        </Grid>
      </Grid>
    );
  }
};

const DeliveryActualDeliveryDateColumn = ({
  label,
  locale,
  record,
  isUbl21,
}) => {
  // Builds the actual Delivey date taken into account all the delivery instances
  const deliveryNode = isUbl21
    ? get(record, 'Delivery')
    : get(record, 'delivery');

  const sortedActualDate = deliveryNode
    ?.map((d) =>
      isUbl21
        ? get(d, 'ActualDeliveryDate[0]._')
        : get(d, 'actualDeliveryDate.value')
    )
    .sort();

  let startDate = sortedActualDate?.[0];
  let endDate = sortedActualDate?.[sortedActualDate.length - 1];
  const data = {
    startDate: startDate,
    endDate: endDate ? endDate : startDate,
  };
  if (
    (data.startDate === undefined && data.endDate === undefined) ||
    (data.endDate && data.startDate === data.endDate)
  ) {
    return (
      <Grid item>
        <GenericDateField
          locale={locale}
          label={label}
          tz={tz}
          id={'startDate'}
          record={data}
        />
      </Grid>
    );
  } else {
    return (
      <Grid container direction='column' style={{ alignItems: 'center ' }}>
        <Grid item>
          <GenericDateField
            locale={locale}
            label={label}
            tz={tz}
            id={'startDate'}
            record={data}
          />
        </Grid>
        <Grid item>
          <Typography variant='caption'>-</Typography>
        </Grid>
        <Grid item>
          <GenericDateField
            locale={locale}
            tz={tz}
            id={'endDate'}
            record={data}
            oneLine
          />
        </Grid>
      </Grid>
    );
  }
};

// TODO: remove this build summary and use the one used for the invoices

/**
 * Computes the sum of total Without Vat from the lines
 */
const buildSummaryTotalWithoutVat = (lines: any, isUbl21: boolean): number => {
  let summaryTotalWithoutVat = 0.0;
  Object.entries<any | OrderLineDetails>(lines).forEach((line) => {
    const totalWithoutVat = isUbl21
      ? (line[1] as OrderLineDetails).LineItem?.[0]?.TaxTotal?.[0]
          ?.TaxSubtotal?.[0]?.TaxableAmount?.[0]?._
      : get(line[1], Ubl.totalWithoutVat);
    summaryTotalWithoutVat += totalWithoutVat;
  });
  return summaryTotalWithoutVat;
};

/**
 * Computes the total vat value for an invoice/order line.
 * @param {object} line invoice/order line
 */
export const buildSummaryTotalVat = (
  lines: any,
  isUbl21: boolean
): number | undefined => {
  let result = 0.0;
  for (let line of Object.entries<any | OrderLineDetails>(lines)) {
    const totalVat = isUbl21
      ? (line[1] as OrderLineDetails).LineItem?.[0]?.TaxTotal?.[0]
          ?.TaxSubtotal?.[0]?.TaxAmount?.[0]?._
      : get(line[1], Ubl.totalVat);

    if (isNaN(totalVat)) {
      return undefined; // return undefined if one of the values in the list is not valid
    }
    result += totalVat;
  }
  return result;
};

const OrderAddressField = (props) => {
  const { visibleParts = AddressParts.StreetName | AddressParts.CityName } =
    props;
  return (
    <AddressField
      {...props}
      missingPartIndicator={false}
      visibleParts={visibleParts}
    />
  );
};

const ContactInformation = ({ data, isUbl21, ...rest }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Grid container item xs={12} {...rest}>
      <Box>
        <Typography>{translate('dxMessages.headers.contact')}:</Typography>
        <SimpleLabeledField
          label={translate('dxMessages.headers.contactName')}
          data={get(data, isUbl21 ? 'Name[0]._' : 'name.value')}
          oneLine
          data-testid='contactName'
          className={classes.contactItem}
        />
        <SimpleLabeledField
          label={translate('dxMessages.headers.id')}
          data={get(data, isUbl21 ? 'ID[0]._' : 'id.value')}
          oneLine
          data-testid='contactID'
          className={classes.contactItem}
        />
        <SimpleLabeledField
          label={translate('dxMessages.headers.contactPhone')}
          data={get(data, isUbl21 ? 'Telephone[0]._' : 'telephone.value')}
          oneLine
          data-testid='contactPhone'
          className={classes.contactItem}
        />
        <SimpleLabeledField
          label={translate('dxMessages.headers.contactNote')}
          data={get(data, isUbl21 ? 'Note[0]._' : 'note[0].value')}
          oneLine
          data-testid='contactNote'
          className={classes.contactItem}
        />
      </Box>
    </Grid>
  );
};

interface PropsModel {
  record: P2pData;
  resource?: string;
  basePath?: string;
}

const OrderPreview = (props: PropsModel) => {
  const { record } = props;

  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useCommonPreviewStyles(props);
  const resource = useResourceContext(props);

  const [data, setData] = useState<P2pPreviewData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isUbl21, setIsUbl21] = useState(false);

  const orderService = useMemo(
    () =>
      DocumentServiceFactory.create(
        OrderServiceCreator,
        dataProvider
      ) as OrderService,
    [dataProvider]
  );

  const isUbl21Format = useCallback((data: any) => {
    if (data.Order) {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    const getData = async () => {
      const nodeId: string | undefined = record.id;
      const isDraftDocument = AlfrescoDocumentService.isWebDocument(record);

      try {
        let properties: any = {};
        let ublProperties: any = {};
        let lines: any[];

        properties = cloneDeep(record?.properties ?? {});
        if (record?.ublProperties && record?.lines) {
          ublProperties = cloneDeep(record?.ublProperties);
          lines = cloneDeep(record?.lines);
        } else if (nodeId) {
          let results: AlfrescoContent;
          if (isDraftDocument) {
            results = await orderService.loadDraft(nodeId);
          } else {
            results = await orderService.load(nodeId);
          }

          ublProperties = results?.ublContent?.ublProperties;
          lines = results?.ublContent?.lines;
        } else {
          throw new Error('record id cannot be null!');
        }
        const linesView = P2pUtils.convertOrderLines2ViewObject(lines);

        const previewData: P2pPreviewData = {
          id: nodeId,
          properties,
          ublProperties,
          linesView,
        };

        const isUbl21 = isUbl21Format(previewData.ublProperties);
        const summaryTotalVat = buildSummaryTotalVat(linesView, isUbl21);
        const summaryTotalWithoutVat = buildSummaryTotalWithoutVat(
          linesView,
          isUbl21
        );
        const summaryTotalWithVat =
          summaryTotalVat !== undefined
            ? summaryTotalVat + summaryTotalWithoutVat
            : undefined;

        set(properties, Metadata.totalVat, summaryTotalVat);
        set(properties, Metadata.totalWithVat, summaryTotalWithVat);
        set(properties, Metadata.totalWithoutVat, summaryTotalWithoutVat);
        setIsUbl21(isUbl21);
        setData(previewData);
        setLoading(false);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(error);
        setLoading(false);
      }
    };
    if (!loading && !data && !error) {
      setLoading(true);
      getData();
    }
  }, [data, orderService, dataProvider, error, loading, record, isUbl21Format]);

  if (error) {
    return (
      <Typography variant='h5'>
        {translate('dxMessages.error_messages.unable_to_display')}
      </Typography>
    );
  } else if (loading || !data) {
    return <Loading />;
  } else {
    const lineIds = Object.keys(data.linesView);
    const currencyID =
      DataHelpers.getCurrencyID(data) ||
      get(data, OrderService.MONO_CURRENCY_FIELD);
    const deliveryDate = isUbl21
      ? (data.ublProperties.Order[0] as OrderDetails).Delivery?.[0]
          ?.RequestedDeliveryPeriod?.[0]?.StartDate?.[0]?._
      : get(data, Ubl.deliveryRequestStartDate);
    const taxSummaryTable = isUbl21
      ? (data.ublProperties.Order[0] as OrderDetails).TaxTotal?.[0]
          ?.TaxSubtotal || []
      : get(data.ublProperties, Ubl.taxSubtotal) || [];

    return (
      <Paper square classes={{ root: classes.paper }}>
        <Grid container justify='center'>
          <Grid item>
            <Typography variant='h6' style={{ fontSize: '2em' }}>
              {translate('dxMessages.orders.document_label')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container classes={{ container: classes.section }}>
          <Grid item xs={2}>
            <SimpleLabeledField
              label={translate('dxMessages.orders.orderNumber')}
              data={
                isUbl21
                  ? (data.ublProperties.Order[0] as OrderDetails).ID?.[0]?._
                  : get(data.properties, Metadata.documentId)
              }
              className={Metadata.documentId}
            />
          </Grid>
          <Grid item xs={3}>
            <GenericLabeledDateField
              label={translate('dxMessages.headers.issueDate')}
              id={
                isUbl21
                  ? 'ublProperties.Order[0].IssueDate[0]._'
                  : Metadata.issueDate
              }
              record={data}
              className={Metadata.issueDate}
            />
          </Grid>
          <Grid item xs={6}>
            {deliveryDate && (
              <DeliveryRequestPeriod
                record={data}
                startDate={deliveryDate}
                endDate={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .Delivery?.[0]?.RequestedDeliveryPeriod?.[0]
                        ?.EndDate?.[0]?._
                    : get(data, Ubl.deliveryRequestEndDate)
                }
                isUbl21={isUbl21}
              />
            )}
          </Grid>
        </Grid>
        <Grid container classes={{ container: classes.graySection }}>
          <Grid item container direction='column' xs={6}>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.buyerCustomer')}
                data={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .BuyerCustomerParty?.[0]?.Party?.[0]?.PartyName?.[0]
                        ?.Name?.[0]?._
                    : get(data, Ubl.buyerCustomerName)
                }
                className={'buyerCustomerName'}
                oneLine
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.gln')}
                data={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .BuyerCustomerParty?.[0]?.Party?.[0]?.EndpointID?.[0]?._
                    : get(data, Ubl.buyerCustomerGLN)
                }
                className={'buyerCustomerGLN'}
                oneLine
              />
            </Grid>
            <Grid item>
              <OrderAddressField
                address={getBuyerCustomerAddress(data)}
                className={'buyerCustomerAddress'}
              />
            </Grid>
            <Grid item>
              <ContactInformation
                data={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .BuyerCustomerParty?.[0]?.BuyerContact?.[0]
                    : get(data, 'ublProperties.buyerCustomerParty.buyerContact')
                }
                isUbl21={isUbl21}
                data-testid={'buyer-contact'}
              />
            </Grid>
          </Grid>
          <Grid item container direction='column' xs={6}>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.supplier')}
                data={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .SellerSupplierParty?.[0]?.Party?.[0]?.PartyName?.[0]
                        ?.Name?.[0]?._
                    : get(data.properties, Metadata.recipientName)
                }
                className={Metadata.recipientName}
                oneLine
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.gln')}
                data={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .SellerSupplierParty?.[0]?.Party?.[0]?.EndpointID?.[0]
                        ?._
                    : get(data, Ubl.sellerSupplierGLN)
                }
                className={'supplierGLN'}
                oneLine
              />
            </Grid>
            <Grid item>
              <OrderAddressField
                address={getSellerSupplierAddress(data)}
                className={'sellerSupplierAddress'}
              />
            </Grid>
            <Grid item>
              <ContactInformation
                data={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .SellerSupplierParty?.[0]?.SellerContact?.[0]
                    : get(
                        data,
                        'ublProperties.sellerSupplierParty.sellerContact'
                      )
                }
                isUbl21={isUbl21}
                data-testid={'seller-contact'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container classes={{ container: classes.graySection }}>
          <Grid item container direction='column' xs={6}>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.deliveryLocationName')}
                data={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .Delivery?.[0]?.DeliveryParty?.[0]?.PartyName?.[0]
                        ?.Name?.[0]?._
                    : get(data.properties, Metadata.locationName)
                }
                className={Metadata.locationName}
                oneLine
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.gln')}
                data={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .Delivery?.[0]?.DeliveryParty?.[0]?.EndpointID?.[0]?._
                    : get(data, Ubl.deliveryPartyGLN)
                }
                className={'deliveryPartyGLN'}
                oneLine
              />
            </Grid>
            <Grid item>
              {isUbl21 && (
                <OrderAddressField
                  label={translate('dxMessages.headers.locationAddress')}
                  address={getDeliveryLocationAddress(data)}
                  className={'deliveryLocationAddress'}
                  visibleParts={AddressParts.AllParts}
                ></OrderAddressField>
              )}
              {!isUbl21 && (
                <SimpleLabeledField
                  label={translate('dxMessages.headers.locationAddress')}
                  data={get(data.properties, Metadata.locationAddress)}
                  oneLine
                  className={'deliveryLocationAddress'}
                />
              )}
            </Grid>
          </Grid>
          <Grid item container direction='column' xs={6}>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.buyer')}
                data={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .AccountingCustomerParty?.[0]?.Party?.[0]
                        ?.PartyName?.[0]?.Name?.[0]._
                    : get(data, Ubl.accountingCustomerPartyName)
                }
                className={'accountingCustomerPartyName'}
                oneLine
              />
            </Grid>
            <Grid item>
              <SimpleLabeledField
                label={translate('dxMessages.headers.gln')}
                data={
                  isUbl21
                    ? (data.ublProperties.Order[0] as OrderDetails)
                        .AccountingCustomerParty?.[0]?.Party?.[0]
                        ?.EndpointID?.[0]?._
                    : get(data, Ubl.accountingCustomerGLN)
                }
                className={'accountingCustomerGLN'}
                oneLine
              />
            </Grid>
            <Grid item>
              <OrderAddressField
                address={getAccountingCustomerAddress(data)}
                className={'accountingCustomerAddress'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Lines
          loading={loading}
          currencyID={currencyID}
          resource={resource}
          linesView={data.linesView}
          lineIds={lineIds}
          record={record}
          isUbl21={isUbl21}
        />
        <Grid container justify='flex-end'>
          <Grid item>
            <TaxTotals data={data} />
          </Grid>
        </Grid>
        {taxSummaryTable.length > 0 && (
          <Grid item container justify='flex-end'>
            <Grid item>
              <TaxSummaryPanel data={data} />
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }
};

export default OrderPreview;
