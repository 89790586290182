export const FieldTypes = {
  Container: 'Container',
  DateInput: 'DateInput',
  TimeInput: 'TimeInput',
  SingleDateInput: 'SingleDateInput',
  OrderLinesTable: 'OrderLinesTable',
  ArrayLinesTable: 'ArrayLinesTable',
  ReferenceInput: 'ReferenceInput',
  SelectInput: 'SelectInput',
  SelectArrayInput: 'SelectArrayInput',
  SelectTaxArrayInput: 'SelectTaxArrayInput',
  SearchSelectInput: 'SearchSelectInput',
  AutocompleteTextInput: 'AutocompleteTextInput',
  TextInput: 'TextInput',
  GLNInput: 'GLNInput',
  NumberInput: 'NumberInput',
  NumberDisplay: 'NumberDisplay',
  BooleanInput: 'BooleanInput',
  ActionButton: 'ActionButton',
  LayoutSpacer: 'LayoutSpacer',
  MessageDisplay: 'MessageDisplay',
  DOCUMENT_ID: 'DOCUMENT_ID',
  MONO_VAT: 'customFields.MONO_VAT',
  TAX_CATEGORY: 'customFields.TAX_CATEGORY',
  TARGET_CURRENCY: 'customFields.TARGET_CURRENCY',
  EXCHANGE_RATE: 'customFields.EXCHANGE_RATE',
  APPLY_RATE: 'customFields.APPLY_RATE',
  EFACTURA_FLAG: 'customFields.EFACTURA_FLAG',
  EFACTURA_EMAIL: 'customFields.EFACTURA_EMAIL',
  EFACTURA_EMAIL_FLAG: 'customFields.EFACTURA_EMAIL_FLAG',
  EFACTURA_SELECT: 'customFields.EFACTURA_SELECT',
  PEPPOL_ACCESS_POINT: 'customFields.PEPPOL_ACCESS_POINT',
  SGR_LINE: 'customFields.SGR_LINE',
  PEPPOL_COMPANY_NAME: 'customFields.PEPPOL_COMPANY_NAME',
  SGT_Amount: 'SGT_Amount',
  SGT_Percent: 'SGT_Percent',
  GT_Amount: 'GT_Amount',
  GT_Percent: 'GT_Percent',
  Taxes: 'Taxes',
  DOC_LEVEL_DISCOUNTS: 'customFields.DOC_LEVEL_DISCOUNTS',
  DOC_LEVEL_CHARGES: 'customFields.DOC_LEVEL_CHARGES',
  MESSAGE: 'customFields.MESSAGE',
};
