import { Typography } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { useDataProvider, useTranslate } from 'ra-core';
import { useEffect, useMemo, useState } from 'react';
import { Loading } from 'react-admin';
import {
  AlfrescoDocumentService,
  DespatchAdviceService,
  DespatchAdviceServiceCreator,
  DocumentServiceFactory,
} from '../../services';
import {
  AlfrescoContent,
  P2pData,
  P2pPreviewData,
  P2pUblContent,
} from '../../shared/types';
import { P2pUtils } from '../common/P2pUtils';
import { useCommonPreviewStyles } from '../common/PreviewStyles';
import DespatchAdvicePreviewController from './DespatchAdvicePreviewController';

interface PropsModel {
  record: P2pData;
  basePath?: string;
}

const DespatchAdvicePreview = (props: PropsModel) => {
  const { basePath, record } = props;

  const dataProvider = useDataProvider();
  const classes = useCommonPreviewStyles(props);
  const translate = useTranslate();

  const [data, setData] = useState<P2pPreviewData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const desadvService = useMemo(
    () =>
      DocumentServiceFactory.create(
        DespatchAdviceServiceCreator,
        dataProvider
      ) as DespatchAdviceService,
    [dataProvider]
  );

  useEffect(() => {
    const getData = async () => {
      const nodeId: string | undefined = record.id;

      try {
        let properties: any = {};
        let ublProperties: any = {};
        let lines: any[];

        properties = cloneDeep(record?.properties ?? {});

        if (record?.ublProperties && record?.lines) {
          ublProperties = cloneDeep(record?.ublProperties);
          lines = cloneDeep(record?.lines);
        } else if (nodeId) {
          let data: P2pUblContent | AlfrescoContent;
          if (AlfrescoDocumentService.isWebDocument(record)) {
            data = await desadvService.loadDraft(nodeId);
            ublProperties = data?.ublContent.ublProperties;
            lines = data?.ublContent.lines;
          } else {
            data = await desadvService.load(nodeId);
            ublProperties = data?.ublProperties;
            lines = data?.lines;
          }
        } else {
          throw new Error('record id cannot be null!');
        }
        const linesView = P2pUtils.convertLines2ViewObject(lines);

        const previewData: P2pPreviewData = {
          id: nodeId,
          properties,
          ublProperties,
          linesView,
        };

        setData(previewData);
        setLoading(false);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(error);
        setLoading(false);
      }
    };

    if (!loading && !data && !error) {
      setLoading(true);
      getData();
    }
  }, [data, dataProvider, desadvService, error, loading, record]);

  if (error) {
    return (
      <Typography variant='h5'>
        {translate('dxMessages.error_messages.unable_to_display')}
      </Typography>
    );
  } else if (loading || !data) {
    return <Loading />;
  } else {
    return (
      <DespatchAdvicePreviewController
        {...{
          classes,
          record: data,
          basePath,
        }}
      />
    );
  }
};

DespatchAdvicePreview.propTypes = {
  record: PropTypes.object.isRequired,
  basePath: PropTypes.string,
  resource: PropTypes.string,
};

export default DespatchAdvicePreview;
