import { Template } from '../shared/types';

export const UPDATE_FORM_TEMPLATE = 'UPDATE_FORM_TEMPLATE';

export interface UpdateFormTemplateAction {
  type: string;
  payload: Template | null;
}

export const updateFormTemplate = (
  template: Template | null
): UpdateFormTemplateAction => ({
  type: UPDATE_FORM_TEMPLATE,
  payload: template,
});
