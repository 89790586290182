import {
  Box,
  Dialog,
  DialogContent,
  Grow,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { FC, useState } from 'react';
import {
  PaginationActions,
  PaginationProps,
  Pagination as RaPagination,
  ReduxState,
  useListContext,
  useTranslate,
} from 'react-admin';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';

/**
 * A floating pagination (appearing in front of all screen content).
 *
 * The standard react-admin pagination appears at the bottom of the list.
 * When the list is longer than one screen, user must scrolls downn the
 * page to access the pagination buttons. With the floating pagination,
 * the buttons are always available.
 *
 * The floating pagination also display the total of items in the top left
 * corner (using the smart 'countLabel' if any, ex:
 * "One invoice found |||| %{smart_count} invoices found").
 *
 */

const useStyles = makeStyles(
  (theme) => ({
    bottomRightBox: {
      zIndex: 3000,
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(9),
    },
    raPagination: {
      // // Minimize the height of the MUI pagination:
      overflow: 'hidden',
      paddingLeft: 4,
      '&:last-child': {
        paddingLeft: 4,
      },
      '& .MuiToolbar-root': {
        minHeight: 0,
        fontSize: '0.8125rem',
      },
      '& .MuiTablePagination-caption': {
        margin: 0,
        padding: 0,
      },
      '& .MuiTablePagination-selectRoot': {
        height: '1em',
        margin: 0,
        padding: 0,
        bottom: 4,
      },
      '& .MuiTablePagination-select': {
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        height: 24,
      },
      '& .MuiTablePagination-toolbar': {
        paddingLeft: 4,
      },
      '& .page-number': {
        margin: 0,
        padding: 0,
        '& span': {
          margin: 0,
          padding: 0,
        },
      },
      '& .next-page': {
        margin: 0,
        padding: 0,
      },
      '& .previous-page': {
        margin: 0,
        padding: 0,
      },
    },
  }),
  { name: 'FloatingPagination' }
);
const FloatingPagination: FC<
  PaginationProps & {
    hotKeyModifier?: string;
  }
> = (props) => {
  const translate = useTranslate();

  const open = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );

  const classes = useStyles({ open });
  const { total, setPage, page, perPage } = useListContext();
  const [pageDialogOpen, setPageDialogOpen] = useState(false);

  const lastPage = Math.ceil(total / perPage);

  const { hotKeyModifier, ...rest } = props;

  // Key bindings on right and left arrows to go to the next/prev page...
  useHotkeys(
    [hotKeyModifier ? hotKeyModifier + '+right' : 'right'],
    () => page !== lastPage && setPage(page + 1)
  );
  useHotkeys(
    [hotKeyModifier ? hotKeyModifier + '+left' : 'left'],
    () => page !== 1 && setPage(page - 1)
  );
  // ... and 'g' to open a tiny dialog where user can enter
  // a page number to jump to.
  useHotkeys(['g'], () => setPageDialogOpen(true));

  const closeDialog = () => setPageDialogOpen(false);

  return (
    <>
      {/* Bottom right corner: the pagination. */}
      <Box className={classes.bottomRightBox}>
        <Grow in={true}>
          <div>
            <Box
              boxShadow={4}
              borderRadius={32}
              bgcolor='background.paper'
              style={{ border: '1px solid grey' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: 24,
                }}
              >
                {total > 0 && lastPage > 1 && (
                  <Tooltip title={translate('dxMessages.list.paginationHelp')}>
                    <HelpIcon fontSize='small' style={{ paddingLeft: 4 }} />
                  </Tooltip>
                )}
                <RaPagination
                  {...rest}
                  // @ts-ignore
                  ActionsComponent={PaginationActions}
                  className={classes.raPagination}
                />
              </div>
            </Box>
          </div>
        </Grow>
      </Box>
      {/* The dialog to enter a page to jump to. */}
      <Dialog open={pageDialogOpen} onClose={closeDialog}>
        <IconButton
          aria-label='close'
          onClick={closeDialog}
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: 'primary',
          }}
          size='small'
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TextField
            label={translate('dxMessages.list.goToPage')}
            type='number'
            autoFocus
            // On ENTER, jump to the page.
            onKeyDown={(ev) => {
              if (ev.key !== 'Enter') return;

              // @ts-ignore
              const newPage = +ev.target.value;
              if (newPage >= 1 && newPage <= lastPage) {
                if (page !== newPage) setPage(newPage);
                closeDialog();
              }
            }}
            variant='standard'
            size='small'
            InputLabelProps={{
              shrink: true,
            }}
            helperText={`1 ... ${lastPage}`}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FloatingPagination;
