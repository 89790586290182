import {
  Checkbox,
  Chip,
  FormControl,
  FormControlProps,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { clone, get, set } from 'lodash';
import PropTypes from 'prop-types';
import { ChoicesProps, FieldTitle, InputProps, useChoices } from 'ra-core';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SupportCreateSuggestionOptions } from 'react-admin';
import { useForm } from 'react-final-form';
import NumberInput from './NumberInput';
import useInput from './useInput';
import { useSupportCreateSuggestion } from './useSupportCreateSuggestion';

const SelectTaxArrayInput = (props: SelectTaxArrayInputProps) => {
  const {
    choices = [],
    classes: classesOverride,
    className,
    create,
    createLabel,
    createValue,
    disableValue,
    format,
    helperText,
    label,
    loaded,
    loading,
    margin = 'dense',
    multiple,
    onBlur,
    onChange,
    onCreate,
    onFocus,
    options,
    optionText,
    optionValue,
    itemOptionValue = '_', // "_" from Oasis Ubl 2.1 leaf values
    parse,
    resource,
    source,
    translateChoice,
    validate,
    variant = 'filled',
    disabled,
    readOnly,
    defaultValue,
    ...rest
  } = props;

  if (create || onCreate) {
    throw new Error(
      'This is a custom version of SelectTaxArrayInput. Creating new items not supported. This would need further adaptation of the original component.'
    );
  }

  const classes = useStyles(props);
  const inputLabel = useRef<any>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const form = useForm();

  /**
   * Complete list of values used by the select input control
   */
  const [choiceValues, setChoiceValues] = React.useState<any[]>([]);

  /**
   * Selected values
   */
  const [selectedValues, setSelectedValues] = React.useState<any[]>([]);

  /**
   * Choices in the select input control. When creating, the first choice is a special value choice.
   */
  const [finalChoices, setFinalChoices] = React.useState<any[]>([]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Will be null while loading and we don't need this fix in that case
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  const { getChoiceText, getChoiceValue, getDisableValue } = useChoices({
    optionText,
    optionValue,
    disableValue,
    translateChoice,
  });
  const {
    input,
    isRequired,
    meta: { error, submitError },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    defaultValue,
    ...rest,
  });

  const handleChange = useCallback(
    (event, newItem) => {
      if (newItem) {
        input.onChange([...input.value, getChoiceValue(newItem)]);
        return;
      }

      // Manage the exclusive or multiple use case here
      // exclusive use case
      const previousSelection = selectedValues;
      let newSelection = event.target.value.filter(
        (s) => s?._ !== previousSelection[0]?._
      );
      if (newSelection.length === 0) {
        newSelection = previousSelection;
      }
      // multiple use case
      if (multiple) {
        newSelection = event.target.value;
      }

      const newChoiceValues = clone(choiceValues);
      newChoiceValues.forEach((c) => {
        if (!newSelection.find((n) => n._ === c._)) {
          delete c.value;
        }
      });
      input.onChange(newSelection);
      setOpen(false);
    },
    [selectedValues, multiple, choiceValues, input, getChoiceValue]
  );

  const {
    getCreateItem,
    handleChange: handleChangeWithCreateSupport,
    createElement,
  } = useSupportCreateSuggestion({
    create,
    createLabel,
    createValue,
    handleChange,
    onCreate,
    optionText,
  });

  const createItem = create || onCreate ? getCreateItem() : null;

  const getItemValue = useCallback(
    (item) => {
      return get(item, itemOptionValue);
    },
    [itemOptionValue]
  );

  const setItemValue = useCallback(
    (item, value) => {
      return set(item, itemOptionValue, value);
    },
    [itemOptionValue]
  );

  useEffect(() => {
    const tempFinalChoices =
      create || onCreate ? [...choices, createItem] : choices;

    const tempChoiceValues = tempFinalChoices?.map((choice: any) => {
      const item = {};
      setItemValue(item, getChoiceValue(choice));
      return item;
    });

    setFinalChoices(tempFinalChoices);
    setChoiceValues(tempChoiceValues);
  }, [choices, create, createItem, getChoiceValue, onCreate, setItemValue]);

  const inputToDataValues = useCallback(
    (inputValues: any[]) => {
      if (!choiceValues?.length) {
        return [];
      }

      const newInputValues = inputValues.map((inputValue) =>
        choiceValues.find(
          (choiceValue) =>
            getItemValue(inputValue) === getItemValue(choiceValue)
        )
      );

      // Adds the values associated to the tax selection and returns the data
      return newInputValues.map((t: any) => {
        const tax = get(form.getState().values, source)?.find(
          (ts: any) => t._ === ts._
        );
        if (t._ === tax?._) {
          set(t, 'value', tax.value);
        }
        return t;
      });
    },
    [choiceValues, getItemValue, source, form]
  );

  useEffect(() => {
    const values = inputToDataValues(input.value || []);
    setSelectedValues(values);
  }, [input.value, inputToDataValues]);

  const renderMenuItemOption = useCallback(
    (choice) => getChoiceText(choice),
    [getChoiceText]
  );

  /**
   * Get the object corresponding to the choice. Select input does reference equality.
   * We need the same objects as in the values collection
   */
  const choiceToDataValue = useCallback(
    (choice) =>
      choiceValues.find((cv) => getItemValue(cv) === getChoiceValue(choice)),
    [choiceValues, getChoiceValue, getItemValue]
  );

  const renderMenuItem = useCallback(
    (choice, idx) => {
      return choice ? (
        <MenuItem
          key={idx} // changed for complex id types
          value={choiceToDataValue(choice)} // reference comparison - used to make selection work
          disabled={getDisableValue(choice)}
        >
          <Checkbox
            checked={selectedValues.indexOf(choiceToDataValue(choice)) > -1}
            size='small'
            color='default'
          />
          {!!createItem && choice?.id === createItem.id
            ? createItem.name
            : renderMenuItemOption(choice)}
        </MenuItem>
      ) : null;
    },
    [
      choiceToDataValue,
      createItem,
      getDisableValue,
      renderMenuItemOption,
      selectedValues,
    ]
  );

  return (
    <div style={{ position: 'relative', bottom: 8 }}>
      <FormControl
        margin={margin}
        className={classnames(classes.root, className)}
        error={!!(error || submitError)}
        variant={variant}
        {...sanitizeRestProps(rest)}
      >
        <InputLabel
          ref={inputLabel}
          id={`${label}-outlined-label`}
          error={!!(error || submitError)}
          variant={variant}
        >
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
          }}
        >
          <Grid style={{ display: 'block' }}>
            <Select
              autoWidth
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              multiple={true} // exclusive or multiple choice is handle at handleChange level
              labelId={`${label}-outlined-label`}
              error={!!(error || submitError)}
              renderValue={(selected: any[]) => (
                <div className={classes.selected}>
                  {selected?.length > 1 && (
                    <Chip
                      label={selected?.length}
                      className={classes.chip}
                      size='small'
                    />
                  )}
                  {selected
                    .map((item) =>
                      choices.find((choice) => {
                        return getChoiceValue(choice) === getItemValue(item);
                      })
                    )
                    .filter((item) => !!item)
                    .map((item) => renderMenuItemOption(item))
                    .join(', ')}
                </div>
              )}
              data-testid='selectTaxArray'
              {...input}
              onChange={handleChangeWithCreateSupport}
              value={selectedValues}
              labelWidth={labelWidth}
              {...options}
            >
              {choiceValues && finalChoices.map(renderMenuItem)}
            </Select>
          </Grid>
          <Grid style={{ display: 'flex', flexDirection: 'row' }}>
            {selectedValues.map((tax, index) => {
              return (
                <NumberInput
                  source={`${source}[${index}].value`}
                  label={`dxMessages.invoices.taxes.${tax._}`}
                  style={{ width: '8em', marginLeft: '1em' }}
                  onChange={(e) => {
                    let float = parseFloat(e.target.value);
                    if (isNaN(float) && e.target.value !== '') {
                      return null;
                    }
                    if (e.target.value === '') {
                      // reset case => needs to be handle anyway
                      float = e.target.value;
                    }
                    const inputValue = input.value;
                    const newValue = {
                      _: tax._,
                      value: float,
                    };
                    const newSelectedValues = inputValue.map((v) => {
                      if (v._ === newValue._) {
                        // existing value
                        v.value = newValue.value;
                      }
                      return v;
                    });
                    setSelectedValues(newSelectedValues);
                    input.onChange(newSelectedValues);
                  }}
                  key={`${source}[${index}].value`}
                  validate={validate}
                  defaultValue={0}
                />
              );
            })}
          </Grid>
        </Grid>
      </FormControl>
      {createElement}
    </div>
  );
};

export interface SelectTaxArrayInputProps
  extends Omit<ChoicesProps, 'choices' | 'optionText'>,
    Omit<SupportCreateSuggestionOptions, 'handleChange'>,
    Omit<InputProps<SelectProps>, 'source'>,
    Omit<FormControlProps, 'defaultValue' | 'onBlur' | 'onChange' | 'onFocus'> {
  choices?: object[];
  source: string;
  itemOptionValue: string;
  readOnly?: boolean;
  multiple?: boolean;
}

SelectTaxArrayInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  disableValue: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
  translateChoice: PropTypes.bool,
};

SelectTaxArrayInput.defaultProps = {
  options: {},
  optionText: 'name',
  optionValue: 'id',
  disableValue: 'disabled',
  translateChoice: true,
};

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  alwaysOn,
  basePath,
  choices,
  classNamInputWithOptionsPropse,
  componenInputWithOptionsPropst,
  crudGetMInputWithOptionsPropsatching,
  crudGetOInputWithOptionsPropsne,
  defaultValue,
  disableValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  loaded,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}: any) => rest;

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    chip: {
      backgroundColor: 'transparent',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.grey[500],
      margin: theme.spacing(1 / 3),
    },
    selected: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'SelectTaxArrayInput' }
);

export default SelectTaxArrayInput;
