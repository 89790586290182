import { AutoCompleteSingleSelect } from '@dx-ui/dx-common';
import {
  Chip,
  FormControl,
  FormHelperText,
  LinearProgress,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FieldTitle, useTranslate } from 'react-admin';
import useInput from './useInput';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: '3px 0px',
      minWidth: 100,
      marginTop: 5,
      marginBottom: 4,
    },
    linearProgress: {
      bottom: '-2rem',
    },
  }),
  { name: 'SearchSelectInput' }
);

const SearchSelectInput = (props: any) => {
  const {
    icon = undefined,
    options,
    sortLabels = true,
    numberToDisplaySearch,
    source,
    titleSuffix,
    label,
    className,
    qaselector,
    loading,
    style,
    helperText,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();

  const {
    isRequired,
    input,
    meta: { valid, error },
  } = useInput(props);

  const getOptionId = (x) => {
    const { optionValue = 'id' } = props;
    return x[optionValue];
  };
  const renderOptionLabel = (x) => {
    const { optionText = 'label' } = props;
    return x[optionText];
  };

  const renderOptionTooltip = (x) => {
    const { optionTooltip = 'tooltip' } = props;
    return x[optionTooltip];
  };

  const handleChange = (evt) => {
    let onChange = input.onChange;
    if (!onChange) {
      return;
    }
    onChange(evt);
  };

  const filterOption = (option, query, lowerCaseQuery, translate) => {
    const { optionText = 'label', optionValue = 'id' } = props;
    if (!query) {
      return true;
    }
    let optionLabel = option[optionText];
    let value = option[optionValue];
    optionLabel = translate(optionLabel, { _: optionLabel });
    return (
      optionLabel.toLowerCase().indexOf(lowerCaseQuery) !== -1 ||
      value.toLowerCase().indexOf(lowerCaseQuery) !== -1
    );
  };

  const value = input ? input.value : '';
  const showError = !valid;

  return (
    <FormControl
      className={classnames(classes.root, className)}
      error={showError}
      style={style}
    >
      {!!label && (
        <InputLabel>
          <>
            <FieldTitle label={label} source={source} isRequired={isRequired} />
            {titleSuffix &&
              titleSuffix.map((s) => {
                return (
                  <Chip
                    label={s}
                    size='small'
                    style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                    key={s}
                  />
                );
              })}
          </>
        </InputLabel>
      )}
      {loading ? (
        <LinearProgress classes={{ root: classes.linearProgress }} />
      ) : (
        <AutoCompleteSingleSelect
          value={value}
          onChange={handleChange}
          icon={icon}
          sortLabels={sortLabels}
          options={options}
          getOptionId={getOptionId}
          renderOptionLabel={renderOptionLabel}
          renderOptionTooltip={renderOptionTooltip}
          filterOption={filterOption}
          numberToDisplaySearch={numberToDisplaySearch}
          {...{ qaselector }}
        />
      )}
      {showError ? (
        <FormHelperText>{translate(error, { _: error })}</FormHelperText>
      ) : (
        <FormHelperText>
          {helperText ? translate(helperText, { _: helperText }) : ' '}
        </FormHelperText>
      )}
    </FormControl>
  );
};

SearchSelectInput.propTypes = {
  classes: PropTypes.object,
  translate: PropTypes.func,
};

export default SearchSelectInput;
