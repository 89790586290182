import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { DxTheme } from '../../types';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    spaced: {
      marginRight: 5,
      marginTop: 5,
    },
  }),
  { name: 'RolesField' }
);

const RolesField = ({ className, record = { enabledRoles: [] } }: any) => {
  const classes = useStyles();
  return (
    <>
      {record?.enabledRoles?.map((role, idx) => (
        <Chip
          key={idx}
          label={role}
          className={classNames(classes.spaced, className)}
        />
      ))}
    </>
  );
};

RolesField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
};

RolesField.defaultProps = {
  addLabel: true,
};

export default RolesField;
