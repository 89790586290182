import { IconButton, Tooltip } from '@material-ui/core';
import PreviewIcon from '@material-ui/icons/RemoveRedEye';
import React from 'react';
import { useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { readStatusChange } from '../../actions/readStatusChange';
import { colors } from '../../constants/Colors';
import { Constants } from '../../constants/Constants';

const PreviewLink = (props) => {
  const {
    record,
    title,
    tooltip = 'dxMessages.buttons.preview',
    renderAsLink, // renders as a link with title underlined.
    className,
    href,
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();

  const onClick = () => {
    if (
      record &&
      record.properties &&
      record.properties['edm:readStatus'] === Constants.READ_STATUS_NEW
    ) {
      record.properties['edm:readStatus'] = Constants.READ_STATUS_DOWNLOADED;
      dispatch(readStatusChange(Constants.READ_STATUS_DOWNLOADED));
    }
  };

  return renderAsLink ? (
    <Tooltip title={translate(tooltip)}>
      <a
        href={href ? href : `${record.id}/preview`}
        rel='noopener noreferrer'
        target='_blank'
        style={{
          color: colors.mainColor5,
        }}
        onClick={onClick}
      >
        {title}
      </a>
    </Tooltip>
  ) : (
    <a
      href={href ? href : `${record.id}/preview`}
      rel='noopener noreferrer'
      target='_blank'
      onClick={onClick}
    >
      <Tooltip title={translate(tooltip)}>
        <IconButton color='primary' className={className}>
          <PreviewIcon />
        </IconButton>
      </Tooltip>
    </a>
  );
};

export default PreviewLink;
