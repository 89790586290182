import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import {
  Datagrid,
  FunctionField,
  linkToRecord,
  SimpleList,
  useLocale,
  useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
  columnWidthStyles,
  documentTypeCodeToResource,
  EmptyValueField,
  getDxApplication,
  getMessagePrefix,
  merge,
  ProcessStatusIcon,
} from '../..';
import { Constants, Metadata } from '../../constants';
import useTz from '../../intl/useTz';
import { DxTheme } from '../../types';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      width: '100%',
      minWidth: 500,
      margin: 'auto',
      borderSpacing: 0,
    },
    headerCell: {
      padding: '5px 10px',
      verticalAlign: 'middle',
      '&.alignRight': {
        textAlign: 'right',
      },
      '&.alignRight span[role=button]': {
        // sortable columns
        justifyContent: 'flex-end',
      },
      '&:last-child': {
        padding: '5px 10px',
      },
      '& span[role=button]': {
        // https://docprocess.atlassian.net/browse/DXPOR-349
        verticalAlign: 'unset',
        display: 'flex',
      },
    },
    row: {
      '& .showOnHover': {
        visibility: 'hidden',
      },
      '&:hover .showOnHover': {
        visibility: 'visible',
      },
      '&:hover .stickyCol': {
        backgroundColor: theme.app.tableOnHoverBkgColor, // color like row hover one.
      },
      '&:hover td:first-child': {
        backgroundColor: theme.app.tableOnHoverBkgColor, // set color like row hover one.
      },
      verticalAlign: 'text-bottom',
    },
    headerRow: {
      '&:hover .stickyCol': {
        backgroundColor: theme.app.tableBkgColor,
      },
    },
    contextualButtons: (props: { app: any }) => {
      return {
        display: 'flex',
        width: 'fit-content',
        position: 'relative',
        left:
          props.app === Constants.DXARCHIVE_APP
            ? 'calc(100vw - 615px)'
            : 'calc(100vw - 615px)',
        bottom: '1em',
        borderRadius: 30,
        background: theme.app.tableBkgColor,
        boxShadow:
          '0px 1px 2px rgba(0, 0, 0, 0.24), 0px 1px 3px rgba(0, 0, 0, 0.12)',
      };
    },
    headerCell_withStickyCols: {}, // Provided by the parent caller
    row_withStickyCols: {}, // Provided by the parent caller
    headerRow_withStickyCols: {}, // Provided by the parent caller
    tableScroll: {}, // Provided by the parent caller
  }),
  { name: 'ResponsiveGrid' }
);

const ResponsiveGrid = (props) => {
  const isSmall = useMediaQuery((theme: DxTheme) =>
    theme.breakpoints.down('sm')
  );
  const classes = useStyles({ isSmall, ...props });
  const translate = useTranslate();
  const locale = useLocale();

  const {
    columnsVisibility,
    classes: classesOverride,
    columns,
    basePath,
    resource,
    showContextualButtons,
    DisplayContextualButtons,
    contextualToolbar,
    ...rest
  } = props;

  const tz = useTz();
  const theme: DxTheme = useTheme();
  const dxApplication = getDxApplication();

  const getPrimaryText = (record) => {
    if (!record) return null;
    if (dxApplication === Constants.DXPURCHASE_APP) {
      return (
        <>
          <Link to={`${linkToRecord(basePath, record.id)}/show`}>
            <Typography variant='h6'>
              {translate(
                `dxMessages.${getMessagePrefix(resource)}.document_label`
              ) +
                `
        ${
          record.properties[Metadata.documentId]
            ? record.properties[Metadata.documentId]
            : '--'
        }`}
            </Typography>
          </Link>
          {getStatus(record)}
        </>
      );
    } else {
      const messagePrefix = getMessagePrefix(
        documentTypeCodeToResource(record.properties[Metadata.documentTypeCode])
      );
      return (
        <>
          <Typography>
            {translate(`dxMessages.${messagePrefix}.document_label`) +
              ` :
        ${record['name']}`}
          </Typography>
          <ActionsButtons record={record} />
        </>
      );
    }
  };

  const ActionsButtons = ({ record }) => {
    return (
      <>
        {contextualToolbar &&
          React.cloneElement(contextualToolbar, {
            basePath,
            resource,
            locale,
            record,
            tz,
            ...rest,
          })}
      </>
    );
  };

  const getStatus = (record) => {
    if (!record) return null;
    const processStatus = record.properties[Metadata.processStatus];
    return processStatus ? (
      <>
        <Typography>
          {<ProcessStatusIcon status={processStatus} />}
          <span>{translate(`dxMessages.processStatus.${processStatus}`)}</span>
        </Typography>
        <Typography variant='caption'>
          {record.modifiedAt ? (
            moment(record.modifiedAt).tz(tz).format('HH:mm - DD MMM YYYY')
          ) : (
            <EmptyValueField />
          )}
        </Typography>
        <ActionsButtons record={record} />
      </>
    ) : (
      <EmptyValueField />
    );
  };

  if (isSmall) {
    return (
      <SimpleList
        {...rest}
        primaryText={getPrimaryText}
        linkType={false}
        rowStyle={(record, index) => ({
          backgroundColor:
            index & 1 ? theme.app.tableOnHoverBkgColor : 'inherit',
        })}
      />
    );
  } else {
    return (
      <div className={classes.tableScroll}>
        <Datagrid
          classes={{
            headerRow: classNames(
              classes.headerRow,
              classes.headerRow_withStickyCols
            ),
            headerCell: classNames(
              classes.headerCell,
              classes.headerCell_withStickyCols
            ),
            row: classNames(classes.row, classes.row_withStickyCols),
          }}
          {...rest}
        >
          {[...columns]
            .filter(
              (column) => column.alwaysOn || columnsVisibility.get(column.id)
            )
            .map((column, index) => {
              let headerClassName = '';
              if (column.sticky) {
                headerClassName = 'stickyCol';
              }
              if (column.alignRight) {
                headerClassName = headerClassName + ' alignRight';
              }
              return (
                <FunctionField
                  cellClassName={column.sticky ? 'stickyCol' : undefined}
                  headerClassName={headerClassName.trim()}
                  label={
                    typeof column.label === 'function'
                      ? column.label({ resource })
                      : column.label
                  }
                  source={column.id}
                  sortBy={column.sortBy ? column.sortBy : null}
                  sortable={column.sortable}
                  key={`${column.id}_${index}`}
                  render={(record) => {
                    // We change the basePath here to make sure it is aligned with the resource
                    let newBasePath = basePath;
                    if (resource === Constants.RESOURCE_DOCUMENTS) {
                      newBasePath = `/${documentTypeCodeToResource(
                        get(record, `properties.${Metadata.documentTypeCode}`)
                      )}`;
                    }
                    return (
                      <div
                        style={merge(
                          columnWidthStyles(column.width),
                          column.cellStyle
                        )}
                        key={`${column.id}_${record.id}`}
                        className={`${column.id}_${record.id}`}
                      >
                        {column.renderCell({
                          record,
                          translate,
                          locale,
                          tz,
                          basePath: newBasePath,
                          readOnly: column.readOnly,
                          id: column.id,
                          alignRight: column.alignRight,
                        })}
                        {column.sticky === true && index === 0 && (
                          <>
                            {contextualToolbar &&
                              React.cloneElement(contextualToolbar, {
                                basePath,
                                resource,
                                locale,
                                record,
                                className: classes.contextualButtons,
                                tz,
                                ...rest,
                              })}
                          </>
                        )}
                      </div>
                    );
                  }}
                />
              );
            })}
        </Datagrid>
      </div>
    );
  }
};

export default ResponsiveGrid;
