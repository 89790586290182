import {
  cellStyle,
  DateInput,
  GenericDateField,
  GenericSimpleField,
  Metadata,
  widthPresets,
} from '@dx-ui/dx-common';
import React from 'react';
import { TextInput } from 'react-admin';

export const ExtendedInvoiceDXUIDField = (props) => (
  <GenericSimpleField {...props} />
);

export const ExtendedInvoiceIDField = (props) => (
  <GenericSimpleField {...props} />
);

export const ExtendedInvoiceIssueDateField = (props) => (
  <GenericDateField {...props} />
);

export const createColumnsExtendedInvoiceDetails = () => [
  {
    id: Metadata.extendedInvoiceDxuid,
    label: 'dxMessages.headers.extendedInvoiceDxuid',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <ExtendedInvoiceDXUIDField {...props} />,
  },
  {
    id: Metadata.extendedInvoiceId,
    label: 'dxMessages.headers.extendedInvoiceId',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    renderCell: (props) => <ExtendedInvoiceIDField {...props} />,
  },
  {
    id: Metadata.extendedInvoiceIssueDate,
    label: 'dxMessages.headers.extendedInvoiceIssueDate',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <ExtendedInvoiceIssueDateField {...props} />,
  },
];

export const createExtendedInvoiceDetailsFilters = () => [
  {
    id: Metadata.extendedInvoiceId,
    filter: (props) => (
      <TextInput
        source={Metadata.extendedInvoiceId}
        label='dxMessages.headers.extendedInvoiceId'
        resettable
        {...props}
      />
    ),
  },
  {
    id: Metadata.extendedInvoiceIssueDate,
    filter: (props) => (
      <DateInput
        source={Metadata.extendedInvoiceIssueDate}
        label='dxMessages.headers.extendedInvoiceIssueDate'
        {...props}
      />
    ),
  },
];
