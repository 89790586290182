import { ArrayInput } from '@dx-ui/dx-common';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { get } from 'lodash';
import { FormDataConsumer, useTranslate } from 'react-admin';
import { OrderService } from '../../../services/OrderService';
import { useCommonFieldStyles } from './CommonFieldStyles';
import { FieldComponentProps } from './FieldComponentProps';
import CustomSimpleFormIterator from './OrderSimpleFormIterator';
import TaxTotals from './TaxTotals';

const useStyles = makeStyles(
  (theme) => ({
    linesMaxWidth: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
  }),
  { name: 'OrderDocLinesTable' }
);
interface OrderDocLinesTableProps extends FieldComponentProps {
  renderItems: any;
}
/**
 * Renders a lines table for Orders.
 */
const OrderDocLinesTable = (props: OrderDocLinesTableProps) => {
  const {
    field,
    readOnly: readOnlyForm,
    basePath,
    renderItems,
    record,
  } = props;

  const translate = useTranslate();
  const classes = useStyles(props);
  const commonFieldClasses = useCommonFieldStyles(props);

  const options = field.options;
  const readOnly = !!readOnlyForm || !!options?.readOnly;

  const sanitizeArrayInputProps = ({
    selectValues,
    template,
    renderItems,
    classes,
    ...rest
  }: any) => rest;

  const sanitizeItemProps = ({ field, renderItems, classes, ...rest }: any) =>
    rest;

  const disableRemove = (record?.lines?.length ?? 0) <= 1;

  return (
    <Grid container item direction='column' spacing={2}>
      {
        <Grid item>
          <Typography className={commonFieldClasses.header} variant='h6'>
            {options?.title &&
              field.title &&
              translate(field.title, { _: field.title })}
          </Typography>
        </Grid>
      }
      <Grid item>
        <Paper square className={commonFieldClasses.paper}>
          <Grid container direction='column'>
            <Grid container item spacing={2} wrap='nowrap'>
              {field?.topItems &&
                renderItems(field.topItems, sanitizeItemProps(props))}
            </Grid>

            {field.items && (
              <Grid
                container
                item
                className={classNames(classes.linesMaxWidth)}
              >
                <ArrayInput {...sanitizeArrayInputProps(props)} label={''}>
                  <CustomSimpleFormIterator
                    disableAdd={readOnly || !(options?.addable ?? true)}
                    disableRemove={
                      readOnly || !(options?.removable ?? true) || disableRemove
                    }
                    createNewLine={(data) => {
                      const monoVat: number | undefined = get(
                        data,
                        OrderService.MONO_VAT_FIELD
                      );
                      return OrderService.initNewLine(monoVat);
                    }}
                  >
                    {renderItems(field.items, {
                      ...{ ...sanitizeItemProps(props), basePath },
                    })}
                  </CustomSimpleFormIterator>
                </ArrayInput>
              </Grid>
            )}
            <FormDataConsumer>
              {({ formData }) => (
                <>
                  <Grid item container direction='column' alignItems='flex-end'>
                    <TaxTotals data={formData} />
                  </Grid>
                </>
              )}
            </FormDataConsumer>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OrderDocLinesTable;
