import { isPspAdministrator } from '@dx-ui/dx-common';
import { IconButton, TextField, Tooltip } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import { Record, linkToRecord } from 'ra-core';
import { FC, ReactElement, useState } from 'react';
import {
  Button,
  ButtonProps,
  useNotify,
  usePermissions,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '18px',
  },
}));

const CreateTicketButton: FC<EditButtonProps> = ({
  basePath = '',
  label = 'dxMessages.monitoring.efactura.createTask',
  record,
  icon = defaultIcon,
  ...rest
}) => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  const translate = useTranslate();
  const [display, setDisplay] = useState(false);
  const [ticketName, setTicketName] = useState('');
  const recordJson = useRecordContext();
  const showButton = isPspAdministrator(permissions);
  const classes = useStyles();
  if (!showButton) {
    return null;
  }

  const show = () => {
    setDisplay(true);
  };

  const ignore = (id, ticketName) => {
    const request = new Request(
      `/efactura/monitoring/create/` + id + '?ticketName=' + ticketName,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        }),
        credentials: 'include',
      }
    );
    fetch(request).then((response) => {
      if (response.status < 200 || response.status >= 300) {
        // eslint-disable-next-line no-console
        notify('Could not update.', 'error');
      } else {
        notify('Updated.');
      }
    });
  };

  return (
    <div>
      {!display && (
        <div>
          <IconButton
            //label={label}
            onClick={show}
          >
            <Tooltip title={translate(label)} arrow className={classes.tooltip}>
              {icon}
            </Tooltip>
          </IconButton>
        </div>
      )}
      {display && (
        <div>
          <TextField
            label='Ticket name:'
            value={ticketName}
            onChange={(event) => setTicketName(event.target.value)}
            autoFocus
          />
          <Button
            component={Link}
            to={linkToRecord(basePath, recordJson && recordJson.id)}
            label='Send'
            onClick={(e) => {
              e.stopPropagation();
              if (recordJson !== undefined) {
                ignore(recordJson.id, ticketName);
              }
            }}
            {...(rest as any)}
          >
            {saveIcon}
          </Button>
        </div>
      )}
    </div>
  );
};

const defaultIcon = (
  <CreateIcon
    color='primary'
    style={{
      width: '20px',
      height: '20px',
    }}
  />
);
const saveIcon = <SaveIcon />;

interface Props {
  basePath?: string;
  record?: Record;
  icon?: ReactElement;
}

export type EditButtonProps = Props & ButtonProps & MuiButtonProps;

CreateTicketButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
};

export default CreateTicketButton;
