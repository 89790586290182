import {
  AppColors,
  FeatureFlagsToggler,
  PreviewButton as previewButton,
  RelatedDocuments,
  userBaseRoutes,
  UserRoles,
} from '@dx-ui/dx-common';
import { Route } from 'react-router-dom';
import {
  createColumnsProcessDetails,
  createCommonColumns,
} from '../../aspects';
import {
  DownloadButton as downloadButton,
  ShowMetadataButton as showMetadataButton,
} from '../../shared';
import { DespatchAdviceCreate } from '../despatchAdvice';
import { InvoiceCreate } from '../invoice';
import OrderCreate from '../order/OrderCreate';
import ReceiptAdviceCreate from '../receiptAdvice/ReceiptAdviceCreate';
import { WayBillCreate } from '../waybill';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route
    path='/:resource/:id/related-documents'
    render={(props) => (
      <RelatedDocuments
        columnsProcessDetails={createColumnsProcessDetails()}
        commonColumns={createCommonColumns()}
        showMetadataButton={showMetadataButton}
        downloadButton={downloadButton}
        previewButton={previewButton}
      />
    )}
  />,
  <Route path='/**/create-invoice/:recipientId' component={InvoiceCreate} />,
  <Route path='/**/create-waybill/:recipientId' component={WayBillCreate} />,
  <Route
    path='/**/create-receiptAdvice/:recipientId'
    component={ReceiptAdviceCreate}
  />,
  <Route path='/**/create-order/:recipientId' component={OrderCreate} />,
  <Route
    path='/**/create-despatchAdvice/:recipientId'
    component={DespatchAdviceCreate}
  />,
  <Route path='/theme' component={AppColors} />,
  <Route
    path='/experimental'
    render={() => (
      <FeatureFlagsToggler product={UserRoles.DXPURCHASE_PRODUCT} />
    )}
  />,
  ...userBaseRoutes,
];
