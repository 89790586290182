import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountCreate from './AccountCreate';
import AccountEdit from './AccountEdit';
import AccountList from './AccountList';

/**
 * The <ReactAdmin /> configuration-accounts resource.
 */
const account = {
  icon: AccountCircleIcon,
  list: AccountList,
  edit: AccountEdit,
  create: AccountCreate,
};

export default account;
