import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import { FC } from 'react';
import {
  Create,
  FieldProps,
  SaveButton,
  SimpleForm,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin';
import { Regulator } from '../types';
import { RegulatorData } from './RegulatorData';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

/**
 * The menu with regulator acccess interface.
 *
 * Only visible/editable for Comapny administrators.
 */
const RegulatorCreate: FC<FieldProps<Regulator>> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onFailure = (error) => {
    notify(
      `resources.regulatorAccess.create.errors.${error?.body?.message}`,
      'error',
      {
        _:
          error?.body?.message !== ''
            ? error?.body?.message
            : error?.body?.error,
      }
    );
  };

  const onSuccess = (response) => {
    notify(
      'resources.regulatorAccess.create.notifications.successMessage',
      'success',
      {
        approverEmail: response?.data?.approverEmail,
      },
      false,
      5000
    );
    redirect('list', props.basePath);
  };

  return (
    <Create
      title={'resources.regulatorAccess.menu'}
      onSuccess={onSuccess}
      onFailure={onFailure}
      component='div'
      {...props}
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <FormLayout />
      </SimpleForm>
    </Create>
  );
};

const FormLayout: FC<FieldProps<Regulator>> = (props) => {
  const { record } = props;

  if (!record) return null;

  return (
    <Grid container direction='column'>
      <Grid item>
        <RegulatorData />
      </Grid>
    </Grid>
  );
};

const CustomToolbar = (props) => {
  const classes = useStyles();
  const { record } = props;

  if (!record) return null;

  return (
    <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
      <SaveButton
        disabled={props.invalid}
        label='resources.regulatorAccess.list.actions.save'
        icon={<SendIcon />}
      />
    </Toolbar>
  );
};
export default RegulatorCreate;
