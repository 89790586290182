const ColumnConstants = {
  // Delivery Table
  DELIVERY_ID: 'DeliveryId',
  DELIVERY_START_DATE: 'DeliveryStartDate',
  DELIVERY_END_DATE: 'DeliveryEndDate',
  DELIVERY_ACTUAL_DATE: 'ActualDeliveryDate',
  DELIVERY_QUANTITY: 'DeliveryQuantity',
  DELIVERY_TABLE: 'DeliveryTable',
  // Order Lines Table
  ORDER_ITEM_ID: 'LineItemID',
  ORDER_ITEM_DESC: 'ItemDescription',
  ORDER_BUYER_ID: 'BuyersItemIdentification',
  ORDER_QUANTITY: 'Quantity',
  ORDER_UNIT_CODE: 'UnitCode',
  ORDER_PRICE: 'OrderPrice',
  ORDER_BEST_BEFORE_DATE: 'BestBeforeDate',
};

export { ColumnConstants };
