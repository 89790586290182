import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import get from 'lodash/get';
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  useLocale,
  useTranslate,
} from 'react-admin';
import { Constants, Metadata } from '../../constants';
import useTz from '../../intl/useTz';
import { DxTheme } from '../../types';
import {
  columnWidthStyles,
  documentTypeCodeToResource,
  merge,
} from '../../utils';
import RowsContextualButtons from './RowsContextualButtons';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    headerCell: {
      padding: '5px 10px',
      verticalAlign: 'middle',
      '&.alignRight': {
        textAlign: 'right',
      },
      '&.alignRight span[role=button]': {
        // sortable columns
        justifyContent: 'flex-end',
      },
      '&:last-child': {
        padding: '5px 10px',
      },
      '& span[role=button]': {
        // https://docprocess.atlassian.net/browse/DXPOR-349
        verticalAlign: 'unset',
        display: 'flex',
      },
    },
    row: {
      // Manages the hover animation for List items (row property of Material-UI CSS API) defined with class showOnHover
      '& .showOnHover': {
        visibility: 'hidden',
      },
      '&:hover .showOnHover': {
        visibility: 'visible',
      },
      '&:hover .stickyCol': {
        backgroundColor: theme.app.tableOnHoverBkgColor, // set opacity and color like row hover one.
      },
      '&:hover td:first-child': {
        backgroundColor: theme.app.tableOnHoverBkgColor, // set opacity and color like row hover one.
      },
      verticalAlign: 'text-bottom',
    },
    headerRow: {
      '&:hover .stickyCol': {
        backgroundColor: theme.app.tableBkgColor,
      },
    },
    view: {
      padding: theme.spacing(2),
    },
    header: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    tableScroll: {
      // wrapper div css class
      position: 'relative',
      // width: 'calc(100vw - 335px)',
      zIndex: 1,
      overflow: 'auto',
      '& table': {
        borderCollapse: 'separate',
      },
    },
    headerCell_withStickyCols: {
      '&.stickyCol': {
        background: theme.app.tableBkgColor,
        position: 'sticky',
        left: 0,
        top: 0,
        zIndex: 20,
      },
    },
    row_withStickyCols: {
      '& td:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 20,
        backgroundColor: theme.app.tableBkgColor,
      },
    },
    headerRow_withStickyCols: {
      '& th:first-child': {
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 30,
        backgroundColor: theme.app.tableBkgColor,
      },
    },
  }),
  { name: 'Rows' }
);

export enum ListType {
  LINKED = 'linked',
  MESSAGE = 'message',
  ATTACH = 'attach',
  RELATED = 'related',
}

const buildIds = (lines) => {
  return Object.keys(lines);
};

export interface RowsProps {
  commonColumns: any;
  basePath: any;
  resource: any;
  documents: any;
  showMetadataButton: any;
  downloadButton: any;
  previewButton: any;
  setSort: any;
  currentSort: any;
  specificResource: any;
  listType: any;
  onDelete?: any;
  isLoading?: boolean;
  redirectOnDelete?: boolean;
  refreshOnDelete?: boolean;
  isDeletionAllowed?: boolean;
}

/**
 * Renders the rows with the alwaysOn columns and the contextual buttons.
 * @param {array} commonColumns list of common columns with regards to the model aspects.
 * @param {string} basePath react-admin basePath
 * @param {object} resource resource
 * @param {array} documents array of document metadata representing a document
 * @param {object} showMetadataButton Component to render the button to show the document metadata
 * @param {object} downloadButton Component to render the button to download the document representation
 * @param {object} setSort Datagrid sorting policy
 * @param {object} currentSort current sort
 * @param {string} specificResource target document resource
 * @param {string} listType tag to help distinguishing the document list
 * @param {boolean} redirectOnDelete default to true
 * @param {boolean} refreshOnDelete default to true,
 * @param {boolean} isLoading
 * @param {boolean} isDeletionAllowed Contextual delete button display management. Default to true,
 */
export const Rows = ({
  commonColumns,
  basePath,
  resource,
  documents,
  showMetadataButton,
  downloadButton,
  previewButton,
  setSort,
  currentSort,
  specificResource,
  listType,
  onDelete,
  redirectOnDelete = true,
  refreshOnDelete = true,
  isLoading,
  isDeletionAllowed = true,
  ...rest
}: RowsProps) => {
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles();
  const tz = useTz();

  const ids = buildIds({ ...documents });
  return (
    <div className={classes.tableScroll}>
      <ListContextProvider
        value={{
          ids,
          data: { ...documents },
          currentSort,
          setSort,
          resource,
          selectedIds: [],
        }}
      >
        <Datagrid
          classes={{
            headerRow: classNames(
              classes.headerRow,
              classes.headerRow_withStickyCols
            ),
            headerCell: classNames(
              classes.headerCell,
              classes.headerCell_withStickyCols
            ),
            row: classNames(classes.row, classes.row_withStickyCols),
          }}
          {...rest}
        >
          {commonColumns.map((column, index) => {
            if (
              (specificResource === Constants.RESOURCE_WEBINVOICE ||
                specificResource === Constants.RESOURCE_WEBWAYBILL ||
                specificResource === Constants.RESOURCE_WEBDESPATCH_ADVICE ||
                specificResource === Constants.RESOURCE_WEBRECEIPT_ADVICE ||
                specificResource === Constants.RESOURCE_WEBORDER) &&
              column.id === Metadata.processDocumentFormatType
            ) {
              return null;
            }
            if (
              (listType === ListType.ATTACH &&
                column.id === Metadata.processStatus) ||
              (listType === ListType.MESSAGE &&
                column.id === Metadata.processStatus)
            ) {
              return null;
            }
            let headerClassName = '';
            if (column.id === 'name') {
              headerClassName = 'stickyCol';
            }
            if (column.alignRight) {
              headerClassName = headerClassName + ' alignRight';
            }
            return (
              <FunctionField
                label={
                  typeof column.label === 'function'
                    ? column.label({ resource })
                    : column.label
                }
                cellClassName={column.id === 'name' ? 'stickyCol' : undefined}
                headerClassName={headerClassName.trim()}
                source={column.id}
                key={`${column.id}_${index}`}
                sortable={column.sortable}
                render={(record) => {
                  // We change the basePath here to make sure it is aligned with the resource
                  const newBasePath = `/${documentTypeCodeToResource(
                    get(record?.properties, Metadata.documentTypeCode),
                    get(record?.properties, Metadata.processDocumentFormatType)
                  )}`;
                  return (
                    <div
                      style={merge(
                        columnWidthStyles(column.width),
                        column.cellStyle
                      )}
                      key={`${column.id}_${index}`}
                    >
                      {column.renderCell({
                        record,
                        translate,
                        locale,
                        tz,
                        basePath: newBasePath,
                        readOnly: column.readOnly ? column.readOnly : false,
                        id: column.id,
                      })}
                      {index === 0 && (
                        <>
                          <RowsContextualButtons
                            basePath={basePath}
                            resource={resource}
                            record={record}
                            showMetadataButton={showMetadataButton}
                            downloadButton={downloadButton}
                            previewButton={previewButton}
                            specificResource={specificResource}
                            refreshOnDelete={refreshOnDelete}
                            redirectOnDelete={redirectOnDelete}
                            renderAsMenu={
                              listType === ListType.LINKED ? true : false
                            }
                            onDelete={onDelete}
                            isDeletionAllowed={isDeletionAllowed}
                          />
                        </>
                      )}
                    </div>
                  );
                }}
              />
            );
          })}
        </Datagrid>
      </ListContextProvider>
    </div>
  );
};
