// TODO: the Save button is not grayed after saving.
import { Constants } from '@dx-ui/dx-common';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  DataProvider,
  FieldProps,
  FormTab,
  SaveButton,
  SaveContextProvider,
  TabbedForm,
  Title,
  Toolbar,
  useAuthProvider,
  useDataProvider,
  useNotify,
  usePermissions,
  UserIdentity,
  useTranslate,
} from 'react-admin';
import { isCompanyAdministrator, isPspAdministrator } from '../configuration';
import Job from '../job/Job';
import ChangePassword from './ChangePassword';
import ProfileInfo from './ProfileInfo';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The dataProvider decorator for handling user profiles.
 */
const addUserProfileOverrides = (dataProvider: DataProvider): DataProvider => ({
  ...dataProvider,
  async updateUserProfile({ data }: { data: UserIdentity }) {
    const ur = await dataProvider
      // update() returns the user account, updated with the info contained
      // in the submitted user profile.
      .update('configuration-userprofiles', {
        id: data.id,
        data,
        previousData: { id: data.id },
      });

    // Override the account in the local storage with the updated one.
    localStorage.setItem(Constants.DX_USER_ACCOUNT, JSON.stringify(ur.data));

    return ur;
  },
});

/**
 * The user profile.
 *
 * Inspired by https://marmelab.com/blog/2020/12/14/react-admin-v3-userprofile.html
 * but without the profile refresh (we only display the user email in the App bar and
 * email is immutable, so no need  to refresh).
 */
const ProfileEdit = () => {
  const translate = useTranslate();
  const dataProvider = addUserProfileOverrides(useDataProvider());
  const notify = useNotify();
  const [saving, setSaving] = useState<boolean>();
  const { permissions } = usePermissions();
  const authProvider = useAuthProvider();
  const identity: any = authProvider.getIdentity?.();

  // Need a referential equality on 'saveContext' below so use callback and memo hooks.
  const handleSave = useCallback(
    (values) => {
      setSaving(true);
      dataProvider
        .updateUserProfile({ data: values })
        .then(() => {
          setSaving(false);
          notify('resources.profile.edit.notifications.updated', 'info');
        })
        .catch((error) => {
          setSaving(false);
          notify(
            error.message || 'resources.profile.edit.notifications.error',
            'warning'
          );
        });
    },
    [dataProvider, notify]
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving,
    }),
    [saving, handleSave]
  );

  if (!identity || !permissions) {
    return null;
  }

  return (
    <SaveContextProvider value={saveContext}>
      <Title title='resources.profile.edit.title' />
      <TabbedForm
        save={handleSave}
        toolbar={<UserEditToolbar />}
        record={identity}
        validate={validateProfile}
      >
        <FormTab
          label={
            identity?.hasDocProcessIdentity
              ? 'resources.profile.edit.tabs.personalInfoAndPassword'
              : 'resources.profile.edit.tabs.personalInfo'
          }
        >
          <SanitizedGrid
            container
            spacing={2}
            direction='column'
            style={{ width: '100%', marginTop: '1em' }}
          >
            {isPspAdministrator(permissions) && (
              <Grid item>
                <Alert severity='info' style={{ margin: '1em' }}>
                  {translate('resources.profile.edit.pspAdministratorAlert')}
                </Alert>
              </Grid>
            )}
            {isCompanyAdministrator(permissions) && (
              <Grid item>
                <Alert severity='info' style={{ margin: '1em' }}>
                  {translate(
                    'resources.profile.edit.companyAdministratorAlert'
                  )}
                </Alert>
              </Grid>
            )}
            <Grid item>
              <ProfileInfo />
            </Grid>
            {identity?.hasDocProcessIdentity && (
              <Grid item>
                <ChangePassword />
              </Grid>
            )}
          </SanitizedGrid>
        </FormTab>
        <FormTab label='resources.profile.edit.tabs.job'>
          <JobTab />
        </FormTab>
      </TabbedForm>
    </SaveContextProvider>
  );
};

const JobTab: FC<FieldProps<UserIdentity>> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  const { record } = props;

  if (!record) return null;

  return (
    <Paper className={classes.paper} style={{ marginTop: '1em' }}>
      {record?.job?.attributes?.length > 0 && (
        // @ts-ignore
        <Job companyId={record.company.id} hasShow={true} {...props} />
      )}
      {(!record?.job?.attributes || record.job.attributes.length === 0) && (
        <Typography>
          {translate('resources.accounts.job.noJob', {
            _: 'No job configured.',
          })}
        </Typography>
      )}
    </Paper>
  );
};

// A custom toolbar to remove the default delete button.
const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const validateProfile = (values: any) => {
  const errors: any = {};

  // Either both old and new passwords are set or none.
  if (values.password && !values.newPassword) {
    errors.newPassword = 'Required';
  }
  if (values.newPassword && !values.password) {
    errors.password = 'Required';
  }

  return errors;
};

const SanitizedGrid = ({ basePath, ...props }: any) => {
  return <Grid {...props} />;
};

export default ProfileEdit;
