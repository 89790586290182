import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-admin';

const styles = (theme): any => ({
  button: {
    marginLeft: 4,
  },
  icon: {
    marginRight: 4,
  },
});

const ToolbarButton = (props) => {
  const { translate, label = 'a.action.edit', classes, loading } = props;

  let icon = props.icon;
  if (!icon) {
    icon = <EditIcon />;
  }

  if (loading) {
    icon = <CircularProgress size={24} />;
  }

  const sanitizeButtonProps = ({
    basePath,
    translate,
    handleSubmit,
    handleSubmitWithRedirect,
    submitOnEnter,
    classes,
    loading,
    onSave,
    ...rest
  }) => rest;

  return (
    <Button
      color='primary'
      variant='contained'
      type='button'
      classes={{ root: classes.button }}
      {...sanitizeButtonProps(props)}
    >
      {React.cloneElement(icon, { className: classes.icon })}
      {translate(label, { _: label })}
    </Button>
  );
};

ToolbarButton.propTypes = {
  label: PropTypes.string,
  translate: PropTypes.func,
  className: PropTypes.string,
};

const enhance = compose(translate, withStyles(styles));
export default enhance(ToolbarButton);
