export interface AuditTrailDto {
  /**
   * Document ID.
   */
  id: string;
  /**
   * Nodes of trail.
   */
  items: AuditTrailItemDto[];
}

export interface AuditTrailItemDto {
  /**
   * Event Type.
   */
  type: string;

  category: Category;

  /**
   * Date on event was fired.
   */
  timestamp: number;

  /**
   * Description information for internationalisation.
   */
  description: AuditTrailItemDescriptionDto;

  /**
   * The real payload of the business event behind the trail item.
   */
  payload: any;
}

export interface AuditTrailItemDescriptionDto {
  /**
   * i18n key for front application.
   */
  key: string;

  /**
   * i18n key for front application.
   */
  message: string;

  /**
   * i18n message parameters.
   */
  params: any;
}

export enum Category {
  FLOW_STARTED = 'FLOW_STARTED',
  DOCUMENT_RECEIVED = 'DOCUMENT_RECEIVED',
  DOCUMENT_SENT = 'DOCUMENT_SENT',
  THIRD_PARTY_ACKNOWLEDGMENT = 'THIRD_PARTY_ACKNOWLEDGMENT',
  DOCUMENT_REPRESENTATION_GENERATED = 'DOCUMENT_REPRESENTATION_GENERATED',
  DOCUMENT_ERROR = 'DOCUMENT_ERROR',
  DOCUMENT_WARNING = 'DOCUMENT_WARNING',
  DOCUMENT_VALIDATION = 'DOCUMENT_VALIDATION',
  WAIT_FOR_USER_ACTION = 'WAIT_FOR_USER_ACTION',
  USER_ACTION_DONE = 'USER_ACTION_DONE',
  USER_ACTION_DELETED = 'USER_ACTION_DELETED',
  FLOW_END = 'FLOW_END',
  INCIDENT = 'INCIDENT',
}
