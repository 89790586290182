import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import {
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormTab,
  SaveButton,
  TabbedForm,
  Toolbar,
  useNotify,
  usePermissions,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { isPspAdministrator } from '../configuration';
import { Account } from '../types';
import AccountChangeHistoryTab from './edition/AccountChangeHistoryTab';
import UsageRightTab from './edition/UsageRightTab';
import UserTab from './edition/UserTab';

/**
 * The edition of a user account.
 *
 * Only visible for administrators.
 * The aside panel contains the last login attempts of the user so the administrator
 * can make his minds about the user activity before editing his account.
 */
const AccountEdit: FC<EditProps> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('resources.accounts.edit.notifications.updated');
    refresh();
  };

  const onFailure = (error) => {
    if (error?.body?.key) {
      notify(error.body.key, 'error', error.body.params);
    } else if (error?.message) {
      notify(error.message, 'error');
    } else {
      notify('ra.notification.http_error', 'error');
    }
  };

  return (
    <Edit
      title={<AccountTitle />}
      component='div'
      undoable={false}
      onSuccess={onSuccess}
      onFailure={onFailure}
      {...props}
    >
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab label='resources.accounts.edit.tabs.user.title'>
          <UserTab />
        </FormTab>
        <FormTab label='resources.accounts.edit.tabs.usageRights.title'>
          <UsageRightTab />
        </FormTab>
        <FormTab label='resources.accounts.edit.tabs.history.title'>
          <AccountChangeHistoryTab />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const AccountTitle: FC<FieldProps<Account>> = ({ record }) => {
  const { loaded, permissions } = usePermissions();
  const translate = useTranslate();

  if (!record || !loaded || !permissions) return null;

  // Show the account company only for PSP administrators (company administrator only see
  // the accounts belonging to their own company, so no need to repeat the company name).
  return isPspAdministrator(permissions) ? (
    <span>
      {translate('resources.accounts.edit.titleWithCompany', {
        username: record.username,
        companyName: record.company.name,
      })}
    </span>
  ) : (
    <span>
      {translate('resources.accounts.edit.titleWithoutCompany', {
        username: record.username,
      })}
    </span>
  );
};

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

// The only way to define our own confirmation labels on delete is to provide
// a custom toolbar.
const CustomToolbar = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { record } = props;

  if (!record) return null;

  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton disabled={props.pristine} />
      <DeleteButton
        undoable={false}
        confirmTitle={translate('resources.accounts.edit.deleteConfirmTitle', {
          username: record.username,
        })}
      />
    </Toolbar>
  );
};

export default AccountEdit;
