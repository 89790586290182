import {
  DocumentTypeCode,
  EmptyValue,
  GenericLabeledDateField,
  Metadata,
  PriceTextField,
  ReceiptAdviceSubType,
  SimpleLabeledField,
  Ubl,
} from '@dx-ui/dx-common';
import { ReceiptAdviceModel } from '@dx-ui/lib-oasis-ubl-2.1/src/ReceiptAdviceModel';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cloneDeep, get } from 'lodash';
import { ListContextProvider, Translate, useDataProvider } from 'ra-core';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Datagrid,
  FunctionField,
  Loading,
  TextField,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import {
  AlfrescoDocumentService,
  DocumentServiceFactory,
  LineProcessor,
  ReceiptAdviceService,
  ReceiptAdviceServiceCreator,
} from '../../services';
import { AlfrescoContent, P2pData, P2pPreviewData } from '../../shared/types';
import { CommaSeparator } from '../../shared/webForms/utils';
import { P2pUtils } from '../common/P2pUtils';
import {
  AddressField,
  AddressParts,
  getBuyerCustomerAddress,
  getSellerSupplierAddress,
} from '../common/PreviewAddress';
import { useCellStyle, useCommonPreviewStyles } from '../common/PreviewStyles';

export const useStyles = makeStyles(
  (theme) => ({
    lineItem: { minWidth: '8em' },
    title: { fontSize: '2em' },
    contactItem: { paddingRight: theme.spacing(2) },
  }),
  { name: 'ReceiptAdvicePreview' }
);

/**
 *
 * @param rejectReasonsNode UBL rejectReasons Object
 * @param translate
 * @returns a CSV string with translated reject reasons
 */
const buildRejectReasons = (
  rejectReasonsNode: any[any],
  translate: Translate
): string => {
  let rejectReasons: string[] = [];
  if (rejectReasonsNode) {
    rejectReasonsNode.forEach((r) => {
      rejectReasons.push(
        translate(
          `dxMessages.receiptAdvices.returnReason${Object.values<string>(r)[0]}`
        )
      );
    });
    return rejectReasons.join(', ');
  } else {
    return ''; // TODO return EmptyValue => to sync with test auto
  }
};

const Lines = ({
  loading,
  currencyID,
  resource,
  linesView,
  lineIds,
  isUbl21,
  documentSubType,
}) => {
  const translate = useTranslate();
  const commonClasses = useCommonPreviewStyles();
  const cellStyleClasses = useCellStyle();
  const classes = useStyles();

  const sourceQuantity = isUbl21
    ? 'ReceivedQuantity[0]._'
    : Ubl.quantityReceiptAdvice;
  const sourceUnitCode = isUbl21
    ? 'ReceivedQuantity[0].unitCode'
    : Ubl.unitCodeReceiptAdvice;

  const displaySGRTax =
    documentSubType === ReceiptAdviceSubType.GoodsReceiptAdvice &&
    Object.values(linesView).some((_: any) => LineProcessor.isLineSGR(_));

  return (
    <Grid
      container
      direction='column'
      classes={{ container: commonClasses.section }}
    >
      <Grid container item>
        {currencyID && (
          <Grid container item xs={2} justify='flex-start'>
            <SimpleLabeledField
              label={translate('dxMessages.headers.currency')}
              data={currencyID}
              oneLine
              className={Metadata.currency}
            />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Grid item>
          {!loading && !!linesView && (
            <ListContextProvider
              value={{
                ids: lineIds,
                data: linesView,
                currentSort: { field: 'nr', order: 'ASC' },
                resource,
                selectedIds: [],
              }}
            >
              <Datagrid
                classes={{
                  row: commonClasses.row,
                  rowCell: commonClasses.linesCell,
                  headerCell: commonClasses.headerCell,
                  tbody: commonClasses.tbody,
                }}
              >
                <TextField
                  source='nr'
                  label='dxMessages.headers.number'
                  sortable={false}
                />
                {documentSubType === ReceiptAdviceSubType.GoodsReturnAdvice && (
                  <FunctionField
                    source={'RejectReason'}
                    render={(record) => (
                      <Typography>
                        {buildRejectReasons(
                          get(
                            record,
                            isUbl21 ? 'RejectReason' : 'rejectReason'
                          ),
                          translate
                        )}
                      </Typography>
                    )}
                    label='dxMessages.receiptAdvices.goodsReturnReason'
                    sortable={false}
                  />
                )}
                <TextField
                  source={
                    isUbl21
                      ? 'Item[0].StandardItemIdentification[0].ID[0]._'
                      : Ubl.receivedCodeStandard
                  }
                  label='dxMessages.headers.codeStandard'
                  sortable={false}
                  className={classes.lineItem}
                  // TODO set emptyText={EmptyValue} => to sync with test auto
                />
                <TextField
                  source={
                    isUbl21
                      ? 'Item[0].Description[0]._'
                      : Ubl.receivedDescription
                  }
                  label='dxMessages.headers.description'
                  sortable={false}
                  classes={{ root: cellStyleClasses.cellWrapped }}
                  className={classes.lineItem}
                  // TODO set emptyText={EmptyValue} => to sync with test auto
                />
                <TextField
                  source={
                    isUbl21
                      ? 'Item[0].SellersItemIdentification[0].ID[0]._'
                      : Ubl.receivedCodeSupplier
                  }
                  label='dxMessages.headers.codeSupplier'
                  sortable={false}
                  className={classes.lineItem}
                  // TODO set emptyText={EmptyValue} => to sync with test auto
                />
                <TextField
                  source={
                    isUbl21
                      ? 'Item[0].BuyersItemIdentification[0].ID[0]._'
                      : Ubl.receivedCodeClient
                  }
                  label='dxMessages.headers.codeClient'
                  sortable={false}
                  className={classes.lineItem}
                  // TODO set emptyText={EmptyValue} => to sync with test auto
                />
                <TextField
                  source={sourceQuantity}
                  label='dxMessages.headers.quantity'
                  sortable={false}
                  // TODO set emptyText={EmptyValue} => to sync with test auto
                />
                <TextField
                  source={sourceUnitCode}
                  label='dxMessages.headers.um'
                  sortable={false}
                  // TODO set emptyText={EmptyValue} => to sync with test auto
                />
                {documentSubType ===
                  ReceiptAdviceSubType.GoodsReceiptAdvice && (
                  <PriceTextField
                    headerClassName='alignRight'
                    cellClassName='alignRight'
                    source={Ubl.greenTaxPerUnitAmount}
                    label='dxMessages.headers.unitaryGreenTax'
                    sortable={false}
                  />
                )}
                {displaySGRTax && (
                  <PriceTextField
                    headerClassName='alignRight'
                    cellClassName='alignRight'
                    source={Ubl.sgrTaxAmount}
                    label='dxMessages.headers.sgrTax'
                    sortable={false}
                  />
                )}
                <PriceTextField
                  headerClassName='alignRight'
                  cellClassName='alignRight'
                  source={
                    isUbl21
                      ? 'Item[0].AdditionalItemProperty[0].ValueQuantity[0]._'
                      : Ubl.price
                  }
                  label='dxMessages.headers.price'
                  sortable={false}
                />
                {documentSubType === ReceiptAdviceSubType.GoodsReturnAdvice && (
                  <FunctionField
                    source={Ubl.vatPercentage}
                    label='dxMessages.headers.vat'
                    sortable={false}
                    render={(record) => {
                      const value = isUbl21
                        ? get(
                            record,
                            'Item[0].ClassifiedTaxCategory[0].Percent[0]._'
                          )
                        : get(record, Ubl.vatPercentage);
                      return (
                        <Typography>
                          {!isNaN(value) ? `${value}%` : EmptyValue}
                        </Typography>
                      );
                    }}
                    style={{ minWidth: '3em' }}
                  />
                )}
                {documentSubType === ReceiptAdviceSubType.GoodsReturnAdvice && (
                  <PriceTextField
                    headerClassName='alignRight'
                    cellClassName='alignRight'
                    source={
                      isUbl21
                        ? 'Item[0].AdditionalItemProperty[1].ValueQuantity[0]._'
                        : Ubl.totalWithoutVat
                    }
                    label='dxMessages.headers.totalWithoutVat'
                    sortable={false}
                  />
                )}
                {documentSubType === ReceiptAdviceSubType.GoodsReturnAdvice && (
                  <PriceTextField
                    headerClassName='alignRight'
                    cellClassName='alignRight'
                    source={
                      isUbl21
                        ? 'Item[0].AdditionalItemProperty[2].ValueQuantity[0]._'
                        : Ubl.totalVat
                    }
                    label='dxMessages.headers.totalVat'
                    sortable={false}
                  />
                )}
              </Datagrid>
            </ListContextProvider>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

interface PropsModel {
  record: P2pData;
  basePath?: string;
  toolbar?: ComponentType<any>;
  resource?: string;
}

const ContactInformation = ({ data, isUbl21, ...rest }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Grid container item xs={12} {...rest}>
      <Box>
        <Typography>{translate('dxMessages.headers.contact')}:</Typography>
        <SimpleLabeledField
          label={translate('dxMessages.headers.contactName')}
          data={get(data, isUbl21 ? 'Name[0]._' : 'name.value')}
          oneLine
          data-testid='contactName'
          className={classes.contactItem}
        />
        <SimpleLabeledField
          label={translate('dxMessages.headers.contactIDCardSeries')}
          data={get(data, isUbl21 ? 'ID[0]._' : 'id.value')}
          oneLine
          data-testid='contactIDCardSeries'
          className={classes.contactItem}
        />
        <SimpleLabeledField
          label={translate('dxMessages.headers.contactIDCardNumber')}
          data={get(data, isUbl21 ? 'ID[0].schemeID' : 'id.schemeID')}
          oneLine
          data-testid='contactIDCardNumber'
          className={classes.contactItem}
        />
        <SimpleLabeledField
          label={translate('dxMessages.headers.contactIDCardIssuer')}
          data={get(
            data,
            isUbl21 ? 'ID[0].schemeAgencyName' : 'id.schemeAgencyName'
          )}
          oneLine
          data-testid='contactIDCardIssuer'
          className={classes.contactItem}
        />
      </Box>
    </Grid>
  );
};

const ReceiptAdvicePreview = (props: PropsModel) => {
  const { basePath, record, toolbar } = props;

  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();
  const commonClasses = useCommonPreviewStyles(props);
  const resource = useResourceContext(props);

  const [data, setData] = useState<P2pPreviewData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isUbl21, setIsUbl21] = useState(false);

  const recadvService = useMemo(
    () =>
      DocumentServiceFactory.create(
        ReceiptAdviceServiceCreator,
        dataProvider
      ) as ReceiptAdviceService,
    [dataProvider]
  );

  const isUbl21Format = useCallback((data: any) => {
    if (data.ReceiptAdvice) {
      return true;
    }
    return false;
  }, []);

  const getDocumentSubType = useCallback(
    (data: any): ReceiptAdviceSubType | undefined => {
      let subType: ReceiptAdviceSubType | undefined;

      if (isUbl21Format(data)) {
        subType = get(data, 'ReceiptAdvice[0].ReceiptAdviceTypeCode[0]._');
      } else {
        subType = get(data, 'receiptAdviceTypeCode.value');
      }

      if (!subType) {
        subType = ReceiptAdviceSubType.GoodsReceiptAdvice;
      }

      return subType;
    },
    [isUbl21Format]
  );

  useEffect(() => {
    const getData = async () => {
      const nodeId: string | undefined = record.id;
      const isDraftDocument: boolean =
        AlfrescoDocumentService.isWebDocument(record);

      try {
        let properties: any = {};
        let ublProperties: any = {};
        let lines: any[];

        properties = cloneDeep(record?.properties ?? {});
        if (record?.ublProperties && record?.lines) {
          ublProperties = cloneDeep(record?.ublProperties);
          lines = cloneDeep(record?.lines);
        } else if (nodeId) {
          let results: AlfrescoContent;
          if (isDraftDocument) {
            results = await recadvService.loadDraft(nodeId);
          } else {
            results = await recadvService.load(nodeId);
          }

          ublProperties = results?.ublContent?.ublProperties;
          lines = results?.ublContent?.lines;
        } else {
          throw new Error('record id cannot be null!');
        }
        const linesView = P2pUtils.convertLines2ViewObject(lines);

        const previewData: P2pPreviewData = {
          id: nodeId,
          properties,
          ublProperties,
          linesView,
        };

        setIsUbl21(isUbl21Format(previewData.ublProperties));
        setData(previewData);
        setLoading(false);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(error);
        setLoading(false);
      }
    };
    if (!loading && !data && !error) {
      setLoading(true);
      getData();
    }
  }, [
    data,
    dataProvider,
    error,
    isUbl21Format,
    loading,
    recadvService,
    record,
  ]);

  if (error) {
    return (
      <Typography variant='h5'>
        {translate('dxMessages.error_messages.unable_to_display')}
      </Typography>
    );
  } else if (loading || !data) {
    return <Loading />;
  } else {
    const lineIds = Object.keys(data.linesView);
    let currencyID: string | undefined;

    // currency is read from the first line's value
    if (!!data.linesView) {
      if (isUbl21) {
        currencyID = get(
          data.linesView[0],
          'Item[0].AdditionalItemProperty[0].ValueQuantity[0].unitCode'
        );
      } else {
        currencyID = get(data.linesView[0], 'price.priceAmount.currencyID');
      }
    }

    const getBuyerAddress = (data: any) => {
      if (isUbl21) {
        return ReceiptAdviceService.buildAddressDetails(
          get(
            data,
            'ublProperties.ReceiptAdvice[0].BuyerCustomerParty[0].Party[0].PostalAddress[0]'
          )
        );
      } else {
        return getBuyerCustomerAddress(data);
      }
    };

    const getSupplierAddress = (data: any) => {
      if (isUbl21) {
        return ReceiptAdviceService.buildAddressDetails(
          get(
            data,
            'ublProperties.ReceiptAdvice[0].SellerSupplierParty[0].Party[0].PostalAddress[0]'
          )
        );
      } else {
        return getSellerSupplierAddress(data);
      }
    };

    const documentSubType = getDocumentSubType(data.ublProperties);

    const getInvoiceReferenceIndexNode = (data: any) => {
      let indexNode = -1;
      if (isUbl21) {
        if (
          (data.ublProperties as ReceiptAdviceModel).ReceiptAdvice[0]
            .AdditionalDocumentReference
        ) {
          (
            data.ublProperties as ReceiptAdviceModel
          ).ReceiptAdvice[0].AdditionalDocumentReference?.forEach(
            (addRef, index) => {
              if (
                addRef?.DocumentTypeCode?.[0]?._ === DocumentTypeCode.INVOIC
              ) {
                indexNode = index;
              }
            }
          );

          return indexNode;
        }
      } else {
        if (data.ublProperties.additionalDocumentReference) {
          data.ublProperties.additionalDocumentReference?.forEach(
            (addRef, index) => {
              if (addRef.documentTypeCode.value === DocumentTypeCode.INVOIC) {
                indexNode = index;
              }
            }
          );
        }
      }
      return indexNode;
    };

    return (
      <>
        <Paper square classes={{ root: commonClasses.paper }}>
          <Grid container justify='center'>
            <Grid item>
              <Typography variant='h6' className={classes.title}>
                {documentSubType === ReceiptAdviceSubType.GoodsReceiptAdvice &&
                  translate('dxMessages.receiptAdvices.document_label')}
                {documentSubType === ReceiptAdviceSubType.GoodsReturnAdvice &&
                  translate('dxMessages.receiptAdvices.document_label_return')}
                {documentSubType ===
                  ReceiptAdviceSubType.AcknowledgementOfReceipt &&
                  translate('dxMessages.receiptAdvices.document_label_ack')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container classes={{ container: commonClasses.section }}>
            <Grid item xs={2}>
              <SimpleLabeledField
                label={translate('dxMessages.receiptAdvices.number')}
                data={
                  isUbl21
                    ? get(data, 'ublProperties.ReceiptAdvice[0].ID[0]._')
                    : get(data, `properties.${Metadata.documentId}`)
                }
                className={Metadata.documentId}
              />
            </Grid>
            <Grid item xs={3}>
              <GenericLabeledDateField
                label={translate('dxMessages.headers.issueDate')}
                id={
                  isUbl21
                    ? 'ublProperties.ReceiptAdvice[0].IssueDate[0]._'
                    : Metadata.issueDate
                }
                record={data}
                className={Metadata.issueDate}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            direction='row'
            classes={{ container: commonClasses.graySection }}
            data-testid='supplier'
          >
            <Grid container item direction='column' xs={6}>
              <SimpleLabeledField
                label={translate('dxMessages.headers.buyer')}
                data={
                  get(data.properties, Metadata.issuerName) ||
                  get(
                    data,
                    'ublProperties.ReceiptAdvice[0].BuyerCustomerParty[0].Party[0].PartyName[0].Name[0]._'
                  )
                }
                oneLine
                className={Metadata.issuerName}
              />
              <SimpleLabeledField
                label={translate('dxMessages.headers.buyerId')}
                data={
                  get(data.properties, Metadata.issuerId) ||
                  get(
                    data,
                    'ublProperties.ReceiptAdvice[0].BuyerCustomerParty[0].Party[0].PartyIdentification[0].ID[0]._'
                  )
                }
                oneLine
                className={Metadata.issuerId}
              />
              <SimpleLabeledField
                label={translate('dxMessages.headers.gln')}
                data={get(
                  data,
                  isUbl21
                    ? 'ublProperties.ReceiptAdvice[0].BuyerCustomerParty[0].Party[0].EndpointID[0]._'
                    : Ubl.buyerCustomerGLN
                )}
                oneLine
                className={'buyerCustomerGLN'}
              />
              <AddressField
                address={getBuyerAddress(data)}
                missingPartIndicator={false}
                visibleParts={AddressParts.StreetName | AddressParts.CityName}
                className={'buyerCustomerAddress'}
              />
            </Grid>
            <Grid container item direction='column' xs={6}>
              <SimpleLabeledField
                label={translate('dxMessages.headers.deliveryLocationName')}
                data={
                  isUbl21
                    ? get(
                        data,
                        'ublProperties.ReceiptAdvice[0].DeliveryCustomerParty[0].Party[0].PartyName[0].Name[0]._'
                      )
                    : get(data.properties, Metadata.locationName)
                }
                oneLine
                className={Metadata.locationName}
              />
              <SimpleLabeledField
                label={translate('dxMessages.headers.gln')}
                data={get(
                  data,
                  isUbl21
                    ? 'ublProperties.ReceiptAdvice[0].DeliveryCustomerParty[0].Party[0].EndpointID[0]._'
                    : Ubl.deliveryCustomerGLN
                )}
                oneLine
                className={'deliveryCustomerGLN'}
              />
              {isUbl21 ? (
                <AddressField
                  label={translate('dxMessages.headers.locationAddress')}
                  address={ReceiptAdviceService.buildAddressDetails(
                    get(
                      data,
                      'ublProperties.ReceiptAdvice[0].DeliveryCustomerParty[0].Party[0].PostalAddress[0]'
                    )
                  )}
                  className={Metadata.locationAddress}
                />
              ) : (
                <SimpleLabeledField
                  label={translate('dxMessages.headers.locationAddress')}
                  data={
                    isUbl21
                      ? 'location address'
                      : get(data.properties, Metadata.locationAddress)
                  }
                  oneLine
                  className={Metadata.locationAddress}
                />
              )}
              <GenericLabeledDateField
                label={translate('dxMessages.headers.deliveryDate')}
                id={
                  isUbl21
                    ? 'ublProperties.ReceiptAdvice[0].Shipment[0].Delivery[0].ActualDeliveryDate[0]._'
                    : Ubl.shipmentDeliveryDate
                }
                record={data}
                oneLine
                className={'deliveryDate'}
              />
            </Grid>
            {documentSubType === ReceiptAdviceSubType.GoodsReturnAdvice && (
              <ContactInformation
                data={get(
                  data,
                  isUbl21
                    ? 'ublProperties.ReceiptAdvice[0].BuyerCustomerParty[0].Party[0].Contact[0]'
                    : 'ublProperties.buyerCustomerParty.party.contact'
                )}
                isUbl21={isUbl21}
                data-testid={'customer-contact'}
              />
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            direction='row'
            classes={{ container: commonClasses.graySection }}
          >
            <Grid container item xs={6} direction='column'>
              <SimpleLabeledField
                label={translate('dxMessages.headers.supplier')}
                data={
                  get(data.properties, Metadata.recipientName) ||
                  get(
                    data,
                    'ublProperties.ReceiptAdvice[0].SellerSupplierParty[0].Party[0].PartyName[0].Name[0]._'
                  )
                }
                oneLine
                className={Metadata.recipientName}
              />
              <SimpleLabeledField
                label={translate('dxMessages.headers.supplierId')}
                data={
                  get(data.properties, Metadata.recipientId) ||
                  get(
                    data,
                    'ublProperties.ReceiptAdvice[0].SellerSupplierParty[0].Party[0].PartyIdentification[0].ID[0]._'
                  )
                }
                oneLine
                className={Metadata.recipientId}
              />
              <SimpleLabeledField
                label={translate('dxMessages.headers.supplierReceiverCode')}
                data={get(
                  data,
                  isUbl21
                    ? 'ublProperties.ReceiptAdvice[0].SellerSupplierParty[0].CustomerAssignedAccountID[0]._'
                    : Ubl.sellerSupplierReceiverCode
                )}
                oneLine
                className={'supplierReceiverCode'}
              />
              <SimpleLabeledField
                label={translate('dxMessages.headers.gln')}
                data={get(
                  data,
                  isUbl21
                    ? 'ublProperties.ReceiptAdvice[0].SellerSupplierParty[0].Party[0].EndpointID[0]._'
                    : Ubl.sellerSupplierGLN
                )}
                oneLine
                className={'sellerSupplierGLN'}
              />
              <AddressField
                address={getSupplierAddress(data)}
                missingPartIndicator={false}
                visibleParts={AddressParts.StreetName | AddressParts.CityName}
                className={'supplierCustomerAddress'}
              />
            </Grid>
            <Grid container item xs={6} direction='column'></Grid>
            {documentSubType === ReceiptAdviceSubType.GoodsReturnAdvice && (
              <ContactInformation
                data={get(
                  data,
                  isUbl21
                    ? 'ublProperties.ReceiptAdvice[0].SellerSupplierParty[0].Party[0].Contact[0]'
                    : 'ublProperties.sellerSupplierParty.party.contact'
                )}
                isUbl21={isUbl21}
                data-testid={'supplier-contact'}
              />
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            classes={{ container: commonClasses.section }}
          >
            <Grid item xs={6}>
              <Grid
                container
                direction='row'
                style={{ alignItems: 'baseline ' }}
              >
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.headers.orderId')}
                    data={get(
                      data,
                      isUbl21
                        ? 'ublProperties.ReceiptAdvice[0].OrderReference[0].ID[0]._'
                        : 'ublProperties.orderReference[0].id.value'
                    )}
                    oneLine
                    className={Metadata.orderId}
                  />
                </Grid>
                <CommaSeparator />
                <Grid item>
                  <GenericLabeledDateField
                    label={translate('dxMessages.headers.orderDate')}
                    id={
                      isUbl21
                        ? 'ublProperties.ReceiptAdvice[0].OrderReference[0].IssueDate[0]._'
                        : 'ublProperties.orderReference[0].issueDate.value'
                    }
                    record={data}
                    oneLine
                    className={Metadata.orderDate}
                  />
                </Grid>
              </Grid>
              {(documentSubType === ReceiptAdviceSubType.GoodsReturnAdvice ||
                documentSubType ===
                  ReceiptAdviceSubType.GoodsReceiptAdvice) && (
                <Grid
                  container
                  direction='row'
                  style={{ alignItems: 'baseline ' }}
                >
                  <Grid item>
                    <SimpleLabeledField
                      label={translate('dxMessages.headers.invoiceId')}
                      data={get(
                        data,
                        isUbl21
                          ? `ublProperties.ReceiptAdvice[0].AdditionalDocumentReference[${getInvoiceReferenceIndexNode(
                              data
                            )}].ID[0]._`
                          : `ublProperties.additionalDocumentReference[${getInvoiceReferenceIndexNode(
                              data
                            )}].id.value`
                      )}
                      oneLine
                      className={Metadata.extendedInvoiceId}
                    />
                  </Grid>
                  <CommaSeparator />
                  <Grid item>
                    <GenericLabeledDateField
                      label={translate('dxMessages.headers.invoiceDate')}
                      id={
                        isUbl21
                          ? `ublProperties.ReceiptAdvice[0].AdditionalDocumentReference[${getInvoiceReferenceIndexNode(
                              data
                            )}].IssueDate[0]._`
                          : `ublProperties.additionalDocumentReference[${getInvoiceReferenceIndexNode(
                              data
                            )}].issueDate.value`
                      }
                      record={data}
                      oneLine
                      className={Metadata.extendedInvoiceIssueDate}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction='row'
                style={{ alignItems: 'baseline ' }}
              >
                <Grid item>
                  <SimpleLabeledField
                    label={translate('dxMessages.headers.despatchAdviceId')}
                    data={get(
                      data,
                      isUbl21
                        ? 'ublProperties.ReceiptAdvice[0].DespatchDocumentReference[0].ID[0]._'
                        : 'ublProperties.despatchDocumentReference[0].id.value'
                    )}
                    oneLine
                    className={Metadata.despatchAdviceId}
                  />
                </Grid>
                <CommaSeparator />
                <Grid item>
                  <GenericLabeledDateField
                    label={translate('dxMessages.headers.despatchAdviceDate')}
                    id={
                      isUbl21
                        ? 'ublProperties.ReceiptAdvice[0].DespatchDocumentReference[0].IssueDate[0]._'
                        : 'ublProperties.despatchDocumentReference[0].issueDate.value'
                    }
                    record={data}
                    oneLine
                    className={Metadata.despatchAdviceDate}
                  />
                </Grid>
              </Grid>
              {documentSubType === ReceiptAdviceSubType.GoodsReturnAdvice && (
                <Grid
                  container
                  direction='row'
                  style={{ alignItems: 'baseline ' }}
                >
                  <Grid item>
                    <SimpleLabeledField
                      label={translate('dxMessages.headers.receiptAdviceId')}
                      data={get(
                        data,
                        isUbl21
                          ? 'ublProperties.ReceiptAdvice[0].AdditionalDocumentReference[0].ID[0]._'
                          : 'ublProperties.additionalDocumentReference[0].id.value'
                      )}
                      oneLine
                      className={Metadata.receiptAdviceId}
                    />
                  </Grid>
                  <CommaSeparator />
                  <Grid item>
                    <GenericLabeledDateField
                      label={translate('dxMessages.headers.receiptAdviceDate')}
                      id={
                        isUbl21
                          ? 'ublProperties.ReceiptAdvice[0].AdditionalDocumentReference[0].IssueDate[0]._'
                          : 'ublProperties.additionalDocumentReference[0].issueDate.value'
                      }
                      record={data}
                      oneLine
                      className={Metadata.receiptAdviceDate}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            direction='row'
            classes={{ container: commonClasses.section }}
          >
            <Lines
              loading={loading}
              currencyID={currencyID}
              resource={resource}
              linesView={data.linesView}
              lineIds={lineIds}
              isUbl21={isUbl21}
              documentSubType={documentSubType}
            />
          </Grid>
        </Paper>

        {toolbar &&
          React.createElement(toolbar, {
            record: data,
            resource,
            basePath,
          })}
      </>
    );
  }
};

export default ReceiptAdvicePreview;
