import { gql, useQuery } from '@apollo/client';
import { DxTheme, useSetupUsage } from '@dx-ui/dx-common/src';
import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import { SetupUsage } from '@dx-ui/dx-common/src/utils/useSetupUsage';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { debounce, groupBy, round } from 'lodash';
import moment from 'moment';
import { FC, useState } from 'react';
import { Identifier, useLocale, useTranslate } from 'react-admin';
import ReactCardFlip from 'react-card-flip';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Tooltip as RcTooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import CashCollectionProjectionOverTimeDemo from '../demo/CashCollectionProjectionOverTimeDemo';

const I18N_KEY =
  'dxMessages.dashboard.widgets.CashCollectionProjectionOverTime';

const CashCollectionProjectionOverTime: FC<IWidgetContent> = ({
  account,
  onTheShelves,
  userPreferencesRootKey,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const [help, setHelp] = useState(false);

  const setupUsage = useSetupUsage();

  return (
    // Flip the card to display the help.
    <ReactCardFlip isFlipped={help} flipDirection='vertical'>
      <Card classes={{ root: classes.card }}>
        {!onTheShelves && (
          <div className={classes.help}>
            <IconButton onClick={() => setHelp(!help)} size='small'>
              <HelpIcon />
            </IconButton>
          </div>
        )}
        <CardHeader title={translate(`${I18N_KEY}.title`)} />
        <CardContent style={{ marginBottom: '1em' }}>
          {/* MUST set a height for the diagram. */}
          <div style={{ width: '100%', height: '35vh' }}>
            {onTheShelves || setupUsage !== SetupUsage.PRODUCTION ? (
              <CashCollectionProjectionOverTimeDemo
                userPreferencesRootKey={userPreferencesRootKey}
              />
            ) : (
              // Only demo values for the moment.
              // <CashCollectionProjectionOverTimeLive
              //   companyId={account.company.id}
              //   userPreferencesRootKey={userPreferencesRootKey}
              // />
              <CashCollectionProjectionOverTimeDemo
                userPreferencesRootKey={userPreferencesRootKey}
              />
            )}
          </div>
        </CardContent>
      </Card>
      {/* Display the guide for this widget.  */}
      <Card style={{ backgroundColor: theme.palette.grey[50] }}>
        <div className={classes.help}>
          <IconButton onClick={() => setHelp(!help)} size='small'>
            <KeyboardReturnIcon />
          </IconButton>
        </div>
        <Box m={1} mt={2} p={1}>
          <Help />
        </Box>
      </Card>
    </ReactCardFlip>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CashCollectionProjectionOverTimeLive: FC<{
  companyId: Identifier;
  userPreferencesRootKey: string;
}> = ({ companyId, userPreferencesRootKey }) => {
  const translate = useTranslate();
  // Ask the analytic endpoint for the amount due for the user company.
  const { loading, error, data } = useQuery(GRAPHQL_REQUEST, {
    variables: {
      client:
        process.env.NODE_ENV === 'production'
          ? companyId
          : '001G_xGUkNWTwXqRdHxm15oh9OegSKvYtpuLBgd6oFZgXRdNOasspAh',
    },
  });

  if (error)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          error,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );

  return (
    <CashCollectionBarChart
      loading={loading}
      data={data}
      userPreferencesRootKey={userPreferencesRootKey}
    />
  );
};

export const CashCollectionBarChart: FC<{
  loading: boolean;
  data: any;
  userPreferencesRootKey: string;
}> = ({ loading, data, userPreferencesRootKey }) => {
  const translate = useTranslate();

  if (loading) return <CircularProgress size='1em' />;

  // No data, no bar chart.
  if (data.metrics.length === 0)
    return <Typography>{translate(`${I18N_KEY}.NoReception`)}</Typography>;
  const dateAndCount = groupBy(
    data.metrics.map((_) => ({
      date: _.dimensions.dateDimension.date,
      amount: round(_.value, 2),
      currencyCode: _.currencyCode,
    })),
    ({ date }) => date
  );
  const metrics = Object.keys(dateAndCount).map((date) =>
    dateAndCount[date].reduce((acc, t) => {
      acc.date = date;
      acc.amount = t.amount || 0;
      acc.currencyCode = t.currencyCode;
      return acc;
    }, {})
  );
  return (
    <DocumentCountBarChart
      data={metrics}
      sessionStorageRootKey={userPreferencesRootKey}
    />
  );
};

const DocumentCountBarChart: FC<{
  data: any[];
  sessionStorageRootKey: string;
}> = ({ data, sessionStorageRootKey }) => {
  const translate = useTranslate();
  const locale = useLocale();
  const theme = useTheme();
  const [startIndex, setStartIndex] = useState(() => {
    // Survive browser reloading.
    const index = sessionStorage.getItem(`${sessionStorageRootKey}.startIndex`);
    return index ? parseInt(index) : 0;
  });
  const [endIndex, setEndIndex] = useState(() => {
    // Survive browser reloading.
    const index = sessionStorage.getItem(`${sessionStorageRootKey}.endIndex`);
    return index ? parseInt(index) : undefined;
  });

  const renderCustomizedLabel = (props, getContrastText) => {
    const { x, y, width, height, value, fill, index } = props;
    const render = (value, index) => {
      let prefix = translate(`${I18N_KEY}.due`);
      if (index === 0) {
        prefix = translate(`${I18N_KEY}.overdue`);
      }
      if (value === 0) return;
      return (
        <>
          <tspan x={x + width / 2}>
            {index === 0 && height > 20 && `${prefix}`}
            {index > 0 && height > 20 && `${prefix}+${index}`}
          </tspan>
        </>
      );
    };
    return (
      <g>
        <text
          x={x + width / 2}
          y={y + height / 2}
          textAnchor='middle'
          dominantBaseline='middle'
          fill={getContrastText(fill)}
        >
          {render(value, index)}
        </text>
      </g>
    );
  };
  return (
    /* Set a 99% width to fix the window down size pb. */
    <ResponsiveContainer width='99%' height='100%'>
      <BarChart
        data={[...data].sort((s1, s2) => s1.date.localeCompare(s2.date))}
        margin={{
          top: 5,
          right: 30,
          left: 40,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' tickLine={false} interval={0} tick={tick} />
        <YAxis allowDecimals={false}>
          <Label value={data[0]?.currencyCode} position='left' />
        </YAxis>
        {/* @ts-ignore */}
        <RcTooltip content={<BarTooltip locale={locale} />} />

        <Bar
          name={translate(`${I18N_KEY}.amount`)}
          dataKey='amount'
          isAnimationActive={false} // https://github.com/recharts/recharts/issues/829
        >
          <LabelList
            dataKey='amount'
            content={(props) =>
              renderCustomizedLabel(props, theme.palette.getContrastText)
            }
          />
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={barColor(index)} />
          ))}
        </Bar>
        <Brush
          dataKey='date'
          height={20}
          startIndex={startIndex}
          endIndex={endIndex}
          onChange={debounce((e) => {
            setStartIndex(() => {
              // Survive browser reloading.
              sessionStorage.setItem(
                `${sessionStorageRootKey}.startIndex`,
                e.startIndex
              );
              return e.startIndex;
            });
            setEndIndex(() => {
              // Survive browser reloading.
              sessionStorage.setItem(
                `${sessionStorageRootKey}.endIndex`,
                e.endIndex
              );
              return e.endIndex;
            });
          }, 500)}
          // No label in the brush.
          tickFormatter={() => ''}
          // Prevent the widget from moving when dragging the brush.
          className='outsideDashboardGrip'
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

/**
 * Defines the fill for the current month, month + 1, ..., month + 5
 * @param index bar index
 * @returns color code as a string
 */
const barColor = (index) => {
  const colors = [
    '#660000',
    '#E06666',
    '#FF9900',
    '#F1C232',
    '#FFE599',
    '#93C47D',
  ];
  return colors[index];
};

// X axis: put the month name
const tick = ({ x, y, payload: { value } }) => {
  const month = moment(value).format('MMMM');
  return (
    <text x={x} y={y + 5} fontSize='12' textAnchor='middle'>
      {month}
    </text>
  );
};

// The tooltip per dated bars: the date and the list of amounts.
const BarTooltip = ({ active, payload, label, locale }) =>
  active && payload && payload.length ? (
    <div
      style={{
        // Use a semi-transparent background so the adjacent bars are not hidden
        // (and use can still compare bare values).
        background: 'rgba(210, 210, 210, 0.9)',
        border: '1px solid black',
        padding: '5px',
      }}
    >
      <Typography>{moment(label, 'YYYYMMDD').format('MMMM')}</Typography>
      <ul>
        {payload.map((_) => (
          <li key={_.name}>
            <Typography>
              {_.name}:{' '}
              {new Intl.NumberFormat(locale, {
                style: 'currency',
                currency: _.payload.currencyCode,
              }).format(_.value)}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  ) : null;

const Help: FC<{}> = () => {
  const locale = useLocale();
  const theme = useTheme();

  switch (locale) {
    case 'fr':
      return (
        <>
          <Typography paragraph>
            Ce graphique affiche mois par mois le total prévisionnel des
            factures dues
          </Typography>
          <Typography>
            Vous pouvez affiner votre recherche en zoomant sur un mois
            spécifique. Utilisez le barre de brosse au bas du diagramme et
            ajustez la période de temps en en faisant glisser chaque extrémité
            de la barre :
          </Typography>

          <div
            style={{
              width: '80%',
              marginTop: '1em',
              background: theme.palette.grey[400],
              border: '1px solid black',
              borderLeft: `7px solid ${theme.palette.grey[700]}`,
              borderRight: `7px solid ${theme.palette.grey[700]}`,
            }}
          >
            <span>← presser ici</span>
            <span style={{ float: 'right' }}>ou ici →</span>
          </div>
        </>
      );
    case 'ro':
      return (
        <>
          <Typography paragraph>
            Graficul afișează luna de lună totalul proiectat al facturilor
            datorate
          </Typography>
          <Typography>
            Vă puteți rafina căutarea mărind o anumită lună. Folosește bara de
            perii din partea de jos a diagramei și ajustați perioada de timp cu
            alunecarea fiecărui capăt al barei:
          </Typography>
          <div
            style={{
              width: '80%',
              marginTop: '1em',
              background: theme.palette.grey[400],
              border: '1px solid black',
              borderLeft: `7px solid ${theme.palette.grey[700]}`,
              borderRight: `7px solid ${theme.palette.grey[700]}`,
            }}
          >
            <span>← strângeți aici</span>
            <span style={{ float: 'right' }}>sau aici →</span>
          </div>
        </>
      );
    default:
      return (
        <>
          <Typography paragraph>
            This chart displays month by month the total projected of invoices
            due
          </Typography>
          <Typography>
            You can refine your search by zooming in on specific Month. Use the
            brush bar at the bottom of the diagram and adjust the time period by
            sliding each end of the bar:
          </Typography>
          <div
            style={{
              width: '80%',
              marginTop: '1em',
              background: theme.palette.grey[400],
              border: '1px solid black',
              borderLeft: `7px solid ${theme.palette.grey[700]}`,
              borderRight: `7px solid ${theme.palette.grey[700]}`,
            }}
          >
            <span>← squeeze here</span>
            <span style={{ float: 'right' }}>or here →</span>
          </div>
        </>
      );
  }
};

const useStyles = makeStyles((theme: DxTheme) => ({
  // Fixed into the top right corner.
  help: {
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(0),
  },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
  },
}));

export const GRAPHQL_REQUEST = gql`
  query DailyDocumentMetrics(
    $client: String!
    $startDate: String!
    $endDate: String!
  ) {
    dailyDocumentMetrics(
      client: $client
      startDate: $startDate
      endDate: $endDate
    ) {
      metrics {
        name
        value
        dimensions {
          dateDimension {
            date
          }
        }
      }
    }
  }
`;

export default CashCollectionProjectionOverTime;
