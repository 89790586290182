import { Card, CardContent, withStyles } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ClearIcon from '@material-ui/icons/Clear';
import StatusIcon from '@material-ui/icons/Flag';
import {
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns';
import React, { cloneElement, FC } from 'react';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  FilterProps,
  List,
  ListProps,
  ReferenceField,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useLocale,
  usePermissions,
} from 'react-admin';
import { isPspAdministrator } from '../configuration';
import Pagination from '../Pagination';

/**
 * The list of user accounts.
 *
 * Only visible for administrators.
 * A PSP administartor sees all of them.
 * A company administrator only sees the account belonging to his company.
 */
const AccountList: FC<ListProps> = (props) => {
  const locale = useLocale();
  const { loaded, permissions } = usePermissions();
  if (!loaded || !permissions) return null;

  return (
    <List
      title='resources.accounts.list.title'
      aside={<FilterSidebar />}
      actions={<AccountActions />}
      // A company administrator only sees his own company accounts
      // so don't show the company filter.
      filters={isPspAdministrator(permissions) ? <AccountFilters /> : undefined}
      exporter={false}
      sort={{ field: 'created', order: 'DESC' }}
      pagination={<Pagination />}
      {...props}
    >
      <Datagrid
        rowClick='edit'
        // Only for test purpose.
        rowStyle={(record, index) => ({
          '--data-item': `'${record.id}'`,
        })}
      >
        <TextField
          label='resources.accounts.list.headers.person'
          source='username'
          sortBy='email'
        />
        {/* A company administrator only sees his own company accounts
        so don't show the company column */}
        {isPspAdministrator(permissions) && (
          <ReferenceField
            label='resources.accounts.list.headers.company'
            source='company.id'
            reference='configuration-companies'
            sortable={false}
          >
            <TextField source='name' />
          </ReferenceField>
        )}
        <BooleanField
          label='resources.accounts.list.headers.enabled'
          source='enabled'
          sortable={false}
          // @ts-ignore
          FalseIcon={ErrorIcon}
        />
        <BooleanField
          label='resources.accounts.list.headers.nonLocked'
          source='accountNonLocked'
          sortable={false}
          // @ts-ignore
          FalseIcon={ErrorIcon}
        />
        <DateField
          label='resources.accounts.list.headers.created'
          source='created'
          options={{
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          }}
          locales={locale || 'en'}
        />
      </Datagrid>
    </List>
  );
};

const AccountFilters = (props: Omit<FilterProps, 'children'>) => {
  return (
    <Filter {...props}>
      <TextInput
        label='resources.accounts.list.filters.company'
        source='companyName'
        placeholder='INC SA'
      />
    </Filter>
  );
};

const FilterSidebar = () => (
  <FilterCard>
    <CardContent>
      <FilterLiveSearch
        // @ts-ignore
        label='resources.accounts.list.filters.person'
        source='username'
        placeholder='john.doe@...'
      />
      <FilterList
        label='resources.accounts.list.filters.status.title'
        icon={<StatusIcon />}
      >
        <FilterListItem
          label='resources.accounts.list.filters.status.disabled'
          value={{
            status: 'DISABLED',
          }}
        />
        {/* <FilterListItem
          label='resources.accounts.list.filters.status.locked'
          value={{
            status: 'LOCKED',
          }}
        /> */}
      </FilterList>
      <FilterList
        label='resources.accounts.list.filters.created.title'
        icon={<AccessTimeIcon />}
      >
        <FilterListItem
          label='resources.accounts.list.filters.created.today'
          value={{
            createdAfterTime: startOfDay(new Date()).getTime(),
            createdBeforeTime: undefined,
          }}
        />
        <FilterListItem
          label='resources.accounts.list.filters.created.thisWeek'
          value={{
            createdAfterTime: startOfWeek(new Date()).getTime(),
            createdBeforeTime: undefined,
          }}
        />
        <FilterListItem
          label='resources.accounts.list.filters.created.lastWeek'
          value={{
            createdAfterTime: subWeeks(startOfWeek(new Date()), 1).getTime(),
            createdBeforeTime: startOfWeek(new Date()).getTime(),
          }}
        />
        <FilterListItem
          label='resources.accounts.list.filters.created.thisMonth'
          value={{
            createdAfterTime: startOfMonth(new Date()).getTime(),
            createdBeforeTime: undefined,
          }}
        />
        <FilterListItem
          label='resources.accounts.list.filters.created.lastMonth'
          value={{
            createdAfterTime: subMonths(startOfMonth(new Date()), 1).getTime(),
            createdBeforeTime: startOfMonth(new Date()).getTime(),
          }}
        />
        <FilterListItem
          label='resources.accounts.list.filters.created.earlier'
          value={{
            createdAfterTime: undefined,
            createdBeforeTime: subMonths(startOfMonth(new Date()), 1).getTime(),
          }}
        />
      </FilterList>
    </CardContent>
  </FilterCard>
);

const FilterCard = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      order: -1, // Display on the left rather than on the right of the list.
      width: '17em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(Card);

const AccountActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton
        basePath={basePath}
        label='resources.accounts.list.toptoolbar.create'
        variant='contained'
        style={{ marginLeft: '1em' }}
      />
    </TopToolbar>
  );
};

const ErrorIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}))(ClearIcon);

export default AccountList;
