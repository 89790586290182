import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { required, TextInput, usePermissions, useTranslate } from 'react-admin';
import { isPspAdministrator } from '../../configuration';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The tab with company general information & its supported authentication types.
 *
 * Only visible for administrators.
 * Only editable by a PSP administartor and read-only for a company administrator.
 */
const CompanyTab = () => {
  return (
    <Grid container direction='column' style={{ marginTop: '1em' }}>
      <Grid item>
        <Description />
      </Grid>
    </Grid>
  );
};

const Description = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const { loaded, permissions } = usePermissions();

  if (!loaded || !permissions) return null;

  return (
    <Paper className={classes.paper}>
      <Grid container direction='column'>
        {!isPspAdministrator(permissions) && (
          <Grid item>
            <Typography variant='caption'>
              {translate(
                'resources.companies.edit.tabs.company.sections.description.subtitle'
              )}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Grid container direction='column'>
                <Grid item>
                  <TextInput
                    label='resources.companies.edit.tabs.company.sections.description.name'
                    source='name'
                    validate={required()}
                    InputProps={{
                      readOnly: !isPspAdministrator(permissions),
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextInput
                    label='resources.companies.edit.tabs.company.sections.description.fiscalCode'
                    source='fiscalCode'
                    validate={required()}
                    InputProps={{
                      readOnly: !isPspAdministrator(permissions),
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <TextInput
                label='resources.companies.edit.tabs.company.sections.description.address'
                source='address'
                multiline
                validate={required()}
                InputProps={{
                  readOnly: !isPspAdministrator(permissions),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        {isPspAdministrator(permissions) && (
          <Grid item>
            <TextInput
              label='resources.companies.edit.tabs.company.sections.description.cmsRootDir'
              source='cmsRootDir'
              validate={required()}
              fullWidth
              helperText='resources.companies.edit.tabs.company.sections.description.cmsRootDirHelper'
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default CompanyTab;
