import { DxTheme, ToolbarBackButton } from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';
import { ShowView, SimpleShowLayout, Toolbar } from 'react-admin';
import { ForecastPreview } from './';

const useStyles = makeStyles((theme: DxTheme) => ({
  toolbar: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: 0,
  },
}));

const ForecastShowView = ({ basePath, controllerProps, title }: any) => {
  const classes = useStyles();
  return (
    <ShowView {...controllerProps} title={title}>
      <SimpleShowLayout>
        <ForecastPreview
          record={controllerProps.record}
          resource={controllerProps.resource}
          basePath={basePath}
        />
        <Toolbar classes={{ toolbar: classes.toolbar }}>
          <ToolbarBackButton />
        </Toolbar>
      </SimpleShowLayout>
    </ShowView>
  );
};

export default ForecastShowView;
