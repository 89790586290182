import mimeTypeFinder from 'mime-types';

export class DomFileDownloadService {
  /**
   * Downloads a file
   * @param {*} content File content
   * @param {*} filename File name
   */
  downloadFile(content, filename) {
    var fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    const mimeType = mimeTypeFinder.lookup(filename);
    var blob = new Blob([content], { type: mimeType ? mimeType : undefined });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // Manage IE11+ & Edge
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      fakeLink.setAttribute('href', URL.createObjectURL(blob));
      fakeLink.setAttribute('download', filename);
      fakeLink.click();
    }
  }
}
