import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import {
  Datagrid,
  DateField,
  EmailField,
  FieldProps,
  List,
  ResourceContextProvider,
  SelectField,
  useTranslate,
} from 'react-admin';
import { Account } from '../../types';
import AccountDiffPanel from './AccountDiffPanel';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The change history of an account.
 */
const AccountChangeHistoryTab: FC<FieldProps<Account>> = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (!record?.id) return null;

  return (
    <div style={{ marginTop: '1em' }}>
      <Typography variant='caption'>
        {translate('resources.accounts.edit.tabs.history.subtitle')}
      </Typography>
      <Paper className={classes.paper} style={{ marginTop: '1em' }}>
        <ResourceContextProvider value='accountlifecycleevents'>
          <List
            title=' '
            syncWithLocation
            component='div'
            basePath='/accountlifecycleevents'
            sort={{ field: 'id', order: 'ASC' }}
            filter={{ accountId: record.id }}
            bulkActionButtons={false}
            actions={false}
            empty={
              <span>
                {translate('resources.accounts.edit.tabs.history.empty')}
              </span>
            }
          >
            <Datagrid
              expand={<AccountDiffPanel />}
              isRowExpandable={(row) => row.type === 'UPDATE'}
            >
              <SelectField
                label='resources.accounts.edit.tabs.history.changeType'
                source='type'
                choices={[
                  {
                    id: 'UPDATE',
                    name: 'resources.accounts.edit.tabs.history.update',
                  },
                  {
                    id: 'CREATION',
                    name: 'resources.accounts.edit.tabs.history.creation',
                  },
                  {
                    id: 'DELETION',
                    name: 'resources.accounts.edit.tabs.history.deletion',
                  },
                ]}
              />
              <DateField
                label='resources.accounts.edit.tabs.history.when'
                source='when'
                showTime={true}
              />
              <EmailField
                label='resources.accounts.edit.tabs.history.by'
                source='by.person.email'
              />
            </Datagrid>
          </List>
        </ResourceContextProvider>
      </Paper>
    </div>
  );
};

export default AccountChangeHistoryTab;
