import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { Pagination } from '@material-ui/lab';
import { FC, useState } from 'react';
import { useGetIdentity, useRedirect, useTranslate } from 'react-admin';
import ReactCardFlip from 'react-card-flip';
import { DxTheme } from '../../../../types';
import { GA_EVENTS, sendGAEvent, useSetupUsage } from '../../../../utils';
import { SetupUsage } from '../../../../utils/useSetupUsage';
import { IWidgetContent } from '../../Widget';
import cartouche from '../cartouche.png';
import cartoucheDark from '../cartoucheDark.png';

const useStyles = makeStyles((theme: DxTheme) => ({
  main: {
    paddingLeft: 15,
    paddingTop: 8,
    background: `url(${
      theme.palette.type === 'dark' ? cartoucheDark : cartouche
    }) no-repeat`,
    '& .icon': {
      color: '#dc2440',
    },
    backgroundSize: '500px 80px',
  },
  // Fixed into the bottom left corner.
  configure: {
    position: 'fixed',
    left: theme.spacing(0),
    bottom: theme.spacing(0),
  },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
  },
  help: {
    position: 'fixed',
    right: theme.spacing(1),
    top: theme.spacing(0),
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    height: '10vh',
    rowGap: '10px',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    color: '#757ce8',
  },
}));

const SavedQueries: FC<IWidgetContent> = ({
  onTheShelves,
  openConfiguration,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const redirect = useRedirect();
  const [help, setHelp] = useState(false);
  const [option, setOption] = useState('/dxportal');
  const setupUsage = useSetupUsage();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;
  const preferences: any = localStorage.getItem('preferences');
  const parsePreferences = JSON.parse(preferences);
  const savedQueries: any = [
    parsePreferences?.dxarchive?.savedQueries,
    parsePreferences?.dxportal?.savedQueries,
  ]
    .flatMap((item) => item)
    .filter((el) => el !== undefined);

  // Right now DxArchive is disabled. The next lines will be part of the functionality
  // const { permissions } = usePermissions();
  // const features: any =
  //   permissions !== undefined ? Object.keys(permissions) : [];
  // const lowerCaseFeatures = features.map((item) => item.toLowerCase());
  // const hasArchiveAccess = lowerCaseFeatures.includes('dxarchive');

  const handleDisplay = (ev) => {
    setOption(ev.target.value);
    setCurrentPage(1);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const ItemsPerPage = 9;
  const startIndex = (currentPage - 1) * ItemsPerPage;
  const endIndex = startIndex + ItemsPerPage;
  const visibleQueries = savedQueries.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const onClickQuery = (query) => {
    const origin = window.location.origin;
    const generateQueryString = (obj) => {
      const stringifyValue = (value) => {
        if (typeof value === 'object' && !Array.isArray(value)) {
          return generateQueryString(value);
        } else if (Array.isArray(value)) {
          return JSON.stringify(value);
        } else {
          return JSON.stringify(value);
        }
      };

      const queryString = Object.entries(obj)
        .map(([key, value]) => `"${key}":${stringifyValue(value)}`)
        .join(',');

      return queryString ? `{${queryString}}` : '';
    };

    const displayedFilters = encodeURIComponent(
      generateQueryString(query.displayedFilters)
    );
    const filter = encodeURIComponent(generateQueryString(query.filter));

    if (query?.pathName !== undefined) {
      const queryStringProd = `${origin}${query.pathName}#/${query.resource}?displayedFilters=${displayedFilters}&filter=${filter}&order=${query.sort.order}&page=1&perPage=${query.perPage}&sort=${query.sort.field}`;
      const queryStringDemo = `/${query.resource}?displayedFilters=${displayedFilters}&filter=${filter}&order=${query.sort.order}&page=1&perPage=${query.perPage}&sort=${query.sort.field}`;
      if (
        setupUsage === SetupUsage.PRODUCTION ||
        setupUsage === SetupUsage.UAT
      ) {
        sendGAEvent(
          GA_EVENTS.categories.DASHBOARD.name,
          GA_EVENTS.categories.DASHBOARD.actions.SAVE_QUERY_CLICKED,
          account?.company?.cmsRootDir
        );
        window.location.href = queryStringProd;
      } else {
        sendGAEvent(
          GA_EVENTS.categories.DASHBOARD.name,
          GA_EVENTS.categories.DASHBOARD.actions.SAVE_QUERY_CLICKED,
          account?.company?.cmsRootDir
        );
        redirect(queryStringDemo);
      }
    } else {
      const queryString = `/${query.resource}?displayedFilters=${displayedFilters}&filter=${filter}&order=${query.sort.order}&page=1&perPage=${query.perPage}&sort=${query.sort.field}`;
      sendGAEvent(
        GA_EVENTS.categories.DASHBOARD.name,
        GA_EVENTS.categories.DASHBOARD.actions.SAVE_QUERY_CLICKED,
        account?.company?.cmsRootDir
      );
      redirect(queryString);
    }
  };

  return (
    <ReactCardFlip isFlipped={help} flipDirection='vertical'>
      <Card
        classes={{
          root: classes.card,
        }}
      >
        <CardHeader
          title={
            <div className={classes.main}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '50px' }}>
                  <Grid item xs={3}>
                    <Box className='icon'>
                      <BookmarkIcon style={{ width: '47px', height: '47px' }} />
                    </Box>
                  </Grid>
                </div>
                <div style={{ display: 'block' }}>
                  <Typography noWrap align='right' style={{ fontSize: '18px' }}>
                    {translate(
                      'dxMessages.dashboard.widgets.SavedQueries.title'
                    )}
                  </Typography>
                  <FormControl
                    variant='standard'
                    style={{
                      minWidth: 120,
                      height: 30,
                      alignItems: 'flex-end',
                      display: 'none',
                    }}
                  >
                    <Select
                      labelId='display'
                      id='display'
                      onChange={handleDisplay}
                      label='Display'
                      value={option}
                      disabled={onTheShelves}
                      // options will be displayed under the select component
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      style={{ height: '50px', width: '160px' }}
                    >
                      <MenuItem value='/dxportal'>{'DocProcess'}</MenuItem>
                      <MenuItem value='/dxarchive'>{'DxArchive'}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          }
        />
        <CardContent>
          {/* MUST set a height for the diagram. */}
          <div style={{ display: 'block', overflowY: 'auto' }}>
            <div className={classes.content}>
              {visibleQueries.map((query) => (
                <Typography
                  key={query?.label}
                  style={{
                    textDecorationLine: 'underline',
                    width: '33%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <span
                      onClick={() => {
                        if (openConfiguration === false) {
                          onClickQuery(query);
                        }
                      }}
                      onMouseEnter={(e) => {
                        if (openConfiguration === false) {
                          return (e.currentTarget.style.backgroundColor =
                            '#ecdfec');
                        }
                      }}
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = 'transparent')
                      }
                      style={{
                        cursor:
                          openConfiguration === false ? 'pointer' : 'inherit',
                      }}
                    >
                      {query?.label}
                    </span>
                  </div>
                </Typography>
              ))}
            </div>
          </div>
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <div style={{ fontSize: '14px' }}>
                {savedQueries.length !== 0
                  ? endIndex < savedQueries.length
                    ? `${startIndex + 1}-${endIndex} of ${savedQueries.length}`
                    : `${startIndex + 1}-${savedQueries.length} of ${
                        savedQueries.length
                      }`
                  : ''}
              </div>
              <Pagination
                count={Math.ceil(savedQueries.length / ItemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
                size='small'
              />
            </div>
          </div>
        </CardActions>
      </Card>
      <Card style={{ backgroundColor: theme.palette.grey[50] }}>
        <div className={classes.help}>
          <IconButton onClick={() => setHelp(!help)} size='small'>
            <KeyboardReturnIcon />
          </IconButton>
        </div>
        <Box m={1} mt={2} p={1}></Box>
      </Card>
    </ReactCardFlip>
  );
};

export default SavedQueries;
