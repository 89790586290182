import merge from 'lodash/merge';
import { defaultTheme } from 'react-admin';
import { colors } from '../constants/Colors';

export const muiTheme: any = merge({}, defaultTheme, {
  palette: {
    primary: {
      light: colors.lightGrey,
      main: colors.mainColor5,
      dark: colors.primary[700],
    },
    secondary: {
      light: colors.lightGrey,
      main: colors.brightOrange,
      dark: colors.secondary[700],
    },
    input: {
      bottomLine: colors.black30,
      helperText: colors.black50,
      labelText: colors.black50,
      inputText: colors.black80,
      disabled: colors.black50,
    },
    background: {
      default: colors.white,
    },
  },
  overrides: {
    MuiInput: {
      root: {
        fontSize: '14px',
      },
      input: {
        '&:not($inputMultiline)': {
          height: '32px',
          lineHeight: '32px',
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      underline: {
        '&:hover:not($disabled):before': {
          backgroundColor: 'transparent',
        },
        '&:before': {
          backgroundColor: 'transparent',
        },
        '&:after': {
          backgroundColor: colors.mainColor5,
        },
        '&$error:after': {
          borderBottomColor: colors.brightOrange,
        },
      },
      multiline: {
        padding: '8px 0 8px',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'transparent',
      },
      underline: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:before': {
          backgroundColor: 'transparent',
        },
        '&:after': {
          backgroundColor: colors.mainColor5,
        },
        '&$error:after': {
          borderBottomColor: colors.brightOrange,
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        lineHeight: '32px',
      },
    },
    MuiFormLabel: {
      root: {
        '&$error': {
          color: colors.brightOrange,
        },
        fontSize: '14px',
        '&$focused': {
          color: colors.mainColor5,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '14px',
        color: colors.black50,
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.857142857)',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px',
        paddingTop: 8, // Force dense
        paddingBottom: 8,
        '&$selected': {
          backgroundColor: 'none',
          fontWeight: 500,
          color: colors.mainColor5,
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'unset',
        borderBottomStyle: 'solid',
        borderColor: colors.brightOrange,
        zIndex: 2000,
      },
      colorSecondary: {
        backgroundColor: colors.white,
      },
    },
    RaAppBar: {
      toolbar: {
        color: colors.black65,
      },
    },
    MuiTableRow: {
      root: {
        '&$hover': {
          '&:hover': {
            backgroundColor: colors.lightGrey,
          },
        },
        backgroundColor: colors.white,
      },
    },
    MuiIconButton: {
      colorPrimary: {
        '&:hover': {
          backgroundColor: colors.lightGrey,
        },
      },
      root: {
        '&:hover': {
          backgroundColor: colors.lightGrey,
        },
      },
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: colors.lightGrey,
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: colors.purple[400],
        },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: colors.grey[600],
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: colors.lightGrey,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: colors.brightOrange,
        },
      },
    },
    RaBulkActionsToolbar: {
      icon: {
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.white30,
        },
      },
    },
    RaBulkDeleteWithConfirmButton: {
      deleteButton: {
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.white30,
        },
      },
    },
    MuiTablePagination: {
      caption: {
        fontSize: '0.8125rem',
      },
      selectRoot: {
        fontSize: '0.8125rem',
      },
    },
    RaPaginationActions: {
      actions: {
        fontSize: '0.8125rem',
      },
    },
  },
  app: {
    tableBkgColor: colors.white,
    tableOnHoverBkgColor: colors.lightGrey,
  },
  colors,
});
