export const displayDataDemo = [
  {
    properties: {
      'edm:documentTypeCode': 'INVOIC',
      'edm:issueDate': '2024-05-14T06:43:58.003+0000',
      'edm:processStatus': 'FORMAT_ERROR',
    },
  },
  {
    properties: {
      'edm:documentId': '012346',
      'edm:documentTypeCode': 'INVOIC',
      'edm:recipientName': 'Company 02',
      'edm:issuerName': 'Supplier 02',
      'edm:issueDate': '2024-05-13T06:43:58.003+0000',
      'edm:processStatus': 'SENT',
    },
  },
  {
    properties: {
      'edm:documentId': '012347',
      'edm:documentTypeCode': 'INVOIC',
      'edm:recipientName': 'Company 03',
      'edm:issuerName': 'Supplier 03',
      'edm:issueDate': '2024-05-13T06:43:59.003+0000',
      'edm:processStatus': 'SENT',
    },
  },
  {
    properties: {
      'edm:documentId': '012348',
      'edm:documentTypeCode': 'INVOIC',
      'edm:recipientName': 'Company 03',
      'edm:issuerName': 'Supplier 03',
      'edm:issueDate': '2024-05-13T06:44:59.003+0000',
      'edm:processStatus': 'SENT',
    },
  },
  {
    properties: {
      'edm:documentId': '012347',
      'edm:documentTypeCode': 'INVOIC',
      'edm:recipientName': 'Company 04',
      'edm:issuerName': 'Supplier 04',
      'edm:issueDate': '2024-05-13T06:43:51.003+0000',
      'edm:processStatus': 'SENT',
    },
  },
  {
    properties: {
      'edm:documentTypeCode': 'ORDERS',
      'edm:issueDate': '2024-05-14T06:43:58.003+0000',
      'edm:processStatus': 'FORMAT_ERROR',
    },
  },
  {
    properties: {
      'edm:documentId': '012349',
      'edm:documentTypeCode': 'ORDERS',
      'edm:recipientName': 'Company 01',
      'edm:issuerName': 'Supplier 01',
      'edm:issueDate': '2024-05-14T06:43:58.003+0000',
      'edm:processStatus': 'SENT',
    },
  },
  {
    properties: {
      'edm:documentId': '012350',
      'edm:documentTypeCode': 'ORDERS',
      'edm:recipientName': 'Company 01',
      'edm:issuerName': 'Supplier 01',
      'edm:issueDate': '2024-05-14T06:43:58.003+0000',
      'edm:processStatus': 'SENT',
    },
  },
  {
    properties: {
      'edm:documentId': '012351',
      'edm:documentTypeCode': 'ORDERS',
      'edm:recipientName': 'Company 01',
      'edm:issuerName': 'Supplier 01',
      'edm:issueDate': '2024-05-14T06:43:58.003+0000',
      'edm:processStatus': 'SENT',
    },
  },
];
