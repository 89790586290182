import { UPDATE_FORM_TEMPLATE } from '../actions/UpdateFormTemplate';
import { Template } from '../shared/types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  previousState = null,
  { type, payload }: { type: string; payload: Template }
) => {
  if (type === UPDATE_FORM_TEMPLATE) {
    return payload;
  }
  return previousState;
};
