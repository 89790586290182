import { Button, FormControl, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ContentFilter from '@material-ui/icons/FilterList';
import { useGetIdentity, useTranslate } from 'react-admin';
import { colors } from '../../constants/Colors';
import { DxTheme } from '../../types';
import { GA_EVENTS, sendGAEvent } from '../../utils';
import { AutoCompleteMultiSelect } from '../inputs';
import {
  withPlaceholderMultiple,
  withoutPlaceholderMultiple,
} from '../menus/SelectRenderer';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: {
      marginLeft: '1em',
      '& div:before': {
        borderBottom: 'unset !important', // remove MuiInput underline to mimic a button
      },
      '& div:after': {
        borderBottom: 'unset !important', // remove MuiInput underline to mimic a button
      },
      // Behaves as react-admin buttons on hover and focus
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '& div[role=button]:focus': {
        backgroundColor: 'transparent',
      },
    },
    defaultBtn: {
      color: theme.colors.black65,
    },
    contentFilter: {
      marginRight: 2,
    },
  }),
  { name: 'FilterButton' }
);

const FilterButton = (props: any) => {
  const { filters, displayedFilters, showFilter, hideFilter, setFilters } =
    props;

  const classes = useStyles(props);
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const selectableFilters = filters.filter((filter) => !filter.props.alwaysOn);

  /**
   * Gets the Ids of the current displayed filters
   */
  const getCurrentDisplayedFiltersIds = (filters, displayedFilters) => {
    if (displayedFilters && Object.keys(displayedFilters).length !== 0) {
      const newDisplayedFiltersIds = filters
        .filter((filter) => displayedFilters[filter.props.source] === true)
        .map((filter) => filter.key);
      return Array.from(newDisplayedFiltersIds);
    }
    return [];
  };

  // Filters can be hidden by their own Hide Button or by an action on the selection box.
  // displayedFilters props is the one which reflects the current display.
  // Gets from it the filters Ids.
  const displayedFiltersIds = getCurrentDisplayedFiltersIds(
    selectableFilters,
    displayedFilters
  );

  const handleShow = (source, defaultValue) => {
    sendGAEvent(
      GA_EVENTS.categories.LIST.name,
      GA_EVENTS.categories.LIST.actions.MORE_FILTERS,
      account?.company?.cmsRootDir
    );

    showFilter(source, defaultValue);
  };

  const getOptionId = (x) => x.key;
  const renderOptionLabel = (x) => x.props.label;

  const renderIcon = (translate) => (
    <Tooltip
      title={translate('dxMessages.list.addFilter', { _: 'More Filters' })}
    >
      {/* TODO: put all the styles in in classes */}
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          color: colors.mainColor5,
        }}
      >
        <ContentFilter className={classes.contentFilter} />

        <div style={{ display: 'block', textTransform: 'uppercase' }}>
          {translate('dxMessages.list.addFilter', { _: 'More Filters' })}
        </div>
        <ArrowDropDown />
      </div>
    </Tooltip>
  );

  const resetFilters = () => {
    setFilters({}, {});
  };

  const handleSetDefault = () => {
    resetFilters();
  };

  /**
   * Updates the list of filters to display.
   * In case of adding a filter, add the corresponding column
   */
  const handleShownChange = (evt) => {
    // Initialized the new list from the event.
    // The value is an array containing the new state of the multi selection
    const newSelectedFilterIds = withoutPlaceholderMultiple(evt.target.value);
    let currentFilterId;
    const displayedFiltersIds = getCurrentDisplayedFiltersIds(
      filters,
      displayedFilters
    );
    // The existing integration done with our AutocompleteMultiSelect component
    // implies to retrieve the only one element which have been added or removed.
    if (newSelectedFilterIds.length > displayedFiltersIds.length) {
      // Addition case. Use array destructring here.
      [currentFilterId] = newSelectedFilterIds.filter(
        (filter) => !displayedFiltersIds.includes(filter)
      );
      // Retrieve the related filter info. Use array destructuring
      const [filter] = filters.filter(
        (filter) => filter.key === currentFilterId
      );

      // Display the Filter
      handleShow(filter.props.source, undefined);
    } else {
      // Subtraction case. Use array destructring here.
      [currentFilterId] = displayedFiltersIds.filter(
        (filter) => !newSelectedFilterIds.includes(filter)
      );
      // Retrieve the related filter info. Use array destructuring
      const [filter] = filters.filter(
        (filter) => filter.key === currentFilterId
      );
      // Hide the Filter
      try {
        hideFilter(filter.props.source);
      } catch (e) {
        // ignore for the moment
        // TODO : find the reason why react-admin removeKey() needs to have a filter URL var non-empty
      }
    }
  };

  const filterOption = (option, query, lowerCaseQuery, translate) => {
    if (!query) {
      return true;
    }
    let { label } = option.props;
    label = translate(label);
    return label.toLowerCase().indexOf(lowerCaseQuery) !== -1;
  };

  return (
    <div>
      <FormControl className={classes.root}>
        <AutoCompleteMultiSelect
          value={withPlaceholderMultiple(displayedFiltersIds)}
          onChange={handleShownChange}
          placeholder={undefined}
          filterPlaceholder={undefined}
          icon={undefined}
          ref={undefined}
          sortLabels={true}
          options={selectableFilters}
          getOptionId={getOptionId}
          renderOptionLabel={renderOptionLabel}
          filterOption={filterOption}
          onOpen={undefined}
          loading={undefined}
          errorText={undefined}
          renderValue={renderIcon(translate)}
        >
          {!!Array.from(displayedFiltersIds).length && (
            <Button
              classes={{ root: classes.defaultBtn }}
              onClick={handleSetDefault}
            >
              {translate('dxMessages.list.default', {
                _: 'DEFAULT',
              })}
            </Button>
          )}
        </AutoCompleteMultiSelect>
      </FormControl>
    </div>
  );
};

export default FilterButton;
