import UblCommonSources, {
  IAddressSources,
  IAmountSources,
  IContactSources,
  IDeliverySources,
  IDocumentReferenceSources,
  ILineItemSources,
  ILocationSources,
  IOrderReferenceSources,
  IPartySources,
  IShipmentStageSources,
  ITransportHandlingUnitSources,
} from '../common/UblCommon2-0Sources';

/**
 * Source model used to build DespatchAdvice preview view
 */
export interface IShipmentInformationSources {
  id: string;
  grossVolumeMeasure: string;
  grossVolumeMeasureUnitCode: string;
  netVolumeMeasure: string;
  netVolumeMeasureUnitCode: string;
  totalGoodsItemQuantity: string;
  totalGoodsItemQuantityUnitCode: string;
  declaredCustomsValueAmount: string;
  declaredCustomsValueAmountCurrencyID: string;
  specialInstructions: string;
  exportCountryIdentificationCode: string;
  delivery: {
    source: string;
    fields: IDeliverySources;
  };
  shipmentStage: {
    source: string;
    fields: IShipmentStageSources;
  };
  transportHandlingUnit: {
    source: string;
    fields: ITransportHandlingUnitSources;
  };
}
export interface IDespatchAdvicePartySources {
  customerAssignedAccountId: string;
  buyerCustomerParty: {
    title: string;
    source: string;
    fields: IPartySources;
  };
  deliveryCustomerParty: {
    title: string;
    source: string;
    fields: IPartySources;
  };
  sellerSupplierParty: {
    title: string;
    source: string;
    fields: IPartySources;
  };
  despatchSupplierParty: {
    title: string;
    source: string;
    fields: IPartySources;
  };
}

export interface IDespatchAdviceRootSources {
  orderReference: {
    source: string;
    fields: IOrderReferenceSources;
  };
  documentReferences: string;
  note: string;
  lineCountNumeric: string;
  issueDate: string;
  issueTime: string;
  number: string;
  shipmentInformation: {
    source: string;
    fields: IShipmentInformationSources;
  };
  despatchLine: {
    source: string;
    fields: IDespatchAdviceLineSources;
  };
}

export interface IDespatchAdviceLineSources {
  id: string;
  deliveredQuantity: string;
  deliveryQuantityUnitCode: string;
  item: {
    source: string;
    fields: ILineItemSources;
  };
  orderReference: {
    source: string;
    fields: IOrderReferenceSources;
  };
  orderLineReferenceLineId: string;
}
export interface IDespatchAdviceSources {
  AmountSources: IAmountSources;
  AddressSources: IAddressSources;
  ContactSources: IContactSources;
  DocumentReferenceSources: IDocumentReferenceSources;
  LocationSources: ILocationSources;
  PartySources: IPartySources;
  DespatchAdvicePartySources: IDespatchAdvicePartySources;
  DespatchAdviceRootSources: IDespatchAdviceRootSources;
  ShipmentInformationSources: IShipmentInformationSources;
  DeliverySources: IDeliverySources;
  DespatchAdviceLineSources: IDespatchAdviceLineSources;
  OrderReferenceSources: IOrderReferenceSources;
}
export default class DespatchAdviceSources extends UblCommonSources {
  constructor(public web: boolean) {
    super(web);
  }

  public ShipmentInformationSources = (): IShipmentInformationSources => {
    return {
      id: this.web ? 'ID[0]._' : 'id.value',
      declaredCustomsValueAmount: this.web
        ? 'DeclaredCustomsValueAmount[0]._'
        : 'declaredCustomsValueAmount.value',
      declaredCustomsValueAmountCurrencyID: this.web
        ? 'DeclaredCustomsValueAmount[0].currencyID'
        : 'declaredCustomsValueAmount.currencyID',
      exportCountryIdentificationCode: this.web
        ? 'ExportCountry[0].IdentificationCode[0]._'
        : 'exportCountry.identificationCode.value',
      grossVolumeMeasure: this.web
        ? 'GrossVolumeMeasure[0]._'
        : 'grossVolumeMeasure.value',
      grossVolumeMeasureUnitCode: this.web
        ? 'GrossVolumeMeasure[0].unitCode'
        : 'grossVolumeMeasure.unitCode',
      netVolumeMeasure: this.web
        ? 'NetVolumeMeasure[0]._'
        : 'netVolumeMeasure.value',
      netVolumeMeasureUnitCode: this.web
        ? 'NetVolumeMeasure[0].unitCode'
        : 'netVolumeMeasure.unitCode',
      specialInstructions: this.web
        ? 'SpecialInstructions[0]._'
        : 'specialInstructions.value',
      totalGoodsItemQuantity: this.web
        ? 'TotalGoodsItemQuantity[0]._'
        : 'totalGoodsItemQuantity.value',
      totalGoodsItemQuantityUnitCode: this.web
        ? 'TotalGoodsItemQuantity[0].unitCode'
        : 'totalGoodsItemQuantity.unitCode',
      delivery: {
        source: this.web ? 'Delivery[0]' : 'delivery',
        fields: this.DeliverySources(),
      },
      shipmentStage: {
        source: this.web ? 'ShipmentStage[0]' : 'shipmentStage',
        fields: this.ShipmentStageSources(),
      },
      transportHandlingUnit: {
        source: this.web ? 'TransportHandlingUnit[0]' : 'transportHandlingUnit',
        fields: this.TransportHandlingUnitSources(),
      },
    };
  };

  public DespatchAdvicePartySources = (): IDespatchAdvicePartySources => {
    return {
      buyerCustomerParty: {
        title: 'dxMessages.headers.buyer',
        source: this.web
          ? 'BuyerCustomerParty[0].Party[0]'
          : 'buyerCustomerParty.party',
        fields: this.PartySources(),
      },
      deliveryCustomerParty: {
        title: 'dxMessages.headers.buyer',
        source: this.web
          ? 'DeliveryCustomerParty[0].Party[0]'
          : 'deliveryCustomerParty.party',
        fields: this.PartySources(),
      },
      customerAssignedAccountId: this.web
        ? 'SellerSupplierParty[0].CustomerAssignedAccountID[0]._'
        : 'sellerSupplierParty.customerAssignedAccountID.value',
      sellerSupplierParty: {
        title: 'dxMessages.headers.supplier',
        source: this.web
          ? 'SellerSupplierParty[0].Party[0]'
          : 'sellerSupplierParty.party',
        fields: this.PartySources(),
      },
      despatchSupplierParty: {
        title: 'dxMessages.headers.shippingLocation',
        source: this.web
          ? 'DespatchSupplierParty[0].Party[0]'
          : 'despatchSupplierParty.party',
        fields: this.PartySources(),
      },
    };
  };

  public DespatchAdviceLineSources = (): IDespatchAdviceLineSources => {
    return {
      id: this.web ? 'ID[0]._' : 'id.value',
      deliveredQuantity: this.web
        ? 'DeliveredQuantity[0]._'
        : 'deliveredQuantity.value',
      deliveryQuantityUnitCode: this.web
        ? 'DeliveredQuantity[0].unitCode'
        : 'deliveredQuantity.unitCode',
      item: {
        source: this.web ? 'Item[0]' : 'item',
        fields: this.LineItemSources(),
      },
      orderReference: {
        source: this.web
          ? 'OrderLineReference[0].OrderReference[0]'
          : 'orderLineReference[0].orderReference',
        fields: this.OrderReferenceSources(),
      },
      orderLineReferenceLineId: this.web
        ? 'OrderLineReference[0].LineID[0]._'
        : 'orderLineReference[0].lineID.value',
    };
  };

  public DespatchAdviceRootSources = (): IDespatchAdviceRootSources => {
    return {
      orderReference: {
        source: this.web ? 'OrderReference[0]' : 'orderReference[0]',
        fields: this.OrderReferenceSources(),
      },
      documentReferences: this.web
        ? 'AdditionaldocumentReferences'
        : 'additionalDocumentReference',
      note: this.web ? 'Note[0]._' : 'note[0].value',
      lineCountNumeric: this.web
        ? 'LineCountNumeric[0]._'
        : 'lineCountNumeric.value',
      issueDate: this.web ? 'IssueDate[0]._' : 'issueDate.value',
      issueTime: this.web ? 'IssueTime[0]._' : 'issueTime.value',
      number: this.web ? 'ID[0]._' : 'id.value',
      shipmentInformation: {
        source: this.web ? 'Shipment[0]' : 'shipment',
        fields: this.ShipmentInformationSources(),
      },
      despatchLine: {
        source: this.web ? 'DespatchLine' : 'despatchLine',
        fields: this.DespatchAdviceLineSources(),
      },
    };
  };

  public DespatchAdviceSources = () => {
    return {
      root: {
        source: this.web ? 'ublProperties.DespatchAdvice[0]' : 'ublProperties',
        fields: {
          ...this.DespatchAdviceRootSources(),
          ...this.DespatchAdvicePartySources(),
        },
      },
    };
  };

  public DespatchAdviceObjectFieldSources = () => {
    return {
      root: {
        fields: {
          documentReference: {
            fields: this.DocumentReferenceSources(),
          },
        },
      },
    };
  };

  /**
   * Uppercase the first letter of the given string.
   * @param string the string to modify
   */
  public static uppercaseFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Generate all properties from the given object.
   * @param element the element used to generate properties
   */
  public static generateUblProperties(element: any) {
    if (typeof element === 'string') {
      return { '': element };
    } else {
      let fields = {};
      let result: any;

      if (element.fields) {
        for (const field of Object.entries(element.fields)) {
          let elementFields = this.generateUblProperties(field[1]);

          let enhancedSubFields = {};
          Object.keys(elementFields).forEach((key) => {
            enhancedSubFields[field[0] + this.uppercaseFirstLetter(key)] =
              element.source
                ? element.source + '.' + elementFields[key]
                : elementFields[key];
          });

          fields = { ...fields, ...enhancedSubFields };
        }
        result = fields;
      } else {
        result = { '': element.source };
      }
      return result;
    }
  }

  /**
   * Contains all properties generated from the previous model for web documents.
   */
  public static WebDespatchAdviceUbl: any = () => {
    const webDespatchAdvice = new DespatchAdviceSources(true);
    return {
      ...DespatchAdviceSources.generateUblProperties(
        webDespatchAdvice.DespatchAdviceSources().root
      ),
      ...DespatchAdviceSources.generateUblProperties(
        webDespatchAdvice.DespatchAdviceObjectFieldSources().root
      ),
    };
  };

  /**
   * Contains all properties generated from the previous model for non web documents.
   */
  public static Ubl: any = () => {
    const despatchAdvice = new DespatchAdviceSources(false);
    return {
      ...DespatchAdviceSources.generateUblProperties(
        despatchAdvice.DespatchAdviceSources().root
      ),
      ...DespatchAdviceSources.generateUblProperties(
        despatchAdvice.DespatchAdviceObjectFieldSources().root
      ),
    };
  };
}
