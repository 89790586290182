import { useFeature } from 'flagged';
import { useEffect } from 'react';
import { useAuthProvider } from 'react-admin';
import { getDxApplication, getDxApplicationDisplayName } from '../utils/utils';
import useSetupUsage, { SetupUsage } from './useSetupUsage';

const useDelightedSurvey = () => {
  const authProvider = useAuthProvider();
  const delightedSurveyEnabled = useFeature('delightedSurvey');
  const setupUsage = useSetupUsage();

  useEffect(() => {
    const initialize_NPS_survey = () => {
      const { fullName, company, email }: any = authProvider.getIdentity?.();
      const params = {
        email: email ?? 'anonymous',
        name: fullName ?? 'anonymous',
        properties: {
          company: company.name ?? 'unknown',
          product: getDxApplicationDisplayName(getDxApplication()),
        },
      };
      const stringifyParams = JSON.stringify(params);
      const snippetKey = process.env.REACT_APP_DELIGHTED_KEY;
      const previousScripts: any =
        document.getElementsByClassName('deligthed-survey');

      if (previousScripts.length === 1) {
        previousScripts[0].parentElement.removeChild(previousScripts[0]);
      }
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.className = 'deligthed-survey';
      script.text =
        `!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"${snippetKey}","delighted");` +
        `delighted.survey(${stringifyParams});`;
      document.body.append(script);
    };
    if (delightedSurveyEnabled && setupUsage === SetupUsage.PRODUCTION) {
      initialize_NPS_survey();
    }
  }, [authProvider, delightedSurveyEnabled, setupUsage]);
};

export default useDelightedSurvey;
