import {
  Constants,
  documentTypeCodeToResource,
  nodeTypeToResource,
  useGAPageViews,
} from '@dx-ui/dx-common';
import get from 'lodash/get';
import { ShowController } from 'react-admin';
import { DespatchAdviceShowView } from '../modules/despatchAdvice';
import { ForecastShowView } from '../modules/forecast';
import { InvoiceShowView } from '../modules/invoice';
import { OrderShowView } from '../modules/order';
import { ReceiptAdviceShowView } from '../modules/receiptAdvice';

const CommonShow = (props) => {
  const { translate, theme, basePath, id, resource, location, ...rest } = props;

  useGAPageViews();

  return (
    <div>
      <ShowController
        location={location}
        basePath={basePath}
        id={id}
        resource={documentTypeCodeToResource(get(location, 'typeCode'))}
        {...rest}
      >
        {(controllerProps) => {
          if (
            nodeTypeToResource(get(controllerProps.record, 'nodeType')) ===
            Constants.RESOURCE_INVOICE
          ) {
            return (
              <InvoiceShowView
                controllerProps={controllerProps}
                basePath={basePath}
                {...rest}
              />
            );
          } else if (
            nodeTypeToResource(get(controllerProps.record, 'nodeType')) ===
            Constants.RESOURCE_ORDER
          ) {
            return (
              <OrderShowView
                controllerProps={controllerProps}
                basePath={basePath}
                {...rest}
              />
            );
          } else if (
            nodeTypeToResource(get(controllerProps.record, 'nodeType')) ===
            Constants.RESOURCE_DESPATCH_ADVICE
          ) {
            return (
              <DespatchAdviceShowView
                controllerProps={controllerProps}
                basePath={basePath}
                {...rest}
              />
            );
          } else if (
            nodeTypeToResource(get(controllerProps.record, 'nodeType')) ===
            Constants.RESOURCE_RECEIPT_ADVICE
          ) {
            return (
              <ReceiptAdviceShowView
                controllerProps={controllerProps}
                basePath={basePath}
                {...rest}
              />
            );
          } else if (
            nodeTypeToResource(get(controllerProps.record, 'nodeType')) ===
            Constants.RESOURCE_FORECAST
          ) {
            return (
              <ForecastShowView
                controllerProps={controllerProps}
                basePath={basePath}
                {...rest}
              />
            );
          } else {
            return <></>;
          }
        }}
      </ShowController>
    </div>
  );
};

export default CommonShow;
