import { SelectArrayInput } from '@dx-ui/dx-common';
import { get } from 'lodash';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a SelectInput
 */
const SelectArrayInputField = (props: FieldComponentProps) => {
  const { field, selectValues } = props;

  const options = field.options;

  let valueField: string = options?.optionValue || 'id';
  let nameField: string = options?.optionText ?? valueField;

  // select items
  const choices = field.source ? get(selectValues, field.source) || [] : [];

  // allow empty value
  const allowEmpty = field.allowEmpty ? field.allowEmpty : false;

  const sanitizeProps = ({
    inputProps,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <SelectArrayInput
          {...sanitizeProps(fieldProps)}
          optionText={nameField}
          optionValue={valueField}
          itemOptionValue={options?.itemOptionValue}
          choices={choices}
          allowEmpty={allowEmpty}
        />
      )}
    </FieldWrapper>
  );
};

export default SelectArrayInputField;
