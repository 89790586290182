// The toolbar for the invoice validation cockpit.
// Display two buttons: Approve or Reject.
import { colors } from '@dx-ui/dx-common';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { SaveButton, Toolbar } from 'react-admin';

const i18nKey =
  'dxMessages.task.invoice_matching.invoiceHoldDecisionTask.cockpit.';

// The toolbar of the invoice validation cockpit.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const InvoiceValidationCockpitToolbar = (props) => {
  const { record, pristine, saving } = props;
  if (!record) return null;

  return (
    <Toolbar {...props}>
      <SaveButton
        label={i18nKey + 'button.approve'}
        icon={<ThumbUpIcon />}
        transform={buildRequest('accept')}
        disabled={saving}
      />
      <SaveButton
        label={i18nKey + 'button.reject'}
        icon={<ThumbDownIcon />}
        transform={buildRequest('reject')}
        style={{ margin: '2px', backgroundColor: colors.brightOrange }}
        disabled={pristine || saving}
      />
    </Toolbar>
  );
};

const buildRequest = (action: string) => (props: any) => {
  return {
    input: {
      data: props.input.data,
      action: action,
    },
  };
};

export default InvoiceValidationCockpitToolbar;
