import { Alert } from '@material-ui/lab';
import { get } from 'lodash';
import { useLocale } from 'react-admin';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders an Alert message displaying a text according to Locale change
 */
const MessageDisplayField = (props: FieldComponentProps) => {
  const locale = useLocale();

  const { field, selectValues } = props;

  const options = { ...field.options };

  let valueField: string = options?.optionValue || 'id';
  let nameField: string = options?.optionText ?? valueField;

  // select the message to display according to the locale
  const choices = field.source ? get(selectValues, field.source) || [] : [];
  let message = choices.find((c: string) => c[valueField] === locale)?.[
    nameField
  ];
  if (message === undefined) {
    message = choices.find((c: string) => c[valueField] === field.defaultValue)[
      nameField
    ];
  }

  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <Alert severity='warning' style={{ margin: '1em' }}>
          {message}
        </Alert>
      )}
    </FieldWrapper>
  );
};

export default MessageDisplayField;
