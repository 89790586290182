import { SimpleLabeledField } from '@dx-ui/dx-common';
import { Grid } from '@material-ui/core';
import { get } from 'lodash';
import { Fragment } from 'react';
import { useTranslate } from 'react-admin';
import { IWayBillSources } from '../WayBillSources';

interface PropsModel {
  record: any;
  dataSources: IWayBillSources;
  title: string;
}
const WayBillContactFragment = (props: PropsModel): JSX.Element => {
  const { record, dataSources, title } = props;
  const translate = useTranslate();
  return (
    <Fragment>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.contactName')}
          data={get(record, dataSources.ContactSources.contactName)}
          oneLine
          className={`${title}_contactName`}
        />
      </Grid>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.phone')}
          data={get(record, dataSources.ContactSources.phone)}
          oneLine
          className={`${title}_phone`}
        />
      </Grid>
      <Grid item>
        <SimpleLabeledField
          label={translate('dxMessages.waybill.partyTile.email')}
          data={get(record, dataSources.ContactSources.email)}
          oneLine
          className={`${title}_email`}
        />
      </Grid>
    </Fragment>
  );
};

export default WayBillContactFragment;
