import { makeStyles } from '@material-ui/core/styles';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import React from 'react';
import { SaveButton, Toolbar, useNotify } from 'react-admin';

const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    // justifyContent: "center",
    columnGap: '1em',
  },
});

const useButtonStyles = makeStyles({
  reject: {
    backgroundColor: 'red',
  },
});

type action = 'accept' | 'reject';

// A toolbar to customise the Save & Cancel buttons.
const SaintGobainCockpitToolbar = (props) => {
  const toolbarClasses = useToolbarStyles();
  const buttonClasses = useButtonStyles();
  const notify = useNotify();
  const { record } = props;
  if (!record) return null;

  const buildRequest = (action: action) => (props: any) => {
    const uiTask = props.uiSpecification?.type;
    if (action === 'accept') {
      if (uiTask === 'sg_ap_validation') {
        if (!props.input?.data?.sapId) {
          notify(
            'dxMessages.task.saintgobainui.errorMsg.missing_sap_id',
            'error'
          );
          return Promise.reject();
        }
        if (props.input?.data?.sapId.length > 10) {
          notify(
            'dxMessages.task.saintgobainui.errorMsg.max_sap_id_length',
            'error'
          );
          return Promise.reject();
        }
        const reg = new RegExp('^\\d+$');
        if (!reg.test(props.input?.data?.sapId)) {
          notify('dxMessages.error_messages.type_number', 'error');
          return Promise.reject();
        }
      }
      if (
        props.input?.data?.comment ||
        props.input?.data?.additionalInformation
      ) {
        notify(
          'dxMessages.task.saintgobainui.errorMsg.rej_reason_with_accept',
          'error'
        );
        return Promise.reject();
      }
    } else {
      if (!props.input?.data?.comment) {
        notify(
          'dxMessages.task.saintgobainui.errorMsg.missing_rej_reason',
          'error'
        );
        return Promise.reject();
      }
      if (uiTask === 'sg_ap_validation') {
        if (props.input?.data?.sapId) {
          notify(
            'dxMessages.task.saintgobainui.errorMsg.sapid_with_rej',
            'error'
          );
          return Promise.reject();
        }
      }
    }
    return {
      ...props,
      input: {
        data: props.input?.data || {},
        action: action,
      },
    };
  };

  return (
    // @ts-ignore
    <Toolbar {...props} classes={toolbarClasses}>
      <SaveButton
        label='Approve'
        icon={<ThumbUpIcon />}
        transform={buildRequest('accept')}
      />
      <SaveButton
        className={buttonClasses.reject}
        label='Reject'
        icon={<ThumbDownIcon />}
        transform={buildRequest('reject')}
      />
    </Toolbar>
  );
};

export default SaintGobainCockpitToolbar;
