import { Badge, Divider, IconButton, Menu, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NotificationAddIcon from '@material-ui/icons/NotificationImportant';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { usePreferences } from '@react-admin/ra-preferences';
import { startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  MenuItemLink,
  fetchUtils,
  useLocale,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { isPspAdministrator } from '../../configuration';
import { UsageRight } from '../../configuration/types';
import { Constants } from '../../constants';
import { colors } from '../../constants/Colors';
import {
  getDxApplication,
  useInterval,
  useSetupUsage,
  useSwitchTo,
} from '../../utils';
import { SetupUsage } from '../../utils/useSetupUsage';
import { MessageState, MessageType } from './types';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    secondaryText: {
      fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
      fontSize: 12, // 0.75em
      fontWeight: 400,
      letterSpacing: '0.05em',
      color: colors.black50,
    },
  }),
  { name: 'NotificationsMenu' }
);

const NotificationsMenu = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { permissions } = usePermissions<UsageRight[], any>();
  const handleOpenMenu = (event) => {
    getNewPublishedMessages();
    setAnchorEl(event.currentTarget);
  };
  const [count, setCount] = useState(0);
  const [data, setData] = useState();
  const viewVersion = useSelector((state: any) => state.admin.ui.viewVersion);

  const translate = useTranslate();
  const locale = useLocale();
  const [lastClickAfterPublish, setLastClickAfterPublish] = usePreferences(
    'dxportal.messageCenter.cfg.lastClickAfterPublish',
    startOfMonth(new Date()).getTime()
  );

  const switchTo = useSwitchTo();
  const dxApplication = getDxApplication();
  const setupUsage: SetupUsage | undefined = useSetupUsage();

  const handleClickListItem = (event, id) => {
    // Stores click timestamp
    setLastClickAfterPublish(new Date().getTime());
    // close the menu
    setAnchorEl(null);

    if (setupUsage === SetupUsage.DEV) {
      switchTo(`/#/${Constants.RESOURCE_MESSAGES}/${id}/show`, dxApplication);
    } else {
      switchTo(
        `/${dxApplication}/#/${Constants.RESOURCE_MESSAGES}/${id}/show`,
        dxApplication
      );
    }
  };

  const getNewPublishedMessages = async () => {
    // Can't use useQuery and dataProvider.getUtils :
    // * UseQuery, because it is not possible
    //    to call a hook into useInterval.
    // * dataProvider.getUtils, because we need to not interfere with redux-store in order to avoid
    //    the rendering of the current page with incorrect data.
    const currentDate = new Date();
    const lastMonth = currentDate.setMonth(currentDate.getMonth() - 1);
    fetchUtils
      .fetchJson(
        `${window.location.origin}/api/${Constants.RESOURCE_MESSAGES}?_end=10` +
          `&_order=DESC&_sort=publicationTs&_start=0&language=${locale}&publishedAfterTime=${lastMonth}&state=${MessageState.PUBLISHED}` +
          `&types=${MessageType.BANNER}&types=${MessageType.DOWNTIME}&types=${MessageType.MARKETING}&types=${MessageType.GENERAL}`,
        {
          credentials: 'include',
        }
      )
      .then(({ json: data }) => {
        setCount(data?.length);
        setData(data as any);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error : %o', error);
      });
  };

  useEffect(() => {
    getNewPublishedMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewVersion, locale]);

  // Get the last published messages from the message center.
  useInterval(() => {
    getNewPublishedMessages();
  }, 1000 * 60 * 5);

  const open = Boolean(anchorEl);

  const notif = (data as any)?.some(
    (_) => _.publicationInstant > lastClickAfterPublish
  );
  return (
    <div>
      <Tooltip title={translate('dxMessages.buttons.messagesCenter')}>
        <IconButton
          aria-label='messages-center'
          onClick={handleOpenMenu}
          color='inherit'
        >
          <Badge
            badgeContent={<></>}
            invisible={!notif}
            color='primary'
            overlap='rectangle'
            variant='dot'
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        open={
          !isPspAdministrator(permissions as any) && count === 0 ? false : open
        }
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        getContentAnchorEl={null}
        onClose={(_) => setAnchorEl(null)}
      >
        {isPspAdministrator(permissions as any) && (
          <MenuItemLink
            to={`/${Constants.RESOURCE_MESSAGES}`}
            primaryText={translate('dxMessages.buttons.messagesCenter')}
            leftIcon={<NotificationAddIcon />}
            key='messageCenter'
            onClick={(_) => setAnchorEl(null)}
          />
        )}
        {isPspAdministrator(permissions as any) && count > 0 && (
          <Divider style={{ height: 4 }} />
        )}
        {count > 0 &&
          (data as any)?.map((n, index) => {
            return (
              <MenuItemLink
                primaryText={
                  <div style={{ minWidth: '100%', marginLeft: 2 }}>
                    {n.topic}
                    <div className={classes.secondaryText}>
                      {translate(
                        `dxMessages.messagesCenter.type.${n.type.toUpperCase()}`
                      )}
                    </div>
                  </div>
                }
                to={''} // cf. onClick
                leftIcon={
                  <Badge
                    badgeContent={<></>}
                    invisible={n.publicationInstant <= lastClickAfterPublish}
                    color='primary'
                    overlap='rectangle'
                    variant='dot'
                  >
                    <NotificationsIcon />
                  </Badge>
                }
                key={`message_${index}`}
                onClick={(event) => handleClickListItem(event, n.id)}
              />
            );
          })}
      </Menu>
    </div>
  );
};

export default NotificationsMenu;
