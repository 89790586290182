import { InputIcon, sortLabeledItemLists } from '@dx-ui/dx-common';
import {
  CircularProgress,
  FormControl,
  Input,
  MenuItem,
  Popover,
  Tooltip,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import keycode from 'keycode';
import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { SelectRenderer } from '../menus/SelectRenderer';

const AutoCompleteSingleSelectInput = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<any>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const translate = useTranslate();
  const {
    onOpen,
    onClose,
    onChange,
    name,
    onBlur,
    readOnly,
    label,
    classes,
    className: classNameProp,
    disabled,
    value: valueRaw,
    sortLabels = false,
    options,
    getOptionId,
    renderOptionLabel,
    renderOptionTooltip,
    filterOption,
    children,
    icon,
    placeholder,
    filterPlaceholder: filterPlaceholderProp,
    onFocus,
    inputRef,
    intl,
    loading,
    errorText,
    renderValue,
    numberToDisplaySearch = 10,
    ...other
  } = props;

  let ignoreNextBlur = false;

  const handleClick = (event) => {
    ignoreNextBlur = true;
    setOpen(true);
    setQuery('');
    setAnchorEl(event.currentTarget);

    if (onOpen) {
      onOpen(event);
    }
  };

  const handleClose = (evt) => {
    setOpen(false);
    if (onClose) {
      onClose(evt);
    }
  };

  const handleItemClick = (id) => (event) => {
    if (!onChange) {
      return;
    }

    const newValue = id;

    const { target } = event;
    /* eslint-disable no-param-reassign */
    event.target = { ...target, value: newValue, name };
    /* eslint-enable no-param-reassign */
    onChange(event, newValue);
    setOpen(false);
  };

  const handleBlur = (event) => {
    if (ignoreNextBlur === true) {
      event.stopPropagation();
      ignoreNextBlur = false;
      return;
    }

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleKeyDown = (event) => {
    if (readOnly) {
      return;
    }

    if (['space', 'up', 'down'].includes(keycode(event))) {
      event.preventDefault();
      // Opening the menu is going to blur the. It will be focused back when closed.
      ignoreNextBlur = true;
      setOpen(true);
      setQuery('');
      setAnchorEl(event.currentTarget);

      if (onOpen) {
        onOpen(event);
      }
    }
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSelectRef = (node) => {
    if (inputRef) {
      inputRef(node, valueRaw);
    }
  };

  const value = valueRaw?.length === 1 && valueRaw[0] === '_' ? [] : valueRaw;

  const sortedOptions = sortLabels
    ? sortLabeledItemLists(translate, options, renderOptionLabel)
    : options;

  const lowerCaseQuery = query.toLowerCase();

  const unselectedItems: any[] = [];
  sortedOptions.forEach((option, idx) => {
    const id = getOptionId(option);
    if (value.includes(id)) {
      return;
    } // They"ve been iterated above
    if (
      filterOption &&
      !filterOption(option, query, lowerCaseQuery, translate)
    ) {
      return;
    }
    unselectedItems.push(
      <Tooltip
        title={
          renderOptionTooltip && renderOptionTooltip(option)
            ? translate(renderOptionTooltip(option), {
                _: renderOptionTooltip(option),
              })
            : ''
        }
        key={`${idx}-${id}`}
      >
        <div key={`${idx}-${id}`}>
          <MenuItem onClick={handleItemClick(id)}>
            {translate(renderOptionLabel(option), {
              _: renderOptionLabel(option),
            })}
          </MenuItem>
        </div>
      </Tooltip>
    );
  });

  const minimumMenuWidth = !!anchorEl ? anchorEl?.clientWidth : undefined;

  const filterPlaceholder =
    filterPlaceholderProp ||
    translate('dxMessages.list.search', { _: 'Search' });

  const searchAppearance =
    filterOption && sortedOptions.length > numberToDisplaySearch;
  /*
    Same component 2 behaviours :
      - if renderValue is undefined, the input displays the selected item.
      - if renderValue is a Node object the input displays only this one.
    */
  const displayValue = renderValue ? (
    renderValue
  ) : (
    <SelectRenderer placeholder={placeholder} value={value} classes={classes}>
      {(id) => {
        if (!id) {
          return null;
        }
        const option = sortedOptions.find((op) => getOptionId(op) === id);
        return option ? (
          <Tooltip
            title={
              renderOptionTooltip(option)
                ? translate(renderOptionTooltip(option), {
                    _: renderOptionTooltip(option),
                  })
                : ''
            }
          >
            <span>
              {translate(renderOptionLabel(option), {
                _: renderOptionLabel(option),
              })}
            </span>
          </Tooltip>
        ) : (
          id
        );
      }}
    </SelectRenderer>
  );

  return (
    <div className={classes.root}>
      <div
        className={classNames(
          classes.select,
          classes.selectMenu,
          {
            [classes.disabled]: disabled,
          },
          classNameProp
        )}
        aria-pressed={open ? 'true' : 'false'}
        tabIndex={disabled ? undefined : 0}
        role='button'
        aria-owns={open ? `menu-${name || ''}` : undefined}
        aria-haspopup='true'
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onClick={disabled || readOnly ? undefined : handleClick}
        onFocus={onFocus}
      >
        {displayValue}
      </div>
      <input
        value={value}
        name={name}
        readOnly={readOnly}
        ref={handleSelectRef}
        {...other}
        type='hidden'
      />
      {!renderValue && <ArrowDropDownIcon className={classes.icon} />}
      <Popover
        id={`menu-${name || ''}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          style: {
            minWidth: minimumMenuWidth,
            overflowY: 'initial',
          },
        }}
      >
        <div className={classes.paperWrapper}>
          {searchAppearance && (
            <FormControl className={classes.filter}>
              <Input
                placeholder={filterPlaceholder}
                value={query}
                onChange={handleQueryChange}
                autoFocus
              />
              <InputIcon>
                <SearchIcon className={classes.icon} />
              </InputIcon>
            </FormControl>
          )}
          <div className={classes.items}>
            {loading && (
              <div className={classes.loader}>
                <CircularProgress size={24} />
              </div>
            )}
            {!!errorText && (
              <div className={classes.errorText}>{errorText}</div>
            )}
            {unselectedItems}
          </div>
          {children && <div className={classes.children}>{children}</div>}
        </div>
      </Popover>
    </div>
  );
};

export default AutoCompleteSingleSelectInput;
