import { ReferenceInput, SelectInput } from '@dx-ui/dx-common';
import { useCommonFieldStyles } from './CommonFieldStyles';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a ReferenceInput
 * The rendering takes into account that the field can be a member of an array.
 */
const ReferenceInputField = (props: FieldComponentProps) => {
  const { field, readOnly } = props;

  const classes = useCommonFieldStyles(props);

  const options = field.options;
  const sanitizeProps: any = ({ withConfirm, enableIfValid, ...rest }): any =>
    rest;

  return (
    <FieldWrapper {...sanitizeProps(props)}>
      {(fieldProps) => (
        <ReferenceInput {...fieldProps} reference={field.reference}>
          <SelectInput
            optionText={options?.optionText}
            className={readOnly ? classes.readOnly : ''}
          />
        </ReferenceInput>
      )}
    </FieldWrapper>
  );
};

export default ReferenceInputField;
