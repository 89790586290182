import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { useSelector } from 'react-redux';
import { Constants } from '../constants/Constants';
import { checkPermissions } from '../security';
import { UserRoles } from '../security/UserRoles';

const useTaskCount = ({
  permissions,
  productName,
}: {
  permissions: any;
  productName: string;
}): number => {
  const viewVersion = useSelector((state: any) => state.admin.ui.viewVersion);
  const taskListCount = useSelector(
    (state: any) => state.admin.resources[Constants.RESOURCE_TASKS]?.list?.total
  );
  const dataProvider = useDataProvider();
  const [count, setCount] = useState(0); // The task count.

  useEffect(
    () => {
      let mounted = false;
      if (
        (productName === Constants.DXPURCHASE_APP &&
          checkPermissions(
            permissions,
            UserRoles.DXPURCHASE_PRODUCT,
            UserRoles.VIEW_TASKS
          )) ||
        (productName === Constants.RECONCILIATION_COCKPIT_APP &&
          (checkPermissions(
            permissions,
            UserRoles.RECONCILIATION_COCKPIT_PRODUCT,
            'reconciliator'
          ) ||
            checkPermissions(
              permissions,
              UserRoles.RECONCILIATION_COCKPIT_PRODUCT,
              'viewer'
            )))
      ) {
        // Same API call but real users can't have both Product access
        dataProvider
          .getOne(Constants.RESOURCE_TASKS, {
            id:
              productName === Constants.RECONCILIATION_COCKPIT_APP
                ? 'taskCount?flowDefinitionKeys=i2o_matching,i2pr_matching'
                : 'taskCount',
          })
          .then(({ data }: any) => {
            if (!mounted) {
              // Don't change the state if the component is not visible anymore.
              setCount(data.taskCount);
            }
          })
          .catch((error: any) => {
            if (!mounted) {
              // Don't change the state if the component is not visible anymore.
              // eslint-disable-next-line no-console
              console.debug('Task management is not available');
            }
          });
      }
      return () => {
        mounted = true;
      };
    }, // The effect depends on the view version so get called on each refresh.
    [viewVersion, taskListCount, dataProvider, permissions, productName]
  );

  return count;
};

export default useTaskCount;
