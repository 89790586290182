import {
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import WhereToVoteIcon from '@material-ui/icons/WhereToVote';
import { useEffect, useState } from 'react';
import {
  fetchUtils,
  HttpError,
  Identifier,
  Loading,
  useNotify,
  useTranslate,
} from 'react-admin';
import { Company } from '../configuration/types';
import { Constants, REST_ENDPOINTS } from '../constants';
import { saveUsageRightsData } from './SsoAuthProvider';

const PersonCompanySwitcher = ({ identity, ...props }) => {
  const classes = useStyles(props);
  const translate = useTranslate();
  const notify = useNotify();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<HttpError | null>(null);

  useEffect(() => {
    setLoading(true);
    setError(null);
    fetchUtils
      .fetchJson(`${REST_ENDPOINTS.ROOT}/${REST_ENDPOINTS.MY_COMPANIES}`, {
        credentials: 'include',
      })
      .then(({ json }) => {
        setLoading(false);
        // Sort the company per name.
        setCompanies(json.sort((c1, c2) => c1.name.localeCompare(c2.name)));
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchCompany = (companyId: Identifier) => {
    fetchUtils
      .fetchJson(
        `${window.location.origin}${REST_ENDPOINTS.ROOT}/${REST_ENDPOINTS.SWITCH_COMPANY}/${companyId}`,
        {
          credentials: 'include',
        }
      )
      .then(({ json: account }) => {
        // Small hack here to set the local storage to help with switching
        // app smoother.
        // localStorage.setItem(getUser()?.id + '.dxApplication', appName);

        // Calling 'switchCompany' updates the SSO cookie with the new account
        // re-set the local storage and reload the App to reflect the new account view.
        localStorage.setItem(
          Constants.DX_USER_ACCOUNT,
          JSON.stringify(account)
        );
      })
      .then(() => {
        // Calling usage-rigths
        // Then deals with permissions
        return fetchUtils.fetchJson(`${window.location.origin}/usage-rights`, {
          credentials: 'include',
        });
      })
      .then(({ json: usageRigths }) => {
        const userName = localStorage.getItem('user');

        saveUsageRightsData(userName, usageRigths);

        // Reload the app but reset the URL hash first as it may point to a page
        // (like the account management page) not accessible with the new account.
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, '', ' ');
        window.location.reload();
      })
      .catch((error) =>
        notify(
          'dxMessages.error_messages.OnError_contact_the_support',
          'error',
          {
            error: error.message,
          }
        )
      );
  };

  return (
    <div>
      <MenuItem onClick={handleClick} disabled={!!error}>
        <ListItemIcon className={classes.icon}>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText className={classes.root}>
          {translate('dxMessages.user_menu.SwitchCompany')}
        </ListItemText>
        <KeyboardArrowRightIcon />
      </MenuItem>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {loading && <Loading />}
        {!loading &&
          !error &&
          companies.map((company) => (
            <MenuItem
              key={company.id}
              onClick={() => switchCompany(company.id)}
              disabled={company.id === identity.company.id}
            >
              {company.id === identity.company.id && (
                <Box mr={1}>
                  <WhereToVoteIcon color='error' fontSize='small' />
                </Box>
              )}
              {company.name}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.secondary,
    },
    active: {
      color: theme.palette.text.primary,
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: 'PersonCompanySwitcher' }
);

export default PersonCompanySwitcher;
