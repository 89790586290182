export const REDIRECT_TO = 'REDIRECT_TO';

export interface RedirectAction {
  readonly type: typeof REDIRECT_TO;
  readonly meta: { redirectTo?: string };
}

/**
 * Action to trigger to redirect to custom url
 * @param redirect Path to direct to
 * @return {{type: string, payload: {redirectTo: string}}}
 */
export const redirect = (redirectTo?: string): RedirectAction => ({
  type: REDIRECT_TO,
  meta: { redirectTo },
});
