import { Typography } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import { useTranslate } from 'ra-core';
import React, { useEffect, useMemo, useState } from 'react';
import { Loading, useDataProvider } from 'react-admin';
import { DocumentServiceFactory, WaybillServiceCreator } from '../../services';
import { AlfrescoDocumentService } from '../../services/AlfrescoDocumentService';
import { WaybillService } from '../../services/WaybillService';
import { AlfrescoContent, P2pPreviewData } from '../../shared/types';
import { useCommonPreviewStyles } from '../common/PreviewStyles';
import WayBillPreviewController from './WayBillPreviewController';

interface PropsModel {
  record: P2pPreviewData;
  toolbar?: any;
  basePath?: any;
}

const WayBillPreview = (props: PropsModel) => {
  const { basePath, record, toolbar }: PropsModel = props;

  const dataProvider = useDataProvider();
  const classes = useCommonPreviewStyles(props);
  const translate = useTranslate();

  const [data, setData] = useState<P2pPreviewData | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const waybillService = useMemo(
    () =>
      DocumentServiceFactory.create(
        WaybillServiceCreator,
        dataProvider
      ) as WaybillService,
    [dataProvider]
  );

  useEffect(() => {
    const getData = async () => {
      const nodeId: string | undefined = record.id;

      try {
        let properties: any = {};
        let ublProperties: any = {};

        properties = cloneDeep(record?.properties ?? {});

        if (record?.ublProperties) {
          ublProperties = cloneDeep(record?.ublProperties);
        } else if (nodeId) {
          let data: AlfrescoContent;
          if (AlfrescoDocumentService.isWebDocument(record)) {
            data = await waybillService.loadDraft(nodeId);
          } else {
            data = await waybillService.load(nodeId);
          }

          ublProperties = data?.ublContent.ublProperties;
        } else {
          throw new Error('record id cannot be null!');
        }

        const previewData: P2pPreviewData = {
          id: nodeId,
          properties,
          ublProperties,
          linesView: [],
        };

        setData(previewData);
        setLoading(false);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(error);
        setLoading(false);
      }
    };
    if (!loading && !data && !error) {
      setLoading(true);
      getData();
    }
  }, [data, dataProvider, error, loading, record, waybillService]);

  if (loading || !data) {
    return <Loading />;
  } else if (error) {
    return (
      <Typography variant='h5'>
        {translate('dxMessages.error_messages.unable_to_display')}
      </Typography>
    );
  } else {
    return (
      <WayBillPreviewController
        {...{
          classes,
          toolbar,
          record: data,
          basePath,
        }}
      />
    );
  }
};

export default WayBillPreview;
