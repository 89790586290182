import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  useLocale,
  useNotify,
  useRedirect,
} from 'react-admin';
import { CountryService } from '../../../../services';

export const PeppolCreate = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const locale = useLocale();

  const onSuccess = () => {
    notify('resources.companies.edit.notificationsPeppol.created');
    redirect('list', props.basePath);
  };

  const countryService = new CountryService(locale);
  const countries = countryService.getCountries();
  const countryItems = countries.map((country) => {
    return {
      id: country.code,
      name: country.name,
    };
  });

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput
          label={'dxMessages.peppol.issuer_identification.title'}
          source='issuerIdentification'
        />
        <TextInput
          label={'dxMessages.peppol.company_name.title'}
          source='companyName'
        />
        <SelectInput
          label={'dxMessages.peppol.country.title'}
          source='country'
          choices={countryItems}
        />
        <TextInput
          label={'dxMessages.peppol.company_identification.title'}
          source='companyIdentification'
        />
        <TextInput
          label={'dxMessages.peppol.schemaId.title'}
          source='schemaId'
          type='number'
        />
        <TextInput label={'dxMessages.peppol.value.title'} source='valueId' />
      </SimpleForm>
    </Create>
  );
};

export default PeppolCreate;
