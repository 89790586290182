import UblCommonSources, {
  IAddressSources,
  IAmountSources,
  IContactSources,
  IDocumentReferenceSources,
  ILocationSources,
  IPartySources,
} from '../common/UblCommon1-0Sources';

/**
 * Source model used to build waybill preview view
 */
export interface IShipmentStageSources {
  id: string;
  loadingNumber: string;
  loadingDate: string;
  loadingLocation: {
    source: string;
    fields: ILocationSources;
  };
  unloadingDate: string;
  unloadingLocation: {
    source: string;
    fields: ILocationSources;
  };
}

export interface IConsignmentSources {
  id: string;
  serviceDescription: string;
  carrierAssignedID: string;
  remarks: string;
  packagesNumber: string;
  childConsignmentID: string;
  transportCostBeforeFees: {
    title: string;
    source: string;
    fields: IAmountSources;
  };
  transportFees: {
    title: string;
    source: string;
    fields: IAmountSources;
  };
  transportCostIncludingFees: {
    title: string;
    source: string;
    fields: IAmountSources;
  };
}

export interface IShipmentInformationSources {
  id: string;
  licensePlate: string;
  marksAndNumbers: string;
  packingMethod: string;
  goodsNature: string;
  shipmentStage: {
    source: string;
    fields: IShipmentStageSources;
  };
  consignment: {
    source: string;
    fields: IConsignmentSources;
  };
}

export interface IWayBillPartySources {
  consignorParty: {
    title: string;
    source: string;
    fields: IPartySources;
  };
  freightForwarderParty: {
    title: string;
    source: string;
    fields: IPartySources;
  };
  consigneeParty: {
    title: string;
    source: string;
    fields: IPartySources;
  };
  carrierParty: {
    title: string;
    source: string;
    fields: IPartySources;
  };
}

export interface IWayBillRootSources {
  orderReferenceId: string;
  documentReferences: string;
  note: string;
  issueDate: string;
  issueTime: string;
  number: string;
  shipmentInformation: {
    source: string;
    fields: IShipmentInformationSources;
  };
}
export interface IWayBillSources {
  AmountSources: IAmountSources;
  AddressSources: IAddressSources;
  ContactSources: IContactSources;
  DocumentReferenceSources: IDocumentReferenceSources;
  LocationSources: ILocationSources;
  PartySources: IPartySources;
  WayBillPartySources: IWayBillPartySources;
  WayBillRootSources: IWayBillRootSources;
  ShipmentStageSources: IShipmentStageSources;
  ConsignmentSources: IConsignmentSources;
  ShipmentInformationSources: IShipmentInformationSources;
}
export default class WayBillSources extends UblCommonSources {
  constructor(public web: boolean) {
    super(web);
  }

  public ShipmentStageSources = (): IShipmentStageSources => {
    return {
      id: this.web ? 'ID[0].IdentifierContent' : 'id.value',
      loadingNumber: this.web
        ? 'LoadingTransportEvent[0].IdentificationID[0].IdentifierContent'
        : 'loadingTransportEvent.identificationID.value',
      loadingDate: this.web
        ? 'TransitPeriod[0].StartDate[0].DateContent'
        : 'transitPeriod.startDate.value',
      loadingLocation: {
        source: this.web ? 'LoadingPortLocation[0]' : 'loadingPortLocation',
        fields: this.LocationSources(),
      },
      unloadingDate: this.web
        ? 'TransitPeriod[0].EndDate[0].DateContent'
        : 'transitPeriod.endDate.value',
      unloadingLocation: {
        source: this.web ? 'UnloadingPortLocation[0]' : 'unloadingPortLocation',
        fields: this.LocationSources(),
      },
    };
  };

  public ConsignmentSources = () => {
    return {
      id: this.web ? 'ID[0].IdentifierContent' : 'id.value',
      serviceDescription: this.web
        ? 'CarrierAssignedID[0].IdentificationSchemeNameText'
        : 'carrierAssignedID.schemeName',
      carrierAssignedID: this.web
        ? 'CarrierAssignedID[0].IdentifierContent'
        : 'carrierAssignedID.value',
      remarks: this.web ? 'Remarks[0].TextContent' : 'remarks[0].value',
      packagesNumber: this.web
        ? 'TotalPackagesQuantity[0].QuantityContent'
        : 'totalPackagesQuantity.value',
      childConsignmentID: this.web
        ? 'ChildConsignment[0].ID[0].IdentifierContent'
        : 'childConsignment.id.value',
      transportCostBeforeFees: {
        title: 'dxMessages.waybill.loadingTable.costs.transportCostBeforeFees',
        source: this.web
          ? 'ChildConsignment[0].TotalInvoiceAmount[0]'
          : 'childConsignment[0].totalInvoiceAmount',
        fields: this.AmountSources(),
      },
      transportFees: {
        title: 'dxMessages.waybill.loadingTable.costs.transportFees',
        source: this.web
          ? 'ChildConsignment[0].DeclaredCustomsValueAmount[0]'
          : 'childConsignment[0].declaredCustomsValueAmount',
        fields: this.AmountSources(),
      },
      transportCostIncludingFees: {
        title:
          'dxMessages.waybill.loadingTable.costs.transportCostIncludingFees',
        source: this.web ? 'TotalInvoiceAmount[0]' : 'totalInvoiceAmount',
        fields: this.AmountSources(),
      },
    };
  };

  public ShipmentInformationSources = (): IShipmentInformationSources => {
    return {
      id: this.web ? 'ID[0].IdentifierContent' : 'id.value',
      licensePlate: this.web
        ? 'TransportHandlingUnit[0].TransportMeans[0].RoadTransport[0].LicensePlateID[0].IdentifierContent'
        : 'transportHandlingUnit[0].transportMeans[0].roadTransport.licensePlateID.value',
      marksAndNumbers: '',
      packingMethod: '',
      goodsNature: '',
      shipmentStage: {
        source: this.web ? 'ShipmentStage[0]' : 'shipmentStage[0]',
        fields: this.ShipmentStageSources(),
      },
      consignment: {
        source: this.web ? 'Consignment[0]' : 'consignment[0]',
        fields: this.ConsignmentSources(),
      },
    };
  };

  public WayBillPartySources = (): IWayBillPartySources => {
    return {
      consignorParty: {
        title: 'dxMessages.waybill.consignorParty',
        source: this.web ? 'ConsignorParty[0]' : 'consignorParty',
        fields: this.PartySources(),
      },
      freightForwarderParty: {
        title: 'dxMessages.waybill.freightForwarderParty',
        source: this.web ? 'FreightForwarderParty[0]' : 'freightForwarderParty',
        fields: this.PartySources(),
      },
      consigneeParty: {
        title: 'dxMessages.waybill.consigneeParty',
        source: this.web
          ? 'Shipment[0].Consignment[0].ConsigneeParty[0]'
          : 'shipment.consignment[0].consigneeParty',
        fields: this.PartySources(),
      },
      carrierParty: {
        title: 'dxMessages.waybill.carrierParty',
        source: this.web ? 'CarrierParty[0]' : 'carrierParty',
        fields: this.PartySources(),
      },
    };
  };

  public WayBillRootSources = (): IWayBillRootSources => {
    return {
      orderReferenceId: this.web
        ? 'ShippingOrderID[0].IdentifierContent'
        : 'shippingOrderID.value',
      documentReferences: this.web ? 'DocumentReference' : 'documentReference',
      note: this.web ? 'Note[0].TextContent' : 'note[0].value',
      issueDate: this.web ? 'IssueDate[0].DateContent' : 'issueDate.value',
      issueTime: this.web ? 'IssueTime[0].TimeContent' : 'issueTime.value',
      number: this.web ? 'ID[0].IdentifierContent' : 'id.value',
      shipmentInformation: {
        source: this.web ? 'Shipment[0]' : 'shipment',
        fields: this.ShipmentInformationSources(),
      },
    };
  };

  public WayBillSources = () => {
    return {
      root: {
        source: this.web ? 'ublProperties.Waybill[0]' : 'ublProperties',
        fields: {
          ...this.WayBillRootSources(),
          ...this.WayBillPartySources(),
        },
      },
    };
  };

  public WayBillObjectFieldSources = () => {
    return {
      root: {
        fields: {
          documentReference: {
            fields: this.DocumentReferenceSources(),
          },
        },
      },
    };
  };

  /**
   * Uppercase the first letter of the given string.
   * @param string the string to modify
   */
  public static uppercaseFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Generate all properties from the given object.
   * @param element the element used to generate properties
   */
  public static generateUblProperties(element: any) {
    if (typeof element === 'string') {
      return { '': element };
    } else {
      let fields = {};
      let result: any;

      if (element.fields) {
        for (const field of Object.entries(element.fields)) {
          let elementFields = this.generateUblProperties(field[1]);

          let enhancedSubFields = {};
          Object.keys(elementFields).forEach((key) => {
            enhancedSubFields[field[0] + this.uppercaseFirstLetter(key)] =
              element.source
                ? element.source + '.' + elementFields[key]
                : elementFields[key];
          });

          fields = { ...fields, ...enhancedSubFields };
        }
        result = fields;
      } else {
        result = { '': element.source };
      }
      return result;
    }
  }

  /**
   * Contains all properties generated from the previous model for web documents.
   */
  public static WebWaybillUbl: any = () => {
    const webWaybill = new WayBillSources(true);
    return {
      ...WayBillSources.generateUblProperties(webWaybill.WayBillSources().root),
      ...WayBillSources.generateUblProperties(
        webWaybill.WayBillObjectFieldSources().root
      ),
    };
  };

  /**
   * Contains all properties generated from the previous model for non web documents.
   */
  public static Ubl: any = () => {
    const waybill = new WayBillSources(false);
    return {
      ...WayBillSources.generateUblProperties(waybill.WayBillSources().root),
      ...WayBillSources.generateUblProperties(
        waybill.WayBillObjectFieldSources().root
      ),
    };
  };
}
