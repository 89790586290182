import { DomainMessages } from './DomainMessages';
/**
 * French I18N messages.
 * In order to not alterate w/ react-admin messages, the application messages must not be defined
 * under ${locale}:ra namespace (where locale = en, ro fr , etc ..).
 * Moreover, ${locale}:resources is the namespace reserved to interact w/ react-admin resources label and title.
 * See : https://marmelab.com/react-admin/Translation.html#translating-resource-and-field-names
 */
export const frDomainMessages: DomainMessages = {
  dxMessages: {
    // French Application messages
    dashboard: {
      categories: {
        general: 'Général',
        'nway-matching': 'Réconciliation',
        'cash-collection': 'Encours de facture',
      },
      ConfigurationDescription:
        "Personnalisez votre tableau de bord en faisant glisser et en déposant des widgets d'en bas sur la page principale, en les déplaçant et en les supprimant si nécessaire.",
      DesignYourHomePage: "Personnaliser votre page d'accueil",
      LearnMore: 'En savoir plus',
      ConfigurationSingletonDescription:
        "Cette icône dans le coin supérieur droit de certains widgets signifie que vous pouvez l'ajouter " +
        "plus d'une fois danse le tableau de bord, en définissant différentes configurations afin que " +
        'chacune affiche des données différentes.',
      noAccessRightForDocXchange:
        "Vous n'avez pas les droits pour accéder à docXchange. " +
        'Votre administrateur a peut-être oublié de vous les assigner.' +
        "Veuillez le contacter si vous pensez que c'est le cas.",
      NoData: 'Aucune donnée',
      NoWidgetToAdd:
        "Aucune proposition, toutes les widgets sont déjà à l'écran.",
      OnErrorContactSupport:
        'Oups, une erreur est survenue. Veuillez réessayer plus tard ou contacter le support de DocProcess (support@doc-process.com) [%{error}]',
      Welcome: 'Bienvenue %{firstname}',
      widgets: {
        DocumentReceptions: {
          title: 'Documents, par jour et par type',
          during: 'durant',
          NoReception: 'Aucun document trouvé pour les critères sélectionnés.',
        },
        InvoiceAcceptanceSummary: {
          AutoApproved: 'Appprouvée auto.',
          ManuallyApproved: 'Appprouvée manuellement',
          Rejected: 'Rejetée',
          Cancelled: 'Annulée',
          i2pr: 'Réconciliations facture/avis de réception',
          i2o: 'Réconciliations facture/commandes',
          SetEarlierDate: 'Choississez une date antérieure.',
          Since_le: 'Depuis',
        },
        InvoiceCount: {
          Count: 'Factures (%{on})',
          On: 'Le',
          rejected: '%{count} rej.',
        },
        DocumentCountPerDayAndStatus: {
          INVOICE: {
            title: 'Factures par jour et statut',
          },
          ORDER: {
            title: 'Commandes par jour et statut',
          },
          RECADV: {
            title: 'Avis de réception par jour et statut',
          },
          DESADV: {
            title: "Avis d'expédition par jour et statut",
          },
          during: 'Période',
          NoReception: 'Aucun document trouvé pour les critères sélectionnés.',
          status: {
            Accepted_by_Customer: 'Accepté',
            Content_valid: 'Contenu validé',
            Dispute: 'Litige',
            Format_valid: 'Format validé',
            No_credit: 'Plus de crédit',
            No_status: 'Autre',
            Outage: 'Panne',
            Pending_Enrichments: 'Enrichissements en attente',
            Paid_by_recipient: 'Payé',
            Pending_to_Customer: 'En attente côté destinataire',
            Received_by_Customer: 'Reçu',
            Received_by_DX: 'Reçu par DocProcess',
            Rejected_by_Customer: 'Refusé',
            Received_by_Recipient: 'Reçu',
            Rejected_by_DX: 'Rejeté par DocProcess',
            Sent: 'Envoyé',
            Transmitted_by_DX: 'Transmis par DocProcess',
            Matching_in_Progress: 'Réconciliation en cours',
          },
        },
        InvoiceWaitingForDocuments: {
          Count: 'Factures en attente de documents',
        },
        InvoiceWaitingForApproval: {
          Count: "Factures en attente d'approbation",
        },
        InvoiceWaitingForYourApproval: {
          Count: 'Factures en attente de votre approbation',
          overdue: 'en retard',
        },
        OrderCount: {
          Count: 'Commandes (%{on})',
          On: 'Le',
        },
        PendingTaskCount: {
          Count: 'Tâches en attente',
          Since_le: 'Depuis',
        },
        RecadvCount: {
          Count: 'Avis de réception (%{on})',
          On: 'Le',
        },
        DesadvCount: {
          Count: "Avis d'expédition (%{on})",
          On: 'Le',
        },
        TotalVariationOnAcceptedInvoiceLines: {
          title: 'Total des différences des montants acceptés',
          helper:
            'Un montant positif signifie que vous avez payé plus que les prix exacts.',
          Since: 'Depuis',
          Since_helper: 'La date de départ du calcul des différences',
        },
        WelcomeBanner: { LastLogin: 'Votre dernière connexion' },
        CashCollectionProjectionOverTime: {
          title: "Projection des encours d'encaissement",
          due: 'Due M',
          overdue: 'Impayé',
          amount: 'Montant',
        },
        TotalAmountOwedTopTen: {
          title: 'Montant total dû (10 premiers clients)',
          overdue: 'Impayé',
          amount: 'Montant',
          intervals: {
            current: 'due',
            overdue_1_30: 'en retard depuis 1 - 30 jours',
            overdue_31_60: 'en retard depuis 31 - 60 jours',
            overdue_61_90: 'en retard depuis 61 - 90 jours',
            overdue_91_180: 'en retard depuis 91 - 180 jours',
            overdue_beyond_180: 'en retard > 180 jours',
          },
        },
        InformBanner: {
          title:
            'La liste des documents en cours de traitement peut être différente de celle affichée dans le graphique.',
        },
        DocumentCompany: {
          INVOICE: 'Factures pour',
          ORDER: 'Commandes pour',
          RECADV: 'Avis de réception pour',
          DESADV: "Avis d'expédition pour",
        },
        Tooltip: {
          message: 'cliquer pour voir plus de détails par compagnie',
        },
        Columns: {
          company: 'Entreprise',
          status: 'Etat',
          count: 'Nombre',
        },
        WidgetTitle: {
          name: 'Titre du widget',
        },
        ListButton: {
          title: 'Tableau',
        },
        SummaryTitle: {
          title: 'Synthèse pour ',
        },
        Date: {
          month: 'Mois',
          year: 'Année',
        },
        AppsWidget: {
          title: 'Mes applications',
        },
        Display: {
          title: 'Afficher',
          days: 'Jours',
          months: 'Mois',
          years: 'Ans',
        },
        DateRange: {
          title: 'Plage de dates',
          last7Days: '7 derniers jours',
          last14Days: '14 derniers jours',
          thisMonth: 'Mois courant',
          lastMonth: 'Mois dernier',
          selectMonth: 'Sélectionner mois',
          thisQuarter: 'Trimestre courant',
          lastQuarter: 'Trimestre dernier',
          thisYear: 'Année courante',
          lastYear: 'Année dernière',
          selectYear: 'Sélectionner année',
          last3Years: '3 dernières années',
        },
        TableButton: {
          title: 'Afficher sous forme de tableau',
        },
        SavedQueries: {
          title: 'Mes requêtes enregistrées',
          business: {
            title: 'Entreprise',
          },
        },
        DisplayDocuments: {
          title: 'Documents récents',
          INVOICE: {
            title: 'Dernières factures',
          },
          ORDER: {
            title: 'Dernières commandes',
          },
          DESADV: {
            title: "Dernières avis d'expédition",
          },
          RECADV: {
            title: 'Dernières avis de réception',
          },
          documentType: {
            INVOIC: 'Facture',
            ORDERS: 'Commande',
            ORDER: 'Commande',
            RECADV: 'Avis de réception',
            DESADV: "Avis d'expédition",
          },
          recipient: 'Destinataire',
          issuer: 'Emetteur',
          status: 'Etat',
          issueDate: "Date d'émission",
          document: 'Type de document',
          button: 'ouvrir dans la recherche',
          clear: 'Effacer',
          limitMessage:
            'Note: résultats limités aux 100 derniers documents par type',
        },
      },
    },
    landingPage: {
      empty: {
        click: 'Cliquez',
        drop: 'et déposez vos widgets',
        openedConfigurationDrop: 'Déposez vos widgets',
      },
      message: {
        post: 'Posté',
      },
    },
    pspMessage: 'Êtes-vous sûr de vouloir synchroniser?',
    catalogList: {
      menu: 'Catalogues',
    },
    catalogEdit: {
      menu: 'Modification de catalogues',
    },
    v2administration: {
      menu: 'V2 Administration',
    },
    peppol: {
      menu: 'PEPPOL',
      accessPoint: {
        title: 'Peppol Participant ID',
      },
      schemaId: {
        title: 'Peppol: ID de schéma',
      },
      value: {
        title: 'Peppol: Valeur',
      },
      company_identification: {
        title: 'Code TVA du destinataire',
      },
      company_name: {
        title: "Nom de l'entreprise du destinataire",
      },
      issuer_identification: {
        title: "Code TVA de l'émetteur",
      },
      country: {
        title: 'Pays du destinataire',
      },
      error: 'Impossible de supprimer.',
    },
    monitoring: {
      menuMonitoring: 'Surveillance',
      efactura: {
        incidentId: "Id de l'incident",
        filename: 'Nom de fichier',
        reason: 'Raison',
        status: 'Statut',
        userId: 'Id utilisateur',
        date: 'Date',
        eventDateStart: "Date de début de l'événement",
        eventDateEnd: "Fin de la date de l'événement",
        ignore: 'Ignorer',
        supplierNotified: 'Notifier le fournisseur',
        createTask: 'Créer une tâche Jira',
      },
    },
    companyManagement: {
      menu: 'Comptes',
      menuV2: 'utilisateurs & société (V2)',
      globalView: 'V2 Vue Globale',
      documentSearch: 'V2 Recherche de Documents',
      redirect: 'Aller à',
      contractedProducts: 'Produits sous contrat',
      suppliers: 'Fournisseurs',
      customers: 'Clients',
      contacts: 'Contacts',
      account: {
        title: 'Compte',
        name: "Nom de l'entreprise",
        vatCode: 'TVA code',
        registryNumber: 'Numéro de registre du commerce',
        corporateStock: "Montant des actions de l'entreprise",
        uidIdentification: "Identification de l'UID",
        idstatus: 'État',
        idformattype: "Format d'entrée de la facture",
        idformattypedesadv: "Format d'entrée désadv",
        supplierId: 'ID du fournisseur',
        sync: 'SYNC',
        success: 'La société a été synchronisée avec succès',
        error: 'Erreur de synchronisation',
        isCompanyAdmin: 'Administrateur de la société',
        v3Synchronize: 'Synchronisation V3 planifiée',
        country: 'Pays',
        detailsTooltip:
          'Cette option est disponible uniquement pour les sociétés ANAF.',
        detailsTooltipRo: 'Cette option est disponible uniquement en Roumanie.',
        getDetails: 'obtenir details',
        downloadCsvTemplate: 'Télécharger le modèle CSV',
        processing: 'Traitement ...',
      },
      users: {
        userId: "Identifiant d'utilisateur",
        role: "rôle d'utilisateur",
        title: 'Utilisateurs',
        help: "Une liste avec tous les rôles qui peuvent être attribués à l'utilisateur. Un rôle est disponible si l'entreprise a accès à la fonctionnalité et que le rôle d'utilisateur est attribué.",
        isCompanyAdmin: 'Administrateur client',
      },
      contact: {
        title: 'Contact',
        lastName: 'Nom',
        firstName: 'Prénom',
        phone: 'Téléphone',
        mail: 'Email',
      },
      dropdowndef: {
        title: 'Définition déroulante',
        description: 'Description',
        idtype: "Type d'identifiant",
        value: 'Valeur',
        delete_dropdown_error:
          "Impossible de supprimer, l'élément sélectionné est en cours d'utilisation.",
      },
      formattype: {
        comment: 'Commentaire',
        description: 'Description',
        idDocumentType: 'Identifiant du type de document',
        delete_formattype_error:
          "Impossible de supprimer, le type de format sélectionné est en cours d'utilisation.",
      },
      dxrole: {
        name: 'Nom',
        adminOnly: 'Administrateur uniquement',
        delete_dxrole_error:
          "Impossible de supprimer, le rôle sélectionné est en cours d'utilisation.",
      },
      dxProduct: {
        title: 'DX Product',
        description: 'Description',
        comments: 'Commentaires',
        flgActive: 'flgActive',
        idProductGroup: 'IdProductGroup',
        delete_product_error:
          "Impossible de supprimer, l'élément sélectionné est en cours d'utilisation.",
      },
      accountRelation: {
        title: 'relation de compte',
        customer: 'Clients',
        customerId: 'Clients ID',
        supplier: 'Fournisseur',
        supplierId: 'Fournisseur ID',
        suppliercode4customer: 'Fournisseur code for clients',
        delete_account_error:
          "Impossible de supprimer, le compte sélectionné est en cours d'utilisation.",
      },
      addresses: {
        title: 'Adresses',
        addressType: 'Type d’adresse',
        street: 'Rue',
        additionalStreet: 'Rue supplémentaire',
        buildingNumber: 'Numéro du bâtiment',
        postBox: 'Boîte aux lettres',
        city: 'Ville',
        postalCode: 'Code postal',
        financialAccount: 'IBAN',
        bank: 'Banque',
        gln: 'GLN',
        country: 'Code pays',
        flgBilling: 'Facturation adresse',
        flgShipping: 'Expédition adresse',
        flgLogistic: 'Logistique adresse',
      },
      addressBuffer: {
        delete: 'SUPPRIMER',
        message: "L'adresse a été synchronisée avec succès",
      },
      productUsage: {
        product: 'Produit',
        title: 'Produits Sous Contrat',
        totalusage: 'Utilisation totale',
        totalcredit: 'Crédit total',
        thresold: 'Seuil',
        advailible: 'Disponible',
      },
      supportedProducts: {
        product: 'Produit',
        title: 'Produits Pris En Charge',
        delete_supportedproduct_error:
          "Impossible de supprimer, le produit sélectionné est en cours d'utilisation.",
      },
      audit: {
        tsChange: 'Dernière modification à',
        userIdChange: 'Dernière modification par',
      },
      commonDocumentColumns: {
        currentState: 'État actuel',
        dxuid: 'DXUID',
        sourceCif: 'Source CIF',
        destinationCif: 'Destination CIF',
        originalFileName: "Nom de fichier d'origine",
        ingestDate: "Date d'ingestion",
        ingestDateBefore: "Date d'ingestion avant",
        ingestDateAfter: "Date d'ingestion après",
        invoiceId: 'ID de facture',
        orderId: 'Commande ID',
        receiptAdviceId: 'ID avis de réception',
        despatchAdviceId: "ID avis d'expédition",
        issuingDate: "Date d'émission",
        issuingDateStart: "Date d'émission Début",
        issuingDateEnd: "Fin date d'émission",
        customerName: 'Client',
        supplierName: 'Fournisseur',
      },
    },
    invoices: {
      title: 'Factures',
      document_label: 'Facture',
      edit_title: 'Edition facture ',
      create_title: 'Création de facture',
      show_title: 'Facture : ',
      status_history_title: 'Historique',
      payment_method: 'Méthode de paiement',
      payment_means_code: 'Moyen de paiement',
      due_date: "Date d'échéance",
      billing_period: 'Période de facturation',
      iban: 'IBAN',
      bank: 'Banque',
      initialInvoice: 'Facture initiale',
      lines: 'Lignes',
      lineId: 'ID de ligne de commande',
      invoiceNumber: 'Numéro de facture',
      delivery: 'Livraison',
      note: 'Note',
      accounting_code_code: 'Code coûts comptables',
      deliveryDate_IssueDate_error:
        "La date de livraison doit être inférieure ou égale à la date d'émission de la facture",
      issueDate_DueDate_error:
        "La date d'échéance doit être supérieure ou égale à la date d'émission de la facture",
      referenceIssueDate_issueDate_error:
        "La date d'échéance doit être supérieure ou égale à la date d'échéance de la facture de référence",
      issueDate_error:
        "La date d'émission de la facture ne peut etre supérieure à la date courante",
      dateWithoutId_error:
        'Cette date ne peut pas être définie sans son ID associé',
      fmdlinenote_error:
        'Veuillez ajouter la valeur de la note comme "Invoicing period: yyyyMMdd-yyyyMMdd" \n. Exemple. Invoicing period: 20200701-20200731',
      sgrWithoutRef_error: 'Veuillez renseigner le "Code ref produit"',
      valueWithoutName_error:
        'Cette valeur ne peut être définie sans son nom associé',
      orderIdSalesOrderID_error:
        'Veuillez saisir la valeur "NA" pour ID commande (BT-13) car elle ne peut rester vide',
      tax_summary_vat_percent: 'TVA (%)',
      tax_summary_taxable_amount: 'Base imposable (%{currencyID})',
      tax_summary_tax_amount: 'Montant TVA',
      tax_category_code: 'Code de catégorie de taxe',
      fetchBuyerDetailsButtonLabel: 'Complétez les informations',
      fetchBuyerDetailsTooltip:
        "Obtenir les informations de l'entreprise grâce au code fiscal",
      taxCategory: {
        standard: '(S) Standard',
        reverse: '(AE) TVA inverse',
        collection: 'TVA sur la collecte',
        exempt: ' (E) Exonéré de TVA',
        freeExport: "(G) Article d'exportation gratuit, taxe non facturée",
        exemptionWithDeductibility: 'Exonération avec déductibilité',
        exemptWithoutDeduction: 'Exonéré sans droit à déduction',
        zeroVat: '(Z) Zéro TVA',
        triangleTransaction: 'Transaction triangulaire',
        proRata: 'Taxe normale au prorata',
        specialRegime: 'Régime spécial (art. 1521, 1522)',
        specialExemption: 'Reg. dérogation spéciale art 311 CF',
        shorteningSpec: 'Reg. raccourcissement spec 312 CF',
        purchaseWithInstallation:
          'Achats UE de biens avec installation/montage',
        outsideOfScope: '(O) Prestations hors périmètre fiscal',
        EEAIntraCommunity:
          "(K) Exonération de TVA pour les livraisons intracommunautaires de biens et de services dans l'EEE",
        L: '(L) Impôt indirect général des îles Canaries',
        M: "(M) Taxe pour la production, les services et l'importation à Ceuta et Melilla",
        B: '(B) Transféré (TVA), En Italie',
        '00': '(00) 0 %',
        '01': '(01) 6 %',
        '02': '(02) 12 %',
        '03': '(03) 21 %',
        '45': '(45) Reverse charge - Cocontractant',
        NA: '(NA) Exempt Divers hors TVA',
        FD: '(FD) Financial discount',
        SC: '(SC) Small company',
        '00_44': '(00/44) 0% Article 44',
        '03_SE': '(03/SE) Echange standart',
        MA: '(MA) Marge',
        '46_GO': '(46/GO) LIC Marchandises',
        '47_TO': '(47/TO) LIC Travail à façon',
        '47_AS': '(47/AS) LIC Montage',
        '47_DI': '(47/DI) LIC Distance',
        '47_SE': '(47/SE) LIC Services',
        '44': '(44) LIC Services B2B',
        '46_TR': '(46/TR) LIC Triangle a-B-c',
        '47_EX': '(47/EX) Export non C.E.',
        '47_EI': '(47/EI) Export indirect',
        '47_EE': '(47/EE) Export via C.E.',
        NS: '(NS) Not subject to VAT invoice/credit note',
        OSS_G: '(OSS-G) OSS Goods',
        OSS_S: '(OSS-S) OSS Services',
        OSS_I: '(OSS-I) OSS Import',
      },
      tax_reason_code: 'Code de motif fiscal',
      taxReasonCode: {
        VATEX_EU_AE:
          '(AE) => VATEX-EU-AE : Charge inversée Prend en charge la règle EN 16931-1 BR-AE-10',
        VATEX_EU_D:
          "(E) => VATEX-EU-D : Acquisition intracommunautaire à partir de moyens de transport d'occasion Moyens de transport d'occasion",
        VATEX_EU_F:
          "(E) => VATEX-EU-F : Acquisition intracommunautaire de biens d'occasion Biens d'occasion",
        VATEX_EU_G:
          '(G) => VATEX-EU-G : Exportation hors UE prend en charge la règle EN 16931-1 BR-G-10',
        VATEX_EU_I:
          "(E) => VATEX-EU-I : Acquisition intracommunautaire d'œuvres d'art Œuvres d'art",
        VATEX_EU_IC:
          '(K) => VATEX-EU-IC : La fourniture intracommunautaire prend en charge la règle EN 16931-1 BR-IC-10',
        VATEX_EU_O:
          '(O) => VATEX-EU-O : Non soumis à la TVA Prend en charge la règle EN 16931-1 BR-O-10',
        VATEX_EU_J:
          "(E) => VATEX-EU-J : Acquisition intracommunautaire d'objets de collection et d'antiquités Objets de collection et antiquités",
        VATEX_EU_79_C:
          "(E) => VATEX-EU-79-C : Exonération basée sur l'article 79, point c de la directive 2006/112/CE du Conseil",
        VATEX_EU_132:
          "(E) => VATEX-EU-132 : Exonération fondée sur l'article 132 de la directive 2006/112/CE du Conseil",
        VATEX_EU_143:
          "(E) => VATEX-EU-143 : Exonération fondée sur l'article 143 de la directive 2006/112/CE du Conseil",
        VATEX_EU_148:
          "(E) => VATEX-EU-148 : Exonération fondée sur l'article 148 de la directive 2006/112/CE du Conseil",
        VATEX_EU_151:
          "(E) => VATEX-EU-151 : Exonération fondée sur l'article 151 de la directive 2006/112/CE du Conseil",
        VATEX_EU_309:
          "(E) => VATEX-EU-309 : Exonération basée sur l'article 309 de la directive 2006/112/CE du Conseil",
        VATEX_FR_FRANCHISE:
          '(E) => VATEX-FR-FRANCHISE: France domestic VAT franchise in base',
        VATEX_FR_CNWVAT:
          'VATEX-FR-CNWVAT: France domestic Credit Notes without VAT',
        BETE_45: 'BETE-45: Reverse charge - Contractor',
        BETE_EX: ' BETE-EX: Exempt',
        BETE_FD: 'BETE-FD: Financial discount',
        BETE_SC: 'BETE-SC: Small company',
        BETE_00_44: 'BETE-00/44: 0% Clause 44',
        BETE_03_SE: 'BETE-03/SE: Standard exchange',
        BETE_MA: 'BETE-MA: Margin',
        BETE_46_GO: 'BETE-46/GO: Intra-community supply - Goods',
        BETE_47_TO: 'BETE-47/TO: Intra-community supply - Manufacturing cost',
        BETE_47_AS: 'BETE-47/AS: Intra-community supply - Assembly',
        BETE_47_DI: 'BETE-47/DI: Intra-community supply - Distance',
        BETE_47_SE: 'BETE-47/SE: Intra-community supply - Services',
        BETE_44: 'BETE-44: Intra-community supply - Services B2B',
        BETE_46_TR: 'BETE-46/TR: Intra-community supply - Triangle a-B-c',
        BETE_47_EX: 'BETE-47/EX: Export non E.U.',
        BETE_47_EI: 'BETE-47/EI: Indirect export',
        BETE_47_EE: 'BETE-47/EE: Export via E.U.',
        BETE_NS: 'BETE-NS: Not subject to VAT',
      },
      taxReasonCodeTooltip: {
        VATEX_EU_AE:
          'Charge inversée Prend en charge la règle EN 16931-1 BR-AE-10 (VATEX-EU-AE) - À utiliser uniquement avec le code de catégorie TVA AE',
        VATEX_EU_D:
          "Acquisition intracommunautaire à partir de moyens de transport d'occasion Moyens de transport d'occasion (VATEX-EU-D)" +
          ' - Indication que la TVA a été payée conformément aux dispositions transitoires applicables - À utiliser uniquement avec le code de catégorie de TVA E',
        VATEX_EU_F:
          "Acquisition intracommunautaire de biens d'occasion Biens d'occasion (VATEX-EU-F)' + ' - Indication de l'application du régime de marge de TVA pour les biens d'occasion. - À utiliser uniquement avec le code de catégorie de TVA E",
        VATEX_EU_G:
          'Exportation hors UE prend en charge la règle EN 16931-1 BR-G-10 (VATEX-EU-G) - À utiliser uniquement avec le code de catégorie de TVA G',
        VATEX_EU_I:
          "Acquisition intracommunautaire d'œuvres d'art Œuvres d'art (VATEX-EU-I)" +
          " - Indication de l'application du régime de marge de TVA pour les œuvres d'art. - À utiliser uniquement avec le code de catégorie de TVA E",
        VATEX_EU_IC:
          'La fourniture intracommunautaire prend en charge la règle EN 16931-1 BR-IC-10 (VATEX-EU-IC)' +
          ' - À utiliser uniquement avec le code de catégorie de TVA K',
        VATEX_EU_O:
          'Non soumis à la TVA Prend en charge la règle EN 16931-1 BR-O-10 (VATEX-EU-O) - À utiliser uniquement avec le code de catégorie TVA O',
        VATEX_EU_J:
          "Acquisition intracommunautaire d'objets de collection et d'antiquités Objets de collection et antiquités (VATEX-EU-J)" +
          ' - Indication que le régime de marge de TVA pour les objets de collection et les antiquités a été appliqué. - À utiliser uniquement avec le code de catégorie de TVA E',
        VATEX_EU_79_C:
          "Exonération basée sur l'article 79, point c de la directive 2006/112/CE du Conseil (VATEX-EU-79-C) - À utiliser uniquement avec le code de catégorie de TVA E",
        VATEX_EU_132:
          "Exonération fondée sur l'article 132 de la directive 2006/112/CE du Conseil (VATEX-EU-132) - À utiliser uniquement avec le code de catégorie de TVA E",
        VATEX_EU_143:
          "Exonération fondée sur l'article 143 de la directive 2006/112/CE du Conseil (VATEX-EU-143) - À utiliser uniquement avec le code de catégorie de TVA E",
        VATEX_EU_148:
          "Exonération fondée sur l'article 148 de la directive 2006/112/CE du Conseil (VATEX-EU-148) - À utiliser uniquement avec le code de catégorie de TVA E",
        VATEX_EU_151:
          "Exonération fondée sur l'article 151 de la directive 2006/112/CE du Conseil (VATEX-EU-151) - À utiliser uniquement avec le code de catégorie de TVA E",
        VATEX_EU_309:
          "Exonération basée sur l'article 309 de la directive 2006/112/CE du Conseil (VATEX-EU-309) - À utiliser uniquement avec le code de catégorie de TVA E",
        VATEX_FR_FRANCHISE:
          'France domestic VAT franchise in base (VATEX-FR-FRANCHISE). For domestic invoicing in France',
        VATEX_FR_CNWVAT:
          'France domestic Credit Notes without VAT, due to supplier forfeit of VAT for discount (VATEX-FR-CNWVAT). For domestic Credit Notes only in France',
        BETE_45: 'Reverse charge - Contractor (BETE-45)',
        BETE_EX: 'Exempt (BETE-EX)',
        BETE_FD: 'Financial discount (BETE-FD)',
        BETE_SC: 'Small company (BETE-SC)',
        BETE_00_44: '0% Clause 44 (BETE-00/44)',
        BETE_03_SE: 'Standard exchange (BETE-03/SE)',
        BETE_MA: 'Margin (BETE-MA)',
        BETE_46_GO: 'Intra-community supply - Goods (BETE-46/GO)',
        BETE_47_TO: 'Intra-community supply - Manufacturing cost (BETE-47/TO)',
        BETE_47_AS: 'Intra-community supply - Assembly (BETE-47/AS)',
        BETE_47_DI: 'Intra-community supply - Distance (BETE-47/DI)',
        BETE_47_SE: 'Intra-community supply - Services (BETE-47/SE)',
        BETE_44: 'Intra-community supply - Services B2B (BETE-44)',
        BETE_46_TR: 'Intra-community supply - Triangle a-B-c (BETE-46/TR)',
        BETE_47_EX: 'Export non E.U. (BETE-47/EX)',
        BETE_47_EI: 'Indirect export (BETE-47/EI)',
        BETE_47_EE: 'Export via E.U. (BETE-47/EE)',
        BETE_NS: 'Not subject to VAT (BETE-NS)',
      },
      charge_reason_code: 'Code de motif de facturation',
      chargeReasonCode: {
        AA: 'AA - Publicité',
        AAA: 'AAA - Télécommunication',
        AAC: 'AAC - Modification technique',
        AAD: 'AAD - Production de commandes',
        AAE: 'AAE - Dépenses',
        AAF: 'AAF - Hors site',
        AAH: 'AAH - Traitement supplémentaire',
        AAI: 'AAI - Attestation',
        AAS: 'AAS - Acceptation',
        AAT: 'AAT - Livraison urgente',
        AAV: 'AAV - Construction spéciale',
        AAY: 'AAY - Installations aéroportuaires',
        AAZ: 'AAZ - Concession',
        ABA: 'ABA - Stockage obligatoire',
        ABB: 'ABB - Élimination du carburant',
        ABC: "ABC - Dans l'avion",
        ABD: 'ABD - Heures supplémentaires',
        ABF: 'ABF - Outillage',
        ABK: 'ABK - Divers',
        ABL: 'ABL - Emballage supplémentaire',
        ABN: 'ABN - Calage',
        ABR: 'ABR - Conteneurisation',
        ABS: 'ABS - Emballage carton',
        ABT: 'ABT - Enveloppé en toile de jute',
        ABU: 'ABU - Emballage en polyéthylène',
        ACF: 'ACF - Traitements divers',
        ACG: "ACG - Traitement d'émaillage",
        ACH: 'ACH - Traitement thermique',
        ACI: 'ACI - Traitement de placage',
        ACJ: 'ACJ - Peinture',
        ACK: 'ACK - Polissage',
        ACL: 'ACL - Amorçage',
        ACM: 'ACM - Traitement de conservation',
        ACS: 'ACS - Raccord',
        ADC: 'ADC - Consolidation',
        ADE: 'ADE - Connaissement',
        ADJ: 'ADJ - Airbag',
        ADK: 'ADK - Transfert',
        ADL: 'ADL - Feuille de calcul',
        ADM: 'ADM - Liaison',
        ADN: "ADN - Réparation ou remplacement d'un colis consigné cassé",
        ADO: 'ADO - Logistique efficace',
        ADP: 'ADP - Marchandisage',
        ADQ: 'ADQ - Mix produits',
        ADR: 'ADR - Autres prestations',
        ADT: 'ADT - Prise en charge',
        ADW: 'ADW - Maladie chronique',
        ADY: "ADY - Introduction d'un nouveau produit",
        ADZ: 'ADZ - Livraison directe',
        AEA: 'AEA - Détournement',
        AEB: 'AEB - Déconnecter',
        AEC: 'AEC - Distribution',
        AED: 'AED - Manutention de marchandises dangereuses',
        AEF: 'AEF - Loyers et baux',
        AEH: 'AEH - Différentiel de localisation',
        AEI: 'AEI - Ravitaillement des avions',
        AEJ: 'AEJ - Carburant expédié en stockage',
        AEK: 'AEK - Paiement à la livraison',
        AEL: 'AEL - Service de traitement des petites commandes',
        AEM: 'AEM - Services de bureau ou administratifs',
        AEN: 'AEN - Garantie',
        AEO: 'AEO - Collecte et recyclage',
        AEP: "AEP - Collecte des droits d'auteur",
        AES: "AES - Service d'inspection vétérinaire",
        AET: 'AET - Service des retraités',
        AEU: 'AEU - Détenteur du laissez-passer gratuit pour les médicaments',
        AEV: "AEV - Service de protection de l'environnement",
        AEW: "AEW - Service de dépollution de l'environnement",
        AEX: 'AEX - Service national de traitement des chèques hors zone de compte',
        AEY: 'AEY - Service national de paiement hors zone comptable',
        AEZ: 'AEZ - Service national de paiement au sein de la zone de compte',
        AJ: 'AJ - Ajustements',
        AU: 'AU - Authentification',
        CA: 'CA - Catalogage',
        CAB: 'CAB - Cartage',
        CAD: 'CAD - Certification',
        CAE: 'CAE - Certificat de conformité',
        CAF: "CAF - Certificat d'origine",
        CAI: 'CAI - Découpe',
        CAJ: 'CAJ - Service consulaire',
        CAK: 'CAK - Encaissement client',
        CAL: 'CAL - Service de paiement de paie',
        CAM: 'CAM - Transport de fonds',
        CAN: 'CAN - Service de banque à domicile',
        CAO: "CAO - Service d'accords bilatéraux",
        CAP: "CAP - Service de courtage d'assurance",
        CAQ: 'CAQ - Génération de chèque',
        CAR: 'CAR - Emplacement de marchandisage préférentiel',
        CAS: 'CAS - Grue',
        CAT: 'CAT - Service couleur spécial',
        CAU: 'CAU - Tri',
        CAV: 'CAV - Collecte et recyclage des piles',
        CAW: 'CAW - Frais de reprise des produits',
        CAX: 'CAX - Contrôle qualité libéré',
        CAY: 'CAY - Contrôle qualité effectué',
        CAZ: 'CAZ - Embargo sur le contrôle qualité',
        CD: 'CD - Chargement de voiture',
        CG: 'CG - Nettoyage',
        CS: 'CS - Estampage des cigarettes',
        CT: 'CT - Compter et recompter',
        DAB: 'DAB - Mise en page/conception',
        DAC: "DAC - Allocation d'assortiment",
        DAD: 'DAD - Chauffeur assigné déchargement',
        DAF: 'DAF - Débiteur lié',
        DAG: 'DAG - Indemnité du concessionnaire',
        DAH: 'DAH - Allocation transférable au consommateur',
        DAI: "DAI - Croissance de l'entreprise",
        DAJ: "DAJ - Indemnité d'introduction",
        DAK: "DAK - Promotion d'achats multiples",
        DAL: 'DAL - Partenariat',
        DAM: 'DAM - Gestion des retours',
        DAN: 'DAN - Frais de commande minimum non exécutés',
        DAO: 'DAO - Seuil de franchise point de vente',
        DAP: 'DAP - Remise en gros',
        DAQ: 'DAQ - Commission de transfert de crédits documentaires',
        DL: 'DL - Livraison',
        EG: 'EG - Gravure',
        EP: 'EP - Expédition',
        ER: 'ER - Garantie de change',
        FAA: 'FAA - Fabrication',
        FAB: 'FAB - Péréquation du fret',
        FAC: 'FAC - Manutention extraordinaire de fret',
        FC: 'FC - Service de fret',
        FH: 'FH - Remplissage/manutention',
        FI: 'FI - Financement',
        GAA: 'GAA - Broyage',
        HAA: 'HAA - Tuyau',
        HD: 'HD - Manipulation',
        HH: 'HH - Levage et transport',
        IAA: 'IAA - Installation',
        IAB: 'IAB - Installation et garantie',
        ID: 'ID - Livraison intérieure',
        IF: 'IF - Contrôle',
        IR: 'IR - Installation et formation',
        IS: 'IS - Facturation',
        KO: 'KO - Cashering',
        L1: 'L1 - Nombre de porteurs',
        LA: 'LA - Étiquetage',
        LAA: 'LAA - Travail',
        LAB: 'LAB - Réparation et retour',
        LF: 'LF - Légalisation',
        MAE: 'MAE - Montage',
        MI: 'MI - Facture postale',
        ML: 'ML - Facture envoyée par courrier à chaque emplacement',
        NAA: 'NAA - Conteneurs non consignés',
        OA: 'OA - Connecteurs de câbles extérieurs',
        PA: 'PA - Facture avec expédition',
        PAA: "PAA - Phosphatation (traitement de l'acier)",
        PC: 'PC - Emballage',
        PL: 'PL - Palettisation',
        RAB: 'RAB - Reconditionnement',
        RAC: 'RAC - Réparation',
        RAD: 'RAD - Conteneur consigné',
        RAF: 'RAF - Réapprovisionnement',
        RE: 'RE - Re-livraison',
        RF: 'RF - Remise à neuf',
        RH: 'RH - Location de wagons',
        RV: 'RV - Chargement',
        SA: 'SA - Récupération',
        SAA: 'SAA - Expédition et manutention',
        SAD: 'SAD - Emballage spécial',
        SAE: 'SAE - Estampage',
        SAI: 'SAI - Déchargement du destinataire',
        SG: 'SG - Emballage rétractable',
        SH: 'SH - Traitement spécial',
        SM: 'SM - Finition spéciale',
        SU: 'SU - Configuration',
        TAB: 'TAB - Location de citernes',
        TAC: 'TAC - Test',
        TT: 'TT - Transport - facturation tiers',
        TV: 'TV - Transport par fournisseur',
        V1: 'V1 - Dépôt',
        V2: 'V2 - Dock de dépôt',
        WH: 'WH - Entreposage',
        XAA: 'XAA - Combiner tous les envois le jour même',
        YY: 'YY - Prise en charge fractionnée',
        ZZZ: 'ZZZ - Défini mutuellement',
      },
      discount_reason_code: 'Code de motif de remise',
      discountReasonCode: {
        '41': '41 - Prime pour travaux en avance',
        '42': '42 - Autre prime',
        '60': '60 - Remise consommateur du fabricant',
        '62': '62 - En raison du statut militaire',
        '63': '63 - Suite à un accident du travail',
        '64': '64 - Accord particulier',
        '65': '65 - Remise sur erreur de production',
        '66': '66 - Remise sur nouveau point de vente',
        '67': '67 - Remise sur les échantillons',
        '68': '68 - Remise fin de gamme',
        '70': '70 - Remise Incoterm',
        '71': '71 - Seuil de franchise au point de vente',
        '88': '88 - Supplément/déduction matériel',
        '95': '95 - Remise',
        '100': '100 - Rabais spécial',
        '102': '102 - Fixe à long terme',
        '103': '103 - Temporaire',
        '104': '104 - Standard',
        '105': "105 - Chiffre d'affaires annuel",
      },
      taxes: {
        label: 'Taxe unitaire',
        SGT_Amount: 'Taxe soda',
        SGT_Percent: 'Taxe soda %',
        GT_Amount: 'Taxe verte',
        GT_Percent: 'Taxe Verte %',
      },
      commercialInvoice: '380 - Facture commerciale',
      cancelInvoice: "381 - Facture d'annulation",
      correctiveInvoice: '384 - Facture corrective',
      selfbillingInvoice: "389 - Facture d'auto-facturation",
      accountingInvoice:
        '751 - Informations de facturation à des fins comptables',
      missingExchangeRate: 'Veuillez saisir le taux de change',
      missingTargetCurrencyCode: 'Veuillez saisir le code devise cible',
      applyExchangeRateButtonLabel: 'Appliquer le taux de change',
      applyExchangeRateTooltip:
        'Appliquer le taux de change pour toutes les lignes de la facture',
      efactura: {
        efactura_choice_label: "Options d'e-factura",
        efactura_send: 'Envoi e-Factura',
        efactura_no_send: "Pas d'envoi e-Factura",
        efactura_only: 'Seul e-Factura',
      },
      custom: {
        multimedia: {
          internal_transaction: 'Transaction interne',
          intra_community: 'Achat intra-communautaire',
          import_services: 'Importer des services',
          transport_invoice: 'Facture de transport à charge sur DVI',
        },
      },
      businessProcessType: {
        '1': 'Marchandise',
        '2': 'Services ou autres acquisitions',
        '3': 'Remises commerciales et financières',
      },
      taxRepresentative: 'Représentant fiscal',
      documentLevelCharges: 'Déductions globales au document',
      documentLevelDiscounts: 'Taxes globales au document',
      primaryAccountNumberID:
        'Numéro de compte principal de la carte de paiement',
      orderReferenceSalesOrderId: 'Référence de la commande client',
    },
    customers: {
      title: 'Clients',
    },
    issuers: {
      title: 'Emetteurs',
    },
    role: {
      meniu: 'Rôles',
      domain: 'Domaine',
      document: 'Documents',
    },
    despatchAdvices: {
      title: "Avis d'expédition",
      document_label: "Avis d'expédition",
      edit_title: "Edition avis d'expédition ",
      create_title: "Création avis d'expédition",
      show_title: "Avis d'expédition : ",
      number: 'No : ',
      despatchAdviceNumber: "Numéro d'avis d'expédition",
      shippingContactName: 'Nom du contact',
      shippingContactPhone: 'Téléphone de contact',
      shippingContactEmail: 'Courrier électronique de contact',
      despatchSupplierPartyID: 'ID expéditeur',
      shipmentId: "ID d'expédition",
      shipment: 'Expédition',
      grossVolumeMeasure: 'Volume brut',
      grossVolumeMeasureUnitCode: 'Unité de mesure',
      netVolumeMeasure: 'Volume net',
      netVolumeMeasureUnitCode: 'Unité de mesure',
      totalNumberofGoods: 'Nombre total de marchandises',
      totalNumberofGoodsUnitCode: 'Unité de mesure',
      customsDeclaredValueAmount: 'Montant de la valeur en douane déclarée',
      customsDeclaredValueAmountCurrency: 'Devise',
      specialInstructions: 'Instructions particulieres',
      exportCountryCode: "Pays d'exportation",
      shipmentStageId: "ID de l'étape d'expédition",
      transportModeCode: 'Code du mode de transport',
      transportMeansTypeCode: "Moyens de transport pour l'��tape d'expédition",
      transportMeansTypeCodeListId:
        "Liste d'identification des moyens de transport",
      carrierCode: 'Code de transporteur',
      carrierName: 'Nom du transporteur',
      trackingId: "ID de suivi de l'envoi",
      actualDeliveryDate: 'Date réelle de livraison',
      actualDeliveryTime: 'Heure réelle de livraison',
      requestedDeliveryDate: 'Date de livraison requise',
      requestedDeliveryTime: 'Heure de livraison requise',
      estimatedArrivalDate: "Date d'arrivée estimée",
      estimatedArrivalTime: "Heure d'arrivée estimée",
      handlingUnitTypeCode: "Unité de mesure de l'unité de manutention",
      handlingUnitShippingMarks: 'Type de marquage colis',
      handlingUnitPackageQuantity: 'Nombre de colis',
      handlingUnitPackageQuantityUnitCode: 'Unité de mesure',
      handlingUnitPackageReturnableMaterialIndicator:
        'Type de colis (consigné ou non)',
      handlingUnitPackagePackingLevelCode: "Code de niveau d'emballage",
      handlingUnitPackagePackagingTypeCode: "Type d'identification du colis",
      missingLineOrderIDref: "Veuillez saisir l'Order ID",
      logisticOnly: 'Logistique uniquement',
      logisticOnlyMessage:
        "Il s'agit d'un avis d'expédition logistique, il ne parviendra donc pas à l'acheteur via le canal EDI. Ceci a pour seul but de permettre l'émission et l'impression de l'envoi.",
    },
    receiptAdvices: {
      title: 'Avis de réception',
      document_label: 'Avis de réception',
      document_label_return: 'Avis de retour',
      document_label_ack: 'Avis de confirmation de réception',
      palletReceiptNotice: '352 - Avis de réception de palette',
      receiptAdviceSubType: '632 - Avis de réception',
      goodsReturnSubType: '35E - Avis de retour',
      ackReceiptSubType: '352 - Avis de confirmation de réception',
      create_title: 'Création avis de réception',
      edit_title: 'Edition avis de réception ',
      create_goods_return_title: 'Création avis de retour',
      edit_goods_return_title: 'Edition avis de retour ',
      show_title: 'Avis de réception : ',
      number: 'Nr : ',
      goodsReturnID: 'ID avis de retour',
      receiptAdviceID: 'ID avis de réception',
      goodsReturnIssueDate: "Date d'émission",
      receiptAdviceType: "Type d'avis de réception",
      goodsReturnReason: 'Motif retour',
      licensePlateId: "Plaque d'immatriculation",
      productService: 'Prodit / Service',
      customerContact: 'Contact client',
      supplierContact: 'Contact fournisseur',
      returnReasonAlteredMerchandise: 'Marchandise altérée',
      returnReasonMissingMerchandise: 'Marchandise manquante',
      returnReasonExtraQuantity: 'Quantité supplémentaire',
    },
    feedbackMessages: {
      title: 'Messages',
      document_label: 'Messages',
      edit_title: 'Edition Message ',
      show_title: 'Message : ',
      no_docs: 'Pas de messages',
    },
    linkedDocuments: {
      no_docs: 'Pas de documents',
    },
    attachments: {
      title: 'Pièces jointes',
      document_label: 'Pièces jointes',
      edit_title: 'Edition pièce jointe ',
      show_title: 'Pièce jointe : ',
      no_docs: 'Pas de pièce jointe',
    },
    orders: {
      title: 'Commandes',
      document_label: 'Commande',
      edit_title: 'Edition commande ',
      create_title: 'Création de commande',
      show_title: 'Commande : ',
      orderNumber: 'Numéro de commande',
      initialOrder: '105 - Commande initiale',
      updatedOrder: '231 - Commande mise à jour',
      updatedOrderNotModified: '320 - Confirmation de commande',
      finalOrder: '220 - Commande finale',
      crossDocking: '50E - Cross-docking',
      goodsReturn: '70E - Retour des marchandises',
      orderType: 'Type de commande',
    },
    forecast: {
      title: 'Planifications de livraison',
      document_label: 'Planification',
      edit_title: 'Edition planification de livraison ',
      show_title: 'Planification : ',
    },
    contract: {
      title: 'Contrats',
      document_label: 'Contrat',
      edit_title: 'Edition contrat ',
      show_title: 'Contrat : ',
    },
    apm: {
      title:
        'Rendez-vous sur le cockpit de classification des dépenses pour voir cette tâche',
      subheader:
        "Veuillez vous adresser à votre conseiller commercial pour plus d'informations",
    },
    apmValidation: {
      title:
        'Rendez-vous dans le cockpit de réconciliation pour voir cette tâche',
      subheader: 'Veuillez approuver ou rejeter la facture ci dessous:',
      open_cockpit: 'Ouvrir le cockpit',
    },
    catalog: {
      title: 'Catalogues',
      document_label: 'Catalogue',
      edit_title: 'Edition catalogue ',
      show_title: 'Catalogue : ',
    },
    waybill: {
      title: 'CMRs (tous)',
      document_label: 'CMR',
      document_sublabel: 'Convention de transport de Marchandise par la Route',
      edit_title: 'Edition de CMR',
      create_title: 'Création CMR',
      show_title: 'CMR : ',
      number: 'N° de CMR',
      carrierParty: 'TRANSPORTEUR',
      consigneeParty: 'DESTINATAIRE',
      consignorParty: 'EXPÉDITEUR',
      freightForwarderParty: 'TRANSPORTEUR DE FRET',
      note: 'Note',
      partyTile: {
        vatIdentification: 'N° de TVA',
        name: 'Nom',
        street: 'Rue',
        additionalStreet: 'Rue 2',
        number: 'N° de bâtiment',
        city: 'Ville',
        postalCode: 'Code postal',
        countrySubEntityCode: 'Code comté',
        countryCode: 'Code pays',
        contactName: 'Nom du contact',
        phone: 'Téléphone',
        email: 'Email',
      },
      loadingTable: {
        serviceDescription: 'Type de transport',
        loadingNumber: 'N° de chargement',
        licensePlate: "Plaque d'immatriculation du (des) véhicule (s)",
        marksAndNumber: 'Marques et numéros',
        packageNumber: 'Nombre de colis',
        packingMethod: "mode d'emballage",
        goodsNature: 'Nature de la marchandise',
        remarks: 'Observations',
        loadingLocation: 'LIEU DE PRISE EN CHARGE',
        unloadingLocation: 'LIEU DE LIVRAISON',
        loadingDate: 'Date de chargement',
        unloadingDate: 'Date de déchargement',
        costs: {
          transportCostBeforeFees: 'Frais de transport hors taxes',
          transportFees: 'Taxes de transport',
          transportCostIncludingFees:
            'Frais de transport toutes taxes comprises',
        },
        addressName: 'Nom adresse',
      },
      serviceDescription: {
        glassSale: 'Vente de verre',
        glassAcquisition: 'Acquisition de verre',
        culletsReturn: 'Retour calcin',
        shelvesReturn: 'Retour étagères',
        fees: 'Honoraires',
      },
      issueDate_loadingDate_error:
        "Doit être inférieure ou égale à la date d'émission de la facture",
      unloadingDate_loadingDate_error:
        'Doit être supérieure ou égale à la date de chargement',
    },
    dxDocuments: {
      title: 'Documents (tous)',
      document_label: 'Document',
      edit_title: 'Edition document ',
      show_title: 'Document : ',
    },
    state: {
      completed: 'terminé',
      suspended: 'En attente',
      active: 'Active',
    },
    appSwitcher: {
      DocProcess: {
        label: 'Gérer vos documents et processus métiers',
      },
      DxArchive: {
        label: 'Archiver vos documents',
      },
      DxCatalog: {
        label: 'Gérer vos catalogues de produits',
      },
      ReconciliationCockpit: {
        label: 'Gérer vos comptes fournisseurs',
      },
      ExpenseClassificationCockpit: {
        label: 'Affecter les dépenses de vos factures',
      },
      DxContract: {
        label: 'Gérer vos contrats',
      },
      DxUpload: {
        label: 'Connecteur intelligent DocProcess',
      },
      EtransportCockpit: {
        label: 'Déclarer votre transport routier en Roumanie',
      },
      C4Cockpit: {
        label: 'Gérez vos documents efactura',
      },
    },
    user_menu: {
      settings: 'Préférences',
      language: 'Langue',
      select_language: 'Choisissez une langue',
      account_preferences: 'Mon compte',
      AccountNotFound: 'Compte introuvable!',
      AccountToImpersonate: 'Qui voulez-vous être?',
      AccountToImpersonateHelper: 'e-mail ou login <ENTER>',
      SwitchCompany: 'Changer de société',
    },
    help_menu: {
      title: 'Aide',
      userGuide: 'Guide utilisateur',
      feedbacks: 'Faire des commentaires',
      mailto:
        'mailto:feedback@doc-process.com?subject=Commentaires sur %{DxApp}',
    },
    menu: {
      home: 'Accueil',
      documents: 'Documents',
      create: 'Création documents',
      administration: 'Administration',
      tasks: 'Tâches',
      tooltip: 'Vue documents',
    },
    buttons: {
      unselect: 'Déselectionner',
      preview: 'Apercu',
      more: 'Plus ...',
      related_documents: 'Documents connexes et messages',
      download: 'Télécharger',
      ediDocument: 'EDI Document',
      createNewDocument: 'Création',
      cloneInvoice: 'Dupliquer la facture',
      cancelInvoice: 'Annuler la facture',
      correctiveInvoice: 'Corriger la facture',
      send: 'Envoyer',
      convertToInvoice: 'Convertir en facture',
      convertToDespatchAdvice: "Convertir en avis d'expédition",
      convertToReceiptAdvice: 'Convertir en avis de réception',
      editInvoice: 'Editer la facture',
      editDespatchAdvice: "Editer l'avis d'expédition",
      editWayBill: 'Editer le CMR',
      editReceiptAdvice: "Editer l'avis de réception",
      editGoodsReturn: "Editer l'avis de retour",
      editOrder: 'Editer la commande',
      cloneWayBill: 'Dupliquer le CMR',
      cloneDespatchAdvice: "Dupliquer l'avis d'expédition",
      cloneDocument: 'Dupliquer le document',
      appsSwitcher: "Sélecteur d'application",
      messagesCenter: 'Gestion des messages',
    },
    inputs: {
      min_input_length: 'Minimum %{minLength} caractères',
      add_new_entry: 'Ajouter "%{entry}"',
    },
    error_messages: {
      must_be_greater: 'Doit être plus grand que %{target}',
      phone_number_invalid: 'Le numéro de téléphone saisi est invalide',
      generic_error:
        'Une erreur inattendue est survenue. Veuillez réessayer plus tard.',
      form_load_error:
        'Une erreur est survenue pendant le chargement des données du formulaire',
      form_save_error:
        'Une erreur est survenue pendant la sauvegarde des données du formulaire',
      form_invalid_template:
        'Le form ne peut pas être affiché due à une erreur. Veuillez contacter le support.',
      max_decimals: 'Veuillez utiliser maximum %{decimals} décimales',
      type_number: 'La valeur doit être un numéro',
      unable_to_display:
        'Document pas encore disponible. Veuillez réessayer ultérieurement.',
      quantities_use_same_sign:
        'Les quantités doivent toutes être positives ou toutes negatives',
      non_zero: 'La valeur ne peut pas être égale à 0',
      negative: 'La valeur doit être negative.',
      invalid_DXUID: 'DXUID invalide',
      invalid: 'Invalide',
      fixed_length: 'Doit contenir exactement %{fixedLength} chiffres',
      line_error: 'Cette ligne contient une ou plusieurs erreurs.',
      OnError_contact_the_support:
        'Oups, une erreur est survenue. Veuillez réessayer plus tard ou contacter le support ' +
        'de DocProcess (support@doc-process.com) [%{error}]',
      address_already_exist: "L'adresse existe déjà pour ce GLN",
      company_already_exists:
        "Le nom de l'entreprise ou le code TVA existent déjà.",
      company_info_not_found: "Informations sur l'entreprise non trouvées",
      email_already_exists: 'Cette adresse e-mail est déjà enregistrée.',
      product_usage_already_exists: 'Ce produit est déjà enregistré.',
      impossible_delete_company:
        "Impossible de supprimer la société, il faut d'abord supprimer les dépendances",
      vat_not_found: "Attention, code TVA introuvable dans l'annuaire public",
      not_enough_rights: "Vous n'avez pas les droits pour cette opération",
      invalid_character: 'Caractère non valide : %{unwanted}',
      fieldTobeSet: 'le champ %{label} doit également être renseigné',
    },
    processStatus: {
      ACCEPTED_BY_CUSTOMER: 'Accepté par le destinataire',
      ACCEPTED_BY_RECIPIENT: 'Accepté par le destinataire',
      ACCOUNT_CHECKED: 'Reçu par DocProcess',
      ALLOCATION_IN_PROGRESS: 'Allocation en cours',
      CONTENT_ERROR: 'Erreur contenu',
      DELIVERED: 'Transmis',
      DELIVERED_FROM_CONTENT_ERROR: 'Erreur contenu',
      DELIVERED_FROM_FORMAT_ERROR: 'Erreur format',
      ENRICHED: 'Reçu par DocProcess',
      FORMAT_ERROR: 'Erreur format',
      LOCALLY_DELIVERED: 'Reçu par DocProcess',
      MATCHING_ERROR: 'Réconciliation en échec',
      MATCHING_IN_PROGRESS: 'Réconciliation en cours',
      NEW: 'Reçu par DocProcess',
      NO_CREDIT: 'Plus de crédit',
      NORMALIZED: 'Reçu par DocProcess',
      NOTIFIED: 'Transmis',
      PAID_BY_RECIPIENT: 'Payé par le destinataire',
      PARTIALLY_PAID_BY_RECIPIENT: 'Partiellement payé par le destinataire',
      PENDING_ENRICHMENTS: 'En attente d’enrichissement',
      PENDING_RECADV: 'En attente d’enrichissement',
      PENDING_SCHEDULED_DELIVERY: 'Transmis',
      PENDING_TO_CUSTOMER: 'En attente du destinataire',
      PENDING_CORRECTION: 'En attente de correction',
      RECEIVED_BY_CUSTOMER: 'Reçu par le destinataire',
      RECEIVED_BY_DX: 'Reçu par DocProcess',
      RECEIVED_BY_RECIPIENT: 'Reçu par le destinataire',
      RECEIVED_BY_SUPPLIER: 'Reçu par le fournisseur',
      REJECTED_BY_CUSTOMER: 'Rejeté par le destinataire',
      REJECTED_BY_RECIPIENT: 'Rejeté par le destinataire',
      REJECTED_BY_SUPPLIER: 'Refusé par le fournisseur',
      RECEIVED_BY_BSP: 'Reçu par le Service Provider',
      RECEIVED_AND_VALIDATED_BY_BSP: 'Reçu et validé par le Service Provider',
      REJECTED_BY_BSP: 'Rejeté par le Service Provider',
      UNDER_QUERY: 'En attente d’informations complémentaires',
      CONDITIONALLY_ACCEPTED: 'Accepté sous condition',
      IN_PROCESS: 'En cours de traitement',
      SENT: 'Transmis',
      SUSPENDED: 'Plus de crédit',
      VALIDATED: 'Reçu par DocProcess',
      WAITING_FOR_RECIPIENT: 'En attente du destinataire',
      DRAFT: 'Brouillon',
      openAuditTrail: {
        tooltip:
          "Cliquez pour ouvrir la fenêtre d'historique des évènements et obtenir plus d'informations sur l'état du document",
      },
    },
    readStatus: {
      NEW: 'Nouveau',
      DOWNLOADED: 'Téléchargé',
      READ: 'Lu',
      CONVERTED: 'Converti',
    },
    invoiceTypes: {
      FAM: 'FAM : Auto-facturation de marchandise',
      FAS: 'FAS : Auto-facturation de service',
      FCG: 'FCG : Facture de frais généraux',
      FIM: "FIM : Facture d'actifs",
      FMD: 'FMD : Facture de remises',
      FME: 'FME : Facture de marchandise externe',
      FMF: 'FMF : Facture de marchandise',
      FSR: 'FSR : Facture de service',
      FGO: 'FGO : Facture de frais généraux avec commande',
    },
    processDocumentFormatTypes: {
      ORIGINAL: 'Original',
      ISSUER_ORIGINAL: 'Original',
      IMAGE: 'Image',
      ISSUER_IMAGE: 'Image',
      RECIPIENT_IMAGE: 'Image',
      TARGET: 'Cible',
      DX: 'UBL',
    },
    archivePeriod: {
      1: '1',
      10: '10',
      Permanent: 'Permanent',
    },
    documentTypeCode: {
      APERAK: 'Message',
      CATLOG: 'Catalogue',
      CNTCND: 'Contrat',
      DESADV: "Avis d'expédition",
      DELFOR: 'Planning de livraison',
      INVOIC: 'Facture',
      INVOICE: 'Facture',
      ORDERS: 'Commande',
      ORDER: 'Commande',
      RECADV: 'Avis de réception',
      ATTACH: 'Pièce jointe',
      WAYBIL: 'CMR',
    },
    regulatorExtraDetails: {
      efacturaOnly: 'Autorité fiscale seule',
    },
    regulatorStatusCode: {
      '0': 'Accepté',
      '10': 'Rejeté',
      '20': "Erreur interne à l'autorité fiscale",
      '40': 'En attente',
    },
    taxPointDateCode: {
      '3': "Date d'émission de la facture",
      '35': 'Date de livraison effective',
      '432': 'Montant payé le même jour',
    },
    headers: {
      accountingCost: 'Coût comptable',
      companyLegalForm: 'Forme juridique de la société',
      id: 'ID',
      manufacturersItemIdentification: 'Code des fabricants',
      transactionType: 'Type de transaction',
      packageSequence: "ID de séquence d'emballage",
      numberOfPackages: 'Nombre de colis',
      packingLevelCode: "Séquence d'emballage",
      packagingTypeCode: 'Type du colis',
      packageID: 'Identifiant du package (SSCC)',
      certNumberBioProduct: 'No de cert pour BIO',
      deliveryNoteReferenceNumber: 'Numéro de bordereau de livraison',
      packageIDC: 'Code SSCC',
      packageMarkerID: 'ID du marqueur de package',
      shippingContainer: "Qualificatif d'ID de package",
      bestBefore: 'Date de péremption',
      expiryDate: "Date d'expiration",
      lotNumberID: 'Numéro de lot',
      tradeCode: 'Numéro de registre du commerce',
      supplierBank: 'Banque du fournisseur',
      clientBank: 'Banque du client',
      packingType: 'Type d’emballage',
      UmUnitPriceBase: 'Unité de mesure de base pour le prix unitaire',
      unitPriceBase: 'Base du prix unitaire',
      deliveryQuantityCode: 'Code de quantité livré',
      deliveryQuantity: 'Quantité livrée',
      unitsNumber: "Nombre d'unités par boîte",
      invoiceTypeCode: 'Code de type de facture',
      duration: 'Durée',
      periodType: 'Type de période',
      referenceReporting: 'Rapport de référence',
      referenceEvent: 'Événement de référence',
      paymentDeadline: 'Date limite de paiement',
      paymentMeansCode: 'Type de paiement UN/ECE 4461',
      paymentMeansID: 'Délai de paiement (TP)',
      addition: 'Information additionnelle',
      customer: 'Client',
      customerId: 'ID client',
      status: 'Etat',
      documentName: 'Nom de fichier',
      documents: 'Documents connexes',
      documentNumber: 'Numéro de document',
      issueDate: "Date d'émission",
      sender: 'Expediteur',
      senderId: 'ID expediteur',
      recipientName: 'Destinataire',
      recipientId: 'ID destinataire',
      receiverId: 'ID Récepteur',
      createdBy: 'Créé par',
      modifiedBy: 'Modifié par',
      modificationDate: 'Date de modification',
      modifiedAt: 'Date de modification',
      creationDate: 'Date de création',
      createdAt: 'Date de création',
      documentTypeCode: 'Type de document',
      documentId: 'ID document',
      document: 'Document',
      excisePayerCode: 'Code du contribuable',
      issuerName: 'Emetteur',
      archivePeriod: "Période d'archivage",
      archiveDate: "Date d'archivage",
      supplier: 'Fournisseur',
      supplierId: 'ID fournisseur',
      buyer: 'Acheteur',
      buyerId: 'ID acheteur',
      buyerVatIdentifier: 'Code contribuable client',
      buyerReference: "Référence de l'acheteur",
      startedAt: 'Demarré à',
      deliveryDate: 'Date de livraison',
      actualDeliveryDate: 'Date réellede livraison',
      deliveryHour: 'Heure de livraison',
      parties: 'Participants',
      orders: 'Commandes',
      number: 'Num.',
      totalWithVat: 'TTC',
      totalWithoutVat: 'HT',
      totalVat: 'Total TVA',
      vat: 'TVA',
      vatPercent: 'TVA (%)',
      quantity: 'Quantité',
      quantityLidl: 'Quantité de cartons/caisses',
      price: 'Prix',
      invoiceNumber: 'Numéro de facture',
      invoiceId: 'ID facture',
      invoiceDate: 'Invoice date',
      orderId: 'ID commande',
      orderDate: 'Date de commande',
      forecastId: 'ID planification',
      attachment: 'Pièce jointe',
      invoiceType: 'Type de facture',
      processDocumentFormatType: 'Format de document',
      originalFileName: 'Nom de fichier original',
      adherentUniqueIdentifier: "ID unique d'adherent",
      issuerId: 'ID émetteur',
      gln: 'Code GLN',
      deliveryLocationName: 'Référence livraison',
      locationAddress: 'Adresse de livraison',
      address: 'Adresse',
      currency: 'Monnaie',
      processStatus: 'Etat du processus',
      readStatus: 'Etat lecture',
      receiptAdviceId: 'ID avis de réception',
      receiptAdviceDate: 'Date avis de réception',
      despatchAdviceId: "ID avis d'expédition",
      despatchAdviceDate: "Date avis d'expédition",
      contractId: 'ID contrat',
      contractDate: 'Date de contrat',
      dxuid: 'DXUID',
      documentReferenceDxuid: 'DXUID de référence',
      attachmentParentDxuid: 'DXUID de référence',
      show_metadata: 'Metadonnées',
      description: 'Description',
      referenceInvoiceId: 'Facture de référence',
      referenceInvoiceDate: 'Date de facturation',
      codeClient: 'Code client',
      codeStandard: 'Code EAN',
      itemClassificationCode: "Code de classification de l'article",
      codeSupplier: 'Code fournisseur',
      codeBuyer: 'Code client',
      productCodeRef: 'Code ref produit',
      um: 'U.M',
      messageDetails: 'Details message',
      buyerCustomer: 'Commandé par',
      supplierReceiverCode: 'Code fournisseur',
      supplierName: 'Name',
      numberOfLines: 'Nbre lignes',
      user: 'Utilisateur',
      streetName: 'Rue',
      additionalStreet: 'Rue supplémentaire',
      buildingNumber: 'Num. de bâtiment',
      cityName: 'Ville',
      postalZone: 'Code postal',
      countryCode: 'Pays',
      discount: 'Réduction (%)',
      discountAmount: 'Montant de la remise',
      taxAmount: 'Montant des frais',
      taxType: 'Type de taxe',
      greenTax: 'Taxe verte',
      sugarTax: 'Taxe soda',
      sgrTax: 'Consigne',
      unitaryGreenTax: 'Taxe verte unitaire',
      paymentTermsQualifier: 'Qualificatif',
      paymentTermsStartEvent: "Démarrer l'événement",
      paymentTermsReferenceEventCode: 'Reference event code',
      paymentTermsSettlementPeriodCode: 'Code période',
      paymentType: 'Code de moyen de paiement UN / ECE 4461',
      paymentTermsSettlementPeriodDurationMeasure: 'Mesure de la durée',
      paymentTerms: 'Paiement',
      identification: 'Identification',
      linkedDocuments: 'Documents liés',
      carrierPartyName: 'Nom du transporteur',
      consignorPartyName: "Nom de l'expediteur",
      loadingLocationAddress: 'Adresse du lieu de chargement',
      loadingLocationGln: 'Code GLN du lieu de chargement',
      loadingLocationName: 'Nom du lieu de chargement',
      unloadingLocationAddress: 'Adresse du lieu de déchargement',
      unloadingLocationGln: 'Code GLN du lieu de déchargement',
      unloadingLocationName: 'Nom du lieu de déchargement',
      extendedInvoiceId: 'N° de facture',
      extendedInvoiceIssueDate: "Date d'émission de la facture",
      extendedInvoiceDxuid: 'DXUID de facture',
      paymentExchangeRateSourceCurrencyCode: 'Code devise source',
      paymentExchangeRateTargetCurrencyCode: 'Code devise cible',
      paymentExchangeRateCalculationRate: 'Taux de change',
      shippingLocation: "Lieu d'expédition",
      shipment: 'Expédition',
      shipmentInformation: 'Information expédition et contact',
      shipmentStage: "Etape de l'expédition",
      shipmentDelivery: "Suivi de l'expédition",
      shipmentTransportHandlingUnit: 'Expédition - Unité de transport',
      orderReferenceSalesOrderId: 'ID commande fournisseur',
      orderLineReferenceLineId: 'Référence  commande',
      orderTypeCode: 'Code du type de commande',
      unitaryPrice: 'Prix unitaire',
      fiscalCode: 'Code fiscale',
      contactName: 'Nom',
      contact: 'Contact',
      contactPhone: 'Téléphone',
      contactNote: 'Note',
      contactIDCardSeries: 'ID Serie carte',
      contactIDCardNumber: 'ID Numéro carte',
      contactIDCardIssuer: 'ID Fournisseur carte',
      measureUnit: 'Unité de mésure',
      documentSubTypeCode: 'Sous-type document',
      name: 'Nom',
      regulatorId: 'ID de téléchargement autorité fiscale',
      regulatorStatusCode: "Code d'état autorité fiscale",
      regulatorExtraDetails: 'Info autorité fiscale',
      grossWeightMeasure: 'Poids brut',
      grossWeightMeasureAttributeId: 'Id Poids brut',
      grossVolumeMeasure: 'Volume brut',
      heightMeasure: 'Hauteur',
      heightMeasureUm: 'Hauteur U.M.',
      quantityPerPallet: 'Maximum quantité de cartons/caisses par palette',
      quantityUnitPerPallet: 'Id Pièces sur la palette',
      grossWeightMeasureUm: 'Poids U.M.',
      grossVolumeMeasureUm: 'Volume U.M.',
      heightMeasureAttributeId: 'Attribut du hauteur',
      grossVolumeMeasureAttributeId: 'Attribut du volume',
      customizationID: 'Personnalisation ID',
      profileID: 'Profil ID',
      projectReferenceID: 'Référence du projet',
      contactElectronicMail: 'Email',
      sendPDFToElectronicMail: 'Email pour envoi de PDF',
      taxPointDate: 'Date échéance TVA',
      taxPointDateCode: 'Code échéance TVA',
      businessProcessType: 'Type de processus métier',
      payeeParty: 'Nom du bénéficiaire',
      taxRepresentativeCompanyID:
        'Identifiant TVA du représentant fiscal du vendeur',
      taxRepresentativeName: 'Nom du représentant fiscal du vendeur',
      additionalDocumentReference: 'Documents de reference complémentaires',
      addressLine: "Information d'adresse complémentaire",
      invoicePeriodEndDate: 'Date de fin de période de facturation',
      invoicePeriodStartDate: 'Date de début de période de facturation',
      additionalItemPropertyName: 'Nom attribut élément',
      additionalItemPropertyValue: 'Valeur attribut élément',
      baseQuantity: 'Quantité de base',
      tradeRegistryNumber: 'Numéro de registre du commerce',
    },
    list: {
      columns: 'Colonnes',
      search: 'Chercher',
      default: 'Défaut',
      addFilter: 'Filtres',
      clearAll: 'Effacer',
      goToPage: 'Aller à la page',
      paginationHelp:
        'Suggestion : Vous pouvez utiliser les touches GAUCHE et DROITE pour naviguer entre les pages ou appuyer sur la touche "g" pour accéder à une page spécifique',
    },
    filter: {
      date: {
        datePicker: 'Choisir une date',
        today: "Aujourd'hui",
        allTime: 'Réinitialiser la date',
        yesterday: 'Hier',
        thisWeek: 'Semaine courante',
        last7days: '7 derniers jours',
        lastWeek: 'Semaine dernière',
        last14days: '14 derniers jours',
        thisMonth: 'Mois courant',
        lastMonth: 'Mois dernier',
        last30days: 'Derniers 30 jours',
      },
      numberRange: {
        from: 'de',
        to: 'à',
      },
      issuerLabels: {
        name: 'Emetteur',
        id: 'ID Emetteur',
      },
      recipientLabels: {
        name: 'Destinataire',
        id: 'ID Destinataire',
      },
      receiver: {
        id: 'ID Récepteur',
      },
      savedQueriesSaveBtn: 'Enregistrer requête',
      savedQueriesMenu: 'Requêtes enregistrées',
      savedQueriesSaveText: 'Choisissez un libellé pour cette requête',
      savedQueriesDeleteTitle: 'Supprimer : %{savedQuery}',
      removeQueryRemoveText: 'Voulez-vous supprimer la requête?',
      dateRange: {
        from: 'Du:',
        to: 'Au:',
        invalidRange: 'Plage de temps invalide',
      },
      apply: 'Appliquer',
    },
    pdf: {
      loadError: 'Impossible de visualiser le document',
      page: 'Page',
      of: 'de',
    },
    showMetadata: {
      error: 'Impossible de visualiser les metadonnées',
      aperakDetailsError: "Impossible d'afficher les détails du message",
    },
    download: {
      error: "Erreur: Impossible d'obtenir les représentations du document",
      errorBulk: 'Erreur: Impossible de télécharger les documents sélectionnés',
      inProgress: 'Téléchargement en cours ...',
      completed: 'Téléchargement terminé.',
      warningLimit: 'Le nombre maximal de sélections est limité à 200 items',
    },
    delete: {
      error: 'Error: impossible de supprimer le document sélectionné',
      errorBulk: 'Error: impossible de supprimer les documents sélectionnés',
      inProgress: 'Suppresion en cours ...',
      completed: 'Suppression effectuée.',
      warningLimit: 'Le nombre maximal de sélections est limité à 200 items',
    },
    messages: {
      update_profile_title: 'Mise à jour données utilisateur',
      no_apps_purchased: 'Avez-vous acheté une application?',
    },
    webForm: {
      saved: 'Document ID *** %{documentId} *** enregistré ...',
      savedWithNoId: 'Document enregistré ...',
      errorSaving: 'Impossible de sauvegarder le document ...',
      noDocumentId: "Merci d'identifier le document avant de sauvegarder",
      sent: 'Document envoyé ...',
      errorSending: "Impossible d'envoyer le document",
      errorLoadTemplate: 'Impossible de trouver un modèle de formulaire valide',
      saveConfirmTitle: 'Sauvegarde document',
      saveConfirmMessage:
        'Le formulaire contient des champs invalides. Etes-vous sûr de vouloir continuer la sauvegarde?',
      sendUnknownError:
        'Une erreur inconnue s"est produite. Veuillez contacter le support.',
      sendInvalidUblError:
        'Format de document invalid. Veuillez contacter le support.',
      sendErrorTitle: 'Erreur',
      deleteLine: 'Suppression de la ligne : %{lineNumber}',
      deleteLineTooltip: 'Supprimer',
      deleteLineConfirm: 'Etes-vous sûr ?',
      deleteLineNotifySuccess: 'Ligne numéro %{lineNumber} supprimé',
      popup: {
        title: 'Le saviez vous ?',
        content:
          'Savez-vous que vous pouvez convertir une commande en facture à partir du menu "Factures" ?',
        moreInfoTextLink: 'Cliquez ici pour comprendre comment',
        moreInfoTextLinkUrl:
          'https://support.doc-process.com/hc/en-us/articles/8880637306397-Did-you-know-you-can-automatically-convert-an-order-to-an-invoice-',
        checkboxDismissText: 'Ne plus afficher ceci',
      },
      unitCode: {
        EA: 'EA - Unité',
        H87: 'H87 - Pièce',
        XPP: 'XPP - Article en vrac',
        XBX: 'XBX - Boîte',
        XPA: 'XPA - Forfait',
        XPX: 'XPX - Palette',
        XBG: 'XBG - Sac',
        SET: 'SET - Ensemble',
        KGM: 'KGM - Kilogramme',
        GRM: 'GRM - Gramme',
        '27': '27 - Tonne théorique',
        LTR: 'LTR - Litre',
        MLT: 'MLT - Millilitre',
        MTQ: 'MTQ - Mètre cube',
        MTK: 'MTK - Mètre carré',
        LM: 'LM - Mètre linéaire',
        DAY: 'DAY - Jour',
      },
    },
    export: {
      visibleColumns: 'Exporter les colonnes visibles',
      allColumns: 'Exporter toutes les colonnes',
    },
    auth: {
      companySelectionAtLogin:
        "Vous appartenez à plus d'une companie. Sélectionnez-en une.",
      crt_password: 'Mot de passe actuel',
      crt_password_required: 'Veuillez saisir votre mot de passe actuel',
      crt_password_invalid: "Le mot de passe n'est pas valide",

      new_password: 'Nouveau mot de passe',
      new_password_required: 'Veuillez saisir votre nouveau mot de passe',

      confirm_password: 'Confirmer le mot de passe',
      confirm_password_required:
        'Veuillez saisir à nouveau votre nouveau mot de passe',

      lost_password_title: 'Mot de passe oublié ',
      lost_password_desc:
        'Veuillez saisir votre nom utilisateur pour demander le changement de mot de passe',
      lost_password_email_notif:
        "Si un compte existe pour ce nom d'utilisateur, un e-mail contenant plus d'instructions vient d'être envoyé.",
      lost_password_error:
        "Une erreur s'est produite lors de votre demande de mot de passe oublié.",

      change_password_title: 'Changement mot de passe',
      change_password_confirmation:
        "Votre mot de passe a été changé avec succès. Vous pouvez désormais l'utiliser pour vous connecter.",
      change_password_expired:
        "Le lien de réinitialisation de mot de passe a expiré. Votre mot de passe n'a pas été changé.",
      change_password_error:
        "Une erreur s'est produite lors du changement du mot de passe.",
      change_password_match_error:
        'Les mots de passe ne sont pas identiques. Veuillez réessayer.',

      password_criteria_error:
        'Le nouveau mot de passe ne remplit pas tous les critères de sécurité.',
      password_criteria_alert_title: 'Stratégie de mot de passe de DocProcess',
      password_criteria_text:
        'Pour être suffisamment sécurisé, votre mot de passe doit remplir les crières suivants:',
      password_criteria_length: 'Contenir au moins 8 caractères',
      password_criteria_lowercase: 'Contenir au moins une lettre minuscule',
      password_criteria_uppercase: 'Contenir au moins une lettre majuscule',
      password_criteria_digit: 'Contenir au moins un chiffre',
      password_criteria_special:
        'Contenir au moins un des caractères spéciaux suivants: ! & @ # : ? + * - /  $ ( )',
      token_too_many_attempts:
        'La limite de demandes a été dépassé.  Veuillez ré-essayer dans 30 minutes.',
      token_invalid_or_already_used:
        "Votre demande a expirée ou n'est pas valide.",
    },
    myAccount: {
      email: 'Email',
      enabled: 'Activé',
      firstName: 'Prénom',
      lastName: 'Nom',
      phoneNumber: 'Numéro de téléphone',
      roles: 'Rôles',
      profile_update_success:
        "Les données de l'utilisateur (sauf l'adresse email) ont été mises à jour avec succès. Si l'adresse email a changé, une confirmation supplémentaire sera nécessaire. Des instructions vous seront envoyés vers la nouvelle adresse email.",
      profile_update_error:
        "Impossible de mettre à jour les données de l'utilisateur. Une erreur est survenue.",
      create_user_success:
        "L'utilisateur a été créé avec succès. Un e-mail a été envoyé à l'adresse indiquée pour plus d'instructions.",
      create_user_success_synchronize:
        "L'utilisateur a été créé avec succès. Un e-mail sera envoyé à l'adresse indiquée, une fois l'entreprise synchronisée avec la V3.",
      create_user_error: "Impossible de créer l'utilisateur",
      delete_user_error: 'Impossible de supprimer cet utilisateur : %{error}',
      delete_customer_admin_error:
        "Il est obligatoire d'avoir au moins un administrateur.",
      email_update_success: "L'adresse email a été mise à jour avec succès",
      email_update_error:
        "Impossible de mettre à jour l'adresse email. Une erreur est survenue.",
      companyName: 'Société',
      generate_password_reset_link_button:
        'Générer un lien de réinitialisation du mot de passe',
    },
    task: {
      documentId: 'ID document',
      documentType: 'Type',
      due: 'Dû',
      start_after: 'Depuis',
      start_before: 'Après',
      description: 'Description',
      start_since: 'Depuis',
      due_in: 'Dû dans',
      orderId: 'ID commande',
      now: 'Maintenant',
      anyInvoice: 'Facture',
      commercialInvoice: 'Facture commerciale',
      cancelInvoice: "Facture d'annulation",
      correctiveInvoice: 'Facture corrective',
      serviceInvoice: 'Facture FSR',
      order: 'Commande',
      order_negotiation: 'Négociation de commande client',
      in_one_day: 'Dans un jour',
      in_three_days: 'Dans trois jours',
      in_one_week: 'Dans une semaine',
      in_two_weeks: 'Dans deux semaines',
      in_one_month: 'Dans un mois',
      in_three_months: 'Dans trois mois',
      in_six_months: 'Dans six mois',
      in_one_year: 'Dans un an',
      button: {
        approve: 'Approuver',
        reject: 'Rejeter',
        open: 'Ouvrir la tâche',
      },
      invoiceToCancel: 'Facture à annuler',
      invoiceMatched: 'Facture appariée',
      invoiceNotMatched: 'Facture non appariée',
      title: 'Vous devez approuver ou rejeter cette facture',
      matched: 'Groupe de documents',
      request: {
        success: 'Succès!',
      },
      comment: {
        message: 'Veuillez fournir un commentaire',
      },
      taskCountError: "La gestion des tâches n'est pas disponible",
      saintgobainui: {
        rejectReason: {
          incorrect_order_number: 'Numéro de commande incorrect',
          incorrect_deliverynote_number: "Numéro d'avis incorrect",
          incorrect_loading_location: 'Lieu de chargement incorrect',
          incorrect_unloading_location: 'Lieu de déchargement incorrect',
          incorrect_transport_cost: 'Prix de transport incorrect',
          incorrect_invoice_currency: 'La devise de la facture est incorrecte',
        },
        label: 'Motif du rejet',
        title: 'Facture et CMR associés',
        additionalInfo: 'Information additionnelle',
        errorMsg: {
          missing_sap_id:
            "L'ID SAP est manquant. Vous devez en ajouter un pour approuver cette tâche",
          max_sap_id_length: "L'ID SAP ne peut excéder 10 caractères",
          missing_rej_reason:
            'Motif de rejet manquant. Veuillez en sélectionner un dans la liste',
          rej_reason_with_accept:
            "Vous ne pouvez pas avoir de motif de refus ou d'informations supplémentaires lors de l'acceptation d'une facture. Veuillez le supprimer avant de continuer",
          sapid_with_rej:
            "Vous ne pouvez pas ajouter un SapId lors du rejet d'une facture. Veuillez le supprimer avant de continuer",
        },
      },
      multimedia: {
        card: {
          title: 'Valider la facture',
          subheader: 'Veuillez approuver ou rejeter la facture ci dessous:',
        },
      },
      reginamaria: {
        card: {
          supplier: {
            title: 'Négociation de commande fournisseur',
            subheader:
              "Veuillez apporter des modifications à la commande ou l'envoyer sans modifications",
          },
          client: {
            title: 'Négociation de commande client',
            subheader: 'Veuillez approuver ou rejeter la commande',
          },
        },
        table: {
          title: 'Modifier la commande #%{orderId}',
          deliveryTitle: 'Livraison',
          orderTitle: 'Commander des articles',
          startDate: 'Date de début demandée',
          endDate: 'Date de fin demandée',
          actualDeliveryDate: 'Date de livraison réelle',
          quantity: 'Quantité',
          unitCode: 'U.M.',
          itemId: "Identifiant de l'article",
          itemDesc: "Description de l'article",
          buyerIdentification: "Identifiant de l'acheteur",
          price: 'Prix unitaire HT',
          expirationDate: "Date d'expiration",
          numberOfDeliveries: 'Nombre de livraisons',
          deliveryId: 'Identifiant de livraison',
          comment: 'Commentaire',
          emptyDeliveryTable: 'Pas de données de livraison.',
          emptyOrderTable: 'Aucune donnée trouvée dans la commande.',
          deleteVerification: 'Êtes-vous sûr de vouloir supprimer cette ligne',
          bulkButton: 'Appliquer à tous',
          modificationsApplied: 'Modifications appliquées',
          addRemoveColumns: 'Ajouter ou supprimer des colonnes',
          nRowsSelected: '{0} ligne(s) sélectionnée(s)',
          searchTooltip: 'Chercher',
          searchPlaceholder: 'Chercher',
          showColumnsTitle: 'Afficher Colonnes',
          labelRowsSelect: 'lignes',
          firstTooltip: 'Première Page',
          previousTooltip: 'Page Précédente',
          nextTooltip: 'Page Suivante',
          lastTooltip: 'Dernière Page',
          labelDisplayedRows: '{from}-{to} de {count}',
          addTooltip: 'Ajouter',
          deleteTooltip: 'Supprimer',
          editTooltip: 'Editer',
          cancelTooltip: 'Annuler',
          saveTooltip: 'Sauver',
          deleteText: 'Êtes-vous sûr de supprimer cette ligne ?',
          lineStatusCode: 'Status de la ligne',
        },
        lineStatusCode: {
          NO_CHANGE: 'Inchangée',
          NOT_ACCEPTED: 'Non acceptée',
          UPDATED: 'Modifiée',
        },
        client: {
          tooltip:
            'Si les lignes ne sont pas surlignées en vert, le processus a ajouté les données manquantes par ligne',
        },
        toolbar: {
          accept: 'Accepter',
          send: 'Envoyer',
          reject: 'Rejeter',
        },
        buttons: {
          undo: 'Annuler suppression',
        },
        key: {
          infoSupplier:
            "Veuillez ajouter toute modification aux lignes de commande dans le tableau ci-dessous. Une fois terminé, vous pouvez envoyer la commande ou si vous n'êtes pas satisfait, vous pouvez la rejeter.",
          infoClient:
            'Ce sont les modifications proposées par le fournisseur. Vous pouvez accepter ou refuser les modifications.',
          originalValue: "Valeur d'origine",
          proposedValue: 'Valeur proposée',
        },
        errorMsg: {
          missing_rej_reason: 'Motif de rejet manquant',
          missing_delivery_information_line:
            'Informations de livraison manquantes pour la ligne %{lineId}',
          delivered_quantity_too_big:
            'La quantité livrée est supérieure à la quantité de ligne',
          delivered_quantity_too_low:
            'La quantité livrée est inférieure à la quantité de ligne',
          negative_numbers: 'Les nombres négatifs ne sont pas autorisés',
          same_delivery_dates:
            "Les dates de livraison sont les mêmes qu'une autre entrée. Veuillez les combiner",
          incorrect_quantity:
            'La quantité ne peut pas être supérieure à la quantité initiale',
          actual_date_mandatory: 'La date de livraison réelle est obligatoire',
          dates_not_in_range:
            'La livraison réelle doit être comprise entre la date de début et la date de fin demandées',
          dates_not_in_range_with_extra:
            'La livraison réelle doit être comprise entre la date de début et la date de fin ( + %{extra} jours ) demandées',
          dates_not_in_range_in_line:
            'Ligne %{lineId} : La livraison réelle doit être comprise entre la date de début et la date de fin demandées',
          dates_not_in_range_with_extra_in_line:
            'Ligne %{lineId} : La livraison réelle doit être comprise entre la date de début et la date de fin ( + %{extra} jours ) demandées',
          incorrect_start_date: 'Date de début incorrecte',
          incorrect_end_date: 'Date de fin incorrecte',
          price_higher_than_tolerance:
            'Le nouveau prix ne peut être augmenté que de %{greaterThan}% du prix actuel au maximum',
          price_lower_than_tolerance:
            'Le nouveau prix ne peut être abaissé que de %{lessThan}% du prix actuel au maximum',
          item_quantity:
            'La quantité livrée est supérieure à la quantité définie pour la ligne %{lineId}. Veuillez la corriger avant de soumettre',
          item_quantity_lower:
            'La quantité livrée est inférieure à la quantité définie pour la ligne %{lineId}. Veuillez la corriger avant de soumettre',
          invalid_quantity_value:
            "La valeur de quantité livrée saisie n'est pas valide",
          actual_date_mandatory_line:
            'La date de livraison réelle est manquante pour la ligne %{lineId}',
          delivery_dates_inconsistency_line:
            'Les dates de livraisons sont incorrectes pour la ligne %{lineId}',
          missing_requested_start_or_end_dates_line:
            'La date de début ou de fin de période souhaitée est manquante pour la ligne %{lineId}',
          end_date_must_be_in_future:
            "La date de fin demandée doit être dans le futur ou égale à la date d'aujourd'hui.",
          start_date_must_be_in_future:
            "La date de début demandée doit être dans le futur ou égale à la date d'aujourd'hui.",
          dates_overlap: 'Les dates de livraison ne sont pas valides.',
        },
      },
      invoice_matching: {
        invoiceHoldDecisionTask: {
          title: 'Réconciliation de facture',
          subtitle: 'Veuillez approuver ou rejeter la facture ci dessous:',
          cockpit: {
            title: 'Approuvez ou rejetez la facture',
            button: {
              approve: 'Approuver',
              reject: 'Rejeter',
            },
            nwayMatchingOfTheinvoice: 'Réconciliation de la facture',
            invoiceCluster: 'Les documents compris dans la réconciliation',
            comment:
              'Vous devez saisir un commentaire pour débloquer les boutons Ajouter/Rejecter:',
            invoiceClusterDetails:
              'La facture, ses avis de réception (liés par le numéro de commande), et peut-être des factures acceptées précédemment liées aux avis.',
            i2oInvoiceClusterDetails:
              'La facture, ses commandes, et peut-être des factures acceptées précédemment liées aux commandes.',
            raceConditionOnInvoiceApproval:
              "Vous avez déjà accepté cette facture mais entre-temps quelqu'un d'autre a apparié les mêmes lignes d'avis de réception, causant un conflit. Vous devez donc à nouveu accepter ou rejeter cette facture.",
            i2oRaceConditionOnInvoiceApproval:
              "Vous avez déjà accepté cette facture mais entre-temps quelqu'un d'autre a apparié les mêmes lignes de commande, causant un conflit. Vous devez donc à nouveu accepter ou rejeter cette facture.",
            incompleteCluster:
              "La réconciliation est peut-être incomplète: tous les documents y participant n'ont pas été retrouvés avant la date butoir (avis de réception manquants?).",
            i2oIncompleteCluster:
              "La réconciliation est peut-être incomplète: tous les documents y participant n'ont pas été retrouvés avant la date butoir (commandes manquants?).",
            usedExchangeRate: "Taux d'échange  utilisés:",
            element: 'Element',
            rules: 'Règles',
            acceptedMinusInvoiced: 'accepté - déjà facturé',
            unitaryNetPriceAlgo: 'prix net = brut - discount + taxe verte',
            match: 'Réconciliation',
            quantityRule: 'Quantité facturée <= somme des quantités reçues',
            i2oQuantityRule:
              'Quantité facturée <= somme des quantités commandées',
            unitaryPrice: 'Prix unitaire',
            unitaryPriceRule:
              'Prix unitaire net facturé <= prix unitaire net des avis de réception, taxe verte comprise',
            i2oUnitaryPriceRule:
              'Prix unitaire net facturé <= prix unitaire de la commande',
            correcting: 'Corrigeant',
            correctionChain:
              'La facture commerciale à corriger et toutes les factures correctives associées',
            errors: {
              PartialNwayMatchingException:
                "La réconciliation n'est que partielle à cause d'erreurs dans les lignes de la facture:",
              InvoiceLineWithNegativeQuantity:
                'Ligne de facture avec une quantité négative: %{invoiceId} à la ligne: %{lineId}',
              CorrectiveInvoiceWithNoReferencedInvoiceId:
                'Facture corrective sans référence sur celle à corriger: %{id}',
              CorrectiveWithoutSameOrderRefAsCommercial:
                "Facture corrective avec un identifiant de commande different de celle qu'elle corige: %{correctiveId}, " +
                '%{correctiveOrderId} - %{commercialId}, %{commercialOrderId}',
              RecadvsWithDuplicateID:
                'Avis de réception avec le même identifiant au sein du cluster de réconciliation!',
              RecadvWithDuplicateLineID:
                'Avis de réception avec des identifiants de ligne identiques!',
              InvoiceWithDuplicateLineID:
                'Facture avec des identifiants de ligne identiques!',
              InvoiceWithNoRequiredTopOrderRef:
                'Facture sans référence globale à une commande!',
              clusteringPeriodElapsed:
                "La période d'attente des avis de réception est arrivée à terme sans atteindre une réconciliation correcte!",
              i2oClusteringPeriodElapsed:
                "La période d'attente des commandes est arrivée à terme sans atteindre une réconciliation correcte!",
              waitingForCorrectedInvoicePeriodElapsed:
                "La facture à corriger n'est pas arrivée et la période d'attente est arrivée à terme!",
              waitingForRecadvPeriodElapsed:
                "La période d'attente des avis de réception est arrivée à terme sans atteindre une réconciliation correcte!",
              i2oWaitingForOrderPeriodElapsed:
                "La période d'attente des commandes est arrivée à terme sans atteindre une réconciliation correcte!",
            },
            wrongSgrTaxes:
              'Les articles ont été parfaitement rapprochés mais certaines consignes dans les lignes de facture ne correspondent pas à celles des avis',
          },
        },
        cancellationDecisionTask: {
          title: 'Annulation de facture',
          subtitle:
            "Veuillez approuver ou rejeter la facture d'annulation ci dessous:",
          cockpit: {
            title: "Approuver our rejeter la facture d'annulation",
            button: {
              cancelInvoice: "Approuver l'annulation",
              rejectCancellation: "Rejeter l'annulation",
            },
            invoiceAcceptance:
              "Cette facture a été %{acceptance} le %{date} par '%{user}'",
            automaticInvoiceAcceptance:
              'Cette facture a été automatiquement %{acceptance} le %{date}.',
            accepted: 'acceptée',
            rejected: 'rejetée',
            withTheComment: 'avec le commentaire:',
            invoiceNotFound:
              'La facture à annuler est introuvable (référence: %{ref}).',
            cancelInvoiceWithNoRef:
              "Cette facture d'annulation n'a pas de référence sur une facture à annuler.",
            comment: 'Commentaire',
            theCancelInvoice: "La facture d'annulation",
            theInvoiceToCancel: 'La facture à annuler',
            theCancelInvoiceDetails:
              "Détails de la facture d'annulation et de celle à annuler",
          },
        },
      },
    },
    uploadFiles: {
      uploadBox:
        "Faites glisser le fichier à attacher ou <span class='filepond--label-action'> Parcourir </span>",
      preparing: 'Préparer',
      ready: 'Prêt',
      clickToRemove: 'Cliquez pour supprimer',
      clickToCancel: 'Cliquez pour annuler',
      fileTooLarge: 'Le fichier est trop gros',
      maxFileSizeIs: 'La taille maximale du fichier est {filesize}',
      shortFileTooLarge: 'Trop gros',
      shortMaxFileSizeIs: 'La taille maximale est de {filesize}',
      uploadError: 'Erreur de chargement',
      loadError: 'Erreur lors du téléchargement',
      loadInitError: "Impossible d'obtenir la description du fichier",
      tapToRetry: 'Cliquez sur le cercle pour réessayer',
      uploadRefreshInfo:
        'Les fichiers téléchargés vont être disponibles prochainement dans la liste des pièces jointes. Veuillez rafraîchir manuellement la liste.',
      successfullyCreated:
        'Le fichier a été téléchargé! Il devrait être disponible prochainement dans la liste des pièces jointes',
      removeFile: 'Le fichier a été supprimé',
      uploadInitError:
        'Le téléchargement de fichiers est momentanément indisponible.',
      maxFilesReached:
        'La limite maximale du nombre de fichiers attachés a été atteinte.',
    },
    ubl: {
      packageMarkerID: 'EAN-128 ou autre format',
      packagingTypeCode: {
        pallet: 'palette',
        box: 'boîte',
        carton: 'carton',
      },
      payment_means_code: {
        bank_transfer: 'Transfert bancaire',
        cash: 'Numéraire',
        collection: 'Encaissement',
      },
      payment_terms_settlement_period_code: {
        day: 'D - jour',
        weekDay: 'WD - jour ouvrable',
        month: 'M - mois',
        year: 'Y - année',
      },
    },
    analytics: {
      dashboardMenuGroup: 'Tableaux de bord',
      analytics: 'Analyses',
      activitySummary: "Résumé de l'activité",
      timePeriod: 'Période de temps',
      monthly: 'mensuel',
      daily: 'journalier',
      monthlySummary: "Résumé de l'activité mensuelle",
      dailySummary: "Résume de l'activité journalière",
      month: 'Mois',
      day: 'Sélectionner une date',
      totalProcessed: 'Documents traités',
      processedByType: 'Documents traités par type',
      tableSummary:
        'Affichage du nombre total de documents reçus et envoyés pour la période sélectionnée.',
      processedByCompany: 'Documents traités par companie',
      documentType: 'Sélectionner le type de document',
      chartSummary: 'Affiche seulement les 10 premières entreprises.',
      type: 'Type',
      documentsReceived: 'Documents reçus',
      documentsSent: 'Documents envoyés',
    },
    audit_trail: {
      show: 'Historique des évènements',
      title: 'Historique des évènements',
      // See AuditTrailTimeline for the allowed categories.
      categories: {
        FLOW_STARTED: 'Début du traitement',
        DOCUMENT_RECEIVED: 'Document reçu',
        DOCUMENT_SENT: 'Document envoyé',
        THIRD_PARTY_ACKNOWLEDGMENT: 'Réception confirmée',
        DOCUMENT_REPRESENTATION_GENERATED: 'Représentation générée',
        DOCUMENT_ERROR: 'Erreur',
        WAIT_FOR_USER_ACTION: "En attente d'action de l'utilisateur",
        USER_ACTION_DONE: 'Tâche achevée',
        FLOW_END: 'Traitement terminé',
        INCIDENT: 'Incident',
        DOCUMENT_VALIDATION: 'Validation du document',
        USER_ACTION_DELETED: 'Tâche annulée',
      },
      types: {
        // See audit-trail-1.0.sql for the event types allowed into the trail.
        P2P_INVOICE_FORMAT_ERROR: 'Mauvais format',
        P2P_INVOICE_REJECTED_BY_RECIPIENT: "Rejetée par l'acheteur",
        P2P_INVOICE_PAID_BY_RECIPIENT: 'Payée',
        P2P_INVOICE_RECEIVED_BY_RECIPIENT: "Reçue par l'acheteur",
        P2P_INVOICE_RECEIVED_BY_BSP: 'Reçue par le Business Service Provider',
        P2P_INVOICE_REJECTED_BY_BSP: 'Rejetée par le Business Service Provider',
        P2P_INVOICE_CONTENT_ERROR: 'Erreur de contenu',
        P2P_INVOICE_CONTENT_WARNING: 'Avertissement à propos du contenu',
        P2P_INVOICE_SENT: "Envoyée à l'acheteur",
        P2P_INVOICE_RECEIVED_BY_DX: 'Reçue par DocProcess',
        P2P_INVOICE_END: 'Fin du traitement',
        P2P_INVOICE_WAIT_STATUS: "En attente de l'accusé de l'acheteur",
        P2P_INVOICE_ACCEPTED_BY_RECIPIENT: "Acceptée par l'acheteur",
        P2P_INVOICE_WAITING_FOR_RECIPIENT: "En attente d'action de l'acheteur",
        P2P_INVOICE_SENT_TO_REGULATOR: "Facture envoyée à l'autorité fiscale",
        P2P_INVOICE_WAIT_STATUS_FROM_REGULATOR:
          "En attente de la réponse de l'autorité fiscale",
        P2P_INVOICE_STATUS_FROM_REGULATOR: "Réponse de l'autorité fiscale",
        P2P_INVOICE_PARTIALLY_PAID_BY_RECIPIENT:
          'Partiellement payé par le destinataire',
        P2P_INVOICE_RECEIVED_AND_VALIDATED_BY_BSP:
          'Reçu et validé par le Business Service Provider',

        P2P_INVOICE_UNDER_QUERY: 'En attente d’informations complémentaires',
        P2P_INVOICE_CONDITIONALLY_ACCEPTED: 'Accepté sous condition',
        P2P_INVOICE_IN_PROCESS: 'En cours de traitement par le destinataire',

        P2P_ORDER_CONTENT_ERROR: 'Erreur de contenu',
        P2P_ORDER_FORMAT_ERROR: 'mauvais format',
        P2P_ORDER_RECEIVED_BY_DX: 'Reçue par DocProcess',
        P2P_ORDER_SENT: 'Envoyée au fournisseur',
        P2P_ORDER_RECEIVED_BY_RECIPIENT: 'Reçue par le fournisseur',
        P2P_ORDER_END: 'Fin du traitement',

        P2P_ORDER_NOT_CHANGED:
          "La commande n'a pas été modifiée par le fournisseur",
        P2P_ORDER_CHANGED: 'La commande a été modifiée par le fournisseur',
        P2P_ORDER_RECEIVED_BY_SUPPLIER:
          'La commande a été reçue par le fournisseu',
        P2P_ORDER_REJECTED_BY_RECIPIENT:
          "La commande a été rejetée par l'acheteur",
        P2P_ORDER_ACCEPTED_BY_RECIPIENT:
          "La commande a été acceptée par l'acheteur",
        P2P_ORDER_ACCEPTED_BY_SUPPLIER:
          'La commande a été acceptée par le fournisseur',
        P2P_ORDER_REJECTED_BY_SUPPLIER:
          'La commande a été rejetée par le fournisseur',

        P2P_RECADV_CONTENT_ERROR: 'Erreur de contenu',
        P2P_RECADV_FORMAT_ERROR: 'mauvais format',
        P2P_RECADV_RECEIVED_BY_DX: 'Reçu par DocProcess',
        P2P_RECADV_SENT: 'Envoyé au fournisseur',
        P2P_RECADV_RECEIVED_BY_RECIPIENT: 'Reçu par le fournisseur',
        P2P_RECADV_END: 'Fin du traitement',

        P2P_DESADV_CONTENT_ERROR: 'Erreur de contenu',
        P2P_DESADV_FORMAT_ERROR: 'mauvais format',
        P2P_DESADV_RECEIVED_BY_DX: 'Reçu par DocProcess',
        P2P_DESADV_SENT: "Envoyé à l'acheteur",
        P2P_DESADV_RECEIVED_BY_RECIPIENT: "Reçu par l'acheteur",
        P2P_DESADV_END: 'Fin du traitement',

        USER_TASK_CREATED: {
          invoiceHoldDecisionTask: 'Demande de reconciliation manuelle',
          cancellationDecisionTask: "Demande d'annulation manuelle",
          costCenterAllocationTask:
            "Allocation complète des lignes de facture infructueuse! Demande d'allocation manuelle",
          invoiceValidationTask: 'Demande de validation manuelle',
        },
        USER_TASK_COMPLETED: {
          invoiceHoldDecisionTask: 'Reconciliation achevée',
          cancellationDecisionTask: 'Annulation achevée',
          costCenterAllocationTask: 'Allocation manuelle achevée',
          invoiceValidationTask: 'Validation effectuée',
        },
        USER_TASK_DELETED: {
          invoiceHoldDecisionTask: 'Reconciliation annulée',
          cancellationDecisionTask: 'Annulation annulée',
          invoiceValidationTask: 'Validation réaffectée',
        },

        I2PR_STARTED: 'Réconciliation avec les bons de réception',
        I2PR_SUCCESS: 'Réconciliation approuvée',
        I2PR_ERROR: 'Réconciliation en erreur',
        I2PR_REJECTED: 'Réconciliation rejetée',
        I2PR_SKIPPED: 'Réconciliation ignorée',

        I2O_STARTED: 'Réconciliation avec les commandes',
        I2O_SUCCESS: 'Réconciliation approuvée',
        I2O_ERROR: 'Réconciliation en erreur',
        I2O_REJECTED: 'Réconciliation rejetée',
        I2O_SKIPPED: 'Réconciliation ignorée',

        ALLOCATION_STARTED: "Tentative d'allocation automatique des dépenses",
        ALLOCATION_DONE: 'Allocation enregistrée',
        AUTOMATIC_ALLOCATION_DONE: 'Allocation automatique réussie',

        // V2 events:
        // NEW => 'FLOW_STARTED'
        PROCESSED: 'Traité',
        ACCOUNT_CHECKED: 'Compte vérifié',
        DELIVERED_FROM_FORMAT_ERROR: 'Délivré (erreur de format)',
        REJECTED_BY_CUSTOMER: 'Rejeté par le client',
        RECEIVED_BY_CUSTOMER: 'Reçu par le client',
        VALIDATED: 'Validé',
        DELIVERED_FROM_CONTENT_ERROR: 'Erreur de contenu',
        DELIVERED: 'Envoyé',
        NOTIFIED: 'Notifié',
        RECEIVED_BY_DX: 'Reçu par DocProcess',
        ACCEPTED_BY_CUSTOMER: 'Accepté par le client',
        PENDING_TO_CUSTOMER: 'En attente du client',
      },
      noTrail: "Pas d'historique.",
      descriptions: {},
    },
    messagesCenter: {
      state: {
        DRAFT: 'Brouillon',
        PUBLISHED: 'Publié',
        title: 'Etat',
        help: "Visibilité du message. Le message peut être créé en tant que BROUILLON et ne sera pas visible jusqu'à ce qu'une édition le définisse comme PUBLIÉ. Définir son état comme PUBLIÉ au moment de la création le rendra visible immédiatement",
      },
      topic: {
        title: 'Objet',
        help: "Définit un sujet pour identifier votre message. Ce sujet est affiché dans le menu des notifications de l'utilisateur",
      },
      language: {
        title: 'Langage',
        help: "La visibilité des messages tient compte du choix de langue de l'utilisateur. Vous pouvez donc spécialiser le message selon la langue",
      },
      link: {
        text: "Plus d'info",
        href: "Lien plus d'info",
        public: 'Lien public',
        knowledgeBase: 'Lien vers la base de connaissance',
        help: 'Lien "plus d\'info" (optionnel) vers un lien public externe (Lien vers la base de connaissance = faux) or un lien vers la base de connaissance (Lien vers la base de connaissance = vrai)',
        show: 'Ne plus afficher',
      },
      type: {
        BANNER: 'Bannière',
        DOWNTIME: 'Interruption de service',
        MARKETING: 'News',
        GENERAL: 'Opérations',
        title: 'Type',
        help: "Catégorie de messages. Un message <<Opérations>> ou <<Marketing>> est visible sur la page d'accueil pour tous les utilisateurs.",
      },
      content: {
        title: 'Contenu du message',
        help: 'Le contenu du message est limité à 300 caractères et peut être défini sur plusieurs lignes. Cliquez sur le bouton Afficher pour un rendu du résultat',
      },
    },
  }, // End of French DxMessages
  resources: {
    'management/company': {
      name: 'Sociétés',
    },
    'management/dxrole': {
      name: 'Role',
    },
    'management/formattype': {
      name: 'Format type',
    },
    'management/supplier': {
      name: 'Fournisseurs',
    },
    'management/customer': {
      name: 'Clients',
    },
    'management/productusage': {
      name: 'Produits Sous Contrat',
    },
    'management/supportedproducts': {
      name: 'Produits Pris En Charge',
    },
    'management/address': {
      name: 'Adresses',
    },
    'management/bufferaddress': {
      name: "Importation d'adresse",
    },
    'management/contact': {
      name: 'Contact',
    },
    'management/dropdowndefinition': {
      name: 'La liste déroulante',
    },
    'management/dxproduct': {
      name: 'DX Produit',
    },
    'management/accountrelation': {
      name: 'Relation de compte',
    },
    'management/v2invoice': {
      name: 'V2 Factures',
    },
    'management/v2order': {
      name: 'V2 Commandes',
    },
    'management/v2receiptadvice': {
      name: 'V2 Avis de réception',
    },
    'management/v2despatchadvice': {
      name: "V2 Avis d'expédition",
    },
    'efactura/monitoring/error': {
      name: 'Efactura Erreur de contenu',
    },
    'efactura/monitoring/rejected': {
      name: 'Efactura Documents rejetés',
    },
    'management/peppol': {
      name: 'Peppol',
    },
    usermgmt: {
      name: 'Comptes utilisateurs',
    },
    // Override French react-admin reserved messages
    customers: {
      name: 'Clients',
      fields: {
        id: 'Identifiant',
        name: 'Nom',
      },
    },
    issuers: {
      name: 'Emetteurs',
      fields: {
        id: 'Identifiant',
        name: 'Nom',
      },
    },
    invoice: {
      name: 'Factures',
    },
    despatchAdvice: {
      name: "Avis d'expédition",
    },
    recadv: {
      name: 'Avis de réception',
    },
    feedbackMessage: {
      name: 'Message de retour',
    },
    attachment: {
      name: 'Pièces jointes',
    },
    order: {
      name: 'Commandes',
    },
    delfor: {
      name: 'Planifications livraison',
    },
    cntcnd: {
      name: 'Contrats',
    },
    catlog: {
      name: 'Catalogue',
    },
    documents: {
      name: 'Documents (tous)',
    },
    waybill: {
      name: 'CMR',
    },
    webWaybill: {
      name: 'CMR',
    },
    webInvoice: {
      name: 'Factures',
    },
    webDespatchAdvice: {
      name: "Avis d'expédition",
    },
    webReceiptAdvice: {
      name: 'Avis de réception',
    },
    webOrder: {
      name: 'Commandes',
    },
    userprofiles: {
      name: 'Profil utilisateur',
    },
    tasks: {
      name: 'Mes Tâches',
      list: {
        title: 'Mes tâches',
      },
      edit: {
        title: 'Ta tâche',
      },
    },
    'configuration-message': {
      name: 'Messages',
    },
  }, // End of override French react-admin reserved messages
  Forbidden: 'Non Autorisé',
  Unauthorized: 'Accès non autoririsé',
  NoDocsFound: 'Désolé, aucun document trouvé',
};
