import { useCallback, useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { CommonDataProvider } from '../../../dataProvider';
import { AttachmentPolicy } from './types';

/**
 * Gets the attachment policy
 */
const useAttachmentPolicy = (
  documentType: string,
  recipientId: string
): [boolean, AttachmentPolicy | undefined, string | undefined] => {
  const dataProvider = useDataProvider() as CommonDataProvider;
  const [policy, setPolicy] = useState<AttachmentPolicy | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const loadAppProfile = useCallback(async () => {
    const response = await dataProvider.apiGet('company/attach/policy', {
      documentType: documentType,
      recipientId: recipientId,
    });

    const data = response.data as AttachmentPolicy;
    return data;
  }, [dataProvider, documentType, recipientId]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);

        const data = await loadAppProfile();

        setPolicy(data);
        setError(undefined);
        setLoading(false);
      } catch (e) {
        setPolicy(undefined);
        setError(e?.message);
        setLoading(false);
      }
    };

    loadData();
  }, [dataProvider, documentType, loadAppProfile, recipientId]);

  return [loading, policy, error];
};

export default useAttachmentPolicy;
