import { Button } from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CreateIcon from '@material-ui/icons/Create';
import React, { FC } from 'react';
import { SaveButton, Toolbar, ToolbarProps, useLoading } from 'react-admin';
import { DxTheme } from '../../types';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    cancelButton: {
      marginLeft: '0.5em',
    },
  }),
  { name: 'EditToolbar' }
);

export interface EditToolbarProps extends ToolbarProps {
  onCancel?: any;
  onEdit?: any;
  readOnly?: boolean;
  saving?: any;
}

const EditToolbar: FC<EditToolbarProps> = (props) => {
  const {
    readOnly,
    onEdit,
    onCancel,
    handleSubmitWithRedirect,
    invalid,
    saving,
  } = props;

  const sanitizeToolbarProps = ({ readOnly, onEdit, onCancel, ...rest }: any) =>
    rest;

  const classes = useStyles();
  const loading = useLoading();

  const handleOnCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  const handleOnEdit = () => {
    if (typeof onEdit === 'function') {
      onEdit();
    }
  };

  return (
    <Toolbar {...sanitizeToolbarProps(props)}>
      {readOnly ? (
        <Button
          onClick={handleOnEdit}
          label='ra.action.edit'
          icon={<CreateIcon />}
          disabled={loading}
        />
      ) : (
        <>
          <SaveButton
            disabled={props.pristine || props.saving}
            handleSubmitWithRedirect={handleSubmitWithRedirect}
            invalid={invalid}
            saving={saving}
            redirect='list'
            submitOnEnter={true}
          />
          {onCancel && (
            <Button
              className={classes.cancelButton}
              onClick={handleOnCancel}
              label='ra.action.cancel'
              icon={<CancelIcon />}
              disabled={props.saving}
            />
          )}
        </>
      )}
    </Toolbar>
  );
};

export default EditToolbar;
