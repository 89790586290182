import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import SettingsIcon from '@material-ui/icons/Settings';
import { usePreferences } from '@react-admin/ra-preferences';
import moment from 'moment';
import { FC, useState } from 'react';
import { useQuery, useTranslate } from 'react-admin';
import ReactCardFlip from 'react-card-flip';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { DxTheme } from '../../../../types';

type MatchingType = 'i2pr' | 'i2o' | 'i2o2r';

/**
 */
const InvoiceAcceptanceSummary: FC<
  IWidgetContent & {
    type: MatchingType;
  }
> = ({ onTheShelves, userPreferencesRootKey, type }) => {
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const [configuring, setConfiguring] = useState(false);
  const [after, setAfter] = usePreferences(
    `${userPreferencesRootKey}.${type}.Since`,
    moment().startOf('month').valueOf()
  );

  const onSinceChange = (e) => {
    const next = moment(e.target.value);
    if (next.isValid()) setAfter(next.valueOf());
  };

  return (
    <ReactCardFlip isFlipped={configuring} flipDirection='vertical'>
      <Card classes={{ root: classes.card }}>
        {!onTheShelves && (
          <div className={classes.configure}>
            <IconButton
              onClick={() => setConfiguring(!configuring)}
              size='small'
            >
              <SettingsIcon />
            </IconButton>
          </div>
        )}
        <CardHeader
          title={translate(
            `dxMessages.dashboard.widgets.InvoiceAcceptanceSummary.${type}`
          )}
          subheader={`${translate(
            'dxMessages.dashboard.widgets.InvoiceAcceptanceSummary.Since_le'
          )}  ${moment(after).format('ll')}`}
        />
        <CardContent>
          <div style={{ width: '100%', height: '30vh' }}>
            {onTheShelves ? (
              <InvoiceAcceptanceSummaryChart
                data={{
                  acceptances: {
                    REJECTED: 12,
                    CANCELLED: 6,
                    AUTO_APPROVED: 45,
                    MANUALLY_APPROVED: 33,
                  },
                }}
              />
            ) : (
              <InvoiceAcceptanceSummaryLive type={type} after={after} />
            )}
          </div>
        </CardContent>
      </Card>
      <Card style={{ backgroundColor: theme.palette.action.selected }}>
        <div className={classes.configure}>
          <IconButton onClick={() => setConfiguring(!configuring)} size='small'>
            <KeyboardReturnIcon />
          </IconButton>
        </div>
        <Box ml={5} p='10px'>
          <TextField
            id='date'
            label={translate(
              'dxMessages.dashboard.widgets.InvoiceAcceptanceSummary.Since_le'
            )}
            type='date'
            defaultValue={moment(after).format('YYYY-MM-DD')}
            size='small'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onSinceChange}
          />
        </Box>
      </Card>
    </ReactCardFlip>
  );
};

const InvoiceAcceptanceSummaryLive = ({ type, after }) => {
  const translate = useTranslate();
  // Be careful: useGetOne() breaks the browser on logout.
  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'nwaymatching-statistics',
    payload: { id: `invoiceApprovals?type=${type}&after=${after}` },
  });

  if (error)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          error,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );
  if (loading) return <CircularProgress />;

  // Pie chart disappears when zero data so check for emptiness.
  const empty =
    data.acceptances.AUTO_APPROVED +
      data.acceptances.MANUALLY_APPROVED +
      data.acceptances.REJECTED +
      data.acceptances.CANCELLED ===
    0;

  return empty ? (
    <Box display='flex' justifyContent='center'>
      <Typography>
        {translate('dxMessages.dashboard.NoData')}.{' '}
        {translate(
          'dxMessages.dashboard.widgets.InvoiceAcceptanceSummary.SetEarlierDate'
        )}
      </Typography>
    </Box>
  ) : (
    <InvoiceAcceptanceSummaryChart data={data} />
  );
};

const InvoiceAcceptanceSummaryChart = ({ data }) => {
  const translate = useTranslate();
  const theme = useTheme();

  const COLORS = {
    AUTO_APPROVED: theme.palette.success.light,
    MANUALLY_APPROVED: theme.palette.success.dark,
    REJECTED: theme.palette.error.main,
    CANCELLED: theme.palette.common.black,
  };
  const LABELS = {
    AUTO_APPROVED: translate(
      'dxMessages.dashboard.widgets.InvoiceAcceptanceSummary.AutoApproved'
    ),
    MANUALLY_APPROVED: translate(
      'dxMessages.dashboard.widgets.InvoiceAcceptanceSummary.ManuallyApproved'
    ),
    REJECTED: translate(
      'dxMessages.dashboard.widgets.InvoiceAcceptanceSummary.Rejected'
    ),
    CANCELLED: translate(
      'dxMessages.dashboard.widgets.InvoiceAcceptanceSummary.Cancelled'
    ),
  };

  return (
    /* Set a 99% width to fix the window down size pb. */
    <ResponsiveContainer width='99%'>
      <PieChart>
        <Pie
          dataKey='value'
          data={Object.keys(data.acceptances).map((_) => ({
            name: _,
            value: data.acceptances[_],
          }))}
          labelLine
          label={renderLabel(theme)}
          isAnimationActive={false}
          paddingAngle={5}
          innerRadius='40%'
          outerRadius='65%'
        >
          {Object.keys(data.acceptances).map((_) => (
            <Cell
              name={LABELS[_]}
              key={_}
              fill={COLORS[_]}
              stroke={theme.palette.background.paper}
            />
          ))}
        </Pie>
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

const renderLabel = (theme: Theme) => (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, outerRadius, fill, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 12;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={theme.palette.text.primary}
      >
        {value}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill={theme.palette.text.hint}
        fontSize='smaller'
      >
        {`(${(percent * 100).toFixed(0)}%)`}
      </text>
    </g>
  );
};

const useStyles = makeStyles((theme: DxTheme) => ({
  // Fixed into the bottom left corner.
  configure: {
    position: 'fixed',
    left: theme.spacing(0),
    bottom: theme.spacing(0),
  },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
  },
}));

export default InvoiceAcceptanceSummary;
