import { DataProvider } from 'react-admin';
import { Constants, REST_ENDPOINTS } from '../constants';
import { httpClient } from './dataProviderUtils';

export interface GenericResult {
  data: any;
}

export type CommonDataProviderCalls = {
  apiGet: (resource: string, params: any) => Promise<GenericResult>;
  apiPut: (resource: string, params: any) => Promise<GenericResult>;
  apiPost: (resource: string, params: any) => Promise<GenericResult>;
  apiShowMetadata: (resource: string, params: any) => Promise<GenericResult>;
  getPreferences: () => Promise<GenericResult>;
  setPreferences: (data: any, previousData: any) => any;
};

/**
 * Gives the first part for the urls used in queries based on resource and query type
 */
export const commonBaseUrlFormatter = (
  appName: string,
  resource: string,
  rootUrl: string
): string => {
  if (
    resource.startsWith(Constants.RESOURCE_USERPROFILES) ||
    resource.startsWith(Constants.RESOURCE_USERS) ||
    resource.startsWith(Constants.RESOURCE_COMPANY) ||
    resource.startsWith('management') ||
    resource.startsWith('efactura/monitoring') ||
    resource.startsWith(Constants.RESOURCE_PASSWORD_MGMT)
  ) {
    return `${rootUrl}`; // ex: https://localhost:3000/dostuff
  } else {
    return `${rootUrl}/${appName}`; // ex: https://localhost:3000/dxportal/dostuff
  }
};

/**
 * Generic dataprovider calls
 */
export type CommonDataProvider = DataProvider & CommonDataProviderCalls;

function objectToQueryString(obj) {
  var str: any[] = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && p !== 'id') {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
}

/**
 * Allows url format based on resource
 */
export type BaseUrlFormatter = (resource: string, params?: any) => string;

/**
 * Transforms Web resources to be compliant for webform REST API
 * @param resource name of the resource to be eventually changed
 * @returns if web resource, returns the webform REST API compliant resource
 */
export const resourceWebTypeToWebDocumentResource = (
  resource: string
): string => {
  let newResource = resource;
  if (resource === Constants.RESOURCE_WEBINVOICE) {
    // Specific case for web Invoices list => webInvoice resource to invoice resource
    newResource = `${Constants.RESOURCE_WEBDOCUMENT}/${Constants.RESOURCE_INVOICE}`;
  }

  if (resource === Constants.RESOURCE_WEBDESPATCH_ADVICE) {
    // Specific case for web DespatchAdvice list => webDespatchAdvice resource to despatcAdvice resource
    newResource = `${Constants.RESOURCE_WEBDOCUMENT}/${Constants.RESOURCE_DESPATCH_ADVICE}`;
  }

  if (resource === Constants.RESOURCE_WEBRECEIPT_ADVICE) {
    newResource = `${Constants.RESOURCE_WEBDOCUMENT}/${Constants.RESOURCE_RECEIPT_ADVICE}`;
  }

  if (resource === Constants.RESOURCE_WEBWAYBILL) {
    newResource = `${Constants.RESOURCE_WEBDOCUMENT}/${Constants.RESOURCE_WAYBILL}`;
  }

  if (resource === Constants.RESOURCE_WEBORDER) {
    newResource = `${Constants.RESOURCE_WEBDOCUMENT}/${Constants.RESOURCE_ORDER}`;
  }

  return newResource;
};

/**
 * Returns a data provider with common operations
 */
export const buildDataProvider = (
  baseUrlFormatter: BaseUrlFormatter
): CommonDataProviderCalls => {
  return {
    apiPost: async (resource, params) => {
      const options: RequestInit = {};
      const url = `${baseUrlFormatter(resource, params)}/${resource}`;
      options.method = 'POST';
      options.body = JSON.stringify(params);

      const response = await httpClient(url, options);
      const { json } = response;
      return { data: json };
    },

    apiPut: async (resource, params) => {
      const options: RequestInit = {};
      const url = `${baseUrlFormatter(resource, params)}/${resource}`;
      options.method = 'PUT';
      options.body = JSON.stringify(params);
      const response = await httpClient(url, options);
      const { json } = response;
      return { data: json };
    },

    apiGet: async (resource, params) => {
      const options: RequestInit = {};
      let url = params?.id
        ? `${baseUrlFormatter(resource, params)}/${resource}/${params.id}`
        : `${baseUrlFormatter(resource, params)}/${resource}`;

      var queryString = objectToQueryString(params);
      url = queryString.length ? `${url}?${queryString}` : `${url}`;

      const response = await httpClient(url, options);
      const { json } = response;
      return { data: json };
    },

    apiShowMetadata: async (resource, params) => {
      const options: RequestInit = {};
      const { record } = params;
      const url = `${baseUrlFormatter(resource, params)}/${record.id}/metadata`;
      const response = await httpClient(url, options);
      const { json } = response;
      return { data: json };
    },
    // The data provider is enhanced according to react-admin spec to store
    // the user preferences on the backend side.
    getPreferences: (): Promise<{ data: any }> => {
      return httpClient(
        `${REST_ENDPOINTS.ROOT}/${REST_ENDPOINTS.USER_MGMT_PERSON_PREFERENCES}`,
        {
          method: 'GET',
        }
      ).then(({ json }) => {
        return { data: json };
      });
    },
    setPreferences: ({ data, previousData }) => {
      httpClient(
        `${REST_ENDPOINTS.ROOT}/${REST_ENDPOINTS.USER_MGMT_PERSON_PREFERENCES}`,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        }
      );
      return Promise.resolve({ data });
    },
  };
};
