/**
 * All the widgets user can put on his dashboard.
 *
 * You MUST reference all your widgets here, and define its size on screen
 * knowning that the dashboard is a 12-column grid.
 *
 */
import { UserRoles } from '@dx-ui/dx-common/src';
import { Account, UsageRight } from '@dx-ui/dx-common/src/configuration/types';
import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import CashCollectionProjectionOverTime from '@dx-ui/dx-common/src/layout/Dashboard/widgets/cash-collection/live/CashCollectionProjectionOverTime';
import TotalAmountOwedTopTen from '@dx-ui/dx-common/src/layout/Dashboard/widgets/cash-collection/live/TotalAmountOwedTopTen';
import DesadvCount from '@dx-ui/dx-common/src/layout/Dashboard/widgets/general/live/DesadvCount';
import DocumentCountPerDayAndStatus, {
  DocumentType,
  RelationRole,
} from '@dx-ui/dx-common/src/layout/Dashboard/widgets/general/live/DocumentCountPerDayAndStatus';
import DocumentReceptions from '@dx-ui/dx-common/src/layout/Dashboard/widgets/general/live/DocumentReceptions';
import InvoiceCount from '@dx-ui/dx-common/src/layout/Dashboard/widgets/general/live/InvoiceCount';
import OrderCount from '@dx-ui/dx-common/src/layout/Dashboard/widgets/general/live/OrderCount';
import PendingTaskCount from '@dx-ui/dx-common/src/layout/Dashboard/widgets/general/live/PendingTaskCount';
import RecadvCount from '@dx-ui/dx-common/src/layout/Dashboard/widgets/general/live/RecadvCount';
import InvoiceAcceptanceSummary from '@dx-ui/dx-common/src/layout/Dashboard/widgets/nway/InvoiceAcceptanceSummary';
import InvoiceWaitingForApproval from '@dx-ui/dx-common/src/layout/Dashboard/widgets/nway/InvoiceWaitingForApproval';
import InvoiceWaitingForDocuments from '@dx-ui/dx-common/src/layout/Dashboard/widgets/nway/InvoiceWaitingForDocuments';
import InvoiceWaitingForYourApproval from '@dx-ui/dx-common/src/layout/Dashboard/widgets/nway/InvoiceWaitingForYourApproval';
import TotalVariationOnAcceptedInvoiceLines from '@dx-ui/dx-common/src/layout/Dashboard/widgets/nway/TotalVariationOnAcceptedInvoiceLines';
import { FC } from 'react';
import WelcomeBanner from './widgets/WelcomeBanner';

export interface WidgetConfiguration {
  // The category of the widget.
  // The widget expects the i18n key: dxMessages.dashboard.categories.<category>
  category: string;
  // The widget content.
  content: FC<IWidgetContent>;
  // Whether the widget is closeable, that is, when on screen, can be removed
  // by the user out from the dashboard and go back into the configuration drawer.
  closeable: boolean;
  // Only one instance of the widget on the dashboard?
  singleton: boolean;
  // The size of the widget, in column count for the width (12-column grid) and
  // line count for the height.
  size: { w: number; h: number };
  // Checks whether the widget is allowed for the account.
  isAllowedFor: (account: Account) => boolean;
}

export type WidgetKey = string;

const Widgets: Record<WidgetKey, WidgetConfiguration> = {
  WelcomeBanner: {
    category: 'welcome',
    content: WelcomeBanner,
    closeable: false,
    singleton: true,
    size: { w: 12, h: 2 },
    isAllowedFor: () => true, // Any account.
  },
  // General
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  InvoiceCount: {
    category: 'general',
    content: InvoiceCount,
    closeable: true,
    singleton: true,
    size: { w: 4, h: 1 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          (_.feature.id === 'DxPurchase' &&
            _.roles.some((_) => 'VIEW_INVOICE' === _.id)) ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  OrderCount: {
    category: 'general',
    content: OrderCount,
    closeable: true,
    singleton: true,
    size: { w: 4, h: 1 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          (_.feature.id === 'DxPurchase' &&
            _.roles.some((_) => 'VIEW_ORDER' === _.id)) ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  RecadvCount: {
    category: 'general',
    content: RecadvCount,
    closeable: true,
    singleton: true,
    size: { w: 4, h: 1 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          (_.feature.id === 'DxPurchase' &&
            _.roles.some((_) => 'VIEW_RECEIPT_ADVICE' === _.id)) ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  DesadvCount: {
    category: 'general',
    content: DesadvCount,
    closeable: true,
    singleton: true,
    size: { w: 4, h: 1 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          (_.feature.id === 'DxPurchase' &&
            _.roles.some((_) => 'VIEW_DESPATCH_ADVICE' === _.id)) ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  PendingTaskCount: {
    category: 'general',
    content: PendingTaskCount,
    closeable: true,
    singleton: true,
    size: { w: 4, h: 1 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          (_.feature.id === 'DxPurchase' &&
            _.roles.some((_) => 'VIEW_TASKS' === _.id)) ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  DocumentReceptions: {
    category: 'general',
    content: DocumentReceptions,
    closeable: true,
    singleton: false,
    size: { w: 12, h: 5 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          (_.feature.id === 'DxPurchase' &&
            _.roles.some((_) =>
              [
                UserRoles.VIEW_INVOICE,
                UserRoles.VIEW_ORDER,
                UserRoles.VIEW_RECEIPT_ADVICE,
                UserRoles.VIEW_DESPATCH_ADVICE,
              ].includes(_.id)
            )) ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  InvoiceCountPerDayAndStatus: {
    category: 'general',
    content: (props) => (
      <DocumentCountPerDayAndStatus
        {...props}
        documentType={DocumentType.INVOICE}
        relationRole={RelationRole.ALL}
      />
    ),
    closeable: true,
    singleton: false,
    size: { w: 12, h: 5 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          (_.feature.id === 'DxPurchase' &&
            _.roles.some((_) => UserRoles.VIEW_INVOICE === _.id)) ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  OrderCountPerDayAndStatus: {
    category: 'general',
    content: (props) => (
      <DocumentCountPerDayAndStatus
        {...props}
        documentType={DocumentType.ORDER}
        relationRole={RelationRole.ALL}
      />
    ),
    closeable: true,
    singleton: false,
    size: { w: 12, h: 5 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          (_.feature.id === 'DxPurchase' &&
            _.roles.some((_) => UserRoles.VIEW_ORDER === _.id)) ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  RecadvCountPerDayAndStatus: {
    category: 'general',
    content: (props) => (
      <DocumentCountPerDayAndStatus
        {...props}
        documentType={DocumentType.RECADV}
        relationRole={RelationRole.ALL}
      />
    ),
    closeable: true,
    singleton: false,
    size: { w: 12, h: 5 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          (_.feature.id === 'DxPurchase' &&
            _.roles.some((_) => UserRoles.VIEW_RECEIPT_ADVICE === _.id)) ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  DesadvCountPerDayAndStatus: {
    category: 'general',
    content: (props) => (
      <DocumentCountPerDayAndStatus
        {...props}
        documentType={DocumentType.DESADV}
        relationRole={RelationRole.ALL}
      />
    ),
    closeable: true,
    singleton: false,
    size: { w: 12, h: 5 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          (_.feature.id === 'DxPurchase' &&
            _.roles.some((_) => UserRoles.VIEW_DESPATCH_ADVICE === _.id)) ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  // Cash collection
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  CashCollectionProjectionOverTime: {
    category: 'cash-collection',
    content: (props) => <CashCollectionProjectionOverTime {...props} />,
    closeable: true,
    singleton: true,
    size: { w: 8, h: 5 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          // (_.feature.id === 'DxPurchase' &&
          //   _.roles.some((_) => UserRoles.VIEW_INVOICE === _.id)) ||
          _.feature.id === 'administration' &&
          _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR)
      ),
  },
  TotalAmountOwedTopTen: {
    category: 'cash-collection',
    content: (props) => <TotalAmountOwedTopTen {...props} />,
    closeable: true,
    singleton: true,
    size: { w: 8, h: 5 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          // (_.feature.id === 'DxPurchase' &&
          //   _.roles.some((_) => UserRoles.VIEW_INVOICE === _.id)) ||
          _.feature.id === 'administration' &&
          _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR)
      ),
  },
  // N-way matching
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  InvoiceWaitingForYourApproval: {
    category: 'nway-matching',
    content: InvoiceWaitingForYourApproval,
    closeable: true,
    singleton: true,
    size: { w: 4, h: 1 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          _.feature.id === 'reconciliation-cockpit' ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  InvoiceWaitingForApproval: {
    category: 'nway-matching',
    content: InvoiceWaitingForApproval,
    closeable: true,
    singleton: true,
    size: { w: 4, h: 1 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          _.feature.id === 'reconciliation-cockpit' ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  InvoiceWaitingForDocuments: {
    category: 'nway-matching',
    content: InvoiceWaitingForDocuments,
    closeable: true,
    singleton: true,
    size: { w: 4, h: 1 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          _.feature.id === 'reconciliation-cockpit' ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  TotalVariationOnAcceptedInvoiceLines: {
    category: 'nway-matching',
    content: TotalVariationOnAcceptedInvoiceLines,
    closeable: true,
    singleton: true,
    size: { w: 4, h: 1 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          _.feature.id === 'reconciliation-cockpit' ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  I2prInvoiceAcceptanceSummary: {
    category: 'nway-matching',
    content: (props) => <InvoiceAcceptanceSummary type='i2pr' {...props} />,
    closeable: true,
    singleton: false,
    size: { w: 4, h: 4 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          _.feature.id === 'reconciliation-cockpit' ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
  I2oInvoiceAcceptanceSummary: {
    category: 'nway-matching',
    content: (props) => <InvoiceAcceptanceSummary type='i2o' {...props} />,
    closeable: true,
    singleton: false,
    size: { w: 4, h: 4 },
    isAllowedFor: (account: Account) =>
      allUsageRights(account).some(
        (_) =>
          _.feature.id === 'reconciliation-cockpit' ||
          (_.feature.id === 'administration' &&
            _.roles.find((r) => r.id === UserRoles.PSP_ADMINISTRATOR))
      ),
  },
};

const allUsageRights = (account: Account): UsageRight[] =>
  account.configuredUsageRights
    .concat(account.delegatedUsageRights)
    .concat(account.onTheFlyUsageRights);

export default Widgets;
