import {
  checkPermissions,
  Constants,
  DocumentTypeCode,
  documentTypeCodeToResource,
  formatDate,
  formatDateToAlfresco,
  Metadata,
  UserRoles,
} from '@dx-ui/dx-common';
import {
  DespatchLineDetails,
  OrderLineReferenceDetails,
  OrderReferenceDetails,
} from '@dx-ui/lib-oasis-ubl-2.1/src/DespatchAdviceModel';
import { cloneDeep, get, set } from 'lodash';
import { buildAddressAsAString } from '../modules/common/PreviewAddress';
import DespatchAdviceSources from '../modules/despatchAdvice/DespatchAdviceSources';
import {
  Address,
  AlfrescoContent,
  DocumentProperties,
  P2pData,
  P2pUblContent,
  TemplateModelElement,
  WebFormData,
} from '../shared/types';
import { setSourceField } from '../shared/webForms/utils';
import {
  DuplicationType,
  IAlfrescoDocumentService,
} from './AlfrescoDocumentService';
import { BaseDocumentService } from './BaseDocumentService';
import { CatalogService } from './CatalogService';
import { CompanyModel, CompanyService, CreationPolicy } from './CompanyService';
import { DataHelpers } from './DataHelpers';
import { FormDataHelpers } from './FormDataHelpers';
import { IDocumentService } from './IDocumentService';
import { TemplatesService } from './TemplatesService';

const TEMPLATE_TYPE = 'DESADV';

export interface IDespatchAdviceService extends IDocumentService {}

export class DespatchAdviceService
  extends BaseDocumentService
  implements IDespatchAdviceService
{
  constructor(
    private companyService: CompanyService,
    private templatesService: TemplatesService,
    private catalogService: CatalogService,
    documentService: IAlfrescoDocumentService
  ) {
    super(documentService);
  }

  private static despatchAdviceHeader: any = {
    _D: 'urn:oasis:names:specification:ubl:schema:xsd:DespatchAdvice-2',
    _A: 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2',
    _B: 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2',
  };

  async loadDraft(nodeId: string | undefined): Promise<AlfrescoContent> {
    const resource = documentTypeCodeToResource(DocumentTypeCode.DESADV);
    const payload = { id: nodeId };
    const response = await this.documentService.dataProvider[
      Constants.API_GET_JSON_CONTENT
    ](resource, payload);
    // convert => response contains ublProperties and properties
    // load draft => response contains only raw file content.
    const data = {
      ublProperties: response.data.ublProperties
        ? response.data.ublProperties
        : response.data,
    };
    const webDespatchAdviceSources = new DespatchAdviceSources(true);
    const despatchAdvice = get(
      data,
      webDespatchAdviceSources.DespatchAdviceSources().root.source
    );

    const lines = get(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdviceRootSources().despatchLine.source
    );

    delete despatchAdvice['DespatchLine'];

    // EDM Properties are extracted from UBL content
    const metadataProperties = {};
    metadataProperties[Metadata.documentId] = get(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdviceRootSources().number
    );
    metadataProperties[Metadata.issueDate] = formatDateToAlfresco(
      get(
        despatchAdvice,
        webDespatchAdviceSources.DespatchAdviceRootSources().issueDate
      )
    );

    const buyerCustomerParty = get(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdvicePartySources().buyerCustomerParty
        .source
    );

    metadataProperties[Metadata.recipientId] = get(
      buyerCustomerParty,
      webDespatchAdviceSources.PartySources().vatId
    );
    metadataProperties[Metadata.recipientName] = get(
      buyerCustomerParty,
      webDespatchAdviceSources.PartySources().name
    );

    const sellerSupplierParty = get(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdvicePartySources().sellerSupplierParty
        .source
    );

    metadataProperties[Metadata.issuerId] = get(
      sellerSupplierParty,
      webDespatchAdviceSources.PartySources().vatId
    );
    metadataProperties[Metadata.issuerName] = get(
      sellerSupplierParty,
      webDespatchAdviceSources.PartySources().name
    );

    const result = {
      id: nodeId,
      properties: metadataProperties,
      ublContent: {
        ublProperties: data.ublProperties,
        lines: lines,
      },
    };

    this.setRequiredDataIfMissing(result);

    return result;
  }

  public async load(nodeId: string): Promise<P2pUblContent> {
    const result = await this.documentService.loadDocument(
      nodeId,
      DocumentTypeCode.DESADV
    );

    const lines = result.despatchLine;
    delete result.despatchLine;
    const ublProperties = result;

    return {
      lines,
      ublProperties,
    };
  }

  async createFromOrder(nodeId: string): Promise<P2pData> {
    const result = await this.documentService.convertTo(
      nodeId,
      DocumentTypeCode.DESADV
    );
    const data = {
      id: result.id,
      properties: result.properties,
      ublProperties: result.ublContent,
      lines: [],
    };
    this.setRequiredDataIfMissing(data);
    return data;
  }

  async createFromReceiptAdvice(nodeId: string): Promise<P2pData> {
    const result = await this.documentService.convertTo(
      nodeId,
      DocumentTypeCode.DESADV
    );
    const data = {
      id: result.id,
      properties: result.properties,
      ublProperties: result.ublContent,
      lines: [],
    };
    this.setRequiredDataIfMissing(data);
    return data;
  }

  createNewContent(): AlfrescoContent {
    const data: AlfrescoContent = {
      ...this.documentService.initNewDocument(DocumentTypeCode.DESADV),
      ublContent: {
        lines: [],
        ublProperties: {
          DespatchAdvice: [{}],
        },
      },
    };

    this.setRequiredDataIfMissing(data);
    return data;
  }

  public async createNew(recipientId: string): Promise<string | undefined> {
    let despatchAdviceContent: AlfrescoContent = await this.createNewContent();
    set(despatchAdviceContent.properties, Metadata.recipientId, recipientId);

    const despatchAdviceProperties = await this.createOrUpdateDocument(
      undefined,
      despatchAdviceContent
    );
    return despatchAdviceProperties.id;
  }

  public async fetchCreationPolicy(
    resource: string,
    recipientId: string
  ): Promise<CreationPolicy> {
    return await this.companyService.fetchCreationPolicy(resource, recipientId);
  }

  private setRequiredDataIfMissing(data: any) {
    if (!get(data.properties, Metadata.documentTypeCode)) {
      set(data.properties, Metadata.documentTypeCode, DocumentTypeCode.DESADV);
    }

    set(data.properties, Metadata.readStatus, Constants.READ_STATUS_NEW);

    set(
      data.properties,
      Metadata.processDocumentFormatType,
      Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT
    );
  }

  private cleanupOrderLineReferenceNode = (line) => {
    /** TODO I'm here using the oasis UBL 2.1 Object representation which
     * should be the best choice to replace our current object representation for Despatch Advices
     **/
    const lineDetails: DespatchLineDetails = line;
    const orderLineReference: OrderLineReferenceDetails[] =
      lineDetails.OrderLineReference;
    const orderReferenceNode: OrderReferenceDetails[] =
      orderLineReference?.[0]?.OrderReference ?? [];
    if (orderReferenceNode[0]) {
      const orderId = orderReferenceNode?.[0]?.ID?.[0]?._;
      const salesOrderID = orderReferenceNode?.[0]?.SalesOrderID?.[0]?._;
      const orderDate = orderReferenceNode?.[0]?.IssueDate?.[0]?._;
      const orderTypeCode = orderReferenceNode?.[0]?.OrderTypeCode?.[0]?._;

      if (!orderDate) {
        delete orderReferenceNode[0].IssueDate;
      }
      if (!orderTypeCode) {
        delete orderReferenceNode[0].OrderTypeCode;
      }
      if (!salesOrderID) {
        delete orderReferenceNode[0].SalesOrderID;
      }
      if (!orderId && !orderDate && !orderTypeCode && !salesOrderID) {
        delete orderLineReference[0].OrderReference;
      }
    }
  };
  /**
   * Saves a despatch Advice document to alfresco
   */
  public async createOrUpdateDocument(
    nodeId: string | undefined,
    data: AlfrescoContent
  ): Promise<DocumentProperties> {
    const queryType = nodeId
      ? Constants.API_PUT_SAVE_DOCUMENT
      : Constants.API_POST_CREATE_DOCUMENT;
    const resource = documentTypeCodeToResource(DocumentTypeCode.DESADV);

    const webDespatchAdviceSources = new DespatchAdviceSources(true);

    const lines = data.ublContent.lines;
    lines.forEach((line, index) => {
      const lineId = get(
        line,
        webDespatchAdviceSources.DespatchAdviceRootSources().despatchLine.fields
          .id
      );
      if (!lineId) {
        set(
          line,
          webDespatchAdviceSources.DespatchAdviceRootSources().despatchLine
            .fields.id,
          `${index + 1}`
        );
      }
      const shipmentNodeInLine = get(
        line,
        webDespatchAdviceSources.DespatchAdviceRootSources().shipmentInformation
          .source
      );
      if (shipmentNodeInLine) {
        const shipmentId = get(
          shipmentNodeInLine,
          webDespatchAdviceSources.ShipmentInformationSources().id
        );
        if (!shipmentId) {
          set(
            shipmentNodeInLine,
            webDespatchAdviceSources.ShipmentInformationSources().id,
            '1'
          );
        }
      }

      this.cleanupOrderLineReferenceNode(line);
    });
    data.ublContent.lines = [];

    const despatchAdvice = get(
      data.ublContent,
      webDespatchAdviceSources.DespatchAdviceSources().root.source
    );

    set(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdviceRootSources().despatchLine.source,
      lines
    );

    set(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdviceRootSources().lineCountNumeric,
      lines.length
    );

    set(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdviceRootSources().number,
      data.properties[Metadata.documentId]
    );

    set(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdviceRootSources().issueDate,
      formatDate(data.properties[Metadata.issueDate])
    );

    const shipment = get(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdviceRootSources().shipmentInformation
        .source
    );
    const shipmentId = get(
      shipment,
      webDespatchAdviceSources.ShipmentInformationSources().id
    );
    if (!shipmentId) {
      set(
        shipment,
        webDespatchAdviceSources.ShipmentInformationSources().id,
        '1'
      );
    }
    shipment?.TransportHandlingUnit?.forEach((line, index) => {
      const transportHandelingUnitId = get(
        line,
        webDespatchAdviceSources.TransportHandlingUnitSources().id
      );
      if (!transportHandelingUnitId) {
        set(
          line,
          webDespatchAdviceSources.TransportHandlingUnitSources().id,
          `${index + 1}`
        );
      }
    });

    // EDM Properties are extracted from UBL content
    const metadataProperties = {};

    // Metadata : Order details
    const orderReference = get(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdviceRootSources().orderReference.source
    );
    set(
      metadataProperties,
      Metadata.orderId,
      get(orderReference, webDespatchAdviceSources.OrderReferenceSources().id)
    );
    set(
      metadataProperties,
      Metadata.orderDate,
      formatDateToAlfresco(
        get(
          orderReference,
          webDespatchAdviceSources.OrderReferenceSources().issueDate
        )
      )
    );

    const sellerSupplierParty = get(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdvicePartySources().sellerSupplierParty
        .source
    );
    set(
      metadataProperties,
      Metadata.issuerId,
      get(sellerSupplierParty, webDespatchAdviceSources.PartySources().vatId)
    );
    set(
      metadataProperties,
      Metadata.issuerName,
      get(sellerSupplierParty, webDespatchAdviceSources.PartySources().name)
    );

    const deliveryCustomerParty = get(
      despatchAdvice,
      webDespatchAdviceSources.DespatchAdvicePartySources()
        .deliveryCustomerParty.source
    );

    // Delivery location name
    set(
      metadataProperties,
      Metadata.locationName,
      get(deliveryCustomerParty, webDespatchAdviceSources.PartySources().name)
    );

    // Delivery location address
    const deliveryPostalAddress = get(
      deliveryCustomerParty,
      webDespatchAdviceSources.PartySources().postalAddress.source
    );
    const deliveryAddress: Address | undefined =
      DespatchAdviceService.buildAddressDetails(deliveryPostalAddress);
    metadataProperties[Metadata.locationAddress] =
      buildAddressAsAString(deliveryAddress);

    // Delivery location GLN
    set(
      metadataProperties,
      Metadata.gln,
      get(deliveryCustomerParty, webDespatchAdviceSources.PartySources().gln)
    );

    // Delivery date
    const delivery = get(
      shipment,
      webDespatchAdviceSources.ShipmentInformationSources().delivery.source
    );
    set(
      metadataProperties,
      Metadata.deliveryDate,
      formatDateToAlfresco(
        get(
          delivery,
          webDespatchAdviceSources.DeliverySources().actualDeliveryDate
        )
      )
    );

    const despatchAdviceData: any = {
      ublContent: {
        ...DespatchAdviceService.despatchAdviceHeader,
        ...data.ublContent.ublProperties,
      },
      properties: { ...data.properties, ...metadataProperties },
    };
    const payload = {
      data: despatchAdviceData,
      id: nodeId,
    };
    const result = await this.documentService.dataProvider[queryType](
      resource,
      payload
    );
    return result.data;
  }

  /**
   * Checks if the user has the permissions to create a document
   */
  public static canCreate(permissions: any): boolean {
    return (
      permissions &&
      checkPermissions(
        permissions,
        UserRoles.DXPURCHASE_PRODUCT,
        UserRoles.CREATE_DESPATCH_ADVICE
      )
    );
  }

  /**
   * Initializes a new line with template info
   */
  public static initNewLine(items: TemplateModelElement[] | undefined): any {
    let line: any = {};
    const webDespatchAdviceUbl = new DespatchAdviceSources(true);
    set(line, webDespatchAdviceUbl.DespatchAdviceLineSources().id, undefined);
    items?.forEach((item) => {
      if (item.defaultValue) {
        set(line, `${item.source}`, item.defaultValue);
      }
    });

    return line;
  }

  public async clone(nodeId: string): Promise<AlfrescoContent> {
    return await this.documentService.copy(nodeId, DuplicationType.COPY);
  }

  /**
   * RecipientId from Alfresco metadata based on document type
   */
  public static getRecipientId(alfrescoProperties: any): string | undefined {
    return get(alfrescoProperties, Metadata.recipientId);
  }

  /**
   * Gets or creates a document.
   * @param nodeId Alfresco node Id of the document
   * @param recipientId recipient Id of the document
   * @param locale
   * @param alfrescoMetadata - alfresco properties (edm:* fields)
   */
  public async getData(
    nodeId: string | undefined,
    recipientId: string,
    locale: string,
    alfrescoMetadata: any = {},
    newTemplate?: any
  ): Promise<WebFormData> {
    let document: AlfrescoContent;

    if (newTemplate) {
      document = this.createNewContent() as AlfrescoContent;
    } else {
      document = (await this.loadDraft(nodeId)) as AlfrescoContent;
    }

    const flattenedDocument = {
      properties: document.properties,
      ublProperties: document.ublContent.ublProperties,
      lines: document.ublContent.lines,
    };

    const completeData: P2pData = {
      id: nodeId,
      properties: alfrescoMetadata,
      lines: [],
      ublProperties: {},
    };
    const selectValues = [];

    DataHelpers.setRecipientId(flattenedDocument, recipientId);
    const recipientDetails: CompanyModel = await this.companyService.getDetails(
      recipientId
    );

    // Adds recipientName information. Retrieves it from B/E
    if (!DataHelpers.getRecipientName(flattenedDocument)) {
      DataHelpers.setRecipientName(flattenedDocument, recipientDetails.name);
    }

    const { data: hardcodedData, lists: hardcodedLists } =
      this.getHardcodedValues(TEMPLATE_TYPE);

    const documentTypeCode: DocumentTypeCode | undefined =
      DataHelpers.getDocumentTypeCode(flattenedDocument);

    if (documentTypeCode === undefined) {
      throw new Error('documentTypeCode cannot be undefined!');
    }

    const template = await this.templatesService.getTemplate(
      documentTypeCode,
      undefined,
      undefined,
      recipientId
    );

    const { defaultValues: templateData, selectValues: templateLists } =
      this.templatesService.getValues(template);

    // sets default
    this.mergeFormData(completeData, hardcodedData);
    this.mergeFormData(selectValues, hardcodedLists, true);
    this.mergeFormData(selectValues, templateLists);
    this.mergeFormData(completeData, templateData);

    // sets document data
    this.mergeFormData(completeData, flattenedDocument);

    // sets dynamic data
    // const { data, lists } = await this.fillInvoiceDocumentReference(
    //   flattenedDocument,
    //   locale
    // );
    const sellerSupplierPartyData: any = {};
    const sellerSupplierPartyLists: any = {};
    const issuerDetails: CompanyModel = await this.companyService.getDetails();
    await this.fillSellerSupplierPartyInformation(
      sellerSupplierPartyData,
      sellerSupplierPartyLists,
      locale,
      issuerDetails
    );
    this.mergeFormData(selectValues, sellerSupplierPartyLists);
    this.mergeFormData(completeData, sellerSupplierPartyData);

    const despatchSupplierPartyData: any = {};
    const despatchSupplierPartyLists: any = {};
    await this.fillDespatchSupplierPartyInformation(
      despatchSupplierPartyData,
      despatchSupplierPartyLists,
      locale,
      issuerDetails
    );
    this.mergeFormData(selectValues, despatchSupplierPartyLists);
    this.mergeFormData(completeData, despatchSupplierPartyData);

    const relations: CompanyModel[] = await this.companyService.getRelations(
      documentTypeCode
    );
    const relation = relations.filter(
      (r) => r.identification === recipientId
    )[0];
    const buyerCustomerPartyData: any = {};
    const buyerCustomerPartyLists: any = {};
    await this.fillBuyerCustomerPartyInformation(
      completeData,
      buyerCustomerPartyData,
      buyerCustomerPartyLists,
      locale,
      recipientDetails,
      relation
    );
    this.mergeFormData(selectValues, buyerCustomerPartyLists);
    this.mergeFormData(completeData, buyerCustomerPartyData);

    const deliveryCustomerPartyData: any = {};
    const deliveryCustomerPartyLists: any = {};
    const deliveryGLN: string | undefined = get(
      flattenedDocument,
      DespatchAdviceSources.WebDespatchAdviceUbl().deliveryCustomerPartyGln
    );
    await this.fillDeliveryCustomerPartyInformation(
      deliveryCustomerPartyData,
      deliveryCustomerPartyLists,
      locale,
      recipientDetails,
      deliveryGLN
    );
    this.mergeFormData(selectValues, deliveryCustomerPartyLists);
    this.mergeFormData(completeData, deliveryCustomerPartyData);

    if (
      completeData.lines.length === 0 &&
      documentTypeCode === DocumentTypeCode.DESADV
    ) {
      const newLine = DespatchAdviceService.initNewLine(
        template.model.find((field) => field.source === 'lines')?.items
      );
      completeData.lines = [newLine];
    }

    const supplierId = get(completeData.properties, Metadata.issuerId);
    const catalogLines = await this.catalogService.getCatalogLines(
      recipientId,
      supplierId
    );
    if (catalogLines) {
      const { lists: catalogLists } = await this.fillLinesSuggestionsInfo(
        catalogLines,
        'Item[0].BuyersItemIdentification[0].ID[0]._',
        'Item[0].SellersItemIdentification[0].ID[0]._',
        'Item[0].StandardItemIdentification[0].ID[0]._',
        'Item[0].Description[0]._'
      );
      this.mergeFormData(selectValues, catalogLists, true);
    }

    const documentData: P2pData = {
      id: completeData.id,
      lines: completeData.lines,
      properties: completeData.properties,
      ublProperties: completeData.ublProperties,
    };

    // Optional behavior according to template definition
    let modifiedTemplate = cloneDeep(template);

    // modifiedTemplate = this.manageDescriptionType(
    //   modifiedTemplate,
    //   documentData
    // );

    return {
      documentData,
      selectValues,
      template: modifiedTemplate,
    };
  }

  /**
   * Checks if a template exists for user's data
   */
  public async hasDesadvTemplate(
    documentRecipientId: string
  ): Promise<boolean> {
    let subTypes = await this.templatesService.getSubTypes(
      DocumentTypeCode.DESADV,
      documentRecipientId
    );

    return subTypes.length > 0;
  }

  /**
   * Saves the data as an Alfresco document
   * @param formData Formatted document data
   */
  public async saveData(formData: P2pData): Promise<DocumentProperties> {
    const data = FormDataHelpers.convertToAlfrescoContent(formData);
    return await this.createOrUpdateDocument(formData.id, data);
  }

  /**
   * Despatch Advice context
   */
  private async fillSellerSupplierPartyInformation(
    data: any,
    lists: any,
    locale: any,
    details: CompanyModel
  ) {
    const despatchAdviceUbl = DespatchAdviceSources.WebDespatchAdviceUbl();

    setSourceField(
      data,
      despatchAdviceUbl.sellerSupplierPartyVatId,
      details.identification
    );
    setSourceField(
      data,
      despatchAdviceUbl.sellerSupplierPartyName,
      details.name
    );

    setSourceField(
      data,
      despatchAdviceUbl.sellerSupplierPartyPostalAddressNumber,
      details.billingAddress?.buildingNumber
    );

    setSourceField(
      data,
      despatchAdviceUbl.sellerSupplierPartyGln,
      details.billingAddress?.gln
    );

    // HOT FIX : TO BE REVISITED ONCE WE HAVE ALL THE SPECS CLEARED
    // V2 DB Address workaround which is take into account on backend side could not fit issuer info.
    // B/E sets name = tb_address.street, street = tb_address.additionalstreet
    let street = details.billingAddress?.street;
    if (!street || street === '') {
      // Meaning that additionalstreet was empty or not defined
      // => reverts the B/E V2 DB workaround
      street = details.billingAddress?.name;
    }
    setSourceField(
      data,
      despatchAdviceUbl.sellerSupplierPartyPostalAddressStreet,
      street
    );
    setSourceField(
      data,
      despatchAdviceUbl.sellerSupplierPartyPostalAddressAddStreet,
      details.billingAddress?.additionalStreet,
      false
    );
    setSourceField(
      data,
      despatchAdviceUbl.sellerSupplierPartyPostalAddressCity,
      details.billingAddress?.city
    );
    setSourceField(
      data,
      despatchAdviceUbl.sellerSupplierPartyPostalAddressPostalCode,
      details.billingAddress?.postalCode
    );

    const countrySelectData = this.getCountriesSelectData(
      details.billingAddress?.idCountry,
      locale
    );

    setSourceField(
      // selected country code
      lists,
      despatchAdviceUbl.sellerSupplierPartyPostalAddressCountryCode,
      countrySelectData.choices
    );
    setSourceField(
      // country code selection list
      data,
      despatchAdviceUbl.sellerSupplierPartyPostalAddressCountryCode,
      countrySelectData.defaultValue
    );
  }

  /**
   * Despatch Advice context
   */
  private async fillDespatchSupplierPartyInformation(
    despatchSupplierPartyData: any,
    despatchSupplierPartyLists: any,
    locale: any,
    details: CompanyModel
  ) {
    const despatchAdviceUbl = DespatchAdviceSources.WebDespatchAdviceUbl();
    const shipmentAddressGln = details.billingAddress.gln;

    setSourceField(
      despatchSupplierPartyData,
      despatchAdviceUbl.despatchSupplierPartyVatId,
      details.identification
    );
    setSourceField(
      despatchSupplierPartyData,
      despatchAdviceUbl.despatchSupplierPartyName,
      details.name
    );

    const shippingAddressItems = details.shippingAddress?.map((l) => {
      return {
        id: l.gln,
        name: l.name,
        street: l.street === '' ? l.name : l.street,
        buildingNumber: l.buildingNumber,
        city: l.city,
        countryCode: l.idCountry,
        postalCode: l.postalCode,
        gln: l.gln,
      };
    });

    // build selection box items
    const shippingAddressSelectItems = this.createSelectData(
      shippingAddressItems,
      'id',
      'name',
      false, // apply alternative default value
      shipmentAddressGln // default value
    );

    setSourceField(
      // selected delivery address name
      despatchSupplierPartyLists,
      despatchAdviceUbl.despatchSupplierPartyName,
      shippingAddressSelectItems.choices
    );

    let shippingAddress = details.shippingAddress?.filter(
      (address) => address.gln === shipmentAddressGln
    )[0];
    if (
      !shippingAddress &&
      details.shippingAddress &&
      details.shippingAddress[0]
    ) {
      shippingAddress = details.shippingAddress[0];
    }
    if (shippingAddress) {
      setSourceField(
        despatchSupplierPartyData,
        despatchAdviceUbl.despatchSupplierPartyGln,
        shippingAddress?.gln
      );
      setSourceField(
        despatchSupplierPartyData,
        despatchAdviceUbl.despatchSupplierPartyName,
        shippingAddress?.name
      );
      setSourceField(
        despatchSupplierPartyData,
        despatchAdviceUbl.despatchSupplierPartyPostalAddressNumber,
        shippingAddress?.buildingNumber,
        false
      );
      setSourceField(
        despatchSupplierPartyData,
        despatchAdviceUbl.despatchSupplierPartyPostalAddressStreet,
        shippingAddress?.street === ''
          ? shippingAddress?.name
          : shippingAddress?.street,
        false
      );
      setSourceField(
        despatchSupplierPartyData,
        despatchAdviceUbl.despatchSupplierPartyPostalAddressCity,
        shippingAddress?.city
      );
      setSourceField(
        despatchSupplierPartyData,
        despatchAdviceUbl.despatchSupplierPartyPostalAddressPostalCode,
        shippingAddress?.postalCode,
        false
      );
      const deliveryCountrySelectData = this.getCountriesSelectData(
        shippingAddress?.idCountry,
        locale
      );

      setSourceField(
        despatchSupplierPartyLists,
        despatchAdviceUbl.despatchSupplierPartyPostalAddressCountryCode,
        deliveryCountrySelectData.choices
      );

      setSourceField(
        despatchSupplierPartyData,
        despatchAdviceUbl.despatchSupplierPartyPostalAddressCountryCode,
        deliveryCountrySelectData.defaultValue
      );
    }
  }

  /**
   * Despatch Advice context
   */
  private async fillBuyerCustomerPartyInformation(
    document: P2pData,
    buyerCustomerPartyData,
    buyerCustomerPartyLists,
    locale: any,
    details: CompanyModel,
    relation: CompanyModel
  ) {
    const despatchAdviceUbl = DespatchAdviceSources.WebDespatchAdviceUbl();

    setSourceField(
      buyerCustomerPartyData,
      despatchAdviceUbl.buyerCustomerPartyVatId,
      details.identification
    );
    setSourceField(
      buyerCustomerPartyData,
      despatchAdviceUbl.buyerCustomerPartyName,
      details.name
    );

    setSourceField(
      buyerCustomerPartyData,
      despatchAdviceUbl.buyerCustomerPartyPostalAddressNumber,
      details.billingAddress?.buildingNumber
    );

    setSourceField(
      buyerCustomerPartyData,
      despatchAdviceUbl.buyerCustomerPartyGln,
      details.billingAddress?.gln
    );

    // HOT FIX : TO BE REVISITED ONCE WE HAVE ALL THE SPECS CLEARED
    // V2 DB Address workaround which is take into account on backend side could not fit issuer info.
    // B/E sets name = tb_address.street, street = tb_address.additionalstreet
    let street = details.billingAddress?.street;
    if (!street || street === '') {
      // Meaning that additionalstreet was empty or not defined
      // => reverts the B/E V2 DB workaround
      street = details.billingAddress?.name;
    }
    setSourceField(
      buyerCustomerPartyData,
      despatchAdviceUbl.buyerCustomerPartyPostalAddressStreet,
      street
    );
    setSourceField(
      buyerCustomerPartyData,
      despatchAdviceUbl.buyerCustomerPartyPostalAddressAddStreet,
      details.billingAddress?.additionalStreet,
      false
    );
    setSourceField(
      buyerCustomerPartyData,
      despatchAdviceUbl.buyerCustomerPartyPostalAddressCity,
      details.billingAddress?.city
    );
    setSourceField(
      buyerCustomerPartyData,
      despatchAdviceUbl.buyerCustomerPartyPostalAddressPostalCode,
      details.billingAddress?.postalCode
    );

    const countrySelectData = this.getCountriesSelectData(
      details.billingAddress?.idCountry,
      locale
    );

    setSourceField(
      // selected country code
      buyerCustomerPartyLists,
      despatchAdviceUbl.buyerCustomerPartyPostalAddressCountryCode,
      countrySelectData.choices
    );
    setSourceField(
      // country code selection list
      buyerCustomerPartyData,
      despatchAdviceUbl.buyerCustomerPartyPostalAddressCountryCode,
      countrySelectData.defaultValue
    );

    // Check if the document already contains the supplier code
    const ublSupplierCode = get(
      document.ublProperties,
      'DespatchAdvice[0].SellerSupplierParty[0].CustomerAssignedAccountID[0]._'
    );
    setSourceField(
      buyerCustomerPartyData,
      despatchAdviceUbl.customerAssignedAccountId,
      ublSupplierCode ? ublSupplierCode : relation?.supplierCode
    );
  }

  /**
   * Despatch Advice context
   */
  private async fillDeliveryCustomerPartyInformation(
    deliveryCustomerPartyData,
    deliveryCustomerPartyLists,
    locale: any,
    details: CompanyModel,
    deliveryGLN: string | undefined
  ) {
    const despatchAdviceUbl = DespatchAdviceSources.WebDespatchAdviceUbl();

    // delivery address information
    const shippingAddressItems = details.shippingAddress?.map((l) => {
      return {
        id: l.gln,
        name: l.name,
        street: l.street,
        buildingNumber: l.buildingNumber,
        city: l.city,
        countryCode: l.idCountry,
        postalCode: l.postalCode,
        gln: l.gln,
      };
    });

    // build selection box items
    const shippingAddressSelectItems = this.createSelectData(
      shippingAddressItems,
      'id',
      'name',
      true, // don't apply alternative default value
      deliveryGLN // default value
    );

    setSourceField(
      // selected delivery address name
      deliveryCustomerPartyLists,
      despatchAdviceUbl.deliveryCustomerPartyName,
      shippingAddressSelectItems.choices
    );

    let shippingAddress = details.shippingAddress?.filter(
      (address) => address.gln === deliveryGLN
    )[0];

    // Builds the country list (with a possible default value)
    const deliveryCountrySelectData = this.getCountriesSelectData(
      shippingAddress?.idCountry, // if undefined => no default
      locale
    );

    setSourceField(
      deliveryCustomerPartyLists,
      despatchAdviceUbl.deliveryCustomerPartyPostalAddressCountryCode,
      deliveryCountrySelectData.choices
    );

    if (shippingAddress) {
      setSourceField(
        deliveryCustomerPartyData,
        despatchAdviceUbl.deliveryCustomerPartyGln,
        shippingAddress?.gln
      );
      setSourceField(
        deliveryCustomerPartyData,
        despatchAdviceUbl.deliveryCustomerPartyName,
        shippingAddress?.name
      );
      setSourceField(
        deliveryCustomerPartyData,
        despatchAdviceUbl.deliveryCustomerPartyPostalAddressNumber,
        shippingAddress?.buildingNumber,
        false
      );
      setSourceField(
        deliveryCustomerPartyData,
        despatchAdviceUbl.deliveryCustomerPartyPostalAddressStreet,
        shippingAddress?.street,
        false
      );
      setSourceField(
        deliveryCustomerPartyData,
        despatchAdviceUbl.deliveryCustomerPartyPostalAddressCity,
        shippingAddress?.city
      );
      setSourceField(
        deliveryCustomerPartyData,
        despatchAdviceUbl.deliveryCustomerPartyPostalAddressPostalCode,
        shippingAddress?.postalCode,
        false
      );

      setSourceField(
        deliveryCustomerPartyData,
        despatchAdviceUbl.deliveryCustomerPartyPostalAddressCountryCode,
        deliveryCountrySelectData.defaultValue
      );
    }
  }

  /**
   * Build an Address object to use the same method as the one used by OrderPreview
   * */
  public static buildAddressDetails = (
    ublAddress: any
  ): Address | undefined => {
    if (ublAddress === undefined) {
      return undefined;
    }

    const address: Address = { id: '' };

    const buildingNumber = get(ublAddress, 'BuildingNumber[0]._');
    if (buildingNumber !== undefined) {
      address.buildingNumber = { value: buildingNumber };
    }
    const cityName = get(ublAddress, 'CityName[0]._');
    if (cityName !== undefined) {
      address.cityName = { value: cityName };
    }
    const country = get(ublAddress, 'Country[0].IdentificationCode[0]._');
    if (buildingNumber !== undefined) {
      address.country = {
        identificationCode: { value: country },
        name: { value: undefined },
      };
    }
    const postalZone = get(ublAddress, 'PostalZone[0]._');
    if (postalZone !== undefined) {
      address.postalZone = { value: postalZone };
    }
    const streetName = get(ublAddress, 'StreetName[0]._');
    if (buildingNumber !== undefined) {
      address.streetName = { value: streetName };
    }
    const additionalStreetName = get(ublAddress, 'AdditionalStreetName[0]._');
    if (additionalStreetName !== undefined) {
      address.additionalStreetName = { value: additionalStreetName };
    }

    return address;
  };
}
