import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { DayPickerRangeController } from 'react-dates';
import { START_DATE } from 'react-dates/constants';
import 'react-dates/initialize'; // necessary for compatiblity as react-dates relies on react-with-styles
import 'react-dates/lib/css/_datepicker.css'; // necessary for compatiblity as react-dates relies on react-with-styles
import Draggable from 'react-draggable';
import { Metadata } from '../../constants';
import { DxTheme } from '../../types';
import { formatDate, formatDateToAlfrescoTZ } from '../../utils';
import NestingMenuItem from '../menus/NestingMenuItem';
import { resolveDateRange } from '../predefinedDateRange';
import useInput from './useInput';

const defaultMenuItems = [
  {
    id: null,
    label: 'dxMessages.filter.date.allTime',
  },
  {
    id: 'today',
    label: 'dxMessages.filter.date.today',
  },
  {
    id: 'yesterday',
    label: 'dxMessages.filter.date.yesterday',
  },
  {
    id: 'this-week',
    label: 'dxMessages.filter.date.thisWeek',
  },
  {
    id: 'last-7-days',
    label: 'dxMessages.filter.date.last7days',
  },
  {
    id: 'last-week',
    label: 'dxMessages.filter.date.lastWeek',
  },
  {
    id: 'last-14-days',
    label: 'dxMessages.filter.date.last14days',
  },
  {
    id: 'this-month',
    label: 'dxMessages.filter.date.thisMonth',
  },
  {
    id: 'last-month',
    label: 'dxMessages.filter.date.lastMonth',
  },
  {
    id: 'last-30-days',
    label: 'dxMessages.filter.date.last30days',
  },
];

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    container: {
      marginBottom: 8,
      minWidth: 200,
    },
    rangeDatePicker: {
      // NOTE: the order of these styles DO matter

      // Will edit everything selected including everything between a range of dates
      '& .CalendarDay__selected_span': {
        background: theme.colors.blue[200], //background
        color: theme.colors.white, //text
        border: '1px solid ' + theme.colors.blue[100], //default styles include a border
      },

      // Will edit selected date or the endpoints of a range of dates
      '& .CalendarDay__selected': {
        background: theme.colors.darkBlue,
        color: theme.colors.white,
      },

      // Will edit when hovered over. _span style also has this property
      '& .CalendarDay__selected:hover': {
        background: theme.colors.darkBlue,
        color: theme.colors.white,
      },

      // Will edit when the second date (end date) in a range of dates
      // is not yet selected. Edits the dates between your mouse and said date
      '& .CalendarDay__hovered_span:hover, & .CalendarDay__hovered_span': {
        background: theme.colors.blue[50],
        border: '1px solid ' + theme.colors.white,
      },
    },
    button: {
      margin: 4,
    },
    cancelButton: {
      margin: 4,
      backgroundColor: theme.palette.secondary.light,
      color: theme.colors.mainColor4,
      '&:hover': {
        backgroundColor: theme.colors.blue[100],
      },
    },
  }),
  { name: 'DateInput' }
);

const PAST_LIMIT = '1900-01-01';
const FUTURE_LIMIT = '2050-01-01';

const PaperComponent = (props) => {
  const classes = useStyles();
  return (
    <Draggable
      handle='#draggable-datePicker-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} className={classes.container} />
    </Draggable>
  );
};

const DateInput = (props) => {
  const {
    label,
    source,
    resource,
    readOnly,
    disabled,
    menuItems = defaultMenuItems,
    className,
  } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const { input, isRequired } = useInput(props);

  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [startDate, setStartDate] = useState<moment.Moment | undefined>(
    undefined
  );
  const [endDate, setEndDate] = useState<moment.Moment | undefined>(undefined);

  const [previousStartDate, setPreviousStartDate] = useState<
    moment.Moment | undefined
  >(undefined);
  const [previousEndDate, setPreviousEndDate] = useState<
    moment.Moment | undefined
  >(undefined);

  const [open, setOpen] = useState(false);

  // startRange, enndRange: set the picker to the searched values.
  const [startRange, setStartRange] = useState<moment.Moment | undefined>(
    undefined
  );
  const [endRange, setEndRange] = useState<moment.Moment | undefined>(
    undefined
  );
  const [disable, setDisable] = useState<boolean>(false);
  const [invalidRange, setInvalidRange] = useState<boolean>(false);

  const updateDates = useCallback(
    (start: any, end: any) => {
      if (source === Metadata.created || source === Metadata.modified) {
        input.onChange({
          from: formatDateToAlfrescoTZ(start),
          to: formatDateToAlfrescoTZ(end),
        });
      } else {
        input.onChange({
          from: formatDate(start),
          to: formatDate(end),
        });
      }
    },
    [input, source]
  );

  // Selection of a predefined choice
  const handleChange = (evt) => {
    const { value } = evt.target;
    let range: {
      from: string | undefined;
      to: string | undefined;
    } | null = null;
    let newStartDate: moment.Moment | undefined;
    let newEndDate: moment.Moment | undefined;

    if (value) {
      const selectedRangeSelector = { predefinedRange: value };
      const dateRange = resolveDateRange(selectedRangeSelector);

      newStartDate = moment(dateRange?.from);
      newEndDate = moment(dateRange?.to).endOf('day');

      if (source === Metadata.created || source === Metadata.modified) {
        range = {
          from: formatDateToAlfrescoTZ(newStartDate),
          to: formatDateToAlfrescoTZ(newEndDate),
        };
      } else {
        range = {
          from: formatDate(newStartDate),
          to: formatDate(newEndDate),
        };
      }
    }

    setStartRange(newStartDate);
    setEndRange(newEndDate);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setFocusedInput(START_DATE);

    if (!previousStartDate && !previousEndDate && !range) {
      // Reset on already empty input
      return;
    }

    if (input.onChange) {
      input.onChange(range);
    }
  };

  /**
   * Selection of a start Date (and eventually an end Date) with the Date Picker
   */
  const handleDatesChange = ({ startDate, endDate }) => {
    if (startDate) {
      const endDateOrEndDay = endDate || startDate;
      const newEndDate = moment(endDateOrEndDay).endOf('day');
      const newStartDate = moment(startDate).startOf('day');
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    setStartRange(undefined);
    setEndRange(undefined);
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput || START_DATE);
  };

  const preventDefault = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleClose = () => {
    setOpen(false);
    setDisable(false);
  };

  const handleConfirm = () => {
    if (
      !startDate?.isSame(previousStartDate) ||
      !endDate?.isSame(previousEndDate)
    ) {
      updateDates(startDate, endDate);
    }
    setOpen(false);
    setDisable(false);
  };

  const handleCancel = () => {
    setStartRange(previousStartDate);
    setEndRange(previousEndDate);
    setPreviousStartDate(undefined);
    setPreviousEndDate(undefined);
    setOpen(false);
    setDisable(false);
  };

  const handleOpen = () => {
    let startDate: moment.Moment | undefined = undefined;
    let endDate: moment.Moment | undefined = undefined;
    let focusedInput: any = START_DATE;

    startDate = input.value?.from
      ? moment(input.value.from).startOf('day')
      : undefined;
    endDate = input.value?.to ? moment(input.value.to).endOf('day') : undefined;

    setFocusedInput(focusedInput);
    setStartDate(startDate);
    setEndDate(endDate);
    setStartRange(startDate);
    setEndRange(endDate);

    setPreviousStartDate(startDate);
    setPreviousEndDate(endDate);

    setOpen(true);
  };
  /**
   * Displays the selected date (or date range) in the read-only input field of the select Menu.
   */
  const selectionRenderer = () => {
    if (!input.value) {
      return '';
    }

    if (!input.value?.to && !input.value?.from) {
      return '';
    }

    const range = resolveDateRange(input.value);

    const from = moment(range?.from);
    const to = moment(range?.to);

    if (from.isSame(to, 'day')) {
      return from.format('l');
    }

    return `${from.format('l')} – ${to.format('l')}`;
  };

  const handleChangeTextStart = (event) => {
    const value = event.target.value;

    if (value.length > 0 && value.length <= 10) {
      setStartRange(value);
    }
  };

  const handleChangeTextEnd = (event) => {
    const value = event.target.value;

    if (value.length > 0 && value.length <= 10) {
      setEndRange(value);
    }
  };

  // From and To date input fields change management
  useEffect(() => {
    const searchRange = () => {
      setInvalidRange(false);
      const momentStartRange =
        startRange !== undefined
          ? moment(startRange)
          : startDate
          ? startDate
          : undefined;
      const momentEndRange =
        endRange !== undefined
          ? moment(endRange)
          : endDate
          ? endDate
          : undefined;
      if (momentStartRange?.isValid() && momentEndRange?.isValid()) {
        if (
          !momentStartRange.isBetween(
            moment(PAST_LIMIT),
            moment(FUTURE_LIMIT)
          ) ||
          !momentEndRange.isBetween(moment(PAST_LIMIT), moment(FUTURE_LIMIT))
        ) {
          // out of range dates
          setInvalidRange(true);
          return;
        }
        if (
          startDate?.isSame(momentStartRange, 'day') &&
          endDate?.isSame(momentEndRange, 'day')
        ) {
          // no effective change
          return;
        }
        if (momentStartRange?.isAfter(momentEndRange)) {
          // invalid range
          setInvalidRange(true);
          return;
        }
        setStartDate(momentStartRange);
        setEndDate(momentEndRange);
      } else if (momentStartRange?.isValid() && !momentEndRange?.isValid()) {
        if (
          !momentStartRange.isBetween(moment(PAST_LIMIT), moment(FUTURE_LIMIT))
        ) {
          // out of range date
          setInvalidRange(true);
          return;
        }
        const newEndDate = moment(momentStartRange).endOf('day');
        const newStartDate = moment(momentStartRange).startOf('day');
        if (startDate?.isSame(newStartDate, 'day')) {
          // no effective change
          return;
        }

        setStartDate(momentStartRange);
        if (!endDate) {
          // Sets the end date in order to anticipate
          // a single date input. But do that only if
          // the To input field is empty
          setEndDate(newEndDate);
        }
      } else if (!momentStartRange?.isValid() && momentEndRange?.isValid()) {
        const momentStartDate =
          startDate !== undefined ? moment(startDate) : undefined;
        if (!momentStartDate?.isValid()) {
          // invalid dates
          setInvalidRange(true);
          return;
        }
        if (momentStartDate.isAfter(momentEndRange)) {
          // invalid range
          setInvalidRange(true);
          return;
        }
        if (
          !momentEndRange.isBetween(moment(PAST_LIMIT), moment(FUTURE_LIMIT))
        ) {
          // out of range date
          setInvalidRange(true);
          return;
        }
        if (momentEndRange.isSame(endDate, 'day')) {
          // no effective change
          return;
        }

        setStartDate(momentStartDate);
        setEndDate(momentEndRange);
      }
    };
    searchRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endRange, startRange]);

  const handleMouseDown = (event) => {
    // Prevent the default calendar picker behavior
    event.preventDefault();
  };
  const locale = useLocale();
  moment.locale(locale);

  return (
    <FormControl
      required={isRequired}
      className={classNames(classes.container, className)}
    >
      {!!label && (
        <InputLabel variant='filled' margin='dense'>
          {translate(label, { _: label })}
        </InputLabel>
      )}
      <Select
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={input.value ? input.value.predefinedRange || 'range' : ''}
        onChange={handleChange}
        renderValue={selectionRenderer}
        readOnly={readOnly}
        resource={resource}
        disabled={disabled}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          getContentAnchorEl: null,
        }}
      >
        <NestingMenuItem
          onClose={handleClose}
          value='range'
          noAccessToMenu={true}
          menuItems={[
            <Dialog
              open={open}
              PaperComponent={PaperComponent}
              aria-labelledby='draggable-datePicker-title'
              key={'draggable-datePicker'}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  marginTop: '10px',
                  marginRight: '5px',
                }}
              >
                <DialogTitle
                  style={{ cursor: 'move' }}
                  id='draggable-datePicker-title'
                >
                  {translate('dxMessages.filter.date.datePicker', {
                    _: 'Pick dates',
                  })}
                </DialogTitle>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'baseline',
                    marginRight: '1em',
                    gap: '10px',
                  }}
                >
                  <TextField
                    label={translate('dxMessages.filter.dateRange.from')}
                    type='date'
                    onChange={handleChangeTextStart}
                    onClick={() => setDisable(true)}
                    value={
                      startRange === undefined
                        ? startDate === undefined
                          ? undefined
                          : startDate !== undefined
                          ? moment(startDate).format('YYYY-MM-DD')
                          : undefined
                        : moment(startRange).format('YYYY-MM-DD')
                    }
                    InputProps={{
                      readOnly: false,
                      onClick: handleMouseDown,
                      id: 'draggable-datePicker-from',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label={translate('dxMessages.filter.dateRange.to')}
                    type='date'
                    onChange={handleChangeTextEnd}
                    onClick={() => setDisable(true)}
                    value={
                      endRange === undefined
                        ? endDate === undefined
                          ? undefined
                          : endDate !== undefined
                          ? moment(endDate).format('YYYY-MM-DD')
                          : undefined
                        : moment(endRange).format('YYYY-MM-DD')
                    }
                    InputProps={{
                      readOnly: false,
                      onClick: handleMouseDown,
                      id: 'draggable-datePicker-to',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <DialogContent>
                <div style={{ outline: 'none' }} key={`DayPicker_${source}`}>
                  <div
                    className={classNames(classes.rangeDatePicker)}
                    onClick={preventDefault}
                  >
                    <DayPickerRangeController
                      startDate={startDate}
                      endDate={endDate}
                      onDatesChange={handleDatesChange}
                      focusedInput={focusedInput}
                      onFocusChange={handleFocusChange}
                      numberOfMonths={2}
                      minimumNights={0}
                      hideKeyboardShortcutsPanel
                    />
                  </div>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    style={{ marginTop: '1em', height: '4em' }}
                  >
                    <Grid item xs={8}>
                      {invalidRange && (
                        <Alert severity='error'>
                          {translate(
                            'dxMessages.filter.dateRange.invalidRange',
                            { _: 'Invalid date range' }
                          )}
                        </Alert>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={handleCancel}
                        classes={{ root: classes.cancelButton }}
                        className='cancel-date-input'
                      >
                        {translate('ra.action.cancel')}
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={handleConfirm}
                        disabled={(!startDate && !endDate) || invalidRange}
                        classes={{ root: classes.button }}
                        className='confirm-date-input'
                      >
                        {translate('ra.action.confirm')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </DialogContent>
            </Dialog>,
          ]}
        >
          {translate('dxMessages.filter.date.datePicker', {
            _: 'Pick dates',
          })}
        </NestingMenuItem>
        {menuItems.map(({ id, label }) => (
          <MenuItem key={id} value={id} disabled={disable}>
            {translate(label, { _: label })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

DateInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
};

DateInput.defaultProps = {
  options: {},
};

export default DateInput;
