export interface IAddressSources {
  street: string;
  addStreet: string;
  number: string;
  city: string;
  postalCode: string;
  countrySubEntityCode: string;
  countryCode: string;
}
export interface IDocumentReferenceSources {
  uuid: string;
  number: string;
  issueDate: string;
  documentTypeCode: string;
}
export interface IContactSources {
  contactName: string;
  phone: string;
  email: string;
  teleFax: string;
}
export interface ILocationSources {
  name: string;
  gln: string;
  address: {
    source: string;
    fields: IAddressSources;
  };
}
export interface IPartySources {
  vatId: string;
  name: string;
  gln: string;
  contact: {
    source: string;
    fields: IContactSources;
  };
  postalAddress: {
    source: string;
    fields: IAddressSources;
  };
}

export interface IAmountSources {
  currency: string;
  value: string;
}

export interface IDeliverySources {
  id: string;
  trackingID: string;
  actualDeliveryDate: string;
  actualDeliveryTime: string;
  requestedDeliveryPeriodStartDate: string;
  requestedDeliveryPeriodStartTime: string;
  estimatedDeliveryPeriodStartDate: string;
  estimatedDeliveryPeriodStartTime: string;
}

export interface IShipmentStageSources {
  id: string;
  transportModeCode: string;
  transportMeansTypeCode: string;
  transportMeansTypeCodeListId: string;
  carrierParty: {
    source: string;
    fields: IPartySources;
  };
}

export interface IPackageSources {
  quantity: string;
  quantityUnitCode: string;
  returnableMaterialIndicator: string;
  packageLevelCode: string;
  packagingTypeCode: string;
}
export interface ITransportHandlingUnitSources {
  id: string;
  transportHandlingUnitTypeCode: string;
  shippingMarks: string;
  package: {
    source: string;
    fields: IPackageSources;
  };
}

export interface ILineItemSources {
  buyersItemIdentification: string;
  description: string;
  sellersItemIdentification: string;
  standardItemIdentification: string;
}

export interface IOrderReferenceSources {
  id: string;
  issueDate: string;
  orderTypeCode: string;
  salesOrderId: string;
}
export interface IUBLCommonSources {
  DocumentReferenceSources(): IDocumentReferenceSources;
  AmountSources(): IAmountSources;
  ContactSources(): IContactSources;
  AddressSources(): IAddressSources;
  LocationSources(): ILocationSources;
  PartySources(): IPartySources;
  DeliverySources(): IDeliverySources;
  ShipmentStageSources(): IShipmentStageSources;
  TransportHandlingUnitSources(): ITransportHandlingUnitSources;
  PackageSources(): IPackageSources;
  LineItemSources(): ILineItemSources;
  OrderReferenceSources(): IOrderReferenceSources;
}
class UblCommonSources implements IUBLCommonSources {
  constructor(public web: boolean) {}
  public DocumentReferenceSources = (): IDocumentReferenceSources => {
    return {
      uuid: this.web ? 'UUID[0]._' : 'UUID.value',
      number: this.web ? 'ID[0]._' : 'id.value',
      issueDate: this.web ? 'IssueDate[0]._' : 'issueDate.value',
      documentTypeCode: this.web
        ? 'DocumentTypeCode[0]._'
        : 'documentTypeCode.value',
    };
  };

  public AmountSources = () => {
    return {
      currency: this.web ? 'AmountCurrencyIdentifier' : 'currencyID',
      value: this.web ? '_' : 'value',
    };
  };

  public ContactSources = (): IContactSources => {
    return {
      contactName: this.web ? 'Name[0]._' : 'name.value',
      phone: this.web ? 'Telephone[0]._' : 'telephone.value',
      email: this.web ? 'ElectronicMail[0]._' : 'electronicMail.value',
      teleFax: this.web ? 'Telefax[0]._' : 'telefax.value',
    };
  };

  public AddressSources = (): IAddressSources => {
    return {
      street: this.web ? 'StreetName[0]._' : 'streetName.value',
      addStreet: this.web
        ? 'AdditionalStreetName[0]._'
        : 'additionalStreetName.value',
      number: this.web ? 'BuildingNumber[0]._' : 'buildingNumber.value',
      city: this.web ? 'CityName[0]._' : 'cityName.value',
      postalCode: this.web ? 'PostalZone[0]._' : 'postalZone.value',
      countrySubEntityCode: this.web
        ? 'CountrySubentityCode[0]._'
        : 'countrySubentityCode.value',
      countryCode: this.web
        ? 'Country[0].IdentificationCode[0]._'
        : 'country.identificationCode.value',
    };
  };

  public LocationSources = (): ILocationSources => {
    return {
      name: this.web ? 'Name[0]._' : 'name.value',
      gln: this.web ? 'ID[0]._' : 'id.value',
      address: {
        source: this.web ? 'Address[0]' : 'address',
        fields: this.AddressSources(),
      },
    };
  };

  public PartySources = (): IPartySources => {
    return {
      vatId: this.web
        ? 'PartyIdentification[0].ID[0]._'
        : 'partyIdentification[0].id.value',
      name: this.web ? 'PartyName[0].Name[0]._' : 'partyName[0].name.value',
      contact: {
        source: this.web ? 'Contact[0]' : 'contact',
        fields: this.ContactSources(),
      },
      gln: this.web ? 'EndpointID[0]._' : 'endpointID.value',
      postalAddress: {
        source: this.web ? 'PostalAddress[0]' : 'postalAddress',
        fields: this.AddressSources(),
      },
    };
  };

  public DeliverySources = (): IDeliverySources => {
    return {
      id: this.web ? 'ID[0]._' : 'ID.value',
      trackingID: this.web ? 'TrackingID[0]._' : 'trackingID.value',
      actualDeliveryDate: this.web
        ? 'ActualDeliveryDate[0]._'
        : 'actualDeliveryDate.value',
      actualDeliveryTime: this.web
        ? 'ActualDeliveryTime[0]._'
        : 'actualDeliveryTime.value',
      requestedDeliveryPeriodStartDate: this.web
        ? 'RequestedDeliveryPeriod[0].StartDate[0]._'
        : 'requestedDeliveryPeriod.startDate.value',
      requestedDeliveryPeriodStartTime: this.web
        ? 'RequestedDeliveryPeriod[0].StartTime[0]._'
        : 'requestedDeliveryPeriod.startTime.value',
      estimatedDeliveryPeriodStartDate: this.web
        ? 'EstimatedDeliveryPeriod[0].StartDate[0]._'
        : 'estimatedDeliveryPeriod.startDate.value',
      estimatedDeliveryPeriodStartTime: this.web
        ? 'EstimatedDeliveryPeriod[0].StartTime[0]._'
        : 'estimatedDeliveryPeriod.startTime.value',
    };
  };

  public LineItemSources = (): ILineItemSources => {
    return {
      buyersItemIdentification: this.web
        ? 'BuyersItemIdentification[0].ID[0]._'
        : 'buyersItemIdentification.id.value',
      standardItemIdentification: this.web
        ? 'StandardItemIdentification[0].ID[0]._'
        : 'standardItemIdentification.id.value',
      description: this.web ? 'Description[0]._' : 'description[0].value',
      sellersItemIdentification: this.web
        ? 'SellersItemIdentification[0].ID[0]._'
        : 'sellersItemIdentification.id.value',
    };
  };

  public ShipmentStageSources = (): IShipmentStageSources => {
    return {
      id: this.web ? 'ID[0]._' : 'id.value',
      carrierParty: {
        source: this.web ? 'CarrierParty[0]' : 'carrierParty',
        fields: this.PartySources(),
      },
      transportMeansTypeCode: this.web
        ? 'TransportMeansTypeCode[0]._'
        : 'transportMeansTypeCode.value',
      transportMeansTypeCodeListId: this.web
        ? 'TransportMeansTypeCode[0].listID'
        : 'transportMeansTypeCode.listID',
      transportModeCode: this.web
        ? 'TransportModeCode[0]._'
        : 'transportModeCode.value',
    };
  };

  public PackageSources = (): IPackageSources => {
    return {
      packageLevelCode: this.web
        ? 'PackageLevelCode[0]._'
        : 'packageLevelCode.value',
      packagingTypeCode: this.web
        ? 'PackagingTypeCode[0]._'
        : 'packagingTypeCode.value',
      quantity: this.web ? 'Quantity[0]._' : 'quantity.value',
      quantityUnitCode: this.web ? 'Quantity[0].unitCode' : 'quantity.unitCode',
      returnableMaterialIndicator: this.web
        ? 'ReturnableMaterialIndicator[0]._'
        : 'returnableMaterialIndicator.value',
    };
  };

  public TransportHandlingUnitSources = (): ITransportHandlingUnitSources => {
    return {
      id: this.web ? 'ID[0]._' : 'id.value',
      transportHandlingUnitTypeCode: this.web
        ? 'TransportHandlingUnitTypeCode[0]._'
        : 'transportHandlingUnitTypeCode.value',
      shippingMarks: this.web ? 'ShippingMarks[0]._' : 'shippingMarks.value',
      package: {
        source: this.web ? 'Package[0]' : 'package',
        fields: this.PackageSources(),
      },
    };
  };
  public OrderReferenceSources = (): IOrderReferenceSources => {
    return {
      id: this.web ? 'ID[0]._' : 'id.value',
      issueDate: this.web ? 'IssueDate[0]._' : 'issueDate.value',
      orderTypeCode: this.web ? 'OrderTypeCode[0]._' : 'orderTypeCode.value',
      salesOrderId: this.web ? 'SalesOrderID[0]._' : 'salesOrderID.value',
    };
  };
}

export default UblCommonSources;
