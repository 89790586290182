import ReactGA from 'react-ga4';

// https://www.optimizesmart.com/event-tracking-guide-google-analytics-simplified-version/#a3a

export const SITE_SPEED_SAMPLE_RATE = 100;

export const GA_EVENTS = {
  categories: {
    AUDIT_TRAIL: {
      name: 'audit trail',
      actions: {
        SHOW: 'show',
      },
    },
    LIST: {
      name: 'list screen',
      actions: {
        MORE_FILTERS: 'more filters',
        SAVE_QUERY: 'save query',
        LOAD_SAVED_QUERY: 'click saved query',
        EXPORT_VISIBLE_COLUMNS: 'export visible columns',
        EXPORT_ALL_COLUMNS: 'export all columns',
        CHOOSE_COLUMNS: 'choose columns',
        FILTERS_RESET: 'reset filters',
      },
    },
    LIST_CONTEXT_MENU: {
      name: 'list contextual menu',
      actions: {
        DOWNLOAD: 'download',
        EDIT: 'edit document',
        DELETE: 'delete document',
      },
    },
    RELATED_DOCUMENTS: {
      name: 'related documents',
      actions: {
        UPLOAD_ATTACHMENTS: 'upload files',
        DELETE_ATTACHMENT: 'delete attachment',
      },
    },
    SHOW_METADATA: {
      name: 'show metadata',
      actions: {
        SHOW: 'show',
      },
    },
    DOWNLOAD_DATA: {
      name: 'download data',
      actions: {
        DOWNLOAD: 'download',
        BULK_DOWNLOAD: 'bulk download',
      },
    },
    SAVED_QUERIES: {
      name: 'saved queries',
      actions: {
        SAVE: 'save',
        SELECT: 'select',
        DELETE: 'delete',
      },
    },
    HELP_MENU: {
      name: 'Help menu',
      actions: {
        OPEN_USERGUIDE: 'open user guide',
      },
    },
    DASHBOARD: {
      name: 'Dashboard',
      actions: {
        CONFIGURE: 'open widgets configuration',
        DRAG_WIDGET: 'drag widget',
        LIST_DOCUMENTS: 'list documents',
        STATUS_BY_COMPANY: 'status by company',
        CHART_TABLE_VIEW: 'chart table view',
        CONFIGURE_HOMEPAGE: 'open widgets configuration (homepage)',
        MESSAGE_MORE_DETAILS: 'message more details',
        SAVE_QUERY_CLICKED: 'saved query clicked',
        SHOW_DOCUMENT_PREVIEW: 'show document preview',
      },
    },
    USER_MGMT: {
      name: 'User management',
      actions: {
        DELETE_USER: 'delete user',
        CHANGE_PASSWWORD: 'change password',
      },
    },
    DROPDOWN_DEF_MGMT: {
      name: 'Dropdown definition management',
      actions: {
        DELETE_DROPDOWNDEF: 'delete dropdown definition element',
      },
    },
    PRODUCT_USAGE_MGMT: {
      name: 'Product usage management',
      actions: {
        DELETE_PRODUCT_USAGE: 'delete product usage element',
      },
    },
    FORMAT_TYPE_MGMT: {
      name: 'Format type management',
      actions: {
        DELETE_FORMATTYPE: 'delete format type definition element',
      },
    },
    DX_PRODUCT_MGMT: {
      name: 'Dx product management',
      actions: {
        DELETE_DXPRODUCT: 'delete dx product element',
      },
    },
    ACCOUNT_RELATION_MGMT: {
      name: 'Account relation management',
      actions: {
        DELETE_ACCOUNTRELATION: 'delete an account relation',
      },
    },
    SEARCH: {
      name: 'search',
      actions: {
        SEARCH: 'search',
      },
    },
  },
};

export function sendGAEvent(
  category: string,
  action: string,
  company_uuid?: string,
  label?: string
) {
  ReactGA.gtag('event', action, {
    event_category: category,
    event_company_uuid: company_uuid,
    event_label: label,
  });
}
