import {
  Constants,
  DocumentTypeCode,
  Metadata,
  Ubl as P2pUbl,
  RegulatorExtraDetailsType,
  getBspId,
  replaceAll,
} from '@dx-ui/dx-common';
import _ from 'lodash';
import omit from 'lodash/omit';
import { DataProvider } from 'react-admin';
import DespatchAdviceSources from '../modules/despatchAdvice/DespatchAdviceSources';
import WaybillSources from '../modules/waybill/WayBillSources';
import {
  AlfrescoProperties,
  Template,
  TemplateModelElement,
  UblProperties,
} from '../shared/types';
import { FieldTypes } from '../shared/webForms/fields/FieldTypes';
import { getSourceField } from '../shared/webForms/utils';

export interface TemplateDefaultValues
  extends UblProperties,
    AlfrescoProperties {}

/**
 * Provides operations for dealing with web form templates .
 */
export class TemplatesService {
  constructor(public dataProvider: DataProvider) {}

  public static ERROR_GET_METADATA =
    'Unable to get template metadata from DxRegistry';

  /**
   * template parsing, translating string w/ constants to constants values.
   * Uses the technic which works on flat object : JSON.stringify + replace + JSON.parse.
   */
  public static transformTemplate = (template: Template, newKeys?: any) => {
    let templateAsString = JSON.stringify(omit(template, 'metadata'));
    Object.keys(FieldTypes).forEach((type) => {
      const searchString = `"FieldTypes.${type}"`;
      templateAsString = replaceAll(
        templateAsString,
        searchString,
        `"${FieldTypes[type]}"`
      );
    });
    Object.keys(Metadata).forEach((metadata) => {
      const searchString = `"Metadata.${metadata}"`;
      templateAsString = replaceAll(
        templateAsString,
        searchString,
        `"${Metadata[metadata]}"`
      );
    });
    if (newKeys) {
      Object.keys(newKeys).forEach((ubl) => {
        const searchString = `"Ubl.${ubl}"`;
        templateAsString = replaceAll(
          templateAsString,
          searchString,
          `"${newKeys[ubl]}"`
        );
      });
    }

    return JSON.parse(templateAsString);
  };

  /**
   * Gets the templates.
   * Default UI WEB form templates are accessible under the following directories :
   * 1) despatch advices : src/modules/ui/packages/dx-portal/src/templates/DESADV/<recipientID>/standard/
   * 2) invoice : src/modules/ui/packages/dx-portal/src/templates/INVOIC/<recipientID>/<documentSubType>/
   * 3) receipt advices : src/modules/ui/packages/dx-portal/src/templates/RECADV/<recipientID>/standard/
   * where recipientID default value is 'default', and documentSubType default value is 'FMF'
   * A valid recipientID can be edm:recipientId value : ex: RO123456
   *
   * FYI Backend API to get template is :
   *  src/modules/backend/docxchange/src/main/java/com/docprocess/dxportal/presentation/controller/DxPurchase.java
   * Method used to fetch web template base on recipient document type and subdocument type.
   *

   * @param documentType    document type (INVOIC,DESADV,RECADV....)
   * @param documentSubType document class (FMF,FCG...) @optional
   * @param documentCategoryCode ex: 380, 381, 384 @optional
   * @param templateOwnerId     template owner fiscal code
   * @return web form template
   *
   * @GetMapping("/webformtemplate/{bspId}/{recipientId}/{documentType}/{documentSubtype}")
   */
  async getTemplate(
    documentType: string,
    documentSubType: string | undefined,
    documentCategoryCode: string | undefined,
    templateOwnerId: string
  ): Promise<Template> {
    const bspId = getBspId(); //ex: docprocess

    const payload = {
      bspId: bspId,
      recipientId: templateOwnerId,
      documentType,
      documentSubType,
      documentCategoryCode,
    };

    try {
      const response = await this.dataProvider[Constants.API_GET_TEMPLATE](
        Constants.RESOURCE_WEBFORMTEMPLATE, // resource
        payload
      );

      let formTemplate: Template = response.data;

      if (process.env.NODE_ENV === 'development') {
        try {
          // in developement use the template file directly - it allows changing dynamically
          formTemplate = await this.loadTemplateFromFile(
            documentType,
            documentSubType,
            documentCategoryCode,
            templateOwnerId
          );
        } catch {}
      }

      if (documentType === DocumentTypeCode.WAYBIL) {
        formTemplate = await TemplatesService.transformTemplate(
          formTemplate,
          WaybillSources.WebWaybillUbl()
        );
      } else if (documentType === DocumentTypeCode.DESADV) {
        formTemplate = await TemplatesService.transformTemplate(
          formTemplate,
          DespatchAdviceSources.WebDespatchAdviceUbl()
        );
      } else if (documentType === DocumentTypeCode.RECADV) {
        formTemplate = await TemplatesService.transformTemplate(formTemplate);
      } else {
        formTemplate = await TemplatesService.transformTemplate(
          formTemplate,
          P2pUbl
        );
      }

      this.changeFieldSources(formTemplate);

      return formTemplate;
    } catch (e) {
      throw new Error(
        `Unable to load or parse template.\nContext: ${JSON.stringify(
          payload
        )}.\nError: ${e}`
      );
    }
  }

  /**
   * Used in dev only - NOT PRODUCTION -
   * It allows recharging dynamically the template on any template modification
   * form-templates folder created with:
   *  ln -s ../../../../../backend/docxchange/src/main/resources/webformtemplate/ form-templates
   */
  private async loadTemplateFromFile(
    documentType: string,
    documentSubType: string | undefined,
    documentCategoryCode: string | undefined,
    templateOwnerId: string
  ): Promise<Template> {
    const documentCategoryCodeParam = documentCategoryCode
      ? `_${documentCategoryCode}`
      : '';
    const documentSubTypeParam = documentSubType ? `_${documentSubType}` : '';
    const fileName = `${templateOwnerId}_${documentType}${documentCategoryCodeParam}${documentSubTypeParam}.json`;
    const templateFilePath = `./form-templates/${fileName}`;

    // eslint-disable-next-line no-console
    console.warn(
      `Running on dev environment. Reading file template directly: ${templateFilePath}.`
    );

    const result = await import(`./form-templates/${fileName}`);

    const template: Template = {
      model: result.model as TemplateModelElement[],
      onChangeFunctions: result.onChangeFunctions,
    };

    return template;
  }

  /**
   * Gets the template metadata based on following backend call
   * @GetMapping(value = "/webformtemplate/metadata")
   */
  public async getTemplatesMetadata(): Promise<any> {
    const response = await this.dataProvider[
      Constants.API_GET_TEMPLATES_METADATA
    ](
      Constants.RESOURCE_WEBFORMTEMPLATE, // resource
      {} // no params
    );

    const metadata = response.data;
    return metadata;
  }

  /**
   * Checks if 'edm:' is present in the field source value and use 'properties.{source_value}'
   */
  private changeFieldSources(template: Template) {
    // First search at root level
    template.model.forEach((rootField) => {
      if (rootField.source) {
        rootField.source = getSourceField(rootField.source);
      }

      if (rootField.items && Array.isArray(rootField.items)) {
        // Search within the items

        rootField.items?.forEach((field) => {
          if (field.source) {
            field.source = getSourceField(field.source);
          }
        });

        rootField.topItems?.forEach((field) => {
          if (field.source) {
            field.source = getSourceField(field.source);
          }
        });
      }
    });
  }

  async getSubTypes(
    documentType: string,
    recipientId?: string,
    regulatorExtraDetails?: RegulatorExtraDetailsType
  ): Promise<any> {
    if (regulatorExtraDetails === RegulatorExtraDetailsType.ANAF_ONLY) {
      recipientId = 'EFACTURA';
    }

    if (regulatorExtraDetails === RegulatorExtraDetailsType.PEPPOL) {
      recipientId = 'PEPPOL';
    }
    const payload = {
      bspId: getBspId(),
      recipientId: recipientId,
      documentType: documentType,
    };

    const response = await this.dataProvider[
      Constants.API_GET_TEMPLATES_SUB_TYPES
    ](
      Constants.RESOURCE_WEBFORMTEMPLATE, // resource
      payload
    );

    return response.data;
  }

  /**
   * Checks if a template exists for the specified recipient id and document type
   */
  public async hasTemplate(
    documentRecipientId: string,
    documentTypeCode: DocumentTypeCode
  ): Promise<boolean> {
    let subTypes = await this.getSubTypes(
      documentTypeCode,
      documentRecipientId
    );

    return subTypes.length > 0;
  }

  private setValues(
    field: TemplateModelElement,
    values: any,
    selectValues: any
  ) {
    const path = getSourceField(field.source);

    if (!path) {
      return;
    }

    if (field.defaultValue) {
      _.set(values, path, field.defaultValue);
    }
    if (field.selectValues) {
      _.set(selectValues, path, field.selectValues);
    }
  }

  /**
   * Gets the template field defaultValue and the SelectValues
   * @param template Template
   */
  public getValues(template: Template): {
    defaultValues: TemplateDefaultValues;
    selectValues: TemplateDefaultValues;
  } {
    let defaultValues: TemplateDefaultValues = {
      properties: {},
      ublProperties: {},
    };
    let selectValues: TemplateDefaultValues = {
      properties: {},
      ublProperties: {},
    };

    template.model.forEach((element) => {
      if (!element.options?.hidden) {
        // First search at root level
        this.setValues(element, defaultValues, selectValues);
        if (
          element.type === FieldTypes.Container ||
          element.type === FieldTypes.OrderLinesTable ||
          element.type === FieldTypes.ArrayLinesTable
        ) {
          // Search within the items
          element.items?.forEach((item) => {
            if (!item.options?.hidden) {
              this.setValues(item, defaultValues, selectValues);
            }
          });
          if (element.topItems) {
            element.topItems.forEach((topItem) => {
              if (!topItem.options?.hidden) {
                this.setValues(topItem, defaultValues, selectValues);
              }
            });
          }
        }
      }
    });

    return { defaultValues, selectValues };
  }
}
