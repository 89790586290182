import AnyInvoiceIcon from '@material-ui/icons/AttachMoney';
import CancelInvoiceIcon from '@material-ui/icons/Clear';
import CommercialInvoiceIcon from '@material-ui/icons/FormatListNumbered';
import CorrectiveInvoiceIcon from '@material-ui/icons/PlaylistAdd';
import { Record as RA_Record } from 'ra-core';
import React from 'react';

export const INVOICE_ICONS = (
  typeCode: string,
  style: React.CSSProperties = {}
) => {
  switch (typeCode) {
    case '380':
      return <CommercialInvoiceIcon style={style} />;
    case '381':
      return <CancelInvoiceIcon style={style} />;
    case '384':
      return <CorrectiveInvoiceIcon style={style} />;
    default:
      return <AnyInvoiceIcon style={style} />;
  }
};

// The task react-admin resource. The template parameter 'T' is the flow variables
// as specified by uiSpecification.neededFlowVariables.
// So uiSpecification.neededFlowVariables = [ "foobar", "barfoo"]
// => T : { "foobar": ..., "barfoo": ... }
export interface Task<T extends Record<string, any> = Record<string, any>>
  extends RA_Record {
  definitionKey: string; // ex: validationUserTask
  startTime: number;
  dueTime?: number;
  candidateRoles: Array<string>; // ex: ["DXSUPPORT"]
  flow: {
    id: string; // ex: 7f6a9f55-bf59-11ea-9cff-1e031b5d8825
    definitionKey: string; // ex: invoice-test
    // (name, value) of the flow variables the task belongs to. The variables
    // are the one specified below in uiSpecification.neededFlowVariables
    variables: T;
  };
  uiSpecification: {
    type: string; // ex: nwaymatchingvalidation
    allowCancellation: boolean; // true means displays a cancel button.
    neededFlowVariables: Array<string>;
  };
}

export interface FieldProps<T extends RA_Record = RA_Record> {
  addLabel?: boolean;
  label?: string;
  record?: T;
  source?: string;
  resource?: string;
  basePath?: string;
  formClassName?: string;
  id?: string;
  classes?: any;
  locale?: any;
  dataProvider?: any;
}

export type UblInvoice = any;
export type UblRecadv = any;
export type UblOrder = any;

export type InvoiceLineId = string;
export interface MatchingRecadvLine {
  orderId: string;
  orderLineId?: string;
  proposal: {
    recadvId: string;
    recadvLineId: string;
    quantity: number; // Matched quantity. May be less than the received quantity.
    onPendingInvoicedItem: boolean;
  };
}
export interface MatchingOrderLine {
  orderId: string;
  orderLineId?: string;
  quantity: number; // Matched quantity. May be less than the received quantity.
}
export interface Matching
  extends Record<InvoiceLineId, Array<MatchingRecadvLine>> {}
export interface I2oMatching
  extends Record<InvoiceLineId, Array<MatchingOrderLine>> {}

export type Hint = 'ITEM_OK' | 'UNITARY_PRICE_OK' | 'UNIT_CODE_OK';
export interface SuggestedRecadvLine {
  orderId: string;
  orderLineId?: string;
  proposal: {
    recadvId: string;
    recadvLineId: string;
    quantity: number;
    hints: Array<Hint>;
    unitaryPriceWoGreenTaxAndVat: Price;
  };
}

export interface SuggestedOrderLine {
  orderId: string;
  orderLineId?: string;
  quantity: number;
  hints: Array<Hint>;
  unitaryPriceWoGreenTaxAndVat: Price;
}

export interface Suggestion
  extends Record<InvoiceLineId, Array<SuggestedRecadvLine>> {}

export interface I2oSuggestion
  extends Record<InvoiceLineId, Array<SuggestedOrderLine>> {}

export interface InvoiceReference {
  id: string;
  issueDate: string; // YYYY-MM-dd
  dxuid?: string;
  processInstanceId: string;
}

export interface RecadvLineSnapshot {
  id: string;
  lineId: string;
  quantity: number;
  invoiceReferences: Array<InvoiceReference>;
}

export interface OrderLineSnapshot {
  id: string;
  lineId: string;
  quantity: number;
  invoiceReferences: Array<InvoiceReference>;
}

export interface Price {
  value: number;
  currency: string;
}

export interface ExchangeRate {
  source: string;
  from: string;
  to: string;
  at: string;
  value: number;
}
