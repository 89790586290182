import { Constants, WebFormMode } from '@dx-ui/dx-common/src';
import { useEffect, useMemo, useState } from 'react';
import {
  Loading,
  linkToRecord,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import { useRouteMatch } from 'react-router-dom';
import {
  DocumentServiceFactory,
  InvoiceService,
  InvoiceServiceCreator,
} from '../../services';

const InvoiceCreate = (props) => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();

  const [data, setData] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const match: any = useRouteMatch();
  const { recipientId } = match.params;

  const invoiceService: InvoiceService = useMemo(
    () =>
      DocumentServiceFactory.create(
        InvoiceServiceCreator,
        dataProvider
      ) as InvoiceService,
    [dataProvider]
  );

  useEffect(() => {
    const createNewInvoice = async () => {
      setLoading(true);
      try {
        const nodeId: string | undefined = await invoiceService.createNew(
          recipientId
        );

        setData(nodeId);
        setLoading(false);
        redirect(
          `/${linkToRecord(Constants.RESOURCE_WEBINVOICE, nodeId)}`,
          undefined, // basePath
          undefined, // id
          undefined, // data
          { redirectMode: WebFormMode.SCRATCH_INVOICE } // state
        );
      } catch (e) {
        notify(
          'dxMessages.dashboard.OnErrorContactSupport',
          'error',
          { error: e },
          true
        );
        setLoading(false);
      }
    };
    if (!data) {
      createNewInvoice();
    }
  }, [data, invoiceService, notify, recipientId, redirect]);
  if (loading) {
    return <Loading />;
  } else return null;
};

export default InvoiceCreate;
