import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    passwordInfo: {
      padding: 5,
      color: theme.palette.text.primary,
      '&>div': {
        display: 'flex',
        alignItems: 'center',
        '&>svg': {
          paddingRight: theme.spacing(1),
        },
      },
    },
  }),
  { name: 'PasswordCriterias' }
);

const PasswordCriterias = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Typography variant='caption' className={classes.passwordInfo}>
      <div>
        <InfoIcon />
        {translate('dxMessages.auth.password_criteria_text')}
      </div>
      <ul>
        <li>{translate('dxMessages.auth.password_criteria_length')}</li>
        <li>{translate('dxMessages.auth.password_criteria_lowercase')}</li>
        <li>{translate('dxMessages.auth.password_criteria_uppercase')}</li>
        <li>{translate('dxMessages.auth.password_criteria_digit')}</li>
        <li>{translate('dxMessages.auth.password_criteria_special')}</li>
      </ul>
    </Typography>
  );
};

export default PasswordCriterias;
