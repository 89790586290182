import { DomainMessages } from './DomainMessages';
/**
 * Arabic I18N arabic messages.
 * In order to not alterate w/ react-admin messages, the application messages must not be defined
 * under ${locale}:ra namespace (where locale = en, ro fr , etc ..).
 * Moreover, ${locale}:resources is the namespace reserved to interact w/ react-admin resources label and title.
 * See : https://marmelab.com/react-admin/Translation.html#translating-resource-and-field-names
 */
export const arDomainMessages: DomainMessages = {
  dxMessages: {
    // Arabic Application messages
    dashboard: {
      categories: {
        general: 'General',
        'nway-matching': 'N-way matching',
        'cash-collection': 'Cash collection',
      },
      ConfigurationDescription:
        'Customize your dashboard by dragging and dropping widgets from below to the main page, moving them around, and  removing them as needed.',
      ConfigurationSingletonDescription:
        'This icon on the top right corner of some widgets means you can add it ' +
        'more than once on the dashboard, setting different configurations so ' +
        'each one displays different data.',
      DesignYourHomePage: 'Design your home page',
      LearnMore: 'Learn more',
      noAccessRightForDocXchange:
        'You do not own enough access rights to access docXchange. ' +
        'Your administrator may have forgotten to switch it on into your account. ' +
        'Please, contact him if you think you should.',
      NoData: 'No data',
      NoWidgetToAdd: 'No widget to add, all of them are already on screen.',
      OnErrorContactSupport:
        'Oups, an error occured. Please, retry later on or contact the DocProcess ' +
        'support (support@doc-process.com) [%{error}]',
      Welcome: 'Welcome %{firstname}',
      widgets: {
        DocumentReceptions: {
          title: 'Documents, per day and type',
          during: 'Time period',
          NoReception: 'No documents for the selected criteria.',
        },
        InvoiceAcceptanceSummary: {
          AutoApproved: 'Auto. approved',
          ManuallyApproved: 'Manually approved',
          Rejected: 'Rejected',
          Cancelled: 'Cancelled',
          i2pr: 'Invoice/receipt advices matchings',
          i2o: 'Invoice/orders matchings',
          SetEarlierDate: 'Select an earlier date.',
          Since_le: 'Since',
        },
        InvoiceCount: {
          Count: 'Invoice (%{on})',
          On: 'On',
          rejected: '%{count} rej.',
        },
        DocumentCountPerDayAndStatus: {
          INVOICE: {
            title: 'Invoices, per day and status',
          },
          ORDER: {
            title: 'Orders, per day and status',
          },
          RECADV: {
            title: 'Receipt advices, per day and status',
          },
          DESADV: {
            title: 'Despatch advices, per day and status',
          },
          during: 'Time period',
          NoReception: 'No documents for the selected criteria.',
          status: {
            Accepted_by_Customer: 'Accepted',
            Content_valid: 'Content validated',
            Dispute: 'Dispute',
            Format_valid: 'Format validated',
            No_credit: 'No credit',
            No_status: 'Other',
            Outage: 'Outage',
            Pending_Enrichments: 'Pending enrichments',
            Paid_by_recipient: 'Paid',
            Pending_to_Customer: 'Pending on recipient side',
            Received_by_Customer: 'Received',
            Received_by_DX: 'Received by DocProcess',
            Rejected_by_Customer: 'Rejected',
            Received_by_Recipient: 'Received',
            Rejected_by_DX: 'Rejected by DocProcess',
            Sent: 'Sent',
            Transmitted_by_DX: 'Transmitted by DocProcess',
            Matching_in_Progress: 'Matching in progress',
          },
        },
        InvoiceWaitingForDocuments: {
          Count: 'Invoices waiting for documents',
        },
        InvoiceWaitingForApproval: {
          Count: 'Invoices waiting for approval',
        },
        InvoiceWaitingForYourApproval: {
          Count: 'Invoices waiting for your approval',
          overdue: 'overdue',
        },
        OrderCount: {
          Count: 'Orders (%{on})',
          On: 'On',
        },
        PendingTaskCount: {
          Count: 'Pending tasks',
          Since_le: 'Since',
        },
        RecadvCount: {
          Count: 'Receipt advices (%{on})',
          On: 'On',
        },
        DesadvCount: {
          Count: 'Despatch advices (%{on})',
          On: 'On',
        },
        TotalVariationOnAcceptedInvoiceLines: {
          title: 'Total variation on accepted amounts',
          helper:
            'A positive amount means that you paid more than the exact prices.',
          Since: 'Since',
          Since_helper: 'The date when to start computing the differences',
        },
        WelcomeBanner: { LastLogin: 'Your last login' },
        CashCollectionProjectionOverTime: {
          title: 'Projection of cash collected overtime',
          due: 'Due M',
          overdue: 'Overdue',
          amount: 'Amount',
        },
        TotalAmountOwedTopTen: {
          title: 'Total amount owed (top 10 clients)',
          overdue: 'Overdue',
          amount: 'Amount',
          intervals: {
            current: 'due',
            overdue_1_30: 'overdue since 1 - 30 days',
            overdue_31_60: 'overdue since 31 - 60 days',
            overdue_61_90: 'overdue since 61 - 90 days',
            overdue_91_180: 'overdue since 91 - 180 days',
            overdue_beyond_180: 'overdue > 180 days',
          },
        },
        InformBanner: {
          title:
            'List of documents in processing may be different than that shown in chart.',
        },
        DocumentCompany: {
          INVOICE: 'Invoices for',
          ORDER: 'Orders for',
          RECADV: 'Receipt Advices for',
          DESADV: 'Despatch Advices for',
        },
        Tooltip: {
          message: 'click to see details per company',
        },
        Columns: {
          company: 'Company',
          status: 'Status',
          count: 'Count',
        },
        WidgetTitle: {
          name: 'Widget title',
        },
        ListButton: {
          title: 'Table',
        },
        SummaryTitle: {
          title: 'Summary for ',
        },
        Date: {
          month: 'Month',
          year: 'Year',
        },
        AppsWidget: {
          title: 'My applications',
        },
        Display: {
          title: 'Display',
          days: 'Days',
          months: 'Months',
          years: 'Years',
        },
        DateRange: {
          title: 'Date range',
          last7Days: 'Last 7 days',
          last14Days: 'Last 14 days',
          thisMonth: 'This month',
          lastMonth: 'Last month',
          selectMonth: 'Select month',
          thisQuarter: 'This quarter',
          lastQuarter: 'Last quarter',
          thisYear: 'This year',
          lastYear: 'Last year',
          selectYear: 'Select year',
          last3Years: 'Last 3 years',
        },
        TableButton: {
          title: 'Display as table',
        },
        SavedQueries: {
          title: 'My saved queries',
          business: {
            title: 'Business',
          },
        },
        DisplayDocuments: {
          title: 'Recent documents',
          INVOICE: {
            title: 'Latest invoices',
          },
          ORDER: {
            title: 'Latest orders',
          },
          DESADV: {
            title: 'Latest despatch advices',
          },
          RECADV: {
            title: 'Latest receipt advices',
          },
          documentType: {
            INVOIC: 'Invoice',
            ORDERS: 'Order',
            ORDER: 'Order',
            RECADV: 'Receipt Advice',
            DESADV: 'Despatch Advice',
          },
          recipient: 'Recipient',
          issuer: 'Issuer',
          status: 'Status',
          issueDate: 'Issue Date',
          document: 'Document type',
          button: 'open in search',
          clear: 'Clear',
          limitMessage: 'Note: displaying only last 100 documents per type',
        },
      },
    },
    landingPage: {
      empty: {
        click: 'Click',
        drop: 'and drop your widgets',
        openedConfigurationDrop: 'Drop your widgets',
      },
      message: {
        post: 'Posted',
      },
    },
    pspMessage: 'Are you sure you want to sync?',
    catalogList: {
      menu: 'Catalogs',
    },
    catalogEdit: {
      menu: 'Catalogs editing ',
    },
    v2administration: {
      menu: 'V2 Administration',
    },
    peppol: {
      menu: 'PEPPOL',
      accessPoint: {
        title: 'Peppol Participant ID',
      },
      schemaId: {
        title: 'Peppol: Schema ID',
      },
      value: {
        title: 'Peppol: Value',
      },
      company_identification: {
        title: "Recipient's VAT Code",
      },
      company_name: {
        title: "Recipient's Company Name",
      },
      issuer_identification: {
        title: "Issuer's VAT Code",
      },
      country: {
        title: "Recipient's Country",
      },
      error: 'Unable to delete.',
    },
    monitoring: {
      menuMonitoring: 'Monitoring',
      efactura: {
        incidentId: 'Incident Id',
        filename: 'Filename',
        reason: 'Reason',
        status: 'Status',
        userId: 'User Id',
        date: 'Date',
        eventDateStart: 'Event Date Start',
        eventDateEnd: 'Event Date End',
        ignore: 'Ignore',
        supplierNotified: 'Supplier Notify',
        createTask: 'Create Jira Task',
      },
    },
    companyManagement: {
      menu: 'Accounts',
      menuV2: 'User & Company (V2)',
      globalView: 'V2 Global View',
      documentSearch: 'V2 Document Search',
      redirect: 'Go to',
      contractedProducts: 'Contracted products',
      suppliers: 'Suppliers',
      customers: 'customers',
      contacts: 'Contacts',
      account: {
        title: 'Account',
        name: 'Company Name',
        vatCode: 'VAT Code',
        idstatus: 'State',
        idformattype: 'Invoice input format',
        idformattypedesadv: 'Desadv input format',
        registryNumber: 'Trade Registry number',
        corporateStock: 'Corporate Stock amount',
        uidIdentification: 'UID Identification',
        supplierId: 'Supplier Id',
        sync: 'SYNC',
        success: 'Company was synchronized successfully',
        error: 'Error in synchronization',
        isCompanyAdmin: 'Company admin',
        v3Synchronize: 'V3 Synchronize Planned',
        country: 'Country',
        detailsTooltip: 'This option is available only for ANAF companies.',
        detailsTooltipRo: 'This option is supported only in Romania.',
        getDetails: 'get Details',
        downloadCsvTemplate: 'Download CSV template',
        processing: 'Processing ...',
      },
      users: {
        userId: 'User Id',
        role: 'User Role',
        title: 'Users',
        help: 'A list with all the roles that can be assigned to the user. A role is available if the company has access to the feature and the user role is assigned.',
        isCompanyAdmin: 'Customer Administrator',
      },
      contact: {
        title: 'Contact',
        lastName: 'Name',
        firstName: 'First Name',
        phone: 'Phone',
        mail: 'Mail',
      },
      dropdowndef: {
        title: 'Dropdown Definition',
        description: 'Description',
        idtype: 'Id Type',
        value: 'Value',
        delete_dropdown_error:
          'Unable to delete, the selected item is already in use.',
      },
      formattype: {
        comment: 'Comment',
        description: 'Description',
        idDocumentType: 'Id Document Type',
        delete_formattype_error:
          'Unable to delete, the selected format type is already in use.',
      },
      dxrole: {
        name: 'Name',
        adminOnly: 'AdminOnly',
        delete_dxrole_error:
          'Unable to delete, the selected role is already in use.',
      },
      dxProduct: {
        title: 'DX Product',
        description: 'Description',
        comments: 'Comments',
        flgActive: 'flgActive',
        idProductGroup: 'idProductGroup',
        delete_product_error:
          'Unable to delete, the selected item is already in use.',
      },
      accountRelation: {
        title: 'Account relation',
        customer: 'Customer',
        customerId: 'Customer ID',
        supplier: 'Supplier',
        supplierId: 'Supplier ID',
        suppliercode4customer: 'Supplier code for customer',
        delete_account_error:
          'Unable to delete, the selected account is already in use.',
      },
      addresses: {
        title: 'Addresses',
        addressType: 'Address Type',
        street: 'Street',
        additionalStreet: 'Additional Street',
        buildingNumber: 'Building Number',
        postBox: 'Post Box',
        city: 'City',
        postalCode: 'Postal Code',
        financialAccount: 'IBAN',
        bank: 'Bank',
        gln: 'GLN',
        country: 'Country Code',
        flgBilling: 'Billing Address',
        flgShipping: 'Shipping Address',
        flgLogistic: 'Logistic Address',
      },
      addressBuffer: {
        delete: 'DELETE',
        message: 'Address was synchronized successfully',
      },
      supportedProducts: {
        product: 'Product',
        title: 'Supported Products',
        delete_supportedproduct_error:
          'Unable to delete, the selected product is already in use.',
      },
      productUsage: {
        product: 'Product',
        title: 'Contracted Products',
        totalusage: 'Total Usage',
        totalcredit: 'Total Credit',
        thresold: 'Threshold',
        advailible: 'Available',
      },
      audit: {
        tsChange: 'Last change at',
        userIdChange: 'Last change by',
      },
      commonDocumentColumns: {
        currentState: 'Current State',
        dxuid: 'DXUID',
        sourceCif: 'Source CIF',
        destinationCif: 'Destination CIF',
        originalFileName: 'Original Filename',
        ingestDate: 'Ingest Date',
        ingestDateBefore: 'Ingest Date Before',
        ingestDateAfter: 'Ingest Date After',
        invoiceId: 'Invoice ID',
        orderId: 'Order ID',
        receiptAdviceId: 'Receipt Advice ID',
        despatchAdviceId: 'Despatch Advice ID',
        issuingDate: 'Issuing date',
        issuingDateStart: 'Issuing date start',
        issuingDateEnd: 'Issuing date end',
        customerName: 'Customer',
        supplierName: 'Supplier',
      },
    },
    invoices: {
      title: 'الفواتير',
      document_label: 'فاتورة',
      edit_title: 'تحرير الفاتورة ',
      create_title: 'إنشاء فاتورة',
      show_title: 'فاتورة : ',
      status_history_title: 'سجل الحالة',
      payment_method: 'طريقة الدفع او السداد',
      payment_means_code: 'وسائل الدفع',
      due_date: 'تاريخ الاستحقاق',
      billing_period: 'فترة الفاتورة',
      iban: 'IBAN',
      bank: 'بنك',
      initialInvoice: 'الفاتورة الأولية',
      lineId: 'Order line ID',
      lines: 'خطوط',
      invoiceNumber: 'رقم الفاتورة',
      delivery: 'توصيل',
      note: 'ملحوظة',
      accounting_code_code: 'كود تكلفة المحاسبة',
      deliveryDate_IssueDate_error:
        'يجب أن يكون تاريخ التسليم أقل من تاريخ إصدار الفاتورة أو مساويًا له',
      issueDate_DueDate_error:
        'يجب أن يكون تاريخ الاستحقاق أكبر من تاريخ إصدار الفاتورة أو مساويًا له',
      referenceIssueDate_issueDate_error:
        'يجب أن يكون تاريخ الإصدار أكبر من تاريخ إصدار الفاتورة المرجعية أو مساويًا له',
      issueDate_error: 'لا يمكن أن يكون تاريخ الإصدار أكبر من التاريخ الحالي',
      dateWithoutId_error: 'لا يمكن تعيين هذا التاريخ بدون المعرف المرتبط به',
      fmdlinenote_error:
        'Please add the value for the note as "Invoicing period: yyyyMMdd-yyyyMMdd" \n. Example. Invoicing period: 20200701-20200731',
      sgrWithoutRef_error: 'Please set the "Product ref" field',
      valueWithoutName_error:
        "This value can't be set without its related name",
      orderIdSalesOrderID_error:
        'Please set to "NA" Order ID (BT-13) in that case',
      tax_summary_vat_percent: '(%) VAT',
      tax_summary_taxable_amount: 'المبلغ الخاضع للضريبة (%{currencyID})',
      tax_summary_tax_amount: 'قيمة الضريبة',
      tax_category_code: 'Tax category code',
      fetchBuyerDetailsButtonLabel: 'Complete the informations',
      fetchBuyerDetailsTooltip: 'Bring the information about company',
      taxCategory: {
        standard: '(S) Standard',
        reverse: '(AE) Reverse taxation',
        collection: 'Tax on collection',
        exempt: '(E) Tax exempt',
        freeExport: '(G) Free export item, tax not charged',
        exemptionWithDeductibility: 'Exemption with deductibility',
        exemptWithoutDeduction: 'Exempt without right of deduction',
        zeroVat: '(Z) Zero tax',
        triangleTransaction: 'Triangular transaction',
        proRata: 'Tax normal pro rata',
        specialRegime: 'Special regime (art. 1521, 1522)',
        specialExemption: 'Reg. special exemption art 311 CF',
        shorteningSpec: 'Reg. shortening spec 312 CF',
        purchaseWithInstallation:
          'EU purchases of goods with installation / assembly',
        outsideOfScope: '(O) Services outside scope of tax',
        EEAIntraCommunity:
          '(K) VAT exempt for EEA intra-community supply of goods and services',
        L: '(L) Canary Islands general indirect tax',
        M: '(M) Tax for production, services and importation in Ceuta and Melilla',
        B: '(B) Transferred (VAT), In Italy',
        '00': '(00) 0 %',
        '01': '(01) 6 %',
        '02': '(02) 12 %',
        '03': '(03) 21 %',
        '45': '(45) Reverse charge - Contractor',
        NA: '(NA) Exempt (miscelleanous)',
        FD: '(FD) Financial discount',
        SC: '(SC) Small company',
        '00_44': '(00/44) 0% Clause 44',
        '03_SE': '(03/SE) Standard exchange ',
        MA: '(MA) Margin',
        '46_GO': '(46/GO) ICD Goods',
        '47_TO': '(47/TO) ICD Manufacturing cost',
        '47_AS': '(47/AS) ICD Assembly',
        '47_DI': '(47/DI) ICD Distance',
        '47_SE': '(47/SE) ICD Services',
        '44': '(44) ICD Services B2B',
        '46_TR': '(46/TR) ICD Triangle a-B-c',
        '47_EX': '(47/EX) Export non E.U.',
        '47_EI': '(47/EI) Indirect export',
        '47_EE': '(47/EE) Export via E.U.',
        NS: '(NS) Not subject to VAT invoice/credit note',
        OSS_G: '(OSS-G) OSS Goods',
        OSS_S: '(OSS-S) OSS Services',
        OSS_I: '(OSS-I) OSS Import',
      },
      tax_reason_code: 'Tax reason code',
      taxReasonCode: {
        VATEX_EU_AE:
          '(AE) => VATEX-EU-AE: Reverse charge Supports EN 16931-1 rule BR-AE-10',
        VATEX_EU_D:
          '(E) => VATEX-EU-D: Intra-Community acquisition from second hand means of transport Second-hand means of transport',
        VATEX_EU_F:
          '(E) => VATEX-EU-F: Intra-Community acquisition of second hand goods Second-hand goods',
        VATEX_EU_G:
          '(G) => VATEX-EU-G: Export outside the EU Supports EN 16931-1 rule BR-G-10',
        VATEX_EU_I:
          '(E) =>  VATEX-EU-I: Intra-Community acquisition of works of art Works of art',
        VATEX_EU_IC:
          '(K) => VATEX-EU-IC: Intra-Community supply Supports EN 16931-1 rule BR-IC-10',
        VATEX_EU_O:
          '(O) => VATEX-EU-O: Not subject to VAT Supports EN 16931-1 rule BR-O-10',
        VATEX_EU_J:
          "(E) => VATEX-EU-J: Intra-Community acquisition of collectors items and antiques Collectors' items and antiques",
        VATEX_EU_79_C:
          '(E) => VATEX-EU-79-C: Exempt based on article 79, point c of Council Directive 2006/112/EC',
        VATEX_EU_132:
          '(E) => VATEX-EU-132: Exempt based on article 132 of Council Directive 2006/112/EC',
        VATEX_EU_143:
          '(E) => VATEX-EU-143: Exempt based on article 143 of Council Directive 2006/112/EC',
        VATEX_EU_148:
          '(E) => VATEX-EU-148: Exempt based on article 148 of Council Directive 2006/112/EC',
        VATEX_EU_151:
          '(E) => VATEX-EU-151: Exempt based on article 151 of Council Directive 2006/112/EC',
        VATEX_EU_309:
          '(E) => VATEX-EU-309: Exempt based on article 309 of Council Directive 2006/112/EC',
        VATEX_FR_FRANCHISE:
          '(E) => VATEX-FR-FRANCHISE: France domestic VAT franchise in base',
        VATEX_FR_CNWVAT:
          'VATEX-FR-CNWVAT: France domestic Credit Notes without VAT',
        BETE_45: 'BETE-45: Reverse charge - Contractor',
        BETE_EX: ' BETE-EX: Exempt',
        BETE_FD: 'BETE-FD: Financial discount',
        BETE_SC: 'BETE-SC: Small company',
        BETE_00_44: 'BETE-00/44: 0% Clause 44',
        BETE_03_SE: 'BETE-03/SE: Standard exchange',
        BETE_MA: 'BETE-MA: Margin',
        BETE_46_GO: 'BETE-46/GO: Intra-community supply - Goods',
        BETE_47_TO: 'BETE-47/TO: Intra-community supply - Manufacturing cost',
        BETE_47_AS: 'BETE-47/AS: Intra-community supply - Assembly',
        BETE_47_DI: 'BETE-47/DI: Intra-community supply - Distance',
        BETE_47_SE: 'BETE-47/SE: Intra-community supply - Services',
        BETE_44: 'BETE-44: Intra-community supply - Services B2B',
        BETE_46_TR: 'BETE-46/TR: Intra-community supply - Triangle a-B-c',
        BETE_47_EX: 'BETE-47/EX: Export non E.U.',
        BETE_47_EI: 'BETE-47/EI: Indirect export',
        BETE_47_EE: 'BETE-47/EE: Export via E.U.',
        BETE_NS: 'BETE-NS: Not subject to VAT',
      },
      taxReasonCodeTooltip: {
        VATEX_EU_AE:
          'Reverse charge Supports EN 16931-1 rule BR-AE-10 (VATEX-EU-AE)' +
          ' - Only use with VAT category code AE',
        VATEX_EU_D:
          'Intra-Community acquisition from second hand means of transport Second-hand means of transport (VATEX-EU-D)' +
          ' - Indication that VAT has been paid according to the relevant transitional arrangements - Only use with VAT category code E',
        VATEX_EU_F:
          'Intra-Community acquisition of second hand goods Second-hand goods (VATEX-EU-F)' +
          ' - Indication that the VAT margin scheme for second-hand goods has been applied. - Only use with VAT category code E',
        VATEX_EU_G:
          'Export outside the EU Supports EN 16931-1 rule BR-G-10 (VATEX-EU-G) - Only use with VAT category code G',
        VATEX_EU_I:
          'Intra-Community acquisition of works of art Works of art (VATEX-EU-I)' +
          ' - Indication that the VAT margin scheme for works of art has been applied. - Only use with VAT category code E',
        VATEX_EU_IC:
          'Intra-Community supply Supports EN 16931-1 rule BR-IC-10 (VATEX-EU-IC) - Only use with VAT category code K',
        VATEX_EU_O:
          'Not subject to VAT Supports EN 16931-1 rule BR-O-10 (VATEX-EU-O) - Only use with VAT category code O',
        VATEX_EU_J:
          'Intra-Community acquisition of collectors items and antiques Collector’s items and antiques (VATEX-EU-J)' +
          ' - Indication that the VAT margin scheme for collector’s items and antiques has been applied. - Only use with VAT category code E',
        VATEX_EU_79_C:
          'Exempt based on article 79, point c of Council Directive 2006/112/EC (VATEX-EU-79-C) - Only use with VAT category code E',
        VATEX_EU_132:
          'Exempt based on article 132 of Council Directive 2006/112/EC (VATEX-EU-132) - Only use with VAT category code E',
        VATEX_EU_143:
          'Exempt based on article 143 of Council Directive 2006/112/EC (VATEX-EU-143) - Only use with VAT category code E',
        VATEX_EU_148:
          'Exempt based on article 148 of Council Directive 2006/112/EC (VATEX-EU-148) - Only use with VAT category code E',
        VATEX_EU_151:
          'Exempt based on article 151 of Council Directive 2006/112/EC (VATEX-EU-151) - Only use with VAT category code E',
        VATEX_EU_309:
          'Exempt based on article 309 of Council Directive 2006/112/EC (VATEX-EU-309) - Only use with VAT category code E',
        VATEX_FR_FRANCHISE:
          'France domestic VAT franchise in base (VATEX-FR-FRANCHISE). For domestic invoicing in France',
        VATEX_FR_CNWVAT:
          'France domestic Credit Notes without VAT, due to supplier forfeit of VAT for discount (VATEX-FR-CNWVAT). For domestic Credit Notes only in France',
        BETE_45: 'Reverse charge - Contractor (BETE-45)',
        BETE_EX: 'Exempt (BETE-EX)',
        BETE_FD: 'Financial discount (BETE-FD)',
        BETE_SC: 'Small company (BETE-SC)',
        BETE_00_44: '0% Clause 44 (BETE-00/44)',
        BETE_03_SE: 'Standard exchange (BETE-03/SE)',
        BETE_MA: 'Margin (BETE-MA)',
        BETE_46_GO: 'Intra-community supply - Goods (BETE-46/GO)',
        BETE_47_TO: 'Intra-community supply - Manufacturing cost (BETE-47/TO)',
        BETE_47_AS: 'Intra-community supply - Assembly (BETE-47/AS)',
        BETE_47_DI: 'Intra-community supply - Distance (BETE-47/DI)',
        BETE_47_SE: 'Intra-community supply - Services (BETE-47/SE)',
        BETE_44: 'Intra-community supply - Services B2B (BETE-44)',
        BETE_46_TR: 'Intra-community supply - Triangle a-B-c (BETE-46/TR)',
        BETE_47_EX: 'Export non E.U. (BETE-47/EX)',
        BETE_47_EI: 'Indirect export (BETE-47/EI)',
        BETE_47_EE: 'Export via E.U. (BETE-47/EE)',
        BETE_NS: 'Not subject to VAT (BETE-NS)',
      },
      charge_reason_code: 'Charge reason code',
      chargeReasonCode: {
        AA: 'AA - Advertising',
        AAA: 'AAA - Telecommunication',
        AAC: 'AAC - Technical modification',
        AAD: 'AAD - Job-order production',
        AAE: 'AAE - Outlays',
        AAF: 'AAF - Off-premises',
        AAH: 'AAH - Additional processing',
        AAI: 'AAI - Attesting',
        AAS: 'AAS - Acceptance',
        AAT: 'AAT - Rush delivery',
        AAV: 'AAV - Special construction',
        AAY: 'AAY - Airport facilities',
        AAZ: 'AAZ - Concession',
        ABA: 'ABA - Compulsory storage',
        ABB: 'ABB - Fuel removal',
        ABC: 'ABC - Into plane',
        ABD: 'ABD - Overtime',
        ABF: 'ABF - Tooling',
        ABK: 'ABK - Miscellaneous',
        ABL: 'ABL - Additional packaging',
        ABN: 'ABN - Dunnage',
        ABR: 'ABR - Containerisation',
        ABS: 'ABS - Carton packing',
        ABT: 'ABT - Hessian wrapped',
        ABU: 'ABU - Polyethylene wrap packing',
        ACF: 'ACF - Miscellaneous treatment',
        ACG: 'ACG - Enamelling treatment',
        ACH: 'ACH - Heat treatment',
        ACI: 'ACI - Plating treatment',
        ACJ: 'ACJ - Painting',
        ACK: 'ACK - Polishing',
        ACL: 'ACL - Priming',
        ACM: 'ACM - Preservation treatment',
        ACS: 'ACS - Fitting',
        ADC: 'ADC - Consolidation',
        ADE: 'ADE - Bill of lading',
        ADJ: 'ADJ - Airbag',
        ADK: 'ADK - Transfer',
        ADL: 'ADL - Slipsheet',
        ADM: 'ADM - Binding',
        ADN: 'ADN - Repair or replacement of broken returnable package',
        ADO: 'ADO - Efficient logistics',
        ADP: 'ADP - Merchandising',
        ADQ: 'ADQ - Product mix',
        ADR: 'ADR - Other services',
        ADT: 'ADT - Pick-up',
        ADW: 'ADW - Chronic illness',
        ADY: 'ADY - New product introduction',
        ADZ: 'ADZ - Direct delivery',
        AEA: 'AEA - Diversion',
        AEB: 'AEB - Disconnect',
        AEC: 'AEC - Distribution',
        AED: 'AED - Handling of hazardous cargo',
        AEF: 'AEF - Rents and leases',
        AEH: 'AEH - Location differential',
        AEI: 'AEI - Aircraft refueling',
        AEJ: 'AEJ - Fuel shipped into storage',
        AEK: 'AEK - Cash on delivery',
        AEL: 'AEL - Small order processing service',
        AEM: 'AEM - Clerical or administrative services',
        AEN: 'AEN - Guarantee',
        AEO: 'AEO - Collection and recycling',
        AEP: 'AEP - Copyright fee collection',
        AES: 'AES - Veterinary inspection service',
        AET: 'AET - Pensioner service',
        AEU: 'AEU - Medicine free pass holder',
        AEV: 'AEV - Environmental protection service',
        AEW: 'AEW - Environmental clean-up service',
        AEX: 'AEX - National cheque processing service outside account area',
        AEY: 'AEY - National payment service outside account area',
        AEZ: 'AEZ - National payment service within account area',
        AJ: 'AJ - Adjustments',
        AU: 'AU - Authentication',
        CA: 'CA - Cataloguing',
        CAB: 'CAB - Cartage',
        CAD: 'CAD - Certification',
        CAE: 'CAE - Certificate of conformance',
        CAF: 'CAF - Certificate of origin',
        CAI: 'CAI - Cutting',
        CAJ: 'CAJ - Consular service',
        CAK: 'CAK - Customer collection',
        CAL: 'CAL - Payroll payment service',
        CAM: 'CAM - Cash transportation',
        CAN: 'CAN - Home banking service',
        CAO: 'CAO - Bilateral agreement service',
        CAP: 'CAP - Insurance brokerage service',
        CAQ: 'CAQ - Cheque generation',
        CAR: 'CAR - Preferential merchandising location',
        CAS: 'CAS - Crane',
        CAT: 'CAT - Special colour service',
        CAU: 'CAU - Sorting',
        CAV: 'CAV - Battery collection and recycling',
        CAW: 'CAW - Product take back fee',
        CAX: 'CAX - Quality control released',
        CAY: 'CAY - Quality control held',
        CAZ: 'CAZ - Quality control embargo',
        CD: 'CD - Car loading',
        CG: 'CG - Cleaning',
        CS: 'CS - Cigarette stamping',
        CT: 'CT - Count and recount',
        DAB: 'DAB - Layout/design',
        DAC: 'DAC - Assortment allowance',
        DAD: 'DAD - Driver assigned unloading',
        DAF: 'DAF - Debtor bound',
        DAG: 'DAG - Dealer allowance',
        DAH: 'DAH - Allowance transferable to the consumer',
        DAI: 'DAI - Growth of business',
        DAJ: 'DAJ - Introduction allowance',
        DAK: 'DAK - Multi-buy promotion',
        DAL: 'DAL - Partnership',
        DAM: 'DAM - Return handling',
        DAN: 'DAN - Minimum order not fulfilled charge',
        DAO: 'DAO - Point of sales threshold allowance',
        DAP: 'DAP - Wholesaling discount',
        DAQ: 'DAQ - Documentary credits transfer commission',
        DL: 'DL - Delivery',
        EG: 'EG - Engraving',
        EP: 'EP - Expediting',
        ER: 'ER - Exchange rate guarantee',
        FAA: 'FAA - Fabrication',
        FAB: 'FAB - Freight equalization',
        FAC: 'FAC - Freight extraordinary handling',
        FC: 'FC - Freight service',
        FH: 'FH - Filling/handling',
        FI: 'FI - Financing',
        GAA: 'GAA - Grinding',
        HAA: 'HAA - Hose',
        HD: 'HD - Handling',
        HH: 'HH - Hoisting and hauling',
        IAA: 'IAA - Installation',
        IAB: 'IAB - Installation and warranty',
        ID: 'ID - Inside delivery',
        IF: 'IF - Inspection',
        IR: 'IR - Installation and training',
        IS: 'IS - Invoicing',
        KO: 'KO - Koshering',
        L1: 'L1 - Carrier count',
        LA: 'LA - Labelling',
        LAA: 'LAA - Labour',
        LAB: 'LAB - Repair and return',
        LF: 'LF - Legalisation',
        MAE: 'MAE - Mounting',
        MI: 'MI - Mail invoice',
        ML: 'ML - Mail invoice to each location',
        NAA: 'NAA - Non-returnable containers',
        OA: 'OA - Outside cable connectors',
        PA: 'PA - Invoice with shipment',
        PAA: 'PAA - Phosphatizing (steel treatment)',
        PC: 'PC - Packing',
        PL: 'PL - Palletizing',
        RAB: 'RAB - Repacking',
        RAC: 'RAC - Repair',
        RAD: 'RAD - Returnable container',
        RAF: 'RAF - Restocking',
        RE: 'RE - Re-delivery',
        RF: 'RF - Refurbishing',
        RH: 'RH - Rail wagon hire',
        RV: 'RV - Loading',
        SA: 'SA - Salvaging',
        SAA: 'SAA - Shipping and handling',
        SAD: 'SAD - Special packaging',
        SAE: 'SAE - Stamping',
        SAI: 'SAI - Consignee unload',
        SG: 'SG - Shrink-wrap',
        SH: 'SH - Special handling',
        SM: 'SM - Special finish',
        SU: 'SU - Set-up',
        TAB: 'TAB - Tank renting',
        TAC: 'TAC - Testing',
        TT: 'TT - Transportation - third party billing',
        TV: 'TV - Transportation by vendor',
        V1: 'V1 - Drop yard',
        V2: 'V2 - Drop dock',
        WH: 'WH - Warehousing',
        XAA: 'XAA - Combine all same day shipment',
        YY: 'YY - Split pick-up',
        ZZZ: 'ZZZ - Mutually defined',
      },
      discount_reason_code: 'Discount reason code',
      discountReasonCode: {
        '41': '41 - Bonus for works ahead of schedule',
        '42': '42 - Other bonus',
        '60': '60 - Manufacturer’s consumer discount',
        '62': '62 - Due to military status',
        '63': '63 - Due to work accident',
        '64': '64 - Special agreement',
        '65': '65 - Production error discount',
        '66': '66 - New outlet discount',
        '67': '67 - Sample discount',
        '68': '68 - End-of-range discount',
        '70': '70 - Incoterm discount',
        '71': '71 - Point of sales threshold allowance',
        '88': '88 - Material surcharge/deduction',
        '95': '95 - Discount',
        '100': '100 - Special rebate',
        '102': '102 - Fixed long term',
        '103': '103 - Temporary',
        '104': '104 - Standard',
        '105': '105 - Yearly turnover',
      },
      taxes: {
        label: 'Unitary tax',
        SGT_Amount: 'Sugar Tax',
        SGT_Percent: 'Sugar Tax %',
        GT_Amount: 'Green Tax',
        GT_Percent: 'Green Tax %',
      },
      commercialInvoice: '380 - Commercial invoice',
      cancelInvoice: '381 - Cancel invoice',
      correctiveInvoice: '384 - Corrective invoice',
      selfbillingInvoice: '389 - Self-billing invoice',
      accountingInvoice: '751 - Invoice information for accounting purposes',
      missingExchangeRate: 'Please set currency exchange rate as well',
      missingTargetCurrencyCode: 'Please set target currency code as well',
      applyExchangeRateButtonLabel: 'Apply Exchange Rate',
      applyExchangeRateTooltip: 'Apply exchange rate on all invoice lines',
      efactura: {
        efactura_choice_label: 'e-Factura options',
        efactura_send: 'send to e-Factura',
        efactura_no_send: 'No e-Factura',
        efactura_only: 'only e-Factura',
      },
      custom: {
        multimedia: {
          internal_transaction: 'Internal transaction',
          intra_community: 'Intra-community purchase',
          import_services: 'Import services',
          transport_invoice: 'Transport invoice chargeable on DVI',
        },
      },
      businessProcessType: {
        '1': 'Merchandise',
        '2': 'Services or other acquisitions',
        '3': 'Trade and financial discounts',
      },
      taxRepresentative: 'Tax representative',
      documentLevelCharges: 'Document level charges',
      documentLevelDiscounts: 'Document level discounts',
      primaryAccountNumberID: 'Primary account number of card',
      orderReferenceSalesOrderId: 'Sales order reference',
    },
    customers: {
      title: 'العملاء',
    },
    issuers: {
      title: 'المصدرين',
    },
    role: {
      meniu: 'Roles',
      domain: 'Domain',
      document: 'Documents',
    },
    despatchAdvices: {
      title: 'نصائح الإرسال',
      document_label: 'نصيحة إيفاد',
      edit_title: ' تحرير نصيحة الإرسال',
      create_title: 'إنشاء مشورة الإرسال',
      show_title: 'نصيحة إيفاد :',
      number: 'رقم: ',
      despatchAdviceNumber: 'رقم إشعار الإرسال',
      shippingContactName: 'اسم جهة الاتصال',
      shippingContactPhone: 'اتصال هاتفي',
      shippingContactEmail: 'الاتصال بالبريد الإلكتروني',
      despatchSupplierPartyID: 'معرف مورد الإرسال',
      shipmentId: 'شحنة معرف',
      shipment: 'شحنة',
      grossVolumeMeasure: 'الحجم الإجمالي',
      grossVolumeMeasureUnitCode: 'وحدة القياس',
      netVolumeMeasure: 'صافي حجم',
      netVolumeMeasureUnitCode: 'وحدة القياس',
      totalNumberofGoods: 'العدد الإجمالي للبضائع',
      totalNumberofGoodsUnitCode: 'كود الوحدة',
      customsDeclaredValueAmount: 'مبلغ القيمة الجمركية المصرح بها',
      customsDeclaredValueAmountCurrency: 'عملة',
      specialInstructions: 'تعليمات خاصة',
      exportCountryCode: 'بلد التصدير',
      shipmentStageId: 'معرف مرحلة الشحن',
      transportModeCode: 'رمز وضع النقل',
      transportMeansTypeCode: 'وسيلة النقل لمرحلة الشحن',
      transportMeansTypeCodeListId: 'قائمة تعريف وسائل النقل',
      carrierCode: 'رمز الناقل',
      carrierName: 'اسم الناقل',
      trackingId: 'معرف تتبع الشحنة',
      actualDeliveryDate: 'تاريخ التسليم الفعلي',
      actualDeliveryTime: 'وقت التسليم الفعلي',
      requestedDeliveryDate: 'تاريخ التسليم المطلوب',
      requestedDeliveryTime: 'الوقت المطلوب',
      estimatedArrivalDate: 'تاريخ الوصول المتوقع',
      estimatedArrivalTime: 'الوقت المتوقع للوصول',
      handlingUnitTypeCode: 'وحدة قياس وحدة المناولة',
      handlingUnitShippingMarks: 'نوع الوسم',
      handlingUnitPackageQuantity: 'عدد الحزم',
      handlingUnitPackageQuantityUnitCode: 'وحدة القياس',
      handlingUnitPackageReturnableMaterialIndicator:
        'نوع الحزمة (قابلة للإرجاع أم لا)',
      handlingUnitPackagePackingLevelCode: 'كود مستوى التعبئة والتغليف',
      handlingUnitPackagePackagingTypeCode: 'نوع تعريف الحزمة',
      missingLineOrderIDref: 'يرجى تعيين معرف الطلب',
      logisticOnly: 'Logistic Only',
      logisticOnlyMessage:
        'This is a logistic despatch advice, so it will not reach the buyer via EDI channel. The only purpose for this is to allow the despatch to be issued and printed',
    },
    receiptAdvices: {
      title: 'إشعارات الاستلام',
      document_label: 'إشعار الاستلام',
      document_label_return: 'Goods return advice',
      document_label_ack: 'Acknowledgement of receipt',
      palletReceiptNotice: '352 - Pallet receipt notice',
      receiptAdviceSubType: '632 - Receipt Advice',
      goodsReturnSubType: '35E - Goods return advice',
      ackReceiptSubType: '352 - Acknowledgement of receipt',
      create_title: 'Create Receipt Advice',
      edit_title: 'Edit Receipt Advice',
      create_goods_return_title: 'Create Goods Return',
      edit_goods_return_title: 'Edit Goods Return',
      show_title: 'إشعار الاستلام:',
      number: 'رقم:',
      goodsReturnID: 'Goods return ID',
      receiptAdviceID: 'Receipt advice ID',
      goodsReturnIssueDate: 'Issue date',
      receiptAdviceType: 'Receipt advice type',
      goodsReturnReason: 'Goods return reason',
      licensePlateId: 'License plate ID',
      productService: 'Product / Service',
      customerContact: 'Customer contact',
      supplierContact: 'Supplier contact',
      returnReasonAlteredMerchandise: 'Altered Merchandise',
      returnReasonMissingMerchandise: 'Missing Merchandise',
      returnReasonExtraQuantity: 'Extra Quantity',
    },
    feedbackMessages: {
      title: 'الرسائل',
      document_label: 'رسالة',
      edit_title: 'تحرير الرسالة',
      show_title: 'الرسالة:',
      no_docs: 'No messages',
    },
    linkedDocuments: {
      no_docs: 'No documents',
    },
    attachments: {
      title: 'المرفقات',
      document_label: 'المرفق',
      edit_title: 'تحرير المرفق',
      show_title: 'المرفق :',
      no_docs: 'No attachments',
    },
    orders: {
      title: 'طلب',
      document_label: 'أمر',
      edit_title: 'تحرير الأمر',
      create_title: 'إنشاء النظام',
      show_title: 'طلب :',
      orderNumber: 'رقم الأمر',
      initialOrder: '105 - Initial order',
      updatedOrder: '231 - Updated order',
      updatedOrderNotModified: '320 - Acknowledgement of order',
      finalOrder: 'الترتيب النهائي',
      crossDocking: 'يمر بقسوة',
      goodsReturn: 'عودة البضائع',
      orderType: 'نوع الطلب',
    },
    forecast: {
      title: 'التنبؤ',
      document_label: 'توقعات',
      edit_title: 'تحرير التوقعات',
      show_title: 'توقعات:',
    },
    contract: {
      title: 'انكماش',
      document_label: 'عقد',
      edit_title: 'تحرير العقد',
      show_title: 'عقد :',
    },
    catalog: {
      title: 'كتالوجات',
      document_label: 'فهرس',
      edit_title: 'تحرير الكتالوج',
      show_title: 'فهرس :',
    },
    apm: {
      title: 'Head over to the Expense Classification Cockpit to see this task',
      subheader: 'Please talk to your sales advisor for more information',
    },
    apmValidation: {
      title: 'Head over to the Validation Cockpit to see this task',
      subheader: 'Please, approve or reject the invoice below:',
      open_cockpit: 'Open the cockpit',
    },
    waybill: {
      title: 'CMRs (tous)',
      document_label: 'CMR',
      document_sublabel: 'اتفاقية عقد النقل الدولي للبضائع بالطرق البرية',
      edit_title: 'تحرير CMR',
      create_title: 'إنشاء CMR',
      show_title: 'CMR : ',
      number: 'رقم المستند',
      carrierParty: 'طرف الناقل',
      consigneeParty: 'الطرف المرسل إليه',
      consignorParty: 'الطرف المرسل',
      freightForwarderParty: 'طرف وكيل الشحن',
      note: 'ملحوظة',
      partyTile: {
        vatIdentification: 'تحديد TOT',
        name: 'اسم',
        street: 'شارع',
        additionalStreet: 'شارع إضافي',
        number: 'رقم الشارع / المبنى',
        city: 'مدينة',
        postalCode: 'الرمز البريدي',
        countrySubEntityCode: 'كود المقاطعة',
        countryCode: 'الرقم الدولي',
        contactName: 'اتصل',
        phone: 'هاتف',
        email: 'البريد الإلكتروني',
      },
      loadingTable: {
        serviceDescription: 'نوع النقل',
        loadingNumber: 'رقم التحميل',
        licensePlate: 'لوحة ترخيص المركبات',
        marksAndNumber: 'علامات وارقام',
        packageNumber: 'عدد الحزم',
        packingMethod: 'طريقة التعبئة',
        goodsNature: 'طبيعة السلع',
        remarks: 'ملاحظات',
        loadingLocation: 'موقع التحميل',
        unloadingLocation: 'موقع التفريغ',
        loadingDate: 'تاريخ التحميل',
        unloadingDate: 'تاريخ التفريغ',
        costs: {
          transportCostBeforeFees: 'تكلفة النقل قبل الرسوم',
          transportFees: 'رسوم النقل',
          transportCostIncludingFees: 'إجمالي تكلفة النقل شاملة الرسوم',
        },
        addressName: 'اسم عنوان',
      },
      serviceDescription: {
        glassSale: 'بيع الزجاج',
        glassAcquisition: 'اقتناء الزجاج',
        culletsReturn: 'عودة الزجاج المكسور',
        shelvesReturn: 'عودة الرفوف',
        fees: 'الرسوم',
      },
      issueDate_loadingDate_error:
        'يجب أن يكون أقل أو يساوي تاريخ إصدار الفاتورة',
      unloadingDate_loadingDate_error:
        'يجب أن يكون أكبر من أو يساوي تاريخ التحميل',
    },
    dxDocuments: {
      title: 'كافة المستندات',
      document_label: 'وثيقة',
      edit_title: 'تحرير المستند',
      show_title: 'الوثيقة:',
    },
    state: {
      completed: 'منجز',
      suspended: 'معلق',
      active: 'نشيط',
    },
    appSwitcher: {
      DocProcess: {
        label: 'Manage your documents and business workflows',
      },
      DxArchive: {
        label: 'Archive your documents',
      },
      DxCatalog: {
        label: 'Manage your product catalogs',
      },
      ExpenseClassificationCockpit: {
        label: 'Allocate expenses to your invoices',
      },
      ReconciliationCockpit: {
        label: 'Manage your Accounts Payable',
      },
      DxContract: {
        label: 'Manage your contracts',
      },
      DxUpload: {
        label: 'DocProcess smart connector',
      },
      EtransportCockpit: {
        label: 'Declare your road transport in Romania',
      },
      C4Cockpit: {
        label: 'Manage your efactura documents',
      },
    },
    user_menu: {
      settings: 'الإعدادات',
      language: 'لغة',
      select_language: 'اختر لغة',
      account_preferences: 'حسابي',
      AccountNotFound: 'Account not found!',
      AccountToImpersonate: 'Account to impersonate',
      AccountToImpersonateHelper: 'email or login <ENTER>',
      SwitchCompany: 'Switch company',
    },
    help_menu: {
      title: 'قائمة التعليمات',
      userGuide: 'دليل المستخدم',
      feedbacks: 'إعطاء ردود الفعل',
      mailto: 'mailto:feedback@doc-process.com?subject=%{DxApp} ردود الفعل على',
    },
    menu: {
      home: 'الصفحة الرئيسية',
      documents: 'مستندات',
      create: 'إنشاء الوثيقة',
      administration: 'الادارة',
      tasks: 'مهام',
      tooltip: 'Documents view',
    },
    buttons: {
      unselect: 'إلغاء الاختيار',
      preview: 'معاينة',
      more: 'أكثر ...',
      related_documents: 'الوثائق والرسائل ذات الصلة',
      download: 'تحميل',
      ediDocument: 'EDI Document',
      createNewDocument: 'Create',
      cloneInvoice: 'استنساخ الفاتورة',
      cancelInvoice: 'إلغاء الفاتورة',
      correctiveInvoice: 'قم بتصحيح الفاتورة',
      send: 'إرسال',
      convertToInvoice: 'تحويل إلى فاتورة',
      convertToDespatchAdvice: 'تحويل إلى إرسال المشورة',
      convertToReceiptAdvice: 'Convert to receipt Advice',
      editInvoice: 'تحرير الفاتورة',
      editDespatchAdvice: 'تحرير نصيحة الإرسال',
      editWayBill: 'تحرير CMR',
      editReceiptAdvice: 'Edit receipt Advice',
      editGoodsReturn: 'Edit goods return',
      editOrder: 'تحرير الأمر',
      cloneWayBill: 'استنساخ CMR',
      cloneDespatchAdvice: 'Clone the Despatch advice',
      cloneDocument: 'Clone document',
      appsSwitcher: 'Applications switcher',
      messagesCenter: 'Messages center',
    },
    inputs: {
      min_input_length: 'Minimum %{minLength} characters',
      add_new_entry: 'Add "%{entry}"',
    },
    error_messages: {
      must_be_greater: 'يجب أن يكون أكبر من %{target}',
      phone_number_invalid: 'رقم الهاتف غير صالح',
      generic_error:
        'لقد حدث خطأ غير متوقع. الرجاء معاودة المحاولة في وقت لاحق.',
      form_load_error: 'حدث خطأ أثناء تحميل بيانات النموذج',
      form_save_error: 'حدث خطأ أثناء حفظ بيانات النموذج',
      form_invalid_template: 'لا يمكن عرض النموذج. يرجى الاتصال بالدعم.',
      max_decimals: 'يُرجى استخدام٪ {عشرية} كحد أقصى من الكسور العشرية',
      type_number: 'يجب أن تكون القيمة رقمًا',
      unable_to_display:
        'الوثيقة ليست متاحة بعد. الرجاء إعادة المحاولة لاحقًا.',
      quantities_use_same_sign: 'يجب أن تكون جميع الكميات موجبة أو كلها سلبية',
      non_zero: 'لا يمكن أن تكون القيمة مساوية لـ 0',
      negative: 'يجب أن تكون القيمة سالبة.',
      invalid_DXUID: 'invalid DXUID',
      invalid: 'Invalid',
      fixed_length: 'Must contain exactly %{fixedLength} digits',
      line_error: 'This line contains one or more errors.',
      OnError_contact_the_support:
        'Oups, an error occured. Please, retry later on or contact the DocProcess ' +
        'support (support@doc-process.com) [%{error}]',
      address_already_exist: 'العنوان موجود بالفعل لهذا GLN',
      company_already_exists: 'The company name or the VAT code already exist.',
      company_info_not_found: 'Company information not found',
      email_already_exists: 'This email address is already registered.',
      product_usage_already_exists: 'This product is already registered.',
      impossible_delete_company:
        'Impossible to delete company, dependencies need to be removed first',
      vat_not_found: 'Warning, VAT code not found in public directory',
      not_enough_rights: 'Not enough rigths for this operation',
      invalid_character: 'Invalid character: %{unwanted}',
      fieldTobeSet: '%{label} needs to be also set',
    },
    processStatus: {
      ACCEPTED_BY_CUSTOMER: 'مقبول من قبل المستلم',
      ACCEPTED_BY_RECIPIENT: 'مقبول من قبل المستلم',
      ACCOUNT_CHECKED: 'استقبله DX',
      ALLOCATION_IN_PROGRESS: 'Allocation in progress',
      CONTENT_ERROR: 'خطأ في المحتوى',
      DELIVERED: 'أرسلت',
      DELIVERED_FROM_CONTENT_ERROR: 'خطأ في المحتوى',
      DELIVERED_FROM_FORMAT_ERROR: 'خطأ في التنسيق',
      ENRICHED: 'استقبله DX',
      FORMAT_ERROR: 'خطأ في التنسيق',
      LOCALLY_DELIVERED: 'استقبله DX',
      MATCHING_ERROR: 'Matching failed',
      MATCHING_IN_PROGRESS: 'المطابقة قيد التقدم',
      NEW: 'استقبله DX',
      NO_CREDIT: 'لا ائتمان',
      NORMALIZED: 'استقبله DX',
      NOTIFIED: 'أرسلت',
      PAID_BY_RECIPIENT: 'يدفعه المستلم',
      PARTIALLY_PAID_BY_RECIPIENT: 'Partially paid by recipient',
      PENDING_ENRICHMENTS: 'التخصيب في انتظار',
      PENDING_RECADV: 'التخصيب في انتظار',
      PENDING_SCHEDULED_DELIVERY: 'أرسلت',
      PENDING_TO_CUSTOMER: 'في انتظار المستلم',
      PENDING_CORRECTION: 'Pending correction',
      RECEIVED_BY_CUSTOMER: 'استقبله المستلم',
      RECEIVED_BY_SUPPLIER: 'Received by supplier',
      RECEIVED_BY_DX: 'استقبله DX',
      RECEIVED_BY_RECIPIENT: 'استقبله المستلم',
      REJECTED_BY_CUSTOMER: 'رفضه المستلم',
      REJECTED_BY_RECIPIENT: 'رفضه المستلم',
      REJECTED_BY_SUPPLIER: 'Rejected by supplier',
      RECEIVED_BY_BSP: 'Received by Service Provider',
      RECEIVED_AND_VALIDATED_BY_BSP:
        'Received and validated by Service Provider',
      REJECTED_BY_BSP: 'Rejected by Service Provider',
      UNDER_QUERY: 'Pending info',
      CONDITIONALLY_ACCEPTED: 'Conditionally accepted',
      IN_PROCESS: 'In Process',
      SENT: 'أرسلت',
      SUSPENDED: 'لا يوجد رصيد',
      VALIDATED: 'استقبله DX',
      WAITING_FOR_RECIPIENT: 'الانتظار من قبل المستلم',
      DRAFT: 'مشروع',
      openAuditTrail: {
        tooltip:
          'Click to open the processing details drawer and have more information on the document status',
      },
    },
    readStatus: {
      NEW: 'جديد',
      DOWNLOADED: 'تم التنزيل',
      READ: 'اقرأ',
      CONVERTED: 'محولة',
    },
    invoiceTypes: {
      FAM: 'FAM : فاتورة البضائع الذاتية',
      FAS: 'FAS : خدمات الفاتورة الذاتية',
      FCG: 'FCG : فاتورة مصاريف عامة',
      FIM: 'FIM : فاتورة الأصول',
      FMD: 'FMD : فاتورة الخصم',
      FME: 'FME : فاتورة بضائع خارجية',
      FMF: 'FMF : فاتورة بضاعة',
      FSR: 'FSR : فاتورة الخدمة',
      FGO: 'FGO : General expenses invoice with order',
    },
    processDocumentFormatTypes: {
      ORIGINAL: 'أصلي',
      ISSUER_ORIGINAL: 'أصلي',
      IMAGE: 'صورة',
      ISSUER_IMAGE: 'صورة',
      RECIPIENT_IMAGE: 'صورة',
      TARGET: 'استهداف',
      DX: 'UBL',
    },
    archivePeriod: {
      1: '1',
      10: '10',
      Permanent: 'دائم',
    },
    documentTypeCode: {
      APERAK: 'رسالة',
      CATLOG: 'فهرس',
      CNTCND: 'عقد',
      DESADV: 'نصيحة إيفاد',
      DELFOR: 'توقعات',
      INVOIC: 'فاتورة',
      INVOICE: 'فاتورة',
      ORDERS: 'طلب',
      ORDER: 'طلب',
      RECADV: 'إشعار الاستلام',
      ATTACH: 'المرفق',
      WAYBIL: 'CMR',
    },
    regulatorExtraDetails: {
      efacturaOnly: 'Fiscal authority only',
    },
    regulatorStatusCode: {
      '0': 'Accepted',
      '10': 'Rejected',
      '20': 'Fiscal authority internal error',
      '40': 'Pending',
    },
    taxPointDateCode: {
      '3': 'Invoice issue date',
      '35': 'Actual date of delivery',
      '432': 'Amount paid on the same day',
    },
    headers: {
      accountingCost: 'Accounting cost',
      companyLegalForm: 'Company legal form',
      id: 'ID',
      manufacturersItemIdentification: 'Manufacturers Code',
      transactionType: 'Transaction type',
      packageSequence: 'Package sequence ID',
      numberOfPackages: 'Number of packages',
      packingLevelCode: 'Package sequence',
      packagingTypeCode: 'Type of package',
      packageIDC: 'SSCC code',
      packageID: 'Package ID (SSCC)',
      deliveryNoteReferenceNumber: 'Delivery Note number',
      certNumberBioProduct: 'Prod cert no for BIO',
      packageMarkerID: 'Package marker ID',
      shippingContainer: 'Serial shipping container',
      bestBefore: 'Best Before',
      expiryDate: 'Expiry Date',
      lotNumberID: 'Batch number',
      tradeCode: 'Trade register number',
      supplierBank: 'Supplier Bank',
      clientBank: 'Client Bank',
      packingType: 'Type of packaging',
      UmUnitPriceBase: 'Base measure unit for unit price',
      unitPriceBase: 'Basis for the unit price',
      deliveryQuantityCode: 'Delivery quantity code',
      deliveryQuantity: 'Delivered quantity',
      unitsNumber: 'Number of units per box',
      invoiceTypeCode: 'Invoice Type Code',
      duration: 'Duration',
      periodType: 'Period Type',
      referenceReporting: 'Reference Reporting',
      referenceEvent: 'Reference Event',
      paymentDeadline: 'Payment Deadline',
      paymentMeansCode: 'Payment Type UN/ECE 4461',
      paymentMeansID: 'Payment Term (TP)',
      addition: 'Additional Information',
      customer: 'الزبون',
      customerId: 'معرف العميل',
      status: 'الحالة',
      documentName: 'اسم الملف',
      documents: 'الوثائق ذات الصلة',
      documentNumber: 'رقم المستند',
      issueDate: 'تاريخ الإصدار',
      sender: 'مرسل',
      senderId: 'معرّف المرسل',
      recipientName: 'مستلم',
      recipientId: 'معرّف المستلم',
      receiverId: 'Receiver ID',
      createdBy: 'انشأ من قبل',
      modifiedBy: 'تم التعديل بواسطة',
      modificationDate: 'تاريخ التعديل',
      modifiedAt: 'تاريخ التعديل',
      creationDate: 'تاريخ الإنشاء',
      createdAt: 'تاريخ الإنشاء',
      documentTypeCode: 'نوع الوثيقة',
      documentId: 'معرف المستند',
      document: 'وثيقة',
      excisePayerCode: 'Excise payer code',
      issuerName: 'المُصدر',
      archivePeriod: 'فترة الأرشفة',
      archiveDate: 'تاريخ الأرشفة',
      supplier: 'المورد',
      supplierId: 'معرف المورد',
      buyer: 'مشتر',
      buyerId: 'معرّف المشتري',
      buyerVatIdentifier: 'Customer VAT ID',
      buyerReference: 'Buyer reference',
      startedAt: 'بدأت في',
      deliveryDate: 'تاريخ التسليم او الوصول',
      actualDeliveryDate: 'تاريخ التسليم الفعلي',
      deliveryHour: 'ساعة التوصيل',
      parties: 'الأطراف المعنية',
      orders: 'الطلب',
      number: 'رقم',
      totalWithVat: 'الإجمالي مع ضريبة القيمة المضافة',
      totalWithoutVat: 'الإجمالي بدون ضريبة القيمة المضافة',
      totalVat: 'إجمالي ضريبة القيمة المضافة',
      vat: 'ضريبة القيمة المضافة',
      vatPercent: 'VAT (%)',
      quantity: 'كمية',
      quantityLidl: 'Number of cartons/boxes',
      price: 'السعر',
      invoiceNumber: 'رقم الفاتورة',
      invoiceId: 'رقم تعريف الفاتورة',
      invoiceDate: 'Invoice Date',
      orderId: 'معرف الطلب',
      orderDate: 'تاريخ الطلب',
      forecastId: 'معرف التوقعات',
      attachment: 'المرفق',
      invoiceType: 'نوع الفاتورة',
      processDocumentFormatType: 'نوع تنسيق المستند',
      originalFileName: 'اسم الملف الأصلي',
      adherentUniqueIdentifier: 'المعرف الفريد الملتصق',
      issuerId: 'معرّف المُصدر',
      gln: 'كود GLN',
      deliveryLocationName: 'اسم موقع التسليم',
      locationAddress: 'عنوان موقع التسليم',
      address: 'عنوان',
      currency: 'عملة',
      processStatus: 'حالة العملية',
      readStatus: 'قراءة الحالة',
      receiptAdviceId: 'معرف إشعار الاستلام',
      receiptAdviceDate: 'تاريخ إشعار الاستلام',
      despatchAdviceId: 'معرّف إرشادات الإرسال',
      despatchAdviceDate: 'تاريخ إشعار الإرسال',
      contractId: 'معرف العقد',
      contractDate: 'تاريخ العقد',
      dxuid: 'DXUID',
      documentReferenceDxuid: 'مرجع المستند DXUID',
      attachmentParentDxuid: 'مرفق الوالد DXUID',
      show_metadata: 'البيانات الوصفية',
      description: 'وصف',
      referenceInvoiceId: 'فاتورة مرجعية',
      referenceInvoiceDate: 'تاريخ الفاتورة المرجعية',
      codeClient: 'رمز العميل',
      codeStandard: 'EAN شفرة',
      itemClassificationCode: 'كود تصنيف البند',
      codeSupplier: 'كود المورد',
      codeBuyer: 'Buyer code',
      productCodeRef: 'Product ref',
      um: 'U.M',
      messageDetails: 'تفاصيل الرسالة',
      buyerCustomer: 'مرتبة حسب',
      supplierReceiverCode: 'كود المورد',
      supplierName: 'Name',
      numberOfLines: 'عدد الخطوط',
      user: 'المستعمل',
      streetName: 'شارع',
      additionalStreet: 'Additional street',
      buildingNumber: 'رقم المبنى',
      cityName: 'مدينة',
      postalZone: 'منطقة البريدية',
      countryCode: 'بلد',
      discount: 'خصم (٪)',
      discountAmount: 'Discount amount',
      taxAmount: 'Tax amount',
      taxType: 'Tax type',
      greenTax: 'الضريبة الخضراء',
      sugarTax: 'Sugar tax',
      sgrTax: 'D.R.S.',
      unitaryGreenTax: 'Unitary green tax',
      paymentTermsQualifier: 'المؤهل',
      paymentTermsStartEvent: 'بدء الحدث',
      paymentTermsReferenceEventCode: 'رمز الحدث المرجعي',
      paymentTermsSettlementPeriodCode: 'رمز الفترة',
      paymentType: 'Means of payment code UN / ECE 4461',
      paymentTermsSettlementPeriodDurationMeasure: 'قياس المدة',
      paymentTerms: 'شروط الدفع',
      identification: 'هوية',
      linkedDocuments: 'المستندات المرتبطة',
      carrierPartyName: 'اسم الطرف الناقل',
      consignorPartyName: 'اسم طرف المرسل',
      loadingLocationAddress: 'تحميل عنوان الموقع',
      loadingLocationGln: 'موقع التحميل GLN',
      loadingLocationName: 'تحميل اسم الموقع',
      unloadingLocationAddress: 'تفريغ عنوان الموقع',
      unloadingLocationGln: 'موقع التفريغ GLN',
      unloadingLocationName: 'تفريغ اسم الموقع',
      extendedInvoiceId: 'معرف الفاتورة',
      extendedInvoiceIssueDate: 'تاريخ إصدار الفاتورة',
      extendedInvoiceDxuid: 'فاتورة DXUID',
      paymentExchangeRateSourceCurrencyCode: 'كود العملة المصدر',
      paymentExchangeRateTargetCurrencyCode: 'رمز العملة الهدف',
      paymentExchangeRateCalculationRate: 'سعر صرف العملة',
      shippingLocation: 'Locația de expediere',
      shipment: 'شحنة',
      shipmentInformation: 'معلومات الشحنة',
      shipmentStage: 'مرحلة الشحن',
      shipmentDelivery: 'توصيل الشحنة',
      shipmentTransportHandlingUnit: 'وحدة مناولة نقل الشحنة',
      orderReferenceSalesOrderId: 'معرف طلب المورد',
      orderLineReferenceLineId: 'مرجع عنصر سطر الطلب',
      orderTypeCode: 'كود نوع الطلب',
      unitaryPrice: 'Unitary price',
      fiscalCode: 'Fiscal code',
      contactName: 'لقب',
      contact: 'اتصل',
      contactPhone: 'هاتف',
      contactNote: 'ملحوظة',
      contactIDCardSeries: 'ID card series',
      contactIDCardNumber: 'ID card number',
      contactIDCardIssuer: 'ID card issuer',
      measureUnit: 'Measure unit',
      documentSubTypeCode: 'Document Subtype',
      name: 'لقب',
      regulatorId: 'Fiscal authority download ID',
      regulatorStatusCode: 'Fiscal authority status code',
      regulatorExtraDetails: 'Fiscal authority details',
      grossWeightMeasure: 'Gross Weight Measure',
      grossVolumeMeasure: 'Gross Volume Measure',
      heightMeasure: 'Height Measure',
      grossWeightMeasureUm: 'Weight Measure U.M.',
      grossVolumeMeasureUm: 'Volume Measure U.M.',
      heightMeasureUm: 'Height Measure U.M.',
      heightMeasureAttributeId: 'Height Measure Attribute ID',
      grossWeightMeasureAttributeId: 'Gross Weight Measure Atribute ID',
      grossVolumeMeasureAttributeId: 'Gross Volume Measure Attribute ID',
      quantityPerPallet: 'Maximum number of cartons/boxes per pallet',
      quantityUnitPerPallet: 'U.M. Quantity',
      customizationID: 'Customization ID',
      profileID: 'Profile ID',
      projectReferenceID: 'Project reference',
      contactElectronicMail: 'Email',
      sendPDFToElectronicMail: 'Email for sending PDF',
      taxPointDate: 'VAT validity date',
      taxPointDateCode: 'VAT validity date code',
      businessProcessType: 'Business process type',
      payeeParty: 'Payee party name',
      taxRepresentativeCompanyID: 'Seller tax representative tax number',
      taxRepresentativeName: 'Seller tax representative name',
      additionalDocumentReference: 'Additional documents references',
      addressLine: 'Additional address line',
      invoicePeriodEndDate: 'End date of Invoicing period',
      invoicePeriodStartDate: 'Start date of invoicing period',
      additionalItemPropertyName: 'Item attribute name',
      additionalItemPropertyValue: 'Item attribute value',
      baseQuantity: 'Base quantity',
      tradeRegistryNumber: 'Trade registry number',
    },
    list: {
      columns: 'الأعمدة',
      search: 'بحث',
      default: 'إفتراضي',
      addFilter: 'المزيد من المرشحات',
      clearAll: 'امسح الكل',
      goToPage: 'Go to page',
      paginationHelp:
        'Hint: You can use the LEFT and RIGHT keys to navigate between the pages or press the "g" key to go to a specific page',
    },
    filter: {
      date: {
        datePicker: 'اختر التواريخ',
        today: 'اليوم',
        allTime: 'تاريخ إعادة التعيين',
        yesterday: 'في الامس',
        thisWeek: 'هذا الاسبوع',
        last7days: 'آخر 7 يومًا',
        lastWeek: 'الاسبوع الماضى',
        last14days: 'آخر 14 يومًا',
        thisMonth: 'هذا الشهر',
        lastMonth: 'الشهر الماضي',
        last30days: 'آخر 30 يومًا',
      },
      numberRange: {
        from: 'من عند',
        to: 'إلى',
      },
      issuerLabels: {
        name: 'اسم المُصدر',
        id: 'معرّف المُصدر',
      },
      recipientLabels: {
        name: 'اسم المستلم',
        id: 'معرف المستلم',
      },
      receiver: {
        id: 'Receiver ID',
      },
      savedQueriesSaveBtn: 'حفظ الاستعلام',
      savedQueriesMenu: 'الاستعلامات المحفوظة',
      savedQueriesSaveText: 'احفظ الاستعلام الحالي باسم',
      savedQueriesDeleteTitle: '%{savedQuery} :حذف',
      removeQueryRemoveText: 'هل تريد حقًا إزالة هذا الاستعلام؟',
      dateRange: {
        from: 'From:',
        to: 'To: ',
        invalidRange: 'Invalid date range',
      },
      apply: 'Apply',
    },
    pdf: {
      loadError: 'غير قادر على معاينة المستند',
      page: 'الصفحة',
      of: 'من',
    },
    showMetadata: {
      error: 'غير قادر على عرض البيانات الوصفية',
      aperakDetailsError: 'غير قادر على عرض تفاصيل الرسالة',
    },
    download: {
      error: 'خطأ: تعذر استرداد تمثيلات المستند',
      errorBulk: 'خطأ: تعذر تنزيل المستندات المحددة',
      inProgress: 'التنزيل قيد التقدم ...',
      completed: 'انتها التحميل.',
      warningLimit: 'يرجى تحديد اختيارك لـ 200 عنصر',
    },
    delete: {
      error: 'خطأ: تعذر حذف المستند المحدد',
      errorBulk: 'خطأ: تعذر حذف المستندات المحددة',
      inProgress: 'الحذف قيد التقدم ...',
      completed: 'اكتمل الحذف.',
      warningLimit: 'يرجى تحديد اختيارك لـ 200 عنصر',
    },
    messages: {
      update_profile_title: 'تحديث ملف تعريف المستخدم',
      no_apps_purchased: 'هل قمت بشراء أي تطبيق؟',
    },
    webForm: {
      saved: 'معرف المستند *** %{documentId} *** تم حفظه ...',
      savedWithNoId: 'تم حفظ المستند ...',
      errorSaving: 'تعذر حفظ المسودة ...',
      noDocumentId: 'الرجاء تحديد الوثيقة أولا',
      sent: 'تم إرسال المستند ...',
      errorSending: 'تعذر إرسال المستند',
      errorLoadTemplate: 'تعذر العثور على قالب نموذج صالح',
      saveConfirmTitle: 'نسخة احتياطية من المستند',
      saveConfirmMessage:
        'النموذج يحتوي على حقول غير صالحة. هل أنت متأكد أنك تريد متابعة النسخ الاحتياطي؟',
      sendUnknownError: 'حدث خطأ غير معروف. يرجى الاتصال بالدعم.',
      sendInvalidUblError: 'تنسيق المستند غير صالح. يرجى الاتصال بالدعم.',
      sendErrorTitle: 'عيب',
      deleteLine: 'حذف رقم السطر %{lineNumber}',
      deleteLineTooltip: 'حذف',
      deleteLineConfirm: 'هل أنت متأكد؟',
      deleteLineNotifySuccess: 'تم حذف "رقم السطر %{lineNumber}',
      popup: {
        title: 'Did you know ?',
        content:
          'Did you know that you can convert an order to an invoice from the "Orders" menu ?',
        moreInfoTextLink: 'Click here to learn how',
        moreInfoTextLinkUrl:
          'https://support.doc-process.com/hc/en-us/articles/8880637306397-Did-you-know-you-can-automatically-convert-an-order-to-an-invoice-',
        checkboxDismissText: "Don't show this again",
      },
      unitCode: {
        EA: 'EA - unit',
        '27': '27 - theoretical ton',
        DAY: 'DAY - Day',
        GRM: 'GRM - Gram',
        H87: 'H87 - Piece',
        KGM: 'KGM - Kilogram',
        LM: 'LM - Metre',
        LTR: 'LTR - Litre',
        MLT: 'MLT - Millilitre',
        MTK: 'MTK - Square metre',
        MTQ: 'MTQ - Cubic metre',
        SET: 'SET - Set',
        XBG: 'XBG - Bag',
        XBX: 'XBX - Box',
        XPA: 'XPA - Packet',
        XPP: 'XPP - Unpacked article',
        XPX: 'XPX - Pallet',
      },
    },
    export: {
      visibleColumns: 'تصدير الأعمدة المرئية',
      allColumns: 'تصدير كافة الأع��دة',
    },
    auth: {
      companySelectionAtLogin:
        'You belongs to more than one companies. Select the one you want to log in to.',
      crt_password: 'كلمة المرور الحالية',
      crt_password_required: 'الرجاء إدخال كلمة المرور الحالية الخاصة بك',
      crt_password_invalid: 'كلمة المرور غير صحيحة.',

      new_password: 'كلمة المرور الجديدة',
      new_password_required: 'الرجاء إدخال كلمة المرور الجديدة الخاصة بك',

      confirm_password: 'أعد كتابة كلمة السر الجديدة',
      confirm_password_required:
        'الرجاء إدخال كلمة المرور الجديدة الخاصة بك مرة أخرى',

      lost_password_title: 'نسيت رقمك السري ؟',
      lost_password_desc:
        'الرجاء إدخال اسم المستخدم الخاص بك لطلب إعادة تعيين كلمة المرور',
      lost_password_email_notif:
        'في حالة وجود حساب لاسم المستخدم هذا ، سيتم إرسال بريد إلكتروني مع مزيد من الإرشادات.',
      lost_password_error: 'حدث خطأ أثناء إرسال تعليمات استعادة كلمة المرور',

      change_password_title: 'تغيير كلمة المرور',
      change_password_confirmation:
        'كلمة السر الخاصة بك تم تغييرها بنجاح. الرجاء تسجيل الدخول باستخدام كلمة المرور الجديدة.',
      change_password_expired:
        'انتهت صلاحية رابط إعادة تعيين كلمة المرور الخاصة بك. لم يتم تغيير كلمة المرور.',
      change_password_error: 'تعذر تغيير كلمة المرور الخاصة بك بسبب خطأ داخلي.',
      change_password_match_error:
        'كلمات المرور غير متطابقة ، يرجى المحاولة مرة أخرى.',

      password_criteria_error:
        'كلمة المرور الجديدة ليست قوية بما يكفي. انظر المعايير للحصول على التفاصيل.',
      password_criteria_alert_title: 'سياسة كلمة مرور DocProcess',
      password_criteria_text:
        'من أجل الحصول على كلمة مرور قوية ، يجب أن تتوافق كلمة مرورك مع المعايير التالية:',
      password_criteria_length: 'تحتوي على 8 أحرف على الأقل',
      password_criteria_lowercase: 'تحتوي على حرف صغير',
      password_criteria_uppercase: 'Contain an uppercase letter',
      password_criteria_digit: 'تحتوي على رقم',
      password_criteria_special:
        '! & @ # : ? + * - /  $ ( ) :تحتوي على واحد على الأقل من الأحرف التالية:',
      token_too_many_attempts:
        'محاولات كثيرة. يرجى المحاولة مرة أخرى بعد 30 دقيقة.',
      token_invalid_or_already_used: 'طلبك غير صالح أو انتهت صلاحيته.',
    },
    myAccount: {
      email: 'البريد الإلكتروني',
      firstName: 'الاسم الاول',
      enabled: 'Enabled',
      lastName: 'الكنية',
      phoneNumber: 'رقم الهاتف',
      roles: 'الأدوار',
      profile_update_success:
        'تم تحديث بيانات المستخدم الخاصة بك (باستثناء عنوان البريد الإلكتروني) بنجاح. إذا تم تغيير عنوان بريدك الإلكتروني ، فستكون هناك حاجة إلى التأكيد. تحقق من صندوق البريد الإلكتروني الجديد للحصول على التعليمات.',
      profile_update_error: 'لا يمكن تحديث ملف تعريف المستخدم. حدث خطأ.',
      create_user_success:
        'The user has been  successfully created. An email has been sent to the address given for more instructions.',
      create_user_success_synchronize:
        'The user has been successfully created. An email will be sent to the address given, once company is synched with V3.',
      create_user_error: 'Unable to create the user',
      delete_user_error: 'Unable to delete this user : %{error}',
      delete_customer_admin_error:
        'It is required to have at least one administrator per company.',
      email_update_success: 'تم تحديث بريدك الإلكتروني بنجاح.',
      email_update_error: 'لا يمكن تحديث البريد الإلكتروني. حدث خطأ.',
      companyName: 'Company',
      generate_password_reset_link_button: 'Generate reset password link',
    },
    task: {
      documentId: 'معرف المستند',
      documentType: 'اكتب',
      due: 'بسبب',
      start_after: 'ابدأ بعد ذلك',
      start_before: 'ابدأ من قبل',
      description: 'وصف',
      start_since: 'تبدأ منذ ذلك الحين',
      due_in: 'ويرجع ذلك',
      orderId: 'Order ID',
      now: 'الآن',
      anyInvoice: 'فاتورة',
      commercialInvoice: 'فاتورة تجارية',
      cancelInvoice: 'إلغاء الفاتورة',
      correctiveInvoice: 'فاتورة تصحيحية',
      serviceInvoice: 'فاتورة FSR',
      order: 'ترتيب',
      order_negotiation: 'Order Negotiation',
      in_one_day: 'في يوم واحد',
      in_three_days: 'في ثلاثة ايام',
      in_one_week: 'في أسبوع واحد',
      in_two_weeks: 'خلال اسبوعين',
      in_one_month: 'في شهر واحد',
      in_three_months: 'في غضون ثلاثة أشهر',
      in_six_months: 'في ستة أشهر',
      in_one_year: 'في عام واحد',
      button: {
        approve: 'يوافق',
        reject: 'رفض',
        open: 'افتح المهمة',
      },
      invoiceToCancel: 'فاتورة للإلغاء',
      invoiceMatched: 'فاتورة مطابقة',
      invoiceNotMatched: 'الفاتورة غير متطابقة',
      title: 'Trebuie să aprobați sau să refuzați factura',
      matched: 'كتلة مطابقة N-way',
      request: {
        success: 'نجاح!',
      },
      comment: {
        message: 'يرجى تقديم تعليق',
      },
      taskCountError: 'إدارة المهام غير متوفرة',
      saintgobainui: {
        rejectReason: {
          incorrect_order_number: 'رقم الطلب غير صحيح',
          incorrect_deliverynote_number: 'رقم إشعار التسليم غير صحيح',
          incorrect_loading_location: 'موقع تحميل غير صحيح',
          incorrect_unloading_location: 'موقع تفريغ غير صحيح',
          incorrect_transport_cost: 'تكلفة النقل غير صحيحة',
          incorrect_invoice_currency: 'عملة الفاتورة غير صحيحة',
        },
        label: 'سبب الرفض',
        title: 'الفاتورة و CMRs المرتبطة بها',
        additionalInfo: 'معلومة اضافية',
        errorMsg: {
          missing_sap_id:
            'معرف SAP مفقود. يجب عليك إضافة واحدة للموافقة على هذه المهمة',
          max_sap_id_length: 'الحد الأقصى لطول معرف SAP هو 10',
          missing_rej_reason:
            'سبب الرفض مفقود. الرجاء تحديد واحد من القائمة المنسدلة',
          rej_reason_with_accept:
            'لا يمكن أن يكون لديك معرّف SAP عند رفض فاتورة. يرجى إزالته قبل المتابعة',
          sapid_with_rej:
            'لا يمكن أن يكون لديك معرّف SAP عند رفض فاتورة. يرجى إزالته قبل المتابعة',
        },
      },
      multimedia: {
        card: {
          title: 'Invoice Validation',
          subheader: 'Please, approve or reject the invoice below:',
        },
      },
      reginamaria: {
        card: {
          supplier: {
            title: 'Supplier Order Negotiation',
            subheader:
              'Please make changes to the order or send it without modifications',
          },
          client: {
            title: 'Client Order Negotiation',
            subheader: 'Please approve or reject the order',
          },
        },
        table: {
          title: 'Amend Order #%{orderId}',
          deliveryTitle: 'Delivery',
          orderTitle: 'Order Items',
          startDate: 'Requested Start Date',
          endDate: 'Requested End Date',
          actualDeliveryDate: 'Actual Delivery Date',
          quantity: 'Quantity',
          unitCode: 'U.M.',
          itemId: 'Item Id',
          itemDesc: 'Item Description',
          buyerIdentification: 'Buyer Identification',
          price: 'Unit price without VAT',
          expirationDate: 'Expiration Date',
          numberOfDeliveries: 'Number of Deliveries',
          deliveryId: 'Delivery Id',
          comment: 'Comment',
          emptyDeliveryTable: 'No delivery data.',
          emptyOrderTable: 'No data found in order.',
          deleteVerification: 'Are you sure you want to delete this row?',
          bulkButton: 'Apply to all',
          modificationsApplied: 'Modifications applied',
          addRemoveColumns: 'Add or remove columns',
          nRowsSelected: '{0} row(s) selected',
          searchTooltip: 'Search',
          searchPlaceholder: 'Search',
          showColumnsTitle: 'Show Columns',
          labelRowsSelect: 'rows',
          firstTooltip: 'First Page',
          previousTooltip: 'Previous Page',
          nextTooltip: 'Next Page',
          lastTooltip: 'Last Page',
          labelDisplayedRows: '{from}-{to} of {count}',
          addTooltip: 'Add',
          deleteTooltip: 'Delete',
          editTooltip: 'Edit',
          cancelTooltip: 'Cancel',
          saveTooltip: 'Save',
          deleteText: 'Are you sure delete this row?',
          lineStatusCode: 'Line status',
        },
        lineStatusCode: {
          NO_CHANGE: 'Unchanged',
          NOT_ACCEPTED: 'Not accepted',
          UPDATED: 'Updated',
        },
        client: {
          tooltip:
            'If the lines are not highlighted green, the process added the missing data per line',
        },
        toolbar: {
          accept: 'Accept',
          send: 'Send',
          reject: 'Reject',
        },
        buttons: {
          undo: 'Undo delete',
        },
        key: {
          infoSupplier:
            'Please add any changes to the order lines in the table below. Once finished, you can send the order or if you are not happy, you can reject it.',
          infoClient:
            'These are the proposed changes by the supplier. You can accept or reject the changes.',
          originalValue: 'Original value',
          proposedValue: 'Proposed value',
        },
        errorMsg: {
          missing_rej_reason: 'Rejection reason missing',
          missing_delivery_information_line:
            'Missing delivery information for line %{lineId}',
          delivered_quantity_too_big:
            'Delivered quantity is bigger than line quantity',
          delivered_quantity_too_low:
            'Delivered quantity is lower than line quantity',
          negative_numbers: 'Negative numbers are not allowed',
          same_delivery_dates:
            'Delivery dates are the same as another entry. Please combine them',
          incorrect_quantity: "Quantity can't be higher than initial quantity",
          actual_date_mandatory: 'The actual delivery date is mandatory',
          dates_not_in_range:
            'The actual delivery must be between the requested start date and end date',
          dates_not_in_range_with_extra:
            'The actual delivery must be between the requested start date and end date + %{extra} days',
          dates_not_in_range_in_line:
            'Line %{lineId} : the actual delivery must be between the requested start date and end date',
          dates_not_in_range_with_extra_in_line:
            'Line %{lineId} : the actual delivery must be between the requested start date and end date + %{extra} days',
          incorrect_start_date: 'Incorrect start delivery date',
          incorrect_end_date: 'Incorrect end delivery date',
          price_higher_than_tolerance:
            'The new price can only be increased by %{greaterThan}% of the current price at most.',
          price_lower_than_tolerance:
            'The new price can only be lowered by %{lessThan}% of the current price at most.',
          item_quantity:
            'Delivered quantity is higher than line quantity for line %{lineId}. Please fix it before submitting',
          item_quantity_lower:
            'Delivered quantity is lower than line quantity for line %{lineId}. Please fix it before submitting',
          invalid_quantity_value:
            'The delivered quantity value entered is invalid',
          actual_date_mandatory_line:
            'The actual delivery date is missing for line %{lineId}',
          delivery_dates_inconsistency_line:
            'The delivery dates are inconsistent for line %{lineId}',
          missing_requested_start_or_end_dates_line:
            'Missing mandatory requested start or end date for line %{lineId}',
          end_date_must_be_in_future:
            "The requested end date must be in the future or equal to today's date.",
          start_date_must_be_in_future:
            "The requested start date must be in the future or equal to today's date.",
          dates_overlap: 'The requested start date and end date are not valid.',
        },
      },
      invoice_matching: {
        invoiceHoldDecisionTask: {
          title: 'مطابقة الفاتورة بطريقة n',
          subtitle: 'الرجاء الموافقة أو رفض الفاتورة أدناه:',
          cockpit: {
            title: 'الموافقة على الفاتورة أو رفضها',
            button: {
              approve: 'يوافق',
              reject: 'رفض',
            },
            nwayMatchingOfTheinvoice: 'مطابقة n-way',
            invoiceCluster: 'المستندات المتضمنة في مطابقة n-way',
            comment: 'يجب إدخال تعليق لفتح زري الموافقة / الرفض:',
            invoiceClusterDetails:
              'الفاتورة وإشعارات الاستلام الخاصة بها (مرتبطة بأمر) ، وقد تكون فواتير سابقة مقبولة على نفس إخطارات الاستلام.',
            i2oInvoiceClusterDetails:
              'The invoice, its orders, and may be previous accepted invoices on the same orders.',
            raceConditionOnInvoiceApproval:
              'لقد قبلت بالفعل هذه الفاتورة ولكن شخصًا آخر قام بمطابقة بعض سطور إشعار الاستلام في الوقت نفسه ، مما تسبب في حدوث تعارض. أنت بحاجة لقبوله / رفضه مرة أخرى.',
            i2oRaceConditionOnInvoiceApproval:
              'You have already accepted this invoice but someone else matched some of the order lines in the meantime, causing a conflict. You need to accept/reject it again.',
            incompleteCluster:
              'قد تكون المطابقة غير مكتملة حيث لم يتم استرداد جميع المستندات المعنية قبل المهلة الزمنية (هل فاتتك إشعارات الاستلام؟).',
            i2oIncompleteCluster:
              'The matching may be incomplete as all the involved documents have not been retrieved before the time limit (missing orders?).',

            usedExchangeRate: 'سعر الصرف المستخدم في المطابقة:',
            element: 'جزء',
            rules: 'قواعد',
            acceptedMinusInvoiced: 'مقبول - تم إصدار فاتورة به بالفعل',
            unitaryNetPriceAlgo: 'net price = gross - discount + green tax',
            match: 'مباراة',
            quantityRule:
              'كمية الفاتورة <= مجموع كميات إخطار الاستلام المتطابقة',
            i2oQuantityRule:
              'Invoice quantity <= sum of matched order quantities',
            unitaryPrice: 'السعر الوحدوي',
            unitaryPriceRule: 'السعر الأحادي للفاتورة <= سعر موحد للطلب',
            i2oUnitaryPriceRule:
              'Invoice unitary net price <= order unitary price',
            correcting: 'تصحيح',
            correctionChain:
              'الفاتورة التجارية لتصحيح وجميع فواتيرها التصحيحية',
            errors: {
              PartialNwayMatchingException:
                'لقد حصلت فقط على مطابقة جزئية من الاتجاه n بسبب أخطاء في سطور الفاتورة:',
              InvoiceLineWithNegativeQuantity:
                'بند الفاتورة بكمية سالبة: %{invoiceId} في السطر: %{lineId}',
              CorrectiveInvoiceWithNoReferencedInvoiceId:
                'فاتورة تصحيحية بدون معرّف مرجعي على واحد لتصحيحه: %{id}',
              CorrectiveWithoutSameOrderRefAsCommercial:
                'تصحيحي بدون نفس مرجع الطلب التجاري: %{correctiveId} ،' +
                '%{correctiveOrderId} - %s{commercialId}, %{commercialOrderId}',
              RecadvsWithDuplicateID:
                'إخطارات الاستلام في مجموعة مطابقة n-way بنفس المعرف!',
              RecadvWithDuplicateLineID: 'إخطارات الاستلام مع معرف خط مكرر',
              InvoiceWithDuplicateLineID: 'فاتورة مع معرف سطر مكرر',
              InvoiceWithNoRequiredTopOrderRef:
                'فاتورة بدون مرجع الطلب الإلزامي (الأعلى)!',
              clusteringPeriodElapsed:
                'توقف عن انتظار إخطارات الاستلام: انقضت الفترة!',
              i2oClusteringPeriodElapsed:
                'Stop waiting for orders: period elapsed!',
              waitingForCorrectedInvoicePeriodElapsed:
                'توقف عن انتظار تصحيح الفاتورة: انقضت الفترة!',
              waitingForRecadvPeriodElapsed:
                'توقف عن انتظار إخطارات الاستلام: انقضت الفترة!',
              i2oWaitingForOrderPeriodElapsed:
                'Stop waiting for orders: period elapsed!',
            },
            wrongSgrTaxes:
              'The items were perfectly matched but some SGR taxes in the invoice lines do not match the ones in the receipt advices',
          },
        },
        cancellationDecisionTask: {
          title: 'إلغاء الفاتورة',
          subtitle: 'الرجاء الموافقة أو رفض فاتورة الإلغاء أدناه::',
          cockpit: {
            title: 'قبول أو رفض إلغاء الفاتورة',
            button: {
              cancelInvoice: 'الموافقة على الإلغاء',
              rejectCancellation: 'رفض الإلغاء',
            },
            invoiceAcceptance:
              'تم %{acceptance} هذه الفاتورة في %{date} بواسطة %{user}',
            automaticInvoiceAcceptance:
              'تم تلقائيًا تحويل هذه الفاتورة إلى٪ %{acceptance} في %{date}.',
            accepted: 'قبلت',
            rejected: 'مرفوض',
            withTheComment: 'مع التعليق:',
            invoiceNotFound:
              'لم يتم العثور على الفاتورة المراد إلغاءها (المرجع: %{ref}).',
            cancelInvoiceWithNoRef:
              'لا تحتوي فاتورة الإلغاء هذه على إشارة إلى الفاتورة المراد إلغاؤها.',
            comment: 'تعليق',
            theCancelInvoice: 'فاتورة الإلغاء',
            theInvoiceToCancel: 'فاتورة الإلغاء',
            theCancelInvoiceDetails: 'تفاصيل فاتورة الإلغاء وهدفها',
          },
        },
      },
    },
    uploadFiles: {
      uploadBox:
        "<span class='filepond--label-action'>تصفح</span>اسحب الملف وأفلته لإرفاقه أو اسحبه وأفلته لإرفاقه أو",
      preparing: 'خطة',
      ready: 'جاهز',
      clickToRemove: 'انقر للإزالة',
      clickToCancel: 'انقر للإلغاء',
      fileTooLarge: 'الملف كبير جدا',
      maxFileSizeIs: '%{filesize}الحجم الأقصى للملف هو',
      shortFileTooLarge: 'كبير جدًا',
      shortMaxFileSizeIs: 'الحجم الأقصى هو 30 ميغا بايت',
      uploadError: 'خطأ أثناء التحميل',
      loadError: 'خطأ أثناء التنزيل',
      loadInitError: 'فشل الحصول على وصف الملف',
      tapToRetry: 'انقر فوق الدائرة لإعادة المحاولة',
      uploadRefreshInfo:
        'Uploaded files will soon be available in the attachment list. Please refresh manually.',
      successfullyCreated:
        'لقد تم رفع الملف! من المفترض أن يتوفر في قائمة المرفقات قريبًا',
      removeFile: 'تمت إزالة الملف',
      uploadInitError: 'Upload fonctionality not currently available.',
      maxFilesReached: 'Maximum number of attached files has been reached.',
    },
    ubl: {
      packageMarkerID: 'EAN-128 or other format',
      packagingTypeCode: {
        pallet: 'pallet',
        box: 'box',
        carton: 'carton',
      },
      payment_means_code: {
        bank_transfer: 'حوالة بنكية',
        cash: 'السيولة النقدية',
        collection: 'مجموعة',
      },
      payment_terms_settlement_period_code: {
        day: 'D - يوم',
        weekDay: 'WD - يوم الأسبوع',
        month: 'M - شهر',
        year: 'Y - عام',
      },
    },
    analytics: {
      dashboardMenuGroup: 'لوحة القيادة',
      analytics: 'تحليلات',
      activitySummary: 'ملخص النشاط',
      timePeriod: 'الفترة الزمنية',
      monthly: 'شهريا',
      daily: 'اليومي',
      monthlySummary: 'ملخص النشاط الشهري',
      dailySummary: 'ملخص النشاط اليومي',
      month: 'شهر',
      day: 'Select date',
      totalProcessed: 'الوثائق المجهزة',
      processedByType: 'تمت معالجة المستندات حسب النوع',
      tableSummary:
        'يعرض هذا الجدول العدد الإجمالي للوثائق المستلمة والمرسلة للفترة المحددة.',
      processedByCompany: 'المستندات التي تمت معالجتها من قبل الشركة',
      documentType: 'حدد نوع الوثيقة',
      chartSummary: 'يعرض هذا المخطط أول 10 شركات فقط.',
      type: 'نوع',
      documentsReceived: 'تم استلام المستندات',
      documentsSent: 'المستندات المرسلة',
    },
    audit_trail: {
      show: 'سجل تدقيق',
      title: 'سجل تدقيق',
      categories: {
        FLOW_STARTED: 'بدء التدفق',
        DOCUMENT_RECEIVED: 'تم استلام الوثيقة',
        DOCUMENT_SENT: 'تم إرسال المستند',
        THIRD_PARTY_ACKNOWLEDGMENT: 'إقرار الطرف الثالث',
        DOCUMENT_REPRESENTATION_GENERATED: 'تم إنشاء التمثيل',
        DOCUMENT_ERROR: 'خطأ',
        DOCUMENT_VALIDATION: 'Document validation',
        WAIT_FOR_USER_ACTION: 'انتظر إجراء المستخدم',
        USER_ACTION_DONE: 'انتهى إجراء المستخدم',
        USER_ACTION_DELETED: 'Action cancelled',
        FLOW_END: 'نهاية التدفق',
        INCIDENT: 'حادث',
      },
      types: {
        // See audit-trail-1.0.sql for the event types allowed into the trail.
        P2P_INVOICE_FORMAT_ERROR: 'Bad format!',
        P2P_INVOICE_REJECTED_BY_RECIPIENT: 'Rejected by buyer',
        P2P_INVOICE_PAID_BY_RECIPIENT: 'Paid',
        P2P_INVOICE_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by buyer',
        P2P_INVOICE_RECEIVED_BY_BSP:
          'Reception acknowledged by Business Service Provider',
        P2P_INVOICE_REJECTED_BY_BSP: 'Rejected by Business Service Provider',
        P2P_INVOICE_CONTENT_ERROR: 'Content error!',
        P2P_INVOICE_CONTENT_WARNING: 'Content warning!',
        P2P_INVOICE_SENT: 'Invoice sent to buyer',
        P2P_INVOICE_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_INVOICE_END: 'End of processing',
        P2P_INVOICE_WAIT_STATUS: 'Waiting for buyer acknowledgment',
        P2P_INVOICE_ACCEPTED_BY_RECIPIENT: 'Accepted by buyer',
        P2P_INVOICE_WAITING_FOR_RECIPIENT: 'Waiting for buyer action',
        P2P_INVOICE_SENT_TO_REGULATOR: 'Invoice sent to the fiscal authority',
        P2P_INVOICE_WAIT_STATUS_FROM_REGULATOR:
          'Waiting for fiscal authority response',
        P2P_INVOICE_STATUS_FROM_REGULATOR: 'Fiscal authority response',
        P2P_INVOICE_PARTIALLY_PAID_BY_RECIPIENT: 'Partially paid by recipient',
        P2P_INVOICE_RECEIVED_AND_VALIDATED_BY_BSP:
          'Received and validated by Business Service Provider',

        P2P_INVOICE_UNDER_QUERY: 'Pending info',
        P2P_INVOICE_CONDITIONALLY_ACCEPTED: 'Conditionally accepted',
        P2P_INVOICE_IN_PROCESS: 'In process on recipient side',

        P2P_ORDER_CONTENT_ERROR: 'Content error!',
        P2P_ORDER_FORMAT_ERROR: 'Bad format!',
        P2P_ORDER_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_ORDER_SENT: 'Order sent to supplier',
        P2P_ORDER_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by supplier',
        P2P_ORDER_END: 'End of processing',

        P2P_ORDER_NOT_CHANGED: 'The order was not modified by the supplier',
        P2P_ORDER_CHANGED: 'The order was modified by the supplier',
        P2P_ORDER_RECEIVED_BY_SUPPLIER:
          'The order was received by the supplier',
        P2P_ORDER_REJECTED_BY_RECIPIENT:
          'The order was rejected by the recipient',
        P2P_ORDER_ACCEPTED_BY_RECIPIENT:
          'The order was accepted by the recipient',
        P2P_ORDER_ACCEPTED_BY_SUPPLIER:
          'The order was accepted by the supplier',
        P2P_ORDER_REJECTED_BY_SUPPLIER:
          'The order was rejected by the supplier',

        P2P_RECADV_CONTENT_ERROR: 'Content error!',
        P2P_RECADV_FORMAT_ERROR: 'Bad format!',
        P2P_RECADV_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_RECADV_SENT: 'Receipt advice sent to supplier',
        P2P_RECADV_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by supplier',
        P2P_RECADV_END: 'End of processing',

        P2P_DESADV_CONTENT_ERROR: 'Content error!',
        P2P_DESADV_FORMAT_ERROR: 'Bad format!',
        P2P_DESADV_RECEIVED_BY_DX: 'Received by DocProcess',
        P2P_DESADV_SENT: 'Despatch advice sent to buyer',
        P2P_DESADV_RECEIVED_BY_RECIPIENT: 'Reception acknowledged by buyer',
        P2P_DESADV_END: 'End of processing',

        USER_TASK_CREATED: {
          invoiceHoldDecisionTask: 'Manual reconciliation requested',
          cancellationDecisionTask: 'Manual cancellation requested',
          costCenterAllocationTask: 'Failed! Manual allocation requested',
          invoiceValidationTask: 'Manual validation requested',
        },
        USER_TASK_COMPLETED: {
          invoiceHoldDecisionTask: 'Reconciliation task done',
          cancellationDecisionTask: 'Cancellation task done',
          costCenterAllocationTask: 'Manual allocation done',
          invoiceValidationTask: 'Manual validation done',
        },
        USER_TASK_DELETED: {
          invoiceHoldDecisionTask: 'Reconciliation task deleted',
          cancellationDecisionTask: 'Cancellation task deleted',
          invoiceValidationTask: 'Validation task reassigned',
        },

        I2PR_STARTED: 'Matching with receipt advices started',
        I2PR_SUCCESS: 'Matching approved',
        I2PR_ERROR: 'Matching in error',
        I2PR_REJECTED: 'Matching rejected',
        I2PR_SKIPPED: 'Matching skipped',

        I2O_STARTED: 'Matching with orders started',
        I2O_SUCCESS: 'Matching approved',
        I2O_ERROR: 'Matching in error',
        I2O_REJECTED: 'Matching rejected',
        I2O_SKIPPED: 'Matching skipped',

        ALLOCATION_STARTED: 'Try to automatically allocate general expenses',
        ALLOCATION_DONE: 'Allocation registered',
        AUTOMATIC_ALLOCATION_DONE: 'Automatic allocation passed',

        // V2 events:
        // NEW => 'FLOW_STARTED'
        PROCESSED: 'Processed',
        ACCOUNT_CHECKED: 'Account checked',
        DELIVERED_FROM_FORMAT_ERROR: 'Delivered (format error)',
        REJECTED_BY_CUSTOMER: 'Rejected by customer',
        RECEIVED_BY_CUSTOMER: 'Received by customer',
        VALIDATED: 'Validated',
        DELIVERED_FROM_CONTENT_ERROR: 'Content Error!',
        DELIVERED: 'Sent',
        NOTIFIED: 'Notified',
        RECEIVED_BY_DX: 'Received by DocProcess',
        ACCEPTED_BY_CUSTOMER: 'Accepted by customer',
        PENDING_TO_CUSTOMER: 'Pending to customer',
      },
      noTrail: 'لا يوجد مسار تدقيق.',
      descriptions: {},
    },
    messagesCenter: {
      state: {
        DRAFT: 'Draft',
        PUBLISHED: 'Published',
        title: 'State',
        help: "Message visibility. The message can be created as DRAFT and won't be visible until an edition setting it as PUBLISHED. Setting its state to PUBLISHED at creation time will make it visible immediately",
      },
      topic: {
        title: 'Topic',
        help: "Defines a topic to identify your message. This topic is displayed in the user's notifications menu",
      },
      language: {
        title: 'Language',
        help: "The message visibility takes into account the user's language choice. You can then specialize the message according to the language",
      },
      link: {
        text: 'More details',
        href: 'More info link',
        public: 'Public Link',
        knowledgeBase: 'Knowledge base link',
        help: 'Optional "more info" link to an external public link (Knowledge base link = false) or a link to the knowledge base (Knowledge base link = true)',
        show: 'Don’t show again',
      },
      type: {
        BANNER: 'Banner',
        DOWNTIME: 'Downtime',
        MARKETING: 'News',
        GENERAL: 'Operations',
        title: 'Type',
        help: 'Message category. An <<Operations>> or a <<Marketing>> message is visible on the home page for all users.',
      },
      content: {
        title: 'Message content',
        help: 'Message content limited to 300 characters and which could be multilignes. Click on Show button for an idea of the rendering',
      },
    },
  }, // End of Arabic DxMessages
  resources: {
    'management/formattype': {
      name: 'Format type',
    },
    'management/dxrole': {
      name: 'Role',
    },
    // Override Arabic react-admin reserved messages
    'management/company': {
      name: 'Companies',
    },
    'management/supplier': {
      name: 'Suppliers',
    },
    'management/customer': {
      name: 'Customers',
    },
    'management/productusage': {
      name: 'Products',
    },
    'management/supportedproducts': {
      name: 'Supported Products',
    },
    'management/contact': {
      name: 'Contact',
    },
    'management/dropdowndefinition': {
      name: 'Dropdown Definition',
    },
    'management/dxproduct': {
      name: 'DX Product',
    },
    'management/accountrelation': {
      name: 'Account relation',
    },
    'management/address': {
      name: 'Addresses',
    },
    'management/bufferaddress': {
      name: 'Address import',
    },
    'management/peppol': {
      name: 'Peppol',
    },
    usermgmt: {
      name: 'Accounts',
    },
    'management/v2invoice': {
      name: 'V2 Invoices',
    },
    'management/v2order': {
      name: 'V2 Orders',
    },
    'management/v2receiptadvice': {
      name: 'V2 Receipt Advices',
    },
    'management/v2despatchadvice': {
      name: 'V2 Despatch Advices',
    },
    'efactura/monitoring/error': {
      name: 'Efactura Content Error',
    },
    'efactura/monitoring/rejected': {
      name: 'Efactura Rejected Documents',
    },
    customers: {
      name: 'العملاء',
      fields: {
        id: 'الشفرة',
        name: 'اسم',
      },
    },
    issuers: {
      name: 'المصدرين',
      fields: {
        id: 'الشفرة',
        name: 'اسم',
      },
    },
    invoice: {
      name: 'الفواتير',
    },
    despatchAdvice: {
      name: 'نصيحة إيفاد',
    },
    recadv: {
      name: 'إشعارات الاستلام',
    },
    feedbackMessage: {
      name: 'رسائل الملاحظات',
    },
    attachment: {
      name: 'المرفقات',
    },
    order: {
      name: 'الطلب',
    },
    delfor: {
      name: 'التنبؤ',
    },
    cntcnd: {
      name: 'انكماش',
    },
    catlog: {
      name: 'كتالوجات',
    },
    documents: {
      name: 'كافة المستندات',
    },
    waybill: {
      name: 'CMR',
    },
    webWaybill: {
      name: 'CMR',
    },
    webInvoice: {
      name: 'الفواتير',
    },
    webDespatchAdvice: {
      name: 'نصيحة إيفاد',
    },
    webReceiptAdvice: {
      name: 'Receipt Advices',
    },
    webOrder: {
      name: 'الطلب',
    },
    userprofiles: {
      name: 'ملفات تعريف المستخدم',
    },
    tasks: {
      name: 'مهامي',
      list: {
        title: 'مهامي',
      },
      edit: {
        title: 'مهمتك',
      },
    },
    'configuration-message': {
      name: 'Messages',
    },
  }, // End of Override Arabic react-admin reserved messages
  Forbidden: 'ممنوع',
  Unauthorized: 'غير مصرح',
  NoDocsFound: 'عذرا ، لا توجد وثائق',
};
