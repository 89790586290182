import { GA_EVENTS, sendGAEvent } from '@dx-ui/dx-common/src';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import {
  Create,
  OnSuccess,
  PasswordInput,
  SimpleForm,
  required,
  useDataProvider,
  useGetIdentity,
  useNotify,
  useTranslate,
} from 'react-admin';
import { Constants } from '../../constants';
import { useGAPageViews } from '../../utils';
import PasswordCriterias from '../PasswordCriterias';
import { isPasswordStrong } from '../PasswordManagementUtils';
import EditToolbar from './EditToolbar';

const useStyles = makeStyles(
  (theme) => ({
    noActions: {
      marginTop: 0,
    },
  }),
  { name: 'ChangePassword' }
);

interface ChangePasswordModel {
  oldPassword: string;
  password: string;
  confirmedPassword: string;
}

enum ErrorCode {
  PASSWORDS_DO_NOT_MATCH = 'PASSWORDS_DO_NOT_MATCH',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  MISSING_EMAIL_ADDRESS = 'MISSING_EMAIL_ADDRESS',
}

const ChangePassword: FC<any> = (props) => {
  const { username } = props;

  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const oldPasswordValid = async (value: string) => {
    try {
      const resource = `${Constants.RESOURCE_PASSWORD_MGMT}/check`;

      const response = await dataProvider.apiPost(resource, {
        oldPassword: value,
      });
      if (response.data.code === ErrorCode.WRONG_PASSWORD) {
        return 'dxMessages.auth.crt_password_invalid';
      }
    } catch (error) {
      if (error?.body?.code === ErrorCode.WRONG_PASSWORD) {
        return 'dxMessages.auth.crt_password_invalid';
      }
    }
  };

  const passwordsTheSame = (value, allValues: ChangePasswordModel) => {
    return allValues.password !== allValues.confirmedPassword
      ? translate('dxMessages.auth.change_password_match_error')
      : undefined;
  };

  const passwordStrength = (value) => {
    return !isPasswordStrong(value)
      ? translate('dxMessages.auth.password_criteria_error')
      : undefined;
  };

  const oldPasswordValidate = [
    required('dxMessages.auth.crt_password_required'),
    oldPasswordValid,
  ];
  const passwordValidate = [
    required(translate('dxMessages.auth.new_password_required')),
    passwordStrength,
  ];
  const confirmPasswordValidate = [
    required(translate('dxMessages.auth.confirm_password_required')),
    passwordsTheSame,
  ];

  const onFailure = (error) => {
    switch (error?.body?.code) {
      case ErrorCode.WRONG_PASSWORD:
        notify('dxMessages.auth.crt_password_invalid', 'error');
        break;
      case ErrorCode.PASSWORDS_DO_NOT_MATCH:
        notify('dxMessages.auth.change_password_match_error', 'error');
        break;
      default:
        notify('dxMessages.auth.change_password_error', 'error');
    }
  };

  const onSuccess: OnSuccess = (response) => {
    notify(
      'dxMessages.auth.change_password_confirmation',
      'info',
      undefined,
      undefined,
      50000
    );
    sendGAEvent(
      GA_EVENTS.categories.USER_MGMT.name,
      GA_EVENTS.categories.USER_MGMT.actions.CHANGE_PASSWWORD,
      account?.company?.cmsRootDir
    );
  };

  useGAPageViews();

  return (
    <Card>
      <Create
        resource={`${Constants.RESOURCE_PASSWORD_MGMT}/change/${username}`}
        {...props}
        onFailure={onFailure}
        onSuccess={onSuccess}
        classes={{ noActions: classes.noActions }}
        title={<></>}
      >
        <SimpleForm toolbar={<EditToolbar />} variant='standard'>
          <PasswordCriterias />
          <PasswordInput
            source='oldPassword'
            //@ts-ignore
            validate={oldPasswordValidate}
            label='dxMessages.auth.crt_password'
            autoComplete='new-password' // strangelly the way to disable the autofill
          />
          <PasswordInput
            source='password'
            validate={passwordValidate}
            label='dxMessages.auth.new_password'
          />
          <PasswordInput
            source='confirmedPassword'
            validate={confirmPasswordValidate}
            label='dxMessages.auth.confirm_password'
          />
        </SimpleForm>
      </Create>
    </Card>
  );
};

export default ChangePassword;
