import React from 'react';
import {
  AutocompleteInput,
  Create,
  maxLength,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';

export const DxProductCreate = (props: any) => {
  const notify = useNotify();

  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.created');
    redirect('list', props.basePath);
  };

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput
          label={'dxMessages.companyManagement.dxProduct.description'}
          source='description'
        />
        <TextInput
          label={'dxMessages.companyManagement.dxProduct.comments'}
          source='comments'
          validate={[maxLength(1000)]}
        />
        <SelectInput
          label={'dxMessages.companyManagement.dxProduct.flgActive'}
          source='flgActive'
          choices={[
            { id: 1, name: 'Active' },
            { id: 0, name: 'Inactive' },
          ]}
          validate={[required(), maxLength(50)]}
        />
        <ReferenceInput
          label='dxMessages.companyManagement.dxProduct.idProductGroup'
          source='idProductGroup'
          reference='management/dropdowndefinition'
          validate={required()}
          filterToQuery={(searchText) => ({
            description: [searchText],
            idType: '10',
          })}
          sort={{ field: 'description', order: 'ASC' }}
        >
          <AutocompleteInput optionText='description' fullWidth />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default DxProductCreate;
