import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { get } from 'lodash';
import * as React from 'react';
import { FC } from 'react';
import { BooleanInput, FieldProps, required, useLocale } from 'react-admin';
import { useFormState } from 'react-final-form';
import { Account } from '../../types';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * Warn the user to double check of all new account info before saving it.
 */
const CheckAndSaveSetp: FC<FieldProps<Account>> = () => {
  const classes = useStyles();
  const locale = useLocale();
  const { values } = useFormState({ subscription: onValueChangedSubscription });

  // If Doc Process identit(ies) were configured, warn about the password policy.

  // Get here the mail logins associated with the Doc Process identities, if any.
  const logins: Array<string> | undefined = get(values, 'person.identities', [])
    .filter((id: any) => get(id, 'provider.id') === 'Doc Process')
    .map((id: any) => id.id);

  // The email to notify about the dx identity creations.
  const email = get(values, 'person.email');

  switch (locale) {
    case 'fr':
      return (
        <Paper className={classes.paper}>
          <Typography>
            <DoneIcon style={{ color: 'green' }} /> Terminé!
            <br />
            <br />
            Vérifiez que toutes les informations sont correctes puis sauvez ce
            nouveau compte utilisateur.
            {logins && logins.length > 0 && (
              <>
                &nbsp;Une fois créé, <a href={`mailto:${email}`}>{email}</a>{' '}
                recevra un courrier électronique contenant un lien afin de
                configurer son mot de passe et d'accéder à l'application.
                <br />
                <br />
                Ce lien n'est valable que 10 jours donc <b>rappelez lui</b> d'y
                accéder dès que possible.
                <br />
                <br />
                Vous pouvez vérifier plus tard que l'utilisateur a bien réussi à
                configurer son mot de passe et à se connecter en allant dans son
                compte (dans
                <i>Utilisateurs &amp; Société &gt; Comptes Utilisateurs </i>),
                et en cliquant sur{' '}
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <i>Derniéres connexions</i>
                  <MenuOpenIcon />
                </div>
              </>
            )}
          </Typography>
          <br />
          <br />
          <div style={{ display: 'inline-flex' }}>
            <BooleanInput
              label=''
              source='termsAcceptance'
              validate={required()}
            />
            <Typography variant='caption'>
              En cochant cette case, vous acceptez les termes et les conditions
              d’utilisation de la plateforme DocProcess BEA. Les présentes
              conditions vous donnent droit à des services tels que l’accès à la
              plate-forme, le traitement et le téléchargement des documents,
              formation et support technique. Vous acceptez de maintenir la
              confidentialité et de protéger les informations disponibles sur la
              plateforme en tant que secret commercial en empêchant toute copie,
              leur utilisation ou divulgation non autorisée. DocProcess SA
              conserve tout titre et propriété de la plateforme DocProcess SA.
              Vous acceptez de ne pas procéder à l'ingénierie inverse, modifier,
              décrypter, extraire, désassembler, copier ou décompiler le
              Logiciel, ou autoriser quiconque à le faire.
            </Typography>
          </div>
        </Paper>
      );
    default:
      // en or default to en.
      return (
        <Paper className={classes.paper}>
          <Typography>
            <DoneIcon style={{ color: 'green' }} /> Congrats! You are done.
            <br />
            <br />
            Double check that all the information are correct, accept the terms
            and conditions of use below, then save the new account.
            {logins && logins.length > 0 && (
              <>
                &nbsp;After saving, <a href={`mailto:${email}`}>{email}</a> will
                receive an email with a link to configure his password and
                access the application.
                <br />
                <br />
                This link expires after ten days so{' '}
                <b>remind them of clicking it</b> as soon as possible.
                <br />
                <br />
                You can check later on whether the user succeeded in configuring
                their password and logging into the application by navigating to
                their account (in <i>User &amp; Company &gt; Accounts </i>), and
                clicking on{' '}
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <i>Last logins</i>
                  <MenuOpenIcon />
                </div>
              </>
            )}
          </Typography>
          <br />
          <br />
          <div style={{ display: 'inline-flex' }}>
            <BooleanInput
              label=''
              source='termsAcceptance'
              validate={required()}
            />
            <Typography variant='caption'>
              By checking this box, you accept the terms and conditions of use
              of the DocProcess BEA platform. These conditions entitle you to
              services such as access to the platform, processing and
              downloading of documents, training and technical support. You
              agree to maintain the confidentiality and protect the information
              available on the platform as a trade secret by preventing any
              unauthorized copying, use or disclosure. DocProcess SA retains all
              title and ownership of the DocProcess SA platform. You agree not
              to reverse engineer, modify, decrypt, extract, disassemble, copy
              or decompile the Software, or permit anyone else to do so.
            </Typography>
          </div>
        </Paper>
      );
  }
};

// Only trigger a render when the form values change.
const onValueChangedSubscription = { values: true };

export default CheckAndSaveSetp;
