import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import PersonIcon from '@material-ui/icons/Person';
import { FC } from 'react';
import { useQuery, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { DxTheme } from '../../../../types';
import cartouche from '../cartouche.png';
import cartoucheDark from '../cartoucheDark.png';

/**
 * Displays the count of invoices assigned to the user and waiting for his approval.
 */
const InvoiceWaitingForYourApproval: FC<IWidgetContent> = ({
  onTheShelves,
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Card classes={{ root: classes.card }}>
      <div className={classes.main}>
        <Grid container>
          <Grid item xs={3}>
            <Box className='icon'>
              <PersonIcon style={{ width: '48px', height: '48px' }} />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Typography color='textSecondary' align='right' noWrap>
              {translate(
                'dxMessages.dashboard.widgets.InvoiceWaitingForYourApproval.Count'
              )}
            </Typography>
            <Typography variant='h5' component='h2' noWrap align='right'>
              {/* When on the shelves, do not fetch the real count. */}
              {onTheShelves ? '123' : <InvoiceWaitingForYourApprovalCount />}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

const InvoiceWaitingForYourApprovalCount = () => {
  const theme = useTheme();
  const translate = useTranslate();

  // Be careful: useGetOne() breaks the browser on logout so 'useQuery' here.
  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'nwaymatching-statistics',
    payload: { id: 'myDueReconciliationTasks' },
  });

  if (error)
    return (
      <Tooltip
        title={translate('dxMessages.dashboard.OnErrorContactSupport', {
          error,
        })}
      >
        <ErrorIcon color='error' />
      </Tooltip>
    );
  if (loading) return <CircularProgress size='1em' />;

  return (
    <>
      {data!.count}{' '}
      {data!.overDues > 0 && (
        <Button
          size='small'
          color='primary'
          component={Link}
          to={{
            pathname: '/api/bpm/tasks',
            search: `filter={"duePeriod":"NOW"}`,
          }}
        >
          <span
            style={{ color: theme.palette.error.main, fontWeight: 'bolder' }}
          >{`(${data!.overDues} ${translate(
            'dxMessages.dashboard.widgets.InvoiceWaitingForYourApproval.overdue'
          )})`}</span>
        </Button>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: DxTheme) => ({
  main: {
    padding: 16,
    background: `url(${
      theme.palette.type === 'dark' ? cartoucheDark : cartouche
    }) no-repeat`,
    '& .icon': {
      color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440',
    },
  },
  card: {
    backgroundColor: 'transparent',
    backgroundImage: `linear-gradient(0deg, ${theme.colors.white}, transparent)`,
  },
}));

export default InvoiceWaitingForYourApproval;
