export interface IAddressSources {
  street: string;
  addStreet: string;
  number: string;
  city: string;
  postalCode: string;
  countrySubEntityCode: string;
  countryCode: string;
}
export interface IDocumentReferenceSources {
  uuid: string;
  number: string;
  issueDate: string;
  documentTypeCode: string;
}
export interface IContactSources {
  contactName: string;
  phone: string;
  email: string;
  teleFax: string;
}
export interface ILocationSources {
  name: string;
  gln: string;
  address: {
    source: string;
    fields: IAddressSources;
  };
}
export interface IPartySources {
  vatId: string;
  name: string;
  gln: string;
  contact: {
    source: string;
    fields: IContactSources;
  };
  postalAddress: {
    source: string;
    fields: IAddressSources;
  };
}

export interface IAmountSources {
  currency: string;
  value: string;
}

export interface IDeliverySources {
  actualDeliveryDate: string;
  actualDeliveryTime: string;
}

export interface ILineItemSources {
  buyersItemIdentification: string;
  description: string;
  sellersItemIdentification: string;
  standardItemIdentification: string;
}
export interface IUBLCommonSources {
  DocumentReferenceSources(): IDocumentReferenceSources;
  AmountSources(): IAmountSources;
  ContactSources(): IContactSources;
  AddressSources(): IAddressSources;
  LocationSources(): ILocationSources;
  PartySources(): IPartySources;
  DeliverySources(): IDeliverySources;
  LineItemSources(): ILineItemSources;
}
class UblCommonSources implements IUBLCommonSources {
  constructor(public web: boolean) {}
  public DocumentReferenceSources = (): IDocumentReferenceSources => {
    return {
      uuid: this.web ? 'UUID[0].IdentifierContent' : 'UUID.value',
      number: this.web ? 'ID[0].IdentifierContent' : 'id.value',
      issueDate: this.web ? 'IssueDate[0].DateContent' : 'issueDate.value',
      documentTypeCode: this.web
        ? 'DocumentTypeCode[0].CodeContent'
        : 'documentTypeCode.value',
    };
  };

  public AmountSources = () => {
    return {
      currency: this.web ? 'AmountCurrencyIdentifier' : 'currencyID',
      value: this.web ? 'AmountContent' : 'value',
    };
  };

  public ContactSources = (): IContactSources => {
    return {
      contactName: this.web ? 'Name[0].TextContent' : 'name.value',
      phone: this.web ? 'Telephone[0].TextContent' : 'telephone.value',
      email: this.web
        ? 'ElectronicMail[0].TextContent'
        : 'electronicMail.value',
      teleFax: this.web ? 'Telefax[0].TextContent' : 'telefax.value',
    };
  };

  public AddressSources = (): IAddressSources => {
    return {
      street: this.web ? 'StreetName[0].TextContent' : 'streetName.value',
      addStreet: this.web
        ? 'AdditionalStreetName[0].TextContent'
        : 'additionalStreetName.value',
      number: this.web
        ? 'BuildingNumber[0].TextContent'
        : 'buildingNumber.value',
      city: this.web ? 'CityName[0].TextContent' : 'cityName.value',
      postalCode: this.web ? 'PostalZone[0].TextContent' : 'postalZone.value',
      countrySubEntityCode: this.web
        ? 'CountrySubentityCode[0].TextContent'
        : 'countrySubentityCode.value',
      countryCode: this.web
        ? 'Country[0].IdentificationCode[0].CodeContent'
        : 'country.identificationCode.value',
    };
  };

  public LocationSources = (): ILocationSources => {
    return {
      name: this.web ? 'Name[0].TextContent' : 'name.value',
      gln: this.web ? 'ID[0].IdentifierContent' : 'id.value',
      address: {
        source: this.web ? 'Address[0]' : 'address',
        fields: this.AddressSources(),
      },
    };
  };

  public PartySources = (): IPartySources => {
    return {
      vatId: this.web
        ? 'PartyIdentification[0].ID[0].IdentifierContent'
        : 'partyIdentification[0].id.value',
      name: this.web
        ? 'PartyName[0].Name[0].TextContent'
        : 'partyName[0].name.value',
      contact: {
        source: this.web ? 'Contact[0]' : 'contact',
        fields: this.ContactSources(),
      },
      gln: this.web ? 'EndpointID[0].IdentifierContent' : 'endpointID.value',
      postalAddress: {
        source: this.web ? 'PostalAddress[0]' : 'postalAddress',
        fields: this.AddressSources(),
      },
    };
  };

  public DeliverySources = (): IDeliverySources => {
    return {
      actualDeliveryDate: this.web
        ? 'ActualDeliveryDate[0].DateContent'
        : 'actualDeliveryDate.value',
      actualDeliveryTime: this.web
        ? 'ActualDeliveryTime[0].TimeContent'
        : 'actualDeliveryTime.value',
    };
  };

  public LineItemSources = (): ILineItemSources => {
    return {
      buyersItemIdentification: this.web
        ? 'BuyersItemIdentification[0].ID[0].IdentifierContent'
        : 'buyersItemIdentification.id.value',
      standardItemIdentification: this.web
        ? 'StandardItemIdentification[0].ID[0].IdentifierContent'
        : 'standardItemIdentification.id.value',
      description: this.web
        ? 'Description[0].TextContent'
        : 'description[0].value',
      sellersItemIdentification: this.web
        ? 'SellersItemIdentification[0].ID[0].IdentifierContent'
        : 'sellersItemIdentification.id.value',
    };
  };
}

export default UblCommonSources;
