import romanianMessages from '@docprocess/ra-language-romanian';
import merge from 'deepmerge';
import arabicMessages from 'ra-language-arabic';
import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import { arDomainMessages as arGeneralDomainMessages } from './ar-domainMessages';
import enDomainMessages from './en-domain';
import { enDomainMessages as enGeneralDomainMessages } from './en-domainMessages';
import frDomainMessages from './fr-domain';
import { frDomainMessages as frGeneralDomainMessages } from './fr-domainMessages';
import { heDomainMessages as heGeneralDomainMessages } from './he-domainMessages';
import { hebrewMessages } from './hebrewRACustomMessages';
import roDomainMessages from './ro-domain';
import { roDomainMessages as roGeneralDomainMessages } from './ro-domainMessages';

// Aggregate react-admin and application specific I18N messages
const messages = {
  ar: { ...arabicMessages, ...arGeneralDomainMessages },
  en: {
    ...englishMessages,
    ...merge.all([enGeneralDomainMessages, enDomainMessages]),
  },
  fr: {
    ...frenchMessages,
    ...merge.all([frGeneralDomainMessages, frDomainMessages]),
  },
  he: {
    ...hebrewMessages,
    ...heGeneralDomainMessages,
  },
  ro: {
    ...romanianMessages,
    ...merge.all([roGeneralDomainMessages, roDomainMessages]),
  },
};

const getMessages = (locale: string) => {
  return messages[locale];
};

const getDirection = (locale: string) => {
  let direction = 'ltr';
  switch (locale) {
    case 'ar':
    case 'he':
      direction = 'rtl';
      break;
    default:
      direction = 'ltr';
  }
  return direction;
};

const getAvailableTranslatedLocales = () => {
  return Object.keys(messages);
};

/**
 * Retrieves the locale choice eventually saved on local storage.
 * The "old fashion" local storage entry will be removed by LocaleSwitcher.
 */
const getUserLocaleSavedOnlocalStorage = () => {
  let localStorageLanguage;
  if (typeof localStorage !== 'undefined') {
    // Old fashion (before react-admin user preferences introduction).
    // Retrieves current user
    const user = localStorage.getItem('user');
    localStorageLanguage = localStorage.getItem(user + '.language');
    if (localStorageLanguage) {
      localStorageLanguage = localStorageLanguage
        .toLowerCase()
        .split(/[_-]+/)[0];
    }
    if (!localStorageLanguage) {
      // let's have a try with react-admin user prefs saved value
      const prefs = localStorage.getItem('preferences');
      if (prefs) {
        const savedLocale = JSON.parse(prefs)?.locale;
        localStorageLanguage = savedLocale?.toLowerCase()?.split(/[_-]+/)[0];
      }
    }
  }

  return localStorageLanguage;
};

/**
 * Retrieves the locale from the navigator settings
 */
const getBrowserLocale = () => {
  let browserLocale =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator['userLanguage'];

  if (browserLocale) {
    browserLocale = browserLocale.toLowerCase().split(/[_-]+/)[0];
  }
  return browserLocale;
};

/**
 * Retrieves the locale from the user local storage settings || browser || default(en)
 */
const getLocaleInfo = () => {
  const availableLocales = getAvailableTranslatedLocales();
  const userLocale = getUserLocaleSavedOnlocalStorage();
  if (userLocale && availableLocales.indexOf(userLocale) !== -1) {
    return userLocale;
  }
  const browserLocale = getBrowserLocale();
  if (browserLocale && availableLocales.indexOf(browserLocale) !== -1) {
    return browserLocale;
  }
  return 'en';
};

export {
  getUserLocaleSavedOnlocalStorage,
  getBrowserLocale,
  getDirection,
  getLocaleInfo,
  getMessages,
  getAvailableTranslatedLocales,
};
