import {
  GA_EVENTS,
  isPspAdministrator,
  sendGAEvent,
  useGAPageViews,
} from '@dx-ui/dx-common/src';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DeleteWithConfirmButton,
  ExportButton,
  Filter,
  FilterProps,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TopToolbar,
  useGetIdentity,
  useNotify,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { Empty } from '../../common/Empty';

const SupportedProductsFilters = (props: Omit<FilterProps, 'children'>) => {
  const { permissions, loaded } = usePermissions();
  if (!loaded || !permissions) return null;

  return (
    <Filter {...props}>
      {isPspAdministrator(permissions) && (
        <ReferenceInput
          label='resources.accounts.list.headers.company'
          source='idaccounts'
          reference='management/company'
          filterToQuery={(searchText) => ({
            accountname: [searchText],
          })}
          sort={{ field: 'accountname', order: 'ASC' }}
          allowEmpty={true}
          alwaysOn
        >
          <AutocompleteInput
            optionText='accountname'
            optionValue='id'
            resettable={true}
          />
        </ReferenceInput>
      )}
      {isPspAdministrator(permissions) && (
        <ReferenceInput
          label={'dxMessages.companyManagement.productUsage.product'}
          source='iddxproduct'
          reference='management/dxproduct'
          filterToQuery={(searchText) => ({
            accountname: [searchText],
          })}
          sort={{ field: 'description', order: 'ASC' }}
          allowEmpty={true}
          alwaysOn
        >
          <AutocompleteInput
            optionText='description'
            optionValue='id'
            resettable={true}
          />
        </ReferenceInput>
      )}
    </Filter>
  );
};

const SupportedProductsList = (props) => {
  const notify = useNotify();
  const { permissions, loaded } = usePermissions();
  const translate = useTranslate();
  useGAPageViews();
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const ListActions = () => {
    return (
      <TopToolbar>
        <CreateButton />
        <ExportButton maxResults={5000} />
      </TopToolbar>
    );
  };

  if (!loaded || !permissions) return null;
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<SupportedProductsFilters />}
      empty={isPspAdministrator(permissions) ? <Empty /> : false}
      actions={<ListActions />}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        {isPspAdministrator(permissions) && (
          <ReferenceField
            label='resources.accounts.list.headers.company'
            source='idaccounts'
            reference='management/company'
            link={false}
            sortable={false}
          >
            <TextField source='accountname' />
          </ReferenceField>
        )}
        <ReferenceField
          label={'dxMessages.companyManagement.productUsage.product'}
          source='iddxproduct'
          reference='management/dxproduct'
          link={false}
          sortable={false}
        >
          <TextField source='description' />
        </ReferenceField>
        <DeleteWithConfirmButton
          onClick={() =>
            sendGAEvent(
              GA_EVENTS.categories.PRODUCT_USAGE_MGMT.name,
              GA_EVENTS.categories.PRODUCT_USAGE_MGMT.actions
                .DELETE_PRODUCT_USAGE,
              account?.company?.cmsRootDir
            )
          }
          onFailure={(error) => {
            if (error.status === 400) {
              notify(
                'dxMessages.companyManagement.supportedProducts.delete_supportedproduct_error',
                'error',
                {
                  _: 'Unable to delete the supported product',
                  error: translate(
                    'dxMessages.companyManagement.supportedProducts.delete_supportedproduct_error'
                  ),
                }
              );
            } else {
              notify(
                'dxMessages.companyManagement.supportedProducts.delete_supportedproduct_error',
                'error',
                {
                  _: 'Unable to delete the supported product',
                  error: `${error}`,
                }
              );
            }
          }}
        />
      </Datagrid>
    </List>
  );
};

export default SupportedProductsList;
