import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';
import 'filepond/dist/filepond.min.css';
import { useRefresh, useTranslate } from 'react-admin';
import { ProcessStatus } from '../../../constants';
import AttachmentsRefreshButton from './AttachmentsRefreshButton';
import { AttachmentFile, AttachmentPolicy } from './types';
import { UploadFilesField } from './UploadFilesField';

export interface UploadFilesFieldWithPolicyProps {
  postUrl: string;
  resource: string;
  recipientId: string;
  processStatus: ProcessStatus;
  invoiceType?: string;
  existingAttachments?: AttachmentFile[];
  onAddNew?: (attachment: AttachmentFile) => void;
  onReload?: () => void;
  loading: boolean;
  attachPolicy: AttachmentPolicy | undefined;
  attachPolicyError: string | undefined;
}

export const DEFAULT_INVOICE_TYPE = 'DEFAULT';

export const UploadFilesFieldWithPolicy = ({
  postUrl,
  resource,
  recipientId,
  processStatus,
  invoiceType = DEFAULT_INVOICE_TYPE,
  existingAttachments = [],
  onAddNew,
  onReload,
  loading,
  attachPolicy,
  attachPolicyError,
}: UploadFilesFieldWithPolicyProps) => {
  const translate = useTranslate();
  const refresh = useRefresh();

  const handleRefreshAttachments = () => {
    if (typeof onReload === 'function') {
      onReload();
    } else {
      refresh();
    }
  };

  if (attachPolicyError) {
    return (
      <Box display='flex' justifyContent='center' p={2}>
        <FormHelperText error>
          {translate('dxMessages.uploadFiles.uploadInitError')}
        </FormHelperText>
      </Box>
    );
  } else if (loading) {
    return (
      <Box display='flex' justifyContent='center' p={2}>
        <CircularProgress size={24} />
      </Box>
    );
  } else {
    if (!attachPolicy) {
      return null; // should not happen
    }

    // this should not happen but check anyway
    if (
      !attachPolicy.maxFileSize ||
      !attachPolicy.maxNumberOfAccepted ||
      !attachPolicy.codeAndStatuses?.length
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        'Attachment policy settings are not correct %o',
        attachPolicy
      );

      return null;
    }

    // check invoice type and process status
    const showUploadFiles = attachPolicy.codeAndStatuses.some(
      (setting) =>
        (setting?.invoiceType === DEFAULT_INVOICE_TYPE ||
          setting?.invoiceType === invoiceType) &&
        setting?.processStatus?.indexOf(processStatus) > -1
    );
    // max number of files we can upload / attach
    const maxFiles = attachPolicy.maxNumberOfAccepted;

    if (!showUploadFiles) {
      return null;
    } else {
      return (
        <>
          {existingAttachments.length < maxFiles ? (
            <>
              <Box pb={1} pt={1}>
                <Alert
                  severity='info'
                  action={
                    <AttachmentsRefreshButton
                      color='inherit'
                      size='small'
                      onClick={handleRefreshAttachments}
                    />
                  }
                >
                  {translate('dxMessages.uploadFiles.uploadRefreshInfo')}
                </Alert>
              </Box>
              <UploadFilesField
                postUrl={postUrl}
                maxFileSize={attachPolicy?.maxFileSize}
                maxFiles={maxFiles}
                onAddNew={onAddNew}
              />
            </>
          ) : (
            <Box display='flex' justifyContent='center' p={2}>
              <FormHelperText>
                {translate('dxMessages.uploadFiles.maxFilesReached')}
              </FormHelperText>
            </Box>
          )}
        </>
      );
    }
  }
};
