import { Constants } from '@dx-ui/dx-common';
import { Button } from '@material-ui/core';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';

export const AccountRelationCreate: any = ({ setCreate, ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('resources.address.edit.notifications.created');
    if (setCreate !== undefined) {
      setCreate(false);
    } else {
      redirect('list', props.basePath);
    }
  };

  // for psp admin suppliers tab is before customer tab and when we create one
  // of them we want to know in which tab we are for having the id auto-completed
  const findRole = () => {
    const supplierTab = parseInt(
      props.location.pathname.charAt(props.location.pathname.length - 1)
    );
    return supplierTab;
  };

  const CustomCreateToolbar = (props) => (
    <Toolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <SaveButton />
      <CancelButton />
    </Toolbar>
  );

  const CancelButton = () => {
    const translate = useTranslate();
    return (
      <Button
        onClick={() => {
          if (setCreate !== undefined) {
            setCreate(false);
          } else {
            redirect('list', props.basePath);
          }
        }}
        variant='contained'
      >
        {translate('ra.action.cancel')}
      </Button>
    );
  };

  return (
    <Create
      {...props}
      onSuccess={onSuccess}
      resource={Constants.RESOURCE_SUPPLIERS}
    >
      <SimpleForm toolbar={<CustomCreateToolbar />}>
        <ReferenceInput
          label='dxMessages.companyManagement.accountRelation.customer'
          source='idcustomer'
          reference='management/company'
          link={false}
          filterToQuery={(searchText) => ({
            accountname: [searchText],
          })}
          sort={{ field: 'accountname', order: 'ASC' }}
          validate={required()}
          defaultValue={findRole() === 5 ? parseInt(props?.id) : ''}
          disabled={findRole() === 5 && props.id}
        >
          <AutocompleteInput
            source='accountname'
            optionText='accountname'
            fullwidth
          />
        </ReferenceInput>

        <ReferenceInput
          label='dxMessages.companyManagement.accountRelation.supplier'
          source='idsupplier'
          reference='management/company'
          link={false}
          filterToQuery={(searchText) => ({
            accountname: [searchText],
          })}
          sort={{ field: 'accountname', order: 'ASC' }}
          validate={required()}
          defaultValue={findRole() === 6 ? parseInt(props?.id) : ''}
          disabled={findRole() === 6 && props.id}
        >
          <AutocompleteInput
            source='accountname'
            optionText='accountname'
            fullwidth
          />
        </ReferenceInput>

        <TextInput
          label={
            'dxMessages.companyManagement.accountRelation.suppliercode4customer'
          }
          source='supplierCode4Customer'
        />
      </SimpleForm>
    </Create>
  );
};

export default AccountRelationCreate;
