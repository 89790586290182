// The summary of an invoice.
import { Grid, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { FC } from 'react';

const OrderSummaryField: FC<OrderSummaryFieldProps> = ({
  order,
  icon,
  subtitle,
}) => {
  const issuer = order?.BuyerCustomerParty?.Party?.PartyName.map(
    (pn: any) => pn?.Name?.value
  ).find((name: any) => !!name);

  const issuerId =
    order?.AccountingCustomerParty?.Party?.PartyIdentification.find(
      (pi: any) => pi.ID && 'VAT' === pi.ID?.schemeID?.toUpperCase().trim()
    )?.ID.value || 'No issuer ID';

  const recipient = order?.SellerSupplierParty?.Party?.PartyName.map(
    (pn: any) => pn?.Name?.value
  ).find((name: any) => !!name);

  const recipientId =
    order?.SellerSupplierParty?.Party?.PartyIdentification.find(
      (pi: any) => pi.ID
    )?.ID.value || 'No recipient ID';

  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <Typography
          style={{
            fontWeight: 'bolder',
            fontSize: 'larger',
            wordBreak: 'break-all',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {icon && icon}&nbsp;<span>№ {order?.ID?.value?.trim()}</span>
        </Typography>
        <Typography variant='caption' style={{ color: 'gray' }}>
          {subtitle}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant='caption'>Issue date</Typography>
            <br />
            {order?.IssueDate.value &&
              moment(order.IssueDate.value).format('ll')}
          </Grid>
          <Grid item xs={4}>
            <Typography variant='caption'>Issuer</Typography>
            <br />
            <Tooltip title={issuerId} placement='top'>
              <span>{issuer ? issuer : 'REGINA MARIA'}</span>
            </Tooltip>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='caption'>Recipient</Typography>
            <br />
            <Tooltip title={recipientId} placement='top'>
              <span>{recipient ? recipient : '-'}</span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

type UblOrder = any;
interface OrderSummaryFieldProps {
  order: UblOrder;
  icon?: React.ReactNode;
  subtitle?: string;
}

export default OrderSummaryField;
