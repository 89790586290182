import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import HttpStatus from 'http-status-codes';
import { useState } from 'react';
import {
  TopToolbar,
  useAuthProvider,
  useNotify,
  useRedirect,
  useTranslate,
} from 'react-admin';
import { Field, Form } from 'react-final-form';
import Recaptcha from 'react-recaptcha';
import { Button } from '../components';
import { DxTheme } from '../types';
import Input from './Input';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    form: {
      padding: '0 1em 1em 1em',
      '& .recaptcha': {
        paddingTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
      },
    },
    input: {
      marginTop: '1em',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    info: {
      maxWidth: 400,
      '&>div': {
        paddingBottom: 10,
      },
    },
    cancelButton: {
      marginLeft: '0.5em',
      backgroundColor: theme.palette.secondary.light,
      color: theme.colors.mainColor4,
      '&:hover': {
        backgroundColor: theme.colors.blue[100],
      },
    },
  }),
  { name: 'SendLostPasswordEmailForm' }
);

const SendLostPasswordEmailForm = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const authProvider = useAuthProvider();

  const [loading, setLoading] = useState(false);

  const [recaptchaValidated, setRecaptchaValidated] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

  const validate = (values) => {
    const errors: {
      username: string | undefined;
    } = { username: undefined };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }

    return errors;
  };

  const submit = async (values) => {
    if (!recaptchaValidated) {
      return;
    }

    try {
      setLoading(true);
      const response: any = await authProvider.lostPassword({
        username: values.username,
        application: '',
        referer: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
      });
      setLoading(false);

      if (response.status === HttpStatus.TOO_MANY_REQUESTS) {
        // request sent too many times
        setLoading(false);
        notify(
          'dxMessages.auth.token_too_many_attempts',
          'warning',
          undefined,
          undefined,
          30000
        );
      } else if (response.status < 200 || response.status >= 300) {
        // generate error
        throw new Error(
          `Error during password change. Http status returned: ${response.status}.`
        );
      } else {
        // OK
        notify(
          'dxMessages.auth.lost_password_email_notif',
          'info',
          undefined,
          undefined,
          30000
        );
        redirect('/login');
      }
    } catch (error) {
      setLoading(false);
      notify('dxMessages.auth.lost_password_error', 'error');
    }
  };

  const onVerifyRecaptcha = () => {
    setRecaptchaValidated(true);
  };

  const onLoadRecaptcha = () => {
    setRecaptchaLoaded(true);
  };

  const handleOnCancel = () => {
    redirect('/login');
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <h2>{translate('dxMessages.auth.lost_password_title')}</h2>
            <span>{translate('dxMessages.auth.lost_password_desc')}</span>
            <div className={classes.input}>
              <Field
                autoFocus
                id='username'
                name='username'
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </div>
            <div className='recaptcha'>
              {!recaptchaLoaded && <span>reCAPTCHA</span>}
              <Recaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                render='explicit'
                onloadCallback={onLoadRecaptcha}
                verifyCallback={onVerifyRecaptcha}
              />
            </div>
          </div>
          <TopToolbar>
            <Button
              label='dxMessages.buttons.send'
              icon={<SendIcon />}
              type='submit'
              disabled={
                loading || submitting || pristine || !recaptchaValidated
              }
              loading={loading}
              color='primary'
            />
            <Button
              className={classes.cancelButton}
              onClick={handleOnCancel}
              label='ra.action.cancel'
              icon={<CancelIcon />}
              disabled={loading || submitting}
              color='secondary'
            />
          </TopToolbar>
        </form>
      )}
    />
  );
};

export default SendLostPasswordEmailForm;
