import React from 'react';

const AnnouncementsContext = React.createContext({
  announcement: undefined,
  onDismissAnnouncement: () => {},
});

export default AnnouncementsContext;
export const AnnouncementsProvider = AnnouncementsContext.Provider;
export const AnnouncementsConsumer = AnnouncementsContext.Consumer;
