import {
  jssPreset,
  makeStyles,
  StylesProvider,
} from '@material-ui/core/styles';
import { PreferencesContextProvider } from '@react-admin/ra-preferences';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { Layout, useLocale } from 'react-admin';
import { SideBarMenu } from '.';
import { AnnouncementsConsumer } from '../announcements/AnnouncementsContext';
import AnnouncementsFetcher from '../announcements/AnnouncementsFetcher';
import { getDirection } from '../intl/LocaleInfo';
import { muiTheme } from '../themes/CreateTheme';
import { DxTheme } from '../types';
import { useDelightedSurvey } from './../utils';
import DxAppBar from './DxAppBar';

interface StyleProps {
  announcementsVisible: boolean;
}

const useStyles = makeStyles<DxTheme, StyleProps>(
  (theme) => ({
    appFrame: (props) => ({
      marginTop: props.announcementsVisible ? 80 : 48,
    }),
    content: {
      padding: 0,
    },
  }),
  { name: 'DxLayout' }
);

export const DxLayout = (props) => {
  const locale = useLocale();
  const dir = getDirection(locale);
  // Configure JSS
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  useDelightedSurvey();

  return (
    <div dir={dir}>
      <StylesProvider jss={jss}>
        <AnnouncementsFetcher>
          <AnnouncementsConsumer>
            {({ announcement }) => (
              <StyledDxLayout
                {...props}
                appBar={DxAppBar}
                menu={SideBarMenu}
                announcementsVisible={!!announcement}
                theme={{ ...muiTheme, direction: dir }}
              />
            )}
          </AnnouncementsConsumer>
        </AnnouncementsFetcher>
      </StylesProvider>
    </div>
  );
};

const StyledDxLayout = ({ announcementsVisible, ...props }) => {
  const classes = useStyles({
    announcementsVisible,
  });

  return (
    <PreferencesContextProvider synchronize>
      <Layout {...props} classes={classes} />
    </PreferencesContextProvider>
  );
};
