// A task card displaying a summary of an invoice.
// It expects a UBL invoice.
//
// Use in the task list for example.
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    invoiceId: {
      fontWeight: 'bolder',
      fontSize: 'larger',
      wordBreak: 'break-all',
      display: 'flex',
      alignItems: 'center',
    },
    correctionChain: {
      color: 'gray',
      marginLeft: '1em',
    },
  }),
  { name: 'InvoiceSummaryField' }
);

enum InvoiceTypeCode {
  COMMERCIAL = '380',
  CANCEl = '381',
}

const i18nKey =
  'dxMessages.task.invoice_matching.invoiceHoldDecisionTask.cockpit.';

const InvoiceSummaryField: FC<InvoiceSummaryFieldProps> = ({
  invoice,
  icon,
  correctionInvoiceChain,
}) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (!invoice) return null;

  const typeCode = get(invoice, 'InvoiceTypeCode.value');

  // In case of cancel or corrective invoice, get the targeted invoice:
  const referencedInvoice = get(
    invoice,
    'BillingReference[0].InvoiceDocumentReference.ID.value'
  );

  const recipient = get(invoice, 'AccountingCustomerParty.Party.PartyName', [])
    .map((pn: any) => pn?.Name?.value)
    .find((name: any) => !!name);
  const issuer = get(invoice, 'AccountingSupplierParty.Party.PartyName', [])
    .map((pn: any) => pn?.Name?.value)
    .find((name: any) => !!name);
  const orderID = invoice?.OrderReference?.ID?.value;

  return (
    <Grid container direction='column'>
      <Grid item style={{ marginBottom: '5px' }}>
        <Typography className={classes.invoiceId}>
          {icon && icon}&nbsp;<span>№ {invoice.ID.value.trim()}</span>
        </Typography>
        {correctionInvoiceChain && (
          <Grid item>
            <Typography variant='caption' className={classes.correctionChain}>
              <Tooltip title={translate(i18nKey + 'correctionChain')}>
                <span>{translate(i18nKey + 'correcting')}</span>
              </Tooltip>
              :&nbsp;
              <span style={{ color: 'black', wordBreak: 'break-all' }}>
                {correctionInvoiceChain
                  .slice(1) // Remove myself.
                  .map((invoice) => invoice.ID.value.trim())
                  .join()}
              </span>
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant='caption'>
              {translate('dxMessages.headers.issueDate')}
            </Typography>
            <Typography>
              {invoice?.IssueDate.value &&
                moment(invoice.IssueDate.value).format('ll')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='caption'>
              {translate('dxMessages.headers.recipientName')}
            </Typography>
            <Typography>{recipient ? recipient : '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='caption'>
              {translate('dxMessages.headers.issuerName')}
            </Typography>
            <Typography>{issuer ? issuer : '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {typeCode === InvoiceTypeCode.CANCEl && (
        <Grid item>
          <Typography>
            <span style={{ fontWeight: 'bolder' }}>
              {translate('dxMessages.task.invoiceToCancel')}:
            </span>{' '}
            № {referencedInvoice}
          </Typography>
        </Grid>
      )}
      {orderID && (
        <Grid item>
          <Typography>
            <span style={{ fontWeight: 'bolder' }}>
              {translate('dxMessages.task.order')}:
            </span>{' '}
            № {orderID}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

type UblInvoice = any;
interface InvoiceSummaryFieldProps {
  invoice: UblInvoice;
  correctionInvoiceChain?: Array<UblInvoice>;
  icon?: React.ReactNode;
  subtitle?: string;
}

export default InvoiceSummaryField;
