import { Chip } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { FieldTitle, InputProps } from 'ra-core';
import { FunctionComponent } from 'react';
import {
  InputHelperText,
  ResettableTextField,
  sanitizeInputRestProps,
} from 'react-admin';
import useInput from './useInput';

export type TextInputProps = InputProps<TextFieldProps> &
  Omit<TextFieldProps, 'label' | 'helperText'>;

/**
 * An Input component for a string
 *
 * @example
 * <TextInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
const TextInput: FunctionComponent<TextInputProps> = ({
  label,
  format,
  helperText,
  titleSuffix,
  onBlur,
  onFocus,
  onChange,
  options,
  parse,
  resource,
  source,
  validate,
  defaultValue,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, submitError },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'text',
    validate,
    defaultValue,
    ...rest,
  });

  return (
    <ResettableTextField
      id={id}
      {...input}
      label={
        label !== '' &&
        label !== false && (
          <>
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
            {titleSuffix &&
              titleSuffix.map((s) => {
                return (
                  <Chip
                    label={s}
                    size='small'
                    style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                    key={s}
                  />
                );
              })}
          </>
        )
      }
      error={!!(error || submitError)}
      helperText={
        <InputHelperText
          touched={true}
          error={error || submitError}
          helperText={helperText}
        />
      }
      {...options}
      {...sanitizeInputRestProps(rest)}
    />
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
};

TextInput.defaultProps = {
  options: {},
};

export default TextInput;
