import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC } from 'react';
import {
  ArrayInput,
  FieldProps,
  Loading,
  SimpleFormIterator,
  useAuthProvider,
  useGetOne,
  useTranslate,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { Account, AccountCreationFormValues, Company } from '../../types';
import ConfigurableUsageRight from '../ConfigurableUsageRight';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The step in the account creation wizard where the user select usage rights assigned
 * to the account.
 *
 * @see AccountCreate
 */
const UsageRightsSelectionStep: FC<FieldProps<Account>> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { values } = useFormState<AccountCreationFormValues>({
    subscription: onValuesChangeSubscription,
  });
  const authProvider = useAuthProvider();
  const identity: any = authProvider.getIdentity?.();

  if (!identity) return null;

  // If the company ID is not set into the form, default to the company of the user.
  // (the list of selectable usage rights depends on the company - taht is the features
  // the company subscribed for).
  const companyId = values.company?.id || identity?.company.id;

  return (
    <Paper className={classes.paper}>
      <Typography variant='caption'>
        {translate(
          'resources.accounts.create.wizard.steps.usageRightsSelection.subtitle'
        )}
      </Typography>
      <ConfiguredUsageRights companyId={companyId} />
    </Paper>
  );
};

const ConfiguredUsageRights: FC<FieldProps<Account> & { companyId: string }> =
  ({ companyId }) => {
    // Fetch the company to get all the features it subscribed for,
    const {
      data: company,
      loading,
      error,
    } = useGetOne<Company>('configuration-companies', companyId);

    if (loading) return <Loading />;
    if (!company || error) return null;

    // Build the features (and the associated roles) the user can choose.
    // Result ex: features = { 'administration': ['company administrator', 'PSP administrator'], ... }
    const features = company.subscriptions.reduce<
      Record<string, Array<string>>
    >((acc, s) => {
      // The selectable roles for each feature are all the roles defined by the feature (availableRoles)
      // minus the excluded ones by the company subscription.
      acc[s.feature.id] = s.feature.availableRoles
        .filter((r) => !s.excludedRoleIds.includes(r.id))
        .map((r) => r.id);
      return acc;
    }, {});

    const validate = (values) => {
      if (!values) return undefined;

      // No duplicate feature.
      const featureIds = values.filter((ur) => !!ur).map((ur) => ur.feature.id);
      const duplicates = featureIds.filter(
        (f, i) => featureIds.indexOf(f) !== i
      );

      if (duplicates.length !== 0)
        return 'resources.accounts.create.wizard.steps.usageRightsSelection.errors.duplicateFeatures';
    };

    return (
      <ArrayInput label='' source='usageRights' validate={validate}>
        <SimpleFormIterator>
          <ConfigurableUsageRight features={features} />
        </SimpleFormIterator>
      </ArrayInput>
    );
  };

// Only trigger a render when the form values change
const onValuesChangeSubscription = { values: true };

export default UsageRightsSelectionStep;
