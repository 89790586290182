export default {
  resources: {
    login: {
      email: 'Email',
      password: 'Password',
      company: 'Company',
      or: 'OR',
      loginWith: 'Signup with %{providerId}',
      onlyWithRegisteredAlternateLogins:
        'Only if your alternate login has been registered.',
      emailCheck: 'You have entered an invalid email address!',
      errors: {
        badCredentials: 'Authentication failed',
        locked:
          'Account locked after %{count} failed attempts. The lock will be released %{date}.',
        unexpected:
          'We are sorry: an unexpected error occurred. Please, retry later on or contact the Doc Process support.',
      },
      notifications: {
        selectCompany: 'Please, select the company you want to login with',
      },
    },
    task: {
      menu: 'My tasks',
      list: {
        title: 'My tasks',
      },
      'cora-fgx-pdf': {
        Cora_FGX_Validation_User_Task: {
          edit: {
            title: 'Approve or reject the invoice',
            open: {
              button: 'Approve or Reject',
            },
            save: {
              button: 'Approve',
            },
            cancel: {
              button: 'Reject',
            },
            update: {
              button: 'Save',
            },
            cancelUpdate: {
              button: 'Cancel',
            },
            successMessage: 'Done',
          },
        },
      },
    },
    administration: {
      menu: 'Administration',
    },
    departments: {
      menu: 'Departments',
    },
    roles: {
      menu: 'Roles',
    },
    establishments: {
      menu: 'Establishments',
    },
    validationMatrix: {
      menu: 'Validation Matrix',
    },
    user_and_company: {
      menu: 'User & Company',
    },
    user_and_company_v3: {
      menu: 'User & Company (V3)',
    },
    companies: {
      menu: 'Companies',
      list: {
        title: 'Companies',
        filters: {
          name: 'Name',
        },
        headers: {
          name: 'Name',
          fiscalCode: 'Fiscal code',
          address: 'Address',
        },
        toptoolbar: {
          create: 'Create company',
        },
      },
      edit: {
        title: '%{companyName} company',
        deleteConfirmTitle: 'Delete %{username}',
        tabs: {
          company: {
            title: 'Company',
            sections: {
              description: {
                subtitle:
                  'Information below are read-only. In case of error, please contact the support to put them right.',
                name: 'name',
                fiscalCode: 'fiscal code',
                address: 'address',
                cmsRootDir: 'company directory',
                cmsRootDirHelper:
                  'The directory in the Content Management System where all the company documents (invoices, ...) get stored',
              },
              userAuthenticationTypes: {
                subtitle: 'Supported login types',
                subtitleHelper:
                  'The authentication types (password-based Doc Process login, social login, corporate login, ...) ' +
                  'accepted by this company for its users to login. Contact the support if you want to add new login types.',
              },
            },
          },
          subscriptions: {
            title: 'Feature subscriptions',
            subtitle_1:
              'All the Doc Process features (read-only) the company subscribed to.\u0020' +
              'They depend on the contract signed with Doc Process. Contact the\u0020',
            subtitle_2: 'support',
            subtitle_3: '\u0020if you want to change this list.',
            subtitle_4:
              'Once subscribed, each feature usage can be assigned to a user account through the Accounts menu',
            psp_message:
              'When selecting a feature, the company will have access to all underlying roles, minus those ' +
              'specifically excluded.',
            feature: 'feature',
            active: 'active',
            excludedRoleIds: 'excluded roles',
          },
          delegations: {
            title: 'Employee delegations',
            subtitle:
              'The delegations of the usage rights of a user to another one. Use it ' +
              'when an employee is out of office for a period and you want another one ' +
              'to take over his duty during his absence.',
            representee: 'representee',
            representeeHelper: 'The user whose rights need to be delegated.',
            delegee: 'delegee',
            delegeeHelper: 'The user who inherits the usage rights.',
            periodTitle:
              'This delegation will be active (be careful: in your time zone):',
            from: 'from',
            to: 'to',
            errors: {
              samePerson:
                'line %{line}: representee and deleguee MUST be two different persons.',
              fromAfterTo:
                "line %{line}: the 'from' date MUST be greater than 'to'.",
            },
          },
          history: {
            title: 'Accounts change history',
            subtitle:
              'All the events during the lifetime of the company accounts.',
            accountOf: 'Account of',
            changeType: 'Change',
            when: 'When',
            by: 'By',
          },
        },
        notifications: {
          created: 'Company created',
          updated: 'Company updated',
        },
        notificationsPeppol: {
          created: 'Element created',
          updated: 'Element updated',
        },
      },
    },
    company: {
      menu: 'Company',
    },
    people: {
      menu: 'People',
      list: {
        title: 'People',
        filters: {
          email: 'Email',
        },
        headers: {
          email: 'Email',
          firstname: 'First name',
          lastname: 'Last name',
        },
      },
      edit: {
        sections: {
          info: {
            email: 'email',
            emailHelper:
              'The email is never editable. You must delete and create a new account if you want to change it.',
            phone: 'phone number',
            phoneHelper: 'The phone number is optionnal.',
            firstname: 'first name',
            lastname: 'last name',
            language: 'language',
            english: 'English',
            romanian: 'Romanian',
            french: 'French',
          },
          authentication: {
            title: 'Authentication',
            subtitle:
              'The logins the person can use to login into his accounts below.',
            type: 'type',
            login: 'login',
            none: 'No login configured.',
          },
          accounts: {
            title: 'Accounts',
            subtitle: 'The accounts of the person per company.',
            company: 'Company',
            enabled: 'Enabled',
            nonLocked: 'Non locked',
          },
        },
        notifications: {
          updated: 'Person updated',
        },
      },
    },
    address: {
      menu: 'Addresses',
      list: {
        title: 'Addresses',
        headers: {
          street: 'Street',
          additionalStreet: 'Additional Street',
          buildingNumber: 'Building Number',
          postBox: 'Post Box',
          city: 'City',
          postalCode: 'Postal Code',
          financialAccount: 'IBAN',
          bank: 'Bank',
          gln: 'GLN',
          country: 'Country Code',
          flgBilling: 'Billing Address',
          flgShipping: 'Shipping Address',
          flgLogistic: 'Logistic Address',
        },
      },
      edit: {
        title: 'Address',
        sections: {
          street: 'Street',
          additionalStreet: 'Additional Street',
          buildingNumber: 'Building Number',
          postBox: 'Post Box',
          city: 'City',
          postalCode: 'Postal Code',
          financialAccount: 'IBAN',
          bank: 'Bank',
          gln: 'GLN',
          country: 'Country Code',
          flgBilling: 'Billing Address',
          flgShipping: 'Shipping Address',
          flgLogistic: 'Logistic Address',
        },
        notifications: {
          updated: 'The element has been updated',
          created: 'Element successfuly created',
          flgError: 'Please select address type',
        },
      },
    },
    supportedProducts: {
      create: {
        error: 'This product has already been created.',
      },
    },
    accounts: {
      menu: 'Accounts',
      list: {
        title: 'Accounts',
        filters: {
          company: 'Company',
          person: 'Person',
          status: {
            title: 'Status',
            disabled: 'disabled',
            locked: 'locked',
          },
          created: {
            title: 'Created',
            today: 'Today',
            thisWeek: 'This week',
            lastWeek: 'Last week',
            thisMonth: 'This month',
            lastMonth: 'Last month',
            earlier: 'Earlier',
          },
        },
        headers: {
          person: 'Person',
          company: 'Company',
          enabled: 'Enabled',
          nonLocked: 'Non locked',
          created: 'Creation',
        },
        toptoolbar: {
          create: 'Create account',
        },
      },
      create: {
        title: 'Account creation wizard',
        notifications: {
          created: 'Account successfuly created',
        },
        wizard: {
          back: 'Back',
          next: 'Next',
          steps: {
            companySelection: {
              title: 'Select the company the account belongs to',
              subtitle: 'The details of each company are in the Company menu',
              company: 'Company',
            },
            personSelection: {
              title: 'Define the person the account is for',
              subtitle:
                'You can either select an existing person OR create a new one.',
              selectPerson: 'Select an existing person.',
              createPerson: 'Create a new person.',
              or: 'OR',
              person: 'person',
              job: 'Job title',
              authentication: 'Authentication',
              allFieldsMandatory:
                'Please fill in all the required fields below.',
              uniqueAmongCompanyEmails:
                'Must be unique among all the company account emails',
              selectedLanguageOnLogin: 'The selected language on login',
              phoneHelper:
                'The phone number is optional. You can leave it blank.',
            },
            usageRightsSelection: {
              title: 'Assign the usage rights to this account',
              subtitle:
                'The usage rights assigned for this account. ' +
                'You can only assign rights for the features the company subscribed for. ' +
                'You may enter no right here and assign them later on after the account creation.',
              feature: 'feature',
              roles: 'roles',
              errors: {
                duplicateFeatures:
                  'You put twice the same features. Please, group all the roles into one.',
              },
            },
            checkAndSave: {
              title: 'Double check and save',
            },
          },
        },
      },
      edit: {
        titleWithCompany: '%{username} account in %{companyName}',
        titleWithoutCompany: '%{username} account',
        deleteConfirmTitle: 'Delete %{username}',
        tabs: {
          user: {
            title: 'user',
            created: 'Created on %{date}',
            accountEnabled: 'Enabled',
            accountDisabled: 'Disabled',
            accountEnabledHelper: 'Disabling prevents user from log-ining',
            nonLocked: 'Not locked',
            locked: 'Locked',
            nonLockedHelper:
              "Switchable when locked (after too many failed logins, see 'Last logins' on the right corner)",
            lockedHelper: 'Locked after too many failed logins',
            lastLogins: 'Last logins',
            sections: {
              lastLogins: {
                title: 'Last 10 logins',
                subtitle:
                  'Nota bene. – An account gets locked after tree failed logins. Resetting the password releases the lock.',
                noLogin: 'No one used this account to log in.',
                loggedWith: 'with',
                badCredentials: 'bad credentials',
                locked: 'account locked!',
                unlocked: 'account unlocked',
                passwordReset: 'password reset',
                signout: 'signout',
              },
              job: { title: 'Job title' },
              company: {
                title: 'Company',
                subtitle: 'The company data is editable from the Company menu',
                name: 'name',
                address: 'address',
                fiscalCode: 'fiscal code',
              },
              person: {
                title: 'User',
                subtitleForPspAdmin:
                  'A person may own multiple accounts so its data are not editable here ' +
                  'but from the People menu',
                subtitleForCompanyAdmin:
                  'Only the person themself can edit information below ' +
                  'through his user profile (in the top right corner',
                email: 'email',
                phone: 'phone',
                phoneHelper: 'The phone number is optionnal.',
                noPhone: 'No phone number.',
                firstname: 'first name',
                lastname: 'last name',
                language: 'language',
                languageHelper: 'The selected language when you login',
                english: 'English',
                romanian: 'Romanian',
                french: 'French',
              },
            },
          },
          usageRights: {
            title: 'usage rights',
            pspAdminSubtitle:
              'An account may hold three kinds of usage rights: (1) the configurable ' +
              'rights, (2) the rights automatically assigned by the Doc Process ' +
              'platform, and (3) the rights inherited by delegation from users. These ' +
              'last two are not configurable.',
            companyAdminSubtitle:
              'An account may hold two kinds of usage rights: (1) the configurable ' +
              'rights and (2) the rights inherited by delegation from users.',
            sections: {
              configurables: {
                title: 'Configurable usage rights',
                subtitle: 'The usage rights configured for this account.',
              },
              onthefly: {
                title: 'Automatically assigned usage rights',
                subtitle:
                  'The read-only usage rights automatically assigned by the Doc Process platform.',
                none: 'None',
              },
              delegateds: {
                title: 'Usage rights inherited by delegation',
                subtitle:
                  'The read-only usage rights inherited from other users (see the company delegation tab ',
                none: 'None',
                feature: 'feature',
                roles: 'roles',
                from: 'delegation from',
              },
            },
          },
          history: {
            title: 'change history',
            subtitle: 'All the events during the lifetime of the account.',
            changeType: 'Change',
            when: 'When',
            by: 'By',
            empty: 'No event yet.',
            update: 'update',
            creation: 'creation',
            deletion: 'deletion',
            changes: {
              what: 'What have changed:',
              accountEnabled: 'account enabled',
              accountDisabled: 'account disabled',
              newLogins: 'New logins:',
              with: 'with',
              removedLogins: 'Removed logins:',
              newUsageRights: 'New usage rights:',
              withRoles: 'with roles:',
              removedUsageRights: 'Usage rights removed:',
              newUsageRightRoles: 'Usage rights with new roles:',
              withNewRoles: 'with new roles:',
              removedUsageRightRoles: 'Usage rights with removed roles:',
              removedRoles: 'removed roles:',
              jobAddedAttributes: 'New job attributes:',
              jobRemovedAttributes: 'Removed job attributes:',
              jobChangedAttributes: 'Modified job attributes:',
            },
            filters: {
              username: 'username',
            },
          },
        },
        notifications: {
          updated: 'The account has been updated',
        },
        errors: {
          identityAlreadyExists: 'Identity %{identityId} already exists!',
          personOrIdentityAlreadyExists:
            'The person or an identity already exists!',
        },
      },
      job: {
        key: 'key',
        keyHelper: 'Choose a pre-defined one or create yours',
        value: 'value',
        noJob: 'No job defined',
        create: {
          subtitle:
            'You can complete the description of the user by describing their job here (or leave this part blank).',
        },
        edit: {
          subtitle:
            'You can complete the description of the user by describing their job here (or leave this part blank).',
        },
        show: {
          subtitle: 'Your job attributes as defined by your company.',
        },
      },
    },
    identityProviders: {
      menu: 'Identity providers',
      list: {
        title: 'The login types supported by the Doc Process platform',
        headers: { id: 'Login type' },
      },
      common: {
        authentication: 'Authentication',
        authenticationHelper:
          'The logins the person can use to login into this account. ' +
          'The proposed types of login (Doc Process, Google, ...) are the ones the company ' +
          'subscribed for, listed in its Company menu',
        loginType: 'Login type',
        email: 'email',
        emailOpenIdConnectHelper:
          "On the login page, click on 'SIGINUP WITH WITH %{providerId}' then type this email account.",
        emailBuiltInHelper:
          "This email address is the user's login (the account creation triggers the sending by email of a link to the user so that he can configure his password).",
        errors: {
          notCompliant: 'Not compliant with the password policy',
          onlyOneDxIdentity: 'You can configure only one Doc Process login.',
        },
      },
    },
    regulatorAccessTransport: {
      submenu: 'Etransport Access',
    },
    regulatorAccess: {
      menu: 'Fiscal authority',
      submenu: 'Access',
      subtitle: 'Fiscal authority access details',
      subtitleHelper: 'List of existing fiscal authority accesses',
      infoMessage:
        'Starting on 1 July 2022, Romanian legislation mandates that the following types of invoices must be sent to and certified by the government’s fiscal authority system: ' +
        '(1) Invoices that include "high fiscal risk" goods, as defined by the fiscal authority. ' +
        '(2) Invoices for Business-to-Government (B2G) transactions (public procurement). ' +
        'From 2023, this mandate will expand to all Business-to-Business (B2B) invoicing. ' +
        'In order to let the DocProcess platform interact with the fiscal authority on your behalf strictly for this purpose, you need to provide the email of the Approver who will authorize DocProcess to access your company’s Private Virtual Space (SPV).',
      status: {
        PENDING_VALIDATION: 'Pending validation',
        UNDER_VALIDATION: 'Under validation',
        VALIDATED: 'Validated',
        REJECTED: 'Rejected',
      },
      clientId: 'Client ID',
      clientSecret: 'Secret',
      approverEmail: "Approver's email",
      create: {
        notifications: {
          updated: 'Access data updated',
          successMessage:
            "An email has been sent to the Approver's email: %{approverEmail}. " +
            'This email contains a link that will redirect the Approver to the fiscal authority website, where they will need to log in and authorize DocProcess. The Approver must access this link from the computer that has the fiscal authority certificate installed on the web browser, or else the access can’t be granted. ' +
            'Once the connection is successful, the Approver will be automatically redirected to the DocProcess platform, and invoicing according to the fiscal authority process will start.',
        },
        errors: {
          'regulator.unavailable': 'fiscal authority unavailable',
        },
        actions: {
          new: 'New access',
        },
      },
      list: {
        headers: {
          id: 'Client ID',
          secret: 'Secret',
          created: 'Creation date',
          email: 'Created by',
          status: 'Status',
          companyId: 'Company',
          approverEmail: 'Approver email',
        },
        actions: {
          save: 'Grant access',
        },
      },
    },
    dxfeatures: {
      // Enter below the description of your features and their associated roles:
      administration: {
        description: 'User administration',
        'company administrator': 'Only for my company',
        'PSP administrator': 'For all companies',
      },
      archiving: {
        description: 'Document archiving',
      },
      'purchase to pay': { description: 'Purchase to pay' },
      'CORAFGX-SUPERVISOR': {
        description: 'Supervisor',
        'VIEW-INVOICE': 'View invoices',
        'EDIT-INVOICE': 'Edit invoices',
      },
      'CORAFGX-APPROVER': {
        description: 'Approve tasks',
        'APPROVE-INVOICE': 'Approve invoices',
      },
      'CORAFGX-TASK-ACCESS': { description: 'View tasks' },
      'CORAFGX-BUSINESS-ADMIN': {
        description: 'Cora VisaIX admin',
        'EDIT-CONTENT': 'Edit business content',
      },
      'CMS-DM': {
        description: 'Document access',
        'DOWNLOAD-ALL': 'Download all documents',
        'DOWNLOAD-INVOICE': 'Download invoice',
        'READ-ALL': 'View all documents',
      },
      'reconciliation-cockpit': {
        description: 'Reconciliation cockpit',
        reconciliator: 'Reconciliator',
        viewer: 'Viewer',
      },
      'allocation-cockpit': {
        description: 'Expense classification cockpit',
        allocator: 'Allocator',
        viewer: 'Viewer',
      },
      'simple-validation-cockpit': {
        description: 'Simple validation cockpit',
        INVOICE_VALIDATOR_LEVEL_1: 'Invoice validator level 1',
        INVOICE_VALIDATOR_LEVEL_2: 'Invoice validator level 2',
      },
    },
    profile: {
      edit: {
        title: 'Your profile',
        emailHelper:
          'The email is never editable. Ask your administror if you want to change it.',
        changePassword: 'Change password',
        changePasswordHelper:
          'To change your password, enter the current one and the new one ' +
          '(which must conform with the policy).',
        currentPassword: 'Current password',
        newPassword: 'New password',
        pspAdministratorAlert:
          'You are an administrator of all accounts of the Doc Process platform .',
        companyAdministratorAlert:
          'You are an administrator of your company accounts.',
        notifications: {
          updated: 'Your profile has been updated',
          error:
            'A technical error prevnted from updating your profile. Please try later on',
        },
        tabs: {
          personalInfoAndPassword: 'Personal info & Password',
          personalInfo: 'Personal info',
          job: 'Job title',
        },
      },
    },
  },
  myAccount: {
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    phoneNumber: 'Phone number',
    roles: 'Roles',
    profile_update_success:
      'Your user data (except the email address) has been successfully updated. If your email address has been changed, a confirmation will be needed. Check your new email inbox for instructions.',
    profile_update_error: 'Cannot update user profile. An error has occured.',
    email_update_success: 'Your email has been successfully updated.',
    email_update_error: 'Cannot update email. An error has occured.',
    update_profile_title: 'User profile',
  },
  auth: {
    crt_password: 'Current password',
    crt_password_required: 'Please enter your current password',
    crt_password_invalid: 'Password is incorrect.',

    new_password: 'New password',
    new_password_required: 'Please enter your new password',

    confirm_password: 'Retype the new password',
    confirm_password_required: 'Please enter your new password again',

    lost_password_title: 'Forgot your password?',
    lost_password_desc:
      'Please enter your username to request a password reset',
    lost_password_email_notif:
      'If an account exists for this username, an e-mail will be sent with further instructions.',
    lost_password_error:
      'En error occured while sending password recovery instructions',

    change_password_title: 'Change password',
    configure_password_title: 'Configure password',
    change_password_confirmation:
      'Your password has been succesfuly changed. Please login using the new password.',
    change_password_expired:
      'Your reset password link has expired. Password has not been changed.',
    change_password_error:
      'Could not change your password due to an internal error.',
    change_password_match_error: 'The passwords don’t match, please try again.',
    token_too_many_attempts:
      'Too many attempts. Please try again in 30 minutes.',
    token_invalid_or_already_used:
      'Your request is invalid or has already been fulfilled.',
    associatedWithLogin: 'for the login',
  },
  menuBar: {
    selectLanguage: 'Language',
    languageSuccess: 'Language updated successfully',
    languageError: 'Language could not be updated, please try again later',
  },
  error: {
    internal:
      'Oups. Please, retry in a few minutes then contact the support@docprocess.com if the error persists.',
    taskNotFound: 'No luck: another user has completed this action before you!',
    accept: {
      with_rej_reason:
        'Sorry, you cant accept an invoice with a rejection reason! Remove it before continuing.',
    },
    reject: {
      without_rej_reason:
        'A rejection reason is missing. Please add it before continuing',
    },
  },
};
