/**
 * Display the count of documents received by DocProcess per document
 * type and per day.
 */
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { CashCollectionBarChart } from '../live/CashCollectionProjectionOverTime';

const CashCollectionProjectionOverTimeDemo: FC<{
  userPreferencesRootKey: string;
}> = ({ userPreferencesRootKey }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(undefined);
  useEffect(() => {
    const getData = () => {
      setLoading(true);
      let data: any = { metrics: [] as any[] };
      const currentMonth = moment().startOf('month');
      // based on sample entries, creates random data for current month, month + 1, ..., month + 5
      [0, 1, 2, 3, 4, 5].forEach((index) => {
        const date = moment(currentMonth).add(index, 'M');
        data.metrics.push({
          dimensions: {
            dateDimension: { date: date.format('YYYY-MM-DD') },
          },
          value: Math.random() * 20000,
          currencyCode: 'EUR',
        });
      });
      setData(data);
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <CashCollectionBarChart
      loading={loading}
      data={data}
      userPreferencesRootKey={userPreferencesRootKey}
    />
  );
};

export default CashCollectionProjectionOverTimeDemo;
