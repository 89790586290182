import { Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { FC } from 'react';
import {
  AutocompleteInput,
  FieldProps,
  ReferenceInput,
  required,
  useTranslate,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Account } from '../../types';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2, 2),
  },
}));

/**
 * The step in the account creation wizard where the user selects the company
 * the account will belong to.
 *
 * @see AccountCreate
 */
const CompanySelectionStep: FC<FieldProps<Account>> = () => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Paper className={classes.paper}>
      <Grid container direction='column' style={{ marginTop: '1em' }}>
        <Grid item>
          <Typography variant='caption'>
            {translate(
              'resources.accounts.create.wizard.steps.companySelection.subtitle'
            )}
            <IconButton
              color='primary'
              component={Link}
              to='/configuration-companies'
              size='small'
            >
              <EditIcon style={{ width: '16px', height: '16px' }} />
            </IconButton>
            .
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={6}>
              <ReferenceInput
                label='resources.accounts.create.wizard.steps.companySelection.company'
                source='company.id'
                reference='configuration-companies'
                validate={required()}
                filterToQuery={(searchText) => ({
                  name: [searchText],
                })}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <AutocompleteInput optionText='name' fullWidth />
              </ReferenceInput>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompanySelectionStep;
