import { makeStyles } from '@material-ui/core/styles';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React from 'react';
import { RegulatorStatus } from '../../configuration/types';
import { colors } from '../../constants/Colors';
import { DxTheme } from '../../types';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    statusIcon: {
      fontSize: 'medium',
      marginRight: 3,
      verticalAlign: 'middle',
      strokeWidth: 2,
    },
  }),
  { name: 'RegulatorStatusIcon' }
);

export const RegulatorStatusIcon = (props) => {
  const { status } = props;
  const classes = useStyles();

  switch (status) {
    case RegulatorStatus.validated:
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.functionalGreen,
            stroke: colors.functionalGreen,
          }}
          className={classes.statusIcon}
        />
      );

    case RegulatorStatus.rejected:
      return (
        <RadioButtonUncheckedIcon
          style={{
            color: colors.red[50],
            stroke: colors.red[50],
          }}
          className={classes.statusIcon}
        />
      );

    case RegulatorStatus.pending_validation:
    case RegulatorStatus.under_validation:
      return (
        <HourglassEmptyIcon
          style={{
            color: colors.functionalOrange,
            stroke: colors.functionalOrange,
          }}
          className={classes.statusIcon}
        />
      );

    default:
      return (
        <RadioButtonUncheckedIcon
          style={{ stroke: colors.black05 }}
          className={classes.statusIcon}
        />
      );
  }
};
