import {
  Constants,
  DxTheme,
  useLocalStorageValueGeneric,
} from '@dx-ui/dx-common/src';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogTitleProps,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { FC, useEffect, useState } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';

const useStylesDialogContent = makeStyles(
  (theme: DxTheme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }),

  { name: 'DialogContentWebFormPopup' }
);

const useStylesDialogActions = makeStyles(
  (theme: DxTheme) => ({
    root: {
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  }),

  { name: 'DialogActionsWebFormPopup' }
);

const useStylesDialogTitle = makeStyles(
  (theme: DxTheme) => ({
    root: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.grey[500],
    },
  }),

  { name: 'DialogTitleWebFormPopup' }
);

const BootstrapDialogTitle: FC<
  DialogTitleProps & { id: string; onClose: Function; classes: any }
> = (props: any) => {
  const { children, onClose, id, classes, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label='close' onClick={onClose} classes={classes}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

const WebFormPopup = (props) => {
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);
  const [zendeskJWT, setZendeskJwt] = useState();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const classesContent = useStylesDialogContent();
  const classesActions = useStylesDialogActions();
  const classesTitle = useStylesDialogTitle();
  const [hideWebFormPopup, setHideWebFormPopup] =
    useLocalStorageValueGeneric<boolean>('dismissWebformPopup', false);

  useEffect(() => {
    const getZendeskJWT = async () => {
      try {
        const response = await dataProvider[Constants.RESOURCE_ZENDESK](
          Constants.RESOURCE_ZENDESK,
          {}
        );

        if (response && response.data) {
          setZendeskJwt(response?.data);
        }
      } catch {
        // TODO: handle it as an error, not a 'no trail'.
        setZendeskJwt(undefined);
      }
    };
    if (!zendeskJWT) {
      getZendeskJWT();
    }
  }, [dataProvider, zendeskJWT]);

  const handleClose = () => {
    setOpen(false);
    if (checked) {
      setHideWebFormPopup(true);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open && !hideWebFormPopup}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
          classes={classesTitle}
        >
          {translate('dxMessages.webForm.popup.title')}
        </BootstrapDialogTitle>
        <DialogContent dividers classes={classesContent}>
          <Typography gutterBottom>
            {translate('dxMessages.webForm.popup.content')}
          </Typography>
          <Typography>
            <a
              href={`https://docprocess.zendesk.com/access/jwt?jwt=${zendeskJWT}&return_to=${translate(
                'dxMessages.webForm.popup.moreInfoTextLinkUrl'
              )}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {translate('dxMessages.webForm.popup.moreInfoTextLink')}
            </a>
          </Typography>
        </DialogContent>
        <DialogActions classes={classesActions}>
          <Checkbox checked={checked} onChange={onChange} size='small' />
          <Typography>
            {translate('dxMessages.webForm.popup.checkboxDismissText')}
          </Typography>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WebFormPopup;
