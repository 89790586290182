import {
  Button,
  Grid,
  Paper,
  Step,
  StepButton,
  Stepper,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { FormWithRedirect, SaveButton, useTranslate } from 'react-admin';
import { useFormState } from 'react-final-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '1em',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    buttonPaper: {
      padding: theme.spacing(2, 2),
      background: theme.palette.action.hover,
    },
  })
);

/**
 * A <ReactAdmin /> wizard form.
 *
 * It's a regular <ReactAdmin /> Create FC but with a custom form layout built on
 * a material-ui stepper.
 */
const WizardForm = (props: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [activeStep, setActiveStep] = React.useState(0);
  const { steps } = props;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <FormWithRedirect
      {...props}
      render={({ handleSubmitWithRedirect, saving }) => (
        <form>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((step: any, index: number) => (
                <Step key={step.label}>
                  <StepButton onClick={handleStep(index)}>
                    {translate(step.label)}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              <Grid container direction='column' spacing={2}>
                <Grid item>{steps[activeStep].fc}</Grid>
                <Grid item>
                  <WizardButtons
                    lastStepIndex={steps.length - 1}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    saving={saving}
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    validateStep={steps[activeStep].validation}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </form>
      )}
    />
  );
};

/**
 * The Back and Next (or Save for the last step) buttons.
 */
const WizardButtons = ({
  lastStepIndex,
  activeStep,
  handleBack,
  handleNext,
  saving,
  handleSubmitWithRedirect,
  validateStep,
}: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { values } = useFormState();

  return (
    <Paper className={classes.buttonPaper} elevation={0} square>
      <div>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}
          data-testid='back'
        >
          {translate('resources.accounts.create.wizard.back')}
        </Button>
        {activeStep === lastStepIndex && (
          <SaveButton
            data-testid='save'
            saving={saving}
            disabled={!validateStep(values)}
            handleSubmitWithRedirect={handleSubmitWithRedirect}
          />
        )}
        {activeStep !== lastStepIndex && (
          <Button
            variant='contained'
            color='primary'
            onClick={handleNext}
            // Disable the Next button if the form is not valid.
            disabled={!validateStep(values)}
            data-testid='next'
          >
            {translate('resources.accounts.create.wizard.next')}
          </Button>
        )}
      </div>
    </Paper>
  );
};

export default WizardForm;
