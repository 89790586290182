// icon to be shown inside an input
import React from 'react';
import { colors } from '../../constants/Colors';

const styles: any = (theme) => ({
  root: {
    position: 'absolute',
    bottom: '5px',
    left: '8px',
    pointerEvents: 'none',
    '& > svg': {
      color: colors.black65,
      display: 'block',
    },
    '& > :global(.material-icons)': {
      color: colors.black65,
      display: 'block',
    },
  },
});

export default function InputIcon({ children }) {
  return <div className={styles.root}>{children}</div>;
}
