import {
  Create,
  maxLength,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from 'react-admin';
export const FormatTypeCreate = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const nameValidate = [required(), maxLength(50)];
  const commentValidate = [maxLength(500)];
  const onSuccess = () => {
    notify('resources.address.edit.notifications.created');
    redirect('list', props.basePath);
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput
          label={'dxMessages.companyManagement.formattype.description'}
          source='description'
          validate={nameValidate}
        />
        <TextInput
          label={'dxMessages.companyManagement.formattype.comment'}
          source='comment'
          validate={commentValidate}
        />
        <SelectInput
          label={'dxMessages.companyManagement.formattype.idDocumentType'}
          source='idDocumentType'
          choices={[
            { id: 1, name: 'INVOICE' },
            { id: 2, name: 'DESADV' },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};
export default FormatTypeCreate;
