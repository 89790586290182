import { sendGAEvent } from '@dx-ui/dx-common';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { cloneDeep, set } from 'lodash';
import { useTranslate } from 'ra-core';
import { useState } from 'react';
import { Confirm, useGetIdentity, useNotify } from 'react-admin';
import { useForm } from 'react-final-form';
import { GA_EVENTS } from '../../GAUtils';
import { FormDataHelpers } from '../../services/FormDataHelpers';

export default ({ record }: any) => {
  const documentForm = useForm();
  const translate = useTranslate();
  const notify = useNotify();
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const handleConfirm = () => {
    // Remove the line, call for totals re-computation and then dispatch
    const lineIndex = record.nr - 1;
    const documentData = documentForm.getState().values;
    const lines = documentData.lines;
    lines.splice(lineIndex, 1);
    set(documentData, 'lines', lines);
    const data: any = cloneDeep(documentData);
    FormDataHelpers.recalculateTaxesAndPrices(data);

    // In case of MonoVat, reset monoVat if TaxSummary has more than one lines
    FormDataHelpers.manageMonoVat(data);

    // In case of Mono Tax Category, reset mono TaxCategory if TaxSummary has more than one lines
    FormDataHelpers.manageMonoTaxCategory(data);

    documentForm.batch(() => {
      const properties = Object.keys(data).filter((t) => t !== 'id');
      properties.forEach((propName) => {
        documentForm.change(propName, data[propName]);
      });
    });
    notify('dxMessages.webForm.deleteLineNotifySuccess', 'success', {
      lineNumber: record.nr,
    });
    sendGAEvent(
      GA_EVENTS.categories.FORM.name,
      GA_EVENTS.categories.FORM.actions.REMOVE_LINE,
      account?.company?.cmsRootDir
    );
    setOpen(false);
  };
  return (
    <>
      <Tooltip title={translate('dxMessages.webForm.deleteLineTooltip')}>
        <IconButton onClick={handleClick}>
          <DeleteIcon color={'error'} />
        </IconButton>
      </Tooltip>
      <Confirm
        isOpen={open}
        title={translate('dxMessages.webForm.deleteLine', {
          lineNumber: record.nr,
        })}
        content='dxMessages.webForm.deleteLineConfirm'
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};
