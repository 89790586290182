import Cookies from 'js-cookie';
import { Constants } from '../constants/Constants';

/**
 * Sends a logout to the backend
 */
export const sendLogout = () => {
  // Delete the SSO cookie. Do not care if it fails.
  const options = {
    headers: new Headers({ Accept: 'application/json' }),
    // BE CAREFUL: MUST BE a POST (see DxAuthenticationWebSecurityConfiguration).
    method: 'POST',
    // Both cred props below are needed to make it work on Safari.
    withCredentials: true,
    credentials: 'include',
  };
  const csrfCookie = Cookies.get('XSRF-TOKEN');
  if (csrfCookie) {
    options.headers.append('X-XSRF-TOKEN', csrfCookie);
  }

  // Remove delighted NPS survey cookie
  if (Cookies.get('_delighted_web')) {
    try {
      Cookies.remove('_delighted_web');
    } catch (ex) {}
  }

  // @ts-ignore
  fetch('/api/auth/signout', options).then(() => {
    if (typeof localStorage !== 'undefined') {
      try {
        // First retrieves the current user name
        const user = localStorage.getItem('user');
        // Then deals with user information
        localStorage.removeItem(user + '.' + Constants.PERMISSIONS);
        localStorage.removeItem(user + '.' + Constants.BSP_ID);
        localStorage.removeItem(user + '.' + Constants.COMPANY_UUID);
      } catch (ex) {
        // Not really an issue for us ( ex: No more space in local storage)
        // but can be reported anyway as a warning.
        // eslint-disable-next-line no-console
        console.warn(
          'unable to remove user information from local storage : ' + ex
        );
      }
    }
  });
};
