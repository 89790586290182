import { IconButton, Tooltip } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import { Dns, Folder, PostAdd, Public } from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import AppsIcon from '@material-ui/icons/Apps';
import AttachMoney from '@material-ui/icons/AttachMoney';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import BusinessIcon from '@material-ui/icons/Business';
import InventoryIcon from '@material-ui/icons/Category';
import ContactsIcon from '@material-ui/icons/Contacts';
import CreateIcon from '@material-ui/icons/Create';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Description from '@material-ui/icons/Description';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import EventNote from '@material-ui/icons/EventNote';
import ToggleIcon from '@material-ui/icons/ExpandMore';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import GavelIcon from '@material-ui/icons/Gavel';
import DxDocumentIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import LocalShipping from '@material-ui/icons/LocalShipping';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import NoteAdd from '@material-ui/icons/NoteAdd';
import NotificationsIcon from '@material-ui/icons/Notifications';
import People from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import RegulatorIcon from '@material-ui/icons/Policy';
import Receipt from '@material-ui/icons/Receipt';
import TestingIcon from '@material-ui/icons/RotateLeft';
import Schedule from '@material-ui/icons/Schedule';
import SendIcon from '@material-ui/icons/Send';
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { cloneDeep } from 'lodash';
import React, { Fragment } from 'react';
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  ReduxState,
  getResources,
  toggleSidebar,
  usePermissionsOptimized,
  useTranslate,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import SavedQueriesMenuItems from '../components/saved-queries/SavedQueriesMenuItems';
import { isPspAdministrator } from '../configuration';
import { Constants } from '../constants/Constants';
import { UserRoles } from '../security/UserRoles';
import useTaskCount from '../tasks/useTaskcount';
import { DxTheme } from '../types';
import { getDxApplication } from '../utils/utils';
import MenuItemsGroup from './MenuItemsGroup';

const useSideBarMenuStyles = makeStyles(
  (theme: DxTheme) => ({
    active: {
      color: theme.colors.mainColor5,
      fontWeight: 700,
      fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
      backgroundColor: theme.colors.lightGrey,
    },
    menuItem: (props: { open: boolean }) => {
      return {
        paddingLeft: props.open ? theme.spacing(4) : theme.spacing(2),
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
        '&:hover': {
          backgroundColor: theme.colors.lightGrey,
        },
      };
    },
    sidebar: (props: { open: boolean }) => {
      return {
        marginTop: '1em',
        height: 'calc(100vh - 91px)',
        overflowY: props.open ? 'auto' : 'unset',
        overflowX: props.open ? 'auto' : 'unset',
      };
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(270deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(90deg)',
    },
    toggle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  }),
  { name: 'SideBarMenu' }
);

const useBadgeStyles = makeStyles(
  (theme) => ({
    anchorOriginTopRightRectangle: {
      transform: 'scale(0.8) translate(50%, -50%)',
    },
  }),
  { name: 'BadgeMenu' }
);

interface RaResource {
  name: string;
  icon?: React.ReactElement;
  options?: {
    label?: string;
  };
  hasList?: boolean;
  hasEdit?: boolean;
  hasShow?: boolean;
}

/**
 * Associates an Icon to a resource.
 * @param {string} name resource name
 */
export const MenuIcon = ({
  resourceName,
  style,
}: {
  resourceName: string;
  style?: any;
}) => {
  let icon = <div />;
  switch (resourceName) {
    case 'configuration-accounts':
      icon = <AccountCircleIcon {...{ style }} />;
      break;
    case 'configuration-companies':
      icon = <BusinessIcon {...{ style }} />;
      break;
    case 'configuration-people':
      icon = <PersonIcon {...{ style }} />;
      break;
    case 'configuration-identityProviders':
      icon = <FingerprintIcon {...{ style }} />;
      break;
    case 'regulatorAccess':
      icon = <RegulatorIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_INVOICE:
      icon = <AttachMoney {...{ style }} />;
      break;
    case Constants.RESOURCE_DESPATCH_ADVICE:
    case Constants.RESOURCE_WEBDESPATCH_ADVICE:
      icon = <LocalShipping {...{ style }} />;
      break;
    case Constants.RESOURCE_RECEIPT_ADVICE:
    case Constants.RESOURCE_WEBRECEIPT_ADVICE:
      icon = <Receipt {...{ style }} />;
      break;
    case Constants.RESOURCE_ACCOUNT:
      icon = <BusinessIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_PRODUCTUSAGE:
      icon = <AppsIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_USERS:
      icon = <AccountCircleIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_CONTACT:
      icon = <ContactsIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_SUPPORTED_PRODUCTS:
      icon = <PlaylistAddCheckIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_ADDRESS_BUFFER:
      icon = <Dns {...{ style }} />;
      break;
    case Constants.RESOURCE_DROPDOWNDEF:
      icon = <ListIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_DX_ROLE:
      icon = <ListIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_FORMAT_TYPE:
      icon = <InventoryIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_DXPRODUCT:
      icon = <InventoryIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_SUPPLIERS:
      icon = <MarkunreadMailboxIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_CUSTOMER:
      icon = <SendIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_PEPPOL:
      icon = <PostAdd {...{ style }} />;
      break;
    case Constants.RESOURCE_ORDER:
    case Constants.RESOURCE_WEBORDER:
      icon = <AddShoppingCart {...{ style }} />;
      break;
    case Constants.RESOURCE_CONTRACT:
      icon = <People {...{ style }} />;
      break;
    case Constants.RESOURCE_CATALOG:
      icon = <LocalLibrary {...{ style }} />;
      break;
    case Constants.RESOURCE_FORECAST:
      icon = <Schedule {...{ style }} />;
      break;
    case Constants.RESOURCE_CATALOG_DRAFT:
    case Constants.RESOURCE_WEBFORM_DRAFT:
      icon = <CreateIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_DXSGRCATALOG:
    case Constants.RESOURCE_DXCORASGRCATALOG:
    case Constants.RESOURCE_DXCATALOG:
    case Constants.RESOURCE_DXDRAFT:
      icon = <ListIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_WEBINVOICE:
      icon = <AttachMoney {...{ style }} />;
      break;
    case Constants.RESOURCE_TASKS:
      icon = <FormatListBulletedIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_WAYBILL:
    case Constants.RESOURCE_WEBWAYBILL:
      icon = <EventNote {...{ style }} />;
      break;
    case Constants.RESOURCE_DXUPLOAD:
      icon = <TestingIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_V2INVOICE:
      icon = <AttachMoney {...{ style }} />;
      break;
    case Constants.RESOURCE_V2ORDER:
      icon = <AddShoppingCart {...{ style }} />;
      break;
    case Constants.RESOURCE_V2RECEIPTADVICE:
      icon = <Receipt {...{ style }} />;
      break;
    case Constants.RESOURCE_V2DESPATCHADVICE:
      icon = <LocalShipping {...{ style }} />;
      break;
    case Constants.RESOURCE_EFACTURA_MONITORING_CONTENT_ERROR:
      icon = <ErrorIcon {...{ style }} />;
      break;
    case Constants.RESOURCE_EFACTURA_MONITORING_REJECTED_DOCUMENTS:
      icon = <GavelIcon {...{ style }} />;
      break;
    default:
      icon = <DxDocumentIcon {...{ style }} />;
  }
  return icon;
};

const BadgedMenu = ({
  resource,
  count,
}: {
  resource: RaResource;
  count: number;
}) => {
  const classes = useBadgeStyles();
  if (resource.name === Constants.RESOURCE_TASKS && count > 0) {
    return (
      <Badge
        classes={{
          anchorOriginTopRightRectangle: classes.anchorOriginTopRightRectangle,
        }}
        badgeContent={count}
        color='error'
      >
        <MenuIcon resourceName={resource.name} />
      </Badge>
    );
  } else {
    return <MenuIcon resourceName={resource.name} />;
  }
};

const DefinedMenuSections = {
  UserManagement: {
    label: 'resources.user_and_company.menu',
    icon: <People />,
  },
  MessageCenter: {
    label: 'resources.configuration-message.name',
    icon: <NotificationsIcon />,
  },
  UserManagementV3: {
    label: 'resources.user_and_company_v3.menu',
    icon: <People />,
  },
  RegulatorAccess: {
    label: 'resources.regulatorAccess.menu',
    icon: <RegulatorIcon />,
  },
  V2Management: {
    label: 'dxMessages.companyManagement.menu',
    icon: <SettingsIcon />,
  },
  V2Management_V2: {
    label: 'dxMessages.companyManagement.menuV2',
    icon: <SettingsIcon />,
  },
  V2_GlobalView: {
    label: 'dxMessages.companyManagement.globalView',
    icon: <Public />,
  },
  Peppol: {
    label: 'dxMessages.peppol.menu',
    icon: <Folder />,
  },
  V2Administration: {
    label: 'dxMessages.v2administration.menu',
    icon: <NoteAdd />,
  },
  CatalogList: {
    label: 'dxMessages.catalogList.menu',
    icon: <Description />,
  },
  CatalogEdit: {
    label: 'dxMessages.catalogEdit.menu',
    icon: <NoteAdd />,
  },
  Tasks: { label: 'dxMessages.menu.tasks', icon: <PlaylistAddCheckIcon /> },
  V2_DocumentSearch: {
    label: 'dxMessages.companyManagement.documentSearch',
    icon: <Description />,
  },
  Monitoring: {
    label: 'dxMessages.monitoring.menuMonitoring',
    icon: <VisibilityIcon />,
  },
  Documents: { label: 'dxMessages.menu.documents', icon: <Description /> },
  Create: { label: 'dxMessages.menu.create', icon: <NoteAdd /> },
  SavedQueries: {
    label: 'dxMessages.filter.savedQueriesMenu',
    icon: <BookmarksIcon />,
  },
  asArray: (): any[] =>
    Object.keys(DefinedMenuSections).map(
      (section) => DefinedMenuSections[section]
    ),
};

/**
 * Gets the resources to display based on permissions
 * @param {object} resources all resources
 * @param {string} productName current application name
 */
const useResourcesToDisplay = ({
  translatedResources,
  productName,
}: {
  translatedResources: RaResource[];
  productName: string;
}) => {
  const newResources: { [key: string]: RaResource[] } = {};
  const { permissions } = usePermissionsOptimized();

  DefinedMenuSections.asArray().forEach(
    (menuSection) => (newResources[menuSection.label] = [])
  ); // init with empty collections

  let product = UserRoles.DXPURCHASE_PRODUCT;
  if (productName === Constants.DXARCHIVE_APP) {
    product = UserRoles.DXARCHIVE_PRODUCT;
  }

  if (productName === Constants.DXCATALOG_APP) {
    product = UserRoles.DXCATALOG_PRODUCT;
  }
  const filterResources = (allowedResource: string) => {
    return translatedResources.filter(
      (resource) => resource.name === allowedResource && resource.hasList
    );
  };

  /**
   * Add menu item to specific menu section
   */
  const addResourceWithPermission = (
    allowedPermission: string,
    allowedResource: string,
    menuSection = DefinedMenuSections.Documents
  ) => {
    if (
      permissions[product]?.filter(
        (permission: string) => permission === allowedPermission
      )?.length === 1
    ) {
      newResources[menuSection.label].push(...filterResources(allowedResource));
    }
  };

  /**
   * Add menu item depending on the V3 usage rights of the current account.
   */
  const addConfigurationResourceWithPermission = (
    rolesSection: string,
    allowedRoles: string[],
    allowedResource: string,
    menuSection = DefinedMenuSections.Documents
  ) => {
    if (
      permissions?.[rolesSection]?.filter((r) => allowedRoles.includes(r))
        .length > 0
    ) {
      newResources[menuSection.label].push(...filterResources(allowedResource));
    }
  };

  /**
   * Add menu item to specific menu section
   */
  const addResource = (
    allowedResource: string,
    menuSection = DefinedMenuSections.Documents
  ) => {
    if (permissions[product]) {
      newResources[menuSection.label].push(...filterResources(allowedResource));
    }
  };

  if (permissions) {
    addConfigurationResourceWithPermission(
      UserRoles.DXUPLOAD_PRODUCT,
      [UserRoles.DXUPLOAD_ACCESS, UserRoles.DXADMIN],
      Constants.RESOURCE_DXUPLOAD
    );

    addConfigurationResourceWithPermission(
      UserRoles.DXCATALOG_PRODUCT,
      [
        UserRoles.DXCATALOG_ACCESS,
        UserRoles.CATALOG_VIEWER,
        UserRoles.CATALOG_WRITER,
        UserRoles.DXADMIN,
      ],
      Constants.RESOURCE_DXCATALOG,
      DefinedMenuSections.CatalogList
    );

    addConfigurationResourceWithPermission(
      UserRoles.DXCATALOG_PRODUCT,
      [
        UserRoles.DXCATALOG_ACCESS,
        UserRoles.CATALOG_VIEWER,
        UserRoles.CATALOG_WRITER,
        UserRoles.DXADMIN,
      ],
      Constants.RESOURCE_DXDRAFT,
      DefinedMenuSections.CatalogList
    );

    addConfigurationResourceWithPermission(
      UserRoles.DXCATALOG_PRODUCT,
      [
        UserRoles.DXCATALOG_ACCESS,
        UserRoles.CATALOG_VIEWER,
        UserRoles.CATALOG_WRITER,
        UserRoles.DXADMIN,
      ],
      Constants.RESOURCE_DXSGRCATALOG,
      DefinedMenuSections.CatalogList
    );

    addConfigurationResourceWithPermission(
      UserRoles.DXCATALOG_PRODUCT,
      [
        UserRoles.DXCATALOG_ACCESS,
        UserRoles.CATALOG_VIEWER,
        UserRoles.CATALOG_WRITER,
        UserRoles.DXADMIN,
      ],
      Constants.RESOURCE_DXCORASGRCATALOG,
      DefinedMenuSections.CatalogList
    );

    addConfigurationResourceWithPermission(
      UserRoles.DXCATALOG_PRODUCT,
      [UserRoles.CATALOG_WRITER, UserRoles.DXADMIN],
      Constants.RESOURCE_CATALOG_DRAFT,
      DefinedMenuSections.CatalogEdit
    );

    addConfigurationResourceWithPermission(
      UserRoles.DXCATALOG_PRODUCT,
      [UserRoles.CATALOG_WRITER, UserRoles.DXADMIN],
      Constants.RESOURCE_WEBFORM_DRAFT,
      DefinedMenuSections.CatalogEdit
    );

    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_FORMAT_TYPE,
      DefinedMenuSections.V2Administration
    );

    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_DX_ROLE,
      DefinedMenuSections.V2Administration
    );
    if (
      permissions[UserRoles.DXPURCHASE_PRODUCT] &&
      permissions[UserRoles.DXPURCHASE_PRODUCT].find(
        (p: string) => p === UserRoles.DXPURCHASE_ACCESS
      ) &&
      permissions[UserRoles.DXPURCHASE_PRODUCT].filter(
        (p: string) =>
          p !== UserRoles.DXPURCHASE_ACCESS && p !== UserRoles.BETA_TESTER
      ).length !== 0
    ) {
      // the user has access to DxProcess and can view documents.
      addResource(Constants.RESOURCE_DOCUMENTS, DefinedMenuSections.Documents);
    }

    // message Center
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      'configuration-message',
      DefinedMenuSections.MessageCenter
    );
    // User management.
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR, UserRoles.COMPANY_ADMINISTRATOR],
      'configuration-accounts',
      isPspAdministrator(permissions)
        ? DefinedMenuSections.UserManagementV3
        : DefinedMenuSections.UserManagement
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR, UserRoles.COMPANY_ADMINISTRATOR],
      'configuration-companies',
      isPspAdministrator(permissions)
        ? DefinedMenuSections.UserManagementV3
        : DefinedMenuSections.UserManagement
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      'configuration-people',
      isPspAdministrator(permissions)
        ? DefinedMenuSections.UserManagementV3
        : DefinedMenuSections.UserManagement
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      'configuration-identityProviders',
      isPspAdministrator(permissions)
        ? DefinedMenuSections.UserManagementV3
        : DefinedMenuSections.UserManagement
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.COMPANY_ADMINISTRATOR],
      'configuration-companyadminpeople',
      isPspAdministrator(permissions)
        ? DefinedMenuSections.UserManagementV3
        : DefinedMenuSections.UserManagement
    );

    // V2 customer admin user access
    addConfigurationResourceWithPermission(
      UserRoles.DXPURCHASE_PRODUCT,
      [UserRoles.EFACTURA_ACCESS, UserRoles.DXADMIN],
      'regulatorAccess/efactura',
      DefinedMenuSections.RegulatorAccess
    );

    // V3 user with feature and role
    addConfigurationResourceWithPermission(
      UserRoles.EFACTURA_PRODUCT,
      [UserRoles.EFACTURA_ACCESS],
      'regulatorAccess/efactura',
      DefinedMenuSections.RegulatorAccess
    );

    // V3 psp admin
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      'regulatorAccess/efactura',
      DefinedMenuSections.RegulatorAccess
    );

    addResource('configuration-dxfeatures', DefinedMenuSections.UserManagement);
    addResource('loginevents', DefinedMenuSections.UserManagement);
    addResource('accountlifecycleevents', DefinedMenuSections.UserManagement);
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_PRODUCTUSAGE,
      DefinedMenuSections.V2_GlobalView
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_ADDRESSES,
      DefinedMenuSections.V2_GlobalView
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_CONTACT,
      DefinedMenuSections.V2_GlobalView
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_SUPPORTED_PRODUCTS,
      DefinedMenuSections.V2_GlobalView
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_SUPPLIERS,
      DefinedMenuSections.V2_GlobalView
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.COMPANY_ADMINISTRATOR],
      Constants.RESOURCE_PRODUCTUSAGE,
      isPspAdministrator(permissions)
        ? DefinedMenuSections.V2Management_V2
        : DefinedMenuSections.V2Management
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.COMPANY_ADMINISTRATOR],
      Constants.RESOURCE_ADDRESSES,
      isPspAdministrator(permissions)
        ? DefinedMenuSections.V2Management_V2
        : DefinedMenuSections.V2Management
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_ADDRESS_BUFFER,
      isPspAdministrator(permissions)
        ? DefinedMenuSections.V2Management_V2
        : DefinedMenuSections.V2Management
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.COMPANY_ADMINISTRATOR],
      Constants.RESOURCE_CONTACT,
      isPspAdministrator(permissions)
        ? DefinedMenuSections.V2Management_V2
        : DefinedMenuSections.V2Management
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.COMPANY_ADMINISTRATOR],
      Constants.RESOURCE_SUPPORTED_PRODUCTS,
      isPspAdministrator(permissions)
        ? DefinedMenuSections.V2Management_V2
        : DefinedMenuSections.V2Management
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_DROPDOWNDEF,
      DefinedMenuSections.V2Administration
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_DXPRODUCT,
      DefinedMenuSections.V2Administration
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.COMPANY_ADMINISTRATOR],
      Constants.RESOURCE_SUPPLIERS,
      isPspAdministrator(permissions)
        ? DefinedMenuSections.V2Management_V2
        : DefinedMenuSections.V2Management
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.COMPANY_ADMINISTRATOR],
      Constants.RESOURCE_CUSTOMER,
      DefinedMenuSections.V2Management
    );

    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_USERS,
      isPspAdministrator(permissions)
        ? DefinedMenuSections.V2Management_V2
        : DefinedMenuSections.V2Management
    );

    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.COMPANY_ADMINISTRATOR, UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_ACCOUNT,
      isPspAdministrator(permissions)
        ? DefinedMenuSections.V2Management_V2
        : DefinedMenuSections.V2Management
    );

    addResourceWithPermission(
      UserRoles.CUSTOMER_ADMINISTRATOR,
      Constants.RESOURCE_PRODUCTUSAGE,
      DefinedMenuSections.V2Management
    );
    addResourceWithPermission(
      UserRoles.CUSTOMER_ADMINISTRATOR,
      Constants.RESOURCE_ADDRESSES,
      DefinedMenuSections.V2Management
    );
    addResourceWithPermission(
      UserRoles.CUSTOMER_ADMINISTRATOR,
      Constants.RESOURCE_CONTACT,
      DefinedMenuSections.V2Management
    );
    addResourceWithPermission(
      UserRoles.CUSTOMER_ADMINISTRATOR,
      Constants.RESOURCE_USERS,
      DefinedMenuSections.V2Management
    );
    addResourceWithPermission(
      UserRoles.CUSTOMER_ADMINISTRATOR,
      Constants.RESOURCE_SUPPLIERS,
      DefinedMenuSections.V2Management
    );
    addResourceWithPermission(
      UserRoles.CUSTOMER_ADMINISTRATOR,
      Constants.RESOURCE_CUSTOMER,
      DefinedMenuSections.V2Management
    );
    addResourceWithPermission(
      UserRoles.CUSTOMER_ADMINISTRATOR,
      Constants.RESOURCE_ACCOUNT,
      DefinedMenuSections.V2Management
    );
    // V2 Document Search
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_V2INVOICE,
      DefinedMenuSections.V2_DocumentSearch
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_V2ORDER,
      DefinedMenuSections.V2_DocumentSearch
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_V2DESPATCHADVICE,
      DefinedMenuSections.V2_DocumentSearch
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_V2RECEIPTADVICE,
      DefinedMenuSections.V2_DocumentSearch
    );
    //Monitoring
    addConfigurationResourceWithPermission(
      UserRoles.DXPURCHASE_PRODUCT,
      [UserRoles.BETA_TESTER],
      Constants.RESOURCE_EFACTURA_MONITORING_CONTENT_ERROR,
      DefinedMenuSections.Monitoring
    );
    addConfigurationResourceWithPermission(
      UserRoles.DXPURCHASE_PRODUCT,
      [UserRoles.BETA_TESTER],
      Constants.RESOURCE_EFACTURA_MONITORING_REJECTED_DOCUMENTS,
      DefinedMenuSections.Monitoring
    );
    // Task Management
    addResourceWithPermission(
      UserRoles.VIEW_TASKS,
      Constants.RESOURCE_TASKS,
      DefinedMenuSections.Tasks
    );

    addResourceWithPermission(
      UserRoles.VIEW_CONTRACT,
      Constants.RESOURCE_CONTRACT,
      DefinedMenuSections.Documents
    );
    addResourceWithPermission(
      UserRoles.VIEW_ORDER,
      Constants.RESOURCE_ORDER,
      DefinedMenuSections.Documents
    );
    addResourceWithPermission(
      UserRoles.CREATE_ORDER,
      Constants.RESOURCE_WEBORDER,
      DefinedMenuSections.Create
    );
    addResourceWithPermission(
      UserRoles.VIEW_DESPATCH_ADVICE,
      Constants.RESOURCE_DESPATCH_ADVICE,
      DefinedMenuSections.Documents
    );
    addResourceWithPermission(
      UserRoles.CREATE_DESPATCH_ADVICE,
      Constants.RESOURCE_WEBDESPATCH_ADVICE,
      DefinedMenuSections.Create
    );
    addResourceWithPermission(
      UserRoles.VIEW_RECEIPT_ADVICE,
      Constants.RESOURCE_RECEIPT_ADVICE,
      DefinedMenuSections.Documents
    );
    addResourceWithPermission(
      UserRoles.CREATE_RECEIPT_ADVICE,
      Constants.RESOURCE_WEBRECEIPT_ADVICE,
      DefinedMenuSections.Create
    );
    addResourceWithPermission(
      UserRoles.VIEW_INVOICE,
      Constants.RESOURCE_INVOICE,
      DefinedMenuSections.Documents
    );
    addResourceWithPermission(
      UserRoles.CREATE_INVOICE,
      Constants.RESOURCE_WEBINVOICE,
      DefinedMenuSections.Create
    );
    addResourceWithPermission(
      UserRoles.VIEW_FORECAST,
      Constants.RESOURCE_FORECAST,
      DefinedMenuSections.Documents
    );
    addResourceWithPermission(
      UserRoles.VIEW_CATALOG,
      Constants.RESOURCE_CATALOG,
      DefinedMenuSections.Documents
    );
    addResourceWithPermission(
      UserRoles.VIEW_WAYBILL,
      Constants.RESOURCE_WAYBILL,
      DefinedMenuSections.Documents
    );
    addConfigurationResourceWithPermission(
      UserRoles.ADMINISTRATION,
      [UserRoles.PSP_ADMINISTRATOR],
      Constants.RESOURCE_PEPPOL,
      DefinedMenuSections.Peppol
    );
    addResourceWithPermission(
      UserRoles.CREATE_WAYBILL,
      Constants.RESOURCE_WEBWAYBILL,
      DefinedMenuSections.Create
    );
    addResource(Constants.RESOURCE_FEEDBACK_MESSAGE);
    addResource(Constants.RESOURCE_ATTACHMENT);
    addResource(Constants.RESOURCE_USERPROFILES);
  }

  return newResources || {};
};

const SideBarMenu = (props: MenuProps) => {
  const { dense, hasDashboard, onMenuClick } = props;
  const resources: RaResource[] = useSelector((state: any) =>
    getResources(state)
  );
  const productName = useSelector(
    (state: any) => state.application ?? getDxApplication()
  );
  const open = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );
  const translate = useTranslate();
  const classes = useSideBarMenuStyles({ open });
  const { permissions } = usePermissionsOptimized();
  const count = useTaskCount({ permissions, productName });
  const dispatch = useDispatch();

  const translatedResources = React.useMemo(() => {
    const results = resources
      .filter((resource) => resource.hasList)
      .map((resource) => {
        let newResource = cloneDeep(resource);
        newResource.icon = <BadgedMenu resource={resource} count={count} />;

        // set translated label
        const options = newResource.options ?? {};

        // this is based on logic from react-admin's sidebarmenu
        options.label = newResource.options?.label
          ? translate(newResource.options.label)
          : translate(`resources.${resource.name}.name`);

        newResource.options = options;

        return newResource;
      });

    return results;
  }, [count, resources, translate]);

  const resourcesToDisplay = useResourcesToDisplay({
    translatedResources,
    productName,
  });

  return (
    <div className={classes.sidebar}>
      <div className={classes.toggle}>
        <Tooltip
          title={translate(
            open ? 'ra.action.close_menu' : 'ra.action.open_menu',
            {
              _: 'Open/Close menu',
            }
          )}
          enterDelay={500}
        >
          <IconButton
            color='inherit'
            onClick={() => dispatch(toggleSidebar())}
            size='small'
          >
            <ToggleIcon
              classes={{
                root: open
                  ? classes.menuButtonIconOpen
                  : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
      {hasDashboard && (
        <MenuItemsGroup
          label={translate('dxMessages.analytics.dashboardMenuGroup')}
          icon={<DashboardIcon />}
          key={`menu-group-dashboard`}
        >
          <DashboardMenuItem
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
            classes={{ root: classes.menuItem }}
            {...{
              activeClassName: classes.active,
              primaryText: translate('dxMessages.analytics.activitySummary'),
              leftIcon: <EqualizerOutlinedIcon />,
            }}
          />
          {/* <MenuItemLink
            onClick={onMenuClick}
            to={`/analytics`}
            dense={dense}
            sidebarIsOpen={open}
            classes={{ root: classes.menuItem }}
            {...{
              activeClassName: classes.active,
              primaryText: translate('dxMessages.analytics.analytics'),
              leftIcon: <TrendingUpOutlinedIcon />,
            }}
          /> */}
        </MenuItemsGroup>
      )}
      {DefinedMenuSections.asArray().map((menuSection, groupIdx) => {
        const menuItems: RaResource[] = resourcesToDisplay[menuSection.label];
        return (
          menuItems && (
            <Fragment key={`menu-fragment-${groupIdx}`}>
              {!!menuItems.length && (
                <MenuItemsGroup
                  label={translate(menuSection.label)}
                  icon={menuSection.icon}
                  key={`menu-group-${groupIdx}`}
                >
                  {menuItems.map((resource: any, idx: number) => (
                    <MenuItemLink
                      key={`menu-${groupIdx}-${idx}`}
                      onClick={onMenuClick}
                      to={`/${resource.name}`}
                      dense={dense}
                      sidebarIsOpen={open}
                      classes={{ root: classes.menuItem }}
                      {...{
                        activeClassName: classes.active,
                        primaryText: resource.options?.label ?? '???',
                        leftIcon: resource.icon,
                      }}
                    />
                  ))}
                </MenuItemsGroup>
              )}
            </Fragment>
          )
        );
      })}
      {open && (
        <SavedQueriesMenuItems classes={{ menuItem: classes.menuItem }} />
      )}
    </div>
  );
};

export default SideBarMenu;
