import PersonIcon from '@material-ui/icons/Person';
import PersonEdit from './PersonEdit';
import PersonList from './PersonList';

const persons = {
  icon: PersonIcon,
  list: PersonList,
  edit: PersonEdit,
};

export default persons;
