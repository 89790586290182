// A task UI to accept or reject a commercial invoice.
// It's a two-part layour:
// 1. the invoice, line by line with the n-way matching status,
//    expandable to show the details of the matching.
// 2. the n-way matching cluster displaying all the documents participating to the maching.
import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import compose from 'lodash/flowRight';
import { FC } from 'react';
import { TextInput, Title, required, useTranslate } from 'react-admin';
import {
  createColumnsProcessDetails,
  createCommonColumns,
} from '../../../../../../aspects';
import {
  EnhancedProps,
  FieldProps,
  Task,
} from '../../../../../../shared/types';
import ClusterPanel from '../../common/ClusterPanel';
import {
  ExchangeRate,
  Matching,
  RecadvLineSnapshot,
  Suggestion,
} from '../types';
import NwayMatchedInvoiceField from './I2prNwayMatchedInvoiceField';

const styles: any = (theme) => ({
  header: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  paper: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
});

// The columns to display in the cluster panel.
const columns: any[] = createCommonColumns()
  .concat(createColumnsProcessDetails())
  .filter((column) => column.displayInCluster === true);

// Make the document ID not clickable as we have the preview in the expand panel.
Object.values(columns).forEach((value: any) => {
  if (value.id === 'edm:documentId') {
    value.readOnly = true;
    value.label = 'dxMessages.headers.documentId';
  }
});

const i18nKey =
  'dxMessages.task.invoice_matching.invoiceHoldDecisionTask.cockpit.';

// The UI cockpit.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const NwayMatchingCockpit: FC<
  FieldProps<Task<NwayMatchingCockpitVariables>> &
    EnhancedProps & { classes: any }
> = (props) => {
  const { locale, resource, record, id } = props;
  const translate = useTranslate();
  if (!record) return null;

  const clusterDxuids = (record?.flow?.variables?.cluster || []).map(
    (doc) => doc['dxuid']
  );

  return (
    <>
      <Title title={i18nKey + 'title'} />
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Typography className={props.classes.header} variant='h6'>
            {translate(i18nKey + 'nwayMatchingOfTheinvoice')}:
          </Typography>
        </Grid>
        <Grid item>
          <Paper
            square
            className={classNames(props.classes.paper, 'related-document')}
          >
            <NwayMatchedInvoiceField record={record} locale={locale} />
          </Paper>
        </Grid>
        <Grid item>
          <Typography className={props.classes.header} variant='h6'>
            {translate(i18nKey + 'invoiceCluster')}:
          </Typography>
          <Typography variant='caption'>
            {translate(i18nKey + 'invoiceClusterDetails')}
          </Typography>
        </Grid>
        <Grid item>
          <Paper
            square
            className={classNames(props.classes.paper, 'related-document')}
          >
            <ClusterPanel
              resource={resource}
              id={id}
              dxuids={clusterDxuids}
              columns={columns}
            />
          </Paper>
        </Grid>
        <Grid item>
          <TextInput
            source='input.data.comment'
            label={i18nKey + 'comment'}
            fullWidth
            multiline
            rows='3'
            validate={required()}
          />
        </Grid>
      </Grid>
    </>
  );
};

interface NwayMatchingCockpitVariables {
  // The documents involved in the matching, that is: the invoice and the recadvs.
  // if 'completeCluster' below is false, it may lack some recadvs belonging to the cluster.
  // (the invoice is always present)
  cluster: Array<ClusterDocument>;
  companyId: string;
  // The invoice under matching.
  virtualInvoice: UblInvoice;
  // The recadvs belonging to the cluster.
  recadvs?: Array<UblRecadv>;
  // A flag indicating whether all the recadvs have been retrieved.
  completeCluster?: boolean;
  // A flag indicating whether the invoice was totally matched (all the lines match)
  invoiceMatched?: boolean;
  // The lines that was perfectly matched.
  matching?: Matching;
  // The lines that was imperfectly matched (bad unitary price,... but the item identifications
  // matches)
  suggestion?: Suggestion;
  // The quantities already invoiced on the recadv lines.
  recadvLineSnapshots?: Array<RecadvLineSnapshot>;
  // The currency exchange rates used during the macthing (when recadvs and invoice use different
  // currencies)
  exchangeRates?: Array<ExchangeRate>;
  // A flag indicating that this invoice was accepted previously but another invoice on the same
  // recadv lines was also accepted just before and removed some quantities on the recadv lines
  // that was matched by this invoice: so its acceptation was rollback-ed and its n-way matching
  // re-played.
  rollback: boolean;
  // The stringified JSON error ({key: "<error_id>", message: "<msg in English>"}).
  error?: string;
  // A flag indicating whether the sum of the SGR taxes on the invoice lines
  // matches the ones on the recadv lines.
  sgrTaxesMatched?: boolean;
  // The details of the errors when sgrTaxesMatched is false.
  sgrTaxesErrors?: SgrMatchingError[];
}

export interface SgrMatchingError {
  invoiceLineId: string;
  invoiceSgrAmount: number;
  recadvsSgrAmount: number;
  currency: string;
  noCurrencyRate: boolean;
}

type UblInvoice = any;
type UblRecadv = any;

// The cluster of P2P documents used during the n-way matching.
// That is:
// 1. the invoice under matching - always present (reason: target).
// 2. the recadvs linked to this invoice - may be lacking if not found (reason: order_link).
// 3. other invoices that invoiced part of the recadvs in the past (reason: invoiced_items)
// 4. if the invoice is a corrective, the invoices belonging to the correction chain
//    (the original commercial invoice to correct plus all the correctives issued on it till
//    the current one)
interface ClusterDocument {
  type: 'invoice' | 'recadv';
  dxuid: string;
  reason: 'target' | 'order_link' | 'invoiced_items' | 'correction_chain';
}

const enhance = compose(withStyles(styles, { withTheme: true }));

export default enhance(NwayMatchingCockpit);
