import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import classnames from 'classnames';
import React from 'react';
import { useTranslate } from 'react-admin';
import { toolbarStyles } from './styles';

const useStyles: any = makeStyles(toolbarStyles, {
  name: 'CustomToolbarButton',
});

const CustomToolbarButton: React.FC<any> = (props) => {
  const {
    className,
    label = 'ra.action.save',
    icon = <SaveIcon />,
    loading,
  } = props;

  const classes = useStyles(props);
  const translate = useTranslate();

  const sanitizeProps = ({
    saving,
    loading,
    basePath,
    onSave,
    handleSubmit,
    handleSubmitWithRedirect,
    submitOnEnter,
    pristine,
    invalid,
    undoable,
    ...rest
  }) => rest;

  const handleClick = (event) => {
    if (loading) {
      // prevent double submission
      event.preventDefault();
    } else {
      const { onClick } = props;

      event.preventDefault();

      if (typeof onClick === 'function') {
        onClick();
      }
    }
  };

  return (
    <Button
      {...sanitizeProps(props)}
      variant='contained'
      color={loading ? 'default' : 'primary'}
      className={classnames(classes.toolbarButton, className)}
      onClick={handleClick}
    >
      {loading ? (
        <CircularProgress
          size={18}
          thickness={2}
          className={classes.leftIcon}
        />
      ) : (
        React.cloneElement(icon, {
          className: classnames(classes.leftIcon, classes.icon),
        })
      )}
      {label && translate(label, { _: label })}
    </Button>
  );
};

export default CustomToolbarButton;
