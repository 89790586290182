import { Ubl } from '@dx-ui/dx-common';
import { cloneDeep, set, toPlainObject } from 'lodash';
import { LineProcessor } from '../../services/FormDataHelpers';
import { InvoiceLine } from '../../shared/types';

export class P2pUtils {
  /**
   * Converts a lines array to an object (usefull when displaying lines by a datagrid)
   */
  public static convertLines2ViewObject = (lines: any[]) => {
    const linesCopy = P2pUtils.createLinesCopy(lines);
    return toPlainObject(linesCopy);
  };

  public static convertInvoiceLines2ViewObject = (
    lines: InvoiceLine[],
    currencyID: string
  ) => {
    const linesCopy = P2pUtils.createLinesCopy(lines);
    linesCopy.forEach((line) => {
      LineProcessor.ensureAllowanceChargeAndDiscount(line, currencyID);
    });
    return toPlainObject(linesCopy);
  };

  public static convertOrderLines2ViewObject = (lines: any[]) => {
    const linesCopy = P2pUtils.createLinesCopy(lines);

    linesCopy.forEach((line) => {
      if (line.LineItem?.[0]?.ID?.[0]) {
        // draft document case. Rebuild the ID value
        line.LineItem[0].ID[0]._ = `${line.nr}`;
      }
      //lineExtensionAmount.value
      const { totalWithoutVat } = LineProcessor.calculateLineValues(line);
      set(line, Ubl.totalWithoutVat, totalWithoutVat);
    });

    return toPlainObject(linesCopy);
  };

  private static createLinesCopy(lines: any[]): any[] {
    if (!(lines instanceof Array)) {
      return [];
    }
    const linesCopy = cloneDeep(lines);
    linesCopy.forEach((line, index) => {
      line['nr'] = index + 1;
    });

    return linesCopy;
  }
}
