/**
 * Display the count of documents received by DocProcess per document
 * type and per day.
 */
import { FC, useEffect, useState } from 'react';
import {
  CashCollectionBarChart,
  DUE_DATE_INTERVALS,
} from '../live/TotalAmountOwedTopTen';

const TotalAmountOwedTopTenDemo: FC<{
  userPreferencesRootKey: string;
}> = ({ userPreferencesRootKey }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(undefined);
  useEffect(() => {
    const getData = () => {
      setLoading(true);
      let data: any = { metrics: [] };
      // based on sample entries, creates random data
      [
        'Client1',
        'Client2',
        'Client3',
        'Client4',
        'Client5',
        'Client6',
        'Client7',
        'Client8',
        'Client9',
        'Client10',
      ].forEach((recipientName) => {
        DUE_DATE_INTERVALS.forEach((interval) => {
          data.metrics.push({
            interval: interval,
            client: recipientName,
            value: Math.random() * 1000,
            currencyCode: 'EUR',
          });
        });
      });
      setData(data);
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <CashCollectionBarChart
      loading={loading}
      dueDateIntervals={DUE_DATE_INTERVALS}
      data={data}
      userPreferencesRootKey={userPreferencesRootKey}
    />
  );
};

export default TotalAmountOwedTopTenDemo;
