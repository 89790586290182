import { useGAPageViews } from '@dx-ui/dx-common';
import compose from 'lodash/flowRight';
import { ShowContext, translate, useShowController } from 'react-admin';
import WayBillShowView from './WayBillShowView';

const WayBillShow = (props: any) => {
  const controllerProps = useShowController(props);
  useGAPageViews();

  return (
    <ShowContext.Provider value={controllerProps}>
      <WayBillShowView controllerProps={controllerProps} {...props} />
    </ShowContext.Provider>
  );
};

const enhance = compose(translate);

export default enhance(WayBillShow);
