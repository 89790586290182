import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'flex-end',
    lineHeight: '0.65',
    padding: '1px 10px',
  },
}));

export const RowChanged = (props) => {
  const { newValue, oldValue } = props;
  const classes = useStyles();
  return (
    <div>
      {oldValue != null && (
        <div>
          <Alert classes={classes} icon={false} severity='error'>
            <strong>{oldValue}</strong>
          </Alert>
        </div>
      )}
      {newValue != null && (
        <div>
          {/* Alert really works for highlighting the difference in one cell */}
          <Alert classes={classes} icon={false} severity='success'>
            <strong>{newValue}</strong>
          </Alert>
        </div>
      )}
    </div>
  );
};

const ColumnRenderer = (props) => {
  const { rowId, comparedLines, originalLines, tableData, jsonPath } = props;
  if (
    get(comparedLines[rowId], jsonPath) != null &&
    get(originalLines[rowId], jsonPath) != null
  ) {
    //Edition, will return 2 Alerts. One red and one green
    return (
      <RowChanged
        oldValue={get(originalLines[rowId], jsonPath)}
        newValue={get(comparedLines[rowId], jsonPath)}
      />
    );
  } else if (get(comparedLines[rowId], jsonPath) != null) {
    // Addition, will return one green cell
    return (
      <RowChanged
        oldValue={undefined}
        newValue={get(comparedLines[rowId], jsonPath)}
      />
    );
  } else if (
    get(originalLines[rowId], jsonPath) != null &&
    comparedLines[rowId] == null
  ) {
    // Deletion, will return one red cell
    return (
      <RowChanged
        oldValue={get(originalLines[rowId], jsonPath)}
        newValue={undefined}
      />
    );
  } else {
    // No change, will return the value with no alert
    // Aligns the display with the RowChanged ones
    return (
      <div style={{ paddingRight: '10px' }}>
        {get(tableData, jsonPath) || ''}
      </div>
    );
  }
};

export default ColumnRenderer;
