/**
 * Display the count of documents received by DocProcess per document
 * type and per day with faked data.
 */
import { usePreferences } from '@react-admin/ra-preferences';
import moment, { Moment } from 'moment';
import { FC, useEffect, useState } from 'react';
import {
  DocumentReceptionsCountBarChart,
  DocumentType,
} from '../live/DocumentReceptions';

const DocumentReceptionsDemo: FC<{
  allowedDocumentTypes: DocumentType[];
  startDate: Moment;
  endDate: Moment;
  userPreferencesRootKey: string;
  onTheShelves: boolean;
  openConfiguration: boolean;
}> = ({
  allowedDocumentTypes,
  startDate,
  endDate,
  userPreferencesRootKey,
  onTheShelves,
  openConfiguration,
}) => {
  // Keep in the user preferences the document types he's interested in.
  const [documentTypes, setDocumentTypes] = usePreferences<DocumentType[]>(
    `${userPreferencesRootKey}.documentTypes`,
    allowedDocumentTypes
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(undefined);

  const epocStart = startDate.format('x');
  const epocEnd = endDate.format('x');

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      let data = {
        dailyDocumentMetrics: {
          metrics: [] as any[],
        },
      };

      // based on sample entries, creates random data per current month
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach(() => {
        const date = moment(
          parseInt(epocStart) +
            Math.floor(
              Math.random() * (parseInt(epocEnd) - parseInt(epocStart))
            )
        );

        data.dailyDocumentMetrics.metrics.push({
          dimensions: {
            dateDimension: { date: date.format('YYYY-MM-DD') },
          },
          docType: 'INVOICE',
          value: parseInt(Math.floor(Math.random() * 20).toString()),
        });
        data.dailyDocumentMetrics.metrics.push({
          dimensions: {
            dateDimension: { date: date.format('YYYY-MM-DD') },
          },
          docType: 'ORDER',
          value: parseInt(Math.floor(Math.random() * 15).toString()),
        });
        data.dailyDocumentMetrics.metrics.push({
          dimensions: {
            dateDimension: { date: date.format('YYYY-MM-DD') },
          },
          docType: 'RECADV',
          value: parseInt(Math.floor(Math.random() * 5).toString()),
        });
        data.dailyDocumentMetrics.metrics.push({
          dimensions: {
            dateDimension: { date: date.format('YYYY-MM-DD') },
          },
          docType: 'DESADV',
          value: parseInt(Math.floor(Math.random() * 5).toString()),
        });
      });
      setData(data);
      setLoading(false);
    };
    getData();
  }, [epocStart, epocEnd]);

  // Record the types of documents to show in the bar chart.
  const onToggleDocumentType: any = (type: DocumentType, show: boolean) => {
    if (show && !documentTypes.includes(type))
      setDocumentTypes([...documentTypes, type]);
    else if (!show && documentTypes.includes(type))
      setDocumentTypes(documentTypes.filter((_) => _ !== type));
  };

  return (
    <DocumentReceptionsCountBarChart
      loading={loading}
      data={data}
      documentTypes={documentTypes}
      allowedDocumentTypes={allowedDocumentTypes}
      userPreferencesRootKey={userPreferencesRootKey}
      onToggleDocumentType={onToggleDocumentType}
      onTheShelves={onTheShelves}
      openConfiguration={openConfiguration}
    />
  );
};

export default DocumentReceptionsDemo;
