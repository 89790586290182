import {
  cellStyle,
  colors,
  Constants,
  DateInput,
  DocumentTypeCode,
  documentTypeCodeToResource,
  EmptyValueField,
  GenericDateField,
  GenericSimpleField,
  GenericSubType,
  isUblAvailable,
  Metadata,
  PreviewButton as PreviewLink,
  SubTypeLabels,
  ThresholdTextInput,
  widthPresets,
} from '@dx-ui/dx-common';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import get from 'lodash/get';
import { linkToRecord } from 'ra-core';
import { TextInput, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import { DataHelpers } from '../services/DataHelpers';
import { ProcessStatusField } from './ProcessDetailsFields';

const useStyles = makeStyles(
  (theme) => ({
    documentField: {
      color: theme.palette.primary.main,
      textDecoration: 'none', // When rendered as a link, no underline until mouse on hover
      '&:hover.underlineOnHover': {
        textDecoration: 'underline',
      },
    },
    unread: {
      fontWeight: 700,
    },
  }),
  { name: 'CommonFields' }
);

/**
 * Renders the Document Type Code value as a Field
 * @param {*} translate I18N translate function
 * @param {*} record current row values
 */
export const DocumentTypeCodeField = ({ record = { properties: [] } }) => {
  const translate = useTranslate();

  const documentTypeCode = record.properties[Metadata.documentTypeCode];
  if (documentTypeCode === DocumentTypeCode.APERAK) {
    return (
      <Typography>{translate('dxMessages.documentTypeCode.APERAK')}</Typography>
    );
  } else if (documentTypeCode === DocumentTypeCode.CATLOG) {
    return (
      <Typography>{translate('dxMessages.documentTypeCode.CATLOG')}</Typography>
    );
  } else if (documentTypeCode === DocumentTypeCode.CNTCND) {
    return (
      <Typography>{translate('dxMessages.documentTypeCode.CNTCND')}</Typography>
    );
  } else if (documentTypeCode === DocumentTypeCode.DESADV) {
    return (
      <Typography>{translate('dxMessages.documentTypeCode.DESADV')}</Typography>
    );
  } else if (documentTypeCode === DocumentTypeCode.DELFOR) {
    return (
      <Typography>{translate('dxMessages.documentTypeCode.DELFOR')}</Typography>
    );
  } else if (documentTypeCode === DocumentTypeCode.INVOIC) {
    return (
      <Typography>{translate('dxMessages.documentTypeCode.INVOIC')}</Typography>
    );
  } else if (documentTypeCode === DocumentTypeCode.ORDERS) {
    return (
      <Typography>{translate('dxMessages.documentTypeCode.ORDERS')}</Typography>
    );
  } else if (documentTypeCode === DocumentTypeCode.RECADV) {
    return (
      <Typography>{translate('dxMessages.documentTypeCode.RECADV')}</Typography>
    );
  } else if (documentTypeCode === DocumentTypeCode.WAYBIL) {
    return (
      <Typography>{translate('dxMessages.documentTypeCode.WAYBIL')}</Typography>
    );
  } else {
    return documentTypeCode ? (
      <Typography>{documentTypeCode}</Typography>
    ) : (
      <EmptyValueField />
    );
  }
};

/**
 * Renders issuer info as a Field
 * @param {*} className
 * @param {*} record current row values
 */
export const IssuerField = ({ className, record = { properties: [] } }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Typography className={className}>
      {record.properties[Metadata.issuerName] || <EmptyValueField />}
    </Typography>
    {record.properties[Metadata.issuerId] ? (
      <Typography variant='caption'>
        {record.properties[Metadata.issuerId]}
      </Typography>
    ) : (
      !record.properties[Metadata.issuerName] && <EmptyValueField />
    )}
  </div>
);

/**
 * Renders issuerId info as a Field
 * @param {*} className
 * @param {*} record current row values
 */
export const IssuerIdField = (props) => <GenericSimpleField {...props} />;

/**
 * Renders recipient info as a Field
 * @param {*} className
 * @param {*} record current row values
 */
export const RecipientField = ({ className, record = { properties: [] } }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Typography className={className}>
      {record.properties[Metadata.recipientName] || <EmptyValueField />}
    </Typography>
    {record.properties[Metadata.recipientId] ? (
      <Typography variant='caption'>
        {record.properties[Metadata.recipientId]}
      </Typography>
    ) : (
      !record.properties[Metadata.recipientName] && <EmptyValueField />
    )}
  </div>
);

/**
 * Renders recipientId info as a Field
 * @param {*} className
 * @param {*} record current row values
 */
export const RecipientIdField = (props) => <GenericSimpleField {...props} />;

/**
 * Renders document name info as a Field
 * @param {*} className
 * @param {*} record current row values
 * @param {boolean} readOnly renders the field as a link if false or undefined
 * @param {string} basePath
 */
export const DocumentNameField = ({
  className,
  record = { properties: [], content: [] },
  readOnly,
}: any) => {
  const translate = useTranslate();

  if (readOnly === true) {
    // ShowMetadata use case
    return (
      <Typography className={className} variant='body2'>
        {record['name']}
      </Typography>
    );
  } else if (
    record.content.mimeType &&
    record.content.mimeType === 'application/pdf'
  ) {
    // PDF file => Preview
    return (
      <PreviewLink
        renderAsLink
        title={record['name']}
        href={`${record.id}/preview`}
      />
    );
  } else {
    // All format but PDF file => Download
    return (
      <Tooltip title={translate('dxMessages.buttons.download')}>
        <a
          href={`${record.id}/download`}
          style={{ color: colors.mainColor5 }}
          className={className}
        >
          {record['name']}
        </a>
      </Tooltip>
    );
  }
};

/**
 * Renders DXUID info as a Field
 * @param {*} className
 * @param {*} record current row values
 */
export const DXUIDField = (props) => <GenericSimpleField {...props} />;

/**
 * Renders document info as a Field
 * @param {*} className
 * @param {*} record current row values
 * @param {boolean} readOnly renders the field as a link if false or undefined
 * @param {string} basePath
 */
export const DocumentField = ({
  className,
  record = { properties: [], content: [] },
  readOnly,
  basePath,
}: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  let newBasePath = basePath;
  // We change the basePath for living documents here to make sure it is aligned with the resource
  const processDocumentFormatType =
    DataHelpers.getProcessDocumentFormatType(record);
  const processStatus = DataHelpers.getProcessStatus(record);
  let isReadOnly = readOnly;
  if (
    processDocumentFormatType !== Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT
  ) {
    newBasePath = `/${documentTypeCodeToResource(
      get(record.properties, Metadata.documentTypeCode)
    )}`;
    // No link when no UBL available for non web document
    isReadOnly = readOnly ? readOnly : !isUblAvailable(processStatus);
  }
  const documentId = record.properties[Metadata.documentId];
  const text = documentId ? documentId : '--';
  if (isReadOnly === true) {
    // All Applications ShowMetadata use case or any document CommonShow
    return documentId ? (
      <Typography className={className} variant='body2'>
        {documentId}
      </Typography>
    ) : (
      <EmptyValueField />
    );
  } else {
    return (
      <Tooltip title={translate('dxMessages.buttons.preview')}>
        <Link
          to={
            {
              pathname: `${linkToRecord(newBasePath, record.id)}/show`,
              typeCode: record.properties[Metadata.documentTypeCode],
            } as any
          }
          className={classNames(
            classes.documentField,
            'underlineOnHover',
            record.properties[Metadata.readStatus] === Constants.READ_STATUS_NEW
              ? classes.unread
              : null
          )}
        >
          {text}
        </Link>
      </Tooltip>
    );
  }
};

/**
 * Renders document Id info as a Field
 * @param {*} className
 * @param {*} record current row values
 */
export const DocumentIdField = (props) => <GenericSimpleField {...props} />;

export const IssueDateField = (props) => <GenericDateField {...props} />;

export const CreatedAtField = (props) => <GenericDateField {...props} />;

export const ModifiedAtField = (props) => <GenericDateField {...props} />;

export const DeliveryDateField = (props) => <GenericDateField {...props} />;

/**
 * Renders the edm:processDocumentFormatType value as a Field
 * @param {*} translate I18N translate function
 * @param {*} record current row values
 */
export const ProcessDocumentFormatTypeField = ({
  record = { properties: [] },
}) => {
  const translate = useTranslate();
  const processDocumentFormatType =
    record.properties[Metadata.processDocumentFormatType];
  if (
    processDocumentFormatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_IMAGE
  ) {
    return (
      <Typography>
        {translate('dxMessages.processDocumentFormatTypes.IMAGE')}
      </Typography>
    );
  } else if (
    processDocumentFormatType ===
    Constants.PROCESS_DOCUMENT_FORMAT_TYPE_ISSUER_IMAGE
  ) {
    return (
      <Typography>
        {translate('dxMessages.processDocumentFormatTypes.ISSUER_IMAGE')}
      </Typography>
    );
  } else if (
    processDocumentFormatType ===
    Constants.PROCESS_DOCUMENT_FORMAT_TYPE_RECIPIENT_IMAGE
  ) {
    return (
      <Typography>
        {translate('dxMessages.processDocumentFormatTypes.RECIPIENT_IMAGE')}
      </Typography>
    );
  } else if (
    processDocumentFormatType ===
    Constants.PROCESS_DOCUMENT_FORMAT_TYPE_ORIGINAL
  ) {
    return (
      <Typography>
        {translate('dxMessages.processDocumentFormatTypes.ORIGINAL')}
      </Typography>
    );
  } else if (
    processDocumentFormatType ===
    Constants.PROCESS_DOCUMENT_FORMAT_TYPE_ISSUER_ORIGINAL
  ) {
    return (
      <Typography>
        {translate('dxMessages.processDocumentFormatTypes.ISSUER_ORIGINAL')}
      </Typography>
    );
  } else if (
    processDocumentFormatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_TARGET
  ) {
    return (
      <Typography>
        {translate('dxMessages.processDocumentFormatTypes.TARGET')}
      </Typography>
    );
  } else if (
    processDocumentFormatType === Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DX ||
    processDocumentFormatType === null
  ) {
    return (
      <Typography>
        {translate('dxMessages.processDocumentFormatTypes.DX')}
      </Typography>
    );
  } else {
    return <EmptyValueField />;
  }
};

export const DocumentSubTypeField = (props) => {
  const subType = get(
    props.record?.properties,
    Metadata.documentSubTypeCode
  ) as GenericSubType;

  if (SubTypeLabels[subType]) {
    return <GenericSimpleField {...props} label={SubTypeLabels[subType]} />;
  } else {
    return <EmptyValueField />;
  }
};

export const ReceiverIdField = (props) => {
  const receiverId = get(props.record?.properties, Metadata.receiverId);
  if (receiverId?.length !== 0) {
    return <GenericSimpleField {...props} label={receiverId} />;
  } else {
    return <EmptyValueField />;
  }
};

const documentColumnLabel = ({ resource }) => {
  let documentColumnLabel = 'dxMessages.headers.documentId';
  switch (resource) {
    case Constants.RESOURCE_INVOICE:
    case Constants.RESOURCE_WEBINVOICE:
      documentColumnLabel = 'dxMessages.headers.invoiceId';
      break;
    case Constants.RESOURCE_ORDER:
    case Constants.RESOURCE_WEBORDER:
      documentColumnLabel = 'dxMessages.headers.orderId';
      break;
    case Constants.RESOURCE_DESPATCH_ADVICE:
    case Constants.RESOURCE_WEBDESPATCH_ADVICE:
      documentColumnLabel = 'dxMessages.headers.despatchAdviceId';
      break;
    case Constants.RESOURCE_RECEIPT_ADVICE:
    case Constants.RESOURCE_WEBRECEIPT_ADVICE:
      documentColumnLabel = 'dxMessages.headers.receiptAdviceId';
      break;
    case Constants.RESOURCE_FORECAST:
      documentColumnLabel = 'dxMessages.headers.forecastId';
      break;
    default:
      documentColumnLabel = 'dxMessages.headers.documentId';
  }
  return documentColumnLabel;
};

/**
 * common columns
 */
export const createCommonColumns = () => [
  {
    id: 'name',
    label: 'dxMessages.headers.documentName',
    sortable: true,
    hiddenByDefault: true, // not display by default
    displayedOnlyInLists: true, // not in metadata
    displayInRelatedDocuments: true, // displayed in Related documents
    displayInGroupingMode: false, // not selectable for display
    width: widthPresets.huge,
    cellStyle: cellStyle,
    renderCell: (props) => <DocumentNameField {...props} />,
  },
  {
    id: Metadata.documentId,
    label: (props) => documentColumnLabel({ ...props }),
    sortable: false, // Alfresco doesn't allow sorting on d:text
    alwaysOn: true,
    displayInCluster: true,
    displayInLinkedDocuments: true,
    sticky: true, // The column doesn't move on horizontal scrolling.
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <DocumentField {...props} />,
  },
  {
    id: Metadata.processStatus,
    label: 'dxMessages.headers.status',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    alwaysOn: true,
    displayInRelatedDocuments: true,
    displayInCluster: true,
    displayInLinkedDocuments: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <ProcessStatusField {...props} />,
  },
  {
    id: Metadata.documentTypeCode,
    label: 'dxMessages.headers.documentTypeCode',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    displayInLinkedDocuments: true,
    width: widthPresets.medium,
    displayInCluster: true,
    cellStyle: cellStyle,
    readOnly: true,
    renderCell: (props) => <DocumentTypeCodeField {...props} />,
  },
  {
    id: Metadata.issuerName,
    label: 'dxMessages.headers.issuerName',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    alwaysOn: true,
    displayInRelatedDocuments: true,
    displayInLinkedDocuments: true,
    width: widthPresets.medium,
    renderCell: (props) => <IssuerField {...props} />,
  },
  {
    id: Metadata.issuerId,
    label: 'dxMessages.headers.issuerId',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    displayedOnlyInLists: true, // not in metadata
    width: widthPresets.medium,
    renderCell: (props) => <IssuerIdField {...props} />,
  },
  {
    id: Metadata.recipientName,
    label: 'dxMessages.headers.recipientName',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    alwaysOn: true,
    displayInRelatedDocuments: true,
    displayInLinkedDocuments: true,
    width: widthPresets.medium,
    renderCell: (props) => <RecipientField {...props} />,
  },
  {
    id: Metadata.recipientId,
    label: 'dxMessages.headers.recipientId',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.medium,
    displayedOnlyInLists: true, // not in metadata
    renderCell: (props) => <RecipientIdField {...props} />,
  },
  {
    id: Metadata.issueDate,
    label: 'dxMessages.headers.issueDate',
    sortable: true,
    alwaysOn: true,
    displayInRelatedDocuments: true,
    displayInCluster: true,
    displayInLinkedDocuments: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <IssueDateField {...props} />,
  },
  {
    id: Metadata.createdAt,
    label: 'dxMessages.headers.creationDate',
    sortable: true,
    sortBy: Metadata.created,
    alwaysOn: true,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <CreatedAtField {...props} />,
  },
  {
    id: Metadata.modifiedAt,
    label: 'dxMessages.headers.modificationDate',
    sortable: true,
    sortBy: Metadata.modified,
    alwaysOn: false,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <ModifiedAtField {...props} id='modifiedAt' />,
  },
  {
    id: Metadata.dxuid,
    label: 'dxMessages.headers.dxuid',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.large,
    cellStyle: cellStyle,
    renderCell: (props) => <DXUIDField {...props} />,
  },
  {
    id: Metadata.processDocumentFormatType,
    label: 'dxMessages.headers.processDocumentFormatType',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    displayInGroupingMode: false,
    displayInRelatedDocuments: true,
    readOnly: true,
    renderCell: (props) => <ProcessDocumentFormatTypeField {...props} />,
  },
  {
    id: Metadata.adherentUniqueIdentifier,
    label: 'dxMessages.headers.adherentUniqueIdentifier',
    sortable: false, // Alfresco doesn't allow sorting on d:text
    hiddenByDefault: true,
    width: widthPresets.small,
    cellStyle: cellStyle,
    readOnly: true,
    renderCell: (props) => <GenericSimpleField {...props} />,
  },
  {
    id: Metadata.deliveryDate,
    label: 'dxMessages.headers.deliveryDate',
    sortable: true,
    hiddenByDefault: true,
    width: widthPresets.medium,
    cellStyle: cellStyle,
    renderCell: (props) => <DeliveryDateField {...props} />,
  },
];

/**
 * Common filters
 */
export const createCommonFilters = () => [
  {
    id: Metadata.documentId,
    filter: (props) => (
      <TextInput
        source={Metadata.documentId}
        label={documentColumnLabel({ ...props })}
        resettable
        {...props}
      />
    ),
    alwaysOn: true,
  },
  {
    id: Metadata.issuerName,
    filter: (props) => (
      <TextInput
        source={Metadata.issuerName}
        label='dxMessages.filter.issuerLabels.name'
        resettable
        {...props}
      />
    ),
    alwaysOn: true,
  },
  {
    id: Metadata.issuerId,
    filter: (props) => (
      <TextInput
        source={Metadata.issuerId}
        label='dxMessages.filter.issuerLabels.id'
        resettable
        {...props}
      />
    ),
  },
  {
    id: Metadata.recipientName,
    filter: (props) => (
      <TextInput
        source={Metadata.recipientName}
        label='dxMessages.filter.recipientLabels.name'
        resettable
        {...props}
      />
    ),
    alwaysOn: true,
  },
  {
    id: Metadata.recipientId,
    filter: (props) => (
      <TextInput
        source={Metadata.recipientId}
        label='dxMessages.filter.recipientLabels.id'
        resettable
        {...props}
      />
    ),
  },
  {
    id: Metadata.issueDate,
    filter: (props) => (
      <DateInput
        source={Metadata.issueDate}
        label='dxMessages.headers.issueDate'
        {...props}
      />
    ),
    alwaysOn: true,
  },
  {
    id: Metadata.created,
    filter: (props) => (
      <DateInput
        source={Metadata.created}
        label='dxMessages.headers.creationDate'
        {...props}
      />
    ),
    alwaysOn: true,
  },
  {
    id: Metadata.modified,
    filter: (props) => (
      <DateInput
        source={Metadata.modified}
        label='dxMessages.headers.modificationDate'
        {...props}
      />
    ),
  },
  {
    id: Metadata.adherentUniqueIdentifier,
    filter: (props) => (
      <TextInput
        source={Metadata.adherentUniqueIdentifier}
        label='dxMessages.headers.adherentUniqueIdentifier'
        resettable
        {...props}
      />
    ),
  },
  {
    id: Metadata.dxuid,
    filter: (props) => (
      <ThresholdTextInput
        source={Metadata.dxuid}
        label='dxMessages.headers.dxuid'
        resettable
        minLengthThreshold={24}
        validationRegExp='^D$|^DX[_A-Za-z0-9]*$'
        validationErrorMessage='dxMessages.error_messages.invalid_DXUID'
        {...props}
      />
    ),
  },
  {
    id: Metadata.deliveryDate,
    filter: (props) => (
      <DateInput
        source={Metadata.deliveryDate}
        label='dxMessages.headers.deliveryDate'
        {...props}
      />
    ),
  },
];
