export const toolbarStyles = (theme): any => ({
  toolbar: {
    backgroundColor: theme.palette.background.default,
  },
  toolbarButton: {
    marginLeft: 5,
    minWidth: 180,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 18,
  },
  toolbarRightButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
});
