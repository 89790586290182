import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import NavigationRefresh from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';
import { refreshView, useTranslate } from 'ra-core';
import * as React from 'react';
import { FC, ReactElement, useCallback } from 'react';
import { useDispatch } from 'react-redux';

const AttachmentsRefreshButton: FC<AttachmentsRefreshButtonButtonProps> = ({
  label = 'ra.action.refresh',
  icon = defaultIcon,
  onClick,
  refresh = false,
  className,
  ...rest
}) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      if (refresh) {
        dispatch(refreshView());
      }
      if (typeof onClick === 'function') {
        onClick(event);
      }
    },
    [dispatch, onClick, refresh]
  );

  return (
    <Tooltip title={label && translate(label, { _: label })}>
      <IconButton
        aria-label={label && translate(label, { _: label })}
        className={className}
        color='inherit'
        onClick={handleClick}
        {...rest}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};

const defaultIcon = <NavigationRefresh />;

interface Props {
  className?: string;
  icon?: ReactElement;
  label?: string;
  refresh?: boolean;
  onClick?: (e: MouseEvent) => void;
}

export type AttachmentsRefreshButtonButtonProps = Props & IconButtonProps;

AttachmentsRefreshButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
  refresh: PropTypes.bool,
};

export default AttachmentsRefreshButton;
