import { DxTheme } from '@dx-ui/dx-common';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { connect } from 'react-redux';
import {
  UpdateFormTemplateAction,
  updateFormTemplate,
} from '../../../actions/UpdateFormTemplate';
import { OnChangeDoParams, onChangeFunctions } from '../onChangeFunctions';
import { buildFieldValidation, checkFieldValidity } from '../utils';
import { useCommonFieldStyles } from './CommonFieldStyles';
import { FieldComponentProps } from './FieldComponentProps';
import { sanitizeFieldComponentProps } from './SanitizeFieldProps';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    fieldStyle: {
      width: '100%',
    },
    hidden: {
      display: 'none',
    },
  }),
  { name: 'FieldWrapper' }
);

interface FieldWrapperProps extends FieldComponentProps {
  children: any;
  updateFormTemplate: (template) => UpdateFormTemplateAction;
  className?: string;
}

/**
 * Renders a container (a Paper containing inputs)
 */
const FieldWrapper = (props: FieldWrapperProps) => {
  const {
    template,
    field,
    readOnly: readOnlyForm,
    updateFormTemplate,
    record,
    source,
    children,
    documentForm,
  } = props;

  const classes = useStyles(props);
  const commonFieldClasses = useCommonFieldStyles(props);
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const form = useForm();
  const [loading, setLoading] = useState(false);
  const [previousValue, setPreviousValue] = useState();

  const options = field?.options;
  const readOnly = readOnlyForm || options?.readOnly;
  const validate = React.useMemo(
    () =>
      readOnly
        ? []
        : buildFieldValidation(template, field, { source: source }, translate),
    [readOnly, template, field, source, translate]
  );

  const error = checkFieldValidity(field);
  if (error) {
    return error;
  }

  /**
   * Addresses value as text issue (@see https://github.com/facebook/react/issues/1425)
   */
  const getValue = (
    targetElement: HTMLTextAreaElement | HTMLInputElement | any
  ) => {
    let result: string | number | undefined | null = targetElement?.value;
    if (targetElement?.type === 'number') {
      const resultNumber = parseFloat(targetElement.value);
      if (!isNaN(resultNumber)) {
        result = resultNumber;
      }
    }

    return result;
  };

  const childInputProps = {
    ...sanitizeFieldComponentProps(props),
    label: field.label,
    className: classNames(
      commonFieldClasses.noWrapTitle,
      readOnly ? commonFieldClasses.readOnly : '',
      classes.fieldStyle,
      !!options?.hidden ? classes.hidden : ''
    ),
    inputProps: {
      style: options?.inputStyle,
      readOnly,
    },
    style: options?.style,
    qaselector: source,
    validate,
    fullWidth: !!options?.fullWidth,
    onFocus: () => {
      const previousValue = get(record, source || '');
      setPreviousValue(previousValue);
    },
    onChange: (event) => {
      if (field.onChangeDo) {
        const params: OnChangeDoParams = {
          form,
          template,
          formData: record,
          emitter: source,
          newValue: event?.target ? getValue(event?.target) : event,
          previousValue: getValue({
            value: previousValue,
            type: event?.target?.type,
          }),
          updateFormTemplate,
          translate,
          showNotification: notify,
          dataProvider,
          documentForm: documentForm,
        };
        setLoading(true);
        onChangeFunctions.callOnChangeDo(field.onChangeDo, params);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    },
    readOnly,
    ...children?.props,
    defaultValue: field.defaultValue,
    variant: options?.variant || 'standard',
    tooltip: options?.tooltip || undefined,
    withConfirm: options?.withConfirm || undefined,
    enableIfValid: options?.enableIfValid || undefined,
    multiple: options?.multiple || undefined,
  };

  return (
    <Grid
      item
      xs={options?.xs}
      spacing={options?.spacing}
      direction={options?.direction}
      className={!!options?.hidden ? classes.hidden : ''}
    >
      {children({ ...childInputProps, loading: loading })}
    </Grid>
  );
};

const enhance = connect(undefined, {
  updateFormTemplate,
});

export default enhance(FieldWrapper);
