import {
  checkPermissions,
  Constants,
  DocumentTypeCode,
  documentTypeCodeToResource,
  formatDate,
  formatDateToAlfresco,
  Metadata,
  ProcessStatus,
  UserRoles,
} from '@dx-ui/dx-common';
import { AddressDetails } from '@dx-ui/lib-oasis-ubl-2.1/src/WaybillModel';
import { cloneDeep, get, set, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { buildAddressAsAString } from '../modules/common/PreviewAddress';
import WaybillSources from '../modules/waybill/WayBillSources';
import {
  AlfrescoContent,
  DocumentProperties,
  P2pData,
  WebFormData,
} from '../shared/types';
import { setFieldPropertyBy, setSourceField } from '../shared/webForms/utils';
import {
  DuplicationType,
  IAlfrescoDocumentService,
} from './AlfrescoDocumentService';
import { BaseDocumentService } from './BaseDocumentService';
import { CompanyService } from './CompanyService';
import { DataHelpers } from './DataHelpers';
import { FormDataHelpers } from './FormDataHelpers';
import { IDocumentService } from './IDocumentService';
import { IInvoiceService } from './InvoiceService';
import { TemplatesService } from './TemplatesService';

const TEMPLATE_TYPE = 'WAYBILL';

export interface IWaybillService extends IDocumentService {}

export enum ServiceDescriptionCode {
  GLASS_SALE = '1',
  GLASS_ACQUISITION = '2',
  CULLETS_RETURN = '3',
  SHELVES_RETURN = '4',
  FEES = '5',
}
export class WaybillService
  extends BaseDocumentService
  implements IWaybillService
{
  constructor(
    private invoiceService: IInvoiceService,
    private companyService: CompanyService,
    private templatesService: TemplatesService,
    documentService: IAlfrescoDocumentService
  ) {
    super(documentService);
  }

  private static documentType: DocumentTypeCode = DocumentTypeCode.WAYBIL;
  private static waybillHeader: any = {
    _D: 'urn:oasis:names:specification:ubl:schema:xsd:Waybill-2',
    _S: 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2',
    _B: 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2',
  };

  public async load(nodeId: string): Promise<AlfrescoContent> {
    const result = await this.documentService.loadDocument(
      nodeId,
      WaybillService.documentType
    );

    return {
      properties: {},
      ublContent: {
        lines: [],
        ublProperties: result,
      },
    };
  }

  public async loadDraft(nodeId: string | undefined): Promise<AlfrescoContent> {
    const resource = documentTypeCodeToResource(WaybillService.documentType);
    const payload = { id: nodeId };
    const response = await this.documentService.dataProvider[
      Constants.API_GET_JSON_CONTENT
    ](resource, payload);
    const data = { ublProperties: response.data };
    const webWayBillSources = new WaybillSources(true);
    const waybill = get(data, webWayBillSources.WayBillSources().root.source);
    // EDM Properties are extracted from UBL content
    const metatadaProperties = {};
    metatadaProperties[Metadata.documentId] = get(
      waybill,
      webWayBillSources.WayBillRootSources().number
    );
    metatadaProperties[Metadata.issueDate] = formatDateToAlfresco(
      get(waybill, webWayBillSources.WayBillRootSources().issueDate)
    );

    const consignorParty = get(
      waybill,
      webWayBillSources.WayBillPartySources().consignorParty.source
    );
    metatadaProperties[Metadata.recipientId] = get(
      consignorParty,
      webWayBillSources.WayBillPartySources().consignorParty.fields.vatId
    );
    metatadaProperties[Metadata.recipientName] = get(
      consignorParty,
      webWayBillSources.WayBillPartySources().consignorParty.fields.name
    );

    const carrierParty = get(
      waybill,
      webWayBillSources.WayBillPartySources().carrierParty.source
    );
    metatadaProperties[Metadata.issuerId] = get(
      carrierParty,
      webWayBillSources.WayBillPartySources().carrierParty.fields.vatId
    );
    metatadaProperties[Metadata.issuerName] = get(
      carrierParty,
      webWayBillSources.WayBillPartySources().carrierParty.fields.name
    );

    const result = {
      properties: metatadaProperties,
      ublContent: {
        lines: [],
        ublProperties: response.data,
      },
    };

    this.setRequiredDataIfMissing(result, DocumentTypeCode.WAYBIL);

    return result;
  }

  public createNewContent(): AlfrescoContent {
    const data: AlfrescoContent = {
      ...this.documentService.initNewDocument(DocumentTypeCode.WAYBIL),
      ublContent: {
        lines: [],
        ublProperties: {},
      },
    };

    this.setRequiredDataIfMissing(data);
    return data;
  }

  public async createNew(recipientId: string): Promise<string | undefined> {
    let waybillContent: AlfrescoContent = await this.createNewContent();
    set(waybillContent.properties, Metadata.recipientId, recipientId);

    const waybillProperties = await this.createOrUpdateDocument(
      undefined,
      waybillContent
    );

    return waybillProperties.id;
  }

  /**
   * Saves a waybill document to alfresco
   */
  public async createOrUpdateDocument(
    nodeId: string | undefined,
    data: AlfrescoContent
  ): Promise<DocumentProperties> {
    const queryType = nodeId
      ? Constants.API_PUT_SAVE_DOCUMENT
      : Constants.API_POST_CREATE_DOCUMENT;
    const resource = documentTypeCodeToResource(WaybillService.documentType);

    const webWayBillSources = new WaybillSources(true);
    const waybill = get(
      data.ublContent,
      webWayBillSources.WayBillSources().root.source
    );
    // EDM Properties are extracted from UBL content
    const metatadaProperties = {};
    metatadaProperties[Metadata.documentId] = get(
      waybill,
      webWayBillSources.WayBillRootSources().number
    );

    // Metadata : DespatchAdvice details
    let documentReferences = get(
      waybill,
      webWayBillSources.WayBillRootSources().documentReferences
    );

    if (documentReferences) {
      // Check consistency
      documentReferences = documentReferences.filter(
        (element) =>
          get(
            element,
            webWayBillSources.DocumentReferenceSources().documentTypeCode
          ) !== undefined
      );

      documentReferences.forEach((element) => {
        const typeCode = get(
          element,
          webWayBillSources.DocumentReferenceSources().documentTypeCode
        );
        if (typeCode === DocumentTypeCode.DESADV) {
          set(
            metatadaProperties,
            Metadata.despatchAdviceId,
            get(element, webWayBillSources.DocumentReferenceSources().number)
          );
        }
        const issueDate = get(
          element,
          webWayBillSources.DocumentReferenceSources().issueDate
        );
        if (issueDate) {
          set(
            element,
            webWayBillSources.DocumentReferenceSources().issueDate,
            formatDate(issueDate)
          );
        }
      });
      set(
        waybill,
        webWayBillSources.WayBillRootSources().documentReferences,
        documentReferences
      );
    }

    // Metadata : Order details
    set(
      metatadaProperties,
      Metadata.orderId,
      get(waybill, webWayBillSources.WayBillRootSources().orderReferenceId)
    );

    // Metadata : transport details
    const carrierParty = get(
      waybill,
      webWayBillSources.WayBillPartySources().carrierParty.source
    );
    set(
      metatadaProperties,
      Metadata.carrierPartyName,
      get(carrierParty, webWayBillSources.PartySources().name)
    );
    set(
      metatadaProperties,
      Metadata.issuerName,
      get(carrierParty, webWayBillSources.PartySources().name)
    );
    set(
      metatadaProperties,
      Metadata.issuerId,
      get(carrierParty, webWayBillSources.PartySources().vatId)
    );

    const consignorParty = get(
      waybill,
      webWayBillSources.WayBillPartySources().consignorParty.source
    );
    set(
      metatadaProperties,
      Metadata.consignorPartyName,
      get(consignorParty, webWayBillSources.PartySources().name)
    );

    // loading / unloading locations
    const shipmentInformation = get(
      waybill,
      webWayBillSources.WayBillRootSources().shipmentInformation.source
    );

    // Required default IDs and Date format
    set(
      shipmentInformation,
      webWayBillSources.ShipmentInformationSources().id,
      '1'
    );
    const consigment = get(
      shipmentInformation,
      webWayBillSources.ShipmentInformationSources().consignment.source
    );
    set(consigment, webWayBillSources.ConsignmentSources().id, '1');

    const shipmentStage = get(
      shipmentInformation,
      webWayBillSources.ShipmentInformationSources().shipmentStage.source
    );
    set(shipmentStage, webWayBillSources.ShipmentStageSources().id, '1');
    const loadingDate = get(
      shipmentStage,
      webWayBillSources.ShipmentStageSources().loadingDate
    );
    if (loadingDate) {
      set(
        shipmentStage,
        webWayBillSources.ShipmentStageSources().loadingDate,
        formatDate(loadingDate)
      );
    }

    const unloadingDate = get(
      shipmentStage,
      webWayBillSources.ShipmentStageSources().unloadingDate
    );
    if (unloadingDate) {
      set(
        shipmentStage,
        webWayBillSources.ShipmentStageSources().unloadingDate,
        formatDate(unloadingDate)
      );
    }

    // Metadata : transport details loading info
    const loadingLocation = get(
      shipmentStage,
      webWayBillSources.ShipmentStageSources().loadingLocation.source
    );
    set(
      metatadaProperties,
      Metadata.loadingLocationName,
      get(
        loadingLocation,
        webWayBillSources.ShipmentStageSources().loadingLocation.fields.name
      )
    );

    const loadingLocationAddressSource = get(
      loadingLocation,
      webWayBillSources.ShipmentStageSources().loadingLocation.fields.address
        .source
    );
    const loadingLocationAddress = this._createAddress(
      webWayBillSources,
      loadingLocationAddressSource
    );

    set(
      metatadaProperties,
      Metadata.loadingLocationAddress,
      loadingLocationAddress
    );

    // Metadata : transport details unloading info
    const unloadingLocation = get(
      shipmentStage,
      webWayBillSources.ShipmentStageSources().unloadingLocation.source
    );
    set(
      metatadaProperties,
      Metadata.unloadingLocationName,
      get(
        unloadingLocation,
        webWayBillSources.ShipmentStageSources().unloadingLocation.fields.name
      )
    );

    const unloadingLocationAddressSource = get(
      unloadingLocation,
      webWayBillSources.ShipmentStageSources().unloadingLocation.fields.address
        .source
    );

    let unloadingLocationAddress = this._createAddress(
      webWayBillSources,
      unloadingLocationAddressSource
    );
    set(
      metatadaProperties,
      Metadata.unloadingLocationAddress,
      unloadingLocationAddress
    );

    // ChildConsigment ID must be filled with "1": WayBill/Shipment/Consignment/ChildConsignment/ID/IdentifierContent
    set(
      consigment,
      webWayBillSources.ConsignmentSources().childConsignmentID,
      '1'
    );

    const waybillData: AlfrescoContent = {
      ublContent: {
        ...WaybillService.waybillHeader,
        ...data.ublContent.ublProperties,
      },
      properties: { ...data.properties, ...metatadaProperties },
    };
    const payload = {
      data: waybillData,
      id: nodeId,
    };
    const result = await this.documentService.dataProvider[queryType](
      resource,
      payload
    );
    return result.data;
  }

  public async clone(nodeId: string): Promise<AlfrescoContent> {
    return await this.documentService.copy(nodeId, DuplicationType.COPY);
  }

  private setRequiredDataIfMissing(data, documentTypeCode?: DocumentTypeCode) {
    const webWaybillSources = new WaybillSources(true);
    if (documentTypeCode && !get(data.properties, Metadata.documentTypeCode)) {
      set(data.properties, Metadata.documentTypeCode, documentTypeCode);
    }

    if (!get(data.properties, Metadata.processDocumentFormatType)) {
      set(
        data.properties,
        Metadata.processDocumentFormatType,
        Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT
      );
    }

    if (!get(data.properties, Metadata.processStatus)) {
      set(data.properties, Metadata.processStatus, ProcessStatus.DRAFT);
    }

    if (!get(data.properties, Metadata.readStatus)) {
      set(data.properties, Metadata.readStatus, Constants.READ_STATUS_NEW);
    }

    // document issue date : format + copy in Metadata
    // Valid issue date for the doc is when it is sent (not a UI responsability).
    // Thus, this one is set only for DRAFT document listing/search purpose and
    // always set to now when the document is editable.
    const now = moment(new Date());
    const newIssueDate = now.format('YYYY-MM-DD');
    let waybill = get(
      data.ublContent,
      webWaybillSources.WayBillSources().root.source
    );
    if (!waybill) {
      set(data.ublContent, webWaybillSources.WayBillSources().root.source, {});
      waybill = get(
        data.ublContent,
        webWaybillSources.WayBillSources().root.source
      );
    }
    set(
      waybill,
      webWaybillSources.WayBillRootSources().issueDate,
      newIssueDate
    );
    set(
      data.properties,
      Metadata.issueDate,
      formatDateToAlfresco(newIssueDate)
    );
  }

  /**
   * Checks if the user has the permissions to create a document
   */
  public static canCreate(permissions: any): boolean {
    return (
      permissions &&
      checkPermissions(
        permissions,
        UserRoles.DXPURCHASE_PRODUCT,
        UserRoles.CREATE_WAYBILL
      )
    );
  }

  private _createAddress(
    webWayBillSources: any,
    addressInfo: any
  ): string | undefined {
    const loadingStreet = get(
      addressInfo,
      webWayBillSources.AddressSources().street
    );
    const loadingAddStreet = get(
      addressInfo,
      webWayBillSources.AddressSources().addStreet
    );
    const loadingBuilding = get(
      addressInfo,
      webWayBillSources.AddressSources().number
    );
    const loadingCity = get(
      addressInfo,
      webWayBillSources.AddressSources().city
    );
    const loadingCountrySubEntityCode = get(
      addressInfo,
      webWayBillSources.AddressSources().countrySubEntityCode
    );
    const loadingPostalCode = get(
      addressInfo,
      webWayBillSources.AddressSources().postalCode
    );
    const loadingCountryCode = get(
      addressInfo,
      webWayBillSources.AddressSources().countryCode
    );

    const newAddress: AddressDetails = {
      StreetName: [{ _: loadingStreet ? loadingStreet : '' }],
      AdditionalStreetName: [{ _: loadingAddStreet ? loadingAddStreet : '' }],
      BuildingNumber: [{ _: loadingBuilding ? loadingBuilding : '' }],
      CityName: [{ _: loadingCity ? loadingCity : '' }],
      PostalZone: [{ _: loadingPostalCode ? loadingPostalCode : '' }],
      CountrySubentityCode: [{ _: loadingCountrySubEntityCode }],
      Country: [
        {
          IdentificationCode: [
            { _: loadingCountryCode ? loadingCountryCode : '' },
          ],
        },
      ],
    };

    const address: string | undefined = buildAddressAsAString(newAddress);

    return address;
  }

  /**
   * Returns the I18N key associated to serviceCode
   * @param serviceCode WayBill/Shipment/Consignment/CarrierAssignedID/IdentifierContent value
   */
  public static getWaybillServiceDescriptionText(
    serviceCode: string | number | null | undefined
  ) {
    let descriptionText: string;
    switch (serviceCode) {
      case ServiceDescriptionCode.GLASS_SALE:
        descriptionText = 'dxMessages.waybill.serviceDescription.glassSale';
        break;
      case ServiceDescriptionCode.GLASS_ACQUISITION:
        descriptionText =
          'dxMessages.waybill.serviceDescription.glassAcquisition';
        break;
      case ServiceDescriptionCode.CULLETS_RETURN:
        descriptionText = 'dxMessages.waybill.serviceDescription.culletsReturn';
        break;
      case ServiceDescriptionCode.SHELVES_RETURN:
        descriptionText = 'dxMessages.waybill.serviceDescription.shelvesReturn';
        break;
      case ServiceDescriptionCode.FEES:
        descriptionText = 'dxMessages.waybill.serviceDescription.fees';
        break;
      default:
        descriptionText = '';
    }
    return descriptionText;
  }

  /**
   * get data for a Waybill document based on 'mode'
   * @param nodeId Alfresco node Id of the document
   * @param recipientId recipient Id of the document
   * @param locale
   * @param alfrescoMetadata - alfresco properties (edm:* fields)
   */
  public async getData(
    nodeId: string | undefined,
    recipientId: string,
    locale: string,
    alfrescoMetadata: any = {},
    newTemplate?: any
  ): Promise<WebFormData> {
    let document: AlfrescoContent;
    if (newTemplate) {
      // template reload only
      document = this.createNewContent();
    } else {
      document = await this.loadDraft(nodeId);
    }

    const flattenedDocument = {
      properties: document.properties,
      ublProperties: document.ublContent.ublProperties,
      lines: document.ublContent.lines,
    };
    const completeData: P2pData = {
      id: nodeId,
      properties: alfrescoMetadata,
      lines: [],
      ublProperties: {},
    };
    const selectValues = [];

    DataHelpers.setRecipientId(flattenedDocument, recipientId);

    // Adds recipientName information. Retrieves it from B/E if not in consignorParty
    if (!DataHelpers.getRecipientName(flattenedDocument)) {
      const details = await this.companyService.getDetails(recipientId);
      DataHelpers.setRecipientName(flattenedDocument, details.name);
    }

    const { data: hardcodedData, lists: hardcodedLists } =
      this.getHardcodedValues(TEMPLATE_TYPE);

    const documentTypeCode: DocumentTypeCode | undefined =
      DataHelpers.getDocumentTypeCode(flattenedDocument);

    if (documentTypeCode === undefined) {
      throw new Error('documentTypeCode cannot be undefined!');
    }

    const template = await this.templatesService.getTemplate(
      documentTypeCode,
      undefined,
      undefined,
      recipientId
    );

    const { defaultValues: templateData, selectValues: templateLists } =
      this.templatesService.getValues(template);

    // sets default
    this.mergeFormData(completeData, hardcodedData);
    this.mergeFormData(selectValues, hardcodedLists, true);
    this.mergeFormData(selectValues, templateLists);
    this.mergeFormData(completeData, templateData);

    // sets document data
    this.mergeFormData(completeData, flattenedDocument);

    // sets dynamic data
    const { data, lists } = await this.fillInvoiceDocumentReference(
      flattenedDocument,
      locale
    );
    await this.fillCarrierPartyInformation(data, lists, locale);
    this.mergeFormData(selectValues, lists);
    this.mergeFormData(completeData, data);

    await this.fillLoadingUnloadingPortLocation(
      data,
      lists,
      locale,
      recipientId,
      flattenedDocument
    );

    this.mergeFormData(selectValues, lists);
    this.mergeFormData(completeData, data);

    const documentData: P2pData = {
      id: completeData.id,
      lines: completeData.lines,
      properties: completeData.properties,
      ublProperties: completeData.ublProperties,
    };

    // Optional behavior according to template definition
    let modifiedTemplate = cloneDeep(template);

    modifiedTemplate = this.manageDescriptionType(
      modifiedTemplate,
      documentData
    );

    return {
      documentData,
      selectValues,
      template: modifiedTemplate,
    };
  }

  /**
   * Saves the data as an Alfresco document
   * @param formData Formatted document data
   */
  public async saveData(formData: P2pData): Promise<DocumentProperties> {
    const data = FormDataHelpers.convertToAlfrescoContent(formData);
    return await this.createOrUpdateDocument(formData.id, data);
  }

  /**
   * Checks if a template exists for user's data
   */
  public async hasWaybillTemplate(
    documentRecipientId: string
  ): Promise<boolean> {
    let invoiceSubTypes = await this.templatesService.getSubTypes(
      DocumentTypeCode.WAYBIL,
      documentRecipientId
    );

    return invoiceSubTypes.length > 0;
  }

  /**
   * Waybill context
   */
  private async fillCarrierPartyInformation(
    data: any,
    lists: any,
    locale: any
  ) {
    const details = await this.companyService.getDetails();
    const WaybillUbl = WaybillSources.WebWaybillUbl();

    setSourceField(data, WaybillUbl.carrierPartyVatId, details.identification);
    setSourceField(data, WaybillUbl.carrierPartyName, details.name);
    setSourceField(
      data,
      WaybillUbl.carrierPartyPostalAddressNumber,
      details.billingAddress?.buildingNumber
    );

    // HOT FIX : TO BE REVISITED ONCE WE HAVE ALL THE SPECS CLEARED
    // V2 DB Address workaround which is take into account on backend side could not fit issuer info.
    // B/E sets name = tb_address.street, street = tb_address.additionalstreet
    let street = details.billingAddress?.street;
    if (!street || street === '') {
      // Meaning that additionalstreet was empty or not defined
      // => reverts the B/E V2 DB workaround
      street = details.billingAddress?.name;
    }
    setSourceField(data, WaybillUbl.carrierPartyPostalAddressStreet, street);
    setSourceField(
      data,
      WaybillUbl.carrierPartyPostalAddressAddStreet,
      details.billingAddress?.additionalStreet,
      false
    );
    setSourceField(
      data,
      WaybillUbl.carrierPartyPostalAddressCity,
      details.billingAddress?.city
    );
    setSourceField(
      data,
      WaybillUbl.carrierPartyPostalAddressPostalCode,
      details.billingAddress?.postalCode
    );

    const countrySelectData = this.getCountriesSelectData(
      details.billingAddress?.idCountry,
      locale
    );

    setSourceField(
      // selected country code
      lists,
      WaybillUbl.carrierPartyPostalAddressCountryCode,
      countrySelectData.choices
    );
    setSourceField(
      // country code selection list
      data,
      WaybillUbl.carrierPartyPostalAddressCountryCode,
      countrySelectData.defaultValue
    );
  }

  /**
   * Waybill context
   */
  private async fillLoadingUnloadingPortLocation(
    data: any,
    lists: any,
    locale: any,
    recipientId: string,
    document: any
  ) {
    const details = await this.companyService.getDetails(recipientId);
    const WaybillUbl = WaybillSources.WebWaybillUbl();

    const locations = details.logisticAddress?.map((l) => {
      return {
        id: l.gln,
        name: `${l.name} - ${l.city}`,
        street: l.street,
        additionalStreet: l.additionalStreet,
        buildingNumber: l.buildingNumber,
        city: l.city,
        countryCode: l.idCountry,
        postalCode: l.postalCode,
      };
    });

    // build selection box items
    const locationsSelectItems = this.createSelectData(
      locations,
      'id',
      'name',
      true, // don't apply alternative default value
      undefined // default value
    );
    const countrySelectData = this.getCountriesSelectData(
      locations[0].countryCode,
      locale
    );

    // Secures the entries if DB is not set correctly
    const noDupChoices = uniqBy(locationsSelectItems.choices, 'id');

    // sets loadingPortLocation info
    setSourceField(
      lists,
      WaybillUbl.shipmentInformationShipmentStageLoadingLocationName,
      noDupChoices
    );
    setSourceField(
      lists,
      WaybillUbl.shipmentInformationShipmentStageLoadingLocationAddressCountryCode,
      countrySelectData.choices
    );

    // sets unloadingPortLocation info
    setSourceField(
      lists,
      WaybillUbl.shipmentInformationShipmentStageUnloadingLocationName,
      noDupChoices
    );
    setSourceField(
      lists,
      WaybillUbl.shipmentInformationShipmentStageUnloadingLocationAddressCountryCode,
      countrySelectData.choices
    );
  }

  /**
   * Waybill context
   */
  private async fillInvoiceDocumentReference(document: any, locale: string) {
    const data: any = {};
    const lists: any = {};
    const filter = {
      [Metadata.processDocumentFormatType]:
        Constants.PROCESS_DOCUMENT_FORMAT_TYPE_DRAFT,
      [Metadata.processStatus]: ProcessStatus.DRAFT,
    };
    let invoicesDratf: any | undefined = undefined;
    invoicesDratf = await this.invoiceService.getInvoices(filter);
    const invoiceIdItems = invoicesDratf?.map((r) => {
      const issueDate = moment
        .tz(get(r.properties, Metadata.issueDate), 'UTC')
        .format('l');
      return {
        id: get(r.properties, Metadata.dxuid),
        name: `${get(r.properties, Metadata.documentId)} - ${issueDate}`,
      };
    });
    const referenceInvoiceIdSelectItems = this.createSelectData(
      invoiceIdItems,
      'id',
      'name',
      false,
      invoiceIdItems[0]?.id
    );
    const webWayBillSources = new WaybillSources(true);
    const waybillSource = webWayBillSources.WayBillSources().root.source;

    const referenceInvoiceSource = `${waybillSource}.${
      webWayBillSources.WayBillRootSources().documentReferences
    }[0].${webWayBillSources.DocumentReferenceSources().uuid}`;
    setSourceField(
      lists,
      referenceInvoiceSource,
      referenceInvoiceIdSelectItems.choices
    );

    const referenceInvoiceTypeCode = `${waybillSource}${
      webWayBillSources.WayBillRootSources().documentReferences
    }[0].${webWayBillSources.DocumentReferenceSources().documentTypeCode}`;
    setSourceField(data, referenceInvoiceTypeCode, DocumentTypeCode.INVOIC);

    return { data, lists };
  }

  /**
   * Sets the required properties of shipmentInformationShipmentStageLoadingNumber and
   *  ublProperties.Waybill[0].DocumentReference[1].ID[0].IdentifierContent according to
   * ServiceDescriptionCode
   */
  private manageDescriptionType(
    modifiedTemplate: any,
    documentData: P2pData
  ): any {
    // Change fields properties according to service description type current value
    const descriptionTypeCode =
      DataHelpers.getServiceDescriptionTypeCode(documentData);
    let isRequired = false;
    if (descriptionTypeCode) {
      if (
        descriptionTypeCode === ServiceDescriptionCode.GLASS_SALE ||
        descriptionTypeCode === ServiceDescriptionCode.GLASS_ACQUISITION ||
        descriptionTypeCode === ServiceDescriptionCode.FEES
      ) {
        isRequired = true;
      }
      const webWayBillSources = new WaybillSources(true);
      // loading number
      modifiedTemplate = setFieldPropertyBy(
        modifiedTemplate,
        'source',
        'ublProperties.Waybill[0].Shipment[0].ShipmentStage[0].LoadingTransportEvent[0].IdentificationID[0].IdentifierContent',
        'required',
        isRequired
      );
      if (descriptionTypeCode === ServiceDescriptionCode.GLASS_ACQUISITION) {
        // despatch Advice ID is optional for GLASS ACQUISITION
        isRequired = false;
      }
      // despatch Advice reference ID
      modifiedTemplate = setFieldPropertyBy(
        modifiedTemplate,
        'source',
        `${webWayBillSources.WayBillSources().root.source}.${
          webWayBillSources.WayBillRootSources().documentReferences
        }[1].${webWayBillSources.DocumentReferenceSources().number}`,
        'required',
        isRequired
      );
    }
    return modifiedTemplate;
  }
}
