import { AuditTrailButton } from '@dx-ui/dx-common';
import { useFeature } from 'flagged';
import React from 'react';
import { TopToolbar } from 'react-admin';
import { AlfrescoDocumentService } from '../services';

const DocumentPreviewShowActions = (props) => {
  const { data } = props;
  const auditTrailEnabled = useFeature('auditTrail');

  if (!data) return null;
  let isWebDocument = AlfrescoDocumentService.isWebDocument(data);

  return (
    <TopToolbar>
      {auditTrailEnabled && !isWebDocument && (
        <AuditTrailButton documentId={data.id} />
      )}
    </TopToolbar>
  );
};

export default DocumentPreviewShowActions;
