import { cellStyle } from '@dx-ui/dx-common';
import { makeStyles } from '@material-ui/core/styles';

export const styles: any = (theme) => ({
  headerCell: {
    '&.alignRight': {
      textAlign: 'right',
    },
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  tbody: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  row: {
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  section: {
    paddingTop: '2em',
  },
  graySection: {
    backgroundColor: theme.palette.grey[100],
    paddingTop: '1em',
    paddingBottom: '1em',
    marginTop: '2em',
  },
  linesCell: {
    '&.alignRight': {
      textAlign: 'right',
    },
    cursor: 'initial',
    pointerEvents: 'none',
  },
  cellWrapped: {
    ...cellStyle,
  },
  paper: {
    padding: theme.spacing(2),
    minWidth: 750, // Ensure that the view won't be altered when window size is small
    boxShadow: 'unset',
  },
  circularProgress: {
    position: 'relative',
    left: '25em',
    top: '2em',
  },
});

export const useCellStyle = makeStyles(() => ({
  cellWrapped: cellStyle as any,
}));

export const useCommonPreviewStyles = makeStyles(
  (theme) => ({
    headerCell: {
      '&.alignRight': {
        textAlign: 'right',
      },
      borderTopStyle: 'solid',
      borderTopWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    tbody: {
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    row: {
      '&:hover': {
        backgroundColor: 'unset !important',
      },
    },
    section: {
      paddingTop: '2em',
    },
    graySection: {
      backgroundColor: theme.palette.grey[100],
      paddingTop: '1em',
      paddingBottom: '1em',
      marginTop: '2em',
    },
    linesCell: {
      '&.alignRight': {
        textAlign: 'right',
      },
      cursor: 'initial',
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(2),
      minWidth: 750, // Ensure that the view won't be altered when window size is small
      boxShadow: 'unset',
    },
    circularProgress: {
      position: 'relative',
      left: '25em',
      top: '2em',
    },
  }),
  { name: 'CommonPreviewStyles' }
);
