import { WebFormMode } from '@dx-ui/dx-common';
import { DespatchAdvicePreview } from '..';
import DocumentEdit from '../../common/DocumentEdit';

const WebDespatchAdviceEdit = (props) => {
  const { location } = props;
  const redirectMode = location?.state?.redirectMode;
  return (
    <DocumentEdit
      {...props}
      previewComponent={DespatchAdvicePreview}
      title={
        redirectMode && redirectMode === WebFormMode.SCRATCH_DESPATCH_ADVICE
          ? 'dxMessages.despatchAdvices.create_title'
          : 'dxMessages.despatchAdvices.edit_title'
      }
      mode={WebFormMode.DRAFT_DESPATCH_ADVICE}
    />
  );
};

export default WebDespatchAdviceEdit;
