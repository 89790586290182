import { Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { FC } from 'react';
import { FieldProps } from './types';

const MomentField: FC<FieldProps & { errorWhenInPast?: boolean }> = ({
  record,
  source,
  errorWhenInPast,
}) => {
  if (!record || !source || !record[source]) return <Typography>-</Typography>;

  const now = moment();
  const diff = moment(record[source]).diff(now);
  const duration = moment.duration(diff);

  return (
    <Tooltip title={moment(new Date(record[source])).format('llll')}>
      <div style={{ whiteSpace: 'nowrap' }}>
        {(diff > 0 || !errorWhenInPast) && (
          <Typography>{moment(record[source]).fromNow(true)}</Typography>
        )}
        {diff <= 0 && errorWhenInPast && (
          <Typography color='error'>{duration.humanize(true)}</Typography>
        )}
      </div>
    </Tooltip>
  );
};

export default MomentField;
