import { isPspAdministrator, useGAPageViews } from '@dx-ui/dx-common/src';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  Filter,
  FilterProps,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import { Empty } from '../../../common/Empty';

// interface LastLoginTimeResponse extends RaRecord {
//   // ISO-8601 representation.
//   lastLoginTime: string;
// }

const ContactFilters = (props: Omit<FilterProps, 'children'>) => {
  const { permissions, loaded } = usePermissions();
  if (!loaded || !permissions) return null;

  return (
    <Filter {...props}>
      {isPspAdministrator(permissions) && (
        <ReferenceInput
          label='resources.accounts.list.headers.company'
          source='companyId'
          reference='management/company'
          filterToQuery={(searchText) => ({
            accountname: [searchText],
          })}
          sort={{ field: 'accountname', order: 'ASC' }}
          allowEmpty={true}
          alwaysOn
        >
          <AutocompleteInput
            optionText='accountname'
            optionValue='id'
            resettable={true}
          />
        </ReferenceInput>
      )}
    </Filter>
  );
};

const ContactList = (props: any) => {
  useGAPageViews();
  const { permissions, loaded } = usePermissions();

  if (!loaded || !permissions) return null;

  const ListActions = () => {
    return (
      <TopToolbar>
        {isPspAdministrator(permissions) && <CreateButton />}
        <ExportButton maxResults={5000} />
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<ContactFilters />}
      empty={isPspAdministrator(permissions) ? <Empty /> : false}
      actions={<ListActions />}
    >
      <Datagrid style={{ marginTop: '1em' }}>
        {isPspAdministrator(permissions) && (
          <ReferenceField
            label='resources.accounts.list.headers.company'
            source='companyId'
            reference='management/company'
            link={false}
            sortable={false}
          >
            <TextField source='accountname' />
          </ReferenceField>
        )}
        <TextField
          label={'dxMessages.companyManagement.contact.lastName'}
          source='lastName'
        />
        <TextField
          label={'dxMessages.companyManagement.contact.firstName'}
          source='firstName'
        />
        <TextField
          label={'dxMessages.companyManagement.contact.phone'}
          source='phone'
          sortable={false}
        />
        <TextField
          label={'dxMessages.companyManagement.contact.mail'}
          source='mail'
          sortable={false}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ContactList;
