import { WebFormMode } from '@dx-ui/dx-common';
import { OrderPreview } from '..';
import DocumentEdit from '../../common/DocumentEdit';

const WebOrderEdit = (props) => {
  const { location } = props;
  const redirectMode = location?.state?.redirectMode;
  return (
    <DocumentEdit
      {...props}
      previewComponent={OrderPreview}
      title={
        redirectMode && redirectMode === WebFormMode.SCRATCH_ORDER
          ? 'dxMessages.orders.create_title'
          : 'dxMessages.orders.edit_title'
      }
      mode={WebFormMode.DRAFT_ORDER}
    />
  );
};

export default WebOrderEdit;
