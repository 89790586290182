import { createStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import compose from 'lodash/flowRight';
import React, { useState } from 'react';
import { translate } from 'react-admin';
import { withRouter } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import {
  ChangePasswordRoutePath,
  UserProfileRoutePath,
} from './MyAccountUtils';
import UserProfile from './UserProfile';

const styles = () => createStyles({});

const sanitizeProps = ({ staticContext, ...rest }) => rest;

export enum TabValues {
  UserProfile,
  ChangePassword,
}

const MyAccount = withStyles(styles)((props: any) => {
  const {
    translate,
    feature = TabValues.ChangePassword,
    history,
    ...rest
  } = sanitizeProps(props);

  const username = localStorage.getItem('user');

  const [value, setValue] = useState(feature);

  const handleChange = (event, value) => {
    if (value === TabValues.UserProfile) {
      history.push(UserProfileRoutePath);
    }

    if (value === TabValues.ChangePassword) {
      history.push(ChangePasswordRoutePath);
    }

    setValue(value);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab
          value={TabValues.UserProfile}
          label={translate('dxMessages.messages.update_profile_title')}
        />
        <Tab
          value={TabValues.ChangePassword}
          label={translate('dxMessages.auth.change_password_title')}
        />
      </Tabs>
      {value === TabValues.UserProfile && (
        <UserProfile {...rest} username={username} />
      )}
      {value === TabValues.ChangePassword && (
        <ChangePassword {...rest} username={username} />
      )}
    </>
  );
});

const enhance = compose(translate, withStyles(styles), withRouter);
export default enhance(MyAccount);
