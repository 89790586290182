//import { TextInput } from 'react-admin';
import MaskedInput from 'react-text-mask';
import TextInput from './TextInput';

/**
 * Defines a specific Masked Input for GLN Code.
 * 13 numbers resettable input
 * @param {*} props
 */
const GLNCodeMaskInput = (props) => {
  const { inputRef, ...other } = props;
  const handleChange = (event, onChange) => {
    /**
     * Necessary because of a React bug on <input type="number">
     * @see https://github.com/facebook/react/issues/1425
     */
    const numericValue = isNaN(parseFloat(event.target.value))
      ? null
      : parseFloat(event.target.value);
    onChange(`${numericValue}`);
  };
  const handleBlur = (event, onBlur) => {
    /**
     * Necessary because of a React bug on <input type="number">
     * @see https://github.com/facebook/react/issues/1425
     */
    const numericValue = isNaN(parseFloat(event.target.value))
      ? null
      : parseFloat(event.target.value);
    onBlur(`${numericValue}`);
  };
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      onBlur={(event) => handleBlur(event, props.onBlur)}
      onChange={(event) => handleChange(event, props.onChange)}
    />
  );
};

const GLNInput = ({
  resettable,
  source,
  label = 'dxMessages.headers.gln',
  InputProps,
  titleSuffix,
  ...rest
}: any) => (
  <TextInput
    InputProps={{
      ...InputProps,
      inputComponent: GLNCodeMaskInput,
    }}
    label={label}
    source={source}
    {...rest}
    resettable={resettable ? true : false}
    titleSuffix={titleSuffix}
  />
);

export default GLNInput;
