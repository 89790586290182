import { GridDirection, GridSize, GridSpacing } from '@material-ui/core';
import { Identifier, Record, RedirectionSideEffect, Translate } from 'ra-core';
import { ReactNode } from 'react';
import { FormRenderProps } from 'react-final-form';

export * from './alfrescoTypes';

// ***************************************************************************************************
// See https://marketplace.visualstudio.com/items?itemName=maptz.regionfolder to use VS Code regions
// ***************************************************************************************************

/* #region Template/Schema related types */

/**
 * Types of comparison used by different compare validators
 */
export enum CompareTypeOperator {
  LT = 'LT',
  LTE = 'LTE',
  GT = 'GT',
  GTE = 'GTE',
  EQ = 'EQ',
}

export const CompareValidatorTargetToday = '__today__';
export interface CompareValidator {
  operator: CompareTypeOperator;
  target: string;
  message: string;
}
export interface IsSetValidator {
  target: string;
  message: string;
}

export interface MustBeSetValidator {
  targets: string[];
  message: string;
}

export interface RegexValidator {
  regex: string;
  message: string;
}

/**
 * Types of Document service used by a templateElement
 */
export enum DocumentServiceName {
  INVOICE_SERVICE = 'INVOICE_SERVICE',
  ORDER_SERVICE = 'ORDER_SERVICE',
  DESPATCH_ADVICE_SERVICE = 'DESPATCH_ADVICE_SERVICE',
  RECEIPT_ADVICE_SERVICE = 'RECEIPT_ADVICE_SERVICE',
}

/**
 * An element inside a model item
 */
export interface TemplateModelElement {
  name: string;
  type: string;
  source?: string;
  sourceName?: string;
  label?: string;
  title?: string;
  onChangeDo?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  maxDecimals?: number;
  numbersOnly?: boolean;
  email?: boolean;
  lineQuantitiesWithSameSign?: boolean;
  nonZero?: boolean;
  negative?: boolean;
  options?: OptionsParams;
  customValidate?: CustomValidation;
  items?: TemplateModelElement[];
  topItems?: TemplateModelElement[];
  operations?: OperationParams[];
  reference?: any;
  resource?: any;
  compareDates?: CompareValidator;
  defaultValue?: any;
  selectValues?: any;
  fixedLength?: number;
  allowEmpty?: boolean;
  regexMatch?: RegexValidator;
  isSet?: IsSetValidator;
  mustBeSet?: IsSetValidator;
  businessTerms?: string[];
}

export interface OptionsParams {
  title?: boolean;

  /**
   * Applied to the field, sets a relative size (xs between 1 and 12)
   */
  xs?: GridSize;

  /**
   * Sets the spacing between layout grid cells
   */
  spacing?: GridSpacing;

  /**
   * Sets the layout flow direction for a grid container
   */
  direction?: GridDirection;

  /**
   * Style applied to InputProps prop of the input control
   */
  inputStyle?: any;

  /**
   * Style applied to the field
   */
  style?: any;

  /**
   * Text used by select inputs
   */
  optionText?: string;

  /**
   * Value used by select inputs
   */
  optionValue?: string;

  /**
   * value type : 'number'
   * accept only numerical value
   */
  optionType?: string;

  /**
   * render Text for autocomplete addition
   */
  optionAddRenderText?: string;

  /**
   * Path to the actual value inside leaf objects used for multi select controls. Example "_" for { _ : "MissingMerchandise" }.
   */
  itemOptionValue?: string;

  /**
   * Value used by AutocompleteTextInputs
   */
  optionTooltip?: string;

  /**
   * Sent into the field props
   */
  fullWidth?: boolean;
  arrayItem?: boolean;

  /**
   * Renders the field as a readonly
   */
  readOnly?: boolean;

  noDefault?: boolean;
  /**
   * Ex: <TextInput type={type} />
   */
  type?: string;
  addable?: boolean;
  removable?: boolean;
  price?: boolean;
  numberToDisplaySearch?: number;
  hidden?: boolean;
  /**
   * ActionButton variant
   */
  variant?: string;
  /**
   * ActionButton tooltip
   */
  tooltip?: string;

  /**
   * ActionButton confirm popup definition
   */
  withConfirm?: any;

  /**
   * Action Button disabled/enabled management
   */
  enableIfValid?: any;

  /**
   * SelectTaskArrayInput specific
   */
  multiple?: boolean;

  /**
   * DocumentService name (newLine used for arrays for instance)
   */
  documentServiceName?: DocumentServiceName;

  /**
   * Arrays inputs computation on Add or Remove lines
   */
  noComputationAddRemove?: boolean;
}

export interface OperationParams {
  text: string;
  source: string;
  members: { name: string }[];
  operator: string;
  currency: string;
}

/**
 * Metadata information describing the template
 */
export interface TemplateMetadata {
  recipientId: string;
  recipientName: string;
  documentTypeCode: string;
  documentSubType?: string;
  category?: string;
  description?: string;
}

/**
 * Template data.
 */
export interface Template {
  metadata?: TemplateMetadata;
  model: TemplateModelElement[];
  onChangeFunctions: OnChangeFunctionInfo[];
}
export interface ValidationEmitter {
  source: string | undefined;
}
/**
 * On change function parameters minimum set of fields
 */
export interface OnChangeFunctionInfo {
  name: string;
  onChangeDo: string; // change this because it has the same name as the other field in the template
}

export interface CustomValidation {
  name: string;
  params: any[];
  message: string;
}

/* #endregion */

/* #region Handwritten types for ubl (fake or real ubl) parts*/

/**
 * Represents an Address in UBL
 */
export interface Address extends Record {
  buildingNumber?: ValueString;
  cityName?: ValueString;
  country?: {
    identificationCode: ValueString;
    name: ValueString;
  };
  postalZone?: ValueString;
  streetName?: ValueString;
  additionalStreetName?: ValueString;
}

export interface V2CompanyModel extends Record {
  accountname?: ValueString;
  idcontact?: ValueString;
  idformattype?: ValueString;
  idformattypedesadv?: ValueString;
  idstatus?: ValueString;
  idtype?: ValueString;
  identification?: ValueString;
  traderegnr?: ValueString;
  uidIdentification?: ValueString;
  countryCode?: ValueString;
  languageCode?: ValueString;
}

export interface Location {
  id: ValueString;
  address: Address;
  description: ValueString[];
}

export interface Delivery {
  actualDeliveryDate: ValueString;
  deliveryLocation: Location;
}

export interface Party {
  party: {
    postalAddress: Address;
  };
}
export interface ValueNumber {
  value: number | undefined;
}

export interface ValueBoolean {
  value: boolean | undefined;
}

export interface ValueString {
  value: string | undefined;
}

export interface IdValue {
  id: ValueString;
}

export interface TaxTotalItem {
  taxAmount: CurrencyAmount;
  taxSubtotal?: TaxItem[];
}

export interface TaxCategory {
  percent?: ValueString;
  taxScheme: {
    id: ValueString;
    name: ValueString;
    taxTypeCode: ValueString;
  };
}

export interface TaxItem {
  percent?: ValueNumber;
  taxAmount: CurrencyAmount;
  taxCategory: TaxCategory;
  taxableAmount?: CurrencyAmount;
}

export interface AllowanceChargeItem {
  id?: any;
  chargeIndicator?: ValueBoolean;
  allowanceChargeReasonCode?: ValueString;
  allowanceChargeReason?: ValueString;
  amount: CurrencyAmount; // in UBL this is not optional!
  accountingCostCode?: ValueString;
  multiplierFactorNumeric?: ValueNumber;
  perUnitAmount?: CurrencyAmount;
  baseAmount?: CurrencyAmount;
}

/**
 * Document totals (calculated for the lines)
 */
export interface LegalMonetaryTotal {
  /**
   * Total with VAT
   */
  taxInclusiveAmount?: CurrencyAmount;

  /*
   * Total without VAT
   */
  taxExclusiveAmount?: CurrencyAmount;

  /**
   * Fill in anything. Not used but requested by UBL
   */
  payableAmount: CurrencyAmount;

  allowanceTotalAmount?: CurrencyAmount;

  chargeTotalAmount?: CurrencyAmount;

  prepaidAmount?: CurrencyAmount;

  payableRoundingAmount?: CurrencyAmount;
}

/**
 * Data in a line (invoice, despatch advice)
 */
export interface Line {
  id?: ValueNumber;

  lineExtensionAmount?: CurrencyAmount;
  price?: {
    priceAmount: CurrencyAmount;
  };
  taxTotal?: TaxTotalItem[];

  allowanceCharge?: AllowanceChargeItem[];
  note?: ValueString[];
  item?: {
    additionalItemIdentification?: { id: ValueString };
    standardItemIdentification?: { id: ValueString };
  };
}

export interface CurrencyAmount {
  currencyID: string;
  value: number | undefined;
}

/**
 * Describes a lines used in invoices
 */
export interface InvoiceLine extends Line {
  invoicedQuantity?: {
    value?: number;
    unitCode?: string;
  };
}

/**
 * Describes a lines used in orders
 */
export interface OrderLine extends Line {
  quantity?: {
    value?: number;
    unitCode?: string;
  };
}

/**
 * Describes a lines used in despatch advices
 */
export interface DesAdvLine extends Line {
  deliveredQuantity?: {
    value?: number;
    unitCode?: string;
  };
}

/**
 * Describes a lines used in despatch advices
 */
export interface RecAdvLine extends Line {
  deliveredQuantity?: {
    value?: number;
    unitCode?: string;
  };
}

/**
 * A selection of ubl (not really ubl) fields for Order.
 */
export interface OrderUblProperties {
  paymentMeans?: any;
  paymentTerms?: any;
  note?: ValueString[];
  // orderLine: any[];
  taxTotal?: TaxTotalItem[];
  lineCountNumeric?: ValueNumber;
  copyIndicator: ValueBoolean;
  sellerSupplierParty?: Party;
  accountingCustomerParty?: Party;
  buyerCustomerParty: Party;
  delivery?: Delivery[];
}

/**
 * A selection of ubl (not really ubl) fields for Invoice.
 */
export interface InvoiceUblProperties {
  paymentMeans?: any;
  paymentTerms?: any;
  note?: ValueString[];
  invoiceTypeCode: ValueString;
  // invoiceLine: InvoiceLine[];
  taxTotal?: TaxTotalItem[];
  lineCountNumeric?: ValueNumber;
  copyIndicator: ValueBoolean;
  sellerSupplierParty?: Party;
  buyerCustomerParty: Party;
  accountingSupplierParty?: Party;
  accountingCustomerParty?: Party;
  delivery?: Delivery[];
  legalMonetaryTotal: LegalMonetaryTotal;
  allowanceCharge?: AllowanceChargeItem[];
}
/* #endregion */

/* #region Types used in the application for documents */
/**
 * Alfresco metadata (properties)
 */
export interface AlfrescoProperties {
  properties: any;
}

/**
 * Describes the ublProperties part of the ubl backend API response.
 */
export interface UblProperties {
  ublProperties: any;
}

/**
 * Ubl data but in a different representation (for the web form).
 * Lines are in an object instead of a list, used by grids.
 */
export interface P2pPreviewData extends AlfrescoProperties, UblProperties {
  id: string | undefined;
  linesView: any;
}

/**
 * Describes the structure for p2p document ubl (not really ubl) content.
 */
export interface P2pUblContent extends UblProperties {
  lines: any[];
}

/**
 * Ubl data but in a different representation (for the web form and preview).
 */
export interface P2pData extends AlfrescoProperties, P2pUblContent {
  id?: string | undefined;
  customFields?: any;
  waste?: any;
}

/**
 * Represents the data from a json ubl file stored in Alfresco
 */
export interface AlfrescoContent extends AlfrescoProperties {
  id?: string;
  ublContent: P2pUblContent;
}

/**
 * Data structure used by the web form to store all displayed data
 * TODO: unify documentData and values inside the same object (ioan)
 */
export interface WebFormData {
  documentData: P2pData;
  selectValues: any[];
  template: Template;
}

/**
 * AlfrescoContent -> P2pData
 */
export const flatten = (
  nodeId: string | undefined,
  data: AlfrescoContent
): P2pData => {
  return {
    id: nodeId,
    properties: data.properties,
    ublProperties: data.ublContent.ublProperties,
    lines: data.ublContent.lines,
  };
};

/* #endregion */

/* #region Task Manager */

// The task react-admin resource. The template parameter 'T' is the flow variables
// as specified by uiSpecification.neededFlowVariables.
// So uiSpecification.neededFlowVariables = [ "foobar", "barfoo"]
// => T : { "foobar": ..., "barfoo": ... }
export interface Task<T> extends Record {
  definitionKey: string; // ex: validationUserTask
  startTime: number;
  dueTime?: number;
  candidateRoles: Array<string>; // ex: ["DXSUPPORT"]
  flow: {
    id: string; // ex: 7f6a9f55-bf59-11ea-9cff-1e031b5d8825
    definitionKey: string; // ex: invoice-test
    // (name, value) of the flow variables the task belongs to. The variables
    // are the one specified below in uiSpecification.neededFlowVariables
    variables: T;
  };
  uiSpecification: {
    type: string; // ex: nwaymatchingvalidation
    allowCancellation: boolean; // true means displays a cancel task button.
    neededFlowVariables: Array<string>;
  };
  dxuids: Array<string>;
  translate: Translate;
}

export interface ChildrenFuncParams {
  isLoading: boolean;
  defaultTitle: string;
  resource: string;
  record?: Record;
  classes: any;
}

export interface ClusterProps {
  basePath: string;
  children: (params: ChildrenFuncParams) => ReactNode;
  hasCreate?: boolean;
  hasEdit?: boolean;
  hasShow?: boolean;
  hasList?: boolean;
  isLoading: boolean;
  resource: string;
  dataProvider: any;
  classes: any;
  dxuids: Array<string>;
}

export interface EnhancedProps {
  id: Identifier;
  record?: Record;
  translate: Translate;
  locale: string;
}

export interface FieldProps<T extends Record = Record> {
  addLabel?: boolean;
  label?: string;
  record?: T;
  source?: string;
  resource?: string;
  basePath?: string;
  formClassName?: string;
  dataProvider: any;
  translate: Translate;
}

export interface WithPermissionsChildrenParams {
  permissions: any;
}

export interface ToolbarProps<T extends Record = Record> {
  handleSubmitWithRedirect?: (redirect: RedirectionSideEffect) => void;
  handleSubmit?: FormRenderProps['handleSubmit'];
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  redirect?: RedirectionSideEffect;
  basePath?: string;
  record?: T;
  resource?: string;
  undoable?: boolean;
  translate: Translate;
}

/* #endregion */

/* #region General types */
export type IDictionary<TValue> = {
  [key in string | number]: TValue;
};
/* #endregion */

/* #region React Admin related types */
export type ShowNotification = (message: string, type?: string) => void;
export type HideNotification = () => void;
export type Redirect = (redirectTo: string) => void;
/* #endregion */

export interface PeppolAccessPoint {
  id: number;
  schemaId: number;
  valueId: string;
  companyIdentification: string;
  companyName: string;
  issuerIdentification: string;
}
