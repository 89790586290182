import { DxTheme, NestingMenuItem, resolveDateRange } from '@dx-ui/dx-common';
import {
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FieldTitle, sanitizeInputRestProps, useTranslate } from 'react-admin';
import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/initialize'; // necessary for compatiblity as react-dates relies on react-with-styles
import 'react-dates/lib/css/_datepicker.css'; // necessary for compatiblity as react-dates relies on react-with-styles
import Draggable from 'react-draggable';
import { formatDate } from '../../utils';
import useInput from './useInput';

const defaultMenuItems = [
  {
    id: null,
    label: 'dxMessages.filter.date.allTime',
  },
  {
    id: 'today',
    label: 'dxMessages.filter.date.today',
  },
];

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    container: {
      marginTop: 8,
      minWidth: 145,
    },
    rangeDatePicker: {
      // NOTE: the order of these styles DO matter

      // Will edit everything selected including everything between a range of dates
      '& .CalendarDay__selected_span': {
        background: theme.colors.blue[200], //background
        color: theme.colors.white, //text
        border: '1px solid ' + theme.colors.blue[100], //default styles include a border
      },

      // Will edit selected date or the endpoints of a range of dates
      '& .CalendarDay__selected': {
        background: theme.colors.darkBlue,
        color: theme.colors.white,
      },

      // Will edit when hovered over. _span style also has this property
      '& .CalendarDay__selected:hover': {
        background: theme.colors.darkBlue,
        color: theme.colors.white,
      },

      // Will edit when the second date (end date) in a range of dates
      // is not yet selected. Edits the dates between your mouse and said date
      '& .CalendarDay__hovered_span:hover, & .CalendarDay__hovered_span': {
        background: theme.colors.blue[50],
        border: '1px solid ' + theme.colors.white,
      },
    },
    button: {
      margin: 4,
    },
    cancelButton: {
      margin: 4,
      backgroundColor: theme.palette.secondary.light,
      color: theme.colors.mainColor4,
      '&:hover': {
        backgroundColor: theme.colors.blue[100],
      },
    },
  }),
  { name: 'SingleDateInput' }
);

const PaperComponent = (props) => {
  const classes = useStyles();
  return (
    <Draggable
      handle='#draggable-datePicker-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} className={classes.container} />
    </Draggable>
  );
};

const SingleDateInput = (props) => {
  const {
    titleSuffix,
    label,
    source,
    readOnly,
    disabled,
    menuItems = defaultMenuItems,
    className,
    qaselector,
    options,
    ...rest
  } = props;

  const translate = useTranslate();
  const classes = useStyles();
  const {
    isRequired,
    input,
    meta: { valid, error },
  } = useInput(props);

  const [date, setDate] = useState<moment.Moment | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(true);
  const [dateSelection, setDateSelection] = useState<moment.Moment | undefined>(
    undefined
  );
  const [previousDate, setPreviousDate] = useState<moment.Moment | undefined>(
    undefined
  );

  // Selection of a predefined choice
  const handleChange = (evt) => {
    const { value } = evt.target;
    let date: moment.Moment | undefined;
    let inputDate: string | null = null;

    if (value) {
      const selectedRangeSelector = { predefinedRange: value };
      const dateRange = resolveDateRange(selectedRangeSelector);
      date = moment(dateRange.from);
      inputDate = dateRange.from;
    }

    setDate(date);

    if (!previousDate && !inputDate) {
      // Reset on already empty input
      return;
    }

    if (input.onChange) {
      input.onChange(formatDate(inputDate));
    }
  };

  /**
   * Selection of a Date with the Date Picker
   */
  const handleDateChange = (date) => {
    if (date) {
      let newDate = moment(date).startOf('day');
      setDate(newDate);
    }

    setDateSelection(undefined);
  };

  const handleFocusChange = () => {
    setFocused(true);
  };

  const preventDefault = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    const value: any = input.value;
    let date: moment.Moment | undefined = undefined;

    if (value) {
      date = moment(value);
    }

    setDate(date);

    setPreviousDate(date);
    setOpen(true);
  };
  /**
   * Displays the selected date (or date range) in the read-only input field of the select Menu.
   */
  const selectionRenderer = () => {
    if (!input.value) {
      return '';
    }

    const range = resolveDateRange(input.value);

    const date = moment(range?.from);

    return `${date.format('l')}`;
  };

  const handleConfirm = () => {
    if (input.onChange && !date?.isSame(previousDate)) {
      input.onChange(formatDate(date));
    }
    setOpen(false);
  };

  const handleCancel = () => {
    setDate(previousDate);
    setPreviousDate(undefined);
    setDateSelection(undefined);

    setOpen(false);
  };

  const showError = !valid;

  const handleChangeTextEnd = (event) => {
    const value = event.target.value;

    if (value.length <= 10) {
      setDateSelection(value);
    }
  };

  const handleMouseDown = (event) => {
    // Prevent the default calendar picker behavior
    event.preventDefault();
  };
  useEffect(() => {
    const searchDate = () => {
      if (dateSelection !== undefined) {
        const newDate = moment(dateSelection).startOf('day');
        if (newDate.isValid()) {
          setDate(newDate);
        }
      }
    };
    searchDate();
  }, [dateSelection]);

  return (
    <FormControl
      error={showError}
      className={classNames(classes.container, className)}
      {...{ qaselector }}
      {...options}
      {...sanitizeInputRestProps(rest)}
    >
      {!!label && (
        <InputLabel style={{ top: titleSuffix ? -3 : 'initial' }}>
          <>
            <FieldTitle label={label} source={source} isRequired={isRequired} />
            {titleSuffix &&
              titleSuffix.map((s) => {
                return (
                  <Chip
                    label={s}
                    size='small'
                    style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                    key={s}
                  />
                );
              })}
          </>
        </InputLabel>
      )}
      <Select
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={input.value ? input.value.predefinedRange || 'range' : ''}
        onChange={handleChange}
        onBlur={input.onBlur}
        renderValue={selectionRenderer}
        readOnly={readOnly}
        disabled={disabled}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          getContentAnchorEl: null,
          disableScrollLock: true,
        }}
      >
        <NestingMenuItem
          onClose={handleClose}
          value='range'
          noAccessToMenu={true}
          menuItems={[
            <Dialog
              key='0'
              open={open}
              PaperComponent={PaperComponent}
              aria-labelledby='draggable-datePicker-title'
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  marginTop: '10px',
                  marginRight: '5px',
                }}
              >
                <DialogTitle
                  style={{ cursor: 'move' }}
                  id='draggable-datePicker-title'
                >
                  {translate('dxMessages.filter.date.datePicker', {
                    _: 'Pick dates',
                  })}
                </DialogTitle>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'baseline',
                    gap: '10px',
                  }}
                >
                  <TextField
                    type='date'
                    onChange={handleChangeTextEnd}
                    value={
                      dateSelection === undefined
                        ? date === undefined
                          ? undefined
                          : moment(date).format('YYYY-MM-DD')
                        : dateSelection
                    }
                    InputProps={{
                      readOnly: false,
                      onClick: handleMouseDown,
                      id: 'draggable-datePicker-from',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <DialogContent>
                <div style={{ outline: 'none' }} key={`DayPicker_${source}`}>
                  <div
                    className={classNames(classes.rangeDatePicker)}
                    onClick={preventDefault}
                  >
                    <DayPickerSingleDateController
                      date={date}
                      onDateChange={handleDateChange}
                      focused={focused}
                      onFocusChange={handleFocusChange}
                      numberOfMonths={2}
                      hideKeyboardShortcutsPanel
                    />
                  </div>
                  <Grid
                    container
                    direction='row'
                    alignItems='center'
                    style={{ marginTop: '1em', height: '4em' }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={handleCancel}
                        classes={{ root: classes.cancelButton }}
                        className='cancel-date-input'
                      >
                        {translate('ra.action.cancel')}
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={handleConfirm}
                        classes={{ root: classes.button }}
                        className='confirm-date-input'
                        disabled={
                          date === undefined || !moment(dateSelection).isValid()
                        }
                      >
                        {translate('ra.action.confirm')}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </DialogContent>
            </Dialog>,
          ]}
        >
          {translate('dxMessages.filter.date.datePicker', {
            _: 'Pick dates',
          })}
        </NestingMenuItem>
        {menuItems.map(({ id, label }) => (
          <MenuItem key={id} value={id}>
            {translate(label, { _: label })}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText>{translate(error)}</FormHelperText>}
    </FormControl>
  );
};

SingleDateInput.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  source: PropTypes.string,
};

SingleDateInput.defaultProps = {
  options: {},
};

export default SingleDateInput;
