import { cloneDeep, get, isArray, mergeWith, omit, set } from 'lodash';
import formValues from '../shared/webForms/form-values';
import { setSourceField } from '../shared/webForms/utils';
import {
  DocumentQueryResult,
  IAlfrescoDocumentService,
} from './AlfrescoDocumentService';
import { CatalogLine } from './CatalogService';
import { CountryService } from './CountryService';

/**
 * Default currency used when creating new documents
 */
export const DefaultCurrencyID = 'RON';

export abstract class BaseDocumentService {
  protected documentService: IAlfrescoDocumentService;

  /**
   *
   */
  constructor(documentService: IAlfrescoDocumentService) {
    this.documentService = documentService;
  }

  /**
   * Sends saved data in Alfresco to the process workflow connector
   * @param nodeId Alfresco node Id of the document
   */
  public async sendData(nodeId: string): Promise<DocumentQueryResult> {
    return await this.documentService.sendDocument(nodeId);
  }

  /**
   * Merge two data objects
   */
  protected mergeFormData(data: any, withData: any, appendLists?: boolean) {
    const customizer = (objValue, srcValue) => {
      if (isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    };
    return appendLists
      ? mergeWith(data, withData, customizer)
      : mergeWith(data, withData);
  }

  /**
   * Sets of data that overwrite any loaded data from other sources (can be used to fill in gaps, or add list values etc)
   */
  protected getHardcodedValues(templateType: string) {
    const values: any =
      formValues?.values?.filter(
        (valueArray: any) => valueArray.templateType === templateType
      )[0] || {};
    const lists = values.selectValues;
    const data = omit(values, 'selectValues');

    return { data, lists };
  }

  /**
   * Builds data used to populate comboboxes
   * @param items datasource
   * @param valueField datasource value field
   * @param nameField datasource name field
   * @param noDefault Don't set any default if selectedItemValue is undefined (default is false)
   * @param selectedItemValue selected value in the data source.
   * It will be inserted together with selectedItemName if it does not exist
   * @param selectedItemName (optional) selected item name (used if given selected item does not exist in the list)
   */
  protected createSelectData(
    items: any[],
    valueField: string,
    nameField: string,
    noDefault: boolean,
    selectedItemValue: string | undefined,
    selectedItemName?: string
  ) {
    const result: {
      choices: any[];
      defaultValue: undefined | string;
    } = {
      choices: [],
      defaultValue: undefined,
    };

    if (!Array.isArray(items) || !items.length) {
      return result;
    }

    result.choices = items;
    result.defaultValue = selectedItemValue;

    if (selectedItemValue === undefined) {
      result.defaultValue = noDefault
        ? undefined
        : result.choices[0][valueField];
    } else {
      const valueExists = items.some(
        (v) => v[valueField] === selectedItemValue
      );
      if (!valueExists) {
        const newItem = {};
        newItem[valueField] = selectedItemValue;
        newItem[nameField] = selectedItemName ?? selectedItemValue;

        result.choices = [newItem, ...result.choices];
      }
      result.defaultValue = selectedItemValue;
    }

    return result;
  }

  /**
   * Gets the list of available countries for 'dropdown' lists
   */
  protected getCountriesSelectData(
    defaultCountryCode: string | undefined,
    locale: string
  ) {
    const countryService = new CountryService(locale);
    const countries = countryService.getCountries();
    const countryItems = countries.map((country) => {
      return {
        id: country.code,
        name: country.name,
      };
    });

    return this.createSelectData(
      countryItems,
      'id',
      'name',
      true,
      defaultCountryCode
    );
  }

  /**
   * Ensure collection items are in a specific order. Empty slots are filled with empty items.
   * Example: discount / green tax. Ensure both items are present in their parent list.
   * This allows addressing them easily from the template. Items[0]-> discount, Items[1] -> green tax.
   */
  protected setupCollectionItems(
    data: any,
    collectionPath: string,
    keyPath: string,
    orderedKeyValues: string[],
    emptyElement: any
  ) {
    const collection = get(data, collectionPath);

    if (!collection) {
      return data;
    }

    if (!Array.isArray(collection)) {
      return data;
    }

    const collectionKeys: string[] = collection.map((item) =>
      get(item, keyPath)
    );

    const hashset: any = {};
    orderedKeyValues.forEach((key) => {
      hashset[key] = undefined;
    });
    collectionKeys.forEach((key) => {
      if (key) {
        hashset[key] = undefined;
      }
    });

    collection.forEach((item) => {
      const key = get(item, keyPath);
      if (key) {
        hashset[key] = item;
      }
    });

    Object.keys(hashset).forEach((key) => {
      if (hashset[key] === undefined) {
        const empty = cloneDeep(emptyElement);
        set(empty, keyPath, key);
        hashset[key] = empty;
      }
    });

    set(data, collectionPath, Object.values(hashset));
  }

  /**
   * Cleanup the specified collection by removing the empty elements.
   * Example: discount / green tax -> if one of them hasn't been filled then remove them.
   */
  protected cleanCollectionItems(
    data: any,
    collectionPath: string,
    checkPath: string
  ) {
    const collection = get(data, collectionPath);

    if (!collection) {
      return data;
    }

    if (!Array.isArray(collection)) {
      return data;
    }

    let itemsToRemove =
      collection.filter((item) => get(item, checkPath) === undefined) || [];

    itemsToRemove.forEach((item) => {
      const idx = collection.indexOf(item);
      if (idx > -1) {
        collection.splice(idx, 1);
      }
    });
  }

  /**
   * Returns the choices from catalog which can be applied to code client, code supplier, code standard and description
   * @param catalogLines
   * @param codeClient
   * @param codeSupplier
   * @param codeStandard
   * @param description
   * @returns
   */
  protected async fillLinesSuggestionsInfo(
    catalogLines: CatalogLine[],
    codeClient: string,
    codeSupplier: string,
    codeStandard: string,
    description: string
  ): Promise<{ lists: any }> {
    const lists = {};

    const buyersItemIdentificationItems = catalogLines
      ?.filter((c) => c.buyersItemIdentification !== null)
      .map((c) => {
        return {
          id: `${c.lineNumber}__${c.buyersItemIdentification}`,
          name: c.buyersItemIdentification,
          description: c.description,
        };
      });

    if (buyersItemIdentificationItems.length !== 0) {
      const buyersItemIdentificationSelectedItems = this.createSelectData(
        buyersItemIdentificationItems,
        'id',
        'name',
        true,
        undefined
      );
      setSourceField(
        lists,
        codeClient,
        buyersItemIdentificationSelectedItems.choices
      );
    }

    const sellersItemIdentificationItems = catalogLines
      ?.filter((c) => c.sellersItemIdentification !== null)
      .map((c) => {
        return {
          id: `${c.lineNumber}__${c.sellersItemIdentification}`,
          name: c.sellersItemIdentification,
          description: c.description,
        };
      });

    if (sellersItemIdentificationItems.length !== 0) {
      const sellersItemIdentificationSelectedItems = this.createSelectData(
        sellersItemIdentificationItems,
        'id',
        'name',
        true,
        undefined
      );
      setSourceField(
        lists,
        codeSupplier,
        sellersItemIdentificationSelectedItems.choices
      );
    }

    const standardItemIdentificationItems = catalogLines
      ?.filter((c) => c.standardItemIdentification !== null)
      .map((c) => {
        return {
          id: `${c.lineNumber}__${c.standardItemIdentification}`,
          name: c.standardItemIdentification,
          description: c.description,
        };
      });

    if (standardItemIdentificationItems.length !== 0) {
      const standardItemIdentificationSelectedItems = this.createSelectData(
        standardItemIdentificationItems,
        'id',
        'name',
        true,
        undefined
      );
      setSourceField(
        lists,
        codeStandard,
        standardItemIdentificationSelectedItems.choices
      );
    }

    const productDescriptionItems = catalogLines
      ?.filter((c) => c.description !== null)
      .map((c) => {
        return {
          id: `${c.lineNumber}__${c.description}`,
          name: c.description,
          description: c.description,
        };
      });

    if (productDescriptionItems.length !== 0) {
      const productDescriptionSelectedItems = this.createSelectData(
        productDescriptionItems,
        'id',
        'name',
        true,
        undefined
      );
      setSourceField(
        lists,
        description,
        productDescriptionSelectedItems.choices
      );
    }

    return { lists };
  }
}
