import { DxTheme, EmptyValue, sendGAEvent } from '@dx-ui/dx-common';
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ContentSave from '@material-ui/icons/Save';
import {
  CancelEditButton,
  EditRowButton,
  EditableDatagrid,
  SaveRowButton,
} from '@react-admin/ra-editable-datagrid';
import classNames from 'classnames';
import { cloneDeep, get, set } from 'lodash';
import { ListContextProvider } from 'ra-core';
import { ArrayInput, TextField } from 'ra-ui-materialui';
import { ReactElement, useEffect, useState } from 'react';
import {
  BooleanField,
  FormDataConsumer,
  FunctionField,
  Toolbar,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { Form, useForm } from 'react-final-form';
import { FieldTypes } from '..';
import { GA_EVENTS } from '../../../GAUtils';
import { P2pUtils } from '../../../modules/common/P2pUtils';
import { InvoiceService } from '../../../services';
import { DataHelpers } from '../../../services/DataHelpers';
import { FormDataHelpers } from '../../../services/FormDataHelpers';
import DeleteLineIconButton from '../DeleteLineIconButton';
import { useCommonFieldStyles } from './CommonFieldStyles';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';
import { FormField } from './FormField';
import CustomSimpleFormIterator from './OrderSimpleFormIterator';
import TaxSummaryPanel from './TaxSummaryPanel';
import TaxTotals from './TaxTotals';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    linesMaxWidth: {
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    toolbar: {
      backgroundColor: theme.app.tableBkgColor,
      paddingLeft: 0,
    },
    addButton: {
      width: 'unset',
    },
  }),
  { name: 'InvoiceLinesTable' }
);

const InvalidField = (props) => {
  return <span>???</span>;
  // return <JSONPretty id='json-pretty' data={props}></JSONPretty>;
};

// const DebugField = (props) => {
//   return (
//     <Grid item key={props.field.name} xs={12}>
//       {props.field.name} - {props.field.type}
//     </Grid>
//   );
// };

export const ReadField = (props) => {
  const { field } = props;
  const sanitizeProps: any = ({
    loading,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;
  switch (field.type) {
    case FieldTypes.TextInput:
    case FieldTypes.SelectInput:
    case FieldTypes.AutocompleteTextInput:
    case FieldTypes.DateInput:
    case FieldTypes.SingleDateInput:
    case FieldTypes.ReferenceInput:
    case FieldTypes.GLNInput:
    case FieldTypes.NumberInput:
    case FieldTypes.NumberDisplay:
    case FieldTypes.TimeInput:
    case FieldTypes.BooleanInput:
      return (
        <FieldWrapper {...sanitizeProps(props)}>
          {(fieldProps) => <TextField {...fieldProps} />}
        </FieldWrapper>
      );
    // return <DebugField {...props} />;

    // return <span key={props.field.name}>Spacer: {props.field.name}</span>;
    default:
      return <InvalidField />;
  }
};

const AddButton = ({ onClick }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Button size='small' {...{ onClick }} className={classes.addButton}>
      <AddIcon className={classes.leftIcon} />
      {translate('ra.action.add')}
    </Button>
  );
};

interface InvoiceLinesTableProps extends FieldComponentProps {
  renderItems: any;
}
/**
 * Renders an Order lines table for invoices.
 */
const InvoiceLinesTable = (props: InvoiceLinesTableProps) => {
  const {
    field,
    readOnly: readOnlyForm,
    record,
    basePath,
    renderItems,
  } = props;

  const [openAddNewLine, setOpenAddNewLine] = useState(false);
  const documentForm = useForm();

  const linesIds = Object.keys(record.lines);
  const linesView = P2pUtils.convertLines2ViewObject(record.lines);
  const totalLines = record.lines.length;
  const translate = useTranslate();
  const classes = useStyles(props);
  const commonFieldClasses = useCommonFieldStyles(props);
  // TODO: restrain change verification only on lines, currency and taxTotal
  useEffect(() => {
    FormDataHelpers.recalculateTaxesAndPrices(record);
  }, [record]);

  const options = field.options;
  const readOnly = !!readOnlyForm || !!options?.readOnly;
  const currencyID = DataHelpers.getCurrencyID(record);
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  useEffect(() => {
    sendGAEvent(
      GA_EVENTS.categories.FORM.name,
      GA_EVENTS.categories.FORM.actions.TOTAL_LINES,
      account?.company?.cmsRootDir,
      `${record.lines.length}`
    );
  }, [account, record.lines.length]);

  const sanitizeArrayInputProps = ({
    selectValues,
    template,
    renderItems,
    classes,
    ...rest
  }: any) => rest;

  const sanitizeItemProps = ({
    field,
    renderItems,
    classes,
    mutationMode,
    hasBulkActions,
    onToggleItem,
    save,
    quitEditMode,
    saving,
    selectable,
    ...rest
  }: any) => rest;

  const disableRemove = (record?.lines?.length ?? 0) <= 1;

  const SaveButton = ({ onClick }) => {
    const label = translate('ra.action.save', {
      _: 'ra.action.save',
    });

    return (
      <Tooltip title={label}>
        <IconButton
          aria-label={label}
          onClick={onClick}
          size='small'
          color='primary'
        >
          <ContentSave />
        </IconButton>
      </Tooltip>
    );
  };

  const renderDatagridItemsForWrite = (items, otherProps) =>
    items?.map((item, idx) => {
      return (
        <div style={{ marginRight: '1em' }} key={`${item.name}_${idx}`}>
          <FormField
            {...otherProps}
            key={`${item.name}_${idx}`}
            source={item.source} // need to set it here
            field={item}
          />
        </div>
      );
    });

  const sanitizeRestProps = ({ variant, selectValues, ...rest }: any) => rest;

  const renderDatagridItemsForRead = (items, otherProps) =>
    items?.map((item, colIdx) => {
      const label = item.businessTerms ? (
        <>
          {translate(item.label, { _: item.label })}
          {item.businessTerms.map((s) => {
            return (
              <Chip
                label={s}
                size='small'
                style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                key={s}
              />
            );
          })}
        </>
      ) : (
        translate(item.label, { _: item.label })
      );
      if (item.type === FieldTypes.BooleanInput) {
        return (
          <BooleanField
            {...sanitizeRestProps(otherProps)}
            key={`${item.name}_${colIdx}`}
            source={item.source} // need to set it here
            label={label}
          />
        );
      } else if (item.type === FieldTypes.SelectTaxArrayInput) {
        return (
          <FunctionField
            {...sanitizeRestProps(otherProps)}
            key={`${item.name}_${colIdx}`}
            source={item.source} // need to set it here
            label={label}
            render={(record) => {
              const selected = get(record, `${item.source}`);
              return (
                <table>
                  <tbody>
                    <tr>
                      {selected !== undefined &&
                        selected.map((tax: any) => {
                          return (
                            <th style={{ width: '9em' }}>
                              {translate(`dxMessages.invoices.taxes.${tax._}`)}
                            </th>
                          );
                        })}
                    </tr>
                    <tr>
                      {selected !== undefined &&
                        selected.map((tax: any) => {
                          return <td align='center'>{tax.value}</td>;
                        })}
                    </tr>
                  </tbody>
                </table>
              );
            }}
          />
        );
      } else if (
        item.type === FieldTypes.SearchSelectInput ||
        item.type === FieldTypes.SelectInput
      ) {
        return (
          <FunctionField
            {...sanitizeRestProps(otherProps)}
            key={`${item.name}_${colIdx}`}
            source={item.source} // need to set it here
            label={label}
            render={(record) => {
              const value = get(record, item.source);
              if (value === undefined) {
                return <Typography>{EmptyValue}</Typography>;
              }
              const selected = item.selectValues?.find(
                (s) => s[item.options.optionValue] === value
              );
              if (selected !== undefined && selected?.tooltip) {
                return (
                  <Tooltip title={translate(selected.tooltip)}>
                    <Typography>
                      {translate(selected[item.options.optionText], {
                        _: selected[item.options.optionText] || value,
                      })}
                    </Typography>
                  </Tooltip>
                );
              } else {
                if (selected !== undefined) {
                  return (
                    <Typography>
                      {translate(selected[item.options.optionText], {
                        _: selected[item.options.optionText] || value,
                      })}
                    </Typography>
                  );
                } else {
                  return <Typography>{value}</Typography>;
                }
              }
            }}
          />
        );
      } else {
        return (
          <TextField
            {...sanitizeRestProps(otherProps)}
            key={`${item.name}_${colIdx}`}
            source={item.source} // need to set it here
            label={label}
          />
        );
      }
    });

  /**
   * EditableDatagrid modify line method.
   * @param props
   */
  const saveLine = (props) => {
    const documentData = documentForm.getState().values;
    const lineIndex: number = props.nr - 1;
    const newLineData = { ...props };
    set(documentData, `lines[${lineIndex}]`, newLineData);
    const data: any = cloneDeep(documentData);

    FormDataHelpers.recalculateTaxesAndPrices(data);

    // In case of MonoVat, reset monoVat if TaxSummary has more than one lines
    FormDataHelpers.manageMonoVat(data);

    // In case of Mono Tax Category, reset mono TaxCategory if TaxSummary has more than one lines
    FormDataHelpers.manageMonoTaxCategory(data);

    documentForm.batch(() => {
      const properties = Object.keys(data).filter((t) => t !== 'id');
      properties.forEach((propName) => {
        documentForm.change(propName, data[propName]);
      });
    });
  };

  /**
   * EditableDatagrid create line method.
   * @param newLine user defined line
   */
  const createLine = (newLine) => {
    const documentData = documentForm.getState().values;
    const lineIndex: number = newLine.nr - 1;
    set(documentData, `lines[${lineIndex}]`, newLine);
    const data: any = cloneDeep(documentData);

    FormDataHelpers.recalculateTaxesAndPrices(data);

    // In case of MonoVat, reset monoVat if TaxSummary has more than one lines
    FormDataHelpers.manageMonoVat(data);

    // In case of Mono Tax Category, reset mono TaxCategory if TaxSummary has more than one lines
    FormDataHelpers.manageMonoTaxCategory(data);

    documentForm.batch(() => {
      const properties = Object.keys(data).filter((t) => t !== 'id');
      properties.forEach((propName) => {
        documentForm.change(propName, data[propName]);
      });
    });
    sendGAEvent(
      GA_EVENTS.categories.FORM.name,
      GA_EVENTS.categories.FORM.actions.ADD_LINE,
      account?.company?.cmsRootDir
    );
    setOpenAddNewLine(false);
  };

  const CustomAction = (props) => (
    <>
      <EditRowButton />
      {!disableRemove && (
        <DeleteLineIconButton mutationMode='undoable' {...props} />
      )}
    </>
  );

  const EditLineForm = (props: any) => {
    const {
      record,
      id,
      className,
      quitEditMode,
      selectable,
      basePath,
      resource,
      saving,
      selected,
      undoable,
      tableData,
      ...rest
    } = props;
    const form = useForm();
    const cancel = () => {
      let cancelledLineForm = form.getState().values;
      form.reset(cancelledLineForm);
      quitEditMode();
    };

    return (
      <Dialog
        open={true}
        onClose={() => {}}
        aria-labelledby='edit-line-dialog-title'
        aria-describedby='edit-line-dialog-description'
        maxWidth='xl'
        fullWidth={true}
      >
        <DialogContent>
          <Form onSubmit={saveLine} initialValues={record} {...rest}>
            {({ handleSubmit, invalid, dirty, form }): ReactElement => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TextField
                  source='nr'
                  record={record}
                  style={{ marginRight: '2em' }}
                />
                {renderDatagridItemsForWrite(field.items, {
                  ...{ ...sanitizeItemProps(rest), basePath },
                })}
                <SaveRowButton
                  dirty={dirty}
                  handleSubmit={handleSubmit}
                  invalid={invalid}
                  quitEditMode={quitEditMode}
                  saving={saving}
                  undoable={undoable}
                />
                <CancelEditButton cancel={cancel} />
              </div>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    );
  };

  const CreateLineForm = (props: any) => {
    const { lineNumber, className, basePath, resource, ...rest } = props;
    return (
      <Dialog
        open={props.open}
        aria-labelledby='create-line-dialog-title'
        aria-describedby='create-line-dialog-description'
        maxWidth='xl'
        fullWidth={true}
      >
        <DialogContent>
          <Form
            onSubmit={() => {}}
            initialValues={{
              nr: lineNumber,
              ...FormDataHelpers.initNewInvoiceLine(currencyID),
            }}
            {...rest}
          >
            {({ invalid, dirty, form }): ReactElement => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography style={{ marginRight: '2em' }}>
                  {lineNumber}
                </Typography>
                {renderDatagridItemsForWrite(field.items, {
                  ...{ ...sanitizeItemProps(rest), basePath },
                })}
                <SaveButton
                  onClick={(event) => createLine(form.getState().values)}
                />
                <CancelEditButton cancel={() => setOpenAddNewLine(false)} />
              </div>
            )}
          </Form>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Grid container item direction='column' spacing={2}>
      {
        <Grid item>
          <Typography className={commonFieldClasses.header} variant='h6'>
            {options?.title &&
              field.title &&
              translate(field.title, { _: field.title })}
            {field.businessTerms?.map((s) => {
              return (
                <Chip
                  label={s}
                  size='small'
                  style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                  key={s}
                />
              );
            })}
          </Typography>
        </Grid>
      }
      <Grid item>
        <Paper square className={commonFieldClasses.paper}>
          <Grid container direction='column'>
            <Grid
              container
              item
              spacing={2}
              wrap='nowrap'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {field?.topItems &&
                renderItems(field.topItems, sanitizeItemProps(props))}
            </Grid>
            {field.items && (
              <Grid item className={classNames(classes.linesMaxWidth)}>
                {totalLines < InvoiceService.LIMIT_FOR_ARRAYINPUT && (
                  <ArrayInput {...sanitizeArrayInputProps(props)} label={''}>
                    <CustomSimpleFormIterator
                      disableAdd={readOnly || !(options?.addable ?? true)}
                      disableRemove={
                        disableRemove ||
                        readOnly ||
                        !(options?.removable ?? true)
                      }
                      createNewLine={() =>
                        FormDataHelpers.initNewInvoiceLine(currencyID)
                      }
                    >
                      {renderItems(field.items, {
                        ...{ ...sanitizeItemProps(props), basePath },
                      })}
                    </CustomSimpleFormIterator>
                  </ArrayInput>
                )}
                {totalLines >= InvoiceService.LIMIT_FOR_ARRAYINPUT && (
                  <ListContextProvider
                    value={{
                      ids: linesIds,
                      data: linesView,
                      currentSort: { field: 'nr', order: 'ASC' },
                      resource: props.resource,
                      selectedIds: [],
                    }}
                  >
                    <EditableDatagrid
                      editForm={
                        <EditLineForm {...props} documentForm={documentForm} />
                      }
                      actions={
                        options?.removable ?? true ? <CustomAction /> : false
                      }
                    >
                      <TextField source='nr' />
                      {renderDatagridItemsForRead(field.items, {
                        ...{
                          ...sanitizeItemProps(props),
                          basePath,
                        },
                      })}
                    </EditableDatagrid>
                    <CreateLineForm
                      {...props}
                      documentForm={documentForm}
                      open={openAddNewLine}
                      lineNumber={linesIds.length + 1}
                    />
                    <Toolbar className={classes.toolbar}>
                      {(options?.addable ?? true) && (
                        <AddButton
                          onClick={() => setOpenAddNewLine(true)}
                          {...props}
                        />
                      )}
                    </Toolbar>
                  </ListContextProvider>
                )}
              </Grid>
            )}
            <FormDataConsumer>
              {({ formData }) => (
                <>
                  <Grid item container direction='column' alignItems='flex-end'>
                    <TaxTotals data={formData} />
                  </Grid>
                  <Grid item container direction='column' alignItems='flex-end'>
                    <TaxSummaryPanel data={formData} />
                  </Grid>
                </>
              )}
            </FormDataConsumer>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InvoiceLinesTable;
