import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FilePondFile, registerPlugin } from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import React, { useRef } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { FilePond } from 'react-filepond';
import { DxTheme } from '../../..';
import { AttachmentFile } from './types';

registerPlugin(FilePondPluginFileValidateSize);

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    filepond: {
      '& .filepond--file-action-button': {
        display: 'none',
      },
      '& .filepond--file-status-sub': {
        display: 'none',
      },
    },
  }),
  { name: 'UploadFilesField' }
);

export interface UploadFilesFieldProps {
  postUrl: string;
  /**
   * set to 10 for '10 MB'
   */
  maxFileSize?: number;
  maxFiles?: number;
  onAddNew?: (attachment: AttachmentFile) => void;
}

export const UploadFilesField = ({
  postUrl,
  maxFileSize = 10,
  maxFiles,
  onAddNew,
}: UploadFilesFieldProps) => {
  const ref = useRef(null);
  const classes = useStyles();

  const translate = useTranslate();
  const notify = useNotify();

  const labels = {
    labelIdle: translate('dxMessages.uploadFiles.uploadBox'),
    labelFileProcessing: translate('dxMessages.uploadFiles.preparing'),
    labelFileProcessingComplete: translate('dxMessages.uploadFiles.ready'),
    labelTapToUndo: translate('dxMessages.uploadFiles.clickToRemove'),
    labelTapToCancel: translate('dxMessages.uploadFiles.clickToCancel'),
    labelFileProcessingError: (error) => {
      if (error?.code === 429) {
        // HTTP 429 - Too Many Requests
        // Max number of attachment files reached
        return translate('dxMessages.uploadFiles.maxFilesReached');
      }

      return translate('dxMessages.uploadFiles.uploadError');
    },
    labelFileLoadError: translate('dxMessages.uploadFiles.loadError'),
    labelTapToRetry: translate('dxMessages.uploadFiles.tapToRetry'),
    labelMaxFileSizeExceeded: translate('dxMessages.uploadFiles.fileTooLarge'),
    labelMaxFileSize: translate('dxMessages.uploadFiles.maxFileSizeIs'),
  };

  return (
    <FilePond
      className={classNames(classes.filepond)}
      ref={ref}
      allowMultiple={true}
      {...labels}
      allowFileSizeValidation={true}
      allowRevert={false}
      allowReplace={false}
      allowReorder={false}
      maxFileSize={`${maxFileSize}MB`}
      maxFiles={maxFiles}
      maxParallelUploads={1}
      server={{
        process: {
          url: postUrl,
          timeout: 600000,
          onload: (response) => {
            if (typeof onAddNew === 'function') {
              try {
                const attachment = JSON.parse(response) as AttachmentFile;
                onAddNew(attachment);
              } catch {}
            }

            return response.key;
          },
        },
        revert: null,
      }}
      onwarning={(error: any, file?: FilePondFile, status?: any) => {
        notify('dxMessages.uploadFiles.maxFilesReached', 'warning');
      }}
    />
  );
};
