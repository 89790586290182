import { Alert, AlertTitle } from '@material-ui/lab';
import React, { FC } from 'react';
import {
  FieldProps,
  regex,
  Translate,
  UserIdentity,
  useTranslate,
} from 'react-admin';

/**
 * The regular expression to validate a password compliance
 * with the Doc Process policy.
 *
 * Explanation:
 * (?=.*[a-z]) - one or more lower case letters
 * (?=.*[A-Z]) - one or more upper case letters
 * (?=.*[0-9]) - one or more numbers
 * (?=.*[!&@#:\?\+\*\-\/\$\(\)]) - one of the special characters
 * (?=.{8,}) - must have at least 8 characters
 */
const REGEXP =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!&@#:\?\+\*\-\/\$\(\)])(?=.{8,})/g;

/**
 * The validate function for a <ReactAdmin/> input containing a password.
 */
const validatePassword = regex(
  REGEXP,
  'resources.identityProviders.common.errors.notCompliant'
);

/**
 * Tells whether the password complies with the Doc Process policy.
 */
const isPasswordCompliant = (password: string): boolean => {
  if (!password) return false;
  return !!password.match(REGEXP);
};

/**
 * The description of the Doc Process password policy.
 */
const DxPasswordPolicyAlert: FC<FieldProps<UserIdentity>> = (props) => {
  const translate = useTranslate();
  return (
    <Alert severity='warning' style={{ margin: '1em' }}>
      {i18nPasswordPolicy(translate)}
    </Alert>
  );
};

const i18nPasswordPolicy = (translate: Translate) => {
  return (
    <>
      <AlertTitle>
        {translate('dxMessages.auth.password_criteria_alert_title', {
          _: 'Doc Process password policy',
        })}
      </AlertTitle>
      {translate('dxMessages.auth.password_criteria_text', {
        _: 'your password must:',
      })}
      <ul>
        <li>
          {translate('dxMessages.auth.password_criteria_length', {
            _: 'contain at least 8 characters',
          })}
        </li>
        <li>
          {translate('dxMessages.auth.password_criteria_lowercase', {
            _: 'one or more lower case letters',
          })}
        </li>
        <li>
          {translate('dxMessages.auth.password_criteria_uppercase', {
            _: 'one or more upper case letters',
          })}
        </li>
        <li>
          {translate('dxMessages.auth.password_criteria_digit', {
            _: 'one or more numbers',
          })}
        </li>
        <li>
          {translate('dxMessages.auth.password_criteria_special', {
            _: 'one of the special characters: !&amp;@#:?+*-/$()',
          })}
        </li>
      </ul>
    </>
  );
};

export { DxPasswordPolicyAlert, validatePassword, isPasswordCompliant };
