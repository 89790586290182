/**
 * The General Message widget, always at the top of the Landing page,
 * not moveable, not removeable.
 *
 * Display the a message of the NotificationCenter of type GENERAL
 */
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { usePreferences } from '@react-admin/ra-preferences';
import { FC, useEffect, useRef, useState } from 'react';
import {
  fetchUtils,
  useDataProvider,
  useGetIdentity,
  useLocale,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useSelector } from 'react-redux';
import {
  IMessage,
  IMessageContent,
  MessageState,
  MessageType,
} from '../../../components/notifications/types';
import { Constants } from '../../../constants';
import { DxTheme } from '../../../types';
import { GA_EVENTS, sendGAEvent, useInterval } from '../../../utils';
import DocProcessLogo from '../../DocProcessLogo';
import { MARGIN } from '../LandingPage';

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    card: (props: { maxHeight: number; messageType: MessageType }) => {
      return {
        maxHeight: props.maxHeight,
        minWidth: 400,
        backgroundColor:
          props.messageType === MessageType.MARKETING
            ? theme.colors.grey[100]
            : theme.colors.lightPurple,
        color:
          props.messageType === MessageType.MARKETING
            ? theme.colors.lightPurple
            : theme.colors.white,
        borderRadius: 20,
        margin: MARGIN,
      };
    },
    content: (props: { messageType: MessageType }) => {
      return {
        display: 'flex',
        flexWrap: 'wrap',
        '& .tui-editor-contents > *, & .tui-editor-contents > p': {
          color:
            props.messageType === MessageType.MARKETING
              ? theme.colors.lightPurple
              : theme.colors.white,
          margin: 0,
          padding: 0,
        },
        '& div': {
          margin: 0,
          padding: 0,
        },
      };
    },
    lastLogin: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    publicationInstant: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
  }),
  { name: 'MktMessageWidget' }
);

const MessageWidget: FC<IMessageContent> = ({
  messageIssuer,
  messageIssuerTitle,
  messageType,
  id,
}) => {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const refresh = useRefresh();
  const ref: any = useRef();
  const cardRef: any = useRef();
  const [rowHeight, setRowHeight] = useState(24);
  const [cardWidth, setCardWidth] = useState(0);
  const [cardHeight, setCardHeight] = useState(0);
  const [data, setData] = useState<any>();
  const [hideMarketingMessageArray, setHideMarketingMessageArray] =
    usePreferences<any>(`landingPage.messages.hideMessage.marketing`, null);
  const [hideGeneralMessageArray, setHideGeneralMessageArray] =
    usePreferences<any>('landingPage.messages.hideMessage.general', null);
  const viewVersion = useSelector((state: any) => state.admin.ui.viewVersion);
  const { identity } = useGetIdentity();
  // @ts-ignore
  const account: Account = identity;

  const maxHeight = 300;
  const classes = useStyles({ maxHeight: maxHeight, messageType: messageType });

  /**
   * Creates on the fly the Anchor element to open the right URL with Zendesk token if needed
   * @param currentMessage
   */
  const onClickMoreInfo = async (currentMessage: IMessage | undefined) => {
    sendGAEvent(
      GA_EVENTS.categories.DASHBOARD.name,
      GA_EVENTS.categories.DASHBOARD.actions.MESSAGE_MORE_DETAILS,
      account?.company?.cmsRootDir,
      currentMessage?.moreInfoLink
    );
    if (!currentMessage?.moreInfoLinkToKnowledgeBase) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('type', 'hidden');
      a.href = `${currentMessage?.moreInfoLink}`;
      a.target = '_blank';
      a.rel = 'noopener noreferrer';
      a.click();
      a.remove();
    } else {
      try {
        const response = await dataProvider[Constants.RESOURCE_ZENDESK](
          Constants.RESOURCE_ZENDESK,
          {}
        );

        if (response && response.data) {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('type', 'hidden');
          a.href = `https://docprocess.zendesk.com/access/jwt?jwt=${response.data}&return_to=${currentMessage.moreInfoLink}`;
          a.target = '_blank';
          a.rel = 'noopener noreferrer';
          a.click();
          a.remove();
        }
      } catch {}
    }
  };

  const locale = useLocale();

  const getMessages = async () => {
    // Can't use useQuery and dataProvider.getUtils :
    // * UseQuery, because it is not possible
    //    to call a hook into useInterval.
    // * dataProvider.getUtils, because we need to not interfere with redux-store in order to avoid
    //    the rendering of the current page with incorrect data.
    if (id) {
      fetchUtils
        .fetchJson(
          `${window.location.origin}/api/${Constants.RESOURCE_MESSAGES}/${id}`,
          {
            credentials: 'include',
          }
        )
        .then(({ json: data }) => {
          setData(data as any);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('error : %o', error);
          setData({});
        });
    } else {
      if (messageType === MessageType.GENERAL) {
        fetchUtils
          .fetchJson(
            `${window.location.origin}/api/${Constants.RESOURCE_MESSAGES}/last?language=${locale}&type=${messageType}`,
            {
              credentials: 'include',
            }
          )
          .then(({ json: data }) => {
            if (hideGeneralMessageArray !== null) {
              hideGeneralMessageArray.forEach((hideGeneral) => {
                if (hideGeneral.id === data.id) {
                  setData({});
                } else {
                  setData(data as any);
                }
              });
            } else {
              setData(data as any);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('error : %o', error);
            setData({});
          });
      } else {
        // Marketing
        fetchUtils
          .fetchJson(
            `${window.location.origin}/api/${Constants.RESOURCE_MESSAGES}?_end=10&_order=DESC&_sort=publicationTs&_start=0&language=${locale}&state=${MessageState.PUBLISHED}&types=${messageType}`,
            {
              credentials: 'include',
            }
          )
          .then(({ json: data }) => {
            if (hideMarketingMessageArray !== null) {
              const filteredMessages = data.filter(
                (objData) =>
                  !hideMarketingMessageArray.some(
                    (objHide) => objData.id === objHide.id
                  )
              );
              if (filteredMessages.length > 1) {
                setData(
                  filteredMessages[
                    Math.floor(Math.random() * filteredMessages?.length)
                  ] as any
                );
              } else {
                setData(filteredMessages[0]);
              }
            } else {
              setData(data[Math.floor(Math.random() * data?.length)] as any);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log('error : %o', error);
            setData({});
          });
      }
    }
  };

  useEffect(() => {
    getMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewVersion, locale]);

  // Get the last published messages from the message center.
  useInterval(() => {
    getMessages();
  }, 1000 * 60 * 5);

  const textMessage = (
    <Typography
      component='pre'
      ref={ref}
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
      }}
    >
      {data?.content}
    </Typography>
  );

  // get the height of the text at a some point
  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setRowHeight(ref.current.clientHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [textMessage]);

  // get the width of the card
  useEffect(() => {
    const handleResize = () => {
      if (cardRef.current) {
        setCardWidth(cardRef.current.clientWidth);
        setCardHeight(cardRef.current.clientHeight);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [textMessage]);

  if (!data || (data && Object.keys(data).length === 0)) {
    return null;
  }

  const pixelsToRows = (pixels) => {
    return pixels / 24;
  };
  const rowsNumber = pixelsToRows(rowHeight);

  const onClickHideMessage = () => {
    if (data.type === MessageType.MARKETING) {
      if (hideMarketingMessageArray !== null) {
        const alreadyHide = hideMarketingMessageArray.some(
          (obj) => obj.id === data.id
        );
        if (!alreadyHide) {
          const newArray: any = [];
          hideMarketingMessageArray.forEach((message) => {
            if (!newArray.includes(message)) {
              newArray.push(message);
            }
          });
          const saveObj = { id: data.id };
          newArray.push(saveObj);
          setHideMarketingMessageArray(newArray);
        }
      } else {
        const saveObj = { id: data.id };
        setHideMarketingMessageArray([saveObj]);
      }
    } else {
      const saveObj = { id: data.id };
      setHideGeneralMessageArray([saveObj]);
    }
    refresh();
  };

  return (
    <Card classes={{ root: classes.card }} ref={cardRef}>
      <CardContent>
        <Grid container style={{ flexWrap: 'nowrap' }}>
          <Grid item>
            <DocProcessLogo />
          </Grid>
          <Grid item xs={2}>
            <Box>
              <Typography
                style={{
                  marginLeft: '1em',
                  marginRight: '20px',
                  display:
                    (rowsNumber === 1 &&
                      data?.moreInfoLink?.length !== 0 &&
                      cardWidth < 705) ||
                    (rowsNumber === 1 && cardWidth < 685) ||
                    (rowsNumber > 2 &&
                      data?.moreInfoLink?.length === 0 &&
                      cardHeight < 112) ||
                    (rowsNumber === 2 &&
                      cardWidth < 680 &&
                      data?.moreInfoLink?.length !== 0) ||
                    (rowsNumber > 2 &&
                      cardHeight >= 112 &&
                      cardWidth < 775 &&
                      data?.moreInfoLink?.length !== 0) ||
                    (rowsNumber >= 3 &&
                      data?.moreInfoLink?.length !== 0 &&
                      cardHeight < 112) ||
                    (rowsNumber === 3 &&
                      data?.moreInfoLink?.length === 0 &&
                      cardWidth < 700) ||
                    rowsNumber >= 4
                      ? 'none'
                      : 'flex',
                }}
              >
                {messageIssuerTitle}
              </Typography>
              <Typography
                style={{
                  marginLeft: '1em',
                  marginRight: '100px',
                  display:
                    (rowsNumber === 1 && cardWidth < 685) ||
                    (rowsNumber === 1 &&
                      data?.moreInfoLink?.length !== 0 &&
                      cardWidth < 1000) ||
                    (rowsNumber > 2 && cardHeight < 112) ||
                    (cardWidth <= 1230 &&
                      rowsNumber >= 2 &&
                      data?.moreInfoLink?.length !== 0) ||
                    (rowsNumber >= 2 && cardWidth < 1005) ||
                    (rowsNumber > 2 && cardHeight >= 112 && cardWidth < 775) ||
                    (rowsNumber === 2 &&
                      cardWidth < 680 &&
                      data?.moreInfoLink?.length !== 0) ||
                    rowsNumber > 3
                      ? 'none'
                      : 'flex',
                }}
                variant='caption'
              >
                {messageIssuer}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={8}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Grid item className={classes.content}>
              {textMessage}
            </Grid>
            <Grid item>
              {data?.moreInfoLink && (
                <Typography
                  onClick={() => onClickMoreInfo(data)}
                  style={{
                    cursor: 'pointer',
                    textDecorationLine: 'underline',
                  }}
                >
                  {translate('dxMessages.messagesCenter.link.text')}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
            className={classes.publicationInstant}
            style={{
              display:
                (rowsNumber === 1 &&
                  data?.moreInfoLink?.length !== 0 &&
                  cardWidth < 705) ||
                (rowsNumber === 1 && cardWidth < 685) ||
                (rowsNumber === 2 &&
                  cardWidth < 680 &&
                  data?.moreInfoLink?.length !== 0) ||
                (rowsNumber < 2 &&
                  data?.moreInfoLink?.length !== 0 &&
                  cardWidth < 1210) ||
                (rowsNumber === 3 &&
                  data?.moreInfoLink?.length === 0 &&
                  cardWidth < 700) ||
                rowsNumber > 3
                  ? 'none'
                  : 'flex',
            }}
          >
            <Grid>
              {data?.publicationInstant && (
                <Typography variant='caption'>{`${translate(
                  'dxMessages.landingPage.message.post'
                )} ${new Date(data?.publicationInstant).toLocaleDateString(
                  locale,
                  {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  }
                )}`}</Typography>
              )}
              <Typography
                onClick={() => onClickHideMessage()}
                variant='caption'
                style={{
                  display: 'block',
                  cursor: 'pointer',
                  textDecorationLine: 'underline',
                }}
              >
                {translate('dxMessages.messagesCenter.link.show')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MessageWidget;
