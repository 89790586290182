export const sortLabeledItemLists = (translate, labeledItems, getItemLabel) => {
  const translateItemLabel = (item) => {
    const label = getItemLabel(item);
    return {
      translatedLabel: label && translate(label, { _: label }).toLowerCase(),
      item: item,
    };
  };

  let sortedItems = labeledItems
    .map((labeledItem) => translateItemLabel(labeledItem))
    .sort((a, b) =>
      a.translatedLabel > b.translatedLabel
        ? 1
        : a.translatedLabel < b.translatedLabel
        ? -1
        : 0
    )
    .map((x) => x.item);

  return sortedItems;
};
