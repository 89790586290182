import { WebFormMode } from '@dx-ui/dx-common';
import { InvoicePreview } from '..';
import DocumentEdit from '../../common/DocumentEdit';

const WebInvoiceEdit = (props) => {
  const { location } = props;
  const redirectMode = location?.state?.redirectMode;
  return (
    <DocumentEdit
      {...props}
      previewComponent={InvoicePreview}
      title={
        redirectMode && redirectMode === WebFormMode.SCRATCH_INVOICE
          ? 'dxMessages.invoices.create_title'
          : 'dxMessages.invoices.edit_title'
      }
      mode={WebFormMode.DRAFT_INVOICE}
    />
  );
};

export default WebInvoiceEdit;
