import { Chip } from '@material-ui/core';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FieldTitle, InputProps } from 'ra-core';
import { FunctionComponent } from 'react';
import { InputHelperText, sanitizeInputRestProps } from 'react-admin';
import { DxTheme } from '../../types';
import useInput from './useInput';

const convertStringToNumber = (value) => {
  const float = parseFloat(value);

  return isNaN(float) ? null : float;
};

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    root: (props: { readOnly: boolean }) => {
      // Specific to NumberInput for Firefox and Safari
      // Removal of the spins arrows
      // Defined here on purpose instead of generic :
      // src/modules/ui/packages/dx-portal/src/shared/webForms/fields/CommonFieldStyles.ts
      return {
        '& input': {
          '-moz-appearance': props.readOnly ? 'textfield' : 'inherit',
        },
        '& input::-webkit-inner-spin-button': {
          display: props.readOnly ? 'none' : 'visible',
        },
      };
    },
  }),
  { name: 'DxNumberInput' }
);

/**
 * An Input component for a number
 *
 * @example
 * <NumberInput source="nb_views" />
 *
 * You can customize the `step` props (which defaults to "any")
 * @example
 * <NumberInput source="nb_views" step={1} />
 *
 * The object passed as `options` props is passed to the material-ui <TextField> component
 */
const NumberInput: FunctionComponent<NumberInputProps> = ({
  format,
  helperText,
  titleSuffix,
  label,
  margin = 'dense',
  onBlur,
  onFocus,
  onChange,
  options,
  parse = convertStringToNumber,
  resource,
  source,
  step,
  min,
  max,
  validate,
  variant = 'filled',
  inputProps: overrideInputProps,
  defaultValue,
  ...rest
}) => {
  const {
    id,
    input,
    isRequired,
    meta: { error, submitError },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'number',
    validate,
    defaultValue,
    ...rest,
  });

  const classes = useStyles({ readOnly: overrideInputProps?.readOnly });

  const inputProps = { ...overrideInputProps, step, min, max };

  return (
    <TextField
      id={id}
      classes={{ root: classes.root }}
      {...input}
      variant={variant}
      error={!!(error || submitError)}
      helperText={
        <InputHelperText
          touched={true}
          error={error || submitError}
          helperText={helperText}
        />
      }
      label={
        <>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
          {titleSuffix &&
            titleSuffix.map((s) => {
              return (
                <Chip
                  label={s}
                  size='small'
                  style={{ marginLeft: '0.5em', fontSize: '0.5rem' }}
                  key={s}
                />
              );
            })}
        </>
      }
      margin={margin}
      inputProps={inputProps}
      {...options}
      {...sanitizeInputRestProps(rest)}
    />
  );
};

NumberInput.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

NumberInput.defaultProps = {
  options: {},
  step: 'any',
  textAlign: 'right',
};

export interface NumberInputProps
  extends InputProps<TextFieldProps>,
    Omit<
      TextFieldProps,
      | 'label'
      | 'helperText'
      | 'onChange'
      | 'onBlur'
      | 'onFocus'
      | 'defaultValue'
    > {
  step?: string | number;
  min?: string | number;
  max?: string | number;
  labelSuffix?: any;
}

export default NumberInput;
