import { Typography } from '@material-ui/core';
import { cloneElement, FC } from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ListProps,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  usePermissions,
  useTranslate,
} from 'react-admin';
import { RegulatorStatusIcon } from '../../components';
import { UserRoles } from '../../security';
import { isPspAdministrator } from '../configuration';
import Pagination from '../Pagination';
import { InfoMessage } from './RegulatorData';

const AppRegFilters = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput
        label='resources.regulatorAccess.list.headers.companyId'
        source='company'
        alwaysOn
      />
    </Filter>
  );
};

const RegulatorStatusField = (props) => {
  const { label, source } = props;
  const translate = useTranslate();
  return (
    <FunctionField
      label={label}
      source={source}
      sortable={true}
      render={(record: any) => {
        const { status } = record;
        return (
          <Typography>
            {<RegulatorStatusIcon status={status} />}
            <span>
              {translate(`resources.regulatorAccess.status.${status}`, {
                _: '--',
              })}
            </span>
          </Typography>
        );
      }}
    />
  );
};

const CompanyField = (props) => {
  const { label, source } = props;
  return (
    <FunctionField
      label={label}
      source={source}
      sortable={true}
      render={(record: any) => {
        const { id, companyName, companyFiscalCode } = record;
        return (
          <div>
            <Typography variant='caption'>{companyName}</Typography>
            <br />
            <Typography variant='caption'>{companyFiscalCode}</Typography>
          </div>
        );
      }}
    />
  );
};

/**
 * The list of regulator access for a company.
 *
 * Only visible for Company administrators.
 */
const RegulatorList: FC<ListProps> = (props) => {
  const { loaded, permissions } = usePermissions();
  if (!loaded || !permissions) return null;
  const appPermissions = permissions?.AUTH_ROLES
    ? permissions?.AUTH_ROLES[UserRoles.DXPURCHASE_PRODUCT]
    : permissions?.[UserRoles.DXPURCHASE_PRODUCT];
  const isV2DxAdmin: boolean =
    (appPermissions && appPermissions.find((p) => p === UserRoles.DXADMIN)) ||
    false;
  const isPspAdmin: boolean = isPspAdministrator(permissions);
  const readOnly: boolean = isPspAdmin || isV2DxAdmin;

  return (
    <>
      <InfoMessage />
      <List
        title='resources.regulatorAccess.menu'
        actions={<RegulatorActions readOnly={readOnly} />}
        pagination={<Pagination />}
        bulkActionButtons={false}
        exporter={false}
        sort={{ field: 'created', order: 'DESC' }}
        filters={readOnly ? <AppRegFilters /> : undefined}
        {...props}
        hasCreate={!readOnly}
      >
        <View readOnly={readOnly} />
      </List>
    </>
  );
};

const View = (props: any) => {
  const { readOnly } = props;
  return (
    <Datagrid {...props}>
      {readOnly && (
        <CompanyField
          label='resources.regulatorAccess.list.headers.companyId'
          source='companyDetails'
          sortable={false}
        />
      )}
      <TextField
        label='resources.regulatorAccess.list.headers.approverEmail'
        source='approverEmail'
        sortable={false}
      />
      <DateField
        label='resources.regulatorAccess.list.headers.created'
        source='created'
        options={{
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }}
      />
      <TextField
        label='resources.regulatorAccess.list.headers.email'
        source='email'
        sortable={false}
      />
      <RegulatorStatusField
        label='resources.regulatorAccess.list.headers.status'
        source='status'
      />
    </Datagrid>
  );
};

const RegulatorActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    basePath,
    readOnly,
    ...rest
  } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {!readOnly && (
        <CreateButton
          basePath={basePath}
          variant='contained'
          style={{ marginLeft: '1em' }}
          label='resources.regulatorAccess.create.actions.new'
        />
      )}
    </TopToolbar>
  );
};

export default RegulatorList;
