// The edit view of the task resource.
// It display the UI of the task (according to the UI type in its uiSpecification).
//
// Note: you can prefix all the label translation keys like:
// resource.task.${record.flow.definitionKey}.${record.definitionKey}
// to customise all the labels according to the task meaning.
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import compose from 'lodash/flowRight';
import { useState } from 'react';
import { Edit, SimpleForm } from 'react-admin';
import ActionInput from './action/ActionInput';
import ActionToolbar from './action/ActionToolbar';

const styles: any = () => ({
  // No Paper shadow around the Edit form.
  root: {
    '& > div ': {
      boxShadow: 'unset',
    },
  },
});

const DisplayErrors = (props) => {
  const { formErrors } = props;
  return (
    <Alert severity='error'>
      <ul>
        {formErrors.map((e, index) => (
          <li key={index}>
            <strong>{e}</strong>
          </li>
        ))}
      </ul>
    </Alert>
  );
};

const TaskEdit: any = (props) => {
  const { classes } = props;
  const [formErrors, setFormErrors] = useState([]);
  return (
    <Edit {...props} undoable={false} classes={{ root: classes.root }}>
      <SimpleForm
        toolbar={<ActionToolbar setFormErrors={setFormErrors} />}
        redirect={'list'}
      >
        <ActionInput {...props} />
        {formErrors.length > 0 && <DisplayErrors formErrors={formErrors} />}
      </SimpleForm>
    </Edit>
  );
};

const enhance = compose(withStyles(styles, { withTheme: true }));
export default enhance(TaskEdit);
