import {
  checkPermissions,
  Constants,
  DocumentTypeCode,
  documentTypeCodeToResource,
  EmptyValue,
  formatDateToAlfresco,
  Metadata,
  OrderSubType,
  RegulatorExtraDetailsType,
  UserRoles,
} from '@dx-ui/dx-common';
import {
  AddressDetails,
  CustomerPartyDetails,
  ItemPropertyDetails,
  OrderDetails,
  OrderLineDetails,
  OrderModel,
  PartyDetails,
  SupplierPartyDetails,
} from '@dx-ui/lib-oasis-ubl-2.1/src/OrderModel';
import { cloneDeep, first, get, set, unset } from 'lodash';
import { buildAddressAsAString } from '../modules/common/PreviewAddress';
import {
  Address,
  AlfrescoContent,
  AlfrescoProperties,
  DocumentProperties,
  P2pData,
  WebFormData,
} from '../shared/types';
import { setSourceField } from '../shared/webForms/utils';
import {
  DuplicationType,
  IAlfrescoDocumentService,
} from './AlfrescoDocumentService';
import { BaseDocumentService } from './BaseDocumentService';
import { CatalogService } from './CatalogService';
import {
  CompanyAddress,
  CompanyModel,
  CompanyService,
  CreationPolicy,
} from './CompanyService';
import { DataHelpers } from './DataHelpers';
import { IDocumentService } from './IDocumentService';
import { TemplatesService } from './TemplatesService';

const TEMPLATE_TYPE = 'ORDER';

// LINE PRICE VALUES
const PRICE_COL_PATH = 'Order[0].AdditionalItemProperty';
const PRICE_KEY_PATH = 'ValueQualifier[0]._';
const PRICE_CHECK_PATH = 'ValueQuantity[0]._';
const PRICE_VALUES = ['AAA', '128', '124'];

export interface IOrderService extends IDocumentService {}

export class OrderService extends BaseDocumentService implements IOrderService {
  /**
   * 'ublProperties.' part is important in the constant value because of how data is read from the template
   */
  public static CUSTOM_CATEGORY = 'custom';
  public static MONO_CURRENCY_FIELD = `ublProperties.${OrderService.CUSTOM_CATEGORY}.MONO_CURRENCY`;
  public static MONO_VAT_FIELD = `ublProperties.${OrderService.CUSTOM_CATEGORY}.MONO_VAT`;
  public static MONO_TAX_CATEGORY_FIELD = `ublProperties.${OrderService.CUSTOM_CATEGORY}.MONO_TAX_CATEGORY`;

  constructor(
    private companyService: CompanyService,
    private templatesService: TemplatesService,
    private catalogService: CatalogService,
    documentService: IAlfrescoDocumentService
  ) {
    super(documentService);
  }

  public async loadDraft(nodeId: string | undefined): Promise<AlfrescoContent> {
    const resource = documentTypeCodeToResource(DocumentTypeCode.ORDERS);
    const payload = { id: nodeId };
    const response = await this.documentService.dataProvider[
      Constants.API_GET_JSON_CONTENT
    ](resource, payload);

    const ublData: OrderModel = response.data as OrderModel;

    let order: OrderDetails | undefined = undefined;

    if (ublData?.Order?.length) {
      order = ublData?.Order[0];
    }

    if (!order) {
      throw new Error(`Unexpected error or no order for nodeId ${nodeId}.`);
    }

    // store lines outside of the UBL content
    // TODO: this should be changed. Lines should stay together with data.
    let lines: OrderLineDetails[] = order.OrderLine;

    this.setupItemOrderConventions(ublData);

    // Mono VAT
    let customUblProperties = { ublProperties: {} };
    if (order?.TaxTotal?.[0]?.TaxSubtotal?.length === 1) {
      // Can update MonoVAT field
      set(
        customUblProperties,
        OrderService.MONO_VAT_FIELD,
        order?.TaxTotal?.[0]?.TaxSubtotal?.[0]?.Percent?.[0]?._
      );
    }

    // EDM Properties are extracted from UBL content
    const metadataProperties = this.buildMetadataProperties(order);

    // set mono Currency
    set(
      customUblProperties,
      OrderService.MONO_CURRENCY_FIELD,
      metadataProperties[Metadata.currency]
    );

    order.OrderLine = [];

    const result: AlfrescoContent = {
      id: nodeId,
      properties: metadataProperties,
      ublContent: {
        ublProperties: { ...customUblProperties.ublProperties, ...ublData },
        lines,
      },
    };

    return result;
  }

  public async load(nodeId: string): Promise<AlfrescoContent> {
    const result = await this.documentService.loadDocument(
      nodeId,
      DocumentTypeCode.ORDERS
    );

    const lines = result.orderLine?.map((line) => line.lineItem);
    delete result.orderLine;
    const ublProperties = result;

    const data: AlfrescoContent = {
      id: nodeId,
      properties: {},
      ublContent: {
        ublProperties,
        lines,
      },
    };

    return data;
  }

  public createNewContent(
    documentSubtype: OrderSubType = OrderSubType.FinalOrder
  ): AlfrescoContent {
    const content: OrderModel = {
      ...OrderService.orderHeader,
      Order: [
        {
          UBLVersionID: [
            {
              _: '2.1',
            },
          ],
          OrderTypeCode: [{ _: documentSubtype }],
          ID: [],
          IssueDate: [],
          OrderLine: [],
          AccountingCustomerParty: [{}],
          BuyerCustomerParty: [{}],
          SellerSupplierParty: [{}],
          Delivery: [
            {
              DeliveryParty: [{}],
            },
          ],
          LineCountNumeric: [{ _: 1 }], // 1 line by default (see bellow)
        },
      ],
    };

    this.setupItemOrderConventions(content);

    const data: AlfrescoContent = {
      ...this.documentService.initNewDocument(
        DocumentTypeCode.ORDERS,
        documentSubtype
      ),
      ublContent: {
        lines: [OrderService.initNewLine()],
        ublProperties: content,
      },
    };

    return data;
  }

  public async createNew(recipientId: string): Promise<string | undefined> {
    let orderContent: AlfrescoContent = await this.createNewContent();
    // Specific to Recadv and Order => for the creation from scratch,
    // the issuerId is map to recipientId. Templates limitation ...
    set(orderContent.properties, Metadata.issuerId, recipientId);
    const unflattenedOrder = {
      properties: orderContent.properties,
      ...orderContent.ublContent,
    };
    // Saving w/o nodeId => POST create
    const orderProperties = await this.saveData(unflattenedOrder);
    return orderProperties.id;
  }

  private setupItemOrderConventions(data: OrderModel) {
    this.setupLineTotalConvention(data);
  }

  private cleanupItemOrderConventions(data: OrderModel) {
    this.cleanupLineTotalConvention(data);
  }

  /**
   *
   */
  private setupLineTotalConvention(data: OrderModel) {
    const lines = data.Order[0].OrderLine ?? [];

    lines.forEach((line) => {
      if (line.LineItem?.length) {
        const emptyItem: ItemPropertyDetails = {
          Name: [],
        };
        this.setupCollectionItems(
          line.LineItem[0],
          PRICE_COL_PATH,
          PRICE_KEY_PATH,
          PRICE_VALUES,
          emptyItem
        );
      }
    });
  }

  /**
   *
   */
  private cleanupLineTotalConvention(data: OrderModel) {
    const lines = data.Order[0].OrderLine ?? [];

    lines.forEach((line) => {
      if (line.LineItem?.length) {
        this.cleanCollectionItems(
          line.LineItem[0],
          PRICE_COL_PATH,
          PRICE_CHECK_PATH
        );
      }
    });
  }

  /**
   * Gets or creates a document.
   * @param nodeId Alfresco node Id of the document
   * @param recipientId ??? -> actually this is the issuerId / customerId for Order
   * @param locale
   * @param alfrescoMetadata - alfresco properties (edm:* fields)
   */
  public async getData(
    nodeId: string | undefined,
    recipientId: string,
    locale: string,
    alfrescoMetadata: any = {},
    newTemplate?: any
  ): Promise<WebFormData> {
    let clientId: string;

    // load document from alfresco or initialize a new one
    let document: AlfrescoContent;

    if (newTemplate) {
      // create a new document
      document = this.createNewContent();

      // 'recipientId' parameter name is not correct -> this should be fixed
      // was true for invoices etc, but not for order
      clientId = recipientId;
    } else {
      //load document from alfresco
      document = await this.loadDraft(nodeId);

      // document already created -> take the issuer directly
      clientId = get(alfrescoMetadata, Metadata.issuerId);
    }

    const completeData: P2pData = {
      id: nodeId,
      properties: alfrescoMetadata,
      lines: [],
      ublProperties: {},
    };
    const selectValues = [];

    /* #region add hard coded data -> this should be deleted in the future */
    const { data: hardcodedData, lists: hardcodedLists } =
      this.getHardcodedValues(TEMPLATE_TYPE);

    this.mergeFormData(completeData, hardcodedData);
    this.mergeFormData(selectValues, hardcodedLists, true);
    /* #endregion */

    /* #region add data from the loaded document */
    // document content (with lines and data separated)
    const Order: OrderDetails = document.ublContent.ublProperties;
    const lines: OrderLineDetails[] = document.ublContent.lines;

    const flattenedDocument = {
      properties: document.properties,
      ublProperties: Order,
      lines,
    };

    this.mergeFormData(completeData, flattenedDocument);
    /* #endregion */

    /* #region call REST APIs and dynamically fill parts of the data */

    // customer
    const companyDetails: CompanyModel = await this.companyService.getDetails();
    const { buyerCustomerParty, buyerCustomerPartyLists } =
      await this.getBuyerCustomerPartyInformation(companyDetails, locale);

    this.mergeFormData(selectValues, buyerCustomerPartyLists);

    // customer delivery location
    const { deliveryParty, deliveryPartyLists } =
      await this.getDeliveryInformation(companyDetails, locale);

    this.mergeFormData(selectValues, deliveryPartyLists);

    // accounting customer
    const { accountingCustomerParty, accountingCustomerPartyLists } =
      await this.getAccountingCustomerPartyInformation(companyDetails, locale);

    this.mergeFormData(selectValues, accountingCustomerPartyLists);

    // supplier / recipient
    const { sellerSupplierParty, sellerSupplierPartyLists } =
      await this.getSupplierDataAndLists(locale);

    this.mergeFormData(selectValues, sellerSupplierPartyLists);

    // UBL content with loaded data
    const partialOrder: OrderModel = {
      Order: [
        {
          ID: [],
          OrderLine: [],
          IssueDate: [],
          SellerSupplierParty: [sellerSupplierParty],
          Delivery: [{ DeliveryParty: [deliveryParty] }],
          BuyerCustomerParty: [buyerCustomerParty],
          AccountingCustomerParty: [accountingCustomerParty],
        },
      ],
    };

    this.mergeFormData(completeData.ublProperties, partialOrder);

    /* #endregion */

    /* #region add data from the template */
    const documentSubTypeCode: OrderSubType =
      this.getOrderSubtypeCode(document);

    const template = await this.templatesService.getTemplate(
      DocumentTypeCode.ORDERS,
      undefined,
      documentSubTypeCode,
      clientId
    );

    const { defaultValues, selectValues: templateLists } =
      this.templatesService.getValues(template);

    this.mergeFormData(selectValues, templateLists);

    // default values from template have not the priority
    this.mergeFormData(defaultValues, completeData);
    this.mergeFormData(completeData, defaultValues);

    /* #endregion */

    /* #region add missing data */
    // Lines
    if (completeData.lines.length === 0) {
      const newLine = OrderService.initNewLine();
      completeData.lines = [newLine];
    }

    const supplierId = get(
      completeData.ublProperties,
      'Order[0].SellerSupplierParty[0].Party[0].PartyIdentification[0].ID[0]._'
    );
    const catalogLines = await this.catalogService.getCatalogLines(
      clientId,
      supplierId
    );
    if (catalogLines) {
      const { lists: catalogLists } = await this.fillLinesSuggestionsInfo(
        catalogLines,
        'LineItem[0].Item[0].BuyersItemIdentification[0].ID[0]._', // code client
        'LineItem[0].Item[0].SellersItemIdentification[0].ID[0]._', // code supplier
        'LineItem[0].Item[0].StandardItemIdentification[0].ID[0]._', // ean
        'LineItem[0].Item[0].Description[0]._' // description
      );
      this.mergeFormData(selectValues, catalogLists);
    }

    /* #endregion */

    const documentData: P2pData = {
      id: completeData.id,
      lines: completeData.lines,
      properties: completeData.properties,
      ublProperties: completeData.ublProperties,
    };

    const result = {
      documentData,
      selectValues,
      template,
    };

    return result;
  }

  /**
   * Saves the data as an Alfresco document
   * @param formData Formatted document data
   */
  public async saveData(formData: P2pData): Promise<DocumentProperties> {
    const data = cloneDeep(formData);
    // /!\ don't use form formData after this point. Use data instead. It avoids accidentally changing it.
    const nodeId = data.id;

    const queryType = nodeId
      ? Constants.API_PUT_SAVE_DOCUMENT
      : Constants.API_POST_CREATE_DOCUMENT;

    const resource = documentTypeCodeToResource(DocumentTypeCode.ORDERS);

    const lines = data.lines as OrderLineDetails[];
    const ublData: OrderModel = data.ublProperties as OrderModel;
    const Order = ublData.Order[0];

    /* #region lines */
    lines.forEach((line, index) => {
      line.LineItem[0].ID = [{ _: `${index + 1}` }];
    });

    Order.OrderLine = lines;
    Order.LineCountNumeric = [{ _: lines.length }];
    /* #endregion */

    /* #region cleanup create empty elements inside collections */

    // cleanup empty collection fields (added during loading)
    this.cleanupItemOrderConventions(ublData);

    /* #endregion */

    // EDM Properties are extracted from UBL content
    const metadataProperties = this.buildMetadataProperties(Order);

    // Sets the header currency (will be available as soon as the first line is well filled)
    Order.DocumentCurrencyCode = [{ _: metadataProperties[Metadata.currency] }];

    // TODO: clean up custom added fields (uses internally and not part of ubl)
    unset(ublData, OrderService.CUSTOM_CATEGORY);
    unset(ublData, 'id');

    const OrderData = {
      ublContent: {
        ...OrderService.orderHeader,
        ...ublData,
      },
      properties: { ...formData.properties, ...metadataProperties },
    };

    const payload = {
      data: OrderData,
      id: nodeId,
    };

    const result = await this.documentService.dataProvider[queryType](
      resource,
      payload
    );

    return result.data as DocumentProperties;
  }

  /**
   * Create a new document by copying data from source node id
   */
  public async clone(nodeId: string): Promise<DocumentProperties> {
    return await this.documentService.copy(nodeId, DuplicationType.COPY);
  }

  /**
   * RecipientId from Alfresco metadata based on document type
   */
  public static getRecipientId(alfrescoProperties: any): string | undefined {
    return get(alfrescoProperties, Metadata.issuerId);
  }

  /**
   * regulatorExtraDetails from Alfresco metadata based on document type
   */
  public static getRegulatorExtraDetails(
    alfrescoProperties: any
  ): RegulatorExtraDetailsType | undefined {
    return get(alfrescoProperties, Metadata.regulatorExtraDetails);
  }

  /**
   * Checks if the user has the permissions to create a document
   */
  public static canCreate(permissions: any): boolean {
    return (
      permissions &&
      checkPermissions(
        permissions,
        UserRoles.DXPURCHASE_PRODUCT,
        UserRoles.CREATE_ORDER
      )
    );
  }

  public async fetchCreationPolicy(
    resource: string,
    recipientId: string
  ): Promise<CreationPolicy> {
    return await this.companyService.fetchCreationPolicy(resource, recipientId);
  }

  /**
   * Initializes a new line
   */
  public static initNewLine(defaultVat?: number | undefined): OrderLineDetails {
    let line: OrderLineDetails = {
      LineItem: [
        {
          ID: [{ _: '' }],
          Item: [{}],
        },
      ],
    };

    if (defaultVat !== undefined) {
      set(line, 'LineItem[0].TaxTotal[0].TaxSubtotal[0].Percent', [
        { _: defaultVat },
      ]); // Line VAT amount
    }

    return line;
  }

  /**
   * Build an Address object to use the same method as the one used by OrderPreview
   * */
  public static buildAddressDetails = (
    ublAddress: AddressDetails | undefined
  ): Address | undefined => {
    if (ublAddress === undefined) {
      return undefined;
    }

    const address: Address = { id: '' };

    const buildingNumber = get(ublAddress, 'BuildingNumber[0]._');
    if (buildingNumber !== undefined) {
      address.buildingNumber = { value: buildingNumber };
    }
    const cityName = get(ublAddress, 'CityName[0]._');
    if (cityName !== undefined) {
      address.cityName = { value: cityName };
    }
    const country = get(ublAddress, 'Country[0].IdentificationCode[0]._');
    if (buildingNumber !== undefined) {
      address.country = {
        identificationCode: { value: country },
        name: { value: undefined },
      };
    }
    const postalZone = get(ublAddress, 'PostalZone[0]._');
    if (postalZone !== undefined) {
      address.postalZone = { value: postalZone };
    }
    const streetName = get(ublAddress, 'StreetName[0]._');
    if (buildingNumber !== undefined) {
      address.streetName = { value: streetName };
    }
    const additionalStreetName = get(ublAddress, 'AdditionalStreetName[0]._');
    if (additionalStreetName !== undefined) {
      address.additionalStreetName = { value: additionalStreetName };
    }

    return address;
  };

  /**
   * Takes the ubl content and reads informations used as metadata properties
   */
  private buildMetadataProperties(order: OrderDetails) {
    // EDM Properties are extracted from UBL content
    const metadataProperties = {};
    metadataProperties[Metadata.documentId] = first(order.ID)?._;
    metadataProperties[Metadata.documentTypeCode] = DocumentTypeCode.ORDERS;
    metadataProperties[Metadata.documentSubTypeCode] = first(
      order.OrderTypeCode
    )?._;
    metadataProperties[Metadata.issueDate] = formatDateToAlfresco(
      first(order.IssueDate)?._
    );

    // find currency in first price line if any
    metadataProperties[Metadata.currency] =
      order.OrderLine?.[0]?.LineItem?.[0]?.Price?.[0].PriceAmount?.[0].currencyID;

    // recipientId / recipientName
    if (
      !!order.SellerSupplierParty?.length &&
      !!order.SellerSupplierParty[0].Party?.length
    ) {
      const supplierParty = order.SellerSupplierParty[0].Party[0];
      metadataProperties[Metadata.recipientId] =
        supplierParty.PartyIdentification![0].ID[0]._;
      metadataProperties[Metadata.recipientName] =
        supplierParty.PartyName![0].Name[0]._;
    }

    // issuerId / issuerName / issueDate
    if (
      !!order.BuyerCustomerParty?.length &&
      !!order.BuyerCustomerParty[0].Party?.length
    ) {
      const customerParty = order.BuyerCustomerParty![0].Party![0];
      metadataProperties[Metadata.issuerId] =
        customerParty.PartyIdentification![0].ID[0]._;
      metadataProperties[Metadata.issuerName] =
        customerParty.PartyName![0].Name[0]._;
      metadataProperties[Metadata.issueDate] = formatDateToAlfresco(
        first(order.IssueDate)?._
      );
    }

    // documentId
    metadataProperties[Metadata.documentId] = first(order.ID)?._;

    // Delivery information
    if (!!order.Delivery?.[0]?.DeliveryParty?.length) {
      // Delivery location name
      if (!!order.Delivery[0].DeliveryParty[0].PartyName?.length) {
        metadataProperties[Metadata.locationName] =
          order.Delivery[0].DeliveryParty[0].PartyName[0].Name[0]._;
      }
      // Delivery location address
      if (!!order.Delivery[0].DeliveryParty[0].PostalAddress?.length) {
        const deliveryAddress: Address | undefined =
          OrderService.buildAddressDetails(
            order.Delivery[0].DeliveryParty[0].PostalAddress[0]
          );
        metadataProperties[Metadata.locationAddress] =
          buildAddressAsAString(deliveryAddress);
      }
      // Delivery location GLN
      if (!!order.Delivery[0].DeliveryParty[0].EndpointID?.length) {
        metadataProperties[Metadata.gln] =
          order.Delivery[0].DeliveryParty[0].EndpointID[0]._;
      }
    }

    // Delivery Date
    if (
      !!order.Delivery?.[0]?.RequestedDeliveryPeriod?.[0]?.StartDate?.length
    ) {
      metadataProperties[Metadata.deliveryDate] = formatDateToAlfresco(
        order.Delivery[0].RequestedDeliveryPeriod[0].StartDate[0]._
      );
    }

    return metadataProperties;
  }

  private async getSupplierDataAndLists(
    locale: string,
    noDefault: boolean = true
  ) {
    const sellerSupplierPartyLists: any = {};
    let sellerSupplierParty: SupplierPartyDetails = {};

    //get the list with all relations (in case of orders => list if suppliers)
    const suppliers: CompanyModel[] = await this.companyService.getRelations(
      DocumentTypeCode.ORDERS
    );

    if (suppliers.length > 0) {
      let supplierId;
      if (suppliers.length === 1) {
        supplierId = suppliers[0].identification;
      }

      // DATA

      if (!noDefault || supplierId) {
        sellerSupplierParty = await this.getSellerSupplierParty(supplierId);
      }

      // LISTS

      //supplier code list
      const selectSuppliersData = this.createSelectData(
        suppliers,
        'identification',
        'name',
        true,
        supplierId
      );

      set(
        sellerSupplierPartyLists,
        'ublProperties.Order[0].SellerSupplierParty[0].Party[0].PartyIdentification[0].ID[0]._',
        selectSuppliersData.choices
      );

      // country list
      const countryId = get(
        sellerSupplierParty,
        'Party[0].PostalAddress[0].Country[0].IdentificationCode[0]._'
      );
      const countrySelectData = this.getCountriesSelectData(countryId, locale);

      setSourceField(
        // selected country code
        sellerSupplierPartyLists,
        `ublProperties.Order[0].SellerSupplierParty[0].Party[0].PostalAddress[0].Country[0].IdentificationCode[0]._`,
        countrySelectData.choices
      );
    }

    return { sellerSupplierParty, sellerSupplierPartyLists };
  }

  public async getSellerSupplierParty(identification: string) {
    const sellerSupplierParty: SupplierPartyDetails = {};

    const supplier = await this.companyService.getDetails(identification);

    if (!supplier) {
      throw new Error(`No related suppliers were found!`);
    }

    // PartyDetails
    const partyDetails: PartyDetails = {
      PartyIdentification: [{ ID: [{ _: supplier.identification }] }],
      PartyName: [{ Name: [{ _: supplier.name }] }],
    };

    // AddressDetails
    const supplierCompanyAddress: CompanyAddress = supplier.billingAddress;
    if (supplierCompanyAddress) {
      const postalAddress: AddressDetails = this.companyAddressToUBL(
        supplierCompanyAddress
      );

      partyDetails.PostalAddress = [postalAddress];

      // GLN
      if (supplierCompanyAddress?.gln) {
        partyDetails.EndpointID = [{ _: supplierCompanyAddress?.gln }];
      }
    }

    // Trade Register Number
    if (supplier.registrationNumber) {
      partyDetails.PartyLegalEntity = [
        { CompanyID: [{ _: supplier.registrationNumber }] },
      ];
    }

    // SupplierPartyDetails
    sellerSupplierParty.Party = [partyDetails];

    // Retrieves the supplierCode given by the client to the supplier for the Order flow.
    const relations = await this.companyService.getRelations(
      DocumentTypeCode.ORDERS
    );
    const currentRelation = relations.filter(
      (r) => r.identification === supplier.identification
    )[0];
    sellerSupplierParty.CustomerAssignedAccountID = [
      { _: currentRelation?.supplierCode },
    ];

    return sellerSupplierParty;
  }

  /**
   * Converts backend addresses to UBL addresses
   */
  private companyAddressToUBL(companyAddress: CompanyAddress) {
    const postalAddress: AddressDetails = {};

    postalAddress.BuildingNumber = [
      { _: companyAddress?.buildingNumber ?? EmptyValue },
    ];

    // HOT FIX : TO BE REVISITED ONCE WE HAVE ALL THE SPECS CLEARED
    // V2 DB Address workaround which is take into account on backend side could not fit issuer info.
    // B/E sets name = tb_address.street, street = tb_address.additionalstreet
    let street = companyAddress?.street;
    if (!street) {
      // Meaning that additionalstreet was empty or not defined
      // => reverts the B/E V2 DB workaround
      street = companyAddress?.name;
    }
    postalAddress.StreetName = [{ _: street ?? EmptyValue }];

    if (companyAddress?.additionalStreet) {
      postalAddress.AdditionalStreetName = [
        { _: companyAddress?.additionalStreet },
      ];
    }

    postalAddress.CityName = [{ _: companyAddress?.city ?? EmptyValue }];
    postalAddress.PostalZone = [
      { _: companyAddress?.postalCode ?? EmptyValue },
    ];

    if (companyAddress?.idCountry) {
      postalAddress.Country = [
        {
          IdentificationCode: [{ _: companyAddress?.idCountry }],
        },
      ];
    }

    return postalAddress;
  }

  private async getBuyerCustomerPartyInformation(
    companyDetails: CompanyModel | undefined,
    locale: string
  ) {
    if (!companyDetails) {
      throw new Error('Customer data cannot be empty.');
    }

    const buyerCustomerPartyLists: any = {};

    // PartyDetails
    const partyDetails: PartyDetails = {
      PartyIdentification: [{ ID: [{ _: companyDetails.identification }] }],
      PartyName: [{ Name: [{ _: companyDetails.name }] }],
    };

    // AddressDetails
    const companyAddress = companyDetails.billingAddress;

    if (companyAddress) {
      const postalAddress: AddressDetails =
        this.companyAddressToUBL(companyAddress);

      partyDetails.PostalAddress = [postalAddress];

      if (companyAddress?.gln) {
        partyDetails.EndpointID = [{ _: companyAddress?.gln }];
      }
    }

    if (companyDetails.registrationNumber) {
      partyDetails.PartyLegalEntity = [
        { CompanyID: [{ _: companyDetails.registrationNumber }] },
      ];
    }

    // CustomerPartyDetails
    const buyerCustomerParty: CustomerPartyDetails = {
      Party: [partyDetails],
    };

    // LISTS
    const countrySelectData = this.getCountriesSelectData(
      companyAddress?.idCountry,
      locale
    );

    setSourceField(
      // selected country code
      buyerCustomerPartyLists,
      `ublProperties.Order[0].BuyerCustomerParty[0].Party[0].PostalAddress[0].Country[0].IdentificationCode[0]._`,
      countrySelectData.choices
    );

    return {
      buyerCustomerParty,
      buyerCustomerPartyLists,
    };
  }

  private async getAccountingCustomerPartyInformation(
    companyDetails: CompanyModel | undefined,
    locale: string
  ) {
    if (!companyDetails) {
      throw new Error('Customer data cannot be empty.');
    }

    const accountingCustomerPartyLists: any = {};

    // PartyDetails
    const partyDetails: PartyDetails = {
      PartyIdentification: [{ ID: [{ _: companyDetails.identification }] }],
      PartyName: [{ Name: [{ _: companyDetails.name }] }],
    };

    // AddressDetails
    const companyAddress = companyDetails.billingAddress;

    if (companyAddress) {
      const postalAddress: AddressDetails =
        this.companyAddressToUBL(companyAddress);

      partyDetails.PostalAddress = [postalAddress];

      if (companyAddress?.gln) {
        partyDetails.EndpointID = [{ _: companyAddress?.gln }];
      }
    }

    if (companyDetails.registrationNumber) {
      partyDetails.PartyLegalEntity = [
        { CompanyID: [{ _: companyDetails.registrationNumber }] },
      ];
    }

    // CustomerPartyDetails
    const accountingCustomerParty: CustomerPartyDetails = {
      Party: [partyDetails],
    };

    // LISTS
    const countrySelectData = this.getCountriesSelectData(
      companyAddress?.idCountry,
      locale
    );

    setSourceField(
      // selected country code
      accountingCustomerPartyLists,
      `ublProperties.Order[0].AccountingCustomerParty[0].Party[0].PostalAddress[0].Country[0].IdentificationCode[0]._`,
      countrySelectData.choices
    );

    return {
      accountingCustomerParty,
      accountingCustomerPartyLists,
    };
  }

  private async getDeliveryInformation(
    companyDetails: CompanyModel,
    locale: string,
    noAlternativeDefault: boolean = true
  ) {
    const deliveryPartyLists: any = {};

    // PartyDetails
    const partyDetails: PartyDetails = {
      PartyIdentification: [{ ID: [{ _: companyDetails.identification }] }],
    };

    // AddressDetails
    const address =
      noAlternativeDefault && companyDetails.shippingAddress.length > 1
        ? undefined
        : first(companyDetails.shippingAddress);

    if (address) {
      const postalAddress: AddressDetails = this.companyAddressToUBL(address);
      if (address?.name) {
        partyDetails.PartyName = [{ Name: [{ _: address?.name }] }];
      }

      if (address?.gln) {
        partyDetails.EndpointID = [{ _: address?.gln }];
      }

      partyDetails.PostalAddress = [postalAddress];
    }

    // CustomerPartyDetails
    const deliveryParty: PartyDetails = partyDetails;

    // country
    const countrySelectData = this.getCountriesSelectData(
      address?.idCountry,
      locale
    );

    setSourceField(
      deliveryPartyLists,
      `ublProperties.Order[0].Delivery[0].DeliveryParty[0].PostalAddress[0].Country[0].IdentificationCode[0]._`,
      countrySelectData.choices
    );
    //delivery locations
    const deliverySelectData = this.createSelectData(
      companyDetails.shippingAddress ?? [],
      'gln',
      'name',
      true,
      address?.gln
    );

    // LISTS
    setSourceField(
      deliveryPartyLists,
      `ublProperties.Order[0].Delivery[0].DeliveryParty[0].EndpointID[0]._`,
      deliverySelectData.choices
    );

    return {
      deliveryParty: deliveryParty,
      deliveryPartyLists: deliveryPartyLists,
    };
  }

  private getOrderSubtypeCode(metadata: AlfrescoProperties) {
    const val = DataHelpers.getDocumentSubtypeCode(metadata);
    const documentSubTypeCode: OrderSubType =
      Object.values(OrderSubType).find((v) => val === v) ??
      OrderSubType.FinalOrder;
    return documentSubTypeCode;
  }

  private static orderHeader = {
    _D: 'urn:oasis:names:specification:ubl:schema:xsd:Order-2',
    _A: 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2',
    _B: 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2',
  };
}
