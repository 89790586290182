import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import { useTranslate } from 'ra-core';
import { useEffect, useState } from 'react';
import {
  Confirm,
  useDeleteWithConfirmController,
  useGetIdentity,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { Metadata } from '../../constants';
import { DxTheme } from '../../types';
import { GA_EVENTS, sendGAEvent } from '../../utils';

const sanitizeRestProps = ({
  basePath,
  filterValues,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  label,
  pristine,
  resource,
  saving,
  undoable,
  redirect,
  submitOnEnter,
  ...rest
}) => rest;

const useStyles = makeStyles(
  (theme: DxTheme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    button: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    label: {
      paddingLeft: '0.5em',
    },
    labelRightIcon: {
      paddingRight: '0.5em',
    },
    smallIcon: {
      fontSize: 20,
    },
    mediumIcon: {
      fontSize: 22,
    },
    largeIcon: {
      fontSize: 24,
    },
  }),
  { name: 'DeleteIconButton' }
);

const DeleteIconButton = (props) => {
  const {
    label,
    className,
    icon,
    onClick,
    onSuccess,
    disabled,
    color,
    resource,
    record,
    basePath,
    redirect,
    redirectOnDelete = true,
    refreshOnDelete = true,
    ...rest
  } = props;
  const notify = useNotify();
  const redirectMethod = useRedirect();
  const refresh = useRefresh();
  const [deleted, setDeleted] = useState(false);
  const { identity }: any = useGetIdentity();
  useEffect(() => {
    if (deleted === true) {
      sendGAEvent(
        GA_EVENTS.categories.LIST_CONTEXT_MENU.name,
        GA_EVENTS.categories.LIST_CONTEXT_MENU.actions.DELETE,
        identity.company.cmsRootDir,
        `node id: ${record?.id}`
      );
    }
  }, [deleted, identity, record?.id]);

  const handleOnSuccess = () => {
    setDeleted(true);

    notify(
      'dxMessages.delete.completed',
      'success',
      /** no args */ null,
      false /* not undoable */,
      5000 /* autoHideDuration ms */
    );

    if (typeof onSuccess === 'function') {
      onSuccess(record);
    }

    if (redirectOnDelete) {
      redirectMethod(redirect, basePath || `/${resource}`);
    }

    if (refreshOnDelete) {
      refresh();
    }
  };

  const { open, loading, handleDialogOpen, handleDialogClose, handleDelete } =
    useDeleteWithConfirmController({
      resource,
      record,
      basePath,
      redirect,
      onClick,
      onSuccess: handleOnSuccess,
    });
  const classes = useStyles(props);
  const translate = useTranslate();

  const shortifyName = (name: string | undefined) => {
    if (!name) {
      return name;
    }

    if (name.length > 20) {
      return `${name.substr(0, 10)}...${name.substr(name.length - 10, 10)}`;
    }
  };

  return label && !disabled ? (
    <>
      <Tooltip title={translate(label, { _: label })}>
        <IconButton
          {...sanitizeRestProps(rest)}
          aria-label={translate(label, { _: label })}
          className={classnames(
            'ra-delete-button',
            classes.deleteButton,
            className
          )}
          color={color}
          onClick={handleDialogOpen}
          disabled={loading}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Confirm
        isOpen={open}
        loading={loading}
        title='ra.message.delete_title'
        content='ra.message.delete_content'
        translateOptions={{
          name: '',
          id:
            record.properties[Metadata.documentId] ||
            shortifyName(record.name) ||
            '--',
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  ) : (
    <IconButton
      className={classnames(
        'ra-delete-button',
        classes.deleteButton,
        className
      )}
      color={color}
      disabled={disabled || loading}
      onClick={handleDelete}
      key='button'
      {...sanitizeRestProps(rest)}
    >
      {icon}
    </IconButton>
  );
};

DeleteIconButton.defaultProps = {
  redirect: 'list',
  undoable: true,
  icon: <ActionDelete />,
  color: 'primary',
  label: 'ra.action.delete',
};

export default DeleteIconButton;
