/* eslint-disable jsx-a11y/anchor-is-valid */
import { IconButton, Popover, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { Fragment, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { colors } from '../constants/Colors';
import { DxTheme } from '../types';
import { AnnouncementsConsumer } from './AnnouncementsContext';

const useStyles = makeStyles(
  (theme) => ({
    root: (props: { isSmall: any; height: number }) => {
      return {
        position: 'relative',
        overflow: 'hidden',
        height: props.height,
        overflowX: props.isSmall ? 'auto' : 'inherit',
      };
    },
    container: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: '32px',
      padding: '0 20px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: 'white',
      transform: 'translate3d(0, 0, 0.00001px)',
    },
    icon: {
      '&:hover': {
        opacity: 0.5,
      },
      cursor: 'pointer',
    },
    link: {
      color: 'white',
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.5,
      },
    },
    label: {
      fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
      fontSize: 14, // 0.875em;
      lineHeight: '1.5em',
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      flex: 1,
    },
    infoClose: {
      float: 'right',
      marginTop: 8,
      marginRight: 8,
    },
    info: {
      maxWidth: 440,
      overflow: 'hidden', // To prevent margin collapse
    },
    infoTitle: {
      fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
      fontSize: 16, // 1em;
      fontWeight: 400,
      margin: 16,
      marginTop: 20,
    },
    infoMessage: {
      fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
      fontSize: 14, // 0.875em;
      lineHeight: '1.5em',
      margin: 16,
    },
    error: {
      backgroundColor: colors.functionalRed,
      overflowX: 'auto',
    },
    downtime: {
      backgroundColor: colors.black80,
      overflowX: 'auto',
    },
    marketing: {
      backgroundColor: colors.mainColor5,
      overflowX: 'auto',
    },
  }),
  { name: 'AnnouncementsBar' }
);

const AnnouncementBar = (props) => {
  const { announcement, onDismissAnnouncement } = props;

  const isSmall = useMediaQuery((theme: DxTheme) =>
    theme.breakpoints.down('sm')
  );
  const classes = useStyles({ isSmall, ...props });

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseClick = () => {
    onDismissAnnouncement(announcement.id);
  };

  const handleOpenMore = (evt) => {
    evt.preventDefault();
    setOpen(true);
    setAnchorEl(evt.currentTarget);
  };

  const handleDismissMore = (evt) => {
    evt.preventDefault();
    setOpen(false);
  };

  const createMarkup = (text) => {
    return { __html: text };
  };

  return (
    <div className={classNames(classes.root, classes[announcement.type])}>
      <div className={classes.container}>
        <CloseIcon className={classes.icon} onClick={handleCloseClick} />
        <div className={classes.label} title={announcement.message}>
          {announcement.message}
          {!!announcement.linkHref && (
            <Fragment>
              &nbsp;
              <a
                className={classes.link}
                href={announcement.linkHref}
                target='_blank'
                rel='noopener noreferrer'
              >
                {announcement.linkText}
              </a>
            </Fragment>
          )}
          {!!announcement.linkMoreInfo && (
            <Fragment>
              &nbsp;
              <a className={classes.link} onClick={handleOpenMore}>
                {announcement.linkText}
              </a>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleDismissMore}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                PaperProps={{ style: { marginTop: 12 } }}
              >
                <div className={classes.info}>
                  <IconButton
                    className={classes.infoClose}
                    onClick={handleDismissMore}
                  >
                    <CloseIcon />
                  </IconButton>
                  <div className={classes.infoTitle}>
                    {announcement.linkMoreInfo.title}
                  </div>
                  {announcement.linkMoreInfo.messages &&
                    announcement.linkMoreInfo.messages.map((msg, idx) => (
                      <div
                        className={classes.infoMessage}
                        dangerouslySetInnerHTML={createMarkup(
                          sanitizeHtml(msg)
                        )}
                        key={idx}
                      />
                    ))}
                  {announcement.linkMoreInfo.message &&
                    !announcement.linkMoreInfo.messages && (
                      <div className={classes.infoMessage}>
                        {announcement.linkMoreInfo.message
                          .split('\n')
                          .map((msg, idx) => (
                            <p key={idx}>{msg}</p>
                          ))}
                      </div>
                    )}
                </div>
              </Popover>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const AnnouncementsBar = (props) => {
  const classes = useStyles(props);

  return (
    <AnnouncementsConsumer>
      {({ announcement, onDismissAnnouncement }) =>
        !!announcement && (
          <AnnouncementBar
            announcement={announcement}
            height={32}
            onDismissAnnouncement={onDismissAnnouncement}
            classes={classes}
          />
        )
      }
    </AnnouncementsConsumer>
  );
};

export default AnnouncementsBar;
