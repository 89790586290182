/**
 * The welcome banner, always at the top of the dashboard,
 * not moveable, not removeable.
 *
 * Display the last login so user can check his password
 * is not compromised.
 */
import { IWidgetContent } from '@dx-ui/dx-common/src/layout/Dashboard/Widget';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { usePreferences } from '@react-admin/ra-preferences';
import moment from 'moment';
import React, { FC } from 'react';
import { Loading, useQuery, useTranslate } from 'react-admin';
import WelcomeMessage from './WelcomeMessage';

const WelcomeBanner: FC<IWidgetContent> = ({
  account,
  userPreferencesRootKey,
  updateSize,
  resetDashboardToDefault,
}) => {
  const translate = useTranslate();
  const [expanded, setExpanded] = usePreferences(
    `${userPreferencesRootKey}.expanded`,
    true
  );

  const onToggleExpansion = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded);
    updateSize(12, isExpanded ? 2 : 1);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={onToggleExpansion}
      style={{ maxHeight: '13em', overflowY: 'auto' }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box>
          <Typography
            variant='h5'
            component='h2'
            style={{ fontWeight: 'bolder' }}
          >
            {translate('dxMessages.dashboard.Welcome', { firstname: '' })}
          </Typography>
          <LastLogin accountId={account?.id} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <WelcomeMessage resetDashboardToDefault={resetDashboardToDefault} />
      </AccordionDetails>
    </Accordion>
  );
};

// interface LastLoginTimeResponse extends RaRecord {
//   // ISO-8601 representation.
//   lastLoginTime: string;
// }

const LastLogin = ({ accountId }) => {
  const translate = useTranslate();
  // Be careful: useGetOne() breaks the browser on logout.
  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'configuration-accounts',
    payload: { id: `${accountId}/lastLoginTime` },
  });

  if (error) return null;
  if (loading) return <Loading />;
  if (!data.lastLoginTime) return null;

  return (
    <Typography variant='caption'>
      {translate('dxMessages.dashboard.widgets.WelcomeBanner.LastLogin')}:{' '}
      {moment(data.lastLoginTime).format('lll')}
    </Typography>
  );
};

export default WelcomeBanner;
