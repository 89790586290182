import { Constants } from '@dx-ui/dx-common';
import { Sync } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Record, RedirectionSideEffect } from 'ra-core';
import { FC, ReactElement, SyntheticEvent } from 'react';
import {
  Button,
  ButtonProps,
  useNotify,
  useRecordContext,
  useUpdate,
} from 'react-admin';

const PspAdminSyncButton: FC<SyncButtonProps> = ({
  basePath = '',
  label = 'dxMessages.companyManagement.account.sync',
  record,
  icon = defaultIcon,
  ...rest
}) => {
  const recordContext: any = useRecordContext();
  const [syncAction] = useUpdate();
  const notify: any = useNotify();

  const syncAddress = () => {
    syncAction(
      Constants.RESOURCE_ADDRESS_BUFFER,
      recordContext.id,
      undefined,
      undefined,
      {
        onSuccess: () => {
          notify('dxMessages.companyManagement.addressBuffer.message');
        },
        onFailure: () => {
          notify('Error', {
            type: 'error',
            messageArgs: {
              error: 'An error occurred',
            },
          });
        },
      }
    );
  };

  return (
    <Button
      label={label}
      {...(rest as any)}
      resource='id'
      onClick={() => syncAddress()}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <Sync />;

interface Props {
  basePath?: string;
  classes?: object;
  className?: string;
  icon?: ReactElement;
  label?: string;
  onClick?: (e: MouseEvent) => void;
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;

  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  undoable?: boolean;
}

export type SyncButtonProps = Props & ButtonProps;

PspAdminSyncButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  // @ts-ignore
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  undoable: PropTypes.bool,
  icon: PropTypes.element,
};

PspAdminSyncButton.defaultProps = {
  undoable: true,
};

export default PspAdminSyncButton;
