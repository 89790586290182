import BusinessIcon from '@material-ui/icons/Business';
import CompanyCreate from './CompanyCreate';
import CompanyEdit from './CompanyEdit';
import CompanyList from './CompanyList';

const company = {
  icon: BusinessIcon,
  list: CompanyList,
  edit: CompanyEdit,
  create: CompanyCreate,
};

export default company;
