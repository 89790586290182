import { GLNInput } from '@dx-ui/dx-common';
import { FieldComponentProps } from './FieldComponentProps';
import FieldWrapper from './FieldWrapper';

/**
 * Renders a GLNInput
 * The rendering takes into account that the field can be a member of an array.
 */
const GLNInputField = (props: FieldComponentProps) => {
  const { field } = props;
  const sanitizeProps: any = ({
    loading,
    withConfirm,
    enableIfValid,
    ...rest
  }): any => rest;
  return (
    <FieldWrapper {...props}>
      {(fieldProps) => (
        <GLNInput
          {...sanitizeProps(fieldProps)}
          titleSuffix={field?.businessTerms}
          resettable
        />
      )}
    </FieldWrapper>
  );
};

export default GLNInputField;
